import React from 'react';
import { Outlet } from 'react-router-dom';
import AssessmentNavbar from '../components/Navbar/AssessmentNavbar';

const LayoutAssessment = () => {
    return (
        <div>
            <AssessmentNavbar/>
            <Outlet/>
        </div>
    );
};

export default LayoutAssessment;