export const storePhone = (phone) => {
    return {
        type: "UPDATE_PHONE",
        payload: phone
    };
};

export const storeToken = (token) => {
    return {
        type: "UPDATE_TOKEN",
        payload: token
    };
};

export const resetStorePass = () => {
    return {
        type: "RESET_DATA"
    };
}