import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AccountPopover from './AccountPopover';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import "./MyNavbar.css"
import { AppBar, Badge, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import DrawerMyNavbar from './DrawerMyNavbar';
import LanguagePopover from './LanguagePopover';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MyNavbar = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { auth, cart, profile } = useSelector(state => state)
    const [anchorElNav, setAnchorElNav] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(true);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const SumQtyinCart = () => {
        const sum = cart.items.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.qty);
        }, 0);

        return sum
    };
    
    return (
        <Box sx={{ display: 'flex', padding: "0.5rem 0px", marginBottom: "70px" }}>
            <AppBar
                position="fixed"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, py: 0.5 }}
            >
                <Container maxWidth="lg" disableGutters>
                    <Toolbar sx={{ flexWrap: 'wrap' }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="/"
                                sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                            >
                                <img
                                    alt="logo"
                                    src={require('../../assets/W.png')}
                                    style={{
                                        height: 60,
                                        maxWidth: 200,
                                    }}
                                />
                            </Link>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon fontSize='24pt' />
                            </IconButton>
                        </Box>
                        <Link
                            variant="button"
                            color="text.primary"
                            href="/"
                            sx={{ my: 1, mx: 1.5, textDecoration: 'none', display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}
                        >
                            <img
                                alt="logo"
                                src={require('../../assets/W.png')}
                                style={{
                                    height: 60,
                                    maxWidth: "100%",
                                }}
                            />
                        </Link>
                        <Box sx={{ flexGrow: 1, mx: 2, display: { xs: 'none', md: 'flex' } }}>
                            <div className='dropdown list'>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="/explore/products"
                                    sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                    textTransform="none"
                                >
                                    {t('navbar.shopping')}
                                </Link>
                            </div>
                            <div className='dropdown list'>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="#"
                                    sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                    textTransform="none"
                                >
                                    {t('navbar.about')}
                                </Link>
                                <ul className="dropdown-content">
                                    <li><a href="/about/ourteam">{t('sub_navbar.ourteams')}</a></li>
                                    <li><a href="/about/contact">{t('sub_navbar.contact')}</a></li>
                                </ul>
                            </div>
                            <div className='dropdown list'>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="/blog"
                                    sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                    textTransform="none"
                                >
                                    {t('navbar.news')}
                                </Link>
                            </div>
                            <div className='dropdown list'>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="#"
                                    sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                    textTransform="none"
                                >
                                    {t('navbar.explore')}
                                </Link>
                                <ul className="dropdown-content">
                                    <li><a href="/explore/review">{t('sub_navbar.customer_review')}</a></li>
                                    <li><a href="/explore/products">{t('sub_navbar.product')}</a></li>
                                    <li><a href="/assessment">{t('sub_navbar.assessment')}</a></li>
                                </ul>
                            </div>
                        </Box>
                        <Box sx={{ px: 2, display: { xs: 'none', md: 'flex' } }}>
                            <LanguagePopover />
                        </Box>
                        {auth.accessToken.token ? (
                            <AccountPopover username={auth.userName} image={profile.user.image} />
                        ) : (
                            <Button variant="text" disableTouchRipple disableFocusRipple disableRipple disableElevation sx={{ my: 1, mx: 1.5, textTransform: 'none', color: "#0D5478" }} onClick={() => window.location.replace('/login')}>
                                {t('navbar_menu.login')}
                            </Button>
                        )}
                        <Link
                            variant="button"
                            underline="none"
                            color="#000000"
                            href="/cart"
                            sx={{ my: 1, px: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <Badge color="error" badgeContent={SumQtyinCart()}>
                                <ShoppingCartIcon />
                            </Badge>
                        </Link>
                    </Toolbar>
                </Container>
            </AppBar>
            <DrawerMyNavbar open={anchorElNav} handleDrawerClose={handleCloseNavMenu} handleDrawerOpen={handleOpenNavMenu} cartLength={SumQtyinCart()} />
        </Box>
    );
}

export default MyNavbar;