import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { fDate } from '../../utils/formatTime'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const Personalinfo = (props) => {
    const { t, i18n } = useTranslation()
    const { profile } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    return (
        <Box>
            <Helmet>
                <title> Profile | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="lg">
                <Grid container>
                    <h3 className="mb-4">{t('profile_page.profile')}</h3>
                    <Grid item xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <img src={profile.user.image ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/profile/${profile.user.image}` : require("../../assets/icon/account/user.png")} alt="profile" className="img-fluid rounded-circle" style={{ objectFit: "cover"}} />
                        <Typography variant="body2" sx={{ my: 1, fontSize: 18 }} component={"h5"}>{profile.user.firstname} {profile.user.lastname}</Typography>
                    </Grid>
                    <Grid item xs={12} alignItems={"center"}>
                        <List sx={{ p: 0 }}>
                            <ListItem sx={{ display: "flex", width: "100%", padding: "8px 24px" }}>
                                <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                    <Typography component="h5" variant="body1" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "150px" }}>
                                        {t('profile_page.edit_profile.email')}
                                    </Typography>
                                    <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                        <Typography variant="body1" component={"h5"}>
                                            {profile.user.email || "N/A"}
                                        </Typography>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ display: "flex", width: "100%", padding: "8px 24px" }}>
                                <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                    <Typography component="h5" variant="body1" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "150px" }}>
                                        {t('profile_page.edit_profile.birthday')}
                                    </Typography>
                                    <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                        <Typography variant="body1" component={"h5"}>
                                            {fDate(profile.user.birthday, i18n.language) || "N/A"}
                                        </Typography>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            <ListItem sx={{ display: "flex", width: "100%", padding: "8px 24px" }}>
                                <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                    <Typography component="h5" variant="body1" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "150px" }}>
                                        {t('profile_page.edit_profile.gender.header')}
                                    </Typography>
                                    <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                        <Typography variant="body1" component={"h5"}>
                                            {( i18n.language === 'en' ? (profile.user.gender || "N/A") : profile.user.gender === "male" ? t('assessment_question.q3.choice.c0') : profile.user.gender === "female" ? t('assessment_question.q3.choice.c1') : "N/A")}
                                        </Typography>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Personalinfo