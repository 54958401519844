import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import React from 'react';
import useResponsive from '../../utils/useResponsive';
import { NavLink as RouterLink } from 'react-router-dom';
import { ProfileMenuList } from '../Drawer/ProfileMenuList';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const drawerWidth = 280

const StyledNavItemIcon = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: '#8e959b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const DrawerProfile = ({ open, handleDrawerClose }) => {
    const {t} = useTranslation()
    const isDesktop = useResponsive('up', 'md');
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: { md: drawerWidth },
            }}
        >
            {/* For Desktop  */}
            {isDesktop ? (<Drawer
                PaperProps={{
                    sx: {
                        width: drawerWidth
                    }
                }}
                variant="permanent"
            >
                <Toolbar sx={{ height: 80 }} />
                <Divider />
                <List>
                    {ProfileMenuList.map((i, index) => (
                        <ListItem key={i.id} disablePadding>
                            <ListItemButton
                                component={RouterLink}
                                to={`${i.path}`}
                                // sx={{
                                //     '&.active': {
                                //         color: '#ffffff',
                                //         bgcolor: '#9cd7db',
                                //         fontWeight: 'fontWeightBold',
                                //     },
                                // }}
                            >
                                <StyledNavItemIcon>
                                    <img src={i.icon} style={{ width: 25, height: 25 }} />
                                </StyledNavItemIcon>
                                <ListItemText primary={t(i.title)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>) :
                (<Drawer
                    PaperProps={{
                        sx: {
                            width: drawerWidth
                        }
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    open={open}
                    onClose={handleDrawerClose}
                    variant="temporary"
                    anchor="left"
                >
                    <Toolbar sx={{ height: 80 }} />
                    <Divider />
                    <List>
                        {ProfileMenuList.map((i, index) => (
                            <ListItem key={i.id} disablePadding>
                                <ListItemButton
                                    onClick={handleDrawerClose}
                                    component={RouterLink}
                                    to={`${i.path}`}
                                    // sx={{
                                    //     '&.active': {
                                    //         color: '#ffffff',
                                    //         bgcolor: '#9cd7db',
                                    //         fontWeight: 'fontWeightBold',
                                    //     },
                                    // }}
                                >
                                    <StyledNavItemIcon>
                                        <img src={i.icon} style={{ width: 25, height: 25 }} />
                                    </StyledNavItemIcon>
                                    <ListItemText primary={t(i.title)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>)}
        </Box>
    );
};

export default DrawerProfile;