import { configureStore } from '@reduxjs/toolkit'
import allReducers from '../reducers'
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE,  } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
  key: 'root',
  storage: storage,
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}
const persistedReducer = persistReducer(persistConfig, allReducers)

/* Creating a store with the persisted reducer and the middleware. */
const store = configureStore({
  devTools: (process.env.NODE_ENV === 'development' ? true : false),
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'SET_REFRESH_TOKEN', 'SET_ACCESS_TOKEN']
      },  immutableCheck: false
      
    }),
})

export default store;