export const Zipcode = [
    { "ZIPCODE_ID": 1, "SUB_DISTRICT_CODE": "100101", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "1", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 2, "SUB_DISTRICT_CODE": "100102", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "2", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 3, "SUB_DISTRICT_CODE": "100103", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "3", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 4, "SUB_DISTRICT_CODE": "100104", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "4", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 5, "SUB_DISTRICT_CODE": "100105", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "5", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 6, "SUB_DISTRICT_CODE": "100106", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "6", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 7, "SUB_DISTRICT_CODE": "100107", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "7", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 8, "SUB_DISTRICT_CODE": "100108", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "8", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 9, "SUB_DISTRICT_CODE": "100109", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "9", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 10, "SUB_DISTRICT_CODE": "100110", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "10", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 11, "SUB_DISTRICT_CODE": "100111", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "11", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 12, "SUB_DISTRICT_CODE": "100112", "PROVINCE_ID": "1", "DISTRICT_ID": "1", "SUB_DISTRICT_ID": "12", "ZIPCODE": "10200" },
    { "ZIPCODE_ID": 13, "SUB_DISTRICT_CODE": "100201", "PROVINCE_ID": "1", "DISTRICT_ID": "2", "SUB_DISTRICT_ID": "13", "ZIPCODE": "10300" },
    { "ZIPCODE_ID": 14, "SUB_DISTRICT_CODE": "100202", "PROVINCE_ID": "1", "DISTRICT_ID": "2", "SUB_DISTRICT_ID": "14", "ZIPCODE": "10300" },
    { "ZIPCODE_ID": 15, "SUB_DISTRICT_CODE": "100203", "PROVINCE_ID": "1", "DISTRICT_ID": "2", "SUB_DISTRICT_ID": "15", "ZIPCODE": "10300" },
    { "ZIPCODE_ID": 16, "SUB_DISTRICT_CODE": "100204", "PROVINCE_ID": "1", "DISTRICT_ID": "2", "SUB_DISTRICT_ID": "16", "ZIPCODE": "10300" },
    { "ZIPCODE_ID": 17, "SUB_DISTRICT_CODE": "100206", "PROVINCE_ID": "1", "DISTRICT_ID": "2", "SUB_DISTRICT_ID": "18", "ZIPCODE": "10300" },
    { "ZIPCODE_ID": 18, "SUB_DISTRICT_CODE": "100301", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "20", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 19, "SUB_DISTRICT_CODE": "100302", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "21", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 20, "SUB_DISTRICT_CODE": "100303", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "22", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 21, "SUB_DISTRICT_CODE": "100304", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "23", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 22, "SUB_DISTRICT_CODE": "100305", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "24", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 23, "SUB_DISTRICT_CODE": "100306", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "25", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 24, "SUB_DISTRICT_CODE": "100307", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "26", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 25, "SUB_DISTRICT_CODE": "100308", "PROVINCE_ID": "1", "DISTRICT_ID": "3", "SUB_DISTRICT_ID": "27", "ZIPCODE": "10530" },
    { "ZIPCODE_ID": 26, "SUB_DISTRICT_CODE": "100401", "PROVINCE_ID": "1", "DISTRICT_ID": "4", "SUB_DISTRICT_ID": "28", "ZIPCODE": "10500" },
    { "ZIPCODE_ID": 27, "SUB_DISTRICT_CODE": "100402", "PROVINCE_ID": "1", "DISTRICT_ID": "4", "SUB_DISTRICT_ID": "29", "ZIPCODE": "10500" },
    { "ZIPCODE_ID": 28, "SUB_DISTRICT_CODE": "100403", "PROVINCE_ID": "1", "DISTRICT_ID": "4", "SUB_DISTRICT_ID": "30", "ZIPCODE": "10500" },
    { "ZIPCODE_ID": 29, "SUB_DISTRICT_CODE": "100404", "PROVINCE_ID": "1", "DISTRICT_ID": "4", "SUB_DISTRICT_ID": "31", "ZIPCODE": "10500" },
    { "ZIPCODE_ID": 30, "SUB_DISTRICT_CODE": "100405", "PROVINCE_ID": "1", "DISTRICT_ID": "4", "SUB_DISTRICT_ID": "32", "ZIPCODE": "10500" },
    { "ZIPCODE_ID": 31, "SUB_DISTRICT_CODE": "100502", "PROVINCE_ID": "1", "DISTRICT_ID": "5", "SUB_DISTRICT_ID": "34", "ZIPCODE": "10220" },
    { "ZIPCODE_ID": 32, "SUB_DISTRICT_CODE": "100508", "PROVINCE_ID": "1", "DISTRICT_ID": "5", "SUB_DISTRICT_ID": "40", "ZIPCODE": "10220" },
    { "ZIPCODE_ID": 33, "SUB_DISTRICT_CODE": "100601", "PROVINCE_ID": "1", "DISTRICT_ID": "6", "SUB_DISTRICT_ID": "43", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 34, "SUB_DISTRICT_CODE": "100608", "PROVINCE_ID": "1", "DISTRICT_ID": "6", "SUB_DISTRICT_ID": "50", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 35, "SUB_DISTRICT_CODE": "100701", "PROVINCE_ID": "1", "DISTRICT_ID": "7", "SUB_DISTRICT_ID": "51", "ZIPCODE": "10330" },
    { "ZIPCODE_ID": 36, "SUB_DISTRICT_CODE": "100702", "PROVINCE_ID": "1", "DISTRICT_ID": "7", "SUB_DISTRICT_ID": "52", "ZIPCODE": "10330" },
    { "ZIPCODE_ID": 37, "SUB_DISTRICT_CODE": "100703", "PROVINCE_ID": "1", "DISTRICT_ID": "7", "SUB_DISTRICT_ID": "53", "ZIPCODE": "10330" },
    { "ZIPCODE_ID": 38, "SUB_DISTRICT_CODE": "100704", "PROVINCE_ID": "1", "DISTRICT_ID": "7", "SUB_DISTRICT_ID": "54", "ZIPCODE": "10330" },
    { "ZIPCODE_ID": 39, "SUB_DISTRICT_CODE": "100801", "PROVINCE_ID": "1", "DISTRICT_ID": "8", "SUB_DISTRICT_ID": "55", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 40, "SUB_DISTRICT_CODE": "100802", "PROVINCE_ID": "1", "DISTRICT_ID": "8", "SUB_DISTRICT_ID": "56", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 41, "SUB_DISTRICT_CODE": "100803", "PROVINCE_ID": "1", "DISTRICT_ID": "8", "SUB_DISTRICT_ID": "57", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 42, "SUB_DISTRICT_CODE": "100804", "PROVINCE_ID": "1", "DISTRICT_ID": "8", "SUB_DISTRICT_ID": "58", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 43, "SUB_DISTRICT_CODE": "100805", "PROVINCE_ID": "1", "DISTRICT_ID": "8", "SUB_DISTRICT_ID": "59", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 44, "SUB_DISTRICT_CODE": "100905", "PROVINCE_ID": "1", "DISTRICT_ID": "9", "SUB_DISTRICT_ID": "65", "ZIPCODE": "10260" },
    { "ZIPCODE_ID": 45, "SUB_DISTRICT_CODE": "101001", "PROVINCE_ID": "1", "DISTRICT_ID": "10", "SUB_DISTRICT_ID": "73", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 46, "SUB_DISTRICT_CODE": "101002", "PROVINCE_ID": "1", "DISTRICT_ID": "10", "SUB_DISTRICT_ID": "74", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 47, "SUB_DISTRICT_CODE": "101101", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "80", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 48, "SUB_DISTRICT_CODE": "101102", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "81", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 49, "SUB_DISTRICT_CODE": "101103", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "82", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 50, "SUB_DISTRICT_CODE": "101104", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "83", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 51, "SUB_DISTRICT_CODE": "101105", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "84", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 52, "SUB_DISTRICT_CODE": "101106", "PROVINCE_ID": "1", "DISTRICT_ID": "11", "SUB_DISTRICT_ID": "85", "ZIPCODE": "10520" },
    { "ZIPCODE_ID": 53, "SUB_DISTRICT_CODE": "101203", "PROVINCE_ID": "1", "DISTRICT_ID": "12", "SUB_DISTRICT_ID": "88", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 54, "SUB_DISTRICT_CODE": "101204", "PROVINCE_ID": "1", "DISTRICT_ID": "12", "SUB_DISTRICT_ID": "89", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 55, "SUB_DISTRICT_CODE": "101301", "PROVINCE_ID": "1", "DISTRICT_ID": "13", "SUB_DISTRICT_ID": "96", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 56, "SUB_DISTRICT_CODE": "101302", "PROVINCE_ID": "1", "DISTRICT_ID": "13", "SUB_DISTRICT_ID": "97", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 57, "SUB_DISTRICT_CODE": "101303", "PROVINCE_ID": "1", "DISTRICT_ID": "13", "SUB_DISTRICT_ID": "98", "ZIPCODE": "10100" },
    { "ZIPCODE_ID": 58, "SUB_DISTRICT_CODE": "101401", "PROVINCE_ID": "1", "DISTRICT_ID": "14", "SUB_DISTRICT_ID": "99", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 59, "SUB_DISTRICT_CODE": "101501", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "105", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 60, "SUB_DISTRICT_CODE": "101502", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "106", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 61, "SUB_DISTRICT_CODE": "101503", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "107", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 62, "SUB_DISTRICT_CODE": "101504", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "108", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 63, "SUB_DISTRICT_CODE": "101505", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "109", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 64, "SUB_DISTRICT_CODE": "101506", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "110", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 65, "SUB_DISTRICT_CODE": "101507", "PROVINCE_ID": "1", "DISTRICT_ID": "15", "SUB_DISTRICT_ID": "111", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 66, "SUB_DISTRICT_CODE": "101601", "PROVINCE_ID": "1", "DISTRICT_ID": "16", "SUB_DISTRICT_ID": "113", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 67, "SUB_DISTRICT_CODE": "101602", "PROVINCE_ID": "1", "DISTRICT_ID": "16", "SUB_DISTRICT_ID": "114", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 68, "SUB_DISTRICT_CODE": "101701", "PROVINCE_ID": "1", "DISTRICT_ID": "17", "SUB_DISTRICT_ID": "115", "ZIPCODE": "10310" },
    { "ZIPCODE_ID": 69, "SUB_DISTRICT_CODE": "101702", "PROVINCE_ID": "1", "DISTRICT_ID": "17", "SUB_DISTRICT_ID": "116", "ZIPCODE": "10310" },
    { "ZIPCODE_ID": 70, "SUB_DISTRICT_CODE": "101704", "PROVINCE_ID": "1", "DISTRICT_ID": "17", "SUB_DISTRICT_ID": "118", "ZIPCODE": "10310" },
    { "ZIPCODE_ID": 71, "SUB_DISTRICT_CODE": "101801", "PROVINCE_ID": "1", "DISTRICT_ID": "18", "SUB_DISTRICT_ID": "119", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 72, "SUB_DISTRICT_CODE": "101802", "PROVINCE_ID": "1", "DISTRICT_ID": "18", "SUB_DISTRICT_ID": "120", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 73, "SUB_DISTRICT_CODE": "101803", "PROVINCE_ID": "1", "DISTRICT_ID": "18", "SUB_DISTRICT_ID": "121", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 74, "SUB_DISTRICT_CODE": "101804", "PROVINCE_ID": "1", "DISTRICT_ID": "18", "SUB_DISTRICT_ID": "122", "ZIPCODE": "10600" },
    { "ZIPCODE_ID": 75, "SUB_DISTRICT_CODE": "101901", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "123", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 76, "SUB_DISTRICT_CODE": "101902", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "124", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 77, "SUB_DISTRICT_CODE": "101903", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "125", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 78, "SUB_DISTRICT_CODE": "101904", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "126", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 79, "SUB_DISTRICT_CODE": "101905", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "127", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 80, "SUB_DISTRICT_CODE": "101907", "PROVINCE_ID": "1", "DISTRICT_ID": "19", "SUB_DISTRICT_ID": "129", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 81, "SUB_DISTRICT_CODE": "102004", "PROVINCE_ID": "1", "DISTRICT_ID": "20", "SUB_DISTRICT_ID": "134", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 82, "SUB_DISTRICT_CODE": "102005", "PROVINCE_ID": "1", "DISTRICT_ID": "20", "SUB_DISTRICT_ID": "135", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 83, "SUB_DISTRICT_CODE": "102006", "PROVINCE_ID": "1", "DISTRICT_ID": "20", "SUB_DISTRICT_ID": "136", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 84, "SUB_DISTRICT_CODE": "102007", "PROVINCE_ID": "1", "DISTRICT_ID": "20", "SUB_DISTRICT_ID": "137", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 85, "SUB_DISTRICT_CODE": "102009", "PROVINCE_ID": "1", "DISTRICT_ID": "20", "SUB_DISTRICT_ID": "139", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 86, "SUB_DISTRICT_CODE": "102105", "PROVINCE_ID": "1", "DISTRICT_ID": "21", "SUB_DISTRICT_ID": "147", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 87, "SUB_DISTRICT_CODE": "102107", "PROVINCE_ID": "1", "DISTRICT_ID": "21", "SUB_DISTRICT_ID": "149", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 88, "SUB_DISTRICT_CODE": "102201", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "150", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 89, "SUB_DISTRICT_CODE": "102202", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "151", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 90, "SUB_DISTRICT_CODE": "102203", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "152", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 91, "SUB_DISTRICT_CODE": "102204", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "153", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 92, "SUB_DISTRICT_CODE": "102205", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "154", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 93, "SUB_DISTRICT_CODE": "102206", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "155", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 94, "SUB_DISTRICT_CODE": "102207", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "156", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 95, "SUB_DISTRICT_CODE": "102208", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "157", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 96, "SUB_DISTRICT_CODE": "102209", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "158", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 97, "SUB_DISTRICT_CODE": "102210", "PROVINCE_ID": "1", "DISTRICT_ID": "22", "SUB_DISTRICT_ID": "159", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 98, "SUB_DISTRICT_CODE": "102302", "PROVINCE_ID": "1", "DISTRICT_ID": "23", "SUB_DISTRICT_ID": "161", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 99, "SUB_DISTRICT_CODE": "102303", "PROVINCE_ID": "1", "DISTRICT_ID": "23", "SUB_DISTRICT_ID": "162", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 100, "SUB_DISTRICT_CODE": "102401", "PROVINCE_ID": "1", "DISTRICT_ID": "24", "SUB_DISTRICT_ID": "163", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 101, "SUB_DISTRICT_CODE": "102402", "PROVINCE_ID": "1", "DISTRICT_ID": "24", "SUB_DISTRICT_ID": "164", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 102, "SUB_DISTRICT_CODE": "102403", "PROVINCE_ID": "1", "DISTRICT_ID": "24", "SUB_DISTRICT_ID": "165", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 103, "SUB_DISTRICT_CODE": "102404", "PROVINCE_ID": "1", "DISTRICT_ID": "24", "SUB_DISTRICT_ID": "166", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 104, "SUB_DISTRICT_CODE": "102501", "PROVINCE_ID": "1", "DISTRICT_ID": "25", "SUB_DISTRICT_ID": "167", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 105, "SUB_DISTRICT_CODE": "102502", "PROVINCE_ID": "1", "DISTRICT_ID": "25", "SUB_DISTRICT_ID": "168", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 106, "SUB_DISTRICT_CODE": "102503", "PROVINCE_ID": "1", "DISTRICT_ID": "25", "SUB_DISTRICT_ID": "169", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 107, "SUB_DISTRICT_CODE": "102504", "PROVINCE_ID": "1", "DISTRICT_ID": "25", "SUB_DISTRICT_ID": "170", "ZIPCODE": "10700" },
    { "ZIPCODE_ID": 108, "SUB_DISTRICT_CODE": "102601", "PROVINCE_ID": "1", "DISTRICT_ID": "26", "SUB_DISTRICT_ID": "171", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 109, "SUB_DISTRICT_CODE": "102701", "PROVINCE_ID": "1", "DISTRICT_ID": "27", "SUB_DISTRICT_ID": "172", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 110, "SUB_DISTRICT_CODE": "102702", "PROVINCE_ID": "1", "DISTRICT_ID": "27", "SUB_DISTRICT_ID": "173", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 111, "SUB_DISTRICT_CODE": "102703", "PROVINCE_ID": "1", "DISTRICT_ID": "27", "SUB_DISTRICT_ID": "174", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 112, "SUB_DISTRICT_CODE": "102801", "PROVINCE_ID": "1", "DISTRICT_ID": "28", "SUB_DISTRICT_ID": "175", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 113, "SUB_DISTRICT_CODE": "102802", "PROVINCE_ID": "1", "DISTRICT_ID": "28", "SUB_DISTRICT_ID": "176", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 114, "SUB_DISTRICT_CODE": "102803", "PROVINCE_ID": "1", "DISTRICT_ID": "28", "SUB_DISTRICT_ID": "177", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 115, "SUB_DISTRICT_CODE": "102901", "PROVINCE_ID": "1", "DISTRICT_ID": "29", "SUB_DISTRICT_ID": "178", "ZIPCODE": "10800" },
    { "ZIPCODE_ID": 116, "SUB_DISTRICT_CODE": "103001", "PROVINCE_ID": "1", "DISTRICT_ID": "30", "SUB_DISTRICT_ID": "179", "ZIPCODE": "10900" },
    { "ZIPCODE_ID": 117, "SUB_DISTRICT_CODE": "103002", "PROVINCE_ID": "1", "DISTRICT_ID": "30", "SUB_DISTRICT_ID": "180", "ZIPCODE": "10900" },
    { "ZIPCODE_ID": 118, "SUB_DISTRICT_CODE": "103003", "PROVINCE_ID": "1", "DISTRICT_ID": "30", "SUB_DISTRICT_ID": "181", "ZIPCODE": "10900" },
    { "ZIPCODE_ID": 119, "SUB_DISTRICT_CODE": "103004", "PROVINCE_ID": "1", "DISTRICT_ID": "30", "SUB_DISTRICT_ID": "182", "ZIPCODE": "10900" },
    { "ZIPCODE_ID": 120, "SUB_DISTRICT_CODE": "103005", "PROVINCE_ID": "1", "DISTRICT_ID": "30", "SUB_DISTRICT_ID": "183", "ZIPCODE": "10900" },
    { "ZIPCODE_ID": 121, "SUB_DISTRICT_CODE": "103101", "PROVINCE_ID": "1", "DISTRICT_ID": "31", "SUB_DISTRICT_ID": "184", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 122, "SUB_DISTRICT_CODE": "103102", "PROVINCE_ID": "1", "DISTRICT_ID": "31", "SUB_DISTRICT_ID": "185", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 123, "SUB_DISTRICT_CODE": "103103", "PROVINCE_ID": "1", "DISTRICT_ID": "31", "SUB_DISTRICT_ID": "186", "ZIPCODE": "10120" },
    { "ZIPCODE_ID": 124, "SUB_DISTRICT_CODE": "103201", "PROVINCE_ID": "1", "DISTRICT_ID": "32", "SUB_DISTRICT_ID": "187", "ZIPCODE": "10250" },
    { "ZIPCODE_ID": 125, "SUB_DISTRICT_CODE": "103202", "PROVINCE_ID": "1", "DISTRICT_ID": "32", "SUB_DISTRICT_ID": "188", "ZIPCODE": "10250" },
    { "ZIPCODE_ID": 126, "SUB_DISTRICT_CODE": "103203", "PROVINCE_ID": "1", "DISTRICT_ID": "32", "SUB_DISTRICT_ID": "189", "ZIPCODE": "10250" },
    { "ZIPCODE_ID": 127, "SUB_DISTRICT_CODE": "103204", "PROVINCE_ID": "1", "DISTRICT_ID": "32", "SUB_DISTRICT_ID": "190", "ZIPCODE": "10250" },
    { "ZIPCODE_ID": 128, "SUB_DISTRICT_CODE": "103301", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "191", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 129, "SUB_DISTRICT_CODE": "103302", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "192", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 130, "SUB_DISTRICT_CODE": "103303", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "193", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 131, "SUB_DISTRICT_CODE": "103304", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "194", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 132, "SUB_DISTRICT_CODE": "103305", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "195", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 133, "SUB_DISTRICT_CODE": "103306", "PROVINCE_ID": "1", "DISTRICT_ID": "33", "SUB_DISTRICT_ID": "196", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 134, "SUB_DISTRICT_CODE": "103401", "PROVINCE_ID": "1", "DISTRICT_ID": "34", "SUB_DISTRICT_ID": "197", "ZIPCODE": "10250" },
    { "ZIPCODE_ID": 135, "SUB_DISTRICT_CODE": "103501", "PROVINCE_ID": "1", "DISTRICT_ID": "35", "SUB_DISTRICT_ID": "198", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 136, "SUB_DISTRICT_CODE": "103502", "PROVINCE_ID": "1", "DISTRICT_ID": "35", "SUB_DISTRICT_ID": "199", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 137, "SUB_DISTRICT_CODE": "103503", "PROVINCE_ID": "1", "DISTRICT_ID": "35", "SUB_DISTRICT_ID": "200", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 138, "SUB_DISTRICT_CODE": "103504", "PROVINCE_ID": "1", "DISTRICT_ID": "35", "SUB_DISTRICT_ID": "201", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 139, "SUB_DISTRICT_CODE": "103602", "PROVINCE_ID": "1", "DISTRICT_ID": "36", "SUB_DISTRICT_ID": "203", "ZIPCODE": "10210" },
    { "ZIPCODE_ID": 140, "SUB_DISTRICT_CODE": "103701", "PROVINCE_ID": "1", "DISTRICT_ID": "37", "SUB_DISTRICT_ID": "205", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 141, "SUB_DISTRICT_CODE": "103702", "PROVINCE_ID": "1", "DISTRICT_ID": "37", "SUB_DISTRICT_ID": "206", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 142, "SUB_DISTRICT_CODE": "103703", "PROVINCE_ID": "1", "DISTRICT_ID": "37", "SUB_DISTRICT_ID": "207", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 143, "SUB_DISTRICT_CODE": "103704", "PROVINCE_ID": "1", "DISTRICT_ID": "37", "SUB_DISTRICT_ID": "208", "ZIPCODE": "10400" },
    { "ZIPCODE_ID": 144, "SUB_DISTRICT_CODE": "103801", "PROVINCE_ID": "1", "DISTRICT_ID": "38", "SUB_DISTRICT_ID": "209", "ZIPCODE": "10230" },
    { "ZIPCODE_ID": 145, "SUB_DISTRICT_CODE": "103802", "PROVINCE_ID": "1", "DISTRICT_ID": "38", "SUB_DISTRICT_ID": "210", "ZIPCODE": "10230" },
    { "ZIPCODE_ID": 146, "SUB_DISTRICT_CODE": "103901", "PROVINCE_ID": "1", "DISTRICT_ID": "39", "SUB_DISTRICT_ID": "211", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 147, "SUB_DISTRICT_CODE": "103902", "PROVINCE_ID": "1", "DISTRICT_ID": "39", "SUB_DISTRICT_ID": "212", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 148, "SUB_DISTRICT_CODE": "103903", "PROVINCE_ID": "1", "DISTRICT_ID": "39", "SUB_DISTRICT_ID": "213", "ZIPCODE": "10110" },
    { "ZIPCODE_ID": 149, "SUB_DISTRICT_CODE": "104001", "PROVINCE_ID": "1", "DISTRICT_ID": "40", "SUB_DISTRICT_ID": "214", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 150, "SUB_DISTRICT_CODE": "104002", "PROVINCE_ID": "1", "DISTRICT_ID": "40", "SUB_DISTRICT_ID": "215", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 151, "SUB_DISTRICT_CODE": "104003", "PROVINCE_ID": "1", "DISTRICT_ID": "40", "SUB_DISTRICT_ID": "216", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 152, "SUB_DISTRICT_CODE": "104004", "PROVINCE_ID": "1", "DISTRICT_ID": "40", "SUB_DISTRICT_ID": "217", "ZIPCODE": "10160" },
    { "ZIPCODE_ID": 153, "SUB_DISTRICT_CODE": "104101", "PROVINCE_ID": "1", "DISTRICT_ID": "41", "SUB_DISTRICT_ID": "218", "ZIPCODE": "10210" },
    { "ZIPCODE_ID": 154, "SUB_DISTRICT_CODE": "104102", "PROVINCE_ID": "1", "DISTRICT_ID": "41", "SUB_DISTRICT_ID": "219", "ZIPCODE": "10210" },
    { "ZIPCODE_ID": 155, "SUB_DISTRICT_CODE": "104201", "PROVINCE_ID": "1", "DISTRICT_ID": "42", "SUB_DISTRICT_ID": "220", "ZIPCODE": "10220" },
    { "ZIPCODE_ID": 156, "SUB_DISTRICT_CODE": "104202", "PROVINCE_ID": "1", "DISTRICT_ID": "42", "SUB_DISTRICT_ID": "221", "ZIPCODE": "10220" },
    { "ZIPCODE_ID": 157, "SUB_DISTRICT_CODE": "104203", "PROVINCE_ID": "1", "DISTRICT_ID": "42", "SUB_DISTRICT_ID": "222", "ZIPCODE": "10220" },
    { "ZIPCODE_ID": 158, "SUB_DISTRICT_CODE": "104301", "PROVINCE_ID": "1", "DISTRICT_ID": "43", "SUB_DISTRICT_ID": "223", "ZIPCODE": "10230" },
    { "ZIPCODE_ID": 159, "SUB_DISTRICT_CODE": "104401", "PROVINCE_ID": "1", "DISTRICT_ID": "44", "SUB_DISTRICT_ID": "224", "ZIPCODE": "10240" },
    { "ZIPCODE_ID": 160, "SUB_DISTRICT_CODE": "104501", "PROVINCE_ID": "1", "DISTRICT_ID": "45", "SUB_DISTRICT_ID": "225", "ZIPCODE": "10310" },
    { "ZIPCODE_ID": 161, "SUB_DISTRICT_CODE": "104601", "PROVINCE_ID": "1", "DISTRICT_ID": "46", "SUB_DISTRICT_ID": "226", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 162, "SUB_DISTRICT_CODE": "104602", "PROVINCE_ID": "1", "DISTRICT_ID": "46", "SUB_DISTRICT_ID": "227", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 163, "SUB_DISTRICT_CODE": "104603", "PROVINCE_ID": "1", "DISTRICT_ID": "46", "SUB_DISTRICT_ID": "228", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 164, "SUB_DISTRICT_CODE": "104604", "PROVINCE_ID": "1", "DISTRICT_ID": "46", "SUB_DISTRICT_ID": "229", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 165, "SUB_DISTRICT_CODE": "104605", "PROVINCE_ID": "1", "DISTRICT_ID": "46", "SUB_DISTRICT_ID": "230", "ZIPCODE": "10510" },
    { "ZIPCODE_ID": 166, "SUB_DISTRICT_CODE": "104701", "PROVINCE_ID": "1", "DISTRICT_ID": "47", "SUB_DISTRICT_ID": "231", "ZIPCODE": "10260" },
    { "ZIPCODE_ID": 167, "SUB_DISTRICT_CODE": "104801", "PROVINCE_ID": "1", "DISTRICT_ID": "48", "SUB_DISTRICT_ID": "232", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 168, "SUB_DISTRICT_CODE": "104802", "PROVINCE_ID": "1", "DISTRICT_ID": "48", "SUB_DISTRICT_ID": "233", "ZIPCODE": "10170" },
    { "ZIPCODE_ID": 169, "SUB_DISTRICT_CODE": "104901", "PROVINCE_ID": "1", "DISTRICT_ID": "49", "SUB_DISTRICT_ID": "234", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 170, "SUB_DISTRICT_CODE": "104902", "PROVINCE_ID": "1", "DISTRICT_ID": "49", "SUB_DISTRICT_ID": "235", "ZIPCODE": "10140" },
    { "ZIPCODE_ID": 171, "SUB_DISTRICT_CODE": "105001", "PROVINCE_ID": "1", "DISTRICT_ID": "50", "SUB_DISTRICT_ID": "236", "ZIPCODE": "10150" },
    { "ZIPCODE_ID": 172, "SUB_DISTRICT_CODE": "110101", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "237", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 173, "SUB_DISTRICT_CODE": "110102", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "238", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 174, "SUB_DISTRICT_CODE": "110103", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "239", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 175, "SUB_DISTRICT_CODE": "110104", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "240", "ZIPCODE": "10280" },
    { "ZIPCODE_ID": 176, "SUB_DISTRICT_CODE": "110108", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "244", "ZIPCODE": "10280" },
    { "ZIPCODE_ID": 177, "SUB_DISTRICT_CODE": "110110", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "246", "ZIPCODE": "10280" },
    { "ZIPCODE_ID": 178, "SUB_DISTRICT_CODE": "110111", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "247", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 179, "SUB_DISTRICT_CODE": "110112", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "248", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 180, "SUB_DISTRICT_CODE": "110113", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "249", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 181, "SUB_DISTRICT_CODE": "110114", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "250", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 182, "SUB_DISTRICT_CODE": "110115", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "251", "ZIPCODE": "10270" },
    { "ZIPCODE_ID": 183, "SUB_DISTRICT_CODE": "110116", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "252", "ZIPCODE": "10280" },
    { "ZIPCODE_ID": 184, "SUB_DISTRICT_CODE": "110117", "PROVINCE_ID": "2", "DISTRICT_ID": "52", "SUB_DISTRICT_ID": "253", "ZIPCODE": "10280" },
    { "ZIPCODE_ID": 185, "SUB_DISTRICT_CODE": "110201", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "260", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 186, "SUB_DISTRICT_CODE": "110202", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "261", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 187, "SUB_DISTRICT_CODE": "110203", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "262", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 188, "SUB_DISTRICT_CODE": "110204", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "263", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 189, "SUB_DISTRICT_CODE": "110205", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "264", "ZIPCODE": "10550" },
    { "ZIPCODE_ID": 190, "SUB_DISTRICT_CODE": "110206", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "265", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 191, "SUB_DISTRICT_CODE": "110207", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "266", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 192, "SUB_DISTRICT_CODE": "110208", "PROVINCE_ID": "2", "DISTRICT_ID": "53", "SUB_DISTRICT_ID": "267", "ZIPCODE": "10560" },
    { "ZIPCODE_ID": 193, "SUB_DISTRICT_CODE": "110301", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "269", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 194, "SUB_DISTRICT_CODE": "110302", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "270", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 195, "SUB_DISTRICT_CODE": "110303", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "271", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 196, "SUB_DISTRICT_CODE": "110304", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "272", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 197, "SUB_DISTRICT_CODE": "110308", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "276", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 198, "SUB_DISTRICT_CODE": "110309", "PROVINCE_ID": "2", "DISTRICT_ID": "54", "SUB_DISTRICT_ID": "277", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 199, "SUB_DISTRICT_CODE": "110401", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "278", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 200, "SUB_DISTRICT_CODE": "110402", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "279", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 201, "SUB_DISTRICT_CODE": "110403", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "280", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 202, "SUB_DISTRICT_CODE": "110404", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "281", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 203, "SUB_DISTRICT_CODE": "110405", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "282", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 204, "SUB_DISTRICT_CODE": "110406", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "283", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 205, "SUB_DISTRICT_CODE": "110407", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "284", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 206, "SUB_DISTRICT_CODE": "110408", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "285", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 207, "SUB_DISTRICT_CODE": "110409", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "286", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 208, "SUB_DISTRICT_CODE": "110410", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "287", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 209, "SUB_DISTRICT_CODE": "110411", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "288", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 210, "SUB_DISTRICT_CODE": "110412", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "289", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 211, "SUB_DISTRICT_CODE": "110413", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "290", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 212, "SUB_DISTRICT_CODE": "110414", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "291", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 213, "SUB_DISTRICT_CODE": "110415", "PROVINCE_ID": "2", "DISTRICT_ID": "55", "SUB_DISTRICT_ID": "292", "ZIPCODE": "10130" },
    { "ZIPCODE_ID": 214, "SUB_DISTRICT_CODE": "110501", "PROVINCE_ID": "2", "DISTRICT_ID": "56", "SUB_DISTRICT_ID": "293", "ZIPCODE": "10290" },
    { "ZIPCODE_ID": 215, "SUB_DISTRICT_CODE": "110502", "PROVINCE_ID": "2", "DISTRICT_ID": "56", "SUB_DISTRICT_ID": "294", "ZIPCODE": "10290" },
    { "ZIPCODE_ID": 216, "SUB_DISTRICT_CODE": "110503", "PROVINCE_ID": "2", "DISTRICT_ID": "56", "SUB_DISTRICT_ID": "295", "ZIPCODE": "10290" },
    { "ZIPCODE_ID": 217, "SUB_DISTRICT_CODE": "110504", "PROVINCE_ID": "2", "DISTRICT_ID": "56", "SUB_DISTRICT_ID": "296", "ZIPCODE": "10290" },
    { "ZIPCODE_ID": 218, "SUB_DISTRICT_CODE": "110505", "PROVINCE_ID": "2", "DISTRICT_ID": "56", "SUB_DISTRICT_ID": "297", "ZIPCODE": "10290" },
    { "ZIPCODE_ID": 219, "SUB_DISTRICT_CODE": "110601", "PROVINCE_ID": "2", "DISTRICT_ID": "57", "SUB_DISTRICT_ID": "298", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 220, "SUB_DISTRICT_CODE": "110602", "PROVINCE_ID": "2", "DISTRICT_ID": "57", "SUB_DISTRICT_ID": "299", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 221, "SUB_DISTRICT_CODE": "110603", "PROVINCE_ID": "2", "DISTRICT_ID": "57", "SUB_DISTRICT_ID": "300", "ZIPCODE": "10540" },
    { "ZIPCODE_ID": 222, "SUB_DISTRICT_CODE": "120101", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "301", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 223, "SUB_DISTRICT_CODE": "120102", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "302", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 224, "SUB_DISTRICT_CODE": "120103", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "303", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 225, "SUB_DISTRICT_CODE": "120104", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "304", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 226, "SUB_DISTRICT_CODE": "120105", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "305", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 227, "SUB_DISTRICT_CODE": "120106", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "306", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 228, "SUB_DISTRICT_CODE": "120107", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "307", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 229, "SUB_DISTRICT_CODE": "120108", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "308", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 230, "SUB_DISTRICT_CODE": "120109", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "309", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 231, "SUB_DISTRICT_CODE": "120110", "PROVINCE_ID": "3", "DISTRICT_ID": "58", "SUB_DISTRICT_ID": "310", "ZIPCODE": "11000" },
    { "ZIPCODE_ID": 232, "SUB_DISTRICT_CODE": "120201", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "311", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 233, "SUB_DISTRICT_CODE": "120202", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "312", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 234, "SUB_DISTRICT_CODE": "120203", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "313", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 235, "SUB_DISTRICT_CODE": "120204", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "314", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 236, "SUB_DISTRICT_CODE": "120205", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "315", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 237, "SUB_DISTRICT_CODE": "120206", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "316", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 238, "SUB_DISTRICT_CODE": "120207", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "317", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 239, "SUB_DISTRICT_CODE": "120208", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "318", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 240, "SUB_DISTRICT_CODE": "120209", "PROVINCE_ID": "3", "DISTRICT_ID": "59", "SUB_DISTRICT_ID": "319", "ZIPCODE": "11130" },
    { "ZIPCODE_ID": 241, "SUB_DISTRICT_CODE": "120301", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "320", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 242, "SUB_DISTRICT_CODE": "120302", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "321", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 243, "SUB_DISTRICT_CODE": "120303", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "322", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 244, "SUB_DISTRICT_CODE": "120304", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "323", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 245, "SUB_DISTRICT_CODE": "120305", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "324", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 246, "SUB_DISTRICT_CODE": "120306", "PROVINCE_ID": "3", "DISTRICT_ID": "60", "SUB_DISTRICT_ID": "325", "ZIPCODE": "11140" },
    { "ZIPCODE_ID": 247, "SUB_DISTRICT_CODE": "120401", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "326", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 248, "SUB_DISTRICT_CODE": "120402", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "327", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 249, "SUB_DISTRICT_CODE": "120403", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "328", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 250, "SUB_DISTRICT_CODE": "120404", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "329", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 251, "SUB_DISTRICT_CODE": "120405", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "330", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 252, "SUB_DISTRICT_CODE": "120406", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "331", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 253, "SUB_DISTRICT_CODE": "120407", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "332", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 254, "SUB_DISTRICT_CODE": "120408", "PROVINCE_ID": "3", "DISTRICT_ID": "61", "SUB_DISTRICT_ID": "333", "ZIPCODE": "11110" },
    { "ZIPCODE_ID": 255, "SUB_DISTRICT_CODE": "120501", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "334", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 256, "SUB_DISTRICT_CODE": "120502", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "335", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 257, "SUB_DISTRICT_CODE": "120503", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "336", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 258, "SUB_DISTRICT_CODE": "120504", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "337", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 259, "SUB_DISTRICT_CODE": "120505", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "338", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 260, "SUB_DISTRICT_CODE": "120506", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "339", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 261, "SUB_DISTRICT_CODE": "120507", "PROVINCE_ID": "3", "DISTRICT_ID": "62", "SUB_DISTRICT_ID": "340", "ZIPCODE": "11150" },
    { "ZIPCODE_ID": 262, "SUB_DISTRICT_CODE": "120601", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "341", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 263, "SUB_DISTRICT_CODE": "120602", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "342", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 264, "SUB_DISTRICT_CODE": "120603", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "343", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 265, "SUB_DISTRICT_CODE": "120604", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "344", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 266, "SUB_DISTRICT_CODE": "120605", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "345", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 267, "SUB_DISTRICT_CODE": "120606", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "346", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 268, "SUB_DISTRICT_CODE": "120607", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "347", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 269, "SUB_DISTRICT_CODE": "120608", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "348", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 270, "SUB_DISTRICT_CODE": "120609", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "349", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 271, "SUB_DISTRICT_CODE": "120610", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "350", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 272, "SUB_DISTRICT_CODE": "120611", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "351", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 273, "SUB_DISTRICT_CODE": "120612", "PROVINCE_ID": "3", "DISTRICT_ID": "63", "SUB_DISTRICT_ID": "352", "ZIPCODE": "11120" },
    { "ZIPCODE_ID": 274, "SUB_DISTRICT_CODE": "130101", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "353", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 275, "SUB_DISTRICT_CODE": "130102", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "354", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 276, "SUB_DISTRICT_CODE": "130103", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "355", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 277, "SUB_DISTRICT_CODE": "130104", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "356", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 278, "SUB_DISTRICT_CODE": "130105", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "357", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 279, "SUB_DISTRICT_CODE": "130106", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "358", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 280, "SUB_DISTRICT_CODE": "130107", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "359", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 281, "SUB_DISTRICT_CODE": "130108", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "360", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 282, "SUB_DISTRICT_CODE": "130109", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "361", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 283, "SUB_DISTRICT_CODE": "130110", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "362", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 284, "SUB_DISTRICT_CODE": "130111", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "363", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 285, "SUB_DISTRICT_CODE": "130112", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "364", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 286, "SUB_DISTRICT_CODE": "130113", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "365", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 287, "SUB_DISTRICT_CODE": "130114", "PROVINCE_ID": "4", "DISTRICT_ID": "66", "SUB_DISTRICT_ID": "366", "ZIPCODE": "12000" },
    { "ZIPCODE_ID": 288, "SUB_DISTRICT_CODE": "130201", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "367", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 289, "SUB_DISTRICT_CODE": "130202", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "368", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 290, "SUB_DISTRICT_CODE": "130203", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "369", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 291, "SUB_DISTRICT_CODE": "130204", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "370", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 292, "SUB_DISTRICT_CODE": "130205", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "371", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 293, "SUB_DISTRICT_CODE": "130206", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "372", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 294, "SUB_DISTRICT_CODE": "130207", "PROVINCE_ID": "4", "DISTRICT_ID": "67", "SUB_DISTRICT_ID": "373", "ZIPCODE": "12120" },
    { "ZIPCODE_ID": 295, "SUB_DISTRICT_CODE": "130301", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "374", "ZIPCODE": "12130" },
    { "ZIPCODE_ID": 296, "SUB_DISTRICT_CODE": "130302", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "375", "ZIPCODE": "12130" },
    { "ZIPCODE_ID": 297, "SUB_DISTRICT_CODE": "130303", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "376", "ZIPCODE": "12110" },
    { "ZIPCODE_ID": 298, "SUB_DISTRICT_CODE": "130304", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "377", "ZIPCODE": "12110" },
    { "ZIPCODE_ID": 299, "SUB_DISTRICT_CODE": "130305", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "378", "ZIPCODE": "12110" },
    { "ZIPCODE_ID": 300, "SUB_DISTRICT_CODE": "130306", "PROVINCE_ID": "4", "DISTRICT_ID": "68", "SUB_DISTRICT_ID": "379", "ZIPCODE": "12110" },
    { "ZIPCODE_ID": 301, "SUB_DISTRICT_CODE": "130401", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "380", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 302, "SUB_DISTRICT_CODE": "130402", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "381", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 303, "SUB_DISTRICT_CODE": "130403", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "382", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 304, "SUB_DISTRICT_CODE": "130404", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "383", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 305, "SUB_DISTRICT_CODE": "130405", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "384", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 306, "SUB_DISTRICT_CODE": "130406", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "385", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 307, "SUB_DISTRICT_CODE": "130407", "PROVINCE_ID": "4", "DISTRICT_ID": "69", "SUB_DISTRICT_ID": "386", "ZIPCODE": "12170" },
    { "ZIPCODE_ID": 308, "SUB_DISTRICT_CODE": "130501", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "387", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 309, "SUB_DISTRICT_CODE": "130502", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "388", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 310, "SUB_DISTRICT_CODE": "130503", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "389", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 311, "SUB_DISTRICT_CODE": "130504", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "390", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 312, "SUB_DISTRICT_CODE": "130505", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "391", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 313, "SUB_DISTRICT_CODE": "130506", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "392", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 314, "SUB_DISTRICT_CODE": "130507", "PROVINCE_ID": "4", "DISTRICT_ID": "70", "SUB_DISTRICT_ID": "393", "ZIPCODE": "12140" },
    { "ZIPCODE_ID": 315, "SUB_DISTRICT_CODE": "130601", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "394", "ZIPCODE": "12130" },
    { "ZIPCODE_ID": 316, "SUB_DISTRICT_CODE": "130602", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "395", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 317, "SUB_DISTRICT_CODE": "130603", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "396", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 318, "SUB_DISTRICT_CODE": "130604", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "397", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 319, "SUB_DISTRICT_CODE": "130605", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "398", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 320, "SUB_DISTRICT_CODE": "130606", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "399", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 321, "SUB_DISTRICT_CODE": "130607", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "400", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 322, "SUB_DISTRICT_CODE": "130608", "PROVINCE_ID": "4", "DISTRICT_ID": "71", "SUB_DISTRICT_ID": "401", "ZIPCODE": "12150" },
    { "ZIPCODE_ID": 323, "SUB_DISTRICT_CODE": "130701", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "402", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 324, "SUB_DISTRICT_CODE": "130702", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "403", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 325, "SUB_DISTRICT_CODE": "130703", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "404", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 326, "SUB_DISTRICT_CODE": "130704", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "405", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 327, "SUB_DISTRICT_CODE": "130705", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "406", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 328, "SUB_DISTRICT_CODE": "130706", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "407", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 329, "SUB_DISTRICT_CODE": "130707", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "408", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 330, "SUB_DISTRICT_CODE": "130708", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "409", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 331, "SUB_DISTRICT_CODE": "130709", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "410", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 332, "SUB_DISTRICT_CODE": "130710", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "411", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 333, "SUB_DISTRICT_CODE": "130711", "PROVINCE_ID": "4", "DISTRICT_ID": "72", "SUB_DISTRICT_ID": "412", "ZIPCODE": "12160" },
    { "ZIPCODE_ID": 334, "SUB_DISTRICT_CODE": "140101", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "413", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 335, "SUB_DISTRICT_CODE": "140102", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "414", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 336, "SUB_DISTRICT_CODE": "140103", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "415", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 337, "SUB_DISTRICT_CODE": "140104", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "416", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 338, "SUB_DISTRICT_CODE": "140105", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "417", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 339, "SUB_DISTRICT_CODE": "140106", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "418", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 340, "SUB_DISTRICT_CODE": "140107", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "419", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 341, "SUB_DISTRICT_CODE": "140108", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "420", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 342, "SUB_DISTRICT_CODE": "140109", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "421", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 343, "SUB_DISTRICT_CODE": "140110", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "422", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 344, "SUB_DISTRICT_CODE": "140111", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "423", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 345, "SUB_DISTRICT_CODE": "140112", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "424", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 346, "SUB_DISTRICT_CODE": "140113", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "425", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 347, "SUB_DISTRICT_CODE": "140114", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "426", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 348, "SUB_DISTRICT_CODE": "140115", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "427", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 349, "SUB_DISTRICT_CODE": "140116", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "428", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 350, "SUB_DISTRICT_CODE": "140117", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "429", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 351, "SUB_DISTRICT_CODE": "140118", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "430", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 352, "SUB_DISTRICT_CODE": "140119", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "431", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 353, "SUB_DISTRICT_CODE": "140120", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "432", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 354, "SUB_DISTRICT_CODE": "140121", "PROVINCE_ID": "5", "DISTRICT_ID": "74", "SUB_DISTRICT_ID": "433", "ZIPCODE": "13000" },
    { "ZIPCODE_ID": 355, "SUB_DISTRICT_CODE": "140201", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "435", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 356, "SUB_DISTRICT_CODE": "140202", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "436", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 357, "SUB_DISTRICT_CODE": "140203", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "437", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 358, "SUB_DISTRICT_CODE": "140204", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "438", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 359, "SUB_DISTRICT_CODE": "140205", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "439", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 360, "SUB_DISTRICT_CODE": "140206", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "440", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 361, "SUB_DISTRICT_CODE": "140207", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "441", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 362, "SUB_DISTRICT_CODE": "140208", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "442", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 363, "SUB_DISTRICT_CODE": "140209", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "443", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 364, "SUB_DISTRICT_CODE": "140210", "PROVINCE_ID": "5", "DISTRICT_ID": "75", "SUB_DISTRICT_ID": "444", "ZIPCODE": "13130" },
    { "ZIPCODE_ID": 365, "SUB_DISTRICT_CODE": "140301", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "445", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 366, "SUB_DISTRICT_CODE": "140302", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "446", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 367, "SUB_DISTRICT_CODE": "140303", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "447", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 368, "SUB_DISTRICT_CODE": "140304", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "448", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 369, "SUB_DISTRICT_CODE": "140305", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "449", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 370, "SUB_DISTRICT_CODE": "140306", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "450", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 371, "SUB_DISTRICT_CODE": "140307", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "451", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 372, "SUB_DISTRICT_CODE": "140308", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "452", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 373, "SUB_DISTRICT_CODE": "140309", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "453", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 374, "SUB_DISTRICT_CODE": "140310", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "454", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 375, "SUB_DISTRICT_CODE": "140311", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "455", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 376, "SUB_DISTRICT_CODE": "140312", "PROVINCE_ID": "5", "DISTRICT_ID": "76", "SUB_DISTRICT_ID": "456", "ZIPCODE": "13260" },
    { "ZIPCODE_ID": 377, "SUB_DISTRICT_CODE": "140401", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "457", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 378, "SUB_DISTRICT_CODE": "140402", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "458", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 379, "SUB_DISTRICT_CODE": "140403", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "459", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 380, "SUB_DISTRICT_CODE": "140404", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "460", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 381, "SUB_DISTRICT_CODE": "140405", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "461", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 382, "SUB_DISTRICT_CODE": "140406", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "462", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 383, "SUB_DISTRICT_CODE": "140407", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "463", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 384, "SUB_DISTRICT_CODE": "140408", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "464", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 385, "SUB_DISTRICT_CODE": "140409", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "465", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 386, "SUB_DISTRICT_CODE": "140410", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "466", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 387, "SUB_DISTRICT_CODE": "140411", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "467", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 388, "SUB_DISTRICT_CODE": "140412", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "468", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 389, "SUB_DISTRICT_CODE": "140413", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "469", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 390, "SUB_DISTRICT_CODE": "140414", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "470", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 391, "SUB_DISTRICT_CODE": "140415", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "471", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 392, "SUB_DISTRICT_CODE": "140416", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "472", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 393, "SUB_DISTRICT_CODE": "140417", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "473", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 394, "SUB_DISTRICT_CODE": "140418", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "474", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 395, "SUB_DISTRICT_CODE": "140419", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "475", "ZIPCODE": "13290" },
    { "ZIPCODE_ID": 396, "SUB_DISTRICT_CODE": "140420", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "476", "ZIPCODE": "13290" },
    { "ZIPCODE_ID": 397, "SUB_DISTRICT_CODE": "140421", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "477", "ZIPCODE": "13290" },
    { "ZIPCODE_ID": 398, "SUB_DISTRICT_CODE": "140422", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "478", "ZIPCODE": "13290" },
    { "ZIPCODE_ID": 399, "SUB_DISTRICT_CODE": "140423", "PROVINCE_ID": "5", "DISTRICT_ID": "77", "SUB_DISTRICT_ID": "479", "ZIPCODE": "13190" },
    { "ZIPCODE_ID": 400, "SUB_DISTRICT_CODE": "140501", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "480", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 401, "SUB_DISTRICT_CODE": "140502", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "481", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 402, "SUB_DISTRICT_CODE": "140503", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "482", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 403, "SUB_DISTRICT_CODE": "140504", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "483", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 404, "SUB_DISTRICT_CODE": "140505", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "484", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 405, "SUB_DISTRICT_CODE": "140506", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "485", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 406, "SUB_DISTRICT_CODE": "140507", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "486", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 407, "SUB_DISTRICT_CODE": "140508", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "487", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 408, "SUB_DISTRICT_CODE": "140509", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "488", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 409, "SUB_DISTRICT_CODE": "140510", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "489", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 410, "SUB_DISTRICT_CODE": "140511", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "490", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 411, "SUB_DISTRICT_CODE": "140512", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "491", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 412, "SUB_DISTRICT_CODE": "140513", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "492", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 413, "SUB_DISTRICT_CODE": "140514", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "493", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 414, "SUB_DISTRICT_CODE": "140515", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "494", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 415, "SUB_DISTRICT_CODE": "140516", "PROVINCE_ID": "5", "DISTRICT_ID": "78", "SUB_DISTRICT_ID": "495", "ZIPCODE": "13250" },
    { "ZIPCODE_ID": 416, "SUB_DISTRICT_CODE": "140601", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "496", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 417, "SUB_DISTRICT_CODE": "140602", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "497", "ZIPCODE": "13180" },
    { "ZIPCODE_ID": 418, "SUB_DISTRICT_CODE": "140603", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "498", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 419, "SUB_DISTRICT_CODE": "140604", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "499", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 420, "SUB_DISTRICT_CODE": "140605", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "500", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 421, "SUB_DISTRICT_CODE": "140606", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "501", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 422, "SUB_DISTRICT_CODE": "140607", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "502", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 423, "SUB_DISTRICT_CODE": "140608", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "503", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 424, "SUB_DISTRICT_CODE": "140609", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "504", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 425, "SUB_DISTRICT_CODE": "140610", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "505", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 426, "SUB_DISTRICT_CODE": "140611", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "506", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 427, "SUB_DISTRICT_CODE": "140612", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "507", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 428, "SUB_DISTRICT_CODE": "140613", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "508", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 429, "SUB_DISTRICT_CODE": "140614", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "509", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 430, "SUB_DISTRICT_CODE": "140615", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "510", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 431, "SUB_DISTRICT_CODE": "140616", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "511", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 432, "SUB_DISTRICT_CODE": "140617", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "512", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 433, "SUB_DISTRICT_CODE": "140618", "PROVINCE_ID": "5", "DISTRICT_ID": "79", "SUB_DISTRICT_ID": "513", "ZIPCODE": "13160" },
    { "ZIPCODE_ID": 434, "SUB_DISTRICT_CODE": "140701", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "514", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 435, "SUB_DISTRICT_CODE": "140702", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "515", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 436, "SUB_DISTRICT_CODE": "140703", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "516", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 437, "SUB_DISTRICT_CODE": "140704", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "517", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 438, "SUB_DISTRICT_CODE": "140705", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "518", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 439, "SUB_DISTRICT_CODE": "140706", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "519", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 440, "SUB_DISTRICT_CODE": "140707", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "520", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 441, "SUB_DISTRICT_CODE": "140708", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "521", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 442, "SUB_DISTRICT_CODE": "140709", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "522", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 443, "SUB_DISTRICT_CODE": "140710", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "523", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 444, "SUB_DISTRICT_CODE": "140711", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "524", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 445, "SUB_DISTRICT_CODE": "140712", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "525", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 446, "SUB_DISTRICT_CODE": "140713", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "526", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 447, "SUB_DISTRICT_CODE": "140714", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "527", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 448, "SUB_DISTRICT_CODE": "140715", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "528", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 449, "SUB_DISTRICT_CODE": "140716", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "529", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 450, "SUB_DISTRICT_CODE": "140717", "PROVINCE_ID": "5", "DISTRICT_ID": "80", "SUB_DISTRICT_ID": "530", "ZIPCODE": "13220" },
    { "ZIPCODE_ID": 451, "SUB_DISTRICT_CODE": "140801", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "531", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 452, "SUB_DISTRICT_CODE": "140802", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "532", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 453, "SUB_DISTRICT_CODE": "140803", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "533", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 454, "SUB_DISTRICT_CODE": "140804", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "534", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 455, "SUB_DISTRICT_CODE": "140805", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "535", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 456, "SUB_DISTRICT_CODE": "140806", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "536", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 457, "SUB_DISTRICT_CODE": "140807", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "537", "ZIPCODE": "13280" },
    { "ZIPCODE_ID": 458, "SUB_DISTRICT_CODE": "140808", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "538", "ZIPCODE": "13280" },
    { "ZIPCODE_ID": 459, "SUB_DISTRICT_CODE": "140809", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "539", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 460, "SUB_DISTRICT_CODE": "140810", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "540", "ZIPCODE": "13280" },
    { "ZIPCODE_ID": 461, "SUB_DISTRICT_CODE": "140811", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "541", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 462, "SUB_DISTRICT_CODE": "140812", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "542", "ZIPCODE": "13280" },
    { "ZIPCODE_ID": 463, "SUB_DISTRICT_CODE": "140813", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "543", "ZIPCODE": "13280" },
    { "ZIPCODE_ID": 464, "SUB_DISTRICT_CODE": "140814", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "544", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 465, "SUB_DISTRICT_CODE": "140815", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "545", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 466, "SUB_DISTRICT_CODE": "140816", "PROVINCE_ID": "5", "DISTRICT_ID": "81", "SUB_DISTRICT_ID": "546", "ZIPCODE": "13120" },
    { "ZIPCODE_ID": 467, "SUB_DISTRICT_CODE": "140901", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "547", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 468, "SUB_DISTRICT_CODE": "140902", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "548", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 469, "SUB_DISTRICT_CODE": "140903", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "549", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 470, "SUB_DISTRICT_CODE": "140904", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "550", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 471, "SUB_DISTRICT_CODE": "140905", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "551", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 472, "SUB_DISTRICT_CODE": "140906", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "552", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 473, "SUB_DISTRICT_CODE": "140907", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "553", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 474, "SUB_DISTRICT_CODE": "140908", "PROVINCE_ID": "5", "DISTRICT_ID": "82", "SUB_DISTRICT_ID": "554", "ZIPCODE": "13140" },
    { "ZIPCODE_ID": 475, "SUB_DISTRICT_CODE": "141001", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "555", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 476, "SUB_DISTRICT_CODE": "141002", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "556", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 477, "SUB_DISTRICT_CODE": "141003", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "557", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 478, "SUB_DISTRICT_CODE": "141004", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "558", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 479, "SUB_DISTRICT_CODE": "141005", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "559", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 480, "SUB_DISTRICT_CODE": "141006", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "560", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 481, "SUB_DISTRICT_CODE": "141007", "PROVINCE_ID": "5", "DISTRICT_ID": "83", "SUB_DISTRICT_ID": "561", "ZIPCODE": "13230" },
    { "ZIPCODE_ID": 482, "SUB_DISTRICT_CODE": "141101", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "562", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 483, "SUB_DISTRICT_CODE": "141102", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "563", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 484, "SUB_DISTRICT_CODE": "141103", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "564", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 485, "SUB_DISTRICT_CODE": "141104", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "565", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 486, "SUB_DISTRICT_CODE": "141105", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "566", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 487, "SUB_DISTRICT_CODE": "141106", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "567", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 488, "SUB_DISTRICT_CODE": "141107", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "568", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 489, "SUB_DISTRICT_CODE": "141108", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "569", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 490, "SUB_DISTRICT_CODE": "141109", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "570", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 491, "SUB_DISTRICT_CODE": "141110", "PROVINCE_ID": "5", "DISTRICT_ID": "84", "SUB_DISTRICT_ID": "571", "ZIPCODE": "13170" },
    { "ZIPCODE_ID": 492, "SUB_DISTRICT_CODE": "141201", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "572", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 493, "SUB_DISTRICT_CODE": "141202", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "573", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 494, "SUB_DISTRICT_CODE": "141203", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "574", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 495, "SUB_DISTRICT_CODE": "141204", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "575", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 496, "SUB_DISTRICT_CODE": "141205", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "576", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 497, "SUB_DISTRICT_CODE": "141206", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "577", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 498, "SUB_DISTRICT_CODE": "141207", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "578", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 499, "SUB_DISTRICT_CODE": "141208", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "579", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 500, "SUB_DISTRICT_CODE": "141209", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "580", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 501, "SUB_DISTRICT_CODE": "141210", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "581", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 502, "SUB_DISTRICT_CODE": "141211", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "582", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 503, "SUB_DISTRICT_CODE": "141212", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "583", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 504, "SUB_DISTRICT_CODE": "141213", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "584", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 505, "SUB_DISTRICT_CODE": "141214", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "585", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 506, "SUB_DISTRICT_CODE": "141215", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "586", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 507, "SUB_DISTRICT_CODE": "141216", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "587", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 508, "SUB_DISTRICT_CODE": "141217", "PROVINCE_ID": "5", "DISTRICT_ID": "85", "SUB_DISTRICT_ID": "588", "ZIPCODE": "13110" },
    { "ZIPCODE_ID": 509, "SUB_DISTRICT_CODE": "141301", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "589", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 510, "SUB_DISTRICT_CODE": "141302", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "590", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 511, "SUB_DISTRICT_CODE": "141303", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "591", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 512, "SUB_DISTRICT_CODE": "141304", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "592", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 513, "SUB_DISTRICT_CODE": "141305", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "593", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 514, "SUB_DISTRICT_CODE": "141306", "PROVINCE_ID": "5", "DISTRICT_ID": "86", "SUB_DISTRICT_ID": "594", "ZIPCODE": "13270" },
    { "ZIPCODE_ID": 515, "SUB_DISTRICT_CODE": "141401", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "595", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 516, "SUB_DISTRICT_CODE": "141402", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "596", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 517, "SUB_DISTRICT_CODE": "141403", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "597", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 518, "SUB_DISTRICT_CODE": "141404", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "598", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 519, "SUB_DISTRICT_CODE": "141405", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "599", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 520, "SUB_DISTRICT_CODE": "141406", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "600", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 521, "SUB_DISTRICT_CODE": "141407", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "601", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 522, "SUB_DISTRICT_CODE": "141408", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "602", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 523, "SUB_DISTRICT_CODE": "141409", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "603", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 524, "SUB_DISTRICT_CODE": "141410", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "604", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 525, "SUB_DISTRICT_CODE": "141411", "PROVINCE_ID": "5", "DISTRICT_ID": "87", "SUB_DISTRICT_ID": "605", "ZIPCODE": "13210" },
    { "ZIPCODE_ID": 526, "SUB_DISTRICT_CODE": "141501", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "606", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 527, "SUB_DISTRICT_CODE": "141502", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "607", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 528, "SUB_DISTRICT_CODE": "141503", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "608", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 529, "SUB_DISTRICT_CODE": "141504", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "609", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 530, "SUB_DISTRICT_CODE": "141505", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "610", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 531, "SUB_DISTRICT_CODE": "141506", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "611", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 532, "SUB_DISTRICT_CODE": "141507", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "612", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 533, "SUB_DISTRICT_CODE": "141508", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "613", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 534, "SUB_DISTRICT_CODE": "141509", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "614", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 535, "SUB_DISTRICT_CODE": "141510", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "615", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 536, "SUB_DISTRICT_CODE": "141511", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "616", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 537, "SUB_DISTRICT_CODE": "141512", "PROVINCE_ID": "5", "DISTRICT_ID": "88", "SUB_DISTRICT_ID": "617", "ZIPCODE": "13150" },
    { "ZIPCODE_ID": 538, "SUB_DISTRICT_CODE": "141601", "PROVINCE_ID": "5", "DISTRICT_ID": "89", "SUB_DISTRICT_ID": "618", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 539, "SUB_DISTRICT_CODE": "141602", "PROVINCE_ID": "5", "DISTRICT_ID": "89", "SUB_DISTRICT_ID": "619", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 540, "SUB_DISTRICT_CODE": "141603", "PROVINCE_ID": "5", "DISTRICT_ID": "89", "SUB_DISTRICT_ID": "620", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 541, "SUB_DISTRICT_CODE": "141604", "PROVINCE_ID": "5", "DISTRICT_ID": "89", "SUB_DISTRICT_ID": "621", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 542, "SUB_DISTRICT_CODE": "141605", "PROVINCE_ID": "5", "DISTRICT_ID": "89", "SUB_DISTRICT_ID": "622", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 543, "SUB_DISTRICT_CODE": "150101", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "623", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 544, "SUB_DISTRICT_CODE": "150102", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "624", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 545, "SUB_DISTRICT_CODE": "150103", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "625", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 546, "SUB_DISTRICT_CODE": "150104", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "626", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 547, "SUB_DISTRICT_CODE": "150105", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "627", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 548, "SUB_DISTRICT_CODE": "150106", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "628", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 549, "SUB_DISTRICT_CODE": "150107", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "629", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 550, "SUB_DISTRICT_CODE": "150108", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "630", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 551, "SUB_DISTRICT_CODE": "150109", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "631", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 552, "SUB_DISTRICT_CODE": "150110", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "632", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 553, "SUB_DISTRICT_CODE": "150111", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "633", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 554, "SUB_DISTRICT_CODE": "150112", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "634", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 555, "SUB_DISTRICT_CODE": "150113", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "635", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 556, "SUB_DISTRICT_CODE": "150114", "PROVINCE_ID": "6", "DISTRICT_ID": "90", "SUB_DISTRICT_ID": "636", "ZIPCODE": "14000" },
    { "ZIPCODE_ID": 557, "SUB_DISTRICT_CODE": "150201", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "637", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 558, "SUB_DISTRICT_CODE": "150202", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "638", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 559, "SUB_DISTRICT_CODE": "150203", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "639", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 560, "SUB_DISTRICT_CODE": "150204", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "640", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 561, "SUB_DISTRICT_CODE": "150205", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "641", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 562, "SUB_DISTRICT_CODE": "150206", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "642", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 563, "SUB_DISTRICT_CODE": "150207", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "643", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 564, "SUB_DISTRICT_CODE": "150208", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "644", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 565, "SUB_DISTRICT_CODE": "150209", "PROVINCE_ID": "6", "DISTRICT_ID": "91", "SUB_DISTRICT_ID": "645", "ZIPCODE": "14140" },
    { "ZIPCODE_ID": 566, "SUB_DISTRICT_CODE": "150301", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "646", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 567, "SUB_DISTRICT_CODE": "150302", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "647", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 568, "SUB_DISTRICT_CODE": "150303", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "648", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 569, "SUB_DISTRICT_CODE": "150304", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "649", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 570, "SUB_DISTRICT_CODE": "150305", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "650", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 571, "SUB_DISTRICT_CODE": "150306", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "651", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 572, "SUB_DISTRICT_CODE": "150307", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "652", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 573, "SUB_DISTRICT_CODE": "150308", "PROVINCE_ID": "6", "DISTRICT_ID": "92", "SUB_DISTRICT_ID": "653", "ZIPCODE": "14130" },
    { "ZIPCODE_ID": 574, "SUB_DISTRICT_CODE": "150401", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "654", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 575, "SUB_DISTRICT_CODE": "150402", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "655", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 576, "SUB_DISTRICT_CODE": "150403", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "656", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 577, "SUB_DISTRICT_CODE": "150404", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "657", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 578, "SUB_DISTRICT_CODE": "150405", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "658", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 579, "SUB_DISTRICT_CODE": "150406", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "659", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 580, "SUB_DISTRICT_CODE": "150407", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "660", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 581, "SUB_DISTRICT_CODE": "150408", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "661", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 582, "SUB_DISTRICT_CODE": "150409", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "662", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 583, "SUB_DISTRICT_CODE": "150410", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "663", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 584, "SUB_DISTRICT_CODE": "150411", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "664", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 585, "SUB_DISTRICT_CODE": "150412", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "665", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 586, "SUB_DISTRICT_CODE": "150413", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "666", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 587, "SUB_DISTRICT_CODE": "150414", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "667", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 588, "SUB_DISTRICT_CODE": "150415", "PROVINCE_ID": "6", "DISTRICT_ID": "93", "SUB_DISTRICT_ID": "668", "ZIPCODE": "14120" },
    { "ZIPCODE_ID": 589, "SUB_DISTRICT_CODE": "150501", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "669", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 590, "SUB_DISTRICT_CODE": "150502", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "670", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 591, "SUB_DISTRICT_CODE": "150503", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "671", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 592, "SUB_DISTRICT_CODE": "150504", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "672", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 593, "SUB_DISTRICT_CODE": "150505", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "673", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 594, "SUB_DISTRICT_CODE": "150506", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "674", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 595, "SUB_DISTRICT_CODE": "150507", "PROVINCE_ID": "6", "DISTRICT_ID": "94", "SUB_DISTRICT_ID": "675", "ZIPCODE": "14150" },
    { "ZIPCODE_ID": 596, "SUB_DISTRICT_CODE": "150601", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "676", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 597, "SUB_DISTRICT_CODE": "150602", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "677", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 598, "SUB_DISTRICT_CODE": "150603", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "678", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 599, "SUB_DISTRICT_CODE": "150604", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "679", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 600, "SUB_DISTRICT_CODE": "150605", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "680", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 601, "SUB_DISTRICT_CODE": "150606", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "681", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 602, "SUB_DISTRICT_CODE": "150607", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "682", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 603, "SUB_DISTRICT_CODE": "150608", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "683", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 604, "SUB_DISTRICT_CODE": "150609", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "684", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 605, "SUB_DISTRICT_CODE": "150610", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "685", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 606, "SUB_DISTRICT_CODE": "150611", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "686", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 607, "SUB_DISTRICT_CODE": "150612", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "687", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 608, "SUB_DISTRICT_CODE": "150613", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "688", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 609, "SUB_DISTRICT_CODE": "150614", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "689", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 610, "SUB_DISTRICT_CODE": "150615", "PROVINCE_ID": "6", "DISTRICT_ID": "95", "SUB_DISTRICT_ID": "690", "ZIPCODE": "14110" },
    { "ZIPCODE_ID": 611, "SUB_DISTRICT_CODE": "150701", "PROVINCE_ID": "6", "DISTRICT_ID": "96", "SUB_DISTRICT_ID": "691", "ZIPCODE": "14160" },
    { "ZIPCODE_ID": 612, "SUB_DISTRICT_CODE": "150702", "PROVINCE_ID": "6", "DISTRICT_ID": "96", "SUB_DISTRICT_ID": "692", "ZIPCODE": "14160" },
    { "ZIPCODE_ID": 613, "SUB_DISTRICT_CODE": "150703", "PROVINCE_ID": "6", "DISTRICT_ID": "96", "SUB_DISTRICT_ID": "693", "ZIPCODE": "14160" },
    { "ZIPCODE_ID": 614, "SUB_DISTRICT_CODE": "150704", "PROVINCE_ID": "6", "DISTRICT_ID": "96", "SUB_DISTRICT_ID": "694", "ZIPCODE": "14160" },
    { "ZIPCODE_ID": 615, "SUB_DISTRICT_CODE": "150705", "PROVINCE_ID": "6", "DISTRICT_ID": "96", "SUB_DISTRICT_ID": "695", "ZIPCODE": "14160" },
    { "ZIPCODE_ID": 616, "SUB_DISTRICT_CODE": "160101", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "696", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 617, "SUB_DISTRICT_CODE": "160102", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "697", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 618, "SUB_DISTRICT_CODE": "160103", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "698", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 619, "SUB_DISTRICT_CODE": "160104", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "699", "ZIPCODE": "13240" },
    { "ZIPCODE_ID": 620, "SUB_DISTRICT_CODE": "160105", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "700", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 621, "SUB_DISTRICT_CODE": "160106", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "701", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 622, "SUB_DISTRICT_CODE": "160107", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "702", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 623, "SUB_DISTRICT_CODE": "160108", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "703", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 624, "SUB_DISTRICT_CODE": "160109", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "704", "ZIPCODE": "15210" },
    { "ZIPCODE_ID": 625, "SUB_DISTRICT_CODE": "160110", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "705", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 626, "SUB_DISTRICT_CODE": "160111", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "706", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 627, "SUB_DISTRICT_CODE": "160112", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "707", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 628, "SUB_DISTRICT_CODE": "160114", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "709", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 629, "SUB_DISTRICT_CODE": "160115", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "710", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 630, "SUB_DISTRICT_CODE": "160116", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "711", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 631, "SUB_DISTRICT_CODE": "160117", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "712", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 632, "SUB_DISTRICT_CODE": "160118", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "713", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 633, "SUB_DISTRICT_CODE": "160119", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "714", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 634, "SUB_DISTRICT_CODE": "160120", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "715", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 635, "SUB_DISTRICT_CODE": "160121", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "716", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 636, "SUB_DISTRICT_CODE": "160122", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "717", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 637, "SUB_DISTRICT_CODE": "160123", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "718", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 638, "SUB_DISTRICT_CODE": "160124", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "719", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 639, "SUB_DISTRICT_CODE": "160125", "PROVINCE_ID": "7", "DISTRICT_ID": "97", "SUB_DISTRICT_ID": "720", "ZIPCODE": "15000" },
    { "ZIPCODE_ID": 640, "SUB_DISTRICT_CODE": "160201", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "721", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 641, "SUB_DISTRICT_CODE": "160202", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "722", "ZIPCODE": "15220" },
    { "ZIPCODE_ID": 642, "SUB_DISTRICT_CODE": "160203", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "723", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 643, "SUB_DISTRICT_CODE": "160204", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "724", "ZIPCODE": "15220" },
    { "ZIPCODE_ID": 644, "SUB_DISTRICT_CODE": "160205", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "725", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 645, "SUB_DISTRICT_CODE": "160206", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "726", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 646, "SUB_DISTRICT_CODE": "160207", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "727", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 647, "SUB_DISTRICT_CODE": "160208", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "728", "ZIPCODE": "18220" },
    { "ZIPCODE_ID": 648, "SUB_DISTRICT_CODE": "160209", "PROVINCE_ID": "7", "DISTRICT_ID": "98", "SUB_DISTRICT_ID": "729", "ZIPCODE": "15140" },
    { "ZIPCODE_ID": 649, "SUB_DISTRICT_CODE": "160301", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "730", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 650, "SUB_DISTRICT_CODE": "160302", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "731", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 651, "SUB_DISTRICT_CODE": "160303", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "732", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 652, "SUB_DISTRICT_CODE": "160304", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "733", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 653, "SUB_DISTRICT_CODE": "160305", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "734", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 654, "SUB_DISTRICT_CODE": "160306", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "735", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 655, "SUB_DISTRICT_CODE": "160307", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "736", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 656, "SUB_DISTRICT_CODE": "160308", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "737", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 657, "SUB_DISTRICT_CODE": "160309", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "738", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 658, "SUB_DISTRICT_CODE": "160310", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "739", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 659, "SUB_DISTRICT_CODE": "160318", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "747", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 660, "SUB_DISTRICT_CODE": "160320", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "749", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 661, "SUB_DISTRICT_CODE": "160322", "PROVINCE_ID": "7", "DISTRICT_ID": "99", "SUB_DISTRICT_ID": "751", "ZIPCODE": "15120" },
    { "ZIPCODE_ID": 662, "SUB_DISTRICT_CODE": "160401", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "764", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 663, "SUB_DISTRICT_CODE": "160402", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "765", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 664, "SUB_DISTRICT_CODE": "160403", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "766", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 665, "SUB_DISTRICT_CODE": "160404", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "767", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 666, "SUB_DISTRICT_CODE": "160405", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "768", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 667, "SUB_DISTRICT_CODE": "160406", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "769", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 668, "SUB_DISTRICT_CODE": "160407", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "770", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 669, "SUB_DISTRICT_CODE": "160408", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "771", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 670, "SUB_DISTRICT_CODE": "160409", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "772", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 671, "SUB_DISTRICT_CODE": "160410", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "773", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 672, "SUB_DISTRICT_CODE": "160411", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "774", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 673, "SUB_DISTRICT_CODE": "160412", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "775", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 674, "SUB_DISTRICT_CODE": "160414", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "777", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 675, "SUB_DISTRICT_CODE": "160417", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "780", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 676, "SUB_DISTRICT_CODE": "160418", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "781", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 677, "SUB_DISTRICT_CODE": "160419", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "782", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 678, "SUB_DISTRICT_CODE": "160422", "PROVINCE_ID": "7", "DISTRICT_ID": "100", "SUB_DISTRICT_ID": "785", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 679, "SUB_DISTRICT_CODE": "160501", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "794", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 680, "SUB_DISTRICT_CODE": "160502", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "795", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 681, "SUB_DISTRICT_CODE": "160503", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "796", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 682, "SUB_DISTRICT_CODE": "160504", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "797", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 683, "SUB_DISTRICT_CODE": "160505", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "798", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 684, "SUB_DISTRICT_CODE": "160506", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "799", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 685, "SUB_DISTRICT_CODE": "160507", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "800", "ZIPCODE": "15180" },
    { "ZIPCODE_ID": 686, "SUB_DISTRICT_CODE": "160508", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "801", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 687, "SUB_DISTRICT_CODE": "160509", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "802", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 688, "SUB_DISTRICT_CODE": "160510", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "803", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 689, "SUB_DISTRICT_CODE": "160511", "PROVINCE_ID": "7", "DISTRICT_ID": "101", "SUB_DISTRICT_ID": "804", "ZIPCODE": "15150" },
    { "ZIPCODE_ID": 690, "SUB_DISTRICT_CODE": "160601", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "805", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 691, "SUB_DISTRICT_CODE": "160602", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "806", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 692, "SUB_DISTRICT_CODE": "160603", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "807", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 693, "SUB_DISTRICT_CODE": "160604", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "808", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 694, "SUB_DISTRICT_CODE": "160605", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "809", "ZIPCODE": "15180" },
    { "ZIPCODE_ID": 695, "SUB_DISTRICT_CODE": "160606", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "810", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 696, "SUB_DISTRICT_CODE": "160607", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "811", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 697, "SUB_DISTRICT_CODE": "160608", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "812", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 698, "SUB_DISTRICT_CODE": "160609", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "813", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 699, "SUB_DISTRICT_CODE": "160610", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "814", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 700, "SUB_DISTRICT_CODE": "160611", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "815", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 701, "SUB_DISTRICT_CODE": "160612", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "816", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 702, "SUB_DISTRICT_CODE": "160613", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "817", "ZIPCODE": "15180" },
    { "ZIPCODE_ID": 703, "SUB_DISTRICT_CODE": "160614", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "818", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 704, "SUB_DISTRICT_CODE": "160615", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "819", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 705, "SUB_DISTRICT_CODE": "160616", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "820", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 706, "SUB_DISTRICT_CODE": "160617", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "821", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 707, "SUB_DISTRICT_CODE": "160618", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "822", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 708, "SUB_DISTRICT_CODE": "160619", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "823", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 709, "SUB_DISTRICT_CODE": "160620", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "824", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 710, "SUB_DISTRICT_CODE": "160621", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "825", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 711, "SUB_DISTRICT_CODE": "160622", "PROVINCE_ID": "7", "DISTRICT_ID": "102", "SUB_DISTRICT_ID": "826", "ZIPCODE": "15110" },
    { "ZIPCODE_ID": 712, "SUB_DISTRICT_CODE": "160701", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "827", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 713, "SUB_DISTRICT_CODE": "160702", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "828", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 714, "SUB_DISTRICT_CODE": "160703", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "829", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 715, "SUB_DISTRICT_CODE": "160704", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "830", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 716, "SUB_DISTRICT_CODE": "160705", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "831", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 717, "SUB_DISTRICT_CODE": "160706", "PROVINCE_ID": "7", "DISTRICT_ID": "103", "SUB_DISTRICT_ID": "832", "ZIPCODE": "15230" },
    { "ZIPCODE_ID": 718, "SUB_DISTRICT_CODE": "160801", "PROVINCE_ID": "7", "DISTRICT_ID": "104", "SUB_DISTRICT_ID": "833", "ZIPCODE": "15240" },
    { "ZIPCODE_ID": 719, "SUB_DISTRICT_CODE": "160802", "PROVINCE_ID": "7", "DISTRICT_ID": "104", "SUB_DISTRICT_ID": "834", "ZIPCODE": "15240" },
    { "ZIPCODE_ID": 720, "SUB_DISTRICT_CODE": "160803", "PROVINCE_ID": "7", "DISTRICT_ID": "104", "SUB_DISTRICT_ID": "835", "ZIPCODE": "15240" },
    { "ZIPCODE_ID": 721, "SUB_DISTRICT_CODE": "160804", "PROVINCE_ID": "7", "DISTRICT_ID": "104", "SUB_DISTRICT_ID": "836", "ZIPCODE": "15240" },
    { "ZIPCODE_ID": 722, "SUB_DISTRICT_CODE": "160805", "PROVINCE_ID": "7", "DISTRICT_ID": "104", "SUB_DISTRICT_ID": "837", "ZIPCODE": "15240" },
    { "ZIPCODE_ID": 723, "SUB_DISTRICT_CODE": "160901", "PROVINCE_ID": "7", "DISTRICT_ID": "105", "SUB_DISTRICT_ID": "838", "ZIPCODE": "15250" },
    { "ZIPCODE_ID": 724, "SUB_DISTRICT_CODE": "160902", "PROVINCE_ID": "7", "DISTRICT_ID": "105", "SUB_DISTRICT_ID": "839", "ZIPCODE": "15250" },
    { "ZIPCODE_ID": 725, "SUB_DISTRICT_CODE": "160903", "PROVINCE_ID": "7", "DISTRICT_ID": "105", "SUB_DISTRICT_ID": "840", "ZIPCODE": "15250" },
    { "ZIPCODE_ID": 726, "SUB_DISTRICT_CODE": "160904", "PROVINCE_ID": "7", "DISTRICT_ID": "105", "SUB_DISTRICT_ID": "841", "ZIPCODE": "15250" },
    { "ZIPCODE_ID": 727, "SUB_DISTRICT_CODE": "160905", "PROVINCE_ID": "7", "DISTRICT_ID": "105", "SUB_DISTRICT_ID": "842", "ZIPCODE": "15250" },
    { "ZIPCODE_ID": 728, "SUB_DISTRICT_CODE": "161001", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "843", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 729, "SUB_DISTRICT_CODE": "161002", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "844", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 730, "SUB_DISTRICT_CODE": "161003", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "845", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 731, "SUB_DISTRICT_CODE": "161004", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "846", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 732, "SUB_DISTRICT_CODE": "161005", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "847", "ZIPCODE": "15190" },
    { "ZIPCODE_ID": 733, "SUB_DISTRICT_CODE": "161006", "PROVINCE_ID": "7", "DISTRICT_ID": "106", "SUB_DISTRICT_ID": "848", "ZIPCODE": "15130" },
    { "ZIPCODE_ID": 734, "SUB_DISTRICT_CODE": "161101", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "849", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 735, "SUB_DISTRICT_CODE": "161102", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "850", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 736, "SUB_DISTRICT_CODE": "161103", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "851", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 737, "SUB_DISTRICT_CODE": "161104", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "852", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 738, "SUB_DISTRICT_CODE": "161105", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "853", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 739, "SUB_DISTRICT_CODE": "161106", "PROVINCE_ID": "7", "DISTRICT_ID": "107", "SUB_DISTRICT_ID": "854", "ZIPCODE": "15170" },
    { "ZIPCODE_ID": 740, "SUB_DISTRICT_CODE": "170101", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "855", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 741, "SUB_DISTRICT_CODE": "170102", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "856", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 742, "SUB_DISTRICT_CODE": "170103", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "857", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 743, "SUB_DISTRICT_CODE": "170104", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "858", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 744, "SUB_DISTRICT_CODE": "170105", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "859", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 745, "SUB_DISTRICT_CODE": "170106", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "860", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 746, "SUB_DISTRICT_CODE": "170107", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "861", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 747, "SUB_DISTRICT_CODE": "170108", "PROVINCE_ID": "8", "DISTRICT_ID": "109", "SUB_DISTRICT_ID": "862", "ZIPCODE": "16000" },
    { "ZIPCODE_ID": 748, "SUB_DISTRICT_CODE": "170201", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "863", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 749, "SUB_DISTRICT_CODE": "170202", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "864", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 750, "SUB_DISTRICT_CODE": "170203", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "865", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 751, "SUB_DISTRICT_CODE": "170204", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "866", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 752, "SUB_DISTRICT_CODE": "170205", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "867", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 753, "SUB_DISTRICT_CODE": "170206", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "868", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 754, "SUB_DISTRICT_CODE": "170207", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "869", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 755, "SUB_DISTRICT_CODE": "170208", "PROVINCE_ID": "8", "DISTRICT_ID": "110", "SUB_DISTRICT_ID": "870", "ZIPCODE": "16130" },
    { "ZIPCODE_ID": 756, "SUB_DISTRICT_CODE": "170301", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "871", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 757, "SUB_DISTRICT_CODE": "170302", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "872", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 758, "SUB_DISTRICT_CODE": "170303", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "873", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 759, "SUB_DISTRICT_CODE": "170304", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "874", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 760, "SUB_DISTRICT_CODE": "170305", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "875", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 761, "SUB_DISTRICT_CODE": "170306", "PROVINCE_ID": "8", "DISTRICT_ID": "111", "SUB_DISTRICT_ID": "876", "ZIPCODE": "16150" },
    { "ZIPCODE_ID": 762, "SUB_DISTRICT_CODE": "170401", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "877", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 763, "SUB_DISTRICT_CODE": "170402", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "878", "ZIPCODE": "16160" },
    { "ZIPCODE_ID": 764, "SUB_DISTRICT_CODE": "170403", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "879", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 765, "SUB_DISTRICT_CODE": "170404", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "880", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 766, "SUB_DISTRICT_CODE": "170405", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "881", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 767, "SUB_DISTRICT_CODE": "170406", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "882", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 768, "SUB_DISTRICT_CODE": "170407", "PROVINCE_ID": "8", "DISTRICT_ID": "112", "SUB_DISTRICT_ID": "883", "ZIPCODE": "16120" },
    { "ZIPCODE_ID": 769, "SUB_DISTRICT_CODE": "170501", "PROVINCE_ID": "8", "DISTRICT_ID": "113", "SUB_DISTRICT_ID": "884", "ZIPCODE": "16140" },
    { "ZIPCODE_ID": 770, "SUB_DISTRICT_CODE": "170502", "PROVINCE_ID": "8", "DISTRICT_ID": "113", "SUB_DISTRICT_ID": "885", "ZIPCODE": "16140" },
    { "ZIPCODE_ID": 771, "SUB_DISTRICT_CODE": "170503", "PROVINCE_ID": "8", "DISTRICT_ID": "113", "SUB_DISTRICT_ID": "886", "ZIPCODE": "16140" },
    { "ZIPCODE_ID": 772, "SUB_DISTRICT_CODE": "170504", "PROVINCE_ID": "8", "DISTRICT_ID": "113", "SUB_DISTRICT_ID": "887", "ZIPCODE": "16140" },
    { "ZIPCODE_ID": 773, "SUB_DISTRICT_CODE": "170601", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "888", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 774, "SUB_DISTRICT_CODE": "170602", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "889", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 775, "SUB_DISTRICT_CODE": "170603", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "890", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 776, "SUB_DISTRICT_CODE": "170604", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "891", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 777, "SUB_DISTRICT_CODE": "170605", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "892", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 778, "SUB_DISTRICT_CODE": "170606", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "893", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 779, "SUB_DISTRICT_CODE": "170607", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "894", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 780, "SUB_DISTRICT_CODE": "170608", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "895", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 781, "SUB_DISTRICT_CODE": "170609", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "896", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 782, "SUB_DISTRICT_CODE": "170610", "PROVINCE_ID": "8", "DISTRICT_ID": "114", "SUB_DISTRICT_ID": "897", "ZIPCODE": "16110" },
    { "ZIPCODE_ID": 783, "SUB_DISTRICT_CODE": "180101", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "898", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 784, "SUB_DISTRICT_CODE": "180102", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "899", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 785, "SUB_DISTRICT_CODE": "180103", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "900", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 786, "SUB_DISTRICT_CODE": "180104", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "901", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 787, "SUB_DISTRICT_CODE": "180105", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "902", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 788, "SUB_DISTRICT_CODE": "180106", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "903", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 789, "SUB_DISTRICT_CODE": "180107", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "904", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 790, "SUB_DISTRICT_CODE": "180108", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "905", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 791, "SUB_DISTRICT_CODE": "180109", "PROVINCE_ID": "9", "DISTRICT_ID": "115", "SUB_DISTRICT_ID": "906", "ZIPCODE": "17000" },
    { "ZIPCODE_ID": 792, "SUB_DISTRICT_CODE": "180201", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "907", "ZIPCODE": "17110" },
    { "ZIPCODE_ID": 793, "SUB_DISTRICT_CODE": "180202", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "908", "ZIPCODE": "17110" },
    { "ZIPCODE_ID": 794, "SUB_DISTRICT_CODE": "180203", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "909", "ZIPCODE": "17110" },
    { "ZIPCODE_ID": 795, "SUB_DISTRICT_CODE": "180204", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "910", "ZIPCODE": "17110" },
    { "ZIPCODE_ID": 796, "SUB_DISTRICT_CODE": "180205", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "911", "ZIPCODE": "17170" },
    { "ZIPCODE_ID": 797, "SUB_DISTRICT_CODE": "180206", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "912", "ZIPCODE": "17170" },
    { "ZIPCODE_ID": 798, "SUB_DISTRICT_CODE": "180207", "PROVINCE_ID": "9", "DISTRICT_ID": "116", "SUB_DISTRICT_ID": "913", "ZIPCODE": "17170" },
    { "ZIPCODE_ID": 799, "SUB_DISTRICT_CODE": "180301", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "914", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 800, "SUB_DISTRICT_CODE": "180302", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "915", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 801, "SUB_DISTRICT_CODE": "180303", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "916", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 802, "SUB_DISTRICT_CODE": "180304", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "917", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 803, "SUB_DISTRICT_CODE": "180306", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "919", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 804, "SUB_DISTRICT_CODE": "180307", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "920", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 805, "SUB_DISTRICT_CODE": "180311", "PROVINCE_ID": "9", "DISTRICT_ID": "117", "SUB_DISTRICT_ID": "924", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 806, "SUB_DISTRICT_CODE": "180401", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "925", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 807, "SUB_DISTRICT_CODE": "180402", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "926", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 808, "SUB_DISTRICT_CODE": "180403", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "927", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 809, "SUB_DISTRICT_CODE": "180404", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "928", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 810, "SUB_DISTRICT_CODE": "180405", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "929", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 811, "SUB_DISTRICT_CODE": "180406", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "930", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 812, "SUB_DISTRICT_CODE": "180407", "PROVINCE_ID": "9", "DISTRICT_ID": "118", "SUB_DISTRICT_ID": "931", "ZIPCODE": "17150" },
    { "ZIPCODE_ID": 813, "SUB_DISTRICT_CODE": "180501", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "932", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 814, "SUB_DISTRICT_CODE": "180502", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "933", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 815, "SUB_DISTRICT_CODE": "180503", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "934", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 816, "SUB_DISTRICT_CODE": "180504", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "935", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 817, "SUB_DISTRICT_CODE": "180505", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "936", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 818, "SUB_DISTRICT_CODE": "180506", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "937", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 819, "SUB_DISTRICT_CODE": "180507", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "938", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 820, "SUB_DISTRICT_CODE": "180508", "PROVINCE_ID": "9", "DISTRICT_ID": "119", "SUB_DISTRICT_ID": "939", "ZIPCODE": "17140" },
    { "ZIPCODE_ID": 821, "SUB_DISTRICT_CODE": "180601", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "940", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 822, "SUB_DISTRICT_CODE": "180602", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "941", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 823, "SUB_DISTRICT_CODE": "180605", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "944", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 824, "SUB_DISTRICT_CODE": "180606", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "945", "ZIPCODE": "17160" },
    { "ZIPCODE_ID": 825, "SUB_DISTRICT_CODE": "180607", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "946", "ZIPCODE": "17160" },
    { "ZIPCODE_ID": 826, "SUB_DISTRICT_CODE": "180608", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "947", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 827, "SUB_DISTRICT_CODE": "180609", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "948", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 828, "SUB_DISTRICT_CODE": "180611", "PROVINCE_ID": "9", "DISTRICT_ID": "120", "SUB_DISTRICT_ID": "950", "ZIPCODE": "17160" },
    { "ZIPCODE_ID": 829, "SUB_DISTRICT_CODE": "180701", "PROVINCE_ID": "9", "DISTRICT_ID": "121", "SUB_DISTRICT_ID": "951", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 830, "SUB_DISTRICT_CODE": "180702", "PROVINCE_ID": "9", "DISTRICT_ID": "121", "SUB_DISTRICT_ID": "952", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 831, "SUB_DISTRICT_CODE": "180703", "PROVINCE_ID": "9", "DISTRICT_ID": "121", "SUB_DISTRICT_ID": "953", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 832, "SUB_DISTRICT_CODE": "180704", "PROVINCE_ID": "9", "DISTRICT_ID": "121", "SUB_DISTRICT_ID": "954", "ZIPCODE": "17120" },
    { "ZIPCODE_ID": 833, "SUB_DISTRICT_CODE": "180801", "PROVINCE_ID": "9", "DISTRICT_ID": "122", "SUB_DISTRICT_ID": "955", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 834, "SUB_DISTRICT_CODE": "180802", "PROVINCE_ID": "9", "DISTRICT_ID": "122", "SUB_DISTRICT_ID": "956", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 835, "SUB_DISTRICT_CODE": "180803", "PROVINCE_ID": "9", "DISTRICT_ID": "122", "SUB_DISTRICT_ID": "957", "ZIPCODE": "17130" },
    { "ZIPCODE_ID": 836, "SUB_DISTRICT_CODE": "190101", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "958", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 837, "SUB_DISTRICT_CODE": "190105", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "962", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 838, "SUB_DISTRICT_CODE": "190106", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "963", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 839, "SUB_DISTRICT_CODE": "190107", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "964", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 840, "SUB_DISTRICT_CODE": "190108", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "965", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 841, "SUB_DISTRICT_CODE": "190109", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "966", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 842, "SUB_DISTRICT_CODE": "190110", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "967", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 843, "SUB_DISTRICT_CODE": "190111", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "968", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 844, "SUB_DISTRICT_CODE": "190112", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "969", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 845, "SUB_DISTRICT_CODE": "190113", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "970", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 846, "SUB_DISTRICT_CODE": "190114", "PROVINCE_ID": "10", "DISTRICT_ID": "123", "SUB_DISTRICT_ID": "971", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 847, "SUB_DISTRICT_CODE": "190201", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "975", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 848, "SUB_DISTRICT_CODE": "190202", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "976", "ZIPCODE": "18260" },
    { "ZIPCODE_ID": 849, "SUB_DISTRICT_CODE": "190203", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "977", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 850, "SUB_DISTRICT_CODE": "190204", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "978", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 851, "SUB_DISTRICT_CODE": "190205", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "979", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 852, "SUB_DISTRICT_CODE": "190206", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "980", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 853, "SUB_DISTRICT_CODE": "190207", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "981", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 854, "SUB_DISTRICT_CODE": "190208", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "982", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 855, "SUB_DISTRICT_CODE": "190209", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "983", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 856, "SUB_DISTRICT_CODE": "190210", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "984", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 857, "SUB_DISTRICT_CODE": "190211", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "985", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 858, "SUB_DISTRICT_CODE": "190212", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "986", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 859, "SUB_DISTRICT_CODE": "190213", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "987", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 860, "SUB_DISTRICT_CODE": "190215", "PROVINCE_ID": "10", "DISTRICT_ID": "124", "SUB_DISTRICT_ID": "988", "ZIPCODE": "18110" },
    { "ZIPCODE_ID": 861, "SUB_DISTRICT_CODE": "190301", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "989", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 862, "SUB_DISTRICT_CODE": "190302", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "990", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 863, "SUB_DISTRICT_CODE": "190303", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "991", "ZIPCODE": "18250" },
    { "ZIPCODE_ID": 864, "SUB_DISTRICT_CODE": "190304", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "992", "ZIPCODE": "18250" },
    { "ZIPCODE_ID": 865, "SUB_DISTRICT_CODE": "190305", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "993", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 866, "SUB_DISTRICT_CODE": "190306", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "994", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 867, "SUB_DISTRICT_CODE": "190307", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "995", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 868, "SUB_DISTRICT_CODE": "190308", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "996", "ZIPCODE": "18250" },
    { "ZIPCODE_ID": 869, "SUB_DISTRICT_CODE": "190309", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "997", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 870, "SUB_DISTRICT_CODE": "190310", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "998", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 871, "SUB_DISTRICT_CODE": "190311", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "999", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 872, "SUB_DISTRICT_CODE": "190312", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1000", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 873, "SUB_DISTRICT_CODE": "190313", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1001", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 874, "SUB_DISTRICT_CODE": "190314", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1002", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 875, "SUB_DISTRICT_CODE": "190315", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1003", "ZIPCODE": "18230" },
    { "ZIPCODE_ID": 876, "SUB_DISTRICT_CODE": "190316", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1004", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 877, "SUB_DISTRICT_CODE": "190317", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1005", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 878, "SUB_DISTRICT_CODE": "190318", "PROVINCE_ID": "10", "DISTRICT_ID": "125", "SUB_DISTRICT_ID": "1006", "ZIPCODE": "18140" },
    { "ZIPCODE_ID": 879, "SUB_DISTRICT_CODE": "190401", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1007", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 880, "SUB_DISTRICT_CODE": "190402", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1008", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 881, "SUB_DISTRICT_CODE": "190403", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1009", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 882, "SUB_DISTRICT_CODE": "190404", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1010", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 883, "SUB_DISTRICT_CODE": "190405", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1011", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 884, "SUB_DISTRICT_CODE": "190406", "PROVINCE_ID": "10", "DISTRICT_ID": "126", "SUB_DISTRICT_ID": "1012", "ZIPCODE": "18150" },
    { "ZIPCODE_ID": 885, "SUB_DISTRICT_CODE": "190501", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1013", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 886, "SUB_DISTRICT_CODE": "190502", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1014", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 887, "SUB_DISTRICT_CODE": "190503", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1015", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 888, "SUB_DISTRICT_CODE": "190504", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1016", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 889, "SUB_DISTRICT_CODE": "190505", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1017", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 890, "SUB_DISTRICT_CODE": "190506", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1018", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 891, "SUB_DISTRICT_CODE": "190507", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1019", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 892, "SUB_DISTRICT_CODE": "190508", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1020", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 893, "SUB_DISTRICT_CODE": "190509", "PROVINCE_ID": "10", "DISTRICT_ID": "127", "SUB_DISTRICT_ID": "1021", "ZIPCODE": "18170" },
    { "ZIPCODE_ID": 894, "SUB_DISTRICT_CODE": "190601", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1022", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 895, "SUB_DISTRICT_CODE": "190602", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1023", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 896, "SUB_DISTRICT_CODE": "190603", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1024", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 897, "SUB_DISTRICT_CODE": "190604", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1025", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 898, "SUB_DISTRICT_CODE": "190605", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1026", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 899, "SUB_DISTRICT_CODE": "190606", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1027", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 900, "SUB_DISTRICT_CODE": "190607", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1028", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 901, "SUB_DISTRICT_CODE": "190608", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1029", "ZIPCODE": "18270" },
    { "ZIPCODE_ID": 902, "SUB_DISTRICT_CODE": "190609", "PROVINCE_ID": "10", "DISTRICT_ID": "128", "SUB_DISTRICT_ID": "1030", "ZIPCODE": "18130" },
    { "ZIPCODE_ID": 903, "SUB_DISTRICT_CODE": "190701", "PROVINCE_ID": "10", "DISTRICT_ID": "129", "SUB_DISTRICT_ID": "1035", "ZIPCODE": "18210" },
    { "ZIPCODE_ID": 904, "SUB_DISTRICT_CODE": "190702", "PROVINCE_ID": "10", "DISTRICT_ID": "129", "SUB_DISTRICT_ID": "1036", "ZIPCODE": "18210" },
    { "ZIPCODE_ID": 905, "SUB_DISTRICT_CODE": "190703", "PROVINCE_ID": "10", "DISTRICT_ID": "129", "SUB_DISTRICT_ID": "1037", "ZIPCODE": "18210" },
    { "ZIPCODE_ID": 906, "SUB_DISTRICT_CODE": "190704", "PROVINCE_ID": "10", "DISTRICT_ID": "129", "SUB_DISTRICT_ID": "1038", "ZIPCODE": "18210" },
    { "ZIPCODE_ID": 907, "SUB_DISTRICT_CODE": "190801", "PROVINCE_ID": "10", "DISTRICT_ID": "130", "SUB_DISTRICT_ID": "1039", "ZIPCODE": "18190" },
    { "ZIPCODE_ID": 908, "SUB_DISTRICT_CODE": "190802", "PROVINCE_ID": "10", "DISTRICT_ID": "130", "SUB_DISTRICT_ID": "1040", "ZIPCODE": "18190" },
    { "ZIPCODE_ID": 909, "SUB_DISTRICT_CODE": "190803", "PROVINCE_ID": "10", "DISTRICT_ID": "130", "SUB_DISTRICT_ID": "1041", "ZIPCODE": "18190" },
    { "ZIPCODE_ID": 910, "SUB_DISTRICT_CODE": "190804", "PROVINCE_ID": "10", "DISTRICT_ID": "130", "SUB_DISTRICT_ID": "1042", "ZIPCODE": "18190" },
    { "ZIPCODE_ID": 911, "SUB_DISTRICT_CODE": "190901", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1043", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 912, "SUB_DISTRICT_CODE": "190902", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1044", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 913, "SUB_DISTRICT_CODE": "190903", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1045", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 914, "SUB_DISTRICT_CODE": "190904", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1046", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 915, "SUB_DISTRICT_CODE": "190905", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1047", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 916, "SUB_DISTRICT_CODE": "190906", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1048", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 917, "SUB_DISTRICT_CODE": "190907", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1049", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 918, "SUB_DISTRICT_CODE": "190908", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1050", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 919, "SUB_DISTRICT_CODE": "190909", "PROVINCE_ID": "10", "DISTRICT_ID": "131", "SUB_DISTRICT_ID": "1051", "ZIPCODE": "18120" },
    { "ZIPCODE_ID": 920, "SUB_DISTRICT_CODE": "191001", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1052", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 921, "SUB_DISTRICT_CODE": "191002", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1053", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 922, "SUB_DISTRICT_CODE": "191003", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1054", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 923, "SUB_DISTRICT_CODE": "191004", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1055", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 924, "SUB_DISTRICT_CODE": "191005", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1056", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 925, "SUB_DISTRICT_CODE": "191006", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1057", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 926, "SUB_DISTRICT_CODE": "191007", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1058", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 927, "SUB_DISTRICT_CODE": "191008", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1059", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 928, "SUB_DISTRICT_CODE": "191009", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1060", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 929, "SUB_DISTRICT_CODE": "191010", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1061", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 930, "SUB_DISTRICT_CODE": "191011", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1062", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 931, "SUB_DISTRICT_CODE": "191012", "PROVINCE_ID": "10", "DISTRICT_ID": "132", "SUB_DISTRICT_ID": "1063", "ZIPCODE": "18160" },
    { "ZIPCODE_ID": 932, "SUB_DISTRICT_CODE": "191101", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1064", "ZIPCODE": "18180" },
    { "ZIPCODE_ID": 933, "SUB_DISTRICT_CODE": "191102", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1065", "ZIPCODE": "18180" },
    { "ZIPCODE_ID": 934, "SUB_DISTRICT_CODE": "191104", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1067", "ZIPCODE": "18180" },
    { "ZIPCODE_ID": 935, "SUB_DISTRICT_CODE": "191105", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1068", "ZIPCODE": "18180" },
    { "ZIPCODE_ID": 936, "SUB_DISTRICT_CODE": "191107", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1070", "ZIPCODE": "18180" },
    { "ZIPCODE_ID": 937, "SUB_DISTRICT_CODE": "191109", "PROVINCE_ID": "10", "DISTRICT_ID": "133", "SUB_DISTRICT_ID": "1072", "ZIPCODE": "18220" },
    { "ZIPCODE_ID": 938, "SUB_DISTRICT_CODE": "191201", "PROVINCE_ID": "10", "DISTRICT_ID": "134", "SUB_DISTRICT_ID": "1073", "ZIPCODE": "18220" },
    { "ZIPCODE_ID": 939, "SUB_DISTRICT_CODE": "191202", "PROVINCE_ID": "10", "DISTRICT_ID": "134", "SUB_DISTRICT_ID": "1074", "ZIPCODE": "18220" },
    { "ZIPCODE_ID": 940, "SUB_DISTRICT_CODE": "191203", "PROVINCE_ID": "10", "DISTRICT_ID": "134", "SUB_DISTRICT_ID": "1075", "ZIPCODE": "18220" },
    { "ZIPCODE_ID": 941, "SUB_DISTRICT_CODE": "191301", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1076", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 942, "SUB_DISTRICT_CODE": "191302", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1077", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 943, "SUB_DISTRICT_CODE": "191303", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1078", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 944, "SUB_DISTRICT_CODE": "191304", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1079", "ZIPCODE": "18240" },
    { "ZIPCODE_ID": 945, "SUB_DISTRICT_CODE": "191305", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1080", "ZIPCODE": "18000" },
    { "ZIPCODE_ID": 946, "SUB_DISTRICT_CODE": "191306", "PROVINCE_ID": "10", "DISTRICT_ID": "135", "SUB_DISTRICT_ID": "1081", "ZIPCODE": "18240" },
    { "ZIPCODE_ID": 947, "SUB_DISTRICT_CODE": "200101", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1082", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 948, "SUB_DISTRICT_CODE": "200102", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1083", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 949, "SUB_DISTRICT_CODE": "200103", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1084", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 950, "SUB_DISTRICT_CODE": "200104", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1085", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 951, "SUB_DISTRICT_CODE": "200105", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1086", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 952, "SUB_DISTRICT_CODE": "200106", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1087", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 953, "SUB_DISTRICT_CODE": "200107", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1088", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 954, "SUB_DISTRICT_CODE": "200108", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1089", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 955, "SUB_DISTRICT_CODE": "200109", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1090", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 956, "SUB_DISTRICT_CODE": "200110", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1091", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 957, "SUB_DISTRICT_CODE": "200111", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1092", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 958, "SUB_DISTRICT_CODE": "200112", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1093", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 959, "SUB_DISTRICT_CODE": "200113", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1094", "ZIPCODE": "20130" },
    { "ZIPCODE_ID": 960, "SUB_DISTRICT_CODE": "200114", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1095", "ZIPCODE": "20130" },
    { "ZIPCODE_ID": 961, "SUB_DISTRICT_CODE": "200115", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1096", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 962, "SUB_DISTRICT_CODE": "200116", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1097", "ZIPCODE": "20130" },
    { "ZIPCODE_ID": 963, "SUB_DISTRICT_CODE": "200117", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1098", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 964, "SUB_DISTRICT_CODE": "200118", "PROVINCE_ID": "11", "DISTRICT_ID": "136", "SUB_DISTRICT_ID": "1099", "ZIPCODE": "20000" },
    { "ZIPCODE_ID": 965, "SUB_DISTRICT_CODE": "200201", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1101", "ZIPCODE": "20170" },
    { "ZIPCODE_ID": 966, "SUB_DISTRICT_CODE": "200202", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1102", "ZIPCODE": "20220" },
    { "ZIPCODE_ID": 967, "SUB_DISTRICT_CODE": "200203", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1103", "ZIPCODE": "20170" },
    { "ZIPCODE_ID": 968, "SUB_DISTRICT_CODE": "200204", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1104", "ZIPCODE": "20170" },
    { "ZIPCODE_ID": 969, "SUB_DISTRICT_CODE": "200205", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1105", "ZIPCODE": "20170" },
    { "ZIPCODE_ID": 970, "SUB_DISTRICT_CODE": "200206", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1106", "ZIPCODE": "20170" },
    { "ZIPCODE_ID": 971, "SUB_DISTRICT_CODE": "200207", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1107", "ZIPCODE": "20220" },
    { "ZIPCODE_ID": 972, "SUB_DISTRICT_CODE": "200208", "PROVINCE_ID": "11", "DISTRICT_ID": "137", "SUB_DISTRICT_ID": "1108", "ZIPCODE": "20220" },
    { "ZIPCODE_ID": 973, "SUB_DISTRICT_CODE": "200301", "PROVINCE_ID": "11", "DISTRICT_ID": "138", "SUB_DISTRICT_ID": "1112", "ZIPCODE": "20190" },
    { "ZIPCODE_ID": 974, "SUB_DISTRICT_CODE": "200302", "PROVINCE_ID": "11", "DISTRICT_ID": "138", "SUB_DISTRICT_ID": "1113", "ZIPCODE": "20190" },
    { "ZIPCODE_ID": 975, "SUB_DISTRICT_CODE": "200303", "PROVINCE_ID": "11", "DISTRICT_ID": "138", "SUB_DISTRICT_ID": "1114", "ZIPCODE": "20190" },
    { "ZIPCODE_ID": 976, "SUB_DISTRICT_CODE": "200304", "PROVINCE_ID": "11", "DISTRICT_ID": "138", "SUB_DISTRICT_ID": "1115", "ZIPCODE": "20190" },
    { "ZIPCODE_ID": 977, "SUB_DISTRICT_CODE": "200305", "PROVINCE_ID": "11", "DISTRICT_ID": "138", "SUB_DISTRICT_ID": "1116", "ZIPCODE": "20190" },
    { "ZIPCODE_ID": 978, "SUB_DISTRICT_CODE": "200401", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1117", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 979, "SUB_DISTRICT_CODE": "200402", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1118", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 980, "SUB_DISTRICT_CODE": "200403", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1119", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 981, "SUB_DISTRICT_CODE": "200404", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1120", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 982, "SUB_DISTRICT_CODE": "200405", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1121", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 983, "SUB_DISTRICT_CODE": "200406", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1122", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 984, "SUB_DISTRICT_CODE": "200407", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1123", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 985, "SUB_DISTRICT_CODE": "200408", "PROVINCE_ID": "11", "DISTRICT_ID": "139", "SUB_DISTRICT_ID": "1124", "ZIPCODE": "20150" },
    { "ZIPCODE_ID": 986, "SUB_DISTRICT_CODE": "200501", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1125", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 987, "SUB_DISTRICT_CODE": "200502", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1126", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 988, "SUB_DISTRICT_CODE": "200503", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1127", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 989, "SUB_DISTRICT_CODE": "200504", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1128", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 990, "SUB_DISTRICT_CODE": "200505", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1129", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 991, "SUB_DISTRICT_CODE": "200506", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1130", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 992, "SUB_DISTRICT_CODE": "200507", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1131", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 993, "SUB_DISTRICT_CODE": "200508", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1132", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 994, "SUB_DISTRICT_CODE": "200509", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1133", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 995, "SUB_DISTRICT_CODE": "200510", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1134", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 996, "SUB_DISTRICT_CODE": "200511", "PROVINCE_ID": "11", "DISTRICT_ID": "140", "SUB_DISTRICT_ID": "1135", "ZIPCODE": "20160" },
    { "ZIPCODE_ID": 997, "SUB_DISTRICT_CODE": "200601", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1136", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 998, "SUB_DISTRICT_CODE": "200602", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1137", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 999, "SUB_DISTRICT_CODE": "200603", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1138", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1000, "SUB_DISTRICT_CODE": "200604", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1139", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1001, "SUB_DISTRICT_CODE": "200605", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1140", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1002, "SUB_DISTRICT_CODE": "200606", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1141", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1003, "SUB_DISTRICT_CODE": "200607", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1142", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1004, "SUB_DISTRICT_CODE": "200608", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1143", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1005, "SUB_DISTRICT_CODE": "200609", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1144", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1006, "SUB_DISTRICT_CODE": "200610", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1145", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1007, "SUB_DISTRICT_CODE": "200611", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1146", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1008, "SUB_DISTRICT_CODE": "200613", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1148", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1009, "SUB_DISTRICT_CODE": "200614", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1149", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1010, "SUB_DISTRICT_CODE": "200615", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1150", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1011, "SUB_DISTRICT_CODE": "200616", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1151", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1012, "SUB_DISTRICT_CODE": "200617", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1152", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1013, "SUB_DISTRICT_CODE": "200618", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1153", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1014, "SUB_DISTRICT_CODE": "200620", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1155", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1015, "SUB_DISTRICT_CODE": "200621", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1156", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1016, "SUB_DISTRICT_CODE": "200622", "PROVINCE_ID": "11", "DISTRICT_ID": "141", "SUB_DISTRICT_ID": "1157", "ZIPCODE": "20140" },
    { "ZIPCODE_ID": 1017, "SUB_DISTRICT_CODE": "200701", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1163", "ZIPCODE": "20110" },
    { "ZIPCODE_ID": 1018, "SUB_DISTRICT_CODE": "200702", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1164", "ZIPCODE": "20110" },
    { "ZIPCODE_ID": 1019, "SUB_DISTRICT_CODE": "200703", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1165", "ZIPCODE": "20230" },
    { "ZIPCODE_ID": 1020, "SUB_DISTRICT_CODE": "200704", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1166", "ZIPCODE": "20230" },
    { "ZIPCODE_ID": 1021, "SUB_DISTRICT_CODE": "200705", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1167", "ZIPCODE": "20110" },
    { "ZIPCODE_ID": 1022, "SUB_DISTRICT_CODE": "200706", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1168", "ZIPCODE": "20110" },
    { "ZIPCODE_ID": 1023, "SUB_DISTRICT_CODE": "200707", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1169", "ZIPCODE": "20110" },
    { "ZIPCODE_ID": 1024, "SUB_DISTRICT_CODE": "200708", "PROVINCE_ID": "11", "DISTRICT_ID": "142", "SUB_DISTRICT_ID": "1170", "ZIPCODE": "20230" },
    { "ZIPCODE_ID": 1025, "SUB_DISTRICT_CODE": "200801", "PROVINCE_ID": "11", "DISTRICT_ID": "143", "SUB_DISTRICT_ID": "1172", "ZIPCODE": "20120" },
    { "ZIPCODE_ID": 1026, "SUB_DISTRICT_CODE": "200901", "PROVINCE_ID": "11", "DISTRICT_ID": "144", "SUB_DISTRICT_ID": "1173", "ZIPCODE": "20180" },
    { "ZIPCODE_ID": 1027, "SUB_DISTRICT_CODE": "200902", "PROVINCE_ID": "11", "DISTRICT_ID": "144", "SUB_DISTRICT_ID": "1174", "ZIPCODE": "20250" },
    { "ZIPCODE_ID": 1028, "SUB_DISTRICT_CODE": "200903", "PROVINCE_ID": "11", "DISTRICT_ID": "144", "SUB_DISTRICT_ID": "1175", "ZIPCODE": "20180" },
    { "ZIPCODE_ID": 1029, "SUB_DISTRICT_CODE": "200904", "PROVINCE_ID": "11", "DISTRICT_ID": "144", "SUB_DISTRICT_ID": "1176", "ZIPCODE": "20250" },
    { "ZIPCODE_ID": 1030, "SUB_DISTRICT_CODE": "200905", "PROVINCE_ID": "11", "DISTRICT_ID": "144", "SUB_DISTRICT_ID": "1177", "ZIPCODE": "20180" },
    { "ZIPCODE_ID": 1031, "SUB_DISTRICT_CODE": "201001", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1178", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1032, "SUB_DISTRICT_CODE": "201002", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1179", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1033, "SUB_DISTRICT_CODE": "201003", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1180", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1034, "SUB_DISTRICT_CODE": "201004", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1181", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1035, "SUB_DISTRICT_CODE": "201005", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1182", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1036, "SUB_DISTRICT_CODE": "201006", "PROVINCE_ID": "11", "DISTRICT_ID": "145", "SUB_DISTRICT_ID": "1183", "ZIPCODE": "20270" },
    { "ZIPCODE_ID": 1037, "SUB_DISTRICT_CODE": "201101", "PROVINCE_ID": "11", "DISTRICT_ID": "146", "SUB_DISTRICT_ID": "1184", "ZIPCODE": "20240" },
    { "ZIPCODE_ID": 1038, "SUB_DISTRICT_CODE": "201102", "PROVINCE_ID": "11", "DISTRICT_ID": "146", "SUB_DISTRICT_ID": "1185", "ZIPCODE": "20240" },
    { "ZIPCODE_ID": 1039, "SUB_DISTRICT_CODE": "210101", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1187", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1040, "SUB_DISTRICT_CODE": "210102", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1188", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1041, "SUB_DISTRICT_CODE": "210103", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1189", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1042, "SUB_DISTRICT_CODE": "210104", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1190", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1043, "SUB_DISTRICT_CODE": "210105", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1191", "ZIPCODE": "21160" },
    { "ZIPCODE_ID": 1044, "SUB_DISTRICT_CODE": "210106", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1192", "ZIPCODE": "21160" },
    { "ZIPCODE_ID": 1045, "SUB_DISTRICT_CODE": "210107", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1193", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1046, "SUB_DISTRICT_CODE": "210108", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1194", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1047, "SUB_DISTRICT_CODE": "210109", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1195", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1048, "SUB_DISTRICT_CODE": "210110", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1196", "ZIPCODE": "21100" },
    { "ZIPCODE_ID": 1049, "SUB_DISTRICT_CODE": "210111", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1197", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1050, "SUB_DISTRICT_CODE": "210112", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1198", "ZIPCODE": "21000" },
    { "ZIPCODE_ID": 1051, "SUB_DISTRICT_CODE": "210113", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1199", "ZIPCODE": "21150" },
    { "ZIPCODE_ID": 1052, "SUB_DISTRICT_CODE": "210114", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1200", "ZIPCODE": "21150" },
    { "ZIPCODE_ID": 1053, "SUB_DISTRICT_CODE": "210115", "PROVINCE_ID": "12", "DISTRICT_ID": "151", "SUB_DISTRICT_ID": "1201", "ZIPCODE": "21100" },
    { "ZIPCODE_ID": 1054, "SUB_DISTRICT_CODE": "210201", "PROVINCE_ID": "12", "DISTRICT_ID": "152", "SUB_DISTRICT_ID": "1204", "ZIPCODE": "21130" },
    { "ZIPCODE_ID": 1055, "SUB_DISTRICT_CODE": "210202", "PROVINCE_ID": "12", "DISTRICT_ID": "152", "SUB_DISTRICT_ID": "1205", "ZIPCODE": "21130" },
    { "ZIPCODE_ID": 1056, "SUB_DISTRICT_CODE": "210203", "PROVINCE_ID": "12", "DISTRICT_ID": "152", "SUB_DISTRICT_ID": "1206", "ZIPCODE": "21130" },
    { "ZIPCODE_ID": 1057, "SUB_DISTRICT_CODE": "210301", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1207", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1058, "SUB_DISTRICT_CODE": "210302", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1208", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1059, "SUB_DISTRICT_CODE": "210303", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1209", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1060, "SUB_DISTRICT_CODE": "210304", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1210", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1061, "SUB_DISTRICT_CODE": "210305", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1211", "ZIPCODE": "21190" },
    { "ZIPCODE_ID": 1062, "SUB_DISTRICT_CODE": "210306", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1212", "ZIPCODE": "21190" },
    { "ZIPCODE_ID": 1063, "SUB_DISTRICT_CODE": "210307", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1213", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1064, "SUB_DISTRICT_CODE": "210308", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1214", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1065, "SUB_DISTRICT_CODE": "210309", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1215", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1066, "SUB_DISTRICT_CODE": "210310", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1216", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1067, "SUB_DISTRICT_CODE": "210311", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1217", "ZIPCODE": "21170" },
    { "ZIPCODE_ID": 1068, "SUB_DISTRICT_CODE": "210312", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1218", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1069, "SUB_DISTRICT_CODE": "210313", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1219", "ZIPCODE": "21170" },
    { "ZIPCODE_ID": 1070, "SUB_DISTRICT_CODE": "210317", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1223", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1071, "SUB_DISTRICT_CODE": "210318", "PROVINCE_ID": "12", "DISTRICT_ID": "153", "SUB_DISTRICT_ID": "1224", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1072, "SUB_DISTRICT_CODE": "210401", "PROVINCE_ID": "12", "DISTRICT_ID": "154", "SUB_DISTRICT_ID": "1228", "ZIPCODE": "21210" },
    { "ZIPCODE_ID": 1073, "SUB_DISTRICT_CODE": "210402", "PROVINCE_ID": "12", "DISTRICT_ID": "154", "SUB_DISTRICT_ID": "1229", "ZIPCODE": "21210" },
    { "ZIPCODE_ID": 1074, "SUB_DISTRICT_CODE": "210403", "PROVINCE_ID": "12", "DISTRICT_ID": "154", "SUB_DISTRICT_ID": "1230", "ZIPCODE": "21210" },
    { "ZIPCODE_ID": 1075, "SUB_DISTRICT_CODE": "210404", "PROVINCE_ID": "12", "DISTRICT_ID": "154", "SUB_DISTRICT_ID": "1231", "ZIPCODE": "21210" },
    { "ZIPCODE_ID": 1076, "SUB_DISTRICT_CODE": "210501", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1232", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1077, "SUB_DISTRICT_CODE": "210502", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1233", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1078, "SUB_DISTRICT_CODE": "210503", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1234", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1079, "SUB_DISTRICT_CODE": "210504", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1235", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1080, "SUB_DISTRICT_CODE": "210505", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1236", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1081, "SUB_DISTRICT_CODE": "210506", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1237", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1082, "SUB_DISTRICT_CODE": "210507", "PROVINCE_ID": "12", "DISTRICT_ID": "155", "SUB_DISTRICT_ID": "1238", "ZIPCODE": "21120" },
    { "ZIPCODE_ID": 1083, "SUB_DISTRICT_CODE": "210601", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1249", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1084, "SUB_DISTRICT_CODE": "210602", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1250", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1085, "SUB_DISTRICT_CODE": "210603", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1251", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1086, "SUB_DISTRICT_CODE": "210604", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1252", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1087, "SUB_DISTRICT_CODE": "210605", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1253", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1088, "SUB_DISTRICT_CODE": "210606", "PROVINCE_ID": "12", "DISTRICT_ID": "156", "SUB_DISTRICT_ID": "1254", "ZIPCODE": "21140" },
    { "ZIPCODE_ID": 1089, "SUB_DISTRICT_CODE": "210701", "PROVINCE_ID": "12", "DISTRICT_ID": "157", "SUB_DISTRICT_ID": "1255", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1090, "SUB_DISTRICT_CODE": "210702", "PROVINCE_ID": "12", "DISTRICT_ID": "157", "SUB_DISTRICT_ID": "1256", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1091, "SUB_DISTRICT_CODE": "210703", "PROVINCE_ID": "12", "DISTRICT_ID": "157", "SUB_DISTRICT_ID": "1257", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1092, "SUB_DISTRICT_CODE": "210704", "PROVINCE_ID": "12", "DISTRICT_ID": "157", "SUB_DISTRICT_ID": "1258", "ZIPCODE": "21110" },
    { "ZIPCODE_ID": 1093, "SUB_DISTRICT_CODE": "210801", "PROVINCE_ID": "12", "DISTRICT_ID": "158", "SUB_DISTRICT_ID": "1259", "ZIPCODE": "21180" },
    { "ZIPCODE_ID": 1094, "SUB_DISTRICT_CODE": "210802", "PROVINCE_ID": "12", "DISTRICT_ID": "158", "SUB_DISTRICT_ID": "1260", "ZIPCODE": "21180" },
    { "ZIPCODE_ID": 1095, "SUB_DISTRICT_CODE": "210803", "PROVINCE_ID": "12", "DISTRICT_ID": "158", "SUB_DISTRICT_ID": "1261", "ZIPCODE": "21180" },
    { "ZIPCODE_ID": 1096, "SUB_DISTRICT_CODE": "210804", "PROVINCE_ID": "12", "DISTRICT_ID": "158", "SUB_DISTRICT_ID": "1262", "ZIPCODE": "21180" },
    { "ZIPCODE_ID": 1097, "SUB_DISTRICT_CODE": "220101", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1263", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1098, "SUB_DISTRICT_CODE": "220102", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1264", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1099, "SUB_DISTRICT_CODE": "220103", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1265", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1100, "SUB_DISTRICT_CODE": "220104", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1266", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1101, "SUB_DISTRICT_CODE": "220105", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1267", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1102, "SUB_DISTRICT_CODE": "220106", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1268", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1103, "SUB_DISTRICT_CODE": "220107", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1269", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1104, "SUB_DISTRICT_CODE": "220108", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1270", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1105, "SUB_DISTRICT_CODE": "220109", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1271", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1106, "SUB_DISTRICT_CODE": "220110", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1272", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1107, "SUB_DISTRICT_CODE": "220111", "PROVINCE_ID": "13", "DISTRICT_ID": "160", "SUB_DISTRICT_ID": "1273", "ZIPCODE": "22000" },
    { "ZIPCODE_ID": 1108, "SUB_DISTRICT_CODE": "220201", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1274", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1109, "SUB_DISTRICT_CODE": "220202", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1275", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1110, "SUB_DISTRICT_CODE": "220203", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1276", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1111, "SUB_DISTRICT_CODE": "220204", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1277", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1112, "SUB_DISTRICT_CODE": "220205", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1278", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1113, "SUB_DISTRICT_CODE": "220206", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1279", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1114, "SUB_DISTRICT_CODE": "220207", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1280", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1115, "SUB_DISTRICT_CODE": "220208", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1281", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1116, "SUB_DISTRICT_CODE": "220209", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1282", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1117, "SUB_DISTRICT_CODE": "220210", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1283", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1118, "SUB_DISTRICT_CODE": "220211", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1284", "ZIPCODE": "22110" },
    { "ZIPCODE_ID": 1119, "SUB_DISTRICT_CODE": "220212", "PROVINCE_ID": "13", "DISTRICT_ID": "161", "SUB_DISTRICT_ID": "1285", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1120, "SUB_DISTRICT_CODE": "220301", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1286", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1121, "SUB_DISTRICT_CODE": "220302", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1287", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1122, "SUB_DISTRICT_CODE": "220303", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1288", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1123, "SUB_DISTRICT_CODE": "220304", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1289", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1124, "SUB_DISTRICT_CODE": "220305", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1290", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1125, "SUB_DISTRICT_CODE": "220306", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1291", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1126, "SUB_DISTRICT_CODE": "220307", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1292", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1127, "SUB_DISTRICT_CODE": "220308", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1293", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1128, "SUB_DISTRICT_CODE": "220309", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1294", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1129, "SUB_DISTRICT_CODE": "220311", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1296", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1130, "SUB_DISTRICT_CODE": "220312", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1297", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1131, "SUB_DISTRICT_CODE": "220313", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1298", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1132, "SUB_DISTRICT_CODE": "220314", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1299", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1133, "SUB_DISTRICT_CODE": "220324", "PROVINCE_ID": "13", "DISTRICT_ID": "162", "SUB_DISTRICT_ID": "1309", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1134, "SUB_DISTRICT_CODE": "220401", "PROVINCE_ID": "13", "DISTRICT_ID": "163", "SUB_DISTRICT_ID": "1316", "ZIPCODE": "22140" },
    { "ZIPCODE_ID": 1135, "SUB_DISTRICT_CODE": "220402", "PROVINCE_ID": "13", "DISTRICT_ID": "163", "SUB_DISTRICT_ID": "1317", "ZIPCODE": "22140" },
    { "ZIPCODE_ID": 1136, "SUB_DISTRICT_CODE": "220404", "PROVINCE_ID": "13", "DISTRICT_ID": "163", "SUB_DISTRICT_ID": "1319", "ZIPCODE": "22140" },
    { "ZIPCODE_ID": 1137, "SUB_DISTRICT_CODE": "220409", "PROVINCE_ID": "13", "DISTRICT_ID": "163", "SUB_DISTRICT_ID": "1324", "ZIPCODE": "22140" },
    { "ZIPCODE_ID": 1138, "SUB_DISTRICT_CODE": "220410", "PROVINCE_ID": "13", "DISTRICT_ID": "163", "SUB_DISTRICT_ID": "1325", "ZIPCODE": "22140" },
    { "ZIPCODE_ID": 1139, "SUB_DISTRICT_CODE": "220501", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1326", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1140, "SUB_DISTRICT_CODE": "220502", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1327", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1141, "SUB_DISTRICT_CODE": "220503", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1328", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1142, "SUB_DISTRICT_CODE": "220504", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1329", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1143, "SUB_DISTRICT_CODE": "220506", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1331", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1144, "SUB_DISTRICT_CODE": "220508", "PROVINCE_ID": "13", "DISTRICT_ID": "164", "SUB_DISTRICT_ID": "1333", "ZIPCODE": "22150" },
    { "ZIPCODE_ID": 1145, "SUB_DISTRICT_CODE": "220601", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1336", "ZIPCODE": "22130" },
    { "ZIPCODE_ID": 1146, "SUB_DISTRICT_CODE": "220602", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1337", "ZIPCODE": "22130" },
    { "ZIPCODE_ID": 1147, "SUB_DISTRICT_CODE": "220603", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1338", "ZIPCODE": "22130" },
    { "ZIPCODE_ID": 1148, "SUB_DISTRICT_CODE": "220604", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1339", "ZIPCODE": "22190" },
    { "ZIPCODE_ID": 1149, "SUB_DISTRICT_CODE": "220605", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1340", "ZIPCODE": "22190" },
    { "ZIPCODE_ID": 1150, "SUB_DISTRICT_CODE": "220606", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1341", "ZIPCODE": "22190" },
    { "ZIPCODE_ID": 1151, "SUB_DISTRICT_CODE": "220607", "PROVINCE_ID": "13", "DISTRICT_ID": "165", "SUB_DISTRICT_ID": "1342", "ZIPCODE": "22120" },
    { "ZIPCODE_ID": 1152, "SUB_DISTRICT_CODE": "220701", "PROVINCE_ID": "13", "DISTRICT_ID": "166", "SUB_DISTRICT_ID": "1343", "ZIPCODE": "22180" },
    { "ZIPCODE_ID": 1153, "SUB_DISTRICT_CODE": "220702", "PROVINCE_ID": "13", "DISTRICT_ID": "166", "SUB_DISTRICT_ID": "1344", "ZIPCODE": "22180" },
    { "ZIPCODE_ID": 1154, "SUB_DISTRICT_CODE": "220703", "PROVINCE_ID": "13", "DISTRICT_ID": "166", "SUB_DISTRICT_ID": "1345", "ZIPCODE": "22180" },
    { "ZIPCODE_ID": 1155, "SUB_DISTRICT_CODE": "220704", "PROVINCE_ID": "13", "DISTRICT_ID": "166", "SUB_DISTRICT_ID": "1346", "ZIPCODE": "22180" },
    { "ZIPCODE_ID": 1156, "SUB_DISTRICT_CODE": "220705", "PROVINCE_ID": "13", "DISTRICT_ID": "166", "SUB_DISTRICT_ID": "1347", "ZIPCODE": "22180" },
    { "ZIPCODE_ID": 1157, "SUB_DISTRICT_CODE": "220801", "PROVINCE_ID": "13", "DISTRICT_ID": "167", "SUB_DISTRICT_ID": "1348", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1158, "SUB_DISTRICT_CODE": "220802", "PROVINCE_ID": "13", "DISTRICT_ID": "167", "SUB_DISTRICT_ID": "1349", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1159, "SUB_DISTRICT_CODE": "220803", "PROVINCE_ID": "13", "DISTRICT_ID": "167", "SUB_DISTRICT_ID": "1350", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1160, "SUB_DISTRICT_CODE": "220804", "PROVINCE_ID": "13", "DISTRICT_ID": "167", "SUB_DISTRICT_ID": "1351", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1161, "SUB_DISTRICT_CODE": "220805", "PROVINCE_ID": "13", "DISTRICT_ID": "167", "SUB_DISTRICT_ID": "1352", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1162, "SUB_DISTRICT_CODE": "220901", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1353", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1163, "SUB_DISTRICT_CODE": "220902", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1354", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1164, "SUB_DISTRICT_CODE": "220903", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1355", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1165, "SUB_DISTRICT_CODE": "220904", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1356", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1166, "SUB_DISTRICT_CODE": "220905", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1357", "ZIPCODE": "22160" },
    { "ZIPCODE_ID": 1167, "SUB_DISTRICT_CODE": "220906", "PROVINCE_ID": "13", "DISTRICT_ID": "168", "SUB_DISTRICT_ID": "1358", "ZIPCODE": "22170" },
    { "ZIPCODE_ID": 1168, "SUB_DISTRICT_CODE": "221001", "PROVINCE_ID": "13", "DISTRICT_ID": "169", "SUB_DISTRICT_ID": "1359", "ZIPCODE": "22210" },
    { "ZIPCODE_ID": 1169, "SUB_DISTRICT_CODE": "221002", "PROVINCE_ID": "13", "DISTRICT_ID": "169", "SUB_DISTRICT_ID": "1360", "ZIPCODE": "22210" },
    { "ZIPCODE_ID": 1170, "SUB_DISTRICT_CODE": "221003", "PROVINCE_ID": "13", "DISTRICT_ID": "169", "SUB_DISTRICT_ID": "1361", "ZIPCODE": "22210" },
    { "ZIPCODE_ID": 1171, "SUB_DISTRICT_CODE": "221004", "PROVINCE_ID": "13", "DISTRICT_ID": "169", "SUB_DISTRICT_ID": "1362", "ZIPCODE": "22210" },
    { "ZIPCODE_ID": 1172, "SUB_DISTRICT_CODE": "221005", "PROVINCE_ID": "13", "DISTRICT_ID": "169", "SUB_DISTRICT_ID": "1363", "ZIPCODE": "22210" },
    { "ZIPCODE_ID": 1173, "SUB_DISTRICT_CODE": "230101", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1364", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1174, "SUB_DISTRICT_CODE": "230102", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1365", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1175, "SUB_DISTRICT_CODE": "230103", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1366", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1176, "SUB_DISTRICT_CODE": "230104", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1367", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1177, "SUB_DISTRICT_CODE": "230105", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1368", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1178, "SUB_DISTRICT_CODE": "230106", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1369", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1179, "SUB_DISTRICT_CODE": "230107", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1370", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1180, "SUB_DISTRICT_CODE": "230108", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1371", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1181, "SUB_DISTRICT_CODE": "230109", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1372", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1182, "SUB_DISTRICT_CODE": "230110", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1373", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1183, "SUB_DISTRICT_CODE": "230111", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1374", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1184, "SUB_DISTRICT_CODE": "230112", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1375", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1185, "SUB_DISTRICT_CODE": "230113", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1376", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1186, "SUB_DISTRICT_CODE": "230114", "PROVINCE_ID": "14", "DISTRICT_ID": "171", "SUB_DISTRICT_ID": "1377", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1187, "SUB_DISTRICT_CODE": "230201", "PROVINCE_ID": "14", "DISTRICT_ID": "172", "SUB_DISTRICT_ID": "1378", "ZIPCODE": "23110" },
    { "ZIPCODE_ID": 1188, "SUB_DISTRICT_CODE": "230202", "PROVINCE_ID": "14", "DISTRICT_ID": "172", "SUB_DISTRICT_ID": "1379", "ZIPCODE": "23110" },
    { "ZIPCODE_ID": 1189, "SUB_DISTRICT_CODE": "230203", "PROVINCE_ID": "14", "DISTRICT_ID": "172", "SUB_DISTRICT_ID": "1380", "ZIPCODE": "23110" },
    { "ZIPCODE_ID": 1190, "SUB_DISTRICT_CODE": "230301", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1381", "ZIPCODE": "23130" },
    { "ZIPCODE_ID": 1191, "SUB_DISTRICT_CODE": "230302", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1382", "ZIPCODE": "23150" },
    { "ZIPCODE_ID": 1192, "SUB_DISTRICT_CODE": "230303", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1383", "ZIPCODE": "23130" },
    { "ZIPCODE_ID": 1193, "SUB_DISTRICT_CODE": "230304", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1384", "ZIPCODE": "23150" },
    { "ZIPCODE_ID": 1194, "SUB_DISTRICT_CODE": "230305", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1385", "ZIPCODE": "23150" },
    { "ZIPCODE_ID": 1195, "SUB_DISTRICT_CODE": "230306", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1386", "ZIPCODE": "23150" },
    { "ZIPCODE_ID": 1196, "SUB_DISTRICT_CODE": "230307", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1387", "ZIPCODE": "23150" },
    { "ZIPCODE_ID": 1197, "SUB_DISTRICT_CODE": "230308", "PROVINCE_ID": "14", "DISTRICT_ID": "173", "SUB_DISTRICT_ID": "1388", "ZIPCODE": "23130" },
    { "ZIPCODE_ID": 1198, "SUB_DISTRICT_CODE": "230401", "PROVINCE_ID": "14", "DISTRICT_ID": "174", "SUB_DISTRICT_ID": "1394", "ZIPCODE": "23140" },
    { "ZIPCODE_ID": 1199, "SUB_DISTRICT_CODE": "230402", "PROVINCE_ID": "14", "DISTRICT_ID": "174", "SUB_DISTRICT_ID": "1395", "ZIPCODE": "23140" },
    { "ZIPCODE_ID": 1200, "SUB_DISTRICT_CODE": "230403", "PROVINCE_ID": "14", "DISTRICT_ID": "174", "SUB_DISTRICT_ID": "1396", "ZIPCODE": "23140" },
    { "ZIPCODE_ID": 1201, "SUB_DISTRICT_CODE": "230404", "PROVINCE_ID": "14", "DISTRICT_ID": "174", "SUB_DISTRICT_ID": "1397", "ZIPCODE": "23140" },
    { "ZIPCODE_ID": 1202, "SUB_DISTRICT_CODE": "230405", "PROVINCE_ID": "14", "DISTRICT_ID": "174", "SUB_DISTRICT_ID": "1398", "ZIPCODE": "23140" },
    { "ZIPCODE_ID": 1203, "SUB_DISTRICT_CODE": "230501", "PROVINCE_ID": "14", "DISTRICT_ID": "175", "SUB_DISTRICT_ID": "1399", "ZIPCODE": "23120" },
    { "ZIPCODE_ID": 1204, "SUB_DISTRICT_CODE": "230502", "PROVINCE_ID": "14", "DISTRICT_ID": "175", "SUB_DISTRICT_ID": "1400", "ZIPCODE": "23120" },
    { "ZIPCODE_ID": 1205, "SUB_DISTRICT_CODE": "230503", "PROVINCE_ID": "14", "DISTRICT_ID": "175", "SUB_DISTRICT_ID": "1401", "ZIPCODE": "23120" },
    { "ZIPCODE_ID": 1206, "SUB_DISTRICT_CODE": "230507", "PROVINCE_ID": "14", "DISTRICT_ID": "175", "SUB_DISTRICT_ID": "1405", "ZIPCODE": "23120" },
    { "ZIPCODE_ID": 1207, "SUB_DISTRICT_CODE": "230601", "PROVINCE_ID": "14", "DISTRICT_ID": "176", "SUB_DISTRICT_ID": "1407", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1208, "SUB_DISTRICT_CODE": "230602", "PROVINCE_ID": "14", "DISTRICT_ID": "176", "SUB_DISTRICT_ID": "1408", "ZIPCODE": "23000" },
    { "ZIPCODE_ID": 1209, "SUB_DISTRICT_CODE": "230701", "PROVINCE_ID": "14", "DISTRICT_ID": "177", "SUB_DISTRICT_ID": "1409", "ZIPCODE": "23170" },
    { "ZIPCODE_ID": 1210, "SUB_DISTRICT_CODE": "230702", "PROVINCE_ID": "14", "DISTRICT_ID": "177", "SUB_DISTRICT_ID": "1410", "ZIPCODE": "23170" },
    { "ZIPCODE_ID": 1211, "SUB_DISTRICT_CODE": "240101", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1411", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1212, "SUB_DISTRICT_CODE": "240102", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1412", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1213, "SUB_DISTRICT_CODE": "240103", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1413", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1214, "SUB_DISTRICT_CODE": "240104", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1414", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1215, "SUB_DISTRICT_CODE": "240105", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1415", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1216, "SUB_DISTRICT_CODE": "240106", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1416", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1217, "SUB_DISTRICT_CODE": "240107", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1417", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1218, "SUB_DISTRICT_CODE": "240108", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1418", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1219, "SUB_DISTRICT_CODE": "240109", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1419", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1220, "SUB_DISTRICT_CODE": "240110", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1420", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1221, "SUB_DISTRICT_CODE": "240111", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1421", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1222, "SUB_DISTRICT_CODE": "240112", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1422", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1223, "SUB_DISTRICT_CODE": "240113", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1423", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1224, "SUB_DISTRICT_CODE": "240114", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1424", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1225, "SUB_DISTRICT_CODE": "240115", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1425", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1226, "SUB_DISTRICT_CODE": "240116", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1426", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1227, "SUB_DISTRICT_CODE": "240117", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1427", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1228, "SUB_DISTRICT_CODE": "240118", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1428", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1229, "SUB_DISTRICT_CODE": "240119", "PROVINCE_ID": "15", "DISTRICT_ID": "178", "SUB_DISTRICT_ID": "1429", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1230, "SUB_DISTRICT_CODE": "240201", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1430", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1231, "SUB_DISTRICT_CODE": "240204", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1433", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1232, "SUB_DISTRICT_CODE": "240208", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1437", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1233, "SUB_DISTRICT_CODE": "240209", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1438", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1234, "SUB_DISTRICT_CODE": "240210", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1439", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1235, "SUB_DISTRICT_CODE": "240211", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1440", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1236, "SUB_DISTRICT_CODE": "240212", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1441", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1237, "SUB_DISTRICT_CODE": "240213", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1442", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1238, "SUB_DISTRICT_CODE": "240214", "PROVINCE_ID": "15", "DISTRICT_ID": "179", "SUB_DISTRICT_ID": "1443", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1239, "SUB_DISTRICT_CODE": "240301", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1444", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1240, "SUB_DISTRICT_CODE": "240302", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1445", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1241, "SUB_DISTRICT_CODE": "240303", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1446", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1242, "SUB_DISTRICT_CODE": "240304", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1447", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1243, "SUB_DISTRICT_CODE": "240305", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1448", "ZIPCODE": "24170" },
    { "ZIPCODE_ID": 1244, "SUB_DISTRICT_CODE": "240306", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1449", "ZIPCODE": "24170" },
    { "ZIPCODE_ID": 1245, "SUB_DISTRICT_CODE": "240307", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1450", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1246, "SUB_DISTRICT_CODE": "240308", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1451", "ZIPCODE": "24170" },
    { "ZIPCODE_ID": 1247, "SUB_DISTRICT_CODE": "240309", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1452", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1248, "SUB_DISTRICT_CODE": "240310", "PROVINCE_ID": "15", "DISTRICT_ID": "180", "SUB_DISTRICT_ID": "1453", "ZIPCODE": "24150" },
    { "ZIPCODE_ID": 1249, "SUB_DISTRICT_CODE": "240401", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1454", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1250, "SUB_DISTRICT_CODE": "240402", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1455", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1251, "SUB_DISTRICT_CODE": "240403", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1456", "ZIPCODE": "24180" },
    { "ZIPCODE_ID": 1252, "SUB_DISTRICT_CODE": "240404", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1457", "ZIPCODE": "24180" },
    { "ZIPCODE_ID": 1253, "SUB_DISTRICT_CODE": "240405", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1458", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1254, "SUB_DISTRICT_CODE": "240406", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1459", "ZIPCODE": "24180" },
    { "ZIPCODE_ID": 1255, "SUB_DISTRICT_CODE": "240407", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1460", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1256, "SUB_DISTRICT_CODE": "240408", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1461", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1257, "SUB_DISTRICT_CODE": "240409", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1462", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1258, "SUB_DISTRICT_CODE": "240410", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1463", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1259, "SUB_DISTRICT_CODE": "240411", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1464", "ZIPCODE": "24180" },
    { "ZIPCODE_ID": 1260, "SUB_DISTRICT_CODE": "240412", "PROVINCE_ID": "15", "DISTRICT_ID": "181", "SUB_DISTRICT_ID": "1465", "ZIPCODE": "24130" },
    { "ZIPCODE_ID": 1261, "SUB_DISTRICT_CODE": "240501", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1466", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1262, "SUB_DISTRICT_CODE": "240502", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1467", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1263, "SUB_DISTRICT_CODE": "240503", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1468", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1264, "SUB_DISTRICT_CODE": "240504", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1469", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1265, "SUB_DISTRICT_CODE": "240505", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1470", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1266, "SUB_DISTRICT_CODE": "240506", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1471", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1267, "SUB_DISTRICT_CODE": "240507", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1472", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1268, "SUB_DISTRICT_CODE": "240508", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1473", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1269, "SUB_DISTRICT_CODE": "240509", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1474", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1270, "SUB_DISTRICT_CODE": "240510", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1475", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1271, "SUB_DISTRICT_CODE": "240511", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1476", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1272, "SUB_DISTRICT_CODE": "240512", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1477", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1273, "SUB_DISTRICT_CODE": "240513", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1478", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1274, "SUB_DISTRICT_CODE": "240514", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1479", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1275, "SUB_DISTRICT_CODE": "240515", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1480", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1276, "SUB_DISTRICT_CODE": "240516", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1481", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1277, "SUB_DISTRICT_CODE": "240517", "PROVINCE_ID": "15", "DISTRICT_ID": "182", "SUB_DISTRICT_ID": "1482", "ZIPCODE": "24140" },
    { "ZIPCODE_ID": 1278, "SUB_DISTRICT_CODE": "240601", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1483", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1279, "SUB_DISTRICT_CODE": "240602", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1484", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1280, "SUB_DISTRICT_CODE": "240603", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1485", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1281, "SUB_DISTRICT_CODE": "240604", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1486", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1282, "SUB_DISTRICT_CODE": "240605", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1487", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1283, "SUB_DISTRICT_CODE": "240606", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1488", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1284, "SUB_DISTRICT_CODE": "240607", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1489", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1285, "SUB_DISTRICT_CODE": "240608", "PROVINCE_ID": "15", "DISTRICT_ID": "183", "SUB_DISTRICT_ID": "1490", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1286, "SUB_DISTRICT_CODE": "240701", "PROVINCE_ID": "15", "DISTRICT_ID": "184", "SUB_DISTRICT_ID": "1491", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1287, "SUB_DISTRICT_CODE": "240702", "PROVINCE_ID": "15", "DISTRICT_ID": "184", "SUB_DISTRICT_ID": "1492", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1288, "SUB_DISTRICT_CODE": "240703", "PROVINCE_ID": "15", "DISTRICT_ID": "184", "SUB_DISTRICT_ID": "1493", "ZIPCODE": "24120" },
    { "ZIPCODE_ID": 1289, "SUB_DISTRICT_CODE": "240801", "PROVINCE_ID": "15", "DISTRICT_ID": "185", "SUB_DISTRICT_ID": "1494", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1290, "SUB_DISTRICT_CODE": "240802", "PROVINCE_ID": "15", "DISTRICT_ID": "185", "SUB_DISTRICT_ID": "1495", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1291, "SUB_DISTRICT_CODE": "240803", "PROVINCE_ID": "15", "DISTRICT_ID": "185", "SUB_DISTRICT_ID": "1496", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1292, "SUB_DISTRICT_CODE": "240805", "PROVINCE_ID": "15", "DISTRICT_ID": "185", "SUB_DISTRICT_ID": "1498", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1293, "SUB_DISTRICT_CODE": "240901", "PROVINCE_ID": "15", "DISTRICT_ID": "186", "SUB_DISTRICT_ID": "1500", "ZIPCODE": "24190" },
    { "ZIPCODE_ID": 1294, "SUB_DISTRICT_CODE": "240902", "PROVINCE_ID": "15", "DISTRICT_ID": "186", "SUB_DISTRICT_ID": "1501", "ZIPCODE": "24190" },
    { "ZIPCODE_ID": 1295, "SUB_DISTRICT_CODE": "240903", "PROVINCE_ID": "15", "DISTRICT_ID": "186", "SUB_DISTRICT_ID": "1502", "ZIPCODE": "24190" },
    { "ZIPCODE_ID": 1296, "SUB_DISTRICT_CODE": "240904", "PROVINCE_ID": "15", "DISTRICT_ID": "186", "SUB_DISTRICT_ID": "1503", "ZIPCODE": "24190" },
    { "ZIPCODE_ID": 1297, "SUB_DISTRICT_CODE": "241001", "PROVINCE_ID": "15", "DISTRICT_ID": "187", "SUB_DISTRICT_ID": "1504", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1298, "SUB_DISTRICT_CODE": "241002", "PROVINCE_ID": "15", "DISTRICT_ID": "187", "SUB_DISTRICT_ID": "1505", "ZIPCODE": "24160" },
    { "ZIPCODE_ID": 1299, "SUB_DISTRICT_CODE": "241101", "PROVINCE_ID": "15", "DISTRICT_ID": "188", "SUB_DISTRICT_ID": "1506", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1300, "SUB_DISTRICT_CODE": "241102", "PROVINCE_ID": "15", "DISTRICT_ID": "188", "SUB_DISTRICT_ID": "1507", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1301, "SUB_DISTRICT_CODE": "241103", "PROVINCE_ID": "15", "DISTRICT_ID": "188", "SUB_DISTRICT_ID": "1508", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1302, "SUB_DISTRICT_CODE": "241104", "PROVINCE_ID": "15", "DISTRICT_ID": "188", "SUB_DISTRICT_ID": "1509", "ZIPCODE": "24000" },
    { "ZIPCODE_ID": 1303, "SUB_DISTRICT_CODE": "241105", "PROVINCE_ID": "15", "DISTRICT_ID": "188", "SUB_DISTRICT_ID": "1510", "ZIPCODE": "24110" },
    { "ZIPCODE_ID": 1304, "SUB_DISTRICT_CODE": "250101", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1511", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1305, "SUB_DISTRICT_CODE": "250102", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1512", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1306, "SUB_DISTRICT_CODE": "250103", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1513", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1307, "SUB_DISTRICT_CODE": "250104", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1514", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1308, "SUB_DISTRICT_CODE": "250105", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1515", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1309, "SUB_DISTRICT_CODE": "250106", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1516", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1310, "SUB_DISTRICT_CODE": "250107", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1517", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1311, "SUB_DISTRICT_CODE": "250108", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1518", "ZIPCODE": "25230" },
    { "ZIPCODE_ID": 1312, "SUB_DISTRICT_CODE": "250109", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1519", "ZIPCODE": "25230" },
    { "ZIPCODE_ID": 1313, "SUB_DISTRICT_CODE": "250110", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1520", "ZIPCODE": "25230" },
    { "ZIPCODE_ID": 1314, "SUB_DISTRICT_CODE": "250111", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1521", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1315, "SUB_DISTRICT_CODE": "250112", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1522", "ZIPCODE": "25230" },
    { "ZIPCODE_ID": 1316, "SUB_DISTRICT_CODE": "250113", "PROVINCE_ID": "16", "DISTRICT_ID": "189", "SUB_DISTRICT_ID": "1523", "ZIPCODE": "25000" },
    { "ZIPCODE_ID": 1317, "SUB_DISTRICT_CODE": "250201", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1524", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1318, "SUB_DISTRICT_CODE": "250202", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1525", "ZIPCODE": "25240" },
    { "ZIPCODE_ID": 1319, "SUB_DISTRICT_CODE": "250203", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1526", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1320, "SUB_DISTRICT_CODE": "250204", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1527", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1321, "SUB_DISTRICT_CODE": "250205", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1528", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1322, "SUB_DISTRICT_CODE": "250206", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1529", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1323, "SUB_DISTRICT_CODE": "250207", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1530", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1324, "SUB_DISTRICT_CODE": "250208", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1531", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1325, "SUB_DISTRICT_CODE": "250209", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1532", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1326, "SUB_DISTRICT_CODE": "250210", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1533", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1327, "SUB_DISTRICT_CODE": "250211", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1534", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1328, "SUB_DISTRICT_CODE": "250212", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1535", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1329, "SUB_DISTRICT_CODE": "250213", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1536", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1330, "SUB_DISTRICT_CODE": "250214", "PROVINCE_ID": "16", "DISTRICT_ID": "190", "SUB_DISTRICT_ID": "1537", "ZIPCODE": "25110" },
    { "ZIPCODE_ID": 1331, "SUB_DISTRICT_CODE": "250301", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1542", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1332, "SUB_DISTRICT_CODE": "250302", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1543", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1333, "SUB_DISTRICT_CODE": "250303", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1544", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1334, "SUB_DISTRICT_CODE": "250304", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1545", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1335, "SUB_DISTRICT_CODE": "250305", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1546", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1336, "SUB_DISTRICT_CODE": "250306", "PROVINCE_ID": "16", "DISTRICT_ID": "191", "SUB_DISTRICT_ID": "1547", "ZIPCODE": "25220" },
    { "ZIPCODE_ID": 1337, "SUB_DISTRICT_CODE": "250601", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1569", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1338, "SUB_DISTRICT_CODE": "250602", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1570", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1339, "SUB_DISTRICT_CODE": "250603", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1571", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1340, "SUB_DISTRICT_CODE": "250604", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1572", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1341, "SUB_DISTRICT_CODE": "250605", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1573", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1342, "SUB_DISTRICT_CODE": "250606", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1574", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1343, "SUB_DISTRICT_CODE": "250607", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1575", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1344, "SUB_DISTRICT_CODE": "250608", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1576", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1345, "SUB_DISTRICT_CODE": "250609", "PROVINCE_ID": "16", "DISTRICT_ID": "194", "SUB_DISTRICT_ID": "1577", "ZIPCODE": "25150" },
    { "ZIPCODE_ID": 1346, "SUB_DISTRICT_CODE": "250701", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1578", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1347, "SUB_DISTRICT_CODE": "250702", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1579", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1348, "SUB_DISTRICT_CODE": "250703", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1580", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1349, "SUB_DISTRICT_CODE": "250704", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1581", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1350, "SUB_DISTRICT_CODE": "250705", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1582", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1351, "SUB_DISTRICT_CODE": "250706", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1583", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1352, "SUB_DISTRICT_CODE": "250707", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1584", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1353, "SUB_DISTRICT_CODE": "250708", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1585", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1354, "SUB_DISTRICT_CODE": "250709", "PROVINCE_ID": "16", "DISTRICT_ID": "195", "SUB_DISTRICT_ID": "1586", "ZIPCODE": "25130" },
    { "ZIPCODE_ID": 1355, "SUB_DISTRICT_CODE": "250801", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1587", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1356, "SUB_DISTRICT_CODE": "250802", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1588", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1357, "SUB_DISTRICT_CODE": "250803", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1589", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1358, "SUB_DISTRICT_CODE": "250804", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1590", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1359, "SUB_DISTRICT_CODE": "250805", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1591", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1360, "SUB_DISTRICT_CODE": "250806", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1592", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1361, "SUB_DISTRICT_CODE": "250807", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1593", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1362, "SUB_DISTRICT_CODE": "250808", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1594", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1363, "SUB_DISTRICT_CODE": "250809", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1595", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1364, "SUB_DISTRICT_CODE": "250810", "PROVINCE_ID": "16", "DISTRICT_ID": "196", "SUB_DISTRICT_ID": "1596", "ZIPCODE": "25140" },
    { "ZIPCODE_ID": 1365, "SUB_DISTRICT_CODE": "250901", "PROVINCE_ID": "16", "DISTRICT_ID": "197", "SUB_DISTRICT_ID": "1601", "ZIPCODE": "25190" },
    { "ZIPCODE_ID": 1366, "SUB_DISTRICT_CODE": "250902", "PROVINCE_ID": "16", "DISTRICT_ID": "197", "SUB_DISTRICT_ID": "1602", "ZIPCODE": "25190" },
    { "ZIPCODE_ID": 1367, "SUB_DISTRICT_CODE": "250903", "PROVINCE_ID": "16", "DISTRICT_ID": "197", "SUB_DISTRICT_ID": "1603", "ZIPCODE": "25190" },
    { "ZIPCODE_ID": 1368, "SUB_DISTRICT_CODE": "250904", "PROVINCE_ID": "16", "DISTRICT_ID": "197", "SUB_DISTRICT_ID": "1604", "ZIPCODE": "25190" },
    { "ZIPCODE_ID": 1369, "SUB_DISTRICT_CODE": "260101", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1649", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1370, "SUB_DISTRICT_CODE": "260102", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1650", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1371, "SUB_DISTRICT_CODE": "260103", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1651", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1372, "SUB_DISTRICT_CODE": "260104", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1652", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1373, "SUB_DISTRICT_CODE": "260105", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1653", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1374, "SUB_DISTRICT_CODE": "260106", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1654", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1375, "SUB_DISTRICT_CODE": "260107", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1655", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1376, "SUB_DISTRICT_CODE": "260108", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1656", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1377, "SUB_DISTRICT_CODE": "260109", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1657", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1378, "SUB_DISTRICT_CODE": "260110", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1658", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1379, "SUB_DISTRICT_CODE": "260111", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1659", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1380, "SUB_DISTRICT_CODE": "260112", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1660", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1381, "SUB_DISTRICT_CODE": "260113", "PROVINCE_ID": "17", "DISTRICT_ID": "202", "SUB_DISTRICT_ID": "1661", "ZIPCODE": "26000" },
    { "ZIPCODE_ID": 1382, "SUB_DISTRICT_CODE": "260201", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1662", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1383, "SUB_DISTRICT_CODE": "260202", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1663", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1384, "SUB_DISTRICT_CODE": "260203", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1664", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1385, "SUB_DISTRICT_CODE": "260204", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1665", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1386, "SUB_DISTRICT_CODE": "260205", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1666", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1387, "SUB_DISTRICT_CODE": "260206", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1667", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1388, "SUB_DISTRICT_CODE": "260207", "PROVINCE_ID": "17", "DISTRICT_ID": "203", "SUB_DISTRICT_ID": "1668", "ZIPCODE": "26130" },
    { "ZIPCODE_ID": 1389, "SUB_DISTRICT_CODE": "260301", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1669", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1390, "SUB_DISTRICT_CODE": "260302", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1670", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1391, "SUB_DISTRICT_CODE": "260303", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1671", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1392, "SUB_DISTRICT_CODE": "260304", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1672", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1393, "SUB_DISTRICT_CODE": "260305", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1673", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1394, "SUB_DISTRICT_CODE": "260306", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1674", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1395, "SUB_DISTRICT_CODE": "260307", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1675", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1396, "SUB_DISTRICT_CODE": "260308", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1676", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1397, "SUB_DISTRICT_CODE": "260309", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1677", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1398, "SUB_DISTRICT_CODE": "260310", "PROVINCE_ID": "17", "DISTRICT_ID": "204", "SUB_DISTRICT_ID": "1678", "ZIPCODE": "26110" },
    { "ZIPCODE_ID": 1399, "SUB_DISTRICT_CODE": "260401", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1679", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1400, "SUB_DISTRICT_CODE": "260402", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1680", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1401, "SUB_DISTRICT_CODE": "260403", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1681", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1402, "SUB_DISTRICT_CODE": "260404", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1682", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1403, "SUB_DISTRICT_CODE": "260405", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1683", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1404, "SUB_DISTRICT_CODE": "260406", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1684", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1405, "SUB_DISTRICT_CODE": "260407", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1685", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1406, "SUB_DISTRICT_CODE": "260408", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1686", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1407, "SUB_DISTRICT_CODE": "260409", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1687", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1408, "SUB_DISTRICT_CODE": "260410", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1688", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1409, "SUB_DISTRICT_CODE": "260411", "PROVINCE_ID": "17", "DISTRICT_ID": "205", "SUB_DISTRICT_ID": "1689", "ZIPCODE": "26120" },
    { "ZIPCODE_ID": 1410, "SUB_DISTRICT_CODE": "270101", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1690", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1411, "SUB_DISTRICT_CODE": "270102", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1691", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1412, "SUB_DISTRICT_CODE": "270103", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1692", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1413, "SUB_DISTRICT_CODE": "270104", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1693", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1414, "SUB_DISTRICT_CODE": "270105", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1694", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1415, "SUB_DISTRICT_CODE": "270106", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1695", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1416, "SUB_DISTRICT_CODE": "270108", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1697", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1417, "SUB_DISTRICT_CODE": "270111", "PROVINCE_ID": "18", "DISTRICT_ID": "206", "SUB_DISTRICT_ID": "1700", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1418, "SUB_DISTRICT_CODE": "270201", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1702", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1419, "SUB_DISTRICT_CODE": "270202", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1703", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1420, "SUB_DISTRICT_CODE": "270203", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1704", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1421, "SUB_DISTRICT_CODE": "270204", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1705", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1422, "SUB_DISTRICT_CODE": "270205", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1706", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1423, "SUB_DISTRICT_CODE": "270206", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1707", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1424, "SUB_DISTRICT_CODE": "270207", "PROVINCE_ID": "18", "DISTRICT_ID": "207", "SUB_DISTRICT_ID": "1708", "ZIPCODE": "27260" },
    { "ZIPCODE_ID": 1425, "SUB_DISTRICT_CODE": "270301", "PROVINCE_ID": "18", "DISTRICT_ID": "208", "SUB_DISTRICT_ID": "1709", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1426, "SUB_DISTRICT_CODE": "270302", "PROVINCE_ID": "18", "DISTRICT_ID": "208", "SUB_DISTRICT_ID": "1710", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1427, "SUB_DISTRICT_CODE": "270306", "PROVINCE_ID": "18", "DISTRICT_ID": "208", "SUB_DISTRICT_ID": "1714", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1428, "SUB_DISTRICT_CODE": "270307", "PROVINCE_ID": "18", "DISTRICT_ID": "208", "SUB_DISTRICT_ID": "1715", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1429, "SUB_DISTRICT_CODE": "270309", "PROVINCE_ID": "18", "DISTRICT_ID": "208", "SUB_DISTRICT_ID": "1717", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1430, "SUB_DISTRICT_CODE": "270401", "PROVINCE_ID": "18", "DISTRICT_ID": "209", "SUB_DISTRICT_ID": "1718", "ZIPCODE": "27210" },
    { "ZIPCODE_ID": 1431, "SUB_DISTRICT_CODE": "270403", "PROVINCE_ID": "18", "DISTRICT_ID": "209", "SUB_DISTRICT_ID": "1720", "ZIPCODE": "27210" },
    { "ZIPCODE_ID": 1432, "SUB_DISTRICT_CODE": "270405", "PROVINCE_ID": "18", "DISTRICT_ID": "209", "SUB_DISTRICT_ID": "1722", "ZIPCODE": "27210" },
    { "ZIPCODE_ID": 1433, "SUB_DISTRICT_CODE": "270406", "PROVINCE_ID": "18", "DISTRICT_ID": "209", "SUB_DISTRICT_ID": "1723", "ZIPCODE": "27210" },
    { "ZIPCODE_ID": 1434, "SUB_DISTRICT_CODE": "270501", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1725", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1435, "SUB_DISTRICT_CODE": "270502", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1726", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1436, "SUB_DISTRICT_CODE": "270503", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1727", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1437, "SUB_DISTRICT_CODE": "270504", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1728", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1438, "SUB_DISTRICT_CODE": "270505", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1729", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1439, "SUB_DISTRICT_CODE": "270506", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1730", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1440, "SUB_DISTRICT_CODE": "270507", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1731", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1441, "SUB_DISTRICT_CODE": "270508", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1732", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1442, "SUB_DISTRICT_CODE": "270509", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1733", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1443, "SUB_DISTRICT_CODE": "270510", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1734", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1444, "SUB_DISTRICT_CODE": "270511", "PROVINCE_ID": "18", "DISTRICT_ID": "210", "SUB_DISTRICT_ID": "1735", "ZIPCODE": "27160" },
    { "ZIPCODE_ID": 1445, "SUB_DISTRICT_CODE": "270601", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1736", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1446, "SUB_DISTRICT_CODE": "270602", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1737", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1447, "SUB_DISTRICT_CODE": "270603", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1738", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1448, "SUB_DISTRICT_CODE": "270604", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1739", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1449, "SUB_DISTRICT_CODE": "270605", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1740", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1450, "SUB_DISTRICT_CODE": "270606", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1741", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1451, "SUB_DISTRICT_CODE": "270607", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1742", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1452, "SUB_DISTRICT_CODE": "270608", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1743", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1453, "SUB_DISTRICT_CODE": "270609", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1744", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1454, "SUB_DISTRICT_CODE": "270610", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1745", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1455, "SUB_DISTRICT_CODE": "270611", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1746", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1456, "SUB_DISTRICT_CODE": "270612", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1747", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1457, "SUB_DISTRICT_CODE": "270613", "PROVINCE_ID": "18", "DISTRICT_ID": "211", "SUB_DISTRICT_ID": "1748", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1458, "SUB_DISTRICT_CODE": "270701", "PROVINCE_ID": "18", "DISTRICT_ID": "212", "SUB_DISTRICT_ID": "1749", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1459, "SUB_DISTRICT_CODE": "270702", "PROVINCE_ID": "18", "DISTRICT_ID": "212", "SUB_DISTRICT_ID": "1750", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1460, "SUB_DISTRICT_CODE": "270703", "PROVINCE_ID": "18", "DISTRICT_ID": "212", "SUB_DISTRICT_ID": "1751", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1461, "SUB_DISTRICT_CODE": "270704", "PROVINCE_ID": "18", "DISTRICT_ID": "212", "SUB_DISTRICT_ID": "1752", "ZIPCODE": "27000" },
    { "ZIPCODE_ID": 1462, "SUB_DISTRICT_CODE": "270801", "PROVINCE_ID": "18", "DISTRICT_ID": "213", "SUB_DISTRICT_ID": "1753", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1463, "SUB_DISTRICT_CODE": "270802", "PROVINCE_ID": "18", "DISTRICT_ID": "213", "SUB_DISTRICT_ID": "1754", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1464, "SUB_DISTRICT_CODE": "270803", "PROVINCE_ID": "18", "DISTRICT_ID": "213", "SUB_DISTRICT_ID": "1755", "ZIPCODE": "27180" },
    { "ZIPCODE_ID": 1465, "SUB_DISTRICT_CODE": "270804", "PROVINCE_ID": "18", "DISTRICT_ID": "213", "SUB_DISTRICT_ID": "1756", "ZIPCODE": "27120" },
    { "ZIPCODE_ID": 1466, "SUB_DISTRICT_CODE": "270901", "PROVINCE_ID": "18", "DISTRICT_ID": "214", "SUB_DISTRICT_ID": "1757", "ZIPCODE": "27250" },
    { "ZIPCODE_ID": 1467, "SUB_DISTRICT_CODE": "270902", "PROVINCE_ID": "18", "DISTRICT_ID": "214", "SUB_DISTRICT_ID": "1758", "ZIPCODE": "27250" },
    { "ZIPCODE_ID": 1468, "SUB_DISTRICT_CODE": "270903", "PROVINCE_ID": "18", "DISTRICT_ID": "214", "SUB_DISTRICT_ID": "1759", "ZIPCODE": "27250" },
    { "ZIPCODE_ID": 1469, "SUB_DISTRICT_CODE": "300101", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1760", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1470, "SUB_DISTRICT_CODE": "300102", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1761", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1471, "SUB_DISTRICT_CODE": "300103", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1762", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1472, "SUB_DISTRICT_CODE": "300104", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1763", "ZIPCODE": "30310" },
    { "ZIPCODE_ID": 1473, "SUB_DISTRICT_CODE": "300105", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1764", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1474, "SUB_DISTRICT_CODE": "300106", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1765", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1475, "SUB_DISTRICT_CODE": "300107", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1766", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1476, "SUB_DISTRICT_CODE": "300108", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1767", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1477, "SUB_DISTRICT_CODE": "300109", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1768", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1478, "SUB_DISTRICT_CODE": "300110", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1769", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1479, "SUB_DISTRICT_CODE": "300111", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1770", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1480, "SUB_DISTRICT_CODE": "300112", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1771", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1481, "SUB_DISTRICT_CODE": "300113", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1772", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1482, "SUB_DISTRICT_CODE": "300114", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1773", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1483, "SUB_DISTRICT_CODE": "300115", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1774", "ZIPCODE": "30310" },
    { "ZIPCODE_ID": 1484, "SUB_DISTRICT_CODE": "300116", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1775", "ZIPCODE": "30310" },
    { "ZIPCODE_ID": 1485, "SUB_DISTRICT_CODE": "300117", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1776", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1486, "SUB_DISTRICT_CODE": "300118", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1777", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1487, "SUB_DISTRICT_CODE": "300119", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1778", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1488, "SUB_DISTRICT_CODE": "300120", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1779", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1489, "SUB_DISTRICT_CODE": "300121", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1780", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1490, "SUB_DISTRICT_CODE": "300122", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1781", "ZIPCODE": "30310" },
    { "ZIPCODE_ID": 1491, "SUB_DISTRICT_CODE": "300123", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1782", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1492, "SUB_DISTRICT_CODE": "300124", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1783", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1493, "SUB_DISTRICT_CODE": "300125", "PROVINCE_ID": "19", "DISTRICT_ID": "215", "SUB_DISTRICT_ID": "1784", "ZIPCODE": "30310" },
    { "ZIPCODE_ID": 1494, "SUB_DISTRICT_CODE": "300201", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1785", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1495, "SUB_DISTRICT_CODE": "300202", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1786", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1496, "SUB_DISTRICT_CODE": "300203", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1787", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1497, "SUB_DISTRICT_CODE": "300204", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1788", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1498, "SUB_DISTRICT_CODE": "300205", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1789", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1499, "SUB_DISTRICT_CODE": "300206", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1790", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1500, "SUB_DISTRICT_CODE": "300207", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1791", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1501, "SUB_DISTRICT_CODE": "300208", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1792", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1502, "SUB_DISTRICT_CODE": "300209", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1793", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1503, "SUB_DISTRICT_CODE": "300210", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1794", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1504, "SUB_DISTRICT_CODE": "300211", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1795", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1505, "SUB_DISTRICT_CODE": "300212", "PROVINCE_ID": "19", "DISTRICT_ID": "216", "SUB_DISTRICT_ID": "1796", "ZIPCODE": "30250" },
    { "ZIPCODE_ID": 1506, "SUB_DISTRICT_CODE": "300301", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1797", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1507, "SUB_DISTRICT_CODE": "300302", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1798", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1508, "SUB_DISTRICT_CODE": "300303", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1799", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1509, "SUB_DISTRICT_CODE": "300304", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1800", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1510, "SUB_DISTRICT_CODE": "300305", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1801", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1511, "SUB_DISTRICT_CODE": "300306", "PROVINCE_ID": "19", "DISTRICT_ID": "217", "SUB_DISTRICT_ID": "1802", "ZIPCODE": "30330" },
    { "ZIPCODE_ID": 1512, "SUB_DISTRICT_CODE": "300401", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1803", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1513, "SUB_DISTRICT_CODE": "300402", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1804", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1514, "SUB_DISTRICT_CODE": "300403", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1805", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1515, "SUB_DISTRICT_CODE": "300404", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1806", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1516, "SUB_DISTRICT_CODE": "300405", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1807", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1517, "SUB_DISTRICT_CODE": "300406", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1808", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1518, "SUB_DISTRICT_CODE": "300407", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1809", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1519, "SUB_DISTRICT_CODE": "300408", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1810", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1520, "SUB_DISTRICT_CODE": "300409", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1811", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1521, "SUB_DISTRICT_CODE": "300410", "PROVINCE_ID": "19", "DISTRICT_ID": "218", "SUB_DISTRICT_ID": "1812", "ZIPCODE": "30260" },
    { "ZIPCODE_ID": 1522, "SUB_DISTRICT_CODE": "300501", "PROVINCE_ID": "19", "DISTRICT_ID": "219", "SUB_DISTRICT_ID": "1813", "ZIPCODE": "30350" },
    { "ZIPCODE_ID": 1523, "SUB_DISTRICT_CODE": "300502", "PROVINCE_ID": "19", "DISTRICT_ID": "219", "SUB_DISTRICT_ID": "1814", "ZIPCODE": "30350" },
    { "ZIPCODE_ID": 1524, "SUB_DISTRICT_CODE": "300503", "PROVINCE_ID": "19", "DISTRICT_ID": "219", "SUB_DISTRICT_ID": "1815", "ZIPCODE": "30350" },
    { "ZIPCODE_ID": 1525, "SUB_DISTRICT_CODE": "300504", "PROVINCE_ID": "19", "DISTRICT_ID": "219", "SUB_DISTRICT_ID": "1816", "ZIPCODE": "30350" },
    { "ZIPCODE_ID": 1526, "SUB_DISTRICT_CODE": "300601", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1817", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1527, "SUB_DISTRICT_CODE": "300602", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1818", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1528, "SUB_DISTRICT_CODE": "300603", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1819", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1529, "SUB_DISTRICT_CODE": "300604", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1820", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1530, "SUB_DISTRICT_CODE": "300605", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1821", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1531, "SUB_DISTRICT_CODE": "300606", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1822", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1532, "SUB_DISTRICT_CODE": "300607", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1823", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1533, "SUB_DISTRICT_CODE": "300608", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1824", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1534, "SUB_DISTRICT_CODE": "300609", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1825", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1535, "SUB_DISTRICT_CODE": "300610", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1826", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1536, "SUB_DISTRICT_CODE": "300611", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1827", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1537, "SUB_DISTRICT_CODE": "300612", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1828", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1538, "SUB_DISTRICT_CODE": "300613", "PROVINCE_ID": "19", "DISTRICT_ID": "220", "SUB_DISTRICT_ID": "1829", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1539, "SUB_DISTRICT_CODE": "300701", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1830", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1540, "SUB_DISTRICT_CODE": "300702", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1831", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1541, "SUB_DISTRICT_CODE": "300703", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1832", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1542, "SUB_DISTRICT_CODE": "300704", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1833", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1543, "SUB_DISTRICT_CODE": "300705", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1834", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1544, "SUB_DISTRICT_CODE": "300706", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1835", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1545, "SUB_DISTRICT_CODE": "300707", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1836", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1546, "SUB_DISTRICT_CODE": "300708", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1837", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1547, "SUB_DISTRICT_CODE": "300709", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1838", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1548, "SUB_DISTRICT_CODE": "300710", "PROVINCE_ID": "19", "DISTRICT_ID": "221", "SUB_DISTRICT_ID": "1839", "ZIPCODE": "30190" },
    { "ZIPCODE_ID": 1549, "SUB_DISTRICT_CODE": "300801", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1840", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1550, "SUB_DISTRICT_CODE": "300802", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1841", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1551, "SUB_DISTRICT_CODE": "300803", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1842", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1552, "SUB_DISTRICT_CODE": "300804", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1843", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1553, "SUB_DISTRICT_CODE": "300805", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1844", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1554, "SUB_DISTRICT_CODE": "300806", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1845", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1555, "SUB_DISTRICT_CODE": "300807", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1846", "ZIPCODE": "36220" },
    { "ZIPCODE_ID": 1556, "SUB_DISTRICT_CODE": "300808", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1847", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1557, "SUB_DISTRICT_CODE": "300809", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1848", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1558, "SUB_DISTRICT_CODE": "300810", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1849", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1559, "SUB_DISTRICT_CODE": "300811", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1850", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1560, "SUB_DISTRICT_CODE": "300812", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1851", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1561, "SUB_DISTRICT_CODE": "300813", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1852", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1562, "SUB_DISTRICT_CODE": "300815", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1854", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1563, "SUB_DISTRICT_CODE": "300817", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1856", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1564, "SUB_DISTRICT_CODE": "300818", "PROVINCE_ID": "19", "DISTRICT_ID": "222", "SUB_DISTRICT_ID": "1857", "ZIPCODE": "36220" },
    { "ZIPCODE_ID": 1565, "SUB_DISTRICT_CODE": "300901", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1859", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1566, "SUB_DISTRICT_CODE": "300902", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1860", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1567, "SUB_DISTRICT_CODE": "300903", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1861", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1568, "SUB_DISTRICT_CODE": "300904", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1862", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1569, "SUB_DISTRICT_CODE": "300905", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1863", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1570, "SUB_DISTRICT_CODE": "300906", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1864", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1571, "SUB_DISTRICT_CODE": "300907", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1865", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1572, "SUB_DISTRICT_CODE": "300908", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1866", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1573, "SUB_DISTRICT_CODE": "300909", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1867", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1574, "SUB_DISTRICT_CODE": "300910", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1868", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1575, "SUB_DISTRICT_CODE": "300911", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1869", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1576, "SUB_DISTRICT_CODE": "300912", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1870", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1577, "SUB_DISTRICT_CODE": "300913", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1871", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1578, "SUB_DISTRICT_CODE": "300914", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1872", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1579, "SUB_DISTRICT_CODE": "300915", "PROVINCE_ID": "19", "DISTRICT_ID": "223", "SUB_DISTRICT_ID": "1873", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1580, "SUB_DISTRICT_CODE": "301001", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1874", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1581, "SUB_DISTRICT_CODE": "301002", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1875", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1582, "SUB_DISTRICT_CODE": "301003", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1876", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1583, "SUB_DISTRICT_CODE": "301004", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1877", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1584, "SUB_DISTRICT_CODE": "301005", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1878", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1585, "SUB_DISTRICT_CODE": "301006", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1879", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1586, "SUB_DISTRICT_CODE": "301007", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1880", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1587, "SUB_DISTRICT_CODE": "301008", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1881", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1588, "SUB_DISTRICT_CODE": "301009", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1882", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1589, "SUB_DISTRICT_CODE": "301010", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1883", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1590, "SUB_DISTRICT_CODE": "301011", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1884", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1591, "SUB_DISTRICT_CODE": "301012", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1885", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1592, "SUB_DISTRICT_CODE": "301013", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1886", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1593, "SUB_DISTRICT_CODE": "301014", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1887", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1594, "SUB_DISTRICT_CODE": "301015", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1888", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1595, "SUB_DISTRICT_CODE": "301016", "PROVINCE_ID": "19", "DISTRICT_ID": "224", "SUB_DISTRICT_ID": "1889", "ZIPCODE": "30160" },
    { "ZIPCODE_ID": 1596, "SUB_DISTRICT_CODE": "301101", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1890", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1597, "SUB_DISTRICT_CODE": "301102", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1891", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1598, "SUB_DISTRICT_CODE": "301103", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1892", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1599, "SUB_DISTRICT_CODE": "301104", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1893", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1600, "SUB_DISTRICT_CODE": "301105", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1894", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1601, "SUB_DISTRICT_CODE": "301106", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1895", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1602, "SUB_DISTRICT_CODE": "301107", "PROVINCE_ID": "19", "DISTRICT_ID": "225", "SUB_DISTRICT_ID": "1896", "ZIPCODE": "30290" },
    { "ZIPCODE_ID": 1603, "SUB_DISTRICT_CODE": "301201", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1897", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1604, "SUB_DISTRICT_CODE": "301203", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1898", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1605, "SUB_DISTRICT_CODE": "301204", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1899", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1606, "SUB_DISTRICT_CODE": "301206", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1901", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1607, "SUB_DISTRICT_CODE": "301207", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1902", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1608, "SUB_DISTRICT_CODE": "301208", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1903", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1609, "SUB_DISTRICT_CODE": "301209", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1904", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1610, "SUB_DISTRICT_CODE": "301210", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1905", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1611, "SUB_DISTRICT_CODE": "301211", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1906", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1612, "SUB_DISTRICT_CODE": "301212", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1907", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1613, "SUB_DISTRICT_CODE": "301214", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1909", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1614, "SUB_DISTRICT_CODE": "301215", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1910", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1615, "SUB_DISTRICT_CODE": "301216", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1911", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1616, "SUB_DISTRICT_CODE": "301218", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1913", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1617, "SUB_DISTRICT_CODE": "301220", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1915", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1618, "SUB_DISTRICT_CODE": "301221", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1916", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1619, "SUB_DISTRICT_CODE": "301222", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1917", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1620, "SUB_DISTRICT_CODE": "301223", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1918", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1621, "SUB_DISTRICT_CODE": "301224", "PROVINCE_ID": "19", "DISTRICT_ID": "226", "SUB_DISTRICT_ID": "1919", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1622, "SUB_DISTRICT_CODE": "301301", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1920", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1623, "SUB_DISTRICT_CODE": "301303", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1922", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1624, "SUB_DISTRICT_CODE": "301304", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1923", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1625, "SUB_DISTRICT_CODE": "301306", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1925", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1626, "SUB_DISTRICT_CODE": "301307", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1926", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1627, "SUB_DISTRICT_CODE": "301308", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1927", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1628, "SUB_DISTRICT_CODE": "301309", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1928", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1629, "SUB_DISTRICT_CODE": "301310", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1929", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1630, "SUB_DISTRICT_CODE": "301313", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1932", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1631, "SUB_DISTRICT_CODE": "301314", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1933", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1632, "SUB_DISTRICT_CODE": "301315", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1934", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1633, "SUB_DISTRICT_CODE": "301317", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1936", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1634, "SUB_DISTRICT_CODE": "301318", "PROVINCE_ID": "19", "DISTRICT_ID": "227", "SUB_DISTRICT_ID": "1937", "ZIPCODE": "30180" },
    { "ZIPCODE_ID": 1635, "SUB_DISTRICT_CODE": "301401", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1938", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1636, "SUB_DISTRICT_CODE": "301402", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1939", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1637, "SUB_DISTRICT_CODE": "301403", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1940", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1638, "SUB_DISTRICT_CODE": "301404", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1941", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1639, "SUB_DISTRICT_CODE": "301405", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1942", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1640, "SUB_DISTRICT_CODE": "301406", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1943", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1641, "SUB_DISTRICT_CODE": "301407", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1944", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1642, "SUB_DISTRICT_CODE": "301409", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1946", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1643, "SUB_DISTRICT_CODE": "301410", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1947", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1644, "SUB_DISTRICT_CODE": "301411", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1948", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1645, "SUB_DISTRICT_CODE": "301412", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1949", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1646, "SUB_DISTRICT_CODE": "301416", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1953", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1647, "SUB_DISTRICT_CODE": "301417", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1954", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1648, "SUB_DISTRICT_CODE": "301418", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1955", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1649, "SUB_DISTRICT_CODE": "301419", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1956", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1650, "SUB_DISTRICT_CODE": "301420", "PROVINCE_ID": "19", "DISTRICT_ID": "228", "SUB_DISTRICT_ID": "1957", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1651, "SUB_DISTRICT_CODE": "301501", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1958", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1652, "SUB_DISTRICT_CODE": "301502", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1959", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1653, "SUB_DISTRICT_CODE": "301503", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1960", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1654, "SUB_DISTRICT_CODE": "301504", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1961", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1655, "SUB_DISTRICT_CODE": "301505", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1962", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1656, "SUB_DISTRICT_CODE": "301506", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1963", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1657, "SUB_DISTRICT_CODE": "301507", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1964", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1658, "SUB_DISTRICT_CODE": "301508", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1965", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1659, "SUB_DISTRICT_CODE": "301509", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1966", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1660, "SUB_DISTRICT_CODE": "301510", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1967", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1661, "SUB_DISTRICT_CODE": "301511", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1968", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1662, "SUB_DISTRICT_CODE": "301512", "PROVINCE_ID": "19", "DISTRICT_ID": "229", "SUB_DISTRICT_ID": "1969", "ZIPCODE": "30110" },
    { "ZIPCODE_ID": 1663, "SUB_DISTRICT_CODE": "301601", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1970", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1664, "SUB_DISTRICT_CODE": "301602", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1971", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1665, "SUB_DISTRICT_CODE": "301603", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1972", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1666, "SUB_DISTRICT_CODE": "301604", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1973", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1667, "SUB_DISTRICT_CODE": "301605", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1974", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1668, "SUB_DISTRICT_CODE": "301606", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1975", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1669, "SUB_DISTRICT_CODE": "301607", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1976", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1670, "SUB_DISTRICT_CODE": "301608", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1977", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1671, "SUB_DISTRICT_CODE": "301609", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1978", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1672, "SUB_DISTRICT_CODE": "301610", "PROVINCE_ID": "19", "DISTRICT_ID": "230", "SUB_DISTRICT_ID": "1979", "ZIPCODE": "30240" },
    { "ZIPCODE_ID": 1673, "SUB_DISTRICT_CODE": "301701", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1980", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1674, "SUB_DISTRICT_CODE": "301702", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1981", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1675, "SUB_DISTRICT_CODE": "301703", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1982", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1676, "SUB_DISTRICT_CODE": "301704", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1983", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1677, "SUB_DISTRICT_CODE": "301705", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1984", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1678, "SUB_DISTRICT_CODE": "301706", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1985", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1679, "SUB_DISTRICT_CODE": "301707", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1986", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1680, "SUB_DISTRICT_CODE": "301710", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1989", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1681, "SUB_DISTRICT_CODE": "301711", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1990", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1682, "SUB_DISTRICT_CODE": "301714", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1993", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1683, "SUB_DISTRICT_CODE": "301715", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1994", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1684, "SUB_DISTRICT_CODE": "301716", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1995", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1685, "SUB_DISTRICT_CODE": "301717", "PROVINCE_ID": "19", "DISTRICT_ID": "231", "SUB_DISTRICT_ID": "1996", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1686, "SUB_DISTRICT_CODE": "301801", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "1997", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1687, "SUB_DISTRICT_CODE": "301802", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "1998", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1688, "SUB_DISTRICT_CODE": "301803", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "1999", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1689, "SUB_DISTRICT_CODE": "301804", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2000", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1690, "SUB_DISTRICT_CODE": "301805", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2001", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1691, "SUB_DISTRICT_CODE": "301806", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2002", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1692, "SUB_DISTRICT_CODE": "301807", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2003", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1693, "SUB_DISTRICT_CODE": "301808", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2004", "ZIPCODE": "30170" },
    { "ZIPCODE_ID": 1694, "SUB_DISTRICT_CODE": "301809", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2005", "ZIPCODE": "30380" },
    { "ZIPCODE_ID": 1695, "SUB_DISTRICT_CODE": "301810", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2006", "ZIPCODE": "30380" },
    { "ZIPCODE_ID": 1696, "SUB_DISTRICT_CODE": "301811", "PROVINCE_ID": "19", "DISTRICT_ID": "232", "SUB_DISTRICT_ID": "2007", "ZIPCODE": "30380" },
    { "ZIPCODE_ID": 1697, "SUB_DISTRICT_CODE": "301901", "PROVINCE_ID": "19", "DISTRICT_ID": "233", "SUB_DISTRICT_ID": "2008", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1698, "SUB_DISTRICT_CODE": "301902", "PROVINCE_ID": "19", "DISTRICT_ID": "233", "SUB_DISTRICT_ID": "2009", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1699, "SUB_DISTRICT_CODE": "301903", "PROVINCE_ID": "19", "DISTRICT_ID": "233", "SUB_DISTRICT_ID": "2010", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1700, "SUB_DISTRICT_CODE": "301904", "PROVINCE_ID": "19", "DISTRICT_ID": "233", "SUB_DISTRICT_ID": "2011", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1701, "SUB_DISTRICT_CODE": "301905", "PROVINCE_ID": "19", "DISTRICT_ID": "233", "SUB_DISTRICT_ID": "2012", "ZIPCODE": "30280" },
    { "ZIPCODE_ID": 1702, "SUB_DISTRICT_CODE": "302001", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2013", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1703, "SUB_DISTRICT_CODE": "302002", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2014", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1704, "SUB_DISTRICT_CODE": "302003", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2015", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1705, "SUB_DISTRICT_CODE": "302004", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2016", "ZIPCODE": "30340" },
    { "ZIPCODE_ID": 1706, "SUB_DISTRICT_CODE": "302005", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2017", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1707, "SUB_DISTRICT_CODE": "302006", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2018", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1708, "SUB_DISTRICT_CODE": "302007", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2019", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1709, "SUB_DISTRICT_CODE": "302008", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2020", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1710, "SUB_DISTRICT_CODE": "302009", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2021", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1711, "SUB_DISTRICT_CODE": "302010", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2022", "ZIPCODE": "30340" },
    { "ZIPCODE_ID": 1712, "SUB_DISTRICT_CODE": "302011", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2023", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1713, "SUB_DISTRICT_CODE": "302012", "PROVINCE_ID": "19", "DISTRICT_ID": "234", "SUB_DISTRICT_ID": "2024", "ZIPCODE": "30140" },
    { "ZIPCODE_ID": 1714, "SUB_DISTRICT_CODE": "302101", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2025", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1715, "SUB_DISTRICT_CODE": "302102", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2026", "ZIPCODE": "30320" },
    { "ZIPCODE_ID": 1716, "SUB_DISTRICT_CODE": "302103", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2027", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1717, "SUB_DISTRICT_CODE": "302104", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2028", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1718, "SUB_DISTRICT_CODE": "302105", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2029", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1719, "SUB_DISTRICT_CODE": "302106", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2030", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1720, "SUB_DISTRICT_CODE": "302107", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2031", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1721, "SUB_DISTRICT_CODE": "302108", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2032", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1722, "SUB_DISTRICT_CODE": "302109", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2033", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1723, "SUB_DISTRICT_CODE": "302110", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2034", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1724, "SUB_DISTRICT_CODE": "302111", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2035", "ZIPCODE": "30130" },
    { "ZIPCODE_ID": 1725, "SUB_DISTRICT_CODE": "302112", "PROVINCE_ID": "19", "DISTRICT_ID": "235", "SUB_DISTRICT_ID": "2036", "ZIPCODE": "30320" },
    { "ZIPCODE_ID": 1726, "SUB_DISTRICT_CODE": "302201", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2037", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1727, "SUB_DISTRICT_CODE": "302202", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2038", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1728, "SUB_DISTRICT_CODE": "302203", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2039", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1729, "SUB_DISTRICT_CODE": "302204", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2040", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1730, "SUB_DISTRICT_CODE": "302205", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2041", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1731, "SUB_DISTRICT_CODE": "302206", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2042", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1732, "SUB_DISTRICT_CODE": "302207", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2043", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1733, "SUB_DISTRICT_CODE": "302208", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2044", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1734, "SUB_DISTRICT_CODE": "302209", "PROVINCE_ID": "19", "DISTRICT_ID": "236", "SUB_DISTRICT_ID": "2045", "ZIPCODE": "30410" },
    { "ZIPCODE_ID": 1735, "SUB_DISTRICT_CODE": "302301", "PROVINCE_ID": "19", "DISTRICT_ID": "237", "SUB_DISTRICT_ID": "2046", "ZIPCODE": "30440" },
    { "ZIPCODE_ID": 1736, "SUB_DISTRICT_CODE": "302302", "PROVINCE_ID": "19", "DISTRICT_ID": "237", "SUB_DISTRICT_ID": "2047", "ZIPCODE": "30440" },
    { "ZIPCODE_ID": 1737, "SUB_DISTRICT_CODE": "302303", "PROVINCE_ID": "19", "DISTRICT_ID": "237", "SUB_DISTRICT_ID": "2048", "ZIPCODE": "30440" },
    { "ZIPCODE_ID": 1738, "SUB_DISTRICT_CODE": "302304", "PROVINCE_ID": "19", "DISTRICT_ID": "237", "SUB_DISTRICT_ID": "2049", "ZIPCODE": "30440" },
    { "ZIPCODE_ID": 1739, "SUB_DISTRICT_CODE": "302305", "PROVINCE_ID": "19", "DISTRICT_ID": "237", "SUB_DISTRICT_ID": "2050", "ZIPCODE": "30440" },
    { "ZIPCODE_ID": 1740, "SUB_DISTRICT_CODE": "302401", "PROVINCE_ID": "19", "DISTRICT_ID": "238", "SUB_DISTRICT_ID": "2051", "ZIPCODE": "30360" },
    { "ZIPCODE_ID": 1741, "SUB_DISTRICT_CODE": "302402", "PROVINCE_ID": "19", "DISTRICT_ID": "238", "SUB_DISTRICT_ID": "2052", "ZIPCODE": "30360" },
    { "ZIPCODE_ID": 1742, "SUB_DISTRICT_CODE": "302403", "PROVINCE_ID": "19", "DISTRICT_ID": "238", "SUB_DISTRICT_ID": "2053", "ZIPCODE": "30360" },
    { "ZIPCODE_ID": 1743, "SUB_DISTRICT_CODE": "302404", "PROVINCE_ID": "19", "DISTRICT_ID": "238", "SUB_DISTRICT_ID": "2054", "ZIPCODE": "30360" },
    { "ZIPCODE_ID": 1744, "SUB_DISTRICT_CODE": "302405", "PROVINCE_ID": "19", "DISTRICT_ID": "238", "SUB_DISTRICT_ID": "2055", "ZIPCODE": "30360" },
    { "ZIPCODE_ID": 1745, "SUB_DISTRICT_CODE": "302501", "PROVINCE_ID": "19", "DISTRICT_ID": "239", "SUB_DISTRICT_ID": "2056", "ZIPCODE": "30370" },
    { "ZIPCODE_ID": 1746, "SUB_DISTRICT_CODE": "302502", "PROVINCE_ID": "19", "DISTRICT_ID": "239", "SUB_DISTRICT_ID": "2057", "ZIPCODE": "30370" },
    { "ZIPCODE_ID": 1747, "SUB_DISTRICT_CODE": "302503", "PROVINCE_ID": "19", "DISTRICT_ID": "239", "SUB_DISTRICT_ID": "2058", "ZIPCODE": "30150" },
    { "ZIPCODE_ID": 1748, "SUB_DISTRICT_CODE": "302504", "PROVINCE_ID": "19", "DISTRICT_ID": "239", "SUB_DISTRICT_ID": "2059", "ZIPCODE": "30370" },
    { "ZIPCODE_ID": 1749, "SUB_DISTRICT_CODE": "302505", "PROVINCE_ID": "19", "DISTRICT_ID": "239", "SUB_DISTRICT_ID": "2060", "ZIPCODE": "30370" },
    { "ZIPCODE_ID": 1750, "SUB_DISTRICT_CODE": "302601", "PROVINCE_ID": "19", "DISTRICT_ID": "240", "SUB_DISTRICT_ID": "2061", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1751, "SUB_DISTRICT_CODE": "302602", "PROVINCE_ID": "19", "DISTRICT_ID": "240", "SUB_DISTRICT_ID": "2062", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1752, "SUB_DISTRICT_CODE": "302603", "PROVINCE_ID": "19", "DISTRICT_ID": "240", "SUB_DISTRICT_ID": "2063", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1753, "SUB_DISTRICT_CODE": "302604", "PROVINCE_ID": "19", "DISTRICT_ID": "240", "SUB_DISTRICT_ID": "2064", "ZIPCODE": "30210" },
    { "ZIPCODE_ID": 1754, "SUB_DISTRICT_CODE": "302701", "PROVINCE_ID": "19", "DISTRICT_ID": "241", "SUB_DISTRICT_ID": "2065", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1755, "SUB_DISTRICT_CODE": "302702", "PROVINCE_ID": "19", "DISTRICT_ID": "241", "SUB_DISTRICT_ID": "2066", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1756, "SUB_DISTRICT_CODE": "302703", "PROVINCE_ID": "19", "DISTRICT_ID": "241", "SUB_DISTRICT_ID": "2067", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1757, "SUB_DISTRICT_CODE": "302704", "PROVINCE_ID": "19", "DISTRICT_ID": "241", "SUB_DISTRICT_ID": "2068", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1758, "SUB_DISTRICT_CODE": "302801", "PROVINCE_ID": "19", "DISTRICT_ID": "242", "SUB_DISTRICT_ID": "2069", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1759, "SUB_DISTRICT_CODE": "302802", "PROVINCE_ID": "19", "DISTRICT_ID": "242", "SUB_DISTRICT_ID": "2070", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1760, "SUB_DISTRICT_CODE": "302803", "PROVINCE_ID": "19", "DISTRICT_ID": "242", "SUB_DISTRICT_ID": "2071", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1761, "SUB_DISTRICT_CODE": "302804", "PROVINCE_ID": "19", "DISTRICT_ID": "242", "SUB_DISTRICT_ID": "2072", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1762, "SUB_DISTRICT_CODE": "302805", "PROVINCE_ID": "19", "DISTRICT_ID": "242", "SUB_DISTRICT_ID": "2073", "ZIPCODE": "30220" },
    { "ZIPCODE_ID": 1763, "SUB_DISTRICT_CODE": "302901", "PROVINCE_ID": "19", "DISTRICT_ID": "243", "SUB_DISTRICT_ID": "2074", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1764, "SUB_DISTRICT_CODE": "302902", "PROVINCE_ID": "19", "DISTRICT_ID": "243", "SUB_DISTRICT_ID": "2075", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1765, "SUB_DISTRICT_CODE": "302903", "PROVINCE_ID": "19", "DISTRICT_ID": "243", "SUB_DISTRICT_ID": "2076", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1766, "SUB_DISTRICT_CODE": "302904", "PROVINCE_ID": "19", "DISTRICT_ID": "243", "SUB_DISTRICT_ID": "2077", "ZIPCODE": "30270" },
    { "ZIPCODE_ID": 1767, "SUB_DISTRICT_CODE": "303001", "PROVINCE_ID": "19", "DISTRICT_ID": "244", "SUB_DISTRICT_ID": "2078", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1768, "SUB_DISTRICT_CODE": "303002", "PROVINCE_ID": "19", "DISTRICT_ID": "244", "SUB_DISTRICT_ID": "2079", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1769, "SUB_DISTRICT_CODE": "303003", "PROVINCE_ID": "19", "DISTRICT_ID": "244", "SUB_DISTRICT_ID": "2080", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1770, "SUB_DISTRICT_CODE": "303004", "PROVINCE_ID": "19", "DISTRICT_ID": "244", "SUB_DISTRICT_ID": "2081", "ZIPCODE": "30120" },
    { "ZIPCODE_ID": 1771, "SUB_DISTRICT_CODE": "303101", "PROVINCE_ID": "19", "DISTRICT_ID": "245", "SUB_DISTRICT_ID": "2082", "ZIPCODE": "30430" },
    { "ZIPCODE_ID": 1772, "SUB_DISTRICT_CODE": "303102", "PROVINCE_ID": "19", "DISTRICT_ID": "245", "SUB_DISTRICT_ID": "2083", "ZIPCODE": "30430" },
    { "ZIPCODE_ID": 1773, "SUB_DISTRICT_CODE": "303103", "PROVINCE_ID": "19", "DISTRICT_ID": "245", "SUB_DISTRICT_ID": "2084", "ZIPCODE": "30430" },
    { "ZIPCODE_ID": 1774, "SUB_DISTRICT_CODE": "303104", "PROVINCE_ID": "19", "DISTRICT_ID": "245", "SUB_DISTRICT_ID": "2085", "ZIPCODE": "30430" },
    { "ZIPCODE_ID": 1775, "SUB_DISTRICT_CODE": "303105", "PROVINCE_ID": "19", "DISTRICT_ID": "245", "SUB_DISTRICT_ID": "2086", "ZIPCODE": "30430" },
    { "ZIPCODE_ID": 1776, "SUB_DISTRICT_CODE": "303201", "PROVINCE_ID": "19", "DISTRICT_ID": "246", "SUB_DISTRICT_ID": "2087", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1777, "SUB_DISTRICT_CODE": "303202", "PROVINCE_ID": "19", "DISTRICT_ID": "246", "SUB_DISTRICT_ID": "2088", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1778, "SUB_DISTRICT_CODE": "303203", "PROVINCE_ID": "19", "DISTRICT_ID": "246", "SUB_DISTRICT_ID": "2089", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1779, "SUB_DISTRICT_CODE": "303204", "PROVINCE_ID": "19", "DISTRICT_ID": "246", "SUB_DISTRICT_ID": "2090", "ZIPCODE": "30000" },
    { "ZIPCODE_ID": 1780, "SUB_DISTRICT_CODE": "303205", "PROVINCE_ID": "19", "DISTRICT_ID": "246", "SUB_DISTRICT_ID": "2091", "ZIPCODE": "30230" },
    { "ZIPCODE_ID": 1781, "SUB_DISTRICT_CODE": "310101", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2092", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1782, "SUB_DISTRICT_CODE": "310102", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2093", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1783, "SUB_DISTRICT_CODE": "310103", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2094", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1784, "SUB_DISTRICT_CODE": "310104", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2095", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1785, "SUB_DISTRICT_CODE": "310105", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2096", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1786, "SUB_DISTRICT_CODE": "310106", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2097", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1787, "SUB_DISTRICT_CODE": "310108", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2099", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1788, "SUB_DISTRICT_CODE": "310112", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2103", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1789, "SUB_DISTRICT_CODE": "310113", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2104", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1790, "SUB_DISTRICT_CODE": "310114", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2105", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1791, "SUB_DISTRICT_CODE": "310117", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2108", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1792, "SUB_DISTRICT_CODE": "310118", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2109", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1793, "SUB_DISTRICT_CODE": "310119", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2110", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1794, "SUB_DISTRICT_CODE": "310120", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2111", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1795, "SUB_DISTRICT_CODE": "310122", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2113", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1796, "SUB_DISTRICT_CODE": "310125", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2116", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1797, "SUB_DISTRICT_CODE": "310126", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2117", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1798, "SUB_DISTRICT_CODE": "310127", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2118", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1799, "SUB_DISTRICT_CODE": "310128", "PROVINCE_ID": "20", "DISTRICT_ID": "250", "SUB_DISTRICT_ID": "2119", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1800, "SUB_DISTRICT_CODE": "310201", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2122", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1801, "SUB_DISTRICT_CODE": "310202", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2123", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1802, "SUB_DISTRICT_CODE": "310203", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2124", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1803, "SUB_DISTRICT_CODE": "310204", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2125", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1804, "SUB_DISTRICT_CODE": "310205", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2126", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1805, "SUB_DISTRICT_CODE": "310206", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2127", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1806, "SUB_DISTRICT_CODE": "310207", "PROVINCE_ID": "20", "DISTRICT_ID": "251", "SUB_DISTRICT_ID": "2128", "ZIPCODE": "31190" },
    { "ZIPCODE_ID": 1807, "SUB_DISTRICT_CODE": "310301", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2129", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1808, "SUB_DISTRICT_CODE": "310302", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2130", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1809, "SUB_DISTRICT_CODE": "310303", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2131", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1810, "SUB_DISTRICT_CODE": "310304", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2132", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1811, "SUB_DISTRICT_CODE": "310305", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2133", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1812, "SUB_DISTRICT_CODE": "310306", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2134", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1813, "SUB_DISTRICT_CODE": "310307", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2135", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1814, "SUB_DISTRICT_CODE": "310308", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2136", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1815, "SUB_DISTRICT_CODE": "310309", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2137", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1816, "SUB_DISTRICT_CODE": "310310", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2138", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1817, "SUB_DISTRICT_CODE": "310311", "PROVINCE_ID": "20", "DISTRICT_ID": "252", "SUB_DISTRICT_ID": "2139", "ZIPCODE": "31160" },
    { "ZIPCODE_ID": 1818, "SUB_DISTRICT_CODE": "310401", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2140", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1819, "SUB_DISTRICT_CODE": "310403", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2142", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1820, "SUB_DISTRICT_CODE": "310405", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2144", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1821, "SUB_DISTRICT_CODE": "310406", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2145", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1822, "SUB_DISTRICT_CODE": "310408", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2147", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1823, "SUB_DISTRICT_CODE": "310413", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2152", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1824, "SUB_DISTRICT_CODE": "310414", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2153", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1825, "SUB_DISTRICT_CODE": "310415", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2154", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1826, "SUB_DISTRICT_CODE": "310416", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2155", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1827, "SUB_DISTRICT_CODE": "310417", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2156", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1828, "SUB_DISTRICT_CODE": "310418", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2157", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1829, "SUB_DISTRICT_CODE": "310424", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2163", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1830, "SUB_DISTRICT_CODE": "310425", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2164", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1831, "SUB_DISTRICT_CODE": "310426", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2165", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1832, "SUB_DISTRICT_CODE": "310427", "PROVINCE_ID": "20", "DISTRICT_ID": "253", "SUB_DISTRICT_ID": "2166", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1833, "SUB_DISTRICT_CODE": "310501", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2173", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1834, "SUB_DISTRICT_CODE": "310502", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2174", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1835, "SUB_DISTRICT_CODE": "310503", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2175", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1836, "SUB_DISTRICT_CODE": "310504", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2176", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1837, "SUB_DISTRICT_CODE": "310505", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2177", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1838, "SUB_DISTRICT_CODE": "310506", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2178", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1839, "SUB_DISTRICT_CODE": "310507", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2179", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1840, "SUB_DISTRICT_CODE": "310508", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2180", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1841, "SUB_DISTRICT_CODE": "310509", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2181", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1842, "SUB_DISTRICT_CODE": "310510", "PROVINCE_ID": "20", "DISTRICT_ID": "254", "SUB_DISTRICT_ID": "2182", "ZIPCODE": "31210" },
    { "ZIPCODE_ID": 1843, "SUB_DISTRICT_CODE": "310601", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2183", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1844, "SUB_DISTRICT_CODE": "310603", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2185", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1845, "SUB_DISTRICT_CODE": "310604", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2186", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1846, "SUB_DISTRICT_CODE": "310607", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2189", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1847, "SUB_DISTRICT_CODE": "310610", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2192", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1848, "SUB_DISTRICT_CODE": "310611", "PROVINCE_ID": "20", "DISTRICT_ID": "255", "SUB_DISTRICT_ID": "2193", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1849, "SUB_DISTRICT_CODE": "310701", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2195", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1850, "SUB_DISTRICT_CODE": "310702", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2196", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1851, "SUB_DISTRICT_CODE": "310703", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2197", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1852, "SUB_DISTRICT_CODE": "310705", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2199", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1853, "SUB_DISTRICT_CODE": "310706", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2200", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1854, "SUB_DISTRICT_CODE": "310707", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2201", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1855, "SUB_DISTRICT_CODE": "310708", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2202", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1856, "SUB_DISTRICT_CODE": "310710", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2204", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1857, "SUB_DISTRICT_CODE": "310713", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2207", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1858, "SUB_DISTRICT_CODE": "310714", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2208", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1859, "SUB_DISTRICT_CODE": "310715", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2209", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1860, "SUB_DISTRICT_CODE": "310716", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2210", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1861, "SUB_DISTRICT_CODE": "310718", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2212", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1862, "SUB_DISTRICT_CODE": "310719", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2213", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1863, "SUB_DISTRICT_CODE": "310720", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2214", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1864, "SUB_DISTRICT_CODE": "310721", "PROVINCE_ID": "20", "DISTRICT_ID": "256", "SUB_DISTRICT_ID": "2215", "ZIPCODE": "31140" },
    { "ZIPCODE_ID": 1865, "SUB_DISTRICT_CODE": "310801", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2219", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1866, "SUB_DISTRICT_CODE": "310802", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2220", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1867, "SUB_DISTRICT_CODE": "310803", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2221", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1868, "SUB_DISTRICT_CODE": "310804", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2222", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1869, "SUB_DISTRICT_CODE": "310805", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2223", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1870, "SUB_DISTRICT_CODE": "310806", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2224", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1871, "SUB_DISTRICT_CODE": "310807", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2225", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1872, "SUB_DISTRICT_CODE": "310808", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2226", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1873, "SUB_DISTRICT_CODE": "310809", "PROVINCE_ID": "20", "DISTRICT_ID": "257", "SUB_DISTRICT_ID": "2227", "ZIPCODE": "31180" },
    { "ZIPCODE_ID": 1874, "SUB_DISTRICT_CODE": "310901", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2228", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1875, "SUB_DISTRICT_CODE": "310902", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2229", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1876, "SUB_DISTRICT_CODE": "310903", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2230", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1877, "SUB_DISTRICT_CODE": "310906", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2233", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1878, "SUB_DISTRICT_CODE": "310907", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2234", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1879, "SUB_DISTRICT_CODE": "310909", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2236", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1880, "SUB_DISTRICT_CODE": "310910", "PROVINCE_ID": "20", "DISTRICT_ID": "258", "SUB_DISTRICT_ID": "2237", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1881, "SUB_DISTRICT_CODE": "311001", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2240", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1882, "SUB_DISTRICT_CODE": "311002", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2241", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1883, "SUB_DISTRICT_CODE": "311003", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2242", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1884, "SUB_DISTRICT_CODE": "311004", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2243", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1885, "SUB_DISTRICT_CODE": "311005", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2244", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1886, "SUB_DISTRICT_CODE": "311006", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2245", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1887, "SUB_DISTRICT_CODE": "311007", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2246", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1888, "SUB_DISTRICT_CODE": "311008", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2247", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1889, "SUB_DISTRICT_CODE": "311009", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2248", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1890, "SUB_DISTRICT_CODE": "311010", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2249", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1891, "SUB_DISTRICT_CODE": "311011", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2250", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1892, "SUB_DISTRICT_CODE": "311012", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2251", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1893, "SUB_DISTRICT_CODE": "311013", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2252", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1894, "SUB_DISTRICT_CODE": "311014", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2253", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1895, "SUB_DISTRICT_CODE": "311015", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2254", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1896, "SUB_DISTRICT_CODE": "311016", "PROVINCE_ID": "20", "DISTRICT_ID": "259", "SUB_DISTRICT_ID": "2255", "ZIPCODE": "31130" },
    { "ZIPCODE_ID": 1897, "SUB_DISTRICT_CODE": "311101", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2259", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1898, "SUB_DISTRICT_CODE": "311102", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2260", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1899, "SUB_DISTRICT_CODE": "311103", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2261", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1900, "SUB_DISTRICT_CODE": "311104", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2262", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1901, "SUB_DISTRICT_CODE": "311105", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2263", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1902, "SUB_DISTRICT_CODE": "311106", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2264", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1903, "SUB_DISTRICT_CODE": "311109", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2267", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1904, "SUB_DISTRICT_CODE": "311110", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2268", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1905, "SUB_DISTRICT_CODE": "311111", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2269", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1906, "SUB_DISTRICT_CODE": "311112", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2270", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1907, "SUB_DISTRICT_CODE": "311114", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2272", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1908, "SUB_DISTRICT_CODE": "311115", "PROVINCE_ID": "20", "DISTRICT_ID": "260", "SUB_DISTRICT_ID": "2273", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1909, "SUB_DISTRICT_CODE": "311201", "PROVINCE_ID": "20", "DISTRICT_ID": "261", "SUB_DISTRICT_ID": "2275", "ZIPCODE": "31220" },
    { "ZIPCODE_ID": 1910, "SUB_DISTRICT_CODE": "311202", "PROVINCE_ID": "20", "DISTRICT_ID": "261", "SUB_DISTRICT_ID": "2276", "ZIPCODE": "31220" },
    { "ZIPCODE_ID": 1911, "SUB_DISTRICT_CODE": "311203", "PROVINCE_ID": "20", "DISTRICT_ID": "261", "SUB_DISTRICT_ID": "2277", "ZIPCODE": "31220" },
    { "ZIPCODE_ID": 1912, "SUB_DISTRICT_CODE": "311204", "PROVINCE_ID": "20", "DISTRICT_ID": "261", "SUB_DISTRICT_ID": "2278", "ZIPCODE": "31220" },
    { "ZIPCODE_ID": 1913, "SUB_DISTRICT_CODE": "311205", "PROVINCE_ID": "20", "DISTRICT_ID": "261", "SUB_DISTRICT_ID": "2279", "ZIPCODE": "31220" },
    { "ZIPCODE_ID": 1914, "SUB_DISTRICT_CODE": "311301", "PROVINCE_ID": "20", "DISTRICT_ID": "262", "SUB_DISTRICT_ID": "2280", "ZIPCODE": "31230" },
    { "ZIPCODE_ID": 1915, "SUB_DISTRICT_CODE": "311302", "PROVINCE_ID": "20", "DISTRICT_ID": "262", "SUB_DISTRICT_ID": "2281", "ZIPCODE": "31230" },
    { "ZIPCODE_ID": 1916, "SUB_DISTRICT_CODE": "311303", "PROVINCE_ID": "20", "DISTRICT_ID": "262", "SUB_DISTRICT_ID": "2282", "ZIPCODE": "31230" },
    { "ZIPCODE_ID": 1917, "SUB_DISTRICT_CODE": "311304", "PROVINCE_ID": "20", "DISTRICT_ID": "262", "SUB_DISTRICT_ID": "2283", "ZIPCODE": "31230" },
    { "ZIPCODE_ID": 1918, "SUB_DISTRICT_CODE": "311305", "PROVINCE_ID": "20", "DISTRICT_ID": "262", "SUB_DISTRICT_ID": "2284", "ZIPCODE": "31230" },
    { "ZIPCODE_ID": 1919, "SUB_DISTRICT_CODE": "311401", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2285", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1920, "SUB_DISTRICT_CODE": "311402", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2286", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1921, "SUB_DISTRICT_CODE": "311403", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2287", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1922, "SUB_DISTRICT_CODE": "311404", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2288", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1923, "SUB_DISTRICT_CODE": "311405", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2289", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1924, "SUB_DISTRICT_CODE": "311406", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2290", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1925, "SUB_DISTRICT_CODE": "311407", "PROVINCE_ID": "20", "DISTRICT_ID": "263", "SUB_DISTRICT_ID": "2291", "ZIPCODE": "31240" },
    { "ZIPCODE_ID": 1926, "SUB_DISTRICT_CODE": "311501", "PROVINCE_ID": "20", "DISTRICT_ID": "264", "SUB_DISTRICT_ID": "2292", "ZIPCODE": "31250" },
    { "ZIPCODE_ID": 1927, "SUB_DISTRICT_CODE": "311502", "PROVINCE_ID": "20", "DISTRICT_ID": "264", "SUB_DISTRICT_ID": "2293", "ZIPCODE": "31250" },
    { "ZIPCODE_ID": 1928, "SUB_DISTRICT_CODE": "311503", "PROVINCE_ID": "20", "DISTRICT_ID": "264", "SUB_DISTRICT_ID": "2294", "ZIPCODE": "31250" },
    { "ZIPCODE_ID": 1929, "SUB_DISTRICT_CODE": "311504", "PROVINCE_ID": "20", "DISTRICT_ID": "264", "SUB_DISTRICT_ID": "2295", "ZIPCODE": "31250" },
    { "ZIPCODE_ID": 1930, "SUB_DISTRICT_CODE": "311505", "PROVINCE_ID": "20", "DISTRICT_ID": "264", "SUB_DISTRICT_ID": "2296", "ZIPCODE": "31250" },
    { "ZIPCODE_ID": 1931, "SUB_DISTRICT_CODE": "311601", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2297", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1932, "SUB_DISTRICT_CODE": "311602", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2298", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1933, "SUB_DISTRICT_CODE": "311603", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2299", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1934, "SUB_DISTRICT_CODE": "311604", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2300", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1935, "SUB_DISTRICT_CODE": "311605", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2301", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1936, "SUB_DISTRICT_CODE": "311606", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2302", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1937, "SUB_DISTRICT_CODE": "311607", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2303", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1938, "SUB_DISTRICT_CODE": "311608", "PROVINCE_ID": "20", "DISTRICT_ID": "265", "SUB_DISTRICT_ID": "2304", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1939, "SUB_DISTRICT_CODE": "311701", "PROVINCE_ID": "20", "DISTRICT_ID": "266", "SUB_DISTRICT_ID": "2305", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1940, "SUB_DISTRICT_CODE": "311702", "PROVINCE_ID": "20", "DISTRICT_ID": "266", "SUB_DISTRICT_ID": "2306", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1941, "SUB_DISTRICT_CODE": "311703", "PROVINCE_ID": "20", "DISTRICT_ID": "266", "SUB_DISTRICT_ID": "2307", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1942, "SUB_DISTRICT_CODE": "311704", "PROVINCE_ID": "20", "DISTRICT_ID": "266", "SUB_DISTRICT_ID": "2308", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1943, "SUB_DISTRICT_CODE": "311801", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2309", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1944, "SUB_DISTRICT_CODE": "311802", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2310", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1945, "SUB_DISTRICT_CODE": "311803", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2311", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1946, "SUB_DISTRICT_CODE": "311804", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2312", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1947, "SUB_DISTRICT_CODE": "311805", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2313", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1948, "SUB_DISTRICT_CODE": "311806", "PROVINCE_ID": "20", "DISTRICT_ID": "267", "SUB_DISTRICT_ID": "2314", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1949, "SUB_DISTRICT_CODE": "311901", "PROVINCE_ID": "20", "DISTRICT_ID": "268", "SUB_DISTRICT_ID": "2315", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1950, "SUB_DISTRICT_CODE": "311902", "PROVINCE_ID": "20", "DISTRICT_ID": "268", "SUB_DISTRICT_ID": "2316", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1951, "SUB_DISTRICT_CODE": "311903", "PROVINCE_ID": "20", "DISTRICT_ID": "268", "SUB_DISTRICT_ID": "2317", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1952, "SUB_DISTRICT_CODE": "311904", "PROVINCE_ID": "20", "DISTRICT_ID": "268", "SUB_DISTRICT_ID": "2318", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1953, "SUB_DISTRICT_CODE": "311905", "PROVINCE_ID": "20", "DISTRICT_ID": "268", "SUB_DISTRICT_ID": "2319", "ZIPCODE": "31120" },
    { "ZIPCODE_ID": 1954, "SUB_DISTRICT_CODE": "312001", "PROVINCE_ID": "20", "DISTRICT_ID": "269", "SUB_DISTRICT_ID": "2320", "ZIPCODE": "31260" },
    { "ZIPCODE_ID": 1955, "SUB_DISTRICT_CODE": "312002", "PROVINCE_ID": "20", "DISTRICT_ID": "269", "SUB_DISTRICT_ID": "2321", "ZIPCODE": "31260" },
    { "ZIPCODE_ID": 1956, "SUB_DISTRICT_CODE": "312003", "PROVINCE_ID": "20", "DISTRICT_ID": "269", "SUB_DISTRICT_ID": "2322", "ZIPCODE": "31260" },
    { "ZIPCODE_ID": 1957, "SUB_DISTRICT_CODE": "312101", "PROVINCE_ID": "20", "DISTRICT_ID": "270", "SUB_DISTRICT_ID": "2323", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1958, "SUB_DISTRICT_CODE": "312102", "PROVINCE_ID": "20", "DISTRICT_ID": "270", "SUB_DISTRICT_ID": "2324", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1959, "SUB_DISTRICT_CODE": "312103", "PROVINCE_ID": "20", "DISTRICT_ID": "270", "SUB_DISTRICT_ID": "2325", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1960, "SUB_DISTRICT_CODE": "312104", "PROVINCE_ID": "20", "DISTRICT_ID": "270", "SUB_DISTRICT_ID": "2326", "ZIPCODE": "31000" },
    { "ZIPCODE_ID": 1961, "SUB_DISTRICT_CODE": "312201", "PROVINCE_ID": "20", "DISTRICT_ID": "271", "SUB_DISTRICT_ID": "2327", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1962, "SUB_DISTRICT_CODE": "312202", "PROVINCE_ID": "20", "DISTRICT_ID": "271", "SUB_DISTRICT_ID": "2328", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1963, "SUB_DISTRICT_CODE": "312203", "PROVINCE_ID": "20", "DISTRICT_ID": "271", "SUB_DISTRICT_ID": "2329", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1964, "SUB_DISTRICT_CODE": "312204", "PROVINCE_ID": "20", "DISTRICT_ID": "271", "SUB_DISTRICT_ID": "2330", "ZIPCODE": "31150" },
    { "ZIPCODE_ID": 1965, "SUB_DISTRICT_CODE": "312301", "PROVINCE_ID": "20", "DISTRICT_ID": "272", "SUB_DISTRICT_ID": "2331", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1966, "SUB_DISTRICT_CODE": "312302", "PROVINCE_ID": "20", "DISTRICT_ID": "272", "SUB_DISTRICT_ID": "2332", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1967, "SUB_DISTRICT_CODE": "312303", "PROVINCE_ID": "20", "DISTRICT_ID": "272", "SUB_DISTRICT_ID": "2333", "ZIPCODE": "31110" },
    { "ZIPCODE_ID": 1968, "SUB_DISTRICT_CODE": "312304", "PROVINCE_ID": "20", "DISTRICT_ID": "272", "SUB_DISTRICT_ID": "2334", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1969, "SUB_DISTRICT_CODE": "312305", "PROVINCE_ID": "20", "DISTRICT_ID": "272", "SUB_DISTRICT_ID": "2335", "ZIPCODE": "31170" },
    { "ZIPCODE_ID": 1970, "SUB_DISTRICT_CODE": "320101", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2336", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1971, "SUB_DISTRICT_CODE": "320102", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2337", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1972, "SUB_DISTRICT_CODE": "320103", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2338", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1973, "SUB_DISTRICT_CODE": "320104", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2339", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1974, "SUB_DISTRICT_CODE": "320105", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2340", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1975, "SUB_DISTRICT_CODE": "320106", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2341", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1976, "SUB_DISTRICT_CODE": "320107", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2342", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1977, "SUB_DISTRICT_CODE": "320109", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2344", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1978, "SUB_DISTRICT_CODE": "320110", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2345", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1979, "SUB_DISTRICT_CODE": "320111", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2346", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1980, "SUB_DISTRICT_CODE": "320112", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2347", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1981, "SUB_DISTRICT_CODE": "320113", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2348", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1982, "SUB_DISTRICT_CODE": "320114", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2349", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1983, "SUB_DISTRICT_CODE": "320116", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2351", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1984, "SUB_DISTRICT_CODE": "320118", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2353", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1985, "SUB_DISTRICT_CODE": "320119", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2354", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1986, "SUB_DISTRICT_CODE": "320120", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2355", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1987, "SUB_DISTRICT_CODE": "320121", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2356", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1988, "SUB_DISTRICT_CODE": "320122", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2357", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1989, "SUB_DISTRICT_CODE": "320125", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2360", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1990, "SUB_DISTRICT_CODE": "320126", "PROVINCE_ID": "21", "DISTRICT_ID": "273", "SUB_DISTRICT_ID": "2361", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 1991, "SUB_DISTRICT_CODE": "320201", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2362", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1992, "SUB_DISTRICT_CODE": "320202", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2363", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1993, "SUB_DISTRICT_CODE": "320203", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2364", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1994, "SUB_DISTRICT_CODE": "320204", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2365", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1995, "SUB_DISTRICT_CODE": "320205", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2366", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1996, "SUB_DISTRICT_CODE": "320206", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2367", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1997, "SUB_DISTRICT_CODE": "320207", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2368", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1998, "SUB_DISTRICT_CODE": "320208", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2369", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 1999, "SUB_DISTRICT_CODE": "320209", "PROVINCE_ID": "21", "DISTRICT_ID": "274", "SUB_DISTRICT_ID": "2370", "ZIPCODE": "32190" },
    { "ZIPCODE_ID": 2000, "SUB_DISTRICT_CODE": "320301", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2371", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2001, "SUB_DISTRICT_CODE": "320302", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2372", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2002, "SUB_DISTRICT_CODE": "320303", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2373", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2003, "SUB_DISTRICT_CODE": "320304", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2374", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2004, "SUB_DISTRICT_CODE": "320305", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2375", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2005, "SUB_DISTRICT_CODE": "320306", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2376", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2006, "SUB_DISTRICT_CODE": "320307", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2377", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2007, "SUB_DISTRICT_CODE": "320308", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2378", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2008, "SUB_DISTRICT_CODE": "320309", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2379", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2009, "SUB_DISTRICT_CODE": "320310", "PROVINCE_ID": "21", "DISTRICT_ID": "275", "SUB_DISTRICT_ID": "2380", "ZIPCODE": "32120" },
    { "ZIPCODE_ID": 2010, "SUB_DISTRICT_CODE": "320401", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2381", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2011, "SUB_DISTRICT_CODE": "320402", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2382", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2012, "SUB_DISTRICT_CODE": "320403", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2383", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2013, "SUB_DISTRICT_CODE": "320404", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2384", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2014, "SUB_DISTRICT_CODE": "320405", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2385", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2015, "SUB_DISTRICT_CODE": "320406", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2386", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2016, "SUB_DISTRICT_CODE": "320407", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2387", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2017, "SUB_DISTRICT_CODE": "320408", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2388", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2018, "SUB_DISTRICT_CODE": "320409", "PROVINCE_ID": "21", "DISTRICT_ID": "276", "SUB_DISTRICT_ID": "2389", "ZIPCODE": "32180" },
    { "ZIPCODE_ID": 2019, "SUB_DISTRICT_CODE": "320501", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2390", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2020, "SUB_DISTRICT_CODE": "320502", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2391", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2021, "SUB_DISTRICT_CODE": "320503", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2392", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2022, "SUB_DISTRICT_CODE": "320504", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2393", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2023, "SUB_DISTRICT_CODE": "320505", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2394", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2024, "SUB_DISTRICT_CODE": "320506", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2395", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2025, "SUB_DISTRICT_CODE": "320507", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2396", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2026, "SUB_DISTRICT_CODE": "320508", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2397", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2027, "SUB_DISTRICT_CODE": "320509", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2398", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2028, "SUB_DISTRICT_CODE": "320510", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2399", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2029, "SUB_DISTRICT_CODE": "320511", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2400", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2030, "SUB_DISTRICT_CODE": "320512", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2401", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2031, "SUB_DISTRICT_CODE": "320513", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2402", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2032, "SUB_DISTRICT_CODE": "320514", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2403", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2033, "SUB_DISTRICT_CODE": "320515", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2404", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2034, "SUB_DISTRICT_CODE": "320516", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2405", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2035, "SUB_DISTRICT_CODE": "320517", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2406", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2036, "SUB_DISTRICT_CODE": "320518", "PROVINCE_ID": "21", "DISTRICT_ID": "277", "SUB_DISTRICT_ID": "2407", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2037, "SUB_DISTRICT_CODE": "320601", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2413", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2038, "SUB_DISTRICT_CODE": "320604", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2416", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2039, "SUB_DISTRICT_CODE": "320605", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2417", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2040, "SUB_DISTRICT_CODE": "320606", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2418", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2041, "SUB_DISTRICT_CODE": "320607", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2419", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2042, "SUB_DISTRICT_CODE": "320610", "PROVINCE_ID": "21", "DISTRICT_ID": "278", "SUB_DISTRICT_ID": "2422", "ZIPCODE": "32210" },
    { "ZIPCODE_ID": 2043, "SUB_DISTRICT_CODE": "320701", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2423", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2044, "SUB_DISTRICT_CODE": "320702", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2424", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2045, "SUB_DISTRICT_CODE": "320703", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2425", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2046, "SUB_DISTRICT_CODE": "320704", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2426", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2047, "SUB_DISTRICT_CODE": "320705", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2427", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2048, "SUB_DISTRICT_CODE": "320706", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2428", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2049, "SUB_DISTRICT_CODE": "320709", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2431", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2050, "SUB_DISTRICT_CODE": "320711", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2433", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2051, "SUB_DISTRICT_CODE": "320713", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2435", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2052, "SUB_DISTRICT_CODE": "320714", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2436", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2053, "SUB_DISTRICT_CODE": "320715", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2437", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2054, "SUB_DISTRICT_CODE": "320716", "PROVINCE_ID": "21", "DISTRICT_ID": "279", "SUB_DISTRICT_ID": "2438", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2055, "SUB_DISTRICT_CODE": "320801", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2445", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2056, "SUB_DISTRICT_CODE": "320802", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2446", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2057, "SUB_DISTRICT_CODE": "320803", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2447", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2058, "SUB_DISTRICT_CODE": "320804", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2448", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2059, "SUB_DISTRICT_CODE": "320805", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2449", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2060, "SUB_DISTRICT_CODE": "320806", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2450", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2061, "SUB_DISTRICT_CODE": "320807", "PROVINCE_ID": "21", "DISTRICT_ID": "280", "SUB_DISTRICT_ID": "2451", "ZIPCODE": "32160" },
    { "ZIPCODE_ID": 2062, "SUB_DISTRICT_CODE": "320901", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2452", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2063, "SUB_DISTRICT_CODE": "320902", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2453", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2064, "SUB_DISTRICT_CODE": "320903", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2454", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2065, "SUB_DISTRICT_CODE": "320904", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2455", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2066, "SUB_DISTRICT_CODE": "320905", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2456", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2067, "SUB_DISTRICT_CODE": "320906", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2457", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2068, "SUB_DISTRICT_CODE": "320907", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2458", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2069, "SUB_DISTRICT_CODE": "320908", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2459", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2070, "SUB_DISTRICT_CODE": "320909", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2460", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2071, "SUB_DISTRICT_CODE": "320910", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2461", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2072, "SUB_DISTRICT_CODE": "320911", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2462", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2073, "SUB_DISTRICT_CODE": "320912", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2463", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2074, "SUB_DISTRICT_CODE": "320913", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2464", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2075, "SUB_DISTRICT_CODE": "320914", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2465", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2076, "SUB_DISTRICT_CODE": "320915", "PROVINCE_ID": "21", "DISTRICT_ID": "281", "SUB_DISTRICT_ID": "2466", "ZIPCODE": "32110" },
    { "ZIPCODE_ID": 2077, "SUB_DISTRICT_CODE": "321001", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2467", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2078, "SUB_DISTRICT_CODE": "321002", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2468", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2079, "SUB_DISTRICT_CODE": "321006", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2472", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2080, "SUB_DISTRICT_CODE": "321007", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2473", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2081, "SUB_DISTRICT_CODE": "321008", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2474", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2082, "SUB_DISTRICT_CODE": "321009", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2475", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2083, "SUB_DISTRICT_CODE": "321010", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2476", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2084, "SUB_DISTRICT_CODE": "321011", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2477", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2085, "SUB_DISTRICT_CODE": "321012", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2478", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2086, "SUB_DISTRICT_CODE": "321013", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2479", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2087, "SUB_DISTRICT_CODE": "321015", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2481", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2088, "SUB_DISTRICT_CODE": "321017", "PROVINCE_ID": "21", "DISTRICT_ID": "282", "SUB_DISTRICT_ID": "2483", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2089, "SUB_DISTRICT_CODE": "321101", "PROVINCE_ID": "21", "DISTRICT_ID": "283", "SUB_DISTRICT_ID": "2486", "ZIPCODE": "32220" },
    { "ZIPCODE_ID": 2090, "SUB_DISTRICT_CODE": "321102", "PROVINCE_ID": "21", "DISTRICT_ID": "283", "SUB_DISTRICT_ID": "2487", "ZIPCODE": "32220" },
    { "ZIPCODE_ID": 2091, "SUB_DISTRICT_CODE": "321103", "PROVINCE_ID": "21", "DISTRICT_ID": "283", "SUB_DISTRICT_ID": "2488", "ZIPCODE": "32220" },
    { "ZIPCODE_ID": 2092, "SUB_DISTRICT_CODE": "321104", "PROVINCE_ID": "21", "DISTRICT_ID": "283", "SUB_DISTRICT_ID": "2489", "ZIPCODE": "32220" },
    { "ZIPCODE_ID": 2093, "SUB_DISTRICT_CODE": "321105", "PROVINCE_ID": "21", "DISTRICT_ID": "283", "SUB_DISTRICT_ID": "2490", "ZIPCODE": "32220" },
    { "ZIPCODE_ID": 2094, "SUB_DISTRICT_CODE": "321201", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2491", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2095, "SUB_DISTRICT_CODE": "321202", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2492", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2096, "SUB_DISTRICT_CODE": "321203", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2493", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2097, "SUB_DISTRICT_CODE": "321204", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2494", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2098, "SUB_DISTRICT_CODE": "321205", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2495", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2099, "SUB_DISTRICT_CODE": "321206", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2496", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2100, "SUB_DISTRICT_CODE": "321207", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2497", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2101, "SUB_DISTRICT_CODE": "321208", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2498", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2102, "SUB_DISTRICT_CODE": "321209", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2499", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2103, "SUB_DISTRICT_CODE": "321210", "PROVINCE_ID": "21", "DISTRICT_ID": "284", "SUB_DISTRICT_ID": "2500", "ZIPCODE": "32170" },
    { "ZIPCODE_ID": 2104, "SUB_DISTRICT_CODE": "321301", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2501", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2105, "SUB_DISTRICT_CODE": "321302", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2502", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2106, "SUB_DISTRICT_CODE": "321303", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2503", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2107, "SUB_DISTRICT_CODE": "321304", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2504", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2108, "SUB_DISTRICT_CODE": "321305", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2505", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2109, "SUB_DISTRICT_CODE": "321306", "PROVINCE_ID": "21", "DISTRICT_ID": "285", "SUB_DISTRICT_ID": "2506", "ZIPCODE": "32230" },
    { "ZIPCODE_ID": 2110, "SUB_DISTRICT_CODE": "321401", "PROVINCE_ID": "21", "DISTRICT_ID": "286", "SUB_DISTRICT_ID": "2507", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2111, "SUB_DISTRICT_CODE": "321402", "PROVINCE_ID": "21", "DISTRICT_ID": "286", "SUB_DISTRICT_ID": "2508", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2112, "SUB_DISTRICT_CODE": "321403", "PROVINCE_ID": "21", "DISTRICT_ID": "286", "SUB_DISTRICT_ID": "2509", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2113, "SUB_DISTRICT_CODE": "321404", "PROVINCE_ID": "21", "DISTRICT_ID": "286", "SUB_DISTRICT_ID": "2510", "ZIPCODE": "32140" },
    { "ZIPCODE_ID": 2114, "SUB_DISTRICT_CODE": "321501", "PROVINCE_ID": "21", "DISTRICT_ID": "287", "SUB_DISTRICT_ID": "2511", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2115, "SUB_DISTRICT_CODE": "321502", "PROVINCE_ID": "21", "DISTRICT_ID": "287", "SUB_DISTRICT_ID": "2512", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2116, "SUB_DISTRICT_CODE": "321503", "PROVINCE_ID": "21", "DISTRICT_ID": "287", "SUB_DISTRICT_ID": "2513", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2117, "SUB_DISTRICT_CODE": "321504", "PROVINCE_ID": "21", "DISTRICT_ID": "287", "SUB_DISTRICT_ID": "2514", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2118, "SUB_DISTRICT_CODE": "321505", "PROVINCE_ID": "21", "DISTRICT_ID": "287", "SUB_DISTRICT_ID": "2515", "ZIPCODE": "32150" },
    { "ZIPCODE_ID": 2119, "SUB_DISTRICT_CODE": "321601", "PROVINCE_ID": "21", "DISTRICT_ID": "288", "SUB_DISTRICT_ID": "2516", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 2120, "SUB_DISTRICT_CODE": "321602", "PROVINCE_ID": "21", "DISTRICT_ID": "288", "SUB_DISTRICT_ID": "2517", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 2121, "SUB_DISTRICT_CODE": "321603", "PROVINCE_ID": "21", "DISTRICT_ID": "288", "SUB_DISTRICT_ID": "2518", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 2122, "SUB_DISTRICT_CODE": "321604", "PROVINCE_ID": "21", "DISTRICT_ID": "288", "SUB_DISTRICT_ID": "2519", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 2123, "SUB_DISTRICT_CODE": "321605", "PROVINCE_ID": "21", "DISTRICT_ID": "288", "SUB_DISTRICT_ID": "2520", "ZIPCODE": "32000" },
    { "ZIPCODE_ID": 2124, "SUB_DISTRICT_CODE": "321701", "PROVINCE_ID": "21", "DISTRICT_ID": "289", "SUB_DISTRICT_ID": "2521", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2125, "SUB_DISTRICT_CODE": "321702", "PROVINCE_ID": "21", "DISTRICT_ID": "289", "SUB_DISTRICT_ID": "2522", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2126, "SUB_DISTRICT_CODE": "321703", "PROVINCE_ID": "21", "DISTRICT_ID": "289", "SUB_DISTRICT_ID": "2523", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2127, "SUB_DISTRICT_CODE": "321704", "PROVINCE_ID": "21", "DISTRICT_ID": "289", "SUB_DISTRICT_ID": "2524", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2128, "SUB_DISTRICT_CODE": "321705", "PROVINCE_ID": "21", "DISTRICT_ID": "289", "SUB_DISTRICT_ID": "2525", "ZIPCODE": "32130" },
    { "ZIPCODE_ID": 2129, "SUB_DISTRICT_CODE": "330101", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2526", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2130, "SUB_DISTRICT_CODE": "330102", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2527", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2131, "SUB_DISTRICT_CODE": "330103", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2528", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2132, "SUB_DISTRICT_CODE": "330104", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2529", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2133, "SUB_DISTRICT_CODE": "330105", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2530", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2134, "SUB_DISTRICT_CODE": "330106", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2531", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2135, "SUB_DISTRICT_CODE": "330107", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2532", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2136, "SUB_DISTRICT_CODE": "330111", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2536", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2137, "SUB_DISTRICT_CODE": "330112", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2537", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2138, "SUB_DISTRICT_CODE": "330115", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2540", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2139, "SUB_DISTRICT_CODE": "330116", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2541", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2140, "SUB_DISTRICT_CODE": "330118", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2543", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2141, "SUB_DISTRICT_CODE": "330119", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2544", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2142, "SUB_DISTRICT_CODE": "330121", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2546", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2143, "SUB_DISTRICT_CODE": "330122", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2547", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2144, "SUB_DISTRICT_CODE": "330123", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2548", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2145, "SUB_DISTRICT_CODE": "330124", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2549", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2146, "SUB_DISTRICT_CODE": "330127", "PROVINCE_ID": "22", "DISTRICT_ID": "290", "SUB_DISTRICT_ID": "2552", "ZIPCODE": "33000" },
    { "ZIPCODE_ID": 2147, "SUB_DISTRICT_CODE": "330201", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2558", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2148, "SUB_DISTRICT_CODE": "330202", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2559", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2149, "SUB_DISTRICT_CODE": "330203", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2560", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2150, "SUB_DISTRICT_CODE": "330204", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2561", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2151, "SUB_DISTRICT_CODE": "330205", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2562", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2152, "SUB_DISTRICT_CODE": "330206", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2563", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2153, "SUB_DISTRICT_CODE": "330207", "PROVINCE_ID": "22", "DISTRICT_ID": "291", "SUB_DISTRICT_ID": "2564", "ZIPCODE": "33190" },
    { "ZIPCODE_ID": 2154, "SUB_DISTRICT_CODE": "330301", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2565", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2155, "SUB_DISTRICT_CODE": "330302", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2566", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2156, "SUB_DISTRICT_CODE": "330303", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2567", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2157, "SUB_DISTRICT_CODE": "330304", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2568", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2158, "SUB_DISTRICT_CODE": "330305", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2569", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2159, "SUB_DISTRICT_CODE": "330306", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2570", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2160, "SUB_DISTRICT_CODE": "330307", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2571", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2161, "SUB_DISTRICT_CODE": "330308", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2572", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2162, "SUB_DISTRICT_CODE": "330309", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2573", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2163, "SUB_DISTRICT_CODE": "330310", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2574", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2164, "SUB_DISTRICT_CODE": "330311", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2575", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2165, "SUB_DISTRICT_CODE": "330312", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2576", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2166, "SUB_DISTRICT_CODE": "330313", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2577", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2167, "SUB_DISTRICT_CODE": "330314", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2578", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2168, "SUB_DISTRICT_CODE": "330315", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2579", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2169, "SUB_DISTRICT_CODE": "330320", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "2584", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2170, "SUB_DISTRICT_CODE": "330321", "PROVINCE_ID": "22", "DISTRICT_ID": "292", "SUB_DISTRICT_ID": "8914", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2171, "SUB_DISTRICT_CODE": "330401", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2591", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2172, "SUB_DISTRICT_CODE": "330402", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2592", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2173, "SUB_DISTRICT_CODE": "330403", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2593", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2174, "SUB_DISTRICT_CODE": "330405", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2595", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2175, "SUB_DISTRICT_CODE": "330406", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2596", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2176, "SUB_DISTRICT_CODE": "330407", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2597", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2177, "SUB_DISTRICT_CODE": "330408", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2598", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2178, "SUB_DISTRICT_CODE": "330409", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2599", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2179, "SUB_DISTRICT_CODE": "330411", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2601", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2180, "SUB_DISTRICT_CODE": "330412", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2602", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2181, "SUB_DISTRICT_CODE": "330413", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2603", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2182, "SUB_DISTRICT_CODE": "330414", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2604", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2183, "SUB_DISTRICT_CODE": "330415", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2605", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2184, "SUB_DISTRICT_CODE": "330416", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2606", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2185, "SUB_DISTRICT_CODE": "330419", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2609", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2186, "SUB_DISTRICT_CODE": "330420", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2610", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2187, "SUB_DISTRICT_CODE": "330421", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2611", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2188, "SUB_DISTRICT_CODE": "330423", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2613", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2189, "SUB_DISTRICT_CODE": "330424", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2614", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2190, "SUB_DISTRICT_CODE": "330425", "PROVINCE_ID": "22", "DISTRICT_ID": "293", "SUB_DISTRICT_ID": "2615", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2191, "SUB_DISTRICT_CODE": "330501", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2620", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2192, "SUB_DISTRICT_CODE": "330502", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2621", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2193, "SUB_DISTRICT_CODE": "330503", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2622", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2194, "SUB_DISTRICT_CODE": "330504", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2623", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2195, "SUB_DISTRICT_CODE": "330505", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2624", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2196, "SUB_DISTRICT_CODE": "330506", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2625", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2197, "SUB_DISTRICT_CODE": "330507", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2626", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2198, "SUB_DISTRICT_CODE": "330508", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2627", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2199, "SUB_DISTRICT_CODE": "330509", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2628", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2200, "SUB_DISTRICT_CODE": "330510", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2629", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2201, "SUB_DISTRICT_CODE": "330511", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2630", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2202, "SUB_DISTRICT_CODE": "330513", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2632", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2203, "SUB_DISTRICT_CODE": "330515", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2634", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2204, "SUB_DISTRICT_CODE": "330517", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2636", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2205, "SUB_DISTRICT_CODE": "330518", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2637", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2206, "SUB_DISTRICT_CODE": "330521", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2640", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2207, "SUB_DISTRICT_CODE": "330522", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2641", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2208, "SUB_DISTRICT_CODE": "330524", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2643", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2209, "SUB_DISTRICT_CODE": "330525", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2644", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2210, "SUB_DISTRICT_CODE": "330526", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2645", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2211, "SUB_DISTRICT_CODE": "330527", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2646", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2212, "SUB_DISTRICT_CODE": "330528", "PROVINCE_ID": "22", "DISTRICT_ID": "294", "SUB_DISTRICT_ID": "2647", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2213, "SUB_DISTRICT_CODE": "330601", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2649", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2214, "SUB_DISTRICT_CODE": "330602", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2650", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2215, "SUB_DISTRICT_CODE": "330603", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2651", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2216, "SUB_DISTRICT_CODE": "330604", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2652", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2217, "SUB_DISTRICT_CODE": "330605", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2653", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2218, "SUB_DISTRICT_CODE": "330606", "PROVINCE_ID": "22", "DISTRICT_ID": "295", "SUB_DISTRICT_ID": "2654", "ZIPCODE": "33180" },
    { "ZIPCODE_ID": 2219, "SUB_DISTRICT_CODE": "330701", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2655", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2220, "SUB_DISTRICT_CODE": "330702", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2656", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2221, "SUB_DISTRICT_CODE": "330703", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2657", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2222, "SUB_DISTRICT_CODE": "330704", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2658", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2223, "SUB_DISTRICT_CODE": "330705", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2659", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2224, "SUB_DISTRICT_CODE": "330706", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2660", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2225, "SUB_DISTRICT_CODE": "330707", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2661", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2226, "SUB_DISTRICT_CODE": "330708", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2662", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2227, "SUB_DISTRICT_CODE": "330709", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2663", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2228, "SUB_DISTRICT_CODE": "330710", "PROVINCE_ID": "22", "DISTRICT_ID": "296", "SUB_DISTRICT_ID": "2664", "ZIPCODE": "33170" },
    { "ZIPCODE_ID": 2229, "SUB_DISTRICT_CODE": "330801", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2665", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2230, "SUB_DISTRICT_CODE": "330802", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2666", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2231, "SUB_DISTRICT_CODE": "330803", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2667", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2232, "SUB_DISTRICT_CODE": "330804", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2668", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2233, "SUB_DISTRICT_CODE": "330805", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2669", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2234, "SUB_DISTRICT_CODE": "330806", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2670", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2235, "SUB_DISTRICT_CODE": "330807", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2671", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2236, "SUB_DISTRICT_CODE": "330808", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2672", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2237, "SUB_DISTRICT_CODE": "330809", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2673", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2238, "SUB_DISTRICT_CODE": "330810", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2674", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2239, "SUB_DISTRICT_CODE": "330811", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2675", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2240, "SUB_DISTRICT_CODE": "330812", "PROVINCE_ID": "22", "DISTRICT_ID": "297", "SUB_DISTRICT_ID": "2676", "ZIPCODE": "33150" },
    { "ZIPCODE_ID": 2241, "SUB_DISTRICT_CODE": "330901", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2677", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2242, "SUB_DISTRICT_CODE": "330902", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2678", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2243, "SUB_DISTRICT_CODE": "330903", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2679", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2244, "SUB_DISTRICT_CODE": "330906", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2682", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2245, "SUB_DISTRICT_CODE": "330907", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2683", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2246, "SUB_DISTRICT_CODE": "330908", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2684", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2247, "SUB_DISTRICT_CODE": "330909", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2685", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2248, "SUB_DISTRICT_CODE": "330910", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2686", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2249, "SUB_DISTRICT_CODE": "330911", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2687", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2250, "SUB_DISTRICT_CODE": "330912", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2688", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2251, "SUB_DISTRICT_CODE": "330913", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2689", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2252, "SUB_DISTRICT_CODE": "330914", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2690", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2253, "SUB_DISTRICT_CODE": "330915", "PROVINCE_ID": "22", "DISTRICT_ID": "298", "SUB_DISTRICT_ID": "2691", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2254, "SUB_DISTRICT_CODE": "331001", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2694", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2255, "SUB_DISTRICT_CODE": "331002", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2695", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2256, "SUB_DISTRICT_CODE": "331003", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2696", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2257, "SUB_DISTRICT_CODE": "331004", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2697", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2258, "SUB_DISTRICT_CODE": "331005", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2698", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2259, "SUB_DISTRICT_CODE": "331006", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2699", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2260, "SUB_DISTRICT_CODE": "331007", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2700", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2261, "SUB_DISTRICT_CODE": "331008", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2701", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2262, "SUB_DISTRICT_CODE": "331010", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2703", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2263, "SUB_DISTRICT_CODE": "331011", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2704", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2264, "SUB_DISTRICT_CODE": "331012", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2705", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2265, "SUB_DISTRICT_CODE": "331014", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2707", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2266, "SUB_DISTRICT_CODE": "331015", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2708", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2267, "SUB_DISTRICT_CODE": "331016", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2709", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2268, "SUB_DISTRICT_CODE": "331017", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2710", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2269, "SUB_DISTRICT_CODE": "331018", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2711", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2270, "SUB_DISTRICT_CODE": "331022", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2715", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2271, "SUB_DISTRICT_CODE": "331024", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2717", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2272, "SUB_DISTRICT_CODE": "331025", "PROVINCE_ID": "22", "DISTRICT_ID": "299", "SUB_DISTRICT_ID": "2718", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2273, "SUB_DISTRICT_CODE": "331101", "PROVINCE_ID": "22", "DISTRICT_ID": "300", "SUB_DISTRICT_ID": "2725", "ZIPCODE": "33220" },
    { "ZIPCODE_ID": 2274, "SUB_DISTRICT_CODE": "331102", "PROVINCE_ID": "22", "DISTRICT_ID": "300", "SUB_DISTRICT_ID": "2726", "ZIPCODE": "33220" },
    { "ZIPCODE_ID": 2275, "SUB_DISTRICT_CODE": "331201", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2727", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2276, "SUB_DISTRICT_CODE": "331202", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2728", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2277, "SUB_DISTRICT_CODE": "331203", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2729", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2278, "SUB_DISTRICT_CODE": "331204", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2730", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2279, "SUB_DISTRICT_CODE": "331205", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2731", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2280, "SUB_DISTRICT_CODE": "331206", "PROVINCE_ID": "22", "DISTRICT_ID": "301", "SUB_DISTRICT_ID": "2732", "ZIPCODE": "33210" },
    { "ZIPCODE_ID": 2281, "SUB_DISTRICT_CODE": "331301", "PROVINCE_ID": "22", "DISTRICT_ID": "302", "SUB_DISTRICT_ID": "2733", "ZIPCODE": "33250" },
    { "ZIPCODE_ID": 2282, "SUB_DISTRICT_CODE": "331302", "PROVINCE_ID": "22", "DISTRICT_ID": "302", "SUB_DISTRICT_ID": "2734", "ZIPCODE": "33250" },
    { "ZIPCODE_ID": 2283, "SUB_DISTRICT_CODE": "331303", "PROVINCE_ID": "22", "DISTRICT_ID": "302", "SUB_DISTRICT_ID": "2735", "ZIPCODE": "33250" },
    { "ZIPCODE_ID": 2284, "SUB_DISTRICT_CODE": "331304", "PROVINCE_ID": "22", "DISTRICT_ID": "302", "SUB_DISTRICT_ID": "2736", "ZIPCODE": "33250" },
    { "ZIPCODE_ID": 2285, "SUB_DISTRICT_CODE": "331305", "PROVINCE_ID": "22", "DISTRICT_ID": "302", "SUB_DISTRICT_ID": "2737", "ZIPCODE": "33250" },
    { "ZIPCODE_ID": 2286, "SUB_DISTRICT_CODE": "331401", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2738", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2287, "SUB_DISTRICT_CODE": "331402", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2739", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2288, "SUB_DISTRICT_CODE": "331403", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2740", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2289, "SUB_DISTRICT_CODE": "331404", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2741", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2290, "SUB_DISTRICT_CODE": "331405", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2742", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2291, "SUB_DISTRICT_CODE": "331406", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2743", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2292, "SUB_DISTRICT_CODE": "331407", "PROVINCE_ID": "22", "DISTRICT_ID": "303", "SUB_DISTRICT_ID": "2744", "ZIPCODE": "33240" },
    { "ZIPCODE_ID": 2293, "SUB_DISTRICT_CODE": "331501", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2745", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2294, "SUB_DISTRICT_CODE": "331502", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2746", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2295, "SUB_DISTRICT_CODE": "331503", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2747", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2296, "SUB_DISTRICT_CODE": "331504", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2748", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2297, "SUB_DISTRICT_CODE": "331505", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2749", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2298, "SUB_DISTRICT_CODE": "331506", "PROVINCE_ID": "22", "DISTRICT_ID": "304", "SUB_DISTRICT_ID": "2750", "ZIPCODE": "33130" },
    { "ZIPCODE_ID": 2299, "SUB_DISTRICT_CODE": "331601", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2751", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2300, "SUB_DISTRICT_CODE": "331602", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2752", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2301, "SUB_DISTRICT_CODE": "331603", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2753", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2302, "SUB_DISTRICT_CODE": "331604", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2754", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2303, "SUB_DISTRICT_CODE": "331605", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2755", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2304, "SUB_DISTRICT_CODE": "331606", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2756", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2305, "SUB_DISTRICT_CODE": "331607", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2757", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2306, "SUB_DISTRICT_CODE": "331608", "PROVINCE_ID": "22", "DISTRICT_ID": "305", "SUB_DISTRICT_ID": "2758", "ZIPCODE": "33270" },
    { "ZIPCODE_ID": 2307, "SUB_DISTRICT_CODE": "331701", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2759", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2308, "SUB_DISTRICT_CODE": "331702", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2760", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2309, "SUB_DISTRICT_CODE": "331703", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2761", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2310, "SUB_DISTRICT_CODE": "331704", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2762", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2311, "SUB_DISTRICT_CODE": "331705", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2763", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2312, "SUB_DISTRICT_CODE": "331706", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2764", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2313, "SUB_DISTRICT_CODE": "331707", "PROVINCE_ID": "22", "DISTRICT_ID": "306", "SUB_DISTRICT_ID": "2765", "ZIPCODE": "33140" },
    { "ZIPCODE_ID": 2314, "SUB_DISTRICT_CODE": "331801", "PROVINCE_ID": "22", "DISTRICT_ID": "307", "SUB_DISTRICT_ID": "2766", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2315, "SUB_DISTRICT_CODE": "331802", "PROVINCE_ID": "22", "DISTRICT_ID": "307", "SUB_DISTRICT_ID": "2767", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2316, "SUB_DISTRICT_CODE": "331803", "PROVINCE_ID": "22", "DISTRICT_ID": "307", "SUB_DISTRICT_ID": "2768", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2317, "SUB_DISTRICT_CODE": "331901", "PROVINCE_ID": "22", "DISTRICT_ID": "308", "SUB_DISTRICT_ID": "2769", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2318, "SUB_DISTRICT_CODE": "331902", "PROVINCE_ID": "22", "DISTRICT_ID": "308", "SUB_DISTRICT_ID": "2770", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2319, "SUB_DISTRICT_CODE": "331903", "PROVINCE_ID": "22", "DISTRICT_ID": "308", "SUB_DISTRICT_ID": "2771", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2320, "SUB_DISTRICT_CODE": "331904", "PROVINCE_ID": "22", "DISTRICT_ID": "308", "SUB_DISTRICT_ID": "2772", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2321, "SUB_DISTRICT_CODE": "331905", "PROVINCE_ID": "22", "DISTRICT_ID": "308", "SUB_DISTRICT_ID": "2773", "ZIPCODE": "33110" },
    { "ZIPCODE_ID": 2322, "SUB_DISTRICT_CODE": "332001", "PROVINCE_ID": "22", "DISTRICT_ID": "309", "SUB_DISTRICT_ID": "2774", "ZIPCODE": "33230" },
    { "ZIPCODE_ID": 2323, "SUB_DISTRICT_CODE": "332002", "PROVINCE_ID": "22", "DISTRICT_ID": "309", "SUB_DISTRICT_ID": "2775", "ZIPCODE": "33230" },
    { "ZIPCODE_ID": 2324, "SUB_DISTRICT_CODE": "332003", "PROVINCE_ID": "22", "DISTRICT_ID": "309", "SUB_DISTRICT_ID": "2776", "ZIPCODE": "33230" },
    { "ZIPCODE_ID": 2325, "SUB_DISTRICT_CODE": "332004", "PROVINCE_ID": "22", "DISTRICT_ID": "309", "SUB_DISTRICT_ID": "2777", "ZIPCODE": "33230" },
    { "ZIPCODE_ID": 2326, "SUB_DISTRICT_CODE": "332005", "PROVINCE_ID": "22", "DISTRICT_ID": "309", "SUB_DISTRICT_ID": "2778", "ZIPCODE": "33230" },
    { "ZIPCODE_ID": 2327, "SUB_DISTRICT_CODE": "332101", "PROVINCE_ID": "22", "DISTRICT_ID": "310", "SUB_DISTRICT_ID": "2779", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2328, "SUB_DISTRICT_CODE": "332102", "PROVINCE_ID": "22", "DISTRICT_ID": "310", "SUB_DISTRICT_ID": "2780", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2329, "SUB_DISTRICT_CODE": "332103", "PROVINCE_ID": "22", "DISTRICT_ID": "310", "SUB_DISTRICT_ID": "2781", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2330, "SUB_DISTRICT_CODE": "332104", "PROVINCE_ID": "22", "DISTRICT_ID": "310", "SUB_DISTRICT_ID": "2782", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2331, "SUB_DISTRICT_CODE": "332105", "PROVINCE_ID": "22", "DISTRICT_ID": "310", "SUB_DISTRICT_ID": "2783", "ZIPCODE": "33120" },
    { "ZIPCODE_ID": 2332, "SUB_DISTRICT_CODE": "332201", "PROVINCE_ID": "22", "DISTRICT_ID": "311", "SUB_DISTRICT_ID": "2784", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2333, "SUB_DISTRICT_CODE": "332202", "PROVINCE_ID": "22", "DISTRICT_ID": "311", "SUB_DISTRICT_ID": "2785", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2334, "SUB_DISTRICT_CODE": "332203", "PROVINCE_ID": "22", "DISTRICT_ID": "311", "SUB_DISTRICT_ID": "2786", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2335, "SUB_DISTRICT_CODE": "332204", "PROVINCE_ID": "22", "DISTRICT_ID": "311", "SUB_DISTRICT_ID": "2787", "ZIPCODE": "33160" },
    { "ZIPCODE_ID": 2336, "SUB_DISTRICT_CODE": "340101", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2788", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2337, "SUB_DISTRICT_CODE": "340104", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2791", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2338, "SUB_DISTRICT_CODE": "340105", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2792", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2339, "SUB_DISTRICT_CODE": "340107", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2794", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2340, "SUB_DISTRICT_CODE": "340108", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2795", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2341, "SUB_DISTRICT_CODE": "340109", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2796", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2342, "SUB_DISTRICT_CODE": "340111", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2798", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2343, "SUB_DISTRICT_CODE": "340112", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2799", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2344, "SUB_DISTRICT_CODE": "340113", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2800", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2345, "SUB_DISTRICT_CODE": "340116", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2803", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2346, "SUB_DISTRICT_CODE": "340119", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2806", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2347, "SUB_DISTRICT_CODE": "340120", "PROVINCE_ID": "23", "DISTRICT_ID": "312", "SUB_DISTRICT_ID": "2807", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2348, "SUB_DISTRICT_CODE": "340201", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2808", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2349, "SUB_DISTRICT_CODE": "340202", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2809", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2350, "SUB_DISTRICT_CODE": "340203", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2810", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2351, "SUB_DISTRICT_CODE": "340204", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2811", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2352, "SUB_DISTRICT_CODE": "340205", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2812", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2353, "SUB_DISTRICT_CODE": "340206", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2813", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2354, "SUB_DISTRICT_CODE": "340207", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2814", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2355, "SUB_DISTRICT_CODE": "340208", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2815", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2356, "SUB_DISTRICT_CODE": "340209", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2816", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2357, "SUB_DISTRICT_CODE": "340210", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2817", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2358, "SUB_DISTRICT_CODE": "340211", "PROVINCE_ID": "23", "DISTRICT_ID": "313", "SUB_DISTRICT_ID": "2818", "ZIPCODE": "34250" },
    { "ZIPCODE_ID": 2359, "SUB_DISTRICT_CODE": "340301", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2819", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2360, "SUB_DISTRICT_CODE": "340302", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2820", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2361, "SUB_DISTRICT_CODE": "340303", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2821", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2362, "SUB_DISTRICT_CODE": "340304", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2822", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2363, "SUB_DISTRICT_CODE": "340305", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2823", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2364, "SUB_DISTRICT_CODE": "340306", "PROVINCE_ID": "23", "DISTRICT_ID": "314", "SUB_DISTRICT_ID": "2824", "ZIPCODE": "34220" },
    { "ZIPCODE_ID": 2365, "SUB_DISTRICT_CODE": "340401", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2825", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2366, "SUB_DISTRICT_CODE": "340402", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2826", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2367, "SUB_DISTRICT_CODE": "340403", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2827", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2368, "SUB_DISTRICT_CODE": "340404", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2828", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2369, "SUB_DISTRICT_CODE": "340405", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2829", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2370, "SUB_DISTRICT_CODE": "340406", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2830", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2371, "SUB_DISTRICT_CODE": "340407", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2831", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2372, "SUB_DISTRICT_CODE": "340408", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2832", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2373, "SUB_DISTRICT_CODE": "340409", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2833", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2374, "SUB_DISTRICT_CODE": "340410", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2834", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2375, "SUB_DISTRICT_CODE": "340411", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2835", "ZIPCODE": "34320" },
    { "ZIPCODE_ID": 2376, "SUB_DISTRICT_CODE": "340412", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2836", "ZIPCODE": "34320" },
    { "ZIPCODE_ID": 2377, "SUB_DISTRICT_CODE": "340413", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2837", "ZIPCODE": "34320" },
    { "ZIPCODE_ID": 2378, "SUB_DISTRICT_CODE": "340414", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2838", "ZIPCODE": "34320" },
    { "ZIPCODE_ID": 2379, "SUB_DISTRICT_CODE": "340415", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2839", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2380, "SUB_DISTRICT_CODE": "340416", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2840", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2381, "SUB_DISTRICT_CODE": "340417", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2841", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2382, "SUB_DISTRICT_CODE": "340418", "PROVINCE_ID": "23", "DISTRICT_ID": "315", "SUB_DISTRICT_ID": "2842", "ZIPCODE": "34150" },
    { "ZIPCODE_ID": 2383, "SUB_DISTRICT_CODE": "340501", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2843", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2384, "SUB_DISTRICT_CODE": "340503", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2845", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2385, "SUB_DISTRICT_CODE": "340504", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2846", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2386, "SUB_DISTRICT_CODE": "340507", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2849", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2387, "SUB_DISTRICT_CODE": "340508", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2850", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2388, "SUB_DISTRICT_CODE": "340510", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2852", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2389, "SUB_DISTRICT_CODE": "340511", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2853", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2390, "SUB_DISTRICT_CODE": "340512", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2854", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2391, "SUB_DISTRICT_CODE": "340513", "PROVINCE_ID": "23", "DISTRICT_ID": "316", "SUB_DISTRICT_ID": "2855", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2392, "SUB_DISTRICT_CODE": "340701", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2863", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2393, "SUB_DISTRICT_CODE": "340702", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2864", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2394, "SUB_DISTRICT_CODE": "340704", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2866", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2395, "SUB_DISTRICT_CODE": "340706", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2868", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2396, "SUB_DISTRICT_CODE": "340708", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2870", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2397, "SUB_DISTRICT_CODE": "340709", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2871", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2398, "SUB_DISTRICT_CODE": "340710", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2872", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2399, "SUB_DISTRICT_CODE": "340711", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2873", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2400, "SUB_DISTRICT_CODE": "340712", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2874", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2401, "SUB_DISTRICT_CODE": "340713", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2875", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2402, "SUB_DISTRICT_CODE": "340715", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2877", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2403, "SUB_DISTRICT_CODE": "340716", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2878", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2404, "SUB_DISTRICT_CODE": "340717", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2879", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2405, "SUB_DISTRICT_CODE": "340720", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2882", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2406, "SUB_DISTRICT_CODE": "340721", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2883", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2407, "SUB_DISTRICT_CODE": "340723", "PROVINCE_ID": "23", "DISTRICT_ID": "318", "SUB_DISTRICT_ID": "2885", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2408, "SUB_DISTRICT_CODE": "340801", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2886", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2409, "SUB_DISTRICT_CODE": "340802", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2887", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2410, "SUB_DISTRICT_CODE": "340803", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2888", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2411, "SUB_DISTRICT_CODE": "340804", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2889", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2412, "SUB_DISTRICT_CODE": "340805", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2890", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2413, "SUB_DISTRICT_CODE": "340806", "PROVINCE_ID": "23", "DISTRICT_ID": "319", "SUB_DISTRICT_ID": "2891", "ZIPCODE": "34280" },
    { "ZIPCODE_ID": 2414, "SUB_DISTRICT_CODE": "340901", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2892", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2415, "SUB_DISTRICT_CODE": "340903", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2894", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2416, "SUB_DISTRICT_CODE": "340904", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2895", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2417, "SUB_DISTRICT_CODE": "340906", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2897", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2418, "SUB_DISTRICT_CODE": "340907", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2898", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2419, "SUB_DISTRICT_CODE": "340909", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2900", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2420, "SUB_DISTRICT_CODE": "340911", "PROVINCE_ID": "23", "DISTRICT_ID": "320", "SUB_DISTRICT_ID": "2902", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2421, "SUB_DISTRICT_CODE": "341001", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2903", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2422, "SUB_DISTRICT_CODE": "341002", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2904", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2423, "SUB_DISTRICT_CODE": "341003", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2905", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2424, "SUB_DISTRICT_CODE": "341004", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2906", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2425, "SUB_DISTRICT_CODE": "341005", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2907", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2426, "SUB_DISTRICT_CODE": "341006", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2908", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2427, "SUB_DISTRICT_CODE": "341007", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2909", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2428, "SUB_DISTRICT_CODE": "341008", "PROVINCE_ID": "23", "DISTRICT_ID": "321", "SUB_DISTRICT_ID": "2910", "ZIPCODE": "34230" },
    { "ZIPCODE_ID": 2429, "SUB_DISTRICT_CODE": "341101", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2911", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2430, "SUB_DISTRICT_CODE": "341102", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2912", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2431, "SUB_DISTRICT_CODE": "341103", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2913", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2432, "SUB_DISTRICT_CODE": "341104", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2914", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2433, "SUB_DISTRICT_CODE": "341105", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2915", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2434, "SUB_DISTRICT_CODE": "341106", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2916", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2435, "SUB_DISTRICT_CODE": "341107", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2917", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2436, "SUB_DISTRICT_CODE": "341108", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2918", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2437, "SUB_DISTRICT_CODE": "341109", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2919", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2438, "SUB_DISTRICT_CODE": "341110", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2920", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2439, "SUB_DISTRICT_CODE": "341111", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2921", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2440, "SUB_DISTRICT_CODE": "341112", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2922", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2441, "SUB_DISTRICT_CODE": "341113", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2923", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2442, "SUB_DISTRICT_CODE": "341114", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2924", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2443, "SUB_DISTRICT_CODE": "341115", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2925", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2444, "SUB_DISTRICT_CODE": "341116", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2926", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2445, "SUB_DISTRICT_CODE": "341117", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2927", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2446, "SUB_DISTRICT_CODE": "341118", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2928", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2447, "SUB_DISTRICT_CODE": "341119", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2929", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2448, "SUB_DISTRICT_CODE": "341120", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2930", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2449, "SUB_DISTRICT_CODE": "341121", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2931", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2450, "SUB_DISTRICT_CODE": "341122", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2932", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2451, "SUB_DISTRICT_CODE": "341123", "PROVINCE_ID": "23", "DISTRICT_ID": "322", "SUB_DISTRICT_ID": "2933", "ZIPCODE": "34130" },
    { "ZIPCODE_ID": 2452, "SUB_DISTRICT_CODE": "341201", "PROVINCE_ID": "23", "DISTRICT_ID": "323", "SUB_DISTRICT_ID": "2934", "ZIPCODE": "34270" },
    { "ZIPCODE_ID": 2453, "SUB_DISTRICT_CODE": "341202", "PROVINCE_ID": "23", "DISTRICT_ID": "323", "SUB_DISTRICT_ID": "2935", "ZIPCODE": "34270" },
    { "ZIPCODE_ID": 2454, "SUB_DISTRICT_CODE": "341203", "PROVINCE_ID": "23", "DISTRICT_ID": "323", "SUB_DISTRICT_ID": "2936", "ZIPCODE": "34270" },
    { "ZIPCODE_ID": 2455, "SUB_DISTRICT_CODE": "341204", "PROVINCE_ID": "23", "DISTRICT_ID": "323", "SUB_DISTRICT_ID": "2937", "ZIPCODE": "34270" },
    { "ZIPCODE_ID": 2456, "SUB_DISTRICT_CODE": "341205", "PROVINCE_ID": "23", "DISTRICT_ID": "323", "SUB_DISTRICT_ID": "2938", "ZIPCODE": "34270" },
    { "ZIPCODE_ID": 2457, "SUB_DISTRICT_CODE": "341401", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2947", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2458, "SUB_DISTRICT_CODE": "341402", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2948", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2459, "SUB_DISTRICT_CODE": "341403", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2949", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2460, "SUB_DISTRICT_CODE": "341404", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2950", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2461, "SUB_DISTRICT_CODE": "341405", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2951", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2462, "SUB_DISTRICT_CODE": "341406", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2952", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2463, "SUB_DISTRICT_CODE": "341407", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2953", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2464, "SUB_DISTRICT_CODE": "341408", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2954", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2465, "SUB_DISTRICT_CODE": "341409", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2955", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2466, "SUB_DISTRICT_CODE": "341410", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2956", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2467, "SUB_DISTRICT_CODE": "341411", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2957", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2468, "SUB_DISTRICT_CODE": "341412", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2958", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2469, "SUB_DISTRICT_CODE": "341413", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2959", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2470, "SUB_DISTRICT_CODE": "341414", "PROVINCE_ID": "23", "DISTRICT_ID": "325", "SUB_DISTRICT_ID": "2960", "ZIPCODE": "34140" },
    { "ZIPCODE_ID": 2471, "SUB_DISTRICT_CODE": "341501", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2961", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2472, "SUB_DISTRICT_CODE": "341502", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2962", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2473, "SUB_DISTRICT_CODE": "341504", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2964", "ZIPCODE": "34310" },
    { "ZIPCODE_ID": 2474, "SUB_DISTRICT_CODE": "341505", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2965", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2475, "SUB_DISTRICT_CODE": "341507", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2967", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2476, "SUB_DISTRICT_CODE": "341508", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2968", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2477, "SUB_DISTRICT_CODE": "341510", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2970", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2478, "SUB_DISTRICT_CODE": "341511", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2971", "ZIPCODE": "34310" },
    { "ZIPCODE_ID": 2479, "SUB_DISTRICT_CODE": "341515", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2975", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2480, "SUB_DISTRICT_CODE": "341516", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2976", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2481, "SUB_DISTRICT_CODE": "341518", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2978", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2482, "SUB_DISTRICT_CODE": "341520", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2980", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2483, "SUB_DISTRICT_CODE": "341521", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2981", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2484, "SUB_DISTRICT_CODE": "341522", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2982", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2485, "SUB_DISTRICT_CODE": "341524", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2984", "ZIPCODE": "34310" },
    { "ZIPCODE_ID": 2486, "SUB_DISTRICT_CODE": "341526", "PROVINCE_ID": "23", "DISTRICT_ID": "326", "SUB_DISTRICT_ID": "2986", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2487, "SUB_DISTRICT_CODE": "341901", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3024", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2488, "SUB_DISTRICT_CODE": "341902", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3025", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2489, "SUB_DISTRICT_CODE": "341904", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3027", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2490, "SUB_DISTRICT_CODE": "341905", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3028", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2491, "SUB_DISTRICT_CODE": "341906", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3029", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2492, "SUB_DISTRICT_CODE": "341907", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3030", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2493, "SUB_DISTRICT_CODE": "341909", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3032", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2494, "SUB_DISTRICT_CODE": "341910", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3033", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2495, "SUB_DISTRICT_CODE": "341911", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3034", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2496, "SUB_DISTRICT_CODE": "341912", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3035", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2497, "SUB_DISTRICT_CODE": "341913", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3036", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2498, "SUB_DISTRICT_CODE": "341914", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3037", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2499, "SUB_DISTRICT_CODE": "341918", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3041", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2500, "SUB_DISTRICT_CODE": "341919", "PROVINCE_ID": "23", "DISTRICT_ID": "330", "SUB_DISTRICT_ID": "3042", "ZIPCODE": "34110" },
    { "ZIPCODE_ID": 2501, "SUB_DISTRICT_CODE": "342001", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3043", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2502, "SUB_DISTRICT_CODE": "342002", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3044", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2503, "SUB_DISTRICT_CODE": "342003", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3045", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2504, "SUB_DISTRICT_CODE": "342004", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3046", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2505, "SUB_DISTRICT_CODE": "342005", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3047", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2506, "SUB_DISTRICT_CODE": "342006", "PROVINCE_ID": "23", "DISTRICT_ID": "331", "SUB_DISTRICT_ID": "3048", "ZIPCODE": "34330" },
    { "ZIPCODE_ID": 2507, "SUB_DISTRICT_CODE": "342101", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3049", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2508, "SUB_DISTRICT_CODE": "342102", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3050", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2509, "SUB_DISTRICT_CODE": "342103", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3051", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2510, "SUB_DISTRICT_CODE": "342104", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3052", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2511, "SUB_DISTRICT_CODE": "342105", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3053", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2512, "SUB_DISTRICT_CODE": "342106", "PROVINCE_ID": "23", "DISTRICT_ID": "332", "SUB_DISTRICT_ID": "3054", "ZIPCODE": "34340" },
    { "ZIPCODE_ID": 2513, "SUB_DISTRICT_CODE": "342201", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3055", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2514, "SUB_DISTRICT_CODE": "342202", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3056", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2515, "SUB_DISTRICT_CODE": "342203", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3057", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2516, "SUB_DISTRICT_CODE": "342204", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3058", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2517, "SUB_DISTRICT_CODE": "342205", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3059", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2518, "SUB_DISTRICT_CODE": "342206", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3060", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2519, "SUB_DISTRICT_CODE": "342207", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3061", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2520, "SUB_DISTRICT_CODE": "342208", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3062", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2521, "SUB_DISTRICT_CODE": "342209", "PROVINCE_ID": "23", "DISTRICT_ID": "333", "SUB_DISTRICT_ID": "3063", "ZIPCODE": "34360" },
    { "ZIPCODE_ID": 2522, "SUB_DISTRICT_CODE": "342401", "PROVINCE_ID": "23", "DISTRICT_ID": "335", "SUB_DISTRICT_ID": "3070", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2523, "SUB_DISTRICT_CODE": "342402", "PROVINCE_ID": "23", "DISTRICT_ID": "335", "SUB_DISTRICT_ID": "3071", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2524, "SUB_DISTRICT_CODE": "342403", "PROVINCE_ID": "23", "DISTRICT_ID": "335", "SUB_DISTRICT_ID": "3072", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2525, "SUB_DISTRICT_CODE": "342404", "PROVINCE_ID": "23", "DISTRICT_ID": "335", "SUB_DISTRICT_ID": "3073", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2526, "SUB_DISTRICT_CODE": "342501", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3074", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2527, "SUB_DISTRICT_CODE": "342502", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3075", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2528, "SUB_DISTRICT_CODE": "342503", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3076", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2529, "SUB_DISTRICT_CODE": "342504", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3077", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2530, "SUB_DISTRICT_CODE": "342505", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3078", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2531, "SUB_DISTRICT_CODE": "342506", "PROVINCE_ID": "23", "DISTRICT_ID": "336", "SUB_DISTRICT_ID": "3079", "ZIPCODE": "34350" },
    { "ZIPCODE_ID": 2532, "SUB_DISTRICT_CODE": "342602", "PROVINCE_ID": "23", "DISTRICT_ID": "337", "SUB_DISTRICT_ID": "3081", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2533, "SUB_DISTRICT_CODE": "342603", "PROVINCE_ID": "23", "DISTRICT_ID": "337", "SUB_DISTRICT_ID": "3082", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2534, "SUB_DISTRICT_CODE": "342604", "PROVINCE_ID": "23", "DISTRICT_ID": "337", "SUB_DISTRICT_ID": "3083", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2535, "SUB_DISTRICT_CODE": "342605", "PROVINCE_ID": "23", "DISTRICT_ID": "337", "SUB_DISTRICT_ID": "3084", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2536, "SUB_DISTRICT_CODE": "342606", "PROVINCE_ID": "23", "DISTRICT_ID": "337", "SUB_DISTRICT_ID": "3085", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2537, "SUB_DISTRICT_CODE": "342901", "PROVINCE_ID": "23", "DISTRICT_ID": "340", "SUB_DISTRICT_ID": "3093", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2538, "SUB_DISTRICT_CODE": "342902", "PROVINCE_ID": "23", "DISTRICT_ID": "340", "SUB_DISTRICT_ID": "3094", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2539, "SUB_DISTRICT_CODE": "342903", "PROVINCE_ID": "23", "DISTRICT_ID": "340", "SUB_DISTRICT_ID": "3095", "ZIPCODE": "34160" },
    { "ZIPCODE_ID": 2540, "SUB_DISTRICT_CODE": "343001", "PROVINCE_ID": "23", "DISTRICT_ID": "341", "SUB_DISTRICT_ID": "3096", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2541, "SUB_DISTRICT_CODE": "343002", "PROVINCE_ID": "23", "DISTRICT_ID": "341", "SUB_DISTRICT_ID": "3097", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2542, "SUB_DISTRICT_CODE": "343003", "PROVINCE_ID": "23", "DISTRICT_ID": "341", "SUB_DISTRICT_ID": "3098", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2543, "SUB_DISTRICT_CODE": "343004", "PROVINCE_ID": "23", "DISTRICT_ID": "341", "SUB_DISTRICT_ID": "3099", "ZIPCODE": "34170" },
    { "ZIPCODE_ID": 2544, "SUB_DISTRICT_CODE": "343101", "PROVINCE_ID": "23", "DISTRICT_ID": "342", "SUB_DISTRICT_ID": "3100", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2545, "SUB_DISTRICT_CODE": "343102", "PROVINCE_ID": "23", "DISTRICT_ID": "342", "SUB_DISTRICT_ID": "3101", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2546, "SUB_DISTRICT_CODE": "343103", "PROVINCE_ID": "23", "DISTRICT_ID": "342", "SUB_DISTRICT_ID": "3102", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2547, "SUB_DISTRICT_CODE": "343104", "PROVINCE_ID": "23", "DISTRICT_ID": "342", "SUB_DISTRICT_ID": "3103", "ZIPCODE": "34000" },
    { "ZIPCODE_ID": 2548, "SUB_DISTRICT_CODE": "343201", "PROVINCE_ID": "23", "DISTRICT_ID": "343", "SUB_DISTRICT_ID": "3104", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2549, "SUB_DISTRICT_CODE": "343202", "PROVINCE_ID": "23", "DISTRICT_ID": "343", "SUB_DISTRICT_ID": "3105", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2550, "SUB_DISTRICT_CODE": "343203", "PROVINCE_ID": "23", "DISTRICT_ID": "343", "SUB_DISTRICT_ID": "3106", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2551, "SUB_DISTRICT_CODE": "343204", "PROVINCE_ID": "23", "DISTRICT_ID": "343", "SUB_DISTRICT_ID": "3107", "ZIPCODE": "34190" },
    { "ZIPCODE_ID": 2552, "SUB_DISTRICT_CODE": "343301", "PROVINCE_ID": "23", "DISTRICT_ID": "344", "SUB_DISTRICT_ID": "3108", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2553, "SUB_DISTRICT_CODE": "343302", "PROVINCE_ID": "23", "DISTRICT_ID": "344", "SUB_DISTRICT_ID": "3109", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2554, "SUB_DISTRICT_CODE": "343303", "PROVINCE_ID": "23", "DISTRICT_ID": "344", "SUB_DISTRICT_ID": "3110", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2555, "SUB_DISTRICT_CODE": "343304", "PROVINCE_ID": "23", "DISTRICT_ID": "344", "SUB_DISTRICT_ID": "3111", "ZIPCODE": "34260" },
    { "ZIPCODE_ID": 2556, "SUB_DISTRICT_CODE": "350101", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3112", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2557, "SUB_DISTRICT_CODE": "350102", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3113", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2558, "SUB_DISTRICT_CODE": "350103", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3114", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2559, "SUB_DISTRICT_CODE": "350104", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3115", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2560, "SUB_DISTRICT_CODE": "350105", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3116", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2561, "SUB_DISTRICT_CODE": "350106", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3117", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2562, "SUB_DISTRICT_CODE": "350107", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3118", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2563, "SUB_DISTRICT_CODE": "350108", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3119", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2564, "SUB_DISTRICT_CODE": "350109", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3120", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2565, "SUB_DISTRICT_CODE": "350110", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3121", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2566, "SUB_DISTRICT_CODE": "350111", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3122", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2567, "SUB_DISTRICT_CODE": "350112", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3123", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2568, "SUB_DISTRICT_CODE": "350113", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3124", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2569, "SUB_DISTRICT_CODE": "350114", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3125", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2570, "SUB_DISTRICT_CODE": "350115", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3126", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2571, "SUB_DISTRICT_CODE": "350116", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3127", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2572, "SUB_DISTRICT_CODE": "350117", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3128", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2573, "SUB_DISTRICT_CODE": "350118", "PROVINCE_ID": "24", "DISTRICT_ID": "346", "SUB_DISTRICT_ID": "3129", "ZIPCODE": "35000" },
    { "ZIPCODE_ID": 2574, "SUB_DISTRICT_CODE": "350201", "PROVINCE_ID": "24", "DISTRICT_ID": "347", "SUB_DISTRICT_ID": "3134", "ZIPCODE": "35170" },
    { "ZIPCODE_ID": 2575, "SUB_DISTRICT_CODE": "350202", "PROVINCE_ID": "24", "DISTRICT_ID": "347", "SUB_DISTRICT_ID": "3135", "ZIPCODE": "35170" },
    { "ZIPCODE_ID": 2576, "SUB_DISTRICT_CODE": "350203", "PROVINCE_ID": "24", "DISTRICT_ID": "347", "SUB_DISTRICT_ID": "3136", "ZIPCODE": "35170" },
    { "ZIPCODE_ID": 2577, "SUB_DISTRICT_CODE": "350204", "PROVINCE_ID": "24", "DISTRICT_ID": "347", "SUB_DISTRICT_ID": "3137", "ZIPCODE": "35170" },
    { "ZIPCODE_ID": 2578, "SUB_DISTRICT_CODE": "350205", "PROVINCE_ID": "24", "DISTRICT_ID": "347", "SUB_DISTRICT_ID": "3138", "ZIPCODE": "35170" },
    { "ZIPCODE_ID": 2579, "SUB_DISTRICT_CODE": "350301", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3139", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2580, "SUB_DISTRICT_CODE": "350302", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3140", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2581, "SUB_DISTRICT_CODE": "350303", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3141", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2582, "SUB_DISTRICT_CODE": "350304", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3142", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2583, "SUB_DISTRICT_CODE": "350305", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3143", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2584, "SUB_DISTRICT_CODE": "350306", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3144", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2585, "SUB_DISTRICT_CODE": "350307", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3145", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2586, "SUB_DISTRICT_CODE": "350308", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3146", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2587, "SUB_DISTRICT_CODE": "350309", "PROVINCE_ID": "24", "DISTRICT_ID": "348", "SUB_DISTRICT_ID": "3147", "ZIPCODE": "35140" },
    { "ZIPCODE_ID": 2588, "SUB_DISTRICT_CODE": "350401", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3148", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2589, "SUB_DISTRICT_CODE": "350402", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3149", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2590, "SUB_DISTRICT_CODE": "350403", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3150", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2591, "SUB_DISTRICT_CODE": "350404", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3151", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2592, "SUB_DISTRICT_CODE": "350405", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3152", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2593, "SUB_DISTRICT_CODE": "350406", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3153", "ZIPCODE": "35180" },
    { "ZIPCODE_ID": 2594, "SUB_DISTRICT_CODE": "350407", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3154", "ZIPCODE": "35180" },
    { "ZIPCODE_ID": 2595, "SUB_DISTRICT_CODE": "350408", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3155", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2596, "SUB_DISTRICT_CODE": "350409", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3156", "ZIPCODE": "35180" },
    { "ZIPCODE_ID": 2597, "SUB_DISTRICT_CODE": "350410", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3157", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2598, "SUB_DISTRICT_CODE": "350411", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3158", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2599, "SUB_DISTRICT_CODE": "350412", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3159", "ZIPCODE": "35180" },
    { "ZIPCODE_ID": 2600, "SUB_DISTRICT_CODE": "350413", "PROVINCE_ID": "24", "DISTRICT_ID": "349", "SUB_DISTRICT_ID": "3160", "ZIPCODE": "35110" },
    { "ZIPCODE_ID": 2601, "SUB_DISTRICT_CODE": "350501", "PROVINCE_ID": "24", "DISTRICT_ID": "350", "SUB_DISTRICT_ID": "3161", "ZIPCODE": "35150" },
    { "ZIPCODE_ID": 2602, "SUB_DISTRICT_CODE": "350502", "PROVINCE_ID": "24", "DISTRICT_ID": "350", "SUB_DISTRICT_ID": "3162", "ZIPCODE": "35150" },
    { "ZIPCODE_ID": 2603, "SUB_DISTRICT_CODE": "350503", "PROVINCE_ID": "24", "DISTRICT_ID": "350", "SUB_DISTRICT_ID": "3163", "ZIPCODE": "35150" },
    { "ZIPCODE_ID": 2604, "SUB_DISTRICT_CODE": "350504", "PROVINCE_ID": "24", "DISTRICT_ID": "350", "SUB_DISTRICT_ID": "3164", "ZIPCODE": "35150" },
    { "ZIPCODE_ID": 2605, "SUB_DISTRICT_CODE": "350505", "PROVINCE_ID": "24", "DISTRICT_ID": "350", "SUB_DISTRICT_ID": "3165", "ZIPCODE": "35150" },
    { "ZIPCODE_ID": 2606, "SUB_DISTRICT_CODE": "350601", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3166", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2607, "SUB_DISTRICT_CODE": "350602", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3167", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2608, "SUB_DISTRICT_CODE": "350603", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3168", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2609, "SUB_DISTRICT_CODE": "350604", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3169", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2610, "SUB_DISTRICT_CODE": "350605", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3170", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2611, "SUB_DISTRICT_CODE": "350606", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3171", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2612, "SUB_DISTRICT_CODE": "350607", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3172", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2613, "SUB_DISTRICT_CODE": "350608", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3173", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2614, "SUB_DISTRICT_CODE": "350609", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3174", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2615, "SUB_DISTRICT_CODE": "350610", "PROVINCE_ID": "24", "DISTRICT_ID": "351", "SUB_DISTRICT_ID": "3175", "ZIPCODE": "35130" },
    { "ZIPCODE_ID": 2616, "SUB_DISTRICT_CODE": "350701", "PROVINCE_ID": "24", "DISTRICT_ID": "352", "SUB_DISTRICT_ID": "3180", "ZIPCODE": "35160" },
    { "ZIPCODE_ID": 2617, "SUB_DISTRICT_CODE": "350702", "PROVINCE_ID": "24", "DISTRICT_ID": "352", "SUB_DISTRICT_ID": "3181", "ZIPCODE": "35160" },
    { "ZIPCODE_ID": 2618, "SUB_DISTRICT_CODE": "350703", "PROVINCE_ID": "24", "DISTRICT_ID": "352", "SUB_DISTRICT_ID": "3182", "ZIPCODE": "35160" },
    { "ZIPCODE_ID": 2619, "SUB_DISTRICT_CODE": "350704", "PROVINCE_ID": "24", "DISTRICT_ID": "352", "SUB_DISTRICT_ID": "3183", "ZIPCODE": "35160" },
    { "ZIPCODE_ID": 2620, "SUB_DISTRICT_CODE": "350802", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3185", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2621, "SUB_DISTRICT_CODE": "350803", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3186", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2622, "SUB_DISTRICT_CODE": "350805", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3188", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2623, "SUB_DISTRICT_CODE": "350806", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3189", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2624, "SUB_DISTRICT_CODE": "350807", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3190", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2625, "SUB_DISTRICT_CODE": "350810", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3193", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2626, "SUB_DISTRICT_CODE": "350811", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3194", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2627, "SUB_DISTRICT_CODE": "350812", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3195", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2628, "SUB_DISTRICT_CODE": "350813", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3196", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2629, "SUB_DISTRICT_CODE": "350814", "PROVINCE_ID": "24", "DISTRICT_ID": "353", "SUB_DISTRICT_ID": "3197", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2630, "SUB_DISTRICT_CODE": "350901", "PROVINCE_ID": "24", "DISTRICT_ID": "354", "SUB_DISTRICT_ID": "3204", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2631, "SUB_DISTRICT_CODE": "350902", "PROVINCE_ID": "24", "DISTRICT_ID": "354", "SUB_DISTRICT_ID": "3205", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2632, "SUB_DISTRICT_CODE": "350903", "PROVINCE_ID": "24", "DISTRICT_ID": "354", "SUB_DISTRICT_ID": "3206", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2633, "SUB_DISTRICT_CODE": "350904", "PROVINCE_ID": "24", "DISTRICT_ID": "354", "SUB_DISTRICT_ID": "3207", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2634, "SUB_DISTRICT_CODE": "350905", "PROVINCE_ID": "24", "DISTRICT_ID": "354", "SUB_DISTRICT_ID": "3208", "ZIPCODE": "35120" },
    { "ZIPCODE_ID": 2635, "SUB_DISTRICT_CODE": "360101", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3209", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2636, "SUB_DISTRICT_CODE": "360102", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3210", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2637, "SUB_DISTRICT_CODE": "360103", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3211", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2638, "SUB_DISTRICT_CODE": "360104", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3212", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2639, "SUB_DISTRICT_CODE": "360105", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3213", "ZIPCODE": "36240" },
    { "ZIPCODE_ID": 2640, "SUB_DISTRICT_CODE": "360106", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3214", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2641, "SUB_DISTRICT_CODE": "360107", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3215", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2642, "SUB_DISTRICT_CODE": "360108", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3216", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2643, "SUB_DISTRICT_CODE": "360109", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3217", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2644, "SUB_DISTRICT_CODE": "360110", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3218", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2645, "SUB_DISTRICT_CODE": "360111", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3219", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2646, "SUB_DISTRICT_CODE": "360112", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3220", "ZIPCODE": "36240" },
    { "ZIPCODE_ID": 2647, "SUB_DISTRICT_CODE": "360113", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3221", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2648, "SUB_DISTRICT_CODE": "360114", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3222", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2649, "SUB_DISTRICT_CODE": "360115", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3223", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2650, "SUB_DISTRICT_CODE": "360116", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3224", "ZIPCODE": "36240" },
    { "ZIPCODE_ID": 2651, "SUB_DISTRICT_CODE": "360117", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3225", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2652, "SUB_DISTRICT_CODE": "360118", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3226", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2653, "SUB_DISTRICT_CODE": "360119", "PROVINCE_ID": "25", "DISTRICT_ID": "355", "SUB_DISTRICT_ID": "3227", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2654, "SUB_DISTRICT_CODE": "360201", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3230", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2655, "SUB_DISTRICT_CODE": "360202", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3231", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2656, "SUB_DISTRICT_CODE": "360203", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3232", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2657, "SUB_DISTRICT_CODE": "360204", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3233", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2658, "SUB_DISTRICT_CODE": "360205", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3234", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2659, "SUB_DISTRICT_CODE": "360206", "PROVINCE_ID": "25", "DISTRICT_ID": "356", "SUB_DISTRICT_ID": "3235", "ZIPCODE": "36170" },
    { "ZIPCODE_ID": 2660, "SUB_DISTRICT_CODE": "360301", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3236", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2661, "SUB_DISTRICT_CODE": "360302", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3237", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2662, "SUB_DISTRICT_CODE": "360303", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3238", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2663, "SUB_DISTRICT_CODE": "360304", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3239", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2664, "SUB_DISTRICT_CODE": "360305", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3240", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2665, "SUB_DISTRICT_CODE": "360306", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3241", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2666, "SUB_DISTRICT_CODE": "360307", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3242", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2667, "SUB_DISTRICT_CODE": "360308", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3243", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2668, "SUB_DISTRICT_CODE": "360309", "PROVINCE_ID": "25", "DISTRICT_ID": "357", "SUB_DISTRICT_ID": "3244", "ZIPCODE": "36140" },
    { "ZIPCODE_ID": 2669, "SUB_DISTRICT_CODE": "360401", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3245", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2670, "SUB_DISTRICT_CODE": "360402", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3246", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2671, "SUB_DISTRICT_CODE": "360403", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3247", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2672, "SUB_DISTRICT_CODE": "360404", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3248", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2673, "SUB_DISTRICT_CODE": "360405", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3249", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2674, "SUB_DISTRICT_CODE": "360406", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3250", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2675, "SUB_DISTRICT_CODE": "360407", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3251", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2676, "SUB_DISTRICT_CODE": "360408", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3252", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2677, "SUB_DISTRICT_CODE": "360409", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3253", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2678, "SUB_DISTRICT_CODE": "360410", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3254", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2679, "SUB_DISTRICT_CODE": "360411", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3255", "ZIPCODE": "36000" },
    { "ZIPCODE_ID": 2680, "SUB_DISTRICT_CODE": "360412", "PROVINCE_ID": "25", "DISTRICT_ID": "358", "SUB_DISTRICT_ID": "3256", "ZIPCODE": "36120" },
    { "ZIPCODE_ID": 2681, "SUB_DISTRICT_CODE": "360501", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3257", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2682, "SUB_DISTRICT_CODE": "360502", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3258", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2683, "SUB_DISTRICT_CODE": "360503", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3259", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2684, "SUB_DISTRICT_CODE": "360504", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3260", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2685, "SUB_DISTRICT_CODE": "360507", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3263", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2686, "SUB_DISTRICT_CODE": "360508", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3264", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2687, "SUB_DISTRICT_CODE": "360509", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3265", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2688, "SUB_DISTRICT_CODE": "360511", "PROVINCE_ID": "25", "DISTRICT_ID": "359", "SUB_DISTRICT_ID": "3267", "ZIPCODE": "36210" },
    { "ZIPCODE_ID": 2689, "SUB_DISTRICT_CODE": "360601", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3270", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2690, "SUB_DISTRICT_CODE": "360602", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3271", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2691, "SUB_DISTRICT_CODE": "360603", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3272", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2692, "SUB_DISTRICT_CODE": "360605", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3274", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2693, "SUB_DISTRICT_CODE": "360606", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3275", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2694, "SUB_DISTRICT_CODE": "360607", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3276", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2695, "SUB_DISTRICT_CODE": "360610", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3279", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2696, "SUB_DISTRICT_CODE": "360611", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3280", "ZIPCODE": "36220" },
    { "ZIPCODE_ID": 2697, "SUB_DISTRICT_CODE": "360613", "PROVINCE_ID": "25", "DISTRICT_ID": "360", "SUB_DISTRICT_ID": "3282", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2698, "SUB_DISTRICT_CODE": "360701", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3286", "ZIPCODE": "36160" },
    { "ZIPCODE_ID": 2699, "SUB_DISTRICT_CODE": "360702", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3287", "ZIPCODE": "36160" },
    { "ZIPCODE_ID": 2700, "SUB_DISTRICT_CODE": "360703", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3288", "ZIPCODE": "36220" },
    { "ZIPCODE_ID": 2701, "SUB_DISTRICT_CODE": "360704", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3289", "ZIPCODE": "36220" },
    { "ZIPCODE_ID": 2702, "SUB_DISTRICT_CODE": "360705", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3290", "ZIPCODE": "36160" },
    { "ZIPCODE_ID": 2703, "SUB_DISTRICT_CODE": "360706", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3291", "ZIPCODE": "36160" },
    { "ZIPCODE_ID": 2704, "SUB_DISTRICT_CODE": "360707", "PROVINCE_ID": "25", "DISTRICT_ID": "361", "SUB_DISTRICT_ID": "3292", "ZIPCODE": "36160" },
    { "ZIPCODE_ID": 2705, "SUB_DISTRICT_CODE": "360801", "PROVINCE_ID": "25", "DISTRICT_ID": "362", "SUB_DISTRICT_ID": "3297", "ZIPCODE": "36250" },
    { "ZIPCODE_ID": 2706, "SUB_DISTRICT_CODE": "360802", "PROVINCE_ID": "25", "DISTRICT_ID": "362", "SUB_DISTRICT_ID": "3298", "ZIPCODE": "36250" },
    { "ZIPCODE_ID": 2707, "SUB_DISTRICT_CODE": "360803", "PROVINCE_ID": "25", "DISTRICT_ID": "362", "SUB_DISTRICT_ID": "3299", "ZIPCODE": "36250" },
    { "ZIPCODE_ID": 2708, "SUB_DISTRICT_CODE": "360804", "PROVINCE_ID": "25", "DISTRICT_ID": "362", "SUB_DISTRICT_ID": "3300", "ZIPCODE": "36250" },
    { "ZIPCODE_ID": 2709, "SUB_DISTRICT_CODE": "360805", "PROVINCE_ID": "25", "DISTRICT_ID": "362", "SUB_DISTRICT_ID": "3301", "ZIPCODE": "36250" },
    { "ZIPCODE_ID": 2710, "SUB_DISTRICT_CODE": "360901", "PROVINCE_ID": "25", "DISTRICT_ID": "363", "SUB_DISTRICT_ID": "3302", "ZIPCODE": "36230" },
    { "ZIPCODE_ID": 2711, "SUB_DISTRICT_CODE": "360902", "PROVINCE_ID": "25", "DISTRICT_ID": "363", "SUB_DISTRICT_ID": "3303", "ZIPCODE": "36230" },
    { "ZIPCODE_ID": 2712, "SUB_DISTRICT_CODE": "360903", "PROVINCE_ID": "25", "DISTRICT_ID": "363", "SUB_DISTRICT_ID": "3304", "ZIPCODE": "36230" },
    { "ZIPCODE_ID": 2713, "SUB_DISTRICT_CODE": "360904", "PROVINCE_ID": "25", "DISTRICT_ID": "363", "SUB_DISTRICT_ID": "3305", "ZIPCODE": "36230" },
    { "ZIPCODE_ID": 2714, "SUB_DISTRICT_CODE": "360905", "PROVINCE_ID": "25", "DISTRICT_ID": "363", "SUB_DISTRICT_ID": "3306", "ZIPCODE": "36230" },
    { "ZIPCODE_ID": 2715, "SUB_DISTRICT_CODE": "361001", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3307", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2716, "SUB_DISTRICT_CODE": "361002", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3308", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2717, "SUB_DISTRICT_CODE": "361003", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3309", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2718, "SUB_DISTRICT_CODE": "361004", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3310", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2719, "SUB_DISTRICT_CODE": "361005", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3311", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2720, "SUB_DISTRICT_CODE": "361006", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3312", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2721, "SUB_DISTRICT_CODE": "361007", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3313", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2722, "SUB_DISTRICT_CODE": "361008", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3314", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2723, "SUB_DISTRICT_CODE": "361009", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3315", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2724, "SUB_DISTRICT_CODE": "361010", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3316", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2725, "SUB_DISTRICT_CODE": "361011", "PROVINCE_ID": "25", "DISTRICT_ID": "364", "SUB_DISTRICT_ID": "3317", "ZIPCODE": "36110" },
    { "ZIPCODE_ID": 2726, "SUB_DISTRICT_CODE": "361101", "PROVINCE_ID": "25", "DISTRICT_ID": "365", "SUB_DISTRICT_ID": "3318", "ZIPCODE": "36190" },
    { "ZIPCODE_ID": 2727, "SUB_DISTRICT_CODE": "361102", "PROVINCE_ID": "25", "DISTRICT_ID": "365", "SUB_DISTRICT_ID": "3319", "ZIPCODE": "36190" },
    { "ZIPCODE_ID": 2728, "SUB_DISTRICT_CODE": "361103", "PROVINCE_ID": "25", "DISTRICT_ID": "365", "SUB_DISTRICT_ID": "3320", "ZIPCODE": "36190" },
    { "ZIPCODE_ID": 2729, "SUB_DISTRICT_CODE": "361104", "PROVINCE_ID": "25", "DISTRICT_ID": "365", "SUB_DISTRICT_ID": "3321", "ZIPCODE": "36190" },
    { "ZIPCODE_ID": 2730, "SUB_DISTRICT_CODE": "361105", "PROVINCE_ID": "25", "DISTRICT_ID": "365", "SUB_DISTRICT_ID": "3322", "ZIPCODE": "36190" },
    { "ZIPCODE_ID": 2731, "SUB_DISTRICT_CODE": "361201", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3323", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2732, "SUB_DISTRICT_CODE": "361202", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3324", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2733, "SUB_DISTRICT_CODE": "361203", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3325", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2734, "SUB_DISTRICT_CODE": "361204", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3326", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2735, "SUB_DISTRICT_CODE": "361205", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3327", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2736, "SUB_DISTRICT_CODE": "361206", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3328", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2737, "SUB_DISTRICT_CODE": "361207", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3329", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2738, "SUB_DISTRICT_CODE": "361208", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3330", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2739, "SUB_DISTRICT_CODE": "361209", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3331", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2740, "SUB_DISTRICT_CODE": "361210", "PROVINCE_ID": "25", "DISTRICT_ID": "366", "SUB_DISTRICT_ID": "3332", "ZIPCODE": "36150" },
    { "ZIPCODE_ID": 2741, "SUB_DISTRICT_CODE": "361301", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3333", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2742, "SUB_DISTRICT_CODE": "361302", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3334", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2743, "SUB_DISTRICT_CODE": "361303", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3335", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2744, "SUB_DISTRICT_CODE": "361304", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3336", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2745, "SUB_DISTRICT_CODE": "361305", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3337", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2746, "SUB_DISTRICT_CODE": "361306", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3338", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2747, "SUB_DISTRICT_CODE": "361307", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3339", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2748, "SUB_DISTRICT_CODE": "361308", "PROVINCE_ID": "25", "DISTRICT_ID": "367", "SUB_DISTRICT_ID": "3340", "ZIPCODE": "36180" },
    { "ZIPCODE_ID": 2749, "SUB_DISTRICT_CODE": "361401", "PROVINCE_ID": "25", "DISTRICT_ID": "368", "SUB_DISTRICT_ID": "3341", "ZIPCODE": "36260" },
    { "ZIPCODE_ID": 2750, "SUB_DISTRICT_CODE": "361402", "PROVINCE_ID": "25", "DISTRICT_ID": "368", "SUB_DISTRICT_ID": "3342", "ZIPCODE": "36260" },
    { "ZIPCODE_ID": 2751, "SUB_DISTRICT_CODE": "361403", "PROVINCE_ID": "25", "DISTRICT_ID": "368", "SUB_DISTRICT_ID": "3343", "ZIPCODE": "36260" },
    { "ZIPCODE_ID": 2752, "SUB_DISTRICT_CODE": "361404", "PROVINCE_ID": "25", "DISTRICT_ID": "368", "SUB_DISTRICT_ID": "3344", "ZIPCODE": "36260" },
    { "ZIPCODE_ID": 2753, "SUB_DISTRICT_CODE": "361501", "PROVINCE_ID": "25", "DISTRICT_ID": "369", "SUB_DISTRICT_ID": "3345", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2754, "SUB_DISTRICT_CODE": "361502", "PROVINCE_ID": "25", "DISTRICT_ID": "369", "SUB_DISTRICT_ID": "3346", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2755, "SUB_DISTRICT_CODE": "361503", "PROVINCE_ID": "25", "DISTRICT_ID": "369", "SUB_DISTRICT_ID": "3347", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2756, "SUB_DISTRICT_CODE": "361504", "PROVINCE_ID": "25", "DISTRICT_ID": "369", "SUB_DISTRICT_ID": "3348", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2757, "SUB_DISTRICT_CODE": "361601", "PROVINCE_ID": "25", "DISTRICT_ID": "370", "SUB_DISTRICT_ID": "3349", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2758, "SUB_DISTRICT_CODE": "361602", "PROVINCE_ID": "25", "DISTRICT_ID": "370", "SUB_DISTRICT_ID": "3350", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2759, "SUB_DISTRICT_CODE": "361603", "PROVINCE_ID": "25", "DISTRICT_ID": "370", "SUB_DISTRICT_ID": "3351", "ZIPCODE": "36130" },
    { "ZIPCODE_ID": 2760, "SUB_DISTRICT_CODE": "370101", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3352", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2761, "SUB_DISTRICT_CODE": "370102", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3353", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2762, "SUB_DISTRICT_CODE": "370103", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3354", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2763, "SUB_DISTRICT_CODE": "370104", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3355", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2764, "SUB_DISTRICT_CODE": "370105", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3356", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2765, "SUB_DISTRICT_CODE": "370106", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3357", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2766, "SUB_DISTRICT_CODE": "370107", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3358", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2767, "SUB_DISTRICT_CODE": "370108", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3359", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2768, "SUB_DISTRICT_CODE": "370109", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3360", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2769, "SUB_DISTRICT_CODE": "370110", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3361", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2770, "SUB_DISTRICT_CODE": "370111", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3362", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2771, "SUB_DISTRICT_CODE": "370112", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3363", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2772, "SUB_DISTRICT_CODE": "370113", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3364", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2773, "SUB_DISTRICT_CODE": "370114", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3365", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2774, "SUB_DISTRICT_CODE": "370115", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3366", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2775, "SUB_DISTRICT_CODE": "370116", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3367", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2776, "SUB_DISTRICT_CODE": "370117", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3368", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2777, "SUB_DISTRICT_CODE": "370118", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3369", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2778, "SUB_DISTRICT_CODE": "370119", "PROVINCE_ID": "26", "DISTRICT_ID": "380", "SUB_DISTRICT_ID": "3370", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2779, "SUB_DISTRICT_CODE": "370201", "PROVINCE_ID": "26", "DISTRICT_ID": "381", "SUB_DISTRICT_ID": "3381", "ZIPCODE": "37210" },
    { "ZIPCODE_ID": 2780, "SUB_DISTRICT_CODE": "370202", "PROVINCE_ID": "26", "DISTRICT_ID": "381", "SUB_DISTRICT_ID": "3382", "ZIPCODE": "37210" },
    { "ZIPCODE_ID": 2781, "SUB_DISTRICT_CODE": "370203", "PROVINCE_ID": "26", "DISTRICT_ID": "381", "SUB_DISTRICT_ID": "3383", "ZIPCODE": "37210" },
    { "ZIPCODE_ID": 2782, "SUB_DISTRICT_CODE": "370204", "PROVINCE_ID": "26", "DISTRICT_ID": "381", "SUB_DISTRICT_ID": "3384", "ZIPCODE": "37210" },
    { "ZIPCODE_ID": 2783, "SUB_DISTRICT_CODE": "370205", "PROVINCE_ID": "26", "DISTRICT_ID": "381", "SUB_DISTRICT_ID": "3385", "ZIPCODE": "37210" },
    { "ZIPCODE_ID": 2784, "SUB_DISTRICT_CODE": "370301", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3387", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2785, "SUB_DISTRICT_CODE": "370302", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3388", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2786, "SUB_DISTRICT_CODE": "370303", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3389", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2787, "SUB_DISTRICT_CODE": "370304", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3390", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2788, "SUB_DISTRICT_CODE": "370305", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3391", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2789, "SUB_DISTRICT_CODE": "370306", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3392", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2790, "SUB_DISTRICT_CODE": "370307", "PROVINCE_ID": "26", "DISTRICT_ID": "382", "SUB_DISTRICT_ID": "3393", "ZIPCODE": "37110" },
    { "ZIPCODE_ID": 2791, "SUB_DISTRICT_CODE": "370401", "PROVINCE_ID": "26", "DISTRICT_ID": "383", "SUB_DISTRICT_ID": "3394", "ZIPCODE": "37180" },
    { "ZIPCODE_ID": 2792, "SUB_DISTRICT_CODE": "370402", "PROVINCE_ID": "26", "DISTRICT_ID": "383", "SUB_DISTRICT_ID": "3395", "ZIPCODE": "37180" },
    { "ZIPCODE_ID": 2793, "SUB_DISTRICT_CODE": "370403", "PROVINCE_ID": "26", "DISTRICT_ID": "383", "SUB_DISTRICT_ID": "3396", "ZIPCODE": "37180" },
    { "ZIPCODE_ID": 2794, "SUB_DISTRICT_CODE": "370404", "PROVINCE_ID": "26", "DISTRICT_ID": "383", "SUB_DISTRICT_ID": "3397", "ZIPCODE": "37180" },
    { "ZIPCODE_ID": 2795, "SUB_DISTRICT_CODE": "370501", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3401", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2796, "SUB_DISTRICT_CODE": "370502", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3402", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2797, "SUB_DISTRICT_CODE": "370503", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3403", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2798, "SUB_DISTRICT_CODE": "370504", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3404", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2799, "SUB_DISTRICT_CODE": "370505", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3405", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2800, "SUB_DISTRICT_CODE": "370506", "PROVINCE_ID": "26", "DISTRICT_ID": "384", "SUB_DISTRICT_ID": "3406", "ZIPCODE": "37290" },
    { "ZIPCODE_ID": 2801, "SUB_DISTRICT_CODE": "370601", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3407", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2802, "SUB_DISTRICT_CODE": "370602", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3408", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2803, "SUB_DISTRICT_CODE": "370603", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3409", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2804, "SUB_DISTRICT_CODE": "370604", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3410", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2805, "SUB_DISTRICT_CODE": "370605", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3411", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2806, "SUB_DISTRICT_CODE": "370606", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3412", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2807, "SUB_DISTRICT_CODE": "370607", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3413", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2808, "SUB_DISTRICT_CODE": "370608", "PROVINCE_ID": "26", "DISTRICT_ID": "385", "SUB_DISTRICT_ID": "3414", "ZIPCODE": "37240" },
    { "ZIPCODE_ID": 2809, "SUB_DISTRICT_CODE": "370701", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3415", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2810, "SUB_DISTRICT_CODE": "370702", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3416", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2811, "SUB_DISTRICT_CODE": "370703", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3417", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2812, "SUB_DISTRICT_CODE": "370704", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3418", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2813, "SUB_DISTRICT_CODE": "370705", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3419", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2814, "SUB_DISTRICT_CODE": "370706", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3420", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2815, "SUB_DISTRICT_CODE": "370707", "PROVINCE_ID": "26", "DISTRICT_ID": "386", "SUB_DISTRICT_ID": "3421", "ZIPCODE": "37000" },
    { "ZIPCODE_ID": 2816, "SUB_DISTRICT_CODE": "390101", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3422", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2817, "SUB_DISTRICT_CODE": "390102", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3423", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2818, "SUB_DISTRICT_CODE": "390103", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3424", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2819, "SUB_DISTRICT_CODE": "390104", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3425", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2820, "SUB_DISTRICT_CODE": "390105", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3426", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2821, "SUB_DISTRICT_CODE": "390106", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3427", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2822, "SUB_DISTRICT_CODE": "390107", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3428", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2823, "SUB_DISTRICT_CODE": "390108", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3429", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2824, "SUB_DISTRICT_CODE": "390109", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3430", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2825, "SUB_DISTRICT_CODE": "390110", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3431", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2826, "SUB_DISTRICT_CODE": "390111", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3432", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2827, "SUB_DISTRICT_CODE": "390112", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3433", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2828, "SUB_DISTRICT_CODE": "390113", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3434", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2829, "SUB_DISTRICT_CODE": "390114", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3435", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2830, "SUB_DISTRICT_CODE": "390115", "PROVINCE_ID": "27", "DISTRICT_ID": "387", "SUB_DISTRICT_ID": "3436", "ZIPCODE": "39000" },
    { "ZIPCODE_ID": 2831, "SUB_DISTRICT_CODE": "390201", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3437", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2832, "SUB_DISTRICT_CODE": "390202", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3438", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2833, "SUB_DISTRICT_CODE": "390205", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3441", "ZIPCODE": "39350" },
    { "ZIPCODE_ID": 2834, "SUB_DISTRICT_CODE": "390206", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3442", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2835, "SUB_DISTRICT_CODE": "390207", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3443", "ZIPCODE": "39350" },
    { "ZIPCODE_ID": 2836, "SUB_DISTRICT_CODE": "390209", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3445", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2837, "SUB_DISTRICT_CODE": "390210", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3446", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2838, "SUB_DISTRICT_CODE": "390211", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3447", "ZIPCODE": "39350" },
    { "ZIPCODE_ID": 2839, "SUB_DISTRICT_CODE": "390213", "PROVINCE_ID": "27", "DISTRICT_ID": "388", "SUB_DISTRICT_ID": "3449", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2840, "SUB_DISTRICT_CODE": "390301", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3456", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2841, "SUB_DISTRICT_CODE": "390302", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3457", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2842, "SUB_DISTRICT_CODE": "390303", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3458", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2843, "SUB_DISTRICT_CODE": "390304", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3459", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2844, "SUB_DISTRICT_CODE": "390305", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3460", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2845, "SUB_DISTRICT_CODE": "390306", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3461", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2846, "SUB_DISTRICT_CODE": "390307", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3462", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2847, "SUB_DISTRICT_CODE": "390308", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3463", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2848, "SUB_DISTRICT_CODE": "390309", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3464", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2849, "SUB_DISTRICT_CODE": "390310", "PROVINCE_ID": "27", "DISTRICT_ID": "389", "SUB_DISTRICT_ID": "3465", "ZIPCODE": "39140" },
    { "ZIPCODE_ID": 2850, "SUB_DISTRICT_CODE": "390401", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3466", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2851, "SUB_DISTRICT_CODE": "390402", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3467", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2852, "SUB_DISTRICT_CODE": "390403", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3468", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2853, "SUB_DISTRICT_CODE": "390404", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3469", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2854, "SUB_DISTRICT_CODE": "390405", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3470", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2855, "SUB_DISTRICT_CODE": "390406", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3471", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2856, "SUB_DISTRICT_CODE": "390407", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3472", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2857, "SUB_DISTRICT_CODE": "390408", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3473", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2858, "SUB_DISTRICT_CODE": "390409", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3474", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2859, "SUB_DISTRICT_CODE": "390410", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3475", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2860, "SUB_DISTRICT_CODE": "390411", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3476", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2861, "SUB_DISTRICT_CODE": "390412", "PROVINCE_ID": "27", "DISTRICT_ID": "390", "SUB_DISTRICT_ID": "3477", "ZIPCODE": "39180" },
    { "ZIPCODE_ID": 2862, "SUB_DISTRICT_CODE": "390501", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3478", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2863, "SUB_DISTRICT_CODE": "390502", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3479", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2864, "SUB_DISTRICT_CODE": "390503", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3480", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2865, "SUB_DISTRICT_CODE": "390504", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3481", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2866, "SUB_DISTRICT_CODE": "390505", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3482", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2867, "SUB_DISTRICT_CODE": "390506", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3483", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2868, "SUB_DISTRICT_CODE": "390507", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3484", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2869, "SUB_DISTRICT_CODE": "390508", "PROVINCE_ID": "27", "DISTRICT_ID": "391", "SUB_DISTRICT_ID": "3485", "ZIPCODE": "39270" },
    { "ZIPCODE_ID": 2870, "SUB_DISTRICT_CODE": "390601", "PROVINCE_ID": "27", "DISTRICT_ID": "392", "SUB_DISTRICT_ID": "3486", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2871, "SUB_DISTRICT_CODE": "390602", "PROVINCE_ID": "27", "DISTRICT_ID": "392", "SUB_DISTRICT_ID": "3487", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2872, "SUB_DISTRICT_CODE": "390603", "PROVINCE_ID": "27", "DISTRICT_ID": "392", "SUB_DISTRICT_ID": "3488", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2873, "SUB_DISTRICT_CODE": "390604", "PROVINCE_ID": "27", "DISTRICT_ID": "392", "SUB_DISTRICT_ID": "3489", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2874, "SUB_DISTRICT_CODE": "390605", "PROVINCE_ID": "27", "DISTRICT_ID": "392", "SUB_DISTRICT_ID": "3490", "ZIPCODE": "39170" },
    { "ZIPCODE_ID": 2875, "SUB_DISTRICT_CODE": "400101", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3491", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2876, "SUB_DISTRICT_CODE": "400102", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3492", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2877, "SUB_DISTRICT_CODE": "400103", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3493", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2878, "SUB_DISTRICT_CODE": "400104", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3494", "ZIPCODE": "40260" },
    { "ZIPCODE_ID": 2879, "SUB_DISTRICT_CODE": "400105", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3495", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2880, "SUB_DISTRICT_CODE": "400106", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3496", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2881, "SUB_DISTRICT_CODE": "400107", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3497", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2882, "SUB_DISTRICT_CODE": "400108", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3498", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2883, "SUB_DISTRICT_CODE": "400109", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3499", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2884, "SUB_DISTRICT_CODE": "400110", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3500", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2885, "SUB_DISTRICT_CODE": "400111", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3501", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2886, "SUB_DISTRICT_CODE": "400112", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3502", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2887, "SUB_DISTRICT_CODE": "400113", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3503", "ZIPCODE": "40260" },
    { "ZIPCODE_ID": 2888, "SUB_DISTRICT_CODE": "400114", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3504", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2889, "SUB_DISTRICT_CODE": "400115", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3505", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2890, "SUB_DISTRICT_CODE": "400116", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3506", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2891, "SUB_DISTRICT_CODE": "400117", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3507", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2892, "SUB_DISTRICT_CODE": "400118", "PROVINCE_ID": "28", "DISTRICT_ID": "393", "SUB_DISTRICT_ID": "3508", "ZIPCODE": "40000" },
    { "ZIPCODE_ID": 2893, "SUB_DISTRICT_CODE": "400201", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3511", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2894, "SUB_DISTRICT_CODE": "400202", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3512", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2895, "SUB_DISTRICT_CODE": "400203", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3513", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2896, "SUB_DISTRICT_CODE": "400204", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3514", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2897, "SUB_DISTRICT_CODE": "400205", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3515", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2898, "SUB_DISTRICT_CODE": "400206", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3516", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2899, "SUB_DISTRICT_CODE": "400207", "PROVINCE_ID": "28", "DISTRICT_ID": "394", "SUB_DISTRICT_ID": "3517", "ZIPCODE": "40270" },
    { "ZIPCODE_ID": 2900, "SUB_DISTRICT_CODE": "400301", "PROVINCE_ID": "28", "DISTRICT_ID": "395", "SUB_DISTRICT_ID": "3518", "ZIPCODE": "40320" },
    { "ZIPCODE_ID": 2901, "SUB_DISTRICT_CODE": "400302", "PROVINCE_ID": "28", "DISTRICT_ID": "395", "SUB_DISTRICT_ID": "3519", "ZIPCODE": "40320" },
    { "ZIPCODE_ID": 2902, "SUB_DISTRICT_CODE": "400303", "PROVINCE_ID": "28", "DISTRICT_ID": "395", "SUB_DISTRICT_ID": "3520", "ZIPCODE": "40320" },
    { "ZIPCODE_ID": 2903, "SUB_DISTRICT_CODE": "400304", "PROVINCE_ID": "28", "DISTRICT_ID": "395", "SUB_DISTRICT_ID": "3521", "ZIPCODE": "40320" },
    { "ZIPCODE_ID": 2904, "SUB_DISTRICT_CODE": "400305", "PROVINCE_ID": "28", "DISTRICT_ID": "395", "SUB_DISTRICT_ID": "3522", "ZIPCODE": "40320" },
    { "ZIPCODE_ID": 2905, "SUB_DISTRICT_CODE": "400401", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3523", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2906, "SUB_DISTRICT_CODE": "400402", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3524", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2907, "SUB_DISTRICT_CODE": "400403", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3525", "ZIPCODE": "40240" },
    { "ZIPCODE_ID": 2908, "SUB_DISTRICT_CODE": "400404", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3526", "ZIPCODE": "40240" },
    { "ZIPCODE_ID": 2909, "SUB_DISTRICT_CODE": "400405", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3527", "ZIPCODE": "40240" },
    { "ZIPCODE_ID": 2910, "SUB_DISTRICT_CODE": "400406", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3528", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2911, "SUB_DISTRICT_CODE": "400407", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3529", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2912, "SUB_DISTRICT_CODE": "400408", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3530", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2913, "SUB_DISTRICT_CODE": "400409", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3531", "ZIPCODE": "40210" },
    { "ZIPCODE_ID": 2914, "SUB_DISTRICT_CODE": "400410", "PROVINCE_ID": "28", "DISTRICT_ID": "396", "SUB_DISTRICT_ID": "3532", "ZIPCODE": "40240" },
    { "ZIPCODE_ID": 2915, "SUB_DISTRICT_CODE": "400501", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3533", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2916, "SUB_DISTRICT_CODE": "400502", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3534", "ZIPCODE": "40290" },
    { "ZIPCODE_ID": 2917, "SUB_DISTRICT_CODE": "400503", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3535", "ZIPCODE": "40290" },
    { "ZIPCODE_ID": 2918, "SUB_DISTRICT_CODE": "400504", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3536", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2919, "SUB_DISTRICT_CODE": "400505", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3537", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2920, "SUB_DISTRICT_CODE": "400506", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3538", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2921, "SUB_DISTRICT_CODE": "400507", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3539", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2922, "SUB_DISTRICT_CODE": "400508", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3540", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2923, "SUB_DISTRICT_CODE": "400509", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3541", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2924, "SUB_DISTRICT_CODE": "400510", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3542", "ZIPCODE": "40290" },
    { "ZIPCODE_ID": 2925, "SUB_DISTRICT_CODE": "400511", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3543", "ZIPCODE": "40130" },
    { "ZIPCODE_ID": 2926, "SUB_DISTRICT_CODE": "400512", "PROVINCE_ID": "28", "DISTRICT_ID": "397", "SUB_DISTRICT_ID": "3544", "ZIPCODE": "40290" },
    { "ZIPCODE_ID": 2927, "SUB_DISTRICT_CODE": "400601", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3545", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2928, "SUB_DISTRICT_CODE": "400602", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3546", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2929, "SUB_DISTRICT_CODE": "400603", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3547", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2930, "SUB_DISTRICT_CODE": "400604", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3548", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2931, "SUB_DISTRICT_CODE": "400605", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3549", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2932, "SUB_DISTRICT_CODE": "400606", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3550", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2933, "SUB_DISTRICT_CODE": "400607", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3551", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2934, "SUB_DISTRICT_CODE": "400608", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3552", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2935, "SUB_DISTRICT_CODE": "400609", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3553", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2936, "SUB_DISTRICT_CODE": "400610", "PROVINCE_ID": "28", "DISTRICT_ID": "398", "SUB_DISTRICT_ID": "3554", "ZIPCODE": "40220" },
    { "ZIPCODE_ID": 2937, "SUB_DISTRICT_CODE": "400701", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3555", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2938, "SUB_DISTRICT_CODE": "400702", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3556", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2939, "SUB_DISTRICT_CODE": "400703", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3557", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2940, "SUB_DISTRICT_CODE": "400704", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3558", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2941, "SUB_DISTRICT_CODE": "400705", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3559", "ZIPCODE": "40310" },
    { "ZIPCODE_ID": 2942, "SUB_DISTRICT_CODE": "400706", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3560", "ZIPCODE": "40310" },
    { "ZIPCODE_ID": 2943, "SUB_DISTRICT_CODE": "400707", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3561", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2944, "SUB_DISTRICT_CODE": "400708", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3562", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2945, "SUB_DISTRICT_CODE": "400709", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3563", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2946, "SUB_DISTRICT_CODE": "400710", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3564", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2947, "SUB_DISTRICT_CODE": "400711", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3565", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2948, "SUB_DISTRICT_CODE": "400712", "PROVINCE_ID": "28", "DISTRICT_ID": "399", "SUB_DISTRICT_ID": "3566", "ZIPCODE": "40140" },
    { "ZIPCODE_ID": 2949, "SUB_DISTRICT_CODE": "400801", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3567", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2950, "SUB_DISTRICT_CODE": "400802", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3568", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2951, "SUB_DISTRICT_CODE": "400803", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3569", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2952, "SUB_DISTRICT_CODE": "400804", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3570", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2953, "SUB_DISTRICT_CODE": "400805", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3571", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2954, "SUB_DISTRICT_CODE": "400806", "PROVINCE_ID": "28", "DISTRICT_ID": "400", "SUB_DISTRICT_ID": "3572", "ZIPCODE": "40250" },
    { "ZIPCODE_ID": 2955, "SUB_DISTRICT_CODE": "400901", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3573", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2956, "SUB_DISTRICT_CODE": "400902", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3574", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2957, "SUB_DISTRICT_CODE": "400905", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3577", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2958, "SUB_DISTRICT_CODE": "400906", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3578", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2959, "SUB_DISTRICT_CODE": "400907", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3579", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2960, "SUB_DISTRICT_CODE": "400909", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3581", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2961, "SUB_DISTRICT_CODE": "400910", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3582", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2962, "SUB_DISTRICT_CODE": "400911", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3583", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2963, "SUB_DISTRICT_CODE": "400912", "PROVINCE_ID": "28", "DISTRICT_ID": "401", "SUB_DISTRICT_ID": "3584", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 2964, "SUB_DISTRICT_CODE": "401001", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3587", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2965, "SUB_DISTRICT_CODE": "401002", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3588", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2966, "SUB_DISTRICT_CODE": "401005", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3591", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2967, "SUB_DISTRICT_CODE": "401009", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3595", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2968, "SUB_DISTRICT_CODE": "401010", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3596", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2969, "SUB_DISTRICT_CODE": "401011", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3597", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2970, "SUB_DISTRICT_CODE": "401013", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3599", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2971, "SUB_DISTRICT_CODE": "401014", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3600", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2972, "SUB_DISTRICT_CODE": "401016", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3602", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2973, "SUB_DISTRICT_CODE": "401017", "PROVINCE_ID": "28", "DISTRICT_ID": "402", "SUB_DISTRICT_ID": "3603", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 2974, "SUB_DISTRICT_CODE": "401101", "PROVINCE_ID": "28", "DISTRICT_ID": "403", "SUB_DISTRICT_ID": "3611", "ZIPCODE": "40340" },
    { "ZIPCODE_ID": 2975, "SUB_DISTRICT_CODE": "401102", "PROVINCE_ID": "28", "DISTRICT_ID": "403", "SUB_DISTRICT_ID": "3612", "ZIPCODE": "40340" },
    { "ZIPCODE_ID": 2976, "SUB_DISTRICT_CODE": "401103", "PROVINCE_ID": "28", "DISTRICT_ID": "403", "SUB_DISTRICT_ID": "3613", "ZIPCODE": "40340" },
    { "ZIPCODE_ID": 2977, "SUB_DISTRICT_CODE": "401104", "PROVINCE_ID": "28", "DISTRICT_ID": "403", "SUB_DISTRICT_ID": "3614", "ZIPCODE": "40340" },
    { "ZIPCODE_ID": 2978, "SUB_DISTRICT_CODE": "401201", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3615", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2979, "SUB_DISTRICT_CODE": "401203", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3616", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2980, "SUB_DISTRICT_CODE": "401204", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3617", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2981, "SUB_DISTRICT_CODE": "401205", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3618", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2982, "SUB_DISTRICT_CODE": "401206", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3619", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2983, "SUB_DISTRICT_CODE": "401207", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3620", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2984, "SUB_DISTRICT_CODE": "401208", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3621", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2985, "SUB_DISTRICT_CODE": "401209", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3622", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2986, "SUB_DISTRICT_CODE": "401210", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3623", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2987, "SUB_DISTRICT_CODE": "401211", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3624", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2988, "SUB_DISTRICT_CODE": "401212", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3625", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2989, "SUB_DISTRICT_CODE": "401213", "PROVINCE_ID": "28", "DISTRICT_ID": "404", "SUB_DISTRICT_ID": "3626", "ZIPCODE": "40120" },
    { "ZIPCODE_ID": 2990, "SUB_DISTRICT_CODE": "401301", "PROVINCE_ID": "28", "DISTRICT_ID": "405", "SUB_DISTRICT_ID": "3637", "ZIPCODE": "40330" },
    { "ZIPCODE_ID": 2991, "SUB_DISTRICT_CODE": "401302", "PROVINCE_ID": "28", "DISTRICT_ID": "405", "SUB_DISTRICT_ID": "3638", "ZIPCODE": "40330" },
    { "ZIPCODE_ID": 2992, "SUB_DISTRICT_CODE": "401303", "PROVINCE_ID": "28", "DISTRICT_ID": "405", "SUB_DISTRICT_ID": "3639", "ZIPCODE": "40330" },
    { "ZIPCODE_ID": 2993, "SUB_DISTRICT_CODE": "401304", "PROVINCE_ID": "28", "DISTRICT_ID": "405", "SUB_DISTRICT_ID": "3640", "ZIPCODE": "40330" },
    { "ZIPCODE_ID": 2994, "SUB_DISTRICT_CODE": "401305", "PROVINCE_ID": "28", "DISTRICT_ID": "405", "SUB_DISTRICT_ID": "3641", "ZIPCODE": "40330" },
    { "ZIPCODE_ID": 2995, "SUB_DISTRICT_CODE": "401401", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3642", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 2996, "SUB_DISTRICT_CODE": "401402", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3643", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 2997, "SUB_DISTRICT_CODE": "401403", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3644", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 2998, "SUB_DISTRICT_CODE": "401404", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3645", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 2999, "SUB_DISTRICT_CODE": "401405", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3646", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 3000, "SUB_DISTRICT_CODE": "401406", "PROVINCE_ID": "28", "DISTRICT_ID": "406", "SUB_DISTRICT_ID": "3647", "ZIPCODE": "40230" },
    { "ZIPCODE_ID": 3001, "SUB_DISTRICT_CODE": "401501", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3648", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3002, "SUB_DISTRICT_CODE": "401502", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3649", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3003, "SUB_DISTRICT_CODE": "401503", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3650", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3004, "SUB_DISTRICT_CODE": "401504", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3651", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3005, "SUB_DISTRICT_CODE": "401505", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3652", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3006, "SUB_DISTRICT_CODE": "401506", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3653", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3007, "SUB_DISTRICT_CODE": "401507", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3654", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3008, "SUB_DISTRICT_CODE": "401508", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3655", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3009, "SUB_DISTRICT_CODE": "401509", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3656", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3010, "SUB_DISTRICT_CODE": "401510", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3657", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3011, "SUB_DISTRICT_CODE": "401511", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3658", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3012, "SUB_DISTRICT_CODE": "401512", "PROVINCE_ID": "28", "DISTRICT_ID": "407", "SUB_DISTRICT_ID": "3659", "ZIPCODE": "40190" },
    { "ZIPCODE_ID": 3013, "SUB_DISTRICT_CODE": "401601", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3660", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3014, "SUB_DISTRICT_CODE": "401602", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3661", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3015, "SUB_DISTRICT_CODE": "401604", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3663", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3016, "SUB_DISTRICT_CODE": "401605", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3664", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3017, "SUB_DISTRICT_CODE": "401606", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3665", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3018, "SUB_DISTRICT_CODE": "401607", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3666", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3019, "SUB_DISTRICT_CODE": "401608", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3667", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3020, "SUB_DISTRICT_CODE": "401610", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3669", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3021, "SUB_DISTRICT_CODE": "401612", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3671", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3022, "SUB_DISTRICT_CODE": "401613", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3672", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3023, "SUB_DISTRICT_CODE": "401614", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3673", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3024, "SUB_DISTRICT_CODE": "401615", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3674", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3025, "SUB_DISTRICT_CODE": "401616", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3675", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3026, "SUB_DISTRICT_CODE": "401617", "PROVINCE_ID": "28", "DISTRICT_ID": "408", "SUB_DISTRICT_ID": "3676", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3027, "SUB_DISTRICT_CODE": "401701", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3677", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3028, "SUB_DISTRICT_CODE": "401702", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3678", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3029, "SUB_DISTRICT_CODE": "401703", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3679", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3030, "SUB_DISTRICT_CODE": "401704", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3680", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3031, "SUB_DISTRICT_CODE": "401705", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3681", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3032, "SUB_DISTRICT_CODE": "401706", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3682", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3033, "SUB_DISTRICT_CODE": "401707", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3683", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3034, "SUB_DISTRICT_CODE": "401710", "PROVINCE_ID": "28", "DISTRICT_ID": "409", "SUB_DISTRICT_ID": "3686", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3035, "SUB_DISTRICT_CODE": "401801", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3689", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3036, "SUB_DISTRICT_CODE": "401802", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3690", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3037, "SUB_DISTRICT_CODE": "401803", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3691", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3038, "SUB_DISTRICT_CODE": "401804", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3692", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3039, "SUB_DISTRICT_CODE": "401805", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3693", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3040, "SUB_DISTRICT_CODE": "401806", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3694", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3041, "SUB_DISTRICT_CODE": "401807", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3695", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3042, "SUB_DISTRICT_CODE": "401808", "PROVINCE_ID": "28", "DISTRICT_ID": "410", "SUB_DISTRICT_ID": "3696", "ZIPCODE": "40180" },
    { "ZIPCODE_ID": 3043, "SUB_DISTRICT_CODE": "401901", "PROVINCE_ID": "28", "DISTRICT_ID": "411", "SUB_DISTRICT_ID": "3697", "ZIPCODE": "40280" },
    { "ZIPCODE_ID": 3044, "SUB_DISTRICT_CODE": "401902", "PROVINCE_ID": "28", "DISTRICT_ID": "411", "SUB_DISTRICT_ID": "3698", "ZIPCODE": "40280" },
    { "ZIPCODE_ID": 3045, "SUB_DISTRICT_CODE": "401903", "PROVINCE_ID": "28", "DISTRICT_ID": "411", "SUB_DISTRICT_ID": "3699", "ZIPCODE": "40280" },
    { "ZIPCODE_ID": 3046, "SUB_DISTRICT_CODE": "401904", "PROVINCE_ID": "28", "DISTRICT_ID": "411", "SUB_DISTRICT_ID": "3700", "ZIPCODE": "40280" },
    { "ZIPCODE_ID": 3047, "SUB_DISTRICT_CODE": "401905", "PROVINCE_ID": "28", "DISTRICT_ID": "411", "SUB_DISTRICT_ID": "3701", "ZIPCODE": "40280" },
    { "ZIPCODE_ID": 3048, "SUB_DISTRICT_CODE": "402001", "PROVINCE_ID": "28", "DISTRICT_ID": "412", "SUB_DISTRICT_ID": "3702", "ZIPCODE": "40350" },
    { "ZIPCODE_ID": 3049, "SUB_DISTRICT_CODE": "402002", "PROVINCE_ID": "28", "DISTRICT_ID": "412", "SUB_DISTRICT_ID": "3703", "ZIPCODE": "40350" },
    { "ZIPCODE_ID": 3050, "SUB_DISTRICT_CODE": "402003", "PROVINCE_ID": "28", "DISTRICT_ID": "412", "SUB_DISTRICT_ID": "3704", "ZIPCODE": "40350" },
    { "ZIPCODE_ID": 3051, "SUB_DISTRICT_CODE": "402004", "PROVINCE_ID": "28", "DISTRICT_ID": "412", "SUB_DISTRICT_ID": "3705", "ZIPCODE": "40350" },
    { "ZIPCODE_ID": 3052, "SUB_DISTRICT_CODE": "402005", "PROVINCE_ID": "28", "DISTRICT_ID": "412", "SUB_DISTRICT_ID": "3706", "ZIPCODE": "40350" },
    { "ZIPCODE_ID": 3053, "SUB_DISTRICT_CODE": "402101", "PROVINCE_ID": "28", "DISTRICT_ID": "413", "SUB_DISTRICT_ID": "3707", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 3054, "SUB_DISTRICT_CODE": "402102", "PROVINCE_ID": "28", "DISTRICT_ID": "413", "SUB_DISTRICT_ID": "3708", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 3055, "SUB_DISTRICT_CODE": "402103", "PROVINCE_ID": "28", "DISTRICT_ID": "413", "SUB_DISTRICT_ID": "3709", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 3056, "SUB_DISTRICT_CODE": "402104", "PROVINCE_ID": "28", "DISTRICT_ID": "413", "SUB_DISTRICT_ID": "3710", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 3057, "SUB_DISTRICT_CODE": "402105", "PROVINCE_ID": "28", "DISTRICT_ID": "413", "SUB_DISTRICT_ID": "3711", "ZIPCODE": "40170" },
    { "ZIPCODE_ID": 3058, "SUB_DISTRICT_CODE": "402201", "PROVINCE_ID": "28", "DISTRICT_ID": "414", "SUB_DISTRICT_ID": "3712", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3059, "SUB_DISTRICT_CODE": "402202", "PROVINCE_ID": "28", "DISTRICT_ID": "414", "SUB_DISTRICT_ID": "3713", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3060, "SUB_DISTRICT_CODE": "402203", "PROVINCE_ID": "28", "DISTRICT_ID": "414", "SUB_DISTRICT_ID": "3714", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3061, "SUB_DISTRICT_CODE": "402204", "PROVINCE_ID": "28", "DISTRICT_ID": "414", "SUB_DISTRICT_ID": "3715", "ZIPCODE": "40160" },
    { "ZIPCODE_ID": 3062, "SUB_DISTRICT_CODE": "402301", "PROVINCE_ID": "28", "DISTRICT_ID": "415", "SUB_DISTRICT_ID": "3716", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3063, "SUB_DISTRICT_CODE": "402302", "PROVINCE_ID": "28", "DISTRICT_ID": "415", "SUB_DISTRICT_ID": "3717", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3064, "SUB_DISTRICT_CODE": "402303", "PROVINCE_ID": "28", "DISTRICT_ID": "415", "SUB_DISTRICT_ID": "3718", "ZIPCODE": "40150" },
    { "ZIPCODE_ID": 3065, "SUB_DISTRICT_CODE": "402401", "PROVINCE_ID": "28", "DISTRICT_ID": "416", "SUB_DISTRICT_ID": "3719", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3066, "SUB_DISTRICT_CODE": "402402", "PROVINCE_ID": "28", "DISTRICT_ID": "416", "SUB_DISTRICT_ID": "3720", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3067, "SUB_DISTRICT_CODE": "402403", "PROVINCE_ID": "28", "DISTRICT_ID": "416", "SUB_DISTRICT_ID": "3721", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3068, "SUB_DISTRICT_CODE": "402404", "PROVINCE_ID": "28", "DISTRICT_ID": "416", "SUB_DISTRICT_ID": "3722", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3069, "SUB_DISTRICT_CODE": "402501", "PROVINCE_ID": "28", "DISTRICT_ID": "417", "SUB_DISTRICT_ID": "3723", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3070, "SUB_DISTRICT_CODE": "402502", "PROVINCE_ID": "28", "DISTRICT_ID": "417", "SUB_DISTRICT_ID": "3724", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3071, "SUB_DISTRICT_CODE": "402503", "PROVINCE_ID": "28", "DISTRICT_ID": "417", "SUB_DISTRICT_ID": "3725", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3072, "SUB_DISTRICT_CODE": "402504", "PROVINCE_ID": "28", "DISTRICT_ID": "417", "SUB_DISTRICT_ID": "3726", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3073, "SUB_DISTRICT_CODE": "402505", "PROVINCE_ID": "28", "DISTRICT_ID": "417", "SUB_DISTRICT_ID": "3727", "ZIPCODE": "40110" },
    { "ZIPCODE_ID": 3074, "SUB_DISTRICT_CODE": "410101", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3732", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3075, "SUB_DISTRICT_CODE": "410102", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3733", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3076, "SUB_DISTRICT_CODE": "410103", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3734", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3077, "SUB_DISTRICT_CODE": "410104", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3735", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3078, "SUB_DISTRICT_CODE": "410105", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3736", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3079, "SUB_DISTRICT_CODE": "410106", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3737", "ZIPCODE": "41330" },
    { "ZIPCODE_ID": 3080, "SUB_DISTRICT_CODE": "410107", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3738", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3081, "SUB_DISTRICT_CODE": "410108", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3739", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3082, "SUB_DISTRICT_CODE": "410109", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3740", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3083, "SUB_DISTRICT_CODE": "410110", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3741", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3084, "SUB_DISTRICT_CODE": "410111", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3742", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3085, "SUB_DISTRICT_CODE": "410112", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3743", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3086, "SUB_DISTRICT_CODE": "410113", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3744", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3087, "SUB_DISTRICT_CODE": "410114", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3745", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3088, "SUB_DISTRICT_CODE": "410115", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3746", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3089, "SUB_DISTRICT_CODE": "410116", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3747", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3090, "SUB_DISTRICT_CODE": "410117", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3748", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3091, "SUB_DISTRICT_CODE": "410118", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3749", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3092, "SUB_DISTRICT_CODE": "410119", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3750", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3093, "SUB_DISTRICT_CODE": "410120", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3751", "ZIPCODE": "41000" },
    { "ZIPCODE_ID": 3094, "SUB_DISTRICT_CODE": "410121", "PROVINCE_ID": "29", "DISTRICT_ID": "421", "SUB_DISTRICT_ID": "3752", "ZIPCODE": "41330" },
    { "ZIPCODE_ID": 3095, "SUB_DISTRICT_CODE": "410201", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3761", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3096, "SUB_DISTRICT_CODE": "410202", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3762", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3097, "SUB_DISTRICT_CODE": "410203", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3763", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3098, "SUB_DISTRICT_CODE": "410204", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3764", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3099, "SUB_DISTRICT_CODE": "410205", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3765", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3100, "SUB_DISTRICT_CODE": "410206", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3766", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3101, "SUB_DISTRICT_CODE": "410207", "PROVINCE_ID": "29", "DISTRICT_ID": "422", "SUB_DISTRICT_ID": "3767", "ZIPCODE": "41250" },
    { "ZIPCODE_ID": 3102, "SUB_DISTRICT_CODE": "410301", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3768", "ZIPCODE": "41360" },
    { "ZIPCODE_ID": 3103, "SUB_DISTRICT_CODE": "410302", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3769", "ZIPCODE": "41220" },
    { "ZIPCODE_ID": 3104, "SUB_DISTRICT_CODE": "410303", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3770", "ZIPCODE": "41220" },
    { "ZIPCODE_ID": 3105, "SUB_DISTRICT_CODE": "410304", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3771", "ZIPCODE": "41220" },
    { "ZIPCODE_ID": 3106, "SUB_DISTRICT_CODE": "410305", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3772", "ZIPCODE": "41360" },
    { "ZIPCODE_ID": 3107, "SUB_DISTRICT_CODE": "410306", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3773", "ZIPCODE": "41360" },
    { "ZIPCODE_ID": 3108, "SUB_DISTRICT_CODE": "410307", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3774", "ZIPCODE": "41220" },
    { "ZIPCODE_ID": 3109, "SUB_DISTRICT_CODE": "410308", "PROVINCE_ID": "29", "DISTRICT_ID": "423", "SUB_DISTRICT_ID": "3775", "ZIPCODE": "41360" },
    { "ZIPCODE_ID": 3110, "SUB_DISTRICT_CODE": "410401", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3776", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3111, "SUB_DISTRICT_CODE": "410402", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3777", "ZIPCODE": "41370" },
    { "ZIPCODE_ID": 3112, "SUB_DISTRICT_CODE": "410403", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3778", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3113, "SUB_DISTRICT_CODE": "410404", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3779", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3114, "SUB_DISTRICT_CODE": "410406", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3781", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3115, "SUB_DISTRICT_CODE": "410407", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3782", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3116, "SUB_DISTRICT_CODE": "410409", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3784", "ZIPCODE": "41370" },
    { "ZIPCODE_ID": 3117, "SUB_DISTRICT_CODE": "410410", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3785", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3118, "SUB_DISTRICT_CODE": "410411", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3786", "ZIPCODE": "41370" },
    { "ZIPCODE_ID": 3119, "SUB_DISTRICT_CODE": "410413", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3788", "ZIPCODE": "41370" },
    { "ZIPCODE_ID": 3120, "SUB_DISTRICT_CODE": "410414", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3789", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3121, "SUB_DISTRICT_CODE": "410415", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3790", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3122, "SUB_DISTRICT_CODE": "410416", "PROVINCE_ID": "29", "DISTRICT_ID": "424", "SUB_DISTRICT_ID": "3791", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3123, "SUB_DISTRICT_CODE": "410501", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3797", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3124, "SUB_DISTRICT_CODE": "410502", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3798", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3125, "SUB_DISTRICT_CODE": "410503", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3799", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3126, "SUB_DISTRICT_CODE": "410504", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3800", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3127, "SUB_DISTRICT_CODE": "410505", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3801", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3128, "SUB_DISTRICT_CODE": "410506", "PROVINCE_ID": "29", "DISTRICT_ID": "425", "SUB_DISTRICT_ID": "3802", "ZIPCODE": "41240" },
    { "ZIPCODE_ID": 3129, "SUB_DISTRICT_CODE": "410601", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3803", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3130, "SUB_DISTRICT_CODE": "410602", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3804", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3131, "SUB_DISTRICT_CODE": "410605", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3807", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3132, "SUB_DISTRICT_CODE": "410606", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3808", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3133, "SUB_DISTRICT_CODE": "410607", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3809", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3134, "SUB_DISTRICT_CODE": "410609", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3811", "ZIPCODE": "41320" },
    { "ZIPCODE_ID": 3135, "SUB_DISTRICT_CODE": "410610", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3812", "ZIPCODE": "41320" },
    { "ZIPCODE_ID": 3136, "SUB_DISTRICT_CODE": "410611", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3813", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3137, "SUB_DISTRICT_CODE": "410612", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3814", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3138, "SUB_DISTRICT_CODE": "410614", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3816", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3139, "SUB_DISTRICT_CODE": "410617", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3819", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3140, "SUB_DISTRICT_CODE": "410618", "PROVINCE_ID": "29", "DISTRICT_ID": "426", "SUB_DISTRICT_ID": "3820", "ZIPCODE": "41320" },
    { "ZIPCODE_ID": 3141, "SUB_DISTRICT_CODE": "410701", "PROVINCE_ID": "29", "DISTRICT_ID": "427", "SUB_DISTRICT_ID": "3828", "ZIPCODE": "41310" },
    { "ZIPCODE_ID": 3142, "SUB_DISTRICT_CODE": "410702", "PROVINCE_ID": "29", "DISTRICT_ID": "427", "SUB_DISTRICT_ID": "3829", "ZIPCODE": "41310" },
    { "ZIPCODE_ID": 3143, "SUB_DISTRICT_CODE": "410703", "PROVINCE_ID": "29", "DISTRICT_ID": "427", "SUB_DISTRICT_ID": "3830", "ZIPCODE": "41310" },
    { "ZIPCODE_ID": 3144, "SUB_DISTRICT_CODE": "410704", "PROVINCE_ID": "29", "DISTRICT_ID": "427", "SUB_DISTRICT_ID": "3831", "ZIPCODE": "41310" },
    { "ZIPCODE_ID": 3145, "SUB_DISTRICT_CODE": "410801", "PROVINCE_ID": "29", "DISTRICT_ID": "428", "SUB_DISTRICT_ID": "3832", "ZIPCODE": "41290" },
    { "ZIPCODE_ID": 3146, "SUB_DISTRICT_CODE": "410802", "PROVINCE_ID": "29", "DISTRICT_ID": "428", "SUB_DISTRICT_ID": "3833", "ZIPCODE": "41290" },
    { "ZIPCODE_ID": 3147, "SUB_DISTRICT_CODE": "410803", "PROVINCE_ID": "29", "DISTRICT_ID": "428", "SUB_DISTRICT_ID": "3834", "ZIPCODE": "41290" },
    { "ZIPCODE_ID": 3148, "SUB_DISTRICT_CODE": "410804", "PROVINCE_ID": "29", "DISTRICT_ID": "428", "SUB_DISTRICT_ID": "3835", "ZIPCODE": "41290" },
    { "ZIPCODE_ID": 3149, "SUB_DISTRICT_CODE": "410901", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3836", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3150, "SUB_DISTRICT_CODE": "410902", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3837", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3151, "SUB_DISTRICT_CODE": "410903", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3838", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3152, "SUB_DISTRICT_CODE": "410904", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3839", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3153, "SUB_DISTRICT_CODE": "410905", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3840", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3154, "SUB_DISTRICT_CODE": "410906", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3841", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3155, "SUB_DISTRICT_CODE": "410907", "PROVINCE_ID": "29", "DISTRICT_ID": "429", "SUB_DISTRICT_ID": "3842", "ZIPCODE": "41230" },
    { "ZIPCODE_ID": 3156, "SUB_DISTRICT_CODE": "411001", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3843", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3157, "SUB_DISTRICT_CODE": "411002", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3844", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3158, "SUB_DISTRICT_CODE": "411003", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3845", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3159, "SUB_DISTRICT_CODE": "411004", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3846", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3160, "SUB_DISTRICT_CODE": "411005", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3847", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3161, "SUB_DISTRICT_CODE": "411006", "PROVINCE_ID": "29", "DISTRICT_ID": "430", "SUB_DISTRICT_ID": "3848", "ZIPCODE": "41280" },
    { "ZIPCODE_ID": 3162, "SUB_DISTRICT_CODE": "411101", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3849", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3163, "SUB_DISTRICT_CODE": "411102", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3850", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3164, "SUB_DISTRICT_CODE": "411103", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3851", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3165, "SUB_DISTRICT_CODE": "411104", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3852", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3166, "SUB_DISTRICT_CODE": "411105", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3853", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3167, "SUB_DISTRICT_CODE": "411106", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3854", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3168, "SUB_DISTRICT_CODE": "411107", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3855", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3169, "SUB_DISTRICT_CODE": "411108", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3856", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3170, "SUB_DISTRICT_CODE": "411109", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3857", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3171, "SUB_DISTRICT_CODE": "411110", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3858", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3172, "SUB_DISTRICT_CODE": "411111", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3859", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3173, "SUB_DISTRICT_CODE": "411112", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3860", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3174, "SUB_DISTRICT_CODE": "411113", "PROVINCE_ID": "29", "DISTRICT_ID": "431", "SUB_DISTRICT_ID": "3861", "ZIPCODE": "41190" },
    { "ZIPCODE_ID": 3175, "SUB_DISTRICT_CODE": "411701", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3928", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3176, "SUB_DISTRICT_CODE": "411702", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3929", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3177, "SUB_DISTRICT_CODE": "411703", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3930", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3178, "SUB_DISTRICT_CODE": "411704", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3931", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3179, "SUB_DISTRICT_CODE": "411705", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3932", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3180, "SUB_DISTRICT_CODE": "411706", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3933", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3181, "SUB_DISTRICT_CODE": "411707", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3934", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3182, "SUB_DISTRICT_CODE": "411708", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3935", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3183, "SUB_DISTRICT_CODE": "411709", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3936", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3184, "SUB_DISTRICT_CODE": "411710", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3937", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3185, "SUB_DISTRICT_CODE": "411711", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3938", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3186, "SUB_DISTRICT_CODE": "411712", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3939", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3187, "SUB_DISTRICT_CODE": "411713", "PROVINCE_ID": "29", "DISTRICT_ID": "437", "SUB_DISTRICT_ID": "3940", "ZIPCODE": "41160" },
    { "ZIPCODE_ID": 3188, "SUB_DISTRICT_CODE": "411801", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3942", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3189, "SUB_DISTRICT_CODE": "411802", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3943", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3190, "SUB_DISTRICT_CODE": "411805", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3946", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3191, "SUB_DISTRICT_CODE": "411806", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3947", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3192, "SUB_DISTRICT_CODE": "411807", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3948", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3193, "SUB_DISTRICT_CODE": "411810", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3951", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3194, "SUB_DISTRICT_CODE": "411812", "PROVINCE_ID": "29", "DISTRICT_ID": "438", "SUB_DISTRICT_ID": "3953", "ZIPCODE": "41210" },
    { "ZIPCODE_ID": 3195, "SUB_DISTRICT_CODE": "411901", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3956", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3196, "SUB_DISTRICT_CODE": "411902", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3957", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3197, "SUB_DISTRICT_CODE": "411903", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3958", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3198, "SUB_DISTRICT_CODE": "411904", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3959", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3199, "SUB_DISTRICT_CODE": "411905", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3960", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3200, "SUB_DISTRICT_CODE": "411906", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3961", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3201, "SUB_DISTRICT_CODE": "411907", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3962", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3202, "SUB_DISTRICT_CODE": "411908", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3963", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3203, "SUB_DISTRICT_CODE": "411909", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3964", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3204, "SUB_DISTRICT_CODE": "411910", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3965", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3205, "SUB_DISTRICT_CODE": "411911", "PROVINCE_ID": "29", "DISTRICT_ID": "439", "SUB_DISTRICT_ID": "3966", "ZIPCODE": "41150" },
    { "ZIPCODE_ID": 3206, "SUB_DISTRICT_CODE": "412001", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3970", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3207, "SUB_DISTRICT_CODE": "412002", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3971", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3208, "SUB_DISTRICT_CODE": "412003", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3972", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3209, "SUB_DISTRICT_CODE": "412004", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3973", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3210, "SUB_DISTRICT_CODE": "412005", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3974", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3211, "SUB_DISTRICT_CODE": "412006", "PROVINCE_ID": "29", "DISTRICT_ID": "440", "SUB_DISTRICT_ID": "3975", "ZIPCODE": "41260" },
    { "ZIPCODE_ID": 3212, "SUB_DISTRICT_CODE": "412101", "PROVINCE_ID": "29", "DISTRICT_ID": "441", "SUB_DISTRICT_ID": "3976", "ZIPCODE": "41340" },
    { "ZIPCODE_ID": 3213, "SUB_DISTRICT_CODE": "412102", "PROVINCE_ID": "29", "DISTRICT_ID": "441", "SUB_DISTRICT_ID": "3977", "ZIPCODE": "41340" },
    { "ZIPCODE_ID": 3214, "SUB_DISTRICT_CODE": "412103", "PROVINCE_ID": "29", "DISTRICT_ID": "441", "SUB_DISTRICT_ID": "3978", "ZIPCODE": "41340" },
    { "ZIPCODE_ID": 3215, "SUB_DISTRICT_CODE": "412104", "PROVINCE_ID": "29", "DISTRICT_ID": "441", "SUB_DISTRICT_ID": "3979", "ZIPCODE": "41340" },
    { "ZIPCODE_ID": 3216, "SUB_DISTRICT_CODE": "412201", "PROVINCE_ID": "29", "DISTRICT_ID": "442", "SUB_DISTRICT_ID": "3980", "ZIPCODE": "41380" },
    { "ZIPCODE_ID": 3217, "SUB_DISTRICT_CODE": "412202", "PROVINCE_ID": "29", "DISTRICT_ID": "442", "SUB_DISTRICT_ID": "3981", "ZIPCODE": "41380" },
    { "ZIPCODE_ID": 3218, "SUB_DISTRICT_CODE": "412203", "PROVINCE_ID": "29", "DISTRICT_ID": "442", "SUB_DISTRICT_ID": "3982", "ZIPCODE": "41380" },
    { "ZIPCODE_ID": 3219, "SUB_DISTRICT_CODE": "412204", "PROVINCE_ID": "29", "DISTRICT_ID": "442", "SUB_DISTRICT_ID": "3983", "ZIPCODE": "41380" },
    { "ZIPCODE_ID": 3220, "SUB_DISTRICT_CODE": "412301", "PROVINCE_ID": "29", "DISTRICT_ID": "443", "SUB_DISTRICT_ID": "3984", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3221, "SUB_DISTRICT_CODE": "412302", "PROVINCE_ID": "29", "DISTRICT_ID": "443", "SUB_DISTRICT_ID": "3985", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3222, "SUB_DISTRICT_CODE": "412303", "PROVINCE_ID": "29", "DISTRICT_ID": "443", "SUB_DISTRICT_ID": "3986", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3223, "SUB_DISTRICT_CODE": "412401", "PROVINCE_ID": "29", "DISTRICT_ID": "444", "SUB_DISTRICT_ID": "3987", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3224, "SUB_DISTRICT_CODE": "412402", "PROVINCE_ID": "29", "DISTRICT_ID": "444", "SUB_DISTRICT_ID": "3988", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3225, "SUB_DISTRICT_CODE": "412403", "PROVINCE_ID": "29", "DISTRICT_ID": "444", "SUB_DISTRICT_ID": "3989", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3226, "SUB_DISTRICT_CODE": "412404", "PROVINCE_ID": "29", "DISTRICT_ID": "444", "SUB_DISTRICT_ID": "3990", "ZIPCODE": "41130" },
    { "ZIPCODE_ID": 3227, "SUB_DISTRICT_CODE": "412501", "PROVINCE_ID": "29", "DISTRICT_ID": "445", "SUB_DISTRICT_ID": "3991", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3228, "SUB_DISTRICT_CODE": "412502", "PROVINCE_ID": "29", "DISTRICT_ID": "445", "SUB_DISTRICT_ID": "3992", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3229, "SUB_DISTRICT_CODE": "412503", "PROVINCE_ID": "29", "DISTRICT_ID": "445", "SUB_DISTRICT_ID": "3993", "ZIPCODE": "41110" },
    { "ZIPCODE_ID": 3230, "SUB_DISTRICT_CODE": "420101", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3994", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3231, "SUB_DISTRICT_CODE": "420102", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3995", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3232, "SUB_DISTRICT_CODE": "420103", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3996", "ZIPCODE": "42100" },
    { "ZIPCODE_ID": 3233, "SUB_DISTRICT_CODE": "420104", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3997", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3234, "SUB_DISTRICT_CODE": "420105", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3998", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3235, "SUB_DISTRICT_CODE": "420106", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "3999", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3236, "SUB_DISTRICT_CODE": "420107", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4000", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3237, "SUB_DISTRICT_CODE": "420108", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4001", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3238, "SUB_DISTRICT_CODE": "420109", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4002", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3239, "SUB_DISTRICT_CODE": "420110", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4003", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3240, "SUB_DISTRICT_CODE": "420111", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4004", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3241, "SUB_DISTRICT_CODE": "420112", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4005", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3242, "SUB_DISTRICT_CODE": "420113", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4006", "ZIPCODE": "42100" },
    { "ZIPCODE_ID": 3243, "SUB_DISTRICT_CODE": "420114", "PROVINCE_ID": "30", "DISTRICT_ID": "446", "SUB_DISTRICT_ID": "4007", "ZIPCODE": "42000" },
    { "ZIPCODE_ID": 3244, "SUB_DISTRICT_CODE": "420201", "PROVINCE_ID": "30", "DISTRICT_ID": "447", "SUB_DISTRICT_ID": "4008", "ZIPCODE": "42210" },
    { "ZIPCODE_ID": 3245, "SUB_DISTRICT_CODE": "420202", "PROVINCE_ID": "30", "DISTRICT_ID": "447", "SUB_DISTRICT_ID": "4009", "ZIPCODE": "42210" },
    { "ZIPCODE_ID": 3246, "SUB_DISTRICT_CODE": "420203", "PROVINCE_ID": "30", "DISTRICT_ID": "447", "SUB_DISTRICT_ID": "4010", "ZIPCODE": "42210" },
    { "ZIPCODE_ID": 3247, "SUB_DISTRICT_CODE": "420204", "PROVINCE_ID": "30", "DISTRICT_ID": "447", "SUB_DISTRICT_ID": "4011", "ZIPCODE": "42210" },
    { "ZIPCODE_ID": 3248, "SUB_DISTRICT_CODE": "420301", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4012", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3249, "SUB_DISTRICT_CODE": "420302", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4013", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3250, "SUB_DISTRICT_CODE": "420303", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4014", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3251, "SUB_DISTRICT_CODE": "420304", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4015", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3252, "SUB_DISTRICT_CODE": "420305", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4016", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3253, "SUB_DISTRICT_CODE": "420306", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4017", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3254, "SUB_DISTRICT_CODE": "420307", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4018", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3255, "SUB_DISTRICT_CODE": "420308", "PROVINCE_ID": "30", "DISTRICT_ID": "448", "SUB_DISTRICT_ID": "4019", "ZIPCODE": "42110" },
    { "ZIPCODE_ID": 3256, "SUB_DISTRICT_CODE": "420401", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4020", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3257, "SUB_DISTRICT_CODE": "420402", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4021", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3258, "SUB_DISTRICT_CODE": "420403", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4022", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3259, "SUB_DISTRICT_CODE": "420404", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4023", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3260, "SUB_DISTRICT_CODE": "420405", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4024", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3261, "SUB_DISTRICT_CODE": "420406", "PROVINCE_ID": "30", "DISTRICT_ID": "449", "SUB_DISTRICT_ID": "4025", "ZIPCODE": "42150" },
    { "ZIPCODE_ID": 3262, "SUB_DISTRICT_CODE": "420501", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4026", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3263, "SUB_DISTRICT_CODE": "420502", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4027", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3264, "SUB_DISTRICT_CODE": "420503", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4028", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3265, "SUB_DISTRICT_CODE": "420504", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4029", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3266, "SUB_DISTRICT_CODE": "420505", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4030", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3267, "SUB_DISTRICT_CODE": "420506", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4031", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3268, "SUB_DISTRICT_CODE": "420507", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4032", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3269, "SUB_DISTRICT_CODE": "420508", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4033", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3270, "SUB_DISTRICT_CODE": "420509", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4034", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3271, "SUB_DISTRICT_CODE": "420510", "PROVINCE_ID": "30", "DISTRICT_ID": "450", "SUB_DISTRICT_ID": "4035", "ZIPCODE": "42120" },
    { "ZIPCODE_ID": 3272, "SUB_DISTRICT_CODE": "420601", "PROVINCE_ID": "30", "DISTRICT_ID": "451", "SUB_DISTRICT_ID": "4043", "ZIPCODE": "42170" },
    { "ZIPCODE_ID": 3273, "SUB_DISTRICT_CODE": "420602", "PROVINCE_ID": "30", "DISTRICT_ID": "451", "SUB_DISTRICT_ID": "4044", "ZIPCODE": "42170" },
    { "ZIPCODE_ID": 3274, "SUB_DISTRICT_CODE": "420603", "PROVINCE_ID": "30", "DISTRICT_ID": "451", "SUB_DISTRICT_ID": "4045", "ZIPCODE": "42170" },
    { "ZIPCODE_ID": 3275, "SUB_DISTRICT_CODE": "420604", "PROVINCE_ID": "30", "DISTRICT_ID": "451", "SUB_DISTRICT_ID": "4046", "ZIPCODE": "42170" },
    { "ZIPCODE_ID": 3276, "SUB_DISTRICT_CODE": "420605", "PROVINCE_ID": "30", "DISTRICT_ID": "451", "SUB_DISTRICT_ID": "4047", "ZIPCODE": "42170" },
    { "ZIPCODE_ID": 3277, "SUB_DISTRICT_CODE": "420701", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4048", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3278, "SUB_DISTRICT_CODE": "420702", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4049", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3279, "SUB_DISTRICT_CODE": "420703", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4050", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3280, "SUB_DISTRICT_CODE": "420704", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4051", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3281, "SUB_DISTRICT_CODE": "420705", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4052", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3282, "SUB_DISTRICT_CODE": "420706", "PROVINCE_ID": "30", "DISTRICT_ID": "452", "SUB_DISTRICT_ID": "4053", "ZIPCODE": "42160" },
    { "ZIPCODE_ID": 3283, "SUB_DISTRICT_CODE": "420801", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4054", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3284, "SUB_DISTRICT_CODE": "420802", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4055", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3285, "SUB_DISTRICT_CODE": "420803", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4056", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3286, "SUB_DISTRICT_CODE": "420804", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4057", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3287, "SUB_DISTRICT_CODE": "420805", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4058", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3288, "SUB_DISTRICT_CODE": "420806", "PROVINCE_ID": "30", "DISTRICT_ID": "453", "SUB_DISTRICT_ID": "4059", "ZIPCODE": "42140" },
    { "ZIPCODE_ID": 3289, "SUB_DISTRICT_CODE": "420901", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4060", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3290, "SUB_DISTRICT_CODE": "420902", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4061", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3291, "SUB_DISTRICT_CODE": "420903", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4062", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3292, "SUB_DISTRICT_CODE": "420904", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4063", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3293, "SUB_DISTRICT_CODE": "420905", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4064", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3294, "SUB_DISTRICT_CODE": "420906", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4065", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3295, "SUB_DISTRICT_CODE": "420910", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4069", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3296, "SUB_DISTRICT_CODE": "420911", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4070", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3297, "SUB_DISTRICT_CODE": "420912", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4071", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3298, "SUB_DISTRICT_CODE": "420913", "PROVINCE_ID": "30", "DISTRICT_ID": "454", "SUB_DISTRICT_ID": "4072", "ZIPCODE": "42130" },
    { "ZIPCODE_ID": 3299, "SUB_DISTRICT_CODE": "421001", "PROVINCE_ID": "30", "DISTRICT_ID": "455", "SUB_DISTRICT_ID": "4076", "ZIPCODE": "42180" },
    { "ZIPCODE_ID": 3300, "SUB_DISTRICT_CODE": "421005", "PROVINCE_ID": "30", "DISTRICT_ID": "455", "SUB_DISTRICT_ID": "4080", "ZIPCODE": "42180" },
    { "ZIPCODE_ID": 3301, "SUB_DISTRICT_CODE": "421007", "PROVINCE_ID": "30", "DISTRICT_ID": "455", "SUB_DISTRICT_ID": "4082", "ZIPCODE": "42180" },
    { "ZIPCODE_ID": 3302, "SUB_DISTRICT_CODE": "421010", "PROVINCE_ID": "30", "DISTRICT_ID": "455", "SUB_DISTRICT_ID": "4085", "ZIPCODE": "42180" },
    { "ZIPCODE_ID": 3303, "SUB_DISTRICT_CODE": "421101", "PROVINCE_ID": "30", "DISTRICT_ID": "456", "SUB_DISTRICT_ID": "4087", "ZIPCODE": "42230" },
    { "ZIPCODE_ID": 3304, "SUB_DISTRICT_CODE": "421102", "PROVINCE_ID": "30", "DISTRICT_ID": "456", "SUB_DISTRICT_ID": "4088", "ZIPCODE": "42230" },
    { "ZIPCODE_ID": 3305, "SUB_DISTRICT_CODE": "421104", "PROVINCE_ID": "30", "DISTRICT_ID": "456", "SUB_DISTRICT_ID": "4090", "ZIPCODE": "42230" },
    { "ZIPCODE_ID": 3306, "SUB_DISTRICT_CODE": "421105", "PROVINCE_ID": "30", "DISTRICT_ID": "456", "SUB_DISTRICT_ID": "4091", "ZIPCODE": "42230" },
    { "ZIPCODE_ID": 3307, "SUB_DISTRICT_CODE": "421106", "PROVINCE_ID": "30", "DISTRICT_ID": "456", "SUB_DISTRICT_ID": "4092", "ZIPCODE": "42230" },
    { "ZIPCODE_ID": 3308, "SUB_DISTRICT_CODE": "421201", "PROVINCE_ID": "30", "DISTRICT_ID": "457", "SUB_DISTRICT_ID": "4093", "ZIPCODE": "42240" },
    { "ZIPCODE_ID": 3309, "SUB_DISTRICT_CODE": "421202", "PROVINCE_ID": "30", "DISTRICT_ID": "457", "SUB_DISTRICT_ID": "4094", "ZIPCODE": "42240" },
    { "ZIPCODE_ID": 3310, "SUB_DISTRICT_CODE": "421203", "PROVINCE_ID": "30", "DISTRICT_ID": "457", "SUB_DISTRICT_ID": "4095", "ZIPCODE": "42240" },
    { "ZIPCODE_ID": 3311, "SUB_DISTRICT_CODE": "421204", "PROVINCE_ID": "30", "DISTRICT_ID": "457", "SUB_DISTRICT_ID": "4096", "ZIPCODE": "42240" },
    { "ZIPCODE_ID": 3312, "SUB_DISTRICT_CODE": "421205", "PROVINCE_ID": "30", "DISTRICT_ID": "457", "SUB_DISTRICT_ID": "4097", "ZIPCODE": "42240" },
    { "ZIPCODE_ID": 3313, "SUB_DISTRICT_CODE": "421301", "PROVINCE_ID": "30", "DISTRICT_ID": "458", "SUB_DISTRICT_ID": "4098", "ZIPCODE": "42220" },
    { "ZIPCODE_ID": 3314, "SUB_DISTRICT_CODE": "421302", "PROVINCE_ID": "30", "DISTRICT_ID": "458", "SUB_DISTRICT_ID": "4099", "ZIPCODE": "42220" },
    { "ZIPCODE_ID": 3315, "SUB_DISTRICT_CODE": "421303", "PROVINCE_ID": "30", "DISTRICT_ID": "458", "SUB_DISTRICT_ID": "4100", "ZIPCODE": "42220" },
    { "ZIPCODE_ID": 3316, "SUB_DISTRICT_CODE": "421304", "PROVINCE_ID": "30", "DISTRICT_ID": "458", "SUB_DISTRICT_ID": "4101", "ZIPCODE": "42220" },
    { "ZIPCODE_ID": 3317, "SUB_DISTRICT_CODE": "421401", "PROVINCE_ID": "30", "DISTRICT_ID": "459", "SUB_DISTRICT_ID": "4102", "ZIPCODE": "42190" },
    { "ZIPCODE_ID": 3318, "SUB_DISTRICT_CODE": "421402", "PROVINCE_ID": "30", "DISTRICT_ID": "459", "SUB_DISTRICT_ID": "4103", "ZIPCODE": "42190" },
    { "ZIPCODE_ID": 3319, "SUB_DISTRICT_CODE": "421403", "PROVINCE_ID": "30", "DISTRICT_ID": "459", "SUB_DISTRICT_ID": "4104", "ZIPCODE": "42190" },
    { "ZIPCODE_ID": 3320, "SUB_DISTRICT_CODE": "430101", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4105", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3321, "SUB_DISTRICT_CODE": "430102", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4106", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3322, "SUB_DISTRICT_CODE": "430103", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4107", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3323, "SUB_DISTRICT_CODE": "430104", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4108", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3324, "SUB_DISTRICT_CODE": "430105", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4109", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3325, "SUB_DISTRICT_CODE": "430106", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4110", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3326, "SUB_DISTRICT_CODE": "430107", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4111", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3327, "SUB_DISTRICT_CODE": "430108", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4112", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3328, "SUB_DISTRICT_CODE": "430109", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4113", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3329, "SUB_DISTRICT_CODE": "430110", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4114", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3330, "SUB_DISTRICT_CODE": "430111", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4115", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3331, "SUB_DISTRICT_CODE": "430113", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4117", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3332, "SUB_DISTRICT_CODE": "430116", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4120", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3333, "SUB_DISTRICT_CODE": "430117", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4121", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3334, "SUB_DISTRICT_CODE": "430118", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4122", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3335, "SUB_DISTRICT_CODE": "430119", "PROVINCE_ID": "31", "DISTRICT_ID": "460", "SUB_DISTRICT_ID": "4123", "ZIPCODE": "43000" },
    { "ZIPCODE_ID": 3336, "SUB_DISTRICT_CODE": "430201", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4124", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3337, "SUB_DISTRICT_CODE": "430202", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4125", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3338, "SUB_DISTRICT_CODE": "430203", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4126", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3339, "SUB_DISTRICT_CODE": "430204", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4127", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3340, "SUB_DISTRICT_CODE": "430205", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4128", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3341, "SUB_DISTRICT_CODE": "430206", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4129", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3342, "SUB_DISTRICT_CODE": "430207", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4130", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3343, "SUB_DISTRICT_CODE": "430208", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4131", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3344, "SUB_DISTRICT_CODE": "430209", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4132", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3345, "SUB_DISTRICT_CODE": "430210", "PROVINCE_ID": "31", "DISTRICT_ID": "461", "SUB_DISTRICT_ID": "4133", "ZIPCODE": "43110" },
    { "ZIPCODE_ID": 3346, "SUB_DISTRICT_CODE": "380107", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8867", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3347, "SUB_DISTRICT_CODE": "380106", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8866", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3348, "SUB_DISTRICT_CODE": "380110", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8870", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3349, "SUB_DISTRICT_CODE": "380112", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8872", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3350, "SUB_DISTRICT_CODE": "380111", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8871", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3351, "SUB_DISTRICT_CODE": "380102", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8862", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3352, "SUB_DISTRICT_CODE": "380105", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8865", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3353, "SUB_DISTRICT_CODE": "380103", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8863", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3354, "SUB_DISTRICT_CODE": "380104", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8864", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3355, "SUB_DISTRICT_CODE": "380109", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8869", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3356, "SUB_DISTRICT_CODE": "380101", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8861", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3357, "SUB_DISTRICT_CODE": "380108", "PROVINCE_ID": "77", "DISTRICT_ID": "999", "SUB_DISTRICT_ID": "8868", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3358, "SUB_DISTRICT_CODE": "380305", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8893", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3359, "SUB_DISTRICT_CODE": "380401", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8889", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3360, "SUB_DISTRICT_CODE": "380403", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8891", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3361, "SUB_DISTRICT_CODE": "380407", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8895", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3362, "SUB_DISTRICT_CODE": "380404", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8892", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3363, "SUB_DISTRICT_CODE": "380402", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8890", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3364, "SUB_DISTRICT_CODE": "380406", "PROVINCE_ID": "77", "DISTRICT_ID": "1002", "SUB_DISTRICT_ID": "8894", "ZIPCODE": "38180" },
    { "ZIPCODE_ID": 3365, "SUB_DISTRICT_CODE": "430501", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4160", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3366, "SUB_DISTRICT_CODE": "430502", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4161", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3367, "SUB_DISTRICT_CODE": "430503", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4162", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3368, "SUB_DISTRICT_CODE": "430504", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4163", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3369, "SUB_DISTRICT_CODE": "430506", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4165", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3370, "SUB_DISTRICT_CODE": "430507", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4166", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3371, "SUB_DISTRICT_CODE": "430508", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4167", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3372, "SUB_DISTRICT_CODE": "430509", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4168", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3373, "SUB_DISTRICT_CODE": "430513", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4172", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3374, "SUB_DISTRICT_CODE": "430521", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4179", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3375, "SUB_DISTRICT_CODE": "430522", "PROVINCE_ID": "31", "DISTRICT_ID": "464", "SUB_DISTRICT_ID": "4180", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3376, "SUB_DISTRICT_CODE": "380302", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8883", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3377, "SUB_DISTRICT_CODE": "380306", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8887", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3378, "SUB_DISTRICT_CODE": "380405", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8886", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3379, "SUB_DISTRICT_CODE": "380301", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8882", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3380, "SUB_DISTRICT_CODE": "380304", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8885", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3381, "SUB_DISTRICT_CODE": "380303", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8884", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3382, "SUB_DISTRICT_CODE": "380307", "PROVINCE_ID": "77", "DISTRICT_ID": "1001", "SUB_DISTRICT_ID": "8888", "ZIPCODE": "38170" },
    { "ZIPCODE_ID": 3383, "SUB_DISTRICT_CODE": "430701", "PROVINCE_ID": "31", "DISTRICT_ID": "466", "SUB_DISTRICT_ID": "4188", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3384, "SUB_DISTRICT_CODE": "430703", "PROVINCE_ID": "31", "DISTRICT_ID": "466", "SUB_DISTRICT_ID": "4190", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3385, "SUB_DISTRICT_CODE": "430704", "PROVINCE_ID": "31", "DISTRICT_ID": "466", "SUB_DISTRICT_ID": "4191", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3386, "SUB_DISTRICT_CODE": "430705", "PROVINCE_ID": "31", "DISTRICT_ID": "466", "SUB_DISTRICT_ID": "4192", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3387, "SUB_DISTRICT_CODE": "430801", "PROVINCE_ID": "31", "DISTRICT_ID": "467", "SUB_DISTRICT_ID": "4195", "ZIPCODE": "43160" },
    { "ZIPCODE_ID": 3388, "SUB_DISTRICT_CODE": "430802", "PROVINCE_ID": "31", "DISTRICT_ID": "467", "SUB_DISTRICT_ID": "4196", "ZIPCODE": "43160" },
    { "ZIPCODE_ID": 3389, "SUB_DISTRICT_CODE": "430803", "PROVINCE_ID": "31", "DISTRICT_ID": "467", "SUB_DISTRICT_ID": "4197", "ZIPCODE": "43160" },
    { "ZIPCODE_ID": 3390, "SUB_DISTRICT_CODE": "430804", "PROVINCE_ID": "31", "DISTRICT_ID": "467", "SUB_DISTRICT_ID": "4198", "ZIPCODE": "43160" },
    { "ZIPCODE_ID": 3391, "SUB_DISTRICT_CODE": "430805", "PROVINCE_ID": "31", "DISTRICT_ID": "467", "SUB_DISTRICT_ID": "4199", "ZIPCODE": "43160" },
    { "ZIPCODE_ID": 3392, "SUB_DISTRICT_CODE": "380202", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8874", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3393, "SUB_DISTRICT_CODE": "380201", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8873", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3394, "SUB_DISTRICT_CODE": "380203", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8875", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3395, "SUB_DISTRICT_CODE": "380206", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8878", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3396, "SUB_DISTRICT_CODE": "380207", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8879", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3397, "SUB_DISTRICT_CODE": "380205", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8877", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3398, "SUB_DISTRICT_CODE": "380204", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8876", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3399, "SUB_DISTRICT_CODE": "380209", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8881", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3400, "SUB_DISTRICT_CODE": "380208", "PROVINCE_ID": "77", "DISTRICT_ID": "1000", "SUB_DISTRICT_ID": "8880", "ZIPCODE": "38150" },
    { "ZIPCODE_ID": 3401, "SUB_DISTRICT_CODE": "380704", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8908", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3402, "SUB_DISTRICT_CODE": "380706", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8910", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3403, "SUB_DISTRICT_CODE": "380701", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8905", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3404, "SUB_DISTRICT_CODE": "380703", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8907", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3405, "SUB_DISTRICT_CODE": "380705", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8909", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3406, "SUB_DISTRICT_CODE": "380702", "PROVINCE_ID": "77", "DISTRICT_ID": "1005", "SUB_DISTRICT_ID": "8906", "ZIPCODE": "38190" },
    { "ZIPCODE_ID": 3407, "SUB_DISTRICT_CODE": "380603", "PROVINCE_ID": "77", "DISTRICT_ID": "1004", "SUB_DISTRICT_ID": "8903", "ZIPCODE": "38220" },
    { "ZIPCODE_ID": 3408, "SUB_DISTRICT_CODE": "380604", "PROVINCE_ID": "77", "DISTRICT_ID": "1004", "SUB_DISTRICT_ID": "8904", "ZIPCODE": "38220" },
    { "ZIPCODE_ID": 3409, "SUB_DISTRICT_CODE": "380601", "PROVINCE_ID": "77", "DISTRICT_ID": "1004", "SUB_DISTRICT_ID": "8901", "ZIPCODE": "38220" },
    { "ZIPCODE_ID": 3410, "SUB_DISTRICT_CODE": "380602", "PROVINCE_ID": "77", "DISTRICT_ID": "1004", "SUB_DISTRICT_ID": "8902", "ZIPCODE": "38220" },
    { "ZIPCODE_ID": 3411, "SUB_DISTRICT_CODE": "380505", "PROVINCE_ID": "77", "DISTRICT_ID": "1003", "SUB_DISTRICT_ID": "8900", "ZIPCODE": "38210" },
    { "ZIPCODE_ID": 3412, "SUB_DISTRICT_CODE": "380501", "PROVINCE_ID": "77", "DISTRICT_ID": "1003", "SUB_DISTRICT_ID": "8896", "ZIPCODE": "38210" },
    { "ZIPCODE_ID": 3413, "SUB_DISTRICT_CODE": "380504", "PROVINCE_ID": "77", "DISTRICT_ID": "1003", "SUB_DISTRICT_ID": "8899", "ZIPCODE": "38210" },
    { "ZIPCODE_ID": 3414, "SUB_DISTRICT_CODE": "380502", "PROVINCE_ID": "77", "DISTRICT_ID": "1003", "SUB_DISTRICT_ID": "8897", "ZIPCODE": "38210" },
    { "ZIPCODE_ID": 3415, "SUB_DISTRICT_CODE": "380503", "PROVINCE_ID": "77", "DISTRICT_ID": "1003", "SUB_DISTRICT_ID": "8898", "ZIPCODE": "38210" },
    { "ZIPCODE_ID": 3416, "SUB_DISTRICT_CODE": "380802", "PROVINCE_ID": "77", "DISTRICT_ID": "1006", "SUB_DISTRICT_ID": "8912", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3417, "SUB_DISTRICT_CODE": "380803", "PROVINCE_ID": "77", "DISTRICT_ID": "1006", "SUB_DISTRICT_ID": "8913", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3418, "SUB_DISTRICT_CODE": "380801", "PROVINCE_ID": "77", "DISTRICT_ID": "1006", "SUB_DISTRICT_ID": "8911", "ZIPCODE": "38000" },
    { "ZIPCODE_ID": 3419, "SUB_DISTRICT_CODE": "431401", "PROVINCE_ID": "31", "DISTRICT_ID": "473", "SUB_DISTRICT_ID": "4231", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3420, "SUB_DISTRICT_CODE": "431402", "PROVINCE_ID": "31", "DISTRICT_ID": "473", "SUB_DISTRICT_ID": "4232", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3421, "SUB_DISTRICT_CODE": "431403", "PROVINCE_ID": "31", "DISTRICT_ID": "473", "SUB_DISTRICT_ID": "4233", "ZIPCODE": "43100" },
    { "ZIPCODE_ID": 3422, "SUB_DISTRICT_CODE": "431501", "PROVINCE_ID": "31", "DISTRICT_ID": "474", "SUB_DISTRICT_ID": "4234", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3423, "SUB_DISTRICT_CODE": "431502", "PROVINCE_ID": "31", "DISTRICT_ID": "474", "SUB_DISTRICT_ID": "4235", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3424, "SUB_DISTRICT_CODE": "431503", "PROVINCE_ID": "31", "DISTRICT_ID": "474", "SUB_DISTRICT_ID": "4236", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3425, "SUB_DISTRICT_CODE": "431504", "PROVINCE_ID": "31", "DISTRICT_ID": "474", "SUB_DISTRICT_ID": "4237", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3426, "SUB_DISTRICT_CODE": "431505", "PROVINCE_ID": "31", "DISTRICT_ID": "474", "SUB_DISTRICT_ID": "4238", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3427, "SUB_DISTRICT_CODE": "431601", "PROVINCE_ID": "31", "DISTRICT_ID": "475", "SUB_DISTRICT_ID": "4239", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3428, "SUB_DISTRICT_CODE": "431602", "PROVINCE_ID": "31", "DISTRICT_ID": "475", "SUB_DISTRICT_ID": "4240", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3429, "SUB_DISTRICT_CODE": "431603", "PROVINCE_ID": "31", "DISTRICT_ID": "475", "SUB_DISTRICT_ID": "4241", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3430, "SUB_DISTRICT_CODE": "431604", "PROVINCE_ID": "31", "DISTRICT_ID": "475", "SUB_DISTRICT_ID": "4242", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3431, "SUB_DISTRICT_CODE": "431605", "PROVINCE_ID": "31", "DISTRICT_ID": "475", "SUB_DISTRICT_ID": "4243", "ZIPCODE": "43120" },
    { "ZIPCODE_ID": 3432, "SUB_DISTRICT_CODE": "431701", "PROVINCE_ID": "31", "DISTRICT_ID": "476", "SUB_DISTRICT_ID": "4244", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3433, "SUB_DISTRICT_CODE": "431702", "PROVINCE_ID": "31", "DISTRICT_ID": "476", "SUB_DISTRICT_ID": "4245", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3434, "SUB_DISTRICT_CODE": "431703", "PROVINCE_ID": "31", "DISTRICT_ID": "476", "SUB_DISTRICT_ID": "4246", "ZIPCODE": "43130" },
    { "ZIPCODE_ID": 3435, "SUB_DISTRICT_CODE": "440101", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4247", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3436, "SUB_DISTRICT_CODE": "440102", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4248", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3437, "SUB_DISTRICT_CODE": "440103", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4249", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3438, "SUB_DISTRICT_CODE": "440104", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4250", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3439, "SUB_DISTRICT_CODE": "440105", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4251", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3440, "SUB_DISTRICT_CODE": "440106", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4252", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3441, "SUB_DISTRICT_CODE": "440107", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4253", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3442, "SUB_DISTRICT_CODE": "440108", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4254", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3443, "SUB_DISTRICT_CODE": "440109", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4255", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3444, "SUB_DISTRICT_CODE": "440110", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4256", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3445, "SUB_DISTRICT_CODE": "440111", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4257", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3446, "SUB_DISTRICT_CODE": "440112", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4258", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3447, "SUB_DISTRICT_CODE": "440113", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4259", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3448, "SUB_DISTRICT_CODE": "440114", "PROVINCE_ID": "32", "DISTRICT_ID": "477", "SUB_DISTRICT_ID": "4260", "ZIPCODE": "44000" },
    { "ZIPCODE_ID": 3449, "SUB_DISTRICT_CODE": "440201", "PROVINCE_ID": "32", "DISTRICT_ID": "478", "SUB_DISTRICT_ID": "4261", "ZIPCODE": "44190" },
    { "ZIPCODE_ID": 3450, "SUB_DISTRICT_CODE": "440202", "PROVINCE_ID": "32", "DISTRICT_ID": "478", "SUB_DISTRICT_ID": "4262", "ZIPCODE": "44190" },
    { "ZIPCODE_ID": 3451, "SUB_DISTRICT_CODE": "440203", "PROVINCE_ID": "32", "DISTRICT_ID": "478", "SUB_DISTRICT_ID": "4263", "ZIPCODE": "44190" },
    { "ZIPCODE_ID": 3452, "SUB_DISTRICT_CODE": "440204", "PROVINCE_ID": "32", "DISTRICT_ID": "478", "SUB_DISTRICT_ID": "4264", "ZIPCODE": "44190" },
    { "ZIPCODE_ID": 3453, "SUB_DISTRICT_CODE": "440205", "PROVINCE_ID": "32", "DISTRICT_ID": "478", "SUB_DISTRICT_ID": "4265", "ZIPCODE": "44190" },
    { "ZIPCODE_ID": 3454, "SUB_DISTRICT_CODE": "440301", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4266", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3455, "SUB_DISTRICT_CODE": "440302", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4267", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3456, "SUB_DISTRICT_CODE": "440303", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4268", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3457, "SUB_DISTRICT_CODE": "440304", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4269", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3458, "SUB_DISTRICT_CODE": "440305", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4270", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3459, "SUB_DISTRICT_CODE": "440306", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4271", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3460, "SUB_DISTRICT_CODE": "440307", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4272", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3461, "SUB_DISTRICT_CODE": "440308", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4273", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3462, "SUB_DISTRICT_CODE": "440309", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4274", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3463, "SUB_DISTRICT_CODE": "440310", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4275", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3464, "SUB_DISTRICT_CODE": "440311", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4276", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3465, "SUB_DISTRICT_CODE": "440312", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4277", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3466, "SUB_DISTRICT_CODE": "440313", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4278", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3467, "SUB_DISTRICT_CODE": "440314", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4279", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3468, "SUB_DISTRICT_CODE": "440315", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4280", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3469, "SUB_DISTRICT_CODE": "440316", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4281", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3470, "SUB_DISTRICT_CODE": "440317", "PROVINCE_ID": "32", "DISTRICT_ID": "479", "SUB_DISTRICT_ID": "4282", "ZIPCODE": "44140" },
    { "ZIPCODE_ID": 3471, "SUB_DISTRICT_CODE": "440401", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4283", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3472, "SUB_DISTRICT_CODE": "440402", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4284", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3473, "SUB_DISTRICT_CODE": "440403", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4285", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3474, "SUB_DISTRICT_CODE": "440404", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4286", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3475, "SUB_DISTRICT_CODE": "440405", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4287", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3476, "SUB_DISTRICT_CODE": "440406", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4288", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3477, "SUB_DISTRICT_CODE": "440407", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4289", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3478, "SUB_DISTRICT_CODE": "440408", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4290", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3479, "SUB_DISTRICT_CODE": "440409", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4291", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3480, "SUB_DISTRICT_CODE": "440410", "PROVINCE_ID": "32", "DISTRICT_ID": "480", "SUB_DISTRICT_ID": "4292", "ZIPCODE": "44150" },
    { "ZIPCODE_ID": 3481, "SUB_DISTRICT_CODE": "440501", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4293", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3482, "SUB_DISTRICT_CODE": "440503", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4295", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3483, "SUB_DISTRICT_CODE": "440505", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4297", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3484, "SUB_DISTRICT_CODE": "440506", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4298", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3485, "SUB_DISTRICT_CODE": "440507", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4299", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3486, "SUB_DISTRICT_CODE": "440508", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4300", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3487, "SUB_DISTRICT_CODE": "440511", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4303", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3488, "SUB_DISTRICT_CODE": "440512", "PROVINCE_ID": "32", "DISTRICT_ID": "481", "SUB_DISTRICT_ID": "4304", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3489, "SUB_DISTRICT_CODE": "440601", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4305", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3490, "SUB_DISTRICT_CODE": "440602", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4306", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3491, "SUB_DISTRICT_CODE": "440604", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4308", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3492, "SUB_DISTRICT_CODE": "440605", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4309", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3493, "SUB_DISTRICT_CODE": "440606", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4310", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3494, "SUB_DISTRICT_CODE": "440607", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4311", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3495, "SUB_DISTRICT_CODE": "440608", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4312", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3496, "SUB_DISTRICT_CODE": "440610", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4314", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3497, "SUB_DISTRICT_CODE": "440611", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4315", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3498, "SUB_DISTRICT_CODE": "440613", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4317", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3499, "SUB_DISTRICT_CODE": "440615", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4319", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3500, "SUB_DISTRICT_CODE": "440616", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4320", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3501, "SUB_DISTRICT_CODE": "440618", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4322", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3502, "SUB_DISTRICT_CODE": "440619", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4323", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3503, "SUB_DISTRICT_CODE": "440620", "PROVINCE_ID": "32", "DISTRICT_ID": "482", "SUB_DISTRICT_ID": "4324", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3504, "SUB_DISTRICT_CODE": "440701", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4325", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3505, "SUB_DISTRICT_CODE": "440702", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4326", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3506, "SUB_DISTRICT_CODE": "440703", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4327", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3507, "SUB_DISTRICT_CODE": "440704", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4328", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3508, "SUB_DISTRICT_CODE": "440705", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4329", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3509, "SUB_DISTRICT_CODE": "440706", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4330", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3510, "SUB_DISTRICT_CODE": "440707", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4331", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3511, "SUB_DISTRICT_CODE": "440708", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4332", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3512, "SUB_DISTRICT_CODE": "440709", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4333", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3513, "SUB_DISTRICT_CODE": "440710", "PROVINCE_ID": "32", "DISTRICT_ID": "483", "SUB_DISTRICT_ID": "4334", "ZIPCODE": "44170" },
    { "ZIPCODE_ID": 3514, "SUB_DISTRICT_CODE": "440801", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4335", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3515, "SUB_DISTRICT_CODE": "440802", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4336", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3516, "SUB_DISTRICT_CODE": "440803", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4337", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3517, "SUB_DISTRICT_CODE": "440804", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4338", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3518, "SUB_DISTRICT_CODE": "440805", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4339", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3519, "SUB_DISTRICT_CODE": "440809", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4343", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3520, "SUB_DISTRICT_CODE": "440810", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4344", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3521, "SUB_DISTRICT_CODE": "440812", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4346", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3522, "SUB_DISTRICT_CODE": "440815", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4349", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3523, "SUB_DISTRICT_CODE": "440816", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4350", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3524, "SUB_DISTRICT_CODE": "440817", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4351", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3525, "SUB_DISTRICT_CODE": "440818", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4352", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3526, "SUB_DISTRICT_CODE": "440819", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4353", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3527, "SUB_DISTRICT_CODE": "440820", "PROVINCE_ID": "32", "DISTRICT_ID": "484", "SUB_DISTRICT_ID": "4354", "ZIPCODE": "44110" },
    { "ZIPCODE_ID": 3528, "SUB_DISTRICT_CODE": "440901", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4355", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3529, "SUB_DISTRICT_CODE": "440902", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4356", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3530, "SUB_DISTRICT_CODE": "440903", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4357", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3531, "SUB_DISTRICT_CODE": "440904", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4358", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3532, "SUB_DISTRICT_CODE": "440905", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4359", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3533, "SUB_DISTRICT_CODE": "440906", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4360", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3534, "SUB_DISTRICT_CODE": "440907", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4361", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3535, "SUB_DISTRICT_CODE": "440908", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4362", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3536, "SUB_DISTRICT_CODE": "440909", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4363", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3537, "SUB_DISTRICT_CODE": "440910", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4364", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3538, "SUB_DISTRICT_CODE": "440911", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4365", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3539, "SUB_DISTRICT_CODE": "440912", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4366", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3540, "SUB_DISTRICT_CODE": "440913", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4367", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3541, "SUB_DISTRICT_CODE": "440914", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4368", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3542, "SUB_DISTRICT_CODE": "440915", "PROVINCE_ID": "32", "DISTRICT_ID": "485", "SUB_DISTRICT_ID": "4369", "ZIPCODE": "44120" },
    { "ZIPCODE_ID": 3543, "SUB_DISTRICT_CODE": "441001", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4373", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3544, "SUB_DISTRICT_CODE": "441002", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4374", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3545, "SUB_DISTRICT_CODE": "441003", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4375", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3546, "SUB_DISTRICT_CODE": "441004", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4376", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3547, "SUB_DISTRICT_CODE": "441005", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4377", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3548, "SUB_DISTRICT_CODE": "441006", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4378", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3549, "SUB_DISTRICT_CODE": "441007", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4379", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3550, "SUB_DISTRICT_CODE": "441008", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4380", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3551, "SUB_DISTRICT_CODE": "441009", "PROVINCE_ID": "32", "DISTRICT_ID": "486", "SUB_DISTRICT_ID": "4381", "ZIPCODE": "44180" },
    { "ZIPCODE_ID": 3552, "SUB_DISTRICT_CODE": "441101", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4382", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3553, "SUB_DISTRICT_CODE": "441102", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4383", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3554, "SUB_DISTRICT_CODE": "441103", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4384", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3555, "SUB_DISTRICT_CODE": "441104", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4385", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3556, "SUB_DISTRICT_CODE": "441105", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4386", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3557, "SUB_DISTRICT_CODE": "441106", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4387", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3558, "SUB_DISTRICT_CODE": "441107", "PROVINCE_ID": "32", "DISTRICT_ID": "487", "SUB_DISTRICT_ID": "4388", "ZIPCODE": "44210" },
    { "ZIPCODE_ID": 3559, "SUB_DISTRICT_CODE": "441201", "PROVINCE_ID": "32", "DISTRICT_ID": "488", "SUB_DISTRICT_ID": "4389", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3560, "SUB_DISTRICT_CODE": "441202", "PROVINCE_ID": "32", "DISTRICT_ID": "488", "SUB_DISTRICT_ID": "4390", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3561, "SUB_DISTRICT_CODE": "441203", "PROVINCE_ID": "32", "DISTRICT_ID": "488", "SUB_DISTRICT_ID": "4391", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3562, "SUB_DISTRICT_CODE": "441204", "PROVINCE_ID": "32", "DISTRICT_ID": "488", "SUB_DISTRICT_ID": "4392", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3563, "SUB_DISTRICT_CODE": "441205", "PROVINCE_ID": "32", "DISTRICT_ID": "488", "SUB_DISTRICT_ID": "4393", "ZIPCODE": "44130" },
    { "ZIPCODE_ID": 3564, "SUB_DISTRICT_CODE": "441301", "PROVINCE_ID": "32", "DISTRICT_ID": "489", "SUB_DISTRICT_ID": "4394", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3565, "SUB_DISTRICT_CODE": "441302", "PROVINCE_ID": "32", "DISTRICT_ID": "489", "SUB_DISTRICT_ID": "4395", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3566, "SUB_DISTRICT_CODE": "441303", "PROVINCE_ID": "32", "DISTRICT_ID": "489", "SUB_DISTRICT_ID": "4396", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3567, "SUB_DISTRICT_CODE": "441304", "PROVINCE_ID": "32", "DISTRICT_ID": "489", "SUB_DISTRICT_ID": "4397", "ZIPCODE": "44160" },
    { "ZIPCODE_ID": 3568, "SUB_DISTRICT_CODE": "450101", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4398", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3569, "SUB_DISTRICT_CODE": "450102", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4399", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3570, "SUB_DISTRICT_CODE": "450103", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4400", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3571, "SUB_DISTRICT_CODE": "450104", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4401", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3572, "SUB_DISTRICT_CODE": "450105", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4402", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3573, "SUB_DISTRICT_CODE": "450106", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4403", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3574, "SUB_DISTRICT_CODE": "450108", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4405", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3575, "SUB_DISTRICT_CODE": "450109", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4406", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3576, "SUB_DISTRICT_CODE": "450110", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4407", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3577, "SUB_DISTRICT_CODE": "450117", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4414", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3578, "SUB_DISTRICT_CODE": "450118", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4415", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3579, "SUB_DISTRICT_CODE": "450120", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4417", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3580, "SUB_DISTRICT_CODE": "450123", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4420", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3581, "SUB_DISTRICT_CODE": "450124", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4421", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3582, "SUB_DISTRICT_CODE": "450125", "PROVINCE_ID": "33", "DISTRICT_ID": "491", "SUB_DISTRICT_ID": "4422", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3583, "SUB_DISTRICT_CODE": "450201", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4432", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3584, "SUB_DISTRICT_CODE": "450202", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4433", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3585, "SUB_DISTRICT_CODE": "450203", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4434", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3586, "SUB_DISTRICT_CODE": "450204", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4435", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3587, "SUB_DISTRICT_CODE": "450205", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4436", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3588, "SUB_DISTRICT_CODE": "450206", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4437", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3589, "SUB_DISTRICT_CODE": "450207", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4438", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3590, "SUB_DISTRICT_CODE": "450208", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4439", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3591, "SUB_DISTRICT_CODE": "450209", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4440", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3592, "SUB_DISTRICT_CODE": "450210", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4441", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3593, "SUB_DISTRICT_CODE": "450211", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4442", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3594, "SUB_DISTRICT_CODE": "450212", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4443", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3595, "SUB_DISTRICT_CODE": "450213", "PROVINCE_ID": "33", "DISTRICT_ID": "492", "SUB_DISTRICT_ID": "4444", "ZIPCODE": "45150" },
    { "ZIPCODE_ID": 3596, "SUB_DISTRICT_CODE": "450301", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4445", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3597, "SUB_DISTRICT_CODE": "450302", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4446", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3598, "SUB_DISTRICT_CODE": "450303", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4447", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3599, "SUB_DISTRICT_CODE": "450304", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4448", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3600, "SUB_DISTRICT_CODE": "450305", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4449", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3601, "SUB_DISTRICT_CODE": "450306", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4450", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3602, "SUB_DISTRICT_CODE": "450307", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4451", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3603, "SUB_DISTRICT_CODE": "450308", "PROVINCE_ID": "33", "DISTRICT_ID": "493", "SUB_DISTRICT_ID": "4452", "ZIPCODE": "45190" },
    { "ZIPCODE_ID": 3604, "SUB_DISTRICT_CODE": "450401", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4453", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3605, "SUB_DISTRICT_CODE": "450402", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4454", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3606, "SUB_DISTRICT_CODE": "450403", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4455", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3607, "SUB_DISTRICT_CODE": "450404", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4456", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3608, "SUB_DISTRICT_CODE": "450405", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4457", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3609, "SUB_DISTRICT_CODE": "450406", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4458", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3610, "SUB_DISTRICT_CODE": "450407", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4459", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3611, "SUB_DISTRICT_CODE": "450408", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4460", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3612, "SUB_DISTRICT_CODE": "450409", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4461", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3613, "SUB_DISTRICT_CODE": "450410", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4462", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3614, "SUB_DISTRICT_CODE": "450411", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4463", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3615, "SUB_DISTRICT_CODE": "450412", "PROVINCE_ID": "33", "DISTRICT_ID": "494", "SUB_DISTRICT_ID": "4464", "ZIPCODE": "45180" },
    { "ZIPCODE_ID": 3616, "SUB_DISTRICT_CODE": "450501", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4465", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3617, "SUB_DISTRICT_CODE": "450502", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4466", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3618, "SUB_DISTRICT_CODE": "450503", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4467", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3619, "SUB_DISTRICT_CODE": "450504", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4468", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3620, "SUB_DISTRICT_CODE": "450506", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4470", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3621, "SUB_DISTRICT_CODE": "450507", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4471", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3622, "SUB_DISTRICT_CODE": "450510", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4474", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3623, "SUB_DISTRICT_CODE": "450515", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4479", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3624, "SUB_DISTRICT_CODE": "450517", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4481", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3625, "SUB_DISTRICT_CODE": "450520", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4484", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3626, "SUB_DISTRICT_CODE": "450522", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4486", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3627, "SUB_DISTRICT_CODE": "450524", "PROVINCE_ID": "33", "DISTRICT_ID": "495", "SUB_DISTRICT_ID": "4488", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3628, "SUB_DISTRICT_CODE": "450601", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4489", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3629, "SUB_DISTRICT_CODE": "450602", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4490", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3630, "SUB_DISTRICT_CODE": "450603", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4491", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3631, "SUB_DISTRICT_CODE": "450604", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4492", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3632, "SUB_DISTRICT_CODE": "450605", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4493", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3633, "SUB_DISTRICT_CODE": "450606", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4494", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3634, "SUB_DISTRICT_CODE": "450607", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4495", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3635, "SUB_DISTRICT_CODE": "450611", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4499", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3636, "SUB_DISTRICT_CODE": "450612", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4500", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3637, "SUB_DISTRICT_CODE": "450613", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4501", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3638, "SUB_DISTRICT_CODE": "450614", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4502", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3639, "SUB_DISTRICT_CODE": "450615", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4503", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3640, "SUB_DISTRICT_CODE": "450617", "PROVINCE_ID": "33", "DISTRICT_ID": "496", "SUB_DISTRICT_ID": "4505", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3641, "SUB_DISTRICT_CODE": "450701", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4506", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3642, "SUB_DISTRICT_CODE": "450702", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4507", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3643, "SUB_DISTRICT_CODE": "450703", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4508", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3644, "SUB_DISTRICT_CODE": "450704", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4509", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3645, "SUB_DISTRICT_CODE": "450705", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4510", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3646, "SUB_DISTRICT_CODE": "450706", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4511", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3647, "SUB_DISTRICT_CODE": "450707", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4512", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3648, "SUB_DISTRICT_CODE": "450708", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4513", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3649, "SUB_DISTRICT_CODE": "450709", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4514", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3650, "SUB_DISTRICT_CODE": "450710", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4515", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3651, "SUB_DISTRICT_CODE": "450711", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4516", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3652, "SUB_DISTRICT_CODE": "450712", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4517", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3653, "SUB_DISTRICT_CODE": "450713", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4518", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3654, "SUB_DISTRICT_CODE": "450714", "PROVINCE_ID": "33", "DISTRICT_ID": "497", "SUB_DISTRICT_ID": "4519", "ZIPCODE": "45110" },
    { "ZIPCODE_ID": 3655, "SUB_DISTRICT_CODE": "450801", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4526", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3656, "SUB_DISTRICT_CODE": "450802", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4527", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3657, "SUB_DISTRICT_CODE": "450803", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4528", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3658, "SUB_DISTRICT_CODE": "450804", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4529", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3659, "SUB_DISTRICT_CODE": "450805", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4530", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3660, "SUB_DISTRICT_CODE": "450806", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4531", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3661, "SUB_DISTRICT_CODE": "450807", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4532", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3662, "SUB_DISTRICT_CODE": "450808", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4533", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3663, "SUB_DISTRICT_CODE": "450809", "PROVINCE_ID": "33", "DISTRICT_ID": "498", "SUB_DISTRICT_ID": "4534", "ZIPCODE": "45230" },
    { "ZIPCODE_ID": 3664, "SUB_DISTRICT_CODE": "450901", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4535", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3665, "SUB_DISTRICT_CODE": "450902", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4536", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3666, "SUB_DISTRICT_CODE": "450903", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4537", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3667, "SUB_DISTRICT_CODE": "450904", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4538", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3668, "SUB_DISTRICT_CODE": "450905", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4539", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3669, "SUB_DISTRICT_CODE": "450906", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4540", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3670, "SUB_DISTRICT_CODE": "450907", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4541", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3671, "SUB_DISTRICT_CODE": "450908", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4542", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3672, "SUB_DISTRICT_CODE": "450909", "PROVINCE_ID": "33", "DISTRICT_ID": "499", "SUB_DISTRICT_ID": "4543", "ZIPCODE": "45210" },
    { "ZIPCODE_ID": 3673, "SUB_DISTRICT_CODE": "451001", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4544", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3674, "SUB_DISTRICT_CODE": "451002", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4545", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3675, "SUB_DISTRICT_CODE": "451003", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4546", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3676, "SUB_DISTRICT_CODE": "451004", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4547", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3677, "SUB_DISTRICT_CODE": "451005", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4548", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3678, "SUB_DISTRICT_CODE": "451006", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4549", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3679, "SUB_DISTRICT_CODE": "451007", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4550", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3680, "SUB_DISTRICT_CODE": "451008", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4551", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3681, "SUB_DISTRICT_CODE": "451009", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4552", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3682, "SUB_DISTRICT_CODE": "451010", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4553", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3683, "SUB_DISTRICT_CODE": "451011", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4554", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3684, "SUB_DISTRICT_CODE": "451012", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4555", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3685, "SUB_DISTRICT_CODE": "451013", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4556", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3686, "SUB_DISTRICT_CODE": "451014", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4557", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3687, "SUB_DISTRICT_CODE": "451015", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4558", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3688, "SUB_DISTRICT_CODE": "451016", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4559", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3689, "SUB_DISTRICT_CODE": "451017", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4560", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3690, "SUB_DISTRICT_CODE": "451018", "PROVINCE_ID": "33", "DISTRICT_ID": "500", "SUB_DISTRICT_ID": "4561", "ZIPCODE": "45120" },
    { "ZIPCODE_ID": 3691, "SUB_DISTRICT_CODE": "451101", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4562", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3692, "SUB_DISTRICT_CODE": "451102", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4563", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3693, "SUB_DISTRICT_CODE": "451103", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4564", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3694, "SUB_DISTRICT_CODE": "451104", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4565", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3695, "SUB_DISTRICT_CODE": "451105", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4566", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3696, "SUB_DISTRICT_CODE": "451106", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4567", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3697, "SUB_DISTRICT_CODE": "451107", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4568", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3698, "SUB_DISTRICT_CODE": "451108", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4569", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3699, "SUB_DISTRICT_CODE": "451109", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4570", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3700, "SUB_DISTRICT_CODE": "451110", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4571", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3701, "SUB_DISTRICT_CODE": "451111", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4572", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3702, "SUB_DISTRICT_CODE": "451112", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4573", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3703, "SUB_DISTRICT_CODE": "451113", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4574", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3704, "SUB_DISTRICT_CODE": "451114", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4575", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3705, "SUB_DISTRICT_CODE": "451115", "PROVINCE_ID": "33", "DISTRICT_ID": "501", "SUB_DISTRICT_ID": "4576", "ZIPCODE": "45130" },
    { "ZIPCODE_ID": 3706, "SUB_DISTRICT_CODE": "451201", "PROVINCE_ID": "33", "DISTRICT_ID": "502", "SUB_DISTRICT_ID": "4577", "ZIPCODE": "45220" },
    { "ZIPCODE_ID": 3707, "SUB_DISTRICT_CODE": "451202", "PROVINCE_ID": "33", "DISTRICT_ID": "502", "SUB_DISTRICT_ID": "4578", "ZIPCODE": "45220" },
    { "ZIPCODE_ID": 3708, "SUB_DISTRICT_CODE": "451203", "PROVINCE_ID": "33", "DISTRICT_ID": "502", "SUB_DISTRICT_ID": "4579", "ZIPCODE": "45220" },
    { "ZIPCODE_ID": 3709, "SUB_DISTRICT_CODE": "451204", "PROVINCE_ID": "33", "DISTRICT_ID": "502", "SUB_DISTRICT_ID": "4580", "ZIPCODE": "45220" },
    { "ZIPCODE_ID": 3710, "SUB_DISTRICT_CODE": "451205", "PROVINCE_ID": "33", "DISTRICT_ID": "502", "SUB_DISTRICT_ID": "4581", "ZIPCODE": "45220" },
    { "ZIPCODE_ID": 3711, "SUB_DISTRICT_CODE": "451301", "PROVINCE_ID": "33", "DISTRICT_ID": "503", "SUB_DISTRICT_ID": "4582", "ZIPCODE": "45240" },
    { "ZIPCODE_ID": 3712, "SUB_DISTRICT_CODE": "451302", "PROVINCE_ID": "33", "DISTRICT_ID": "503", "SUB_DISTRICT_ID": "4583", "ZIPCODE": "45240" },
    { "ZIPCODE_ID": 3713, "SUB_DISTRICT_CODE": "451303", "PROVINCE_ID": "33", "DISTRICT_ID": "503", "SUB_DISTRICT_ID": "4584", "ZIPCODE": "45240" },
    { "ZIPCODE_ID": 3714, "SUB_DISTRICT_CODE": "451304", "PROVINCE_ID": "33", "DISTRICT_ID": "503", "SUB_DISTRICT_ID": "4585", "ZIPCODE": "45240" },
    { "ZIPCODE_ID": 3715, "SUB_DISTRICT_CODE": "451305", "PROVINCE_ID": "33", "DISTRICT_ID": "503", "SUB_DISTRICT_ID": "4586", "ZIPCODE": "45240" },
    { "ZIPCODE_ID": 3716, "SUB_DISTRICT_CODE": "451401", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4587", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3717, "SUB_DISTRICT_CODE": "451402", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4588", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3718, "SUB_DISTRICT_CODE": "451403", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4589", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3719, "SUB_DISTRICT_CODE": "451404", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4590", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3720, "SUB_DISTRICT_CODE": "451405", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4591", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3721, "SUB_DISTRICT_CODE": "451406", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4592", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3722, "SUB_DISTRICT_CODE": "451407", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4593", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3723, "SUB_DISTRICT_CODE": "451408", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4594", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3724, "SUB_DISTRICT_CODE": "451409", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4595", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3725, "SUB_DISTRICT_CODE": "451410", "PROVINCE_ID": "33", "DISTRICT_ID": "504", "SUB_DISTRICT_ID": "4596", "ZIPCODE": "45160" },
    { "ZIPCODE_ID": 3726, "SUB_DISTRICT_CODE": "451501", "PROVINCE_ID": "33", "DISTRICT_ID": "505", "SUB_DISTRICT_ID": "4597", "ZIPCODE": "45250" },
    { "ZIPCODE_ID": 3727, "SUB_DISTRICT_CODE": "451502", "PROVINCE_ID": "33", "DISTRICT_ID": "505", "SUB_DISTRICT_ID": "4598", "ZIPCODE": "45250" },
    { "ZIPCODE_ID": 3728, "SUB_DISTRICT_CODE": "451503", "PROVINCE_ID": "33", "DISTRICT_ID": "505", "SUB_DISTRICT_ID": "4599", "ZIPCODE": "45250" },
    { "ZIPCODE_ID": 3729, "SUB_DISTRICT_CODE": "451504", "PROVINCE_ID": "33", "DISTRICT_ID": "505", "SUB_DISTRICT_ID": "4600", "ZIPCODE": "45250" },
    { "ZIPCODE_ID": 3730, "SUB_DISTRICT_CODE": "451601", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4601", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3731, "SUB_DISTRICT_CODE": "451602", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4602", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3732, "SUB_DISTRICT_CODE": "451603", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4603", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3733, "SUB_DISTRICT_CODE": "451604", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4604", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3734, "SUB_DISTRICT_CODE": "451605", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4605", "ZIPCODE": "45280" },
    { "ZIPCODE_ID": 3735, "SUB_DISTRICT_CODE": "451606", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4606", "ZIPCODE": "45280" },
    { "ZIPCODE_ID": 3736, "SUB_DISTRICT_CODE": "451607", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4607", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3737, "SUB_DISTRICT_CODE": "451608", "PROVINCE_ID": "33", "DISTRICT_ID": "506", "SUB_DISTRICT_ID": "4608", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3738, "SUB_DISTRICT_CODE": "451701", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4609", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3739, "SUB_DISTRICT_CODE": "451702", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4610", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3740, "SUB_DISTRICT_CODE": "451703", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4611", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3741, "SUB_DISTRICT_CODE": "451704", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4612", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3742, "SUB_DISTRICT_CODE": "451705", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4613", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3743, "SUB_DISTRICT_CODE": "451706", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4614", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3744, "SUB_DISTRICT_CODE": "451707", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4615", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3745, "SUB_DISTRICT_CODE": "451708", "PROVINCE_ID": "33", "DISTRICT_ID": "507", "SUB_DISTRICT_ID": "4616", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3746, "SUB_DISTRICT_CODE": "451801", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4617", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3747, "SUB_DISTRICT_CODE": "451802", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4618", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3748, "SUB_DISTRICT_CODE": "451803", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4619", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3749, "SUB_DISTRICT_CODE": "451804", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4620", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3750, "SUB_DISTRICT_CODE": "451805", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4621", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3751, "SUB_DISTRICT_CODE": "451806", "PROVINCE_ID": "33", "DISTRICT_ID": "508", "SUB_DISTRICT_ID": "4622", "ZIPCODE": "45000" },
    { "ZIPCODE_ID": 3752, "SUB_DISTRICT_CODE": "451901", "PROVINCE_ID": "33", "DISTRICT_ID": "509", "SUB_DISTRICT_ID": "4623", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3753, "SUB_DISTRICT_CODE": "451902", "PROVINCE_ID": "33", "DISTRICT_ID": "509", "SUB_DISTRICT_ID": "4624", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3754, "SUB_DISTRICT_CODE": "451903", "PROVINCE_ID": "33", "DISTRICT_ID": "509", "SUB_DISTRICT_ID": "4625", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3755, "SUB_DISTRICT_CODE": "451904", "PROVINCE_ID": "33", "DISTRICT_ID": "509", "SUB_DISTRICT_ID": "4626", "ZIPCODE": "45140" },
    { "ZIPCODE_ID": 3756, "SUB_DISTRICT_CODE": "452001", "PROVINCE_ID": "33", "DISTRICT_ID": "510", "SUB_DISTRICT_ID": "4627", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3757, "SUB_DISTRICT_CODE": "452002", "PROVINCE_ID": "33", "DISTRICT_ID": "510", "SUB_DISTRICT_ID": "4628", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3758, "SUB_DISTRICT_CODE": "452003", "PROVINCE_ID": "33", "DISTRICT_ID": "510", "SUB_DISTRICT_ID": "4629", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3759, "SUB_DISTRICT_CODE": "452004", "PROVINCE_ID": "33", "DISTRICT_ID": "510", "SUB_DISTRICT_ID": "4630", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3760, "SUB_DISTRICT_CODE": "452005", "PROVINCE_ID": "33", "DISTRICT_ID": "510", "SUB_DISTRICT_ID": "4631", "ZIPCODE": "45170" },
    { "ZIPCODE_ID": 3761, "SUB_DISTRICT_CODE": "460101", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4632", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3762, "SUB_DISTRICT_CODE": "460102", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4633", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3763, "SUB_DISTRICT_CODE": "460103", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4634", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3764, "SUB_DISTRICT_CODE": "460104", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4635", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3765, "SUB_DISTRICT_CODE": "460105", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4636", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3766, "SUB_DISTRICT_CODE": "460106", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4637", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3767, "SUB_DISTRICT_CODE": "460107", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4638", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3768, "SUB_DISTRICT_CODE": "460108", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4639", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3769, "SUB_DISTRICT_CODE": "460109", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4640", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3770, "SUB_DISTRICT_CODE": "460111", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4642", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3771, "SUB_DISTRICT_CODE": "460113", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4644", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3772, "SUB_DISTRICT_CODE": "460115", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4646", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3773, "SUB_DISTRICT_CODE": "460116", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4647", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3774, "SUB_DISTRICT_CODE": "460117", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4648", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3775, "SUB_DISTRICT_CODE": "460119", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4650", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3776, "SUB_DISTRICT_CODE": "460120", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4651", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3777, "SUB_DISTRICT_CODE": "460121", "PROVINCE_ID": "34", "DISTRICT_ID": "511", "SUB_DISTRICT_ID": "4652", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3778, "SUB_DISTRICT_CODE": "460201", "PROVINCE_ID": "34", "DISTRICT_ID": "512", "SUB_DISTRICT_ID": "4656", "ZIPCODE": "46230" },
    { "ZIPCODE_ID": 3779, "SUB_DISTRICT_CODE": "460202", "PROVINCE_ID": "34", "DISTRICT_ID": "512", "SUB_DISTRICT_ID": "4657", "ZIPCODE": "46230" },
    { "ZIPCODE_ID": 3780, "SUB_DISTRICT_CODE": "460203", "PROVINCE_ID": "34", "DISTRICT_ID": "512", "SUB_DISTRICT_ID": "4658", "ZIPCODE": "46230" },
    { "ZIPCODE_ID": 3781, "SUB_DISTRICT_CODE": "460204", "PROVINCE_ID": "34", "DISTRICT_ID": "512", "SUB_DISTRICT_ID": "4659", "ZIPCODE": "46230" },
    { "ZIPCODE_ID": 3782, "SUB_DISTRICT_CODE": "460205", "PROVINCE_ID": "34", "DISTRICT_ID": "512", "SUB_DISTRICT_ID": "4660", "ZIPCODE": "46230" },
    { "ZIPCODE_ID": 3783, "SUB_DISTRICT_CODE": "460301", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4661", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3784, "SUB_DISTRICT_CODE": "460302", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4662", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3785, "SUB_DISTRICT_CODE": "460303", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4663", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3786, "SUB_DISTRICT_CODE": "460304", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4664", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3787, "SUB_DISTRICT_CODE": "460305", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4665", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3788, "SUB_DISTRICT_CODE": "460308", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4668", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3789, "SUB_DISTRICT_CODE": "460310", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4670", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3790, "SUB_DISTRICT_CODE": "460311", "PROVINCE_ID": "34", "DISTRICT_ID": "513", "SUB_DISTRICT_ID": "4671", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3791, "SUB_DISTRICT_CODE": "460401", "PROVINCE_ID": "34", "DISTRICT_ID": "514", "SUB_DISTRICT_ID": "4674", "ZIPCODE": "46210" },
    { "ZIPCODE_ID": 3792, "SUB_DISTRICT_CODE": "460402", "PROVINCE_ID": "34", "DISTRICT_ID": "514", "SUB_DISTRICT_ID": "4675", "ZIPCODE": "46210" },
    { "ZIPCODE_ID": 3793, "SUB_DISTRICT_CODE": "460403", "PROVINCE_ID": "34", "DISTRICT_ID": "514", "SUB_DISTRICT_ID": "4676", "ZIPCODE": "46210" },
    { "ZIPCODE_ID": 3794, "SUB_DISTRICT_CODE": "460501", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4677", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3795, "SUB_DISTRICT_CODE": "460502", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4678", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3796, "SUB_DISTRICT_CODE": "460503", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4679", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3797, "SUB_DISTRICT_CODE": "460504", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4680", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3798, "SUB_DISTRICT_CODE": "460505", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4681", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3799, "SUB_DISTRICT_CODE": "460506", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4682", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3800, "SUB_DISTRICT_CODE": "460507", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4683", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3801, "SUB_DISTRICT_CODE": "460508", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4684", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3802, "SUB_DISTRICT_CODE": "460509", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4685", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3803, "SUB_DISTRICT_CODE": "460510", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4686", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3804, "SUB_DISTRICT_CODE": "460511", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4687", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3805, "SUB_DISTRICT_CODE": "460512", "PROVINCE_ID": "34", "DISTRICT_ID": "515", "SUB_DISTRICT_ID": "4688", "ZIPCODE": "46110" },
    { "ZIPCODE_ID": 3806, "SUB_DISTRICT_CODE": "460601", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4689", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3807, "SUB_DISTRICT_CODE": "460602", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4690", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3808, "SUB_DISTRICT_CODE": "460603", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4691", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3809, "SUB_DISTRICT_CODE": "460606", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4694", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3810, "SUB_DISTRICT_CODE": "460608", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4696", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3811, "SUB_DISTRICT_CODE": "460611", "PROVINCE_ID": "34", "DISTRICT_ID": "516", "SUB_DISTRICT_ID": "4699", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3812, "SUB_DISTRICT_CODE": "460701", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4700", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3813, "SUB_DISTRICT_CODE": "460702", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4701", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3814, "SUB_DISTRICT_CODE": "460703", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4702", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3815, "SUB_DISTRICT_CODE": "460704", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4703", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3816, "SUB_DISTRICT_CODE": "460705", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4704", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3817, "SUB_DISTRICT_CODE": "460706", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4705", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3818, "SUB_DISTRICT_CODE": "460707", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4706", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3819, "SUB_DISTRICT_CODE": "460708", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4707", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3820, "SUB_DISTRICT_CODE": "460709", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4708", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3821, "SUB_DISTRICT_CODE": "460710", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4709", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3822, "SUB_DISTRICT_CODE": "460711", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4710", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3823, "SUB_DISTRICT_CODE": "460712", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4711", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3824, "SUB_DISTRICT_CODE": "460713", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4712", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3825, "SUB_DISTRICT_CODE": "460714", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4713", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3826, "SUB_DISTRICT_CODE": "460715", "PROVINCE_ID": "34", "DISTRICT_ID": "517", "SUB_DISTRICT_ID": "4714", "ZIPCODE": "46120" },
    { "ZIPCODE_ID": 3827, "SUB_DISTRICT_CODE": "460801", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4715", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3828, "SUB_DISTRICT_CODE": "460802", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4716", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3829, "SUB_DISTRICT_CODE": "460803", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4717", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3830, "SUB_DISTRICT_CODE": "460804", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4718", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3831, "SUB_DISTRICT_CODE": "460805", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4719", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3832, "SUB_DISTRICT_CODE": "460806", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4720", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3833, "SUB_DISTRICT_CODE": "460807", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4721", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3834, "SUB_DISTRICT_CODE": "460808", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4722", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3835, "SUB_DISTRICT_CODE": "460809", "PROVINCE_ID": "34", "DISTRICT_ID": "518", "SUB_DISTRICT_ID": "4723", "ZIPCODE": "46170" },
    { "ZIPCODE_ID": 3836, "SUB_DISTRICT_CODE": "460901", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4724", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3837, "SUB_DISTRICT_CODE": "460902", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4725", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3838, "SUB_DISTRICT_CODE": "460903", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4726", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3839, "SUB_DISTRICT_CODE": "460904", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4727", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3840, "SUB_DISTRICT_CODE": "460905", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4728", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3841, "SUB_DISTRICT_CODE": "460906", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4729", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3842, "SUB_DISTRICT_CODE": "460907", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4730", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3843, "SUB_DISTRICT_CODE": "460908", "PROVINCE_ID": "34", "DISTRICT_ID": "519", "SUB_DISTRICT_ID": "4731", "ZIPCODE": "46140" },
    { "ZIPCODE_ID": 3844, "SUB_DISTRICT_CODE": "461001", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4736", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3845, "SUB_DISTRICT_CODE": "461002", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4737", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3846, "SUB_DISTRICT_CODE": "461005", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4740", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3847, "SUB_DISTRICT_CODE": "461006", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4741", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3848, "SUB_DISTRICT_CODE": "461007", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4742", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3849, "SUB_DISTRICT_CODE": "461009", "PROVINCE_ID": "34", "DISTRICT_ID": "520", "SUB_DISTRICT_ID": "4744", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3850, "SUB_DISTRICT_CODE": "461101", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4746", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3851, "SUB_DISTRICT_CODE": "461102", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4747", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3852, "SUB_DISTRICT_CODE": "461103", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4748", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3853, "SUB_DISTRICT_CODE": "461104", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4749", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3854, "SUB_DISTRICT_CODE": "461105", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4750", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3855, "SUB_DISTRICT_CODE": "461106", "PROVINCE_ID": "34", "DISTRICT_ID": "521", "SUB_DISTRICT_ID": "4751", "ZIPCODE": "46190" },
    { "ZIPCODE_ID": 3856, "SUB_DISTRICT_CODE": "461201", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4754", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3857, "SUB_DISTRICT_CODE": "461202", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4755", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3858, "SUB_DISTRICT_CODE": "461203", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4756", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3859, "SUB_DISTRICT_CODE": "461204", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4757", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3860, "SUB_DISTRICT_CODE": "461205", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4758", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3861, "SUB_DISTRICT_CODE": "461206", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4759", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3862, "SUB_DISTRICT_CODE": "461207", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4760", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3863, "SUB_DISTRICT_CODE": "461208", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4761", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3864, "SUB_DISTRICT_CODE": "461209", "PROVINCE_ID": "34", "DISTRICT_ID": "522", "SUB_DISTRICT_ID": "4762", "ZIPCODE": "46220" },
    { "ZIPCODE_ID": 3865, "SUB_DISTRICT_CODE": "461301", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4763", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3866, "SUB_DISTRICT_CODE": "461302", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4764", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3867, "SUB_DISTRICT_CODE": "461303", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4765", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3868, "SUB_DISTRICT_CODE": "461304", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4766", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3869, "SUB_DISTRICT_CODE": "461305", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4767", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3870, "SUB_DISTRICT_CODE": "461306", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4768", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3871, "SUB_DISTRICT_CODE": "461307", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4769", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3872, "SUB_DISTRICT_CODE": "461308", "PROVINCE_ID": "34", "DISTRICT_ID": "523", "SUB_DISTRICT_ID": "4770", "ZIPCODE": "46150" },
    { "ZIPCODE_ID": 3873, "SUB_DISTRICT_CODE": "461401", "PROVINCE_ID": "34", "DISTRICT_ID": "524", "SUB_DISTRICT_ID": "4771", "ZIPCODE": "46240" },
    { "ZIPCODE_ID": 3874, "SUB_DISTRICT_CODE": "461402", "PROVINCE_ID": "34", "DISTRICT_ID": "524", "SUB_DISTRICT_ID": "4772", "ZIPCODE": "46240" },
    { "ZIPCODE_ID": 3875, "SUB_DISTRICT_CODE": "461403", "PROVINCE_ID": "34", "DISTRICT_ID": "524", "SUB_DISTRICT_ID": "4773", "ZIPCODE": "46240" },
    { "ZIPCODE_ID": 3876, "SUB_DISTRICT_CODE": "461404", "PROVINCE_ID": "34", "DISTRICT_ID": "524", "SUB_DISTRICT_ID": "4774", "ZIPCODE": "46240" },
    { "ZIPCODE_ID": 3877, "SUB_DISTRICT_CODE": "461501", "PROVINCE_ID": "34", "DISTRICT_ID": "525", "SUB_DISTRICT_ID": "4775", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3878, "SUB_DISTRICT_CODE": "461502", "PROVINCE_ID": "34", "DISTRICT_ID": "525", "SUB_DISTRICT_ID": "4776", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3879, "SUB_DISTRICT_CODE": "461503", "PROVINCE_ID": "34", "DISTRICT_ID": "525", "SUB_DISTRICT_ID": "4777", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3880, "SUB_DISTRICT_CODE": "461504", "PROVINCE_ID": "34", "DISTRICT_ID": "525", "SUB_DISTRICT_ID": "4778", "ZIPCODE": "46180" },
    { "ZIPCODE_ID": 3881, "SUB_DISTRICT_CODE": "461601", "PROVINCE_ID": "34", "DISTRICT_ID": "526", "SUB_DISTRICT_ID": "4779", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3882, "SUB_DISTRICT_CODE": "461602", "PROVINCE_ID": "34", "DISTRICT_ID": "526", "SUB_DISTRICT_ID": "4780", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3883, "SUB_DISTRICT_CODE": "461603", "PROVINCE_ID": "34", "DISTRICT_ID": "526", "SUB_DISTRICT_ID": "4781", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3884, "SUB_DISTRICT_CODE": "461604", "PROVINCE_ID": "34", "DISTRICT_ID": "526", "SUB_DISTRICT_ID": "4782", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3885, "SUB_DISTRICT_CODE": "461605", "PROVINCE_ID": "34", "DISTRICT_ID": "526", "SUB_DISTRICT_ID": "4783", "ZIPCODE": "46160" },
    { "ZIPCODE_ID": 3886, "SUB_DISTRICT_CODE": "461701", "PROVINCE_ID": "34", "DISTRICT_ID": "527", "SUB_DISTRICT_ID": "4784", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3887, "SUB_DISTRICT_CODE": "461702", "PROVINCE_ID": "34", "DISTRICT_ID": "527", "SUB_DISTRICT_ID": "4785", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3888, "SUB_DISTRICT_CODE": "461703", "PROVINCE_ID": "34", "DISTRICT_ID": "527", "SUB_DISTRICT_ID": "4786", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3889, "SUB_DISTRICT_CODE": "461704", "PROVINCE_ID": "34", "DISTRICT_ID": "527", "SUB_DISTRICT_ID": "4787", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3890, "SUB_DISTRICT_CODE": "461705", "PROVINCE_ID": "34", "DISTRICT_ID": "527", "SUB_DISTRICT_ID": "4788", "ZIPCODE": "46000" },
    { "ZIPCODE_ID": 3891, "SUB_DISTRICT_CODE": "461801", "PROVINCE_ID": "34", "DISTRICT_ID": "528", "SUB_DISTRICT_ID": "4789", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3892, "SUB_DISTRICT_CODE": "461802", "PROVINCE_ID": "34", "DISTRICT_ID": "528", "SUB_DISTRICT_ID": "4790", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3893, "SUB_DISTRICT_CODE": "461803", "PROVINCE_ID": "34", "DISTRICT_ID": "528", "SUB_DISTRICT_ID": "4791", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3894, "SUB_DISTRICT_CODE": "461804", "PROVINCE_ID": "34", "DISTRICT_ID": "528", "SUB_DISTRICT_ID": "4792", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3895, "SUB_DISTRICT_CODE": "461805", "PROVINCE_ID": "34", "DISTRICT_ID": "528", "SUB_DISTRICT_ID": "4793", "ZIPCODE": "46130" },
    { "ZIPCODE_ID": 3896, "SUB_DISTRICT_CODE": "470101", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4794", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3897, "SUB_DISTRICT_CODE": "470102", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4795", "ZIPCODE": "47220" },
    { "ZIPCODE_ID": 3898, "SUB_DISTRICT_CODE": "470103", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4796", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3899, "SUB_DISTRICT_CODE": "470104", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4797", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3900, "SUB_DISTRICT_CODE": "470106", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4799", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3901, "SUB_DISTRICT_CODE": "470107", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4800", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3902, "SUB_DISTRICT_CODE": "470109", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4802", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3903, "SUB_DISTRICT_CODE": "470111", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4804", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3904, "SUB_DISTRICT_CODE": "470112", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4805", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3905, "SUB_DISTRICT_CODE": "470113", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4806", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3906, "SUB_DISTRICT_CODE": "470115", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4808", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3907, "SUB_DISTRICT_CODE": "470116", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4809", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3908, "SUB_DISTRICT_CODE": "470117", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4810", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3909, "SUB_DISTRICT_CODE": "470118", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4811", "ZIPCODE": "47220" },
    { "ZIPCODE_ID": 3910, "SUB_DISTRICT_CODE": "470120", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4813", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3911, "SUB_DISTRICT_CODE": "470121", "PROVINCE_ID": "35", "DISTRICT_ID": "529", "SUB_DISTRICT_ID": "4814", "ZIPCODE": "47000" },
    { "ZIPCODE_ID": 3912, "SUB_DISTRICT_CODE": "470201", "PROVINCE_ID": "35", "DISTRICT_ID": "530", "SUB_DISTRICT_ID": "4821", "ZIPCODE": "47210" },
    { "ZIPCODE_ID": 3913, "SUB_DISTRICT_CODE": "470202", "PROVINCE_ID": "35", "DISTRICT_ID": "530", "SUB_DISTRICT_ID": "4822", "ZIPCODE": "47210" },
    { "ZIPCODE_ID": 3914, "SUB_DISTRICT_CODE": "470203", "PROVINCE_ID": "35", "DISTRICT_ID": "530", "SUB_DISTRICT_ID": "4823", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 3915, "SUB_DISTRICT_CODE": "470204", "PROVINCE_ID": "35", "DISTRICT_ID": "530", "SUB_DISTRICT_ID": "4824", "ZIPCODE": "47210" },
    { "ZIPCODE_ID": 3916, "SUB_DISTRICT_CODE": "470205", "PROVINCE_ID": "35", "DISTRICT_ID": "530", "SUB_DISTRICT_ID": "4825", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 3917, "SUB_DISTRICT_CODE": "470301", "PROVINCE_ID": "35", "DISTRICT_ID": "531", "SUB_DISTRICT_ID": "4826", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 3918, "SUB_DISTRICT_CODE": "470303", "PROVINCE_ID": "35", "DISTRICT_ID": "531", "SUB_DISTRICT_ID": "4828", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 3919, "SUB_DISTRICT_CODE": "470305", "PROVINCE_ID": "35", "DISTRICT_ID": "531", "SUB_DISTRICT_ID": "4830", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 3920, "SUB_DISTRICT_CODE": "470401", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4832", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3921, "SUB_DISTRICT_CODE": "470402", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4833", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3922, "SUB_DISTRICT_CODE": "470403", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4834", "ZIPCODE": "47220" },
    { "ZIPCODE_ID": 3923, "SUB_DISTRICT_CODE": "470404", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4835", "ZIPCODE": "47220" },
    { "ZIPCODE_ID": 3924, "SUB_DISTRICT_CODE": "470405", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4836", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3925, "SUB_DISTRICT_CODE": "470406", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4837", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3926, "SUB_DISTRICT_CODE": "470407", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4838", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3927, "SUB_DISTRICT_CODE": "470408", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4839", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3928, "SUB_DISTRICT_CODE": "470409", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4840", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3929, "SUB_DISTRICT_CODE": "470410", "PROVINCE_ID": "35", "DISTRICT_ID": "532", "SUB_DISTRICT_ID": "4841", "ZIPCODE": "47130" },
    { "ZIPCODE_ID": 3930, "SUB_DISTRICT_CODE": "470501", "PROVINCE_ID": "35", "DISTRICT_ID": "533", "SUB_DISTRICT_ID": "4842", "ZIPCODE": "47160" },
    { "ZIPCODE_ID": 3931, "SUB_DISTRICT_CODE": "470502", "PROVINCE_ID": "35", "DISTRICT_ID": "533", "SUB_DISTRICT_ID": "4843", "ZIPCODE": "47160" },
    { "ZIPCODE_ID": 3932, "SUB_DISTRICT_CODE": "470503", "PROVINCE_ID": "35", "DISTRICT_ID": "533", "SUB_DISTRICT_ID": "4844", "ZIPCODE": "47160" },
    { "ZIPCODE_ID": 3933, "SUB_DISTRICT_CODE": "470504", "PROVINCE_ID": "35", "DISTRICT_ID": "533", "SUB_DISTRICT_ID": "4845", "ZIPCODE": "47160" },
    { "ZIPCODE_ID": 3934, "SUB_DISTRICT_CODE": "470505", "PROVINCE_ID": "35", "DISTRICT_ID": "533", "SUB_DISTRICT_ID": "4846", "ZIPCODE": "47160" },
    { "ZIPCODE_ID": 3935, "SUB_DISTRICT_CODE": "470601", "PROVINCE_ID": "35", "DISTRICT_ID": "534", "SUB_DISTRICT_ID": "4849", "ZIPCODE": "47150" },
    { "ZIPCODE_ID": 3936, "SUB_DISTRICT_CODE": "470602", "PROVINCE_ID": "35", "DISTRICT_ID": "534", "SUB_DISTRICT_ID": "4850", "ZIPCODE": "47150" },
    { "ZIPCODE_ID": 3937, "SUB_DISTRICT_CODE": "470603", "PROVINCE_ID": "35", "DISTRICT_ID": "534", "SUB_DISTRICT_ID": "4851", "ZIPCODE": "47150" },
    { "ZIPCODE_ID": 3938, "SUB_DISTRICT_CODE": "470604", "PROVINCE_ID": "35", "DISTRICT_ID": "534", "SUB_DISTRICT_ID": "4852", "ZIPCODE": "47150" },
    { "ZIPCODE_ID": 3939, "SUB_DISTRICT_CODE": "470605", "PROVINCE_ID": "35", "DISTRICT_ID": "534", "SUB_DISTRICT_ID": "4853", "ZIPCODE": "47150" },
    { "ZIPCODE_ID": 3940, "SUB_DISTRICT_CODE": "470701", "PROVINCE_ID": "35", "DISTRICT_ID": "535", "SUB_DISTRICT_ID": "4854", "ZIPCODE": "47270" },
    { "ZIPCODE_ID": 3941, "SUB_DISTRICT_CODE": "470702", "PROVINCE_ID": "35", "DISTRICT_ID": "535", "SUB_DISTRICT_ID": "4855", "ZIPCODE": "47270" },
    { "ZIPCODE_ID": 3942, "SUB_DISTRICT_CODE": "470703", "PROVINCE_ID": "35", "DISTRICT_ID": "535", "SUB_DISTRICT_ID": "4856", "ZIPCODE": "47270" },
    { "ZIPCODE_ID": 3943, "SUB_DISTRICT_CODE": "470704", "PROVINCE_ID": "35", "DISTRICT_ID": "535", "SUB_DISTRICT_ID": "4857", "ZIPCODE": "47270" },
    { "ZIPCODE_ID": 3944, "SUB_DISTRICT_CODE": "470801", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4858", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3945, "SUB_DISTRICT_CODE": "470802", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4859", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3946, "SUB_DISTRICT_CODE": "470803", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4860", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3947, "SUB_DISTRICT_CODE": "470804", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4861", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3948, "SUB_DISTRICT_CODE": "470805", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4862", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3949, "SUB_DISTRICT_CODE": "470806", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4863", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3950, "SUB_DISTRICT_CODE": "470807", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4864", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3951, "SUB_DISTRICT_CODE": "470808", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4865", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3952, "SUB_DISTRICT_CODE": "470809", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4866", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3953, "SUB_DISTRICT_CODE": "470810", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4867", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3954, "SUB_DISTRICT_CODE": "470811", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4868", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3955, "SUB_DISTRICT_CODE": "470812", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4869", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3956, "SUB_DISTRICT_CODE": "470813", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4870", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3957, "SUB_DISTRICT_CODE": "470814", "PROVINCE_ID": "35", "DISTRICT_ID": "536", "SUB_DISTRICT_ID": "4871", "ZIPCODE": "47120" },
    { "ZIPCODE_ID": 3958, "SUB_DISTRICT_CODE": "470901", "PROVINCE_ID": "35", "DISTRICT_ID": "537", "SUB_DISTRICT_ID": "4872", "ZIPCODE": "47250" },
    { "ZIPCODE_ID": 3959, "SUB_DISTRICT_CODE": "470902", "PROVINCE_ID": "35", "DISTRICT_ID": "537", "SUB_DISTRICT_ID": "4873", "ZIPCODE": "47250" },
    { "ZIPCODE_ID": 3960, "SUB_DISTRICT_CODE": "470903", "PROVINCE_ID": "35", "DISTRICT_ID": "537", "SUB_DISTRICT_ID": "4874", "ZIPCODE": "47250" },
    { "ZIPCODE_ID": 3961, "SUB_DISTRICT_CODE": "470904", "PROVINCE_ID": "35", "DISTRICT_ID": "537", "SUB_DISTRICT_ID": "4875", "ZIPCODE": "47250" },
    { "ZIPCODE_ID": 3962, "SUB_DISTRICT_CODE": "471001", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4876", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3963, "SUB_DISTRICT_CODE": "471002", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4877", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3964, "SUB_DISTRICT_CODE": "471003", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4878", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3965, "SUB_DISTRICT_CODE": "471004", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4879", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3966, "SUB_DISTRICT_CODE": "471005", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4880", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3967, "SUB_DISTRICT_CODE": "471006", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4881", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3968, "SUB_DISTRICT_CODE": "471007", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4882", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3969, "SUB_DISTRICT_CODE": "471008", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4883", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3970, "SUB_DISTRICT_CODE": "471009", "PROVINCE_ID": "35", "DISTRICT_ID": "538", "SUB_DISTRICT_ID": "4884", "ZIPCODE": "47140" },
    { "ZIPCODE_ID": 3971, "SUB_DISTRICT_CODE": "471101", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4885", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3972, "SUB_DISTRICT_CODE": "471102", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4886", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3973, "SUB_DISTRICT_CODE": "471103", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4887", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3974, "SUB_DISTRICT_CODE": "471104", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4888", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3975, "SUB_DISTRICT_CODE": "471105", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4889", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3976, "SUB_DISTRICT_CODE": "471106", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4890", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3977, "SUB_DISTRICT_CODE": "471107", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4891", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3978, "SUB_DISTRICT_CODE": "471108", "PROVINCE_ID": "35", "DISTRICT_ID": "539", "SUB_DISTRICT_ID": "4892", "ZIPCODE": "47170" },
    { "ZIPCODE_ID": 3979, "SUB_DISTRICT_CODE": "471201", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4893", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3980, "SUB_DISTRICT_CODE": "471203", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4895", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3981, "SUB_DISTRICT_CODE": "471204", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4896", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3982, "SUB_DISTRICT_CODE": "471206", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4898", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3983, "SUB_DISTRICT_CODE": "471207", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4899", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3984, "SUB_DISTRICT_CODE": "471208", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4900", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3985, "SUB_DISTRICT_CODE": "471210", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4902", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3986, "SUB_DISTRICT_CODE": "471211", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4903", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3987, "SUB_DISTRICT_CODE": "471212", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4904", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3988, "SUB_DISTRICT_CODE": "471213", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4905", "ZIPCODE": "47240" },
    { "ZIPCODE_ID": 3989, "SUB_DISTRICT_CODE": "471214", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4906", "ZIPCODE": "47240" },
    { "ZIPCODE_ID": 3990, "SUB_DISTRICT_CODE": "471215", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4907", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3991, "SUB_DISTRICT_CODE": "471216", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4908", "ZIPCODE": "47240" },
    { "ZIPCODE_ID": 3992, "SUB_DISTRICT_CODE": "471217", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4909", "ZIPCODE": "47240" },
    { "ZIPCODE_ID": 3993, "SUB_DISTRICT_CODE": "471220", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4912", "ZIPCODE": "47240" },
    { "ZIPCODE_ID": 3994, "SUB_DISTRICT_CODE": "471221", "PROVINCE_ID": "35", "DISTRICT_ID": "540", "SUB_DISTRICT_ID": "4913", "ZIPCODE": "47110" },
    { "ZIPCODE_ID": 3995, "SUB_DISTRICT_CODE": "471301", "PROVINCE_ID": "35", "DISTRICT_ID": "541", "SUB_DISTRICT_ID": "4914", "ZIPCODE": "47190" },
    { "ZIPCODE_ID": 3996, "SUB_DISTRICT_CODE": "471302", "PROVINCE_ID": "35", "DISTRICT_ID": "541", "SUB_DISTRICT_ID": "4915", "ZIPCODE": "47190" },
    { "ZIPCODE_ID": 3997, "SUB_DISTRICT_CODE": "471303", "PROVINCE_ID": "35", "DISTRICT_ID": "541", "SUB_DISTRICT_ID": "4916", "ZIPCODE": "47190" },
    { "ZIPCODE_ID": 3998, "SUB_DISTRICT_CODE": "471304", "PROVINCE_ID": "35", "DISTRICT_ID": "541", "SUB_DISTRICT_ID": "4917", "ZIPCODE": "47190" },
    { "ZIPCODE_ID": 3999, "SUB_DISTRICT_CODE": "471401", "PROVINCE_ID": "35", "DISTRICT_ID": "542", "SUB_DISTRICT_ID": "4918", "ZIPCODE": "47260" },
    { "ZIPCODE_ID": 4000, "SUB_DISTRICT_CODE": "471402", "PROVINCE_ID": "35", "DISTRICT_ID": "542", "SUB_DISTRICT_ID": "4919", "ZIPCODE": "47260" },
    { "ZIPCODE_ID": 4001, "SUB_DISTRICT_CODE": "471403", "PROVINCE_ID": "35", "DISTRICT_ID": "542", "SUB_DISTRICT_ID": "4920", "ZIPCODE": "47260" },
    { "ZIPCODE_ID": 4002, "SUB_DISTRICT_CODE": "471404", "PROVINCE_ID": "35", "DISTRICT_ID": "542", "SUB_DISTRICT_ID": "4921", "ZIPCODE": "47260" },
    { "ZIPCODE_ID": 4003, "SUB_DISTRICT_CODE": "471501", "PROVINCE_ID": "35", "DISTRICT_ID": "543", "SUB_DISTRICT_ID": "4922", "ZIPCODE": "47280" },
    { "ZIPCODE_ID": 4004, "SUB_DISTRICT_CODE": "471502", "PROVINCE_ID": "35", "DISTRICT_ID": "543", "SUB_DISTRICT_ID": "4923", "ZIPCODE": "47280" },
    { "ZIPCODE_ID": 4005, "SUB_DISTRICT_CODE": "471503", "PROVINCE_ID": "35", "DISTRICT_ID": "543", "SUB_DISTRICT_ID": "4924", "ZIPCODE": "47280" },
    { "ZIPCODE_ID": 4006, "SUB_DISTRICT_CODE": "471504", "PROVINCE_ID": "35", "DISTRICT_ID": "543", "SUB_DISTRICT_ID": "4925", "ZIPCODE": "47280" },
    { "ZIPCODE_ID": 4007, "SUB_DISTRICT_CODE": "471601", "PROVINCE_ID": "35", "DISTRICT_ID": "544", "SUB_DISTRICT_ID": "4926", "ZIPCODE": "47290" },
    { "ZIPCODE_ID": 4008, "SUB_DISTRICT_CODE": "471602", "PROVINCE_ID": "35", "DISTRICT_ID": "544", "SUB_DISTRICT_ID": "4927", "ZIPCODE": "47290" },
    { "ZIPCODE_ID": 4009, "SUB_DISTRICT_CODE": "471603", "PROVINCE_ID": "35", "DISTRICT_ID": "544", "SUB_DISTRICT_ID": "4928", "ZIPCODE": "47290" },
    { "ZIPCODE_ID": 4010, "SUB_DISTRICT_CODE": "471604", "PROVINCE_ID": "35", "DISTRICT_ID": "544", "SUB_DISTRICT_ID": "4929", "ZIPCODE": "47290" },
    { "ZIPCODE_ID": 4011, "SUB_DISTRICT_CODE": "471605", "PROVINCE_ID": "35", "DISTRICT_ID": "544", "SUB_DISTRICT_ID": "4930", "ZIPCODE": "47290" },
    { "ZIPCODE_ID": 4012, "SUB_DISTRICT_CODE": "471701", "PROVINCE_ID": "35", "DISTRICT_ID": "545", "SUB_DISTRICT_ID": "4931", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 4013, "SUB_DISTRICT_CODE": "471702", "PROVINCE_ID": "35", "DISTRICT_ID": "545", "SUB_DISTRICT_ID": "4932", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 4014, "SUB_DISTRICT_CODE": "471703", "PROVINCE_ID": "35", "DISTRICT_ID": "545", "SUB_DISTRICT_ID": "4933", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 4015, "SUB_DISTRICT_CODE": "471704", "PROVINCE_ID": "35", "DISTRICT_ID": "545", "SUB_DISTRICT_ID": "4934", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 4016, "SUB_DISTRICT_CODE": "471705", "PROVINCE_ID": "35", "DISTRICT_ID": "545", "SUB_DISTRICT_ID": "4935", "ZIPCODE": "47230" },
    { "ZIPCODE_ID": 4017, "SUB_DISTRICT_CODE": "471801", "PROVINCE_ID": "35", "DISTRICT_ID": "546", "SUB_DISTRICT_ID": "4936", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 4018, "SUB_DISTRICT_CODE": "471802", "PROVINCE_ID": "35", "DISTRICT_ID": "546", "SUB_DISTRICT_ID": "4937", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 4019, "SUB_DISTRICT_CODE": "471803", "PROVINCE_ID": "35", "DISTRICT_ID": "546", "SUB_DISTRICT_ID": "4938", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 4020, "SUB_DISTRICT_CODE": "471804", "PROVINCE_ID": "35", "DISTRICT_ID": "546", "SUB_DISTRICT_ID": "4939", "ZIPCODE": "47180" },
    { "ZIPCODE_ID": 4021, "SUB_DISTRICT_CODE": "480101", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4940", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4022, "SUB_DISTRICT_CODE": "480102", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4941", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4023, "SUB_DISTRICT_CODE": "480103", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4942", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4024, "SUB_DISTRICT_CODE": "480104", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4943", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4025, "SUB_DISTRICT_CODE": "480105", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4944", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4026, "SUB_DISTRICT_CODE": "480106", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4945", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4027, "SUB_DISTRICT_CODE": "480107", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4946", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4028, "SUB_DISTRICT_CODE": "480108", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4947", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4029, "SUB_DISTRICT_CODE": "480109", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4948", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4030, "SUB_DISTRICT_CODE": "480110", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4949", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4031, "SUB_DISTRICT_CODE": "480111", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4950", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4032, "SUB_DISTRICT_CODE": "480112", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4951", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4033, "SUB_DISTRICT_CODE": "480113", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4952", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4034, "SUB_DISTRICT_CODE": "480114", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4953", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4035, "SUB_DISTRICT_CODE": "480115", "PROVINCE_ID": "36", "DISTRICT_ID": "549", "SUB_DISTRICT_ID": "4954", "ZIPCODE": "48000" },
    { "ZIPCODE_ID": 4036, "SUB_DISTRICT_CODE": "480201", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4955", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4037, "SUB_DISTRICT_CODE": "480202", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4956", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4038, "SUB_DISTRICT_CODE": "480203", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4957", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4039, "SUB_DISTRICT_CODE": "480204", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4958", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4040, "SUB_DISTRICT_CODE": "480205", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4959", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4041, "SUB_DISTRICT_CODE": "480206", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4960", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4042, "SUB_DISTRICT_CODE": "480207", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4961", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4043, "SUB_DISTRICT_CODE": "480208", "PROVINCE_ID": "36", "DISTRICT_ID": "550", "SUB_DISTRICT_ID": "4962", "ZIPCODE": "48160" },
    { "ZIPCODE_ID": 4044, "SUB_DISTRICT_CODE": "480301", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4963", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4045, "SUB_DISTRICT_CODE": "480302", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4964", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4046, "SUB_DISTRICT_CODE": "480303", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4965", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4047, "SUB_DISTRICT_CODE": "480304", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4966", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4048, "SUB_DISTRICT_CODE": "480305", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4967", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4049, "SUB_DISTRICT_CODE": "480306", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4968", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4050, "SUB_DISTRICT_CODE": "480311", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4973", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4051, "SUB_DISTRICT_CODE": "480312", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4974", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4052, "SUB_DISTRICT_CODE": "480314", "PROVINCE_ID": "36", "DISTRICT_ID": "551", "SUB_DISTRICT_ID": "4976", "ZIPCODE": "48120" },
    { "ZIPCODE_ID": 4053, "SUB_DISTRICT_CODE": "480401", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4977", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4054, "SUB_DISTRICT_CODE": "480402", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4978", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4055, "SUB_DISTRICT_CODE": "480403", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4979", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4056, "SUB_DISTRICT_CODE": "480404", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4980", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4057, "SUB_DISTRICT_CODE": "480408", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4984", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4058, "SUB_DISTRICT_CODE": "480409", "PROVINCE_ID": "36", "DISTRICT_ID": "552", "SUB_DISTRICT_ID": "4985", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4059, "SUB_DISTRICT_CODE": "480501", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4986", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4060, "SUB_DISTRICT_CODE": "480502", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4987", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4061, "SUB_DISTRICT_CODE": "480503", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4988", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4062, "SUB_DISTRICT_CODE": "480504", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4989", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4063, "SUB_DISTRICT_CODE": "480505", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4990", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4064, "SUB_DISTRICT_CODE": "480506", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4991", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4065, "SUB_DISTRICT_CODE": "480507", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4992", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4066, "SUB_DISTRICT_CODE": "480508", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4993", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4067, "SUB_DISTRICT_CODE": "480509", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4994", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4068, "SUB_DISTRICT_CODE": "480510", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4995", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4069, "SUB_DISTRICT_CODE": "480511", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4996", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4070, "SUB_DISTRICT_CODE": "480512", "PROVINCE_ID": "36", "DISTRICT_ID": "553", "SUB_DISTRICT_ID": "4997", "ZIPCODE": "48110" },
    { "ZIPCODE_ID": 4071, "SUB_DISTRICT_CODE": "480601", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "4998", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4072, "SUB_DISTRICT_CODE": "480602", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "4999", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4073, "SUB_DISTRICT_CODE": "480603", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5000", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4074, "SUB_DISTRICT_CODE": "480604", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5001", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4075, "SUB_DISTRICT_CODE": "480605", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5002", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4076, "SUB_DISTRICT_CODE": "480607", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5004", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4077, "SUB_DISTRICT_CODE": "480608", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5005", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4078, "SUB_DISTRICT_CODE": "480609", "PROVINCE_ID": "36", "DISTRICT_ID": "554", "SUB_DISTRICT_ID": "5006", "ZIPCODE": "48170" },
    { "ZIPCODE_ID": 4079, "SUB_DISTRICT_CODE": "480701", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5007", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4080, "SUB_DISTRICT_CODE": "480702", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5008", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4081, "SUB_DISTRICT_CODE": "480703", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5009", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4082, "SUB_DISTRICT_CODE": "480704", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5010", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4083, "SUB_DISTRICT_CODE": "480705", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5011", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4084, "SUB_DISTRICT_CODE": "480706", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5012", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4085, "SUB_DISTRICT_CODE": "480707", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5013", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4086, "SUB_DISTRICT_CODE": "480708", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5014", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4087, "SUB_DISTRICT_CODE": "480709", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5015", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4088, "SUB_DISTRICT_CODE": "480712", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5018", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4089, "SUB_DISTRICT_CODE": "480713", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5019", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4090, "SUB_DISTRICT_CODE": "480715", "PROVINCE_ID": "36", "DISTRICT_ID": "555", "SUB_DISTRICT_ID": "5021", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4091, "SUB_DISTRICT_CODE": "480801", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5023", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4092, "SUB_DISTRICT_CODE": "480802", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5024", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4093, "SUB_DISTRICT_CODE": "480803", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5025", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4094, "SUB_DISTRICT_CODE": "480804", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5026", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4095, "SUB_DISTRICT_CODE": "480805", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5027", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4096, "SUB_DISTRICT_CODE": "480806", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5028", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4097, "SUB_DISTRICT_CODE": "480807", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5029", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4098, "SUB_DISTRICT_CODE": "480808", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5030", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4099, "SUB_DISTRICT_CODE": "480809", "PROVINCE_ID": "36", "DISTRICT_ID": "556", "SUB_DISTRICT_ID": "5031", "ZIPCODE": "48150" },
    { "ZIPCODE_ID": 4100, "SUB_DISTRICT_CODE": "480901", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5032", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4101, "SUB_DISTRICT_CODE": "480902", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5033", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4102, "SUB_DISTRICT_CODE": "480903", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5034", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4103, "SUB_DISTRICT_CODE": "480904", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5035", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4104, "SUB_DISTRICT_CODE": "480905", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5036", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4105, "SUB_DISTRICT_CODE": "480906", "PROVINCE_ID": "36", "DISTRICT_ID": "557", "SUB_DISTRICT_ID": "5037", "ZIPCODE": "48180" },
    { "ZIPCODE_ID": 4106, "SUB_DISTRICT_CODE": "481001", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5038", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4107, "SUB_DISTRICT_CODE": "481002", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5039", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4108, "SUB_DISTRICT_CODE": "481003", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5040", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4109, "SUB_DISTRICT_CODE": "481004", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5041", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4110, "SUB_DISTRICT_CODE": "481005", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5042", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4111, "SUB_DISTRICT_CODE": "481006", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5043", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4112, "SUB_DISTRICT_CODE": "481007", "PROVINCE_ID": "36", "DISTRICT_ID": "558", "SUB_DISTRICT_ID": "5044", "ZIPCODE": "48190" },
    { "ZIPCODE_ID": 4113, "SUB_DISTRICT_CODE": "481101", "PROVINCE_ID": "36", "DISTRICT_ID": "559", "SUB_DISTRICT_ID": "5045", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4114, "SUB_DISTRICT_CODE": "481102", "PROVINCE_ID": "36", "DISTRICT_ID": "559", "SUB_DISTRICT_ID": "5046", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4115, "SUB_DISTRICT_CODE": "481103", "PROVINCE_ID": "36", "DISTRICT_ID": "559", "SUB_DISTRICT_ID": "5047", "ZIPCODE": "48140" },
    { "ZIPCODE_ID": 4116, "SUB_DISTRICT_CODE": "481201", "PROVINCE_ID": "36", "DISTRICT_ID": "560", "SUB_DISTRICT_ID": "5048", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4117, "SUB_DISTRICT_CODE": "481202", "PROVINCE_ID": "36", "DISTRICT_ID": "560", "SUB_DISTRICT_ID": "5049", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4118, "SUB_DISTRICT_CODE": "481203", "PROVINCE_ID": "36", "DISTRICT_ID": "560", "SUB_DISTRICT_ID": "5050", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4119, "SUB_DISTRICT_CODE": "481204", "PROVINCE_ID": "36", "DISTRICT_ID": "560", "SUB_DISTRICT_ID": "5051", "ZIPCODE": "48130" },
    { "ZIPCODE_ID": 4120, "SUB_DISTRICT_CODE": "490101", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5052", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4121, "SUB_DISTRICT_CODE": "490102", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5053", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4122, "SUB_DISTRICT_CODE": "490103", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5054", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4123, "SUB_DISTRICT_CODE": "490104", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5055", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4124, "SUB_DISTRICT_CODE": "490105", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5056", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4125, "SUB_DISTRICT_CODE": "490106", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5057", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4126, "SUB_DISTRICT_CODE": "490107", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5058", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4127, "SUB_DISTRICT_CODE": "490108", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5059", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4128, "SUB_DISTRICT_CODE": "490109", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5060", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4129, "SUB_DISTRICT_CODE": "490110", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5061", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4130, "SUB_DISTRICT_CODE": "490111", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5062", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4131, "SUB_DISTRICT_CODE": "490112", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5063", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4132, "SUB_DISTRICT_CODE": "490113", "PROVINCE_ID": "37", "DISTRICT_ID": "561", "SUB_DISTRICT_ID": "5064", "ZIPCODE": "49000" },
    { "ZIPCODE_ID": 4133, "SUB_DISTRICT_CODE": "490201", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5071", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4134, "SUB_DISTRICT_CODE": "490202", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5072", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4135, "SUB_DISTRICT_CODE": "490203", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5073", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4136, "SUB_DISTRICT_CODE": "490204", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5074", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4137, "SUB_DISTRICT_CODE": "490205", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5075", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4138, "SUB_DISTRICT_CODE": "490206", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5076", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4139, "SUB_DISTRICT_CODE": "490207", "PROVINCE_ID": "37", "DISTRICT_ID": "562", "SUB_DISTRICT_ID": "5077", "ZIPCODE": "49130" },
    { "ZIPCODE_ID": 4140, "SUB_DISTRICT_CODE": "490301", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5078", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4141, "SUB_DISTRICT_CODE": "490302", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5079", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4142, "SUB_DISTRICT_CODE": "490303", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5080", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4143, "SUB_DISTRICT_CODE": "490304", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5081", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4144, "SUB_DISTRICT_CODE": "490305", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5082", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4145, "SUB_DISTRICT_CODE": "490306", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5083", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4146, "SUB_DISTRICT_CODE": "490307", "PROVINCE_ID": "37", "DISTRICT_ID": "563", "SUB_DISTRICT_ID": "5084", "ZIPCODE": "49120" },
    { "ZIPCODE_ID": 4147, "SUB_DISTRICT_CODE": "490401", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5085", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4148, "SUB_DISTRICT_CODE": "490402", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5086", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4149, "SUB_DISTRICT_CODE": "490403", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5087", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4150, "SUB_DISTRICT_CODE": "490404", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5088", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4151, "SUB_DISTRICT_CODE": "490405", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5089", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4152, "SUB_DISTRICT_CODE": "490406", "PROVINCE_ID": "37", "DISTRICT_ID": "564", "SUB_DISTRICT_ID": "5090", "ZIPCODE": "49140" },
    { "ZIPCODE_ID": 4153, "SUB_DISTRICT_CODE": "490503", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5093", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4154, "SUB_DISTRICT_CODE": "490504", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5094", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4155, "SUB_DISTRICT_CODE": "490505", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5095", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4156, "SUB_DISTRICT_CODE": "490506", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5096", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4157, "SUB_DISTRICT_CODE": "490507", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5097", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4158, "SUB_DISTRICT_CODE": "490508", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5098", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4159, "SUB_DISTRICT_CODE": "490511", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5101", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4160, "SUB_DISTRICT_CODE": "490512", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5102", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4161, "SUB_DISTRICT_CODE": "490514", "PROVINCE_ID": "37", "DISTRICT_ID": "565", "SUB_DISTRICT_ID": "5104", "ZIPCODE": "49110" },
    { "ZIPCODE_ID": 4162, "SUB_DISTRICT_CODE": "490601", "PROVINCE_ID": "37", "DISTRICT_ID": "566", "SUB_DISTRICT_ID": "5108", "ZIPCODE": "49150" },
    { "ZIPCODE_ID": 4163, "SUB_DISTRICT_CODE": "490602", "PROVINCE_ID": "37", "DISTRICT_ID": "566", "SUB_DISTRICT_ID": "5109", "ZIPCODE": "49150" },
    { "ZIPCODE_ID": 4164, "SUB_DISTRICT_CODE": "490603", "PROVINCE_ID": "37", "DISTRICT_ID": "566", "SUB_DISTRICT_ID": "5110", "ZIPCODE": "49150" },
    { "ZIPCODE_ID": 4165, "SUB_DISTRICT_CODE": "490604", "PROVINCE_ID": "37", "DISTRICT_ID": "566", "SUB_DISTRICT_ID": "5111", "ZIPCODE": "49150" },
    { "ZIPCODE_ID": 4166, "SUB_DISTRICT_CODE": "490605", "PROVINCE_ID": "37", "DISTRICT_ID": "566", "SUB_DISTRICT_ID": "5112", "ZIPCODE": "49150" },
    { "ZIPCODE_ID": 4167, "SUB_DISTRICT_CODE": "490701", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5113", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4168, "SUB_DISTRICT_CODE": "490702", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5114", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4169, "SUB_DISTRICT_CODE": "490703", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5115", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4170, "SUB_DISTRICT_CODE": "490704", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5116", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4171, "SUB_DISTRICT_CODE": "490705", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5117", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4172, "SUB_DISTRICT_CODE": "490706", "PROVINCE_ID": "37", "DISTRICT_ID": "567", "SUB_DISTRICT_ID": "5118", "ZIPCODE": "49160" },
    { "ZIPCODE_ID": 4173, "SUB_DISTRICT_CODE": "500101", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5119", "ZIPCODE": "50200" },
    { "ZIPCODE_ID": 4174, "SUB_DISTRICT_CODE": "500102", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5120", "ZIPCODE": "50200" },
    { "ZIPCODE_ID": 4175, "SUB_DISTRICT_CODE": "500103", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5121", "ZIPCODE": "50100" },
    { "ZIPCODE_ID": 4176, "SUB_DISTRICT_CODE": "500104", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5122", "ZIPCODE": "50300" },
    { "ZIPCODE_ID": 4177, "SUB_DISTRICT_CODE": "500105", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5123", "ZIPCODE": "50100" },
    { "ZIPCODE_ID": 4178, "SUB_DISTRICT_CODE": "500106", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5124", "ZIPCODE": "50000" },
    { "ZIPCODE_ID": 4179, "SUB_DISTRICT_CODE": "500107", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5125", "ZIPCODE": "50300" },
    { "ZIPCODE_ID": 4180, "SUB_DISTRICT_CODE": "500108", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5126", "ZIPCODE": "50200" },
    { "ZIPCODE_ID": 4181, "SUB_DISTRICT_CODE": "500109", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5127", "ZIPCODE": "50100" },
    { "ZIPCODE_ID": 4182, "SUB_DISTRICT_CODE": "500110", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5128", "ZIPCODE": "50100" },
    { "ZIPCODE_ID": 4183, "SUB_DISTRICT_CODE": "500111", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5129", "ZIPCODE": "50000" },
    { "ZIPCODE_ID": 4184, "SUB_DISTRICT_CODE": "500112", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5130", "ZIPCODE": "50000" },
    { "ZIPCODE_ID": 4185, "SUB_DISTRICT_CODE": "500113", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5131", "ZIPCODE": "50000" },
    { "ZIPCODE_ID": 4186, "SUB_DISTRICT_CODE": "500114", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5132", "ZIPCODE": "50000" },
    { "ZIPCODE_ID": 4187, "SUB_DISTRICT_CODE": "500115", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5133", "ZIPCODE": "50300" },
    { "ZIPCODE_ID": 4188, "SUB_DISTRICT_CODE": "500116", "PROVINCE_ID": "38", "DISTRICT_ID": "568", "SUB_DISTRICT_ID": "5134", "ZIPCODE": "50300" },
    { "ZIPCODE_ID": 4189, "SUB_DISTRICT_CODE": "500203", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5137", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4190, "SUB_DISTRICT_CODE": "500204", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5138", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4191, "SUB_DISTRICT_CODE": "500205", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5139", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4192, "SUB_DISTRICT_CODE": "500206", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5140", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4193, "SUB_DISTRICT_CODE": "500207", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5141", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4194, "SUB_DISTRICT_CODE": "500209", "PROVINCE_ID": "38", "DISTRICT_ID": "569", "SUB_DISTRICT_ID": "5143", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4195, "SUB_DISTRICT_CODE": "500301", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5145", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4196, "SUB_DISTRICT_CODE": "500302", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5146", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4197, "SUB_DISTRICT_CODE": "500303", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5147", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4198, "SUB_DISTRICT_CODE": "500304", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5148", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4199, "SUB_DISTRICT_CODE": "500305", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5149", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4200, "SUB_DISTRICT_CODE": "500306", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5150", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4201, "SUB_DISTRICT_CODE": "500307", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5151", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4202, "SUB_DISTRICT_CODE": "500308", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5152", "ZIPCODE": "50270" },
    { "ZIPCODE_ID": 4203, "SUB_DISTRICT_CODE": "500309", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5153", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4204, "SUB_DISTRICT_CODE": "500310", "PROVINCE_ID": "38", "DISTRICT_ID": "570", "SUB_DISTRICT_ID": "5154", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4205, "SUB_DISTRICT_CODE": "500401", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5155", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4206, "SUB_DISTRICT_CODE": "500402", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5156", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4207, "SUB_DISTRICT_CODE": "500403", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5157", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4208, "SUB_DISTRICT_CODE": "500404", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5158", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4209, "SUB_DISTRICT_CODE": "500405", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5159", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4210, "SUB_DISTRICT_CODE": "500406", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5160", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4211, "SUB_DISTRICT_CODE": "500407", "PROVINCE_ID": "38", "DISTRICT_ID": "571", "SUB_DISTRICT_ID": "5161", "ZIPCODE": "50170" },
    { "ZIPCODE_ID": 4212, "SUB_DISTRICT_CODE": "500501", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5162", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4213, "SUB_DISTRICT_CODE": "500502", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5163", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4214, "SUB_DISTRICT_CODE": "500503", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5164", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4215, "SUB_DISTRICT_CODE": "500504", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5165", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4216, "SUB_DISTRICT_CODE": "500505", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5166", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4217, "SUB_DISTRICT_CODE": "500506", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5167", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4218, "SUB_DISTRICT_CODE": "500507", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5168", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4219, "SUB_DISTRICT_CODE": "500508", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5169", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4220, "SUB_DISTRICT_CODE": "500509", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5170", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4221, "SUB_DISTRICT_CODE": "500510", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5171", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4222, "SUB_DISTRICT_CODE": "500511", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5172", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4223, "SUB_DISTRICT_CODE": "500512", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5173", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4224, "SUB_DISTRICT_CODE": "500513", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5174", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4225, "SUB_DISTRICT_CODE": "500514", "PROVINCE_ID": "38", "DISTRICT_ID": "572", "SUB_DISTRICT_ID": "5175", "ZIPCODE": "50220" },
    { "ZIPCODE_ID": 4226, "SUB_DISTRICT_CODE": "500601", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5176", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4227, "SUB_DISTRICT_CODE": "500602", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5177", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4228, "SUB_DISTRICT_CODE": "500603", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5178", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4229, "SUB_DISTRICT_CODE": "500604", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5179", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4230, "SUB_DISTRICT_CODE": "500605", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5180", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4231, "SUB_DISTRICT_CODE": "500606", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5181", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4232, "SUB_DISTRICT_CODE": "500607", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5182", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4233, "SUB_DISTRICT_CODE": "500608", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5183", "ZIPCODE": "50330" },
    { "ZIPCODE_ID": 4234, "SUB_DISTRICT_CODE": "500609", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5184", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4235, "SUB_DISTRICT_CODE": "500610", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5185", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4236, "SUB_DISTRICT_CODE": "500611", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5186", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4237, "SUB_DISTRICT_CODE": "500612", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5187", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4238, "SUB_DISTRICT_CODE": "500613", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5188", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4239, "SUB_DISTRICT_CODE": "500614", "PROVINCE_ID": "38", "DISTRICT_ID": "573", "SUB_DISTRICT_ID": "5189", "ZIPCODE": "50150" },
    { "ZIPCODE_ID": 4240, "SUB_DISTRICT_CODE": "500701", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5190", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4241, "SUB_DISTRICT_CODE": "500702", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5191", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4242, "SUB_DISTRICT_CODE": "500703", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5192", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4243, "SUB_DISTRICT_CODE": "500704", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5193", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4244, "SUB_DISTRICT_CODE": "500705", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5194", "ZIPCODE": "50330" },
    { "ZIPCODE_ID": 4245, "SUB_DISTRICT_CODE": "500706", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5195", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4246, "SUB_DISTRICT_CODE": "500707", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5196", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4247, "SUB_DISTRICT_CODE": "500708", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5197", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4248, "SUB_DISTRICT_CODE": "500709", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5198", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4249, "SUB_DISTRICT_CODE": "500710", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5199", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4250, "SUB_DISTRICT_CODE": "500711", "PROVINCE_ID": "38", "DISTRICT_ID": "574", "SUB_DISTRICT_ID": "5200", "ZIPCODE": "50180" },
    { "ZIPCODE_ID": 4251, "SUB_DISTRICT_CODE": "500801", "PROVINCE_ID": "38", "DISTRICT_ID": "575", "SUB_DISTRICT_ID": "5201", "ZIPCODE": "50250" },
    { "ZIPCODE_ID": 4252, "SUB_DISTRICT_CODE": "500802", "PROVINCE_ID": "38", "DISTRICT_ID": "575", "SUB_DISTRICT_ID": "5202", "ZIPCODE": "50250" },
    { "ZIPCODE_ID": 4253, "SUB_DISTRICT_CODE": "500803", "PROVINCE_ID": "38", "DISTRICT_ID": "575", "SUB_DISTRICT_ID": "5203", "ZIPCODE": "50250" },
    { "ZIPCODE_ID": 4254, "SUB_DISTRICT_CODE": "500804", "PROVINCE_ID": "38", "DISTRICT_ID": "575", "SUB_DISTRICT_ID": "5204", "ZIPCODE": "50250" },
    { "ZIPCODE_ID": 4255, "SUB_DISTRICT_CODE": "500805", "PROVINCE_ID": "38", "DISTRICT_ID": "575", "SUB_DISTRICT_ID": "5205", "ZIPCODE": "50250" },
    { "ZIPCODE_ID": 4256, "SUB_DISTRICT_CODE": "500901", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5206", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4257, "SUB_DISTRICT_CODE": "500903", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5208", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4258, "SUB_DISTRICT_CODE": "500904", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5209", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4259, "SUB_DISTRICT_CODE": "500905", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5210", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4260, "SUB_DISTRICT_CODE": "500906", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5211", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4261, "SUB_DISTRICT_CODE": "500910", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5215", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4262, "SUB_DISTRICT_CODE": "500911", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5216", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4263, "SUB_DISTRICT_CODE": "500912", "PROVINCE_ID": "38", "DISTRICT_ID": "576", "SUB_DISTRICT_ID": "5217", "ZIPCODE": "50110" },
    { "ZIPCODE_ID": 4264, "SUB_DISTRICT_CODE": "501001", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5223", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4265, "SUB_DISTRICT_CODE": "501002", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5224", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4266, "SUB_DISTRICT_CODE": "501003", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5225", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4267, "SUB_DISTRICT_CODE": "501004", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5226", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4268, "SUB_DISTRICT_CODE": "501005", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5227", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4269, "SUB_DISTRICT_CODE": "501006", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5228", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4270, "SUB_DISTRICT_CODE": "501007", "PROVINCE_ID": "38", "DISTRICT_ID": "577", "SUB_DISTRICT_ID": "5229", "ZIPCODE": "50280" },
    { "ZIPCODE_ID": 4271, "SUB_DISTRICT_CODE": "501101", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5230", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4272, "SUB_DISTRICT_CODE": "501102", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5231", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4273, "SUB_DISTRICT_CODE": "501103", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5232", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4274, "SUB_DISTRICT_CODE": "501104", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5233", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4275, "SUB_DISTRICT_CODE": "501105", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5234", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4276, "SUB_DISTRICT_CODE": "501106", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5235", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4277, "SUB_DISTRICT_CODE": "501107", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5236", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4278, "SUB_DISTRICT_CODE": "501108", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5237", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4279, "SUB_DISTRICT_CODE": "501109", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5238", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4280, "SUB_DISTRICT_CODE": "501110", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5239", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4281, "SUB_DISTRICT_CODE": "501111", "PROVINCE_ID": "38", "DISTRICT_ID": "578", "SUB_DISTRICT_ID": "5240", "ZIPCODE": "50190" },
    { "ZIPCODE_ID": 4282, "SUB_DISTRICT_CODE": "501201", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5241", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4283, "SUB_DISTRICT_CODE": "501202", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5242", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4284, "SUB_DISTRICT_CODE": "501203", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5243", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4285, "SUB_DISTRICT_CODE": "501204", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5244", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4286, "SUB_DISTRICT_CODE": "501205", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5245", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4287, "SUB_DISTRICT_CODE": "501206", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5246", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4288, "SUB_DISTRICT_CODE": "501207", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5247", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4289, "SUB_DISTRICT_CODE": "501208", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5248", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4290, "SUB_DISTRICT_CODE": "501210", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5250", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4291, "SUB_DISTRICT_CODE": "501214", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5254", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4292, "SUB_DISTRICT_CODE": "501215", "PROVINCE_ID": "38", "DISTRICT_ID": "579", "SUB_DISTRICT_ID": "5255", "ZIPCODE": "50120" },
    { "ZIPCODE_ID": 4293, "SUB_DISTRICT_CODE": "501301", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5256", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4294, "SUB_DISTRICT_CODE": "501302", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5257", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4295, "SUB_DISTRICT_CODE": "501303", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5258", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4296, "SUB_DISTRICT_CODE": "501304", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5259", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4297, "SUB_DISTRICT_CODE": "501305", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5260", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4298, "SUB_DISTRICT_CODE": "501306", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5261", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4299, "SUB_DISTRICT_CODE": "501310", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5265", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4300, "SUB_DISTRICT_CODE": "501311", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5266", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4301, "SUB_DISTRICT_CODE": "501312", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5267", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4302, "SUB_DISTRICT_CODE": "501313", "PROVINCE_ID": "38", "DISTRICT_ID": "580", "SUB_DISTRICT_ID": "5268", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4303, "SUB_DISTRICT_CODE": "501401", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5275", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4304, "SUB_DISTRICT_CODE": "501402", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5276", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4305, "SUB_DISTRICT_CODE": "501403", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5277", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4306, "SUB_DISTRICT_CODE": "501404", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5278", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4307, "SUB_DISTRICT_CODE": "501405", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5279", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4308, "SUB_DISTRICT_CODE": "501406", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5280", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4309, "SUB_DISTRICT_CODE": "501407", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5281", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4310, "SUB_DISTRICT_CODE": "501408", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5282", "ZIPCODE": "50290" },
    { "ZIPCODE_ID": 4311, "SUB_DISTRICT_CODE": "501409", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5283", "ZIPCODE": "50290" },
    { "ZIPCODE_ID": 4312, "SUB_DISTRICT_CODE": "501410", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5284", "ZIPCODE": "50290" },
    { "ZIPCODE_ID": 4313, "SUB_DISTRICT_CODE": "501411", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5285", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4314, "SUB_DISTRICT_CODE": "501412", "PROVINCE_ID": "38", "DISTRICT_ID": "581", "SUB_DISTRICT_ID": "5286", "ZIPCODE": "50210" },
    { "ZIPCODE_ID": 4315, "SUB_DISTRICT_CODE": "501501", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5287", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4316, "SUB_DISTRICT_CODE": "501502", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5288", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4317, "SUB_DISTRICT_CODE": "501503", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5289", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4318, "SUB_DISTRICT_CODE": "501504", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5290", "ZIPCODE": "50340" },
    { "ZIPCODE_ID": 4319, "SUB_DISTRICT_CODE": "501505", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5291", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4320, "SUB_DISTRICT_CODE": "501506", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5292", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4321, "SUB_DISTRICT_CODE": "501507", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5293", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4322, "SUB_DISTRICT_CODE": "501508", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5294", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4323, "SUB_DISTRICT_CODE": "501509", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5295", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4324, "SUB_DISTRICT_CODE": "501510", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5296", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4325, "SUB_DISTRICT_CODE": "501511", "PROVINCE_ID": "38", "DISTRICT_ID": "582", "SUB_DISTRICT_ID": "5297", "ZIPCODE": "50230" },
    { "ZIPCODE_ID": 4326, "SUB_DISTRICT_CODE": "501601", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5298", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4327, "SUB_DISTRICT_CODE": "501602", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5299", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4328, "SUB_DISTRICT_CODE": "501603", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5300", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4329, "SUB_DISTRICT_CODE": "501604", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5301", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4330, "SUB_DISTRICT_CODE": "501605", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5302", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4331, "SUB_DISTRICT_CODE": "501606", "PROVINCE_ID": "38", "DISTRICT_ID": "583", "SUB_DISTRICT_ID": "5303", "ZIPCODE": "50240" },
    { "ZIPCODE_ID": 4332, "SUB_DISTRICT_CODE": "501701", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5304", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4333, "SUB_DISTRICT_CODE": "501702", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5305", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4334, "SUB_DISTRICT_CODE": "501703", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5306", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4335, "SUB_DISTRICT_CODE": "501704", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5307", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4336, "SUB_DISTRICT_CODE": "501705", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5308", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4337, "SUB_DISTRICT_CODE": "501706", "PROVINCE_ID": "38", "DISTRICT_ID": "584", "SUB_DISTRICT_ID": "5309", "ZIPCODE": "50260" },
    { "ZIPCODE_ID": 4338, "SUB_DISTRICT_CODE": "501801", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5310", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4339, "SUB_DISTRICT_CODE": "501802", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5311", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4340, "SUB_DISTRICT_CODE": "501803", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5312", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4341, "SUB_DISTRICT_CODE": "501804", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5313", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4342, "SUB_DISTRICT_CODE": "501805", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5314", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4343, "SUB_DISTRICT_CODE": "501806", "PROVINCE_ID": "38", "DISTRICT_ID": "585", "SUB_DISTRICT_ID": "5315", "ZIPCODE": "50310" },
    { "ZIPCODE_ID": 4344, "SUB_DISTRICT_CODE": "501901", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5316", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4345, "SUB_DISTRICT_CODE": "501902", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5317", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4346, "SUB_DISTRICT_CODE": "501903", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5318", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4347, "SUB_DISTRICT_CODE": "501904", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5319", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4348, "SUB_DISTRICT_CODE": "501905", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5320", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4349, "SUB_DISTRICT_CODE": "501906", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5321", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4350, "SUB_DISTRICT_CODE": "501907", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5322", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4351, "SUB_DISTRICT_CODE": "501908", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5323", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4352, "SUB_DISTRICT_CODE": "501909", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5324", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4353, "SUB_DISTRICT_CODE": "501910", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5325", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4354, "SUB_DISTRICT_CODE": "501911", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5326", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4355, "SUB_DISTRICT_CODE": "501912", "PROVINCE_ID": "38", "DISTRICT_ID": "586", "SUB_DISTRICT_ID": "5327", "ZIPCODE": "50140" },
    { "ZIPCODE_ID": 4356, "SUB_DISTRICT_CODE": "502001", "PROVINCE_ID": "38", "DISTRICT_ID": "587", "SUB_DISTRICT_ID": "5328", "ZIPCODE": "50350" },
    { "ZIPCODE_ID": 4357, "SUB_DISTRICT_CODE": "502002", "PROVINCE_ID": "38", "DISTRICT_ID": "587", "SUB_DISTRICT_ID": "5329", "ZIPCODE": "50350" },
    { "ZIPCODE_ID": 4358, "SUB_DISTRICT_CODE": "502003", "PROVINCE_ID": "38", "DISTRICT_ID": "587", "SUB_DISTRICT_ID": "5330", "ZIPCODE": "50350" },
    { "ZIPCODE_ID": 4359, "SUB_DISTRICT_CODE": "502101", "PROVINCE_ID": "38", "DISTRICT_ID": "588", "SUB_DISTRICT_ID": "5331", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4360, "SUB_DISTRICT_CODE": "502102", "PROVINCE_ID": "38", "DISTRICT_ID": "588", "SUB_DISTRICT_ID": "5332", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4361, "SUB_DISTRICT_CODE": "502103", "PROVINCE_ID": "38", "DISTRICT_ID": "588", "SUB_DISTRICT_ID": "5333", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4362, "SUB_DISTRICT_CODE": "502104", "PROVINCE_ID": "38", "DISTRICT_ID": "588", "SUB_DISTRICT_ID": "5334", "ZIPCODE": "50320" },
    { "ZIPCODE_ID": 4363, "SUB_DISTRICT_CODE": "502201", "PROVINCE_ID": "38", "DISTRICT_ID": "589", "SUB_DISTRICT_ID": "5335", "ZIPCODE": "50360" },
    { "ZIPCODE_ID": 4364, "SUB_DISTRICT_CODE": "502202", "PROVINCE_ID": "38", "DISTRICT_ID": "589", "SUB_DISTRICT_ID": "5336", "ZIPCODE": "50360" },
    { "ZIPCODE_ID": 4365, "SUB_DISTRICT_CODE": "502203", "PROVINCE_ID": "38", "DISTRICT_ID": "589", "SUB_DISTRICT_ID": "5337", "ZIPCODE": "50360" },
    { "ZIPCODE_ID": 4366, "SUB_DISTRICT_CODE": "502204", "PROVINCE_ID": "38", "DISTRICT_ID": "589", "SUB_DISTRICT_ID": "5338", "ZIPCODE": "50360" },
    { "ZIPCODE_ID": 4367, "SUB_DISTRICT_CODE": "502205", "PROVINCE_ID": "38", "DISTRICT_ID": "589", "SUB_DISTRICT_ID": "5339", "ZIPCODE": "50360" },
    { "ZIPCODE_ID": 4368, "SUB_DISTRICT_CODE": "502301", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5340", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4369, "SUB_DISTRICT_CODE": "502302", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5341", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4370, "SUB_DISTRICT_CODE": "502303", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5342", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4371, "SUB_DISTRICT_CODE": "502304", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5343", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4372, "SUB_DISTRICT_CODE": "502305", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5344", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4373, "SUB_DISTRICT_CODE": "502306", "PROVINCE_ID": "38", "DISTRICT_ID": "590", "SUB_DISTRICT_ID": "5345", "ZIPCODE": "50130" },
    { "ZIPCODE_ID": 4374, "SUB_DISTRICT_CODE": "502401", "PROVINCE_ID": "38", "DISTRICT_ID": "591", "SUB_DISTRICT_ID": "5346", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4375, "SUB_DISTRICT_CODE": "502402", "PROVINCE_ID": "38", "DISTRICT_ID": "591", "SUB_DISTRICT_ID": "5347", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4376, "SUB_DISTRICT_CODE": "502403", "PROVINCE_ID": "38", "DISTRICT_ID": "591", "SUB_DISTRICT_ID": "5348", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4377, "SUB_DISTRICT_CODE": "502404", "PROVINCE_ID": "38", "DISTRICT_ID": "591", "SUB_DISTRICT_ID": "5349", "ZIPCODE": "50160" },
    { "ZIPCODE_ID": 4378, "SUB_DISTRICT_CODE": "510101", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5350", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4379, "SUB_DISTRICT_CODE": "510102", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5351", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4380, "SUB_DISTRICT_CODE": "510103", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5352", "ZIPCODE": "51150" },
    { "ZIPCODE_ID": 4381, "SUB_DISTRICT_CODE": "510104", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5353", "ZIPCODE": "51150" },
    { "ZIPCODE_ID": 4382, "SUB_DISTRICT_CODE": "510105", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5354", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4383, "SUB_DISTRICT_CODE": "510106", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5355", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4384, "SUB_DISTRICT_CODE": "510107", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5356", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4385, "SUB_DISTRICT_CODE": "510108", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5357", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4386, "SUB_DISTRICT_CODE": "510109", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5358", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4387, "SUB_DISTRICT_CODE": "510110", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5359", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4388, "SUB_DISTRICT_CODE": "510111", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5360", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4389, "SUB_DISTRICT_CODE": "510112", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5361", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4390, "SUB_DISTRICT_CODE": "510113", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5362", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4391, "SUB_DISTRICT_CODE": "510116", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5365", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4392, "SUB_DISTRICT_CODE": "510117", "PROVINCE_ID": "39", "DISTRICT_ID": "595", "SUB_DISTRICT_ID": "5366", "ZIPCODE": "51000" },
    { "ZIPCODE_ID": 4393, "SUB_DISTRICT_CODE": "510201", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5369", "ZIPCODE": "51140" },
    { "ZIPCODE_ID": 4394, "SUB_DISTRICT_CODE": "510202", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5370", "ZIPCODE": "51140" },
    { "ZIPCODE_ID": 4395, "SUB_DISTRICT_CODE": "510203", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5371", "ZIPCODE": "51170" },
    { "ZIPCODE_ID": 4396, "SUB_DISTRICT_CODE": "510204", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5372", "ZIPCODE": "51170" },
    { "ZIPCODE_ID": 4397, "SUB_DISTRICT_CODE": "510205", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5373", "ZIPCODE": "51170" },
    { "ZIPCODE_ID": 4398, "SUB_DISTRICT_CODE": "510206", "PROVINCE_ID": "39", "DISTRICT_ID": "596", "SUB_DISTRICT_ID": "5374", "ZIPCODE": "51170" },
    { "ZIPCODE_ID": 4399, "SUB_DISTRICT_CODE": "510301", "PROVINCE_ID": "39", "DISTRICT_ID": "597", "SUB_DISTRICT_ID": "5375", "ZIPCODE": "51130" },
    { "ZIPCODE_ID": 4400, "SUB_DISTRICT_CODE": "510302", "PROVINCE_ID": "39", "DISTRICT_ID": "597", "SUB_DISTRICT_ID": "5376", "ZIPCODE": "51130" },
    { "ZIPCODE_ID": 4401, "SUB_DISTRICT_CODE": "510303", "PROVINCE_ID": "39", "DISTRICT_ID": "597", "SUB_DISTRICT_ID": "5377", "ZIPCODE": "51130" },
    { "ZIPCODE_ID": 4402, "SUB_DISTRICT_CODE": "510304", "PROVINCE_ID": "39", "DISTRICT_ID": "597", "SUB_DISTRICT_ID": "5378", "ZIPCODE": "51130" },
    { "ZIPCODE_ID": 4403, "SUB_DISTRICT_CODE": "510305", "PROVINCE_ID": "39", "DISTRICT_ID": "597", "SUB_DISTRICT_ID": "5379", "ZIPCODE": "51130" },
    { "ZIPCODE_ID": 4404, "SUB_DISTRICT_CODE": "510401", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5380", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4405, "SUB_DISTRICT_CODE": "510402", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5381", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4406, "SUB_DISTRICT_CODE": "510403", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5382", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4407, "SUB_DISTRICT_CODE": "510404", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5383", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4408, "SUB_DISTRICT_CODE": "510405", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5384", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4409, "SUB_DISTRICT_CODE": "510406", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5385", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4410, "SUB_DISTRICT_CODE": "510408", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5387", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4411, "SUB_DISTRICT_CODE": "510409", "PROVINCE_ID": "39", "DISTRICT_ID": "598", "SUB_DISTRICT_ID": "5388", "ZIPCODE": "51110" },
    { "ZIPCODE_ID": 4412, "SUB_DISTRICT_CODE": "510501", "PROVINCE_ID": "39", "DISTRICT_ID": "599", "SUB_DISTRICT_ID": "5391", "ZIPCODE": "51160" },
    { "ZIPCODE_ID": 4413, "SUB_DISTRICT_CODE": "510502", "PROVINCE_ID": "39", "DISTRICT_ID": "599", "SUB_DISTRICT_ID": "5392", "ZIPCODE": "51160" },
    { "ZIPCODE_ID": 4414, "SUB_DISTRICT_CODE": "510503", "PROVINCE_ID": "39", "DISTRICT_ID": "599", "SUB_DISTRICT_ID": "5393", "ZIPCODE": "51160" },
    { "ZIPCODE_ID": 4415, "SUB_DISTRICT_CODE": "510601", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5394", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4416, "SUB_DISTRICT_CODE": "510602", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5395", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4417, "SUB_DISTRICT_CODE": "510603", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5396", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4418, "SUB_DISTRICT_CODE": "510604", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5397", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4419, "SUB_DISTRICT_CODE": "510605", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5398", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4420, "SUB_DISTRICT_CODE": "510606", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5399", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4421, "SUB_DISTRICT_CODE": "510607", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5400", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4422, "SUB_DISTRICT_CODE": "510608", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5401", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4423, "SUB_DISTRICT_CODE": "510611", "PROVINCE_ID": "39", "DISTRICT_ID": "600", "SUB_DISTRICT_ID": "5404", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4424, "SUB_DISTRICT_CODE": "510701", "PROVINCE_ID": "39", "DISTRICT_ID": "601", "SUB_DISTRICT_ID": "5406", "ZIPCODE": "51180" },
    { "ZIPCODE_ID": 4425, "SUB_DISTRICT_CODE": "510702", "PROVINCE_ID": "39", "DISTRICT_ID": "601", "SUB_DISTRICT_ID": "5407", "ZIPCODE": "51180" },
    { "ZIPCODE_ID": 4426, "SUB_DISTRICT_CODE": "510801", "PROVINCE_ID": "39", "DISTRICT_ID": "602", "SUB_DISTRICT_ID": "5408", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4427, "SUB_DISTRICT_CODE": "510802", "PROVINCE_ID": "39", "DISTRICT_ID": "602", "SUB_DISTRICT_ID": "5409", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4428, "SUB_DISTRICT_CODE": "510803", "PROVINCE_ID": "39", "DISTRICT_ID": "602", "SUB_DISTRICT_ID": "5410", "ZIPCODE": "51120" },
    { "ZIPCODE_ID": 4429, "SUB_DISTRICT_CODE": "520101", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5411", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4430, "SUB_DISTRICT_CODE": "520102", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5412", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4431, "SUB_DISTRICT_CODE": "520103", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5413", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4432, "SUB_DISTRICT_CODE": "520104", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5414", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4433, "SUB_DISTRICT_CODE": "520105", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5415", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4434, "SUB_DISTRICT_CODE": "520106", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5416", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4435, "SUB_DISTRICT_CODE": "520107", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5417", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4436, "SUB_DISTRICT_CODE": "520108", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5418", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4437, "SUB_DISTRICT_CODE": "520109", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5419", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4438, "SUB_DISTRICT_CODE": "520110", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5420", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4439, "SUB_DISTRICT_CODE": "520111", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5421", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4440, "SUB_DISTRICT_CODE": "520112", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5422", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4441, "SUB_DISTRICT_CODE": "520113", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5423", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4442, "SUB_DISTRICT_CODE": "520114", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5424", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4443, "SUB_DISTRICT_CODE": "520115", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5425", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4444, "SUB_DISTRICT_CODE": "520116", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5426", "ZIPCODE": "52100" },
    { "ZIPCODE_ID": 4445, "SUB_DISTRICT_CODE": "520117", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5427", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4446, "SUB_DISTRICT_CODE": "520118", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5428", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4447, "SUB_DISTRICT_CODE": "520119", "PROVINCE_ID": "40", "DISTRICT_ID": "603", "SUB_DISTRICT_ID": "5429", "ZIPCODE": "52000" },
    { "ZIPCODE_ID": 4448, "SUB_DISTRICT_CODE": "520201", "PROVINCE_ID": "40", "DISTRICT_ID": "604", "SUB_DISTRICT_ID": "5432", "ZIPCODE": "52220" },
    { "ZIPCODE_ID": 4449, "SUB_DISTRICT_CODE": "520202", "PROVINCE_ID": "40", "DISTRICT_ID": "604", "SUB_DISTRICT_ID": "5433", "ZIPCODE": "52220" },
    { "ZIPCODE_ID": 4450, "SUB_DISTRICT_CODE": "520203", "PROVINCE_ID": "40", "DISTRICT_ID": "604", "SUB_DISTRICT_ID": "5434", "ZIPCODE": "52220" },
    { "ZIPCODE_ID": 4451, "SUB_DISTRICT_CODE": "520204", "PROVINCE_ID": "40", "DISTRICT_ID": "604", "SUB_DISTRICT_ID": "5435", "ZIPCODE": "52220" },
    { "ZIPCODE_ID": 4452, "SUB_DISTRICT_CODE": "520205", "PROVINCE_ID": "40", "DISTRICT_ID": "604", "SUB_DISTRICT_ID": "5436", "ZIPCODE": "52220" },
    { "ZIPCODE_ID": 4453, "SUB_DISTRICT_CODE": "520301", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5437", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4454, "SUB_DISTRICT_CODE": "520302", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5438", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4455, "SUB_DISTRICT_CODE": "520303", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5439", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4456, "SUB_DISTRICT_CODE": "520304", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5440", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4457, "SUB_DISTRICT_CODE": "520305", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5441", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4458, "SUB_DISTRICT_CODE": "520306", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5442", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4459, "SUB_DISTRICT_CODE": "520307", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5443", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4460, "SUB_DISTRICT_CODE": "520308", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5444", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4461, "SUB_DISTRICT_CODE": "520309", "PROVINCE_ID": "40", "DISTRICT_ID": "605", "SUB_DISTRICT_ID": "5445", "ZIPCODE": "52130" },
    { "ZIPCODE_ID": 4462, "SUB_DISTRICT_CODE": "520401", "PROVINCE_ID": "40", "DISTRICT_ID": "606", "SUB_DISTRICT_ID": "5446", "ZIPCODE": "52210" },
    { "ZIPCODE_ID": 4463, "SUB_DISTRICT_CODE": "520402", "PROVINCE_ID": "40", "DISTRICT_ID": "606", "SUB_DISTRICT_ID": "5447", "ZIPCODE": "52210" },
    { "ZIPCODE_ID": 4464, "SUB_DISTRICT_CODE": "520403", "PROVINCE_ID": "40", "DISTRICT_ID": "606", "SUB_DISTRICT_ID": "5448", "ZIPCODE": "52210" },
    { "ZIPCODE_ID": 4465, "SUB_DISTRICT_CODE": "520404", "PROVINCE_ID": "40", "DISTRICT_ID": "606", "SUB_DISTRICT_ID": "5449", "ZIPCODE": "52210" },
    { "ZIPCODE_ID": 4466, "SUB_DISTRICT_CODE": "520501", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5450", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4467, "SUB_DISTRICT_CODE": "520502", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5451", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4468, "SUB_DISTRICT_CODE": "520503", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5452", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4469, "SUB_DISTRICT_CODE": "520504", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5453", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4470, "SUB_DISTRICT_CODE": "520505", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5454", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4471, "SUB_DISTRICT_CODE": "520506", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5455", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4472, "SUB_DISTRICT_CODE": "520507", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5456", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4473, "SUB_DISTRICT_CODE": "520508", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5457", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4474, "SUB_DISTRICT_CODE": "520509", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5458", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4475, "SUB_DISTRICT_CODE": "520510", "PROVINCE_ID": "40", "DISTRICT_ID": "607", "SUB_DISTRICT_ID": "5459", "ZIPCODE": "52110" },
    { "ZIPCODE_ID": 4476, "SUB_DISTRICT_CODE": "520601", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5460", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4477, "SUB_DISTRICT_CODE": "520602", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5461", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4478, "SUB_DISTRICT_CODE": "520603", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5462", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4479, "SUB_DISTRICT_CODE": "520604", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5463", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4480, "SUB_DISTRICT_CODE": "520605", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5464", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4481, "SUB_DISTRICT_CODE": "520606", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5465", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4482, "SUB_DISTRICT_CODE": "520607", "PROVINCE_ID": "40", "DISTRICT_ID": "608", "SUB_DISTRICT_ID": "5466", "ZIPCODE": "52120" },
    { "ZIPCODE_ID": 4483, "SUB_DISTRICT_CODE": "520701", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5471", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4484, "SUB_DISTRICT_CODE": "520702", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5472", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4485, "SUB_DISTRICT_CODE": "520703", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5473", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4486, "SUB_DISTRICT_CODE": "520704", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5474", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4487, "SUB_DISTRICT_CODE": "520705", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5475", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4488, "SUB_DISTRICT_CODE": "520706", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5476", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4489, "SUB_DISTRICT_CODE": "520707", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5477", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4490, "SUB_DISTRICT_CODE": "520708", "PROVINCE_ID": "40", "DISTRICT_ID": "609", "SUB_DISTRICT_ID": "5478", "ZIPCODE": "52140" },
    { "ZIPCODE_ID": 4491, "SUB_DISTRICT_CODE": "520801", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5479", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4492, "SUB_DISTRICT_CODE": "520802", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5480", "ZIPCODE": "52230" },
    { "ZIPCODE_ID": 4493, "SUB_DISTRICT_CODE": "520803", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5481", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4494, "SUB_DISTRICT_CODE": "520804", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5482", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4495, "SUB_DISTRICT_CODE": "520805", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5483", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4496, "SUB_DISTRICT_CODE": "520806", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5484", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4497, "SUB_DISTRICT_CODE": "520807", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5485", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4498, "SUB_DISTRICT_CODE": "520808", "PROVINCE_ID": "40", "DISTRICT_ID": "610", "SUB_DISTRICT_ID": "5486", "ZIPCODE": "52160" },
    { "ZIPCODE_ID": 4499, "SUB_DISTRICT_CODE": "520901", "PROVINCE_ID": "40", "DISTRICT_ID": "611", "SUB_DISTRICT_ID": "5487", "ZIPCODE": "52180" },
    { "ZIPCODE_ID": 4500, "SUB_DISTRICT_CODE": "520902", "PROVINCE_ID": "40", "DISTRICT_ID": "611", "SUB_DISTRICT_ID": "5488", "ZIPCODE": "52180" },
    { "ZIPCODE_ID": 4501, "SUB_DISTRICT_CODE": "520903", "PROVINCE_ID": "40", "DISTRICT_ID": "611", "SUB_DISTRICT_ID": "5489", "ZIPCODE": "52180" },
    { "ZIPCODE_ID": 4502, "SUB_DISTRICT_CODE": "520904", "PROVINCE_ID": "40", "DISTRICT_ID": "611", "SUB_DISTRICT_ID": "5490", "ZIPCODE": "52180" },
    { "ZIPCODE_ID": 4503, "SUB_DISTRICT_CODE": "521001", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5491", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4504, "SUB_DISTRICT_CODE": "521002", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5492", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4505, "SUB_DISTRICT_CODE": "521003", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5493", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4506, "SUB_DISTRICT_CODE": "521004", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5494", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4507, "SUB_DISTRICT_CODE": "521005", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5495", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4508, "SUB_DISTRICT_CODE": "521006", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5496", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4509, "SUB_DISTRICT_CODE": "521007", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5497", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4510, "SUB_DISTRICT_CODE": "521008", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5498", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4511, "SUB_DISTRICT_CODE": "521010", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5500", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4512, "SUB_DISTRICT_CODE": "521011", "PROVINCE_ID": "40", "DISTRICT_ID": "612", "SUB_DISTRICT_ID": "5501", "ZIPCODE": "52150" },
    { "ZIPCODE_ID": 4513, "SUB_DISTRICT_CODE": "521101", "PROVINCE_ID": "40", "DISTRICT_ID": "613", "SUB_DISTRICT_ID": "5502", "ZIPCODE": "52170" },
    { "ZIPCODE_ID": 4514, "SUB_DISTRICT_CODE": "521102", "PROVINCE_ID": "40", "DISTRICT_ID": "613", "SUB_DISTRICT_ID": "5503", "ZIPCODE": "52170" },
    { "ZIPCODE_ID": 4515, "SUB_DISTRICT_CODE": "521103", "PROVINCE_ID": "40", "DISTRICT_ID": "613", "SUB_DISTRICT_ID": "5504", "ZIPCODE": "52170" },
    { "ZIPCODE_ID": 4516, "SUB_DISTRICT_CODE": "521104", "PROVINCE_ID": "40", "DISTRICT_ID": "613", "SUB_DISTRICT_ID": "5505", "ZIPCODE": "52170" },
    { "ZIPCODE_ID": 4517, "SUB_DISTRICT_CODE": "521201", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5506", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4518, "SUB_DISTRICT_CODE": "521202", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5507", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4519, "SUB_DISTRICT_CODE": "521203", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5508", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4520, "SUB_DISTRICT_CODE": "521204", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5509", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4521, "SUB_DISTRICT_CODE": "521205", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5510", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4522, "SUB_DISTRICT_CODE": "521206", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5511", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4523, "SUB_DISTRICT_CODE": "521207", "PROVINCE_ID": "40", "DISTRICT_ID": "614", "SUB_DISTRICT_ID": "5512", "ZIPCODE": "52190" },
    { "ZIPCODE_ID": 4524, "SUB_DISTRICT_CODE": "521301", "PROVINCE_ID": "40", "DISTRICT_ID": "615", "SUB_DISTRICT_ID": "5513", "ZIPCODE": "52240" },
    { "ZIPCODE_ID": 4525, "SUB_DISTRICT_CODE": "521302", "PROVINCE_ID": "40", "DISTRICT_ID": "615", "SUB_DISTRICT_ID": "5514", "ZIPCODE": "52240" },
    { "ZIPCODE_ID": 4526, "SUB_DISTRICT_CODE": "521303", "PROVINCE_ID": "40", "DISTRICT_ID": "615", "SUB_DISTRICT_ID": "5515", "ZIPCODE": "52240" },
    { "ZIPCODE_ID": 4527, "SUB_DISTRICT_CODE": "521304", "PROVINCE_ID": "40", "DISTRICT_ID": "615", "SUB_DISTRICT_ID": "5516", "ZIPCODE": "52240" },
    { "ZIPCODE_ID": 4528, "SUB_DISTRICT_CODE": "521305", "PROVINCE_ID": "40", "DISTRICT_ID": "615", "SUB_DISTRICT_ID": "5517", "ZIPCODE": "52240" },
    { "ZIPCODE_ID": 4529, "SUB_DISTRICT_CODE": "530101", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5518", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4530, "SUB_DISTRICT_CODE": "530102", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5519", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4531, "SUB_DISTRICT_CODE": "530103", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5520", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4532, "SUB_DISTRICT_CODE": "530104", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5521", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4533, "SUB_DISTRICT_CODE": "530105", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5522", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4534, "SUB_DISTRICT_CODE": "530106", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5523", "ZIPCODE": "53170" },
    { "ZIPCODE_ID": 4535, "SUB_DISTRICT_CODE": "530107", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5524", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4536, "SUB_DISTRICT_CODE": "530108", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5525", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4537, "SUB_DISTRICT_CODE": "530109", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5526", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4538, "SUB_DISTRICT_CODE": "530110", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5527", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4539, "SUB_DISTRICT_CODE": "530111", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5528", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4540, "SUB_DISTRICT_CODE": "530112", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5529", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4541, "SUB_DISTRICT_CODE": "530113", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5530", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4542, "SUB_DISTRICT_CODE": "530114", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5531", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4543, "SUB_DISTRICT_CODE": "530115", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5532", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4544, "SUB_DISTRICT_CODE": "530116", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5533", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4545, "SUB_DISTRICT_CODE": "530117", "PROVINCE_ID": "41", "DISTRICT_ID": "616", "SUB_DISTRICT_ID": "5534", "ZIPCODE": "53000" },
    { "ZIPCODE_ID": 4546, "SUB_DISTRICT_CODE": "530201", "PROVINCE_ID": "41", "DISTRICT_ID": "617", "SUB_DISTRICT_ID": "5536", "ZIPCODE": "53140" },
    { "ZIPCODE_ID": 4547, "SUB_DISTRICT_CODE": "530202", "PROVINCE_ID": "41", "DISTRICT_ID": "617", "SUB_DISTRICT_ID": "5537", "ZIPCODE": "53140" },
    { "ZIPCODE_ID": 4548, "SUB_DISTRICT_CODE": "530203", "PROVINCE_ID": "41", "DISTRICT_ID": "617", "SUB_DISTRICT_ID": "5538", "ZIPCODE": "53140" },
    { "ZIPCODE_ID": 4549, "SUB_DISTRICT_CODE": "530204", "PROVINCE_ID": "41", "DISTRICT_ID": "617", "SUB_DISTRICT_ID": "5539", "ZIPCODE": "53140" },
    { "ZIPCODE_ID": 4550, "SUB_DISTRICT_CODE": "530205", "PROVINCE_ID": "41", "DISTRICT_ID": "617", "SUB_DISTRICT_ID": "5540", "ZIPCODE": "53140" },
    { "ZIPCODE_ID": 4551, "SUB_DISTRICT_CODE": "530301", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5545", "ZIPCODE": "53150" },
    { "ZIPCODE_ID": 4552, "SUB_DISTRICT_CODE": "530302", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5546", "ZIPCODE": "53150" },
    { "ZIPCODE_ID": 4553, "SUB_DISTRICT_CODE": "530303", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5547", "ZIPCODE": "53190" },
    { "ZIPCODE_ID": 4554, "SUB_DISTRICT_CODE": "530304", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5548", "ZIPCODE": "53150" },
    { "ZIPCODE_ID": 4555, "SUB_DISTRICT_CODE": "530305", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5549", "ZIPCODE": "53150" },
    { "ZIPCODE_ID": 4556, "SUB_DISTRICT_CODE": "530306", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5550", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4557, "SUB_DISTRICT_CODE": "530307", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5551", "ZIPCODE": "53150" },
    { "ZIPCODE_ID": 4558, "SUB_DISTRICT_CODE": "530308", "PROVINCE_ID": "41", "DISTRICT_ID": "618", "SUB_DISTRICT_ID": "5552", "ZIPCODE": "53190" },
    { "ZIPCODE_ID": 4559, "SUB_DISTRICT_CODE": "530401", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5553", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4560, "SUB_DISTRICT_CODE": "530402", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5554", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4561, "SUB_DISTRICT_CODE": "530403", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5555", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4562, "SUB_DISTRICT_CODE": "530404", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5556", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4563, "SUB_DISTRICT_CODE": "530405", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5557", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4564, "SUB_DISTRICT_CODE": "530406", "PROVINCE_ID": "41", "DISTRICT_ID": "619", "SUB_DISTRICT_ID": "5558", "ZIPCODE": "53110" },
    { "ZIPCODE_ID": 4565, "SUB_DISTRICT_CODE": "530501", "PROVINCE_ID": "41", "DISTRICT_ID": "620", "SUB_DISTRICT_ID": "5559", "ZIPCODE": "53160" },
    { "ZIPCODE_ID": 4566, "SUB_DISTRICT_CODE": "530502", "PROVINCE_ID": "41", "DISTRICT_ID": "620", "SUB_DISTRICT_ID": "5560", "ZIPCODE": "53160" },
    { "ZIPCODE_ID": 4567, "SUB_DISTRICT_CODE": "530503", "PROVINCE_ID": "41", "DISTRICT_ID": "620", "SUB_DISTRICT_ID": "5561", "ZIPCODE": "53160" },
    { "ZIPCODE_ID": 4568, "SUB_DISTRICT_CODE": "530504", "PROVINCE_ID": "41", "DISTRICT_ID": "620", "SUB_DISTRICT_ID": "5562", "ZIPCODE": "53160" },
    { "ZIPCODE_ID": 4569, "SUB_DISTRICT_CODE": "530601", "PROVINCE_ID": "41", "DISTRICT_ID": "621", "SUB_DISTRICT_ID": "5563", "ZIPCODE": "53180" },
    { "ZIPCODE_ID": 4570, "SUB_DISTRICT_CODE": "530602", "PROVINCE_ID": "41", "DISTRICT_ID": "621", "SUB_DISTRICT_ID": "5564", "ZIPCODE": "53180" },
    { "ZIPCODE_ID": 4571, "SUB_DISTRICT_CODE": "530603", "PROVINCE_ID": "41", "DISTRICT_ID": "621", "SUB_DISTRICT_ID": "5565", "ZIPCODE": "53180" },
    { "ZIPCODE_ID": 4572, "SUB_DISTRICT_CODE": "530604", "PROVINCE_ID": "41", "DISTRICT_ID": "621", "SUB_DISTRICT_ID": "5566", "ZIPCODE": "53180" },
    { "ZIPCODE_ID": 4573, "SUB_DISTRICT_CODE": "530701", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5567", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4574, "SUB_DISTRICT_CODE": "530702", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5568", "ZIPCODE": "53220" },
    { "ZIPCODE_ID": 4575, "SUB_DISTRICT_CODE": "530703", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5569", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4576, "SUB_DISTRICT_CODE": "530704", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5570", "ZIPCODE": "53220" },
    { "ZIPCODE_ID": 4577, "SUB_DISTRICT_CODE": "530705", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5571", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4578, "SUB_DISTRICT_CODE": "530706", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5572", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4579, "SUB_DISTRICT_CODE": "530707", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5573", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4580, "SUB_DISTRICT_CODE": "530708", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5574", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4581, "SUB_DISTRICT_CODE": "530709", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5575", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4582, "SUB_DISTRICT_CODE": "530710", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5576", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4583, "SUB_DISTRICT_CODE": "530711", "PROVINCE_ID": "41", "DISTRICT_ID": "622", "SUB_DISTRICT_ID": "5577", "ZIPCODE": "53120" },
    { "ZIPCODE_ID": 4584, "SUB_DISTRICT_CODE": "530801", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5578", "ZIPCODE": "53130" },
    { "ZIPCODE_ID": 4585, "SUB_DISTRICT_CODE": "530802", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5579", "ZIPCODE": "53130" },
    { "ZIPCODE_ID": 4586, "SUB_DISTRICT_CODE": "530803", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5580", "ZIPCODE": "53130" },
    { "ZIPCODE_ID": 4587, "SUB_DISTRICT_CODE": "530804", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5581", "ZIPCODE": "53130" },
    { "ZIPCODE_ID": 4588, "SUB_DISTRICT_CODE": "530805", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5582", "ZIPCODE": "53130" },
    { "ZIPCODE_ID": 4589, "SUB_DISTRICT_CODE": "530806", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5583", "ZIPCODE": "53210" },
    { "ZIPCODE_ID": 4590, "SUB_DISTRICT_CODE": "530807", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5584", "ZIPCODE": "53210" },
    { "ZIPCODE_ID": 4591, "SUB_DISTRICT_CODE": "530808", "PROVINCE_ID": "41", "DISTRICT_ID": "623", "SUB_DISTRICT_ID": "5585", "ZIPCODE": "53210" },
    { "ZIPCODE_ID": 4592, "SUB_DISTRICT_CODE": "530901", "PROVINCE_ID": "41", "DISTRICT_ID": "624", "SUB_DISTRICT_ID": "5587", "ZIPCODE": "53230" },
    { "ZIPCODE_ID": 4593, "SUB_DISTRICT_CODE": "530902", "PROVINCE_ID": "41", "DISTRICT_ID": "624", "SUB_DISTRICT_ID": "5588", "ZIPCODE": "53230" },
    { "ZIPCODE_ID": 4594, "SUB_DISTRICT_CODE": "530903", "PROVINCE_ID": "41", "DISTRICT_ID": "624", "SUB_DISTRICT_ID": "5589", "ZIPCODE": "53230" },
    { "ZIPCODE_ID": 4595, "SUB_DISTRICT_CODE": "530904", "PROVINCE_ID": "41", "DISTRICT_ID": "624", "SUB_DISTRICT_ID": "5590", "ZIPCODE": "53230" },
    { "ZIPCODE_ID": 4596, "SUB_DISTRICT_CODE": "540101", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5591", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4597, "SUB_DISTRICT_CODE": "540102", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5592", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4598, "SUB_DISTRICT_CODE": "540103", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5593", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4599, "SUB_DISTRICT_CODE": "540104", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5594", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4600, "SUB_DISTRICT_CODE": "540105", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5595", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4601, "SUB_DISTRICT_CODE": "540106", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5596", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4602, "SUB_DISTRICT_CODE": "540107", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5597", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4603, "SUB_DISTRICT_CODE": "540108", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5598", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4604, "SUB_DISTRICT_CODE": "540109", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5599", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4605, "SUB_DISTRICT_CODE": "540110", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5600", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4606, "SUB_DISTRICT_CODE": "540111", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5601", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4607, "SUB_DISTRICT_CODE": "540112", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5602", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4608, "SUB_DISTRICT_CODE": "540113", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5603", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4609, "SUB_DISTRICT_CODE": "540114", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5604", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4610, "SUB_DISTRICT_CODE": "540115", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5605", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4611, "SUB_DISTRICT_CODE": "540116", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5606", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4612, "SUB_DISTRICT_CODE": "540117", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5607", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4613, "SUB_DISTRICT_CODE": "540118", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5608", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4614, "SUB_DISTRICT_CODE": "540119", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5609", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4615, "SUB_DISTRICT_CODE": "540120", "PROVINCE_ID": "42", "DISTRICT_ID": "625", "SUB_DISTRICT_ID": "5610", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4616, "SUB_DISTRICT_CODE": "540201", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5611", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4617, "SUB_DISTRICT_CODE": "540204", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5614", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4618, "SUB_DISTRICT_CODE": "540205", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5615", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4619, "SUB_DISTRICT_CODE": "540206", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5616", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4620, "SUB_DISTRICT_CODE": "540207", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5617", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4621, "SUB_DISTRICT_CODE": "540208", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5618", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4622, "SUB_DISTRICT_CODE": "540209", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5619", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4623, "SUB_DISTRICT_CODE": "540210", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5620", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4624, "SUB_DISTRICT_CODE": "540213", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5623", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4625, "SUB_DISTRICT_CODE": "540214", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5624", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4626, "SUB_DISTRICT_CODE": "540215", "PROVINCE_ID": "42", "DISTRICT_ID": "626", "SUB_DISTRICT_ID": "5625", "ZIPCODE": "54140" },
    { "ZIPCODE_ID": 4627, "SUB_DISTRICT_CODE": "540301", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5628", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4628, "SUB_DISTRICT_CODE": "540302", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5629", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4629, "SUB_DISTRICT_CODE": "540303", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5630", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4630, "SUB_DISTRICT_CODE": "540304", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5631", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4631, "SUB_DISTRICT_CODE": "540305", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5632", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4632, "SUB_DISTRICT_CODE": "540306", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5633", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4633, "SUB_DISTRICT_CODE": "540307", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5634", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4634, "SUB_DISTRICT_CODE": "540308", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5635", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4635, "SUB_DISTRICT_CODE": "540309", "PROVINCE_ID": "42", "DISTRICT_ID": "627", "SUB_DISTRICT_ID": "5636", "ZIPCODE": "54150" },
    { "ZIPCODE_ID": 4636, "SUB_DISTRICT_CODE": "540401", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5637", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4637, "SUB_DISTRICT_CODE": "540402", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5638", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4638, "SUB_DISTRICT_CODE": "540403", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5639", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4639, "SUB_DISTRICT_CODE": "540404", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5640", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4640, "SUB_DISTRICT_CODE": "540405", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5641", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4641, "SUB_DISTRICT_CODE": "540406", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5642", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4642, "SUB_DISTRICT_CODE": "540407", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5643", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4643, "SUB_DISTRICT_CODE": "540408", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5644", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4644, "SUB_DISTRICT_CODE": "540409", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5645", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4645, "SUB_DISTRICT_CODE": "540410", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5646", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4646, "SUB_DISTRICT_CODE": "540411", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5647", "ZIPCODE": "54000" },
    { "ZIPCODE_ID": 4647, "SUB_DISTRICT_CODE": "540412", "PROVINCE_ID": "42", "DISTRICT_ID": "628", "SUB_DISTRICT_ID": "5648", "ZIPCODE": "54130" },
    { "ZIPCODE_ID": 4648, "SUB_DISTRICT_CODE": "540501", "PROVINCE_ID": "42", "DISTRICT_ID": "629", "SUB_DISTRICT_ID": "5649", "ZIPCODE": "54110" },
    { "ZIPCODE_ID": 4649, "SUB_DISTRICT_CODE": "540502", "PROVINCE_ID": "42", "DISTRICT_ID": "629", "SUB_DISTRICT_ID": "5650", "ZIPCODE": "54110" },
    { "ZIPCODE_ID": 4650, "SUB_DISTRICT_CODE": "540503", "PROVINCE_ID": "42", "DISTRICT_ID": "629", "SUB_DISTRICT_ID": "5651", "ZIPCODE": "54110" },
    { "ZIPCODE_ID": 4651, "SUB_DISTRICT_CODE": "540504", "PROVINCE_ID": "42", "DISTRICT_ID": "629", "SUB_DISTRICT_ID": "5652", "ZIPCODE": "54110" },
    { "ZIPCODE_ID": 4652, "SUB_DISTRICT_CODE": "540505", "PROVINCE_ID": "42", "DISTRICT_ID": "629", "SUB_DISTRICT_ID": "5653", "ZIPCODE": "54110" },
    { "ZIPCODE_ID": 4653, "SUB_DISTRICT_CODE": "540601", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5654", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4654, "SUB_DISTRICT_CODE": "540602", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5655", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4655, "SUB_DISTRICT_CODE": "540603", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5656", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4656, "SUB_DISTRICT_CODE": "540604", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5657", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4657, "SUB_DISTRICT_CODE": "540605", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5658", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4658, "SUB_DISTRICT_CODE": "540606", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5659", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4659, "SUB_DISTRICT_CODE": "540607", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5660", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4660, "SUB_DISTRICT_CODE": "540608", "PROVINCE_ID": "42", "DISTRICT_ID": "630", "SUB_DISTRICT_ID": "5661", "ZIPCODE": "54120" },
    { "ZIPCODE_ID": 4661, "SUB_DISTRICT_CODE": "540701", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5662", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4662, "SUB_DISTRICT_CODE": "540702", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5663", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4663, "SUB_DISTRICT_CODE": "540703", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5664", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4664, "SUB_DISTRICT_CODE": "540704", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5665", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4665, "SUB_DISTRICT_CODE": "540705", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5666", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4666, "SUB_DISTRICT_CODE": "540706", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5667", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4667, "SUB_DISTRICT_CODE": "540707", "PROVINCE_ID": "42", "DISTRICT_ID": "631", "SUB_DISTRICT_ID": "5668", "ZIPCODE": "54160" },
    { "ZIPCODE_ID": 4668, "SUB_DISTRICT_CODE": "540801", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5669", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4669, "SUB_DISTRICT_CODE": "540802", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5670", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4670, "SUB_DISTRICT_CODE": "540803", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5671", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4671, "SUB_DISTRICT_CODE": "540804", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5672", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4672, "SUB_DISTRICT_CODE": "540805", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5673", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4673, "SUB_DISTRICT_CODE": "540806", "PROVINCE_ID": "42", "DISTRICT_ID": "632", "SUB_DISTRICT_ID": "5674", "ZIPCODE": "54170" },
    { "ZIPCODE_ID": 4674, "SUB_DISTRICT_CODE": "550101", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5675", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4675, "SUB_DISTRICT_CODE": "550102", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5676", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4676, "SUB_DISTRICT_CODE": "550103", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5677", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4677, "SUB_DISTRICT_CODE": "550104", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5678", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4678, "SUB_DISTRICT_CODE": "550105", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5679", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4679, "SUB_DISTRICT_CODE": "550106", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5680", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4680, "SUB_DISTRICT_CODE": "550107", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5681", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4681, "SUB_DISTRICT_CODE": "550108", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5682", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4682, "SUB_DISTRICT_CODE": "550109", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5683", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4683, "SUB_DISTRICT_CODE": "550116", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5690", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4684, "SUB_DISTRICT_CODE": "550117", "PROVINCE_ID": "43", "DISTRICT_ID": "633", "SUB_DISTRICT_ID": "5691", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4685, "SUB_DISTRICT_CODE": "550202", "PROVINCE_ID": "43", "DISTRICT_ID": "634", "SUB_DISTRICT_ID": "5698", "ZIPCODE": "55170" },
    { "ZIPCODE_ID": 4686, "SUB_DISTRICT_CODE": "550203", "PROVINCE_ID": "43", "DISTRICT_ID": "634", "SUB_DISTRICT_ID": "5699", "ZIPCODE": "55170" },
    { "ZIPCODE_ID": 4687, "SUB_DISTRICT_CODE": "550204", "PROVINCE_ID": "43", "DISTRICT_ID": "634", "SUB_DISTRICT_ID": "5700", "ZIPCODE": "55170" },
    { "ZIPCODE_ID": 4688, "SUB_DISTRICT_CODE": "550205", "PROVINCE_ID": "43", "DISTRICT_ID": "634", "SUB_DISTRICT_ID": "5701", "ZIPCODE": "55170" },
    { "ZIPCODE_ID": 4689, "SUB_DISTRICT_CODE": "550206", "PROVINCE_ID": "43", "DISTRICT_ID": "634", "SUB_DISTRICT_ID": "5702", "ZIPCODE": "55170" },
    { "ZIPCODE_ID": 4690, "SUB_DISTRICT_CODE": "550301", "PROVINCE_ID": "43", "DISTRICT_ID": "635", "SUB_DISTRICT_ID": "5703", "ZIPCODE": "55190" },
    { "ZIPCODE_ID": 4691, "SUB_DISTRICT_CODE": "550302", "PROVINCE_ID": "43", "DISTRICT_ID": "635", "SUB_DISTRICT_ID": "5704", "ZIPCODE": "55190" },
    { "ZIPCODE_ID": 4692, "SUB_DISTRICT_CODE": "550303", "PROVINCE_ID": "43", "DISTRICT_ID": "635", "SUB_DISTRICT_ID": "5705", "ZIPCODE": "55190" },
    { "ZIPCODE_ID": 4693, "SUB_DISTRICT_CODE": "550304", "PROVINCE_ID": "43", "DISTRICT_ID": "635", "SUB_DISTRICT_ID": "5706", "ZIPCODE": "55190" },
    { "ZIPCODE_ID": 4694, "SUB_DISTRICT_CODE": "550401", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5707", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4695, "SUB_DISTRICT_CODE": "550402", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5708", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4696, "SUB_DISTRICT_CODE": "550403", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5709", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4697, "SUB_DISTRICT_CODE": "550404", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5710", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4698, "SUB_DISTRICT_CODE": "550405", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5711", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4699, "SUB_DISTRICT_CODE": "550406", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5712", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4700, "SUB_DISTRICT_CODE": "550407", "PROVINCE_ID": "43", "DISTRICT_ID": "636", "SUB_DISTRICT_ID": "5713", "ZIPCODE": "55150" },
    { "ZIPCODE_ID": 4701, "SUB_DISTRICT_CODE": "550501", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5714", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4702, "SUB_DISTRICT_CODE": "550502", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5715", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4703, "SUB_DISTRICT_CODE": "550503", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5716", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4704, "SUB_DISTRICT_CODE": "550504", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5717", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4705, "SUB_DISTRICT_CODE": "550505", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5718", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4706, "SUB_DISTRICT_CODE": "550506", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5719", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4707, "SUB_DISTRICT_CODE": "550509", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5722", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4708, "SUB_DISTRICT_CODE": "550510", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5723", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4709, "SUB_DISTRICT_CODE": "550511", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5724", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4710, "SUB_DISTRICT_CODE": "550512", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5725", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4711, "SUB_DISTRICT_CODE": "550513", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5726", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4712, "SUB_DISTRICT_CODE": "550514", "PROVINCE_ID": "43", "DISTRICT_ID": "637", "SUB_DISTRICT_ID": "5727", "ZIPCODE": "55120" },
    { "ZIPCODE_ID": 4713, "SUB_DISTRICT_CODE": "550601", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5728", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4714, "SUB_DISTRICT_CODE": "550602", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5729", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4715, "SUB_DISTRICT_CODE": "550603", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5730", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4716, "SUB_DISTRICT_CODE": "550604", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5731", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4717, "SUB_DISTRICT_CODE": "550605", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5732", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4718, "SUB_DISTRICT_CODE": "550606", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5733", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4719, "SUB_DISTRICT_CODE": "550607", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5734", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4720, "SUB_DISTRICT_CODE": "550608", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5735", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4721, "SUB_DISTRICT_CODE": "550609", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5736", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4722, "SUB_DISTRICT_CODE": "550610", "PROVINCE_ID": "43", "DISTRICT_ID": "638", "SUB_DISTRICT_ID": "5737", "ZIPCODE": "55140" },
    { "ZIPCODE_ID": 4723, "SUB_DISTRICT_CODE": "550701", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5738", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4724, "SUB_DISTRICT_CODE": "550702", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5739", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4725, "SUB_DISTRICT_CODE": "550703", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5740", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4726, "SUB_DISTRICT_CODE": "550704", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5741", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4727, "SUB_DISTRICT_CODE": "550705", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5742", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4728, "SUB_DISTRICT_CODE": "550706", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5743", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4729, "SUB_DISTRICT_CODE": "550707", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5744", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4730, "SUB_DISTRICT_CODE": "550708", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5745", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4731, "SUB_DISTRICT_CODE": "550709", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5746", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4732, "SUB_DISTRICT_CODE": "550710", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5747", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4733, "SUB_DISTRICT_CODE": "550711", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5748", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4734, "SUB_DISTRICT_CODE": "550712", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5749", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4735, "SUB_DISTRICT_CODE": "550713", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5750", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4736, "SUB_DISTRICT_CODE": "550714", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5751", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4737, "SUB_DISTRICT_CODE": "550715", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5752", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4738, "SUB_DISTRICT_CODE": "550716", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5753", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4739, "SUB_DISTRICT_CODE": "550717", "PROVINCE_ID": "43", "DISTRICT_ID": "639", "SUB_DISTRICT_ID": "5754", "ZIPCODE": "55110" },
    { "ZIPCODE_ID": 4740, "SUB_DISTRICT_CODE": "550801", "PROVINCE_ID": "43", "DISTRICT_ID": "640", "SUB_DISTRICT_ID": "5755", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4741, "SUB_DISTRICT_CODE": "550802", "PROVINCE_ID": "43", "DISTRICT_ID": "640", "SUB_DISTRICT_ID": "5756", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4742, "SUB_DISTRICT_CODE": "550803", "PROVINCE_ID": "43", "DISTRICT_ID": "640", "SUB_DISTRICT_ID": "5757", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4743, "SUB_DISTRICT_CODE": "550804", "PROVINCE_ID": "43", "DISTRICT_ID": "640", "SUB_DISTRICT_ID": "5758", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4744, "SUB_DISTRICT_CODE": "550901", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5762", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4745, "SUB_DISTRICT_CODE": "550902", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5763", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4746, "SUB_DISTRICT_CODE": "550903", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5764", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4747, "SUB_DISTRICT_CODE": "550904", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5765", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4748, "SUB_DISTRICT_CODE": "550908", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5769", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4749, "SUB_DISTRICT_CODE": "550909", "PROVINCE_ID": "43", "DISTRICT_ID": "641", "SUB_DISTRICT_ID": "5770", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4750, "SUB_DISTRICT_CODE": "551001", "PROVINCE_ID": "43", "DISTRICT_ID": "642", "SUB_DISTRICT_ID": "5773", "ZIPCODE": "55180" },
    { "ZIPCODE_ID": 4751, "SUB_DISTRICT_CODE": "551002", "PROVINCE_ID": "43", "DISTRICT_ID": "642", "SUB_DISTRICT_ID": "5774", "ZIPCODE": "55180" },
    { "ZIPCODE_ID": 4752, "SUB_DISTRICT_CODE": "551003", "PROVINCE_ID": "43", "DISTRICT_ID": "642", "SUB_DISTRICT_ID": "5775", "ZIPCODE": "55180" },
    { "ZIPCODE_ID": 4753, "SUB_DISTRICT_CODE": "551004", "PROVINCE_ID": "43", "DISTRICT_ID": "642", "SUB_DISTRICT_ID": "5776", "ZIPCODE": "55180" },
    { "ZIPCODE_ID": 4754, "SUB_DISTRICT_CODE": "551101", "PROVINCE_ID": "43", "DISTRICT_ID": "643", "SUB_DISTRICT_ID": "5777", "ZIPCODE": "55210" },
    { "ZIPCODE_ID": 4755, "SUB_DISTRICT_CODE": "551102", "PROVINCE_ID": "43", "DISTRICT_ID": "643", "SUB_DISTRICT_ID": "5778", "ZIPCODE": "55210" },
    { "ZIPCODE_ID": 4756, "SUB_DISTRICT_CODE": "551103", "PROVINCE_ID": "43", "DISTRICT_ID": "643", "SUB_DISTRICT_ID": "5779", "ZIPCODE": "55210" },
    { "ZIPCODE_ID": 4757, "SUB_DISTRICT_CODE": "551201", "PROVINCE_ID": "43", "DISTRICT_ID": "644", "SUB_DISTRICT_ID": "5780", "ZIPCODE": "55220" },
    { "ZIPCODE_ID": 4758, "SUB_DISTRICT_CODE": "551202", "PROVINCE_ID": "43", "DISTRICT_ID": "644", "SUB_DISTRICT_ID": "5781", "ZIPCODE": "55220" },
    { "ZIPCODE_ID": 4759, "SUB_DISTRICT_CODE": "551204", "PROVINCE_ID": "43", "DISTRICT_ID": "644", "SUB_DISTRICT_ID": "5783", "ZIPCODE": "55220" },
    { "ZIPCODE_ID": 4760, "SUB_DISTRICT_CODE": "551205", "PROVINCE_ID": "43", "DISTRICT_ID": "644", "SUB_DISTRICT_ID": "5784", "ZIPCODE": "55220" },
    { "ZIPCODE_ID": 4761, "SUB_DISTRICT_CODE": "551301", "PROVINCE_ID": "43", "DISTRICT_ID": "645", "SUB_DISTRICT_ID": "5785", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4762, "SUB_DISTRICT_CODE": "551302", "PROVINCE_ID": "43", "DISTRICT_ID": "645", "SUB_DISTRICT_ID": "5786", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4763, "SUB_DISTRICT_CODE": "551303", "PROVINCE_ID": "43", "DISTRICT_ID": "645", "SUB_DISTRICT_ID": "5787", "ZIPCODE": "55160" },
    { "ZIPCODE_ID": 4764, "SUB_DISTRICT_CODE": "551401", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5788", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4765, "SUB_DISTRICT_CODE": "551402", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5789", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4766, "SUB_DISTRICT_CODE": "551403", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5790", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4767, "SUB_DISTRICT_CODE": "551404", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5791", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4768, "SUB_DISTRICT_CODE": "551405", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5792", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4769, "SUB_DISTRICT_CODE": "551406", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5793", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4770, "SUB_DISTRICT_CODE": "551407", "PROVINCE_ID": "43", "DISTRICT_ID": "646", "SUB_DISTRICT_ID": "5794", "ZIPCODE": "55000" },
    { "ZIPCODE_ID": 4771, "SUB_DISTRICT_CODE": "551501", "PROVINCE_ID": "43", "DISTRICT_ID": "647", "SUB_DISTRICT_ID": "5795", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4772, "SUB_DISTRICT_CODE": "551502", "PROVINCE_ID": "43", "DISTRICT_ID": "647", "SUB_DISTRICT_ID": "5796", "ZIPCODE": "55130" },
    { "ZIPCODE_ID": 4773, "SUB_DISTRICT_CODE": "560101", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5797", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4774, "SUB_DISTRICT_CODE": "560102", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5798", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4775, "SUB_DISTRICT_CODE": "560104", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5800", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4776, "SUB_DISTRICT_CODE": "560105", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5801", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4777, "SUB_DISTRICT_CODE": "560106", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5802", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4778, "SUB_DISTRICT_CODE": "560107", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5803", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4779, "SUB_DISTRICT_CODE": "560108", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5804", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4780, "SUB_DISTRICT_CODE": "560110", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5806", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4781, "SUB_DISTRICT_CODE": "560111", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5807", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4782, "SUB_DISTRICT_CODE": "560112", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5808", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4783, "SUB_DISTRICT_CODE": "560113", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5809", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4784, "SUB_DISTRICT_CODE": "560114", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5810", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4785, "SUB_DISTRICT_CODE": "560115", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5811", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4786, "SUB_DISTRICT_CODE": "560116", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5812", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4787, "SUB_DISTRICT_CODE": "560118", "PROVINCE_ID": "44", "DISTRICT_ID": "648", "SUB_DISTRICT_ID": "5814", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4788, "SUB_DISTRICT_CODE": "560201", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5815", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4789, "SUB_DISTRICT_CODE": "560202", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5816", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4790, "SUB_DISTRICT_CODE": "560203", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5817", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4791, "SUB_DISTRICT_CODE": "560204", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5818", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4792, "SUB_DISTRICT_CODE": "560205", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5819", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4793, "SUB_DISTRICT_CODE": "560206", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5820", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4794, "SUB_DISTRICT_CODE": "560207", "PROVINCE_ID": "44", "DISTRICT_ID": "649", "SUB_DISTRICT_ID": "5821", "ZIPCODE": "56150" },
    { "ZIPCODE_ID": 4795, "SUB_DISTRICT_CODE": "560301", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5822", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4796, "SUB_DISTRICT_CODE": "560306", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5827", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4797, "SUB_DISTRICT_CODE": "560307", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5828", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4798, "SUB_DISTRICT_CODE": "560308", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5829", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4799, "SUB_DISTRICT_CODE": "560309", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5830", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4800, "SUB_DISTRICT_CODE": "560310", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5831", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4801, "SUB_DISTRICT_CODE": "560311", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5832", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4802, "SUB_DISTRICT_CODE": "560312", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5833", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4803, "SUB_DISTRICT_CODE": "560313", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5834", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4804, "SUB_DISTRICT_CODE": "560314", "PROVINCE_ID": "44", "DISTRICT_ID": "650", "SUB_DISTRICT_ID": "5835", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4805, "SUB_DISTRICT_CODE": "560401", "PROVINCE_ID": "44", "DISTRICT_ID": "651", "SUB_DISTRICT_ID": "5837", "ZIPCODE": "56160" },
    { "ZIPCODE_ID": 4806, "SUB_DISTRICT_CODE": "560402", "PROVINCE_ID": "44", "DISTRICT_ID": "651", "SUB_DISTRICT_ID": "5838", "ZIPCODE": "56160" },
    { "ZIPCODE_ID": 4807, "SUB_DISTRICT_CODE": "560403", "PROVINCE_ID": "44", "DISTRICT_ID": "651", "SUB_DISTRICT_ID": "5839", "ZIPCODE": "56160" },
    { "ZIPCODE_ID": 4808, "SUB_DISTRICT_CODE": "560501", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5840", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4809, "SUB_DISTRICT_CODE": "560502", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5841", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4810, "SUB_DISTRICT_CODE": "560503", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5842", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4811, "SUB_DISTRICT_CODE": "560504", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5843", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4812, "SUB_DISTRICT_CODE": "560505", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5844", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4813, "SUB_DISTRICT_CODE": "560506", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5845", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4814, "SUB_DISTRICT_CODE": "560507", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5846", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4815, "SUB_DISTRICT_CODE": "560508", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5847", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4816, "SUB_DISTRICT_CODE": "560509", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5848", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4817, "SUB_DISTRICT_CODE": "560510", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5849", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4818, "SUB_DISTRICT_CODE": "560511", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5850", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4819, "SUB_DISTRICT_CODE": "560512", "PROVINCE_ID": "44", "DISTRICT_ID": "652", "SUB_DISTRICT_ID": "5851", "ZIPCODE": "56120" },
    { "ZIPCODE_ID": 4820, "SUB_DISTRICT_CODE": "560601", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5852", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4821, "SUB_DISTRICT_CODE": "560602", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5853", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4822, "SUB_DISTRICT_CODE": "560603", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5854", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4823, "SUB_DISTRICT_CODE": "560604", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5855", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4824, "SUB_DISTRICT_CODE": "560605", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5856", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4825, "SUB_DISTRICT_CODE": "560606", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5857", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4826, "SUB_DISTRICT_CODE": "560607", "PROVINCE_ID": "44", "DISTRICT_ID": "653", "SUB_DISTRICT_ID": "5858", "ZIPCODE": "56140" },
    { "ZIPCODE_ID": 4827, "SUB_DISTRICT_CODE": "560701", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5859", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4828, "SUB_DISTRICT_CODE": "560702", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5860", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4829, "SUB_DISTRICT_CODE": "560703", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5861", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4830, "SUB_DISTRICT_CODE": "560704", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5862", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4831, "SUB_DISTRICT_CODE": "560705", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5863", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4832, "SUB_DISTRICT_CODE": "560706", "PROVINCE_ID": "44", "DISTRICT_ID": "654", "SUB_DISTRICT_ID": "5864", "ZIPCODE": "56130" },
    { "ZIPCODE_ID": 4833, "SUB_DISTRICT_CODE": "560801", "PROVINCE_ID": "44", "DISTRICT_ID": "655", "SUB_DISTRICT_ID": "5865", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4834, "SUB_DISTRICT_CODE": "560802", "PROVINCE_ID": "44", "DISTRICT_ID": "655", "SUB_DISTRICT_ID": "5866", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4835, "SUB_DISTRICT_CODE": "560803", "PROVINCE_ID": "44", "DISTRICT_ID": "655", "SUB_DISTRICT_ID": "5867", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4836, "SUB_DISTRICT_CODE": "560804", "PROVINCE_ID": "44", "DISTRICT_ID": "655", "SUB_DISTRICT_ID": "5868", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4837, "SUB_DISTRICT_CODE": "560805", "PROVINCE_ID": "44", "DISTRICT_ID": "655", "SUB_DISTRICT_ID": "5869", "ZIPCODE": "56110" },
    { "ZIPCODE_ID": 4838, "SUB_DISTRICT_CODE": "560901", "PROVINCE_ID": "44", "DISTRICT_ID": "656", "SUB_DISTRICT_ID": "5870", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4839, "SUB_DISTRICT_CODE": "560902", "PROVINCE_ID": "44", "DISTRICT_ID": "656", "SUB_DISTRICT_ID": "5871", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4840, "SUB_DISTRICT_CODE": "560903", "PROVINCE_ID": "44", "DISTRICT_ID": "656", "SUB_DISTRICT_ID": "5872", "ZIPCODE": "56000" },
    { "ZIPCODE_ID": 4841, "SUB_DISTRICT_CODE": "570101", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5873", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4842, "SUB_DISTRICT_CODE": "570102", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5874", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4843, "SUB_DISTRICT_CODE": "570103", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5875", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4844, "SUB_DISTRICT_CODE": "570104", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5876", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4845, "SUB_DISTRICT_CODE": "570105", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5877", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4846, "SUB_DISTRICT_CODE": "570106", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5878", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4847, "SUB_DISTRICT_CODE": "570107", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5879", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4848, "SUB_DISTRICT_CODE": "570111", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5883", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4849, "SUB_DISTRICT_CODE": "570112", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5884", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4850, "SUB_DISTRICT_CODE": "570113", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5885", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4851, "SUB_DISTRICT_CODE": "570114", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5886", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4852, "SUB_DISTRICT_CODE": "570115", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5887", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4853, "SUB_DISTRICT_CODE": "570116", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5888", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4854, "SUB_DISTRICT_CODE": "570118", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5890", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4855, "SUB_DISTRICT_CODE": "570120", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5892", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4856, "SUB_DISTRICT_CODE": "570121", "PROVINCE_ID": "45", "DISTRICT_ID": "657", "SUB_DISTRICT_ID": "5893", "ZIPCODE": "57100" },
    { "ZIPCODE_ID": 4857, "SUB_DISTRICT_CODE": "570202", "PROVINCE_ID": "45", "DISTRICT_ID": "658", "SUB_DISTRICT_ID": "5903", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4858, "SUB_DISTRICT_CODE": "570203", "PROVINCE_ID": "45", "DISTRICT_ID": "658", "SUB_DISTRICT_ID": "5904", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4859, "SUB_DISTRICT_CODE": "570204", "PROVINCE_ID": "45", "DISTRICT_ID": "658", "SUB_DISTRICT_ID": "5905", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4860, "SUB_DISTRICT_CODE": "570206", "PROVINCE_ID": "45", "DISTRICT_ID": "658", "SUB_DISTRICT_ID": "5907", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4861, "SUB_DISTRICT_CODE": "570208", "PROVINCE_ID": "45", "DISTRICT_ID": "658", "SUB_DISTRICT_ID": "5909", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4862, "SUB_DISTRICT_CODE": "570301", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5910", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4863, "SUB_DISTRICT_CODE": "570302", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5911", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4864, "SUB_DISTRICT_CODE": "570303", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5912", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4865, "SUB_DISTRICT_CODE": "570304", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5913", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4866, "SUB_DISTRICT_CODE": "570305", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5914", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4867, "SUB_DISTRICT_CODE": "570308", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5917", "ZIPCODE": "57230" },
    { "ZIPCODE_ID": 4868, "SUB_DISTRICT_CODE": "570310", "PROVINCE_ID": "45", "DISTRICT_ID": "659", "SUB_DISTRICT_ID": "5919", "ZIPCODE": "57140" },
    { "ZIPCODE_ID": 4869, "SUB_DISTRICT_CODE": "570401", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5922", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4870, "SUB_DISTRICT_CODE": "570402", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5923", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4871, "SUB_DISTRICT_CODE": "570403", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5924", "ZIPCODE": "57230" },
    { "ZIPCODE_ID": 4872, "SUB_DISTRICT_CODE": "570404", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5925", "ZIPCODE": "57230" },
    { "ZIPCODE_ID": 4873, "SUB_DISTRICT_CODE": "570405", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5926", "ZIPCODE": "57230" },
    { "ZIPCODE_ID": 4874, "SUB_DISTRICT_CODE": "570409", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5930", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4875, "SUB_DISTRICT_CODE": "570410", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5931", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4876, "SUB_DISTRICT_CODE": "570411", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5932", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4877, "SUB_DISTRICT_CODE": "570412", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5933", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4878, "SUB_DISTRICT_CODE": "570413", "PROVINCE_ID": "45", "DISTRICT_ID": "660", "SUB_DISTRICT_ID": "5934", "ZIPCODE": "57160" },
    { "ZIPCODE_ID": 4879, "SUB_DISTRICT_CODE": "570501", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5940", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4880, "SUB_DISTRICT_CODE": "570502", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5941", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4881, "SUB_DISTRICT_CODE": "570503", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5942", "ZIPCODE": "57250" },
    { "ZIPCODE_ID": 4882, "SUB_DISTRICT_CODE": "570504", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5943", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4883, "SUB_DISTRICT_CODE": "570505", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5944", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4884, "SUB_DISTRICT_CODE": "570506", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5945", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4885, "SUB_DISTRICT_CODE": "570507", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5946", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4886, "SUB_DISTRICT_CODE": "570508", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5947", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4887, "SUB_DISTRICT_CODE": "570509", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5948", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4888, "SUB_DISTRICT_CODE": "570510", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5949", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4889, "SUB_DISTRICT_CODE": "570511", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5950", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4890, "SUB_DISTRICT_CODE": "570512", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5951", "ZIPCODE": "57280" },
    { "ZIPCODE_ID": 4891, "SUB_DISTRICT_CODE": "570513", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5952", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4892, "SUB_DISTRICT_CODE": "570514", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5953", "ZIPCODE": "57280" },
    { "ZIPCODE_ID": 4893, "SUB_DISTRICT_CODE": "570515", "PROVINCE_ID": "45", "DISTRICT_ID": "661", "SUB_DISTRICT_ID": "5954", "ZIPCODE": "57120" },
    { "ZIPCODE_ID": 4894, "SUB_DISTRICT_CODE": "570601", "PROVINCE_ID": "45", "DISTRICT_ID": "662", "SUB_DISTRICT_ID": "5958", "ZIPCODE": "57190" },
    { "ZIPCODE_ID": 4895, "SUB_DISTRICT_CODE": "570602", "PROVINCE_ID": "45", "DISTRICT_ID": "662", "SUB_DISTRICT_ID": "5959", "ZIPCODE": "57190" },
    { "ZIPCODE_ID": 4896, "SUB_DISTRICT_CODE": "570603", "PROVINCE_ID": "45", "DISTRICT_ID": "662", "SUB_DISTRICT_ID": "5960", "ZIPCODE": "57190" },
    { "ZIPCODE_ID": 4897, "SUB_DISTRICT_CODE": "570605", "PROVINCE_ID": "45", "DISTRICT_ID": "662", "SUB_DISTRICT_ID": "5961", "ZIPCODE": "57190" },
    { "ZIPCODE_ID": 4898, "SUB_DISTRICT_CODE": "570606", "PROVINCE_ID": "45", "DISTRICT_ID": "662", "SUB_DISTRICT_ID": "5962", "ZIPCODE": "57190" },
    { "ZIPCODE_ID": 4899, "SUB_DISTRICT_CODE": "570701", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5963", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4900, "SUB_DISTRICT_CODE": "570702", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5964", "ZIPCODE": "57270" },
    { "ZIPCODE_ID": 4901, "SUB_DISTRICT_CODE": "570703", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5965", "ZIPCODE": "57240" },
    { "ZIPCODE_ID": 4902, "SUB_DISTRICT_CODE": "570704", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5966", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4903, "SUB_DISTRICT_CODE": "570705", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5967", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4904, "SUB_DISTRICT_CODE": "570706", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5968", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4905, "SUB_DISTRICT_CODE": "570708", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5970", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4906, "SUB_DISTRICT_CODE": "570710", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5972", "ZIPCODE": "57240" },
    { "ZIPCODE_ID": 4907, "SUB_DISTRICT_CODE": "570711", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5973", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4908, "SUB_DISTRICT_CODE": "570712", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5974", "ZIPCODE": "57270" },
    { "ZIPCODE_ID": 4909, "SUB_DISTRICT_CODE": "570713", "PROVINCE_ID": "45", "DISTRICT_ID": "663", "SUB_DISTRICT_ID": "5975", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4910, "SUB_DISTRICT_CODE": "570801", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5980", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4911, "SUB_DISTRICT_CODE": "570802", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5981", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4912, "SUB_DISTRICT_CODE": "570803", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5982", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4913, "SUB_DISTRICT_CODE": "570804", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5983", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4914, "SUB_DISTRICT_CODE": "570805", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5984", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4915, "SUB_DISTRICT_CODE": "570806", "PROVINCE_ID": "45", "DISTRICT_ID": "664", "SUB_DISTRICT_ID": "5985", "ZIPCODE": "57150" },
    { "ZIPCODE_ID": 4916, "SUB_DISTRICT_CODE": "570901", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5986", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4917, "SUB_DISTRICT_CODE": "570902", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5987", "ZIPCODE": "57220" },
    { "ZIPCODE_ID": 4918, "SUB_DISTRICT_CODE": "570903", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5988", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4919, "SUB_DISTRICT_CODE": "570904", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5989", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4920, "SUB_DISTRICT_CODE": "570905", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5990", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4921, "SUB_DISTRICT_CODE": "570906", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5991", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4922, "SUB_DISTRICT_CODE": "570908", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5992", "ZIPCODE": "57220" },
    { "ZIPCODE_ID": 4923, "SUB_DISTRICT_CODE": "570909", "PROVINCE_ID": "45", "DISTRICT_ID": "665", "SUB_DISTRICT_ID": "5993", "ZIPCODE": "57130" },
    { "ZIPCODE_ID": 4924, "SUB_DISTRICT_CODE": "571001", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5994", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4925, "SUB_DISTRICT_CODE": "571002", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5995", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4926, "SUB_DISTRICT_CODE": "571003", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5996", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4927, "SUB_DISTRICT_CODE": "571004", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5997", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4928, "SUB_DISTRICT_CODE": "571005", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5998", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4929, "SUB_DISTRICT_CODE": "571006", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "5999", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4930, "SUB_DISTRICT_CODE": "571007", "PROVINCE_ID": "45", "DISTRICT_ID": "666", "SUB_DISTRICT_ID": "6000", "ZIPCODE": "57180" },
    { "ZIPCODE_ID": 4931, "SUB_DISTRICT_CODE": "571101", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6001", "ZIPCODE": "57170" },
    { "ZIPCODE_ID": 4932, "SUB_DISTRICT_CODE": "571102", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6002", "ZIPCODE": "57170" },
    { "ZIPCODE_ID": 4933, "SUB_DISTRICT_CODE": "571103", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6003", "ZIPCODE": "57170" },
    { "ZIPCODE_ID": 4934, "SUB_DISTRICT_CODE": "571104", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6004", "ZIPCODE": "57170" },
    { "ZIPCODE_ID": 4935, "SUB_DISTRICT_CODE": "571105", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6005", "ZIPCODE": "57260" },
    { "ZIPCODE_ID": 4936, "SUB_DISTRICT_CODE": "571106", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6006", "ZIPCODE": "57260" },
    { "ZIPCODE_ID": 4937, "SUB_DISTRICT_CODE": "571107", "PROVINCE_ID": "45", "DISTRICT_ID": "667", "SUB_DISTRICT_ID": "6007", "ZIPCODE": "57260" },
    { "ZIPCODE_ID": 4938, "SUB_DISTRICT_CODE": "571201", "PROVINCE_ID": "45", "DISTRICT_ID": "668", "SUB_DISTRICT_ID": "6009", "ZIPCODE": "57290" },
    { "ZIPCODE_ID": 4939, "SUB_DISTRICT_CODE": "571202", "PROVINCE_ID": "45", "DISTRICT_ID": "668", "SUB_DISTRICT_ID": "6010", "ZIPCODE": "57290" },
    { "ZIPCODE_ID": 4940, "SUB_DISTRICT_CODE": "571203", "PROVINCE_ID": "45", "DISTRICT_ID": "668", "SUB_DISTRICT_ID": "6011", "ZIPCODE": "57290" },
    { "ZIPCODE_ID": 4941, "SUB_DISTRICT_CODE": "571204", "PROVINCE_ID": "45", "DISTRICT_ID": "668", "SUB_DISTRICT_ID": "6012", "ZIPCODE": "57290" },
    { "ZIPCODE_ID": 4942, "SUB_DISTRICT_CODE": "571205", "PROVINCE_ID": "45", "DISTRICT_ID": "668", "SUB_DISTRICT_ID": "6013", "ZIPCODE": "57290" },
    { "ZIPCODE_ID": 4943, "SUB_DISTRICT_CODE": "571301", "PROVINCE_ID": "45", "DISTRICT_ID": "669", "SUB_DISTRICT_ID": "6014", "ZIPCODE": "57310" },
    { "ZIPCODE_ID": 4944, "SUB_DISTRICT_CODE": "571302", "PROVINCE_ID": "45", "DISTRICT_ID": "669", "SUB_DISTRICT_ID": "6015", "ZIPCODE": "57310" },
    { "ZIPCODE_ID": 4945, "SUB_DISTRICT_CODE": "571303", "PROVINCE_ID": "45", "DISTRICT_ID": "669", "SUB_DISTRICT_ID": "6016", "ZIPCODE": "57310" },
    { "ZIPCODE_ID": 4946, "SUB_DISTRICT_CODE": "571304", "PROVINCE_ID": "45", "DISTRICT_ID": "669", "SUB_DISTRICT_ID": "6017", "ZIPCODE": "57310" },
    { "ZIPCODE_ID": 4947, "SUB_DISTRICT_CODE": "571401", "PROVINCE_ID": "45", "DISTRICT_ID": "670", "SUB_DISTRICT_ID": "6018", "ZIPCODE": "57340" },
    { "ZIPCODE_ID": 4948, "SUB_DISTRICT_CODE": "571402", "PROVINCE_ID": "45", "DISTRICT_ID": "670", "SUB_DISTRICT_ID": "6019", "ZIPCODE": "57340" },
    { "ZIPCODE_ID": 4949, "SUB_DISTRICT_CODE": "571403", "PROVINCE_ID": "45", "DISTRICT_ID": "670", "SUB_DISTRICT_ID": "6020", "ZIPCODE": "57340" },
    { "ZIPCODE_ID": 4950, "SUB_DISTRICT_CODE": "571501", "PROVINCE_ID": "45", "DISTRICT_ID": "671", "SUB_DISTRICT_ID": "6021", "ZIPCODE": "57240" },
    { "ZIPCODE_ID": 4951, "SUB_DISTRICT_CODE": "571502", "PROVINCE_ID": "45", "DISTRICT_ID": "671", "SUB_DISTRICT_ID": "6022", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4952, "SUB_DISTRICT_CODE": "571503", "PROVINCE_ID": "45", "DISTRICT_ID": "671", "SUB_DISTRICT_ID": "6023", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4953, "SUB_DISTRICT_CODE": "571504", "PROVINCE_ID": "45", "DISTRICT_ID": "671", "SUB_DISTRICT_ID": "6024", "ZIPCODE": "57240" },
    { "ZIPCODE_ID": 4954, "SUB_DISTRICT_CODE": "571601", "PROVINCE_ID": "45", "DISTRICT_ID": "672", "SUB_DISTRICT_ID": "6025", "ZIPCODE": "57250" },
    { "ZIPCODE_ID": 4955, "SUB_DISTRICT_CODE": "571602", "PROVINCE_ID": "45", "DISTRICT_ID": "672", "SUB_DISTRICT_ID": "6026", "ZIPCODE": "57250" },
    { "ZIPCODE_ID": 4956, "SUB_DISTRICT_CODE": "571603", "PROVINCE_ID": "45", "DISTRICT_ID": "672", "SUB_DISTRICT_ID": "6027", "ZIPCODE": "57250" },
    { "ZIPCODE_ID": 4957, "SUB_DISTRICT_CODE": "571604", "PROVINCE_ID": "45", "DISTRICT_ID": "672", "SUB_DISTRICT_ID": "6028", "ZIPCODE": "57250" },
    { "ZIPCODE_ID": 4958, "SUB_DISTRICT_CODE": "571605", "PROVINCE_ID": "45", "DISTRICT_ID": "672", "SUB_DISTRICT_ID": "6029", "ZIPCODE": "57000" },
    { "ZIPCODE_ID": 4959, "SUB_DISTRICT_CODE": "571701", "PROVINCE_ID": "45", "DISTRICT_ID": "673", "SUB_DISTRICT_ID": "6030", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4960, "SUB_DISTRICT_CODE": "571702", "PROVINCE_ID": "45", "DISTRICT_ID": "673", "SUB_DISTRICT_ID": "6031", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4961, "SUB_DISTRICT_CODE": "571703", "PROVINCE_ID": "45", "DISTRICT_ID": "673", "SUB_DISTRICT_ID": "6032", "ZIPCODE": "57210" },
    { "ZIPCODE_ID": 4962, "SUB_DISTRICT_CODE": "571801", "PROVINCE_ID": "45", "DISTRICT_ID": "674", "SUB_DISTRICT_ID": "6033", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4963, "SUB_DISTRICT_CODE": "571802", "PROVINCE_ID": "45", "DISTRICT_ID": "674", "SUB_DISTRICT_ID": "6034", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4964, "SUB_DISTRICT_CODE": "571803", "PROVINCE_ID": "45", "DISTRICT_ID": "674", "SUB_DISTRICT_ID": "6035", "ZIPCODE": "57110" },
    { "ZIPCODE_ID": 4965, "SUB_DISTRICT_CODE": "580101", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6036", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4966, "SUB_DISTRICT_CODE": "580102", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6037", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4967, "SUB_DISTRICT_CODE": "580103", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6038", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4968, "SUB_DISTRICT_CODE": "580104", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6039", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4969, "SUB_DISTRICT_CODE": "580105", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6040", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4970, "SUB_DISTRICT_CODE": "580106", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6041", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4971, "SUB_DISTRICT_CODE": "580109", "PROVINCE_ID": "46", "DISTRICT_ID": "675", "SUB_DISTRICT_ID": "6044", "ZIPCODE": "58000" },
    { "ZIPCODE_ID": 4972, "SUB_DISTRICT_CODE": "580201", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6045", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4973, "SUB_DISTRICT_CODE": "580202", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6046", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4974, "SUB_DISTRICT_CODE": "580203", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6047", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4975, "SUB_DISTRICT_CODE": "580204", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6048", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4976, "SUB_DISTRICT_CODE": "580205", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6049", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4977, "SUB_DISTRICT_CODE": "580206", "PROVINCE_ID": "46", "DISTRICT_ID": "676", "SUB_DISTRICT_ID": "6050", "ZIPCODE": "58140" },
    { "ZIPCODE_ID": 4978, "SUB_DISTRICT_CODE": "580301", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6051", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4979, "SUB_DISTRICT_CODE": "580302", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6052", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4980, "SUB_DISTRICT_CODE": "580303", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6053", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4981, "SUB_DISTRICT_CODE": "580304", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6054", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4982, "SUB_DISTRICT_CODE": "580305", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6055", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4983, "SUB_DISTRICT_CODE": "580306", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6056", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4984, "SUB_DISTRICT_CODE": "580307", "PROVINCE_ID": "46", "DISTRICT_ID": "677", "SUB_DISTRICT_ID": "6057", "ZIPCODE": "58130" },
    { "ZIPCODE_ID": 4985, "SUB_DISTRICT_CODE": "580401", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6058", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4986, "SUB_DISTRICT_CODE": "580402", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6059", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4987, "SUB_DISTRICT_CODE": "580403", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6060", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4988, "SUB_DISTRICT_CODE": "580404", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6061", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4989, "SUB_DISTRICT_CODE": "580405", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6062", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4990, "SUB_DISTRICT_CODE": "580406", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6063", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4991, "SUB_DISTRICT_CODE": "580408", "PROVINCE_ID": "46", "DISTRICT_ID": "678", "SUB_DISTRICT_ID": "6064", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 4992, "SUB_DISTRICT_CODE": "580501", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6068", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4993, "SUB_DISTRICT_CODE": "580502", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6069", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4994, "SUB_DISTRICT_CODE": "580503", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6070", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4995, "SUB_DISTRICT_CODE": "580504", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6071", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4996, "SUB_DISTRICT_CODE": "580505", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6072", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4997, "SUB_DISTRICT_CODE": "580506", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6073", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4998, "SUB_DISTRICT_CODE": "580507", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6074", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 4999, "SUB_DISTRICT_CODE": "580508", "PROVINCE_ID": "46", "DISTRICT_ID": "679", "SUB_DISTRICT_ID": "6075", "ZIPCODE": "58120" },
    { "ZIPCODE_ID": 5000, "SUB_DISTRICT_CODE": "580601", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6076", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5001, "SUB_DISTRICT_CODE": "580602", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6077", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5002, "SUB_DISTRICT_CODE": "580603", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6078", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5003, "SUB_DISTRICT_CODE": "580604", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6079", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5004, "SUB_DISTRICT_CODE": "580605", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6080", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5005, "SUB_DISTRICT_CODE": "580606", "PROVINCE_ID": "46", "DISTRICT_ID": "680", "SUB_DISTRICT_ID": "6081", "ZIPCODE": "58110" },
    { "ZIPCODE_ID": 5006, "SUB_DISTRICT_CODE": "580701", "PROVINCE_ID": "46", "DISTRICT_ID": "681", "SUB_DISTRICT_ID": "6082", "ZIPCODE": "58150" },
    { "ZIPCODE_ID": 5007, "SUB_DISTRICT_CODE": "580702", "PROVINCE_ID": "46", "DISTRICT_ID": "681", "SUB_DISTRICT_ID": "6083", "ZIPCODE": "58150" },
    { "ZIPCODE_ID": 5008, "SUB_DISTRICT_CODE": "580703", "PROVINCE_ID": "46", "DISTRICT_ID": "681", "SUB_DISTRICT_ID": "6084", "ZIPCODE": "58150" },
    { "ZIPCODE_ID": 5009, "SUB_DISTRICT_CODE": "580704", "PROVINCE_ID": "46", "DISTRICT_ID": "681", "SUB_DISTRICT_ID": "6085", "ZIPCODE": "58150" },
    { "ZIPCODE_ID": 5010, "SUB_DISTRICT_CODE": "600101", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6086", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5011, "SUB_DISTRICT_CODE": "600102", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6087", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5012, "SUB_DISTRICT_CODE": "600103", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6088", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5013, "SUB_DISTRICT_CODE": "600104", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6089", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5014, "SUB_DISTRICT_CODE": "600105", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6090", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5015, "SUB_DISTRICT_CODE": "600106", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6091", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5016, "SUB_DISTRICT_CODE": "600107", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6092", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5017, "SUB_DISTRICT_CODE": "600108", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6093", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5018, "SUB_DISTRICT_CODE": "600109", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6094", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5019, "SUB_DISTRICT_CODE": "600110", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6095", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5020, "SUB_DISTRICT_CODE": "600111", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6096", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5021, "SUB_DISTRICT_CODE": "600112", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6097", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5022, "SUB_DISTRICT_CODE": "600113", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6098", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5023, "SUB_DISTRICT_CODE": "600114", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6099", "ZIPCODE": "60240" },
    { "ZIPCODE_ID": 5024, "SUB_DISTRICT_CODE": "600115", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6100", "ZIPCODE": "60240" },
    { "ZIPCODE_ID": 5025, "SUB_DISTRICT_CODE": "600116", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6101", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5026, "SUB_DISTRICT_CODE": "600117", "PROVINCE_ID": "47", "DISTRICT_ID": "683", "SUB_DISTRICT_ID": "6102", "ZIPCODE": "60000" },
    { "ZIPCODE_ID": 5027, "SUB_DISTRICT_CODE": "600201", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6103", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5028, "SUB_DISTRICT_CODE": "600202", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6104", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5029, "SUB_DISTRICT_CODE": "600203", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6105", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5030, "SUB_DISTRICT_CODE": "600204", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6106", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5031, "SUB_DISTRICT_CODE": "600205", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6107", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5032, "SUB_DISTRICT_CODE": "600206", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6108", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5033, "SUB_DISTRICT_CODE": "600207", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6109", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5034, "SUB_DISTRICT_CODE": "600208", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6110", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5035, "SUB_DISTRICT_CODE": "600209", "PROVINCE_ID": "47", "DISTRICT_ID": "684", "SUB_DISTRICT_ID": "6111", "ZIPCODE": "60170" },
    { "ZIPCODE_ID": 5036, "SUB_DISTRICT_CODE": "600301", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6112", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5037, "SUB_DISTRICT_CODE": "600302", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6113", "ZIPCODE": "60250" },
    { "ZIPCODE_ID": 5038, "SUB_DISTRICT_CODE": "600303", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6114", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5039, "SUB_DISTRICT_CODE": "600304", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6115", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5040, "SUB_DISTRICT_CODE": "600305", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6116", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5041, "SUB_DISTRICT_CODE": "600306", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6117", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5042, "SUB_DISTRICT_CODE": "600307", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6118", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5043, "SUB_DISTRICT_CODE": "600308", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6119", "ZIPCODE": "60250" },
    { "ZIPCODE_ID": 5044, "SUB_DISTRICT_CODE": "600309", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6120", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5045, "SUB_DISTRICT_CODE": "600310", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6121", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5046, "SUB_DISTRICT_CODE": "600311", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6122", "ZIPCODE": "60120" },
    { "ZIPCODE_ID": 5047, "SUB_DISTRICT_CODE": "600312", "PROVINCE_ID": "47", "DISTRICT_ID": "685", "SUB_DISTRICT_ID": "6123", "ZIPCODE": "60250" },
    { "ZIPCODE_ID": 5048, "SUB_DISTRICT_CODE": "600401", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6124", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5049, "SUB_DISTRICT_CODE": "600402", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6125", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5050, "SUB_DISTRICT_CODE": "600403", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6126", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5051, "SUB_DISTRICT_CODE": "600404", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6127", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5052, "SUB_DISTRICT_CODE": "600405", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6128", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5053, "SUB_DISTRICT_CODE": "600406", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6129", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5054, "SUB_DISTRICT_CODE": "600407", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6130", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5055, "SUB_DISTRICT_CODE": "600408", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6131", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5056, "SUB_DISTRICT_CODE": "600409", "PROVINCE_ID": "47", "DISTRICT_ID": "686", "SUB_DISTRICT_ID": "6132", "ZIPCODE": "60110" },
    { "ZIPCODE_ID": 5057, "SUB_DISTRICT_CODE": "600501", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6133", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5058, "SUB_DISTRICT_CODE": "600502", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6134", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5059, "SUB_DISTRICT_CODE": "600503", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6135", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5060, "SUB_DISTRICT_CODE": "600504", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6136", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5061, "SUB_DISTRICT_CODE": "600505", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6137", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5062, "SUB_DISTRICT_CODE": "600506", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6138", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5063, "SUB_DISTRICT_CODE": "600507", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6139", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5064, "SUB_DISTRICT_CODE": "600508", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6140", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5065, "SUB_DISTRICT_CODE": "600509", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6141", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5066, "SUB_DISTRICT_CODE": "600510", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6142", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5067, "SUB_DISTRICT_CODE": "600511", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6143", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5068, "SUB_DISTRICT_CODE": "600512", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6144", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5069, "SUB_DISTRICT_CODE": "600513", "PROVINCE_ID": "47", "DISTRICT_ID": "687", "SUB_DISTRICT_ID": "6145", "ZIPCODE": "60180" },
    { "ZIPCODE_ID": 5070, "SUB_DISTRICT_CODE": "600601", "PROVINCE_ID": "47", "DISTRICT_ID": "688", "SUB_DISTRICT_ID": "6146", "ZIPCODE": "60230" },
    { "ZIPCODE_ID": 5071, "SUB_DISTRICT_CODE": "600602", "PROVINCE_ID": "47", "DISTRICT_ID": "688", "SUB_DISTRICT_ID": "6147", "ZIPCODE": "60230" },
    { "ZIPCODE_ID": 5072, "SUB_DISTRICT_CODE": "600603", "PROVINCE_ID": "47", "DISTRICT_ID": "688", "SUB_DISTRICT_ID": "6148", "ZIPCODE": "60230" },
    { "ZIPCODE_ID": 5073, "SUB_DISTRICT_CODE": "600604", "PROVINCE_ID": "47", "DISTRICT_ID": "688", "SUB_DISTRICT_ID": "6149", "ZIPCODE": "60230" },
    { "ZIPCODE_ID": 5074, "SUB_DISTRICT_CODE": "600605", "PROVINCE_ID": "47", "DISTRICT_ID": "688", "SUB_DISTRICT_ID": "6150", "ZIPCODE": "60230" },
    { "ZIPCODE_ID": 5075, "SUB_DISTRICT_CODE": "600701", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6151", "ZIPCODE": "60140" },
    { "ZIPCODE_ID": 5076, "SUB_DISTRICT_CODE": "600702", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6152", "ZIPCODE": "60210" },
    { "ZIPCODE_ID": 5077, "SUB_DISTRICT_CODE": "600703", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6153", "ZIPCODE": "60260" },
    { "ZIPCODE_ID": 5078, "SUB_DISTRICT_CODE": "600704", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6154", "ZIPCODE": "60210" },
    { "ZIPCODE_ID": 5079, "SUB_DISTRICT_CODE": "600705", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6155", "ZIPCODE": "60140" },
    { "ZIPCODE_ID": 5080, "SUB_DISTRICT_CODE": "600706", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6156", "ZIPCODE": "60140" },
    { "ZIPCODE_ID": 5081, "SUB_DISTRICT_CODE": "600707", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6157", "ZIPCODE": "60140" },
    { "ZIPCODE_ID": 5082, "SUB_DISTRICT_CODE": "600708", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6158", "ZIPCODE": "60210" },
    { "ZIPCODE_ID": 5083, "SUB_DISTRICT_CODE": "600709", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6159", "ZIPCODE": "60260" },
    { "ZIPCODE_ID": 5084, "SUB_DISTRICT_CODE": "600710", "PROVINCE_ID": "47", "DISTRICT_ID": "689", "SUB_DISTRICT_ID": "6160", "ZIPCODE": "60210" },
    { "ZIPCODE_ID": 5085, "SUB_DISTRICT_CODE": "600801", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6161", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5086, "SUB_DISTRICT_CODE": "600802", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6162", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5087, "SUB_DISTRICT_CODE": "600803", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6163", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5088, "SUB_DISTRICT_CODE": "600804", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6164", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5089, "SUB_DISTRICT_CODE": "600805", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6165", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5090, "SUB_DISTRICT_CODE": "600806", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6166", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5091, "SUB_DISTRICT_CODE": "600807", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6167", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5092, "SUB_DISTRICT_CODE": "600808", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6168", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5093, "SUB_DISTRICT_CODE": "600809", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6169", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5094, "SUB_DISTRICT_CODE": "600810", "PROVINCE_ID": "47", "DISTRICT_ID": "690", "SUB_DISTRICT_ID": "6170", "ZIPCODE": "60160" },
    { "ZIPCODE_ID": 5095, "SUB_DISTRICT_CODE": "600901", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6171", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5096, "SUB_DISTRICT_CODE": "600902", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6172", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5097, "SUB_DISTRICT_CODE": "600903", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6173", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5098, "SUB_DISTRICT_CODE": "600904", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6174", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5099, "SUB_DISTRICT_CODE": "600905", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6175", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5100, "SUB_DISTRICT_CODE": "600906", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6176", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5101, "SUB_DISTRICT_CODE": "600907", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6177", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5102, "SUB_DISTRICT_CODE": "600908", "PROVINCE_ID": "47", "DISTRICT_ID": "691", "SUB_DISTRICT_ID": "6178", "ZIPCODE": "60220" },
    { "ZIPCODE_ID": 5103, "SUB_DISTRICT_CODE": "601001", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6179", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5104, "SUB_DISTRICT_CODE": "601002", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6180", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5105, "SUB_DISTRICT_CODE": "601003", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6181", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5106, "SUB_DISTRICT_CODE": "601004", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6182", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5107, "SUB_DISTRICT_CODE": "601005", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6183", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5108, "SUB_DISTRICT_CODE": "601006", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6184", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5109, "SUB_DISTRICT_CODE": "601007", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6185", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5110, "SUB_DISTRICT_CODE": "601008", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6186", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5111, "SUB_DISTRICT_CODE": "601009", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6187", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5112, "SUB_DISTRICT_CODE": "601010", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6188", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5113, "SUB_DISTRICT_CODE": "601011", "PROVINCE_ID": "47", "DISTRICT_ID": "692", "SUB_DISTRICT_ID": "6189", "ZIPCODE": "60130" },
    { "ZIPCODE_ID": 5114, "SUB_DISTRICT_CODE": "601101", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6190", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5115, "SUB_DISTRICT_CODE": "601102", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6191", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5116, "SUB_DISTRICT_CODE": "601103", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6192", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5117, "SUB_DISTRICT_CODE": "601104", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6193", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5118, "SUB_DISTRICT_CODE": "601105", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6194", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5119, "SUB_DISTRICT_CODE": "601106", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6195", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5120, "SUB_DISTRICT_CODE": "601107", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6196", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5121, "SUB_DISTRICT_CODE": "601108", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6197", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5122, "SUB_DISTRICT_CODE": "601109", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6198", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5123, "SUB_DISTRICT_CODE": "601110", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6199", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5124, "SUB_DISTRICT_CODE": "601116", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6205", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5125, "SUB_DISTRICT_CODE": "601117", "PROVINCE_ID": "47", "DISTRICT_ID": "693", "SUB_DISTRICT_ID": "6206", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5126, "SUB_DISTRICT_CODE": "601201", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6207", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5127, "SUB_DISTRICT_CODE": "601202", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6208", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5128, "SUB_DISTRICT_CODE": "601203", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6209", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5129, "SUB_DISTRICT_CODE": "601204", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6210", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5130, "SUB_DISTRICT_CODE": "601205", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6211", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5131, "SUB_DISTRICT_CODE": "601206", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6212", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5132, "SUB_DISTRICT_CODE": "601207", "PROVINCE_ID": "47", "DISTRICT_ID": "694", "SUB_DISTRICT_ID": "6213", "ZIPCODE": "60190" },
    { "ZIPCODE_ID": 5133, "SUB_DISTRICT_CODE": "601301", "PROVINCE_ID": "47", "DISTRICT_ID": "695", "SUB_DISTRICT_ID": "6214", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5134, "SUB_DISTRICT_CODE": "601303", "PROVINCE_ID": "47", "DISTRICT_ID": "695", "SUB_DISTRICT_ID": "6216", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5135, "SUB_DISTRICT_CODE": "601304", "PROVINCE_ID": "47", "DISTRICT_ID": "695", "SUB_DISTRICT_ID": "6217", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5136, "SUB_DISTRICT_CODE": "601305", "PROVINCE_ID": "47", "DISTRICT_ID": "695", "SUB_DISTRICT_ID": "6218", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5137, "SUB_DISTRICT_CODE": "601401", "PROVINCE_ID": "47", "DISTRICT_ID": "696", "SUB_DISTRICT_ID": "6222", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5138, "SUB_DISTRICT_CODE": "601501", "PROVINCE_ID": "47", "DISTRICT_ID": "697", "SUB_DISTRICT_ID": "6223", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5139, "SUB_DISTRICT_CODE": "601502", "PROVINCE_ID": "47", "DISTRICT_ID": "697", "SUB_DISTRICT_ID": "6224", "ZIPCODE": "60150" },
    { "ZIPCODE_ID": 5140, "SUB_DISTRICT_CODE": "610101", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6225", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5141, "SUB_DISTRICT_CODE": "610102", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6226", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5142, "SUB_DISTRICT_CODE": "610103", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6227", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5143, "SUB_DISTRICT_CODE": "610104", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6228", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5144, "SUB_DISTRICT_CODE": "610105", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6229", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5145, "SUB_DISTRICT_CODE": "610106", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6230", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5146, "SUB_DISTRICT_CODE": "610107", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6231", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5147, "SUB_DISTRICT_CODE": "610108", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6232", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5148, "SUB_DISTRICT_CODE": "610109", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6233", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5149, "SUB_DISTRICT_CODE": "610110", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6234", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5150, "SUB_DISTRICT_CODE": "610111", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6235", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5151, "SUB_DISTRICT_CODE": "610112", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6236", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5152, "SUB_DISTRICT_CODE": "610113", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6237", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5153, "SUB_DISTRICT_CODE": "610114", "PROVINCE_ID": "48", "DISTRICT_ID": "701", "SUB_DISTRICT_ID": "6238", "ZIPCODE": "61000" },
    { "ZIPCODE_ID": 5154, "SUB_DISTRICT_CODE": "610201", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6240", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5155, "SUB_DISTRICT_CODE": "610202", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6241", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5156, "SUB_DISTRICT_CODE": "610203", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6242", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5157, "SUB_DISTRICT_CODE": "610204", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6243", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5158, "SUB_DISTRICT_CODE": "610205", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6244", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5159, "SUB_DISTRICT_CODE": "610206", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6245", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5160, "SUB_DISTRICT_CODE": "610207", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6246", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5161, "SUB_DISTRICT_CODE": "610208", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6247", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5162, "SUB_DISTRICT_CODE": "610209", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6248", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5163, "SUB_DISTRICT_CODE": "610210", "PROVINCE_ID": "48", "DISTRICT_ID": "702", "SUB_DISTRICT_ID": "6249", "ZIPCODE": "61120" },
    { "ZIPCODE_ID": 5164, "SUB_DISTRICT_CODE": "610301", "PROVINCE_ID": "48", "DISTRICT_ID": "703", "SUB_DISTRICT_ID": "6250", "ZIPCODE": "61150" },
    { "ZIPCODE_ID": 5165, "SUB_DISTRICT_CODE": "610302", "PROVINCE_ID": "48", "DISTRICT_ID": "703", "SUB_DISTRICT_ID": "6251", "ZIPCODE": "61150" },
    { "ZIPCODE_ID": 5166, "SUB_DISTRICT_CODE": "610303", "PROVINCE_ID": "48", "DISTRICT_ID": "703", "SUB_DISTRICT_ID": "6252", "ZIPCODE": "61150" },
    { "ZIPCODE_ID": 5167, "SUB_DISTRICT_CODE": "610304", "PROVINCE_ID": "48", "DISTRICT_ID": "703", "SUB_DISTRICT_ID": "6253", "ZIPCODE": "61150" },
    { "ZIPCODE_ID": 5168, "SUB_DISTRICT_CODE": "610305", "PROVINCE_ID": "48", "DISTRICT_ID": "703", "SUB_DISTRICT_ID": "6254", "ZIPCODE": "61150" },
    { "ZIPCODE_ID": 5169, "SUB_DISTRICT_CODE": "610401", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6255", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5170, "SUB_DISTRICT_CODE": "610402", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6256", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5171, "SUB_DISTRICT_CODE": "610403", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6257", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5172, "SUB_DISTRICT_CODE": "610404", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6258", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5173, "SUB_DISTRICT_CODE": "610405", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6259", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5174, "SUB_DISTRICT_CODE": "610406", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6260", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5175, "SUB_DISTRICT_CODE": "610407", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6261", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5176, "SUB_DISTRICT_CODE": "610408", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6262", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5177, "SUB_DISTRICT_CODE": "610409", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6263", "ZIPCODE": "61170" },
    { "ZIPCODE_ID": 5178, "SUB_DISTRICT_CODE": "610410", "PROVINCE_ID": "48", "DISTRICT_ID": "704", "SUB_DISTRICT_ID": "6264", "ZIPCODE": "61110" },
    { "ZIPCODE_ID": 5179, "SUB_DISTRICT_CODE": "610501", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6265", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5180, "SUB_DISTRICT_CODE": "610502", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6266", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5181, "SUB_DISTRICT_CODE": "610503", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6267", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5182, "SUB_DISTRICT_CODE": "610504", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6268", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5183, "SUB_DISTRICT_CODE": "610505", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6269", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5184, "SUB_DISTRICT_CODE": "610506", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6270", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5185, "SUB_DISTRICT_CODE": "610507", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6271", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5186, "SUB_DISTRICT_CODE": "610508", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6272", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5187, "SUB_DISTRICT_CODE": "610509", "PROVINCE_ID": "48", "DISTRICT_ID": "705", "SUB_DISTRICT_ID": "6273", "ZIPCODE": "61130" },
    { "ZIPCODE_ID": 5188, "SUB_DISTRICT_CODE": "610601", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6274", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5189, "SUB_DISTRICT_CODE": "610602", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6275", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5190, "SUB_DISTRICT_CODE": "610603", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6276", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5191, "SUB_DISTRICT_CODE": "610604", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6277", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5192, "SUB_DISTRICT_CODE": "610605", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6278", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5193, "SUB_DISTRICT_CODE": "610606", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6279", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5194, "SUB_DISTRICT_CODE": "610607", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6280", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5195, "SUB_DISTRICT_CODE": "610609", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6281", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5196, "SUB_DISTRICT_CODE": "610610", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6282", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5197, "SUB_DISTRICT_CODE": "610611", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6283", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5198, "SUB_DISTRICT_CODE": "610612", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6284", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5199, "SUB_DISTRICT_CODE": "610613", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6285", "ZIPCODE": "61180" },
    { "ZIPCODE_ID": 5200, "SUB_DISTRICT_CODE": "610614", "PROVINCE_ID": "48", "DISTRICT_ID": "706", "SUB_DISTRICT_ID": "6286", "ZIPCODE": "61140" },
    { "ZIPCODE_ID": 5201, "SUB_DISTRICT_CODE": "610701", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6292", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5202, "SUB_DISTRICT_CODE": "610702", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6293", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5203, "SUB_DISTRICT_CODE": "610703", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6294", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5204, "SUB_DISTRICT_CODE": "610704", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6295", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5205, "SUB_DISTRICT_CODE": "610705", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6296", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5206, "SUB_DISTRICT_CODE": "610706", "PROVINCE_ID": "48", "DISTRICT_ID": "707", "SUB_DISTRICT_ID": "6297", "ZIPCODE": "61160" },
    { "ZIPCODE_ID": 5207, "SUB_DISTRICT_CODE": "610801", "PROVINCE_ID": "48", "DISTRICT_ID": "708", "SUB_DISTRICT_ID": "6298", "ZIPCODE": "61170" },
    { "ZIPCODE_ID": 5208, "SUB_DISTRICT_CODE": "610802", "PROVINCE_ID": "48", "DISTRICT_ID": "708", "SUB_DISTRICT_ID": "6299", "ZIPCODE": "61170" },
    { "ZIPCODE_ID": 5209, "SUB_DISTRICT_CODE": "610803", "PROVINCE_ID": "48", "DISTRICT_ID": "708", "SUB_DISTRICT_ID": "6300", "ZIPCODE": "61170" },
    { "ZIPCODE_ID": 5210, "SUB_DISTRICT_CODE": "620101", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6301", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5211, "SUB_DISTRICT_CODE": "620102", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6302", "ZIPCODE": "62160" },
    { "ZIPCODE_ID": 5212, "SUB_DISTRICT_CODE": "620103", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6303", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5213, "SUB_DISTRICT_CODE": "620104", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6304", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5214, "SUB_DISTRICT_CODE": "620105", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6305", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5215, "SUB_DISTRICT_CODE": "620106", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6306", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5216, "SUB_DISTRICT_CODE": "620107", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6307", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5217, "SUB_DISTRICT_CODE": "620110", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6310", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5218, "SUB_DISTRICT_CODE": "620111", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6311", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5219, "SUB_DISTRICT_CODE": "620112", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6312", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5220, "SUB_DISTRICT_CODE": "620113", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6313", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5221, "SUB_DISTRICT_CODE": "620114", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6314", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5222, "SUB_DISTRICT_CODE": "620115", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6315", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5223, "SUB_DISTRICT_CODE": "620117", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6317", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5224, "SUB_DISTRICT_CODE": "620118", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6318", "ZIPCODE": "62160" },
    { "ZIPCODE_ID": 5225, "SUB_DISTRICT_CODE": "620119", "PROVINCE_ID": "49", "DISTRICT_ID": "709", "SUB_DISTRICT_ID": "6319", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5226, "SUB_DISTRICT_CODE": "620201", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6323", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5227, "SUB_DISTRICT_CODE": "620202", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6324", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5228, "SUB_DISTRICT_CODE": "620203", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6325", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5229, "SUB_DISTRICT_CODE": "620204", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6326", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5230, "SUB_DISTRICT_CODE": "620205", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6327", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5231, "SUB_DISTRICT_CODE": "620206", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6328", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5232, "SUB_DISTRICT_CODE": "620207", "PROVINCE_ID": "49", "DISTRICT_ID": "710", "SUB_DISTRICT_ID": "6329", "ZIPCODE": "62150" },
    { "ZIPCODE_ID": 5233, "SUB_DISTRICT_CODE": "620301", "PROVINCE_ID": "49", "DISTRICT_ID": "711", "SUB_DISTRICT_ID": "6330", "ZIPCODE": "62180" },
    { "ZIPCODE_ID": 5234, "SUB_DISTRICT_CODE": "620302", "PROVINCE_ID": "49", "DISTRICT_ID": "711", "SUB_DISTRICT_ID": "6331", "ZIPCODE": "62180" },
    { "ZIPCODE_ID": 5235, "SUB_DISTRICT_CODE": "620303", "PROVINCE_ID": "49", "DISTRICT_ID": "711", "SUB_DISTRICT_ID": "6332", "ZIPCODE": "62180" },
    { "ZIPCODE_ID": 5236, "SUB_DISTRICT_CODE": "620304", "PROVINCE_ID": "49", "DISTRICT_ID": "711", "SUB_DISTRICT_ID": "6333", "ZIPCODE": "62180" },
    { "ZIPCODE_ID": 5237, "SUB_DISTRICT_CODE": "620403", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6336", "ZIPCODE": "62130" },
    { "ZIPCODE_ID": 5238, "SUB_DISTRICT_CODE": "620404", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6337", "ZIPCODE": "62130" },
    { "ZIPCODE_ID": 5239, "SUB_DISTRICT_CODE": "620405", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6338", "ZIPCODE": "62130" },
    { "ZIPCODE_ID": 5240, "SUB_DISTRICT_CODE": "620406", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6339", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5241, "SUB_DISTRICT_CODE": "620407", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6340", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5242, "SUB_DISTRICT_CODE": "620408", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6341", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5243, "SUB_DISTRICT_CODE": "620409", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6342", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5244, "SUB_DISTRICT_CODE": "620410", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6343", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5245, "SUB_DISTRICT_CODE": "620411", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6344", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5246, "SUB_DISTRICT_CODE": "620412", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6345", "ZIPCODE": "62140" },
    { "ZIPCODE_ID": 5247, "SUB_DISTRICT_CODE": "620413", "PROVINCE_ID": "49", "DISTRICT_ID": "712", "SUB_DISTRICT_ID": "6346", "ZIPCODE": "62130" },
    { "ZIPCODE_ID": 5248, "SUB_DISTRICT_CODE": "620501", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6348", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5249, "SUB_DISTRICT_CODE": "620502", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6349", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5250, "SUB_DISTRICT_CODE": "620504", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6351", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5251, "SUB_DISTRICT_CODE": "620505", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6352", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5252, "SUB_DISTRICT_CODE": "620506", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6353", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5253, "SUB_DISTRICT_CODE": "620507", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6354", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5254, "SUB_DISTRICT_CODE": "620508", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6355", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5255, "SUB_DISTRICT_CODE": "620509", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6356", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5256, "SUB_DISTRICT_CODE": "620513", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6360", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5257, "SUB_DISTRICT_CODE": "620516", "PROVINCE_ID": "49", "DISTRICT_ID": "713", "SUB_DISTRICT_ID": "6363", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5258, "SUB_DISTRICT_CODE": "620601", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6365", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5259, "SUB_DISTRICT_CODE": "620602", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6366", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5260, "SUB_DISTRICT_CODE": "620603", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6367", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5261, "SUB_DISTRICT_CODE": "620604", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6368", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5262, "SUB_DISTRICT_CODE": "620605", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6369", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5263, "SUB_DISTRICT_CODE": "620606", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6370", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5264, "SUB_DISTRICT_CODE": "620607", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6371", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5265, "SUB_DISTRICT_CODE": "620608", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6372", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5266, "SUB_DISTRICT_CODE": "620609", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6373", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5267, "SUB_DISTRICT_CODE": "620610", "PROVINCE_ID": "49", "DISTRICT_ID": "714", "SUB_DISTRICT_ID": "6374", "ZIPCODE": "62110" },
    { "ZIPCODE_ID": 5268, "SUB_DISTRICT_CODE": "620701", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6375", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5269, "SUB_DISTRICT_CODE": "620702", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6376", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5270, "SUB_DISTRICT_CODE": "620703", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6377", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5271, "SUB_DISTRICT_CODE": "620704", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6378", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5272, "SUB_DISTRICT_CODE": "620705", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6379", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5273, "SUB_DISTRICT_CODE": "620706", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6380", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5274, "SUB_DISTRICT_CODE": "620707", "PROVINCE_ID": "49", "DISTRICT_ID": "715", "SUB_DISTRICT_ID": "6381", "ZIPCODE": "62170" },
    { "ZIPCODE_ID": 5275, "SUB_DISTRICT_CODE": "620801", "PROVINCE_ID": "49", "DISTRICT_ID": "716", "SUB_DISTRICT_ID": "6382", "ZIPCODE": "62190" },
    { "ZIPCODE_ID": 5276, "SUB_DISTRICT_CODE": "620802", "PROVINCE_ID": "49", "DISTRICT_ID": "716", "SUB_DISTRICT_ID": "6383", "ZIPCODE": "62190" },
    { "ZIPCODE_ID": 5277, "SUB_DISTRICT_CODE": "620803", "PROVINCE_ID": "49", "DISTRICT_ID": "716", "SUB_DISTRICT_ID": "6384", "ZIPCODE": "62190" },
    { "ZIPCODE_ID": 5278, "SUB_DISTRICT_CODE": "620901", "PROVINCE_ID": "49", "DISTRICT_ID": "717", "SUB_DISTRICT_ID": "6385", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5279, "SUB_DISTRICT_CODE": "620902", "PROVINCE_ID": "49", "DISTRICT_ID": "717", "SUB_DISTRICT_ID": "6386", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5280, "SUB_DISTRICT_CODE": "620903", "PROVINCE_ID": "49", "DISTRICT_ID": "717", "SUB_DISTRICT_ID": "6387", "ZIPCODE": "62120" },
    { "ZIPCODE_ID": 5281, "SUB_DISTRICT_CODE": "621001", "PROVINCE_ID": "49", "DISTRICT_ID": "718", "SUB_DISTRICT_ID": "6388", "ZIPCODE": "62210" },
    { "ZIPCODE_ID": 5282, "SUB_DISTRICT_CODE": "621002", "PROVINCE_ID": "49", "DISTRICT_ID": "718", "SUB_DISTRICT_ID": "6389", "ZIPCODE": "62210" },
    { "ZIPCODE_ID": 5283, "SUB_DISTRICT_CODE": "621003", "PROVINCE_ID": "49", "DISTRICT_ID": "718", "SUB_DISTRICT_ID": "6390", "ZIPCODE": "62210" },
    { "ZIPCODE_ID": 5284, "SUB_DISTRICT_CODE": "621004", "PROVINCE_ID": "49", "DISTRICT_ID": "718", "SUB_DISTRICT_ID": "6391", "ZIPCODE": "62210" },
    { "ZIPCODE_ID": 5285, "SUB_DISTRICT_CODE": "621101", "PROVINCE_ID": "49", "DISTRICT_ID": "719", "SUB_DISTRICT_ID": "6392", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5286, "SUB_DISTRICT_CODE": "621102", "PROVINCE_ID": "49", "DISTRICT_ID": "719", "SUB_DISTRICT_ID": "6393", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5287, "SUB_DISTRICT_CODE": "621103", "PROVINCE_ID": "49", "DISTRICT_ID": "719", "SUB_DISTRICT_ID": "6394", "ZIPCODE": "62000" },
    { "ZIPCODE_ID": 5288, "SUB_DISTRICT_CODE": "630101", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6395", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5289, "SUB_DISTRICT_CODE": "630102", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6396", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5290, "SUB_DISTRICT_CODE": "630103", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6397", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5291, "SUB_DISTRICT_CODE": "630104", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6398", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5292, "SUB_DISTRICT_CODE": "630105", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6399", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5293, "SUB_DISTRICT_CODE": "630106", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6400", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5294, "SUB_DISTRICT_CODE": "630107", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6401", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5295, "SUB_DISTRICT_CODE": "630108", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6402", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5296, "SUB_DISTRICT_CODE": "630109", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6403", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5297, "SUB_DISTRICT_CODE": "630111", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6405", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5298, "SUB_DISTRICT_CODE": "630112", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6406", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5299, "SUB_DISTRICT_CODE": "630113", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6407", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5300, "SUB_DISTRICT_CODE": "630114", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6408", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5301, "SUB_DISTRICT_CODE": "630115", "PROVINCE_ID": "50", "DISTRICT_ID": "720", "SUB_DISTRICT_ID": "6409", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5302, "SUB_DISTRICT_CODE": "630201", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6412", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5303, "SUB_DISTRICT_CODE": "630202", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6413", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5304, "SUB_DISTRICT_CODE": "630203", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6414", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5305, "SUB_DISTRICT_CODE": "630204", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6415", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5306, "SUB_DISTRICT_CODE": "630205", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6416", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5307, "SUB_DISTRICT_CODE": "630206", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6417", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5308, "SUB_DISTRICT_CODE": "630207", "PROVINCE_ID": "50", "DISTRICT_ID": "721", "SUB_DISTRICT_ID": "6418", "ZIPCODE": "63120" },
    { "ZIPCODE_ID": 5309, "SUB_DISTRICT_CODE": "630301", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6419", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5310, "SUB_DISTRICT_CODE": "630302", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6420", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5311, "SUB_DISTRICT_CODE": "630303", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6421", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5312, "SUB_DISTRICT_CODE": "630304", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6422", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5313, "SUB_DISTRICT_CODE": "630305", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6423", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5314, "SUB_DISTRICT_CODE": "630306", "PROVINCE_ID": "50", "DISTRICT_ID": "722", "SUB_DISTRICT_ID": "6424", "ZIPCODE": "63130" },
    { "ZIPCODE_ID": 5315, "SUB_DISTRICT_CODE": "630401", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6425", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5316, "SUB_DISTRICT_CODE": "630402", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6426", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5317, "SUB_DISTRICT_CODE": "630403", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6427", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5318, "SUB_DISTRICT_CODE": "630404", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6428", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5319, "SUB_DISTRICT_CODE": "630405", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6429", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5320, "SUB_DISTRICT_CODE": "630406", "PROVINCE_ID": "50", "DISTRICT_ID": "723", "SUB_DISTRICT_ID": "6430", "ZIPCODE": "63140" },
    { "ZIPCODE_ID": 5321, "SUB_DISTRICT_CODE": "630501", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6431", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5322, "SUB_DISTRICT_CODE": "630502", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6432", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5323, "SUB_DISTRICT_CODE": "630503", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6433", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5324, "SUB_DISTRICT_CODE": "630504", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6434", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5325, "SUB_DISTRICT_CODE": "630505", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6435", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5326, "SUB_DISTRICT_CODE": "630506", "PROVINCE_ID": "50", "DISTRICT_ID": "724", "SUB_DISTRICT_ID": "6436", "ZIPCODE": "63150" },
    { "ZIPCODE_ID": 5327, "SUB_DISTRICT_CODE": "630601", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6437", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5328, "SUB_DISTRICT_CODE": "630602", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6438", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5329, "SUB_DISTRICT_CODE": "630603", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6439", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5330, "SUB_DISTRICT_CODE": "630604", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6440", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5331, "SUB_DISTRICT_CODE": "630605", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6441", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5332, "SUB_DISTRICT_CODE": "630606", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6442", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5333, "SUB_DISTRICT_CODE": "630607", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6443", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5334, "SUB_DISTRICT_CODE": "630608", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6444", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5335, "SUB_DISTRICT_CODE": "630609", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6445", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5336, "SUB_DISTRICT_CODE": "630610", "PROVINCE_ID": "50", "DISTRICT_ID": "725", "SUB_DISTRICT_ID": "6446", "ZIPCODE": "63110" },
    { "ZIPCODE_ID": 5337, "SUB_DISTRICT_CODE": "630701", "PROVINCE_ID": "50", "DISTRICT_ID": "726", "SUB_DISTRICT_ID": "6447", "ZIPCODE": "63160" },
    { "ZIPCODE_ID": 5338, "SUB_DISTRICT_CODE": "630702", "PROVINCE_ID": "50", "DISTRICT_ID": "726", "SUB_DISTRICT_ID": "6448", "ZIPCODE": "63160" },
    { "ZIPCODE_ID": 5339, "SUB_DISTRICT_CODE": "630703", "PROVINCE_ID": "50", "DISTRICT_ID": "726", "SUB_DISTRICT_ID": "6449", "ZIPCODE": "63160" },
    { "ZIPCODE_ID": 5340, "SUB_DISTRICT_CODE": "630704", "PROVINCE_ID": "50", "DISTRICT_ID": "726", "SUB_DISTRICT_ID": "6450", "ZIPCODE": "63160" },
    { "ZIPCODE_ID": 5341, "SUB_DISTRICT_CODE": "630705", "PROVINCE_ID": "50", "DISTRICT_ID": "726", "SUB_DISTRICT_ID": "6451", "ZIPCODE": "63160" },
    { "ZIPCODE_ID": 5342, "SUB_DISTRICT_CODE": "630801", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6452", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5343, "SUB_DISTRICT_CODE": "630802", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6453", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5344, "SUB_DISTRICT_CODE": "630803", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6454", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5345, "SUB_DISTRICT_CODE": "630804", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6455", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5346, "SUB_DISTRICT_CODE": "630805", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6456", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5347, "SUB_DISTRICT_CODE": "630806", "PROVINCE_ID": "50", "DISTRICT_ID": "727", "SUB_DISTRICT_ID": "6457", "ZIPCODE": "63170" },
    { "ZIPCODE_ID": 5348, "SUB_DISTRICT_CODE": "630901", "PROVINCE_ID": "50", "DISTRICT_ID": "728", "SUB_DISTRICT_ID": "6458", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5349, "SUB_DISTRICT_CODE": "630902", "PROVINCE_ID": "50", "DISTRICT_ID": "728", "SUB_DISTRICT_ID": "6459", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5350, "SUB_DISTRICT_CODE": "630903", "PROVINCE_ID": "50", "DISTRICT_ID": "728", "SUB_DISTRICT_ID": "6460", "ZIPCODE": "63000" },
    { "ZIPCODE_ID": 5351, "SUB_DISTRICT_CODE": "640101", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6461", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5352, "SUB_DISTRICT_CODE": "640102", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6462", "ZIPCODE": "64220" },
    { "ZIPCODE_ID": 5353, "SUB_DISTRICT_CODE": "640103", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6463", "ZIPCODE": "64210" },
    { "ZIPCODE_ID": 5354, "SUB_DISTRICT_CODE": "640104", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6464", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5355, "SUB_DISTRICT_CODE": "640105", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6465", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5356, "SUB_DISTRICT_CODE": "640106", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6466", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5357, "SUB_DISTRICT_CODE": "640107", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6467", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5358, "SUB_DISTRICT_CODE": "640108", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6468", "ZIPCODE": "64220" },
    { "ZIPCODE_ID": 5359, "SUB_DISTRICT_CODE": "640109", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6469", "ZIPCODE": "64000" },
    { "ZIPCODE_ID": 5360, "SUB_DISTRICT_CODE": "640110", "PROVINCE_ID": "51", "DISTRICT_ID": "730", "SUB_DISTRICT_ID": "6470", "ZIPCODE": "64210" },
    { "ZIPCODE_ID": 5361, "SUB_DISTRICT_CODE": "640201", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6471", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5362, "SUB_DISTRICT_CODE": "640202", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6472", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5363, "SUB_DISTRICT_CODE": "640203", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6473", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5364, "SUB_DISTRICT_CODE": "640204", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6474", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5365, "SUB_DISTRICT_CODE": "640205", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6475", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5366, "SUB_DISTRICT_CODE": "640206", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6476", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5367, "SUB_DISTRICT_CODE": "640207", "PROVINCE_ID": "51", "DISTRICT_ID": "731", "SUB_DISTRICT_ID": "6477", "ZIPCODE": "64140" },
    { "ZIPCODE_ID": 5368, "SUB_DISTRICT_CODE": "640301", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6478", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5369, "SUB_DISTRICT_CODE": "640302", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6479", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5370, "SUB_DISTRICT_CODE": "640303", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6480", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5371, "SUB_DISTRICT_CODE": "640304", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6481", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5372, "SUB_DISTRICT_CODE": "640305", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6482", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5373, "SUB_DISTRICT_CODE": "640306", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6483", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5374, "SUB_DISTRICT_CODE": "640307", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6484", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5375, "SUB_DISTRICT_CODE": "640308", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6485", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5376, "SUB_DISTRICT_CODE": "640309", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6486", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5377, "SUB_DISTRICT_CODE": "640310", "PROVINCE_ID": "51", "DISTRICT_ID": "732", "SUB_DISTRICT_ID": "6487", "ZIPCODE": "64160" },
    { "ZIPCODE_ID": 5378, "SUB_DISTRICT_CODE": "640401", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6488", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5379, "SUB_DISTRICT_CODE": "640402", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6489", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5380, "SUB_DISTRICT_CODE": "640403", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6490", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5381, "SUB_DISTRICT_CODE": "640404", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6491", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5382, "SUB_DISTRICT_CODE": "640405", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6492", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5383, "SUB_DISTRICT_CODE": "640406", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6493", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5384, "SUB_DISTRICT_CODE": "640407", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6494", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5385, "SUB_DISTRICT_CODE": "640408", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6495", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5386, "SUB_DISTRICT_CODE": "640409", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6496", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5387, "SUB_DISTRICT_CODE": "640410", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6497", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5388, "SUB_DISTRICT_CODE": "640411", "PROVINCE_ID": "51", "DISTRICT_ID": "733", "SUB_DISTRICT_ID": "6498", "ZIPCODE": "64170" },
    { "ZIPCODE_ID": 5389, "SUB_DISTRICT_CODE": "640501", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6499", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5390, "SUB_DISTRICT_CODE": "640502", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6500", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5391, "SUB_DISTRICT_CODE": "640503", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6501", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5392, "SUB_DISTRICT_CODE": "640504", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6502", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5393, "SUB_DISTRICT_CODE": "640505", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6503", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5394, "SUB_DISTRICT_CODE": "640506", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6504", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5395, "SUB_DISTRICT_CODE": "640507", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6505", "ZIPCODE": "64190" },
    { "ZIPCODE_ID": 5396, "SUB_DISTRICT_CODE": "640508", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6506", "ZIPCODE": "64190" },
    { "ZIPCODE_ID": 5397, "SUB_DISTRICT_CODE": "640509", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6507", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5398, "SUB_DISTRICT_CODE": "640510", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6508", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5399, "SUB_DISTRICT_CODE": "640511", "PROVINCE_ID": "51", "DISTRICT_ID": "734", "SUB_DISTRICT_ID": "6509", "ZIPCODE": "64130" },
    { "ZIPCODE_ID": 5400, "SUB_DISTRICT_CODE": "640601", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6510", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5401, "SUB_DISTRICT_CODE": "640602", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6511", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5402, "SUB_DISTRICT_CODE": "640603", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6512", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5403, "SUB_DISTRICT_CODE": "640604", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6513", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5404, "SUB_DISTRICT_CODE": "640605", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6514", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5405, "SUB_DISTRICT_CODE": "640606", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6515", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5406, "SUB_DISTRICT_CODE": "640607", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6516", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5407, "SUB_DISTRICT_CODE": "640608", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6517", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5408, "SUB_DISTRICT_CODE": "640609", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6518", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5409, "SUB_DISTRICT_CODE": "640610", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6519", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5410, "SUB_DISTRICT_CODE": "640611", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6520", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5411, "SUB_DISTRICT_CODE": "640612", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6521", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5412, "SUB_DISTRICT_CODE": "640613", "PROVINCE_ID": "51", "DISTRICT_ID": "735", "SUB_DISTRICT_ID": "6522", "ZIPCODE": "64120" },
    { "ZIPCODE_ID": 5413, "SUB_DISTRICT_CODE": "640701", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6523", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5414, "SUB_DISTRICT_CODE": "640702", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6524", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5415, "SUB_DISTRICT_CODE": "640703", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6525", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5416, "SUB_DISTRICT_CODE": "640704", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6526", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5417, "SUB_DISTRICT_CODE": "640705", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6527", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5418, "SUB_DISTRICT_CODE": "640706", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6528", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5419, "SUB_DISTRICT_CODE": "640707", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6529", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5420, "SUB_DISTRICT_CODE": "640708", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6530", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5421, "SUB_DISTRICT_CODE": "640709", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6531", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5422, "SUB_DISTRICT_CODE": "640710", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6532", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5423, "SUB_DISTRICT_CODE": "640711", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6533", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5424, "SUB_DISTRICT_CODE": "640712", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6534", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5425, "SUB_DISTRICT_CODE": "640713", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6535", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5426, "SUB_DISTRICT_CODE": "640714", "PROVINCE_ID": "51", "DISTRICT_ID": "736", "SUB_DISTRICT_ID": "6536", "ZIPCODE": "64110" },
    { "ZIPCODE_ID": 5427, "SUB_DISTRICT_CODE": "640801", "PROVINCE_ID": "51", "DISTRICT_ID": "737", "SUB_DISTRICT_ID": "6542", "ZIPCODE": "64180" },
    { "ZIPCODE_ID": 5428, "SUB_DISTRICT_CODE": "640802", "PROVINCE_ID": "51", "DISTRICT_ID": "737", "SUB_DISTRICT_ID": "6543", "ZIPCODE": "64180" },
    { "ZIPCODE_ID": 5429, "SUB_DISTRICT_CODE": "640803", "PROVINCE_ID": "51", "DISTRICT_ID": "737", "SUB_DISTRICT_ID": "6544", "ZIPCODE": "64180" },
    { "ZIPCODE_ID": 5430, "SUB_DISTRICT_CODE": "640804", "PROVINCE_ID": "51", "DISTRICT_ID": "737", "SUB_DISTRICT_ID": "6545", "ZIPCODE": "64180" },
    { "ZIPCODE_ID": 5431, "SUB_DISTRICT_CODE": "640805", "PROVINCE_ID": "51", "DISTRICT_ID": "737", "SUB_DISTRICT_ID": "6546", "ZIPCODE": "64180" },
    { "ZIPCODE_ID": 5432, "SUB_DISTRICT_CODE": "640901", "PROVINCE_ID": "51", "DISTRICT_ID": "738", "SUB_DISTRICT_ID": "6547", "ZIPCODE": "64230" },
    { "ZIPCODE_ID": 5433, "SUB_DISTRICT_CODE": "640902", "PROVINCE_ID": "51", "DISTRICT_ID": "738", "SUB_DISTRICT_ID": "6548", "ZIPCODE": "64150" },
    { "ZIPCODE_ID": 5434, "SUB_DISTRICT_CODE": "640903", "PROVINCE_ID": "51", "DISTRICT_ID": "738", "SUB_DISTRICT_ID": "6549", "ZIPCODE": "64150" },
    { "ZIPCODE_ID": 5435, "SUB_DISTRICT_CODE": "640904", "PROVINCE_ID": "51", "DISTRICT_ID": "738", "SUB_DISTRICT_ID": "6550", "ZIPCODE": "64150" },
    { "ZIPCODE_ID": 5436, "SUB_DISTRICT_CODE": "640905", "PROVINCE_ID": "51", "DISTRICT_ID": "738", "SUB_DISTRICT_ID": "6551", "ZIPCODE": "64230" },
    { "ZIPCODE_ID": 5437, "SUB_DISTRICT_CODE": "650101", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6552", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5438, "SUB_DISTRICT_CODE": "650102", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6553", "ZIPCODE": "65230" },
    { "ZIPCODE_ID": 5439, "SUB_DISTRICT_CODE": "650103", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6554", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5440, "SUB_DISTRICT_CODE": "650104", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6555", "ZIPCODE": "65230" },
    { "ZIPCODE_ID": 5441, "SUB_DISTRICT_CODE": "650105", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6556", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5442, "SUB_DISTRICT_CODE": "650106", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6557", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5443, "SUB_DISTRICT_CODE": "650107", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6558", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5444, "SUB_DISTRICT_CODE": "650108", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6559", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5445, "SUB_DISTRICT_CODE": "650109", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6560", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5446, "SUB_DISTRICT_CODE": "650110", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6561", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5447, "SUB_DISTRICT_CODE": "650111", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6562", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5448, "SUB_DISTRICT_CODE": "650112", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6563", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5449, "SUB_DISTRICT_CODE": "650113", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6564", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5450, "SUB_DISTRICT_CODE": "650114", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6565", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5451, "SUB_DISTRICT_CODE": "650115", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6566", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5452, "SUB_DISTRICT_CODE": "650116", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6567", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5453, "SUB_DISTRICT_CODE": "650117", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6568", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5454, "SUB_DISTRICT_CODE": "650118", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6569", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5455, "SUB_DISTRICT_CODE": "650119", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6570", "ZIPCODE": "65000" },
    { "ZIPCODE_ID": 5456, "SUB_DISTRICT_CODE": "650120", "PROVINCE_ID": "52", "DISTRICT_ID": "739", "SUB_DISTRICT_ID": "6571", "ZIPCODE": "65230" },
    { "ZIPCODE_ID": 5457, "SUB_DISTRICT_CODE": "650201", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6572", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5458, "SUB_DISTRICT_CODE": "650202", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6573", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5459, "SUB_DISTRICT_CODE": "650203", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6574", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5460, "SUB_DISTRICT_CODE": "650204", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6575", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5461, "SUB_DISTRICT_CODE": "650205", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6576", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5462, "SUB_DISTRICT_CODE": "650206", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6577", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5463, "SUB_DISTRICT_CODE": "650207", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6578", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5464, "SUB_DISTRICT_CODE": "650208", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6579", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5465, "SUB_DISTRICT_CODE": "650209", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6580", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5466, "SUB_DISTRICT_CODE": "650210", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6581", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5467, "SUB_DISTRICT_CODE": "650211", "PROVINCE_ID": "52", "DISTRICT_ID": "740", "SUB_DISTRICT_ID": "6582", "ZIPCODE": "65120" },
    { "ZIPCODE_ID": 5468, "SUB_DISTRICT_CODE": "650301", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6583", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5469, "SUB_DISTRICT_CODE": "650302", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6584", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5470, "SUB_DISTRICT_CODE": "650303", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6585", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5471, "SUB_DISTRICT_CODE": "650304", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6586", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5472, "SUB_DISTRICT_CODE": "650305", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6587", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5473, "SUB_DISTRICT_CODE": "650306", "PROVINCE_ID": "52", "DISTRICT_ID": "741", "SUB_DISTRICT_ID": "6588", "ZIPCODE": "65170" },
    { "ZIPCODE_ID": 5474, "SUB_DISTRICT_CODE": "650401", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6589", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5475, "SUB_DISTRICT_CODE": "650402", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6590", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5476, "SUB_DISTRICT_CODE": "650403", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6591", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5477, "SUB_DISTRICT_CODE": "650404", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6592", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5478, "SUB_DISTRICT_CODE": "650405", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6593", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5479, "SUB_DISTRICT_CODE": "650406", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6594", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5480, "SUB_DISTRICT_CODE": "650407", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6595", "ZIPCODE": "65240" },
    { "ZIPCODE_ID": 5481, "SUB_DISTRICT_CODE": "650408", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6596", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5482, "SUB_DISTRICT_CODE": "650409", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6597", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5483, "SUB_DISTRICT_CODE": "650410", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6598", "ZIPCODE": "65140" },
    { "ZIPCODE_ID": 5484, "SUB_DISTRICT_CODE": "650411", "PROVINCE_ID": "52", "DISTRICT_ID": "742", "SUB_DISTRICT_ID": "6599", "ZIPCODE": "65240" },
    { "ZIPCODE_ID": 5485, "SUB_DISTRICT_CODE": "650501", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6600", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5486, "SUB_DISTRICT_CODE": "650502", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6601", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5487, "SUB_DISTRICT_CODE": "650503", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6602", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5488, "SUB_DISTRICT_CODE": "650504", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6603", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5489, "SUB_DISTRICT_CODE": "650505", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6604", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5490, "SUB_DISTRICT_CODE": "650506", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6605", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5491, "SUB_DISTRICT_CODE": "650507", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6606", "ZIPCODE": "65110" },
    { "ZIPCODE_ID": 5492, "SUB_DISTRICT_CODE": "650508", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6607", "ZIPCODE": "65210" },
    { "ZIPCODE_ID": 5493, "SUB_DISTRICT_CODE": "650509", "PROVINCE_ID": "52", "DISTRICT_ID": "743", "SUB_DISTRICT_ID": "6608", "ZIPCODE": "65210" },
    { "ZIPCODE_ID": 5494, "SUB_DISTRICT_CODE": "650601", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6609", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5495, "SUB_DISTRICT_CODE": "650602", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6610", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5496, "SUB_DISTRICT_CODE": "650603", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6611", "ZIPCODE": "65180" },
    { "ZIPCODE_ID": 5497, "SUB_DISTRICT_CODE": "650604", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6612", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5498, "SUB_DISTRICT_CODE": "650605", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6613", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5499, "SUB_DISTRICT_CODE": "650606", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6614", "ZIPCODE": "65180" },
    { "ZIPCODE_ID": 5500, "SUB_DISTRICT_CODE": "650607", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6615", "ZIPCODE": "65180" },
    { "ZIPCODE_ID": 5501, "SUB_DISTRICT_CODE": "650608", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6616", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5502, "SUB_DISTRICT_CODE": "650609", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6617", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5503, "SUB_DISTRICT_CODE": "650610", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6618", "ZIPCODE": "65180" },
    { "ZIPCODE_ID": 5504, "SUB_DISTRICT_CODE": "650611", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6619", "ZIPCODE": "65150" },
    { "ZIPCODE_ID": 5505, "SUB_DISTRICT_CODE": "650612", "PROVINCE_ID": "52", "DISTRICT_ID": "744", "SUB_DISTRICT_ID": "6620", "ZIPCODE": "65180" },
    { "ZIPCODE_ID": 5506, "SUB_DISTRICT_CODE": "650701", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6621", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5507, "SUB_DISTRICT_CODE": "650702", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6622", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5508, "SUB_DISTRICT_CODE": "650703", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6623", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5509, "SUB_DISTRICT_CODE": "650704", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6624", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5510, "SUB_DISTRICT_CODE": "650705", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6625", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5511, "SUB_DISTRICT_CODE": "650706", "PROVINCE_ID": "52", "DISTRICT_ID": "745", "SUB_DISTRICT_ID": "6626", "ZIPCODE": "65160" },
    { "ZIPCODE_ID": 5512, "SUB_DISTRICT_CODE": "650801", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6627", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5513, "SUB_DISTRICT_CODE": "650802", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6628", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5514, "SUB_DISTRICT_CODE": "650803", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6629", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5515, "SUB_DISTRICT_CODE": "650804", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6630", "ZIPCODE": "65220" },
    { "ZIPCODE_ID": 5516, "SUB_DISTRICT_CODE": "650805", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6631", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5517, "SUB_DISTRICT_CODE": "650806", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6632", "ZIPCODE": "65220" },
    { "ZIPCODE_ID": 5518, "SUB_DISTRICT_CODE": "650807", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6633", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5519, "SUB_DISTRICT_CODE": "650808", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6634", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5520, "SUB_DISTRICT_CODE": "650809", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6635", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5521, "SUB_DISTRICT_CODE": "650810", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6636", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5522, "SUB_DISTRICT_CODE": "650811", "PROVINCE_ID": "52", "DISTRICT_ID": "746", "SUB_DISTRICT_ID": "6637", "ZIPCODE": "65130" },
    { "ZIPCODE_ID": 5523, "SUB_DISTRICT_CODE": "650901", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6643", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5524, "SUB_DISTRICT_CODE": "650902", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6644", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5525, "SUB_DISTRICT_CODE": "650903", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6645", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5526, "SUB_DISTRICT_CODE": "650904", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6646", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5527, "SUB_DISTRICT_CODE": "650905", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6647", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5528, "SUB_DISTRICT_CODE": "650906", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6648", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5529, "SUB_DISTRICT_CODE": "650907", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6649", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5530, "SUB_DISTRICT_CODE": "650908", "PROVINCE_ID": "52", "DISTRICT_ID": "747", "SUB_DISTRICT_ID": "6650", "ZIPCODE": "65190" },
    { "ZIPCODE_ID": 5531, "SUB_DISTRICT_CODE": "660101", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6651", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5532, "SUB_DISTRICT_CODE": "660102", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6652", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5533, "SUB_DISTRICT_CODE": "660103", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6653", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5534, "SUB_DISTRICT_CODE": "660104", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6654", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5535, "SUB_DISTRICT_CODE": "660105", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6655", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5536, "SUB_DISTRICT_CODE": "660106", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6656", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5537, "SUB_DISTRICT_CODE": "660107", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6657", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5538, "SUB_DISTRICT_CODE": "660108", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6658", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5539, "SUB_DISTRICT_CODE": "660109", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6659", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5540, "SUB_DISTRICT_CODE": "660110", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6660", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5541, "SUB_DISTRICT_CODE": "660111", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6661", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5542, "SUB_DISTRICT_CODE": "660112", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6662", "ZIPCODE": "66170" },
    { "ZIPCODE_ID": 5543, "SUB_DISTRICT_CODE": "660113", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6663", "ZIPCODE": "66170" },
    { "ZIPCODE_ID": 5544, "SUB_DISTRICT_CODE": "660115", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6665", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5545, "SUB_DISTRICT_CODE": "660119", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6669", "ZIPCODE": "66000" },
    { "ZIPCODE_ID": 5546, "SUB_DISTRICT_CODE": "660120", "PROVINCE_ID": "53", "DISTRICT_ID": "748", "SUB_DISTRICT_ID": "6670", "ZIPCODE": "66170" },
    { "ZIPCODE_ID": 5547, "SUB_DISTRICT_CODE": "660201", "PROVINCE_ID": "53", "DISTRICT_ID": "749", "SUB_DISTRICT_ID": "6679", "ZIPCODE": "66180" },
    { "ZIPCODE_ID": 5548, "SUB_DISTRICT_CODE": "660202", "PROVINCE_ID": "53", "DISTRICT_ID": "749", "SUB_DISTRICT_ID": "6680", "ZIPCODE": "66180" },
    { "ZIPCODE_ID": 5549, "SUB_DISTRICT_CODE": "660203", "PROVINCE_ID": "53", "DISTRICT_ID": "749", "SUB_DISTRICT_ID": "6681", "ZIPCODE": "66180" },
    { "ZIPCODE_ID": 5550, "SUB_DISTRICT_CODE": "660204", "PROVINCE_ID": "53", "DISTRICT_ID": "749", "SUB_DISTRICT_ID": "6682", "ZIPCODE": "66180" },
    { "ZIPCODE_ID": 5551, "SUB_DISTRICT_CODE": "660301", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6683", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5552, "SUB_DISTRICT_CODE": "660302", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6684", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5553, "SUB_DISTRICT_CODE": "660303", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6685", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5554, "SUB_DISTRICT_CODE": "660304", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6686", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5555, "SUB_DISTRICT_CODE": "660305", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6687", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5556, "SUB_DISTRICT_CODE": "660306", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6688", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5557, "SUB_DISTRICT_CODE": "660307", "PROVINCE_ID": "53", "DISTRICT_ID": "750", "SUB_DISTRICT_ID": "6689", "ZIPCODE": "66190" },
    { "ZIPCODE_ID": 5558, "SUB_DISTRICT_CODE": "660401", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6690", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5559, "SUB_DISTRICT_CODE": "660402", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6691", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5560, "SUB_DISTRICT_CODE": "660403", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6692", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5561, "SUB_DISTRICT_CODE": "660404", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6693", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5562, "SUB_DISTRICT_CODE": "660405", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6694", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5563, "SUB_DISTRICT_CODE": "660406", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6695", "ZIPCODE": "66150" },
    { "ZIPCODE_ID": 5564, "SUB_DISTRICT_CODE": "660407", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6696", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5565, "SUB_DISTRICT_CODE": "660408", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6697", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5566, "SUB_DISTRICT_CODE": "660409", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6698", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5567, "SUB_DISTRICT_CODE": "660410", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6699", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5568, "SUB_DISTRICT_CODE": "660411", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6700", "ZIPCODE": "66150" },
    { "ZIPCODE_ID": 5569, "SUB_DISTRICT_CODE": "660412", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6701", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5570, "SUB_DISTRICT_CODE": "660413", "PROVINCE_ID": "53", "DISTRICT_ID": "751", "SUB_DISTRICT_ID": "6702", "ZIPCODE": "66110" },
    { "ZIPCODE_ID": 5571, "SUB_DISTRICT_CODE": "660501", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6707", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5572, "SUB_DISTRICT_CODE": "660502", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6708", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5573, "SUB_DISTRICT_CODE": "660503", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6709", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5574, "SUB_DISTRICT_CODE": "660504", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6710", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5575, "SUB_DISTRICT_CODE": "660505", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6711", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5576, "SUB_DISTRICT_CODE": "660506", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6712", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5577, "SUB_DISTRICT_CODE": "660507", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6713", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5578, "SUB_DISTRICT_CODE": "660508", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6714", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5579, "SUB_DISTRICT_CODE": "660509", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6715", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5580, "SUB_DISTRICT_CODE": "660514", "PROVINCE_ID": "53", "DISTRICT_ID": "752", "SUB_DISTRICT_ID": "6720", "ZIPCODE": "66120" },
    { "ZIPCODE_ID": 5581, "SUB_DISTRICT_CODE": "660601", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6722", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5582, "SUB_DISTRICT_CODE": "660602", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6723", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5583, "SUB_DISTRICT_CODE": "660603", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6724", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5584, "SUB_DISTRICT_CODE": "660604", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6725", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5585, "SUB_DISTRICT_CODE": "660605", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6726", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5586, "SUB_DISTRICT_CODE": "660606", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6727", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5587, "SUB_DISTRICT_CODE": "660607", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6728", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5588, "SUB_DISTRICT_CODE": "660608", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6729", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5589, "SUB_DISTRICT_CODE": "660611", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6732", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5590, "SUB_DISTRICT_CODE": "660612", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6733", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5591, "SUB_DISTRICT_CODE": "660613", "PROVINCE_ID": "53", "DISTRICT_ID": "753", "SUB_DISTRICT_ID": "6734", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5592, "SUB_DISTRICT_CODE": "660701", "PROVINCE_ID": "53", "DISTRICT_ID": "754", "SUB_DISTRICT_ID": "6738", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5593, "SUB_DISTRICT_CODE": "660702", "PROVINCE_ID": "53", "DISTRICT_ID": "754", "SUB_DISTRICT_ID": "6739", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5594, "SUB_DISTRICT_CODE": "660703", "PROVINCE_ID": "53", "DISTRICT_ID": "754", "SUB_DISTRICT_ID": "6740", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5595, "SUB_DISTRICT_CODE": "660706", "PROVINCE_ID": "53", "DISTRICT_ID": "754", "SUB_DISTRICT_ID": "6743", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5596, "SUB_DISTRICT_CODE": "660707", "PROVINCE_ID": "53", "DISTRICT_ID": "754", "SUB_DISTRICT_ID": "6744", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5597, "SUB_DISTRICT_CODE": "660801", "PROVINCE_ID": "53", "DISTRICT_ID": "755", "SUB_DISTRICT_ID": "6747", "ZIPCODE": "66150" },
    { "ZIPCODE_ID": 5598, "SUB_DISTRICT_CODE": "660802", "PROVINCE_ID": "53", "DISTRICT_ID": "755", "SUB_DISTRICT_ID": "6748", "ZIPCODE": "66230" },
    { "ZIPCODE_ID": 5599, "SUB_DISTRICT_CODE": "660803", "PROVINCE_ID": "53", "DISTRICT_ID": "755", "SUB_DISTRICT_ID": "6749", "ZIPCODE": "66230" },
    { "ZIPCODE_ID": 5600, "SUB_DISTRICT_CODE": "660804", "PROVINCE_ID": "53", "DISTRICT_ID": "755", "SUB_DISTRICT_ID": "6750", "ZIPCODE": "66150" },
    { "ZIPCODE_ID": 5601, "SUB_DISTRICT_CODE": "660901", "PROVINCE_ID": "53", "DISTRICT_ID": "756", "SUB_DISTRICT_ID": "6751", "ZIPCODE": "66160" },
    { "ZIPCODE_ID": 5602, "SUB_DISTRICT_CODE": "660902", "PROVINCE_ID": "53", "DISTRICT_ID": "756", "SUB_DISTRICT_ID": "6752", "ZIPCODE": "66160" },
    { "ZIPCODE_ID": 5603, "SUB_DISTRICT_CODE": "660903", "PROVINCE_ID": "53", "DISTRICT_ID": "756", "SUB_DISTRICT_ID": "6753", "ZIPCODE": "66160" },
    { "ZIPCODE_ID": 5604, "SUB_DISTRICT_CODE": "660904", "PROVINCE_ID": "53", "DISTRICT_ID": "756", "SUB_DISTRICT_ID": "6754", "ZIPCODE": "66160" },
    { "ZIPCODE_ID": 5605, "SUB_DISTRICT_CODE": "660905", "PROVINCE_ID": "53", "DISTRICT_ID": "756", "SUB_DISTRICT_ID": "6755", "ZIPCODE": "66160" },
    { "ZIPCODE_ID": 5606, "SUB_DISTRICT_CODE": "661001", "PROVINCE_ID": "53", "DISTRICT_ID": "757", "SUB_DISTRICT_ID": "6756", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5607, "SUB_DISTRICT_CODE": "661002", "PROVINCE_ID": "53", "DISTRICT_ID": "757", "SUB_DISTRICT_ID": "6757", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5608, "SUB_DISTRICT_CODE": "661003", "PROVINCE_ID": "53", "DISTRICT_ID": "757", "SUB_DISTRICT_ID": "6758", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5609, "SUB_DISTRICT_CODE": "661004", "PROVINCE_ID": "53", "DISTRICT_ID": "757", "SUB_DISTRICT_ID": "6759", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5610, "SUB_DISTRICT_CODE": "661005", "PROVINCE_ID": "53", "DISTRICT_ID": "757", "SUB_DISTRICT_ID": "6760", "ZIPCODE": "66130" },
    { "ZIPCODE_ID": 5611, "SUB_DISTRICT_CODE": "661101", "PROVINCE_ID": "53", "DISTRICT_ID": "758", "SUB_DISTRICT_ID": "6761", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5612, "SUB_DISTRICT_CODE": "661102", "PROVINCE_ID": "53", "DISTRICT_ID": "758", "SUB_DISTRICT_ID": "6762", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5613, "SUB_DISTRICT_CODE": "661103", "PROVINCE_ID": "53", "DISTRICT_ID": "758", "SUB_DISTRICT_ID": "6763", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5614, "SUB_DISTRICT_CODE": "661104", "PROVINCE_ID": "53", "DISTRICT_ID": "758", "SUB_DISTRICT_ID": "6764", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5615, "SUB_DISTRICT_CODE": "661105", "PROVINCE_ID": "53", "DISTRICT_ID": "758", "SUB_DISTRICT_ID": "6765", "ZIPCODE": "66210" },
    { "ZIPCODE_ID": 5616, "SUB_DISTRICT_CODE": "661201", "PROVINCE_ID": "53", "DISTRICT_ID": "759", "SUB_DISTRICT_ID": "6766", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5617, "SUB_DISTRICT_CODE": "661202", "PROVINCE_ID": "53", "DISTRICT_ID": "759", "SUB_DISTRICT_ID": "6767", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5618, "SUB_DISTRICT_CODE": "661203", "PROVINCE_ID": "53", "DISTRICT_ID": "759", "SUB_DISTRICT_ID": "6768", "ZIPCODE": "66140" },
    { "ZIPCODE_ID": 5619, "SUB_DISTRICT_CODE": "661204", "PROVINCE_ID": "53", "DISTRICT_ID": "759", "SUB_DISTRICT_ID": "6769", "ZIPCODE": "66220" },
    { "ZIPCODE_ID": 5620, "SUB_DISTRICT_CODE": "670101", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6770", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5621, "SUB_DISTRICT_CODE": "670102", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6771", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5622, "SUB_DISTRICT_CODE": "670103", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6772", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5623, "SUB_DISTRICT_CODE": "670104", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6773", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5624, "SUB_DISTRICT_CODE": "670105", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6774", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5625, "SUB_DISTRICT_CODE": "670106", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6775", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5626, "SUB_DISTRICT_CODE": "670107", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6776", "ZIPCODE": "67250" },
    { "ZIPCODE_ID": 5627, "SUB_DISTRICT_CODE": "670108", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6777", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5628, "SUB_DISTRICT_CODE": "670109", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6778", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5629, "SUB_DISTRICT_CODE": "670110", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6779", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5630, "SUB_DISTRICT_CODE": "670111", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6780", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5631, "SUB_DISTRICT_CODE": "670112", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6781", "ZIPCODE": "67210" },
    { "ZIPCODE_ID": 5632, "SUB_DISTRICT_CODE": "670113", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6782", "ZIPCODE": "67210" },
    { "ZIPCODE_ID": 5633, "SUB_DISTRICT_CODE": "670114", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6783", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5634, "SUB_DISTRICT_CODE": "670115", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6784", "ZIPCODE": "67210" },
    { "ZIPCODE_ID": 5635, "SUB_DISTRICT_CODE": "670116", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6785", "ZIPCODE": "67000" },
    { "ZIPCODE_ID": 5636, "SUB_DISTRICT_CODE": "670117", "PROVINCE_ID": "54", "DISTRICT_ID": "760", "SUB_DISTRICT_ID": "6786", "ZIPCODE": "67210" },
    { "ZIPCODE_ID": 5637, "SUB_DISTRICT_CODE": "670201", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6787", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5638, "SUB_DISTRICT_CODE": "670202", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6788", "ZIPCODE": "67190" },
    { "ZIPCODE_ID": 5639, "SUB_DISTRICT_CODE": "670203", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6789", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5640, "SUB_DISTRICT_CODE": "670204", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6790", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5641, "SUB_DISTRICT_CODE": "670205", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6791", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5642, "SUB_DISTRICT_CODE": "670206", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6792", "ZIPCODE": "67190" },
    { "ZIPCODE_ID": 5643, "SUB_DISTRICT_CODE": "670208", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6794", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5644, "SUB_DISTRICT_CODE": "670209", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6795", "ZIPCODE": "67190" },
    { "ZIPCODE_ID": 5645, "SUB_DISTRICT_CODE": "670210", "PROVINCE_ID": "54", "DISTRICT_ID": "761", "SUB_DISTRICT_ID": "6796", "ZIPCODE": "67150" },
    { "ZIPCODE_ID": 5646, "SUB_DISTRICT_CODE": "670301", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6799", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5647, "SUB_DISTRICT_CODE": "670302", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6800", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5648, "SUB_DISTRICT_CODE": "670303", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6801", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5649, "SUB_DISTRICT_CODE": "670304", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6802", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5650, "SUB_DISTRICT_CODE": "670305", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6803", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5651, "SUB_DISTRICT_CODE": "670306", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6804", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5652, "SUB_DISTRICT_CODE": "670307", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6805", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5653, "SUB_DISTRICT_CODE": "670308", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6806", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5654, "SUB_DISTRICT_CODE": "670309", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6807", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5655, "SUB_DISTRICT_CODE": "670310", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6808", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5656, "SUB_DISTRICT_CODE": "670311", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6809", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5657, "SUB_DISTRICT_CODE": "670312", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6810", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5658, "SUB_DISTRICT_CODE": "670313", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6811", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5659, "SUB_DISTRICT_CODE": "670314", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6812", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5660, "SUB_DISTRICT_CODE": "670315", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6813", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5661, "SUB_DISTRICT_CODE": "670316", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6814", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5662, "SUB_DISTRICT_CODE": "670317", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6815", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5663, "SUB_DISTRICT_CODE": "670318", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6816", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5664, "SUB_DISTRICT_CODE": "670319", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6817", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5665, "SUB_DISTRICT_CODE": "670320", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6818", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5666, "SUB_DISTRICT_CODE": "670321", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6819", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5667, "SUB_DISTRICT_CODE": "670322", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6820", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5668, "SUB_DISTRICT_CODE": "670323", "PROVINCE_ID": "54", "DISTRICT_ID": "762", "SUB_DISTRICT_ID": "6821", "ZIPCODE": "67110" },
    { "ZIPCODE_ID": 5669, "SUB_DISTRICT_CODE": "670401", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6823", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5670, "SUB_DISTRICT_CODE": "670402", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6824", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5671, "SUB_DISTRICT_CODE": "670403", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6825", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5672, "SUB_DISTRICT_CODE": "670404", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6826", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5673, "SUB_DISTRICT_CODE": "670405", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6827", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5674, "SUB_DISTRICT_CODE": "670406", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6828", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5675, "SUB_DISTRICT_CODE": "670407", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6829", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5676, "SUB_DISTRICT_CODE": "670408", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6830", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5677, "SUB_DISTRICT_CODE": "670409", "PROVINCE_ID": "54", "DISTRICT_ID": "763", "SUB_DISTRICT_ID": "6831", "ZIPCODE": "67120" },
    { "ZIPCODE_ID": 5678, "SUB_DISTRICT_CODE": "670501", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6833", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5679, "SUB_DISTRICT_CODE": "670502", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6834", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5680, "SUB_DISTRICT_CODE": "670503", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6835", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5681, "SUB_DISTRICT_CODE": "670504", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6836", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5682, "SUB_DISTRICT_CODE": "670505", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6837", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5683, "SUB_DISTRICT_CODE": "670506", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6838", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5684, "SUB_DISTRICT_CODE": "670507", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6839", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5685, "SUB_DISTRICT_CODE": "670508", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6840", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5686, "SUB_DISTRICT_CODE": "670509", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6841", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5687, "SUB_DISTRICT_CODE": "670510", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6842", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5688, "SUB_DISTRICT_CODE": "670511", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6843", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5689, "SUB_DISTRICT_CODE": "670512", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6844", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5690, "SUB_DISTRICT_CODE": "670513", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6845", "ZIPCODE": "67130" },
    { "ZIPCODE_ID": 5691, "SUB_DISTRICT_CODE": "670514", "PROVINCE_ID": "54", "DISTRICT_ID": "764", "SUB_DISTRICT_ID": "6846", "ZIPCODE": "67180" },
    { "ZIPCODE_ID": 5692, "SUB_DISTRICT_CODE": "670601", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6851", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5693, "SUB_DISTRICT_CODE": "670602", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6852", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5694, "SUB_DISTRICT_CODE": "670603", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6853", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5695, "SUB_DISTRICT_CODE": "670604", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6854", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5696, "SUB_DISTRICT_CODE": "670605", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6855", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5697, "SUB_DISTRICT_CODE": "670606", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6856", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5698, "SUB_DISTRICT_CODE": "670607", "PROVINCE_ID": "54", "DISTRICT_ID": "765", "SUB_DISTRICT_ID": "6857", "ZIPCODE": "67170" },
    { "ZIPCODE_ID": 5699, "SUB_DISTRICT_CODE": "670701", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6858", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5700, "SUB_DISTRICT_CODE": "670702", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6859", "ZIPCODE": "67220" },
    { "ZIPCODE_ID": 5701, "SUB_DISTRICT_CODE": "670703", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6860", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5702, "SUB_DISTRICT_CODE": "670704", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6861", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5703, "SUB_DISTRICT_CODE": "670705", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6862", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5704, "SUB_DISTRICT_CODE": "670706", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6863", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5705, "SUB_DISTRICT_CODE": "670707", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6864", "ZIPCODE": "67220" },
    { "ZIPCODE_ID": 5706, "SUB_DISTRICT_CODE": "670708", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6865", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5707, "SUB_DISTRICT_CODE": "670709", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6866", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5708, "SUB_DISTRICT_CODE": "670710", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6867", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5709, "SUB_DISTRICT_CODE": "670711", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6868", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5710, "SUB_DISTRICT_CODE": "670712", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6869", "ZIPCODE": "67220" },
    { "ZIPCODE_ID": 5711, "SUB_DISTRICT_CODE": "670713", "PROVINCE_ID": "54", "DISTRICT_ID": "766", "SUB_DISTRICT_ID": "6870", "ZIPCODE": "67140" },
    { "ZIPCODE_ID": 5712, "SUB_DISTRICT_CODE": "670801", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6871", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5713, "SUB_DISTRICT_CODE": "670802", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6872", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5714, "SUB_DISTRICT_CODE": "670803", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6873", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5715, "SUB_DISTRICT_CODE": "670804", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6874", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5716, "SUB_DISTRICT_CODE": "670805", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6875", "ZIPCODE": "67230" },
    { "ZIPCODE_ID": 5717, "SUB_DISTRICT_CODE": "670806", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6876", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5718, "SUB_DISTRICT_CODE": "670807", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6877", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5719, "SUB_DISTRICT_CODE": "670808", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6878", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5720, "SUB_DISTRICT_CODE": "670809", "PROVINCE_ID": "54", "DISTRICT_ID": "767", "SUB_DISTRICT_ID": "6879", "ZIPCODE": "67160" },
    { "ZIPCODE_ID": 5721, "SUB_DISTRICT_CODE": "670901", "PROVINCE_ID": "54", "DISTRICT_ID": "768", "SUB_DISTRICT_ID": "6880", "ZIPCODE": "67260" },
    { "ZIPCODE_ID": 5722, "SUB_DISTRICT_CODE": "670902", "PROVINCE_ID": "54", "DISTRICT_ID": "768", "SUB_DISTRICT_ID": "6881", "ZIPCODE": "67260" },
    { "ZIPCODE_ID": 5723, "SUB_DISTRICT_CODE": "670903", "PROVINCE_ID": "54", "DISTRICT_ID": "768", "SUB_DISTRICT_ID": "6882", "ZIPCODE": "67260" },
    { "ZIPCODE_ID": 5724, "SUB_DISTRICT_CODE": "670904", "PROVINCE_ID": "54", "DISTRICT_ID": "768", "SUB_DISTRICT_ID": "6883", "ZIPCODE": "67260" },
    { "ZIPCODE_ID": 5725, "SUB_DISTRICT_CODE": "671001", "PROVINCE_ID": "54", "DISTRICT_ID": "769", "SUB_DISTRICT_ID": "6884", "ZIPCODE": "67240" },
    { "ZIPCODE_ID": 5726, "SUB_DISTRICT_CODE": "671002", "PROVINCE_ID": "54", "DISTRICT_ID": "769", "SUB_DISTRICT_ID": "6885", "ZIPCODE": "67240" },
    { "ZIPCODE_ID": 5727, "SUB_DISTRICT_CODE": "671003", "PROVINCE_ID": "54", "DISTRICT_ID": "769", "SUB_DISTRICT_ID": "6886", "ZIPCODE": "67240" },
    { "ZIPCODE_ID": 5728, "SUB_DISTRICT_CODE": "671004", "PROVINCE_ID": "54", "DISTRICT_ID": "769", "SUB_DISTRICT_ID": "6887", "ZIPCODE": "67240" },
    { "ZIPCODE_ID": 5729, "SUB_DISTRICT_CODE": "671005", "PROVINCE_ID": "54", "DISTRICT_ID": "769", "SUB_DISTRICT_ID": "6888", "ZIPCODE": "67240" },
    { "ZIPCODE_ID": 5730, "SUB_DISTRICT_CODE": "671101", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6889", "ZIPCODE": "67270" },
    { "ZIPCODE_ID": 5731, "SUB_DISTRICT_CODE": "671102", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6890", "ZIPCODE": "67280" },
    { "ZIPCODE_ID": 5732, "SUB_DISTRICT_CODE": "671103", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6891", "ZIPCODE": "67270" },
    { "ZIPCODE_ID": 5733, "SUB_DISTRICT_CODE": "671104", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6892", "ZIPCODE": "67270" },
    { "ZIPCODE_ID": 5734, "SUB_DISTRICT_CODE": "671105", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6893", "ZIPCODE": "67270" },
    { "ZIPCODE_ID": 5735, "SUB_DISTRICT_CODE": "671106", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6894", "ZIPCODE": "67270" },
    { "ZIPCODE_ID": 5736, "SUB_DISTRICT_CODE": "671107", "PROVINCE_ID": "54", "DISTRICT_ID": "770", "SUB_DISTRICT_ID": "6895", "ZIPCODE": "67280" },
    { "ZIPCODE_ID": 5737, "SUB_DISTRICT_CODE": "700101", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6896", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5738, "SUB_DISTRICT_CODE": "700102", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6897", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5739, "SUB_DISTRICT_CODE": "700103", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6898", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5740, "SUB_DISTRICT_CODE": "700104", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6899", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5741, "SUB_DISTRICT_CODE": "700105", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6900", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5742, "SUB_DISTRICT_CODE": "700106", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6901", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5743, "SUB_DISTRICT_CODE": "700107", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6902", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5744, "SUB_DISTRICT_CODE": "700108", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6903", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5745, "SUB_DISTRICT_CODE": "700109", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6904", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5746, "SUB_DISTRICT_CODE": "700110", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6905", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5747, "SUB_DISTRICT_CODE": "700111", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6906", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5748, "SUB_DISTRICT_CODE": "700112", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6907", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5749, "SUB_DISTRICT_CODE": "700113", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6908", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5750, "SUB_DISTRICT_CODE": "700114", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6909", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5751, "SUB_DISTRICT_CODE": "700115", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6910", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5752, "SUB_DISTRICT_CODE": "700116", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6911", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5753, "SUB_DISTRICT_CODE": "700117", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6912", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5754, "SUB_DISTRICT_CODE": "700118", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6913", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5755, "SUB_DISTRICT_CODE": "700119", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6914", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5756, "SUB_DISTRICT_CODE": "700120", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6915", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5757, "SUB_DISTRICT_CODE": "700121", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6916", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5758, "SUB_DISTRICT_CODE": "700122", "PROVINCE_ID": "55", "DISTRICT_ID": "771", "SUB_DISTRICT_ID": "6917", "ZIPCODE": "70000" },
    { "ZIPCODE_ID": 5759, "SUB_DISTRICT_CODE": "700201", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6918", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5760, "SUB_DISTRICT_CODE": "700202", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6919", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5761, "SUB_DISTRICT_CODE": "700203", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6920", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5762, "SUB_DISTRICT_CODE": "700204", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6921", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5763, "SUB_DISTRICT_CODE": "700205", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6922", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5764, "SUB_DISTRICT_CODE": "700206", "PROVINCE_ID": "55", "DISTRICT_ID": "772", "SUB_DISTRICT_ID": "6923", "ZIPCODE": "70150" },
    { "ZIPCODE_ID": 5765, "SUB_DISTRICT_CODE": "700301", "PROVINCE_ID": "55", "DISTRICT_ID": "773", "SUB_DISTRICT_ID": "6927", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5766, "SUB_DISTRICT_CODE": "700302", "PROVINCE_ID": "55", "DISTRICT_ID": "773", "SUB_DISTRICT_ID": "6928", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5767, "SUB_DISTRICT_CODE": "700304", "PROVINCE_ID": "55", "DISTRICT_ID": "773", "SUB_DISTRICT_ID": "6930", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5768, "SUB_DISTRICT_CODE": "700307", "PROVINCE_ID": "55", "DISTRICT_ID": "773", "SUB_DISTRICT_ID": "6933", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5769, "SUB_DISTRICT_CODE": "700401", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6934", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5770, "SUB_DISTRICT_CODE": "700402", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6935", "ZIPCODE": "70210" },
    { "ZIPCODE_ID": 5771, "SUB_DISTRICT_CODE": "700403", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6936", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5772, "SUB_DISTRICT_CODE": "700404", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6937", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5773, "SUB_DISTRICT_CODE": "700405", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6938", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5774, "SUB_DISTRICT_CODE": "700406", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6939", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5775, "SUB_DISTRICT_CODE": "700407", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6940", "ZIPCODE": "70210" },
    { "ZIPCODE_ID": 5776, "SUB_DISTRICT_CODE": "700408", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6941", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5777, "SUB_DISTRICT_CODE": "700409", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6942", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5778, "SUB_DISTRICT_CODE": "700410", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6943", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5779, "SUB_DISTRICT_CODE": "700411", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6944", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5780, "SUB_DISTRICT_CODE": "700412", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6945", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5781, "SUB_DISTRICT_CODE": "700413", "PROVINCE_ID": "55", "DISTRICT_ID": "774", "SUB_DISTRICT_ID": "6946", "ZIPCODE": "70130" },
    { "ZIPCODE_ID": 5782, "SUB_DISTRICT_CODE": "700501", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6947", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5783, "SUB_DISTRICT_CODE": "700502", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6948", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5784, "SUB_DISTRICT_CODE": "700503", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6949", "ZIPCODE": "70190" },
    { "ZIPCODE_ID": 5785, "SUB_DISTRICT_CODE": "700504", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6950", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5786, "SUB_DISTRICT_CODE": "700505", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6951", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5787, "SUB_DISTRICT_CODE": "700506", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6952", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5788, "SUB_DISTRICT_CODE": "700507", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6953", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5789, "SUB_DISTRICT_CODE": "700508", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6954", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5790, "SUB_DISTRICT_CODE": "700509", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6955", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5791, "SUB_DISTRICT_CODE": "700510", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6956", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5792, "SUB_DISTRICT_CODE": "700511", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6957", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5793, "SUB_DISTRICT_CODE": "700512", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6958", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5794, "SUB_DISTRICT_CODE": "700513", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6959", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5795, "SUB_DISTRICT_CODE": "700514", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6960", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5796, "SUB_DISTRICT_CODE": "700515", "PROVINCE_ID": "55", "DISTRICT_ID": "775", "SUB_DISTRICT_ID": "6961", "ZIPCODE": "70110" },
    { "ZIPCODE_ID": 5797, "SUB_DISTRICT_CODE": "700601", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6962", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5798, "SUB_DISTRICT_CODE": "700602", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6963", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5799, "SUB_DISTRICT_CODE": "700603", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6964", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5800, "SUB_DISTRICT_CODE": "700604", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6965", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5801, "SUB_DISTRICT_CODE": "700605", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6966", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5802, "SUB_DISTRICT_CODE": "700606", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6967", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5803, "SUB_DISTRICT_CODE": "700607", "PROVINCE_ID": "55", "DISTRICT_ID": "776", "SUB_DISTRICT_ID": "6968", "ZIPCODE": "70160" },
    { "ZIPCODE_ID": 5804, "SUB_DISTRICT_CODE": "700701", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6969", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5805, "SUB_DISTRICT_CODE": "700702", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6970", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5806, "SUB_DISTRICT_CODE": "700703", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6971", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5807, "SUB_DISTRICT_CODE": "700704", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6972", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5808, "SUB_DISTRICT_CODE": "700705", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6973", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5809, "SUB_DISTRICT_CODE": "700706", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6974", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5810, "SUB_DISTRICT_CODE": "700707", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6975", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5811, "SUB_DISTRICT_CODE": "700708", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6976", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5812, "SUB_DISTRICT_CODE": "700709", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6977", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5813, "SUB_DISTRICT_CODE": "700710", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6978", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5814, "SUB_DISTRICT_CODE": "700711", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6979", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5815, "SUB_DISTRICT_CODE": "700712", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6980", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5816, "SUB_DISTRICT_CODE": "700713", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6981", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5817, "SUB_DISTRICT_CODE": "700714", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6982", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5818, "SUB_DISTRICT_CODE": "700715", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6983", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5819, "SUB_DISTRICT_CODE": "700716", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6984", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5820, "SUB_DISTRICT_CODE": "700717", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6985", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5821, "SUB_DISTRICT_CODE": "700718", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6986", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5822, "SUB_DISTRICT_CODE": "700719", "PROVINCE_ID": "55", "DISTRICT_ID": "777", "SUB_DISTRICT_ID": "6987", "ZIPCODE": "70120" },
    { "ZIPCODE_ID": 5823, "SUB_DISTRICT_CODE": "700801", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6988", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5824, "SUB_DISTRICT_CODE": "700802", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6989", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5825, "SUB_DISTRICT_CODE": "700803", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6990", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5826, "SUB_DISTRICT_CODE": "700804", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6991", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5827, "SUB_DISTRICT_CODE": "700805", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6992", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5828, "SUB_DISTRICT_CODE": "700806", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6993", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5829, "SUB_DISTRICT_CODE": "700807", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6994", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5830, "SUB_DISTRICT_CODE": "700808", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6995", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5831, "SUB_DISTRICT_CODE": "700809", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6996", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5832, "SUB_DISTRICT_CODE": "700810", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6997", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5833, "SUB_DISTRICT_CODE": "700811", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6998", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5834, "SUB_DISTRICT_CODE": "700812", "PROVINCE_ID": "55", "DISTRICT_ID": "778", "SUB_DISTRICT_ID": "6999", "ZIPCODE": "70140" },
    { "ZIPCODE_ID": 5835, "SUB_DISTRICT_CODE": "700901", "PROVINCE_ID": "55", "DISTRICT_ID": "779", "SUB_DISTRICT_ID": "7000", "ZIPCODE": "70170" },
    { "ZIPCODE_ID": 5836, "SUB_DISTRICT_CODE": "700902", "PROVINCE_ID": "55", "DISTRICT_ID": "779", "SUB_DISTRICT_ID": "7001", "ZIPCODE": "70170" },
    { "ZIPCODE_ID": 5837, "SUB_DISTRICT_CODE": "700903", "PROVINCE_ID": "55", "DISTRICT_ID": "779", "SUB_DISTRICT_ID": "7002", "ZIPCODE": "70170" },
    { "ZIPCODE_ID": 5838, "SUB_DISTRICT_CODE": "701001", "PROVINCE_ID": "55", "DISTRICT_ID": "780", "SUB_DISTRICT_ID": "7003", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5839, "SUB_DISTRICT_CODE": "701002", "PROVINCE_ID": "55", "DISTRICT_ID": "780", "SUB_DISTRICT_ID": "7004", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5840, "SUB_DISTRICT_CODE": "701003", "PROVINCE_ID": "55", "DISTRICT_ID": "780", "SUB_DISTRICT_ID": "7005", "ZIPCODE": "70180" },
    { "ZIPCODE_ID": 5841, "SUB_DISTRICT_CODE": "710101", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7006", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5842, "SUB_DISTRICT_CODE": "710102", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7007", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5843, "SUB_DISTRICT_CODE": "710103", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7008", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5844, "SUB_DISTRICT_CODE": "710104", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7009", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5845, "SUB_DISTRICT_CODE": "710105", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7010", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5846, "SUB_DISTRICT_CODE": "710106", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7011", "ZIPCODE": "71190" },
    { "ZIPCODE_ID": 5847, "SUB_DISTRICT_CODE": "710107", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7012", "ZIPCODE": "71190" },
    { "ZIPCODE_ID": 5848, "SUB_DISTRICT_CODE": "710108", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7013", "ZIPCODE": "71190" },
    { "ZIPCODE_ID": 5849, "SUB_DISTRICT_CODE": "710109", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7014", "ZIPCODE": "71190" },
    { "ZIPCODE_ID": 5850, "SUB_DISTRICT_CODE": "710110", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7015", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5851, "SUB_DISTRICT_CODE": "710111", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7016", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5852, "SUB_DISTRICT_CODE": "710113", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7018", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5853, "SUB_DISTRICT_CODE": "710116", "PROVINCE_ID": "56", "DISTRICT_ID": "782", "SUB_DISTRICT_ID": "7021", "ZIPCODE": "71000" },
    { "ZIPCODE_ID": 5854, "SUB_DISTRICT_CODE": "710201", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7022", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5855, "SUB_DISTRICT_CODE": "710202", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7023", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5856, "SUB_DISTRICT_CODE": "710203", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7024", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5857, "SUB_DISTRICT_CODE": "710204", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7025", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5858, "SUB_DISTRICT_CODE": "710205", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7026", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5859, "SUB_DISTRICT_CODE": "710206", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7027", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5860, "SUB_DISTRICT_CODE": "710207", "PROVINCE_ID": "56", "DISTRICT_ID": "783", "SUB_DISTRICT_ID": "7028", "ZIPCODE": "71150" },
    { "ZIPCODE_ID": 5861, "SUB_DISTRICT_CODE": "710301", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7029", "ZIPCODE": "71160" },
    { "ZIPCODE_ID": 5862, "SUB_DISTRICT_CODE": "710302", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7030", "ZIPCODE": "71160" },
    { "ZIPCODE_ID": 5863, "SUB_DISTRICT_CODE": "710303", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7031", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5864, "SUB_DISTRICT_CODE": "710305", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7033", "ZIPCODE": "71160" },
    { "ZIPCODE_ID": 5865, "SUB_DISTRICT_CODE": "710308", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7036", "ZIPCODE": "71160" },
    { "ZIPCODE_ID": 5866, "SUB_DISTRICT_CODE": "710309", "PROVINCE_ID": "56", "DISTRICT_ID": "784", "SUB_DISTRICT_ID": "7037", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5867, "SUB_DISTRICT_CODE": "710401", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7038", "ZIPCODE": "71250" },
    { "ZIPCODE_ID": 5868, "SUB_DISTRICT_CODE": "710402", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7039", "ZIPCODE": "71250" },
    { "ZIPCODE_ID": 5869, "SUB_DISTRICT_CODE": "710403", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7040", "ZIPCODE": "71250" },
    { "ZIPCODE_ID": 5870, "SUB_DISTRICT_CODE": "710404", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7041", "ZIPCODE": "71250" },
    { "ZIPCODE_ID": 5871, "SUB_DISTRICT_CODE": "710405", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7042", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5872, "SUB_DISTRICT_CODE": "710406", "PROVINCE_ID": "56", "DISTRICT_ID": "785", "SUB_DISTRICT_ID": "7043", "ZIPCODE": "71250" },
    { "ZIPCODE_ID": 5873, "SUB_DISTRICT_CODE": "710501", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7044", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5874, "SUB_DISTRICT_CODE": "710502", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7045", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5875, "SUB_DISTRICT_CODE": "710503", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7046", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5876, "SUB_DISTRICT_CODE": "710504", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7047", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5877, "SUB_DISTRICT_CODE": "710505", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7048", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5878, "SUB_DISTRICT_CODE": "710506", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7049", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5879, "SUB_DISTRICT_CODE": "710507", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7050", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5880, "SUB_DISTRICT_CODE": "710508", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7051", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5881, "SUB_DISTRICT_CODE": "710509", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7052", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5882, "SUB_DISTRICT_CODE": "710510", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7053", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5883, "SUB_DISTRICT_CODE": "710511", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7054", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5884, "SUB_DISTRICT_CODE": "710512", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7055", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5885, "SUB_DISTRICT_CODE": "710513", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7056", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5886, "SUB_DISTRICT_CODE": "710514", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7057", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5887, "SUB_DISTRICT_CODE": "710515", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7058", "ZIPCODE": "70190" },
    { "ZIPCODE_ID": 5888, "SUB_DISTRICT_CODE": "710516", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7059", "ZIPCODE": "71120" },
    { "ZIPCODE_ID": 5889, "SUB_DISTRICT_CODE": "710517", "PROVINCE_ID": "56", "DISTRICT_ID": "786", "SUB_DISTRICT_ID": "7060", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5890, "SUB_DISTRICT_CODE": "710601", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7061", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5891, "SUB_DISTRICT_CODE": "710602", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7062", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5892, "SUB_DISTRICT_CODE": "710603", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7063", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5893, "SUB_DISTRICT_CODE": "710604", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7064", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5894, "SUB_DISTRICT_CODE": "710605", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7065", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5895, "SUB_DISTRICT_CODE": "710606", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7066", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5896, "SUB_DISTRICT_CODE": "710607", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7067", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5897, "SUB_DISTRICT_CODE": "710608", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7068", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5898, "SUB_DISTRICT_CODE": "710609", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7069", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5899, "SUB_DISTRICT_CODE": "710610", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7070", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5900, "SUB_DISTRICT_CODE": "710611", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7071", "ZIPCODE": "71130" },
    { "ZIPCODE_ID": 5901, "SUB_DISTRICT_CODE": "710612", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7072", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5902, "SUB_DISTRICT_CODE": "710613", "PROVINCE_ID": "56", "DISTRICT_ID": "787", "SUB_DISTRICT_ID": "7073", "ZIPCODE": "71110" },
    { "ZIPCODE_ID": 5903, "SUB_DISTRICT_CODE": "710701", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7074", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5904, "SUB_DISTRICT_CODE": "710702", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7075", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5905, "SUB_DISTRICT_CODE": "710703", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7076", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5906, "SUB_DISTRICT_CODE": "710704", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7077", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5907, "SUB_DISTRICT_CODE": "710705", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7078", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5908, "SUB_DISTRICT_CODE": "710706", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7079", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5909, "SUB_DISTRICT_CODE": "710707", "PROVINCE_ID": "56", "DISTRICT_ID": "788", "SUB_DISTRICT_ID": "7080", "ZIPCODE": "71180" },
    { "ZIPCODE_ID": 5910, "SUB_DISTRICT_CODE": "710801", "PROVINCE_ID": "56", "DISTRICT_ID": "789", "SUB_DISTRICT_ID": "7081", "ZIPCODE": "71240" },
    { "ZIPCODE_ID": 5911, "SUB_DISTRICT_CODE": "710802", "PROVINCE_ID": "56", "DISTRICT_ID": "789", "SUB_DISTRICT_ID": "7082", "ZIPCODE": "71240" },
    { "ZIPCODE_ID": 5912, "SUB_DISTRICT_CODE": "710803", "PROVINCE_ID": "56", "DISTRICT_ID": "789", "SUB_DISTRICT_ID": "7083", "ZIPCODE": "71240" },
    { "ZIPCODE_ID": 5913, "SUB_DISTRICT_CODE": "710901", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7084", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5914, "SUB_DISTRICT_CODE": "710902", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7085", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5915, "SUB_DISTRICT_CODE": "710903", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7086", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5916, "SUB_DISTRICT_CODE": "710904", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7087", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5917, "SUB_DISTRICT_CODE": "710905", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7088", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5918, "SUB_DISTRICT_CODE": "710906", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7089", "ZIPCODE": "71170" },
    { "ZIPCODE_ID": 5919, "SUB_DISTRICT_CODE": "710911", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7094", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5920, "SUB_DISTRICT_CODE": "710912", "PROVINCE_ID": "56", "DISTRICT_ID": "790", "SUB_DISTRICT_ID": "7095", "ZIPCODE": "71140" },
    { "ZIPCODE_ID": 5921, "SUB_DISTRICT_CODE": "711001", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7096", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5922, "SUB_DISTRICT_CODE": "711002", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7097", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5923, "SUB_DISTRICT_CODE": "711003", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7098", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5924, "SUB_DISTRICT_CODE": "711004", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7099", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5925, "SUB_DISTRICT_CODE": "711005", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7100", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5926, "SUB_DISTRICT_CODE": "711006", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7101", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5927, "SUB_DISTRICT_CODE": "711007", "PROVINCE_ID": "56", "DISTRICT_ID": "791", "SUB_DISTRICT_ID": "7102", "ZIPCODE": "71210" },
    { "ZIPCODE_ID": 5928, "SUB_DISTRICT_CODE": "711101", "PROVINCE_ID": "56", "DISTRICT_ID": "792", "SUB_DISTRICT_ID": "7103", "ZIPCODE": "71260" },
    { "ZIPCODE_ID": 5929, "SUB_DISTRICT_CODE": "711102", "PROVINCE_ID": "56", "DISTRICT_ID": "792", "SUB_DISTRICT_ID": "7104", "ZIPCODE": "71260" },
    { "ZIPCODE_ID": 5930, "SUB_DISTRICT_CODE": "711103", "PROVINCE_ID": "56", "DISTRICT_ID": "792", "SUB_DISTRICT_ID": "7105", "ZIPCODE": "71260" },
    { "ZIPCODE_ID": 5931, "SUB_DISTRICT_CODE": "711104", "PROVINCE_ID": "56", "DISTRICT_ID": "792", "SUB_DISTRICT_ID": "7106", "ZIPCODE": "71260" },
    { "ZIPCODE_ID": 5932, "SUB_DISTRICT_CODE": "711201", "PROVINCE_ID": "56", "DISTRICT_ID": "793", "SUB_DISTRICT_ID": "7107", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5933, "SUB_DISTRICT_CODE": "711202", "PROVINCE_ID": "56", "DISTRICT_ID": "793", "SUB_DISTRICT_ID": "7108", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5934, "SUB_DISTRICT_CODE": "711203", "PROVINCE_ID": "56", "DISTRICT_ID": "793", "SUB_DISTRICT_ID": "7109", "ZIPCODE": "71220" },
    { "ZIPCODE_ID": 5935, "SUB_DISTRICT_CODE": "711301", "PROVINCE_ID": "56", "DISTRICT_ID": "794", "SUB_DISTRICT_ID": "7110", "ZIPCODE": "71170" },
    { "ZIPCODE_ID": 5936, "SUB_DISTRICT_CODE": "711302", "PROVINCE_ID": "56", "DISTRICT_ID": "794", "SUB_DISTRICT_ID": "7111", "ZIPCODE": "71170" },
    { "ZIPCODE_ID": 5937, "SUB_DISTRICT_CODE": "711303", "PROVINCE_ID": "56", "DISTRICT_ID": "794", "SUB_DISTRICT_ID": "7112", "ZIPCODE": "71170" },
    { "ZIPCODE_ID": 5938, "SUB_DISTRICT_CODE": "711304", "PROVINCE_ID": "56", "DISTRICT_ID": "794", "SUB_DISTRICT_ID": "7113", "ZIPCODE": "71170" },
    { "ZIPCODE_ID": 5939, "SUB_DISTRICT_CODE": "720101", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7114", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5940, "SUB_DISTRICT_CODE": "720102", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7115", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5941, "SUB_DISTRICT_CODE": "720103", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7116", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5942, "SUB_DISTRICT_CODE": "720104", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7117", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5943, "SUB_DISTRICT_CODE": "720105", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7118", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5944, "SUB_DISTRICT_CODE": "720106", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7119", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5945, "SUB_DISTRICT_CODE": "720107", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7120", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5946, "SUB_DISTRICT_CODE": "720108", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7121", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5947, "SUB_DISTRICT_CODE": "720109", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7122", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5948, "SUB_DISTRICT_CODE": "720110", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7123", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5949, "SUB_DISTRICT_CODE": "720111", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7124", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5950, "SUB_DISTRICT_CODE": "720112", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7125", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5951, "SUB_DISTRICT_CODE": "720113", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7126", "ZIPCODE": "72230" },
    { "ZIPCODE_ID": 5952, "SUB_DISTRICT_CODE": "720114", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7127", "ZIPCODE": "72230" },
    { "ZIPCODE_ID": 5953, "SUB_DISTRICT_CODE": "720115", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7128", "ZIPCODE": "72210" },
    { "ZIPCODE_ID": 5954, "SUB_DISTRICT_CODE": "720116", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7129", "ZIPCODE": "72210" },
    { "ZIPCODE_ID": 5955, "SUB_DISTRICT_CODE": "720117", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7130", "ZIPCODE": "72210" },
    { "ZIPCODE_ID": 5956, "SUB_DISTRICT_CODE": "720118", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7131", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5957, "SUB_DISTRICT_CODE": "720119", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7132", "ZIPCODE": "72000" },
    { "ZIPCODE_ID": 5958, "SUB_DISTRICT_CODE": "720120", "PROVINCE_ID": "57", "DISTRICT_ID": "797", "SUB_DISTRICT_ID": "7133", "ZIPCODE": "72230" },
    { "ZIPCODE_ID": 5959, "SUB_DISTRICT_CODE": "720201", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7134", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5960, "SUB_DISTRICT_CODE": "720202", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7135", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5961, "SUB_DISTRICT_CODE": "720203", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7136", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5962, "SUB_DISTRICT_CODE": "720204", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7137", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5963, "SUB_DISTRICT_CODE": "720205", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7138", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5964, "SUB_DISTRICT_CODE": "720206", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7139", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5965, "SUB_DISTRICT_CODE": "720207", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7140", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5966, "SUB_DISTRICT_CODE": "720208", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7141", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5967, "SUB_DISTRICT_CODE": "720209", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7142", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5968, "SUB_DISTRICT_CODE": "720210", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7143", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5969, "SUB_DISTRICT_CODE": "720211", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7144", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5970, "SUB_DISTRICT_CODE": "720212", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7145", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5971, "SUB_DISTRICT_CODE": "720213", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7146", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5972, "SUB_DISTRICT_CODE": "720214", "PROVINCE_ID": "57", "DISTRICT_ID": "798", "SUB_DISTRICT_ID": "7147", "ZIPCODE": "72120" },
    { "ZIPCODE_ID": 5973, "SUB_DISTRICT_CODE": "720301", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7152", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5974, "SUB_DISTRICT_CODE": "720302", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7153", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5975, "SUB_DISTRICT_CODE": "720303", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7154", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5976, "SUB_DISTRICT_CODE": "720304", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7155", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5977, "SUB_DISTRICT_CODE": "720305", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7156", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5978, "SUB_DISTRICT_CODE": "720306", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7157", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5979, "SUB_DISTRICT_CODE": "720307", "PROVINCE_ID": "57", "DISTRICT_ID": "799", "SUB_DISTRICT_ID": "7158", "ZIPCODE": "72180" },
    { "ZIPCODE_ID": 5980, "SUB_DISTRICT_CODE": "720401", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7159", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5981, "SUB_DISTRICT_CODE": "720402", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7160", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5982, "SUB_DISTRICT_CODE": "720403", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7161", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5983, "SUB_DISTRICT_CODE": "720404", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7162", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5984, "SUB_DISTRICT_CODE": "720405", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7163", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5985, "SUB_DISTRICT_CODE": "720406", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7164", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5986, "SUB_DISTRICT_CODE": "720407", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7165", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5987, "SUB_DISTRICT_CODE": "720408", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7166", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5988, "SUB_DISTRICT_CODE": "720409", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7167", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5989, "SUB_DISTRICT_CODE": "720410", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7168", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5990, "SUB_DISTRICT_CODE": "720411", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7169", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5991, "SUB_DISTRICT_CODE": "720412", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7170", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5992, "SUB_DISTRICT_CODE": "720413", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7171", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5993, "SUB_DISTRICT_CODE": "720414", "PROVINCE_ID": "57", "DISTRICT_ID": "800", "SUB_DISTRICT_ID": "7172", "ZIPCODE": "72150" },
    { "ZIPCODE_ID": 5994, "SUB_DISTRICT_CODE": "720501", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7173", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 5995, "SUB_DISTRICT_CODE": "720502", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7174", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 5996, "SUB_DISTRICT_CODE": "720503", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7175", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 5997, "SUB_DISTRICT_CODE": "720504", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7176", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 5998, "SUB_DISTRICT_CODE": "720505", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7177", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 5999, "SUB_DISTRICT_CODE": "720506", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7178", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 6000, "SUB_DISTRICT_CODE": "720507", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7179", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 6001, "SUB_DISTRICT_CODE": "720508", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7180", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 6002, "SUB_DISTRICT_CODE": "720509", "PROVINCE_ID": "57", "DISTRICT_ID": "801", "SUB_DISTRICT_ID": "7181", "ZIPCODE": "72140" },
    { "ZIPCODE_ID": 6003, "SUB_DISTRICT_CODE": "720601", "PROVINCE_ID": "57", "DISTRICT_ID": "802", "SUB_DISTRICT_ID": "7182", "ZIPCODE": "72170" },
    { "ZIPCODE_ID": 6004, "SUB_DISTRICT_CODE": "720602", "PROVINCE_ID": "57", "DISTRICT_ID": "802", "SUB_DISTRICT_ID": "7183", "ZIPCODE": "72170" },
    { "ZIPCODE_ID": 6005, "SUB_DISTRICT_CODE": "720603", "PROVINCE_ID": "57", "DISTRICT_ID": "802", "SUB_DISTRICT_ID": "7184", "ZIPCODE": "72170" },
    { "ZIPCODE_ID": 6006, "SUB_DISTRICT_CODE": "720604", "PROVINCE_ID": "57", "DISTRICT_ID": "802", "SUB_DISTRICT_ID": "7185", "ZIPCODE": "72250" },
    { "ZIPCODE_ID": 6007, "SUB_DISTRICT_CODE": "720605", "PROVINCE_ID": "57", "DISTRICT_ID": "802", "SUB_DISTRICT_ID": "7186", "ZIPCODE": "72250" },
    { "ZIPCODE_ID": 6008, "SUB_DISTRICT_CODE": "720701", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7187", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6009, "SUB_DISTRICT_CODE": "720702", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7188", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6010, "SUB_DISTRICT_CODE": "720703", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7189", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6011, "SUB_DISTRICT_CODE": "720704", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7190", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6012, "SUB_DISTRICT_CODE": "720705", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7191", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6013, "SUB_DISTRICT_CODE": "720706", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7192", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6014, "SUB_DISTRICT_CODE": "720707", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7193", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6015, "SUB_DISTRICT_CODE": "720708", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7194", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6016, "SUB_DISTRICT_CODE": "720709", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7195", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6017, "SUB_DISTRICT_CODE": "720710", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7196", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6018, "SUB_DISTRICT_CODE": "720711", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7197", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6019, "SUB_DISTRICT_CODE": "720712", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7198", "ZIPCODE": "72190" },
    { "ZIPCODE_ID": 6020, "SUB_DISTRICT_CODE": "720713", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7199", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6021, "SUB_DISTRICT_CODE": "720714", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7200", "ZIPCODE": "72190" },
    { "ZIPCODE_ID": 6022, "SUB_DISTRICT_CODE": "720715", "PROVINCE_ID": "57", "DISTRICT_ID": "803", "SUB_DISTRICT_ID": "7201", "ZIPCODE": "72110" },
    { "ZIPCODE_ID": 6023, "SUB_DISTRICT_CODE": "720801", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7202", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6024, "SUB_DISTRICT_CODE": "720802", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7203", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6025, "SUB_DISTRICT_CODE": "720803", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7204", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6026, "SUB_DISTRICT_CODE": "720804", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7205", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6027, "SUB_DISTRICT_CODE": "720805", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7206", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6028, "SUB_DISTRICT_CODE": "720806", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7207", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6029, "SUB_DISTRICT_CODE": "720807", "PROVINCE_ID": "57", "DISTRICT_ID": "804", "SUB_DISTRICT_ID": "7208", "ZIPCODE": "72130" },
    { "ZIPCODE_ID": 6030, "SUB_DISTRICT_CODE": "720901", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7213", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6031, "SUB_DISTRICT_CODE": "720902", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7214", "ZIPCODE": "72220" },
    { "ZIPCODE_ID": 6032, "SUB_DISTRICT_CODE": "720903", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7215", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6033, "SUB_DISTRICT_CODE": "720904", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7216", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6034, "SUB_DISTRICT_CODE": "720905", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7217", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6035, "SUB_DISTRICT_CODE": "720906", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7218", "ZIPCODE": "72220" },
    { "ZIPCODE_ID": 6036, "SUB_DISTRICT_CODE": "720907", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7219", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6037, "SUB_DISTRICT_CODE": "720908", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7220", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6038, "SUB_DISTRICT_CODE": "720909", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7221", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6039, "SUB_DISTRICT_CODE": "720910", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7222", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6040, "SUB_DISTRICT_CODE": "720911", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7223", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6041, "SUB_DISTRICT_CODE": "720912", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7224", "ZIPCODE": "72220" },
    { "ZIPCODE_ID": 6042, "SUB_DISTRICT_CODE": "720913", "PROVINCE_ID": "57", "DISTRICT_ID": "805", "SUB_DISTRICT_ID": "7225", "ZIPCODE": "72160" },
    { "ZIPCODE_ID": 6043, "SUB_DISTRICT_CODE": "721001", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7226", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6044, "SUB_DISTRICT_CODE": "721002", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7227", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6045, "SUB_DISTRICT_CODE": "721003", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7228", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6046, "SUB_DISTRICT_CODE": "721004", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7229", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6047, "SUB_DISTRICT_CODE": "721005", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7230", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6048, "SUB_DISTRICT_CODE": "721006", "PROVINCE_ID": "57", "DISTRICT_ID": "806", "SUB_DISTRICT_ID": "7231", "ZIPCODE": "72240" },
    { "ZIPCODE_ID": 6049, "SUB_DISTRICT_CODE": "730101", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7232", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6050, "SUB_DISTRICT_CODE": "730102", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7233", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6051, "SUB_DISTRICT_CODE": "730103", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7234", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6052, "SUB_DISTRICT_CODE": "730104", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7235", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6053, "SUB_DISTRICT_CODE": "730105", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7236", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6054, "SUB_DISTRICT_CODE": "730106", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7237", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6055, "SUB_DISTRICT_CODE": "730107", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7238", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6056, "SUB_DISTRICT_CODE": "730108", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7239", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6057, "SUB_DISTRICT_CODE": "730109", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7240", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6058, "SUB_DISTRICT_CODE": "730110", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7241", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6059, "SUB_DISTRICT_CODE": "730111", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7242", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6060, "SUB_DISTRICT_CODE": "730112", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7243", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6061, "SUB_DISTRICT_CODE": "730113", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7244", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6062, "SUB_DISTRICT_CODE": "730114", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7245", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6063, "SUB_DISTRICT_CODE": "730115", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7246", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6064, "SUB_DISTRICT_CODE": "730116", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7247", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6065, "SUB_DISTRICT_CODE": "730117", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7248", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6066, "SUB_DISTRICT_CODE": "730118", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7249", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6067, "SUB_DISTRICT_CODE": "730119", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7250", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6068, "SUB_DISTRICT_CODE": "730120", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7251", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6069, "SUB_DISTRICT_CODE": "730121", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7252", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6070, "SUB_DISTRICT_CODE": "730122", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7253", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6071, "SUB_DISTRICT_CODE": "730123", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7254", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6072, "SUB_DISTRICT_CODE": "730124", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7255", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6073, "SUB_DISTRICT_CODE": "730125", "PROVINCE_ID": "58", "DISTRICT_ID": "807", "SUB_DISTRICT_ID": "7256", "ZIPCODE": "73000" },
    { "ZIPCODE_ID": 6074, "SUB_DISTRICT_CODE": "730201", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7257", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6075, "SUB_DISTRICT_CODE": "730202", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7258", "ZIPCODE": "73180" },
    { "ZIPCODE_ID": 6076, "SUB_DISTRICT_CODE": "730203", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7259", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6077, "SUB_DISTRICT_CODE": "730204", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7260", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6078, "SUB_DISTRICT_CODE": "730205", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7261", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6079, "SUB_DISTRICT_CODE": "730206", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7262", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6080, "SUB_DISTRICT_CODE": "730207", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7263", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6081, "SUB_DISTRICT_CODE": "730208", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7264", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6082, "SUB_DISTRICT_CODE": "730209", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7265", "ZIPCODE": "73180" },
    { "ZIPCODE_ID": 6083, "SUB_DISTRICT_CODE": "730210", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7266", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6084, "SUB_DISTRICT_CODE": "730211", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7267", "ZIPCODE": "73180" },
    { "ZIPCODE_ID": 6085, "SUB_DISTRICT_CODE": "730212", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7268", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6086, "SUB_DISTRICT_CODE": "730213", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7269", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6087, "SUB_DISTRICT_CODE": "730214", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7270", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6088, "SUB_DISTRICT_CODE": "730215", "PROVINCE_ID": "58", "DISTRICT_ID": "808", "SUB_DISTRICT_ID": "7271", "ZIPCODE": "73140" },
    { "ZIPCODE_ID": 6089, "SUB_DISTRICT_CODE": "730301", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7272", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6090, "SUB_DISTRICT_CODE": "730302", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7273", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6091, "SUB_DISTRICT_CODE": "730303", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7274", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6092, "SUB_DISTRICT_CODE": "730304", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7275", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6093, "SUB_DISTRICT_CODE": "730305", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7276", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6094, "SUB_DISTRICT_CODE": "730306", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7277", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6095, "SUB_DISTRICT_CODE": "730307", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7278", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6096, "SUB_DISTRICT_CODE": "730308", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7279", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6097, "SUB_DISTRICT_CODE": "730309", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7280", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6098, "SUB_DISTRICT_CODE": "730310", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7281", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6099, "SUB_DISTRICT_CODE": "730311", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7282", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6100, "SUB_DISTRICT_CODE": "730312", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7283", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6101, "SUB_DISTRICT_CODE": "730313", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7284", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6102, "SUB_DISTRICT_CODE": "730314", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7285", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6103, "SUB_DISTRICT_CODE": "730315", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7286", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6104, "SUB_DISTRICT_CODE": "730316", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7287", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6105, "SUB_DISTRICT_CODE": "730317", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7288", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6106, "SUB_DISTRICT_CODE": "730318", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7289", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6107, "SUB_DISTRICT_CODE": "730319", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7290", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6108, "SUB_DISTRICT_CODE": "730320", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7291", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6109, "SUB_DISTRICT_CODE": "730321", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7292", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6110, "SUB_DISTRICT_CODE": "730322", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7293", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6111, "SUB_DISTRICT_CODE": "730323", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7294", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6112, "SUB_DISTRICT_CODE": "730324", "PROVINCE_ID": "58", "DISTRICT_ID": "809", "SUB_DISTRICT_ID": "7295", "ZIPCODE": "73120" },
    { "ZIPCODE_ID": 6113, "SUB_DISTRICT_CODE": "730401", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7302", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6114, "SUB_DISTRICT_CODE": "730402", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7303", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6115, "SUB_DISTRICT_CODE": "730403", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7304", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6116, "SUB_DISTRICT_CODE": "730404", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7305", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6117, "SUB_DISTRICT_CODE": "730405", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7306", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6118, "SUB_DISTRICT_CODE": "730406", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7307", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6119, "SUB_DISTRICT_CODE": "730407", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7308", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6120, "SUB_DISTRICT_CODE": "730408", "PROVINCE_ID": "58", "DISTRICT_ID": "810", "SUB_DISTRICT_ID": "7309", "ZIPCODE": "73150" },
    { "ZIPCODE_ID": 6121, "SUB_DISTRICT_CODE": "730501", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7310", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6122, "SUB_DISTRICT_CODE": "730502", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7311", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6123, "SUB_DISTRICT_CODE": "730503", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7312", "ZIPCODE": "73190" },
    { "ZIPCODE_ID": 6124, "SUB_DISTRICT_CODE": "730504", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7313", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6125, "SUB_DISTRICT_CODE": "730505", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7314", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6126, "SUB_DISTRICT_CODE": "730506", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7315", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6127, "SUB_DISTRICT_CODE": "730507", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7316", "ZIPCODE": "73190" },
    { "ZIPCODE_ID": 6128, "SUB_DISTRICT_CODE": "730508", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7317", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6129, "SUB_DISTRICT_CODE": "730509", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7318", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6130, "SUB_DISTRICT_CODE": "730510", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7319", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6131, "SUB_DISTRICT_CODE": "730511", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7320", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6132, "SUB_DISTRICT_CODE": "730512", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7321", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6133, "SUB_DISTRICT_CODE": "730513", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7322", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6134, "SUB_DISTRICT_CODE": "730514", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7323", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6135, "SUB_DISTRICT_CODE": "730515", "PROVINCE_ID": "58", "DISTRICT_ID": "811", "SUB_DISTRICT_ID": "7324", "ZIPCODE": "73130" },
    { "ZIPCODE_ID": 6136, "SUB_DISTRICT_CODE": "730601", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7325", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6137, "SUB_DISTRICT_CODE": "730602", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7326", "ZIPCODE": "73210" },
    { "ZIPCODE_ID": 6138, "SUB_DISTRICT_CODE": "730603", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7327", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6139, "SUB_DISTRICT_CODE": "730604", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7328", "ZIPCODE": "73210" },
    { "ZIPCODE_ID": 6140, "SUB_DISTRICT_CODE": "730605", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7329", "ZIPCODE": "73210" },
    { "ZIPCODE_ID": 6141, "SUB_DISTRICT_CODE": "730606", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7330", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6142, "SUB_DISTRICT_CODE": "730607", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7331", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6143, "SUB_DISTRICT_CODE": "730608", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7332", "ZIPCODE": "73210" },
    { "ZIPCODE_ID": 6144, "SUB_DISTRICT_CODE": "730609", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7333", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6145, "SUB_DISTRICT_CODE": "730610", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7334", "ZIPCODE": "73220" },
    { "ZIPCODE_ID": 6146, "SUB_DISTRICT_CODE": "730611", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7335", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6147, "SUB_DISTRICT_CODE": "730612", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7336", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6148, "SUB_DISTRICT_CODE": "730613", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7337", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6149, "SUB_DISTRICT_CODE": "730614", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7338", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6150, "SUB_DISTRICT_CODE": "730615", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7339", "ZIPCODE": "73110" },
    { "ZIPCODE_ID": 6151, "SUB_DISTRICT_CODE": "730616", "PROVINCE_ID": "58", "DISTRICT_ID": "812", "SUB_DISTRICT_ID": "7340", "ZIPCODE": "73160" },
    { "ZIPCODE_ID": 6152, "SUB_DISTRICT_CODE": "730701", "PROVINCE_ID": "58", "DISTRICT_ID": "813", "SUB_DISTRICT_ID": "7341", "ZIPCODE": "73170" },
    { "ZIPCODE_ID": 6153, "SUB_DISTRICT_CODE": "730702", "PROVINCE_ID": "58", "DISTRICT_ID": "813", "SUB_DISTRICT_ID": "7342", "ZIPCODE": "73170" },
    { "ZIPCODE_ID": 6154, "SUB_DISTRICT_CODE": "730703", "PROVINCE_ID": "58", "DISTRICT_ID": "813", "SUB_DISTRICT_ID": "7343", "ZIPCODE": "73170" },
    { "ZIPCODE_ID": 6155, "SUB_DISTRICT_CODE": "740101", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7344", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6156, "SUB_DISTRICT_CODE": "740102", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7345", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6157, "SUB_DISTRICT_CODE": "740103", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7346", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6158, "SUB_DISTRICT_CODE": "740104", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7347", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6159, "SUB_DISTRICT_CODE": "740105", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7348", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6160, "SUB_DISTRICT_CODE": "740106", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7349", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6161, "SUB_DISTRICT_CODE": "740107", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7350", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6162, "SUB_DISTRICT_CODE": "740108", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7351", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6163, "SUB_DISTRICT_CODE": "740109", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7352", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6164, "SUB_DISTRICT_CODE": "740110", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7353", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6165, "SUB_DISTRICT_CODE": "740111", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7354", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6166, "SUB_DISTRICT_CODE": "740112", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7355", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6167, "SUB_DISTRICT_CODE": "740113", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7356", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6168, "SUB_DISTRICT_CODE": "740114", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7357", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6169, "SUB_DISTRICT_CODE": "740115", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7358", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6170, "SUB_DISTRICT_CODE": "740116", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7359", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6171, "SUB_DISTRICT_CODE": "740117", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7360", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6172, "SUB_DISTRICT_CODE": "740118", "PROVINCE_ID": "59", "DISTRICT_ID": "814", "SUB_DISTRICT_ID": "7361", "ZIPCODE": "74000" },
    { "ZIPCODE_ID": 6173, "SUB_DISTRICT_CODE": "740201", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7362", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6174, "SUB_DISTRICT_CODE": "740202", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7363", "ZIPCODE": "74130" },
    { "ZIPCODE_ID": 6175, "SUB_DISTRICT_CODE": "740203", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7364", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6176, "SUB_DISTRICT_CODE": "740204", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7365", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6177, "SUB_DISTRICT_CODE": "740205", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7366", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6178, "SUB_DISTRICT_CODE": "740206", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7367", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6179, "SUB_DISTRICT_CODE": "740207", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7368", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6180, "SUB_DISTRICT_CODE": "740208", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7369", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6181, "SUB_DISTRICT_CODE": "740209", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7370", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6182, "SUB_DISTRICT_CODE": "740210", "PROVINCE_ID": "59", "DISTRICT_ID": "815", "SUB_DISTRICT_ID": "7371", "ZIPCODE": "74110" },
    { "ZIPCODE_ID": 6183, "SUB_DISTRICT_CODE": "740301", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7372", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6184, "SUB_DISTRICT_CODE": "740302", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7373", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6185, "SUB_DISTRICT_CODE": "740303", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7374", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6186, "SUB_DISTRICT_CODE": "740304", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7375", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6187, "SUB_DISTRICT_CODE": "740305", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7376", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6188, "SUB_DISTRICT_CODE": "740306", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7377", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6189, "SUB_DISTRICT_CODE": "740307", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7378", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6190, "SUB_DISTRICT_CODE": "740308", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7379", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6191, "SUB_DISTRICT_CODE": "740309", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7380", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6192, "SUB_DISTRICT_CODE": "740310", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7381", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6193, "SUB_DISTRICT_CODE": "740311", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7382", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6194, "SUB_DISTRICT_CODE": "740312", "PROVINCE_ID": "59", "DISTRICT_ID": "816", "SUB_DISTRICT_ID": "7383", "ZIPCODE": "74120" },
    { "ZIPCODE_ID": 6195, "SUB_DISTRICT_CODE": "750101", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7384", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6196, "SUB_DISTRICT_CODE": "750102", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7385", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6197, "SUB_DISTRICT_CODE": "750103", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7386", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6198, "SUB_DISTRICT_CODE": "750104", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7387", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6199, "SUB_DISTRICT_CODE": "750105", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7388", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6200, "SUB_DISTRICT_CODE": "750106", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7389", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6201, "SUB_DISTRICT_CODE": "750107", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7390", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6202, "SUB_DISTRICT_CODE": "750108", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7391", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6203, "SUB_DISTRICT_CODE": "750109", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7392", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6204, "SUB_DISTRICT_CODE": "750110", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7393", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6205, "SUB_DISTRICT_CODE": "750111", "PROVINCE_ID": "60", "DISTRICT_ID": "817", "SUB_DISTRICT_ID": "7394", "ZIPCODE": "75000" },
    { "ZIPCODE_ID": 6206, "SUB_DISTRICT_CODE": "750201", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7395", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6207, "SUB_DISTRICT_CODE": "750202", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7396", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6208, "SUB_DISTRICT_CODE": "750203", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7397", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6209, "SUB_DISTRICT_CODE": "750204", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7398", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6210, "SUB_DISTRICT_CODE": "750205", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7399", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6211, "SUB_DISTRICT_CODE": "750206", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7400", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6212, "SUB_DISTRICT_CODE": "750207", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7401", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6213, "SUB_DISTRICT_CODE": "750208", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7402", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6214, "SUB_DISTRICT_CODE": "750209", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7403", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6215, "SUB_DISTRICT_CODE": "750210", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7404", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6216, "SUB_DISTRICT_CODE": "750211", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7405", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6217, "SUB_DISTRICT_CODE": "750212", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7406", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6218, "SUB_DISTRICT_CODE": "750213", "PROVINCE_ID": "60", "DISTRICT_ID": "818", "SUB_DISTRICT_ID": "7407", "ZIPCODE": "75120" },
    { "ZIPCODE_ID": 6219, "SUB_DISTRICT_CODE": "750301", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7408", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6220, "SUB_DISTRICT_CODE": "750302", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7409", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6221, "SUB_DISTRICT_CODE": "750303", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7410", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6222, "SUB_DISTRICT_CODE": "750304", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7411", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6223, "SUB_DISTRICT_CODE": "750305", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7412", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6224, "SUB_DISTRICT_CODE": "750306", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7413", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6225, "SUB_DISTRICT_CODE": "750307", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7414", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6226, "SUB_DISTRICT_CODE": "750308", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7415", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6227, "SUB_DISTRICT_CODE": "750309", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7416", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6228, "SUB_DISTRICT_CODE": "750310", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7417", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6229, "SUB_DISTRICT_CODE": "750311", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7418", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6230, "SUB_DISTRICT_CODE": "750312", "PROVINCE_ID": "60", "DISTRICT_ID": "819", "SUB_DISTRICT_ID": "7419", "ZIPCODE": "75110" },
    { "ZIPCODE_ID": 6231, "SUB_DISTRICT_CODE": "760101", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7420", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6232, "SUB_DISTRICT_CODE": "760102", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7421", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6233, "SUB_DISTRICT_CODE": "760103", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7422", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6234, "SUB_DISTRICT_CODE": "760104", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7423", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6235, "SUB_DISTRICT_CODE": "760105", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7424", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6236, "SUB_DISTRICT_CODE": "760106", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7425", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6237, "SUB_DISTRICT_CODE": "760107", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7426", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6238, "SUB_DISTRICT_CODE": "760108", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7427", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6239, "SUB_DISTRICT_CODE": "760109", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7428", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6240, "SUB_DISTRICT_CODE": "760110", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7429", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6241, "SUB_DISTRICT_CODE": "760111", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7430", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6242, "SUB_DISTRICT_CODE": "760112", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7431", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6243, "SUB_DISTRICT_CODE": "760113", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7432", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6244, "SUB_DISTRICT_CODE": "760114", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7433", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6245, "SUB_DISTRICT_CODE": "760115", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7434", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6246, "SUB_DISTRICT_CODE": "760116", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7435", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6247, "SUB_DISTRICT_CODE": "760117", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7436", "ZIPCODE": "76100" },
    { "ZIPCODE_ID": 6248, "SUB_DISTRICT_CODE": "760118", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7437", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6249, "SUB_DISTRICT_CODE": "760119", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7438", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6250, "SUB_DISTRICT_CODE": "760120", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7439", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6251, "SUB_DISTRICT_CODE": "760121", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7440", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6252, "SUB_DISTRICT_CODE": "760122", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7441", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6253, "SUB_DISTRICT_CODE": "760123", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7442", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6254, "SUB_DISTRICT_CODE": "760124", "PROVINCE_ID": "61", "DISTRICT_ID": "820", "SUB_DISTRICT_ID": "7443", "ZIPCODE": "76000" },
    { "ZIPCODE_ID": 6255, "SUB_DISTRICT_CODE": "760201", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7445", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6256, "SUB_DISTRICT_CODE": "760202", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7446", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6257, "SUB_DISTRICT_CODE": "760203", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7447", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6258, "SUB_DISTRICT_CODE": "760204", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7448", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6259, "SUB_DISTRICT_CODE": "760205", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7449", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6260, "SUB_DISTRICT_CODE": "760206", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7450", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6261, "SUB_DISTRICT_CODE": "760207", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7451", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6262, "SUB_DISTRICT_CODE": "760208", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7452", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6263, "SUB_DISTRICT_CODE": "760209", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7453", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6264, "SUB_DISTRICT_CODE": "760210", "PROVINCE_ID": "61", "DISTRICT_ID": "821", "SUB_DISTRICT_ID": "7454", "ZIPCODE": "76140" },
    { "ZIPCODE_ID": 6265, "SUB_DISTRICT_CODE": "760301", "PROVINCE_ID": "61", "DISTRICT_ID": "822", "SUB_DISTRICT_ID": "7458", "ZIPCODE": "76160" },
    { "ZIPCODE_ID": 6266, "SUB_DISTRICT_CODE": "760302", "PROVINCE_ID": "61", "DISTRICT_ID": "822", "SUB_DISTRICT_ID": "7459", "ZIPCODE": "76160" },
    { "ZIPCODE_ID": 6267, "SUB_DISTRICT_CODE": "760303", "PROVINCE_ID": "61", "DISTRICT_ID": "822", "SUB_DISTRICT_ID": "7460", "ZIPCODE": "76160" },
    { "ZIPCODE_ID": 6268, "SUB_DISTRICT_CODE": "760304", "PROVINCE_ID": "61", "DISTRICT_ID": "822", "SUB_DISTRICT_ID": "7461", "ZIPCODE": "76160" },
    { "ZIPCODE_ID": 6269, "SUB_DISTRICT_CODE": "760401", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7462", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6270, "SUB_DISTRICT_CODE": "760402", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7463", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6271, "SUB_DISTRICT_CODE": "760403", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7464", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6272, "SUB_DISTRICT_CODE": "760404", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7465", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6273, "SUB_DISTRICT_CODE": "760405", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7466", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6274, "SUB_DISTRICT_CODE": "760406", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7467", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6275, "SUB_DISTRICT_CODE": "760407", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7468", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6276, "SUB_DISTRICT_CODE": "760408", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7469", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6277, "SUB_DISTRICT_CODE": "760409", "PROVINCE_ID": "61", "DISTRICT_ID": "823", "SUB_DISTRICT_ID": "7470", "ZIPCODE": "76120" },
    { "ZIPCODE_ID": 6278, "SUB_DISTRICT_CODE": "760501", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7471", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6279, "SUB_DISTRICT_CODE": "760502", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7472", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6280, "SUB_DISTRICT_CODE": "760503", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7473", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6281, "SUB_DISTRICT_CODE": "760504", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7474", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6282, "SUB_DISTRICT_CODE": "760505", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7475", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6283, "SUB_DISTRICT_CODE": "760506", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7476", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6284, "SUB_DISTRICT_CODE": "760507", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7477", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6285, "SUB_DISTRICT_CODE": "760511", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7481", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6286, "SUB_DISTRICT_CODE": "760512", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7482", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6287, "SUB_DISTRICT_CODE": "760513", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7483", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6288, "SUB_DISTRICT_CODE": "760514", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7484", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6289, "SUB_DISTRICT_CODE": "760515", "PROVINCE_ID": "61", "DISTRICT_ID": "824", "SUB_DISTRICT_ID": "7485", "ZIPCODE": "76130" },
    { "ZIPCODE_ID": 6290, "SUB_DISTRICT_CODE": "760601", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7492", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6291, "SUB_DISTRICT_CODE": "760602", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7493", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6292, "SUB_DISTRICT_CODE": "760603", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7494", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6293, "SUB_DISTRICT_CODE": "760604", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7495", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6294, "SUB_DISTRICT_CODE": "760605", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7496", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6295, "SUB_DISTRICT_CODE": "760606", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7497", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6296, "SUB_DISTRICT_CODE": "760607", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7498", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6297, "SUB_DISTRICT_CODE": "760608", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7499", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6298, "SUB_DISTRICT_CODE": "760609", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7500", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6299, "SUB_DISTRICT_CODE": "760610", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7501", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6300, "SUB_DISTRICT_CODE": "760611", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7502", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6301, "SUB_DISTRICT_CODE": "760612", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7503", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6302, "SUB_DISTRICT_CODE": "760613", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7504", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6303, "SUB_DISTRICT_CODE": "760614", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7505", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6304, "SUB_DISTRICT_CODE": "760615", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7506", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6305, "SUB_DISTRICT_CODE": "760616", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7507", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6306, "SUB_DISTRICT_CODE": "760617", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7508", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6307, "SUB_DISTRICT_CODE": "760618", "PROVINCE_ID": "61", "DISTRICT_ID": "825", "SUB_DISTRICT_ID": "7509", "ZIPCODE": "76150" },
    { "ZIPCODE_ID": 6308, "SUB_DISTRICT_CODE": "760701", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7510", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6309, "SUB_DISTRICT_CODE": "760702", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7511", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6310, "SUB_DISTRICT_CODE": "760703", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7512", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6311, "SUB_DISTRICT_CODE": "760704", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7513", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6312, "SUB_DISTRICT_CODE": "760705", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7514", "ZIPCODE": "76100" },
    { "ZIPCODE_ID": 6313, "SUB_DISTRICT_CODE": "760706", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7515", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6314, "SUB_DISTRICT_CODE": "760707", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7516", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6315, "SUB_DISTRICT_CODE": "760708", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7517", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6316, "SUB_DISTRICT_CODE": "760709", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7518", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6317, "SUB_DISTRICT_CODE": "760710", "PROVINCE_ID": "61", "DISTRICT_ID": "826", "SUB_DISTRICT_ID": "7519", "ZIPCODE": "76110" },
    { "ZIPCODE_ID": 6318, "SUB_DISTRICT_CODE": "760801", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7520", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6319, "SUB_DISTRICT_CODE": "760802", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7521", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6320, "SUB_DISTRICT_CODE": "760803", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7522", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6321, "SUB_DISTRICT_CODE": "760804", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7523", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6322, "SUB_DISTRICT_CODE": "760805", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7524", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6323, "SUB_DISTRICT_CODE": "760806", "PROVINCE_ID": "61", "DISTRICT_ID": "827", "SUB_DISTRICT_ID": "7525", "ZIPCODE": "76170" },
    { "ZIPCODE_ID": 6324, "SUB_DISTRICT_CODE": "770101", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7526", "ZIPCODE": "77000" },
    { "ZIPCODE_ID": 6325, "SUB_DISTRICT_CODE": "770102", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7527", "ZIPCODE": "77000" },
    { "ZIPCODE_ID": 6326, "SUB_DISTRICT_CODE": "770103", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7528", "ZIPCODE": "77000" },
    { "ZIPCODE_ID": 6327, "SUB_DISTRICT_CODE": "770104", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7529", "ZIPCODE": "77000" },
    { "ZIPCODE_ID": 6328, "SUB_DISTRICT_CODE": "770105", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7530", "ZIPCODE": "77000" },
    { "ZIPCODE_ID": 6329, "SUB_DISTRICT_CODE": "770106", "PROVINCE_ID": "62", "DISTRICT_ID": "828", "SUB_DISTRICT_ID": "7531", "ZIPCODE": "77210" },
    { "ZIPCODE_ID": 6330, "SUB_DISTRICT_CODE": "770201", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7532", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6331, "SUB_DISTRICT_CODE": "770202", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7533", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6332, "SUB_DISTRICT_CODE": "770203", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7534", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6333, "SUB_DISTRICT_CODE": "770204", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7535", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6334, "SUB_DISTRICT_CODE": "770206", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7537", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6335, "SUB_DISTRICT_CODE": "770207", "PROVINCE_ID": "62", "DISTRICT_ID": "829", "SUB_DISTRICT_ID": "7538", "ZIPCODE": "77150" },
    { "ZIPCODE_ID": 6336, "SUB_DISTRICT_CODE": "770301", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7539", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6337, "SUB_DISTRICT_CODE": "770302", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7540", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6338, "SUB_DISTRICT_CODE": "770303", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7541", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6339, "SUB_DISTRICT_CODE": "770304", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7542", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6340, "SUB_DISTRICT_CODE": "770305", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7543", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6341, "SUB_DISTRICT_CODE": "770306", "PROVINCE_ID": "62", "DISTRICT_ID": "830", "SUB_DISTRICT_ID": "7544", "ZIPCODE": "77130" },
    { "ZIPCODE_ID": 6342, "SUB_DISTRICT_CODE": "770401", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7545", "ZIPCODE": "77140" },
    { "ZIPCODE_ID": 6343, "SUB_DISTRICT_CODE": "770402", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7546", "ZIPCODE": "77140" },
    { "ZIPCODE_ID": 6344, "SUB_DISTRICT_CODE": "770403", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7547", "ZIPCODE": "77230" },
    { "ZIPCODE_ID": 6345, "SUB_DISTRICT_CODE": "770404", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7548", "ZIPCODE": "77190" },
    { "ZIPCODE_ID": 6346, "SUB_DISTRICT_CODE": "770405", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7549", "ZIPCODE": "77190" },
    { "ZIPCODE_ID": 6347, "SUB_DISTRICT_CODE": "770406", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7550", "ZIPCODE": "77230" },
    { "ZIPCODE_ID": 6348, "SUB_DISTRICT_CODE": "770407", "PROVINCE_ID": "62", "DISTRICT_ID": "831", "SUB_DISTRICT_ID": "7551", "ZIPCODE": "77140" },
    { "ZIPCODE_ID": 6349, "SUB_DISTRICT_CODE": "770501", "PROVINCE_ID": "62", "DISTRICT_ID": "832", "SUB_DISTRICT_ID": "7552", "ZIPCODE": "77170" },
    { "ZIPCODE_ID": 6350, "SUB_DISTRICT_CODE": "770502", "PROVINCE_ID": "62", "DISTRICT_ID": "832", "SUB_DISTRICT_ID": "7553", "ZIPCODE": "77170" },
    { "ZIPCODE_ID": 6351, "SUB_DISTRICT_CODE": "770503", "PROVINCE_ID": "62", "DISTRICT_ID": "832", "SUB_DISTRICT_ID": "7554", "ZIPCODE": "77170" },
    { "ZIPCODE_ID": 6352, "SUB_DISTRICT_CODE": "770504", "PROVINCE_ID": "62", "DISTRICT_ID": "832", "SUB_DISTRICT_ID": "7555", "ZIPCODE": "77170" },
    { "ZIPCODE_ID": 6353, "SUB_DISTRICT_CODE": "770505", "PROVINCE_ID": "62", "DISTRICT_ID": "832", "SUB_DISTRICT_ID": "7556", "ZIPCODE": "77170" },
    { "ZIPCODE_ID": 6354, "SUB_DISTRICT_CODE": "770601", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7557", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6355, "SUB_DISTRICT_CODE": "770602", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7558", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6356, "SUB_DISTRICT_CODE": "770604", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7560", "ZIPCODE": "77220" },
    { "ZIPCODE_ID": 6357, "SUB_DISTRICT_CODE": "770607", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7563", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6358, "SUB_DISTRICT_CODE": "770608", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7564", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6359, "SUB_DISTRICT_CODE": "770609", "PROVINCE_ID": "62", "DISTRICT_ID": "833", "SUB_DISTRICT_ID": "7565", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6360, "SUB_DISTRICT_CODE": "770701", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7566", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6361, "SUB_DISTRICT_CODE": "770702", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7567", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6362, "SUB_DISTRICT_CODE": "770703", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7568", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6363, "SUB_DISTRICT_CODE": "770704", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7569", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6364, "SUB_DISTRICT_CODE": "770705", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7570", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6365, "SUB_DISTRICT_CODE": "770706", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7571", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6366, "SUB_DISTRICT_CODE": "770707", "PROVINCE_ID": "62", "DISTRICT_ID": "834", "SUB_DISTRICT_ID": "7572", "ZIPCODE": "77110" },
    { "ZIPCODE_ID": 6367, "SUB_DISTRICT_CODE": "770801", "PROVINCE_ID": "62", "DISTRICT_ID": "835", "SUB_DISTRICT_ID": "7573", "ZIPCODE": "77120" },
    { "ZIPCODE_ID": 6368, "SUB_DISTRICT_CODE": "770802", "PROVINCE_ID": "62", "DISTRICT_ID": "835", "SUB_DISTRICT_ID": "7574", "ZIPCODE": "77180" },
    { "ZIPCODE_ID": 6369, "SUB_DISTRICT_CODE": "770803", "PROVINCE_ID": "62", "DISTRICT_ID": "835", "SUB_DISTRICT_ID": "7575", "ZIPCODE": "77180" },
    { "ZIPCODE_ID": 6370, "SUB_DISTRICT_CODE": "770804", "PROVINCE_ID": "62", "DISTRICT_ID": "835", "SUB_DISTRICT_ID": "7576", "ZIPCODE": "77180" },
    { "ZIPCODE_ID": 6371, "SUB_DISTRICT_CODE": "770805", "PROVINCE_ID": "62", "DISTRICT_ID": "835", "SUB_DISTRICT_ID": "7577", "ZIPCODE": "77180" },
    { "ZIPCODE_ID": 6372, "SUB_DISTRICT_CODE": "800101", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7578", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6373, "SUB_DISTRICT_CODE": "800102", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7579", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6374, "SUB_DISTRICT_CODE": "800103", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7580", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6375, "SUB_DISTRICT_CODE": "800106", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7583", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6376, "SUB_DISTRICT_CODE": "800107", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7584", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6377, "SUB_DISTRICT_CODE": "800108", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7585", "ZIPCODE": "80280" },
    { "ZIPCODE_ID": 6378, "SUB_DISTRICT_CODE": "800112", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7589", "ZIPCODE": "80280" },
    { "ZIPCODE_ID": 6379, "SUB_DISTRICT_CODE": "800113", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7590", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6380, "SUB_DISTRICT_CODE": "800114", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7591", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6381, "SUB_DISTRICT_CODE": "800115", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7592", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6382, "SUB_DISTRICT_CODE": "800116", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7593", "ZIPCODE": "80280" },
    { "ZIPCODE_ID": 6383, "SUB_DISTRICT_CODE": "800118", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7595", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6384, "SUB_DISTRICT_CODE": "800119", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7596", "ZIPCODE": "80330" },
    { "ZIPCODE_ID": 6385, "SUB_DISTRICT_CODE": "800120", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7597", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6386, "SUB_DISTRICT_CODE": "800121", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7598", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6387, "SUB_DISTRICT_CODE": "800122", "PROVINCE_ID": "63", "DISTRICT_ID": "836", "SUB_DISTRICT_ID": "7599", "ZIPCODE": "80290" },
    { "ZIPCODE_ID": 6388, "SUB_DISTRICT_CODE": "800201", "PROVINCE_ID": "63", "DISTRICT_ID": "837", "SUB_DISTRICT_ID": "7605", "ZIPCODE": "80320" },
    { "ZIPCODE_ID": 6389, "SUB_DISTRICT_CODE": "800202", "PROVINCE_ID": "63", "DISTRICT_ID": "837", "SUB_DISTRICT_ID": "7606", "ZIPCODE": "80320" },
    { "ZIPCODE_ID": 6390, "SUB_DISTRICT_CODE": "800203", "PROVINCE_ID": "63", "DISTRICT_ID": "837", "SUB_DISTRICT_ID": "7607", "ZIPCODE": "80320" },
    { "ZIPCODE_ID": 6391, "SUB_DISTRICT_CODE": "800204", "PROVINCE_ID": "63", "DISTRICT_ID": "837", "SUB_DISTRICT_ID": "7608", "ZIPCODE": "80320" },
    { "ZIPCODE_ID": 6392, "SUB_DISTRICT_CODE": "800205", "PROVINCE_ID": "63", "DISTRICT_ID": "837", "SUB_DISTRICT_ID": "7609", "ZIPCODE": "80320" },
    { "ZIPCODE_ID": 6393, "SUB_DISTRICT_CODE": "800301", "PROVINCE_ID": "63", "DISTRICT_ID": "838", "SUB_DISTRICT_ID": "7610", "ZIPCODE": "80230" },
    { "ZIPCODE_ID": 6394, "SUB_DISTRICT_CODE": "800302", "PROVINCE_ID": "63", "DISTRICT_ID": "838", "SUB_DISTRICT_ID": "7611", "ZIPCODE": "80230" },
    { "ZIPCODE_ID": 6395, "SUB_DISTRICT_CODE": "800303", "PROVINCE_ID": "63", "DISTRICT_ID": "838", "SUB_DISTRICT_ID": "7612", "ZIPCODE": "80230" },
    { "ZIPCODE_ID": 6396, "SUB_DISTRICT_CODE": "800304", "PROVINCE_ID": "63", "DISTRICT_ID": "838", "SUB_DISTRICT_ID": "7613", "ZIPCODE": "80230" },
    { "ZIPCODE_ID": 6397, "SUB_DISTRICT_CODE": "800305", "PROVINCE_ID": "63", "DISTRICT_ID": "838", "SUB_DISTRICT_ID": "7614", "ZIPCODE": "80230" },
    { "ZIPCODE_ID": 6398, "SUB_DISTRICT_CODE": "800401", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7615", "ZIPCODE": "80150" },
    { "ZIPCODE_ID": 6399, "SUB_DISTRICT_CODE": "800403", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7617", "ZIPCODE": "80250" },
    { "ZIPCODE_ID": 6400, "SUB_DISTRICT_CODE": "800404", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7618", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6401, "SUB_DISTRICT_CODE": "800405", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7619", "ZIPCODE": "80150" },
    { "ZIPCODE_ID": 6402, "SUB_DISTRICT_CODE": "800406", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7620", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6403, "SUB_DISTRICT_CODE": "800407", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7621", "ZIPCODE": "80150" },
    { "ZIPCODE_ID": 6404, "SUB_DISTRICT_CODE": "800409", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7623", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6405, "SUB_DISTRICT_CODE": "800410", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7624", "ZIPCODE": "80150" },
    { "ZIPCODE_ID": 6406, "SUB_DISTRICT_CODE": "800415", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7629", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6407, "SUB_DISTRICT_CODE": "800416", "PROVINCE_ID": "63", "DISTRICT_ID": "839", "SUB_DISTRICT_ID": "7630", "ZIPCODE": "80250" },
    { "ZIPCODE_ID": 6408, "SUB_DISTRICT_CODE": "800501", "PROVINCE_ID": "63", "DISTRICT_ID": "840", "SUB_DISTRICT_ID": "7631", "ZIPCODE": "80270" },
    { "ZIPCODE_ID": 6409, "SUB_DISTRICT_CODE": "800502", "PROVINCE_ID": "63", "DISTRICT_ID": "840", "SUB_DISTRICT_ID": "7632", "ZIPCODE": "80270" },
    { "ZIPCODE_ID": 6410, "SUB_DISTRICT_CODE": "800503", "PROVINCE_ID": "63", "DISTRICT_ID": "840", "SUB_DISTRICT_ID": "7633", "ZIPCODE": "80270" },
    { "ZIPCODE_ID": 6411, "SUB_DISTRICT_CODE": "800504", "PROVINCE_ID": "63", "DISTRICT_ID": "840", "SUB_DISTRICT_ID": "7634", "ZIPCODE": "80270" },
    { "ZIPCODE_ID": 6412, "SUB_DISTRICT_CODE": "800505", "PROVINCE_ID": "63", "DISTRICT_ID": "840", "SUB_DISTRICT_ID": "7635", "ZIPCODE": "80270" },
    { "ZIPCODE_ID": 6413, "SUB_DISTRICT_CODE": "800601", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7636", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6414, "SUB_DISTRICT_CODE": "800603", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7638", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6415, "SUB_DISTRICT_CODE": "800604", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7639", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6416, "SUB_DISTRICT_CODE": "800605", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7640", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6417, "SUB_DISTRICT_CODE": "800606", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7641", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6418, "SUB_DISTRICT_CODE": "800607", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7642", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6419, "SUB_DISTRICT_CODE": "800610", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7645", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6420, "SUB_DISTRICT_CODE": "800611", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7646", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6421, "SUB_DISTRICT_CODE": "800612", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7647", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6422, "SUB_DISTRICT_CODE": "800613", "PROVINCE_ID": "63", "DISTRICT_ID": "841", "SUB_DISTRICT_ID": "7648", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6423, "SUB_DISTRICT_CODE": "800701", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7649", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6424, "SUB_DISTRICT_CODE": "800702", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7650", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6425, "SUB_DISTRICT_CODE": "800703", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7651", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6426, "SUB_DISTRICT_CODE": "800704", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7652", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6427, "SUB_DISTRICT_CODE": "800705", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7653", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6428, "SUB_DISTRICT_CODE": "800706", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7654", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6429, "SUB_DISTRICT_CODE": "800707", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7655", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6430, "SUB_DISTRICT_CODE": "800708", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7656", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6431, "SUB_DISTRICT_CODE": "800709", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7657", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6432, "SUB_DISTRICT_CODE": "800710", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7658", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6433, "SUB_DISTRICT_CODE": "800711", "PROVINCE_ID": "63", "DISTRICT_ID": "842", "SUB_DISTRICT_ID": "7659", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6434, "SUB_DISTRICT_CODE": "800801", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7662", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6435, "SUB_DISTRICT_CODE": "800802", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7663", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6436, "SUB_DISTRICT_CODE": "800803", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7664", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6437, "SUB_DISTRICT_CODE": "800804", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7665", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6438, "SUB_DISTRICT_CODE": "800806", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7667", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6439, "SUB_DISTRICT_CODE": "800807", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7668", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6440, "SUB_DISTRICT_CODE": "800809", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7670", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6441, "SUB_DISTRICT_CODE": "800810", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7671", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6442, "SUB_DISTRICT_CODE": "800811", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7672", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6443, "SUB_DISTRICT_CODE": "800813", "PROVINCE_ID": "63", "DISTRICT_ID": "843", "SUB_DISTRICT_ID": "7674", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6444, "SUB_DISTRICT_CODE": "800901", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7676", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6445, "SUB_DISTRICT_CODE": "800902", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7677", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6446, "SUB_DISTRICT_CODE": "800903", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7678", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6447, "SUB_DISTRICT_CODE": "800904", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7679", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6448, "SUB_DISTRICT_CODE": "800905", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7680", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6449, "SUB_DISTRICT_CODE": "800906", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7681", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6450, "SUB_DISTRICT_CODE": "800907", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7682", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6451, "SUB_DISTRICT_CODE": "800908", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7683", "ZIPCODE": "80310" },
    { "ZIPCODE_ID": 6452, "SUB_DISTRICT_CODE": "800909", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7684", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6453, "SUB_DISTRICT_CODE": "800910", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7685", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6454, "SUB_DISTRICT_CODE": "800911", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7686", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6455, "SUB_DISTRICT_CODE": "800912", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7687", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6456, "SUB_DISTRICT_CODE": "800913", "PROVINCE_ID": "63", "DISTRICT_ID": "844", "SUB_DISTRICT_ID": "7688", "ZIPCODE": "80110" },
    { "ZIPCODE_ID": 6457, "SUB_DISTRICT_CODE": "801001", "PROVINCE_ID": "63", "DISTRICT_ID": "845", "SUB_DISTRICT_ID": "7695", "ZIPCODE": "80220" },
    { "ZIPCODE_ID": 6458, "SUB_DISTRICT_CODE": "801002", "PROVINCE_ID": "63", "DISTRICT_ID": "845", "SUB_DISTRICT_ID": "7696", "ZIPCODE": "80220" },
    { "ZIPCODE_ID": 6459, "SUB_DISTRICT_CODE": "801003", "PROVINCE_ID": "63", "DISTRICT_ID": "845", "SUB_DISTRICT_ID": "7697", "ZIPCODE": "80220" },
    { "ZIPCODE_ID": 6460, "SUB_DISTRICT_CODE": "801101", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7698", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6461, "SUB_DISTRICT_CODE": "801102", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7699", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6462, "SUB_DISTRICT_CODE": "801103", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7700", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6463, "SUB_DISTRICT_CODE": "801104", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7701", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6464, "SUB_DISTRICT_CODE": "801105", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7702", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6465, "SUB_DISTRICT_CODE": "801106", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7703", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6466, "SUB_DISTRICT_CODE": "801107", "PROVINCE_ID": "63", "DISTRICT_ID": "846", "SUB_DISTRICT_ID": "7704", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6467, "SUB_DISTRICT_CODE": "801201", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7705", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6468, "SUB_DISTRICT_CODE": "801202", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7706", "ZIPCODE": "80330" },
    { "ZIPCODE_ID": 6469, "SUB_DISTRICT_CODE": "801203", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7707", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6470, "SUB_DISTRICT_CODE": "801204", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7708", "ZIPCODE": "80330" },
    { "ZIPCODE_ID": 6471, "SUB_DISTRICT_CODE": "801205", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7709", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6472, "SUB_DISTRICT_CODE": "801206", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7710", "ZIPCODE": "80330" },
    { "ZIPCODE_ID": 6473, "SUB_DISTRICT_CODE": "801207", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7711", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6474, "SUB_DISTRICT_CODE": "801208", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7712", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6475, "SUB_DISTRICT_CODE": "801209", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7713", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6476, "SUB_DISTRICT_CODE": "801210", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7714", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6477, "SUB_DISTRICT_CODE": "801211", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7715", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6478, "SUB_DISTRICT_CODE": "801212", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7716", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6479, "SUB_DISTRICT_CODE": "801213", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7717", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6480, "SUB_DISTRICT_CODE": "801214", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7718", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6481, "SUB_DISTRICT_CODE": "801215", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7719", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6482, "SUB_DISTRICT_CODE": "801216", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7720", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6483, "SUB_DISTRICT_CODE": "801217", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7721", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6484, "SUB_DISTRICT_CODE": "801218", "PROVINCE_ID": "63", "DISTRICT_ID": "847", "SUB_DISTRICT_ID": "7722", "ZIPCODE": "80140" },
    { "ZIPCODE_ID": 6485, "SUB_DISTRICT_CODE": "801301", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7723", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6486, "SUB_DISTRICT_CODE": "801302", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7724", "ZIPCODE": "80350" },
    { "ZIPCODE_ID": 6487, "SUB_DISTRICT_CODE": "801303", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7725", "ZIPCODE": "80350" },
    { "ZIPCODE_ID": 6488, "SUB_DISTRICT_CODE": "801304", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7726", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6489, "SUB_DISTRICT_CODE": "801305", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7727", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6490, "SUB_DISTRICT_CODE": "801306", "PROVINCE_ID": "63", "DISTRICT_ID": "848", "SUB_DISTRICT_ID": "7728", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6491, "SUB_DISTRICT_CODE": "801401", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7734", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6492, "SUB_DISTRICT_CODE": "801402", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7735", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6493, "SUB_DISTRICT_CODE": "801403", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7736", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6494, "SUB_DISTRICT_CODE": "801404", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7737", "ZIPCODE": "80340" },
    { "ZIPCODE_ID": 6495, "SUB_DISTRICT_CODE": "801405", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7738", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6496, "SUB_DISTRICT_CODE": "801406", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7739", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6497, "SUB_DISTRICT_CODE": "801407", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7740", "ZIPCODE": "80340" },
    { "ZIPCODE_ID": 6498, "SUB_DISTRICT_CODE": "801408", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7741", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6499, "SUB_DISTRICT_CODE": "801409", "PROVINCE_ID": "63", "DISTRICT_ID": "849", "SUB_DISTRICT_ID": "7742", "ZIPCODE": "80120" },
    { "ZIPCODE_ID": 6500, "SUB_DISTRICT_CODE": "801501", "PROVINCE_ID": "63", "DISTRICT_ID": "850", "SUB_DISTRICT_ID": "7743", "ZIPCODE": "80210" },
    { "ZIPCODE_ID": 6501, "SUB_DISTRICT_CODE": "801502", "PROVINCE_ID": "63", "DISTRICT_ID": "850", "SUB_DISTRICT_ID": "7744", "ZIPCODE": "80210" },
    { "ZIPCODE_ID": 6502, "SUB_DISTRICT_CODE": "801503", "PROVINCE_ID": "63", "DISTRICT_ID": "850", "SUB_DISTRICT_ID": "7745", "ZIPCODE": "80210" },
    { "ZIPCODE_ID": 6503, "SUB_DISTRICT_CODE": "801601", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7746", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6504, "SUB_DISTRICT_CODE": "801602", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7747", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6505, "SUB_DISTRICT_CODE": "801603", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7748", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6506, "SUB_DISTRICT_CODE": "801604", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7749", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6507, "SUB_DISTRICT_CODE": "801605", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7750", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6508, "SUB_DISTRICT_CODE": "801606", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7751", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6509, "SUB_DISTRICT_CODE": "801607", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7752", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6510, "SUB_DISTRICT_CODE": "801608", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7753", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6511, "SUB_DISTRICT_CODE": "801609", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7754", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6512, "SUB_DISTRICT_CODE": "801610", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7755", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6513, "SUB_DISTRICT_CODE": "801611", "PROVINCE_ID": "63", "DISTRICT_ID": "851", "SUB_DISTRICT_ID": "7756", "ZIPCODE": "80170" },
    { "ZIPCODE_ID": 6514, "SUB_DISTRICT_CODE": "801701", "PROVINCE_ID": "63", "DISTRICT_ID": "852", "SUB_DISTRICT_ID": "7757", "ZIPCODE": "80360" },
    { "ZIPCODE_ID": 6515, "SUB_DISTRICT_CODE": "801702", "PROVINCE_ID": "63", "DISTRICT_ID": "852", "SUB_DISTRICT_ID": "7758", "ZIPCODE": "80360" },
    { "ZIPCODE_ID": 6516, "SUB_DISTRICT_CODE": "801703", "PROVINCE_ID": "63", "DISTRICT_ID": "852", "SUB_DISTRICT_ID": "7759", "ZIPCODE": "80360" },
    { "ZIPCODE_ID": 6517, "SUB_DISTRICT_CODE": "801704", "PROVINCE_ID": "63", "DISTRICT_ID": "852", "SUB_DISTRICT_ID": "7760", "ZIPCODE": "80360" },
    { "ZIPCODE_ID": 6518, "SUB_DISTRICT_CODE": "801801", "PROVINCE_ID": "63", "DISTRICT_ID": "853", "SUB_DISTRICT_ID": "7761", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6519, "SUB_DISTRICT_CODE": "801802", "PROVINCE_ID": "63", "DISTRICT_ID": "853", "SUB_DISTRICT_ID": "7762", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6520, "SUB_DISTRICT_CODE": "801803", "PROVINCE_ID": "63", "DISTRICT_ID": "853", "SUB_DISTRICT_ID": "7763", "ZIPCODE": "80260" },
    { "ZIPCODE_ID": 6521, "SUB_DISTRICT_CODE": "801901", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7764", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6522, "SUB_DISTRICT_CODE": "801902", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7765", "ZIPCODE": "80180" },
    { "ZIPCODE_ID": 6523, "SUB_DISTRICT_CODE": "801903", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7766", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6524, "SUB_DISTRICT_CODE": "801904", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7767", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6525, "SUB_DISTRICT_CODE": "801905", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7768", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6526, "SUB_DISTRICT_CODE": "801906", "PROVINCE_ID": "63", "DISTRICT_ID": "854", "SUB_DISTRICT_ID": "7769", "ZIPCODE": "80130" },
    { "ZIPCODE_ID": 6527, "SUB_DISTRICT_CODE": "802001", "PROVINCE_ID": "63", "DISTRICT_ID": "855", "SUB_DISTRICT_ID": "7770", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6528, "SUB_DISTRICT_CODE": "802002", "PROVINCE_ID": "63", "DISTRICT_ID": "855", "SUB_DISTRICT_ID": "7771", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6529, "SUB_DISTRICT_CODE": "802003", "PROVINCE_ID": "63", "DISTRICT_ID": "855", "SUB_DISTRICT_ID": "7772", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6530, "SUB_DISTRICT_CODE": "802004", "PROVINCE_ID": "63", "DISTRICT_ID": "855", "SUB_DISTRICT_ID": "7773", "ZIPCODE": "80000" },
    { "ZIPCODE_ID": 6531, "SUB_DISTRICT_CODE": "802101", "PROVINCE_ID": "63", "DISTRICT_ID": "856", "SUB_DISTRICT_ID": "7774", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6532, "SUB_DISTRICT_CODE": "802102", "PROVINCE_ID": "63", "DISTRICT_ID": "856", "SUB_DISTRICT_ID": "7775", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6533, "SUB_DISTRICT_CODE": "802103", "PROVINCE_ID": "63", "DISTRICT_ID": "856", "SUB_DISTRICT_ID": "7776", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6534, "SUB_DISTRICT_CODE": "802104", "PROVINCE_ID": "63", "DISTRICT_ID": "856", "SUB_DISTRICT_ID": "7777", "ZIPCODE": "80160" },
    { "ZIPCODE_ID": 6535, "SUB_DISTRICT_CODE": "802201", "PROVINCE_ID": "63", "DISTRICT_ID": "857", "SUB_DISTRICT_ID": "7778", "ZIPCODE": "80250" },
    { "ZIPCODE_ID": 6536, "SUB_DISTRICT_CODE": "802202", "PROVINCE_ID": "63", "DISTRICT_ID": "857", "SUB_DISTRICT_ID": "7779", "ZIPCODE": "80250" },
    { "ZIPCODE_ID": 6537, "SUB_DISTRICT_CODE": "802203", "PROVINCE_ID": "63", "DISTRICT_ID": "857", "SUB_DISTRICT_ID": "7780", "ZIPCODE": "80250" },
    { "ZIPCODE_ID": 6538, "SUB_DISTRICT_CODE": "802301", "PROVINCE_ID": "63", "DISTRICT_ID": "858", "SUB_DISTRICT_ID": "7781", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6539, "SUB_DISTRICT_CODE": "802302", "PROVINCE_ID": "63", "DISTRICT_ID": "858", "SUB_DISTRICT_ID": "7782", "ZIPCODE": "80290" },
    { "ZIPCODE_ID": 6540, "SUB_DISTRICT_CODE": "802303", "PROVINCE_ID": "63", "DISTRICT_ID": "858", "SUB_DISTRICT_ID": "7783", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6541, "SUB_DISTRICT_CODE": "802304", "PROVINCE_ID": "63", "DISTRICT_ID": "858", "SUB_DISTRICT_ID": "7784", "ZIPCODE": "80190" },
    { "ZIPCODE_ID": 6542, "SUB_DISTRICT_CODE": "810101", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7785", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6543, "SUB_DISTRICT_CODE": "810102", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7786", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6544, "SUB_DISTRICT_CODE": "810103", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7787", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6545, "SUB_DISTRICT_CODE": "810105", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7789", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6546, "SUB_DISTRICT_CODE": "810106", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7790", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6547, "SUB_DISTRICT_CODE": "810111", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7795", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6548, "SUB_DISTRICT_CODE": "810115", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7799", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6549, "SUB_DISTRICT_CODE": "810116", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7800", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6550, "SUB_DISTRICT_CODE": "810117", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7801", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6551, "SUB_DISTRICT_CODE": "810118", "PROVINCE_ID": "64", "DISTRICT_ID": "864", "SUB_DISTRICT_ID": "7802", "ZIPCODE": "81000" },
    { "ZIPCODE_ID": 6552, "SUB_DISTRICT_CODE": "810201", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7811", "ZIPCODE": "81140" },
    { "ZIPCODE_ID": 6553, "SUB_DISTRICT_CODE": "810202", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7812", "ZIPCODE": "81140" },
    { "ZIPCODE_ID": 6554, "SUB_DISTRICT_CODE": "810203", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7813", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6555, "SUB_DISTRICT_CODE": "810204", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7814", "ZIPCODE": "81140" },
    { "ZIPCODE_ID": 6556, "SUB_DISTRICT_CODE": "810205", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7815", "ZIPCODE": "81140" },
    { "ZIPCODE_ID": 6557, "SUB_DISTRICT_CODE": "810206", "PROVINCE_ID": "64", "DISTRICT_ID": "865", "SUB_DISTRICT_ID": "7816", "ZIPCODE": "80240" },
    { "ZIPCODE_ID": 6558, "SUB_DISTRICT_CODE": "810301", "PROVINCE_ID": "64", "DISTRICT_ID": "866", "SUB_DISTRICT_ID": "7817", "ZIPCODE": "81150" },
    { "ZIPCODE_ID": 6559, "SUB_DISTRICT_CODE": "810302", "PROVINCE_ID": "64", "DISTRICT_ID": "866", "SUB_DISTRICT_ID": "7818", "ZIPCODE": "81150" },
    { "ZIPCODE_ID": 6560, "SUB_DISTRICT_CODE": "810303", "PROVINCE_ID": "64", "DISTRICT_ID": "866", "SUB_DISTRICT_ID": "7819", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6561, "SUB_DISTRICT_CODE": "810304", "PROVINCE_ID": "64", "DISTRICT_ID": "866", "SUB_DISTRICT_ID": "7820", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6562, "SUB_DISTRICT_CODE": "810305", "PROVINCE_ID": "64", "DISTRICT_ID": "866", "SUB_DISTRICT_ID": "7821", "ZIPCODE": "81150" },
    { "ZIPCODE_ID": 6563, "SUB_DISTRICT_CODE": "810401", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7822", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6564, "SUB_DISTRICT_CODE": "810402", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7823", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6565, "SUB_DISTRICT_CODE": "810403", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7824", "ZIPCODE": "81170" },
    { "ZIPCODE_ID": 6566, "SUB_DISTRICT_CODE": "810404", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7825", "ZIPCODE": "81170" },
    { "ZIPCODE_ID": 6567, "SUB_DISTRICT_CODE": "810405", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7826", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6568, "SUB_DISTRICT_CODE": "810406", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7827", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6569, "SUB_DISTRICT_CODE": "810407", "PROVINCE_ID": "64", "DISTRICT_ID": "867", "SUB_DISTRICT_ID": "7828", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6570, "SUB_DISTRICT_CODE": "810501", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7830", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6571, "SUB_DISTRICT_CODE": "810502", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7831", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6572, "SUB_DISTRICT_CODE": "810503", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7832", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6573, "SUB_DISTRICT_CODE": "810504", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7833", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6574, "SUB_DISTRICT_CODE": "810505", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7834", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6575, "SUB_DISTRICT_CODE": "810506", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7835", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6576, "SUB_DISTRICT_CODE": "810507", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7836", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6577, "SUB_DISTRICT_CODE": "810508", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7837", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6578, "SUB_DISTRICT_CODE": "810509", "PROVINCE_ID": "64", "DISTRICT_ID": "868", "SUB_DISTRICT_ID": "7838", "ZIPCODE": "81110" },
    { "ZIPCODE_ID": 6579, "SUB_DISTRICT_CODE": "810601", "PROVINCE_ID": "64", "DISTRICT_ID": "869", "SUB_DISTRICT_ID": "7842", "ZIPCODE": "81160" },
    { "ZIPCODE_ID": 6580, "SUB_DISTRICT_CODE": "810602", "PROVINCE_ID": "64", "DISTRICT_ID": "869", "SUB_DISTRICT_ID": "7843", "ZIPCODE": "81160" },
    { "ZIPCODE_ID": 6581, "SUB_DISTRICT_CODE": "810603", "PROVINCE_ID": "64", "DISTRICT_ID": "869", "SUB_DISTRICT_ID": "7844", "ZIPCODE": "81160" },
    { "ZIPCODE_ID": 6582, "SUB_DISTRICT_CODE": "810604", "PROVINCE_ID": "64", "DISTRICT_ID": "869", "SUB_DISTRICT_ID": "7845", "ZIPCODE": "81160" },
    { "ZIPCODE_ID": 6583, "SUB_DISTRICT_CODE": "810701", "PROVINCE_ID": "64", "DISTRICT_ID": "870", "SUB_DISTRICT_ID": "7846", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6584, "SUB_DISTRICT_CODE": "810702", "PROVINCE_ID": "64", "DISTRICT_ID": "870", "SUB_DISTRICT_ID": "7847", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6585, "SUB_DISTRICT_CODE": "810703", "PROVINCE_ID": "64", "DISTRICT_ID": "870", "SUB_DISTRICT_ID": "7848", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6586, "SUB_DISTRICT_CODE": "810704", "PROVINCE_ID": "64", "DISTRICT_ID": "870", "SUB_DISTRICT_ID": "7849", "ZIPCODE": "81120" },
    { "ZIPCODE_ID": 6587, "SUB_DISTRICT_CODE": "810801", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7850", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6588, "SUB_DISTRICT_CODE": "810802", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7851", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6589, "SUB_DISTRICT_CODE": "810803", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7852", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6590, "SUB_DISTRICT_CODE": "810804", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7853", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6591, "SUB_DISTRICT_CODE": "810805", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7854", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6592, "SUB_DISTRICT_CODE": "810806", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7855", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6593, "SUB_DISTRICT_CODE": "810807", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7856", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6594, "SUB_DISTRICT_CODE": "810808", "PROVINCE_ID": "64", "DISTRICT_ID": "871", "SUB_DISTRICT_ID": "7857", "ZIPCODE": "81130" },
    { "ZIPCODE_ID": 6595, "SUB_DISTRICT_CODE": "820101", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7858", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6596, "SUB_DISTRICT_CODE": "820102", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7859", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6597, "SUB_DISTRICT_CODE": "820103", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7860", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6598, "SUB_DISTRICT_CODE": "820104", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7861", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6599, "SUB_DISTRICT_CODE": "820105", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7862", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6600, "SUB_DISTRICT_CODE": "820106", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7863", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6601, "SUB_DISTRICT_CODE": "820107", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7864", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6602, "SUB_DISTRICT_CODE": "820108", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7865", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6603, "SUB_DISTRICT_CODE": "820109", "PROVINCE_ID": "65", "DISTRICT_ID": "872", "SUB_DISTRICT_ID": "7866", "ZIPCODE": "82000" },
    { "ZIPCODE_ID": 6604, "SUB_DISTRICT_CODE": "820201", "PROVINCE_ID": "65", "DISTRICT_ID": "873", "SUB_DISTRICT_ID": "7869", "ZIPCODE": "82160" },
    { "ZIPCODE_ID": 6605, "SUB_DISTRICT_CODE": "820202", "PROVINCE_ID": "65", "DISTRICT_ID": "873", "SUB_DISTRICT_ID": "7870", "ZIPCODE": "82160" },
    { "ZIPCODE_ID": 6606, "SUB_DISTRICT_CODE": "820203", "PROVINCE_ID": "65", "DISTRICT_ID": "873", "SUB_DISTRICT_ID": "7871", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6607, "SUB_DISTRICT_CODE": "820301", "PROVINCE_ID": "65", "DISTRICT_ID": "874", "SUB_DISTRICT_ID": "7872", "ZIPCODE": "82170" },
    { "ZIPCODE_ID": 6608, "SUB_DISTRICT_CODE": "820302", "PROVINCE_ID": "65", "DISTRICT_ID": "874", "SUB_DISTRICT_ID": "7873", "ZIPCODE": "82170" },
    { "ZIPCODE_ID": 6609, "SUB_DISTRICT_CODE": "820303", "PROVINCE_ID": "65", "DISTRICT_ID": "874", "SUB_DISTRICT_ID": "7874", "ZIPCODE": "82170" },
    { "ZIPCODE_ID": 6610, "SUB_DISTRICT_CODE": "820304", "PROVINCE_ID": "65", "DISTRICT_ID": "874", "SUB_DISTRICT_ID": "7875", "ZIPCODE": "82170" },
    { "ZIPCODE_ID": 6611, "SUB_DISTRICT_CODE": "820305", "PROVINCE_ID": "65", "DISTRICT_ID": "874", "SUB_DISTRICT_ID": "7876", "ZIPCODE": "82170" },
    { "ZIPCODE_ID": 6612, "SUB_DISTRICT_CODE": "820401", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7877", "ZIPCODE": "82130" },
    { "ZIPCODE_ID": 6613, "SUB_DISTRICT_CODE": "820402", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7878", "ZIPCODE": "82130" },
    { "ZIPCODE_ID": 6614, "SUB_DISTRICT_CODE": "820403", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7879", "ZIPCODE": "82130" },
    { "ZIPCODE_ID": 6615, "SUB_DISTRICT_CODE": "820404", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7880", "ZIPCODE": "82130" },
    { "ZIPCODE_ID": 6616, "SUB_DISTRICT_CODE": "820405", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7881", "ZIPCODE": "82140" },
    { "ZIPCODE_ID": 6617, "SUB_DISTRICT_CODE": "820406", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7882", "ZIPCODE": "82140" },
    { "ZIPCODE_ID": 6618, "SUB_DISTRICT_CODE": "820407", "PROVINCE_ID": "65", "DISTRICT_ID": "875", "SUB_DISTRICT_ID": "7883", "ZIPCODE": "82130" },
    { "ZIPCODE_ID": 6619, "SUB_DISTRICT_CODE": "820501", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7884", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6620, "SUB_DISTRICT_CODE": "820502", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7885", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6621, "SUB_DISTRICT_CODE": "820503", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7886", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6622, "SUB_DISTRICT_CODE": "820504", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7887", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6623, "SUB_DISTRICT_CODE": "820505", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7888", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6624, "SUB_DISTRICT_CODE": "820506", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7889", "ZIPCODE": "82110" },
    { "ZIPCODE_ID": 6625, "SUB_DISTRICT_CODE": "820507", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7890", "ZIPCODE": "82190" },
    { "ZIPCODE_ID": 6626, "SUB_DISTRICT_CODE": "820508", "PROVINCE_ID": "65", "DISTRICT_ID": "876", "SUB_DISTRICT_ID": "7891", "ZIPCODE": "82190" },
    { "ZIPCODE_ID": 6627, "SUB_DISTRICT_CODE": "820601", "PROVINCE_ID": "65", "DISTRICT_ID": "877", "SUB_DISTRICT_ID": "7892", "ZIPCODE": "82150" },
    { "ZIPCODE_ID": 6628, "SUB_DISTRICT_CODE": "820602", "PROVINCE_ID": "65", "DISTRICT_ID": "877", "SUB_DISTRICT_ID": "7893", "ZIPCODE": "82150" },
    { "ZIPCODE_ID": 6629, "SUB_DISTRICT_CODE": "820603", "PROVINCE_ID": "65", "DISTRICT_ID": "877", "SUB_DISTRICT_ID": "7894", "ZIPCODE": "82150" },
    { "ZIPCODE_ID": 6630, "SUB_DISTRICT_CODE": "820605", "PROVINCE_ID": "65", "DISTRICT_ID": "877", "SUB_DISTRICT_ID": "7896", "ZIPCODE": "82150" },
    { "ZIPCODE_ID": 6631, "SUB_DISTRICT_CODE": "820701", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7897", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6632, "SUB_DISTRICT_CODE": "820702", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7898", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6633, "SUB_DISTRICT_CODE": "820703", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7899", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6634, "SUB_DISTRICT_CODE": "820704", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7900", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6635, "SUB_DISTRICT_CODE": "820705", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7901", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6636, "SUB_DISTRICT_CODE": "820706", "PROVINCE_ID": "65", "DISTRICT_ID": "878", "SUB_DISTRICT_ID": "7902", "ZIPCODE": "82180" },
    { "ZIPCODE_ID": 6637, "SUB_DISTRICT_CODE": "820801", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7903", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6638, "SUB_DISTRICT_CODE": "820802", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7904", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6639, "SUB_DISTRICT_CODE": "820803", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7905", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6640, "SUB_DISTRICT_CODE": "820804", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7906", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6641, "SUB_DISTRICT_CODE": "820805", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7907", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6642, "SUB_DISTRICT_CODE": "820806", "PROVINCE_ID": "65", "DISTRICT_ID": "879", "SUB_DISTRICT_ID": "7908", "ZIPCODE": "82120" },
    { "ZIPCODE_ID": 6643, "SUB_DISTRICT_CODE": "830101", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7909", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6644, "SUB_DISTRICT_CODE": "830102", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7910", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6645, "SUB_DISTRICT_CODE": "830103", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7911", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6646, "SUB_DISTRICT_CODE": "830104", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7912", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6647, "SUB_DISTRICT_CODE": "830105", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7913", "ZIPCODE": "83000" },
    { "ZIPCODE_ID": 6648, "SUB_DISTRICT_CODE": "830106", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7914", "ZIPCODE": "83130" },
    { "ZIPCODE_ID": 6649, "SUB_DISTRICT_CODE": "830107", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7915", "ZIPCODE": "83130" },
    { "ZIPCODE_ID": 6650, "SUB_DISTRICT_CODE": "830108", "PROVINCE_ID": "66", "DISTRICT_ID": "880", "SUB_DISTRICT_ID": "7916", "ZIPCODE": "83100" },
    { "ZIPCODE_ID": 6651, "SUB_DISTRICT_CODE": "830201", "PROVINCE_ID": "66", "DISTRICT_ID": "881", "SUB_DISTRICT_ID": "7917", "ZIPCODE": "83120" },
    { "ZIPCODE_ID": 6652, "SUB_DISTRICT_CODE": "830202", "PROVINCE_ID": "66", "DISTRICT_ID": "881", "SUB_DISTRICT_ID": "7918", "ZIPCODE": "83150" },
    { "ZIPCODE_ID": 6653, "SUB_DISTRICT_CODE": "830203", "PROVINCE_ID": "66", "DISTRICT_ID": "881", "SUB_DISTRICT_ID": "7919", "ZIPCODE": "83150" },
    { "ZIPCODE_ID": 6654, "SUB_DISTRICT_CODE": "830301", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7920", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6655, "SUB_DISTRICT_CODE": "830302", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7921", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6656, "SUB_DISTRICT_CODE": "830303", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7922", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6657, "SUB_DISTRICT_CODE": "830304", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7923", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6658, "SUB_DISTRICT_CODE": "830305", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7924", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6659, "SUB_DISTRICT_CODE": "830306", "PROVINCE_ID": "66", "DISTRICT_ID": "882", "SUB_DISTRICT_ID": "7925", "ZIPCODE": "83110" },
    { "ZIPCODE_ID": 6660, "SUB_DISTRICT_CODE": "840101", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7926", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6661, "SUB_DISTRICT_CODE": "840102", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7927", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6662, "SUB_DISTRICT_CODE": "840103", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7928", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6663, "SUB_DISTRICT_CODE": "840104", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7929", "ZIPCODE": "84100" },
    { "ZIPCODE_ID": 6664, "SUB_DISTRICT_CODE": "840105", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7930", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6665, "SUB_DISTRICT_CODE": "840106", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7931", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6666, "SUB_DISTRICT_CODE": "840107", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7932", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6667, "SUB_DISTRICT_CODE": "840108", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7933", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6668, "SUB_DISTRICT_CODE": "840109", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7934", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6669, "SUB_DISTRICT_CODE": "840110", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7935", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6670, "SUB_DISTRICT_CODE": "840111", "PROVINCE_ID": "67", "DISTRICT_ID": "884", "SUB_DISTRICT_ID": "7936", "ZIPCODE": "84000" },
    { "ZIPCODE_ID": 6671, "SUB_DISTRICT_CODE": "840201", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7937", "ZIPCODE": "84290" },
    { "ZIPCODE_ID": 6672, "SUB_DISTRICT_CODE": "840202", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7938", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6673, "SUB_DISTRICT_CODE": "840203", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7939", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6674, "SUB_DISTRICT_CODE": "840204", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7940", "ZIPCODE": "84290" },
    { "ZIPCODE_ID": 6675, "SUB_DISTRICT_CODE": "840205", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7941", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6676, "SUB_DISTRICT_CODE": "840206", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7942", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6677, "SUB_DISTRICT_CODE": "840207", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7943", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6678, "SUB_DISTRICT_CODE": "840208", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7944", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6679, "SUB_DISTRICT_CODE": "840209", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7945", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6680, "SUB_DISTRICT_CODE": "840210", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7946", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6681, "SUB_DISTRICT_CODE": "840211", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7947", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6682, "SUB_DISTRICT_CODE": "840212", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7948", "ZIPCODE": "84290" },
    { "ZIPCODE_ID": 6683, "SUB_DISTRICT_CODE": "840213", "PROVINCE_ID": "67", "DISTRICT_ID": "885", "SUB_DISTRICT_ID": "7949", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6684, "SUB_DISTRICT_CODE": "840301", "PROVINCE_ID": "67", "DISTRICT_ID": "886", "SUB_DISTRICT_ID": "7950", "ZIPCODE": "84220" },
    { "ZIPCODE_ID": 6685, "SUB_DISTRICT_CODE": "840302", "PROVINCE_ID": "67", "DISTRICT_ID": "886", "SUB_DISTRICT_ID": "7951", "ZIPCODE": "84160" },
    { "ZIPCODE_ID": 6686, "SUB_DISTRICT_CODE": "840303", "PROVINCE_ID": "67", "DISTRICT_ID": "886", "SUB_DISTRICT_ID": "7952", "ZIPCODE": "84220" },
    { "ZIPCODE_ID": 6687, "SUB_DISTRICT_CODE": "840304", "PROVINCE_ID": "67", "DISTRICT_ID": "886", "SUB_DISTRICT_ID": "7953", "ZIPCODE": "84340" },
    { "ZIPCODE_ID": 6688, "SUB_DISTRICT_CODE": "840401", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7954", "ZIPCODE": "84140" },
    { "ZIPCODE_ID": 6689, "SUB_DISTRICT_CODE": "840402", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7955", "ZIPCODE": "84140" },
    { "ZIPCODE_ID": 6690, "SUB_DISTRICT_CODE": "840403", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7956", "ZIPCODE": "84140" },
    { "ZIPCODE_ID": 6691, "SUB_DISTRICT_CODE": "840404", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7957", "ZIPCODE": "84140" },
    { "ZIPCODE_ID": 6692, "SUB_DISTRICT_CODE": "840405", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7958", "ZIPCODE": "84310" },
    { "ZIPCODE_ID": 6693, "SUB_DISTRICT_CODE": "840406", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7959", "ZIPCODE": "84320" },
    { "ZIPCODE_ID": 6694, "SUB_DISTRICT_CODE": "840407", "PROVINCE_ID": "67", "DISTRICT_ID": "887", "SUB_DISTRICT_ID": "7960", "ZIPCODE": "84330" },
    { "ZIPCODE_ID": 6695, "SUB_DISTRICT_CODE": "840501", "PROVINCE_ID": "67", "DISTRICT_ID": "888", "SUB_DISTRICT_ID": "7961", "ZIPCODE": "84280" },
    { "ZIPCODE_ID": 6696, "SUB_DISTRICT_CODE": "840502", "PROVINCE_ID": "67", "DISTRICT_ID": "888", "SUB_DISTRICT_ID": "7962", "ZIPCODE": "84280" },
    { "ZIPCODE_ID": 6697, "SUB_DISTRICT_CODE": "840503", "PROVINCE_ID": "67", "DISTRICT_ID": "888", "SUB_DISTRICT_ID": "7963", "ZIPCODE": "84280" },
    { "ZIPCODE_ID": 6698, "SUB_DISTRICT_CODE": "840601", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7964", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6699, "SUB_DISTRICT_CODE": "840602", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7965", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6700, "SUB_DISTRICT_CODE": "840603", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7966", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6701, "SUB_DISTRICT_CODE": "840604", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7967", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6702, "SUB_DISTRICT_CODE": "840605", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7968", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6703, "SUB_DISTRICT_CODE": "840606", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7969", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6704, "SUB_DISTRICT_CODE": "840607", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7970", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6705, "SUB_DISTRICT_CODE": "840608", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7971", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6706, "SUB_DISTRICT_CODE": "840609", "PROVINCE_ID": "67", "DISTRICT_ID": "889", "SUB_DISTRICT_ID": "7972", "ZIPCODE": "84110" },
    { "ZIPCODE_ID": 6707, "SUB_DISTRICT_CODE": "840701", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7973", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6708, "SUB_DISTRICT_CODE": "840702", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7974", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6709, "SUB_DISTRICT_CODE": "840703", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7975", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6710, "SUB_DISTRICT_CODE": "840704", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7976", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6711, "SUB_DISTRICT_CODE": "840705", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7977", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6712, "SUB_DISTRICT_CODE": "840706", "PROVINCE_ID": "67", "DISTRICT_ID": "890", "SUB_DISTRICT_ID": "7978", "ZIPCODE": "84170" },
    { "ZIPCODE_ID": 6713, "SUB_DISTRICT_CODE": "840801", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7979", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6714, "SUB_DISTRICT_CODE": "840802", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7980", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6715, "SUB_DISTRICT_CODE": "840803", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7981", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6716, "SUB_DISTRICT_CODE": "840806", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7984", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6717, "SUB_DISTRICT_CODE": "840807", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7985", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6718, "SUB_DISTRICT_CODE": "840808", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7986", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6719, "SUB_DISTRICT_CODE": "840809", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7987", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6720, "SUB_DISTRICT_CODE": "840810", "PROVINCE_ID": "67", "DISTRICT_ID": "891", "SUB_DISTRICT_ID": "7988", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6721, "SUB_DISTRICT_CODE": "840901", "PROVINCE_ID": "67", "DISTRICT_ID": "892", "SUB_DISTRICT_ID": "7990", "ZIPCODE": "84230" },
    { "ZIPCODE_ID": 6722, "SUB_DISTRICT_CODE": "840902", "PROVINCE_ID": "67", "DISTRICT_ID": "892", "SUB_DISTRICT_ID": "7991", "ZIPCODE": "84230" },
    { "ZIPCODE_ID": 6723, "SUB_DISTRICT_CODE": "840903", "PROVINCE_ID": "67", "DISTRICT_ID": "892", "SUB_DISTRICT_ID": "7992", "ZIPCODE": "84230" },
    { "ZIPCODE_ID": 6724, "SUB_DISTRICT_CODE": "840904", "PROVINCE_ID": "67", "DISTRICT_ID": "892", "SUB_DISTRICT_ID": "7993", "ZIPCODE": "84230" },
    { "ZIPCODE_ID": 6725, "SUB_DISTRICT_CODE": "841001", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "7995", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6726, "SUB_DISTRICT_CODE": "841002", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "7996", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6727, "SUB_DISTRICT_CODE": "841003", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "7997", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6728, "SUB_DISTRICT_CODE": "841004", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "7998", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6729, "SUB_DISTRICT_CODE": "841005", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "7999", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6730, "SUB_DISTRICT_CODE": "841006", "PROVINCE_ID": "67", "DISTRICT_ID": "893", "SUB_DISTRICT_ID": "8000", "ZIPCODE": "84250" },
    { "ZIPCODE_ID": 6731, "SUB_DISTRICT_CODE": "841101", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8001", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6732, "SUB_DISTRICT_CODE": "841102", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8002", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6733, "SUB_DISTRICT_CODE": "841103", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8003", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6734, "SUB_DISTRICT_CODE": "841104", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8004", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6735, "SUB_DISTRICT_CODE": "841105", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8005", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6736, "SUB_DISTRICT_CODE": "841106", "PROVINCE_ID": "67", "DISTRICT_ID": "894", "SUB_DISTRICT_ID": "8006", "ZIPCODE": "84150" },
    { "ZIPCODE_ID": 6737, "SUB_DISTRICT_CODE": "841201", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8007", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6738, "SUB_DISTRICT_CODE": "841202", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8008", "ZIPCODE": "84270" },
    { "ZIPCODE_ID": 6739, "SUB_DISTRICT_CODE": "841203", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8009", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6740, "SUB_DISTRICT_CODE": "841204", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8010", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6741, "SUB_DISTRICT_CODE": "841205", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8011", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6742, "SUB_DISTRICT_CODE": "841206", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8012", "ZIPCODE": "84270" },
    { "ZIPCODE_ID": 6743, "SUB_DISTRICT_CODE": "841207", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8013", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6744, "SUB_DISTRICT_CODE": "841208", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8014", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6745, "SUB_DISTRICT_CODE": "841209", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8015", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6746, "SUB_DISTRICT_CODE": "841210", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8016", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6747, "SUB_DISTRICT_CODE": "841211", "PROVINCE_ID": "67", "DISTRICT_ID": "895", "SUB_DISTRICT_ID": "8017", "ZIPCODE": "84120" },
    { "ZIPCODE_ID": 6748, "SUB_DISTRICT_CODE": "841301", "PROVINCE_ID": "67", "DISTRICT_ID": "896", "SUB_DISTRICT_ID": "8020", "ZIPCODE": "84240" },
    { "ZIPCODE_ID": 6749, "SUB_DISTRICT_CODE": "841302", "PROVINCE_ID": "67", "DISTRICT_ID": "896", "SUB_DISTRICT_ID": "8021", "ZIPCODE": "84240" },
    { "ZIPCODE_ID": 6750, "SUB_DISTRICT_CODE": "841303", "PROVINCE_ID": "67", "DISTRICT_ID": "896", "SUB_DISTRICT_ID": "8022", "ZIPCODE": "84240" },
    { "ZIPCODE_ID": 6751, "SUB_DISTRICT_CODE": "841304", "PROVINCE_ID": "67", "DISTRICT_ID": "896", "SUB_DISTRICT_ID": "8023", "ZIPCODE": "84240" },
    { "ZIPCODE_ID": 6752, "SUB_DISTRICT_CODE": "841401", "PROVINCE_ID": "67", "DISTRICT_ID": "897", "SUB_DISTRICT_ID": "8024", "ZIPCODE": "84260" },
    { "ZIPCODE_ID": 6753, "SUB_DISTRICT_CODE": "841402", "PROVINCE_ID": "67", "DISTRICT_ID": "897", "SUB_DISTRICT_ID": "8025", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6754, "SUB_DISTRICT_CODE": "841403", "PROVINCE_ID": "67", "DISTRICT_ID": "897", "SUB_DISTRICT_ID": "8026", "ZIPCODE": "84260" },
    { "ZIPCODE_ID": 6755, "SUB_DISTRICT_CODE": "841404", "PROVINCE_ID": "67", "DISTRICT_ID": "897", "SUB_DISTRICT_ID": "8027", "ZIPCODE": "84260" },
    { "ZIPCODE_ID": 6756, "SUB_DISTRICT_CODE": "841405", "PROVINCE_ID": "67", "DISTRICT_ID": "897", "SUB_DISTRICT_ID": "8028", "ZIPCODE": "84260" },
    { "ZIPCODE_ID": 6757, "SUB_DISTRICT_CODE": "841501", "PROVINCE_ID": "67", "DISTRICT_ID": "898", "SUB_DISTRICT_ID": "8029", "ZIPCODE": "84190" },
    { "ZIPCODE_ID": 6758, "SUB_DISTRICT_CODE": "841502", "PROVINCE_ID": "67", "DISTRICT_ID": "898", "SUB_DISTRICT_ID": "8030", "ZIPCODE": "84190" },
    { "ZIPCODE_ID": 6759, "SUB_DISTRICT_CODE": "841503", "PROVINCE_ID": "67", "DISTRICT_ID": "898", "SUB_DISTRICT_ID": "8031", "ZIPCODE": "84190" },
    { "ZIPCODE_ID": 6760, "SUB_DISTRICT_CODE": "841504", "PROVINCE_ID": "67", "DISTRICT_ID": "898", "SUB_DISTRICT_ID": "8032", "ZIPCODE": "84190" },
    { "ZIPCODE_ID": 6761, "SUB_DISTRICT_CODE": "841505", "PROVINCE_ID": "67", "DISTRICT_ID": "898", "SUB_DISTRICT_ID": "8033", "ZIPCODE": "84190" },
    { "ZIPCODE_ID": 6762, "SUB_DISTRICT_CODE": "841601", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8034", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6763, "SUB_DISTRICT_CODE": "841602", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8035", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6764, "SUB_DISTRICT_CODE": "841603", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8036", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6765, "SUB_DISTRICT_CODE": "841604", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8037", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6766, "SUB_DISTRICT_CODE": "841605", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8038", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6767, "SUB_DISTRICT_CODE": "841606", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8039", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6768, "SUB_DISTRICT_CODE": "841607", "PROVINCE_ID": "67", "DISTRICT_ID": "899", "SUB_DISTRICT_ID": "8040", "ZIPCODE": "84210" },
    { "ZIPCODE_ID": 6769, "SUB_DISTRICT_CODE": "841701", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8043", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6770, "SUB_DISTRICT_CODE": "841702", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8044", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6771, "SUB_DISTRICT_CODE": "841703", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8045", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6772, "SUB_DISTRICT_CODE": "841704", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8046", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6773, "SUB_DISTRICT_CODE": "841705", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8047", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6774, "SUB_DISTRICT_CODE": "841706", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8048", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6775, "SUB_DISTRICT_CODE": "841707", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8049", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6776, "SUB_DISTRICT_CODE": "841708", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8050", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6777, "SUB_DISTRICT_CODE": "841709", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8051", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6778, "SUB_DISTRICT_CODE": "841710", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8052", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6779, "SUB_DISTRICT_CODE": "841711", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8053", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6780, "SUB_DISTRICT_CODE": "841712", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8054", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6781, "SUB_DISTRICT_CODE": "841713", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8055", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6782, "SUB_DISTRICT_CODE": "841714", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8056", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6783, "SUB_DISTRICT_CODE": "841715", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8057", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6784, "SUB_DISTRICT_CODE": "841716", "PROVINCE_ID": "67", "DISTRICT_ID": "900", "SUB_DISTRICT_ID": "8058", "ZIPCODE": "84130" },
    { "ZIPCODE_ID": 6785, "SUB_DISTRICT_CODE": "841801", "PROVINCE_ID": "67", "DISTRICT_ID": "901", "SUB_DISTRICT_ID": "8060", "ZIPCODE": "84350" },
    { "ZIPCODE_ID": 6786, "SUB_DISTRICT_CODE": "841802", "PROVINCE_ID": "67", "DISTRICT_ID": "901", "SUB_DISTRICT_ID": "8061", "ZIPCODE": "84350" },
    { "ZIPCODE_ID": 6787, "SUB_DISTRICT_CODE": "841803", "PROVINCE_ID": "67", "DISTRICT_ID": "901", "SUB_DISTRICT_ID": "8062", "ZIPCODE": "84350" },
    { "ZIPCODE_ID": 6788, "SUB_DISTRICT_CODE": "841804", "PROVINCE_ID": "67", "DISTRICT_ID": "901", "SUB_DISTRICT_ID": "8063", "ZIPCODE": "84350" },
    { "ZIPCODE_ID": 6789, "SUB_DISTRICT_CODE": "841901", "PROVINCE_ID": "67", "DISTRICT_ID": "902", "SUB_DISTRICT_ID": "8064", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6790, "SUB_DISTRICT_CODE": "841902", "PROVINCE_ID": "67", "DISTRICT_ID": "902", "SUB_DISTRICT_ID": "8065", "ZIPCODE": "84180" },
    { "ZIPCODE_ID": 6791, "SUB_DISTRICT_CODE": "850101", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8066", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6792, "SUB_DISTRICT_CODE": "850102", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8067", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6793, "SUB_DISTRICT_CODE": "850103", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8068", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6794, "SUB_DISTRICT_CODE": "850104", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8069", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6795, "SUB_DISTRICT_CODE": "850105", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8070", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6796, "SUB_DISTRICT_CODE": "850106", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8071", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6797, "SUB_DISTRICT_CODE": "850107", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8072", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6798, "SUB_DISTRICT_CODE": "850108", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8073", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6799, "SUB_DISTRICT_CODE": "850109", "PROVINCE_ID": "68", "DISTRICT_ID": "905", "SUB_DISTRICT_ID": "8074", "ZIPCODE": "85000" },
    { "ZIPCODE_ID": 6800, "SUB_DISTRICT_CODE": "850201", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8075", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6801, "SUB_DISTRICT_CODE": "850202", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8076", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6802, "SUB_DISTRICT_CODE": "850203", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8077", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6803, "SUB_DISTRICT_CODE": "850204", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8078", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6804, "SUB_DISTRICT_CODE": "850205", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8079", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6805, "SUB_DISTRICT_CODE": "850206", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8080", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6806, "SUB_DISTRICT_CODE": "850207", "PROVINCE_ID": "68", "DISTRICT_ID": "906", "SUB_DISTRICT_ID": "8081", "ZIPCODE": "85130" },
    { "ZIPCODE_ID": 6807, "SUB_DISTRICT_CODE": "850301", "PROVINCE_ID": "68", "DISTRICT_ID": "907", "SUB_DISTRICT_ID": "8082", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6808, "SUB_DISTRICT_CODE": "850302", "PROVINCE_ID": "68", "DISTRICT_ID": "907", "SUB_DISTRICT_ID": "8083", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6809, "SUB_DISTRICT_CODE": "850303", "PROVINCE_ID": "68", "DISTRICT_ID": "907", "SUB_DISTRICT_ID": "8084", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6810, "SUB_DISTRICT_CODE": "850304", "PROVINCE_ID": "68", "DISTRICT_ID": "907", "SUB_DISTRICT_ID": "8085", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6811, "SUB_DISTRICT_CODE": "850305", "PROVINCE_ID": "68", "DISTRICT_ID": "907", "SUB_DISTRICT_ID": "8086", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6812, "SUB_DISTRICT_CODE": "850401", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8089", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6813, "SUB_DISTRICT_CODE": "850402", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8090", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6814, "SUB_DISTRICT_CODE": "850403", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8091", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6815, "SUB_DISTRICT_CODE": "850404", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8092", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6816, "SUB_DISTRICT_CODE": "850405", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8093", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6817, "SUB_DISTRICT_CODE": "850406", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8094", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6818, "SUB_DISTRICT_CODE": "850407", "PROVINCE_ID": "68", "DISTRICT_ID": "908", "SUB_DISTRICT_ID": "8095", "ZIPCODE": "85110" },
    { "ZIPCODE_ID": 6819, "SUB_DISTRICT_CODE": "850501", "PROVINCE_ID": "68", "DISTRICT_ID": "909", "SUB_DISTRICT_ID": "8096", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6820, "SUB_DISTRICT_CODE": "850502", "PROVINCE_ID": "68", "DISTRICT_ID": "909", "SUB_DISTRICT_ID": "8097", "ZIPCODE": "85120" },
    { "ZIPCODE_ID": 6821, "SUB_DISTRICT_CODE": "860101", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8098", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6822, "SUB_DISTRICT_CODE": "860102", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8099", "ZIPCODE": "86120" },
    { "ZIPCODE_ID": 6823, "SUB_DISTRICT_CODE": "860103", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8100", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6824, "SUB_DISTRICT_CODE": "860104", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8101", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6825, "SUB_DISTRICT_CODE": "860105", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8102", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6826, "SUB_DISTRICT_CODE": "860106", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8103", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6827, "SUB_DISTRICT_CODE": "860107", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8104", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6828, "SUB_DISTRICT_CODE": "860108", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8105", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6829, "SUB_DISTRICT_CODE": "860109", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8106", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6830, "SUB_DISTRICT_CODE": "860110", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8107", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6831, "SUB_DISTRICT_CODE": "860111", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8108", "ZIPCODE": "86190" },
    { "ZIPCODE_ID": 6832, "SUB_DISTRICT_CODE": "860112", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8109", "ZIPCODE": "86190" },
    { "ZIPCODE_ID": 6833, "SUB_DISTRICT_CODE": "860113", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8110", "ZIPCODE": "86000" },
    { "ZIPCODE_ID": 6834, "SUB_DISTRICT_CODE": "860114", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8111", "ZIPCODE": "86100" },
    { "ZIPCODE_ID": 6835, "SUB_DISTRICT_CODE": "860115", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8112", "ZIPCODE": "86100" },
    { "ZIPCODE_ID": 6836, "SUB_DISTRICT_CODE": "860116", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8113", "ZIPCODE": "86120" },
    { "ZIPCODE_ID": 6837, "SUB_DISTRICT_CODE": "860117", "PROVINCE_ID": "69", "DISTRICT_ID": "910", "SUB_DISTRICT_ID": "8114", "ZIPCODE": "86100" },
    { "ZIPCODE_ID": 6838, "SUB_DISTRICT_CODE": "860201", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8115", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6839, "SUB_DISTRICT_CODE": "860202", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8116", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6840, "SUB_DISTRICT_CODE": "860203", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8117", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6841, "SUB_DISTRICT_CODE": "860204", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8118", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6842, "SUB_DISTRICT_CODE": "860205", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8119", "ZIPCODE": "86190" },
    { "ZIPCODE_ID": 6843, "SUB_DISTRICT_CODE": "860206", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8120", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6844, "SUB_DISTRICT_CODE": "860207", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8121", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6845, "SUB_DISTRICT_CODE": "860208", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8122", "ZIPCODE": "86190" },
    { "ZIPCODE_ID": 6846, "SUB_DISTRICT_CODE": "860209", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8123", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6847, "SUB_DISTRICT_CODE": "860210", "PROVINCE_ID": "69", "DISTRICT_ID": "911", "SUB_DISTRICT_ID": "8124", "ZIPCODE": "86140" },
    { "ZIPCODE_ID": 6848, "SUB_DISTRICT_CODE": "860301", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8125", "ZIPCODE": "86160" },
    { "ZIPCODE_ID": 6849, "SUB_DISTRICT_CODE": "860302", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8126", "ZIPCODE": "86160" },
    { "ZIPCODE_ID": 6850, "SUB_DISTRICT_CODE": "860303", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8127", "ZIPCODE": "86230" },
    { "ZIPCODE_ID": 6851, "SUB_DISTRICT_CODE": "860304", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8128", "ZIPCODE": "86160" },
    { "ZIPCODE_ID": 6852, "SUB_DISTRICT_CODE": "860305", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8129", "ZIPCODE": "86210" },
    { "ZIPCODE_ID": 6853, "SUB_DISTRICT_CODE": "860306", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8130", "ZIPCODE": "86210" },
    { "ZIPCODE_ID": 6854, "SUB_DISTRICT_CODE": "860307", "PROVINCE_ID": "69", "DISTRICT_ID": "912", "SUB_DISTRICT_ID": "8131", "ZIPCODE": "86210" },
    { "ZIPCODE_ID": 6855, "SUB_DISTRICT_CODE": "860401", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8132", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6856, "SUB_DISTRICT_CODE": "860402", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8133", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6857, "SUB_DISTRICT_CODE": "860403", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8134", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6858, "SUB_DISTRICT_CODE": "860404", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8135", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6859, "SUB_DISTRICT_CODE": "860405", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8136", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6860, "SUB_DISTRICT_CODE": "860406", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8137", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6861, "SUB_DISTRICT_CODE": "860407", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8138", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6862, "SUB_DISTRICT_CODE": "860408", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8139", "ZIPCODE": "86150" },
    { "ZIPCODE_ID": 6863, "SUB_DISTRICT_CODE": "860409", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8140", "ZIPCODE": "86150" },
    { "ZIPCODE_ID": 6864, "SUB_DISTRICT_CODE": "860410", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8141", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6865, "SUB_DISTRICT_CODE": "860411", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8142", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6866, "SUB_DISTRICT_CODE": "860412", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8143", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6867, "SUB_DISTRICT_CODE": "860413", "PROVINCE_ID": "69", "DISTRICT_ID": "913", "SUB_DISTRICT_ID": "8144", "ZIPCODE": "86110" },
    { "ZIPCODE_ID": 6868, "SUB_DISTRICT_CODE": "860501", "PROVINCE_ID": "69", "DISTRICT_ID": "914", "SUB_DISTRICT_ID": "8145", "ZIPCODE": "86170" },
    { "ZIPCODE_ID": 6869, "SUB_DISTRICT_CODE": "860502", "PROVINCE_ID": "69", "DISTRICT_ID": "914", "SUB_DISTRICT_ID": "8146", "ZIPCODE": "86170" },
    { "ZIPCODE_ID": 6870, "SUB_DISTRICT_CODE": "860503", "PROVINCE_ID": "69", "DISTRICT_ID": "914", "SUB_DISTRICT_ID": "8147", "ZIPCODE": "86170" },
    { "ZIPCODE_ID": 6871, "SUB_DISTRICT_CODE": "860504", "PROVINCE_ID": "69", "DISTRICT_ID": "914", "SUB_DISTRICT_ID": "8148", "ZIPCODE": "86170" },
    { "ZIPCODE_ID": 6872, "SUB_DISTRICT_CODE": "860601", "PROVINCE_ID": "69", "DISTRICT_ID": "915", "SUB_DISTRICT_ID": "8149", "ZIPCODE": "86180" },
    { "ZIPCODE_ID": 6873, "SUB_DISTRICT_CODE": "860602", "PROVINCE_ID": "69", "DISTRICT_ID": "915", "SUB_DISTRICT_ID": "8150", "ZIPCODE": "86180" },
    { "ZIPCODE_ID": 6874, "SUB_DISTRICT_CODE": "860603", "PROVINCE_ID": "69", "DISTRICT_ID": "915", "SUB_DISTRICT_ID": "8151", "ZIPCODE": "86180" },
    { "ZIPCODE_ID": 6875, "SUB_DISTRICT_CODE": "860604", "PROVINCE_ID": "69", "DISTRICT_ID": "915", "SUB_DISTRICT_ID": "8152", "ZIPCODE": "86180" },
    { "ZIPCODE_ID": 6876, "SUB_DISTRICT_CODE": "860701", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8153", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6877, "SUB_DISTRICT_CODE": "860702", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8154", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6878, "SUB_DISTRICT_CODE": "860703", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8155", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6879, "SUB_DISTRICT_CODE": "860704", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8156", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6880, "SUB_DISTRICT_CODE": "860705", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8157", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6881, "SUB_DISTRICT_CODE": "860706", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8158", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6882, "SUB_DISTRICT_CODE": "860707", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8159", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6883, "SUB_DISTRICT_CODE": "860708", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8160", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6884, "SUB_DISTRICT_CODE": "860709", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8161", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6885, "SUB_DISTRICT_CODE": "860710", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8162", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6886, "SUB_DISTRICT_CODE": "860711", "PROVINCE_ID": "69", "DISTRICT_ID": "916", "SUB_DISTRICT_ID": "8163", "ZIPCODE": "86130" },
    { "ZIPCODE_ID": 6887, "SUB_DISTRICT_CODE": "860801", "PROVINCE_ID": "69", "DISTRICT_ID": "917", "SUB_DISTRICT_ID": "8164", "ZIPCODE": "86220" },
    { "ZIPCODE_ID": 6888, "SUB_DISTRICT_CODE": "860802", "PROVINCE_ID": "69", "DISTRICT_ID": "917", "SUB_DISTRICT_ID": "8165", "ZIPCODE": "86220" },
    { "ZIPCODE_ID": 6889, "SUB_DISTRICT_CODE": "860803", "PROVINCE_ID": "69", "DISTRICT_ID": "917", "SUB_DISTRICT_ID": "8166", "ZIPCODE": "86220" },
    { "ZIPCODE_ID": 6890, "SUB_DISTRICT_CODE": "860804", "PROVINCE_ID": "69", "DISTRICT_ID": "917", "SUB_DISTRICT_ID": "8167", "ZIPCODE": "86220" },
    { "ZIPCODE_ID": 6891, "SUB_DISTRICT_CODE": "900101", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8168", "ZIPCODE": "90000" },
    { "ZIPCODE_ID": 6892, "SUB_DISTRICT_CODE": "900102", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8169", "ZIPCODE": "90000" },
    { "ZIPCODE_ID": 6893, "SUB_DISTRICT_CODE": "900103", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8170", "ZIPCODE": "90000" },
    { "ZIPCODE_ID": 6894, "SUB_DISTRICT_CODE": "900104", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8171", "ZIPCODE": "90100" },
    { "ZIPCODE_ID": 6895, "SUB_DISTRICT_CODE": "900105", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8172", "ZIPCODE": "90000" },
    { "ZIPCODE_ID": 6896, "SUB_DISTRICT_CODE": "900106", "PROVINCE_ID": "70", "DISTRICT_ID": "918", "SUB_DISTRICT_ID": "8173", "ZIPCODE": "90100" },
    { "ZIPCODE_ID": 6897, "SUB_DISTRICT_CODE": "900201", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8197", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6898, "SUB_DISTRICT_CODE": "900202", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8198", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6899, "SUB_DISTRICT_CODE": "900203", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8199", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6900, "SUB_DISTRICT_CODE": "900204", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8200", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6901, "SUB_DISTRICT_CODE": "900205", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8201", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6902, "SUB_DISTRICT_CODE": "900206", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8202", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6903, "SUB_DISTRICT_CODE": "900207", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8203", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6904, "SUB_DISTRICT_CODE": "900208", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8204", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6905, "SUB_DISTRICT_CODE": "900209", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8205", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6906, "SUB_DISTRICT_CODE": "900210", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8206", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6907, "SUB_DISTRICT_CODE": "900211", "PROVINCE_ID": "70", "DISTRICT_ID": "919", "SUB_DISTRICT_ID": "8207", "ZIPCODE": "90190" },
    { "ZIPCODE_ID": 6908, "SUB_DISTRICT_CODE": "900301", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8208", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6909, "SUB_DISTRICT_CODE": "900302", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8209", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6910, "SUB_DISTRICT_CODE": "900303", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8210", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6911, "SUB_DISTRICT_CODE": "900304", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8211", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6912, "SUB_DISTRICT_CODE": "900305", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8212", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6913, "SUB_DISTRICT_CODE": "900306", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8213", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6914, "SUB_DISTRICT_CODE": "900307", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8214", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6915, "SUB_DISTRICT_CODE": "900308", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8215", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6916, "SUB_DISTRICT_CODE": "900309", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8216", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6917, "SUB_DISTRICT_CODE": "900310", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8217", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6918, "SUB_DISTRICT_CODE": "900311", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8218", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6919, "SUB_DISTRICT_CODE": "900312", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8219", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6920, "SUB_DISTRICT_CODE": "900313", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8220", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6921, "SUB_DISTRICT_CODE": "900314", "PROVINCE_ID": "70", "DISTRICT_ID": "920", "SUB_DISTRICT_ID": "8221", "ZIPCODE": "90130" },
    { "ZIPCODE_ID": 6922, "SUB_DISTRICT_CODE": "900401", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8222", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6923, "SUB_DISTRICT_CODE": "900402", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8223", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6924, "SUB_DISTRICT_CODE": "900403", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8224", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6925, "SUB_DISTRICT_CODE": "900404", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8225", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6926, "SUB_DISTRICT_CODE": "900405", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8226", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6927, "SUB_DISTRICT_CODE": "900406", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8227", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6928, "SUB_DISTRICT_CODE": "900407", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8228", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6929, "SUB_DISTRICT_CODE": "900408", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8229", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6930, "SUB_DISTRICT_CODE": "900409", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8230", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6931, "SUB_DISTRICT_CODE": "900410", "PROVINCE_ID": "70", "DISTRICT_ID": "921", "SUB_DISTRICT_ID": "8231", "ZIPCODE": "90160" },
    { "ZIPCODE_ID": 6932, "SUB_DISTRICT_CODE": "900501", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8232", "ZIPCODE": "90150" },
    { "ZIPCODE_ID": 6933, "SUB_DISTRICT_CODE": "900502", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8233", "ZIPCODE": "90150" },
    { "ZIPCODE_ID": 6934, "SUB_DISTRICT_CODE": "900503", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8234", "ZIPCODE": "90150" },
    { "ZIPCODE_ID": 6935, "SUB_DISTRICT_CODE": "900504", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8235", "ZIPCODE": "90260" },
    { "ZIPCODE_ID": 6936, "SUB_DISTRICT_CODE": "900505", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8236", "ZIPCODE": "90260" },
    { "ZIPCODE_ID": 6937, "SUB_DISTRICT_CODE": "900506", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8237", "ZIPCODE": "90260" },
    { "ZIPCODE_ID": 6938, "SUB_DISTRICT_CODE": "900507", "PROVINCE_ID": "70", "DISTRICT_ID": "922", "SUB_DISTRICT_ID": "8238", "ZIPCODE": "90150" },
    { "ZIPCODE_ID": 6939, "SUB_DISTRICT_CODE": "900601", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8239", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6940, "SUB_DISTRICT_CODE": "900602", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8240", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6941, "SUB_DISTRICT_CODE": "900603", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8241", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6942, "SUB_DISTRICT_CODE": "900604", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8242", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6943, "SUB_DISTRICT_CODE": "900605", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8243", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6944, "SUB_DISTRICT_CODE": "900606", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8244", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6945, "SUB_DISTRICT_CODE": "900607", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8245", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6946, "SUB_DISTRICT_CODE": "900608", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8246", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6947, "SUB_DISTRICT_CODE": "900609", "PROVINCE_ID": "70", "DISTRICT_ID": "923", "SUB_DISTRICT_ID": "8247", "ZIPCODE": "90210" },
    { "ZIPCODE_ID": 6948, "SUB_DISTRICT_CODE": "900701", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8248", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6949, "SUB_DISTRICT_CODE": "900702", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8249", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6950, "SUB_DISTRICT_CODE": "900703", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8250", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6951, "SUB_DISTRICT_CODE": "900704", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8251", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6952, "SUB_DISTRICT_CODE": "900705", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8252", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6953, "SUB_DISTRICT_CODE": "900706", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8253", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6954, "SUB_DISTRICT_CODE": "900707", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8254", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6955, "SUB_DISTRICT_CODE": "900708", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8255", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6956, "SUB_DISTRICT_CODE": "900709", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8256", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6957, "SUB_DISTRICT_CODE": "900710", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8257", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6958, "SUB_DISTRICT_CODE": "900711", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8258", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6959, "SUB_DISTRICT_CODE": "900712", "PROVINCE_ID": "70", "DISTRICT_ID": "924", "SUB_DISTRICT_ID": "8259", "ZIPCODE": "90140" },
    { "ZIPCODE_ID": 6960, "SUB_DISTRICT_CODE": "900801", "PROVINCE_ID": "70", "DISTRICT_ID": "925", "SUB_DISTRICT_ID": "8263", "ZIPCODE": "90270" },
    { "ZIPCODE_ID": 6961, "SUB_DISTRICT_CODE": "900802", "PROVINCE_ID": "70", "DISTRICT_ID": "925", "SUB_DISTRICT_ID": "8264", "ZIPCODE": "90270" },
    { "ZIPCODE_ID": 6962, "SUB_DISTRICT_CODE": "900803", "PROVINCE_ID": "70", "DISTRICT_ID": "925", "SUB_DISTRICT_ID": "8265", "ZIPCODE": "90270" },
    { "ZIPCODE_ID": 6963, "SUB_DISTRICT_CODE": "900804", "PROVINCE_ID": "70", "DISTRICT_ID": "925", "SUB_DISTRICT_ID": "8266", "ZIPCODE": "90270" },
    { "ZIPCODE_ID": 6964, "SUB_DISTRICT_CODE": "900901", "PROVINCE_ID": "70", "DISTRICT_ID": "926", "SUB_DISTRICT_ID": "8267", "ZIPCODE": "90180" },
    { "ZIPCODE_ID": 6965, "SUB_DISTRICT_CODE": "900902", "PROVINCE_ID": "70", "DISTRICT_ID": "926", "SUB_DISTRICT_ID": "8268", "ZIPCODE": "90180" },
    { "ZIPCODE_ID": 6966, "SUB_DISTRICT_CODE": "900903", "PROVINCE_ID": "70", "DISTRICT_ID": "926", "SUB_DISTRICT_ID": "8269", "ZIPCODE": "90180" },
    { "ZIPCODE_ID": 6967, "SUB_DISTRICT_CODE": "900904", "PROVINCE_ID": "70", "DISTRICT_ID": "926", "SUB_DISTRICT_ID": "8270", "ZIPCODE": "90180" },
    { "ZIPCODE_ID": 6968, "SUB_DISTRICT_CODE": "900909", "PROVINCE_ID": "70", "DISTRICT_ID": "926", "SUB_DISTRICT_ID": "8275", "ZIPCODE": "90180" },
    { "ZIPCODE_ID": 6969, "SUB_DISTRICT_CODE": "901001", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8280", "ZIPCODE": "90120" },
    { "ZIPCODE_ID": 6970, "SUB_DISTRICT_CODE": "901002", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8281", "ZIPCODE": "90120" },
    { "ZIPCODE_ID": 6971, "SUB_DISTRICT_CODE": "901003", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8282", "ZIPCODE": "90170" },
    { "ZIPCODE_ID": 6972, "SUB_DISTRICT_CODE": "901004", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8283", "ZIPCODE": "90120" },
    { "ZIPCODE_ID": 6973, "SUB_DISTRICT_CODE": "901005", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8284", "ZIPCODE": "90240" },
    { "ZIPCODE_ID": 6974, "SUB_DISTRICT_CODE": "901006", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8285", "ZIPCODE": "90170" },
    { "ZIPCODE_ID": 6975, "SUB_DISTRICT_CODE": "901007", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8286", "ZIPCODE": "90240" },
    { "ZIPCODE_ID": 6976, "SUB_DISTRICT_CODE": "901008", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8287", "ZIPCODE": "90320" },
    { "ZIPCODE_ID": 6977, "SUB_DISTRICT_CODE": "901009", "PROVINCE_ID": "70", "DISTRICT_ID": "927", "SUB_DISTRICT_ID": "8288", "ZIPCODE": "90170" },
    { "ZIPCODE_ID": 6978, "SUB_DISTRICT_CODE": "901101", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8289", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6979, "SUB_DISTRICT_CODE": "901102", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8290", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6980, "SUB_DISTRICT_CODE": "901103", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8291", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6981, "SUB_DISTRICT_CODE": "901104", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8292", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6982, "SUB_DISTRICT_CODE": "901105", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8293", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6983, "SUB_DISTRICT_CODE": "901107", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8295", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6984, "SUB_DISTRICT_CODE": "901108", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8296", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6985, "SUB_DISTRICT_CODE": "901111", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8299", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6986, "SUB_DISTRICT_CODE": "901112", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8300", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6987, "SUB_DISTRICT_CODE": "901113", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8301", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6988, "SUB_DISTRICT_CODE": "901114", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8302", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 6989, "SUB_DISTRICT_CODE": "901116", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8304", "ZIPCODE": "90250" },
    { "ZIPCODE_ID": 6990, "SUB_DISTRICT_CODE": "901118", "PROVINCE_ID": "70", "DISTRICT_ID": "928", "SUB_DISTRICT_ID": "8306", "ZIPCODE": "90230" },
    { "ZIPCODE_ID": 6991, "SUB_DISTRICT_CODE": "901201", "PROVINCE_ID": "70", "DISTRICT_ID": "929", "SUB_DISTRICT_ID": "8319", "ZIPCODE": "90310" },
    { "ZIPCODE_ID": 6992, "SUB_DISTRICT_CODE": "901202", "PROVINCE_ID": "70", "DISTRICT_ID": "929", "SUB_DISTRICT_ID": "8320", "ZIPCODE": "90310" },
    { "ZIPCODE_ID": 6993, "SUB_DISTRICT_CODE": "901203", "PROVINCE_ID": "70", "DISTRICT_ID": "929", "SUB_DISTRICT_ID": "8321", "ZIPCODE": "90310" },
    { "ZIPCODE_ID": 6994, "SUB_DISTRICT_CODE": "901204", "PROVINCE_ID": "70", "DISTRICT_ID": "929", "SUB_DISTRICT_ID": "8322", "ZIPCODE": "90310" },
    { "ZIPCODE_ID": 6995, "SUB_DISTRICT_CODE": "901301", "PROVINCE_ID": "70", "DISTRICT_ID": "930", "SUB_DISTRICT_ID": "8323", "ZIPCODE": "90220" },
    { "ZIPCODE_ID": 6996, "SUB_DISTRICT_CODE": "901302", "PROVINCE_ID": "70", "DISTRICT_ID": "930", "SUB_DISTRICT_ID": "8324", "ZIPCODE": "90220" },
    { "ZIPCODE_ID": 6997, "SUB_DISTRICT_CODE": "901303", "PROVINCE_ID": "70", "DISTRICT_ID": "930", "SUB_DISTRICT_ID": "8325", "ZIPCODE": "90220" },
    { "ZIPCODE_ID": 6998, "SUB_DISTRICT_CODE": "901304", "PROVINCE_ID": "70", "DISTRICT_ID": "930", "SUB_DISTRICT_ID": "8326", "ZIPCODE": "90220" },
    { "ZIPCODE_ID": 6999, "SUB_DISTRICT_CODE": "901401", "PROVINCE_ID": "70", "DISTRICT_ID": "931", "SUB_DISTRICT_ID": "8327", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 7000, "SUB_DISTRICT_CODE": "901402", "PROVINCE_ID": "70", "DISTRICT_ID": "931", "SUB_DISTRICT_ID": "8328", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 7001, "SUB_DISTRICT_CODE": "901403", "PROVINCE_ID": "70", "DISTRICT_ID": "931", "SUB_DISTRICT_ID": "8329", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 7002, "SUB_DISTRICT_CODE": "901404", "PROVINCE_ID": "70", "DISTRICT_ID": "931", "SUB_DISTRICT_ID": "8330", "ZIPCODE": "90110" },
    { "ZIPCODE_ID": 7003, "SUB_DISTRICT_CODE": "901501", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8331", "ZIPCODE": "90280" },
    { "ZIPCODE_ID": 7004, "SUB_DISTRICT_CODE": "901502", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8332", "ZIPCODE": "90280" },
    { "ZIPCODE_ID": 7005, "SUB_DISTRICT_CODE": "901503", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8333", "ZIPCODE": "90280" },
    { "ZIPCODE_ID": 7006, "SUB_DISTRICT_CODE": "901504", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8334", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7007, "SUB_DISTRICT_CODE": "901505", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8335", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7008, "SUB_DISTRICT_CODE": "901506", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8336", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7009, "SUB_DISTRICT_CODE": "901507", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8337", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7010, "SUB_DISTRICT_CODE": "901508", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8338", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7011, "SUB_DISTRICT_CODE": "901509", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8339", "ZIPCODE": "90280" },
    { "ZIPCODE_ID": 7012, "SUB_DISTRICT_CODE": "901510", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8340", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7013, "SUB_DISTRICT_CODE": "901511", "PROVINCE_ID": "70", "DISTRICT_ID": "932", "SUB_DISTRICT_ID": "8341", "ZIPCODE": "90330" },
    { "ZIPCODE_ID": 7014, "SUB_DISTRICT_CODE": "901601", "PROVINCE_ID": "70", "DISTRICT_ID": "933", "SUB_DISTRICT_ID": "8342", "ZIPCODE": "90230" },
    { "ZIPCODE_ID": 7015, "SUB_DISTRICT_CODE": "901602", "PROVINCE_ID": "70", "DISTRICT_ID": "933", "SUB_DISTRICT_ID": "8343", "ZIPCODE": "90230" },
    { "ZIPCODE_ID": 7016, "SUB_DISTRICT_CODE": "901603", "PROVINCE_ID": "70", "DISTRICT_ID": "933", "SUB_DISTRICT_ID": "8344", "ZIPCODE": "90230" },
    { "ZIPCODE_ID": 7017, "SUB_DISTRICT_CODE": "901604", "PROVINCE_ID": "70", "DISTRICT_ID": "933", "SUB_DISTRICT_ID": "8345", "ZIPCODE": "90115" },
    { "ZIPCODE_ID": 7018, "SUB_DISTRICT_CODE": "910101", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8347", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7019, "SUB_DISTRICT_CODE": "910102", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8348", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7020, "SUB_DISTRICT_CODE": "910103", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8349", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7021, "SUB_DISTRICT_CODE": "910104", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8350", "ZIPCODE": "91140" },
    { "ZIPCODE_ID": 7022, "SUB_DISTRICT_CODE": "910105", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8351", "ZIPCODE": "91140" },
    { "ZIPCODE_ID": 7023, "SUB_DISTRICT_CODE": "910106", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8352", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7024, "SUB_DISTRICT_CODE": "910107", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8353", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7025, "SUB_DISTRICT_CODE": "910108", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8354", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7026, "SUB_DISTRICT_CODE": "910109", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8355", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7027, "SUB_DISTRICT_CODE": "910110", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8356", "ZIPCODE": "91000" },
    { "ZIPCODE_ID": 7028, "SUB_DISTRICT_CODE": "910111", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8357", "ZIPCODE": "91140" },
    { "ZIPCODE_ID": 7029, "SUB_DISTRICT_CODE": "910112", "PROVINCE_ID": "71", "DISTRICT_ID": "936", "SUB_DISTRICT_ID": "8358", "ZIPCODE": "91140" },
    { "ZIPCODE_ID": 7030, "SUB_DISTRICT_CODE": "910201", "PROVINCE_ID": "71", "DISTRICT_ID": "937", "SUB_DISTRICT_ID": "8360", "ZIPCODE": "91160" },
    { "ZIPCODE_ID": 7031, "SUB_DISTRICT_CODE": "910202", "PROVINCE_ID": "71", "DISTRICT_ID": "937", "SUB_DISTRICT_ID": "8361", "ZIPCODE": "91160" },
    { "ZIPCODE_ID": 7032, "SUB_DISTRICT_CODE": "910203", "PROVINCE_ID": "71", "DISTRICT_ID": "937", "SUB_DISTRICT_ID": "8362", "ZIPCODE": "91160" },
    { "ZIPCODE_ID": 7033, "SUB_DISTRICT_CODE": "910204", "PROVINCE_ID": "71", "DISTRICT_ID": "937", "SUB_DISTRICT_ID": "8363", "ZIPCODE": "91160" },
    { "ZIPCODE_ID": 7034, "SUB_DISTRICT_CODE": "910301", "PROVINCE_ID": "71", "DISTRICT_ID": "938", "SUB_DISTRICT_ID": "8364", "ZIPCODE": "91130" },
    { "ZIPCODE_ID": 7035, "SUB_DISTRICT_CODE": "910302", "PROVINCE_ID": "71", "DISTRICT_ID": "938", "SUB_DISTRICT_ID": "8365", "ZIPCODE": "91130" },
    { "ZIPCODE_ID": 7036, "SUB_DISTRICT_CODE": "910303", "PROVINCE_ID": "71", "DISTRICT_ID": "938", "SUB_DISTRICT_ID": "8366", "ZIPCODE": "91130" },
    { "ZIPCODE_ID": 7037, "SUB_DISTRICT_CODE": "910401", "PROVINCE_ID": "71", "DISTRICT_ID": "939", "SUB_DISTRICT_ID": "8369", "ZIPCODE": "91150" },
    { "ZIPCODE_ID": 7038, "SUB_DISTRICT_CODE": "910402", "PROVINCE_ID": "71", "DISTRICT_ID": "939", "SUB_DISTRICT_ID": "8370", "ZIPCODE": "91150" },
    { "ZIPCODE_ID": 7039, "SUB_DISTRICT_CODE": "910403", "PROVINCE_ID": "71", "DISTRICT_ID": "939", "SUB_DISTRICT_ID": "8371", "ZIPCODE": "91150" },
    { "ZIPCODE_ID": 7040, "SUB_DISTRICT_CODE": "910404", "PROVINCE_ID": "71", "DISTRICT_ID": "939", "SUB_DISTRICT_ID": "8372", "ZIPCODE": "91150" },
    { "ZIPCODE_ID": 7041, "SUB_DISTRICT_CODE": "910501", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8373", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7042, "SUB_DISTRICT_CODE": "910502", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8374", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7043, "SUB_DISTRICT_CODE": "910503", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8375", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7044, "SUB_DISTRICT_CODE": "910504", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8376", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7045, "SUB_DISTRICT_CODE": "910505", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8377", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7046, "SUB_DISTRICT_CODE": "910506", "PROVINCE_ID": "71", "DISTRICT_ID": "940", "SUB_DISTRICT_ID": "8378", "ZIPCODE": "91110" },
    { "ZIPCODE_ID": 7047, "SUB_DISTRICT_CODE": "910601", "PROVINCE_ID": "71", "DISTRICT_ID": "941", "SUB_DISTRICT_ID": "8379", "ZIPCODE": "91120" },
    { "ZIPCODE_ID": 7048, "SUB_DISTRICT_CODE": "910602", "PROVINCE_ID": "71", "DISTRICT_ID": "941", "SUB_DISTRICT_ID": "8380", "ZIPCODE": "91120" },
    { "ZIPCODE_ID": 7049, "SUB_DISTRICT_CODE": "910603", "PROVINCE_ID": "71", "DISTRICT_ID": "941", "SUB_DISTRICT_ID": "8381", "ZIPCODE": "91120" },
    { "ZIPCODE_ID": 7050, "SUB_DISTRICT_CODE": "910604", "PROVINCE_ID": "71", "DISTRICT_ID": "941", "SUB_DISTRICT_ID": "8382", "ZIPCODE": "91120" },
    { "ZIPCODE_ID": 7051, "SUB_DISTRICT_CODE": "910605", "PROVINCE_ID": "71", "DISTRICT_ID": "941", "SUB_DISTRICT_ID": "8383", "ZIPCODE": "91120" },
    { "ZIPCODE_ID": 7052, "SUB_DISTRICT_CODE": "910701", "PROVINCE_ID": "71", "DISTRICT_ID": "942", "SUB_DISTRICT_ID": "8384", "ZIPCODE": "91130" },
    { "ZIPCODE_ID": 7053, "SUB_DISTRICT_CODE": "910702", "PROVINCE_ID": "71", "DISTRICT_ID": "942", "SUB_DISTRICT_ID": "8385", "ZIPCODE": "91130" },
    { "ZIPCODE_ID": 7054, "SUB_DISTRICT_CODE": "920101", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8386", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7055, "SUB_DISTRICT_CODE": "920104", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8389", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7056, "SUB_DISTRICT_CODE": "920105", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8390", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7057, "SUB_DISTRICT_CODE": "920106", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8391", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7058, "SUB_DISTRICT_CODE": "920107", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8392", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7059, "SUB_DISTRICT_CODE": "920108", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8393", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7060, "SUB_DISTRICT_CODE": "920109", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8394", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7061, "SUB_DISTRICT_CODE": "920110", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8395", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7062, "SUB_DISTRICT_CODE": "920113", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8398", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7063, "SUB_DISTRICT_CODE": "920114", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8399", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7064, "SUB_DISTRICT_CODE": "920115", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8400", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7065, "SUB_DISTRICT_CODE": "920117", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8402", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7066, "SUB_DISTRICT_CODE": "920118", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8403", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7067, "SUB_DISTRICT_CODE": "920119", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8404", "ZIPCODE": "92190" },
    { "ZIPCODE_ID": 7068, "SUB_DISTRICT_CODE": "920120", "PROVINCE_ID": "72", "DISTRICT_ID": "943", "SUB_DISTRICT_ID": "8405", "ZIPCODE": "92190" },
    { "ZIPCODE_ID": 7069, "SUB_DISTRICT_CODE": "920201", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8413", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7070, "SUB_DISTRICT_CODE": "920202", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8414", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7071, "SUB_DISTRICT_CODE": "920203", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8415", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7072, "SUB_DISTRICT_CODE": "920204", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8416", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7073, "SUB_DISTRICT_CODE": "920205", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8417", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7074, "SUB_DISTRICT_CODE": "920206", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8418", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7075, "SUB_DISTRICT_CODE": "920207", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8419", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7076, "SUB_DISTRICT_CODE": "920208", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8420", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7077, "SUB_DISTRICT_CODE": "920209", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8421", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7078, "SUB_DISTRICT_CODE": "920210", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8422", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7079, "SUB_DISTRICT_CODE": "920211", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8423", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7080, "SUB_DISTRICT_CODE": "920212", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8424", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7081, "SUB_DISTRICT_CODE": "920213", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8425", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7082, "SUB_DISTRICT_CODE": "920214", "PROVINCE_ID": "72", "DISTRICT_ID": "944", "SUB_DISTRICT_ID": "8426", "ZIPCODE": "92110" },
    { "ZIPCODE_ID": 7083, "SUB_DISTRICT_CODE": "920301", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8427", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7084, "SUB_DISTRICT_CODE": "920302", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8428", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7085, "SUB_DISTRICT_CODE": "920303", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8429", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7086, "SUB_DISTRICT_CODE": "920304", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8430", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7087, "SUB_DISTRICT_CODE": "920305", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8431", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7088, "SUB_DISTRICT_CODE": "920306", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8432", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7089, "SUB_DISTRICT_CODE": "920307", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8433", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7090, "SUB_DISTRICT_CODE": "920308", "PROVINCE_ID": "72", "DISTRICT_ID": "945", "SUB_DISTRICT_ID": "8434", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7091, "SUB_DISTRICT_CODE": "920401", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8435", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7092, "SUB_DISTRICT_CODE": "920402", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8436", "ZIPCODE": "92180" },
    { "ZIPCODE_ID": 7093, "SUB_DISTRICT_CODE": "920403", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8437", "ZIPCODE": "92180" },
    { "ZIPCODE_ID": 7094, "SUB_DISTRICT_CODE": "920404", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8438", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7095, "SUB_DISTRICT_CODE": "920407", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8441", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7096, "SUB_DISTRICT_CODE": "920409", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8443", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7097, "SUB_DISTRICT_CODE": "920410", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8444", "ZIPCODE": "92180" },
    { "ZIPCODE_ID": 7098, "SUB_DISTRICT_CODE": "920411", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8445", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7099, "SUB_DISTRICT_CODE": "920412", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8446", "ZIPCODE": "92140" },
    { "ZIPCODE_ID": 7100, "SUB_DISTRICT_CODE": "920413", "PROVINCE_ID": "72", "DISTRICT_ID": "946", "SUB_DISTRICT_ID": "8447", "ZIPCODE": "92180" },
    { "ZIPCODE_ID": 7101, "SUB_DISTRICT_CODE": "920501", "PROVINCE_ID": "72", "DISTRICT_ID": "947", "SUB_DISTRICT_ID": "8448", "ZIPCODE": "92150" },
    { "ZIPCODE_ID": 7102, "SUB_DISTRICT_CODE": "920502", "PROVINCE_ID": "72", "DISTRICT_ID": "947", "SUB_DISTRICT_ID": "8449", "ZIPCODE": "92150" },
    { "ZIPCODE_ID": 7103, "SUB_DISTRICT_CODE": "920503", "PROVINCE_ID": "72", "DISTRICT_ID": "947", "SUB_DISTRICT_ID": "8450", "ZIPCODE": "92150" },
    { "ZIPCODE_ID": 7104, "SUB_DISTRICT_CODE": "920504", "PROVINCE_ID": "72", "DISTRICT_ID": "947", "SUB_DISTRICT_ID": "8451", "ZIPCODE": "92150" },
    { "ZIPCODE_ID": 7105, "SUB_DISTRICT_CODE": "920505", "PROVINCE_ID": "72", "DISTRICT_ID": "947", "SUB_DISTRICT_ID": "8452", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7106, "SUB_DISTRICT_CODE": "920601", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8458", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7107, "SUB_DISTRICT_CODE": "920602", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8459", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7108, "SUB_DISTRICT_CODE": "920605", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8462", "ZIPCODE": "92210" },
    { "ZIPCODE_ID": 7109, "SUB_DISTRICT_CODE": "920606", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8463", "ZIPCODE": "92210" },
    { "ZIPCODE_ID": 7110, "SUB_DISTRICT_CODE": "920607", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8464", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7111, "SUB_DISTRICT_CODE": "920608", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8465", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7112, "SUB_DISTRICT_CODE": "920609", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8466", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7113, "SUB_DISTRICT_CODE": "920610", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8467", "ZIPCODE": "92190" },
    { "ZIPCODE_ID": 7114, "SUB_DISTRICT_CODE": "920611", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8468", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7115, "SUB_DISTRICT_CODE": "920614", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8471", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7116, "SUB_DISTRICT_CODE": "920615", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8472", "ZIPCODE": "92190" },
    { "ZIPCODE_ID": 7117, "SUB_DISTRICT_CODE": "920616", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8473", "ZIPCODE": "92210" },
    { "ZIPCODE_ID": 7118, "SUB_DISTRICT_CODE": "920617", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8474", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7119, "SUB_DISTRICT_CODE": "920619", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8476", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7120, "SUB_DISTRICT_CODE": "920620", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8477", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7121, "SUB_DISTRICT_CODE": "920621", "PROVINCE_ID": "72", "DISTRICT_ID": "948", "SUB_DISTRICT_ID": "8478", "ZIPCODE": "92210" },
    { "ZIPCODE_ID": 7122, "SUB_DISTRICT_CODE": "920701", "PROVINCE_ID": "72", "DISTRICT_ID": "949", "SUB_DISTRICT_ID": "8483", "ZIPCODE": "92220" },
    { "ZIPCODE_ID": 7123, "SUB_DISTRICT_CODE": "920702", "PROVINCE_ID": "72", "DISTRICT_ID": "949", "SUB_DISTRICT_ID": "8484", "ZIPCODE": "92220" },
    { "ZIPCODE_ID": 7124, "SUB_DISTRICT_CODE": "920703", "PROVINCE_ID": "72", "DISTRICT_ID": "949", "SUB_DISTRICT_ID": "8485", "ZIPCODE": "92220" },
    { "ZIPCODE_ID": 7125, "SUB_DISTRICT_CODE": "920704", "PROVINCE_ID": "72", "DISTRICT_ID": "949", "SUB_DISTRICT_ID": "8486", "ZIPCODE": "92000" },
    { "ZIPCODE_ID": 7126, "SUB_DISTRICT_CODE": "920705", "PROVINCE_ID": "72", "DISTRICT_ID": "949", "SUB_DISTRICT_ID": "8487", "ZIPCODE": "92220" },
    { "ZIPCODE_ID": 7127, "SUB_DISTRICT_CODE": "920801", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8488", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7128, "SUB_DISTRICT_CODE": "920802", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8489", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7129, "SUB_DISTRICT_CODE": "920803", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8490", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7130, "SUB_DISTRICT_CODE": "920804", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8491", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7131, "SUB_DISTRICT_CODE": "920805", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8492", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7132, "SUB_DISTRICT_CODE": "920806", "PROVINCE_ID": "72", "DISTRICT_ID": "950", "SUB_DISTRICT_ID": "8493", "ZIPCODE": "92170" },
    { "ZIPCODE_ID": 7133, "SUB_DISTRICT_CODE": "920901", "PROVINCE_ID": "72", "DISTRICT_ID": "951", "SUB_DISTRICT_ID": "8494", "ZIPCODE": "92160" },
    { "ZIPCODE_ID": 7134, "SUB_DISTRICT_CODE": "920902", "PROVINCE_ID": "72", "DISTRICT_ID": "951", "SUB_DISTRICT_ID": "8495", "ZIPCODE": "92160" },
    { "ZIPCODE_ID": 7135, "SUB_DISTRICT_CODE": "920903", "PROVINCE_ID": "72", "DISTRICT_ID": "951", "SUB_DISTRICT_ID": "8496", "ZIPCODE": "92160" },
    { "ZIPCODE_ID": 7136, "SUB_DISTRICT_CODE": "920904", "PROVINCE_ID": "72", "DISTRICT_ID": "951", "SUB_DISTRICT_ID": "8497", "ZIPCODE": "92130" },
    { "ZIPCODE_ID": 7137, "SUB_DISTRICT_CODE": "920905", "PROVINCE_ID": "72", "DISTRICT_ID": "951", "SUB_DISTRICT_ID": "8498", "ZIPCODE": "92160" },
    { "ZIPCODE_ID": 7138, "SUB_DISTRICT_CODE": "921001", "PROVINCE_ID": "72", "DISTRICT_ID": "952", "SUB_DISTRICT_ID": "8499", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7139, "SUB_DISTRICT_CODE": "921002", "PROVINCE_ID": "72", "DISTRICT_ID": "952", "SUB_DISTRICT_ID": "8500", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7140, "SUB_DISTRICT_CODE": "921003", "PROVINCE_ID": "72", "DISTRICT_ID": "952", "SUB_DISTRICT_ID": "8501", "ZIPCODE": "92120" },
    { "ZIPCODE_ID": 7141, "SUB_DISTRICT_CODE": "930101", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8502", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7142, "SUB_DISTRICT_CODE": "930103", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8504", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7143, "SUB_DISTRICT_CODE": "930104", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8505", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7144, "SUB_DISTRICT_CODE": "930105", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8506", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7145, "SUB_DISTRICT_CODE": "930106", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8507", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7146, "SUB_DISTRICT_CODE": "930107", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8508", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7147, "SUB_DISTRICT_CODE": "930108", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8509", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7148, "SUB_DISTRICT_CODE": "930109", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8510", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7149, "SUB_DISTRICT_CODE": "930110", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8511", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7150, "SUB_DISTRICT_CODE": "930111", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8512", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7151, "SUB_DISTRICT_CODE": "930112", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8513", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7152, "SUB_DISTRICT_CODE": "930113", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8514", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7153, "SUB_DISTRICT_CODE": "930114", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8515", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7154, "SUB_DISTRICT_CODE": "930115", "PROVINCE_ID": "73", "DISTRICT_ID": "954", "SUB_DISTRICT_ID": "8516", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7155, "SUB_DISTRICT_CODE": "930201", "PROVINCE_ID": "73", "DISTRICT_ID": "955", "SUB_DISTRICT_ID": "8520", "ZIPCODE": "93180" },
    { "ZIPCODE_ID": 7156, "SUB_DISTRICT_CODE": "930202", "PROVINCE_ID": "73", "DISTRICT_ID": "955", "SUB_DISTRICT_ID": "8521", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7157, "SUB_DISTRICT_CODE": "930203", "PROVINCE_ID": "73", "DISTRICT_ID": "955", "SUB_DISTRICT_ID": "8522", "ZIPCODE": "93180" },
    { "ZIPCODE_ID": 7158, "SUB_DISTRICT_CODE": "930204", "PROVINCE_ID": "73", "DISTRICT_ID": "955", "SUB_DISTRICT_ID": "8523", "ZIPCODE": "93180" },
    { "ZIPCODE_ID": 7159, "SUB_DISTRICT_CODE": "930205", "PROVINCE_ID": "73", "DISTRICT_ID": "955", "SUB_DISTRICT_ID": "8524", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7160, "SUB_DISTRICT_CODE": "930301", "PROVINCE_ID": "73", "DISTRICT_ID": "956", "SUB_DISTRICT_ID": "8525", "ZIPCODE": "93130" },
    { "ZIPCODE_ID": 7161, "SUB_DISTRICT_CODE": "930302", "PROVINCE_ID": "73", "DISTRICT_ID": "956", "SUB_DISTRICT_ID": "8526", "ZIPCODE": "93130" },
    { "ZIPCODE_ID": 7162, "SUB_DISTRICT_CODE": "930305", "PROVINCE_ID": "73", "DISTRICT_ID": "956", "SUB_DISTRICT_ID": "8529", "ZIPCODE": "93130" },
    { "ZIPCODE_ID": 7163, "SUB_DISTRICT_CODE": "930306", "PROVINCE_ID": "73", "DISTRICT_ID": "956", "SUB_DISTRICT_ID": "8530", "ZIPCODE": "93130" },
    { "ZIPCODE_ID": 7164, "SUB_DISTRICT_CODE": "930307", "PROVINCE_ID": "73", "DISTRICT_ID": "956", "SUB_DISTRICT_ID": "8531", "ZIPCODE": "93130" },
    { "ZIPCODE_ID": 7165, "SUB_DISTRICT_CODE": "930401", "PROVINCE_ID": "73", "DISTRICT_ID": "957", "SUB_DISTRICT_ID": "8538", "ZIPCODE": "93160" },
    { "ZIPCODE_ID": 7166, "SUB_DISTRICT_CODE": "930402", "PROVINCE_ID": "73", "DISTRICT_ID": "957", "SUB_DISTRICT_ID": "8539", "ZIPCODE": "93160" },
    { "ZIPCODE_ID": 7167, "SUB_DISTRICT_CODE": "930403", "PROVINCE_ID": "73", "DISTRICT_ID": "957", "SUB_DISTRICT_ID": "8540", "ZIPCODE": "93160" },
    { "ZIPCODE_ID": 7168, "SUB_DISTRICT_CODE": "930501", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8541", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7169, "SUB_DISTRICT_CODE": "930502", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8542", "ZIPCODE": "93150" },
    { "ZIPCODE_ID": 7170, "SUB_DISTRICT_CODE": "930504", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8544", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7171, "SUB_DISTRICT_CODE": "930505", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8545", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7172, "SUB_DISTRICT_CODE": "930506", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8546", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7173, "SUB_DISTRICT_CODE": "930508", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8548", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7174, "SUB_DISTRICT_CODE": "930509", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8549", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7175, "SUB_DISTRICT_CODE": "930510", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8550", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7176, "SUB_DISTRICT_CODE": "930511", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8551", "ZIPCODE": "93150" },
    { "ZIPCODE_ID": 7177, "SUB_DISTRICT_CODE": "930512", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8552", "ZIPCODE": "93150" },
    { "ZIPCODE_ID": 7178, "SUB_DISTRICT_CODE": "930513", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8553", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7179, "SUB_DISTRICT_CODE": "930516", "PROVINCE_ID": "73", "DISTRICT_ID": "958", "SUB_DISTRICT_ID": "8556", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7180, "SUB_DISTRICT_CODE": "930601", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8561", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7181, "SUB_DISTRICT_CODE": "930602", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8562", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7182, "SUB_DISTRICT_CODE": "930603", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8563", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7183, "SUB_DISTRICT_CODE": "930604", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8564", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7184, "SUB_DISTRICT_CODE": "930605", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8565", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7185, "SUB_DISTRICT_CODE": "930606", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8566", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7186, "SUB_DISTRICT_CODE": "930607", "PROVINCE_ID": "73", "DISTRICT_ID": "959", "SUB_DISTRICT_ID": "8567", "ZIPCODE": "93120" },
    { "ZIPCODE_ID": 7187, "SUB_DISTRICT_CODE": "930701", "PROVINCE_ID": "73", "DISTRICT_ID": "960", "SUB_DISTRICT_ID": "8571", "ZIPCODE": "93190" },
    { "ZIPCODE_ID": 7188, "SUB_DISTRICT_CODE": "930702", "PROVINCE_ID": "73", "DISTRICT_ID": "960", "SUB_DISTRICT_ID": "8572", "ZIPCODE": "93190" },
    { "ZIPCODE_ID": 7189, "SUB_DISTRICT_CODE": "930703", "PROVINCE_ID": "73", "DISTRICT_ID": "960", "SUB_DISTRICT_ID": "8573", "ZIPCODE": "93190" },
    { "ZIPCODE_ID": 7190, "SUB_DISTRICT_CODE": "930801", "PROVINCE_ID": "73", "DISTRICT_ID": "961", "SUB_DISTRICT_ID": "8574", "ZIPCODE": "93170" },
    { "ZIPCODE_ID": 7191, "SUB_DISTRICT_CODE": "930802", "PROVINCE_ID": "73", "DISTRICT_ID": "961", "SUB_DISTRICT_ID": "8575", "ZIPCODE": "93170" },
    { "ZIPCODE_ID": 7192, "SUB_DISTRICT_CODE": "930803", "PROVINCE_ID": "73", "DISTRICT_ID": "961", "SUB_DISTRICT_ID": "8576", "ZIPCODE": "93170" },
    { "ZIPCODE_ID": 7193, "SUB_DISTRICT_CODE": "930804", "PROVINCE_ID": "73", "DISTRICT_ID": "961", "SUB_DISTRICT_ID": "8577", "ZIPCODE": "93170" },
    { "ZIPCODE_ID": 7194, "SUB_DISTRICT_CODE": "930806", "PROVINCE_ID": "73", "DISTRICT_ID": "961", "SUB_DISTRICT_ID": "8578", "ZIPCODE": "93170" },
    { "ZIPCODE_ID": 7195, "SUB_DISTRICT_CODE": "930901", "PROVINCE_ID": "73", "DISTRICT_ID": "962", "SUB_DISTRICT_ID": "8579", "ZIPCODE": "93140" },
    { "ZIPCODE_ID": 7196, "SUB_DISTRICT_CODE": "930902", "PROVINCE_ID": "73", "DISTRICT_ID": "962", "SUB_DISTRICT_ID": "8580", "ZIPCODE": "93140" },
    { "ZIPCODE_ID": 7197, "SUB_DISTRICT_CODE": "930903", "PROVINCE_ID": "73", "DISTRICT_ID": "962", "SUB_DISTRICT_ID": "8581", "ZIPCODE": "93140" },
    { "ZIPCODE_ID": 7198, "SUB_DISTRICT_CODE": "931001", "PROVINCE_ID": "73", "DISTRICT_ID": "963", "SUB_DISTRICT_ID": "8582", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7199, "SUB_DISTRICT_CODE": "931002", "PROVINCE_ID": "73", "DISTRICT_ID": "963", "SUB_DISTRICT_ID": "8583", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7200, "SUB_DISTRICT_CODE": "931003", "PROVINCE_ID": "73", "DISTRICT_ID": "963", "SUB_DISTRICT_ID": "8584", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7201, "SUB_DISTRICT_CODE": "931004", "PROVINCE_ID": "73", "DISTRICT_ID": "963", "SUB_DISTRICT_ID": "8585", "ZIPCODE": "93110" },
    { "ZIPCODE_ID": 7202, "SUB_DISTRICT_CODE": "931101", "PROVINCE_ID": "73", "DISTRICT_ID": "964", "SUB_DISTRICT_ID": "8586", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7203, "SUB_DISTRICT_CODE": "931102", "PROVINCE_ID": "73", "DISTRICT_ID": "964", "SUB_DISTRICT_ID": "8587", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7204, "SUB_DISTRICT_CODE": "931103", "PROVINCE_ID": "73", "DISTRICT_ID": "964", "SUB_DISTRICT_ID": "8588", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7205, "SUB_DISTRICT_CODE": "931104", "PROVINCE_ID": "73", "DISTRICT_ID": "964", "SUB_DISTRICT_ID": "8589", "ZIPCODE": "93000" },
    { "ZIPCODE_ID": 7206, "SUB_DISTRICT_CODE": "940101", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8590", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7207, "SUB_DISTRICT_CODE": "940102", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8591", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7208, "SUB_DISTRICT_CODE": "940103", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8592", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7209, "SUB_DISTRICT_CODE": "940104", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8593", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7210, "SUB_DISTRICT_CODE": "940105", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8594", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7211, "SUB_DISTRICT_CODE": "940106", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8595", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7212, "SUB_DISTRICT_CODE": "940107", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8596", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7213, "SUB_DISTRICT_CODE": "940108", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8597", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7214, "SUB_DISTRICT_CODE": "940109", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8598", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7215, "SUB_DISTRICT_CODE": "940110", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8599", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7216, "SUB_DISTRICT_CODE": "940111", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8600", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7217, "SUB_DISTRICT_CODE": "940112", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8601", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7218, "SUB_DISTRICT_CODE": "940113", "PROVINCE_ID": "74", "DISTRICT_ID": "965", "SUB_DISTRICT_ID": "8602", "ZIPCODE": "94000" },
    { "ZIPCODE_ID": 7219, "SUB_DISTRICT_CODE": "940201", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8603", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7220, "SUB_DISTRICT_CODE": "940202", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8604", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7221, "SUB_DISTRICT_CODE": "940203", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8605", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7222, "SUB_DISTRICT_CODE": "940204", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8606", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7223, "SUB_DISTRICT_CODE": "940205", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8607", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7224, "SUB_DISTRICT_CODE": "940206", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8608", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7225, "SUB_DISTRICT_CODE": "940207", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8609", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7226, "SUB_DISTRICT_CODE": "940208", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8610", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7227, "SUB_DISTRICT_CODE": "940211", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8613", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7228, "SUB_DISTRICT_CODE": "940213", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8615", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7229, "SUB_DISTRICT_CODE": "940214", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8616", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7230, "SUB_DISTRICT_CODE": "940215", "PROVINCE_ID": "74", "DISTRICT_ID": "966", "SUB_DISTRICT_ID": "8617", "ZIPCODE": "94120" },
    { "ZIPCODE_ID": 7231, "SUB_DISTRICT_CODE": "940301", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8618", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7232, "SUB_DISTRICT_CODE": "940302", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8619", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7233, "SUB_DISTRICT_CODE": "940303", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8620", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7234, "SUB_DISTRICT_CODE": "940304", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8621", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7235, "SUB_DISTRICT_CODE": "940305", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8622", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7236, "SUB_DISTRICT_CODE": "940306", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8623", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7237, "SUB_DISTRICT_CODE": "940307", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8624", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7238, "SUB_DISTRICT_CODE": "940308", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8625", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7239, "SUB_DISTRICT_CODE": "940309", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8626", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7240, "SUB_DISTRICT_CODE": "940310", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8627", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7241, "SUB_DISTRICT_CODE": "940311", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8628", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7242, "SUB_DISTRICT_CODE": "940312", "PROVINCE_ID": "74", "DISTRICT_ID": "967", "SUB_DISTRICT_ID": "8629", "ZIPCODE": "94170" },
    { "ZIPCODE_ID": 7243, "SUB_DISTRICT_CODE": "940401", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8630", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7244, "SUB_DISTRICT_CODE": "940402", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8631", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7245, "SUB_DISTRICT_CODE": "940403", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8632", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7246, "SUB_DISTRICT_CODE": "940404", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8633", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7247, "SUB_DISTRICT_CODE": "940405", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8634", "ZIPCODE": "94190" },
    { "ZIPCODE_ID": 7248, "SUB_DISTRICT_CODE": "940406", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8635", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7249, "SUB_DISTRICT_CODE": "940407", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8636", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7250, "SUB_DISTRICT_CODE": "940408", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8637", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7251, "SUB_DISTRICT_CODE": "940409", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8638", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7252, "SUB_DISTRICT_CODE": "940410", "PROVINCE_ID": "74", "DISTRICT_ID": "968", "SUB_DISTRICT_ID": "8639", "ZIPCODE": "94130" },
    { "ZIPCODE_ID": 7253, "SUB_DISTRICT_CODE": "940501", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8640", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7254, "SUB_DISTRICT_CODE": "940502", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8641", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7255, "SUB_DISTRICT_CODE": "940503", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8642", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7256, "SUB_DISTRICT_CODE": "940504", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8643", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7257, "SUB_DISTRICT_CODE": "940505", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8644", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7258, "SUB_DISTRICT_CODE": "940506", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8645", "ZIPCODE": "94190" },
    { "ZIPCODE_ID": 7259, "SUB_DISTRICT_CODE": "940507", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8646", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7260, "SUB_DISTRICT_CODE": "940508", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8647", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7261, "SUB_DISTRICT_CODE": "940509", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8648", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7262, "SUB_DISTRICT_CODE": "940510", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8649", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7263, "SUB_DISTRICT_CODE": "940511", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8650", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7264, "SUB_DISTRICT_CODE": "940512", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8651", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7265, "SUB_DISTRICT_CODE": "940513", "PROVINCE_ID": "74", "DISTRICT_ID": "969", "SUB_DISTRICT_ID": "8652", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7266, "SUB_DISTRICT_CODE": "940601", "PROVINCE_ID": "74", "DISTRICT_ID": "970", "SUB_DISTRICT_ID": "8653", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7267, "SUB_DISTRICT_CODE": "940602", "PROVINCE_ID": "74", "DISTRICT_ID": "970", "SUB_DISTRICT_ID": "8654", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7268, "SUB_DISTRICT_CODE": "940603", "PROVINCE_ID": "74", "DISTRICT_ID": "970", "SUB_DISTRICT_ID": "8655", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7269, "SUB_DISTRICT_CODE": "940604", "PROVINCE_ID": "74", "DISTRICT_ID": "970", "SUB_DISTRICT_ID": "8656", "ZIPCODE": "94140" },
    { "ZIPCODE_ID": 7270, "SUB_DISTRICT_CODE": "940701", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8657", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7271, "SUB_DISTRICT_CODE": "940702", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8658", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7272, "SUB_DISTRICT_CODE": "940703", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8659", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7273, "SUB_DISTRICT_CODE": "940704", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8660", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7274, "SUB_DISTRICT_CODE": "940705", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8661", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7275, "SUB_DISTRICT_CODE": "940706", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8662", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7276, "SUB_DISTRICT_CODE": "940707", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8663", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7277, "SUB_DISTRICT_CODE": "940708", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8664", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7278, "SUB_DISTRICT_CODE": "940709", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8665", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7279, "SUB_DISTRICT_CODE": "940710", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8666", "ZIPCODE": "94110" },
    { "ZIPCODE_ID": 7280, "SUB_DISTRICT_CODE": "940711", "PROVINCE_ID": "74", "DISTRICT_ID": "971", "SUB_DISTRICT_ID": "8667", "ZIPCODE": "94190" },
    { "ZIPCODE_ID": 7281, "SUB_DISTRICT_CODE": "940801", "PROVINCE_ID": "74", "DISTRICT_ID": "972", "SUB_DISTRICT_ID": "8668", "ZIPCODE": "94220" },
    { "ZIPCODE_ID": 7282, "SUB_DISTRICT_CODE": "940802", "PROVINCE_ID": "74", "DISTRICT_ID": "972", "SUB_DISTRICT_ID": "8669", "ZIPCODE": "94220" },
    { "ZIPCODE_ID": 7283, "SUB_DISTRICT_CODE": "940803", "PROVINCE_ID": "74", "DISTRICT_ID": "972", "SUB_DISTRICT_ID": "8670", "ZIPCODE": "94220" },
    { "ZIPCODE_ID": 7284, "SUB_DISTRICT_CODE": "940804", "PROVINCE_ID": "74", "DISTRICT_ID": "972", "SUB_DISTRICT_ID": "8671", "ZIPCODE": "94220" },
    { "ZIPCODE_ID": 7285, "SUB_DISTRICT_CODE": "940901", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8672", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7286, "SUB_DISTRICT_CODE": "940902", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8673", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7287, "SUB_DISTRICT_CODE": "940903", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8674", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7288, "SUB_DISTRICT_CODE": "940904", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8675", "ZIPCODE": "94190" },
    { "ZIPCODE_ID": 7289, "SUB_DISTRICT_CODE": "940905", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8676", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7290, "SUB_DISTRICT_CODE": "940906", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8677", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7291, "SUB_DISTRICT_CODE": "940907", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8678", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7292, "SUB_DISTRICT_CODE": "940908", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8679", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7293, "SUB_DISTRICT_CODE": "940909", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8680", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7294, "SUB_DISTRICT_CODE": "940910", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8681", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7295, "SUB_DISTRICT_CODE": "940911", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8682", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7296, "SUB_DISTRICT_CODE": "940912", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8683", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7297, "SUB_DISTRICT_CODE": "940913", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8684", "ZIPCODE": "94190" },
    { "ZIPCODE_ID": 7298, "SUB_DISTRICT_CODE": "940914", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8685", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7299, "SUB_DISTRICT_CODE": "940915", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8686", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7300, "SUB_DISTRICT_CODE": "940916", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8687", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7301, "SUB_DISTRICT_CODE": "940917", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8688", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7302, "SUB_DISTRICT_CODE": "940918", "PROVINCE_ID": "74", "DISTRICT_ID": "973", "SUB_DISTRICT_ID": "8689", "ZIPCODE": "94150" },
    { "ZIPCODE_ID": 7303, "SUB_DISTRICT_CODE": "941001", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8690", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7304, "SUB_DISTRICT_CODE": "941002", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8691", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7305, "SUB_DISTRICT_CODE": "941003", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8692", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7306, "SUB_DISTRICT_CODE": "941004", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8693", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7307, "SUB_DISTRICT_CODE": "941005", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8694", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7308, "SUB_DISTRICT_CODE": "941006", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8695", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7309, "SUB_DISTRICT_CODE": "941007", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8696", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7310, "SUB_DISTRICT_CODE": "941008", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8697", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7311, "SUB_DISTRICT_CODE": "941009", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8698", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7312, "SUB_DISTRICT_CODE": "941010", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8699", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7313, "SUB_DISTRICT_CODE": "941011", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8700", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7314, "SUB_DISTRICT_CODE": "941012", "PROVINCE_ID": "74", "DISTRICT_ID": "974", "SUB_DISTRICT_ID": "8701", "ZIPCODE": "94160" },
    { "ZIPCODE_ID": 7315, "SUB_DISTRICT_CODE": "941101", "PROVINCE_ID": "74", "DISTRICT_ID": "975", "SUB_DISTRICT_ID": "8702", "ZIPCODE": "94230" },
    { "ZIPCODE_ID": 7316, "SUB_DISTRICT_CODE": "941102", "PROVINCE_ID": "74", "DISTRICT_ID": "975", "SUB_DISTRICT_ID": "8703", "ZIPCODE": "94230" },
    { "ZIPCODE_ID": 7317, "SUB_DISTRICT_CODE": "941103", "PROVINCE_ID": "74", "DISTRICT_ID": "975", "SUB_DISTRICT_ID": "8704", "ZIPCODE": "94230" },
    { "ZIPCODE_ID": 7318, "SUB_DISTRICT_CODE": "941201", "PROVINCE_ID": "74", "DISTRICT_ID": "976", "SUB_DISTRICT_ID": "8705", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7319, "SUB_DISTRICT_CODE": "941202", "PROVINCE_ID": "74", "DISTRICT_ID": "976", "SUB_DISTRICT_ID": "8706", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7320, "SUB_DISTRICT_CODE": "941203", "PROVINCE_ID": "74", "DISTRICT_ID": "976", "SUB_DISTRICT_ID": "8707", "ZIPCODE": "94180" },
    { "ZIPCODE_ID": 7321, "SUB_DISTRICT_CODE": "950101", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8708", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7322, "SUB_DISTRICT_CODE": "950102", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8709", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7323, "SUB_DISTRICT_CODE": "950103", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8710", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7324, "SUB_DISTRICT_CODE": "950104", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8711", "ZIPCODE": "95160" },
    { "ZIPCODE_ID": 7325, "SUB_DISTRICT_CODE": "950106", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8713", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7326, "SUB_DISTRICT_CODE": "950108", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8715", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7327, "SUB_DISTRICT_CODE": "950109", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8716", "ZIPCODE": "95160" },
    { "ZIPCODE_ID": 7328, "SUB_DISTRICT_CODE": "950110", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8717", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7329, "SUB_DISTRICT_CODE": "950111", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8718", "ZIPCODE": "95160" },
    { "ZIPCODE_ID": 7330, "SUB_DISTRICT_CODE": "950112", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8719", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7331, "SUB_DISTRICT_CODE": "950114", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8721", "ZIPCODE": "95160" },
    { "ZIPCODE_ID": 7332, "SUB_DISTRICT_CODE": "950115", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8722", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7333, "SUB_DISTRICT_CODE": "950116", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8723", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7334, "SUB_DISTRICT_CODE": "950118", "PROVINCE_ID": "75", "DISTRICT_ID": "977", "SUB_DISTRICT_ID": "8725", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7335, "SUB_DISTRICT_CODE": "950201", "PROVINCE_ID": "75", "DISTRICT_ID": "978", "SUB_DISTRICT_ID": "8726", "ZIPCODE": "95110" },
    { "ZIPCODE_ID": 7336, "SUB_DISTRICT_CODE": "950202", "PROVINCE_ID": "75", "DISTRICT_ID": "978", "SUB_DISTRICT_ID": "8727", "ZIPCODE": "95110" },
    { "ZIPCODE_ID": 7337, "SUB_DISTRICT_CODE": "950203", "PROVINCE_ID": "75", "DISTRICT_ID": "978", "SUB_DISTRICT_ID": "8728", "ZIPCODE": "95110" },
    { "ZIPCODE_ID": 7338, "SUB_DISTRICT_CODE": "950204", "PROVINCE_ID": "75", "DISTRICT_ID": "978", "SUB_DISTRICT_ID": "8729", "ZIPCODE": "95110" },
    { "ZIPCODE_ID": 7339, "SUB_DISTRICT_CODE": "950205", "PROVINCE_ID": "75", "DISTRICT_ID": "978", "SUB_DISTRICT_ID": "8730", "ZIPCODE": "95110" },
    { "ZIPCODE_ID": 7340, "SUB_DISTRICT_CODE": "950301", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8731", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7341, "SUB_DISTRICT_CODE": "950302", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8732", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7342, "SUB_DISTRICT_CODE": "950303", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8733", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7343, "SUB_DISTRICT_CODE": "950304", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8734", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7344, "SUB_DISTRICT_CODE": "950305", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8735", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7345, "SUB_DISTRICT_CODE": "950306", "PROVINCE_ID": "75", "DISTRICT_ID": "979", "SUB_DISTRICT_ID": "8736", "ZIPCODE": "95130" },
    { "ZIPCODE_ID": 7346, "SUB_DISTRICT_CODE": "950401", "PROVINCE_ID": "75", "DISTRICT_ID": "980", "SUB_DISTRICT_ID": "8740", "ZIPCODE": "95150" },
    { "ZIPCODE_ID": 7347, "SUB_DISTRICT_CODE": "950402", "PROVINCE_ID": "75", "DISTRICT_ID": "980", "SUB_DISTRICT_ID": "8741", "ZIPCODE": "95150" },
    { "ZIPCODE_ID": 7348, "SUB_DISTRICT_CODE": "950403", "PROVINCE_ID": "75", "DISTRICT_ID": "980", "SUB_DISTRICT_ID": "8742", "ZIPCODE": "95170" },
    { "ZIPCODE_ID": 7349, "SUB_DISTRICT_CODE": "950404", "PROVINCE_ID": "75", "DISTRICT_ID": "980", "SUB_DISTRICT_ID": "8743", "ZIPCODE": "95150" },
    { "ZIPCODE_ID": 7350, "SUB_DISTRICT_CODE": "950501", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8744", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7351, "SUB_DISTRICT_CODE": "950502", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8745", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7352, "SUB_DISTRICT_CODE": "950503", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8746", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7353, "SUB_DISTRICT_CODE": "950504", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8747", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7354, "SUB_DISTRICT_CODE": "950506", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8749", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7355, "SUB_DISTRICT_CODE": "950507", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8750", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7356, "SUB_DISTRICT_CODE": "950508", "PROVINCE_ID": "75", "DISTRICT_ID": "981", "SUB_DISTRICT_ID": "8751", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7357, "SUB_DISTRICT_CODE": "950601", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8754", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7358, "SUB_DISTRICT_CODE": "950602", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8755", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7359, "SUB_DISTRICT_CODE": "950603", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8756", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7360, "SUB_DISTRICT_CODE": "950604", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8757", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7361, "SUB_DISTRICT_CODE": "950605", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8758", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7362, "SUB_DISTRICT_CODE": "950606", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8759", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7363, "SUB_DISTRICT_CODE": "950607", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8760", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7364, "SUB_DISTRICT_CODE": "950608", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8761", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7365, "SUB_DISTRICT_CODE": "950609", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8762", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7366, "SUB_DISTRICT_CODE": "950610", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8763", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7367, "SUB_DISTRICT_CODE": "950611", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8764", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7368, "SUB_DISTRICT_CODE": "950612", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8765", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7369, "SUB_DISTRICT_CODE": "950613", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8766", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7370, "SUB_DISTRICT_CODE": "950614", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8767", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7371, "SUB_DISTRICT_CODE": "950615", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8768", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7372, "SUB_DISTRICT_CODE": "950616", "PROVINCE_ID": "75", "DISTRICT_ID": "982", "SUB_DISTRICT_ID": "8769", "ZIPCODE": "95140" },
    { "ZIPCODE_ID": 7373, "SUB_DISTRICT_CODE": "950701", "PROVINCE_ID": "75", "DISTRICT_ID": "983", "SUB_DISTRICT_ID": "8770", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7374, "SUB_DISTRICT_CODE": "950702", "PROVINCE_ID": "75", "DISTRICT_ID": "983", "SUB_DISTRICT_ID": "8771", "ZIPCODE": "95120" },
    { "ZIPCODE_ID": 7375, "SUB_DISTRICT_CODE": "950801", "PROVINCE_ID": "75", "DISTRICT_ID": "984", "SUB_DISTRICT_ID": "8772", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7376, "SUB_DISTRICT_CODE": "950802", "PROVINCE_ID": "75", "DISTRICT_ID": "984", "SUB_DISTRICT_ID": "8773", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7377, "SUB_DISTRICT_CODE": "950803", "PROVINCE_ID": "75", "DISTRICT_ID": "984", "SUB_DISTRICT_ID": "8774", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7378, "SUB_DISTRICT_CODE": "950804", "PROVINCE_ID": "75", "DISTRICT_ID": "984", "SUB_DISTRICT_ID": "8775", "ZIPCODE": "95000" },
    { "ZIPCODE_ID": 7379, "SUB_DISTRICT_CODE": "960101", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8776", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7380, "SUB_DISTRICT_CODE": "960102", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8777", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7381, "SUB_DISTRICT_CODE": "960103", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8778", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7382, "SUB_DISTRICT_CODE": "960104", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8779", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7383, "SUB_DISTRICT_CODE": "960105", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8780", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7384, "SUB_DISTRICT_CODE": "960106", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8781", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7385, "SUB_DISTRICT_CODE": "960107", "PROVINCE_ID": "76", "DISTRICT_ID": "985", "SUB_DISTRICT_ID": "8782", "ZIPCODE": "96000" },
    { "ZIPCODE_ID": 7386, "SUB_DISTRICT_CODE": "960201", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8783", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7387, "SUB_DISTRICT_CODE": "960202", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8784", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7388, "SUB_DISTRICT_CODE": "960203", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8785", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7389, "SUB_DISTRICT_CODE": "960204", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8786", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7390, "SUB_DISTRICT_CODE": "960205", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8787", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7391, "SUB_DISTRICT_CODE": "960206", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8788", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7392, "SUB_DISTRICT_CODE": "960207", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8789", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7393, "SUB_DISTRICT_CODE": "960208", "PROVINCE_ID": "76", "DISTRICT_ID": "986", "SUB_DISTRICT_ID": "8790", "ZIPCODE": "96110" },
    { "ZIPCODE_ID": 7394, "SUB_DISTRICT_CODE": "960301", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8791", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7395, "SUB_DISTRICT_CODE": "960302", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8792", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7396, "SUB_DISTRICT_CODE": "960303", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8793", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7397, "SUB_DISTRICT_CODE": "960304", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8794", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7398, "SUB_DISTRICT_CODE": "960305", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8795", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7399, "SUB_DISTRICT_CODE": "960306", "PROVINCE_ID": "76", "DISTRICT_ID": "987", "SUB_DISTRICT_ID": "8796", "ZIPCODE": "96170" },
    { "ZIPCODE_ID": 7400, "SUB_DISTRICT_CODE": "960401", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8797", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7401, "SUB_DISTRICT_CODE": "960402", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8798", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7402, "SUB_DISTRICT_CODE": "960403", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8799", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7403, "SUB_DISTRICT_CODE": "960404", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8800", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7404, "SUB_DISTRICT_CODE": "960405", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8801", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7405, "SUB_DISTRICT_CODE": "960406", "PROVINCE_ID": "76", "DISTRICT_ID": "988", "SUB_DISTRICT_ID": "8802", "ZIPCODE": "96180" },
    { "ZIPCODE_ID": 7406, "SUB_DISTRICT_CODE": "960501", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8803", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7407, "SUB_DISTRICT_CODE": "960502", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8804", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7408, "SUB_DISTRICT_CODE": "960506", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8808", "ZIPCODE": "96220" },
    { "ZIPCODE_ID": 7409, "SUB_DISTRICT_CODE": "960507", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8809", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7410, "SUB_DISTRICT_CODE": "960508", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8810", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7411, "SUB_DISTRICT_CODE": "960509", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8811", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7412, "SUB_DISTRICT_CODE": "960510", "PROVINCE_ID": "76", "DISTRICT_ID": "989", "SUB_DISTRICT_ID": "8812", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7413, "SUB_DISTRICT_CODE": "960601", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8815", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7414, "SUB_DISTRICT_CODE": "960602", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8816", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7415, "SUB_DISTRICT_CODE": "960603", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8817", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7416, "SUB_DISTRICT_CODE": "960604", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8818", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7417, "SUB_DISTRICT_CODE": "960605", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8819", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7418, "SUB_DISTRICT_CODE": "960606", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8820", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7419, "SUB_DISTRICT_CODE": "960607", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8821", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7420, "SUB_DISTRICT_CODE": "960608", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8822", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7421, "SUB_DISTRICT_CODE": "960609", "PROVINCE_ID": "76", "DISTRICT_ID": "990", "SUB_DISTRICT_ID": "8823", "ZIPCODE": "96150" },
    { "ZIPCODE_ID": 7422, "SUB_DISTRICT_CODE": "960701", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8826", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7423, "SUB_DISTRICT_CODE": "960702", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8827", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7424, "SUB_DISTRICT_CODE": "960703", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8828", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7425, "SUB_DISTRICT_CODE": "960704", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8829", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7426, "SUB_DISTRICT_CODE": "960705", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8830", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7427, "SUB_DISTRICT_CODE": "960706", "PROVINCE_ID": "76", "DISTRICT_ID": "991", "SUB_DISTRICT_ID": "8831", "ZIPCODE": "96210" },
    { "ZIPCODE_ID": 7428, "SUB_DISTRICT_CODE": "960801", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8832", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7429, "SUB_DISTRICT_CODE": "960802", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8833", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7430, "SUB_DISTRICT_CODE": "960803", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8834", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7431, "SUB_DISTRICT_CODE": "960804", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8835", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7432, "SUB_DISTRICT_CODE": "960805", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8836", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7433, "SUB_DISTRICT_CODE": "960806", "PROVINCE_ID": "76", "DISTRICT_ID": "992", "SUB_DISTRICT_ID": "8837", "ZIPCODE": "96160" },
    { "ZIPCODE_ID": 7434, "SUB_DISTRICT_CODE": "960901", "PROVINCE_ID": "76", "DISTRICT_ID": "993", "SUB_DISTRICT_ID": "8839", "ZIPCODE": "96190" },
    { "ZIPCODE_ID": 7435, "SUB_DISTRICT_CODE": "960902", "PROVINCE_ID": "76", "DISTRICT_ID": "993", "SUB_DISTRICT_ID": "8840", "ZIPCODE": "96190" },
    { "ZIPCODE_ID": 7436, "SUB_DISTRICT_CODE": "960903", "PROVINCE_ID": "76", "DISTRICT_ID": "993", "SUB_DISTRICT_ID": "8841", "ZIPCODE": "96190" },
    { "ZIPCODE_ID": 7437, "SUB_DISTRICT_CODE": "960904", "PROVINCE_ID": "76", "DISTRICT_ID": "993", "SUB_DISTRICT_ID": "8842", "ZIPCODE": "96190" },
    { "ZIPCODE_ID": 7438, "SUB_DISTRICT_CODE": "960905", "PROVINCE_ID": "76", "DISTRICT_ID": "993", "SUB_DISTRICT_ID": "8843", "ZIPCODE": "96190" },
    { "ZIPCODE_ID": 7439, "SUB_DISTRICT_CODE": "961001", "PROVINCE_ID": "76", "DISTRICT_ID": "994", "SUB_DISTRICT_ID": "8844", "ZIPCODE": "96120" },
    { "ZIPCODE_ID": 7440, "SUB_DISTRICT_CODE": "961002", "PROVINCE_ID": "76", "DISTRICT_ID": "994", "SUB_DISTRICT_ID": "8845", "ZIPCODE": "96120" },
    { "ZIPCODE_ID": 7441, "SUB_DISTRICT_CODE": "961003", "PROVINCE_ID": "76", "DISTRICT_ID": "994", "SUB_DISTRICT_ID": "8846", "ZIPCODE": "96120" },
    { "ZIPCODE_ID": 7442, "SUB_DISTRICT_CODE": "961004", "PROVINCE_ID": "76", "DISTRICT_ID": "994", "SUB_DISTRICT_ID": "8847", "ZIPCODE": "96120" },
    { "ZIPCODE_ID": 7443, "SUB_DISTRICT_CODE": "961101", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8848", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7444, "SUB_DISTRICT_CODE": "961102", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8849", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7445, "SUB_DISTRICT_CODE": "961103", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8850", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7446, "SUB_DISTRICT_CODE": "961104", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8851", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7447, "SUB_DISTRICT_CODE": "961105", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8852", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7448, "SUB_DISTRICT_CODE": "961106", "PROVINCE_ID": "76", "DISTRICT_ID": "995", "SUB_DISTRICT_ID": "8853", "ZIPCODE": "96140" },
    { "ZIPCODE_ID": 7449, "SUB_DISTRICT_CODE": "961201", "PROVINCE_ID": "76", "DISTRICT_ID": "996", "SUB_DISTRICT_ID": "8854", "ZIPCODE": "96220" },
    { "ZIPCODE_ID": 7450, "SUB_DISTRICT_CODE": "961202", "PROVINCE_ID": "76", "DISTRICT_ID": "996", "SUB_DISTRICT_ID": "8855", "ZIPCODE": "96220" },
    { "ZIPCODE_ID": 7451, "SUB_DISTRICT_CODE": "961203", "PROVINCE_ID": "76", "DISTRICT_ID": "996", "SUB_DISTRICT_ID": "8856", "ZIPCODE": "96220" },
    { "ZIPCODE_ID": 7452, "SUB_DISTRICT_CODE": "961204", "PROVINCE_ID": "76", "DISTRICT_ID": "996", "SUB_DISTRICT_ID": "8857", "ZIPCODE": "96220" },
    { "ZIPCODE_ID": 7453, "SUB_DISTRICT_CODE": "961301", "PROVINCE_ID": "76", "DISTRICT_ID": "997", "SUB_DISTRICT_ID": "8858", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7454, "SUB_DISTRICT_CODE": "961302", "PROVINCE_ID": "76", "DISTRICT_ID": "997", "SUB_DISTRICT_ID": "8859", "ZIPCODE": "96130" },
    { "ZIPCODE_ID": 7455, "SUB_DISTRICT_CODE": "961303", "PROVINCE_ID": "76", "DISTRICT_ID": "997", "SUB_DISTRICT_ID": "8860", "ZIPCODE": "96130" }
  ]