import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { Box, Paper, Container, Grid, TextField, Alert, Typography, Snackbar } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import { OTPRequest } from '../../utils/useApi';
import LockImg from "../../assets/icon/lock.svg"
import { useDispatch } from 'react-redux';
import { storePhone, storeToken } from '../../actions/resetpass';
import Swal from 'sweetalert2';

const schema = yup.object().shape({
    phone: yup.string().matches(/^0/, 'Phone number must start with 0').matches(/^[0-9]+$/, 'Phone number must contain only numbers').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit phone number').required(`Please input your phone number.`)
});

const Forgot = () => {
    const dispatch = useDispatch()
    const [loginLoading, setLoginLoading] = useState(false)
    const [openVerification, setopenVerification] = useState(false)
    const [token, setToken] = useState('')
    const [messageSnackbar, setMessageSnackbar] = useState("ttt")
    const [statusSnackbar, setStatusSnackbar] = useState("info")
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const watchLoginField = watch(["phone"])
    const checkLoginEnabled = !Boolean(watchLoginField[0])
    const handleVerificationAlertClose = () => {
        setopenVerification(false);
    };
    const onSubmit = async (values) => {
        try {
            setLoginLoading(true)
            const callRequestPhone = await OTPRequest(JSON.stringify({ "phone": values.phone }))
            if (callRequestPhone.status === true) {
                dispatch(storePhone(values.phone))
                dispatch(storeToken(callRequestPhone.data.token))
                navigate("/reset/otp-verification")
            } else {
                Swal.fire({
                    customClass: {
                        container: 'my-swal',
                    },
                    icon: 'error',
                    title: "This mobile number is not available.",
                })
            }
        } catch {
            console.log("Error")
        } finally {
            setLoginLoading(false)
        }
    }
    return (
        <Container component="form" maxWidth="xs">
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography component="h5" variant="h5" sx={{ textAlign: "center", fontWeight: "700", mb: 2 }}>Forgot your password?</Typography>
                <div style={{
                    height: '4px',
                    width: '55px',
                    display: 'block',
                    margin: '10px auto 20px',
                    backgroundColor: '#ff3366'
                }}></div>
                <Box
                    component="img"
                    src={LockImg}
                    sx={{
                        width: "100%",
                        height: "96px"
                    }}
                    justifyContent="center"
                    alignItems="center"
                ></Box>
                <Typography component="p" variant="body1" sx={{ mb: 2, textAlign: "center" }}>Please enter the phone number you used to sign up for Whealth Vitamin.</Typography>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            size='small'
                            id="phone"
                            name="phone"
                            label="Phone"
                            placeholder='XXX-XXX-XXXX'
                            fullWidth
                            variant="outlined"
                            {...register("phone")}
                            autoComplete="on"
                            error={Boolean(errors.phone)}
                            helperText={errors.phone?.message}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mt: 2 }}>
                    <LoadingButton
                        size="large"
                        fullWidth
                        sx={{ mx: 1 }}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        loading={loginLoading}
                        variant="contained"
                        disabled={checkLoginEnabled}
                    >Request password reset</LoadingButton>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Link to={"/login"} style={{ textAlign: "center", textDecoration: "none" }}>
                        Back to Sign in
                    </Link>
                </Box>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={5000}
                open={openVerification}
                onClose={handleVerificationAlertClose}
                key={'otpver'}
            >
                <Alert onClose={handleVerificationAlertClose} severity={statusSnackbar} variant='filled' sx={{ width: '100%' }}>
                    {messageSnackbar}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Forgot;