import axios from 'axios'

const instanceDb = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL
})

function authHeaders(token) {
    return {"Authorization" : `Bearer ${token}`}
}

const Myheaders = { "Content-Type": "application/json" }

export const getRefreshToken = async (token) => await instanceDb.post(`/auth/refresh`, {},
    {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//register
export const SignOut = async (data, token) => await instanceDb.post(`/auth/logout`, data, { headers: { 'Authorization': `Bearer ${token}` } }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const SignIn = async (data) => await instanceDb.post(`/auth/local/signin`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const OTPRequest = async (data) => await instanceDb.post(`/tbs/request`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const verifyOTP = async (data) => await instanceDb.post(`/tbs/verify`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const SignUpAPI = async (data) => await instanceDb.post(`/auth/local/signup`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const setOTPStatus = async (data) => await instanceDb.post(`/auth/Verifystatus`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//reset
export const callResetPassword = async (data) => await instanceDb.post(`/auth/callreset_password`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const resetPasswordAPI = async (data) => await instanceDb.post(`/auth/reset_password`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//process_answer
export const processAnswerAPI = async (data) => await instanceDb.post(`/ans/process_question`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//profile
export const fetchUserProfile = async (data) => await instanceDb.post(`/user/call_profile`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const updateUserProfile = async (data) => await instanceDb.post(`/user/edit_profile`, data).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//profile/address
export const fetchAddressData = async (data, token) => await instanceDb.post(`/user/search_all_address`, data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fecthAddressDataById = async (data, token) => await instanceDb.post(`/user/select_one_address`, data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const addnewAddressAPI = async (data) => await instanceDb.post(`/user/add_address`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const editAddressAPI = async (data) => await instanceDb.post(`/user/edit_address`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const deleteAddressAPI = async (data) => await instanceDb.post(`/user/del_user_address`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const setDefaultaddressAPI = async (data) => await instanceDb.post(`/user/change_main_address`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//profile/order_history
export const fetchOrderHistoryData = async (data) => await instanceDb.post(`/product/select_user_order`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchOrderById = async (data) => await instanceDb.post(`/product/select_onlyone_order`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//profile/report from assessment
export const fetchAssessmentResult = async (data) => await instanceDb.post(`/product/Select_All_Product_Assignment`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//profile/user_review
export const addNewReview = async (data) => await instanceDb.post(`/user/user_revivew`, data).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//shopping
export const fetchProduct = async (data) => await instanceDb.post(`/product/selectProduct`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchDetailProduct = async (data) => await instanceDb.post(`/product/select_product_filter`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//omisePayment
export const createOrderShopping = async (data) => await instanceDb.post(`product/generate_vitamin_order`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const updateOrderShopping = async (data) => await instanceDb.post(`product/update_data_order`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const checkoutwithCreditCard = async (data, token) => await instanceDb.post(`/omise/creditcharge`, data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const checkoutwithLotusBill = async (data, token) => await instanceDb.post(`/omise/billpayment`, data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const checkoutwithOmise = async (data, token) => await instanceDb.post(`/omise/omisecharge`, data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const sendOrderToCus = async (data) => await instanceDb.post(`/mailer/send-email`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const getChargedatawithIdOmise = async (data, token) => await instanceDb.post('/omise/getchargedata', data, { headers: authHeaders(token) }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//Fetch data from setting in admin
export const fetchHomepage = async (data) => await instanceDb.post(`/admin/home_page_article`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchCustomerReview = async () => await instanceDb.get(`/admin/select_customer_review`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchProductReview = async () => await instanceDb.get(`/admin/select_product_review`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchProductPreview = async () => await instanceDb.get(`/admin/select_shopping_preview`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//Blog
export const fetchBlog = async () => await instanceDb.get(`/admin/select_all_article`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const fetchDetailBlog = async (data) => await instanceDb.post(`/admin/select_single_article`, data, { headers: Myheaders }).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//Product Group for Footer and Filter product
export const fetchProductGroup = async () => await instanceDb.post(`/admin/select_product_group`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//Count Visitor
export const getVisitorPage = async () => await instanceDb.post(`/visit`).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })
export const addVisitorcount = async (data) => await instanceDb.post(`/visit/add`, data).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//Send Email from contact us
export const sendEmailtoCompany = async (data) => await instanceDb.post(`/mailer/send-email-user`, data).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })

//get image
export const getImage = async (data) => await instanceDb.post(`/visit/getimage`, data).then((res) => { return { "status": true, "data": res.data } }).catch((e) => { return { "status": false, "data": e } })