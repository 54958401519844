import { Box, Button, Container, Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ClockIcon from '../../assets/icon/clock-solid.svg';
import LocationIcon from '../../assets/icon/location-dot-solid.svg';
import PhoneIcon from '../../assets/icon/phone-solid.svg';
import { useTranslation } from 'react-i18next';
const Numbercontact = styled.h3`
    color: #ABCECC;
    font-size: 3rem;
    font-weight: 700;
    @media only screen and (max-width: 1199px) {
        font-size: 2.25rem;
    }
    @media only screen and (max-width: 991px) {
        font-size: 1.875rem;
    }
    @media (max-width: 575px) {
        font-size: 1.5rem;
    }
`
const DetailService = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
`

const ContactSection = () => {
    const { t } = useTranslation()
    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={2} py={3} justifyContent="center">
                    <Grid item md={8} sm={12}>
                        <img src={require("../../assets/slide/carousel-3.webp")} alt="contact" style={{ maxWidth: "100%", width: "100%", height: "450px", objectFit: "cover" }} />
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: "30px",
                            height: "100%",
                            justifyContent: "center"
                        }}>
                            <Numbercontact>086-3366336</Numbercontact>
                            <Typography variant="h6" color="#000000" component="div" my={2}>{t('homepage.address')}</Typography>
                            <Typography variant="body1" color="#000000" component="div">{t('homepage.response_time')}</Typography>
                            <Link href="/about/contact" underline="none" my={2}>
                                <Button disableElevation disableRipple sx={{
                                    borderRadius: 0, backgroundColor: "#daebea", color: "#111111", px: 5, py: 1, '&:hover': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}>
                                    <Typography variant="p" sx={{ letterSpacing: "3px", fontWeight: 700, textTransform: "uppercase" }}>
                                        Contact us
                                    </Typography>
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Box mb={2} sx={{ color: "#FFFFFF", backgroundColor: "#a8cecc", justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                <Container maxWidth="xl">
                    <Grid container rowSpacing={2}>
                        <Grid item lg={4} md={6} sm={12}>
                            <DetailService>
                                <Stack direction={"row"}>
                                    <img src={PhoneIcon} alt="location" width="30px" height="30px" />
                                    <Typography variant="h6" component="h6" ml={1} style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('homepage.contact_header.call')}</Typography>
                                </Stack>
                                <Typography variant="body1" component="div" mt={2} mr={1} style={{ wordWrap: "break-word" }}>
                                    086-3366336
                                </Typography>
                            </DetailService>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12}>
                            <DetailService>
                                <Stack direction={"row"}>
                                    <img src={LocationIcon} alt="location" width="30px" height="30px" />
                                    <Typography variant="h6" component="h6" ml={1} style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('homepage.contact_header.location')}</Typography>
                                </Stack>
                                <Typography variant="body1" component="div" mt={2} mr={1}>
                                    {t('homepage.address')}
                                </Typography>
                            </DetailService>
                        </Grid>
                        <Grid item lg={4} md={6} sm={12}>
                            <DetailService>
                                <Stack direction={"row"}>
                                    <img src={ClockIcon} alt="clock" width="30px" height="30px" />
                                    <Typography variant="h6" component="h6" ml={1} style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('homepage.contact_header.time')}</Typography>
                                </Stack>
                                <Typography variant="body1" component="div" mt={2} mr={1}>
                                    {t('homepage.time')}
                                </Typography>
                            </DetailService>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default ContactSection;