import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useRefresh from './useRefresh';
import { resetToken, setAccessToken, setRefreshToken } from '../actions';
import { addVisitorcount, getRefreshToken } from './useApi';
import axios from 'axios';

const useSessionAuth = () => {
    const { accessToken, refreshToken } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const location = useLocation()
    const { slowRefresh } = useRefresh()

    const get_ip_address = async () => {
        await axios.get('https://api.ipify.org/?format=json').then((res) => addVisitorcount({ "ip": res.data.ip }))
    }
    useEffect(() => {
        /* The code `if (location.pathname === "/")` checks if the current URL path is equal to "/". If
        it is, it calls the `addVisitorcount` function with an object containing the IP address of
        the visitor ("192.135.1.1"). This is likely used to track the number of visitors to the
        website and perform some action when the visitor is on the homepage ("/"). */

        if (location.pathname === "/") {
            get_ip_address()
        }
        if (!["/explore/products","/cart"].includes(location.pathname)) {
            let dateNow = new Date()
            if (new Date(refreshToken.expire) <= dateNow) {
                dispatch(resetToken())
            } else if (accessToken.expire <= dateNow) {
                getRefreshToken(refreshToken.token).then(res => {
                    try {
                        if (res.status) {
                            dispatch(setAccessToken(res.data.access_token.token, res.data.access_token.expire))
                            dispatch(setRefreshToken(res.data.refresh_token.token, res.data.refresh_token.expire))
                        } else {
                            dispatch(resetToken())
                        }
                    } catch {
                        dispatch(resetToken())
                    }
                })
            } else {
                getRefreshToken(refreshToken.token).then(res => {
                    try {
                        if (res.status) {
                            dispatch(setAccessToken(res.data.access_token.token, res.data.access_token.expire))
                            dispatch(setRefreshToken(res.data.refresh_token.token, res.data.refresh_token.expire))
                        } else {
                            dispatch(resetToken())
                        }
                    } catch {
                        dispatch(resetToken())
                    }
                })
            }

        }
    }, [location, slowRefresh])
};

export default useSessionAuth;