export const SubDistrict = [
    { "SUB_DISTRICT_ID": 1, "SUB_DISTRICT_CODE": "100101", "SUB_DISTRICT_NAME": "พระบรมมหาราชวัง", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 2, "SUB_DISTRICT_CODE": "100102", "SUB_DISTRICT_NAME": "วังบูรพาภิรมย์", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 3, "SUB_DISTRICT_CODE": "100103", "SUB_DISTRICT_NAME": "วัดราชบพิธ", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 4, "SUB_DISTRICT_CODE": "100104", "SUB_DISTRICT_NAME": "สำราญราษฎร์", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 5, "SUB_DISTRICT_CODE": "100105", "SUB_DISTRICT_NAME": "ศาลเจ้าพ่อเสือ", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6, "SUB_DISTRICT_CODE": "100106", "SUB_DISTRICT_NAME": "เสาชิงช้า", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7, "SUB_DISTRICT_CODE": "100107", "SUB_DISTRICT_NAME": "บวรนิเวศ", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 8, "SUB_DISTRICT_CODE": "100108", "SUB_DISTRICT_NAME": "ตลาดยอด", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 9, "SUB_DISTRICT_CODE": "100109", "SUB_DISTRICT_NAME": "ชนะสงคราม", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 10, "SUB_DISTRICT_CODE": "100110", "SUB_DISTRICT_NAME": "บ้านพานถม", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 11, "SUB_DISTRICT_CODE": "100111", "SUB_DISTRICT_NAME": "บางขุนพรหม", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 12, "SUB_DISTRICT_CODE": "100112", "SUB_DISTRICT_NAME": "วัดสามพระยา", "DISTRICT_ID": 1, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 13, "SUB_DISTRICT_CODE": "100201", "SUB_DISTRICT_NAME": "ดุสิต", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 14, "SUB_DISTRICT_CODE": "100202", "SUB_DISTRICT_NAME": "วชิรพยาบาล", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 15, "SUB_DISTRICT_CODE": "100203", "SUB_DISTRICT_NAME": "สวนจิตรลดา", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 16, "SUB_DISTRICT_CODE": "100204", "SUB_DISTRICT_NAME": "สี่แยกมหานาค", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 17, "SUB_DISTRICT_CODE": "100205", "SUB_DISTRICT_NAME": "*บางซื่อ", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 18, "SUB_DISTRICT_CODE": "100206", "SUB_DISTRICT_NAME": "ถนนนครไชยศรี", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 19, "SUB_DISTRICT_CODE": "100299", "SUB_DISTRICT_NAME": "*สามเสนใน", "DISTRICT_ID": 2, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 20, "SUB_DISTRICT_CODE": "100301", "SUB_DISTRICT_NAME": "กระทุ่มราย", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 21, "SUB_DISTRICT_CODE": "100302", "SUB_DISTRICT_NAME": "หนองจอก", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 22, "SUB_DISTRICT_CODE": "100303", "SUB_DISTRICT_NAME": "คลองสิบ", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 23, "SUB_DISTRICT_CODE": "100304", "SUB_DISTRICT_NAME": "คลองสิบสอง", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 24, "SUB_DISTRICT_CODE": "100305", "SUB_DISTRICT_NAME": "โคกแฝด", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 25, "SUB_DISTRICT_CODE": "100306", "SUB_DISTRICT_NAME": "คู้ฝั่งเหนือ", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 26, "SUB_DISTRICT_CODE": "100307", "SUB_DISTRICT_NAME": "ลำผักชี", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 27, "SUB_DISTRICT_CODE": "100308", "SUB_DISTRICT_NAME": "ลำต้อยติ่ง", "DISTRICT_ID": 3, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 28, "SUB_DISTRICT_CODE": "100401", "SUB_DISTRICT_NAME": "มหาพฤฒาราม", "DISTRICT_ID": 4, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 29, "SUB_DISTRICT_CODE": "100402", "SUB_DISTRICT_NAME": "สีลม", "DISTRICT_ID": 4, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 30, "SUB_DISTRICT_CODE": "100403", "SUB_DISTRICT_NAME": "สุริยวงศ์", "DISTRICT_ID": 4, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 31, "SUB_DISTRICT_CODE": "100404", "SUB_DISTRICT_NAME": "บางรัก", "DISTRICT_ID": 4, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 32, "SUB_DISTRICT_CODE": "100405", "SUB_DISTRICT_NAME": "สี่พระยา", "DISTRICT_ID": 4, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 33, "SUB_DISTRICT_CODE": "100501", "SUB_DISTRICT_NAME": "*ลาดยาว", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 34, "SUB_DISTRICT_CODE": "100502", "SUB_DISTRICT_NAME": "อนุสาวรีย์", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 35, "SUB_DISTRICT_CODE": "100503", "SUB_DISTRICT_NAME": "คลองถนน*", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 36, "SUB_DISTRICT_CODE": "100504", "SUB_DISTRICT_NAME": "*ตลาดบางเขน", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 37, "SUB_DISTRICT_CODE": "100505", "SUB_DISTRICT_NAME": "*สีกัน", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 38, "SUB_DISTRICT_CODE": "100506", "SUB_DISTRICT_NAME": "สายไหม*", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 39, "SUB_DISTRICT_CODE": "100507", "SUB_DISTRICT_NAME": "*ทุ่งสองห้อง", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 40, "SUB_DISTRICT_CODE": "100508", "SUB_DISTRICT_NAME": "ท่าแร้ง", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 41, "SUB_DISTRICT_CODE": "100509", "SUB_DISTRICT_NAME": "ออเงิน*", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 42, "SUB_DISTRICT_CODE": "100599", "SUB_DISTRICT_NAME": "*บางเขน", "DISTRICT_ID": 5, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 43, "SUB_DISTRICT_CODE": "100601", "SUB_DISTRICT_NAME": "คลองจั่น", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 44, "SUB_DISTRICT_CODE": "100602", "SUB_DISTRICT_NAME": "วังทองหลาง*", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 45, "SUB_DISTRICT_CODE": "100603", "SUB_DISTRICT_NAME": "*ลาดพร้าว", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 46, "SUB_DISTRICT_CODE": "100604", "SUB_DISTRICT_NAME": "*คลองกุ่ม", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 47, "SUB_DISTRICT_CODE": "100605", "SUB_DISTRICT_NAME": "*สะพานสูง", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 48, "SUB_DISTRICT_CODE": "100606", "SUB_DISTRICT_NAME": "*คันนายาว", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 49, "SUB_DISTRICT_CODE": "100607", "SUB_DISTRICT_NAME": "*จรเข้บัว", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 50, "SUB_DISTRICT_CODE": "100608", "SUB_DISTRICT_NAME": "หัวหมาก", "DISTRICT_ID": 6, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 51, "SUB_DISTRICT_CODE": "100701", "SUB_DISTRICT_NAME": "รองเมือง", "DISTRICT_ID": 7, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 52, "SUB_DISTRICT_CODE": "100702", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 7, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 53, "SUB_DISTRICT_CODE": "100703", "SUB_DISTRICT_NAME": "ปทุมวัน", "DISTRICT_ID": 7, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 54, "SUB_DISTRICT_CODE": "100704", "SUB_DISTRICT_NAME": "ลุมพินี", "DISTRICT_ID": 7, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 55, "SUB_DISTRICT_CODE": "100801", "SUB_DISTRICT_NAME": "ป้อมปราบ", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 56, "SUB_DISTRICT_CODE": "100802", "SUB_DISTRICT_NAME": "วัดเทพศิรินทร์", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 57, "SUB_DISTRICT_CODE": "100803", "SUB_DISTRICT_NAME": "คลองมหานาค", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 58, "SUB_DISTRICT_CODE": "100804", "SUB_DISTRICT_NAME": "บ้านบาตร", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 59, "SUB_DISTRICT_CODE": "100805", "SUB_DISTRICT_NAME": "วัดโสมนัส", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 60, "SUB_DISTRICT_CODE": "100899", "SUB_DISTRICT_NAME": "*นางเลิ้ง", "DISTRICT_ID": 8, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 61, "SUB_DISTRICT_CODE": "100901", "SUB_DISTRICT_NAME": "*คลองเตย", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 62, "SUB_DISTRICT_CODE": "100902", "SUB_DISTRICT_NAME": "*คลองตัน", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 63, "SUB_DISTRICT_CODE": "100903", "SUB_DISTRICT_NAME": "*พระโขนง", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 64, "SUB_DISTRICT_CODE": "100904", "SUB_DISTRICT_NAME": "บางนา*", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 65, "SUB_DISTRICT_CODE": "100905", "SUB_DISTRICT_NAME": "บางจาก", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 66, "SUB_DISTRICT_CODE": "100906", "SUB_DISTRICT_NAME": "*สวนหลวง", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 67, "SUB_DISTRICT_CODE": "100907", "SUB_DISTRICT_NAME": "*หนองบอน", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 68, "SUB_DISTRICT_CODE": "100908", "SUB_DISTRICT_NAME": "*ประเวศ", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 69, "SUB_DISTRICT_CODE": "100909", "SUB_DISTRICT_NAME": "*ดอกไม้", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 70, "SUB_DISTRICT_CODE": "100997", "SUB_DISTRICT_NAME": "*พระโขนง", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 71, "SUB_DISTRICT_CODE": "100998", "SUB_DISTRICT_NAME": "*คลองตัน", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 72, "SUB_DISTRICT_CODE": "100999", "SUB_DISTRICT_NAME": "*คลองเตย", "DISTRICT_ID": 9, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 73, "SUB_DISTRICT_CODE": "101001", "SUB_DISTRICT_NAME": "มีนบุรี", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 74, "SUB_DISTRICT_CODE": "101002", "SUB_DISTRICT_NAME": "แสนแสบ", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 75, "SUB_DISTRICT_CODE": "101003", "SUB_DISTRICT_NAME": "บางชัน*", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 76, "SUB_DISTRICT_CODE": "101004", "SUB_DISTRICT_NAME": "ทรายกองดิน*", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 77, "SUB_DISTRICT_CODE": "101005", "SUB_DISTRICT_NAME": "ทรายกองดินใต้*", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 78, "SUB_DISTRICT_CODE": "101006", "SUB_DISTRICT_NAME": "สามวาตะวันออก*", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 79, "SUB_DISTRICT_CODE": "101007", "SUB_DISTRICT_NAME": "สามวาตะวันตก*", "DISTRICT_ID": 10, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 80, "SUB_DISTRICT_CODE": "101101", "SUB_DISTRICT_NAME": "ลาดกระบัง", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 81, "SUB_DISTRICT_CODE": "101102", "SUB_DISTRICT_NAME": "คลองสองต้นนุ่น", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 82, "SUB_DISTRICT_CODE": "101103", "SUB_DISTRICT_NAME": "คลองสามประเวศ", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 83, "SUB_DISTRICT_CODE": "101104", "SUB_DISTRICT_NAME": "ลำปลาทิว", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 84, "SUB_DISTRICT_CODE": "101105", "SUB_DISTRICT_NAME": "ทับยาว", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 85, "SUB_DISTRICT_CODE": "101106", "SUB_DISTRICT_NAME": "ขุมทอง", "DISTRICT_ID": 11, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 86, "SUB_DISTRICT_CODE": "101201", "SUB_DISTRICT_NAME": "*ทุ่งวัดดอน", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 87, "SUB_DISTRICT_CODE": "101202", "SUB_DISTRICT_NAME": "*ยานนาวา", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 88, "SUB_DISTRICT_CODE": "101203", "SUB_DISTRICT_NAME": "ช่องนนทรี", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 89, "SUB_DISTRICT_CODE": "101204", "SUB_DISTRICT_NAME": "บางโพงพาง", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 90, "SUB_DISTRICT_CODE": "101205", "SUB_DISTRICT_NAME": "*วัดพระยาไกร", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 91, "SUB_DISTRICT_CODE": "101206", "SUB_DISTRICT_NAME": "*บางโคล่", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 92, "SUB_DISTRICT_CODE": "101207", "SUB_DISTRICT_NAME": "*บางคอแหลม", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 93, "SUB_DISTRICT_CODE": "101208", "SUB_DISTRICT_NAME": "*ทุ่งมหาเมฆ", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 94, "SUB_DISTRICT_CODE": "101298", "SUB_DISTRICT_NAME": "*บางโคล่", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 95, "SUB_DISTRICT_CODE": "101299", "SUB_DISTRICT_NAME": "*บางคอแหลม", "DISTRICT_ID": 12, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 96, "SUB_DISTRICT_CODE": "101301", "SUB_DISTRICT_NAME": "จักรวรรดิ", "DISTRICT_ID": 13, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 97, "SUB_DISTRICT_CODE": "101302", "SUB_DISTRICT_NAME": "สัมพันธวงศ์", "DISTRICT_ID": 13, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 98, "SUB_DISTRICT_CODE": "101303", "SUB_DISTRICT_NAME": "ตลาดน้อย", "DISTRICT_ID": 13, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 99, "SUB_DISTRICT_CODE": "101401", "SUB_DISTRICT_NAME": "สามเสนใน", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 100, "SUB_DISTRICT_CODE": "101402", "SUB_DISTRICT_NAME": "*ถนนเพชรบุรี", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 101, "SUB_DISTRICT_CODE": "101403", "SUB_DISTRICT_NAME": "*ทุ่งพญาไท", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 102, "SUB_DISTRICT_CODE": "101404", "SUB_DISTRICT_NAME": "*มักกะสัน", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 103, "SUB_DISTRICT_CODE": "101405", "SUB_DISTRICT_NAME": "*ถนนพญาไท", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 104, "SUB_DISTRICT_CODE": "101499", "SUB_DISTRICT_NAME": "*ทุ่งพญาไท", "DISTRICT_ID": 14, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 105, "SUB_DISTRICT_CODE": "101501", "SUB_DISTRICT_NAME": "วัดกัลยาณ์", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 106, "SUB_DISTRICT_CODE": "101502", "SUB_DISTRICT_NAME": "หิรัญรูจี", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 107, "SUB_DISTRICT_CODE": "101503", "SUB_DISTRICT_NAME": "บางยี่เรือ", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 108, "SUB_DISTRICT_CODE": "101504", "SUB_DISTRICT_NAME": "บุคคโล", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 109, "SUB_DISTRICT_CODE": "101505", "SUB_DISTRICT_NAME": "ตลาดพลู", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 110, "SUB_DISTRICT_CODE": "101506", "SUB_DISTRICT_NAME": "ดาวคะนอง", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 111, "SUB_DISTRICT_CODE": "101507", "SUB_DISTRICT_NAME": "สำเหร่", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 112, "SUB_DISTRICT_CODE": "101599", "SUB_DISTRICT_NAME": "*คลองสาน", "DISTRICT_ID": 15, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 113, "SUB_DISTRICT_CODE": "101601", "SUB_DISTRICT_NAME": "วัดอรุณ", "DISTRICT_ID": 16, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 114, "SUB_DISTRICT_CODE": "101602", "SUB_DISTRICT_NAME": "วัดท่าพระ", "DISTRICT_ID": 16, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 115, "SUB_DISTRICT_CODE": "101701", "SUB_DISTRICT_NAME": "ห้วยขวาง", "DISTRICT_ID": 17, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 116, "SUB_DISTRICT_CODE": "101702", "SUB_DISTRICT_NAME": "บางกะปิ", "DISTRICT_ID": 17, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 117, "SUB_DISTRICT_CODE": "101703", "SUB_DISTRICT_NAME": "*ดินแดง", "DISTRICT_ID": 17, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 118, "SUB_DISTRICT_CODE": "101704", "SUB_DISTRICT_NAME": "สามเสนนอก", "DISTRICT_ID": 17, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 119, "SUB_DISTRICT_CODE": "101801", "SUB_DISTRICT_NAME": "สมเด็จเจ้าพระยา", "DISTRICT_ID": 18, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 120, "SUB_DISTRICT_CODE": "101802", "SUB_DISTRICT_NAME": "คลองสาน", "DISTRICT_ID": 18, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 121, "SUB_DISTRICT_CODE": "101803", "SUB_DISTRICT_NAME": "บางลำภูล่าง", "DISTRICT_ID": 18, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 122, "SUB_DISTRICT_CODE": "101804", "SUB_DISTRICT_NAME": "คลองต้นไทร", "DISTRICT_ID": 18, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 123, "SUB_DISTRICT_CODE": "101901", "SUB_DISTRICT_NAME": "คลองชักพระ", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 124, "SUB_DISTRICT_CODE": "101902", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 125, "SUB_DISTRICT_CODE": "101903", "SUB_DISTRICT_NAME": "ฉิมพลี", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 126, "SUB_DISTRICT_CODE": "101904", "SUB_DISTRICT_NAME": "บางพรม", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 127, "SUB_DISTRICT_CODE": "101905", "SUB_DISTRICT_NAME": "บางระมาด", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 128, "SUB_DISTRICT_CODE": "101906", "SUB_DISTRICT_NAME": "ทวีวัฒนา*", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 129, "SUB_DISTRICT_CODE": "101907", "SUB_DISTRICT_NAME": "บางเชือกหนัง", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 130, "SUB_DISTRICT_CODE": "101908", "SUB_DISTRICT_NAME": "ศาลาธรรมสพน์*", "DISTRICT_ID": 19, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 131, "SUB_DISTRICT_CODE": "102001", "SUB_DISTRICT_NAME": "*บางพลัด", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 132, "SUB_DISTRICT_CODE": "102002", "SUB_DISTRICT_NAME": "*บางบำหรุ", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 133, "SUB_DISTRICT_CODE": "102003", "SUB_DISTRICT_NAME": "*บางอ้อ", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 134, "SUB_DISTRICT_CODE": "102004", "SUB_DISTRICT_NAME": "ศิริราช", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 135, "SUB_DISTRICT_CODE": "102005", "SUB_DISTRICT_NAME": "บ้านช่างหล่อ", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 136, "SUB_DISTRICT_CODE": "102006", "SUB_DISTRICT_NAME": "บางขุนนนท์", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 137, "SUB_DISTRICT_CODE": "102007", "SUB_DISTRICT_NAME": "บางขุนศรี", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 138, "SUB_DISTRICT_CODE": "102008", "SUB_DISTRICT_NAME": "*บางยี่ขัน", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 139, "SUB_DISTRICT_CODE": "102009", "SUB_DISTRICT_NAME": "อรุณอมรินทร์", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 140, "SUB_DISTRICT_CODE": "102097", "SUB_DISTRICT_NAME": "*บางยี่ขัน", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 141, "SUB_DISTRICT_CODE": "102098", "SUB_DISTRICT_NAME": "*บางบำหรุ", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 142, "SUB_DISTRICT_CODE": "102099", "SUB_DISTRICT_NAME": "*บางอ้อ", "DISTRICT_ID": 20, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 143, "SUB_DISTRICT_CODE": "102101", "SUB_DISTRICT_NAME": "*บางค้อ", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 144, "SUB_DISTRICT_CODE": "102102", "SUB_DISTRICT_NAME": "*จอมทอง", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 145, "SUB_DISTRICT_CODE": "102103", "SUB_DISTRICT_NAME": "*บางขุนเทียน", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 146, "SUB_DISTRICT_CODE": "102104", "SUB_DISTRICT_NAME": "บางบอน*", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 147, "SUB_DISTRICT_CODE": "102105", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 148, "SUB_DISTRICT_CODE": "102106", "SUB_DISTRICT_NAME": "*บางมด", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 149, "SUB_DISTRICT_CODE": "102107", "SUB_DISTRICT_NAME": "แสมดำ", "DISTRICT_ID": 21, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 150, "SUB_DISTRICT_CODE": "102201", "SUB_DISTRICT_NAME": "บางหว้า", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 151, "SUB_DISTRICT_CODE": "102202", "SUB_DISTRICT_NAME": "บางด้วน", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 152, "SUB_DISTRICT_CODE": "102203", "SUB_DISTRICT_NAME": "บางแค", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 153, "SUB_DISTRICT_CODE": "102204", "SUB_DISTRICT_NAME": "บางแคเหนือ", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 154, "SUB_DISTRICT_CODE": "102205", "SUB_DISTRICT_NAME": "บางไผ่", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 155, "SUB_DISTRICT_CODE": "102206", "SUB_DISTRICT_NAME": "บางจาก", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 156, "SUB_DISTRICT_CODE": "102207", "SUB_DISTRICT_NAME": "บางแวก", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 157, "SUB_DISTRICT_CODE": "102208", "SUB_DISTRICT_NAME": "คลองขวาง", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 158, "SUB_DISTRICT_CODE": "102209", "SUB_DISTRICT_NAME": "ปากคลองภาษีเจริญ", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 159, "SUB_DISTRICT_CODE": "102210", "SUB_DISTRICT_NAME": "คูหาสวรรค์", "DISTRICT_ID": 22, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 160, "SUB_DISTRICT_CODE": "102301", "SUB_DISTRICT_NAME": "หลักสอง*", "DISTRICT_ID": 23, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 161, "SUB_DISTRICT_CODE": "102302", "SUB_DISTRICT_NAME": "หนองแขม", "DISTRICT_ID": 23, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 162, "SUB_DISTRICT_CODE": "102303", "SUB_DISTRICT_NAME": "หนองค้างพลู", "DISTRICT_ID": 23, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 163, "SUB_DISTRICT_CODE": "102401", "SUB_DISTRICT_NAME": "ราษฎร์บูรณะ", "DISTRICT_ID": 24, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 164, "SUB_DISTRICT_CODE": "102402", "SUB_DISTRICT_NAME": "บางปะกอก", "DISTRICT_ID": 24, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 165, "SUB_DISTRICT_CODE": "102403", "SUB_DISTRICT_NAME": "บางมด*", "DISTRICT_ID": 24, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 166, "SUB_DISTRICT_CODE": "102404", "SUB_DISTRICT_NAME": "ทุ่งครุ*", "DISTRICT_ID": 24, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 167, "SUB_DISTRICT_CODE": "102501", "SUB_DISTRICT_NAME": "บางพลัด", "DISTRICT_ID": 25, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 168, "SUB_DISTRICT_CODE": "102502", "SUB_DISTRICT_NAME": "บางอ้อ", "DISTRICT_ID": 25, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 169, "SUB_DISTRICT_CODE": "102503", "SUB_DISTRICT_NAME": "บางบำหรุ", "DISTRICT_ID": 25, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 170, "SUB_DISTRICT_CODE": "102504", "SUB_DISTRICT_NAME": "บางยี่ขัน", "DISTRICT_ID": 25, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 171, "SUB_DISTRICT_CODE": "102601", "SUB_DISTRICT_NAME": "ดินแดง", "DISTRICT_ID": 26, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 172, "SUB_DISTRICT_CODE": "102701", "SUB_DISTRICT_NAME": "คลองกุ่ม", "DISTRICT_ID": 27, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 173, "SUB_DISTRICT_CODE": "102702", "SUB_DISTRICT_NAME": "สะพานสูง", "DISTRICT_ID": 27, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 174, "SUB_DISTRICT_CODE": "102703", "SUB_DISTRICT_NAME": "คันนายาว", "DISTRICT_ID": 27, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 175, "SUB_DISTRICT_CODE": "102801", "SUB_DISTRICT_NAME": "ทุ่งวัดดอน", "DISTRICT_ID": 28, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 176, "SUB_DISTRICT_CODE": "102802", "SUB_DISTRICT_NAME": "ยานนาวา", "DISTRICT_ID": 28, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 177, "SUB_DISTRICT_CODE": "102803", "SUB_DISTRICT_NAME": "ทุ่งมหาเมฆ", "DISTRICT_ID": 28, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 178, "SUB_DISTRICT_CODE": "102901", "SUB_DISTRICT_NAME": "บางซื่อ", "DISTRICT_ID": 29, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 179, "SUB_DISTRICT_CODE": "103001", "SUB_DISTRICT_NAME": "ลาดยาว", "DISTRICT_ID": 30, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 180, "SUB_DISTRICT_CODE": "103002", "SUB_DISTRICT_NAME": "เสนานิคม", "DISTRICT_ID": 30, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 181, "SUB_DISTRICT_CODE": "103003", "SUB_DISTRICT_NAME": "จันทรเกษม", "DISTRICT_ID": 30, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 182, "SUB_DISTRICT_CODE": "103004", "SUB_DISTRICT_NAME": "จอมพล", "DISTRICT_ID": 30, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 183, "SUB_DISTRICT_CODE": "103005", "SUB_DISTRICT_NAME": "จตุจักร", "DISTRICT_ID": 30, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 184, "SUB_DISTRICT_CODE": "103101", "SUB_DISTRICT_NAME": "บางคอแหลม", "DISTRICT_ID": 31, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 185, "SUB_DISTRICT_CODE": "103102", "SUB_DISTRICT_NAME": "วัดพระยาไกร", "DISTRICT_ID": 31, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 186, "SUB_DISTRICT_CODE": "103103", "SUB_DISTRICT_NAME": "บางโคล่", "DISTRICT_ID": 31, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 187, "SUB_DISTRICT_CODE": "103201", "SUB_DISTRICT_NAME": "ประเวศ", "DISTRICT_ID": 32, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 188, "SUB_DISTRICT_CODE": "103202", "SUB_DISTRICT_NAME": "หนองบอน", "DISTRICT_ID": 32, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 189, "SUB_DISTRICT_CODE": "103203", "SUB_DISTRICT_NAME": "ดอกไม้", "DISTRICT_ID": 32, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 190, "SUB_DISTRICT_CODE": "103204", "SUB_DISTRICT_NAME": "สวนหลวง", "DISTRICT_ID": 32, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 191, "SUB_DISTRICT_CODE": "103301", "SUB_DISTRICT_NAME": "คลองเตย", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 192, "SUB_DISTRICT_CODE": "103302", "SUB_DISTRICT_NAME": "คลองตัน", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 193, "SUB_DISTRICT_CODE": "103303", "SUB_DISTRICT_NAME": "พระโขนง", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 194, "SUB_DISTRICT_CODE": "103304", "SUB_DISTRICT_NAME": "คลองเตยเหนือ", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 195, "SUB_DISTRICT_CODE": "103305", "SUB_DISTRICT_NAME": "คลองตันเหนือ", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 196, "SUB_DISTRICT_CODE": "103306", "SUB_DISTRICT_NAME": "พระโขนงเหนือ", "DISTRICT_ID": 33, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 197, "SUB_DISTRICT_CODE": "103401", "SUB_DISTRICT_NAME": "สวนหลวง", "DISTRICT_ID": 34, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 198, "SUB_DISTRICT_CODE": "103501", "SUB_DISTRICT_NAME": "บางขุนเทียน", "DISTRICT_ID": 35, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 199, "SUB_DISTRICT_CODE": "103502", "SUB_DISTRICT_NAME": "บางค้อ", "DISTRICT_ID": 35, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 200, "SUB_DISTRICT_CODE": "103503", "SUB_DISTRICT_NAME": "บางมด", "DISTRICT_ID": 35, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 201, "SUB_DISTRICT_CODE": "103504", "SUB_DISTRICT_NAME": "จอมทอง", "DISTRICT_ID": 35, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 202, "SUB_DISTRICT_CODE": "103601", "SUB_DISTRICT_NAME": "ตลาดบางเขน*", "DISTRICT_ID": 36, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 203, "SUB_DISTRICT_CODE": "103602", "SUB_DISTRICT_NAME": "สีกัน", "DISTRICT_ID": 36, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 204, "SUB_DISTRICT_CODE": "103603", "SUB_DISTRICT_NAME": "ทุ่งสองห้อง*", "DISTRICT_ID": 36, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 205, "SUB_DISTRICT_CODE": "103701", "SUB_DISTRICT_NAME": "ทุ่งพญาไท", "DISTRICT_ID": 37, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 206, "SUB_DISTRICT_CODE": "103702", "SUB_DISTRICT_NAME": "ถนนพญาไท", "DISTRICT_ID": 37, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 207, "SUB_DISTRICT_CODE": "103703", "SUB_DISTRICT_NAME": "ถนนเพชรบุรี", "DISTRICT_ID": 37, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 208, "SUB_DISTRICT_CODE": "103704", "SUB_DISTRICT_NAME": "มักกะสัน", "DISTRICT_ID": 37, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 209, "SUB_DISTRICT_CODE": "103801", "SUB_DISTRICT_NAME": "ลาดพร้าว", "DISTRICT_ID": 38, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 210, "SUB_DISTRICT_CODE": "103802", "SUB_DISTRICT_NAME": "จรเข้บัว", "DISTRICT_ID": 38, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 211, "SUB_DISTRICT_CODE": "103901", "SUB_DISTRICT_NAME": "คลองเตยเหนือ", "DISTRICT_ID": 39, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 212, "SUB_DISTRICT_CODE": "103902", "SUB_DISTRICT_NAME": "คลองตันเหนือ", "DISTRICT_ID": 39, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 213, "SUB_DISTRICT_CODE": "103903", "SUB_DISTRICT_NAME": "พระโขนงเหนือ", "DISTRICT_ID": 39, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 214, "SUB_DISTRICT_CODE": "104001", "SUB_DISTRICT_NAME": "บางแค", "DISTRICT_ID": 40, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 215, "SUB_DISTRICT_CODE": "104002", "SUB_DISTRICT_NAME": "บางแคเหนือ", "DISTRICT_ID": 40, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 216, "SUB_DISTRICT_CODE": "104003", "SUB_DISTRICT_NAME": "บางไผ่", "DISTRICT_ID": 40, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 217, "SUB_DISTRICT_CODE": "104004", "SUB_DISTRICT_NAME": "หลักสอง", "DISTRICT_ID": 40, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 218, "SUB_DISTRICT_CODE": "104101", "SUB_DISTRICT_NAME": "ทุ่งสองห้อง", "DISTRICT_ID": 41, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 219, "SUB_DISTRICT_CODE": "104102", "SUB_DISTRICT_NAME": "ตลาดบางเขน", "DISTRICT_ID": 41, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 220, "SUB_DISTRICT_CODE": "104201", "SUB_DISTRICT_NAME": "สายไหม", "DISTRICT_ID": 42, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 221, "SUB_DISTRICT_CODE": "104202", "SUB_DISTRICT_NAME": "ออเงิน", "DISTRICT_ID": 42, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 222, "SUB_DISTRICT_CODE": "104203", "SUB_DISTRICT_NAME": "คลองถนน", "DISTRICT_ID": 42, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 223, "SUB_DISTRICT_CODE": "104301", "SUB_DISTRICT_NAME": "คันนายาว", "DISTRICT_ID": 43, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 224, "SUB_DISTRICT_CODE": "104401", "SUB_DISTRICT_NAME": "สะพานสูง", "DISTRICT_ID": 44, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 225, "SUB_DISTRICT_CODE": "104501", "SUB_DISTRICT_NAME": "วังทองหลาง", "DISTRICT_ID": 45, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 226, "SUB_DISTRICT_CODE": "104601", "SUB_DISTRICT_NAME": "สามวาตะวันตก", "DISTRICT_ID": 46, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 227, "SUB_DISTRICT_CODE": "104602", "SUB_DISTRICT_NAME": "สามวาตะวันออก", "DISTRICT_ID": 46, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 228, "SUB_DISTRICT_CODE": "104603", "SUB_DISTRICT_NAME": "บางชัน", "DISTRICT_ID": 46, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 229, "SUB_DISTRICT_CODE": "104604", "SUB_DISTRICT_NAME": "ทรายกองดิน", "DISTRICT_ID": 46, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 230, "SUB_DISTRICT_CODE": "104605", "SUB_DISTRICT_NAME": "ทรายกองดินใต้", "DISTRICT_ID": 46, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 231, "SUB_DISTRICT_CODE": "104701", "SUB_DISTRICT_NAME": "บางนา", "DISTRICT_ID": 47, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 232, "SUB_DISTRICT_CODE": "104801", "SUB_DISTRICT_NAME": "ทวีวัฒนา", "DISTRICT_ID": 48, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 233, "SUB_DISTRICT_CODE": "104802", "SUB_DISTRICT_NAME": "ศาลาธรรมสพน์", "DISTRICT_ID": 48, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 234, "SUB_DISTRICT_CODE": "104901", "SUB_DISTRICT_NAME": "บางมด", "DISTRICT_ID": 49, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 235, "SUB_DISTRICT_CODE": "104902", "SUB_DISTRICT_NAME": "ทุ่งครุ", "DISTRICT_ID": 49, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 236, "SUB_DISTRICT_CODE": "105001", "SUB_DISTRICT_NAME": "บางบอน", "DISTRICT_ID": 50, "PROVINCE_ID": 1, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 237, "SUB_DISTRICT_CODE": "110101", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 238, "SUB_DISTRICT_CODE": "110102", "SUB_DISTRICT_NAME": "สำโรงเหนือ", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 239, "SUB_DISTRICT_CODE": "110103", "SUB_DISTRICT_NAME": "บางเมือง", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 240, "SUB_DISTRICT_CODE": "110104", "SUB_DISTRICT_NAME": "ท้ายบ้าน", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 241, "SUB_DISTRICT_CODE": "110105", "SUB_DISTRICT_NAME": "*นาเกลือ", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 242, "SUB_DISTRICT_CODE": "110106", "SUB_DISTRICT_NAME": "*แหลมฟ้าผ่า", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 243, "SUB_DISTRICT_CODE": "110107", "SUB_DISTRICT_NAME": "*ในคลองบางปลากด", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 244, "SUB_DISTRICT_CODE": "110108", "SUB_DISTRICT_NAME": "บางปูใหม่", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 245, "SUB_DISTRICT_CODE": "110109", "SUB_DISTRICT_NAME": "*ปากคลองบางปลากด", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 246, "SUB_DISTRICT_CODE": "110110", "SUB_DISTRICT_NAME": "แพรกษา", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 247, "SUB_DISTRICT_CODE": "110111", "SUB_DISTRICT_NAME": "บางโปรง", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 248, "SUB_DISTRICT_CODE": "110112", "SUB_DISTRICT_NAME": "บางปู", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 249, "SUB_DISTRICT_CODE": "110113", "SUB_DISTRICT_NAME": "บางด้วน", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 250, "SUB_DISTRICT_CODE": "110114", "SUB_DISTRICT_NAME": "บางเมืองใหม่", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 251, "SUB_DISTRICT_CODE": "110115", "SUB_DISTRICT_NAME": "เทพารักษ์", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 252, "SUB_DISTRICT_CODE": "110116", "SUB_DISTRICT_NAME": "ท้ายบ้านใหม่", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 253, "SUB_DISTRICT_CODE": "110117", "SUB_DISTRICT_NAME": "แพรกษาใหม่", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 254, "SUB_DISTRICT_CODE": "110194", "SUB_DISTRICT_NAME": "*บางปูเก่า", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 255, "SUB_DISTRICT_CODE": "110195", "SUB_DISTRICT_NAME": "*ในคลองบางปลากด", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 256, "SUB_DISTRICT_CODE": "110196", "SUB_DISTRICT_NAME": "*ปากคลองบางปลากด", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 257, "SUB_DISTRICT_CODE": "110197", "SUB_DISTRICT_NAME": "*แหลมฟ้าผ่า", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 258, "SUB_DISTRICT_CODE": "110198", "SUB_DISTRICT_NAME": "*บ้านคลองสวน", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 259, "SUB_DISTRICT_CODE": "110199", "SUB_DISTRICT_NAME": "*นาเกลือ", "DISTRICT_ID": 52, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 260, "SUB_DISTRICT_CODE": "110201", "SUB_DISTRICT_NAME": "บางบ่อ", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 261, "SUB_DISTRICT_CODE": "110202", "SUB_DISTRICT_NAME": "บ้านระกาศ", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 262, "SUB_DISTRICT_CODE": "110203", "SUB_DISTRICT_NAME": "บางพลีน้อย", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 263, "SUB_DISTRICT_CODE": "110204", "SUB_DISTRICT_NAME": "บางเพรียง", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 264, "SUB_DISTRICT_CODE": "110205", "SUB_DISTRICT_NAME": "คลองด่าน", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 265, "SUB_DISTRICT_CODE": "110206", "SUB_DISTRICT_NAME": "คลองสวน", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 266, "SUB_DISTRICT_CODE": "110207", "SUB_DISTRICT_NAME": "เปร็ง", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 267, "SUB_DISTRICT_CODE": "110208", "SUB_DISTRICT_NAME": "คลองนิยมยาตรา", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 268, "SUB_DISTRICT_CODE": "110209", "SUB_DISTRICT_NAME": "คลองนิยม*", "DISTRICT_ID": 53, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 269, "SUB_DISTRICT_CODE": "110301", "SUB_DISTRICT_NAME": "บางพลีใหญ่", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 270, "SUB_DISTRICT_CODE": "110302", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 271, "SUB_DISTRICT_CODE": "110303", "SUB_DISTRICT_NAME": "บางปลา", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 272, "SUB_DISTRICT_CODE": "110304", "SUB_DISTRICT_NAME": "บางโฉลง", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 273, "SUB_DISTRICT_CODE": "110305", "SUB_DISTRICT_NAME": "*บางเสาธง", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 274, "SUB_DISTRICT_CODE": "110306", "SUB_DISTRICT_NAME": "*ศรีษะจรเข้ใหญ่", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 275, "SUB_DISTRICT_CODE": "110307", "SUB_DISTRICT_NAME": "*ศรีษะจรเข้น้อย", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 276, "SUB_DISTRICT_CODE": "110308", "SUB_DISTRICT_NAME": "ราชาเทวะ", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 277, "SUB_DISTRICT_CODE": "110309", "SUB_DISTRICT_NAME": "หนองปรือ", "DISTRICT_ID": 54, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 278, "SUB_DISTRICT_CODE": "110401", "SUB_DISTRICT_NAME": "ตลาด", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 279, "SUB_DISTRICT_CODE": "110402", "SUB_DISTRICT_NAME": "บางพึ่ง", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 280, "SUB_DISTRICT_CODE": "110403", "SUB_DISTRICT_NAME": "บางจาก", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 281, "SUB_DISTRICT_CODE": "110404", "SUB_DISTRICT_NAME": "บางครุ", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 282, "SUB_DISTRICT_CODE": "110405", "SUB_DISTRICT_NAME": "บางหญ้าแพรก", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 283, "SUB_DISTRICT_CODE": "110406", "SUB_DISTRICT_NAME": "บางหัวเสือ", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 284, "SUB_DISTRICT_CODE": "110407", "SUB_DISTRICT_NAME": "สำโรงใต้", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 285, "SUB_DISTRICT_CODE": "110408", "SUB_DISTRICT_NAME": "บางยอ", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 286, "SUB_DISTRICT_CODE": "110409", "SUB_DISTRICT_NAME": "บางกะเจ้า", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 287, "SUB_DISTRICT_CODE": "110410", "SUB_DISTRICT_NAME": "บางน้ำผึ้ง", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 288, "SUB_DISTRICT_CODE": "110411", "SUB_DISTRICT_NAME": "บางกระสอบ", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 289, "SUB_DISTRICT_CODE": "110412", "SUB_DISTRICT_NAME": "บางกอบัว", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 290, "SUB_DISTRICT_CODE": "110413", "SUB_DISTRICT_NAME": "ทรงคนอง", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 291, "SUB_DISTRICT_CODE": "110414", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 292, "SUB_DISTRICT_CODE": "110415", "SUB_DISTRICT_NAME": "สำโรงกลาง", "DISTRICT_ID": 55, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 293, "SUB_DISTRICT_CODE": "110501", "SUB_DISTRICT_NAME": "นาเกลือ", "DISTRICT_ID": 56, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 294, "SUB_DISTRICT_CODE": "110502", "SUB_DISTRICT_NAME": "บ้านคลองสวน", "DISTRICT_ID": 56, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 295, "SUB_DISTRICT_CODE": "110503", "SUB_DISTRICT_NAME": "แหลมฟ้าผ่า", "DISTRICT_ID": 56, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 296, "SUB_DISTRICT_CODE": "110504", "SUB_DISTRICT_NAME": "ปากคลองบางปลากด", "DISTRICT_ID": 56, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 297, "SUB_DISTRICT_CODE": "110505", "SUB_DISTRICT_NAME": "ในคลองบางปลากด", "DISTRICT_ID": 56, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 298, "SUB_DISTRICT_CODE": "110601", "SUB_DISTRICT_NAME": "บางเสาธง", "DISTRICT_ID": 57, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 299, "SUB_DISTRICT_CODE": "110602", "SUB_DISTRICT_NAME": "ศีรษะจรเข้น้อย", "DISTRICT_ID": 57, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 300, "SUB_DISTRICT_CODE": "110603", "SUB_DISTRICT_NAME": "ศีรษะจรเข้ใหญ่", "DISTRICT_ID": 57, "PROVINCE_ID": 2, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 301, "SUB_DISTRICT_CODE": "120101", "SUB_DISTRICT_NAME": "สวนใหญ่", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 302, "SUB_DISTRICT_CODE": "120102", "SUB_DISTRICT_NAME": "ตลาดขวัญ", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 303, "SUB_DISTRICT_CODE": "120103", "SUB_DISTRICT_NAME": "บางเขน", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 304, "SUB_DISTRICT_CODE": "120104", "SUB_DISTRICT_NAME": "บางกระสอ", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 305, "SUB_DISTRICT_CODE": "120105", "SUB_DISTRICT_NAME": "ท่าทราย", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 306, "SUB_DISTRICT_CODE": "120106", "SUB_DISTRICT_NAME": "บางไผ่", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 307, "SUB_DISTRICT_CODE": "120107", "SUB_DISTRICT_NAME": "บางศรีเมือง", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 308, "SUB_DISTRICT_CODE": "120108", "SUB_DISTRICT_NAME": "บางกร่าง", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 309, "SUB_DISTRICT_CODE": "120109", "SUB_DISTRICT_NAME": "ไทรม้า", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 310, "SUB_DISTRICT_CODE": "120110", "SUB_DISTRICT_NAME": "บางรักน้อย", "DISTRICT_ID": 58, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 311, "SUB_DISTRICT_CODE": "120201", "SUB_DISTRICT_NAME": "วัดชลอ", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 312, "SUB_DISTRICT_CODE": "120202", "SUB_DISTRICT_NAME": "บางกรวย", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 313, "SUB_DISTRICT_CODE": "120203", "SUB_DISTRICT_NAME": "บางสีทอง", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 314, "SUB_DISTRICT_CODE": "120204", "SUB_DISTRICT_NAME": "บางขนุน", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 315, "SUB_DISTRICT_CODE": "120205", "SUB_DISTRICT_NAME": "บางขุนกอง", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 316, "SUB_DISTRICT_CODE": "120206", "SUB_DISTRICT_NAME": "บางคูเวียง", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 317, "SUB_DISTRICT_CODE": "120207", "SUB_DISTRICT_NAME": "มหาสวัสดิ์", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 318, "SUB_DISTRICT_CODE": "120208", "SUB_DISTRICT_NAME": "ปลายบาง", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 319, "SUB_DISTRICT_CODE": "120209", "SUB_DISTRICT_NAME": "ศาลากลาง", "DISTRICT_ID": 59, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 320, "SUB_DISTRICT_CODE": "120301", "SUB_DISTRICT_NAME": "บางม่วง", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 321, "SUB_DISTRICT_CODE": "120302", "SUB_DISTRICT_NAME": "บางแม่นาง", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 322, "SUB_DISTRICT_CODE": "120303", "SUB_DISTRICT_NAME": "บางเลน", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 323, "SUB_DISTRICT_CODE": "120304", "SUB_DISTRICT_NAME": "เสาธงหิน", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 324, "SUB_DISTRICT_CODE": "120305", "SUB_DISTRICT_NAME": "บางใหญ่", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 325, "SUB_DISTRICT_CODE": "120306", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 60, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 326, "SUB_DISTRICT_CODE": "120401", "SUB_DISTRICT_NAME": "โสนลอย", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 327, "SUB_DISTRICT_CODE": "120402", "SUB_DISTRICT_NAME": "บางบัวทอง", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 328, "SUB_DISTRICT_CODE": "120403", "SUB_DISTRICT_NAME": "บางรักใหญ่", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 329, "SUB_DISTRICT_CODE": "120404", "SUB_DISTRICT_NAME": "บางคูรัด", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 330, "SUB_DISTRICT_CODE": "120405", "SUB_DISTRICT_NAME": "ละหาร", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 331, "SUB_DISTRICT_CODE": "120406", "SUB_DISTRICT_NAME": "ลำโพ", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 332, "SUB_DISTRICT_CODE": "120407", "SUB_DISTRICT_NAME": "พิมลราช", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 333, "SUB_DISTRICT_CODE": "120408", "SUB_DISTRICT_NAME": "บางรักพัฒนา", "DISTRICT_ID": 61, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 334, "SUB_DISTRICT_CODE": "120501", "SUB_DISTRICT_NAME": "ไทรน้อย", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 335, "SUB_DISTRICT_CODE": "120502", "SUB_DISTRICT_NAME": "ราษฎร์นิยม", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 336, "SUB_DISTRICT_CODE": "120503", "SUB_DISTRICT_NAME": "หนองเพรางาย", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 337, "SUB_DISTRICT_CODE": "120504", "SUB_DISTRICT_NAME": "ไทรใหญ่", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 338, "SUB_DISTRICT_CODE": "120505", "SUB_DISTRICT_NAME": "ขุนศรี", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 339, "SUB_DISTRICT_CODE": "120506", "SUB_DISTRICT_NAME": "คลองขวาง", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 340, "SUB_DISTRICT_CODE": "120507", "SUB_DISTRICT_NAME": "ทวีวัฒนา", "DISTRICT_ID": 62, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 341, "SUB_DISTRICT_CODE": "120601", "SUB_DISTRICT_NAME": "ปากเกร็ด", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 342, "SUB_DISTRICT_CODE": "120602", "SUB_DISTRICT_NAME": "บางตลาด", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 343, "SUB_DISTRICT_CODE": "120603", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 344, "SUB_DISTRICT_CODE": "120604", "SUB_DISTRICT_NAME": "บางพูด", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 345, "SUB_DISTRICT_CODE": "120605", "SUB_DISTRICT_NAME": "บางตะไนย์", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 346, "SUB_DISTRICT_CODE": "120606", "SUB_DISTRICT_NAME": "คลองพระอุดม", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 347, "SUB_DISTRICT_CODE": "120607", "SUB_DISTRICT_NAME": "ท่าอิฐ", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 348, "SUB_DISTRICT_CODE": "120608", "SUB_DISTRICT_NAME": "เกาะเกร็ด", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 349, "SUB_DISTRICT_CODE": "120609", "SUB_DISTRICT_NAME": "อ้อมเกร็ด", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 350, "SUB_DISTRICT_CODE": "120610", "SUB_DISTRICT_NAME": "คลองข่อย", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 351, "SUB_DISTRICT_CODE": "120611", "SUB_DISTRICT_NAME": "บางพลับ", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 352, "SUB_DISTRICT_CODE": "120612", "SUB_DISTRICT_NAME": "คลองเกลือ", "DISTRICT_ID": 63, "PROVINCE_ID": 3, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 353, "SUB_DISTRICT_CODE": "130101", "SUB_DISTRICT_NAME": "บางปรอก", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 354, "SUB_DISTRICT_CODE": "130102", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 355, "SUB_DISTRICT_CODE": "130103", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 356, "SUB_DISTRICT_CODE": "130104", "SUB_DISTRICT_NAME": "บ้านฉาง", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 357, "SUB_DISTRICT_CODE": "130105", "SUB_DISTRICT_NAME": "บ้านกระแชง", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 358, "SUB_DISTRICT_CODE": "130106", "SUB_DISTRICT_NAME": "บางขะแยง", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 359, "SUB_DISTRICT_CODE": "130107", "SUB_DISTRICT_NAME": "บางคูวัด", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 360, "SUB_DISTRICT_CODE": "130108", "SUB_DISTRICT_NAME": "บางหลวง", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 361, "SUB_DISTRICT_CODE": "130109", "SUB_DISTRICT_NAME": "บางเดื่อ", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 362, "SUB_DISTRICT_CODE": "130110", "SUB_DISTRICT_NAME": "บางพูด", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 363, "SUB_DISTRICT_CODE": "130111", "SUB_DISTRICT_NAME": "บางพูน", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 364, "SUB_DISTRICT_CODE": "130112", "SUB_DISTRICT_NAME": "บางกะดี", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 365, "SUB_DISTRICT_CODE": "130113", "SUB_DISTRICT_NAME": "สวนพริกไทย", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 366, "SUB_DISTRICT_CODE": "130114", "SUB_DISTRICT_NAME": "หลักหก", "DISTRICT_ID": 66, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 367, "SUB_DISTRICT_CODE": "130201", "SUB_DISTRICT_NAME": "คลองหนึ่ง", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 368, "SUB_DISTRICT_CODE": "130202", "SUB_DISTRICT_NAME": "คลองสอง", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 369, "SUB_DISTRICT_CODE": "130203", "SUB_DISTRICT_NAME": "คลองสาม", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 370, "SUB_DISTRICT_CODE": "130204", "SUB_DISTRICT_NAME": "คลองสี่", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 371, "SUB_DISTRICT_CODE": "130205", "SUB_DISTRICT_NAME": "คลองห้า", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 372, "SUB_DISTRICT_CODE": "130206", "SUB_DISTRICT_NAME": "คลองหก", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 373, "SUB_DISTRICT_CODE": "130207", "SUB_DISTRICT_NAME": "คลองเจ็ด", "DISTRICT_ID": 67, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 374, "SUB_DISTRICT_CODE": "130301", "SUB_DISTRICT_NAME": "ประชาธิปัตย์", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 375, "SUB_DISTRICT_CODE": "130302", "SUB_DISTRICT_NAME": "บึงยี่โถ", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 376, "SUB_DISTRICT_CODE": "130303", "SUB_DISTRICT_NAME": "รังสิต", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 377, "SUB_DISTRICT_CODE": "130304", "SUB_DISTRICT_NAME": "ลำผักกูด", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 378, "SUB_DISTRICT_CODE": "130305", "SUB_DISTRICT_NAME": "บึงสนั่น", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 379, "SUB_DISTRICT_CODE": "130306", "SUB_DISTRICT_NAME": "บึงน้ำรักษ์", "DISTRICT_ID": 68, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 380, "SUB_DISTRICT_CODE": "130401", "SUB_DISTRICT_NAME": "บึงบา", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 381, "SUB_DISTRICT_CODE": "130402", "SUB_DISTRICT_NAME": "บึงบอน", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 382, "SUB_DISTRICT_CODE": "130403", "SUB_DISTRICT_NAME": "บึงกาสาม", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 383, "SUB_DISTRICT_CODE": "130404", "SUB_DISTRICT_NAME": "บึงชำอ้อ", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 384, "SUB_DISTRICT_CODE": "130405", "SUB_DISTRICT_NAME": "หนองสามวัง", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 385, "SUB_DISTRICT_CODE": "130406", "SUB_DISTRICT_NAME": "ศาลาครุ", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 386, "SUB_DISTRICT_CODE": "130407", "SUB_DISTRICT_NAME": "นพรัตน์", "DISTRICT_ID": 69, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 387, "SUB_DISTRICT_CODE": "130501", "SUB_DISTRICT_NAME": "ระแหง", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 388, "SUB_DISTRICT_CODE": "130502", "SUB_DISTRICT_NAME": "ลาดหลุมแก้ว", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 389, "SUB_DISTRICT_CODE": "130503", "SUB_DISTRICT_NAME": "คูบางหลวง", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 390, "SUB_DISTRICT_CODE": "130504", "SUB_DISTRICT_NAME": "คูขวาง", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 391, "SUB_DISTRICT_CODE": "130505", "SUB_DISTRICT_NAME": "คลองพระอุดม", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 392, "SUB_DISTRICT_CODE": "130506", "SUB_DISTRICT_NAME": "บ่อเงิน", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 393, "SUB_DISTRICT_CODE": "130507", "SUB_DISTRICT_NAME": "หน้าไม้", "DISTRICT_ID": 70, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 394, "SUB_DISTRICT_CODE": "130601", "SUB_DISTRICT_NAME": "คูคต", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 395, "SUB_DISTRICT_CODE": "130602", "SUB_DISTRICT_NAME": "ลาดสวาย", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 396, "SUB_DISTRICT_CODE": "130603", "SUB_DISTRICT_NAME": "บึงคำพร้อย", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 397, "SUB_DISTRICT_CODE": "130604", "SUB_DISTRICT_NAME": "ลำลูกกา", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 398, "SUB_DISTRICT_CODE": "130605", "SUB_DISTRICT_NAME": "บึงทองหลาง", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 399, "SUB_DISTRICT_CODE": "130606", "SUB_DISTRICT_NAME": "ลำไทร", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 400, "SUB_DISTRICT_CODE": "130607", "SUB_DISTRICT_NAME": "บึงคอไห", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 401, "SUB_DISTRICT_CODE": "130608", "SUB_DISTRICT_NAME": "พืชอุดม", "DISTRICT_ID": 71, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 402, "SUB_DISTRICT_CODE": "130701", "SUB_DISTRICT_NAME": "บางเตย", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 403, "SUB_DISTRICT_CODE": "130702", "SUB_DISTRICT_NAME": "คลองควาย", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 404, "SUB_DISTRICT_CODE": "130703", "SUB_DISTRICT_NAME": "สามโคก", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 405, "SUB_DISTRICT_CODE": "130704", "SUB_DISTRICT_NAME": "กระแชง", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 406, "SUB_DISTRICT_CODE": "130705", "SUB_DISTRICT_NAME": "บางโพธิ์เหนือ", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 407, "SUB_DISTRICT_CODE": "130706", "SUB_DISTRICT_NAME": "เชียงรากใหญ่", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 408, "SUB_DISTRICT_CODE": "130707", "SUB_DISTRICT_NAME": "บ้านปทุม", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 409, "SUB_DISTRICT_CODE": "130708", "SUB_DISTRICT_NAME": "บ้านงิ้ว", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 410, "SUB_DISTRICT_CODE": "130709", "SUB_DISTRICT_NAME": "เชียงรากน้อย", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 411, "SUB_DISTRICT_CODE": "130710", "SUB_DISTRICT_NAME": "บางกระบือ", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 412, "SUB_DISTRICT_CODE": "130711", "SUB_DISTRICT_NAME": "ท้ายเกาะ", "DISTRICT_ID": 72, "PROVINCE_ID": 4, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 413, "SUB_DISTRICT_CODE": "140101", "SUB_DISTRICT_NAME": "ประตูชัย", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 414, "SUB_DISTRICT_CODE": "140102", "SUB_DISTRICT_NAME": "กะมัง", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 415, "SUB_DISTRICT_CODE": "140103", "SUB_DISTRICT_NAME": "หอรัตนไชย", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 416, "SUB_DISTRICT_CODE": "140104", "SUB_DISTRICT_NAME": "หัวรอ", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 417, "SUB_DISTRICT_CODE": "140105", "SUB_DISTRICT_NAME": "ท่าวาสุกรี", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 418, "SUB_DISTRICT_CODE": "140106", "SUB_DISTRICT_NAME": "ไผ่ลิง", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 419, "SUB_DISTRICT_CODE": "140107", "SUB_DISTRICT_NAME": "ปากกราน", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 420, "SUB_DISTRICT_CODE": "140108", "SUB_DISTRICT_NAME": "ภูเขาทอง", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 421, "SUB_DISTRICT_CODE": "140109", "SUB_DISTRICT_NAME": "สำเภาล่ม", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 422, "SUB_DISTRICT_CODE": "140110", "SUB_DISTRICT_NAME": "สวนพริก", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 423, "SUB_DISTRICT_CODE": "140111", "SUB_DISTRICT_NAME": "คลองตะเคียน", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 424, "SUB_DISTRICT_CODE": "140112", "SUB_DISTRICT_NAME": "วัดตูม", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 425, "SUB_DISTRICT_CODE": "140113", "SUB_DISTRICT_NAME": "หันตรา", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 426, "SUB_DISTRICT_CODE": "140114", "SUB_DISTRICT_NAME": "ลุมพลี", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 427, "SUB_DISTRICT_CODE": "140115", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 428, "SUB_DISTRICT_CODE": "140116", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 429, "SUB_DISTRICT_CODE": "140117", "SUB_DISTRICT_NAME": "คลองสวนพลู", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 430, "SUB_DISTRICT_CODE": "140118", "SUB_DISTRICT_NAME": "คลองสระบัว", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 431, "SUB_DISTRICT_CODE": "140119", "SUB_DISTRICT_NAME": "เกาะเรียน", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 432, "SUB_DISTRICT_CODE": "140120", "SUB_DISTRICT_NAME": "บ้านป้อม", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 433, "SUB_DISTRICT_CODE": "140121", "SUB_DISTRICT_NAME": "บ้านรุน", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 434, "SUB_DISTRICT_CODE": "140199", "SUB_DISTRICT_NAME": "*จำปา", "DISTRICT_ID": 74, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 435, "SUB_DISTRICT_CODE": "140201", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 436, "SUB_DISTRICT_CODE": "140202", "SUB_DISTRICT_NAME": "จำปา", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 437, "SUB_DISTRICT_CODE": "140203", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 438, "SUB_DISTRICT_CODE": "140204", "SUB_DISTRICT_NAME": "บ้านร่อม", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 439, "SUB_DISTRICT_CODE": "140205", "SUB_DISTRICT_NAME": "ศาลาลอย", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 440, "SUB_DISTRICT_CODE": "140206", "SUB_DISTRICT_NAME": "วังแดง", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 441, "SUB_DISTRICT_CODE": "140207", "SUB_DISTRICT_NAME": "โพธิ์เอน", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 442, "SUB_DISTRICT_CODE": "140208", "SUB_DISTRICT_NAME": "ปากท่า", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 443, "SUB_DISTRICT_CODE": "140209", "SUB_DISTRICT_NAME": "หนองขนาก", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 444, "SUB_DISTRICT_CODE": "140210", "SUB_DISTRICT_NAME": "ท่าเจ้าสนุก", "DISTRICT_ID": 75, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 445, "SUB_DISTRICT_CODE": "140301", "SUB_DISTRICT_NAME": "นครหลวง", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 446, "SUB_DISTRICT_CODE": "140302", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 447, "SUB_DISTRICT_CODE": "140303", "SUB_DISTRICT_NAME": "บ่อโพง", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 448, "SUB_DISTRICT_CODE": "140304", "SUB_DISTRICT_NAME": "บ้านชุ้ง", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 449, "SUB_DISTRICT_CODE": "140305", "SUB_DISTRICT_NAME": "ปากจั่น", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 450, "SUB_DISTRICT_CODE": "140306", "SUB_DISTRICT_NAME": "บางระกำ", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 451, "SUB_DISTRICT_CODE": "140307", "SUB_DISTRICT_NAME": "บางพระครู", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 452, "SUB_DISTRICT_CODE": "140308", "SUB_DISTRICT_NAME": "แม่ลา", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 453, "SUB_DISTRICT_CODE": "140309", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 454, "SUB_DISTRICT_CODE": "140310", "SUB_DISTRICT_NAME": "คลองสะแก", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 455, "SUB_DISTRICT_CODE": "140311", "SUB_DISTRICT_NAME": "สามไถ", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 456, "SUB_DISTRICT_CODE": "140312", "SUB_DISTRICT_NAME": "พระนอน", "DISTRICT_ID": 76, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 457, "SUB_DISTRICT_CODE": "140401", "SUB_DISTRICT_NAME": "บางไทร", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 458, "SUB_DISTRICT_CODE": "140402", "SUB_DISTRICT_NAME": "บางพลี", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 459, "SUB_DISTRICT_CODE": "140403", "SUB_DISTRICT_NAME": "สนามชัย", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 460, "SUB_DISTRICT_CODE": "140404", "SUB_DISTRICT_NAME": "บ้านแป้ง", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 461, "SUB_DISTRICT_CODE": "140405", "SUB_DISTRICT_NAME": "หน้าไม้", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 462, "SUB_DISTRICT_CODE": "140406", "SUB_DISTRICT_NAME": "บางยี่โท", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 463, "SUB_DISTRICT_CODE": "140407", "SUB_DISTRICT_NAME": "แคออก", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 464, "SUB_DISTRICT_CODE": "140408", "SUB_DISTRICT_NAME": "แคตก", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 465, "SUB_DISTRICT_CODE": "140409", "SUB_DISTRICT_NAME": "ช่างเหล็ก", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 466, "SUB_DISTRICT_CODE": "140410", "SUB_DISTRICT_NAME": "กระแชง", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 467, "SUB_DISTRICT_CODE": "140411", "SUB_DISTRICT_NAME": "บ้านกลึง", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 468, "SUB_DISTRICT_CODE": "140412", "SUB_DISTRICT_NAME": "ช้างน้อย", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 469, "SUB_DISTRICT_CODE": "140413", "SUB_DISTRICT_NAME": "ห่อหมก", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 470, "SUB_DISTRICT_CODE": "140414", "SUB_DISTRICT_NAME": "ไผ่พระ", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 471, "SUB_DISTRICT_CODE": "140415", "SUB_DISTRICT_NAME": "กกแก้วบูรพา", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 472, "SUB_DISTRICT_CODE": "140416", "SUB_DISTRICT_NAME": "ไม้ตรา", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 473, "SUB_DISTRICT_CODE": "140417", "SUB_DISTRICT_NAME": "บ้านม้า", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 474, "SUB_DISTRICT_CODE": "140418", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 475, "SUB_DISTRICT_CODE": "140419", "SUB_DISTRICT_NAME": "ราชคราม", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 476, "SUB_DISTRICT_CODE": "140420", "SUB_DISTRICT_NAME": "ช้างใหญ่", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 477, "SUB_DISTRICT_CODE": "140421", "SUB_DISTRICT_NAME": "โพแตง", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 478, "SUB_DISTRICT_CODE": "140422", "SUB_DISTRICT_NAME": "เชียงรากน้อย", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 479, "SUB_DISTRICT_CODE": "140423", "SUB_DISTRICT_NAME": "โคกช้าง", "DISTRICT_ID": 77, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 480, "SUB_DISTRICT_CODE": "140501", "SUB_DISTRICT_NAME": "บางบาล", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 481, "SUB_DISTRICT_CODE": "140502", "SUB_DISTRICT_NAME": "วัดยม", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 482, "SUB_DISTRICT_CODE": "140503", "SUB_DISTRICT_NAME": "ไทรน้อย", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 483, "SUB_DISTRICT_CODE": "140504", "SUB_DISTRICT_NAME": "สะพานไทย", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 484, "SUB_DISTRICT_CODE": "140505", "SUB_DISTRICT_NAME": "มหาพราหมณ์", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 485, "SUB_DISTRICT_CODE": "140506", "SUB_DISTRICT_NAME": "กบเจา", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 486, "SUB_DISTRICT_CODE": "140507", "SUB_DISTRICT_NAME": "บ้านคลัง", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 487, "SUB_DISTRICT_CODE": "140508", "SUB_DISTRICT_NAME": "พระขาว", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 488, "SUB_DISTRICT_CODE": "140509", "SUB_DISTRICT_NAME": "น้ำเต้า", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 489, "SUB_DISTRICT_CODE": "140510", "SUB_DISTRICT_NAME": "ทางช้าง", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 490, "SUB_DISTRICT_CODE": "140511", "SUB_DISTRICT_NAME": "วัดตะกู", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 491, "SUB_DISTRICT_CODE": "140512", "SUB_DISTRICT_NAME": "บางหลวง", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 492, "SUB_DISTRICT_CODE": "140513", "SUB_DISTRICT_NAME": "บางหลวงโดด", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 493, "SUB_DISTRICT_CODE": "140514", "SUB_DISTRICT_NAME": "บางหัก", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 494, "SUB_DISTRICT_CODE": "140515", "SUB_DISTRICT_NAME": "บางชะนี", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 495, "SUB_DISTRICT_CODE": "140516", "SUB_DISTRICT_NAME": "บ้านกุ่ม", "DISTRICT_ID": 78, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 496, "SUB_DISTRICT_CODE": "140601", "SUB_DISTRICT_NAME": "บ้านเลน", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 497, "SUB_DISTRICT_CODE": "140602", "SUB_DISTRICT_NAME": "เชียงรากน้อย", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 498, "SUB_DISTRICT_CODE": "140603", "SUB_DISTRICT_NAME": "บ้านโพ", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 499, "SUB_DISTRICT_CODE": "140604", "SUB_DISTRICT_NAME": "บ้านกรด", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 500, "SUB_DISTRICT_CODE": "140605", "SUB_DISTRICT_NAME": "บางกระสั้น", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 501, "SUB_DISTRICT_CODE": "140606", "SUB_DISTRICT_NAME": "คลองจิก", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 502, "SUB_DISTRICT_CODE": "140607", "SUB_DISTRICT_NAME": "บ้านหว้า", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 503, "SUB_DISTRICT_CODE": "140608", "SUB_DISTRICT_NAME": "วัดยม", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 504, "SUB_DISTRICT_CODE": "140609", "SUB_DISTRICT_NAME": "บางประแดง", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 505, "SUB_DISTRICT_CODE": "140610", "SUB_DISTRICT_NAME": "สามเรือน", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 506, "SUB_DISTRICT_CODE": "140611", "SUB_DISTRICT_NAME": "เกาะเกิด", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 507, "SUB_DISTRICT_CODE": "140612", "SUB_DISTRICT_NAME": "บ้านพลับ", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 508, "SUB_DISTRICT_CODE": "140613", "SUB_DISTRICT_NAME": "บ้านแป้ง", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 509, "SUB_DISTRICT_CODE": "140614", "SUB_DISTRICT_NAME": "คุ้งลาน", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 510, "SUB_DISTRICT_CODE": "140615", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 511, "SUB_DISTRICT_CODE": "140616", "SUB_DISTRICT_NAME": "บ้านสร้าง", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 512, "SUB_DISTRICT_CODE": "140617", "SUB_DISTRICT_NAME": "ตลาดเกรียบ", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 513, "SUB_DISTRICT_CODE": "140618", "SUB_DISTRICT_NAME": "ขนอนหลวง", "DISTRICT_ID": 79, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 514, "SUB_DISTRICT_CODE": "140701", "SUB_DISTRICT_NAME": "บางปะหัน", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 515, "SUB_DISTRICT_CODE": "140702", "SUB_DISTRICT_NAME": "ขยาย", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 516, "SUB_DISTRICT_CODE": "140703", "SUB_DISTRICT_NAME": "บางเดื่อ", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 517, "SUB_DISTRICT_CODE": "140704", "SUB_DISTRICT_NAME": "เสาธง", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 518, "SUB_DISTRICT_CODE": "140705", "SUB_DISTRICT_NAME": "ทางกลาง", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 519, "SUB_DISTRICT_CODE": "140706", "SUB_DISTRICT_NAME": "บางเพลิง", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 520, "SUB_DISTRICT_CODE": "140707", "SUB_DISTRICT_NAME": "หันสัง", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 521, "SUB_DISTRICT_CODE": "140708", "SUB_DISTRICT_NAME": "บางนางร้า", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 522, "SUB_DISTRICT_CODE": "140709", "SUB_DISTRICT_NAME": "ตานิม", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 523, "SUB_DISTRICT_CODE": "140710", "SUB_DISTRICT_NAME": "ทับน้ำ", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 524, "SUB_DISTRICT_CODE": "140711", "SUB_DISTRICT_NAME": "บ้านม้า", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 525, "SUB_DISTRICT_CODE": "140712", "SUB_DISTRICT_NAME": "ขวัญเมือง", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 526, "SUB_DISTRICT_CODE": "140713", "SUB_DISTRICT_NAME": "บ้านลี่", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 527, "SUB_DISTRICT_CODE": "140714", "SUB_DISTRICT_NAME": "โพธิ์สามต้น", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 528, "SUB_DISTRICT_CODE": "140715", "SUB_DISTRICT_NAME": "พุทเลา", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 529, "SUB_DISTRICT_CODE": "140716", "SUB_DISTRICT_NAME": "ตาลเอน", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 530, "SUB_DISTRICT_CODE": "140717", "SUB_DISTRICT_NAME": "บ้านขล้อ", "DISTRICT_ID": 80, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 531, "SUB_DISTRICT_CODE": "140801", "SUB_DISTRICT_NAME": "ผักไห่", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 532, "SUB_DISTRICT_CODE": "140802", "SUB_DISTRICT_NAME": "อมฤต", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 533, "SUB_DISTRICT_CODE": "140803", "SUB_DISTRICT_NAME": "บ้านแค", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 534, "SUB_DISTRICT_CODE": "140804", "SUB_DISTRICT_NAME": "ลาดน้ำเค็ม", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 535, "SUB_DISTRICT_CODE": "140805", "SUB_DISTRICT_NAME": "ตาลาน", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 536, "SUB_DISTRICT_CODE": "140806", "SUB_DISTRICT_NAME": "ท่าดินแดง", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 537, "SUB_DISTRICT_CODE": "140807", "SUB_DISTRICT_NAME": "ดอนลาน", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 538, "SUB_DISTRICT_CODE": "140808", "SUB_DISTRICT_NAME": "นาคู", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 539, "SUB_DISTRICT_CODE": "140809", "SUB_DISTRICT_NAME": "กุฎี", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 540, "SUB_DISTRICT_CODE": "140810", "SUB_DISTRICT_NAME": "ลำตะเคียน", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 541, "SUB_DISTRICT_CODE": "140811", "SUB_DISTRICT_NAME": "โคกช้าง", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 542, "SUB_DISTRICT_CODE": "140812", "SUB_DISTRICT_NAME": "จักราช", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 543, "SUB_DISTRICT_CODE": "140813", "SUB_DISTRICT_NAME": "หนองน้ำใหญ่", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 544, "SUB_DISTRICT_CODE": "140814", "SUB_DISTRICT_NAME": "ลาดชิด", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 545, "SUB_DISTRICT_CODE": "140815", "SUB_DISTRICT_NAME": "หน้าโคก", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 546, "SUB_DISTRICT_CODE": "140816", "SUB_DISTRICT_NAME": "บ้านใหญ่", "DISTRICT_ID": 81, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 547, "SUB_DISTRICT_CODE": "140901", "SUB_DISTRICT_NAME": "ภาชี", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 548, "SUB_DISTRICT_CODE": "140902", "SUB_DISTRICT_NAME": "โคกม่วง", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 549, "SUB_DISTRICT_CODE": "140903", "SUB_DISTRICT_NAME": "ระโสม", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 550, "SUB_DISTRICT_CODE": "140904", "SUB_DISTRICT_NAME": "หนองน้ำใส", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 551, "SUB_DISTRICT_CODE": "140905", "SUB_DISTRICT_NAME": "ดอนหญ้านาง", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 552, "SUB_DISTRICT_CODE": "140906", "SUB_DISTRICT_NAME": "ไผ่ล้อม", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 553, "SUB_DISTRICT_CODE": "140907", "SUB_DISTRICT_NAME": "กระจิว", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 554, "SUB_DISTRICT_CODE": "140908", "SUB_DISTRICT_NAME": "พระแก้ว", "DISTRICT_ID": 82, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 555, "SUB_DISTRICT_CODE": "141001", "SUB_DISTRICT_NAME": "ลาดบัวหลวง", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 556, "SUB_DISTRICT_CODE": "141002", "SUB_DISTRICT_NAME": "หลักชัย", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 557, "SUB_DISTRICT_CODE": "141003", "SUB_DISTRICT_NAME": "สามเมือง", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 558, "SUB_DISTRICT_CODE": "141004", "SUB_DISTRICT_NAME": "พระยาบันลือ", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 559, "SUB_DISTRICT_CODE": "141005", "SUB_DISTRICT_NAME": "สิงหนาท", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 560, "SUB_DISTRICT_CODE": "141006", "SUB_DISTRICT_NAME": "คู้สลอด", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 561, "SUB_DISTRICT_CODE": "141007", "SUB_DISTRICT_NAME": "คลองพระยาบันลือ", "DISTRICT_ID": 83, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 562, "SUB_DISTRICT_CODE": "141101", "SUB_DISTRICT_NAME": "ลำตาเสา", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 563, "SUB_DISTRICT_CODE": "141102", "SUB_DISTRICT_NAME": "บ่อตาโล่", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 564, "SUB_DISTRICT_CODE": "141103", "SUB_DISTRICT_NAME": "วังน้อย", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 565, "SUB_DISTRICT_CODE": "141104", "SUB_DISTRICT_NAME": "ลำไทร", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 566, "SUB_DISTRICT_CODE": "141105", "SUB_DISTRICT_NAME": "สนับทึบ", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 567, "SUB_DISTRICT_CODE": "141106", "SUB_DISTRICT_NAME": "พยอม", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 568, "SUB_DISTRICT_CODE": "141107", "SUB_DISTRICT_NAME": "หันตะเภา", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 569, "SUB_DISTRICT_CODE": "141108", "SUB_DISTRICT_NAME": "วังจุฬา", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 570, "SUB_DISTRICT_CODE": "141109", "SUB_DISTRICT_NAME": "ข้าวงาม", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 571, "SUB_DISTRICT_CODE": "141110", "SUB_DISTRICT_NAME": "ชะแมบ", "DISTRICT_ID": 84, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 572, "SUB_DISTRICT_CODE": "141201", "SUB_DISTRICT_NAME": "เสนา", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 573, "SUB_DISTRICT_CODE": "141202", "SUB_DISTRICT_NAME": "บ้านแพน", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 574, "SUB_DISTRICT_CODE": "141203", "SUB_DISTRICT_NAME": "เจ้าเจ็ด", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 575, "SUB_DISTRICT_CODE": "141204", "SUB_DISTRICT_NAME": "สามกอ", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 576, "SUB_DISTRICT_CODE": "141205", "SUB_DISTRICT_NAME": "บางนมโค", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 577, "SUB_DISTRICT_CODE": "141206", "SUB_DISTRICT_NAME": "หัวเวียง", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 578, "SUB_DISTRICT_CODE": "141207", "SUB_DISTRICT_NAME": "มารวิชัย", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 579, "SUB_DISTRICT_CODE": "141208", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 580, "SUB_DISTRICT_CODE": "141209", "SUB_DISTRICT_NAME": "รางจรเข้", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 581, "SUB_DISTRICT_CODE": "141210", "SUB_DISTRICT_NAME": "บ้านกระทุ่ม", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 582, "SUB_DISTRICT_CODE": "141211", "SUB_DISTRICT_NAME": "บ้านแถว", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 583, "SUB_DISTRICT_CODE": "141212", "SUB_DISTRICT_NAME": "ชายนา", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 584, "SUB_DISTRICT_CODE": "141213", "SUB_DISTRICT_NAME": "สามตุ่ม", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 585, "SUB_DISTRICT_CODE": "141214", "SUB_DISTRICT_NAME": "ลาดงา", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 586, "SUB_DISTRICT_CODE": "141215", "SUB_DISTRICT_NAME": "ดอนทอง", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 587, "SUB_DISTRICT_CODE": "141216", "SUB_DISTRICT_NAME": "บ้านหลวง", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 588, "SUB_DISTRICT_CODE": "141217", "SUB_DISTRICT_NAME": "เจ้าเสด็จ", "DISTRICT_ID": 85, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 589, "SUB_DISTRICT_CODE": "141301", "SUB_DISTRICT_NAME": "บางซ้าย", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 590, "SUB_DISTRICT_CODE": "141302", "SUB_DISTRICT_NAME": "แก้วฟ้า", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 591, "SUB_DISTRICT_CODE": "141303", "SUB_DISTRICT_NAME": "เต่าเล่า", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 592, "SUB_DISTRICT_CODE": "141304", "SUB_DISTRICT_NAME": "ปลายกลัด", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 593, "SUB_DISTRICT_CODE": "141305", "SUB_DISTRICT_NAME": "เทพมงคล", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 594, "SUB_DISTRICT_CODE": "141306", "SUB_DISTRICT_NAME": "วังพัฒนา", "DISTRICT_ID": 86, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 595, "SUB_DISTRICT_CODE": "141401", "SUB_DISTRICT_NAME": "คานหาม", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 596, "SUB_DISTRICT_CODE": "141402", "SUB_DISTRICT_NAME": "บ้านช้าง", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 597, "SUB_DISTRICT_CODE": "141403", "SUB_DISTRICT_NAME": "สามบัณฑิต", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 598, "SUB_DISTRICT_CODE": "141404", "SUB_DISTRICT_NAME": "บ้านหีบ", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 599, "SUB_DISTRICT_CODE": "141405", "SUB_DISTRICT_NAME": "หนองไม้ซุง", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 600, "SUB_DISTRICT_CODE": "141406", "SUB_DISTRICT_NAME": "อุทัย", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 601, "SUB_DISTRICT_CODE": "141407", "SUB_DISTRICT_NAME": "เสนา", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 602, "SUB_DISTRICT_CODE": "141408", "SUB_DISTRICT_NAME": "หนองน้ำส้ม", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 603, "SUB_DISTRICT_CODE": "141409", "SUB_DISTRICT_NAME": "โพสาวหาญ", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 604, "SUB_DISTRICT_CODE": "141410", "SUB_DISTRICT_NAME": "ธนู", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 605, "SUB_DISTRICT_CODE": "141411", "SUB_DISTRICT_NAME": "ข้าวเม่า", "DISTRICT_ID": 87, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 606, "SUB_DISTRICT_CODE": "141501", "SUB_DISTRICT_NAME": "หัวไผ่", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 607, "SUB_DISTRICT_CODE": "141502", "SUB_DISTRICT_NAME": "กะทุ่ม", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 608, "SUB_DISTRICT_CODE": "141503", "SUB_DISTRICT_NAME": "มหาราช", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 609, "SUB_DISTRICT_CODE": "141504", "SUB_DISTRICT_NAME": "น้ำเต้า", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 610, "SUB_DISTRICT_CODE": "141505", "SUB_DISTRICT_NAME": "บางนา", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 611, "SUB_DISTRICT_CODE": "141506", "SUB_DISTRICT_NAME": "โรงช้าง", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 612, "SUB_DISTRICT_CODE": "141507", "SUB_DISTRICT_NAME": "เจ้าปลุก", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 613, "SUB_DISTRICT_CODE": "141508", "SUB_DISTRICT_NAME": "พิตเพียน", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 614, "SUB_DISTRICT_CODE": "141509", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 615, "SUB_DISTRICT_CODE": "141510", "SUB_DISTRICT_NAME": "บ้านขวาง", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 616, "SUB_DISTRICT_CODE": "141511", "SUB_DISTRICT_NAME": "ท่าตอ", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 617, "SUB_DISTRICT_CODE": "141512", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 88, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 618, "SUB_DISTRICT_CODE": "141601", "SUB_DISTRICT_NAME": "บ้านแพรก", "DISTRICT_ID": 89, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 619, "SUB_DISTRICT_CODE": "141602", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 89, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 620, "SUB_DISTRICT_CODE": "141603", "SUB_DISTRICT_NAME": "สำพะเนียง", "DISTRICT_ID": 89, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 621, "SUB_DISTRICT_CODE": "141604", "SUB_DISTRICT_NAME": "คลองน้อย", "DISTRICT_ID": 89, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 622, "SUB_DISTRICT_CODE": "141605", "SUB_DISTRICT_NAME": "สองห้อง", "DISTRICT_ID": 89, "PROVINCE_ID": 5, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 623, "SUB_DISTRICT_CODE": "150101", "SUB_DISTRICT_NAME": "ตลาดหลวง", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 624, "SUB_DISTRICT_CODE": "150102", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 625, "SUB_DISTRICT_CODE": "150103", "SUB_DISTRICT_NAME": "ศาลาแดง", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 626, "SUB_DISTRICT_CODE": "150104", "SUB_DISTRICT_NAME": "ป่างิ้ว", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 627, "SUB_DISTRICT_CODE": "150105", "SUB_DISTRICT_NAME": "บ้านแห", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 628, "SUB_DISTRICT_CODE": "150106", "SUB_DISTRICT_NAME": "ตลาดกรวด", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 629, "SUB_DISTRICT_CODE": "150107", "SUB_DISTRICT_NAME": "มหาดไทย", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 630, "SUB_DISTRICT_CODE": "150108", "SUB_DISTRICT_NAME": "บ้านอิฐ", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 631, "SUB_DISTRICT_CODE": "150109", "SUB_DISTRICT_NAME": "หัวไผ่", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 632, "SUB_DISTRICT_CODE": "150110", "SUB_DISTRICT_NAME": "จำปาหล่อ", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 633, "SUB_DISTRICT_CODE": "150111", "SUB_DISTRICT_NAME": "โพสะ", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 634, "SUB_DISTRICT_CODE": "150112", "SUB_DISTRICT_NAME": "บ้านรี", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 635, "SUB_DISTRICT_CODE": "150113", "SUB_DISTRICT_NAME": "คลองวัว", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 636, "SUB_DISTRICT_CODE": "150114", "SUB_DISTRICT_NAME": "ย่านซื่อ", "DISTRICT_ID": 90, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 637, "SUB_DISTRICT_CODE": "150201", "SUB_DISTRICT_NAME": "จรเข้ร้อง", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 638, "SUB_DISTRICT_CODE": "150202", "SUB_DISTRICT_NAME": "ไชยภูมิ", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 639, "SUB_DISTRICT_CODE": "150203", "SUB_DISTRICT_NAME": "ชัยฤทธิ์", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 640, "SUB_DISTRICT_CODE": "150204", "SUB_DISTRICT_NAME": "เทวราช", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 641, "SUB_DISTRICT_CODE": "150205", "SUB_DISTRICT_NAME": "ราชสถิตย์", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 642, "SUB_DISTRICT_CODE": "150206", "SUB_DISTRICT_NAME": "ไชโย", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 643, "SUB_DISTRICT_CODE": "150207", "SUB_DISTRICT_NAME": "หลักฟ้า", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 644, "SUB_DISTRICT_CODE": "150208", "SUB_DISTRICT_NAME": "ชะไว", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 645, "SUB_DISTRICT_CODE": "150209", "SUB_DISTRICT_NAME": "ตรีณรงค์", "DISTRICT_ID": 91, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 646, "SUB_DISTRICT_CODE": "150301", "SUB_DISTRICT_NAME": "บางปลากด", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 647, "SUB_DISTRICT_CODE": "150302", "SUB_DISTRICT_NAME": "ป่าโมก", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 648, "SUB_DISTRICT_CODE": "150303", "SUB_DISTRICT_NAME": "สายทอง", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 649, "SUB_DISTRICT_CODE": "150304", "SUB_DISTRICT_NAME": "โรงช้าง", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 650, "SUB_DISTRICT_CODE": "150305", "SUB_DISTRICT_NAME": "บางเสด็จ", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 651, "SUB_DISTRICT_CODE": "150306", "SUB_DISTRICT_NAME": "นรสิงห์", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 652, "SUB_DISTRICT_CODE": "150307", "SUB_DISTRICT_NAME": "เอกราช", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 653, "SUB_DISTRICT_CODE": "150308", "SUB_DISTRICT_NAME": "โผงเผง", "DISTRICT_ID": 92, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 654, "SUB_DISTRICT_CODE": "150401", "SUB_DISTRICT_NAME": "อ่างแก้ว", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 655, "SUB_DISTRICT_CODE": "150402", "SUB_DISTRICT_NAME": "อินทประมูล", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 656, "SUB_DISTRICT_CODE": "150403", "SUB_DISTRICT_NAME": "บางพลับ", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 657, "SUB_DISTRICT_CODE": "150404", "SUB_DISTRICT_NAME": "หนองแม่ไก่", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 658, "SUB_DISTRICT_CODE": "150405", "SUB_DISTRICT_NAME": "รำมะสัก", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 659, "SUB_DISTRICT_CODE": "150406", "SUB_DISTRICT_NAME": "บางระกำ", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 660, "SUB_DISTRICT_CODE": "150407", "SUB_DISTRICT_NAME": "โพธิ์รังนก", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 661, "SUB_DISTRICT_CODE": "150408", "SUB_DISTRICT_NAME": "องครักษ์", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 662, "SUB_DISTRICT_CODE": "150409", "SUB_DISTRICT_NAME": "โคกพุทรา", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 663, "SUB_DISTRICT_CODE": "150410", "SUB_DISTRICT_NAME": "ยางช้าย", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 664, "SUB_DISTRICT_CODE": "150411", "SUB_DISTRICT_NAME": "บ่อแร่", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 665, "SUB_DISTRICT_CODE": "150412", "SUB_DISTRICT_NAME": "ทางพระ", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 666, "SUB_DISTRICT_CODE": "150413", "SUB_DISTRICT_NAME": "สามง่าม", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 667, "SUB_DISTRICT_CODE": "150414", "SUB_DISTRICT_NAME": "บางเจ้าฉ่า", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 668, "SUB_DISTRICT_CODE": "150415", "SUB_DISTRICT_NAME": "คำหยาด", "DISTRICT_ID": 93, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 669, "SUB_DISTRICT_CODE": "150501", "SUB_DISTRICT_NAME": "แสวงหา", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 670, "SUB_DISTRICT_CODE": "150502", "SUB_DISTRICT_NAME": "ศรีพราน", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 671, "SUB_DISTRICT_CODE": "150503", "SUB_DISTRICT_NAME": "บ้านพราน", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 672, "SUB_DISTRICT_CODE": "150504", "SUB_DISTRICT_NAME": "วังน้ำเย็น", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 673, "SUB_DISTRICT_CODE": "150505", "SUB_DISTRICT_NAME": "สีบัวทอง", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 674, "SUB_DISTRICT_CODE": "150506", "SUB_DISTRICT_NAME": "ห้วยไผ่", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 675, "SUB_DISTRICT_CODE": "150507", "SUB_DISTRICT_NAME": "จำลอง", "DISTRICT_ID": 94, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 676, "SUB_DISTRICT_CODE": "150601", "SUB_DISTRICT_NAME": "ไผ่จำศิล", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 677, "SUB_DISTRICT_CODE": "150602", "SUB_DISTRICT_NAME": "ศาลเจ้าโรงทอง", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 678, "SUB_DISTRICT_CODE": "150603", "SUB_DISTRICT_NAME": "ไผ่ดำพัฒนา", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 679, "SUB_DISTRICT_CODE": "150604", "SUB_DISTRICT_NAME": "สาวร้องไห้", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 680, "SUB_DISTRICT_CODE": "150605", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 681, "SUB_DISTRICT_CODE": "150606", "SUB_DISTRICT_NAME": "ยี่ล้น", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 682, "SUB_DISTRICT_CODE": "150607", "SUB_DISTRICT_NAME": "บางจัก", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 683, "SUB_DISTRICT_CODE": "150608", "SUB_DISTRICT_NAME": "ห้วยคันแหลน", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 684, "SUB_DISTRICT_CODE": "150609", "SUB_DISTRICT_NAME": "คลองขนาก", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 685, "SUB_DISTRICT_CODE": "150610", "SUB_DISTRICT_NAME": "ไผ่วง", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 686, "SUB_DISTRICT_CODE": "150611", "SUB_DISTRICT_NAME": "สี่ร้อย", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 687, "SUB_DISTRICT_CODE": "150612", "SUB_DISTRICT_NAME": "ม่วงเตี้ย", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 688, "SUB_DISTRICT_CODE": "150613", "SUB_DISTRICT_NAME": "หัวตะพาน", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 689, "SUB_DISTRICT_CODE": "150614", "SUB_DISTRICT_NAME": "หลักแก้ว", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 690, "SUB_DISTRICT_CODE": "150615", "SUB_DISTRICT_NAME": "ตลาดใหม่", "DISTRICT_ID": 95, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 691, "SUB_DISTRICT_CODE": "150701", "SUB_DISTRICT_NAME": "สามโก้", "DISTRICT_ID": 96, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 692, "SUB_DISTRICT_CODE": "150702", "SUB_DISTRICT_NAME": "ราษฎรพัฒนา", "DISTRICT_ID": 96, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 693, "SUB_DISTRICT_CODE": "150703", "SUB_DISTRICT_NAME": "อบทม", "DISTRICT_ID": 96, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 694, "SUB_DISTRICT_CODE": "150704", "SUB_DISTRICT_NAME": "โพธิ์ม่วงพันธ์", "DISTRICT_ID": 96, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 695, "SUB_DISTRICT_CODE": "150705", "SUB_DISTRICT_NAME": "มงคลธรรมนิมิต", "DISTRICT_ID": 96, "PROVINCE_ID": 6, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 696, "SUB_DISTRICT_CODE": "160101", "SUB_DISTRICT_NAME": "ทะเลชุบศร", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 697, "SUB_DISTRICT_CODE": "160102", "SUB_DISTRICT_NAME": "ท่าหิน", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 698, "SUB_DISTRICT_CODE": "160103", "SUB_DISTRICT_NAME": "กกโก", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 699, "SUB_DISTRICT_CODE": "160104", "SUB_DISTRICT_NAME": "โก่งธนู", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 700, "SUB_DISTRICT_CODE": "160105", "SUB_DISTRICT_NAME": "เขาพระงาม", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 701, "SUB_DISTRICT_CODE": "160106", "SUB_DISTRICT_NAME": "เขาสามยอด", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 702, "SUB_DISTRICT_CODE": "160107", "SUB_DISTRICT_NAME": "โคกกะเทียม", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 703, "SUB_DISTRICT_CODE": "160108", "SUB_DISTRICT_NAME": "โคกลำพาน", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 704, "SUB_DISTRICT_CODE": "160109", "SUB_DISTRICT_NAME": "โคกตูม", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 705, "SUB_DISTRICT_CODE": "160110", "SUB_DISTRICT_NAME": "งิ้วราย", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 706, "SUB_DISTRICT_CODE": "160111", "SUB_DISTRICT_NAME": "ดอนโพธิ์", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 707, "SUB_DISTRICT_CODE": "160112", "SUB_DISTRICT_NAME": "ตะลุง", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 708, "SUB_DISTRICT_CODE": "160113", "SUB_DISTRICT_NAME": "*ทะเลชุบศร", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 709, "SUB_DISTRICT_CODE": "160114", "SUB_DISTRICT_NAME": "ท่าแค", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 710, "SUB_DISTRICT_CODE": "160115", "SUB_DISTRICT_NAME": "ท่าศาลา", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 711, "SUB_DISTRICT_CODE": "160116", "SUB_DISTRICT_NAME": "นิคมสร้างตนเอง", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 712, "SUB_DISTRICT_CODE": "160117", "SUB_DISTRICT_NAME": "บางขันหมาก", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 713, "SUB_DISTRICT_CODE": "160118", "SUB_DISTRICT_NAME": "บ้านข่อย", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 714, "SUB_DISTRICT_CODE": "160119", "SUB_DISTRICT_NAME": "ท้ายตลาด", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 715, "SUB_DISTRICT_CODE": "160120", "SUB_DISTRICT_NAME": "ป่าตาล", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 716, "SUB_DISTRICT_CODE": "160121", "SUB_DISTRICT_NAME": "พรหมมาสตร์", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 717, "SUB_DISTRICT_CODE": "160122", "SUB_DISTRICT_NAME": "โพธิ์เก้าต้น", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 718, "SUB_DISTRICT_CODE": "160123", "SUB_DISTRICT_NAME": "โพธิ์ตรุ", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 719, "SUB_DISTRICT_CODE": "160124", "SUB_DISTRICT_NAME": "สี่คลอง", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 720, "SUB_DISTRICT_CODE": "160125", "SUB_DISTRICT_NAME": "ถนนใหญ่", "DISTRICT_ID": 97, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 721, "SUB_DISTRICT_CODE": "160201", "SUB_DISTRICT_NAME": "พัฒนานิคม", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 722, "SUB_DISTRICT_CODE": "160202", "SUB_DISTRICT_NAME": "ช่องสาริกา", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 723, "SUB_DISTRICT_CODE": "160203", "SUB_DISTRICT_NAME": "มะนาวหวาน", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 724, "SUB_DISTRICT_CODE": "160204", "SUB_DISTRICT_NAME": "ดีลัง", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 725, "SUB_DISTRICT_CODE": "160205", "SUB_DISTRICT_NAME": "โคกสลุง", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 726, "SUB_DISTRICT_CODE": "160206", "SUB_DISTRICT_NAME": "ชอนน้อย", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 727, "SUB_DISTRICT_CODE": "160207", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 728, "SUB_DISTRICT_CODE": "160208", "SUB_DISTRICT_NAME": "ห้วยขุนราม", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 729, "SUB_DISTRICT_CODE": "160209", "SUB_DISTRICT_NAME": "น้ำสุด", "DISTRICT_ID": 98, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 730, "SUB_DISTRICT_CODE": "160301", "SUB_DISTRICT_NAME": "โคกสำโรง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 731, "SUB_DISTRICT_CODE": "160302", "SUB_DISTRICT_NAME": "เกาะแก้ว", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 732, "SUB_DISTRICT_CODE": "160303", "SUB_DISTRICT_NAME": "ถลุงเหล็ก", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 733, "SUB_DISTRICT_CODE": "160304", "SUB_DISTRICT_NAME": "หลุมข้าว", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 734, "SUB_DISTRICT_CODE": "160305", "SUB_DISTRICT_NAME": "ห้วยโป่ง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 735, "SUB_DISTRICT_CODE": "160306", "SUB_DISTRICT_NAME": "คลองเกตุ", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 736, "SUB_DISTRICT_CODE": "160307", "SUB_DISTRICT_NAME": "สะแกราบ", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 737, "SUB_DISTRICT_CODE": "160308", "SUB_DISTRICT_NAME": "เพนียด", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 738, "SUB_DISTRICT_CODE": "160309", "SUB_DISTRICT_NAME": "วังเพลิง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 739, "SUB_DISTRICT_CODE": "160310", "SUB_DISTRICT_NAME": "ดงมะรุม", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 740, "SUB_DISTRICT_CODE": "160311", "SUB_DISTRICT_NAME": "*ชอนสารเดช", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 741, "SUB_DISTRICT_CODE": "160312", "SUB_DISTRICT_NAME": "*หนองม่วง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 742, "SUB_DISTRICT_CODE": "160313", "SUB_DISTRICT_NAME": "*บ่อทอง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 743, "SUB_DISTRICT_CODE": "160314", "SUB_DISTRICT_NAME": "*ยางโทน", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 744, "SUB_DISTRICT_CODE": "160315", "SUB_DISTRICT_NAME": "*ชอนสมบูรณ์", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 745, "SUB_DISTRICT_CODE": "160316", "SUB_DISTRICT_NAME": "*โคกเจริญ", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 746, "SUB_DISTRICT_CODE": "160317", "SUB_DISTRICT_NAME": "*ยางราก", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 747, "SUB_DISTRICT_CODE": "160318", "SUB_DISTRICT_NAME": "วังขอนขว้าง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 748, "SUB_DISTRICT_CODE": "160319", "SUB_DISTRICT_NAME": "*ดงดินแดง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 749, "SUB_DISTRICT_CODE": "160320", "SUB_DISTRICT_NAME": "วังจั่น", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 750, "SUB_DISTRICT_CODE": "160321", "SUB_DISTRICT_NAME": "*หนองมะค่า", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 751, "SUB_DISTRICT_CODE": "160322", "SUB_DISTRICT_NAME": "หนองแขม", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 752, "SUB_DISTRICT_CODE": "160323", "SUB_DISTRICT_NAME": "*วังทอง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 753, "SUB_DISTRICT_CODE": "160389", "SUB_DISTRICT_NAME": "*ชอนสารเดช", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 754, "SUB_DISTRICT_CODE": "160390", "SUB_DISTRICT_NAME": "*ยางโทน", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 755, "SUB_DISTRICT_CODE": "160391", "SUB_DISTRICT_NAME": "*ชอนสมบูรณ์", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 756, "SUB_DISTRICT_CODE": "160392", "SUB_DISTRICT_NAME": "*ดงดินแดง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 757, "SUB_DISTRICT_CODE": "160393", "SUB_DISTRICT_NAME": "*บ่อทอง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 758, "SUB_DISTRICT_CODE": "160394", "SUB_DISTRICT_NAME": "*หนองม่วง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 759, "SUB_DISTRICT_CODE": "160395", "SUB_DISTRICT_NAME": "*ยางราก", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 760, "SUB_DISTRICT_CODE": "160396", "SUB_DISTRICT_NAME": "*โคกเจริญ", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 761, "SUB_DISTRICT_CODE": "160397", "SUB_DISTRICT_NAME": "*ทุ่งท่าช้าง", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 762, "SUB_DISTRICT_CODE": "160398", "SUB_DISTRICT_NAME": "*มหาโพธิ์", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 763, "SUB_DISTRICT_CODE": "160399", "SUB_DISTRICT_NAME": "*สระโบสถ์", "DISTRICT_ID": 99, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 764, "SUB_DISTRICT_CODE": "160401", "SUB_DISTRICT_NAME": "ลำนารายณ์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 765, "SUB_DISTRICT_CODE": "160402", "SUB_DISTRICT_NAME": "ชัยนารายณ์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 766, "SUB_DISTRICT_CODE": "160403", "SUB_DISTRICT_NAME": "ศิลาทิพย์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 767, "SUB_DISTRICT_CODE": "160404", "SUB_DISTRICT_NAME": "ห้วยหิน", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 768, "SUB_DISTRICT_CODE": "160405", "SUB_DISTRICT_NAME": "ม่วงค่อม", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 769, "SUB_DISTRICT_CODE": "160406", "SUB_DISTRICT_NAME": "บัวชุม", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 770, "SUB_DISTRICT_CODE": "160407", "SUB_DISTRICT_NAME": "ท่าดินดำ", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 771, "SUB_DISTRICT_CODE": "160408", "SUB_DISTRICT_NAME": "มะกอกหวาน", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 772, "SUB_DISTRICT_CODE": "160409", "SUB_DISTRICT_NAME": "ซับตะเคียน", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 773, "SUB_DISTRICT_CODE": "160410", "SUB_DISTRICT_NAME": "นาโสม", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 774, "SUB_DISTRICT_CODE": "160411", "SUB_DISTRICT_NAME": "หนองยายโต๊ะ", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 775, "SUB_DISTRICT_CODE": "160412", "SUB_DISTRICT_NAME": "เกาะรัง", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 776, "SUB_DISTRICT_CODE": "160413", "SUB_DISTRICT_NAME": "*หนองรี", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 777, "SUB_DISTRICT_CODE": "160414", "SUB_DISTRICT_NAME": "ท่ามะนาว", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 778, "SUB_DISTRICT_CODE": "160415", "SUB_DISTRICT_NAME": "*กุดตาเพชร", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 779, "SUB_DISTRICT_CODE": "160416", "SUB_DISTRICT_NAME": "*ลำสนธิ", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 780, "SUB_DISTRICT_CODE": "160417", "SUB_DISTRICT_NAME": "นิคมลำนารายณ์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 781, "SUB_DISTRICT_CODE": "160418", "SUB_DISTRICT_NAME": "ชัยบาดาล", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 782, "SUB_DISTRICT_CODE": "160419", "SUB_DISTRICT_NAME": "บ้านใหม่สามัคคี", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 783, "SUB_DISTRICT_CODE": "160420", "SUB_DISTRICT_NAME": "*ซับสมบูรณ์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 784, "SUB_DISTRICT_CODE": "160421", "SUB_DISTRICT_NAME": "*เขารวก", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 785, "SUB_DISTRICT_CODE": "160422", "SUB_DISTRICT_NAME": "เขาแหลม", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 786, "SUB_DISTRICT_CODE": "160492", "SUB_DISTRICT_NAME": "*เขาฉกรรจ์", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 787, "SUB_DISTRICT_CODE": "160493", "SUB_DISTRICT_NAME": "*กุดตาเพชร", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 788, "SUB_DISTRICT_CODE": "160494", "SUB_DISTRICT_NAME": "*หนองรี", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 789, "SUB_DISTRICT_CODE": "160495", "SUB_DISTRICT_NAME": "*ลำสนธิ", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 790, "SUB_DISTRICT_CODE": "160496", "SUB_DISTRICT_NAME": "*หนองผักแว่น", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 791, "SUB_DISTRICT_CODE": "160497", "SUB_DISTRICT_NAME": "*ซับจำปา", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 792, "SUB_DISTRICT_CODE": "160498", "SUB_DISTRICT_NAME": "*แก่งผักกูด", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 793, "SUB_DISTRICT_CODE": "160499", "SUB_DISTRICT_NAME": "*ท่าหลวง", "DISTRICT_ID": 100, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 794, "SUB_DISTRICT_CODE": "160501", "SUB_DISTRICT_NAME": "ท่าวุ้ง", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 795, "SUB_DISTRICT_CODE": "160502", "SUB_DISTRICT_NAME": "บางคู้", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 796, "SUB_DISTRICT_CODE": "160503", "SUB_DISTRICT_NAME": "โพตลาดแก้ว", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 797, "SUB_DISTRICT_CODE": "160504", "SUB_DISTRICT_NAME": "บางลี่", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 798, "SUB_DISTRICT_CODE": "160505", "SUB_DISTRICT_NAME": "บางงา", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 799, "SUB_DISTRICT_CODE": "160506", "SUB_DISTRICT_NAME": "โคกสลุด", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 800, "SUB_DISTRICT_CODE": "160507", "SUB_DISTRICT_NAME": "เขาสมอคอน", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 801, "SUB_DISTRICT_CODE": "160508", "SUB_DISTRICT_NAME": "หัวสำโรง", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 802, "SUB_DISTRICT_CODE": "160509", "SUB_DISTRICT_NAME": "ลาดสาลี่", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 803, "SUB_DISTRICT_CODE": "160510", "SUB_DISTRICT_NAME": "บ้านเบิก", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 804, "SUB_DISTRICT_CODE": "160511", "SUB_DISTRICT_NAME": "มุจลินท์", "DISTRICT_ID": 101, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 805, "SUB_DISTRICT_CODE": "160601", "SUB_DISTRICT_NAME": "ไผ่ใหญ่", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 806, "SUB_DISTRICT_CODE": "160602", "SUB_DISTRICT_NAME": "บ้านทราย", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 807, "SUB_DISTRICT_CODE": "160603", "SUB_DISTRICT_NAME": "บ้านกล้วย", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 808, "SUB_DISTRICT_CODE": "160604", "SUB_DISTRICT_NAME": "ดงพลับ", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 809, "SUB_DISTRICT_CODE": "160605", "SUB_DISTRICT_NAME": "บ้านชี", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 810, "SUB_DISTRICT_CODE": "160606", "SUB_DISTRICT_NAME": "พุคา", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 811, "SUB_DISTRICT_CODE": "160607", "SUB_DISTRICT_NAME": "หินปัก", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 812, "SUB_DISTRICT_CODE": "160608", "SUB_DISTRICT_NAME": "บางพึ่ง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 813, "SUB_DISTRICT_CODE": "160609", "SUB_DISTRICT_NAME": "หนองทรายขาว", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 814, "SUB_DISTRICT_CODE": "160610", "SUB_DISTRICT_NAME": "บางกะพี้", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 815, "SUB_DISTRICT_CODE": "160611", "SUB_DISTRICT_NAME": "หนองเต่า", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 816, "SUB_DISTRICT_CODE": "160612", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 817, "SUB_DISTRICT_CODE": "160613", "SUB_DISTRICT_NAME": "บางขาม", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 818, "SUB_DISTRICT_CODE": "160614", "SUB_DISTRICT_NAME": "ดอนดึง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 819, "SUB_DISTRICT_CODE": "160615", "SUB_DISTRICT_NAME": "ชอนม่วง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 820, "SUB_DISTRICT_CODE": "160616", "SUB_DISTRICT_NAME": "หนองกระเบียน", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 821, "SUB_DISTRICT_CODE": "160617", "SUB_DISTRICT_NAME": "สายห้วยแก้ว", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 822, "SUB_DISTRICT_CODE": "160618", "SUB_DISTRICT_NAME": "มหาสอน", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 823, "SUB_DISTRICT_CODE": "160619", "SUB_DISTRICT_NAME": "บ้านหมี่", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 824, "SUB_DISTRICT_CODE": "160620", "SUB_DISTRICT_NAME": "เชียงงา", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 825, "SUB_DISTRICT_CODE": "160621", "SUB_DISTRICT_NAME": "หนองเมือง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 826, "SUB_DISTRICT_CODE": "160622", "SUB_DISTRICT_NAME": "สนามแจง", "DISTRICT_ID": 102, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 827, "SUB_DISTRICT_CODE": "160701", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 828, "SUB_DISTRICT_CODE": "160702", "SUB_DISTRICT_NAME": "แก่งผักกูด", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 829, "SUB_DISTRICT_CODE": "160703", "SUB_DISTRICT_NAME": "ซับจำปา", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 830, "SUB_DISTRICT_CODE": "160704", "SUB_DISTRICT_NAME": "หนองผักแว่น", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 831, "SUB_DISTRICT_CODE": "160705", "SUB_DISTRICT_NAME": "ทะเลวังวัด", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 832, "SUB_DISTRICT_CODE": "160706", "SUB_DISTRICT_NAME": "หัวลำ", "DISTRICT_ID": 103, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 833, "SUB_DISTRICT_CODE": "160801", "SUB_DISTRICT_NAME": "สระโบสถ์", "DISTRICT_ID": 104, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 834, "SUB_DISTRICT_CODE": "160802", "SUB_DISTRICT_NAME": "มหาโพธิ", "DISTRICT_ID": 104, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 835, "SUB_DISTRICT_CODE": "160803", "SUB_DISTRICT_NAME": "ทุ่งท่าช้าง", "DISTRICT_ID": 104, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 836, "SUB_DISTRICT_CODE": "160804", "SUB_DISTRICT_NAME": "ห้วยใหญ่", "DISTRICT_ID": 104, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 837, "SUB_DISTRICT_CODE": "160805", "SUB_DISTRICT_NAME": "นิยมชัย", "DISTRICT_ID": 104, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 838, "SUB_DISTRICT_CODE": "160901", "SUB_DISTRICT_NAME": "โคกเจริญ", "DISTRICT_ID": 105, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 839, "SUB_DISTRICT_CODE": "160902", "SUB_DISTRICT_NAME": "ยางราก", "DISTRICT_ID": 105, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 840, "SUB_DISTRICT_CODE": "160903", "SUB_DISTRICT_NAME": "หนองมะค่า", "DISTRICT_ID": 105, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 841, "SUB_DISTRICT_CODE": "160904", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 105, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 842, "SUB_DISTRICT_CODE": "160905", "SUB_DISTRICT_NAME": "โคกแสมสาร", "DISTRICT_ID": 105, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 843, "SUB_DISTRICT_CODE": "161001", "SUB_DISTRICT_NAME": "ลำสนธิ", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 844, "SUB_DISTRICT_CODE": "161002", "SUB_DISTRICT_NAME": "ซับสมบูรณ์", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 845, "SUB_DISTRICT_CODE": "161003", "SUB_DISTRICT_NAME": "หนองรี", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 846, "SUB_DISTRICT_CODE": "161004", "SUB_DISTRICT_NAME": "กุดตาเพชร", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 847, "SUB_DISTRICT_CODE": "161005", "SUB_DISTRICT_NAME": "เขารวก", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 848, "SUB_DISTRICT_CODE": "161006", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 106, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 849, "SUB_DISTRICT_CODE": "161101", "SUB_DISTRICT_NAME": "หนองม่วง", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 850, "SUB_DISTRICT_CODE": "161102", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 851, "SUB_DISTRICT_CODE": "161103", "SUB_DISTRICT_NAME": "ดงดินแดง", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 852, "SUB_DISTRICT_CODE": "161104", "SUB_DISTRICT_NAME": "ชอนสมบูรณ์", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 853, "SUB_DISTRICT_CODE": "161105", "SUB_DISTRICT_NAME": "ยางโทน", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 854, "SUB_DISTRICT_CODE": "161106", "SUB_DISTRICT_NAME": "ชอนสารเดช", "DISTRICT_ID": 107, "PROVINCE_ID": 7, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 855, "SUB_DISTRICT_CODE": "170101", "SUB_DISTRICT_NAME": "บางพุทรา", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 856, "SUB_DISTRICT_CODE": "170102", "SUB_DISTRICT_NAME": "บางมัญ", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 857, "SUB_DISTRICT_CODE": "170103", "SUB_DISTRICT_NAME": "โพกรวม", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 858, "SUB_DISTRICT_CODE": "170104", "SUB_DISTRICT_NAME": "ม่วงหมู่", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 859, "SUB_DISTRICT_CODE": "170105", "SUB_DISTRICT_NAME": "หัวไผ่", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 860, "SUB_DISTRICT_CODE": "170106", "SUB_DISTRICT_NAME": "ต้นโพธิ์", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 861, "SUB_DISTRICT_CODE": "170107", "SUB_DISTRICT_NAME": "จักรสีห์", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 862, "SUB_DISTRICT_CODE": "170108", "SUB_DISTRICT_NAME": "บางกระบือ", "DISTRICT_ID": 109, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 863, "SUB_DISTRICT_CODE": "170201", "SUB_DISTRICT_NAME": "สิงห์", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 864, "SUB_DISTRICT_CODE": "170202", "SUB_DISTRICT_NAME": "ไม้ดัด", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 865, "SUB_DISTRICT_CODE": "170203", "SUB_DISTRICT_NAME": "เชิงกลัด", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 866, "SUB_DISTRICT_CODE": "170204", "SUB_DISTRICT_NAME": "โพชนไก่", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 867, "SUB_DISTRICT_CODE": "170205", "SUB_DISTRICT_NAME": "แม่ลา", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 868, "SUB_DISTRICT_CODE": "170206", "SUB_DISTRICT_NAME": "บ้านจ่า", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 869, "SUB_DISTRICT_CODE": "170207", "SUB_DISTRICT_NAME": "พักทัน", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 870, "SUB_DISTRICT_CODE": "170208", "SUB_DISTRICT_NAME": "สระแจง", "DISTRICT_ID": 110, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 871, "SUB_DISTRICT_CODE": "170301", "SUB_DISTRICT_NAME": "โพทะเล", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 872, "SUB_DISTRICT_CODE": "170302", "SUB_DISTRICT_NAME": "บางระจัน", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 873, "SUB_DISTRICT_CODE": "170303", "SUB_DISTRICT_NAME": "โพสังโฆ", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 874, "SUB_DISTRICT_CODE": "170304", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 875, "SUB_DISTRICT_CODE": "170305", "SUB_DISTRICT_NAME": "คอทราย", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 876, "SUB_DISTRICT_CODE": "170306", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 111, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 877, "SUB_DISTRICT_CODE": "170401", "SUB_DISTRICT_NAME": "พระงาม", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 878, "SUB_DISTRICT_CODE": "170402", "SUB_DISTRICT_NAME": "พรหมบุรี", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 879, "SUB_DISTRICT_CODE": "170403", "SUB_DISTRICT_NAME": "บางน้ำเชี่ยว", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 880, "SUB_DISTRICT_CODE": "170404", "SUB_DISTRICT_NAME": "บ้านหม้อ", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 881, "SUB_DISTRICT_CODE": "170405", "SUB_DISTRICT_NAME": "บ้านแป้ง", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 882, "SUB_DISTRICT_CODE": "170406", "SUB_DISTRICT_NAME": "หัวป่า", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 883, "SUB_DISTRICT_CODE": "170407", "SUB_DISTRICT_NAME": "โรงช้าง", "DISTRICT_ID": 112, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 884, "SUB_DISTRICT_CODE": "170501", "SUB_DISTRICT_NAME": "ถอนสมอ", "DISTRICT_ID": 113, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 885, "SUB_DISTRICT_CODE": "170502", "SUB_DISTRICT_NAME": "โพประจักษ์", "DISTRICT_ID": 113, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 886, "SUB_DISTRICT_CODE": "170503", "SUB_DISTRICT_NAME": "วิหารขาว", "DISTRICT_ID": 113, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 887, "SUB_DISTRICT_CODE": "170504", "SUB_DISTRICT_NAME": "พิกุลทอง", "DISTRICT_ID": 113, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 888, "SUB_DISTRICT_CODE": "170601", "SUB_DISTRICT_NAME": "อินทร์บุรี", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 889, "SUB_DISTRICT_CODE": "170602", "SUB_DISTRICT_NAME": "ประศุก", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 890, "SUB_DISTRICT_CODE": "170603", "SUB_DISTRICT_NAME": "ทับยา", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 891, "SUB_DISTRICT_CODE": "170604", "SUB_DISTRICT_NAME": "งิ้วราย", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 892, "SUB_DISTRICT_CODE": "170605", "SUB_DISTRICT_NAME": "ชีน้ำร้าย", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 893, "SUB_DISTRICT_CODE": "170606", "SUB_DISTRICT_NAME": "ท่างาม", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 894, "SUB_DISTRICT_CODE": "170607", "SUB_DISTRICT_NAME": "น้ำตาล", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 895, "SUB_DISTRICT_CODE": "170608", "SUB_DISTRICT_NAME": "ทองเอน", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 896, "SUB_DISTRICT_CODE": "170609", "SUB_DISTRICT_NAME": "ห้วยชัน", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 897, "SUB_DISTRICT_CODE": "170610", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 114, "PROVINCE_ID": 8, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 898, "SUB_DISTRICT_CODE": "180101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 899, "SUB_DISTRICT_CODE": "180102", "SUB_DISTRICT_NAME": "บ้านกล้วย", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 900, "SUB_DISTRICT_CODE": "180103", "SUB_DISTRICT_NAME": "ท่าชัย", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 901, "SUB_DISTRICT_CODE": "180104", "SUB_DISTRICT_NAME": "ชัยนาท", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 902, "SUB_DISTRICT_CODE": "180105", "SUB_DISTRICT_NAME": "เขาท่าพระ", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 903, "SUB_DISTRICT_CODE": "180106", "SUB_DISTRICT_NAME": "หาดท่าเสา", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 904, "SUB_DISTRICT_CODE": "180107", "SUB_DISTRICT_NAME": "ธรรมามูล", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 905, "SUB_DISTRICT_CODE": "180108", "SUB_DISTRICT_NAME": "เสือโฮก", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 906, "SUB_DISTRICT_CODE": "180109", "SUB_DISTRICT_NAME": "นางลือ", "DISTRICT_ID": 115, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 907, "SUB_DISTRICT_CODE": "180201", "SUB_DISTRICT_NAME": "คุ้งสำเภา", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 908, "SUB_DISTRICT_CODE": "180202", "SUB_DISTRICT_NAME": "วัดโคก", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 909, "SUB_DISTRICT_CODE": "180203", "SUB_DISTRICT_NAME": "ศิลาดาน", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 910, "SUB_DISTRICT_CODE": "180204", "SUB_DISTRICT_NAME": "ท่าฉนวน", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 911, "SUB_DISTRICT_CODE": "180205", "SUB_DISTRICT_NAME": "หางน้ำสาคร", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 912, "SUB_DISTRICT_CODE": "180206", "SUB_DISTRICT_NAME": "ไร่พัฒนา", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 913, "SUB_DISTRICT_CODE": "180207", "SUB_DISTRICT_NAME": "อู่ตะเภา", "DISTRICT_ID": 116, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 914, "SUB_DISTRICT_CODE": "180301", "SUB_DISTRICT_NAME": "วัดสิงห์", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 915, "SUB_DISTRICT_CODE": "180302", "SUB_DISTRICT_NAME": "มะขามเฒ่า", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 916, "SUB_DISTRICT_CODE": "180303", "SUB_DISTRICT_NAME": "หนองน้อย", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 917, "SUB_DISTRICT_CODE": "180304", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 918, "SUB_DISTRICT_CODE": "180305", "SUB_DISTRICT_NAME": "หนองมะโมง*", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 919, "SUB_DISTRICT_CODE": "180306", "SUB_DISTRICT_NAME": "หนองขุ่น", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 920, "SUB_DISTRICT_CODE": "180307", "SUB_DISTRICT_NAME": "บ่อแร่", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 921, "SUB_DISTRICT_CODE": "180308", "SUB_DISTRICT_NAME": "กุดจอก*", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 922, "SUB_DISTRICT_CODE": "180309", "SUB_DISTRICT_NAME": "วังตะเคียน*", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 923, "SUB_DISTRICT_CODE": "180310", "SUB_DISTRICT_NAME": "สะพานหิน*", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 924, "SUB_DISTRICT_CODE": "180311", "SUB_DISTRICT_NAME": "วังหมัน", "DISTRICT_ID": 117, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 925, "SUB_DISTRICT_CODE": "180401", "SUB_DISTRICT_NAME": "สรรพยา", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 926, "SUB_DISTRICT_CODE": "180402", "SUB_DISTRICT_NAME": "ตลุก", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 927, "SUB_DISTRICT_CODE": "180403", "SUB_DISTRICT_NAME": "เขาแก้ว", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 928, "SUB_DISTRICT_CODE": "180404", "SUB_DISTRICT_NAME": "โพนางดำตก", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 929, "SUB_DISTRICT_CODE": "180405", "SUB_DISTRICT_NAME": "โพนางดำออก", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 930, "SUB_DISTRICT_CODE": "180406", "SUB_DISTRICT_NAME": "บางหลวง", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 931, "SUB_DISTRICT_CODE": "180407", "SUB_DISTRICT_NAME": "หาดอาษา", "DISTRICT_ID": 118, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 932, "SUB_DISTRICT_CODE": "180501", "SUB_DISTRICT_NAME": "แพรกศรีราชา", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 933, "SUB_DISTRICT_CODE": "180502", "SUB_DISTRICT_NAME": "เที่ยงแท้", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 934, "SUB_DISTRICT_CODE": "180503", "SUB_DISTRICT_NAME": "ห้วยกรด", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 935, "SUB_DISTRICT_CODE": "180504", "SUB_DISTRICT_NAME": "โพงาม", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 936, "SUB_DISTRICT_CODE": "180505", "SUB_DISTRICT_NAME": "บางขุด", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 937, "SUB_DISTRICT_CODE": "180506", "SUB_DISTRICT_NAME": "ดงคอน", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 938, "SUB_DISTRICT_CODE": "180507", "SUB_DISTRICT_NAME": "ดอนกำ", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 939, "SUB_DISTRICT_CODE": "180508", "SUB_DISTRICT_NAME": "ห้วยกรดพัฒนา", "DISTRICT_ID": 119, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 940, "SUB_DISTRICT_CODE": "180601", "SUB_DISTRICT_NAME": "หันคา", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 941, "SUB_DISTRICT_CODE": "180602", "SUB_DISTRICT_NAME": "บ้านเชี่ยน", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 942, "SUB_DISTRICT_CODE": "180603", "SUB_DISTRICT_NAME": "เนินขาม*", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 943, "SUB_DISTRICT_CODE": "180604", "SUB_DISTRICT_NAME": "สุขเดือนห้า*", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 944, "SUB_DISTRICT_CODE": "180605", "SUB_DISTRICT_NAME": "ไพรนกยูง", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 945, "SUB_DISTRICT_CODE": "180606", "SUB_DISTRICT_NAME": "หนองแซง", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 946, "SUB_DISTRICT_CODE": "180607", "SUB_DISTRICT_NAME": "ห้วยงู", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 947, "SUB_DISTRICT_CODE": "180608", "SUB_DISTRICT_NAME": "วังไก่เถื่อน", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 948, "SUB_DISTRICT_CODE": "180609", "SUB_DISTRICT_NAME": "เด่นใหญ่", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 949, "SUB_DISTRICT_CODE": "180610", "SUB_DISTRICT_NAME": "กะบกเตี้ย*", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 950, "SUB_DISTRICT_CODE": "180611", "SUB_DISTRICT_NAME": "สามง่ามท่าโบสถ์", "DISTRICT_ID": 120, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 951, "SUB_DISTRICT_CODE": "180701", "SUB_DISTRICT_NAME": "หนองมะโมง", "DISTRICT_ID": 121, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 952, "SUB_DISTRICT_CODE": "180702", "SUB_DISTRICT_NAME": "วังตะเคียน", "DISTRICT_ID": 121, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 953, "SUB_DISTRICT_CODE": "180703", "SUB_DISTRICT_NAME": "สะพานหิน", "DISTRICT_ID": 121, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 954, "SUB_DISTRICT_CODE": "180704", "SUB_DISTRICT_NAME": "กุดจอก", "DISTRICT_ID": 121, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 955, "SUB_DISTRICT_CODE": "180801", "SUB_DISTRICT_NAME": "เนินขาม", "DISTRICT_ID": 122, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 956, "SUB_DISTRICT_CODE": "180802", "SUB_DISTRICT_NAME": "กะบกเตี้ย", "DISTRICT_ID": 122, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 957, "SUB_DISTRICT_CODE": "180803", "SUB_DISTRICT_NAME": "สุขเดือนห้า", "DISTRICT_ID": 122, "PROVINCE_ID": 9, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 958, "SUB_DISTRICT_CODE": "190101", "SUB_DISTRICT_NAME": "ปากเพรียว", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 959, "SUB_DISTRICT_CODE": "190102", "SUB_DISTRICT_NAME": "หน้าพระลาน*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 960, "SUB_DISTRICT_CODE": "190103", "SUB_DISTRICT_NAME": "พุแค*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 961, "SUB_DISTRICT_CODE": "190104", "SUB_DISTRICT_NAME": "ห้วยบง*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 962, "SUB_DISTRICT_CODE": "190105", "SUB_DISTRICT_NAME": "ดาวเรือง", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 963, "SUB_DISTRICT_CODE": "190106", "SUB_DISTRICT_NAME": "นาโฉง", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 964, "SUB_DISTRICT_CODE": "190107", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 965, "SUB_DISTRICT_CODE": "190108", "SUB_DISTRICT_NAME": "หนองโน", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 966, "SUB_DISTRICT_CODE": "190109", "SUB_DISTRICT_NAME": "หนองยาว", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 967, "SUB_DISTRICT_CODE": "190110", "SUB_DISTRICT_NAME": "ปากข้าวสาร", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 968, "SUB_DISTRICT_CODE": "190111", "SUB_DISTRICT_NAME": "หนองปลาไหล", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 969, "SUB_DISTRICT_CODE": "190112", "SUB_DISTRICT_NAME": "กุดนกเปล้า", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 970, "SUB_DISTRICT_CODE": "190113", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 971, "SUB_DISTRICT_CODE": "190114", "SUB_DISTRICT_NAME": "ตะกุด", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 972, "SUB_DISTRICT_CODE": "190115", "SUB_DISTRICT_NAME": "บ้านแก้ง*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 973, "SUB_DISTRICT_CODE": "190116", "SUB_DISTRICT_NAME": "ผึ้งรวง*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 974, "SUB_DISTRICT_CODE": "190117", "SUB_DISTRICT_NAME": "เขาดินพัฒนา*", "DISTRICT_ID": 123, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 975, "SUB_DISTRICT_CODE": "190201", "SUB_DISTRICT_NAME": "แก่งคอย", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 976, "SUB_DISTRICT_CODE": "190202", "SUB_DISTRICT_NAME": "ทับกวาง", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 977, "SUB_DISTRICT_CODE": "190203", "SUB_DISTRICT_NAME": "ตาลเดี่ยว", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 978, "SUB_DISTRICT_CODE": "190204", "SUB_DISTRICT_NAME": "ห้วยแห้ง", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 979, "SUB_DISTRICT_CODE": "190205", "SUB_DISTRICT_NAME": "ท่าคล้อ", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 980, "SUB_DISTRICT_CODE": "190206", "SUB_DISTRICT_NAME": "หินซ้อน", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 981, "SUB_DISTRICT_CODE": "190207", "SUB_DISTRICT_NAME": "บ้านธาตุ", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 982, "SUB_DISTRICT_CODE": "190208", "SUB_DISTRICT_NAME": "บ้านป่า", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 983, "SUB_DISTRICT_CODE": "190209", "SUB_DISTRICT_NAME": "ท่าตูม", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 984, "SUB_DISTRICT_CODE": "190210", "SUB_DISTRICT_NAME": "ชะอม", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 985, "SUB_DISTRICT_CODE": "190211", "SUB_DISTRICT_NAME": "สองคอน", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 986, "SUB_DISTRICT_CODE": "190212", "SUB_DISTRICT_NAME": "เตาปูน", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 987, "SUB_DISTRICT_CODE": "190213", "SUB_DISTRICT_NAME": "ชำผักแพว", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 988, "SUB_DISTRICT_CODE": "190215", "SUB_DISTRICT_NAME": "ท่ามะปราง", "DISTRICT_ID": 124, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 989, "SUB_DISTRICT_CODE": "190301", "SUB_DISTRICT_NAME": "หนองแค", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 990, "SUB_DISTRICT_CODE": "190302", "SUB_DISTRICT_NAME": "กุ่มหัก", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 991, "SUB_DISTRICT_CODE": "190303", "SUB_DISTRICT_NAME": "คชสิทธิ์", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 992, "SUB_DISTRICT_CODE": "190304", "SUB_DISTRICT_NAME": "โคกตูม", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 993, "SUB_DISTRICT_CODE": "190305", "SUB_DISTRICT_NAME": "โคกแย้", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 994, "SUB_DISTRICT_CODE": "190306", "SUB_DISTRICT_NAME": "บัวลอย", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 995, "SUB_DISTRICT_CODE": "190307", "SUB_DISTRICT_NAME": "ไผ่ต่ำ", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 996, "SUB_DISTRICT_CODE": "190308", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 997, "SUB_DISTRICT_CODE": "190309", "SUB_DISTRICT_NAME": "ห้วยขมิ้น", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 998, "SUB_DISTRICT_CODE": "190310", "SUB_DISTRICT_NAME": "ห้วยทราย", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 999, "SUB_DISTRICT_CODE": "190311", "SUB_DISTRICT_NAME": "หนองไข่น้ำ", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1000, "SUB_DISTRICT_CODE": "190312", "SUB_DISTRICT_NAME": "หนองแขม", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1001, "SUB_DISTRICT_CODE": "190313", "SUB_DISTRICT_NAME": "หนองจิก", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1002, "SUB_DISTRICT_CODE": "190314", "SUB_DISTRICT_NAME": "หนองจรเข้", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1003, "SUB_DISTRICT_CODE": "190315", "SUB_DISTRICT_NAME": "หนองนาก", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1004, "SUB_DISTRICT_CODE": "190316", "SUB_DISTRICT_NAME": "หนองปลาหมอ", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1005, "SUB_DISTRICT_CODE": "190317", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1006, "SUB_DISTRICT_CODE": "190318", "SUB_DISTRICT_NAME": "หนองโรง", "DISTRICT_ID": 125, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1007, "SUB_DISTRICT_CODE": "190401", "SUB_DISTRICT_NAME": "หนองหมู", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1008, "SUB_DISTRICT_CODE": "190402", "SUB_DISTRICT_NAME": "บ้านลำ", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1009, "SUB_DISTRICT_CODE": "190403", "SUB_DISTRICT_NAME": "คลองเรือ", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1010, "SUB_DISTRICT_CODE": "190404", "SUB_DISTRICT_NAME": "วิหารแดง", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1011, "SUB_DISTRICT_CODE": "190405", "SUB_DISTRICT_NAME": "หนองสรวง", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1012, "SUB_DISTRICT_CODE": "190406", "SUB_DISTRICT_NAME": "เจริญธรรม", "DISTRICT_ID": 126, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1013, "SUB_DISTRICT_CODE": "190501", "SUB_DISTRICT_NAME": "หนองแซง", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1014, "SUB_DISTRICT_CODE": "190502", "SUB_DISTRICT_NAME": "หนองควายโซ", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1015, "SUB_DISTRICT_CODE": "190503", "SUB_DISTRICT_NAME": "หนองหัวโพ", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1016, "SUB_DISTRICT_CODE": "190504", "SUB_DISTRICT_NAME": "หนองสีดา", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1017, "SUB_DISTRICT_CODE": "190505", "SUB_DISTRICT_NAME": "หนองกบ", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1018, "SUB_DISTRICT_CODE": "190506", "SUB_DISTRICT_NAME": "ไก่เส่า", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1019, "SUB_DISTRICT_CODE": "190507", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1020, "SUB_DISTRICT_CODE": "190508", "SUB_DISTRICT_NAME": "ม่วงหวาน", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1021, "SUB_DISTRICT_CODE": "190509", "SUB_DISTRICT_NAME": "เขาดิน", "DISTRICT_ID": 127, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1022, "SUB_DISTRICT_CODE": "190601", "SUB_DISTRICT_NAME": "บ้านหมอ", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1023, "SUB_DISTRICT_CODE": "190602", "SUB_DISTRICT_NAME": "บางโขมด", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1024, "SUB_DISTRICT_CODE": "190603", "SUB_DISTRICT_NAME": "สร่างโศก", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1025, "SUB_DISTRICT_CODE": "190604", "SUB_DISTRICT_NAME": "ตลาดน้อย", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1026, "SUB_DISTRICT_CODE": "190605", "SUB_DISTRICT_NAME": "หรเทพ", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1027, "SUB_DISTRICT_CODE": "190606", "SUB_DISTRICT_NAME": "โคกใหญ่", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1028, "SUB_DISTRICT_CODE": "190607", "SUB_DISTRICT_NAME": "ไผ่ขวาง", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1029, "SUB_DISTRICT_CODE": "190608", "SUB_DISTRICT_NAME": "บ้านครัว", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1030, "SUB_DISTRICT_CODE": "190609", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1031, "SUB_DISTRICT_CODE": "190696", "SUB_DISTRICT_NAME": "*ดงตะงาว", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1032, "SUB_DISTRICT_CODE": "190697", "SUB_DISTRICT_NAME": "*บ้านหลวง", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1033, "SUB_DISTRICT_CODE": "190698", "SUB_DISTRICT_NAME": "*ไผ่หลิ่ว", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1034, "SUB_DISTRICT_CODE": "190699", "SUB_DISTRICT_NAME": "*ดอนพุด", "DISTRICT_ID": 128, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1035, "SUB_DISTRICT_CODE": "190701", "SUB_DISTRICT_NAME": "ดอนพุด", "DISTRICT_ID": 129, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1036, "SUB_DISTRICT_CODE": "190702", "SUB_DISTRICT_NAME": "ไผ่หลิ่ว", "DISTRICT_ID": 129, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1037, "SUB_DISTRICT_CODE": "190703", "SUB_DISTRICT_NAME": "บ้านหลวง", "DISTRICT_ID": 129, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1038, "SUB_DISTRICT_CODE": "190704", "SUB_DISTRICT_NAME": "ดงตะงาว", "DISTRICT_ID": 129, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1039, "SUB_DISTRICT_CODE": "190801", "SUB_DISTRICT_NAME": "หนองโดน", "DISTRICT_ID": 130, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1040, "SUB_DISTRICT_CODE": "190802", "SUB_DISTRICT_NAME": "บ้านกลับ", "DISTRICT_ID": 130, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1041, "SUB_DISTRICT_CODE": "190803", "SUB_DISTRICT_NAME": "ดอนทอง", "DISTRICT_ID": 130, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1042, "SUB_DISTRICT_CODE": "190804", "SUB_DISTRICT_NAME": "บ้านโปร่ง", "DISTRICT_ID": 130, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1043, "SUB_DISTRICT_CODE": "190901", "SUB_DISTRICT_NAME": "พระพุทธบาท", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1044, "SUB_DISTRICT_CODE": "190902", "SUB_DISTRICT_NAME": "ขุนโขลน", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1045, "SUB_DISTRICT_CODE": "190903", "SUB_DISTRICT_NAME": "ธารเกษม", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1046, "SUB_DISTRICT_CODE": "190904", "SUB_DISTRICT_NAME": "นายาว", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1047, "SUB_DISTRICT_CODE": "190905", "SUB_DISTRICT_NAME": "พุคำจาน", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1048, "SUB_DISTRICT_CODE": "190906", "SUB_DISTRICT_NAME": "เขาวง", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1049, "SUB_DISTRICT_CODE": "190907", "SUB_DISTRICT_NAME": "ห้วยป่าหวาย", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1050, "SUB_DISTRICT_CODE": "190908", "SUB_DISTRICT_NAME": "พุกร่าง", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1051, "SUB_DISTRICT_CODE": "190909", "SUB_DISTRICT_NAME": "หนองแก", "DISTRICT_ID": 131, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1052, "SUB_DISTRICT_CODE": "191001", "SUB_DISTRICT_NAME": "เสาไห้", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1053, "SUB_DISTRICT_CODE": "191002", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1054, "SUB_DISTRICT_CODE": "191003", "SUB_DISTRICT_NAME": "หัวปลวก", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1055, "SUB_DISTRICT_CODE": "191004", "SUB_DISTRICT_NAME": "งิ้วงาม", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1056, "SUB_DISTRICT_CODE": "191005", "SUB_DISTRICT_NAME": "ศาลารีไทย", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1057, "SUB_DISTRICT_CODE": "191006", "SUB_DISTRICT_NAME": "ต้นตาล", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1058, "SUB_DISTRICT_CODE": "191007", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1059, "SUB_DISTRICT_CODE": "191008", "SUB_DISTRICT_NAME": "พระยาทด", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1060, "SUB_DISTRICT_CODE": "191009", "SUB_DISTRICT_NAME": "ม่วงงาม", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1061, "SUB_DISTRICT_CODE": "191010", "SUB_DISTRICT_NAME": "เริงราง", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1062, "SUB_DISTRICT_CODE": "191011", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1063, "SUB_DISTRICT_CODE": "191012", "SUB_DISTRICT_NAME": "สวนดอกไม้", "DISTRICT_ID": 132, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1064, "SUB_DISTRICT_CODE": "191101", "SUB_DISTRICT_NAME": "มวกเหล็ก", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1065, "SUB_DISTRICT_CODE": "191102", "SUB_DISTRICT_NAME": "มิตรภาพ", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1066, "SUB_DISTRICT_CODE": "191103", "SUB_DISTRICT_NAME": "*แสลงพัน", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1067, "SUB_DISTRICT_CODE": "191104", "SUB_DISTRICT_NAME": "หนองย่างเสือ", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1068, "SUB_DISTRICT_CODE": "191105", "SUB_DISTRICT_NAME": "ลำสมพุง", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1069, "SUB_DISTRICT_CODE": "191106", "SUB_DISTRICT_NAME": "*คำพราน", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1070, "SUB_DISTRICT_CODE": "191107", "SUB_DISTRICT_NAME": "ลำพญากลาง", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1071, "SUB_DISTRICT_CODE": "191108", "SUB_DISTRICT_NAME": "*วังม่วง", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1072, "SUB_DISTRICT_CODE": "191109", "SUB_DISTRICT_NAME": "ซับสนุ่น", "DISTRICT_ID": 133, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1073, "SUB_DISTRICT_CODE": "191201", "SUB_DISTRICT_NAME": "แสลงพัน", "DISTRICT_ID": 134, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1074, "SUB_DISTRICT_CODE": "191202", "SUB_DISTRICT_NAME": "คำพราน", "DISTRICT_ID": 134, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1075, "SUB_DISTRICT_CODE": "191203", "SUB_DISTRICT_NAME": "วังม่วง", "DISTRICT_ID": 134, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1076, "SUB_DISTRICT_CODE": "191301", "SUB_DISTRICT_NAME": "เขาดินพัฒนา", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1077, "SUB_DISTRICT_CODE": "191302", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1078, "SUB_DISTRICT_CODE": "191303", "SUB_DISTRICT_NAME": "ผึ้งรวง", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1079, "SUB_DISTRICT_CODE": "191304", "SUB_DISTRICT_NAME": "พุแค", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1080, "SUB_DISTRICT_CODE": "191305", "SUB_DISTRICT_NAME": "ห้วยบง", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1081, "SUB_DISTRICT_CODE": "191306", "SUB_DISTRICT_NAME": "หน้าพระลาน", "DISTRICT_ID": 135, "PROVINCE_ID": 10, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1082, "SUB_DISTRICT_CODE": "200101", "SUB_DISTRICT_NAME": "บางปลาสร้อย", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1083, "SUB_DISTRICT_CODE": "200102", "SUB_DISTRICT_NAME": "มะขามหย่ง", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1084, "SUB_DISTRICT_CODE": "200103", "SUB_DISTRICT_NAME": "บ้านโขด", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1085, "SUB_DISTRICT_CODE": "200104", "SUB_DISTRICT_NAME": "แสนสุข", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1086, "SUB_DISTRICT_CODE": "200105", "SUB_DISTRICT_NAME": "บ้านสวน", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1087, "SUB_DISTRICT_CODE": "200106", "SUB_DISTRICT_NAME": "หนองรี", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1088, "SUB_DISTRICT_CODE": "200107", "SUB_DISTRICT_NAME": "นาป่า", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1089, "SUB_DISTRICT_CODE": "200108", "SUB_DISTRICT_NAME": "หนองข้างคอก", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1090, "SUB_DISTRICT_CODE": "200109", "SUB_DISTRICT_NAME": "ดอนหัวฬ่อ", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1091, "SUB_DISTRICT_CODE": "200110", "SUB_DISTRICT_NAME": "หนองไม้แดง", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1092, "SUB_DISTRICT_CODE": "200111", "SUB_DISTRICT_NAME": "บางทราย", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1093, "SUB_DISTRICT_CODE": "200112", "SUB_DISTRICT_NAME": "คลองตำหรุ", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1094, "SUB_DISTRICT_CODE": "200113", "SUB_DISTRICT_NAME": "เหมือง", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1095, "SUB_DISTRICT_CODE": "200114", "SUB_DISTRICT_NAME": "บ้านปึก", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1096, "SUB_DISTRICT_CODE": "200115", "SUB_DISTRICT_NAME": "ห้วยกะปิ", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1097, "SUB_DISTRICT_CODE": "200116", "SUB_DISTRICT_NAME": "เสม็ด", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1098, "SUB_DISTRICT_CODE": "200117", "SUB_DISTRICT_NAME": "อ่างศิลา", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1099, "SUB_DISTRICT_CODE": "200118", "SUB_DISTRICT_NAME": "สำนักบก", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1100, "SUB_DISTRICT_CODE": "200199", "SUB_DISTRICT_NAME": "เทศบาลเมืองชลบุรี*", "DISTRICT_ID": 136, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1101, "SUB_DISTRICT_CODE": "200201", "SUB_DISTRICT_NAME": "บ้านบึง", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1102, "SUB_DISTRICT_CODE": "200202", "SUB_DISTRICT_NAME": "คลองกิ่ว", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1103, "SUB_DISTRICT_CODE": "200203", "SUB_DISTRICT_NAME": "มาบไผ่", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1104, "SUB_DISTRICT_CODE": "200204", "SUB_DISTRICT_NAME": "หนองซ้ำซาก", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1105, "SUB_DISTRICT_CODE": "200205", "SUB_DISTRICT_NAME": "หนองบอนแดง", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1106, "SUB_DISTRICT_CODE": "200206", "SUB_DISTRICT_NAME": "หนองชาก", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1107, "SUB_DISTRICT_CODE": "200207", "SUB_DISTRICT_NAME": "หนองอิรุณ", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1108, "SUB_DISTRICT_CODE": "200208", "SUB_DISTRICT_NAME": "หนองไผ่แก้ว", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1109, "SUB_DISTRICT_CODE": "200297", "SUB_DISTRICT_NAME": "*หนองเสือช้าง", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1110, "SUB_DISTRICT_CODE": "200298", "SUB_DISTRICT_NAME": "*คลองพลู", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1111, "SUB_DISTRICT_CODE": "200299", "SUB_DISTRICT_NAME": "*หนองใหญ่", "DISTRICT_ID": 137, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1112, "SUB_DISTRICT_CODE": "200301", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 138, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1113, "SUB_DISTRICT_CODE": "200302", "SUB_DISTRICT_NAME": "คลองพลู", "DISTRICT_ID": 138, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1114, "SUB_DISTRICT_CODE": "200303", "SUB_DISTRICT_NAME": "หนองเสือช้าง", "DISTRICT_ID": 138, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1115, "SUB_DISTRICT_CODE": "200304", "SUB_DISTRICT_NAME": "ห้างสูง", "DISTRICT_ID": 138, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1116, "SUB_DISTRICT_CODE": "200305", "SUB_DISTRICT_NAME": "เขาซก", "DISTRICT_ID": 138, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1117, "SUB_DISTRICT_CODE": "200401", "SUB_DISTRICT_NAME": "บางละมุง", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1118, "SUB_DISTRICT_CODE": "200402", "SUB_DISTRICT_NAME": "หนองปรือ", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1119, "SUB_DISTRICT_CODE": "200403", "SUB_DISTRICT_NAME": "หนองปลาไหล", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1120, "SUB_DISTRICT_CODE": "200404", "SUB_DISTRICT_NAME": "โป่ง", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1121, "SUB_DISTRICT_CODE": "200405", "SUB_DISTRICT_NAME": "เขาไม้แก้ว", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1122, "SUB_DISTRICT_CODE": "200406", "SUB_DISTRICT_NAME": "ห้วยใหญ่", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1123, "SUB_DISTRICT_CODE": "200407", "SUB_DISTRICT_NAME": "ตะเคียนเตี้ย", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1124, "SUB_DISTRICT_CODE": "200408", "SUB_DISTRICT_NAME": "นาเกลือ", "DISTRICT_ID": 139, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1125, "SUB_DISTRICT_CODE": "200501", "SUB_DISTRICT_NAME": "พานทอง", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1126, "SUB_DISTRICT_CODE": "200502", "SUB_DISTRICT_NAME": "หนองตำลึง", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1127, "SUB_DISTRICT_CODE": "200503", "SUB_DISTRICT_NAME": "มาบโป่ง", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1128, "SUB_DISTRICT_CODE": "200504", "SUB_DISTRICT_NAME": "หนองกะขะ", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1129, "SUB_DISTRICT_CODE": "200505", "SUB_DISTRICT_NAME": "หนองหงษ์", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1130, "SUB_DISTRICT_CODE": "200506", "SUB_DISTRICT_NAME": "โคกขี้หนอน", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1131, "SUB_DISTRICT_CODE": "200507", "SUB_DISTRICT_NAME": "บ้านเก่า", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1132, "SUB_DISTRICT_CODE": "200508", "SUB_DISTRICT_NAME": "หน้าประดู่", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1133, "SUB_DISTRICT_CODE": "200509", "SUB_DISTRICT_NAME": "บางนาง", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1134, "SUB_DISTRICT_CODE": "200510", "SUB_DISTRICT_NAME": "เกาะลอย", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1135, "SUB_DISTRICT_CODE": "200511", "SUB_DISTRICT_NAME": "บางหัก", "DISTRICT_ID": 140, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1136, "SUB_DISTRICT_CODE": "200601", "SUB_DISTRICT_NAME": "พนัสนิคม", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1137, "SUB_DISTRICT_CODE": "200602", "SUB_DISTRICT_NAME": "หน้าพระธาตุ", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1138, "SUB_DISTRICT_CODE": "200603", "SUB_DISTRICT_NAME": "วัดหลวง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1139, "SUB_DISTRICT_CODE": "200604", "SUB_DISTRICT_NAME": "บ้านเซิด", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1140, "SUB_DISTRICT_CODE": "200605", "SUB_DISTRICT_NAME": "นาเริก", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1141, "SUB_DISTRICT_CODE": "200606", "SUB_DISTRICT_NAME": "หมอนนาง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1142, "SUB_DISTRICT_CODE": "200607", "SUB_DISTRICT_NAME": "สระสี่เหลี่ยม", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1143, "SUB_DISTRICT_CODE": "200608", "SUB_DISTRICT_NAME": "วัดโบสถ์", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1144, "SUB_DISTRICT_CODE": "200609", "SUB_DISTRICT_NAME": "กุฎโง้ง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1145, "SUB_DISTRICT_CODE": "200610", "SUB_DISTRICT_NAME": "หัวถนน", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1146, "SUB_DISTRICT_CODE": "200611", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1147, "SUB_DISTRICT_CODE": "200612", "SUB_DISTRICT_NAME": "ท่าบุญมี**", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1148, "SUB_DISTRICT_CODE": "200613", "SUB_DISTRICT_NAME": "หนองปรือ", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1149, "SUB_DISTRICT_CODE": "200614", "SUB_DISTRICT_NAME": "หนองขยาด", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1150, "SUB_DISTRICT_CODE": "200615", "SUB_DISTRICT_NAME": "ทุ่งขวาง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1151, "SUB_DISTRICT_CODE": "200616", "SUB_DISTRICT_NAME": "หนองเหียง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1152, "SUB_DISTRICT_CODE": "200617", "SUB_DISTRICT_NAME": "นาวังหิน", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1153, "SUB_DISTRICT_CODE": "200618", "SUB_DISTRICT_NAME": "บ้านช้าง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1154, "SUB_DISTRICT_CODE": "200619", "SUB_DISTRICT_NAME": "เกาะจันทร์**", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1155, "SUB_DISTRICT_CODE": "200620", "SUB_DISTRICT_NAME": "โคกเพลาะ", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1156, "SUB_DISTRICT_CODE": "200621", "SUB_DISTRICT_NAME": "ไร่หลักทอง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1157, "SUB_DISTRICT_CODE": "200622", "SUB_DISTRICT_NAME": "นามะตูม", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1158, "SUB_DISTRICT_CODE": "200623", "SUB_DISTRICT_NAME": "*บ้านเซิด", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1159, "SUB_DISTRICT_CODE": "200696", "SUB_DISTRICT_NAME": "*พูนพัฒนาทรัพย์", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1160, "SUB_DISTRICT_CODE": "200697", "SUB_DISTRICT_NAME": "*บ่อกวางทอง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1161, "SUB_DISTRICT_CODE": "200698", "SUB_DISTRICT_NAME": "*วัดสุวรรณ", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1162, "SUB_DISTRICT_CODE": "200699", "SUB_DISTRICT_NAME": "*บ่อทอง", "DISTRICT_ID": 141, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1163, "SUB_DISTRICT_CODE": "200701", "SUB_DISTRICT_NAME": "ศรีราชา", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1164, "SUB_DISTRICT_CODE": "200702", "SUB_DISTRICT_NAME": "สุรศักดิ์", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1165, "SUB_DISTRICT_CODE": "200703", "SUB_DISTRICT_NAME": "ทุ่งสุขลา", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1166, "SUB_DISTRICT_CODE": "200704", "SUB_DISTRICT_NAME": "บึง", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1167, "SUB_DISTRICT_CODE": "200705", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1168, "SUB_DISTRICT_CODE": "200706", "SUB_DISTRICT_NAME": "เขาคันทรง", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1169, "SUB_DISTRICT_CODE": "200707", "SUB_DISTRICT_NAME": "บางพระ", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1170, "SUB_DISTRICT_CODE": "200708", "SUB_DISTRICT_NAME": "บ่อวิน", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1171, "SUB_DISTRICT_CODE": "200799", "SUB_DISTRICT_NAME": "*ท่าเทววงษ์", "DISTRICT_ID": 142, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1172, "SUB_DISTRICT_CODE": "200801", "SUB_DISTRICT_NAME": "ท่าเทววงษ์", "DISTRICT_ID": 143, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1173, "SUB_DISTRICT_CODE": "200901", "SUB_DISTRICT_NAME": "สัตหีบ", "DISTRICT_ID": 144, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1174, "SUB_DISTRICT_CODE": "200902", "SUB_DISTRICT_NAME": "นาจอมเทียน", "DISTRICT_ID": 144, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1175, "SUB_DISTRICT_CODE": "200903", "SUB_DISTRICT_NAME": "พลูตาหลวง", "DISTRICT_ID": 144, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1176, "SUB_DISTRICT_CODE": "200904", "SUB_DISTRICT_NAME": "บางเสร่", "DISTRICT_ID": 144, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1177, "SUB_DISTRICT_CODE": "200905", "SUB_DISTRICT_NAME": "แสมสาร", "DISTRICT_ID": 144, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1178, "SUB_DISTRICT_CODE": "201001", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1179, "SUB_DISTRICT_CODE": "201002", "SUB_DISTRICT_NAME": "วัดสุวรรณ", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1180, "SUB_DISTRICT_CODE": "201003", "SUB_DISTRICT_NAME": "บ่อกวางทอง", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1181, "SUB_DISTRICT_CODE": "201004", "SUB_DISTRICT_NAME": "ธาตุทอง", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1182, "SUB_DISTRICT_CODE": "201005", "SUB_DISTRICT_NAME": "เกษตรสุวรรณ", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1183, "SUB_DISTRICT_CODE": "201006", "SUB_DISTRICT_NAME": "พลวงทอง", "DISTRICT_ID": 145, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1184, "SUB_DISTRICT_CODE": "201101", "SUB_DISTRICT_NAME": "เกาะจันทร์", "DISTRICT_ID": 146, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1185, "SUB_DISTRICT_CODE": "201102", "SUB_DISTRICT_NAME": "ท่าบุญมี", "DISTRICT_ID": 146, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1186, "SUB_DISTRICT_CODE": "207201", "SUB_DISTRICT_NAME": "หนองปรือ*", "DISTRICT_ID": 148, "PROVINCE_ID": 11, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1187, "SUB_DISTRICT_CODE": "210101", "SUB_DISTRICT_NAME": "ท่าประดู่", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1188, "SUB_DISTRICT_CODE": "210102", "SUB_DISTRICT_NAME": "เชิงเนิน", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1189, "SUB_DISTRICT_CODE": "210103", "SUB_DISTRICT_NAME": "ตะพง", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1190, "SUB_DISTRICT_CODE": "210104", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1191, "SUB_DISTRICT_CODE": "210105", "SUB_DISTRICT_NAME": "เพ", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1192, "SUB_DISTRICT_CODE": "210106", "SUB_DISTRICT_NAME": "แกลง", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1193, "SUB_DISTRICT_CODE": "210107", "SUB_DISTRICT_NAME": "บ้านแลง", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1194, "SUB_DISTRICT_CODE": "210108", "SUB_DISTRICT_NAME": "นาตาขวัญ", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1195, "SUB_DISTRICT_CODE": "210109", "SUB_DISTRICT_NAME": "เนินพระ", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1196, "SUB_DISTRICT_CODE": "210110", "SUB_DISTRICT_NAME": "กะเฉด", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1197, "SUB_DISTRICT_CODE": "210111", "SUB_DISTRICT_NAME": "ทับมา", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1198, "SUB_DISTRICT_CODE": "210112", "SUB_DISTRICT_NAME": "น้ำคอก", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1199, "SUB_DISTRICT_CODE": "210113", "SUB_DISTRICT_NAME": "ห้วยโป่ง", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1200, "SUB_DISTRICT_CODE": "210114", "SUB_DISTRICT_NAME": "มาบตาพุด", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1201, "SUB_DISTRICT_CODE": "210115", "SUB_DISTRICT_NAME": "สำนักทอง", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1202, "SUB_DISTRICT_CODE": "210198", "SUB_DISTRICT_NAME": "*สำนักท้อน", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1203, "SUB_DISTRICT_CODE": "210199", "SUB_DISTRICT_NAME": "*พลา", "DISTRICT_ID": 151, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1204, "SUB_DISTRICT_CODE": "210201", "SUB_DISTRICT_NAME": "สำนักท้อน", "DISTRICT_ID": 152, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1205, "SUB_DISTRICT_CODE": "210202", "SUB_DISTRICT_NAME": "พลา", "DISTRICT_ID": 152, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1206, "SUB_DISTRICT_CODE": "210203", "SUB_DISTRICT_NAME": "บ้านฉาง", "DISTRICT_ID": 152, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1207, "SUB_DISTRICT_CODE": "210301", "SUB_DISTRICT_NAME": "ทางเกวียน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1208, "SUB_DISTRICT_CODE": "210302", "SUB_DISTRICT_NAME": "วังหว้า", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1209, "SUB_DISTRICT_CODE": "210303", "SUB_DISTRICT_NAME": "ชากโดน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1210, "SUB_DISTRICT_CODE": "210304", "SUB_DISTRICT_NAME": "เนินฆ้อ", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1211, "SUB_DISTRICT_CODE": "210305", "SUB_DISTRICT_NAME": "กร่ำ", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1212, "SUB_DISTRICT_CODE": "210306", "SUB_DISTRICT_NAME": "ชากพง", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1213, "SUB_DISTRICT_CODE": "210307", "SUB_DISTRICT_NAME": "กระแสบน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1214, "SUB_DISTRICT_CODE": "210308", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1215, "SUB_DISTRICT_CODE": "210309", "SUB_DISTRICT_NAME": "ทุ่งควายกิน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1216, "SUB_DISTRICT_CODE": "210310", "SUB_DISTRICT_NAME": "กองดิน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1217, "SUB_DISTRICT_CODE": "210311", "SUB_DISTRICT_NAME": "คลองปูน", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1218, "SUB_DISTRICT_CODE": "210312", "SUB_DISTRICT_NAME": "พังราด", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1219, "SUB_DISTRICT_CODE": "210313", "SUB_DISTRICT_NAME": "ปากน้ำกระแส", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1220, "SUB_DISTRICT_CODE": "210314", "SUB_DISTRICT_NAME": "*น้ำเป็น", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1221, "SUB_DISTRICT_CODE": "210315", "SUB_DISTRICT_NAME": "*ชำฆ้อ", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1222, "SUB_DISTRICT_CODE": "210316", "SUB_DISTRICT_NAME": "*ห้วยทับมอญ", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1223, "SUB_DISTRICT_CODE": "210317", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1224, "SUB_DISTRICT_CODE": "210318", "SUB_DISTRICT_NAME": "สองสลึง", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1225, "SUB_DISTRICT_CODE": "210319", "SUB_DISTRICT_NAME": "*เขาน้อย", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1226, "SUB_DISTRICT_CODE": "210398", "SUB_DISTRICT_NAME": "*ชุมแสง", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1227, "SUB_DISTRICT_CODE": "210399", "SUB_DISTRICT_NAME": "*วังจันทร์", "DISTRICT_ID": 153, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1228, "SUB_DISTRICT_CODE": "210401", "SUB_DISTRICT_NAME": "วังจันทร์", "DISTRICT_ID": 154, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1229, "SUB_DISTRICT_CODE": "210402", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 154, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1230, "SUB_DISTRICT_CODE": "210403", "SUB_DISTRICT_NAME": "ป่ายุบใน", "DISTRICT_ID": 154, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1231, "SUB_DISTRICT_CODE": "210404", "SUB_DISTRICT_NAME": "พลงตาเอี่ยม", "DISTRICT_ID": 154, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1232, "SUB_DISTRICT_CODE": "210501", "SUB_DISTRICT_NAME": "บ้านค่าย", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1233, "SUB_DISTRICT_CODE": "210502", "SUB_DISTRICT_NAME": "หนองละลอก", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1234, "SUB_DISTRICT_CODE": "210503", "SUB_DISTRICT_NAME": "หนองตะพาน", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1235, "SUB_DISTRICT_CODE": "210504", "SUB_DISTRICT_NAME": "ตาขัน", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1236, "SUB_DISTRICT_CODE": "210505", "SUB_DISTRICT_NAME": "บางบุตร", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1237, "SUB_DISTRICT_CODE": "210506", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1238, "SUB_DISTRICT_CODE": "210507", "SUB_DISTRICT_NAME": "ชากบก", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1239, "SUB_DISTRICT_CODE": "210508", "SUB_DISTRICT_NAME": "มาบข่า*", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1240, "SUB_DISTRICT_CODE": "210509", "SUB_DISTRICT_NAME": "พนานิคม*", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1241, "SUB_DISTRICT_CODE": "210510", "SUB_DISTRICT_NAME": "นิคมพัฒนา*", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1242, "SUB_DISTRICT_CODE": "210511", "SUB_DISTRICT_NAME": "มะขามคู่*", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1243, "SUB_DISTRICT_CODE": "210594", "SUB_DISTRICT_NAME": "*หนองไร่", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1244, "SUB_DISTRICT_CODE": "210595", "SUB_DISTRICT_NAME": "*มาบยางพร", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1245, "SUB_DISTRICT_CODE": "210596", "SUB_DISTRICT_NAME": "*แม่น้ำคู้", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1246, "SUB_DISTRICT_CODE": "210597", "SUB_DISTRICT_NAME": "*ละหาร", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1247, "SUB_DISTRICT_CODE": "210598", "SUB_DISTRICT_NAME": "*ตาสิทธิ์", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1248, "SUB_DISTRICT_CODE": "210599", "SUB_DISTRICT_NAME": "*ปลวกแดง", "DISTRICT_ID": 155, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1249, "SUB_DISTRICT_CODE": "210601", "SUB_DISTRICT_NAME": "ปลวกแดง", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1250, "SUB_DISTRICT_CODE": "210602", "SUB_DISTRICT_NAME": "ตาสิทธิ์", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1251, "SUB_DISTRICT_CODE": "210603", "SUB_DISTRICT_NAME": "ละหาร", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1252, "SUB_DISTRICT_CODE": "210604", "SUB_DISTRICT_NAME": "แม่น้ำคู้", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1253, "SUB_DISTRICT_CODE": "210605", "SUB_DISTRICT_NAME": "มาบยางพร", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1254, "SUB_DISTRICT_CODE": "210606", "SUB_DISTRICT_NAME": "หนองไร่", "DISTRICT_ID": 156, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1255, "SUB_DISTRICT_CODE": "210701", "SUB_DISTRICT_NAME": "น้ำเป็น", "DISTRICT_ID": 157, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1256, "SUB_DISTRICT_CODE": "210702", "SUB_DISTRICT_NAME": "ห้วยทับมอญ", "DISTRICT_ID": 157, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1257, "SUB_DISTRICT_CODE": "210703", "SUB_DISTRICT_NAME": "ชำฆ้อ", "DISTRICT_ID": 157, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1258, "SUB_DISTRICT_CODE": "210704", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 157, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1259, "SUB_DISTRICT_CODE": "210801", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 158, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1260, "SUB_DISTRICT_CODE": "210802", "SUB_DISTRICT_NAME": "มาบข่า", "DISTRICT_ID": 158, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1261, "SUB_DISTRICT_CODE": "210803", "SUB_DISTRICT_NAME": "พนานิคม", "DISTRICT_ID": 158, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1262, "SUB_DISTRICT_CODE": "210804", "SUB_DISTRICT_NAME": "มะขามคู่", "DISTRICT_ID": 158, "PROVINCE_ID": 12, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1263, "SUB_DISTRICT_CODE": "220101", "SUB_DISTRICT_NAME": "ตลาด", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1264, "SUB_DISTRICT_CODE": "220102", "SUB_DISTRICT_NAME": "วัดใหม่", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1265, "SUB_DISTRICT_CODE": "220103", "SUB_DISTRICT_NAME": "คลองนารายณ์", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1266, "SUB_DISTRICT_CODE": "220104", "SUB_DISTRICT_NAME": "เกาะขวาง", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1267, "SUB_DISTRICT_CODE": "220105", "SUB_DISTRICT_NAME": "คมบาง", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1268, "SUB_DISTRICT_CODE": "220106", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1269, "SUB_DISTRICT_CODE": "220107", "SUB_DISTRICT_NAME": "จันทนิมิต", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1270, "SUB_DISTRICT_CODE": "220108", "SUB_DISTRICT_NAME": "บางกะจะ", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1271, "SUB_DISTRICT_CODE": "220109", "SUB_DISTRICT_NAME": "แสลง", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1272, "SUB_DISTRICT_CODE": "220110", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1273, "SUB_DISTRICT_CODE": "220111", "SUB_DISTRICT_NAME": "พลับพลา", "DISTRICT_ID": 160, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1274, "SUB_DISTRICT_CODE": "220201", "SUB_DISTRICT_NAME": "ขลุง", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1275, "SUB_DISTRICT_CODE": "220202", "SUB_DISTRICT_NAME": "บ่อ", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1276, "SUB_DISTRICT_CODE": "220203", "SUB_DISTRICT_NAME": "เกวียนหัก", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1277, "SUB_DISTRICT_CODE": "220204", "SUB_DISTRICT_NAME": "ตะปอน", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1278, "SUB_DISTRICT_CODE": "220205", "SUB_DISTRICT_NAME": "บางชัน", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1279, "SUB_DISTRICT_CODE": "220206", "SUB_DISTRICT_NAME": "วันยาว", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1280, "SUB_DISTRICT_CODE": "220207", "SUB_DISTRICT_NAME": "ซึ้ง", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1281, "SUB_DISTRICT_CODE": "220208", "SUB_DISTRICT_NAME": "มาบไพ", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1282, "SUB_DISTRICT_CODE": "220209", "SUB_DISTRICT_NAME": "วังสรรพรส", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1283, "SUB_DISTRICT_CODE": "220210", "SUB_DISTRICT_NAME": "ตรอกนอง", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1284, "SUB_DISTRICT_CODE": "220211", "SUB_DISTRICT_NAME": "ตกพรม", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1285, "SUB_DISTRICT_CODE": "220212", "SUB_DISTRICT_NAME": "บ่อเวฬุ", "DISTRICT_ID": 161, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1286, "SUB_DISTRICT_CODE": "220301", "SUB_DISTRICT_NAME": "ท่าใหม่", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1287, "SUB_DISTRICT_CODE": "220302", "SUB_DISTRICT_NAME": "ยายร้า", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1288, "SUB_DISTRICT_CODE": "220303", "SUB_DISTRICT_NAME": "สีพยา", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1289, "SUB_DISTRICT_CODE": "220304", "SUB_DISTRICT_NAME": "บ่อพุ", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1290, "SUB_DISTRICT_CODE": "220305", "SUB_DISTRICT_NAME": "พลอยแหวน", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1291, "SUB_DISTRICT_CODE": "220306", "SUB_DISTRICT_NAME": "เขาวัว", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1292, "SUB_DISTRICT_CODE": "220307", "SUB_DISTRICT_NAME": "เขาบายศรี", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1293, "SUB_DISTRICT_CODE": "220308", "SUB_DISTRICT_NAME": "สองพี่น้อง", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1294, "SUB_DISTRICT_CODE": "220309", "SUB_DISTRICT_NAME": "ทุ่งเบญจา", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1295, "SUB_DISTRICT_CODE": "220310", "SUB_DISTRICT_NAME": "*วังโตนด", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1296, "SUB_DISTRICT_CODE": "220311", "SUB_DISTRICT_NAME": "รำพัน", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1297, "SUB_DISTRICT_CODE": "220312", "SUB_DISTRICT_NAME": "โขมง", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1298, "SUB_DISTRICT_CODE": "220313", "SUB_DISTRICT_NAME": "ตะกาดเง้า", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1299, "SUB_DISTRICT_CODE": "220314", "SUB_DISTRICT_NAME": "คลองขุด", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1300, "SUB_DISTRICT_CODE": "220315", "SUB_DISTRICT_NAME": "*กระแจะ", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1301, "SUB_DISTRICT_CODE": "220316", "SUB_DISTRICT_NAME": "*สนามไชย", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1302, "SUB_DISTRICT_CODE": "220317", "SUB_DISTRICT_NAME": "*ช้างข้าม", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1303, "SUB_DISTRICT_CODE": "220318", "SUB_DISTRICT_NAME": "*นายายอาม", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1304, "SUB_DISTRICT_CODE": "220319", "SUB_DISTRICT_NAME": "*แก่งหางแมว", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1305, "SUB_DISTRICT_CODE": "220320", "SUB_DISTRICT_NAME": "*สามพี่น้อง", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1306, "SUB_DISTRICT_CODE": "220321", "SUB_DISTRICT_NAME": "*เขาวงกต", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1307, "SUB_DISTRICT_CODE": "220322", "SUB_DISTRICT_NAME": "*พวา", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1308, "SUB_DISTRICT_CODE": "220323", "SUB_DISTRICT_NAME": "*ขุนซ่อง", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1309, "SUB_DISTRICT_CODE": "220324", "SUB_DISTRICT_NAME": "เขาแก้ว", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1310, "SUB_DISTRICT_CODE": "220394", "SUB_DISTRICT_NAME": "*กระแจะ", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1311, "SUB_DISTRICT_CODE": "220395", "SUB_DISTRICT_NAME": "*สนามไช", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1312, "SUB_DISTRICT_CODE": "220396", "SUB_DISTRICT_NAME": "*ช้างข้าม", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1313, "SUB_DISTRICT_CODE": "220397", "SUB_DISTRICT_NAME": "*วังโตนด", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1314, "SUB_DISTRICT_CODE": "220398", "SUB_DISTRICT_NAME": "*นายายอาม", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1315, "SUB_DISTRICT_CODE": "220399", "SUB_DISTRICT_NAME": "*แก่งหางแมว", "DISTRICT_ID": 162, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1316, "SUB_DISTRICT_CODE": "220401", "SUB_DISTRICT_NAME": "ทับไทร", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1317, "SUB_DISTRICT_CODE": "220402", "SUB_DISTRICT_NAME": "โป่งน้ำร้อน", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1318, "SUB_DISTRICT_CODE": "220403", "SUB_DISTRICT_NAME": "*ทรายขาว", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1319, "SUB_DISTRICT_CODE": "220404", "SUB_DISTRICT_NAME": "หนองตาคง", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1320, "SUB_DISTRICT_CODE": "220405", "SUB_DISTRICT_NAME": "*ปะตง", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1321, "SUB_DISTRICT_CODE": "220406", "SUB_DISTRICT_NAME": "*ทุ่งขนาน", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1322, "SUB_DISTRICT_CODE": "220407", "SUB_DISTRICT_NAME": "*สะตอน", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1323, "SUB_DISTRICT_CODE": "220408", "SUB_DISTRICT_NAME": "*ทับช้าง", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1324, "SUB_DISTRICT_CODE": "220409", "SUB_DISTRICT_NAME": "เทพนิมิต", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1325, "SUB_DISTRICT_CODE": "220410", "SUB_DISTRICT_NAME": "คลองใหญ่", "DISTRICT_ID": 163, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1326, "SUB_DISTRICT_CODE": "220501", "SUB_DISTRICT_NAME": "มะขาม", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1327, "SUB_DISTRICT_CODE": "220502", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1328, "SUB_DISTRICT_CODE": "220503", "SUB_DISTRICT_NAME": "ปัถวี", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1329, "SUB_DISTRICT_CODE": "220504", "SUB_DISTRICT_NAME": "วังแซ้ม", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1330, "SUB_DISTRICT_CODE": "220505", "SUB_DISTRICT_NAME": "*พลวง", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1331, "SUB_DISTRICT_CODE": "220506", "SUB_DISTRICT_NAME": "ฉมัน", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1332, "SUB_DISTRICT_CODE": "220507", "SUB_DISTRICT_NAME": "*ตะเคียนทอง", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1333, "SUB_DISTRICT_CODE": "220508", "SUB_DISTRICT_NAME": "อ่างคีรี", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1334, "SUB_DISTRICT_CODE": "220509", "SUB_DISTRICT_NAME": "*คลองพลู", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1335, "SUB_DISTRICT_CODE": "220510", "SUB_DISTRICT_NAME": "*ซากไทย", "DISTRICT_ID": 164, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1336, "SUB_DISTRICT_CODE": "220601", "SUB_DISTRICT_NAME": "ปากน้ำแหลมสิงห์", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1337, "SUB_DISTRICT_CODE": "220602", "SUB_DISTRICT_NAME": "เกาะเปริด", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1338, "SUB_DISTRICT_CODE": "220603", "SUB_DISTRICT_NAME": "หนองชิ่ม", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1339, "SUB_DISTRICT_CODE": "220604", "SUB_DISTRICT_NAME": "พลิ้ว", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1340, "SUB_DISTRICT_CODE": "220605", "SUB_DISTRICT_NAME": "คลองน้ำเค็ม", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1341, "SUB_DISTRICT_CODE": "220606", "SUB_DISTRICT_NAME": "บางสระเก้า", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1342, "SUB_DISTRICT_CODE": "220607", "SUB_DISTRICT_NAME": "บางกะไชย", "DISTRICT_ID": 165, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1343, "SUB_DISTRICT_CODE": "220701", "SUB_DISTRICT_NAME": "ปะตง", "DISTRICT_ID": 166, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1344, "SUB_DISTRICT_CODE": "220702", "SUB_DISTRICT_NAME": "ทุ่งขนาน", "DISTRICT_ID": 166, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1345, "SUB_DISTRICT_CODE": "220703", "SUB_DISTRICT_NAME": "ทับช้าง", "DISTRICT_ID": 166, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1346, "SUB_DISTRICT_CODE": "220704", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 166, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1347, "SUB_DISTRICT_CODE": "220705", "SUB_DISTRICT_NAME": "สะตอน", "DISTRICT_ID": 166, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1348, "SUB_DISTRICT_CODE": "220801", "SUB_DISTRICT_NAME": "แก่งหางแมว", "DISTRICT_ID": 167, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1349, "SUB_DISTRICT_CODE": "220802", "SUB_DISTRICT_NAME": "ขุนซ่อง", "DISTRICT_ID": 167, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1350, "SUB_DISTRICT_CODE": "220803", "SUB_DISTRICT_NAME": "สามพี่น้อง", "DISTRICT_ID": 167, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1351, "SUB_DISTRICT_CODE": "220804", "SUB_DISTRICT_NAME": "พวา", "DISTRICT_ID": 167, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1352, "SUB_DISTRICT_CODE": "220805", "SUB_DISTRICT_NAME": "เขาวงกต", "DISTRICT_ID": 167, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1353, "SUB_DISTRICT_CODE": "220901", "SUB_DISTRICT_NAME": "นายายอาม", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1354, "SUB_DISTRICT_CODE": "220902", "SUB_DISTRICT_NAME": "วังโตนด", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1355, "SUB_DISTRICT_CODE": "220903", "SUB_DISTRICT_NAME": "กระแจะ", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1356, "SUB_DISTRICT_CODE": "220904", "SUB_DISTRICT_NAME": "สนามไชย", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1357, "SUB_DISTRICT_CODE": "220905", "SUB_DISTRICT_NAME": "ช้างข้าม", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1358, "SUB_DISTRICT_CODE": "220906", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 168, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1359, "SUB_DISTRICT_CODE": "221001", "SUB_DISTRICT_NAME": "ชากไทย", "DISTRICT_ID": 169, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1360, "SUB_DISTRICT_CODE": "221002", "SUB_DISTRICT_NAME": "พลวง", "DISTRICT_ID": 169, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1361, "SUB_DISTRICT_CODE": "221003", "SUB_DISTRICT_NAME": "ตะเคียนทอง", "DISTRICT_ID": 169, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1362, "SUB_DISTRICT_CODE": "221004", "SUB_DISTRICT_NAME": "คลองพลู", "DISTRICT_ID": 169, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1363, "SUB_DISTRICT_CODE": "221005", "SUB_DISTRICT_NAME": "จันทเขลม", "DISTRICT_ID": 169, "PROVINCE_ID": 13, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1364, "SUB_DISTRICT_CODE": "230101", "SUB_DISTRICT_NAME": "บางพระ", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1365, "SUB_DISTRICT_CODE": "230102", "SUB_DISTRICT_NAME": "หนองเสม็ด", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1366, "SUB_DISTRICT_CODE": "230103", "SUB_DISTRICT_NAME": "หนองโสน", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1367, "SUB_DISTRICT_CODE": "230104", "SUB_DISTRICT_NAME": "หนองคันทรง", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1368, "SUB_DISTRICT_CODE": "230105", "SUB_DISTRICT_NAME": "ห้วงน้ำขาว", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1369, "SUB_DISTRICT_CODE": "230106", "SUB_DISTRICT_NAME": "อ่าวใหญ่", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1370, "SUB_DISTRICT_CODE": "230107", "SUB_DISTRICT_NAME": "วังกระแจะ", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1371, "SUB_DISTRICT_CODE": "230108", "SUB_DISTRICT_NAME": "ห้วยแร้ง", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1372, "SUB_DISTRICT_CODE": "230109", "SUB_DISTRICT_NAME": "เนินทราย", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1373, "SUB_DISTRICT_CODE": "230110", "SUB_DISTRICT_NAME": "ท่าพริก", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1374, "SUB_DISTRICT_CODE": "230111", "SUB_DISTRICT_NAME": "ท่ากุ่ม", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1375, "SUB_DISTRICT_CODE": "230112", "SUB_DISTRICT_NAME": "ตะกาง", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1376, "SUB_DISTRICT_CODE": "230113", "SUB_DISTRICT_NAME": "ชำราก", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1377, "SUB_DISTRICT_CODE": "230114", "SUB_DISTRICT_NAME": "แหลมกลัด", "DISTRICT_ID": 171, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1378, "SUB_DISTRICT_CODE": "230201", "SUB_DISTRICT_NAME": "คลองใหญ่", "DISTRICT_ID": 172, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1379, "SUB_DISTRICT_CODE": "230202", "SUB_DISTRICT_NAME": "ไม้รูด", "DISTRICT_ID": 172, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1380, "SUB_DISTRICT_CODE": "230203", "SUB_DISTRICT_NAME": "หาดเล็ก", "DISTRICT_ID": 172, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1381, "SUB_DISTRICT_CODE": "230301", "SUB_DISTRICT_NAME": "เขาสมิง", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1382, "SUB_DISTRICT_CODE": "230302", "SUB_DISTRICT_NAME": "แสนตุ้ง", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1383, "SUB_DISTRICT_CODE": "230303", "SUB_DISTRICT_NAME": "วังตะเคียน", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1384, "SUB_DISTRICT_CODE": "230304", "SUB_DISTRICT_NAME": "ท่าโสม", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1385, "SUB_DISTRICT_CODE": "230305", "SUB_DISTRICT_NAME": "สะตอ", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1386, "SUB_DISTRICT_CODE": "230306", "SUB_DISTRICT_NAME": "ประณีต", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1387, "SUB_DISTRICT_CODE": "230307", "SUB_DISTRICT_NAME": "เทพนิมิต", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1388, "SUB_DISTRICT_CODE": "230308", "SUB_DISTRICT_NAME": "ทุ่งนนทรี", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1389, "SUB_DISTRICT_CODE": "230395", "SUB_DISTRICT_NAME": "*บ่อไร่", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1390, "SUB_DISTRICT_CODE": "230396", "SUB_DISTRICT_NAME": "*ด่านชุมพล", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1391, "SUB_DISTRICT_CODE": "230397", "SUB_DISTRICT_NAME": "*หนองบอน", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1392, "SUB_DISTRICT_CODE": "230398", "SUB_DISTRICT_NAME": "*ช้างทูน", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1393, "SUB_DISTRICT_CODE": "230399", "SUB_DISTRICT_NAME": "*บ่อพลอย", "DISTRICT_ID": 173, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1394, "SUB_DISTRICT_CODE": "230401", "SUB_DISTRICT_NAME": "บ่อพลอย", "DISTRICT_ID": 174, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1395, "SUB_DISTRICT_CODE": "230402", "SUB_DISTRICT_NAME": "ช้างทูน", "DISTRICT_ID": 174, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1396, "SUB_DISTRICT_CODE": "230403", "SUB_DISTRICT_NAME": "ด่านชุมพล", "DISTRICT_ID": 174, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1397, "SUB_DISTRICT_CODE": "230404", "SUB_DISTRICT_NAME": "หนองบอน", "DISTRICT_ID": 174, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1398, "SUB_DISTRICT_CODE": "230405", "SUB_DISTRICT_NAME": "นนทรีย์", "DISTRICT_ID": 174, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1399, "SUB_DISTRICT_CODE": "230501", "SUB_DISTRICT_NAME": "แหลมงอบ", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1400, "SUB_DISTRICT_CODE": "230502", "SUB_DISTRICT_NAME": "น้ำเชี่ยว", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1401, "SUB_DISTRICT_CODE": "230503", "SUB_DISTRICT_NAME": "บางปิด", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1402, "SUB_DISTRICT_CODE": "230504", "SUB_DISTRICT_NAME": "*เกาะช้าง", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1403, "SUB_DISTRICT_CODE": "230505", "SUB_DISTRICT_NAME": "*เกาะหมาก", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1404, "SUB_DISTRICT_CODE": "230506", "SUB_DISTRICT_NAME": "*เกาะกูด", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1405, "SUB_DISTRICT_CODE": "230507", "SUB_DISTRICT_NAME": "คลองใหญ่", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1406, "SUB_DISTRICT_CODE": "230508", "SUB_DISTRICT_NAME": "*เกาะช้างใต้", "DISTRICT_ID": 175, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1407, "SUB_DISTRICT_CODE": "230601", "SUB_DISTRICT_NAME": "เกาะหมาก", "DISTRICT_ID": 176, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1408, "SUB_DISTRICT_CODE": "230602", "SUB_DISTRICT_NAME": "เกาะกูด", "DISTRICT_ID": 176, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1409, "SUB_DISTRICT_CODE": "230701", "SUB_DISTRICT_NAME": "เกาะช้าง", "DISTRICT_ID": 177, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1410, "SUB_DISTRICT_CODE": "230702", "SUB_DISTRICT_NAME": "เกาะช้างใต้", "DISTRICT_ID": 177, "PROVINCE_ID": 14, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1411, "SUB_DISTRICT_CODE": "240101", "SUB_DISTRICT_NAME": "หน้าเมือง", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1412, "SUB_DISTRICT_CODE": "240102", "SUB_DISTRICT_NAME": "ท่าไข่", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1413, "SUB_DISTRICT_CODE": "240103", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1414, "SUB_DISTRICT_CODE": "240104", "SUB_DISTRICT_NAME": "คลองนา", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1415, "SUB_DISTRICT_CODE": "240105", "SUB_DISTRICT_NAME": "บางตีนเป็ด", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1416, "SUB_DISTRICT_CODE": "240106", "SUB_DISTRICT_NAME": "บางไผ่", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1417, "SUB_DISTRICT_CODE": "240107", "SUB_DISTRICT_NAME": "คลองจุกกระเฌอ", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1418, "SUB_DISTRICT_CODE": "240108", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1419, "SUB_DISTRICT_CODE": "240109", "SUB_DISTRICT_NAME": "บางขวัญ", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1420, "SUB_DISTRICT_CODE": "240110", "SUB_DISTRICT_NAME": "คลองนครเนื่องเขต", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1421, "SUB_DISTRICT_CODE": "240111", "SUB_DISTRICT_NAME": "วังตะเคียน", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1422, "SUB_DISTRICT_CODE": "240112", "SUB_DISTRICT_NAME": "โสธร", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1423, "SUB_DISTRICT_CODE": "240113", "SUB_DISTRICT_NAME": "บางพระ", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1424, "SUB_DISTRICT_CODE": "240114", "SUB_DISTRICT_NAME": "บางกะไห", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1425, "SUB_DISTRICT_CODE": "240115", "SUB_DISTRICT_NAME": "หนามแดง", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1426, "SUB_DISTRICT_CODE": "240116", "SUB_DISTRICT_NAME": "คลองเปรง", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1427, "SUB_DISTRICT_CODE": "240117", "SUB_DISTRICT_NAME": "คลองอุดมชลจร", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1428, "SUB_DISTRICT_CODE": "240118", "SUB_DISTRICT_NAME": "คลองหลวงแพ่ง", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1429, "SUB_DISTRICT_CODE": "240119", "SUB_DISTRICT_NAME": "บางเตย", "DISTRICT_ID": 178, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1430, "SUB_DISTRICT_CODE": "240201", "SUB_DISTRICT_NAME": "บางคล้า", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1431, "SUB_DISTRICT_CODE": "240202", "SUB_DISTRICT_NAME": "*ก้อนแก้ว", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1432, "SUB_DISTRICT_CODE": "240203", "SUB_DISTRICT_NAME": "*คลองเขื่อน", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1433, "SUB_DISTRICT_CODE": "240204", "SUB_DISTRICT_NAME": "บางสวน", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1434, "SUB_DISTRICT_CODE": "240205", "SUB_DISTRICT_NAME": "*บางเล่า", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1435, "SUB_DISTRICT_CODE": "240206", "SUB_DISTRICT_NAME": "*บางโรง", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1436, "SUB_DISTRICT_CODE": "240207", "SUB_DISTRICT_NAME": "*บางตลาด", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1437, "SUB_DISTRICT_CODE": "240208", "SUB_DISTRICT_NAME": "บางกระเจ็ด", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1438, "SUB_DISTRICT_CODE": "240209", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1439, "SUB_DISTRICT_CODE": "240210", "SUB_DISTRICT_NAME": "ท่าทองหลาง", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1440, "SUB_DISTRICT_CODE": "240211", "SUB_DISTRICT_NAME": "สาวชะโงก", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1441, "SUB_DISTRICT_CODE": "240212", "SUB_DISTRICT_NAME": "เสม็ดเหนือ", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1442, "SUB_DISTRICT_CODE": "240213", "SUB_DISTRICT_NAME": "เสม็ดใต้", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1443, "SUB_DISTRICT_CODE": "240214", "SUB_DISTRICT_NAME": "หัวไทร", "DISTRICT_ID": 179, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1444, "SUB_DISTRICT_CODE": "240301", "SUB_DISTRICT_NAME": "บางน้ำเปรี้ยว", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1445, "SUB_DISTRICT_CODE": "240302", "SUB_DISTRICT_NAME": "บางขนาก", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1446, "SUB_DISTRICT_CODE": "240303", "SUB_DISTRICT_NAME": "สิงโตทอง", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1447, "SUB_DISTRICT_CODE": "240304", "SUB_DISTRICT_NAME": "หมอนทอง", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1448, "SUB_DISTRICT_CODE": "240305", "SUB_DISTRICT_NAME": "บึงน้ำรักษ์", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1449, "SUB_DISTRICT_CODE": "240306", "SUB_DISTRICT_NAME": "ดอนเกาะกา", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1450, "SUB_DISTRICT_CODE": "240307", "SUB_DISTRICT_NAME": "โยธะกา", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1451, "SUB_DISTRICT_CODE": "240308", "SUB_DISTRICT_NAME": "ดอนฉิมพลี", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1452, "SUB_DISTRICT_CODE": "240309", "SUB_DISTRICT_NAME": "ศาลาแดง", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1453, "SUB_DISTRICT_CODE": "240310", "SUB_DISTRICT_NAME": "โพรงอากาศ", "DISTRICT_ID": 180, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1454, "SUB_DISTRICT_CODE": "240401", "SUB_DISTRICT_NAME": "บางปะกง", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1455, "SUB_DISTRICT_CODE": "240402", "SUB_DISTRICT_NAME": "ท่าสะอ้าน", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1456, "SUB_DISTRICT_CODE": "240403", "SUB_DISTRICT_NAME": "บางวัว", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1457, "SUB_DISTRICT_CODE": "240404", "SUB_DISTRICT_NAME": "บางสมัคร", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1458, "SUB_DISTRICT_CODE": "240405", "SUB_DISTRICT_NAME": "บางผึ้ง", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1459, "SUB_DISTRICT_CODE": "240406", "SUB_DISTRICT_NAME": "บางเกลือ", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1460, "SUB_DISTRICT_CODE": "240407", "SUB_DISTRICT_NAME": "สองคลอง", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1461, "SUB_DISTRICT_CODE": "240408", "SUB_DISTRICT_NAME": "หนองจอก", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1462, "SUB_DISTRICT_CODE": "240409", "SUB_DISTRICT_NAME": "พิมพา", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1463, "SUB_DISTRICT_CODE": "240410", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1464, "SUB_DISTRICT_CODE": "240411", "SUB_DISTRICT_NAME": "หอมศีล", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1465, "SUB_DISTRICT_CODE": "240412", "SUB_DISTRICT_NAME": "เขาดิน", "DISTRICT_ID": 181, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1466, "SUB_DISTRICT_CODE": "240501", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1467, "SUB_DISTRICT_CODE": "240502", "SUB_DISTRICT_NAME": "เกาะไร่", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1468, "SUB_DISTRICT_CODE": "240503", "SUB_DISTRICT_NAME": "คลองขุด", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1469, "SUB_DISTRICT_CODE": "240504", "SUB_DISTRICT_NAME": "คลองบ้านโพธิ์", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1470, "SUB_DISTRICT_CODE": "240505", "SUB_DISTRICT_NAME": "คลองประเวศ", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1471, "SUB_DISTRICT_CODE": "240506", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1472, "SUB_DISTRICT_CODE": "240507", "SUB_DISTRICT_NAME": "เทพราช", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1473, "SUB_DISTRICT_CODE": "240508", "SUB_DISTRICT_NAME": "ท่าพลับ", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1474, "SUB_DISTRICT_CODE": "240509", "SUB_DISTRICT_NAME": "หนองตีนนก", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1475, "SUB_DISTRICT_CODE": "240510", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1476, "SUB_DISTRICT_CODE": "240511", "SUB_DISTRICT_NAME": "บางซ่อน", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1477, "SUB_DISTRICT_CODE": "240512", "SUB_DISTRICT_NAME": "บางกรูด", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1478, "SUB_DISTRICT_CODE": "240513", "SUB_DISTRICT_NAME": "แหลมประดู่", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1479, "SUB_DISTRICT_CODE": "240514", "SUB_DISTRICT_NAME": "ลาดขวาง", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1480, "SUB_DISTRICT_CODE": "240515", "SUB_DISTRICT_NAME": "สนามจันทร์", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1481, "SUB_DISTRICT_CODE": "240516", "SUB_DISTRICT_NAME": "แสนภูดาษ", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1482, "SUB_DISTRICT_CODE": "240517", "SUB_DISTRICT_NAME": "สิบเอ็ดศอก", "DISTRICT_ID": 182, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1483, "SUB_DISTRICT_CODE": "240601", "SUB_DISTRICT_NAME": "เกาะขนุน", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1484, "SUB_DISTRICT_CODE": "240602", "SUB_DISTRICT_NAME": "บ้านซ่อง", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1485, "SUB_DISTRICT_CODE": "240603", "SUB_DISTRICT_NAME": "พนมสารคาม", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1486, "SUB_DISTRICT_CODE": "240604", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1487, "SUB_DISTRICT_CODE": "240605", "SUB_DISTRICT_NAME": "หนองยาว", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1488, "SUB_DISTRICT_CODE": "240606", "SUB_DISTRICT_NAME": "ท่าถ่าน", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1489, "SUB_DISTRICT_CODE": "240607", "SUB_DISTRICT_NAME": "หนองแหน", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1490, "SUB_DISTRICT_CODE": "240608", "SUB_DISTRICT_NAME": "เขาหินซ้อน", "DISTRICT_ID": 183, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1491, "SUB_DISTRICT_CODE": "240701", "SUB_DISTRICT_NAME": "บางคา", "DISTRICT_ID": 184, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1492, "SUB_DISTRICT_CODE": "240702", "SUB_DISTRICT_NAME": "เมืองใหม่", "DISTRICT_ID": 184, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1493, "SUB_DISTRICT_CODE": "240703", "SUB_DISTRICT_NAME": "ดงน้อย", "DISTRICT_ID": 184, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1494, "SUB_DISTRICT_CODE": "240801", "SUB_DISTRICT_NAME": "คู้ยายหมี", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1495, "SUB_DISTRICT_CODE": "240802", "SUB_DISTRICT_NAME": "ท่ากระดาน", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1496, "SUB_DISTRICT_CODE": "240803", "SUB_DISTRICT_NAME": "ทุ่งพระยา", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1497, "SUB_DISTRICT_CODE": "240804", "SUB_DISTRICT_NAME": "*ท่าตะเกียบ", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1498, "SUB_DISTRICT_CODE": "240805", "SUB_DISTRICT_NAME": "ลาดกระทิง", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1499, "SUB_DISTRICT_CODE": "240806", "SUB_DISTRICT_NAME": "*คลองตะเกรา", "DISTRICT_ID": 185, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1500, "SUB_DISTRICT_CODE": "240901", "SUB_DISTRICT_NAME": "แปลงยาว", "DISTRICT_ID": 186, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1501, "SUB_DISTRICT_CODE": "240902", "SUB_DISTRICT_NAME": "วังเย็น", "DISTRICT_ID": 186, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1502, "SUB_DISTRICT_CODE": "240903", "SUB_DISTRICT_NAME": "หัวสำโรง", "DISTRICT_ID": 186, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1503, "SUB_DISTRICT_CODE": "240904", "SUB_DISTRICT_NAME": "หนองไม้แก่น", "DISTRICT_ID": 186, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1504, "SUB_DISTRICT_CODE": "241001", "SUB_DISTRICT_NAME": "ท่าตะเกียบ", "DISTRICT_ID": 187, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1505, "SUB_DISTRICT_CODE": "241002", "SUB_DISTRICT_NAME": "คลองตะเกรา", "DISTRICT_ID": 187, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1506, "SUB_DISTRICT_CODE": "241101", "SUB_DISTRICT_NAME": "ก้อนแก้ว", "DISTRICT_ID": 188, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1507, "SUB_DISTRICT_CODE": "241102", "SUB_DISTRICT_NAME": "คลองเขื่อน", "DISTRICT_ID": 188, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1508, "SUB_DISTRICT_CODE": "241103", "SUB_DISTRICT_NAME": "บางเล่า", "DISTRICT_ID": 188, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1509, "SUB_DISTRICT_CODE": "241104", "SUB_DISTRICT_NAME": "บางโรง", "DISTRICT_ID": 188, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1510, "SUB_DISTRICT_CODE": "241105", "SUB_DISTRICT_NAME": "บางตลาด", "DISTRICT_ID": 188, "PROVINCE_ID": 15, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1511, "SUB_DISTRICT_CODE": "250101", "SUB_DISTRICT_NAME": "หน้าเมือง", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1512, "SUB_DISTRICT_CODE": "250102", "SUB_DISTRICT_NAME": "รอบเมือง", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1513, "SUB_DISTRICT_CODE": "250103", "SUB_DISTRICT_NAME": "วัดโบสถ์", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1514, "SUB_DISTRICT_CODE": "250104", "SUB_DISTRICT_NAME": "บางเดชะ", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1515, "SUB_DISTRICT_CODE": "250105", "SUB_DISTRICT_NAME": "ท่างาม", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1516, "SUB_DISTRICT_CODE": "250106", "SUB_DISTRICT_NAME": "บางบริบูรณ์", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1517, "SUB_DISTRICT_CODE": "250107", "SUB_DISTRICT_NAME": "ดงพระราม", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1518, "SUB_DISTRICT_CODE": "250108", "SUB_DISTRICT_NAME": "บ้านพระ", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1519, "SUB_DISTRICT_CODE": "250109", "SUB_DISTRICT_NAME": "โคกไม้ลาย", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1520, "SUB_DISTRICT_CODE": "250110", "SUB_DISTRICT_NAME": "ไม้เค็ด", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1521, "SUB_DISTRICT_CODE": "250111", "SUB_DISTRICT_NAME": "ดงขี้เหล็ก", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1522, "SUB_DISTRICT_CODE": "250112", "SUB_DISTRICT_NAME": "เนินหอม", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1523, "SUB_DISTRICT_CODE": "250113", "SUB_DISTRICT_NAME": "โนนห้อม", "DISTRICT_ID": 189, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1524, "SUB_DISTRICT_CODE": "250201", "SUB_DISTRICT_NAME": "กบินทร์", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1525, "SUB_DISTRICT_CODE": "250202", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1526, "SUB_DISTRICT_CODE": "250203", "SUB_DISTRICT_NAME": "วังดาล", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1527, "SUB_DISTRICT_CODE": "250204", "SUB_DISTRICT_NAME": "นนทรี", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1528, "SUB_DISTRICT_CODE": "250205", "SUB_DISTRICT_NAME": "ย่านรี", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1529, "SUB_DISTRICT_CODE": "250206", "SUB_DISTRICT_NAME": "วังตะเคียน", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1530, "SUB_DISTRICT_CODE": "250207", "SUB_DISTRICT_NAME": "หาดนางแก้ว", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1531, "SUB_DISTRICT_CODE": "250208", "SUB_DISTRICT_NAME": "ลาดตะเคียน", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1532, "SUB_DISTRICT_CODE": "250209", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1533, "SUB_DISTRICT_CODE": "250210", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1534, "SUB_DISTRICT_CODE": "250211", "SUB_DISTRICT_NAME": "หนองกี่", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1535, "SUB_DISTRICT_CODE": "250212", "SUB_DISTRICT_NAME": "นาแขม", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1536, "SUB_DISTRICT_CODE": "250213", "SUB_DISTRICT_NAME": "เขาไม้แก้ว", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1537, "SUB_DISTRICT_CODE": "250214", "SUB_DISTRICT_NAME": "วังท่าช้าง", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1538, "SUB_DISTRICT_CODE": "250296", "SUB_DISTRICT_NAME": "*สะพานหิน", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1539, "SUB_DISTRICT_CODE": "250297", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1540, "SUB_DISTRICT_CODE": "250298", "SUB_DISTRICT_NAME": "*ลำพันตา", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1541, "SUB_DISTRICT_CODE": "250299", "SUB_DISTRICT_NAME": "*ทุ่งโพธิ์", "DISTRICT_ID": 190, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1542, "SUB_DISTRICT_CODE": "250301", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1543, "SUB_DISTRICT_CODE": "250302", "SUB_DISTRICT_NAME": "สำพันตา", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1544, "SUB_DISTRICT_CODE": "250303", "SUB_DISTRICT_NAME": "สะพานหิน", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1545, "SUB_DISTRICT_CODE": "250304", "SUB_DISTRICT_NAME": "ทุ่งโพธิ์", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1546, "SUB_DISTRICT_CODE": "250305", "SUB_DISTRICT_NAME": "แก่งดินสอ", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1547, "SUB_DISTRICT_CODE": "250306", "SUB_DISTRICT_NAME": "บุพราหมณ์", "DISTRICT_ID": 191, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1548, "SUB_DISTRICT_CODE": "250401", "SUB_DISTRICT_NAME": "*สระแก้ว", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1549, "SUB_DISTRICT_CODE": "250402", "SUB_DISTRICT_NAME": "*บ้านแก้ง", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1550, "SUB_DISTRICT_CODE": "250403", "SUB_DISTRICT_NAME": "*ศาลาลำดวน", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1551, "SUB_DISTRICT_CODE": "250404", "SUB_DISTRICT_NAME": "*โคกปี่ฆ้อง", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1552, "SUB_DISTRICT_CODE": "250405", "SUB_DISTRICT_NAME": "*ท่าแยก", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1553, "SUB_DISTRICT_CODE": "250406", "SUB_DISTRICT_NAME": "*ท่าเกษม", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1554, "SUB_DISTRICT_CODE": "250407", "SUB_DISTRICT_NAME": "*เขาฉกรรจ์", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1555, "SUB_DISTRICT_CODE": "250408", "SUB_DISTRICT_NAME": "*สระขวัญ", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1556, "SUB_DISTRICT_CODE": "250409", "SUB_DISTRICT_NAME": "*หนองหว้า", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1557, "SUB_DISTRICT_CODE": "250410", "SUB_DISTRICT_NAME": "*พระเพลิง", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1558, "SUB_DISTRICT_CODE": "250411", "SUB_DISTRICT_NAME": "*หนองบอน", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1559, "SUB_DISTRICT_CODE": "250412", "SUB_DISTRICT_NAME": "*เขาสามสิบ", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1560, "SUB_DISTRICT_CODE": "250497", "SUB_DISTRICT_NAME": "*ตาหลังใน", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1561, "SUB_DISTRICT_CODE": "250498", "SUB_DISTRICT_NAME": "*วังสมบูรณ์", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1562, "SUB_DISTRICT_CODE": "250499", "SUB_DISTRICT_NAME": "*วังน้ำเย็น", "DISTRICT_ID": 192, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1563, "SUB_DISTRICT_CODE": "250501", "SUB_DISTRICT_NAME": "*วังน้ำเย็น", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1564, "SUB_DISTRICT_CODE": "250502", "SUB_DISTRICT_NAME": "*วังสมบูรณ์", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1565, "SUB_DISTRICT_CODE": "250503", "SUB_DISTRICT_NAME": "*ตาหลังใน", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1566, "SUB_DISTRICT_CODE": "250504", "SUB_DISTRICT_NAME": "*วังใหม่", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1567, "SUB_DISTRICT_CODE": "250505", "SUB_DISTRICT_NAME": "*คลองหินปูน", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1568, "SUB_DISTRICT_CODE": "250506", "SUB_DISTRICT_NAME": "*ทุ่งมหาเจริญ", "DISTRICT_ID": 193, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1569, "SUB_DISTRICT_CODE": "250601", "SUB_DISTRICT_NAME": "บ้านสร้าง", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1570, "SUB_DISTRICT_CODE": "250602", "SUB_DISTRICT_NAME": "บางกระเบา", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1571, "SUB_DISTRICT_CODE": "250603", "SUB_DISTRICT_NAME": "บางเตย", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1572, "SUB_DISTRICT_CODE": "250604", "SUB_DISTRICT_NAME": "บางยาง", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1573, "SUB_DISTRICT_CODE": "250605", "SUB_DISTRICT_NAME": "บางแตน", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1574, "SUB_DISTRICT_CODE": "250606", "SUB_DISTRICT_NAME": "บางพลวง", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1575, "SUB_DISTRICT_CODE": "250607", "SUB_DISTRICT_NAME": "บางปลาร้า", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1576, "SUB_DISTRICT_CODE": "250608", "SUB_DISTRICT_NAME": "บางขาม", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1577, "SUB_DISTRICT_CODE": "250609", "SUB_DISTRICT_NAME": "กระทุ่มแพ้ว", "DISTRICT_ID": 194, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1578, "SUB_DISTRICT_CODE": "250701", "SUB_DISTRICT_NAME": "ประจันตคาม", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1579, "SUB_DISTRICT_CODE": "250702", "SUB_DISTRICT_NAME": "เกาะลอย", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1580, "SUB_DISTRICT_CODE": "250703", "SUB_DISTRICT_NAME": "บ้านหอย", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1581, "SUB_DISTRICT_CODE": "250704", "SUB_DISTRICT_NAME": "หนองแสง", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1582, "SUB_DISTRICT_CODE": "250705", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1583, "SUB_DISTRICT_CODE": "250706", "SUB_DISTRICT_NAME": "คำโตนด", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1584, "SUB_DISTRICT_CODE": "250707", "SUB_DISTRICT_NAME": "บุฝ้าย", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1585, "SUB_DISTRICT_CODE": "250708", "SUB_DISTRICT_NAME": "หนองแก้ว", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1586, "SUB_DISTRICT_CODE": "250709", "SUB_DISTRICT_NAME": "โพธิ์งาม", "DISTRICT_ID": 195, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1587, "SUB_DISTRICT_CODE": "250801", "SUB_DISTRICT_NAME": "ศรีมหาโพธิ", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1588, "SUB_DISTRICT_CODE": "250802", "SUB_DISTRICT_NAME": "สัมพันธ์", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1589, "SUB_DISTRICT_CODE": "250803", "SUB_DISTRICT_NAME": "บ้านทาม", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1590, "SUB_DISTRICT_CODE": "250804", "SUB_DISTRICT_NAME": "ท่าตูม", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1591, "SUB_DISTRICT_CODE": "250805", "SUB_DISTRICT_NAME": "บางกุ้ง", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1592, "SUB_DISTRICT_CODE": "250806", "SUB_DISTRICT_NAME": "ดงกระทงยาม", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1593, "SUB_DISTRICT_CODE": "250807", "SUB_DISTRICT_NAME": "หนองโพรง", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1594, "SUB_DISTRICT_CODE": "250808", "SUB_DISTRICT_NAME": "หัวหว้า", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1595, "SUB_DISTRICT_CODE": "250809", "SUB_DISTRICT_NAME": "หาดยาง", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1596, "SUB_DISTRICT_CODE": "250810", "SUB_DISTRICT_NAME": "กรอกสมบูรณ์", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1597, "SUB_DISTRICT_CODE": "250896", "SUB_DISTRICT_NAME": "*คู้ลำพัน", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1598, "SUB_DISTRICT_CODE": "250897", "SUB_DISTRICT_NAME": "*โคกปีบ", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1599, "SUB_DISTRICT_CODE": "250898", "SUB_DISTRICT_NAME": "*โคกไทย", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1600, "SUB_DISTRICT_CODE": "250899", "SUB_DISTRICT_NAME": "*ไผ่ชะเลือด", "DISTRICT_ID": 196, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1601, "SUB_DISTRICT_CODE": "250901", "SUB_DISTRICT_NAME": "โคกปีบ", "DISTRICT_ID": 197, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1602, "SUB_DISTRICT_CODE": "250902", "SUB_DISTRICT_NAME": "โคกไทย", "DISTRICT_ID": 197, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1603, "SUB_DISTRICT_CODE": "250903", "SUB_DISTRICT_NAME": "คู้ลำพัน", "DISTRICT_ID": 197, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1604, "SUB_DISTRICT_CODE": "250904", "SUB_DISTRICT_NAME": "ไผ่ชะเลือด", "DISTRICT_ID": 197, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1605, "SUB_DISTRICT_CODE": "251001", "SUB_DISTRICT_NAME": "*อรัญประเทศ", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1606, "SUB_DISTRICT_CODE": "251002", "SUB_DISTRICT_NAME": "*เมืองไผ่", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1607, "SUB_DISTRICT_CODE": "251003", "SUB_DISTRICT_NAME": "*หันทราย", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1608, "SUB_DISTRICT_CODE": "251004", "SUB_DISTRICT_NAME": "*คลองน้ำใส", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1609, "SUB_DISTRICT_CODE": "251005", "SUB_DISTRICT_NAME": "*ท่าข้าม", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1610, "SUB_DISTRICT_CODE": "251006", "SUB_DISTRICT_NAME": "*ป่าไร่", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1611, "SUB_DISTRICT_CODE": "251007", "SUB_DISTRICT_NAME": "*ทับพริก", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1612, "SUB_DISTRICT_CODE": "251008", "SUB_DISTRICT_NAME": "*บ้านใหม่หนองไทร", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1613, "SUB_DISTRICT_CODE": "251009", "SUB_DISTRICT_NAME": "*ผ่านศึก", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1614, "SUB_DISTRICT_CODE": "251010", "SUB_DISTRICT_NAME": "*หนองสังข์", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1615, "SUB_DISTRICT_CODE": "251011", "SUB_DISTRICT_NAME": "*คลองทับจันทร์", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1616, "SUB_DISTRICT_CODE": "251012", "SUB_DISTRICT_NAME": "*ฟากห้วย", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1617, "SUB_DISTRICT_CODE": "251013", "SUB_DISTRICT_NAME": "*บ้านด่าน", "DISTRICT_ID": 198, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1618, "SUB_DISTRICT_CODE": "251101", "SUB_DISTRICT_NAME": "*ตาพระยา", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1619, "SUB_DISTRICT_CODE": "251102", "SUB_DISTRICT_NAME": "*ทัพเสด็จ", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1620, "SUB_DISTRICT_CODE": "251103", "SUB_DISTRICT_NAME": "*โคกสูง", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1621, "SUB_DISTRICT_CODE": "251104", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1622, "SUB_DISTRICT_CODE": "251105", "SUB_DISTRICT_NAME": "*หนองม่วง", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1623, "SUB_DISTRICT_CODE": "251106", "SUB_DISTRICT_NAME": "*ทัพราช", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1624, "SUB_DISTRICT_CODE": "251107", "SUB_DISTRICT_NAME": "*ทัพไทย", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1625, "SUB_DISTRICT_CODE": "251108", "SUB_DISTRICT_NAME": "*โนนหมากมุ่น", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1626, "SUB_DISTRICT_CODE": "251109", "SUB_DISTRICT_NAME": "*โคคลาน", "DISTRICT_ID": 199, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1627, "SUB_DISTRICT_CODE": "251201", "SUB_DISTRICT_NAME": "*วัฒนานคร", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1628, "SUB_DISTRICT_CODE": "251202", "SUB_DISTRICT_NAME": "*ท่าเกวียน", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1629, "SUB_DISTRICT_CODE": "251203", "SUB_DISTRICT_NAME": "*ซับมะกรูด", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1630, "SUB_DISTRICT_CODE": "251204", "SUB_DISTRICT_NAME": "*ผักขะ", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1631, "SUB_DISTRICT_CODE": "251205", "SUB_DISTRICT_NAME": "*โนนหมากเค็ง", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1632, "SUB_DISTRICT_CODE": "251206", "SUB_DISTRICT_NAME": "*หนองน้ำใส", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1633, "SUB_DISTRICT_CODE": "251207", "SUB_DISTRICT_NAME": "*ช่องกุ่ม", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1634, "SUB_DISTRICT_CODE": "251208", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1635, "SUB_DISTRICT_CODE": "251209", "SUB_DISTRICT_NAME": "*ไทยอุดม", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1636, "SUB_DISTRICT_CODE": "251210", "SUB_DISTRICT_NAME": "*ไทรเดี่ยว", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1637, "SUB_DISTRICT_CODE": "251211", "SUB_DISTRICT_NAME": "*คลองหาด", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1638, "SUB_DISTRICT_CODE": "251212", "SUB_DISTRICT_NAME": "*แซร์ออ", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1639, "SUB_DISTRICT_CODE": "251213", "SUB_DISTRICT_NAME": "*หนองหมากฝ้าย", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1640, "SUB_DISTRICT_CODE": "251214", "SUB_DISTRICT_NAME": "*หนองตะเคียนบอน", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1641, "SUB_DISTRICT_CODE": "251215", "SUB_DISTRICT_NAME": "*ห้วยโจด", "DISTRICT_ID": 200, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1642, "SUB_DISTRICT_CODE": "251301", "SUB_DISTRICT_NAME": "*คลองหาด", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1643, "SUB_DISTRICT_CODE": "251302", "SUB_DISTRICT_NAME": "*ไทยอุดม", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1644, "SUB_DISTRICT_CODE": "251303", "SUB_DISTRICT_NAME": "*ซับมะกรูด", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1645, "SUB_DISTRICT_CODE": "251304", "SUB_DISTRICT_NAME": "*ไทรเดี่ยว", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1646, "SUB_DISTRICT_CODE": "251305", "SUB_DISTRICT_NAME": "*คลองไก่เถื่อน", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1647, "SUB_DISTRICT_CODE": "251306", "SUB_DISTRICT_NAME": "*เบญจขร", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1648, "SUB_DISTRICT_CODE": "251307", "SUB_DISTRICT_NAME": "*ไทรทอง", "DISTRICT_ID": 201, "PROVINCE_ID": 16, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1649, "SUB_DISTRICT_CODE": "260101", "SUB_DISTRICT_NAME": "นครนายก", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1650, "SUB_DISTRICT_CODE": "260102", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1651, "SUB_DISTRICT_CODE": "260103", "SUB_DISTRICT_NAME": "บ้านใหญ่", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1652, "SUB_DISTRICT_CODE": "260104", "SUB_DISTRICT_NAME": "วังกระโจม", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1653, "SUB_DISTRICT_CODE": "260105", "SUB_DISTRICT_NAME": "ท่าทราย", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1654, "SUB_DISTRICT_CODE": "260106", "SUB_DISTRICT_NAME": "ดอนยอ", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1655, "SUB_DISTRICT_CODE": "260107", "SUB_DISTRICT_NAME": "ศรีจุฬา", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1656, "SUB_DISTRICT_CODE": "260108", "SUB_DISTRICT_NAME": "ดงละคร", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1657, "SUB_DISTRICT_CODE": "260109", "SUB_DISTRICT_NAME": "ศรีนาวา", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1658, "SUB_DISTRICT_CODE": "260110", "SUB_DISTRICT_NAME": "สาริกา", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1659, "SUB_DISTRICT_CODE": "260111", "SUB_DISTRICT_NAME": "หินตั้ง", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1660, "SUB_DISTRICT_CODE": "260112", "SUB_DISTRICT_NAME": "เขาพระ", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1661, "SUB_DISTRICT_CODE": "260113", "SUB_DISTRICT_NAME": "พรหมณี", "DISTRICT_ID": 202, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1662, "SUB_DISTRICT_CODE": "260201", "SUB_DISTRICT_NAME": "เกาะหวาย", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1663, "SUB_DISTRICT_CODE": "260202", "SUB_DISTRICT_NAME": "เกาะโพธิ์", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1664, "SUB_DISTRICT_CODE": "260203", "SUB_DISTRICT_NAME": "ปากพลี", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1665, "SUB_DISTRICT_CODE": "260204", "SUB_DISTRICT_NAME": "โคกกรวด", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1666, "SUB_DISTRICT_CODE": "260205", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1667, "SUB_DISTRICT_CODE": "260206", "SUB_DISTRICT_NAME": "หนองแสง", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1668, "SUB_DISTRICT_CODE": "260207", "SUB_DISTRICT_NAME": "นาหินลาด", "DISTRICT_ID": 203, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1669, "SUB_DISTRICT_CODE": "260301", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1670, "SUB_DISTRICT_CODE": "260302", "SUB_DISTRICT_NAME": "บ้านพร้าว", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1671, "SUB_DISTRICT_CODE": "260303", "SUB_DISTRICT_NAME": "บ้านพริก", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1672, "SUB_DISTRICT_CODE": "260304", "SUB_DISTRICT_NAME": "อาษา", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1673, "SUB_DISTRICT_CODE": "260305", "SUB_DISTRICT_NAME": "ทองหลาง", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1674, "SUB_DISTRICT_CODE": "260306", "SUB_DISTRICT_NAME": "บางอ้อ", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1675, "SUB_DISTRICT_CODE": "260307", "SUB_DISTRICT_NAME": "พิกุลออก", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1676, "SUB_DISTRICT_CODE": "260308", "SUB_DISTRICT_NAME": "ป่าขะ", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1677, "SUB_DISTRICT_CODE": "260309", "SUB_DISTRICT_NAME": "เขาเพิ่ม", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1678, "SUB_DISTRICT_CODE": "260310", "SUB_DISTRICT_NAME": "ศรีกะอาง", "DISTRICT_ID": 204, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1679, "SUB_DISTRICT_CODE": "260401", "SUB_DISTRICT_NAME": "พระอาจารย์", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1680, "SUB_DISTRICT_CODE": "260402", "SUB_DISTRICT_NAME": "บึงศาล", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1681, "SUB_DISTRICT_CODE": "260403", "SUB_DISTRICT_NAME": "ศีรษะกระบือ", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1682, "SUB_DISTRICT_CODE": "260404", "SUB_DISTRICT_NAME": "โพธิ์แทน", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1683, "SUB_DISTRICT_CODE": "260405", "SUB_DISTRICT_NAME": "บางสมบูรณ์", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1684, "SUB_DISTRICT_CODE": "260406", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1685, "SUB_DISTRICT_CODE": "260407", "SUB_DISTRICT_NAME": "บางปลากด", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1686, "SUB_DISTRICT_CODE": "260408", "SUB_DISTRICT_NAME": "บางลูกเสือ", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1687, "SUB_DISTRICT_CODE": "260409", "SUB_DISTRICT_NAME": "องครักษ์", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1688, "SUB_DISTRICT_CODE": "260410", "SUB_DISTRICT_NAME": "ชุมพล", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1689, "SUB_DISTRICT_CODE": "260411", "SUB_DISTRICT_NAME": "คลองใหญ่", "DISTRICT_ID": 205, "PROVINCE_ID": 17, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 1690, "SUB_DISTRICT_CODE": "270101", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1691, "SUB_DISTRICT_CODE": "270102", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1692, "SUB_DISTRICT_CODE": "270103", "SUB_DISTRICT_NAME": "ศาลาลำดวน", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1693, "SUB_DISTRICT_CODE": "270104", "SUB_DISTRICT_NAME": "โคกปี่ฆ้อง", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1694, "SUB_DISTRICT_CODE": "270105", "SUB_DISTRICT_NAME": "ท่าแยก", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1695, "SUB_DISTRICT_CODE": "270106", "SUB_DISTRICT_NAME": "ท่าเกษม", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1696, "SUB_DISTRICT_CODE": "270107", "SUB_DISTRICT_NAME": "*เขาฉกรรจ์", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1697, "SUB_DISTRICT_CODE": "270108", "SUB_DISTRICT_NAME": "สระขวัญ", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1698, "SUB_DISTRICT_CODE": "270109", "SUB_DISTRICT_NAME": "*หนองหว้า", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1699, "SUB_DISTRICT_CODE": "270110", "SUB_DISTRICT_NAME": "*พระเพลิง", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1700, "SUB_DISTRICT_CODE": "270111", "SUB_DISTRICT_NAME": "หนองบอน", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1701, "SUB_DISTRICT_CODE": "270112", "SUB_DISTRICT_NAME": "*เขาสามสิบ", "DISTRICT_ID": 206, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1702, "SUB_DISTRICT_CODE": "270201", "SUB_DISTRICT_NAME": "คลองหาด", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1703, "SUB_DISTRICT_CODE": "270202", "SUB_DISTRICT_NAME": "ไทยอุดม", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1704, "SUB_DISTRICT_CODE": "270203", "SUB_DISTRICT_NAME": "ซับมะกรูด", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1705, "SUB_DISTRICT_CODE": "270204", "SUB_DISTRICT_NAME": "ไทรเดี่ยว", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1706, "SUB_DISTRICT_CODE": "270205", "SUB_DISTRICT_NAME": "คลองไก่เถื่อน", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1707, "SUB_DISTRICT_CODE": "270206", "SUB_DISTRICT_NAME": "เบญจขร", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1708, "SUB_DISTRICT_CODE": "270207", "SUB_DISTRICT_NAME": "ไทรทอง", "DISTRICT_ID": 207, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1709, "SUB_DISTRICT_CODE": "270301", "SUB_DISTRICT_NAME": "ตาพระยา", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1710, "SUB_DISTRICT_CODE": "270302", "SUB_DISTRICT_NAME": "ทัพเสด็จ", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1711, "SUB_DISTRICT_CODE": "270303", "SUB_DISTRICT_NAME": "โคกสูง*", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1712, "SUB_DISTRICT_CODE": "270304", "SUB_DISTRICT_NAME": "หนองแวง*", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1713, "SUB_DISTRICT_CODE": "270305", "SUB_DISTRICT_NAME": "หนองม่วง*", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1714, "SUB_DISTRICT_CODE": "270306", "SUB_DISTRICT_NAME": "ทัพราช", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1715, "SUB_DISTRICT_CODE": "270307", "SUB_DISTRICT_NAME": "ทัพไทย", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1716, "SUB_DISTRICT_CODE": "270308", "SUB_DISTRICT_NAME": "โนนหมากมุ่น*", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1717, "SUB_DISTRICT_CODE": "270309", "SUB_DISTRICT_NAME": "โคคลาน", "DISTRICT_ID": 208, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1718, "SUB_DISTRICT_CODE": "270401", "SUB_DISTRICT_NAME": "วังน้ำเย็น", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1719, "SUB_DISTRICT_CODE": "270402", "SUB_DISTRICT_NAME": "วังสมบูรณ์*", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1720, "SUB_DISTRICT_CODE": "270403", "SUB_DISTRICT_NAME": "ตาหลังใน", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1721, "SUB_DISTRICT_CODE": "270404", "SUB_DISTRICT_NAME": "วังใหม่*", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1722, "SUB_DISTRICT_CODE": "270405", "SUB_DISTRICT_NAME": "คลองหินปูน", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1723, "SUB_DISTRICT_CODE": "270406", "SUB_DISTRICT_NAME": "ทุ่งมหาเจริญ", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1724, "SUB_DISTRICT_CODE": "270407", "SUB_DISTRICT_NAME": "วังทอง*", "DISTRICT_ID": 209, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1725, "SUB_DISTRICT_CODE": "270501", "SUB_DISTRICT_NAME": "วัฒนานคร", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1726, "SUB_DISTRICT_CODE": "270502", "SUB_DISTRICT_NAME": "ท่าเกวียน", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1727, "SUB_DISTRICT_CODE": "270503", "SUB_DISTRICT_NAME": "ผักขะ", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1728, "SUB_DISTRICT_CODE": "270504", "SUB_DISTRICT_NAME": "โนนหมากเค็ง", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1729, "SUB_DISTRICT_CODE": "270505", "SUB_DISTRICT_NAME": "หนองน้ำใส", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1730, "SUB_DISTRICT_CODE": "270506", "SUB_DISTRICT_NAME": "ช่องกุ่ม", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1731, "SUB_DISTRICT_CODE": "270507", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1732, "SUB_DISTRICT_CODE": "270508", "SUB_DISTRICT_NAME": "แซร์ออ", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1733, "SUB_DISTRICT_CODE": "270509", "SUB_DISTRICT_NAME": "หนองหมากฝ้าย", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1734, "SUB_DISTRICT_CODE": "270510", "SUB_DISTRICT_NAME": "หนองตะเคียนบอน", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1735, "SUB_DISTRICT_CODE": "270511", "SUB_DISTRICT_NAME": "ห้วยโจด", "DISTRICT_ID": 210, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1736, "SUB_DISTRICT_CODE": "270601", "SUB_DISTRICT_NAME": "อรัญประเทศ", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1737, "SUB_DISTRICT_CODE": "270602", "SUB_DISTRICT_NAME": "เมืองไผ่", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1738, "SUB_DISTRICT_CODE": "270603", "SUB_DISTRICT_NAME": "หันทราย", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1739, "SUB_DISTRICT_CODE": "270604", "SUB_DISTRICT_NAME": "คลองน้ำใส", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1740, "SUB_DISTRICT_CODE": "270605", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1741, "SUB_DISTRICT_CODE": "270606", "SUB_DISTRICT_NAME": "ป่าไร่", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1742, "SUB_DISTRICT_CODE": "270607", "SUB_DISTRICT_NAME": "ทับพริก", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1743, "SUB_DISTRICT_CODE": "270608", "SUB_DISTRICT_NAME": "บ้านใหม่หนองไทร", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1744, "SUB_DISTRICT_CODE": "270609", "SUB_DISTRICT_NAME": "ผ่านศึก", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1745, "SUB_DISTRICT_CODE": "270610", "SUB_DISTRICT_NAME": "หนองสังข์", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1746, "SUB_DISTRICT_CODE": "270611", "SUB_DISTRICT_NAME": "คลองทับจันทร์", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1747, "SUB_DISTRICT_CODE": "270612", "SUB_DISTRICT_NAME": "ฟากห้วย", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1748, "SUB_DISTRICT_CODE": "270613", "SUB_DISTRICT_NAME": "บ้านด่าน", "DISTRICT_ID": 211, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1749, "SUB_DISTRICT_CODE": "270701", "SUB_DISTRICT_NAME": "เขาฉกรรจ์", "DISTRICT_ID": 212, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1750, "SUB_DISTRICT_CODE": "270702", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 212, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1751, "SUB_DISTRICT_CODE": "270703", "SUB_DISTRICT_NAME": "พระเพลิง", "DISTRICT_ID": 212, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1752, "SUB_DISTRICT_CODE": "270704", "SUB_DISTRICT_NAME": "เขาสามสิบ", "DISTRICT_ID": 212, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1753, "SUB_DISTRICT_CODE": "270801", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 213, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1754, "SUB_DISTRICT_CODE": "270802", "SUB_DISTRICT_NAME": "หนองม่วง", "DISTRICT_ID": 213, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1755, "SUB_DISTRICT_CODE": "270803", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 213, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1756, "SUB_DISTRICT_CODE": "270804", "SUB_DISTRICT_NAME": "โนนหมากมุ่น", "DISTRICT_ID": 213, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1757, "SUB_DISTRICT_CODE": "270901", "SUB_DISTRICT_NAME": "วังสมบูรณ์", "DISTRICT_ID": 214, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1758, "SUB_DISTRICT_CODE": "270902", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 214, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1759, "SUB_DISTRICT_CODE": "270903", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 214, "PROVINCE_ID": 18, "GEO_ID": 5 },
    { "SUB_DISTRICT_ID": 1760, "SUB_DISTRICT_CODE": "300101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1761, "SUB_DISTRICT_CODE": "300102", "SUB_DISTRICT_NAME": "โพธิ์กลาง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1762, "SUB_DISTRICT_CODE": "300103", "SUB_DISTRICT_NAME": "หนองจะบก", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1763, "SUB_DISTRICT_CODE": "300104", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1764, "SUB_DISTRICT_CODE": "300105", "SUB_DISTRICT_NAME": "มะเริง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1765, "SUB_DISTRICT_CODE": "300106", "SUB_DISTRICT_NAME": "หนองระเวียง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1766, "SUB_DISTRICT_CODE": "300107", "SUB_DISTRICT_NAME": "ปรุใหญ่", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1767, "SUB_DISTRICT_CODE": "300108", "SUB_DISTRICT_NAME": "หมื่นไวย", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1768, "SUB_DISTRICT_CODE": "300109", "SUB_DISTRICT_NAME": "พลกรัง", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1769, "SUB_DISTRICT_CODE": "300110", "SUB_DISTRICT_NAME": "หนองไผ่ล้อม", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1770, "SUB_DISTRICT_CODE": "300111", "SUB_DISTRICT_NAME": "หัวทะเล", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1771, "SUB_DISTRICT_CODE": "300112", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1772, "SUB_DISTRICT_CODE": "300113", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1773, "SUB_DISTRICT_CODE": "300114", "SUB_DISTRICT_NAME": "พุดซา", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1774, "SUB_DISTRICT_CODE": "300115", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1775, "SUB_DISTRICT_CODE": "300116", "SUB_DISTRICT_NAME": "จอหอ", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1776, "SUB_DISTRICT_CODE": "300117", "SUB_DISTRICT_NAME": "โคกกรวด", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1777, "SUB_DISTRICT_CODE": "300118", "SUB_DISTRICT_NAME": "ไชยมงคล", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1778, "SUB_DISTRICT_CODE": "300119", "SUB_DISTRICT_NAME": "หนองบัวศาลา", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1779, "SUB_DISTRICT_CODE": "300120", "SUB_DISTRICT_NAME": "สุรนารี", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1780, "SUB_DISTRICT_CODE": "300121", "SUB_DISTRICT_NAME": "สีมุม", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1781, "SUB_DISTRICT_CODE": "300122", "SUB_DISTRICT_NAME": "ตลาด", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1782, "SUB_DISTRICT_CODE": "300123", "SUB_DISTRICT_NAME": "พะเนา", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1783, "SUB_DISTRICT_CODE": "300124", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1784, "SUB_DISTRICT_CODE": "300125", "SUB_DISTRICT_NAME": "หนองไข่น้ำ", "DISTRICT_ID": 215, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1785, "SUB_DISTRICT_CODE": "300201", "SUB_DISTRICT_NAME": "แชะ", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1786, "SUB_DISTRICT_CODE": "300202", "SUB_DISTRICT_NAME": "เฉลียง", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1787, "SUB_DISTRICT_CODE": "300203", "SUB_DISTRICT_NAME": "ครบุรี", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1788, "SUB_DISTRICT_CODE": "300204", "SUB_DISTRICT_NAME": "โคกกระชาย", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1789, "SUB_DISTRICT_CODE": "300205", "SUB_DISTRICT_NAME": "จระเข้หิน", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1790, "SUB_DISTRICT_CODE": "300206", "SUB_DISTRICT_NAME": "มาบตะโกเอน", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1791, "SUB_DISTRICT_CODE": "300207", "SUB_DISTRICT_NAME": "อรพิมพ์", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1792, "SUB_DISTRICT_CODE": "300208", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1793, "SUB_DISTRICT_CODE": "300209", "SUB_DISTRICT_NAME": "ลำเพียก", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1794, "SUB_DISTRICT_CODE": "300210", "SUB_DISTRICT_NAME": "ครบุรีใต้", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1795, "SUB_DISTRICT_CODE": "300211", "SUB_DISTRICT_NAME": "ตะแบกบาน", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1796, "SUB_DISTRICT_CODE": "300212", "SUB_DISTRICT_NAME": "สระว่านพระยา", "DISTRICT_ID": 216, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1797, "SUB_DISTRICT_CODE": "300301", "SUB_DISTRICT_NAME": "เสิงสาง", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1798, "SUB_DISTRICT_CODE": "300302", "SUB_DISTRICT_NAME": "สระตะเคียน", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1799, "SUB_DISTRICT_CODE": "300303", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1800, "SUB_DISTRICT_CODE": "300304", "SUB_DISTRICT_NAME": "กุดโบสถ์", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1801, "SUB_DISTRICT_CODE": "300305", "SUB_DISTRICT_NAME": "สุขไพบูลย์", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1802, "SUB_DISTRICT_CODE": "300306", "SUB_DISTRICT_NAME": "บ้านราษฎร์", "DISTRICT_ID": 217, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1803, "SUB_DISTRICT_CODE": "300401", "SUB_DISTRICT_NAME": "เมืองคง", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1804, "SUB_DISTRICT_CODE": "300402", "SUB_DISTRICT_NAME": "คูขาด", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1805, "SUB_DISTRICT_CODE": "300403", "SUB_DISTRICT_NAME": "เทพาลัย", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1806, "SUB_DISTRICT_CODE": "300404", "SUB_DISTRICT_NAME": "ตาจั่น", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1807, "SUB_DISTRICT_CODE": "300405", "SUB_DISTRICT_NAME": "บ้านปรางค์", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1808, "SUB_DISTRICT_CODE": "300406", "SUB_DISTRICT_NAME": "หนองมะนาว", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1809, "SUB_DISTRICT_CODE": "300407", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1810, "SUB_DISTRICT_CODE": "300408", "SUB_DISTRICT_NAME": "โนนเต็ง", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1811, "SUB_DISTRICT_CODE": "300409", "SUB_DISTRICT_NAME": "ดอนใหญ่", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1812, "SUB_DISTRICT_CODE": "300410", "SUB_DISTRICT_NAME": "ขามสมบูรณ์", "DISTRICT_ID": 218, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1813, "SUB_DISTRICT_CODE": "300501", "SUB_DISTRICT_NAME": "บ้านเหลื่อม", "DISTRICT_ID": 219, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1814, "SUB_DISTRICT_CODE": "300502", "SUB_DISTRICT_NAME": "วังโพธิ์", "DISTRICT_ID": 219, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1815, "SUB_DISTRICT_CODE": "300503", "SUB_DISTRICT_NAME": "โคกกระเบื้อง", "DISTRICT_ID": 219, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1816, "SUB_DISTRICT_CODE": "300504", "SUB_DISTRICT_NAME": "ช่อระกา", "DISTRICT_ID": 219, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1817, "SUB_DISTRICT_CODE": "300601", "SUB_DISTRICT_NAME": "จักราช", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1818, "SUB_DISTRICT_CODE": "300602", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1819, "SUB_DISTRICT_CODE": "300603", "SUB_DISTRICT_NAME": "ทองหลาง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1820, "SUB_DISTRICT_CODE": "300604", "SUB_DISTRICT_NAME": "สีสุก", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1821, "SUB_DISTRICT_CODE": "300605", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1822, "SUB_DISTRICT_CODE": "300606", "SUB_DISTRICT_NAME": "หนองงูเหลือม", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1823, "SUB_DISTRICT_CODE": "300607", "SUB_DISTRICT_NAME": "หนองพลวง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1824, "SUB_DISTRICT_CODE": "300608", "SUB_DISTRICT_NAME": "หนองยาง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1825, "SUB_DISTRICT_CODE": "300609", "SUB_DISTRICT_NAME": "พระพุทธ", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1826, "SUB_DISTRICT_CODE": "300610", "SUB_DISTRICT_NAME": "ศรีละกอ", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1827, "SUB_DISTRICT_CODE": "300611", "SUB_DISTRICT_NAME": "คลองเมือง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1828, "SUB_DISTRICT_CODE": "300612", "SUB_DISTRICT_NAME": "ช้างทอง", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1829, "SUB_DISTRICT_CODE": "300613", "SUB_DISTRICT_NAME": "หินโคน", "DISTRICT_ID": 220, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1830, "SUB_DISTRICT_CODE": "300701", "SUB_DISTRICT_NAME": "กระโทก", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1831, "SUB_DISTRICT_CODE": "300702", "SUB_DISTRICT_NAME": "พลับพลา", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1832, "SUB_DISTRICT_CODE": "300703", "SUB_DISTRICT_NAME": "ท่าอ่าง", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1833, "SUB_DISTRICT_CODE": "300704", "SUB_DISTRICT_NAME": "ทุ่งอรุณ", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1834, "SUB_DISTRICT_CODE": "300705", "SUB_DISTRICT_NAME": "ท่าลาดขาว", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1835, "SUB_DISTRICT_CODE": "300706", "SUB_DISTRICT_NAME": "ท่าจะหลุง", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1836, "SUB_DISTRICT_CODE": "300707", "SUB_DISTRICT_NAME": "ท่าเยี่ยม", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1837, "SUB_DISTRICT_CODE": "300708", "SUB_DISTRICT_NAME": "โชคชัย", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1838, "SUB_DISTRICT_CODE": "300709", "SUB_DISTRICT_NAME": "ละลมใหม่พัฒนา", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1839, "SUB_DISTRICT_CODE": "300710", "SUB_DISTRICT_NAME": "ด่านเกวียน", "DISTRICT_ID": 221, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1840, "SUB_DISTRICT_CODE": "300801", "SUB_DISTRICT_NAME": "กุดพิมาน", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1841, "SUB_DISTRICT_CODE": "300802", "SUB_DISTRICT_NAME": "ด่านขุนทด", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1842, "SUB_DISTRICT_CODE": "300803", "SUB_DISTRICT_NAME": "ด่านนอก", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1843, "SUB_DISTRICT_CODE": "300804", "SUB_DISTRICT_NAME": "ด่านใน", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1844, "SUB_DISTRICT_CODE": "300805", "SUB_DISTRICT_NAME": "ตะเคียน", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1845, "SUB_DISTRICT_CODE": "300806", "SUB_DISTRICT_NAME": "บ้านเก่า", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1846, "SUB_DISTRICT_CODE": "300807", "SUB_DISTRICT_NAME": "บ้านแปรง", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1847, "SUB_DISTRICT_CODE": "300808", "SUB_DISTRICT_NAME": "พันชนะ", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1848, "SUB_DISTRICT_CODE": "300809", "SUB_DISTRICT_NAME": "สระจรเข้", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1849, "SUB_DISTRICT_CODE": "300810", "SUB_DISTRICT_NAME": "หนองกราด", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1850, "SUB_DISTRICT_CODE": "300811", "SUB_DISTRICT_NAME": "หนองบัวตะเกียด", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1851, "SUB_DISTRICT_CODE": "300812", "SUB_DISTRICT_NAME": "หนองบัวละคร", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1852, "SUB_DISTRICT_CODE": "300813", "SUB_DISTRICT_NAME": "หินดาด", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1853, "SUB_DISTRICT_CODE": "300814", "SUB_DISTRICT_NAME": "*สำนักตะคร้อ", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1854, "SUB_DISTRICT_CODE": "300815", "SUB_DISTRICT_NAME": "ห้วยบง", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1855, "SUB_DISTRICT_CODE": "300816", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1856, "SUB_DISTRICT_CODE": "300817", "SUB_DISTRICT_NAME": "โนนเมืองพัฒนา", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1857, "SUB_DISTRICT_CODE": "300818", "SUB_DISTRICT_NAME": "หนองไทร", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1858, "SUB_DISTRICT_CODE": "300819", "SUB_DISTRICT_NAME": "*บึงปรือ", "DISTRICT_ID": 222, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1859, "SUB_DISTRICT_CODE": "300901", "SUB_DISTRICT_NAME": "โนนไทย", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1860, "SUB_DISTRICT_CODE": "300902", "SUB_DISTRICT_NAME": "ด่านจาก", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1861, "SUB_DISTRICT_CODE": "300903", "SUB_DISTRICT_NAME": "กำปัง", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1862, "SUB_DISTRICT_CODE": "300904", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1863, "SUB_DISTRICT_CODE": "300905", "SUB_DISTRICT_NAME": "ค้างพลู", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1864, "SUB_DISTRICT_CODE": "300906", "SUB_DISTRICT_NAME": "บ้านวัง", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1865, "SUB_DISTRICT_CODE": "300907", "SUB_DISTRICT_NAME": "บัลลังก์", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1866, "SUB_DISTRICT_CODE": "300908", "SUB_DISTRICT_NAME": "สายออ", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1867, "SUB_DISTRICT_CODE": "300909", "SUB_DISTRICT_NAME": "ถนนโพธิ์", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1868, "SUB_DISTRICT_CODE": "300910", "SUB_DISTRICT_NAME": "พังเทียม", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1869, "SUB_DISTRICT_CODE": "300911", "SUB_DISTRICT_NAME": "สระพระ", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1870, "SUB_DISTRICT_CODE": "300912", "SUB_DISTRICT_NAME": "ทัพรั้ง", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1871, "SUB_DISTRICT_CODE": "300913", "SUB_DISTRICT_NAME": "หนองหอย", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1872, "SUB_DISTRICT_CODE": "300914", "SUB_DISTRICT_NAME": "มะค่า", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1873, "SUB_DISTRICT_CODE": "300915", "SUB_DISTRICT_NAME": "มาบกราด", "DISTRICT_ID": 223, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1874, "SUB_DISTRICT_CODE": "301001", "SUB_DISTRICT_NAME": "โนนสูง", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1875, "SUB_DISTRICT_CODE": "301002", "SUB_DISTRICT_NAME": "ใหม่", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1876, "SUB_DISTRICT_CODE": "301003", "SUB_DISTRICT_NAME": "โตนด", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1877, "SUB_DISTRICT_CODE": "301004", "SUB_DISTRICT_NAME": "บิง", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1878, "SUB_DISTRICT_CODE": "301005", "SUB_DISTRICT_NAME": "ดอนชมพู", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1879, "SUB_DISTRICT_CODE": "301006", "SUB_DISTRICT_NAME": "ธารปราสาท", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1880, "SUB_DISTRICT_CODE": "301007", "SUB_DISTRICT_NAME": "หลุมข้าว", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1881, "SUB_DISTRICT_CODE": "301008", "SUB_DISTRICT_NAME": "มะค่า", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1882, "SUB_DISTRICT_CODE": "301009", "SUB_DISTRICT_NAME": "พลสงคราม", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1883, "SUB_DISTRICT_CODE": "301010", "SUB_DISTRICT_NAME": "จันอัด", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1884, "SUB_DISTRICT_CODE": "301011", "SUB_DISTRICT_NAME": "ขามเฒ่า", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1885, "SUB_DISTRICT_CODE": "301012", "SUB_DISTRICT_NAME": "ด่านคล้า", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1886, "SUB_DISTRICT_CODE": "301013", "SUB_DISTRICT_NAME": "ลำคอหงษ์", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1887, "SUB_DISTRICT_CODE": "301014", "SUB_DISTRICT_NAME": "เมืองปราสาท", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1888, "SUB_DISTRICT_CODE": "301015", "SUB_DISTRICT_NAME": "ดอนหวาย", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1889, "SUB_DISTRICT_CODE": "301016", "SUB_DISTRICT_NAME": "ลำมูล", "DISTRICT_ID": 224, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1890, "SUB_DISTRICT_CODE": "301101", "SUB_DISTRICT_NAME": "ขามสะแกแสง", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1891, "SUB_DISTRICT_CODE": "301102", "SUB_DISTRICT_NAME": "โนนเมือง", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1892, "SUB_DISTRICT_CODE": "301103", "SUB_DISTRICT_NAME": "เมืองนาท", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1893, "SUB_DISTRICT_CODE": "301104", "SUB_DISTRICT_NAME": "ชีวึก", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1894, "SUB_DISTRICT_CODE": "301105", "SUB_DISTRICT_NAME": "พะงาด", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1895, "SUB_DISTRICT_CODE": "301106", "SUB_DISTRICT_NAME": "หนองหัวฟาน", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1896, "SUB_DISTRICT_CODE": "301107", "SUB_DISTRICT_NAME": "เมืองเกษตร", "DISTRICT_ID": 225, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1897, "SUB_DISTRICT_CODE": "301201", "SUB_DISTRICT_NAME": "บัวใหญ่", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1898, "SUB_DISTRICT_CODE": "301203", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1899, "SUB_DISTRICT_CODE": "301204", "SUB_DISTRICT_NAME": "เสมาใหญ่", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1900, "SUB_DISTRICT_CODE": "301205", "SUB_DISTRICT_NAME": "*บึงพะไล", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1901, "SUB_DISTRICT_CODE": "301206", "SUB_DISTRICT_NAME": "ดอนตะหนิน", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1902, "SUB_DISTRICT_CODE": "301207", "SUB_DISTRICT_NAME": "หนองบัวสะอาด", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1903, "SUB_DISTRICT_CODE": "301208", "SUB_DISTRICT_NAME": "โนนทองหลาง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1904, "SUB_DISTRICT_CODE": "301209", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1905, "SUB_DISTRICT_CODE": "301210", "SUB_DISTRICT_NAME": "บัวลาย", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1906, "SUB_DISTRICT_CODE": "301211", "SUB_DISTRICT_NAME": "สีดา", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1907, "SUB_DISTRICT_CODE": "301212", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1908, "SUB_DISTRICT_CODE": "301213", "SUB_DISTRICT_NAME": "*แก้งสนามนาง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1909, "SUB_DISTRICT_CODE": "301214", "SUB_DISTRICT_NAME": "กุดจอก", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1910, "SUB_DISTRICT_CODE": "301215", "SUB_DISTRICT_NAME": "ด่านช้าง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1911, "SUB_DISTRICT_CODE": "301216", "SUB_DISTRICT_NAME": "โนนจาน", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1912, "SUB_DISTRICT_CODE": "301217", "SUB_DISTRICT_NAME": "*สีสุก", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1913, "SUB_DISTRICT_CODE": "301218", "SUB_DISTRICT_NAME": "สามเมือง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1914, "SUB_DISTRICT_CODE": "301219", "SUB_DISTRICT_NAME": "*โนนสำราญ", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1915, "SUB_DISTRICT_CODE": "301220", "SUB_DISTRICT_NAME": "ขุนทอง", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1916, "SUB_DISTRICT_CODE": "301221", "SUB_DISTRICT_NAME": "หนองตาดใหญ่", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1917, "SUB_DISTRICT_CODE": "301222", "SUB_DISTRICT_NAME": "เมืองพะไล", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1918, "SUB_DISTRICT_CODE": "301223", "SUB_DISTRICT_NAME": "โนนประดู่", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1919, "SUB_DISTRICT_CODE": "301224", "SUB_DISTRICT_NAME": "หนองแจ้งใหญ่", "DISTRICT_ID": 226, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1920, "SUB_DISTRICT_CODE": "301301", "SUB_DISTRICT_NAME": "ประทาย", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1921, "SUB_DISTRICT_CODE": "301302", "SUB_DISTRICT_NAME": "*โนนแดง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1922, "SUB_DISTRICT_CODE": "301303", "SUB_DISTRICT_NAME": "กระทุ่มราย", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1923, "SUB_DISTRICT_CODE": "301304", "SUB_DISTRICT_NAME": "วังไม้แดง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1924, "SUB_DISTRICT_CODE": "301305", "SUB_DISTRICT_NAME": "*วังหิน", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1925, "SUB_DISTRICT_CODE": "301306", "SUB_DISTRICT_NAME": "ตลาดไทร", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1926, "SUB_DISTRICT_CODE": "301307", "SUB_DISTRICT_NAME": "หนองพลวง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1927, "SUB_DISTRICT_CODE": "301308", "SUB_DISTRICT_NAME": "หนองค่าย", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1928, "SUB_DISTRICT_CODE": "301309", "SUB_DISTRICT_NAME": "หันห้วยทราย", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1929, "SUB_DISTRICT_CODE": "301310", "SUB_DISTRICT_NAME": "ดอนมัน", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1930, "SUB_DISTRICT_CODE": "301311", "SUB_DISTRICT_NAME": "*โนนตาเถร", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1931, "SUB_DISTRICT_CODE": "301312", "SUB_DISTRICT_NAME": "*สำพะเนียง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1932, "SUB_DISTRICT_CODE": "301313", "SUB_DISTRICT_NAME": "นางรำ", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1933, "SUB_DISTRICT_CODE": "301314", "SUB_DISTRICT_NAME": "โนนเพ็ด", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1934, "SUB_DISTRICT_CODE": "301315", "SUB_DISTRICT_NAME": "ทุ่งสว่าง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1935, "SUB_DISTRICT_CODE": "301316", "SUB_DISTRICT_NAME": "*ดอนยาวใหญ่", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1936, "SUB_DISTRICT_CODE": "301317", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1937, "SUB_DISTRICT_CODE": "301318", "SUB_DISTRICT_NAME": "เมืองโดน", "DISTRICT_ID": 227, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1938, "SUB_DISTRICT_CODE": "301401", "SUB_DISTRICT_NAME": "เมืองปัก", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1939, "SUB_DISTRICT_CODE": "301402", "SUB_DISTRICT_NAME": "ตะคุ", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1940, "SUB_DISTRICT_CODE": "301403", "SUB_DISTRICT_NAME": "โคกไทย", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1941, "SUB_DISTRICT_CODE": "301404", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1942, "SUB_DISTRICT_CODE": "301405", "SUB_DISTRICT_NAME": "ตะขบ", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1943, "SUB_DISTRICT_CODE": "301406", "SUB_DISTRICT_NAME": "นกออก", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1944, "SUB_DISTRICT_CODE": "301407", "SUB_DISTRICT_NAME": "ดอน", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1945, "SUB_DISTRICT_CODE": "301408", "SUB_DISTRICT_NAME": "*วังน้ำเขียว", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1946, "SUB_DISTRICT_CODE": "301409", "SUB_DISTRICT_NAME": "ตูม", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1947, "SUB_DISTRICT_CODE": "301410", "SUB_DISTRICT_NAME": "งิ้ว", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1948, "SUB_DISTRICT_CODE": "301411", "SUB_DISTRICT_NAME": "สะแกราช", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1949, "SUB_DISTRICT_CODE": "301412", "SUB_DISTRICT_NAME": "ลำนางแก้ว", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1950, "SUB_DISTRICT_CODE": "301413", "SUB_DISTRICT_NAME": "*วังหมี", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1951, "SUB_DISTRICT_CODE": "301414", "SUB_DISTRICT_NAME": "*ระเริง", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1952, "SUB_DISTRICT_CODE": "301415", "SUB_DISTRICT_NAME": "*อุดมทรัพย์", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1953, "SUB_DISTRICT_CODE": "301416", "SUB_DISTRICT_NAME": "ภูหลวง", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1954, "SUB_DISTRICT_CODE": "301417", "SUB_DISTRICT_NAME": "ธงชัยเหนือ", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1955, "SUB_DISTRICT_CODE": "301418", "SUB_DISTRICT_NAME": "สุขเกษม", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1956, "SUB_DISTRICT_CODE": "301419", "SUB_DISTRICT_NAME": "เกษมทรัพย์", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1957, "SUB_DISTRICT_CODE": "301420", "SUB_DISTRICT_NAME": "บ่อปลาทอง", "DISTRICT_ID": 228, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1958, "SUB_DISTRICT_CODE": "301501", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1959, "SUB_DISTRICT_CODE": "301502", "SUB_DISTRICT_NAME": "สัมฤทธิ์", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1960, "SUB_DISTRICT_CODE": "301503", "SUB_DISTRICT_NAME": "โบสถ์", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1961, "SUB_DISTRICT_CODE": "301504", "SUB_DISTRICT_NAME": "กระเบื้องใหญ่", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1962, "SUB_DISTRICT_CODE": "301505", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1963, "SUB_DISTRICT_CODE": "301506", "SUB_DISTRICT_NAME": "รังกาใหญ่", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1964, "SUB_DISTRICT_CODE": "301507", "SUB_DISTRICT_NAME": "ชีวาน", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1965, "SUB_DISTRICT_CODE": "301508", "SUB_DISTRICT_NAME": "นิคมสร้างตนเอง", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1966, "SUB_DISTRICT_CODE": "301509", "SUB_DISTRICT_NAME": "กระชอน", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1967, "SUB_DISTRICT_CODE": "301510", "SUB_DISTRICT_NAME": "ดงใหญ่", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1968, "SUB_DISTRICT_CODE": "301511", "SUB_DISTRICT_NAME": "ธารละหลอด", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1969, "SUB_DISTRICT_CODE": "301512", "SUB_DISTRICT_NAME": "หนองระเวียง", "DISTRICT_ID": 229, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1970, "SUB_DISTRICT_CODE": "301601", "SUB_DISTRICT_NAME": "ห้วยแถลง", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1971, "SUB_DISTRICT_CODE": "301602", "SUB_DISTRICT_NAME": "ทับสวาย", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1972, "SUB_DISTRICT_CODE": "301603", "SUB_DISTRICT_NAME": "เมืองพลับพลา", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1973, "SUB_DISTRICT_CODE": "301604", "SUB_DISTRICT_NAME": "หลุ่งตะเคียน", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1974, "SUB_DISTRICT_CODE": "301605", "SUB_DISTRICT_NAME": "หินดาด", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1975, "SUB_DISTRICT_CODE": "301606", "SUB_DISTRICT_NAME": "งิ้ว", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1976, "SUB_DISTRICT_CODE": "301607", "SUB_DISTRICT_NAME": "กงรถ", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1977, "SUB_DISTRICT_CODE": "301608", "SUB_DISTRICT_NAME": "หลุ่งประดู่", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1978, "SUB_DISTRICT_CODE": "301609", "SUB_DISTRICT_NAME": "ตะโก", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1979, "SUB_DISTRICT_CODE": "301610", "SUB_DISTRICT_NAME": "ห้วยแคน", "DISTRICT_ID": 230, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1980, "SUB_DISTRICT_CODE": "301701", "SUB_DISTRICT_NAME": "ชุมพวง", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1981, "SUB_DISTRICT_CODE": "301702", "SUB_DISTRICT_NAME": "ประสุข", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1982, "SUB_DISTRICT_CODE": "301703", "SUB_DISTRICT_NAME": "ท่าลาด", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1983, "SUB_DISTRICT_CODE": "301704", "SUB_DISTRICT_NAME": "สาหร่าย", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1984, "SUB_DISTRICT_CODE": "301705", "SUB_DISTRICT_NAME": "ตลาดไทร", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1985, "SUB_DISTRICT_CODE": "301706", "SUB_DISTRICT_NAME": "ช่องแมว", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1986, "SUB_DISTRICT_CODE": "301707", "SUB_DISTRICT_NAME": "ขุย", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1987, "SUB_DISTRICT_CODE": "301708", "SUB_DISTRICT_NAME": "*กระเบื้องนอก", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1988, "SUB_DISTRICT_CODE": "301709", "SUB_DISTRICT_NAME": "*เมืองยาง", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1989, "SUB_DISTRICT_CODE": "301710", "SUB_DISTRICT_NAME": "โนนรัง", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1990, "SUB_DISTRICT_CODE": "301711", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1991, "SUB_DISTRICT_CODE": "301712", "SUB_DISTRICT_NAME": "*ละหานปลาค้าว", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1992, "SUB_DISTRICT_CODE": "301713", "SUB_DISTRICT_NAME": "*โนนอุดม", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1993, "SUB_DISTRICT_CODE": "301714", "SUB_DISTRICT_NAME": "หนองหลัก", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1994, "SUB_DISTRICT_CODE": "301715", "SUB_DISTRICT_NAME": "ไพล", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1995, "SUB_DISTRICT_CODE": "301716", "SUB_DISTRICT_NAME": "โนนตูม", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1996, "SUB_DISTRICT_CODE": "301717", "SUB_DISTRICT_NAME": "โนนยอ", "DISTRICT_ID": 231, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1997, "SUB_DISTRICT_CODE": "301801", "SUB_DISTRICT_NAME": "สูงเนิน", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1998, "SUB_DISTRICT_CODE": "301802", "SUB_DISTRICT_NAME": "เสมา", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 1999, "SUB_DISTRICT_CODE": "301803", "SUB_DISTRICT_NAME": "โคราช", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2000, "SUB_DISTRICT_CODE": "301804", "SUB_DISTRICT_NAME": "บุ่งขี้เหล็ก", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2001, "SUB_DISTRICT_CODE": "301805", "SUB_DISTRICT_NAME": "โนนค่า", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2002, "SUB_DISTRICT_CODE": "301806", "SUB_DISTRICT_NAME": "โค้งยาง", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2003, "SUB_DISTRICT_CODE": "301807", "SUB_DISTRICT_NAME": "มะเกลือเก่า", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2004, "SUB_DISTRICT_CODE": "301808", "SUB_DISTRICT_NAME": "มะเกลือใหม่", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2005, "SUB_DISTRICT_CODE": "301809", "SUB_DISTRICT_NAME": "นากลาง", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2006, "SUB_DISTRICT_CODE": "301810", "SUB_DISTRICT_NAME": "หนองตะไก้", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2007, "SUB_DISTRICT_CODE": "301811", "SUB_DISTRICT_NAME": "กุดจิก", "DISTRICT_ID": 232, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2008, "SUB_DISTRICT_CODE": "301901", "SUB_DISTRICT_NAME": "ขามทะเลสอ", "DISTRICT_ID": 233, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2009, "SUB_DISTRICT_CODE": "301902", "SUB_DISTRICT_NAME": "โป่งแดง", "DISTRICT_ID": 233, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2010, "SUB_DISTRICT_CODE": "301903", "SUB_DISTRICT_NAME": "พันดุง", "DISTRICT_ID": 233, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2011, "SUB_DISTRICT_CODE": "301904", "SUB_DISTRICT_NAME": "หนองสรวง", "DISTRICT_ID": 233, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2012, "SUB_DISTRICT_CODE": "301905", "SUB_DISTRICT_NAME": "บึงอ้อ", "DISTRICT_ID": 233, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2013, "SUB_DISTRICT_CODE": "302001", "SUB_DISTRICT_NAME": "สีคิ้ว", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2014, "SUB_DISTRICT_CODE": "302002", "SUB_DISTRICT_NAME": "บ้านหัน", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2015, "SUB_DISTRICT_CODE": "302003", "SUB_DISTRICT_NAME": "กฤษณา", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2016, "SUB_DISTRICT_CODE": "302004", "SUB_DISTRICT_NAME": "ลาดบัวขาว", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2017, "SUB_DISTRICT_CODE": "302005", "SUB_DISTRICT_NAME": "หนองหญ้าขาว", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2018, "SUB_DISTRICT_CODE": "302006", "SUB_DISTRICT_NAME": "กุดน้อย", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2019, "SUB_DISTRICT_CODE": "302007", "SUB_DISTRICT_NAME": "หนองน้ำใส", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2020, "SUB_DISTRICT_CODE": "302008", "SUB_DISTRICT_NAME": "วังโรงใหญ่", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2021, "SUB_DISTRICT_CODE": "302009", "SUB_DISTRICT_NAME": "มิตรภาพ", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2022, "SUB_DISTRICT_CODE": "302010", "SUB_DISTRICT_NAME": "คลองไผ่", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2023, "SUB_DISTRICT_CODE": "302011", "SUB_DISTRICT_NAME": "ดอนเมือง", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2024, "SUB_DISTRICT_CODE": "302012", "SUB_DISTRICT_NAME": "หนองบัวน้อย", "DISTRICT_ID": 234, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2025, "SUB_DISTRICT_CODE": "302101", "SUB_DISTRICT_NAME": "ปากช่อง", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2026, "SUB_DISTRICT_CODE": "302102", "SUB_DISTRICT_NAME": "กลางดง", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2027, "SUB_DISTRICT_CODE": "302103", "SUB_DISTRICT_NAME": "จันทึก", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2028, "SUB_DISTRICT_CODE": "302104", "SUB_DISTRICT_NAME": "วังกะทะ", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2029, "SUB_DISTRICT_CODE": "302105", "SUB_DISTRICT_NAME": "หมูสี", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2030, "SUB_DISTRICT_CODE": "302106", "SUB_DISTRICT_NAME": "หนองสาหร่าย", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2031, "SUB_DISTRICT_CODE": "302107", "SUB_DISTRICT_NAME": "ขนงพระ", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2032, "SUB_DISTRICT_CODE": "302108", "SUB_DISTRICT_NAME": "โป่งตาลอง", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2033, "SUB_DISTRICT_CODE": "302109", "SUB_DISTRICT_NAME": "คลองม่วง", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2034, "SUB_DISTRICT_CODE": "302110", "SUB_DISTRICT_NAME": "หนองน้ำแดง", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2035, "SUB_DISTRICT_CODE": "302111", "SUB_DISTRICT_NAME": "วังไทร", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2036, "SUB_DISTRICT_CODE": "302112", "SUB_DISTRICT_NAME": "พญาเย็น", "DISTRICT_ID": 235, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2037, "SUB_DISTRICT_CODE": "302201", "SUB_DISTRICT_NAME": "หนองบุนนาก", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2038, "SUB_DISTRICT_CODE": "302202", "SUB_DISTRICT_NAME": "สารภี", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2039, "SUB_DISTRICT_CODE": "302203", "SUB_DISTRICT_NAME": "ไทยเจริญ", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2040, "SUB_DISTRICT_CODE": "302204", "SUB_DISTRICT_NAME": "หนองหัวแรต", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2041, "SUB_DISTRICT_CODE": "302205", "SUB_DISTRICT_NAME": "แหลมทอง", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2042, "SUB_DISTRICT_CODE": "302206", "SUB_DISTRICT_NAME": "หนองตะไก้", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2043, "SUB_DISTRICT_CODE": "302207", "SUB_DISTRICT_NAME": "ลุงเขว้า", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2044, "SUB_DISTRICT_CODE": "302208", "SUB_DISTRICT_NAME": "หนองไม้ไผ่", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2045, "SUB_DISTRICT_CODE": "302209", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 236, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2046, "SUB_DISTRICT_CODE": "302301", "SUB_DISTRICT_NAME": "แก้งสนามนาง", "DISTRICT_ID": 237, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2047, "SUB_DISTRICT_CODE": "302302", "SUB_DISTRICT_NAME": "โนนสำราญ", "DISTRICT_ID": 237, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2048, "SUB_DISTRICT_CODE": "302303", "SUB_DISTRICT_NAME": "บึงพะไล", "DISTRICT_ID": 237, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2049, "SUB_DISTRICT_CODE": "302304", "SUB_DISTRICT_NAME": "สีสุก", "DISTRICT_ID": 237, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2050, "SUB_DISTRICT_CODE": "302305", "SUB_DISTRICT_NAME": "บึงสำโรง", "DISTRICT_ID": 237, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2051, "SUB_DISTRICT_CODE": "302401", "SUB_DISTRICT_NAME": "โนนแดง", "DISTRICT_ID": 238, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2052, "SUB_DISTRICT_CODE": "302402", "SUB_DISTRICT_NAME": "โนนตาเถร", "DISTRICT_ID": 238, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2053, "SUB_DISTRICT_CODE": "302403", "SUB_DISTRICT_NAME": "สำพะเนียง", "DISTRICT_ID": 238, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2054, "SUB_DISTRICT_CODE": "302404", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 238, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2055, "SUB_DISTRICT_CODE": "302405", "SUB_DISTRICT_NAME": "ดอนยาวใหญ่", "DISTRICT_ID": 238, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2056, "SUB_DISTRICT_CODE": "302501", "SUB_DISTRICT_NAME": "วังน้ำเขียว", "DISTRICT_ID": 239, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2057, "SUB_DISTRICT_CODE": "302502", "SUB_DISTRICT_NAME": "วังหมี", "DISTRICT_ID": 239, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2058, "SUB_DISTRICT_CODE": "302503", "SUB_DISTRICT_NAME": "ระเริง", "DISTRICT_ID": 239, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2059, "SUB_DISTRICT_CODE": "302504", "SUB_DISTRICT_NAME": "อุดมทรัพย์", "DISTRICT_ID": 239, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2060, "SUB_DISTRICT_CODE": "302505", "SUB_DISTRICT_NAME": "ไทยสามัคคี", "DISTRICT_ID": 239, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2061, "SUB_DISTRICT_CODE": "302601", "SUB_DISTRICT_NAME": "สำนักตะคร้อ", "DISTRICT_ID": 240, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2062, "SUB_DISTRICT_CODE": "302602", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 240, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2063, "SUB_DISTRICT_CODE": "302603", "SUB_DISTRICT_NAME": "บึงปรือ", "DISTRICT_ID": 240, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2064, "SUB_DISTRICT_CODE": "302604", "SUB_DISTRICT_NAME": "วังยายทอง", "DISTRICT_ID": 240, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2065, "SUB_DISTRICT_CODE": "302701", "SUB_DISTRICT_NAME": "เมืองยาง", "DISTRICT_ID": 241, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2066, "SUB_DISTRICT_CODE": "302702", "SUB_DISTRICT_NAME": "กระเบื้องนอก", "DISTRICT_ID": 241, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2067, "SUB_DISTRICT_CODE": "302703", "SUB_DISTRICT_NAME": "ละหานปลาค้าว", "DISTRICT_ID": 241, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2068, "SUB_DISTRICT_CODE": "302704", "SUB_DISTRICT_NAME": "โนนอุดม", "DISTRICT_ID": 241, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2069, "SUB_DISTRICT_CODE": "302801", "SUB_DISTRICT_NAME": "สระพระ", "DISTRICT_ID": 242, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2070, "SUB_DISTRICT_CODE": "302802", "SUB_DISTRICT_NAME": "มาบกราด", "DISTRICT_ID": 242, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2071, "SUB_DISTRICT_CODE": "302803", "SUB_DISTRICT_NAME": "พังเทียม", "DISTRICT_ID": 242, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2072, "SUB_DISTRICT_CODE": "302804", "SUB_DISTRICT_NAME": "ทัพรั้ง", "DISTRICT_ID": 242, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2073, "SUB_DISTRICT_CODE": "302805", "SUB_DISTRICT_NAME": "หนองหอย", "DISTRICT_ID": 242, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2074, "SUB_DISTRICT_CODE": "302901", "SUB_DISTRICT_NAME": "ขุย", "DISTRICT_ID": 243, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2075, "SUB_DISTRICT_CODE": "302902", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 243, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2076, "SUB_DISTRICT_CODE": "302903", "SUB_DISTRICT_NAME": "ช่องแมว", "DISTRICT_ID": 243, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2077, "SUB_DISTRICT_CODE": "302904", "SUB_DISTRICT_NAME": "ไพล", "DISTRICT_ID": 243, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2078, "SUB_DISTRICT_CODE": "303001", "SUB_DISTRICT_NAME": "เมืองพะไล", "DISTRICT_ID": 244, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2079, "SUB_DISTRICT_CODE": "303002", "SUB_DISTRICT_NAME": "โนนจาน", "DISTRICT_ID": 244, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2080, "SUB_DISTRICT_CODE": "303003", "SUB_DISTRICT_NAME": "บัวลาย", "DISTRICT_ID": 244, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2081, "SUB_DISTRICT_CODE": "303004", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 244, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2082, "SUB_DISTRICT_CODE": "303101", "SUB_DISTRICT_NAME": "สีดา", "DISTRICT_ID": 245, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2083, "SUB_DISTRICT_CODE": "303102", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 245, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2084, "SUB_DISTRICT_CODE": "303103", "SUB_DISTRICT_NAME": "โนนประดู่", "DISTRICT_ID": 245, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2085, "SUB_DISTRICT_CODE": "303104", "SUB_DISTRICT_NAME": "สามเมือง", "DISTRICT_ID": 245, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2086, "SUB_DISTRICT_CODE": "303105", "SUB_DISTRICT_NAME": "หนองตาดใหญ่", "DISTRICT_ID": 245, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2087, "SUB_DISTRICT_CODE": "303201", "SUB_DISTRICT_NAME": "ช้างทอง", "DISTRICT_ID": 246, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2088, "SUB_DISTRICT_CODE": "303202", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 246, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2089, "SUB_DISTRICT_CODE": "303203", "SUB_DISTRICT_NAME": "พระพุทธ", "DISTRICT_ID": 246, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2090, "SUB_DISTRICT_CODE": "303204", "SUB_DISTRICT_NAME": "หนองงูเหลือม", "DISTRICT_ID": 246, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2091, "SUB_DISTRICT_CODE": "303205", "SUB_DISTRICT_NAME": "หนองยาง", "DISTRICT_ID": 246, "PROVINCE_ID": 19, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2092, "SUB_DISTRICT_CODE": "310101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2093, "SUB_DISTRICT_CODE": "310102", "SUB_DISTRICT_NAME": "อิสาณ", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2094, "SUB_DISTRICT_CODE": "310103", "SUB_DISTRICT_NAME": "เสม็ด", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2095, "SUB_DISTRICT_CODE": "310104", "SUB_DISTRICT_NAME": "บ้านบัว", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2096, "SUB_DISTRICT_CODE": "310105", "SUB_DISTRICT_NAME": "สะแกโพรง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2097, "SUB_DISTRICT_CODE": "310106", "SUB_DISTRICT_NAME": "สวายจีก", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2098, "SUB_DISTRICT_CODE": "310107", "SUB_DISTRICT_NAME": "*ห้วยราช", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2099, "SUB_DISTRICT_CODE": "310108", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2100, "SUB_DISTRICT_CODE": "310109", "SUB_DISTRICT_NAME": "บ้านด่าน*", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2101, "SUB_DISTRICT_CODE": "310110", "SUB_DISTRICT_NAME": "*สามแวง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2102, "SUB_DISTRICT_CODE": "310111", "SUB_DISTRICT_NAME": "ปราสาท*", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2103, "SUB_DISTRICT_CODE": "310112", "SUB_DISTRICT_NAME": "พระครู", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2104, "SUB_DISTRICT_CODE": "310113", "SUB_DISTRICT_NAME": "ถลุงเหล็ก", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2105, "SUB_DISTRICT_CODE": "310114", "SUB_DISTRICT_NAME": "หนองตาด", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2106, "SUB_DISTRICT_CODE": "310115", "SUB_DISTRICT_NAME": "โนนขวาง*", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2107, "SUB_DISTRICT_CODE": "310116", "SUB_DISTRICT_NAME": "*ตาเสา", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2108, "SUB_DISTRICT_CODE": "310117", "SUB_DISTRICT_NAME": "ลุมปุ๊ก", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2109, "SUB_DISTRICT_CODE": "310118", "SUB_DISTRICT_NAME": "สองห้อง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2110, "SUB_DISTRICT_CODE": "310119", "SUB_DISTRICT_NAME": "บัวทอง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2111, "SUB_DISTRICT_CODE": "310120", "SUB_DISTRICT_NAME": "ชุมเห็ด", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2112, "SUB_DISTRICT_CODE": "310121", "SUB_DISTRICT_NAME": "*สนวน", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2113, "SUB_DISTRICT_CODE": "310122", "SUB_DISTRICT_NAME": "หลักเขต", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2114, "SUB_DISTRICT_CODE": "310123", "SUB_DISTRICT_NAME": "วังเหนือ*", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2115, "SUB_DISTRICT_CODE": "310124", "SUB_DISTRICT_NAME": "*บ้านตะโก", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2116, "SUB_DISTRICT_CODE": "310125", "SUB_DISTRICT_NAME": "สะแกซำ", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2117, "SUB_DISTRICT_CODE": "310126", "SUB_DISTRICT_NAME": "กลันทา", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2118, "SUB_DISTRICT_CODE": "310127", "SUB_DISTRICT_NAME": "กระสัง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2119, "SUB_DISTRICT_CODE": "310128", "SUB_DISTRICT_NAME": "เมืองฝาง", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2120, "SUB_DISTRICT_CODE": "310198", "SUB_DISTRICT_NAME": "*ปะเคียบ", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2121, "SUB_DISTRICT_CODE": "310199", "SUB_DISTRICT_NAME": "*ห้วยราช", "DISTRICT_ID": 250, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2122, "SUB_DISTRICT_CODE": "310201", "SUB_DISTRICT_NAME": "คูเมือง", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2123, "SUB_DISTRICT_CODE": "310202", "SUB_DISTRICT_NAME": "ปะเคียบ", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2124, "SUB_DISTRICT_CODE": "310203", "SUB_DISTRICT_NAME": "บ้านแพ", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2125, "SUB_DISTRICT_CODE": "310204", "SUB_DISTRICT_NAME": "พรสำราญ", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2126, "SUB_DISTRICT_CODE": "310205", "SUB_DISTRICT_NAME": "หินเหล็กไฟ", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2127, "SUB_DISTRICT_CODE": "310206", "SUB_DISTRICT_NAME": "ตูมใหญ่", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2128, "SUB_DISTRICT_CODE": "310207", "SUB_DISTRICT_NAME": "หนองขมาร", "DISTRICT_ID": 251, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2129, "SUB_DISTRICT_CODE": "310301", "SUB_DISTRICT_NAME": "กระสัง", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2130, "SUB_DISTRICT_CODE": "310302", "SUB_DISTRICT_NAME": "ลำดวน", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2131, "SUB_DISTRICT_CODE": "310303", "SUB_DISTRICT_NAME": "สองชั้น", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2132, "SUB_DISTRICT_CODE": "310304", "SUB_DISTRICT_NAME": "สูงเนิน", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2133, "SUB_DISTRICT_CODE": "310305", "SUB_DISTRICT_NAME": "หนองเต็ง", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2134, "SUB_DISTRICT_CODE": "310306", "SUB_DISTRICT_NAME": "เมืองไผ่", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2135, "SUB_DISTRICT_CODE": "310307", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2136, "SUB_DISTRICT_CODE": "310308", "SUB_DISTRICT_NAME": "บ้านปรือ", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2137, "SUB_DISTRICT_CODE": "310309", "SUB_DISTRICT_NAME": "ห้วยสำราญ", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2138, "SUB_DISTRICT_CODE": "310310", "SUB_DISTRICT_NAME": "กันทรารมย์", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2139, "SUB_DISTRICT_CODE": "310311", "SUB_DISTRICT_NAME": "ศรีภูมิ", "DISTRICT_ID": 252, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2140, "SUB_DISTRICT_CODE": "310401", "SUB_DISTRICT_NAME": "นางรอง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2141, "SUB_DISTRICT_CODE": "310402", "SUB_DISTRICT_NAME": "ตาเป๊ก*", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2142, "SUB_DISTRICT_CODE": "310403", "SUB_DISTRICT_NAME": "สะเดา", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2143, "SUB_DISTRICT_CODE": "310404", "SUB_DISTRICT_NAME": "*ชำนิ", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2144, "SUB_DISTRICT_CODE": "310405", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2145, "SUB_DISTRICT_CODE": "310406", "SUB_DISTRICT_NAME": "หนองโบสถ์", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2146, "SUB_DISTRICT_CODE": "310407", "SUB_DISTRICT_NAME": "*หนองปล่อง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2147, "SUB_DISTRICT_CODE": "310408", "SUB_DISTRICT_NAME": "หนองกง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2148, "SUB_DISTRICT_CODE": "310409", "SUB_DISTRICT_NAME": "*ทุ่งจังหัน", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2149, "SUB_DISTRICT_CODE": "310410", "SUB_DISTRICT_NAME": "*เมืองยาง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2150, "SUB_DISTRICT_CODE": "310411", "SUB_DISTRICT_NAME": "เจริญสุข*", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2151, "SUB_DISTRICT_CODE": "310412", "SUB_DISTRICT_NAME": "*โนนสุวรรณ", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2152, "SUB_DISTRICT_CODE": "310413", "SUB_DISTRICT_NAME": "ถนนหัก", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2153, "SUB_DISTRICT_CODE": "310414", "SUB_DISTRICT_NAME": "หนองไทร", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2154, "SUB_DISTRICT_CODE": "310415", "SUB_DISTRICT_NAME": "ก้านเหลือง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2155, "SUB_DISTRICT_CODE": "310416", "SUB_DISTRICT_NAME": "บ้านสิงห์", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2156, "SUB_DISTRICT_CODE": "310417", "SUB_DISTRICT_NAME": "ลำไทรโยง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2157, "SUB_DISTRICT_CODE": "310418", "SUB_DISTRICT_NAME": "ทรัพย์พระยา", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2158, "SUB_DISTRICT_CODE": "310419", "SUB_DISTRICT_NAME": "อีสานเขต*", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2159, "SUB_DISTRICT_CODE": "310420", "SUB_DISTRICT_NAME": "*ดงอีจาน", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2160, "SUB_DISTRICT_CODE": "310421", "SUB_DISTRICT_NAME": "*โกรกแก้ว", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2161, "SUB_DISTRICT_CODE": "310422", "SUB_DISTRICT_NAME": "*ช่อผกา", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2162, "SUB_DISTRICT_CODE": "310423", "SUB_DISTRICT_NAME": "*ละลวด", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2163, "SUB_DISTRICT_CODE": "310424", "SUB_DISTRICT_NAME": "หนองยายพิมพ์", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2164, "SUB_DISTRICT_CODE": "310425", "SUB_DISTRICT_NAME": "หัวถนน", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2165, "SUB_DISTRICT_CODE": "310426", "SUB_DISTRICT_NAME": "ทุ่งแสงทอง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2166, "SUB_DISTRICT_CODE": "310427", "SUB_DISTRICT_NAME": "หนองโสน", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2167, "SUB_DISTRICT_CODE": "310494", "SUB_DISTRICT_NAME": "*หนองปล่อง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2168, "SUB_DISTRICT_CODE": "310495", "SUB_DISTRICT_NAME": "*ชำนิ", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2169, "SUB_DISTRICT_CODE": "310496", "SUB_DISTRICT_NAME": "*ดอนอะราง", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2170, "SUB_DISTRICT_CODE": "310497", "SUB_DISTRICT_NAME": "*เมืองไผ่", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2171, "SUB_DISTRICT_CODE": "310498", "SUB_DISTRICT_NAME": "*เย้ยปราสาท", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2172, "SUB_DISTRICT_CODE": "310499", "SUB_DISTRICT_NAME": "*หนองกี่", "DISTRICT_ID": 253, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2173, "SUB_DISTRICT_CODE": "310501", "SUB_DISTRICT_NAME": "หนองกี่", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2174, "SUB_DISTRICT_CODE": "310502", "SUB_DISTRICT_NAME": "เย้ยปราสาท", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2175, "SUB_DISTRICT_CODE": "310503", "SUB_DISTRICT_NAME": "เมืองไผ่", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2176, "SUB_DISTRICT_CODE": "310504", "SUB_DISTRICT_NAME": "ดอนอะราง", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2177, "SUB_DISTRICT_CODE": "310505", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2178, "SUB_DISTRICT_CODE": "310506", "SUB_DISTRICT_NAME": "ทุ่งกระตาดพัฒนา", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2179, "SUB_DISTRICT_CODE": "310507", "SUB_DISTRICT_NAME": "ทุ่งกระเต็น", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2180, "SUB_DISTRICT_CODE": "310508", "SUB_DISTRICT_NAME": "ท่าโพธิ์ชัย", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2181, "SUB_DISTRICT_CODE": "310509", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2182, "SUB_DISTRICT_CODE": "310510", "SUB_DISTRICT_NAME": "บุกระสัง", "DISTRICT_ID": 254, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2183, "SUB_DISTRICT_CODE": "310601", "SUB_DISTRICT_NAME": "ละหานทราย", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2184, "SUB_DISTRICT_CODE": "310602", "SUB_DISTRICT_NAME": "ถาวร*", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2185, "SUB_DISTRICT_CODE": "310603", "SUB_DISTRICT_NAME": "ตาจง", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2186, "SUB_DISTRICT_CODE": "310604", "SUB_DISTRICT_NAME": "สำโรงใหม่", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2187, "SUB_DISTRICT_CODE": "310605", "SUB_DISTRICT_NAME": "*โนนดินแดง", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2188, "SUB_DISTRICT_CODE": "310606", "SUB_DISTRICT_NAME": "ยายแย้มวัฒนา*", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2189, "SUB_DISTRICT_CODE": "310607", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2190, "SUB_DISTRICT_CODE": "310608", "SUB_DISTRICT_NAME": "*ลำนางรอง", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2191, "SUB_DISTRICT_CODE": "310609", "SUB_DISTRICT_NAME": "*ส้มป่อย", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2192, "SUB_DISTRICT_CODE": "310610", "SUB_DISTRICT_NAME": "หนองตระครอง", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2193, "SUB_DISTRICT_CODE": "310611", "SUB_DISTRICT_NAME": "โคกว่าน", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2194, "SUB_DISTRICT_CODE": "310699", "SUB_DISTRICT_NAME": "*ไทยเจริญ", "DISTRICT_ID": 255, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2195, "SUB_DISTRICT_CODE": "310701", "SUB_DISTRICT_NAME": "ประโคนชัย", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2196, "SUB_DISTRICT_CODE": "310702", "SUB_DISTRICT_NAME": "แสลงโทน", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2197, "SUB_DISTRICT_CODE": "310703", "SUB_DISTRICT_NAME": "บ้านไทร", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2198, "SUB_DISTRICT_CODE": "310704", "SUB_DISTRICT_NAME": "*จันดุม", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2199, "SUB_DISTRICT_CODE": "310705", "SUB_DISTRICT_NAME": "ละเวี้ย", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2200, "SUB_DISTRICT_CODE": "310706", "SUB_DISTRICT_NAME": "จรเข้มาก", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2201, "SUB_DISTRICT_CODE": "310707", "SUB_DISTRICT_NAME": "ปังกู", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2202, "SUB_DISTRICT_CODE": "310708", "SUB_DISTRICT_NAME": "โคกย่าง", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2203, "SUB_DISTRICT_CODE": "310709", "SUB_DISTRICT_NAME": "*โคกขมิ้น", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2204, "SUB_DISTRICT_CODE": "310710", "SUB_DISTRICT_NAME": "โคกม้า", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2205, "SUB_DISTRICT_CODE": "310711", "SUB_DISTRICT_NAME": "*ป่าชัน", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2206, "SUB_DISTRICT_CODE": "310712", "SUB_DISTRICT_NAME": "*สะเดา", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2207, "SUB_DISTRICT_CODE": "310713", "SUB_DISTRICT_NAME": "ไพศาล", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2208, "SUB_DISTRICT_CODE": "310714", "SUB_DISTRICT_NAME": "ตะโกตาพิ", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2209, "SUB_DISTRICT_CODE": "310715", "SUB_DISTRICT_NAME": "เขาคอก", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2210, "SUB_DISTRICT_CODE": "310716", "SUB_DISTRICT_NAME": "หนองบอน", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2211, "SUB_DISTRICT_CODE": "310717", "SUB_DISTRICT_NAME": "*สำโรง", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2212, "SUB_DISTRICT_CODE": "310718", "SUB_DISTRICT_NAME": "โคกมะขาม", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2213, "SUB_DISTRICT_CODE": "310719", "SUB_DISTRICT_NAME": "โคกตูม", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2214, "SUB_DISTRICT_CODE": "310720", "SUB_DISTRICT_NAME": "ประทัดบุ", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2215, "SUB_DISTRICT_CODE": "310721", "SUB_DISTRICT_NAME": "สี่เหลี่ยม", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2216, "SUB_DISTRICT_CODE": "310797", "SUB_DISTRICT_NAME": "*ป่าชัน", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2217, "SUB_DISTRICT_CODE": "310798", "SUB_DISTRICT_NAME": "*โคกขมิ้น", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2218, "SUB_DISTRICT_CODE": "310799", "SUB_DISTRICT_NAME": "*จันดุม", "DISTRICT_ID": 256, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2219, "SUB_DISTRICT_CODE": "310801", "SUB_DISTRICT_NAME": "บ้านกรวด", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2220, "SUB_DISTRICT_CODE": "310802", "SUB_DISTRICT_NAME": "โนนเจริญ", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2221, "SUB_DISTRICT_CODE": "310803", "SUB_DISTRICT_NAME": "หนองไม้งาม", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2222, "SUB_DISTRICT_CODE": "310804", "SUB_DISTRICT_NAME": "ปราสาท", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2223, "SUB_DISTRICT_CODE": "310805", "SUB_DISTRICT_NAME": "สายตะกู", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2224, "SUB_DISTRICT_CODE": "310806", "SUB_DISTRICT_NAME": "หินลาด", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2225, "SUB_DISTRICT_CODE": "310807", "SUB_DISTRICT_NAME": "บึงเจริญ", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2226, "SUB_DISTRICT_CODE": "310808", "SUB_DISTRICT_NAME": "จันทบเพชร", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2227, "SUB_DISTRICT_CODE": "310809", "SUB_DISTRICT_NAME": "เขาดินเหนือ", "DISTRICT_ID": 257, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2228, "SUB_DISTRICT_CODE": "310901", "SUB_DISTRICT_NAME": "พุทไธสง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2229, "SUB_DISTRICT_CODE": "310902", "SUB_DISTRICT_NAME": "มะเฟือง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2230, "SUB_DISTRICT_CODE": "310903", "SUB_DISTRICT_NAME": "บ้านจาน", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2231, "SUB_DISTRICT_CODE": "310904", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2232, "SUB_DISTRICT_CODE": "310905", "SUB_DISTRICT_NAME": "*ทองหลาง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2233, "SUB_DISTRICT_CODE": "310906", "SUB_DISTRICT_NAME": "บ้านเป้า", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2234, "SUB_DISTRICT_CODE": "310907", "SUB_DISTRICT_NAME": "บ้านแวง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2235, "SUB_DISTRICT_CODE": "310908", "SUB_DISTRICT_NAME": "*บ้านแดงใหญ่", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2236, "SUB_DISTRICT_CODE": "310909", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2237, "SUB_DISTRICT_CODE": "310910", "SUB_DISTRICT_NAME": "หายโศก", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2238, "SUB_DISTRICT_CODE": "310911", "SUB_DISTRICT_NAME": "*กู่สวนแตง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2239, "SUB_DISTRICT_CODE": "310912", "SUB_DISTRICT_NAME": "*หนองเยือง", "DISTRICT_ID": 258, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2240, "SUB_DISTRICT_CODE": "311001", "SUB_DISTRICT_NAME": "ลำปลายมาศ", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2241, "SUB_DISTRICT_CODE": "311002", "SUB_DISTRICT_NAME": "หนองคู", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2242, "SUB_DISTRICT_CODE": "311003", "SUB_DISTRICT_NAME": "แสลงพัน", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2243, "SUB_DISTRICT_CODE": "311004", "SUB_DISTRICT_NAME": "ทะเมนชัย", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2244, "SUB_DISTRICT_CODE": "311005", "SUB_DISTRICT_NAME": "ตลาดโพธิ์", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2245, "SUB_DISTRICT_CODE": "311006", "SUB_DISTRICT_NAME": "หนองกะทิง", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2246, "SUB_DISTRICT_CODE": "311007", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2247, "SUB_DISTRICT_CODE": "311008", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2248, "SUB_DISTRICT_CODE": "311009", "SUB_DISTRICT_NAME": "เมืองแฝก", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2249, "SUB_DISTRICT_CODE": "311010", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2250, "SUB_DISTRICT_CODE": "311011", "SUB_DISTRICT_NAME": "ผไทรินทร์", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2251, "SUB_DISTRICT_CODE": "311012", "SUB_DISTRICT_NAME": "โคกล่าม", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2252, "SUB_DISTRICT_CODE": "311013", "SUB_DISTRICT_NAME": "หินโคน", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2253, "SUB_DISTRICT_CODE": "311014", "SUB_DISTRICT_NAME": "หนองบัวโคก", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2254, "SUB_DISTRICT_CODE": "311015", "SUB_DISTRICT_NAME": "บุโพธิ์", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2255, "SUB_DISTRICT_CODE": "311016", "SUB_DISTRICT_NAME": "หนองโดน", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2256, "SUB_DISTRICT_CODE": "311097", "SUB_DISTRICT_NAME": "*ไทยสามัคคี", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2257, "SUB_DISTRICT_CODE": "311098", "SUB_DISTRICT_NAME": "*ห้วยหิน", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2258, "SUB_DISTRICT_CODE": "311099", "SUB_DISTRICT_NAME": "*สระแก้ว", "DISTRICT_ID": 259, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2259, "SUB_DISTRICT_CODE": "311101", "SUB_DISTRICT_NAME": "สตึก", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2260, "SUB_DISTRICT_CODE": "311102", "SUB_DISTRICT_NAME": "นิคม", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2261, "SUB_DISTRICT_CODE": "311103", "SUB_DISTRICT_NAME": "ทุ่งวัง", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2262, "SUB_DISTRICT_CODE": "311104", "SUB_DISTRICT_NAME": "เมืองแก", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2263, "SUB_DISTRICT_CODE": "311105", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2264, "SUB_DISTRICT_CODE": "311106", "SUB_DISTRICT_NAME": "ร่อนทอง", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2265, "SUB_DISTRICT_CODE": "311107", "SUB_DISTRICT_NAME": "แคนดง*", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2266, "SUB_DISTRICT_CODE": "311108", "SUB_DISTRICT_NAME": "ดงพลอง*", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2267, "SUB_DISTRICT_CODE": "311109", "SUB_DISTRICT_NAME": "ดอนมนต์", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2268, "SUB_DISTRICT_CODE": "311110", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2269, "SUB_DISTRICT_CODE": "311111", "SUB_DISTRICT_NAME": "ท่าม่วง", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2270, "SUB_DISTRICT_CODE": "311112", "SUB_DISTRICT_NAME": "สะแก", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2271, "SUB_DISTRICT_CODE": "311113", "SUB_DISTRICT_NAME": "สระบัว*", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2272, "SUB_DISTRICT_CODE": "311114", "SUB_DISTRICT_NAME": "สนามชัย", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2273, "SUB_DISTRICT_CODE": "311115", "SUB_DISTRICT_NAME": "กระสัง", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2274, "SUB_DISTRICT_CODE": "311116", "SUB_DISTRICT_NAME": "หัวฝาย*", "DISTRICT_ID": 260, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2275, "SUB_DISTRICT_CODE": "311201", "SUB_DISTRICT_NAME": "ปะคำ", "DISTRICT_ID": 261, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2276, "SUB_DISTRICT_CODE": "311202", "SUB_DISTRICT_NAME": "ไทยเจริญ", "DISTRICT_ID": 261, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2277, "SUB_DISTRICT_CODE": "311203", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 261, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2278, "SUB_DISTRICT_CODE": "311204", "SUB_DISTRICT_NAME": "โคกมะม่วง", "DISTRICT_ID": 261, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2279, "SUB_DISTRICT_CODE": "311205", "SUB_DISTRICT_NAME": "หูทำนบ", "DISTRICT_ID": 261, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2280, "SUB_DISTRICT_CODE": "311301", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 262, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2281, "SUB_DISTRICT_CODE": "311302", "SUB_DISTRICT_NAME": "บ้านคู", "DISTRICT_ID": 262, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2282, "SUB_DISTRICT_CODE": "311303", "SUB_DISTRICT_NAME": "บ้านดู่", "DISTRICT_ID": 262, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2283, "SUB_DISTRICT_CODE": "311304", "SUB_DISTRICT_NAME": "ดอนกอก", "DISTRICT_ID": 262, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2284, "SUB_DISTRICT_CODE": "311305", "SUB_DISTRICT_NAME": "ศรีสว่าง", "DISTRICT_ID": 262, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2285, "SUB_DISTRICT_CODE": "311401", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2286, "SUB_DISTRICT_CODE": "311402", "SUB_DISTRICT_NAME": "ห้วยหิน", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2287, "SUB_DISTRICT_CODE": "311403", "SUB_DISTRICT_NAME": "ไทยสามัคคี", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2288, "SUB_DISTRICT_CODE": "311404", "SUB_DISTRICT_NAME": "หนองชัยศรี", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2289, "SUB_DISTRICT_CODE": "311405", "SUB_DISTRICT_NAME": "เสาเดียว", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2290, "SUB_DISTRICT_CODE": "311406", "SUB_DISTRICT_NAME": "เมืองฝ้าย", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2291, "SUB_DISTRICT_CODE": "311407", "SUB_DISTRICT_NAME": "สระทอง", "DISTRICT_ID": 263, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2292, "SUB_DISTRICT_CODE": "311501", "SUB_DISTRICT_NAME": "จันดุม", "DISTRICT_ID": 264, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2293, "SUB_DISTRICT_CODE": "311502", "SUB_DISTRICT_NAME": "โคกขมิ้น", "DISTRICT_ID": 264, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2294, "SUB_DISTRICT_CODE": "311503", "SUB_DISTRICT_NAME": "ป่าชัน", "DISTRICT_ID": 264, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2295, "SUB_DISTRICT_CODE": "311504", "SUB_DISTRICT_NAME": "สะเดา", "DISTRICT_ID": 264, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2296, "SUB_DISTRICT_CODE": "311505", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 264, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2297, "SUB_DISTRICT_CODE": "311601", "SUB_DISTRICT_NAME": "ห้วยราช", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2298, "SUB_DISTRICT_CODE": "311602", "SUB_DISTRICT_NAME": "สามแวง", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2299, "SUB_DISTRICT_CODE": "311603", "SUB_DISTRICT_NAME": "ตาเสา", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2300, "SUB_DISTRICT_CODE": "311604", "SUB_DISTRICT_NAME": "บ้านตะโก", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2301, "SUB_DISTRICT_CODE": "311605", "SUB_DISTRICT_NAME": "สนวน", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2302, "SUB_DISTRICT_CODE": "311606", "SUB_DISTRICT_NAME": "โคกเหล็ก", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2303, "SUB_DISTRICT_CODE": "311607", "SUB_DISTRICT_NAME": "เมืองโพธิ์", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2304, "SUB_DISTRICT_CODE": "311608", "SUB_DISTRICT_NAME": "ห้วยราชา", "DISTRICT_ID": 265, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2305, "SUB_DISTRICT_CODE": "311701", "SUB_DISTRICT_NAME": "โนนสุวรรณ", "DISTRICT_ID": 266, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2306, "SUB_DISTRICT_CODE": "311702", "SUB_DISTRICT_NAME": "ทุ่งจังหัน", "DISTRICT_ID": 266, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2307, "SUB_DISTRICT_CODE": "311703", "SUB_DISTRICT_NAME": "โกรกแก้ว", "DISTRICT_ID": 266, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2308, "SUB_DISTRICT_CODE": "311704", "SUB_DISTRICT_NAME": "ดงอีจาน", "DISTRICT_ID": 266, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2309, "SUB_DISTRICT_CODE": "311801", "SUB_DISTRICT_NAME": "ชำนิ", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2310, "SUB_DISTRICT_CODE": "311802", "SUB_DISTRICT_NAME": "หนองปล่อง", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2311, "SUB_DISTRICT_CODE": "311803", "SUB_DISTRICT_NAME": "เมืองยาง", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2312, "SUB_DISTRICT_CODE": "311804", "SUB_DISTRICT_NAME": "ช่อผกา", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2313, "SUB_DISTRICT_CODE": "311805", "SUB_DISTRICT_NAME": "ละลวด", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2314, "SUB_DISTRICT_CODE": "311806", "SUB_DISTRICT_NAME": "โคกสนวน", "DISTRICT_ID": 267, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2315, "SUB_DISTRICT_CODE": "311901", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 268, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2316, "SUB_DISTRICT_CODE": "311902", "SUB_DISTRICT_NAME": "ทองหลาง", "DISTRICT_ID": 268, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2317, "SUB_DISTRICT_CODE": "311903", "SUB_DISTRICT_NAME": "แดงใหญ่", "DISTRICT_ID": 268, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2318, "SUB_DISTRICT_CODE": "311904", "SUB_DISTRICT_NAME": "กู่สวนแตง", "DISTRICT_ID": 268, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2319, "SUB_DISTRICT_CODE": "311905", "SUB_DISTRICT_NAME": "หนองเยือง", "DISTRICT_ID": 268, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2320, "SUB_DISTRICT_CODE": "312001", "SUB_DISTRICT_NAME": "โนนดินแดง", "DISTRICT_ID": 269, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2321, "SUB_DISTRICT_CODE": "312002", "SUB_DISTRICT_NAME": "ส้มป่อย", "DISTRICT_ID": 269, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2322, "SUB_DISTRICT_CODE": "312003", "SUB_DISTRICT_NAME": "ลำนางรอง", "DISTRICT_ID": 269, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2323, "SUB_DISTRICT_CODE": "312101", "SUB_DISTRICT_NAME": "บ้านด่าน", "DISTRICT_ID": 270, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2324, "SUB_DISTRICT_CODE": "312102", "SUB_DISTRICT_NAME": "ปราสาท", "DISTRICT_ID": 270, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2325, "SUB_DISTRICT_CODE": "312103", "SUB_DISTRICT_NAME": "วังเหนือ", "DISTRICT_ID": 270, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2326, "SUB_DISTRICT_CODE": "312104", "SUB_DISTRICT_NAME": "โนนขวาง", "DISTRICT_ID": 270, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2327, "SUB_DISTRICT_CODE": "312201", "SUB_DISTRICT_NAME": "แคนดง", "DISTRICT_ID": 271, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2328, "SUB_DISTRICT_CODE": "312202", "SUB_DISTRICT_NAME": "ดงพลอง", "DISTRICT_ID": 271, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2329, "SUB_DISTRICT_CODE": "312203", "SUB_DISTRICT_NAME": "สระบัว", "DISTRICT_ID": 271, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2330, "SUB_DISTRICT_CODE": "312204", "SUB_DISTRICT_NAME": "หัวฝาย", "DISTRICT_ID": 271, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2331, "SUB_DISTRICT_CODE": "312301", "SUB_DISTRICT_NAME": "เจริญสุข", "DISTRICT_ID": 272, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2332, "SUB_DISTRICT_CODE": "312302", "SUB_DISTRICT_NAME": "ตาเป๊ก", "DISTRICT_ID": 272, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2333, "SUB_DISTRICT_CODE": "312303", "SUB_DISTRICT_NAME": "อีสานเขต", "DISTRICT_ID": 272, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2334, "SUB_DISTRICT_CODE": "312304", "SUB_DISTRICT_NAME": "ถาวร", "DISTRICT_ID": 272, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2335, "SUB_DISTRICT_CODE": "312305", "SUB_DISTRICT_NAME": "ยายแย้มวัฒนา", "DISTRICT_ID": 272, "PROVINCE_ID": 20, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2336, "SUB_DISTRICT_CODE": "320101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2337, "SUB_DISTRICT_CODE": "320102", "SUB_DISTRICT_NAME": "ตั้งใจ", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2338, "SUB_DISTRICT_CODE": "320103", "SUB_DISTRICT_NAME": "เพี้ยราม", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2339, "SUB_DISTRICT_CODE": "320104", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2340, "SUB_DISTRICT_CODE": "320105", "SUB_DISTRICT_NAME": "ท่าสว่าง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2341, "SUB_DISTRICT_CODE": "320106", "SUB_DISTRICT_NAME": "สลักได", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2342, "SUB_DISTRICT_CODE": "320107", "SUB_DISTRICT_NAME": "ตาอ็อง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2343, "SUB_DISTRICT_CODE": "320108", "SUB_DISTRICT_NAME": "ตากูก*", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2344, "SUB_DISTRICT_CODE": "320109", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2345, "SUB_DISTRICT_CODE": "320110", "SUB_DISTRICT_NAME": "แกใหญ่", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2346, "SUB_DISTRICT_CODE": "320111", "SUB_DISTRICT_NAME": "นอกเมือง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2347, "SUB_DISTRICT_CODE": "320112", "SUB_DISTRICT_NAME": "คอโค", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2348, "SUB_DISTRICT_CODE": "320113", "SUB_DISTRICT_NAME": "สวาย", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2349, "SUB_DISTRICT_CODE": "320114", "SUB_DISTRICT_NAME": "เฉนียง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2350, "SUB_DISTRICT_CODE": "320115", "SUB_DISTRICT_NAME": "บึง*", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2351, "SUB_DISTRICT_CODE": "320116", "SUB_DISTRICT_NAME": "เทนมีย์", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2352, "SUB_DISTRICT_CODE": "320117", "SUB_DISTRICT_NAME": "เขวาสินรินทร์*", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2353, "SUB_DISTRICT_CODE": "320118", "SUB_DISTRICT_NAME": "นาบัว", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2354, "SUB_DISTRICT_CODE": "320119", "SUB_DISTRICT_NAME": "เมืองที", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2355, "SUB_DISTRICT_CODE": "320120", "SUB_DISTRICT_NAME": "ราม", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2356, "SUB_DISTRICT_CODE": "320121", "SUB_DISTRICT_NAME": "บุฤาษี", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2357, "SUB_DISTRICT_CODE": "320122", "SUB_DISTRICT_NAME": "ตระแสง", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2358, "SUB_DISTRICT_CODE": "320123", "SUB_DISTRICT_NAME": "บ้านแร่*", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2359, "SUB_DISTRICT_CODE": "320124", "SUB_DISTRICT_NAME": "ปราสาททอง*", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2360, "SUB_DISTRICT_CODE": "320125", "SUB_DISTRICT_NAME": "แสลงพันธ์", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2361, "SUB_DISTRICT_CODE": "320126", "SUB_DISTRICT_NAME": "กาเกาะ", "DISTRICT_ID": 273, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2362, "SUB_DISTRICT_CODE": "320201", "SUB_DISTRICT_NAME": "ชุมพลบุรี", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2363, "SUB_DISTRICT_CODE": "320202", "SUB_DISTRICT_NAME": "นาหนองไผ่", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2364, "SUB_DISTRICT_CODE": "320203", "SUB_DISTRICT_NAME": "ไพรขลา", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2365, "SUB_DISTRICT_CODE": "320204", "SUB_DISTRICT_NAME": "ศรีณรงค์", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2366, "SUB_DISTRICT_CODE": "320205", "SUB_DISTRICT_NAME": "ยะวึก", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2367, "SUB_DISTRICT_CODE": "320206", "SUB_DISTRICT_NAME": "เมืองบัว", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2368, "SUB_DISTRICT_CODE": "320207", "SUB_DISTRICT_NAME": "สระขุด", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2369, "SUB_DISTRICT_CODE": "320208", "SUB_DISTRICT_NAME": "กระเบื้อง", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2370, "SUB_DISTRICT_CODE": "320209", "SUB_DISTRICT_NAME": "หนองเรือ", "DISTRICT_ID": 274, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2371, "SUB_DISTRICT_CODE": "320301", "SUB_DISTRICT_NAME": "ท่าตูม", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2372, "SUB_DISTRICT_CODE": "320302", "SUB_DISTRICT_NAME": "กระโพ", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2373, "SUB_DISTRICT_CODE": "320303", "SUB_DISTRICT_NAME": "พรมเทพ", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2374, "SUB_DISTRICT_CODE": "320304", "SUB_DISTRICT_NAME": "โพนครก", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2375, "SUB_DISTRICT_CODE": "320305", "SUB_DISTRICT_NAME": "เมืองแก", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2376, "SUB_DISTRICT_CODE": "320306", "SUB_DISTRICT_NAME": "บะ", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2377, "SUB_DISTRICT_CODE": "320307", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2378, "SUB_DISTRICT_CODE": "320308", "SUB_DISTRICT_NAME": "บัวโคก", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2379, "SUB_DISTRICT_CODE": "320309", "SUB_DISTRICT_NAME": "หนองเมธี", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2380, "SUB_DISTRICT_CODE": "320310", "SUB_DISTRICT_NAME": "ทุ่งกุลา", "DISTRICT_ID": 275, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2381, "SUB_DISTRICT_CODE": "320401", "SUB_DISTRICT_NAME": "จอมพระ", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2382, "SUB_DISTRICT_CODE": "320402", "SUB_DISTRICT_NAME": "เมืองลีง", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2383, "SUB_DISTRICT_CODE": "320403", "SUB_DISTRICT_NAME": "กระหาด", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2384, "SUB_DISTRICT_CODE": "320404", "SUB_DISTRICT_NAME": "บุแกรง", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2385, "SUB_DISTRICT_CODE": "320405", "SUB_DISTRICT_NAME": "หนองสนิท", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2386, "SUB_DISTRICT_CODE": "320406", "SUB_DISTRICT_NAME": "บ้านผือ", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2387, "SUB_DISTRICT_CODE": "320407", "SUB_DISTRICT_NAME": "ลุ่มระวี", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2388, "SUB_DISTRICT_CODE": "320408", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2389, "SUB_DISTRICT_CODE": "320409", "SUB_DISTRICT_NAME": "เป็นสุข", "DISTRICT_ID": 276, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2390, "SUB_DISTRICT_CODE": "320501", "SUB_DISTRICT_NAME": "กังแอน", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2391, "SUB_DISTRICT_CODE": "320502", "SUB_DISTRICT_NAME": "ทมอ", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2392, "SUB_DISTRICT_CODE": "320503", "SUB_DISTRICT_NAME": "ไพล", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2393, "SUB_DISTRICT_CODE": "320504", "SUB_DISTRICT_NAME": "ปรือ", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2394, "SUB_DISTRICT_CODE": "320505", "SUB_DISTRICT_NAME": "ทุ่งมน", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2395, "SUB_DISTRICT_CODE": "320506", "SUB_DISTRICT_NAME": "ตาเบา", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2396, "SUB_DISTRICT_CODE": "320507", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2397, "SUB_DISTRICT_CODE": "320508", "SUB_DISTRICT_NAME": "โคกยาง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2398, "SUB_DISTRICT_CODE": "320509", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2399, "SUB_DISTRICT_CODE": "320510", "SUB_DISTRICT_NAME": "บ้านไทร", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2400, "SUB_DISTRICT_CODE": "320511", "SUB_DISTRICT_NAME": "โชคนาสาม", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2401, "SUB_DISTRICT_CODE": "320512", "SUB_DISTRICT_NAME": "เชื้อเพลิง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2402, "SUB_DISTRICT_CODE": "320513", "SUB_DISTRICT_NAME": "ปราสาททนง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2403, "SUB_DISTRICT_CODE": "320514", "SUB_DISTRICT_NAME": "ตานี", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2404, "SUB_DISTRICT_CODE": "320515", "SUB_DISTRICT_NAME": "บ้านพลวง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2405, "SUB_DISTRICT_CODE": "320516", "SUB_DISTRICT_NAME": "กันตวจระมวล", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2406, "SUB_DISTRICT_CODE": "320517", "SUB_DISTRICT_NAME": "สมุด", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2407, "SUB_DISTRICT_CODE": "320518", "SUB_DISTRICT_NAME": "ประทัดบุ", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2408, "SUB_DISTRICT_CODE": "320595", "SUB_DISTRICT_NAME": "*ด่าน", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2409, "SUB_DISTRICT_CODE": "320596", "SUB_DISTRICT_NAME": "*คูตัน", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2410, "SUB_DISTRICT_CODE": "320597", "SUB_DISTRICT_NAME": "*โคกกลาง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2411, "SUB_DISTRICT_CODE": "320598", "SUB_DISTRICT_NAME": "*บักได", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2412, "SUB_DISTRICT_CODE": "320599", "SUB_DISTRICT_NAME": "*กาบเชิง", "DISTRICT_ID": 277, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2413, "SUB_DISTRICT_CODE": "320601", "SUB_DISTRICT_NAME": "กาบเชิง", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2414, "SUB_DISTRICT_CODE": "320602", "SUB_DISTRICT_NAME": "*บักได", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2415, "SUB_DISTRICT_CODE": "320603", "SUB_DISTRICT_NAME": "*โคกกลาง", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2416, "SUB_DISTRICT_CODE": "320604", "SUB_DISTRICT_NAME": "คูตัน", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2417, "SUB_DISTRICT_CODE": "320605", "SUB_DISTRICT_NAME": "ด่าน", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2418, "SUB_DISTRICT_CODE": "320606", "SUB_DISTRICT_NAME": "แนงมุด", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2419, "SUB_DISTRICT_CODE": "320607", "SUB_DISTRICT_NAME": "โคกตะเคียน", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2420, "SUB_DISTRICT_CODE": "320608", "SUB_DISTRICT_NAME": "*ตาเมียง", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2421, "SUB_DISTRICT_CODE": "320609", "SUB_DISTRICT_NAME": "*จีกแดก", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2422, "SUB_DISTRICT_CODE": "320610", "SUB_DISTRICT_NAME": "ตะเคียน", "DISTRICT_ID": 278, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2423, "SUB_DISTRICT_CODE": "320701", "SUB_DISTRICT_NAME": "รัตนบุรี", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2424, "SUB_DISTRICT_CODE": "320702", "SUB_DISTRICT_NAME": "ธาตุ", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2425, "SUB_DISTRICT_CODE": "320703", "SUB_DISTRICT_NAME": "แก", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2426, "SUB_DISTRICT_CODE": "320704", "SUB_DISTRICT_NAME": "ดอนแรด", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2427, "SUB_DISTRICT_CODE": "320705", "SUB_DISTRICT_NAME": "หนองบัวทอง", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2428, "SUB_DISTRICT_CODE": "320706", "SUB_DISTRICT_NAME": "หนองบัวบาน", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2429, "SUB_DISTRICT_CODE": "320707", "SUB_DISTRICT_NAME": "หนองหลวง*", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2430, "SUB_DISTRICT_CODE": "320708", "SUB_DISTRICT_NAME": "หนองเทพ*", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2431, "SUB_DISTRICT_CODE": "320709", "SUB_DISTRICT_NAME": "ไผ่", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2432, "SUB_DISTRICT_CODE": "320710", "SUB_DISTRICT_NAME": "โนน*", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2433, "SUB_DISTRICT_CODE": "320711", "SUB_DISTRICT_NAME": "เบิด", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2434, "SUB_DISTRICT_CODE": "320712", "SUB_DISTRICT_NAME": "ระเวียง*", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2435, "SUB_DISTRICT_CODE": "320713", "SUB_DISTRICT_NAME": "น้ำเขียว", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2436, "SUB_DISTRICT_CODE": "320714", "SUB_DISTRICT_NAME": "กุดขาคีม", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2437, "SUB_DISTRICT_CODE": "320715", "SUB_DISTRICT_NAME": "ยางสว่าง", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2438, "SUB_DISTRICT_CODE": "320716", "SUB_DISTRICT_NAME": "ทับใหญ่", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2439, "SUB_DISTRICT_CODE": "320717", "SUB_DISTRICT_NAME": "คำผง*", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2440, "SUB_DISTRICT_CODE": "320795", "SUB_DISTRICT_NAME": "*สนม", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2441, "SUB_DISTRICT_CODE": "320796", "SUB_DISTRICT_NAME": "*หนองระฆัง", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2442, "SUB_DISTRICT_CODE": "320797", "SUB_DISTRICT_NAME": "*นานวน", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2443, "SUB_DISTRICT_CODE": "320798", "SUB_DISTRICT_NAME": "*โพนโก", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2444, "SUB_DISTRICT_CODE": "320799", "SUB_DISTRICT_NAME": "*แคน", "DISTRICT_ID": 279, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2445, "SUB_DISTRICT_CODE": "320801", "SUB_DISTRICT_NAME": "สนม", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2446, "SUB_DISTRICT_CODE": "320802", "SUB_DISTRICT_NAME": "โพนโก", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2447, "SUB_DISTRICT_CODE": "320803", "SUB_DISTRICT_NAME": "หนองระฆัง", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2448, "SUB_DISTRICT_CODE": "320804", "SUB_DISTRICT_NAME": "นานวน", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2449, "SUB_DISTRICT_CODE": "320805", "SUB_DISTRICT_NAME": "แคน", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2450, "SUB_DISTRICT_CODE": "320806", "SUB_DISTRICT_NAME": "หัวงัว", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2451, "SUB_DISTRICT_CODE": "320807", "SUB_DISTRICT_NAME": "หนองอียอ", "DISTRICT_ID": 280, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2452, "SUB_DISTRICT_CODE": "320901", "SUB_DISTRICT_NAME": "ระแงง", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2453, "SUB_DISTRICT_CODE": "320902", "SUB_DISTRICT_NAME": "ตรึม", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2454, "SUB_DISTRICT_CODE": "320903", "SUB_DISTRICT_NAME": "จารพัต", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2455, "SUB_DISTRICT_CODE": "320904", "SUB_DISTRICT_NAME": "ยาง", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2456, "SUB_DISTRICT_CODE": "320905", "SUB_DISTRICT_NAME": "แตล", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2457, "SUB_DISTRICT_CODE": "320906", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2458, "SUB_DISTRICT_CODE": "320907", "SUB_DISTRICT_NAME": "คาละแมะ", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2459, "SUB_DISTRICT_CODE": "320908", "SUB_DISTRICT_NAME": "หนองเหล็ก", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2460, "SUB_DISTRICT_CODE": "320909", "SUB_DISTRICT_NAME": "หนองขวาว", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2461, "SUB_DISTRICT_CODE": "320910", "SUB_DISTRICT_NAME": "ช่างปี่", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2462, "SUB_DISTRICT_CODE": "320911", "SUB_DISTRICT_NAME": "กุดหวาย", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2463, "SUB_DISTRICT_CODE": "320912", "SUB_DISTRICT_NAME": "ขวาวใหญ่", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2464, "SUB_DISTRICT_CODE": "320913", "SUB_DISTRICT_NAME": "นารุ่ง", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2465, "SUB_DISTRICT_CODE": "320914", "SUB_DISTRICT_NAME": "ตรมไพร", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2466, "SUB_DISTRICT_CODE": "320915", "SUB_DISTRICT_NAME": "ผักไหม", "DISTRICT_ID": 281, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2467, "SUB_DISTRICT_CODE": "321001", "SUB_DISTRICT_NAME": "สังขะ", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2468, "SUB_DISTRICT_CODE": "321002", "SUB_DISTRICT_NAME": "ขอนแตก", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2469, "SUB_DISTRICT_CODE": "321003", "SUB_DISTRICT_NAME": "*ณรงค์", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2470, "SUB_DISTRICT_CODE": "321004", "SUB_DISTRICT_NAME": "*แจนแวน", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2471, "SUB_DISTRICT_CODE": "321005", "SUB_DISTRICT_NAME": "*ตรวจ", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2472, "SUB_DISTRICT_CODE": "321006", "SUB_DISTRICT_NAME": "ดม", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2473, "SUB_DISTRICT_CODE": "321007", "SUB_DISTRICT_NAME": "พระแก้ว", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2474, "SUB_DISTRICT_CODE": "321008", "SUB_DISTRICT_NAME": "บ้านจารย์", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2475, "SUB_DISTRICT_CODE": "321009", "SUB_DISTRICT_NAME": "กระเทียม", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2476, "SUB_DISTRICT_CODE": "321010", "SUB_DISTRICT_NAME": "สะกาด", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2477, "SUB_DISTRICT_CODE": "321011", "SUB_DISTRICT_NAME": "ตาตุม", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2478, "SUB_DISTRICT_CODE": "321012", "SUB_DISTRICT_NAME": "ทับทัน", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2479, "SUB_DISTRICT_CODE": "321013", "SUB_DISTRICT_NAME": "ตาคง", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2480, "SUB_DISTRICT_CODE": "321014", "SUB_DISTRICT_NAME": "*ศรีสุข", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2481, "SUB_DISTRICT_CODE": "321015", "SUB_DISTRICT_NAME": "บ้านชบ", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2482, "SUB_DISTRICT_CODE": "321016", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2483, "SUB_DISTRICT_CODE": "321017", "SUB_DISTRICT_NAME": "เทพรักษา", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2484, "SUB_DISTRICT_CODE": "321093", "SUB_DISTRICT_NAME": "*คูตัน", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2485, "SUB_DISTRICT_CODE": "321094", "SUB_DISTRICT_NAME": "*ด่าน", "DISTRICT_ID": 282, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2486, "SUB_DISTRICT_CODE": "321101", "SUB_DISTRICT_NAME": "ลำดวน", "DISTRICT_ID": 283, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2487, "SUB_DISTRICT_CODE": "321102", "SUB_DISTRICT_NAME": "โชคเหนือ", "DISTRICT_ID": 283, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2488, "SUB_DISTRICT_CODE": "321103", "SUB_DISTRICT_NAME": "อู่โลก", "DISTRICT_ID": 283, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2489, "SUB_DISTRICT_CODE": "321104", "SUB_DISTRICT_NAME": "ตรำดม", "DISTRICT_ID": 283, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2490, "SUB_DISTRICT_CODE": "321105", "SUB_DISTRICT_NAME": "ตระเปียงเตีย", "DISTRICT_ID": 283, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2491, "SUB_DISTRICT_CODE": "321201", "SUB_DISTRICT_NAME": "สำโรงทาบ", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2492, "SUB_DISTRICT_CODE": "321202", "SUB_DISTRICT_NAME": "หนองไผ่ล้อม", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2493, "SUB_DISTRICT_CODE": "321203", "SUB_DISTRICT_NAME": "กระออม", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2494, "SUB_DISTRICT_CODE": "321204", "SUB_DISTRICT_NAME": "หนองฮะ", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2495, "SUB_DISTRICT_CODE": "321205", "SUB_DISTRICT_NAME": "ศรีสุข", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2496, "SUB_DISTRICT_CODE": "321206", "SUB_DISTRICT_NAME": "เกาะแก้ว", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2497, "SUB_DISTRICT_CODE": "321207", "SUB_DISTRICT_NAME": "หมื่นศรี", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2498, "SUB_DISTRICT_CODE": "321208", "SUB_DISTRICT_NAME": "เสม็จ", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2499, "SUB_DISTRICT_CODE": "321209", "SUB_DISTRICT_NAME": "สะโน", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2500, "SUB_DISTRICT_CODE": "321210", "SUB_DISTRICT_NAME": "ประดู่", "DISTRICT_ID": 284, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2501, "SUB_DISTRICT_CODE": "321301", "SUB_DISTRICT_NAME": "บัวเชด", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2502, "SUB_DISTRICT_CODE": "321302", "SUB_DISTRICT_NAME": "สะเดา", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2503, "SUB_DISTRICT_CODE": "321303", "SUB_DISTRICT_NAME": "จรัส", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2504, "SUB_DISTRICT_CODE": "321304", "SUB_DISTRICT_NAME": "ตาวัง", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2505, "SUB_DISTRICT_CODE": "321305", "SUB_DISTRICT_NAME": "อาโพน", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2506, "SUB_DISTRICT_CODE": "321306", "SUB_DISTRICT_NAME": "สำเภาลูน", "DISTRICT_ID": 285, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2507, "SUB_DISTRICT_CODE": "321401", "SUB_DISTRICT_NAME": "บักได", "DISTRICT_ID": 286, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2508, "SUB_DISTRICT_CODE": "321402", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 286, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2509, "SUB_DISTRICT_CODE": "321403", "SUB_DISTRICT_NAME": "จีกแดก", "DISTRICT_ID": 286, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2510, "SUB_DISTRICT_CODE": "321404", "SUB_DISTRICT_NAME": "ตาเมียง", "DISTRICT_ID": 286, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2511, "SUB_DISTRICT_CODE": "321501", "SUB_DISTRICT_NAME": "ณรงค์", "DISTRICT_ID": 287, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2512, "SUB_DISTRICT_CODE": "321502", "SUB_DISTRICT_NAME": "แจนแวน", "DISTRICT_ID": 287, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2513, "SUB_DISTRICT_CODE": "321503", "SUB_DISTRICT_NAME": "ตรวจ", "DISTRICT_ID": 287, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2514, "SUB_DISTRICT_CODE": "321504", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 287, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2515, "SUB_DISTRICT_CODE": "321505", "SUB_DISTRICT_NAME": "ศรีสุข", "DISTRICT_ID": 287, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2516, "SUB_DISTRICT_CODE": "321601", "SUB_DISTRICT_NAME": "เขวาสินรินทร์", "DISTRICT_ID": 288, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2517, "SUB_DISTRICT_CODE": "321602", "SUB_DISTRICT_NAME": "บึง", "DISTRICT_ID": 288, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2518, "SUB_DISTRICT_CODE": "321603", "SUB_DISTRICT_NAME": "ตากูก", "DISTRICT_ID": 288, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2519, "SUB_DISTRICT_CODE": "321604", "SUB_DISTRICT_NAME": "ปราสาททอง", "DISTRICT_ID": 288, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2520, "SUB_DISTRICT_CODE": "321605", "SUB_DISTRICT_NAME": "บ้านแร่", "DISTRICT_ID": 288, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2521, "SUB_DISTRICT_CODE": "321701", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 289, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2522, "SUB_DISTRICT_CODE": "321702", "SUB_DISTRICT_NAME": "คำผง", "DISTRICT_ID": 289, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2523, "SUB_DISTRICT_CODE": "321703", "SUB_DISTRICT_NAME": "โนน", "DISTRICT_ID": 289, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2524, "SUB_DISTRICT_CODE": "321704", "SUB_DISTRICT_NAME": "ระเวียง", "DISTRICT_ID": 289, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2525, "SUB_DISTRICT_CODE": "321705", "SUB_DISTRICT_NAME": "หนองเทพ", "DISTRICT_ID": 289, "PROVINCE_ID": 21, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2526, "SUB_DISTRICT_CODE": "330101", "SUB_DISTRICT_NAME": "เมืองเหนือ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2527, "SUB_DISTRICT_CODE": "330102", "SUB_DISTRICT_NAME": "เมืองใต้", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2528, "SUB_DISTRICT_CODE": "330103", "SUB_DISTRICT_NAME": "คูซอด", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2529, "SUB_DISTRICT_CODE": "330104", "SUB_DISTRICT_NAME": "ซำ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2530, "SUB_DISTRICT_CODE": "330105", "SUB_DISTRICT_NAME": "จาน", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2531, "SUB_DISTRICT_CODE": "330106", "SUB_DISTRICT_NAME": "ตะดอบ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2532, "SUB_DISTRICT_CODE": "330107", "SUB_DISTRICT_NAME": "หนองครก", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2533, "SUB_DISTRICT_CODE": "330108", "SUB_DISTRICT_NAME": "*โนนเพ็ก", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2534, "SUB_DISTRICT_CODE": "330109", "SUB_DISTRICT_NAME": "*พรหมสวัสดิ์", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2535, "SUB_DISTRICT_CODE": "330110", "SUB_DISTRICT_NAME": "*พยุห์", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2536, "SUB_DISTRICT_CODE": "330111", "SUB_DISTRICT_NAME": "โพนข่า", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2537, "SUB_DISTRICT_CODE": "330112", "SUB_DISTRICT_NAME": "โพนค้อ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2538, "SUB_DISTRICT_CODE": "330113", "SUB_DISTRICT_NAME": "*ธาตุ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2539, "SUB_DISTRICT_CODE": "330114", "SUB_DISTRICT_NAME": "*ตำแย", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2540, "SUB_DISTRICT_CODE": "330115", "SUB_DISTRICT_NAME": "โพนเขวา", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2541, "SUB_DISTRICT_CODE": "330116", "SUB_DISTRICT_NAME": "หญ้าปล้อง", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2542, "SUB_DISTRICT_CODE": "330117", "SUB_DISTRICT_NAME": "*บุสูง", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2543, "SUB_DISTRICT_CODE": "330118", "SUB_DISTRICT_NAME": "ทุ่ม", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2544, "SUB_DISTRICT_CODE": "330119", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2545, "SUB_DISTRICT_CODE": "330120", "SUB_DISTRICT_NAME": "*ดวนใหญ่", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2546, "SUB_DISTRICT_CODE": "330121", "SUB_DISTRICT_NAME": "หนองแก้ว", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2547, "SUB_DISTRICT_CODE": "330122", "SUB_DISTRICT_NAME": "น้ำคำ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2548, "SUB_DISTRICT_CODE": "330123", "SUB_DISTRICT_NAME": "โพธิ์", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2549, "SUB_DISTRICT_CODE": "330124", "SUB_DISTRICT_NAME": "หมากเขียบ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2550, "SUB_DISTRICT_CODE": "330125", "SUB_DISTRICT_NAME": "*บ่อแก้ว", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2551, "SUB_DISTRICT_CODE": "330126", "SUB_DISTRICT_NAME": "*ศรีสำราญ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2552, "SUB_DISTRICT_CODE": "330127", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2553, "SUB_DISTRICT_CODE": "330128", "SUB_DISTRICT_NAME": "*หนองค้า", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2554, "SUB_DISTRICT_CODE": "330196", "SUB_DISTRICT_NAME": "*ดวนใหญ่", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2555, "SUB_DISTRICT_CODE": "330197", "SUB_DISTRICT_NAME": "*ธาตุ", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2556, "SUB_DISTRICT_CODE": "330198", "SUB_DISTRICT_NAME": "*บุสูง", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2557, "SUB_DISTRICT_CODE": "330199", "SUB_DISTRICT_NAME": "*คอนกาม", "DISTRICT_ID": 290, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2558, "SUB_DISTRICT_CODE": "330201", "SUB_DISTRICT_NAME": "ยางชุมน้อย", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2559, "SUB_DISTRICT_CODE": "330202", "SUB_DISTRICT_NAME": "ลิ้นฟ้า", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2560, "SUB_DISTRICT_CODE": "330203", "SUB_DISTRICT_NAME": "คอนกาม", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2561, "SUB_DISTRICT_CODE": "330204", "SUB_DISTRICT_NAME": "โนนคูณ", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2562, "SUB_DISTRICT_CODE": "330205", "SUB_DISTRICT_NAME": "กุดเมืองฮาม", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2563, "SUB_DISTRICT_CODE": "330206", "SUB_DISTRICT_NAME": "บึงบอน", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2564, "SUB_DISTRICT_CODE": "330207", "SUB_DISTRICT_NAME": "ยางชุมใหญ่", "DISTRICT_ID": 291, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2565, "SUB_DISTRICT_CODE": "330301", "SUB_DISTRICT_NAME": "ดูน", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2566, "SUB_DISTRICT_CODE": "330302", "SUB_DISTRICT_NAME": "โนนสัง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2567, "SUB_DISTRICT_CODE": "330303", "SUB_DISTRICT_NAME": "หนองหัวช้าง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2568, "SUB_DISTRICT_CODE": "330304", "SUB_DISTRICT_NAME": "ยาง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2569, "SUB_DISTRICT_CODE": "330305", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2570, "SUB_DISTRICT_CODE": "330306", "SUB_DISTRICT_NAME": "หนองแก้ว", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2571, "SUB_DISTRICT_CODE": "330307", "SUB_DISTRICT_NAME": "ทาม", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2572, "SUB_DISTRICT_CODE": "330308", "SUB_DISTRICT_NAME": "ละทาย", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2573, "SUB_DISTRICT_CODE": "330309", "SUB_DISTRICT_NAME": "เมืองน้อย", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2574, "SUB_DISTRICT_CODE": "330310", "SUB_DISTRICT_NAME": "อีปาด", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2575, "SUB_DISTRICT_CODE": "330311", "SUB_DISTRICT_NAME": "บัวน้อย", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2576, "SUB_DISTRICT_CODE": "330312", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2577, "SUB_DISTRICT_CODE": "330313", "SUB_DISTRICT_NAME": "ดู่", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2578, "SUB_DISTRICT_CODE": "330314", "SUB_DISTRICT_NAME": "ผักแพว", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2579, "SUB_DISTRICT_CODE": "330315", "SUB_DISTRICT_NAME": "จาน", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2580, "SUB_DISTRICT_CODE": "330316", "SUB_DISTRICT_NAME": "*ตองบิด", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2581, "SUB_DISTRICT_CODE": "330317", "SUB_DISTRICT_NAME": "*ละเอาะ", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2582, "SUB_DISTRICT_CODE": "330318", "SUB_DISTRICT_NAME": "*น้ำเกลี้ยง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2583, "SUB_DISTRICT_CODE": "330319", "SUB_DISTRICT_NAME": "*เขิน", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2584, "SUB_DISTRICT_CODE": "330320", "SUB_DISTRICT_NAME": "คำเนียม", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2585, "SUB_DISTRICT_CODE": "330394", "SUB_DISTRICT_NAME": "*ตองปิด", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2586, "SUB_DISTRICT_CODE": "330395", "SUB_DISTRICT_NAME": "*ละเอาะ", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2587, "SUB_DISTRICT_CODE": "330396", "SUB_DISTRICT_NAME": "*หนองกุง", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2588, "SUB_DISTRICT_CODE": "330397", "SUB_DISTRICT_NAME": "*โพธิ์", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2589, "SUB_DISTRICT_CODE": "330398", "SUB_DISTRICT_NAME": "*บก", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2590, "SUB_DISTRICT_CODE": "330399", "SUB_DISTRICT_NAME": "*โนนค้อ", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2591, "SUB_DISTRICT_CODE": "330401", "SUB_DISTRICT_NAME": "บึงมะลู", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2592, "SUB_DISTRICT_CODE": "330402", "SUB_DISTRICT_NAME": "กุดเสลา", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2593, "SUB_DISTRICT_CODE": "330403", "SUB_DISTRICT_NAME": "เมือง", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2594, "SUB_DISTRICT_CODE": "330404", "SUB_DISTRICT_NAME": "*หนองหว้า", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2595, "SUB_DISTRICT_CODE": "330405", "SUB_DISTRICT_NAME": "สังเม็ก", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2596, "SUB_DISTRICT_CODE": "330406", "SUB_DISTRICT_NAME": "น้ำอ้อม", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2597, "SUB_DISTRICT_CODE": "330407", "SUB_DISTRICT_NAME": "ละลาย", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2598, "SUB_DISTRICT_CODE": "330408", "SUB_DISTRICT_NAME": "รุง", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2599, "SUB_DISTRICT_CODE": "330409", "SUB_DISTRICT_NAME": "ตระกาจ", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2600, "SUB_DISTRICT_CODE": "330410", "SUB_DISTRICT_NAME": "*เสียว", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2601, "SUB_DISTRICT_CODE": "330411", "SUB_DISTRICT_NAME": "จานใหญ่", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2602, "SUB_DISTRICT_CODE": "330412", "SUB_DISTRICT_NAME": "ภูเงิน", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2603, "SUB_DISTRICT_CODE": "330413", "SUB_DISTRICT_NAME": "ชำ", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2604, "SUB_DISTRICT_CODE": "330414", "SUB_DISTRICT_NAME": "กระแชง", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2605, "SUB_DISTRICT_CODE": "330415", "SUB_DISTRICT_NAME": "โนนสำราญ", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2606, "SUB_DISTRICT_CODE": "330416", "SUB_DISTRICT_NAME": "หนองหญ้าลาด", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2607, "SUB_DISTRICT_CODE": "330417", "SUB_DISTRICT_NAME": "*หนองงูเหลือม", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2608, "SUB_DISTRICT_CODE": "330418", "SUB_DISTRICT_NAME": "*ท่าคล้อ", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2609, "SUB_DISTRICT_CODE": "330419", "SUB_DISTRICT_NAME": "เสาธงชัย", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2610, "SUB_DISTRICT_CODE": "330420", "SUB_DISTRICT_NAME": "ขนุน", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2611, "SUB_DISTRICT_CODE": "330421", "SUB_DISTRICT_NAME": "สวนกล้วย", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2612, "SUB_DISTRICT_CODE": "330422", "SUB_DISTRICT_NAME": "*หนองฮาง", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2613, "SUB_DISTRICT_CODE": "330423", "SUB_DISTRICT_NAME": "เวียงเหนือ", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2614, "SUB_DISTRICT_CODE": "330424", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2615, "SUB_DISTRICT_CODE": "330425", "SUB_DISTRICT_NAME": "ภูผาหมอก", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2616, "SUB_DISTRICT_CODE": "330496", "SUB_DISTRICT_NAME": "*สระเยาว์", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2617, "SUB_DISTRICT_CODE": "330497", "SUB_DISTRICT_NAME": "*พิงพวย", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2618, "SUB_DISTRICT_CODE": "330498", "SUB_DISTRICT_NAME": "*ศรีแก้ว", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2619, "SUB_DISTRICT_CODE": "330499", "SUB_DISTRICT_NAME": "*ตูม", "DISTRICT_ID": 293, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2620, "SUB_DISTRICT_CODE": "330501", "SUB_DISTRICT_NAME": "กันทรารมย์", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2621, "SUB_DISTRICT_CODE": "330502", "SUB_DISTRICT_NAME": "จะกง", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2622, "SUB_DISTRICT_CODE": "330503", "SUB_DISTRICT_NAME": "ใจดี", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2623, "SUB_DISTRICT_CODE": "330504", "SUB_DISTRICT_NAME": "ดองกำเม็ด", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2624, "SUB_DISTRICT_CODE": "330505", "SUB_DISTRICT_NAME": "โสน", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2625, "SUB_DISTRICT_CODE": "330506", "SUB_DISTRICT_NAME": "ปรือใหญ่", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2626, "SUB_DISTRICT_CODE": "330507", "SUB_DISTRICT_NAME": "สะเดาใหญ่", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2627, "SUB_DISTRICT_CODE": "330508", "SUB_DISTRICT_NAME": "ตาอุด", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2628, "SUB_DISTRICT_CODE": "330509", "SUB_DISTRICT_NAME": "ห้วยเหนือ", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2629, "SUB_DISTRICT_CODE": "330510", "SUB_DISTRICT_NAME": "ห้วยใต้", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2630, "SUB_DISTRICT_CODE": "330511", "SUB_DISTRICT_NAME": "หัวเสือ", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2631, "SUB_DISTRICT_CODE": "330512", "SUB_DISTRICT_NAME": "*ละลม", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2632, "SUB_DISTRICT_CODE": "330513", "SUB_DISTRICT_NAME": "ตะเคียน", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2633, "SUB_DISTRICT_CODE": "330514", "SUB_DISTRICT_NAME": "*โคกตาล", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2634, "SUB_DISTRICT_CODE": "330515", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2635, "SUB_DISTRICT_CODE": "330516", "SUB_DISTRICT_NAME": "*ห้วยตามอญ", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2636, "SUB_DISTRICT_CODE": "330517", "SUB_DISTRICT_NAME": "โคกเพชร", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2637, "SUB_DISTRICT_CODE": "330518", "SUB_DISTRICT_NAME": "ปราสาท", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2638, "SUB_DISTRICT_CODE": "330519", "SUB_DISTRICT_NAME": "*ตะเคียนราม", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2639, "SUB_DISTRICT_CODE": "330520", "SUB_DISTRICT_NAME": "*ห้วยติ๊กชู", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2640, "SUB_DISTRICT_CODE": "330521", "SUB_DISTRICT_NAME": "สำโรงตาเจ็น", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2641, "SUB_DISTRICT_CODE": "330522", "SUB_DISTRICT_NAME": "ห้วยสำราญ", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2642, "SUB_DISTRICT_CODE": "330523", "SUB_DISTRICT_NAME": "*ดงรัก", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2643, "SUB_DISTRICT_CODE": "330524", "SUB_DISTRICT_NAME": "กฤษณา", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2644, "SUB_DISTRICT_CODE": "330525", "SUB_DISTRICT_NAME": "ลมศักดิ์", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2645, "SUB_DISTRICT_CODE": "330526", "SUB_DISTRICT_NAME": "หนองฉลอง", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2646, "SUB_DISTRICT_CODE": "330527", "SUB_DISTRICT_NAME": "ศรีตระกูล", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2647, "SUB_DISTRICT_CODE": "330528", "SUB_DISTRICT_NAME": "ศรีสะอาด", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2648, "SUB_DISTRICT_CODE": "330599", "SUB_DISTRICT_NAME": "*ละลม", "DISTRICT_ID": 294, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2649, "SUB_DISTRICT_CODE": "330601", "SUB_DISTRICT_NAME": "ไพรบึง", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2650, "SUB_DISTRICT_CODE": "330602", "SUB_DISTRICT_NAME": "ดินแดง", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2651, "SUB_DISTRICT_CODE": "330603", "SUB_DISTRICT_NAME": "ปราสาทเยอ", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2652, "SUB_DISTRICT_CODE": "330604", "SUB_DISTRICT_NAME": "สำโรงพลัน", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2653, "SUB_DISTRICT_CODE": "330605", "SUB_DISTRICT_NAME": "สุขสวัสดิ์", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2654, "SUB_DISTRICT_CODE": "330606", "SUB_DISTRICT_NAME": "โนนปูน", "DISTRICT_ID": 295, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2655, "SUB_DISTRICT_CODE": "330701", "SUB_DISTRICT_NAME": "พิมาย", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2656, "SUB_DISTRICT_CODE": "330702", "SUB_DISTRICT_NAME": "กู่", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2657, "SUB_DISTRICT_CODE": "330703", "SUB_DISTRICT_NAME": "หนองเชียงทูน", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2658, "SUB_DISTRICT_CODE": "330704", "SUB_DISTRICT_NAME": "ตูม", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2659, "SUB_DISTRICT_CODE": "330705", "SUB_DISTRICT_NAME": "สมอ", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2660, "SUB_DISTRICT_CODE": "330706", "SUB_DISTRICT_NAME": "โพธิ์ศรี", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2661, "SUB_DISTRICT_CODE": "330707", "SUB_DISTRICT_NAME": "สำโรงปราสาท", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2662, "SUB_DISTRICT_CODE": "330708", "SUB_DISTRICT_NAME": "ดู่", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2663, "SUB_DISTRICT_CODE": "330709", "SUB_DISTRICT_NAME": "สวาย", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2664, "SUB_DISTRICT_CODE": "330710", "SUB_DISTRICT_NAME": "พิมายเหนือ", "DISTRICT_ID": 296, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2665, "SUB_DISTRICT_CODE": "330801", "SUB_DISTRICT_NAME": "สิ", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2666, "SUB_DISTRICT_CODE": "330802", "SUB_DISTRICT_NAME": "บักดอง", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2667, "SUB_DISTRICT_CODE": "330803", "SUB_DISTRICT_NAME": "พราน", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2668, "SUB_DISTRICT_CODE": "330804", "SUB_DISTRICT_NAME": "โพธิ์วงศ์", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2669, "SUB_DISTRICT_CODE": "330805", "SUB_DISTRICT_NAME": "ไพร", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2670, "SUB_DISTRICT_CODE": "330806", "SUB_DISTRICT_NAME": "กระหวัน", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2671, "SUB_DISTRICT_CODE": "330807", "SUB_DISTRICT_NAME": "ขุนหาญ", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2672, "SUB_DISTRICT_CODE": "330808", "SUB_DISTRICT_NAME": "โนนสูง", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2673, "SUB_DISTRICT_CODE": "330809", "SUB_DISTRICT_NAME": "กันทรอม", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2674, "SUB_DISTRICT_CODE": "330810", "SUB_DISTRICT_NAME": "ภูฝ้าย", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2675, "SUB_DISTRICT_CODE": "330811", "SUB_DISTRICT_NAME": "โพธิ์กระสังข์", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2676, "SUB_DISTRICT_CODE": "330812", "SUB_DISTRICT_NAME": "ห้วยจันทร์", "DISTRICT_ID": 297, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2677, "SUB_DISTRICT_CODE": "330901", "SUB_DISTRICT_NAME": "เมืองคง", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2678, "SUB_DISTRICT_CODE": "330902", "SUB_DISTRICT_NAME": "เมืองแคน", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2679, "SUB_DISTRICT_CODE": "330903", "SUB_DISTRICT_NAME": "หนองแค", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2680, "SUB_DISTRICT_CODE": "330904", "SUB_DISTRICT_NAME": "กุง*", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2681, "SUB_DISTRICT_CODE": "330905", "SUB_DISTRICT_NAME": "คลีกลิ้ง*", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2682, "SUB_DISTRICT_CODE": "330906", "SUB_DISTRICT_NAME": "จิกสังข์ทอง", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2683, "SUB_DISTRICT_CODE": "330907", "SUB_DISTRICT_NAME": "ด่าน", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2684, "SUB_DISTRICT_CODE": "330908", "SUB_DISTRICT_NAME": "ดู่", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2685, "SUB_DISTRICT_CODE": "330909", "SUB_DISTRICT_NAME": "หนองอึ่ง", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2686, "SUB_DISTRICT_CODE": "330910", "SUB_DISTRICT_NAME": "บัวหุ่ง", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2687, "SUB_DISTRICT_CODE": "330911", "SUB_DISTRICT_NAME": "ไผ่", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2688, "SUB_DISTRICT_CODE": "330912", "SUB_DISTRICT_NAME": "ส้มป่อย", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2689, "SUB_DISTRICT_CODE": "330913", "SUB_DISTRICT_NAME": "หนองหมี", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2690, "SUB_DISTRICT_CODE": "330914", "SUB_DISTRICT_NAME": "หว้านคำ", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2691, "SUB_DISTRICT_CODE": "330915", "SUB_DISTRICT_NAME": "สร้างปี่", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2692, "SUB_DISTRICT_CODE": "330916", "SUB_DISTRICT_NAME": "โจดม่วง*", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2693, "SUB_DISTRICT_CODE": "330917", "SUB_DISTRICT_NAME": "หนองบัวดง*", "DISTRICT_ID": 298, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2694, "SUB_DISTRICT_CODE": "331001", "SUB_DISTRICT_NAME": "กำแพง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2695, "SUB_DISTRICT_CODE": "331002", "SUB_DISTRICT_NAME": "อี่หล่ำ", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2696, "SUB_DISTRICT_CODE": "331003", "SUB_DISTRICT_NAME": "ก้านเหลือง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2697, "SUB_DISTRICT_CODE": "331004", "SUB_DISTRICT_NAME": "ทุ่งไชย", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2698, "SUB_DISTRICT_CODE": "331005", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2699, "SUB_DISTRICT_CODE": "331006", "SUB_DISTRICT_NAME": "แขม", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2700, "SUB_DISTRICT_CODE": "331007", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2701, "SUB_DISTRICT_CODE": "331008", "SUB_DISTRICT_NAME": "ขะยูง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2702, "SUB_DISTRICT_CODE": "331009", "SUB_DISTRICT_NAME": "*ตาโกน", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2703, "SUB_DISTRICT_CODE": "331010", "SUB_DISTRICT_NAME": "ตาเกษ", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2704, "SUB_DISTRICT_CODE": "331011", "SUB_DISTRICT_NAME": "หัวช้าง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2705, "SUB_DISTRICT_CODE": "331012", "SUB_DISTRICT_NAME": "รังแร้ง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2706, "SUB_DISTRICT_CODE": "331013", "SUB_DISTRICT_NAME": "*เมืองจันทร์", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2707, "SUB_DISTRICT_CODE": "331014", "SUB_DISTRICT_NAME": "แต้", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2708, "SUB_DISTRICT_CODE": "331015", "SUB_DISTRICT_NAME": "แข้", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2709, "SUB_DISTRICT_CODE": "331016", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2710, "SUB_DISTRICT_CODE": "331017", "SUB_DISTRICT_NAME": "ปะอาว", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2711, "SUB_DISTRICT_CODE": "331018", "SUB_DISTRICT_NAME": "หนองห้าง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2712, "SUB_DISTRICT_CODE": "331019", "SUB_DISTRICT_NAME": "*โดด", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2713, "SUB_DISTRICT_CODE": "331020", "SUB_DISTRICT_NAME": "*เสียว", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2714, "SUB_DISTRICT_CODE": "331021", "SUB_DISTRICT_NAME": "*หนองม้า", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2715, "SUB_DISTRICT_CODE": "331022", "SUB_DISTRICT_NAME": "สระกำแพงใหญ่", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2716, "SUB_DISTRICT_CODE": "331023", "SUB_DISTRICT_NAME": "*หนองใหญ่", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2717, "SUB_DISTRICT_CODE": "331024", "SUB_DISTRICT_NAME": "โคกหล่าม", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2718, "SUB_DISTRICT_CODE": "331025", "SUB_DISTRICT_NAME": "โคกจาน", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2719, "SUB_DISTRICT_CODE": "331026", "SUB_DISTRICT_NAME": "*ผือใหญ่", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2720, "SUB_DISTRICT_CODE": "331027", "SUB_DISTRICT_NAME": "*อีเซ", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2721, "SUB_DISTRICT_CODE": "331096", "SUB_DISTRICT_NAME": "*ผักไหม", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2722, "SUB_DISTRICT_CODE": "331097", "SUB_DISTRICT_NAME": "*กล้วยกว้าง", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2723, "SUB_DISTRICT_CODE": "331098", "SUB_DISTRICT_NAME": "*ห้วยทับทัน", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2724, "SUB_DISTRICT_CODE": "331099", "SUB_DISTRICT_NAME": "*เป๊าะ", "DISTRICT_ID": 299, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2725, "SUB_DISTRICT_CODE": "331101", "SUB_DISTRICT_NAME": "เป๊าะ", "DISTRICT_ID": 300, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2726, "SUB_DISTRICT_CODE": "331102", "SUB_DISTRICT_NAME": "บึงบูรพ์", "DISTRICT_ID": 300, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2727, "SUB_DISTRICT_CODE": "331201", "SUB_DISTRICT_NAME": "ห้วยทับทัน", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2728, "SUB_DISTRICT_CODE": "331202", "SUB_DISTRICT_NAME": "เมืองหลวง", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2729, "SUB_DISTRICT_CODE": "331203", "SUB_DISTRICT_NAME": "กล้วยกว้าง", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2730, "SUB_DISTRICT_CODE": "331204", "SUB_DISTRICT_NAME": "ผักไหม", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2731, "SUB_DISTRICT_CODE": "331205", "SUB_DISTRICT_NAME": "จานแสนไชย", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2732, "SUB_DISTRICT_CODE": "331206", "SUB_DISTRICT_NAME": "ปราสาท", "DISTRICT_ID": 301, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2733, "SUB_DISTRICT_CODE": "331301", "SUB_DISTRICT_NAME": "โนนค้อ", "DISTRICT_ID": 302, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2734, "SUB_DISTRICT_CODE": "331302", "SUB_DISTRICT_NAME": "บก", "DISTRICT_ID": 302, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2735, "SUB_DISTRICT_CODE": "331303", "SUB_DISTRICT_NAME": "โพธิ์", "DISTRICT_ID": 302, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2736, "SUB_DISTRICT_CODE": "331304", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 302, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2737, "SUB_DISTRICT_CODE": "331305", "SUB_DISTRICT_NAME": "เหล่ากวาง", "DISTRICT_ID": 302, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2738, "SUB_DISTRICT_CODE": "331401", "SUB_DISTRICT_NAME": "ศรีแก้ว", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2739, "SUB_DISTRICT_CODE": "331402", "SUB_DISTRICT_NAME": "พิงพวย", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2740, "SUB_DISTRICT_CODE": "331403", "SUB_DISTRICT_NAME": "สระเยาว์", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2741, "SUB_DISTRICT_CODE": "331404", "SUB_DISTRICT_NAME": "ตูม", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2742, "SUB_DISTRICT_CODE": "331405", "SUB_DISTRICT_NAME": "เสื่องข้าว", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2743, "SUB_DISTRICT_CODE": "331406", "SUB_DISTRICT_NAME": "ศรีโนนงาม", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2744, "SUB_DISTRICT_CODE": "331407", "SUB_DISTRICT_NAME": "สะพุง", "DISTRICT_ID": 303, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2745, "SUB_DISTRICT_CODE": "331501", "SUB_DISTRICT_NAME": "น้ำเกลี้ยง", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2746, "SUB_DISTRICT_CODE": "331502", "SUB_DISTRICT_NAME": "ละเอาะ", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2747, "SUB_DISTRICT_CODE": "331503", "SUB_DISTRICT_NAME": "ตองปิด", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2748, "SUB_DISTRICT_CODE": "331504", "SUB_DISTRICT_NAME": "เขิน", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2749, "SUB_DISTRICT_CODE": "331505", "SUB_DISTRICT_NAME": "รุ่งระวี", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2750, "SUB_DISTRICT_CODE": "331506", "SUB_DISTRICT_NAME": "คูบ", "DISTRICT_ID": 304, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2751, "SUB_DISTRICT_CODE": "331601", "SUB_DISTRICT_NAME": "บุสูง", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2752, "SUB_DISTRICT_CODE": "331602", "SUB_DISTRICT_NAME": "ธาตุ", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2753, "SUB_DISTRICT_CODE": "331603", "SUB_DISTRICT_NAME": "ดวนใหญ่", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2754, "SUB_DISTRICT_CODE": "331604", "SUB_DISTRICT_NAME": "บ่อแก้ว", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2755, "SUB_DISTRICT_CODE": "331605", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2756, "SUB_DISTRICT_CODE": "331606", "SUB_DISTRICT_NAME": "ทุ่งสว่าง", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2757, "SUB_DISTRICT_CODE": "331607", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2758, "SUB_DISTRICT_CODE": "331608", "SUB_DISTRICT_NAME": "โพนยาง", "DISTRICT_ID": 305, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2759, "SUB_DISTRICT_CODE": "331701", "SUB_DISTRICT_NAME": "โคกตาล", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2760, "SUB_DISTRICT_CODE": "331702", "SUB_DISTRICT_NAME": "ห้วยตามอญ", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2761, "SUB_DISTRICT_CODE": "331703", "SUB_DISTRICT_NAME": "ห้วยตึ๊กชู", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2762, "SUB_DISTRICT_CODE": "331704", "SUB_DISTRICT_NAME": "ละลม", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2763, "SUB_DISTRICT_CODE": "331705", "SUB_DISTRICT_NAME": "ตะเคียนราม", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2764, "SUB_DISTRICT_CODE": "331706", "SUB_DISTRICT_NAME": "ดงรัก", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2765, "SUB_DISTRICT_CODE": "331707", "SUB_DISTRICT_NAME": "ไพรพัฒนา", "DISTRICT_ID": 306, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2766, "SUB_DISTRICT_CODE": "331801", "SUB_DISTRICT_NAME": "เมืองจันทร์", "DISTRICT_ID": 307, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2767, "SUB_DISTRICT_CODE": "331802", "SUB_DISTRICT_NAME": "ตาโกน", "DISTRICT_ID": 307, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2768, "SUB_DISTRICT_CODE": "331803", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 307, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2769, "SUB_DISTRICT_CODE": "331901", "SUB_DISTRICT_NAME": "เสียว", "DISTRICT_ID": 308, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2770, "SUB_DISTRICT_CODE": "331902", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 308, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2771, "SUB_DISTRICT_CODE": "331903", "SUB_DISTRICT_NAME": "หนองงูเหลือม", "DISTRICT_ID": 308, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2772, "SUB_DISTRICT_CODE": "331904", "SUB_DISTRICT_NAME": "หนองฮาง", "DISTRICT_ID": 308, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2773, "SUB_DISTRICT_CODE": "331905", "SUB_DISTRICT_NAME": "ท่าคล้อ", "DISTRICT_ID": 308, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2774, "SUB_DISTRICT_CODE": "332001", "SUB_DISTRICT_NAME": "พยุห์", "DISTRICT_ID": 309, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2775, "SUB_DISTRICT_CODE": "332002", "SUB_DISTRICT_NAME": "พรหมสวัสดิ์", "DISTRICT_ID": 309, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2776, "SUB_DISTRICT_CODE": "332003", "SUB_DISTRICT_NAME": "ตำแย", "DISTRICT_ID": 309, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2777, "SUB_DISTRICT_CODE": "332004", "SUB_DISTRICT_NAME": "โนนเพ็ก", "DISTRICT_ID": 309, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2778, "SUB_DISTRICT_CODE": "332005", "SUB_DISTRICT_NAME": "หนองค้า", "DISTRICT_ID": 309, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2779, "SUB_DISTRICT_CODE": "332101", "SUB_DISTRICT_NAME": "โดด", "DISTRICT_ID": 310, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2780, "SUB_DISTRICT_CODE": "332102", "SUB_DISTRICT_NAME": "เสียว", "DISTRICT_ID": 310, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2781, "SUB_DISTRICT_CODE": "332103", "SUB_DISTRICT_NAME": "หนองม้า", "DISTRICT_ID": 310, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2782, "SUB_DISTRICT_CODE": "332104", "SUB_DISTRICT_NAME": "ผือใหญ่", "DISTRICT_ID": 310, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2783, "SUB_DISTRICT_CODE": "332105", "SUB_DISTRICT_NAME": "อีเซ", "DISTRICT_ID": 310, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2784, "SUB_DISTRICT_CODE": "332201", "SUB_DISTRICT_NAME": "กุง", "DISTRICT_ID": 311, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2785, "SUB_DISTRICT_CODE": "332202", "SUB_DISTRICT_NAME": "คลีกลิ้ง", "DISTRICT_ID": 311, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2786, "SUB_DISTRICT_CODE": "332203", "SUB_DISTRICT_NAME": "หนองบัวดง", "DISTRICT_ID": 311, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2787, "SUB_DISTRICT_CODE": "332204", "SUB_DISTRICT_NAME": "โจดม่วง", "DISTRICT_ID": 311, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2788, "SUB_DISTRICT_CODE": "340101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2789, "SUB_DISTRICT_CODE": "340102", "SUB_DISTRICT_NAME": "*โพนเมือง", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2790, "SUB_DISTRICT_CODE": "340103", "SUB_DISTRICT_NAME": "*ท่าเมือง", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2791, "SUB_DISTRICT_CODE": "340104", "SUB_DISTRICT_NAME": "หัวเรือ", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2792, "SUB_DISTRICT_CODE": "340105", "SUB_DISTRICT_NAME": "หนองขอน", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2793, "SUB_DISTRICT_CODE": "340106", "SUB_DISTRICT_NAME": "*ดอนมดแดง", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2794, "SUB_DISTRICT_CODE": "340107", "SUB_DISTRICT_NAME": "ปทุม", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2795, "SUB_DISTRICT_CODE": "340108", "SUB_DISTRICT_NAME": "ขามใหญ่", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2796, "SUB_DISTRICT_CODE": "340109", "SUB_DISTRICT_NAME": "แจระแม", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2797, "SUB_DISTRICT_CODE": "340110", "SUB_DISTRICT_NAME": "*คำไฮใหญ่", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2798, "SUB_DISTRICT_CODE": "340111", "SUB_DISTRICT_NAME": "หนองบ่อ", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2799, "SUB_DISTRICT_CODE": "340112", "SUB_DISTRICT_NAME": "ไร่น้อย", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2800, "SUB_DISTRICT_CODE": "340113", "SUB_DISTRICT_NAME": "กระโสบ", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2801, "SUB_DISTRICT_CODE": "340114", "SUB_DISTRICT_NAME": "*เหล่าแดง", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2802, "SUB_DISTRICT_CODE": "340115", "SUB_DISTRICT_NAME": "*เหล่าเสือโก้ก", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2803, "SUB_DISTRICT_CODE": "340116", "SUB_DISTRICT_NAME": "กุดลาด", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2804, "SUB_DISTRICT_CODE": "340117", "SUB_DISTRICT_NAME": "*หนองบก", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2805, "SUB_DISTRICT_CODE": "340118", "SUB_DISTRICT_NAME": "*แพงใหญ่", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2806, "SUB_DISTRICT_CODE": "340119", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2807, "SUB_DISTRICT_CODE": "340120", "SUB_DISTRICT_NAME": "ปะอาว", "DISTRICT_ID": 312, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2808, "SUB_DISTRICT_CODE": "340201", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2809, "SUB_DISTRICT_CODE": "340202", "SUB_DISTRICT_NAME": "แก้งกอก", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2810, "SUB_DISTRICT_CODE": "340203", "SUB_DISTRICT_NAME": "เอือดใหญ่", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2811, "SUB_DISTRICT_CODE": "340204", "SUB_DISTRICT_NAME": "วาริน", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2812, "SUB_DISTRICT_CODE": "340205", "SUB_DISTRICT_NAME": "ลาดควาย", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2813, "SUB_DISTRICT_CODE": "340206", "SUB_DISTRICT_NAME": "สงยาง", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2814, "SUB_DISTRICT_CODE": "340207", "SUB_DISTRICT_NAME": "ตะบ่าย", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2815, "SUB_DISTRICT_CODE": "340208", "SUB_DISTRICT_NAME": "คำไหล", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2816, "SUB_DISTRICT_CODE": "340209", "SUB_DISTRICT_NAME": "หนามแท่ง", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2817, "SUB_DISTRICT_CODE": "340210", "SUB_DISTRICT_NAME": "นาเลิน", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2818, "SUB_DISTRICT_CODE": "340211", "SUB_DISTRICT_NAME": "ดอนใหญ่", "DISTRICT_ID": 313, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2819, "SUB_DISTRICT_CODE": "340301", "SUB_DISTRICT_NAME": "โขงเจียม", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2820, "SUB_DISTRICT_CODE": "340302", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2821, "SUB_DISTRICT_CODE": "340303", "SUB_DISTRICT_NAME": "นาโพธิ์กลาง", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2822, "SUB_DISTRICT_CODE": "340304", "SUB_DISTRICT_NAME": "หนองแสงใหญ่", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2823, "SUB_DISTRICT_CODE": "340305", "SUB_DISTRICT_NAME": "ห้วยไผ่", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2824, "SUB_DISTRICT_CODE": "340306", "SUB_DISTRICT_NAME": "คำเขื่อนแก้ว", "DISTRICT_ID": 314, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2825, "SUB_DISTRICT_CODE": "340401", "SUB_DISTRICT_NAME": "เขื่องใน", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2826, "SUB_DISTRICT_CODE": "340402", "SUB_DISTRICT_NAME": "สร้างถ่อ", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2827, "SUB_DISTRICT_CODE": "340403", "SUB_DISTRICT_NAME": "ค้อทอง", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2828, "SUB_DISTRICT_CODE": "340404", "SUB_DISTRICT_NAME": "ก่อเอ้", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2829, "SUB_DISTRICT_CODE": "340405", "SUB_DISTRICT_NAME": "หัวดอน", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2830, "SUB_DISTRICT_CODE": "340406", "SUB_DISTRICT_NAME": "ชีทวน", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2831, "SUB_DISTRICT_CODE": "340407", "SUB_DISTRICT_NAME": "ท่าไห", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2832, "SUB_DISTRICT_CODE": "340408", "SUB_DISTRICT_NAME": "นาคำใหญ่", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2833, "SUB_DISTRICT_CODE": "340409", "SUB_DISTRICT_NAME": "แดงหม้อ", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2834, "SUB_DISTRICT_CODE": "340410", "SUB_DISTRICT_NAME": "ธาตุน้อย", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2835, "SUB_DISTRICT_CODE": "340411", "SUB_DISTRICT_NAME": "บ้านไทย", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2836, "SUB_DISTRICT_CODE": "340412", "SUB_DISTRICT_NAME": "บ้านกอก", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2837, "SUB_DISTRICT_CODE": "340413", "SUB_DISTRICT_NAME": "กลางใหญ่", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2838, "SUB_DISTRICT_CODE": "340414", "SUB_DISTRICT_NAME": "โนนรัง", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2839, "SUB_DISTRICT_CODE": "340415", "SUB_DISTRICT_NAME": "ยางขี้นก", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2840, "SUB_DISTRICT_CODE": "340416", "SUB_DISTRICT_NAME": "ศรีสุข", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2841, "SUB_DISTRICT_CODE": "340417", "SUB_DISTRICT_NAME": "สหธาตุ", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2842, "SUB_DISTRICT_CODE": "340418", "SUB_DISTRICT_NAME": "หนองเหล่า", "DISTRICT_ID": 315, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2843, "SUB_DISTRICT_CODE": "340501", "SUB_DISTRICT_NAME": "เขมราฐ", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2844, "SUB_DISTRICT_CODE": "340502", "SUB_DISTRICT_NAME": "*กองโพน", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2845, "SUB_DISTRICT_CODE": "340503", "SUB_DISTRICT_NAME": "ขามป้อม", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2846, "SUB_DISTRICT_CODE": "340504", "SUB_DISTRICT_NAME": "เจียด", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2847, "SUB_DISTRICT_CODE": "340505", "SUB_DISTRICT_NAME": "*พังเคน", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2848, "SUB_DISTRICT_CODE": "340506", "SUB_DISTRICT_NAME": "*นาตาล", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2849, "SUB_DISTRICT_CODE": "340507", "SUB_DISTRICT_NAME": "หนองผือ", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2850, "SUB_DISTRICT_CODE": "340508", "SUB_DISTRICT_NAME": "นาแวง", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2851, "SUB_DISTRICT_CODE": "340509", "SUB_DISTRICT_NAME": "*พะลาน", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2852, "SUB_DISTRICT_CODE": "340510", "SUB_DISTRICT_NAME": "แก้งเหนือ", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2853, "SUB_DISTRICT_CODE": "340511", "SUB_DISTRICT_NAME": "หนองนกทา", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2854, "SUB_DISTRICT_CODE": "340512", "SUB_DISTRICT_NAME": "หนองสิม", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2855, "SUB_DISTRICT_CODE": "340513", "SUB_DISTRICT_NAME": "หัวนา", "DISTRICT_ID": 316, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2856, "SUB_DISTRICT_CODE": "340601", "SUB_DISTRICT_NAME": "*ชานุมาน", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2857, "SUB_DISTRICT_CODE": "340602", "SUB_DISTRICT_NAME": "*โคกสาร", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2858, "SUB_DISTRICT_CODE": "340603", "SUB_DISTRICT_NAME": "*คำเขื่อนแก้ว", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2859, "SUB_DISTRICT_CODE": "340604", "SUB_DISTRICT_NAME": "*หนองข่า", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2860, "SUB_DISTRICT_CODE": "340605", "SUB_DISTRICT_NAME": "*คำโพน", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2861, "SUB_DISTRICT_CODE": "340606", "SUB_DISTRICT_NAME": "*โคกก่ง", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2862, "SUB_DISTRICT_CODE": "340607", "SUB_DISTRICT_NAME": "*ป่าก่อ", "DISTRICT_ID": 317, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2863, "SUB_DISTRICT_CODE": "340701", "SUB_DISTRICT_NAME": "เมืองเดช", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2864, "SUB_DISTRICT_CODE": "340702", "SUB_DISTRICT_NAME": "นาส่วง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2865, "SUB_DISTRICT_CODE": "340703", "SUB_DISTRICT_NAME": "*นาเยีย", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2866, "SUB_DISTRICT_CODE": "340704", "SUB_DISTRICT_NAME": "นาเจริญ", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2867, "SUB_DISTRICT_CODE": "340705", "SUB_DISTRICT_NAME": "*นาเรือง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2868, "SUB_DISTRICT_CODE": "340706", "SUB_DISTRICT_NAME": "ทุ่งเทิง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2869, "SUB_DISTRICT_CODE": "340707", "SUB_DISTRICT_NAME": "*หนองอ้ม", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2870, "SUB_DISTRICT_CODE": "340708", "SUB_DISTRICT_NAME": "สมสะอาด", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2871, "SUB_DISTRICT_CODE": "340709", "SUB_DISTRICT_NAME": "กุดประทาย", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2872, "SUB_DISTRICT_CODE": "340710", "SUB_DISTRICT_NAME": "ตบหู", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2873, "SUB_DISTRICT_CODE": "340711", "SUB_DISTRICT_NAME": "กลาง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2874, "SUB_DISTRICT_CODE": "340712", "SUB_DISTRICT_NAME": "แก้ง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2875, "SUB_DISTRICT_CODE": "340713", "SUB_DISTRICT_NAME": "ท่าโพธิ์ศรี", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2876, "SUB_DISTRICT_CODE": "340714", "SUB_DISTRICT_NAME": "*นาเกษม", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2877, "SUB_DISTRICT_CODE": "340715", "SUB_DISTRICT_NAME": "บัวงาม", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2878, "SUB_DISTRICT_CODE": "340716", "SUB_DISTRICT_NAME": "คำครั่ง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2879, "SUB_DISTRICT_CODE": "340717", "SUB_DISTRICT_NAME": "นากระแซง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2880, "SUB_DISTRICT_CODE": "340718", "SUB_DISTRICT_NAME": "*กุดเรือ", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2881, "SUB_DISTRICT_CODE": "340719", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2882, "SUB_DISTRICT_CODE": "340720", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2883, "SUB_DISTRICT_CODE": "340721", "SUB_DISTRICT_NAME": "ป่าโมง", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2884, "SUB_DISTRICT_CODE": "340722", "SUB_DISTRICT_NAME": "*โคกชำแระ", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2885, "SUB_DISTRICT_CODE": "340723", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 318, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2886, "SUB_DISTRICT_CODE": "340801", "SUB_DISTRICT_NAME": "นาจะหลวย", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2887, "SUB_DISTRICT_CODE": "340802", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2888, "SUB_DISTRICT_CODE": "340803", "SUB_DISTRICT_NAME": "พรสวรรค์", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2889, "SUB_DISTRICT_CODE": "340804", "SUB_DISTRICT_NAME": "บ้านตูม", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2890, "SUB_DISTRICT_CODE": "340805", "SUB_DISTRICT_NAME": "โสกแสง", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2891, "SUB_DISTRICT_CODE": "340806", "SUB_DISTRICT_NAME": "โนนสวรรค์", "DISTRICT_ID": 319, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2892, "SUB_DISTRICT_CODE": "340901", "SUB_DISTRICT_NAME": "โซง", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2893, "SUB_DISTRICT_CODE": "340902", "SUB_DISTRICT_NAME": "ตาเกา*", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2894, "SUB_DISTRICT_CODE": "340903", "SUB_DISTRICT_NAME": "ยาง", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2895, "SUB_DISTRICT_CODE": "340904", "SUB_DISTRICT_NAME": "โดมประดิษฐ์", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2896, "SUB_DISTRICT_CODE": "340905", "SUB_DISTRICT_NAME": "ขี้เหล็ก*", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2897, "SUB_DISTRICT_CODE": "340906", "SUB_DISTRICT_NAME": "บุเปือย", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2898, "SUB_DISTRICT_CODE": "340907", "SUB_DISTRICT_NAME": "สีวิเชียร", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2899, "SUB_DISTRICT_CODE": "340908", "SUB_DISTRICT_NAME": "ไพบูลย์*", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2900, "SUB_DISTRICT_CODE": "340909", "SUB_DISTRICT_NAME": "ยางใหญ่", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2901, "SUB_DISTRICT_CODE": "340910", "SUB_DISTRICT_NAME": "โคกสะอาด*", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2902, "SUB_DISTRICT_CODE": "340911", "SUB_DISTRICT_NAME": "เก่าขาม", "DISTRICT_ID": 320, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2903, "SUB_DISTRICT_CODE": "341001", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2904, "SUB_DISTRICT_CODE": "341002", "SUB_DISTRICT_NAME": "ห้วยข่า", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2905, "SUB_DISTRICT_CODE": "341003", "SUB_DISTRICT_NAME": "คอแลน", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2906, "SUB_DISTRICT_CODE": "341004", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2907, "SUB_DISTRICT_CODE": "341005", "SUB_DISTRICT_NAME": "หนองสะโน", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2908, "SUB_DISTRICT_CODE": "341006", "SUB_DISTRICT_NAME": "โนนค้อ", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2909, "SUB_DISTRICT_CODE": "341007", "SUB_DISTRICT_NAME": "บัวงาม", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2910, "SUB_DISTRICT_CODE": "341008", "SUB_DISTRICT_NAME": "บ้านแมด", "DISTRICT_ID": 321, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2911, "SUB_DISTRICT_CODE": "341101", "SUB_DISTRICT_NAME": "ขุหลุ", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2912, "SUB_DISTRICT_CODE": "341102", "SUB_DISTRICT_NAME": "กระเดียน", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2913, "SUB_DISTRICT_CODE": "341103", "SUB_DISTRICT_NAME": "เกษม", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2914, "SUB_DISTRICT_CODE": "341104", "SUB_DISTRICT_NAME": "กุศกร", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2915, "SUB_DISTRICT_CODE": "341105", "SUB_DISTRICT_NAME": "ขามเปี้ย", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2916, "SUB_DISTRICT_CODE": "341106", "SUB_DISTRICT_NAME": "คอนสาย", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2917, "SUB_DISTRICT_CODE": "341107", "SUB_DISTRICT_NAME": "โคกจาน", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2918, "SUB_DISTRICT_CODE": "341108", "SUB_DISTRICT_NAME": "นาพิน", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2919, "SUB_DISTRICT_CODE": "341109", "SUB_DISTRICT_NAME": "นาสะไม", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2920, "SUB_DISTRICT_CODE": "341110", "SUB_DISTRICT_NAME": "โนนกุง", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2921, "SUB_DISTRICT_CODE": "341111", "SUB_DISTRICT_NAME": "ตระการ", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2922, "SUB_DISTRICT_CODE": "341112", "SUB_DISTRICT_NAME": "ตากแดด", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2923, "SUB_DISTRICT_CODE": "341113", "SUB_DISTRICT_NAME": "ไหล่ทุ่ง", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2924, "SUB_DISTRICT_CODE": "341114", "SUB_DISTRICT_NAME": "เป้า", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2925, "SUB_DISTRICT_CODE": "341115", "SUB_DISTRICT_NAME": "เซเป็ด", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2926, "SUB_DISTRICT_CODE": "341116", "SUB_DISTRICT_NAME": "สะพือ", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2927, "SUB_DISTRICT_CODE": "341117", "SUB_DISTRICT_NAME": "หนองเต่า", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2928, "SUB_DISTRICT_CODE": "341118", "SUB_DISTRICT_NAME": "ถ้ำแข้", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2929, "SUB_DISTRICT_CODE": "341119", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2930, "SUB_DISTRICT_CODE": "341120", "SUB_DISTRICT_NAME": "ห้วยฝ้ายพัฒนา", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2931, "SUB_DISTRICT_CODE": "341121", "SUB_DISTRICT_NAME": "กุดยาลวน", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2932, "SUB_DISTRICT_CODE": "341122", "SUB_DISTRICT_NAME": "บ้านแดง", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2933, "SUB_DISTRICT_CODE": "341123", "SUB_DISTRICT_NAME": "คำเจริญ", "DISTRICT_ID": 322, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2934, "SUB_DISTRICT_CODE": "341201", "SUB_DISTRICT_NAME": "ข้าวปุ้น", "DISTRICT_ID": 323, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2935, "SUB_DISTRICT_CODE": "341202", "SUB_DISTRICT_NAME": "โนนสวาง", "DISTRICT_ID": 323, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2936, "SUB_DISTRICT_CODE": "341203", "SUB_DISTRICT_NAME": "แก่งเค็ง", "DISTRICT_ID": 323, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2937, "SUB_DISTRICT_CODE": "341204", "SUB_DISTRICT_NAME": "กาบิน", "DISTRICT_ID": 323, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2938, "SUB_DISTRICT_CODE": "341205", "SUB_DISTRICT_NAME": "หนองทันน้ำ", "DISTRICT_ID": 323, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2939, "SUB_DISTRICT_CODE": "341301", "SUB_DISTRICT_NAME": "*พนา", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2940, "SUB_DISTRICT_CODE": "341302", "SUB_DISTRICT_NAME": "*จานลาน", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2941, "SUB_DISTRICT_CODE": "341303", "SUB_DISTRICT_NAME": "*ไม้กลอน", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2942, "SUB_DISTRICT_CODE": "341304", "SUB_DISTRICT_NAME": "*ลือ", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2943, "SUB_DISTRICT_CODE": "341305", "SUB_DISTRICT_NAME": "*ห้วย", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2944, "SUB_DISTRICT_CODE": "341306", "SUB_DISTRICT_NAME": "*นาหว้า", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2945, "SUB_DISTRICT_CODE": "341307", "SUB_DISTRICT_NAME": "*พระเหลา", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2946, "SUB_DISTRICT_CODE": "341308", "SUB_DISTRICT_NAME": "*นาป่าแซง", "DISTRICT_ID": 324, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2947, "SUB_DISTRICT_CODE": "341401", "SUB_DISTRICT_NAME": "ม่วงสามสิบ", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2948, "SUB_DISTRICT_CODE": "341402", "SUB_DISTRICT_NAME": "เหล่าบก", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2949, "SUB_DISTRICT_CODE": "341403", "SUB_DISTRICT_NAME": "ดุมใหญ่", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2950, "SUB_DISTRICT_CODE": "341404", "SUB_DISTRICT_NAME": "หนองช้างใหญ่", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2951, "SUB_DISTRICT_CODE": "341405", "SUB_DISTRICT_NAME": "หนองเมือง", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2952, "SUB_DISTRICT_CODE": "341406", "SUB_DISTRICT_NAME": "เตย", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2953, "SUB_DISTRICT_CODE": "341407", "SUB_DISTRICT_NAME": "ยางสักกระโพหลุ่ม", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2954, "SUB_DISTRICT_CODE": "341408", "SUB_DISTRICT_NAME": "หนองไข่นก", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2955, "SUB_DISTRICT_CODE": "341409", "SUB_DISTRICT_NAME": "หนองเหล่า", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2956, "SUB_DISTRICT_CODE": "341410", "SUB_DISTRICT_NAME": "หนองฮาง", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2957, "SUB_DISTRICT_CODE": "341411", "SUB_DISTRICT_NAME": "ยางโยภาพ", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2958, "SUB_DISTRICT_CODE": "341412", "SUB_DISTRICT_NAME": "ไผ่ใหญ่", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2959, "SUB_DISTRICT_CODE": "341413", "SUB_DISTRICT_NAME": "นาเลิง", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2960, "SUB_DISTRICT_CODE": "341414", "SUB_DISTRICT_NAME": "โพนแพง", "DISTRICT_ID": 325, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2961, "SUB_DISTRICT_CODE": "341501", "SUB_DISTRICT_NAME": "วารินชำราบ", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2962, "SUB_DISTRICT_CODE": "341502", "SUB_DISTRICT_NAME": "ธาตุ", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2963, "SUB_DISTRICT_CODE": "341503", "SUB_DISTRICT_NAME": "*ท่าช้าง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2964, "SUB_DISTRICT_CODE": "341504", "SUB_DISTRICT_NAME": "ท่าลาด", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2965, "SUB_DISTRICT_CODE": "341505", "SUB_DISTRICT_NAME": "โนนโหนน", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2966, "SUB_DISTRICT_CODE": "341506", "SUB_DISTRICT_NAME": "*โนนกาเล็น", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2967, "SUB_DISTRICT_CODE": "341507", "SUB_DISTRICT_NAME": "คูเมือง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2968, "SUB_DISTRICT_CODE": "341508", "SUB_DISTRICT_NAME": "สระสมิง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2969, "SUB_DISTRICT_CODE": "341509", "SUB_DISTRICT_NAME": "*ค้อน้อย", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2970, "SUB_DISTRICT_CODE": "341510", "SUB_DISTRICT_NAME": "คำน้ำแซบ", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2971, "SUB_DISTRICT_CODE": "341511", "SUB_DISTRICT_NAME": "บุ่งหวาย", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2972, "SUB_DISTRICT_CODE": "341512", "SUB_DISTRICT_NAME": "*หนองไฮ", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2973, "SUB_DISTRICT_CODE": "341513", "SUB_DISTRICT_NAME": "*สำโรง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2974, "SUB_DISTRICT_CODE": "341514", "SUB_DISTRICT_NAME": "*สว่าง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2975, "SUB_DISTRICT_CODE": "341515", "SUB_DISTRICT_NAME": "คำขวาง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2976, "SUB_DISTRICT_CODE": "341516", "SUB_DISTRICT_NAME": "โพธิ์ใหญ่", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2977, "SUB_DISTRICT_CODE": "341517", "SUB_DISTRICT_NAME": "*โคกก่อง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2978, "SUB_DISTRICT_CODE": "341518", "SUB_DISTRICT_NAME": "แสนสุข", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2979, "SUB_DISTRICT_CODE": "341519", "SUB_DISTRICT_NAME": "*โคกสว่าง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2980, "SUB_DISTRICT_CODE": "341520", "SUB_DISTRICT_NAME": "หนองกินเพล", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2981, "SUB_DISTRICT_CODE": "341521", "SUB_DISTRICT_NAME": "โนนผึ้ง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2982, "SUB_DISTRICT_CODE": "341522", "SUB_DISTRICT_NAME": "เมืองศรีไค", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2983, "SUB_DISTRICT_CODE": "341523", "SUB_DISTRICT_NAME": "*บุ่งมะแลง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2984, "SUB_DISTRICT_CODE": "341524", "SUB_DISTRICT_NAME": "ห้วยขะยุง", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2985, "SUB_DISTRICT_CODE": "341525", "SUB_DISTRICT_NAME": "*แก่งโดม", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2986, "SUB_DISTRICT_CODE": "341526", "SUB_DISTRICT_NAME": "บุ่งไหม", "DISTRICT_ID": 326, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2987, "SUB_DISTRICT_CODE": "341601", "SUB_DISTRICT_NAME": "*บุ่ง", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2988, "SUB_DISTRICT_CODE": "341602", "SUB_DISTRICT_NAME": "*ไก่คำ", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2989, "SUB_DISTRICT_CODE": "341603", "SUB_DISTRICT_NAME": "*นาจิก", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2990, "SUB_DISTRICT_CODE": "341604", "SUB_DISTRICT_NAME": "*ดงมะยาง", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2991, "SUB_DISTRICT_CODE": "341605", "SUB_DISTRICT_NAME": "*อำนาจ", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2992, "SUB_DISTRICT_CODE": "341606", "SUB_DISTRICT_NAME": "*เปือย", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2993, "SUB_DISTRICT_CODE": "341607", "SUB_DISTRICT_NAME": "*ดงบัง", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2994, "SUB_DISTRICT_CODE": "341608", "SUB_DISTRICT_NAME": "*ไร่ขี", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2995, "SUB_DISTRICT_CODE": "341609", "SUB_DISTRICT_NAME": "*ปลาค้าว", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2996, "SUB_DISTRICT_CODE": "341610", "SUB_DISTRICT_NAME": "*เหล่าพรวน", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2997, "SUB_DISTRICT_CODE": "341611", "SUB_DISTRICT_NAME": "*สร้างนกทา", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2998, "SUB_DISTRICT_CODE": "341612", "SUB_DISTRICT_NAME": "*คิ่มใหญ่", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 2999, "SUB_DISTRICT_CODE": "341613", "SUB_DISTRICT_NAME": "*นาผือ", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3000, "SUB_DISTRICT_CODE": "341614", "SUB_DISTRICT_NAME": "*น้ำปลีก", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3001, "SUB_DISTRICT_CODE": "341615", "SUB_DISTRICT_NAME": "*นาวัง", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3002, "SUB_DISTRICT_CODE": "341616", "SUB_DISTRICT_NAME": "*นาหมอม้า", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3003, "SUB_DISTRICT_CODE": "341617", "SUB_DISTRICT_NAME": "*โนนโพธิ์", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3004, "SUB_DISTRICT_CODE": "341618", "SUB_DISTRICT_NAME": "*โนนหนามแท่ง", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3005, "SUB_DISTRICT_CODE": "341619", "SUB_DISTRICT_NAME": "*ห้วยไร่", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3006, "SUB_DISTRICT_CODE": "341620", "SUB_DISTRICT_NAME": "*หนองมะแซว", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3007, "SUB_DISTRICT_CODE": "341621", "SUB_DISTRICT_NAME": "*แมด", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3008, "SUB_DISTRICT_CODE": "341622", "SUB_DISTRICT_NAME": "*กุดปลาดุก", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3009, "SUB_DISTRICT_CODE": "341623", "SUB_DISTRICT_NAME": "*โนนงาม", "DISTRICT_ID": 327, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3010, "SUB_DISTRICT_CODE": "341701", "SUB_DISTRICT_NAME": "*เสนางคนิคม", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3011, "SUB_DISTRICT_CODE": "341702", "SUB_DISTRICT_NAME": "*โพนทอง", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3012, "SUB_DISTRICT_CODE": "341703", "SUB_DISTRICT_NAME": "*ไร่สีสุก", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3013, "SUB_DISTRICT_CODE": "341704", "SUB_DISTRICT_NAME": "*นาเวียง", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3014, "SUB_DISTRICT_CODE": "341705", "SUB_DISTRICT_NAME": "*หนองไฮ", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3015, "SUB_DISTRICT_CODE": "341706", "SUB_DISTRICT_NAME": "*หนองสามสี", "DISTRICT_ID": 328, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3016, "SUB_DISTRICT_CODE": "341801", "SUB_DISTRICT_NAME": "*หัวตะพาน", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3017, "SUB_DISTRICT_CODE": "341802", "SUB_DISTRICT_NAME": "*คำพระ", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3018, "SUB_DISTRICT_CODE": "341803", "SUB_DISTRICT_NAME": "*เค็งใหญ่", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3019, "SUB_DISTRICT_CODE": "341804", "SUB_DISTRICT_NAME": "*หนองแก้ว", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3020, "SUB_DISTRICT_CODE": "341805", "SUB_DISTRICT_NAME": "*โพนเมืองน้อย", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3021, "SUB_DISTRICT_CODE": "341806", "SUB_DISTRICT_NAME": "*สร้างถ่อน้อย", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3022, "SUB_DISTRICT_CODE": "341807", "SUB_DISTRICT_NAME": "*จิกดู่", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3023, "SUB_DISTRICT_CODE": "341808", "SUB_DISTRICT_NAME": "*รัตนวารี", "DISTRICT_ID": 329, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3024, "SUB_DISTRICT_CODE": "341901", "SUB_DISTRICT_NAME": "พิบูล", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3025, "SUB_DISTRICT_CODE": "341902", "SUB_DISTRICT_NAME": "กุดชมภู", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3026, "SUB_DISTRICT_CODE": "341903", "SUB_DISTRICT_NAME": "*คันไร่", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3027, "SUB_DISTRICT_CODE": "341904", "SUB_DISTRICT_NAME": "ดอนจิก", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3028, "SUB_DISTRICT_CODE": "341905", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3029, "SUB_DISTRICT_CODE": "341906", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3030, "SUB_DISTRICT_CODE": "341907", "SUB_DISTRICT_NAME": "โนนกลาง", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3031, "SUB_DISTRICT_CODE": "341908", "SUB_DISTRICT_NAME": "*ฝางคำ", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3032, "SUB_DISTRICT_CODE": "341909", "SUB_DISTRICT_NAME": "โพธิ์ไทร", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3033, "SUB_DISTRICT_CODE": "341910", "SUB_DISTRICT_NAME": "โพธิ์ศรี", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3034, "SUB_DISTRICT_CODE": "341911", "SUB_DISTRICT_NAME": "ระเว", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3035, "SUB_DISTRICT_CODE": "341912", "SUB_DISTRICT_NAME": "ไร่ใต้", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3036, "SUB_DISTRICT_CODE": "341913", "SUB_DISTRICT_NAME": "หนองบัวฮี", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3037, "SUB_DISTRICT_CODE": "341914", "SUB_DISTRICT_NAME": "อ่างศิลา", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3038, "SUB_DISTRICT_CODE": "341915", "SUB_DISTRICT_NAME": "*นิคมสร้างตนเองฯ", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3039, "SUB_DISTRICT_CODE": "341916", "SUB_DISTRICT_NAME": "*ช่องเม็ก", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3040, "SUB_DISTRICT_CODE": "341917", "SUB_DISTRICT_NAME": "*โนนก่อ", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3041, "SUB_DISTRICT_CODE": "341918", "SUB_DISTRICT_NAME": "โนนกาหลง", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3042, "SUB_DISTRICT_CODE": "341919", "SUB_DISTRICT_NAME": "บ้านแขม", "DISTRICT_ID": 330, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3043, "SUB_DISTRICT_CODE": "342001", "SUB_DISTRICT_NAME": "ตาลสุม", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3044, "SUB_DISTRICT_CODE": "342002", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3045, "SUB_DISTRICT_CODE": "342003", "SUB_DISTRICT_NAME": "จิกเทิง", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3046, "SUB_DISTRICT_CODE": "342004", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3047, "SUB_DISTRICT_CODE": "342005", "SUB_DISTRICT_NAME": "นาคาย", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3048, "SUB_DISTRICT_CODE": "342006", "SUB_DISTRICT_NAME": "คำหว้า", "DISTRICT_ID": 331, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3049, "SUB_DISTRICT_CODE": "342101", "SUB_DISTRICT_NAME": "โพธิ์ไทร", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3050, "SUB_DISTRICT_CODE": "342102", "SUB_DISTRICT_NAME": "ม่วงใหญ่", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3051, "SUB_DISTRICT_CODE": "342103", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3052, "SUB_DISTRICT_CODE": "342104", "SUB_DISTRICT_NAME": "สองคอน", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3053, "SUB_DISTRICT_CODE": "342105", "SUB_DISTRICT_NAME": "สารภี", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3054, "SUB_DISTRICT_CODE": "342106", "SUB_DISTRICT_NAME": "เหล่างาม", "DISTRICT_ID": 332, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3055, "SUB_DISTRICT_CODE": "342201", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3056, "SUB_DISTRICT_CODE": "342202", "SUB_DISTRICT_NAME": "โคกก่อง", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3057, "SUB_DISTRICT_CODE": "342203", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3058, "SUB_DISTRICT_CODE": "342204", "SUB_DISTRICT_NAME": "ค้อน้อย", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3059, "SUB_DISTRICT_CODE": "342205", "SUB_DISTRICT_NAME": "โนนกาเล็น", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3060, "SUB_DISTRICT_CODE": "342206", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3061, "SUB_DISTRICT_CODE": "342207", "SUB_DISTRICT_NAME": "โนนกลาง", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3062, "SUB_DISTRICT_CODE": "342208", "SUB_DISTRICT_NAME": "บอน", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3063, "SUB_DISTRICT_CODE": "342209", "SUB_DISTRICT_NAME": "ขามป้อม", "DISTRICT_ID": 333, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3064, "SUB_DISTRICT_CODE": "342301", "SUB_DISTRICT_NAME": "*อำนาจ", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3065, "SUB_DISTRICT_CODE": "342302", "SUB_DISTRICT_NAME": "*ดงมะยาง", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3066, "SUB_DISTRICT_CODE": "342303", "SUB_DISTRICT_NAME": "*เปือย", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3067, "SUB_DISTRICT_CODE": "342304", "SUB_DISTRICT_NAME": "*ดงบัง", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3068, "SUB_DISTRICT_CODE": "342305", "SUB_DISTRICT_NAME": "*ไร่ขี", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3069, "SUB_DISTRICT_CODE": "342306", "SUB_DISTRICT_NAME": "*แมด", "DISTRICT_ID": 334, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3070, "SUB_DISTRICT_CODE": "342401", "SUB_DISTRICT_NAME": "ดอนมดแดง", "DISTRICT_ID": 335, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3071, "SUB_DISTRICT_CODE": "342402", "SUB_DISTRICT_NAME": "เหล่าแดง", "DISTRICT_ID": 335, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3072, "SUB_DISTRICT_CODE": "342403", "SUB_DISTRICT_NAME": "ท่าเมือง", "DISTRICT_ID": 335, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3073, "SUB_DISTRICT_CODE": "342404", "SUB_DISTRICT_NAME": "คำไฮใหญ่", "DISTRICT_ID": 335, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3074, "SUB_DISTRICT_CODE": "342501", "SUB_DISTRICT_NAME": "คันไร่", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3075, "SUB_DISTRICT_CODE": "342502", "SUB_DISTRICT_NAME": "ช่องเม็ก", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3076, "SUB_DISTRICT_CODE": "342503", "SUB_DISTRICT_NAME": "โนนก่อ", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3077, "SUB_DISTRICT_CODE": "342504", "SUB_DISTRICT_NAME": "นิคมสร้างตนเองลำโดมน้อย", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3078, "SUB_DISTRICT_CODE": "342505", "SUB_DISTRICT_NAME": "ฝางคำ", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3079, "SUB_DISTRICT_CODE": "342506", "SUB_DISTRICT_NAME": "คำเขื่อนแก้ว", "DISTRICT_ID": 336, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3080, "SUB_DISTRICT_CODE": "342601", "SUB_DISTRICT_NAME": "*ทุ่งเทิง", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3081, "SUB_DISTRICT_CODE": "342602", "SUB_DISTRICT_NAME": "หนองอ้ม", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3082, "SUB_DISTRICT_CODE": "342603", "SUB_DISTRICT_NAME": "นาเกษม", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3083, "SUB_DISTRICT_CODE": "342604", "SUB_DISTRICT_NAME": "กุดเรือ", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3084, "SUB_DISTRICT_CODE": "342605", "SUB_DISTRICT_NAME": "โคกชำแระ", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3085, "SUB_DISTRICT_CODE": "342606", "SUB_DISTRICT_NAME": "นาห่อม", "DISTRICT_ID": 337, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3086, "SUB_DISTRICT_CODE": "342701", "SUB_DISTRICT_NAME": "*หนองข่า", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3087, "SUB_DISTRICT_CODE": "342702", "SUB_DISTRICT_NAME": "*คำโพน", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3088, "SUB_DISTRICT_CODE": "342703", "SUB_DISTRICT_NAME": "*นาหว้า", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3089, "SUB_DISTRICT_CODE": "342704", "SUB_DISTRICT_NAME": "*ลือ", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3090, "SUB_DISTRICT_CODE": "342705", "SUB_DISTRICT_NAME": "*ห้วย", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3091, "SUB_DISTRICT_CODE": "342706", "SUB_DISTRICT_NAME": "*โนนงาม", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3092, "SUB_DISTRICT_CODE": "342707", "SUB_DISTRICT_NAME": "*นาป่าแซง", "DISTRICT_ID": 338, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3093, "SUB_DISTRICT_CODE": "342901", "SUB_DISTRICT_NAME": "นาเยีย", "DISTRICT_ID": 340, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3094, "SUB_DISTRICT_CODE": "342902", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 340, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3095, "SUB_DISTRICT_CODE": "342903", "SUB_DISTRICT_NAME": "นาเรือง", "DISTRICT_ID": 340, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3096, "SUB_DISTRICT_CODE": "343001", "SUB_DISTRICT_NAME": "นาตาล", "DISTRICT_ID": 341, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3097, "SUB_DISTRICT_CODE": "343002", "SUB_DISTRICT_NAME": "พะลาน", "DISTRICT_ID": 341, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3098, "SUB_DISTRICT_CODE": "343003", "SUB_DISTRICT_NAME": "กองโพน", "DISTRICT_ID": 341, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3099, "SUB_DISTRICT_CODE": "343004", "SUB_DISTRICT_NAME": "พังเคน", "DISTRICT_ID": 341, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3100, "SUB_DISTRICT_CODE": "343101", "SUB_DISTRICT_NAME": "เหล่าเสือโก้ก", "DISTRICT_ID": 342, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3101, "SUB_DISTRICT_CODE": "343102", "SUB_DISTRICT_NAME": "โพนเมือง", "DISTRICT_ID": 342, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3102, "SUB_DISTRICT_CODE": "343103", "SUB_DISTRICT_NAME": "แพงใหญ่", "DISTRICT_ID": 342, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3103, "SUB_DISTRICT_CODE": "343104", "SUB_DISTRICT_NAME": "หนองบก", "DISTRICT_ID": 342, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3104, "SUB_DISTRICT_CODE": "343201", "SUB_DISTRICT_NAME": "แก่งโดม", "DISTRICT_ID": 343, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3105, "SUB_DISTRICT_CODE": "343202", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 343, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3106, "SUB_DISTRICT_CODE": "343203", "SUB_DISTRICT_NAME": "บุ่งมะแลง", "DISTRICT_ID": 343, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3107, "SUB_DISTRICT_CODE": "343204", "SUB_DISTRICT_NAME": "สว่าง", "DISTRICT_ID": 343, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3108, "SUB_DISTRICT_CODE": "343301", "SUB_DISTRICT_NAME": "ตาเกา", "DISTRICT_ID": 344, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3109, "SUB_DISTRICT_CODE": "343302", "SUB_DISTRICT_NAME": "ไพบูลย์", "DISTRICT_ID": 344, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3110, "SUB_DISTRICT_CODE": "343303", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 344, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3111, "SUB_DISTRICT_CODE": "343304", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 344, "PROVINCE_ID": 23, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3112, "SUB_DISTRICT_CODE": "350101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3113, "SUB_DISTRICT_CODE": "350102", "SUB_DISTRICT_NAME": "น้ำคำใหญ่", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3114, "SUB_DISTRICT_CODE": "350103", "SUB_DISTRICT_NAME": "ตาดทอง", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3115, "SUB_DISTRICT_CODE": "350104", "SUB_DISTRICT_NAME": "สำราญ", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3116, "SUB_DISTRICT_CODE": "350105", "SUB_DISTRICT_NAME": "ค้อเหนือ", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3117, "SUB_DISTRICT_CODE": "350106", "SUB_DISTRICT_NAME": "ดู่ทุ่ง", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3118, "SUB_DISTRICT_CODE": "350107", "SUB_DISTRICT_NAME": "เดิด", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3119, "SUB_DISTRICT_CODE": "350108", "SUB_DISTRICT_NAME": "ขั้นไดใหญ่", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3120, "SUB_DISTRICT_CODE": "350109", "SUB_DISTRICT_NAME": "ทุ่งแต้", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3121, "SUB_DISTRICT_CODE": "350110", "SUB_DISTRICT_NAME": "สิงห์", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3122, "SUB_DISTRICT_CODE": "350111", "SUB_DISTRICT_NAME": "นาสะไมย์", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3123, "SUB_DISTRICT_CODE": "350112", "SUB_DISTRICT_NAME": "เขื่องคำ", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3124, "SUB_DISTRICT_CODE": "350113", "SUB_DISTRICT_NAME": "หนองหิน", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3125, "SUB_DISTRICT_CODE": "350114", "SUB_DISTRICT_NAME": "หนองคู", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3126, "SUB_DISTRICT_CODE": "350115", "SUB_DISTRICT_NAME": "ขุมเงิน", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3127, "SUB_DISTRICT_CODE": "350116", "SUB_DISTRICT_NAME": "ทุ่งนางโอก", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3128, "SUB_DISTRICT_CODE": "350117", "SUB_DISTRICT_NAME": "หนองเรือ", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3129, "SUB_DISTRICT_CODE": "350118", "SUB_DISTRICT_NAME": "หนองเป็ด", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3130, "SUB_DISTRICT_CODE": "350196", "SUB_DISTRICT_NAME": "*นาเวียง", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3131, "SUB_DISTRICT_CODE": "350197", "SUB_DISTRICT_NAME": "*ดงมะไฟ", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3132, "SUB_DISTRICT_CODE": "350198", "SUB_DISTRICT_NAME": "*ดู่ลาย", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3133, "SUB_DISTRICT_CODE": "350199", "SUB_DISTRICT_NAME": "*ทรายมูล", "DISTRICT_ID": 346, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3134, "SUB_DISTRICT_CODE": "350201", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 347, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3135, "SUB_DISTRICT_CODE": "350202", "SUB_DISTRICT_NAME": "ดู่ลาด", "DISTRICT_ID": 347, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3136, "SUB_DISTRICT_CODE": "350203", "SUB_DISTRICT_NAME": "ดงมะไฟ", "DISTRICT_ID": 347, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3137, "SUB_DISTRICT_CODE": "350204", "SUB_DISTRICT_NAME": "นาเวียง", "DISTRICT_ID": 347, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3138, "SUB_DISTRICT_CODE": "350205", "SUB_DISTRICT_NAME": "ไผ่", "DISTRICT_ID": 347, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3139, "SUB_DISTRICT_CODE": "350301", "SUB_DISTRICT_NAME": "กุดชุม", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3140, "SUB_DISTRICT_CODE": "350302", "SUB_DISTRICT_NAME": "โนนเปือย", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3141, "SUB_DISTRICT_CODE": "350303", "SUB_DISTRICT_NAME": "กำแมด", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3142, "SUB_DISTRICT_CODE": "350304", "SUB_DISTRICT_NAME": "นาโส่", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3143, "SUB_DISTRICT_CODE": "350305", "SUB_DISTRICT_NAME": "ห้วยแก้ง", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3144, "SUB_DISTRICT_CODE": "350306", "SUB_DISTRICT_NAME": "หนองหมี", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3145, "SUB_DISTRICT_CODE": "350307", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3146, "SUB_DISTRICT_CODE": "350308", "SUB_DISTRICT_NAME": "คำน้ำสร้าง", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3147, "SUB_DISTRICT_CODE": "350309", "SUB_DISTRICT_NAME": "หนองแหน", "DISTRICT_ID": 348, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3148, "SUB_DISTRICT_CODE": "350401", "SUB_DISTRICT_NAME": "ลุมพุก", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3149, "SUB_DISTRICT_CODE": "350402", "SUB_DISTRICT_NAME": "ย่อ", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3150, "SUB_DISTRICT_CODE": "350403", "SUB_DISTRICT_NAME": "สงเปือย", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3151, "SUB_DISTRICT_CODE": "350404", "SUB_DISTRICT_NAME": "โพนทัน", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3152, "SUB_DISTRICT_CODE": "350405", "SUB_DISTRICT_NAME": "ทุ่งมน", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3153, "SUB_DISTRICT_CODE": "350406", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3154, "SUB_DISTRICT_CODE": "350407", "SUB_DISTRICT_NAME": "ดงแคนใหญ่", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3155, "SUB_DISTRICT_CODE": "350408", "SUB_DISTRICT_NAME": "กู่จาน", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3156, "SUB_DISTRICT_CODE": "350409", "SUB_DISTRICT_NAME": "นาแก", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3157, "SUB_DISTRICT_CODE": "350410", "SUB_DISTRICT_NAME": "กุดกุง", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3158, "SUB_DISTRICT_CODE": "350411", "SUB_DISTRICT_NAME": "เหล่าไฮ", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3159, "SUB_DISTRICT_CODE": "350412", "SUB_DISTRICT_NAME": "แคนน้อย", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3160, "SUB_DISTRICT_CODE": "350413", "SUB_DISTRICT_NAME": "ดงเจริญ", "DISTRICT_ID": 349, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3161, "SUB_DISTRICT_CODE": "350501", "SUB_DISTRICT_NAME": "โพธิ์ไทร", "DISTRICT_ID": 350, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3162, "SUB_DISTRICT_CODE": "350502", "SUB_DISTRICT_NAME": "กระจาย", "DISTRICT_ID": 350, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3163, "SUB_DISTRICT_CODE": "350503", "SUB_DISTRICT_NAME": "โคกนาโก", "DISTRICT_ID": 350, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3164, "SUB_DISTRICT_CODE": "350504", "SUB_DISTRICT_NAME": "เชียงเพ็ง", "DISTRICT_ID": 350, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3165, "SUB_DISTRICT_CODE": "350505", "SUB_DISTRICT_NAME": "ศรีฐาน", "DISTRICT_ID": 350, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3166, "SUB_DISTRICT_CODE": "350601", "SUB_DISTRICT_NAME": "ฟ้าหยาด", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3167, "SUB_DISTRICT_CODE": "350602", "SUB_DISTRICT_NAME": "หัวเมือง", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3168, "SUB_DISTRICT_CODE": "350603", "SUB_DISTRICT_NAME": "คูเมือง", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3169, "SUB_DISTRICT_CODE": "350604", "SUB_DISTRICT_NAME": "ผือฮี", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3170, "SUB_DISTRICT_CODE": "350605", "SUB_DISTRICT_NAME": "บากเรือ", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3171, "SUB_DISTRICT_CODE": "350606", "SUB_DISTRICT_NAME": "ม่วง", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3172, "SUB_DISTRICT_CODE": "350607", "SUB_DISTRICT_NAME": "โนนทราย", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3173, "SUB_DISTRICT_CODE": "350608", "SUB_DISTRICT_NAME": "บึงแก", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3174, "SUB_DISTRICT_CODE": "350609", "SUB_DISTRICT_NAME": "พระเสาร์", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3175, "SUB_DISTRICT_CODE": "350610", "SUB_DISTRICT_NAME": "สงยาง", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3176, "SUB_DISTRICT_CODE": "350696", "SUB_DISTRICT_NAME": "*ค้อวัง", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3177, "SUB_DISTRICT_CODE": "350697", "SUB_DISTRICT_NAME": "*น้ำอ้อม", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3178, "SUB_DISTRICT_CODE": "350698", "SUB_DISTRICT_NAME": "*กุดน้ำใส", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3179, "SUB_DISTRICT_CODE": "350699", "SUB_DISTRICT_NAME": "*ฟ้าห่วน", "DISTRICT_ID": 351, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3180, "SUB_DISTRICT_CODE": "350701", "SUB_DISTRICT_NAME": "ฟ้าห่วน", "DISTRICT_ID": 352, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3181, "SUB_DISTRICT_CODE": "350702", "SUB_DISTRICT_NAME": "กุดน้ำใส", "DISTRICT_ID": 352, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3182, "SUB_DISTRICT_CODE": "350703", "SUB_DISTRICT_NAME": "น้ำอ้อม", "DISTRICT_ID": 352, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3183, "SUB_DISTRICT_CODE": "350704", "SUB_DISTRICT_NAME": "ค้อวัง", "DISTRICT_ID": 352, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3184, "SUB_DISTRICT_CODE": "350801", "SUB_DISTRICT_NAME": "*น้ำคำ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3185, "SUB_DISTRICT_CODE": "350802", "SUB_DISTRICT_NAME": "บุ่งค้า", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3186, "SUB_DISTRICT_CODE": "350803", "SUB_DISTRICT_NAME": "สวาท", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3187, "SUB_DISTRICT_CODE": "350804", "SUB_DISTRICT_NAME": "*ส้มผ่อ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3188, "SUB_DISTRICT_CODE": "350805", "SUB_DISTRICT_NAME": "ห้องแซง", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3189, "SUB_DISTRICT_CODE": "350806", "SUB_DISTRICT_NAME": "สามัคคี", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3190, "SUB_DISTRICT_CODE": "350807", "SUB_DISTRICT_NAME": "กุดเชียงหมี", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3191, "SUB_DISTRICT_CODE": "350808", "SUB_DISTRICT_NAME": "*คำเตย", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3192, "SUB_DISTRICT_CODE": "350809", "SUB_DISTRICT_NAME": "*คำไผ่", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3193, "SUB_DISTRICT_CODE": "350810", "SUB_DISTRICT_NAME": "สามแยก", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3194, "SUB_DISTRICT_CODE": "350811", "SUB_DISTRICT_NAME": "กุดแห่", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3195, "SUB_DISTRICT_CODE": "350812", "SUB_DISTRICT_NAME": "โคกสำราญ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3196, "SUB_DISTRICT_CODE": "350813", "SUB_DISTRICT_NAME": "สร้างมิ่ง", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3197, "SUB_DISTRICT_CODE": "350814", "SUB_DISTRICT_NAME": "ศรีแก้ว", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3198, "SUB_DISTRICT_CODE": "350815", "SUB_DISTRICT_NAME": "*ไทยเจริญ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3199, "SUB_DISTRICT_CODE": "350895", "SUB_DISTRICT_NAME": "*ไทยเจริญ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3200, "SUB_DISTRICT_CODE": "350896", "SUB_DISTRICT_NAME": "*คำไผ่", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3201, "SUB_DISTRICT_CODE": "350897", "SUB_DISTRICT_NAME": "*คำเตย", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3202, "SUB_DISTRICT_CODE": "350898", "SUB_DISTRICT_NAME": "*ส้มผ่อ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3203, "SUB_DISTRICT_CODE": "350899", "SUB_DISTRICT_NAME": "*น้ำคำ", "DISTRICT_ID": 353, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3204, "SUB_DISTRICT_CODE": "350901", "SUB_DISTRICT_NAME": "ไทยเจริญ", "DISTRICT_ID": 354, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3205, "SUB_DISTRICT_CODE": "350902", "SUB_DISTRICT_NAME": "น้ำคำ", "DISTRICT_ID": 354, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3206, "SUB_DISTRICT_CODE": "350903", "SUB_DISTRICT_NAME": "ส้มผ่อ", "DISTRICT_ID": 354, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3207, "SUB_DISTRICT_CODE": "350904", "SUB_DISTRICT_NAME": "คำเตย", "DISTRICT_ID": 354, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3208, "SUB_DISTRICT_CODE": "350905", "SUB_DISTRICT_NAME": "คำไผ่", "DISTRICT_ID": 354, "PROVINCE_ID": 24, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3209, "SUB_DISTRICT_CODE": "360101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3210, "SUB_DISTRICT_CODE": "360102", "SUB_DISTRICT_NAME": "รอบเมือง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3211, "SUB_DISTRICT_CODE": "360103", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3212, "SUB_DISTRICT_CODE": "360104", "SUB_DISTRICT_NAME": "นาฝาย", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3213, "SUB_DISTRICT_CODE": "360105", "SUB_DISTRICT_NAME": "บ้านค่าย", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3214, "SUB_DISTRICT_CODE": "360106", "SUB_DISTRICT_NAME": "กุดตุ้ม", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3215, "SUB_DISTRICT_CODE": "360107", "SUB_DISTRICT_NAME": "ชีลอง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3216, "SUB_DISTRICT_CODE": "360108", "SUB_DISTRICT_NAME": "บ้านเล่า", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3217, "SUB_DISTRICT_CODE": "360109", "SUB_DISTRICT_NAME": "นาเสียว", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3218, "SUB_DISTRICT_CODE": "360110", "SUB_DISTRICT_NAME": "หนองนาแซง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3219, "SUB_DISTRICT_CODE": "360111", "SUB_DISTRICT_NAME": "ลาดใหญ่", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3220, "SUB_DISTRICT_CODE": "360112", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3221, "SUB_DISTRICT_CODE": "360113", "SUB_DISTRICT_NAME": "ท่าหินโงม", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3222, "SUB_DISTRICT_CODE": "360114", "SUB_DISTRICT_NAME": "ห้วยต้อน", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3223, "SUB_DISTRICT_CODE": "360115", "SUB_DISTRICT_NAME": "ห้วยบง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3224, "SUB_DISTRICT_CODE": "360116", "SUB_DISTRICT_NAME": "โนนสำราญ", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3225, "SUB_DISTRICT_CODE": "360117", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3226, "SUB_DISTRICT_CODE": "360118", "SUB_DISTRICT_NAME": "บุ่งคล้า", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3227, "SUB_DISTRICT_CODE": "360119", "SUB_DISTRICT_NAME": "ซับสีทอง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3228, "SUB_DISTRICT_CODE": "360198", "SUB_DISTRICT_NAME": "*เจาทอง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3229, "SUB_DISTRICT_CODE": "360199", "SUB_DISTRICT_NAME": "*บ้านเจียง", "DISTRICT_ID": 355, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3230, "SUB_DISTRICT_CODE": "360201", "SUB_DISTRICT_NAME": "บ้านเขว้า", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3231, "SUB_DISTRICT_CODE": "360202", "SUB_DISTRICT_NAME": "ตลาดแร้ง", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3232, "SUB_DISTRICT_CODE": "360203", "SUB_DISTRICT_NAME": "ลุ่มลำชี", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3233, "SUB_DISTRICT_CODE": "360204", "SUB_DISTRICT_NAME": "ชีบน", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3234, "SUB_DISTRICT_CODE": "360205", "SUB_DISTRICT_NAME": "ภูแลนคา", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3235, "SUB_DISTRICT_CODE": "360206", "SUB_DISTRICT_NAME": "โนนแดง", "DISTRICT_ID": 356, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3236, "SUB_DISTRICT_CODE": "360301", "SUB_DISTRICT_NAME": "คอนสวรรค์", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3237, "SUB_DISTRICT_CODE": "360302", "SUB_DISTRICT_NAME": "ยางหวาย", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3238, "SUB_DISTRICT_CODE": "360303", "SUB_DISTRICT_NAME": "ช่องสามหมอ", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3239, "SUB_DISTRICT_CODE": "360304", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3240, "SUB_DISTRICT_CODE": "360305", "SUB_DISTRICT_NAME": "ห้วยไร่", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3241, "SUB_DISTRICT_CODE": "360306", "SUB_DISTRICT_NAME": "บ้านโสก", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3242, "SUB_DISTRICT_CODE": "360307", "SUB_DISTRICT_NAME": "โคกมั่งงอย", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3243, "SUB_DISTRICT_CODE": "360308", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3244, "SUB_DISTRICT_CODE": "360309", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 357, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3245, "SUB_DISTRICT_CODE": "360401", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3246, "SUB_DISTRICT_CODE": "360402", "SUB_DISTRICT_NAME": "บ้านหัน", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3247, "SUB_DISTRICT_CODE": "360403", "SUB_DISTRICT_NAME": "บ้านเดื่อ", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3248, "SUB_DISTRICT_CODE": "360404", "SUB_DISTRICT_NAME": "บ้านเป้า", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3249, "SUB_DISTRICT_CODE": "360405", "SUB_DISTRICT_NAME": "กุดเลาะ", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3250, "SUB_DISTRICT_CODE": "360406", "SUB_DISTRICT_NAME": "โนนกอก", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3251, "SUB_DISTRICT_CODE": "360407", "SUB_DISTRICT_NAME": "สระโพนทอง", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3252, "SUB_DISTRICT_CODE": "360408", "SUB_DISTRICT_NAME": "หนองข่า", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3253, "SUB_DISTRICT_CODE": "360409", "SUB_DISTRICT_NAME": "หนองโพนงาม", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3254, "SUB_DISTRICT_CODE": "360410", "SUB_DISTRICT_NAME": "บ้านบัว", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3255, "SUB_DISTRICT_CODE": "360411", "SUB_DISTRICT_NAME": "ซับสีทอง", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3256, "SUB_DISTRICT_CODE": "360412", "SUB_DISTRICT_NAME": "โนนทอง", "DISTRICT_ID": 358, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3257, "SUB_DISTRICT_CODE": "360501", "SUB_DISTRICT_NAME": "หนองบัวแดง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3258, "SUB_DISTRICT_CODE": "360502", "SUB_DISTRICT_NAME": "กุดชุมแสง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3259, "SUB_DISTRICT_CODE": "360503", "SUB_DISTRICT_NAME": "ถ้ำวัวแดง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3260, "SUB_DISTRICT_CODE": "360504", "SUB_DISTRICT_NAME": "นางแดด", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3261, "SUB_DISTRICT_CODE": "360505", "SUB_DISTRICT_NAME": "*บ้านเจียง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3262, "SUB_DISTRICT_CODE": "360506", "SUB_DISTRICT_NAME": "*เจาทอง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3263, "SUB_DISTRICT_CODE": "360507", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3264, "SUB_DISTRICT_CODE": "360508", "SUB_DISTRICT_NAME": "คูเมือง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3265, "SUB_DISTRICT_CODE": "360509", "SUB_DISTRICT_NAME": "ท่าใหญ่", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3266, "SUB_DISTRICT_CODE": "360510", "SUB_DISTRICT_NAME": "*วังทอง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3267, "SUB_DISTRICT_CODE": "360511", "SUB_DISTRICT_NAME": "วังชมภู", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3268, "SUB_DISTRICT_CODE": "360598", "SUB_DISTRICT_NAME": "*เจาทอง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3269, "SUB_DISTRICT_CODE": "360599", "SUB_DISTRICT_NAME": "*บ้านเจียง", "DISTRICT_ID": 359, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3270, "SUB_DISTRICT_CODE": "360601", "SUB_DISTRICT_NAME": "บ้านกอก", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3271, "SUB_DISTRICT_CODE": "360602", "SUB_DISTRICT_NAME": "หนองบัวบาน", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3272, "SUB_DISTRICT_CODE": "360603", "SUB_DISTRICT_NAME": "บ้านขาม", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3273, "SUB_DISTRICT_CODE": "360604", "SUB_DISTRICT_NAME": "*หนองฉิม", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3274, "SUB_DISTRICT_CODE": "360605", "SUB_DISTRICT_NAME": "กุดน้ำใส", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3275, "SUB_DISTRICT_CODE": "360606", "SUB_DISTRICT_NAME": "หนองโดน", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3276, "SUB_DISTRICT_CODE": "360607", "SUB_DISTRICT_NAME": "ละหาน", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3277, "SUB_DISTRICT_CODE": "360608", "SUB_DISTRICT_NAME": "*ตาเนิน", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3278, "SUB_DISTRICT_CODE": "360609", "SUB_DISTRICT_NAME": "*กะฮาด", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3279, "SUB_DISTRICT_CODE": "360610", "SUB_DISTRICT_NAME": "หนองบัวใหญ่", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3280, "SUB_DISTRICT_CODE": "360611", "SUB_DISTRICT_NAME": "หนองบัวโคก", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3281, "SUB_DISTRICT_CODE": "360612", "SUB_DISTRICT_NAME": "ท่ากูบ*", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3282, "SUB_DISTRICT_CODE": "360613", "SUB_DISTRICT_NAME": "ส้มป่อย", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3283, "SUB_DISTRICT_CODE": "360614", "SUB_DISTRICT_NAME": "ซับใหญ่*", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3284, "SUB_DISTRICT_CODE": "360615", "SUB_DISTRICT_NAME": "*รังงาม", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3285, "SUB_DISTRICT_CODE": "360616", "SUB_DISTRICT_NAME": "ตะโกทอง*", "DISTRICT_ID": 360, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3286, "SUB_DISTRICT_CODE": "360701", "SUB_DISTRICT_NAME": "บ้านชวน", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3287, "SUB_DISTRICT_CODE": "360702", "SUB_DISTRICT_NAME": "บ้านเพชร", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3288, "SUB_DISTRICT_CODE": "360703", "SUB_DISTRICT_NAME": "บ้านตาล", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3289, "SUB_DISTRICT_CODE": "360704", "SUB_DISTRICT_NAME": "หัวทะเล", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3290, "SUB_DISTRICT_CODE": "360705", "SUB_DISTRICT_NAME": "โคกเริงรมย์", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3291, "SUB_DISTRICT_CODE": "360706", "SUB_DISTRICT_NAME": "เกาะมะนาว", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3292, "SUB_DISTRICT_CODE": "360707", "SUB_DISTRICT_NAME": "โคกเพชรพัฒนา", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3293, "SUB_DISTRICT_CODE": "360796", "SUB_DISTRICT_NAME": "*บ้านไร่", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3294, "SUB_DISTRICT_CODE": "360797", "SUB_DISTRICT_NAME": "*นายางกลัก", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3295, "SUB_DISTRICT_CODE": "360798", "SUB_DISTRICT_NAME": "*ห้วยยายจิ๋ว", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3296, "SUB_DISTRICT_CODE": "360799", "SUB_DISTRICT_NAME": "*วะตะแบก", "DISTRICT_ID": 361, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3297, "SUB_DISTRICT_CODE": "360801", "SUB_DISTRICT_NAME": "หนองบัวระเหว", "DISTRICT_ID": 362, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3298, "SUB_DISTRICT_CODE": "360802", "SUB_DISTRICT_NAME": "วังตะเฆ่", "DISTRICT_ID": 362, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3299, "SUB_DISTRICT_CODE": "360803", "SUB_DISTRICT_NAME": "ห้วยแย้", "DISTRICT_ID": 362, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3300, "SUB_DISTRICT_CODE": "360804", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 362, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3301, "SUB_DISTRICT_CODE": "360805", "SUB_DISTRICT_NAME": "โสกปลาดุก", "DISTRICT_ID": 362, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3302, "SUB_DISTRICT_CODE": "360901", "SUB_DISTRICT_NAME": "วะตะแบก", "DISTRICT_ID": 363, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3303, "SUB_DISTRICT_CODE": "360902", "SUB_DISTRICT_NAME": "ห้วยยายจิ๋ว", "DISTRICT_ID": 363, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3304, "SUB_DISTRICT_CODE": "360903", "SUB_DISTRICT_NAME": "นายางกลัก", "DISTRICT_ID": 363, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3305, "SUB_DISTRICT_CODE": "360904", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 363, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3306, "SUB_DISTRICT_CODE": "360905", "SUB_DISTRICT_NAME": "โป่งนก", "DISTRICT_ID": 363, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3307, "SUB_DISTRICT_CODE": "361001", "SUB_DISTRICT_NAME": "ผักปัง", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3308, "SUB_DISTRICT_CODE": "361002", "SUB_DISTRICT_NAME": "กวางโจน", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3309, "SUB_DISTRICT_CODE": "361003", "SUB_DISTRICT_NAME": "หนองคอนไทย", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3310, "SUB_DISTRICT_CODE": "361004", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3311, "SUB_DISTRICT_CODE": "361005", "SUB_DISTRICT_NAME": "กุดยม", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3312, "SUB_DISTRICT_CODE": "361006", "SUB_DISTRICT_NAME": "บ้านเพชร", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3313, "SUB_DISTRICT_CODE": "361007", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3314, "SUB_DISTRICT_CODE": "361008", "SUB_DISTRICT_NAME": "หนองตูม", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3315, "SUB_DISTRICT_CODE": "361009", "SUB_DISTRICT_NAME": "โอโล", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3316, "SUB_DISTRICT_CODE": "361010", "SUB_DISTRICT_NAME": "ธาตุทอง", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3317, "SUB_DISTRICT_CODE": "361011", "SUB_DISTRICT_NAME": "บ้านดอน", "DISTRICT_ID": 364, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3318, "SUB_DISTRICT_CODE": "361101", "SUB_DISTRICT_NAME": "บ้านแท่น", "DISTRICT_ID": 365, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3319, "SUB_DISTRICT_CODE": "361102", "SUB_DISTRICT_NAME": "สามสวน", "DISTRICT_ID": 365, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3320, "SUB_DISTRICT_CODE": "361103", "SUB_DISTRICT_NAME": "สระพัง", "DISTRICT_ID": 365, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3321, "SUB_DISTRICT_CODE": "361104", "SUB_DISTRICT_NAME": "บ้านเต่า", "DISTRICT_ID": 365, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3322, "SUB_DISTRICT_CODE": "361105", "SUB_DISTRICT_NAME": "หนองคู", "DISTRICT_ID": 365, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3323, "SUB_DISTRICT_CODE": "361201", "SUB_DISTRICT_NAME": "ช่องสามหมอ", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3324, "SUB_DISTRICT_CODE": "361202", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3325, "SUB_DISTRICT_CODE": "361203", "SUB_DISTRICT_NAME": "นาหนองทุ่ม", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3326, "SUB_DISTRICT_CODE": "361204", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3327, "SUB_DISTRICT_CODE": "361205", "SUB_DISTRICT_NAME": "หนองสังข์", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3328, "SUB_DISTRICT_CODE": "361206", "SUB_DISTRICT_NAME": "หลุบคา", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3329, "SUB_DISTRICT_CODE": "361207", "SUB_DISTRICT_NAME": "โคกกุง", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3330, "SUB_DISTRICT_CODE": "361208", "SUB_DISTRICT_NAME": "เก่าย่าดี", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3331, "SUB_DISTRICT_CODE": "361209", "SUB_DISTRICT_NAME": "ท่ามะไฟหวาน", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3332, "SUB_DISTRICT_CODE": "361210", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 366, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3333, "SUB_DISTRICT_CODE": "361301", "SUB_DISTRICT_NAME": "คอนสาร", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3334, "SUB_DISTRICT_CODE": "361302", "SUB_DISTRICT_NAME": "ทุ่งพระ", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3335, "SUB_DISTRICT_CODE": "361303", "SUB_DISTRICT_NAME": "โนนคูณ", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3336, "SUB_DISTRICT_CODE": "361304", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3337, "SUB_DISTRICT_CODE": "361305", "SUB_DISTRICT_NAME": "ทุ่งลุยลาย", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3338, "SUB_DISTRICT_CODE": "361306", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3339, "SUB_DISTRICT_CODE": "361307", "SUB_DISTRICT_NAME": "ทุ่งนาเลา", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3340, "SUB_DISTRICT_CODE": "361308", "SUB_DISTRICT_NAME": "ดงกลาง", "DISTRICT_ID": 367, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3341, "SUB_DISTRICT_CODE": "361401", "SUB_DISTRICT_NAME": "บ้านเจียง", "DISTRICT_ID": 368, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3342, "SUB_DISTRICT_CODE": "361402", "SUB_DISTRICT_NAME": "เจาทอง", "DISTRICT_ID": 368, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3343, "SUB_DISTRICT_CODE": "361403", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 368, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3344, "SUB_DISTRICT_CODE": "361404", "SUB_DISTRICT_NAME": "แหลมทอง", "DISTRICT_ID": 368, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3345, "SUB_DISTRICT_CODE": "361501", "SUB_DISTRICT_NAME": "หนองฉิม", "DISTRICT_ID": 369, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3346, "SUB_DISTRICT_CODE": "361502", "SUB_DISTRICT_NAME": "ตาเนิน", "DISTRICT_ID": 369, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3347, "SUB_DISTRICT_CODE": "361503", "SUB_DISTRICT_NAME": "กะฮาด", "DISTRICT_ID": 369, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3348, "SUB_DISTRICT_CODE": "361504", "SUB_DISTRICT_NAME": "รังงาม", "DISTRICT_ID": 369, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3349, "SUB_DISTRICT_CODE": "361601", "SUB_DISTRICT_NAME": "ซับใหญ่", "DISTRICT_ID": 370, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3350, "SUB_DISTRICT_CODE": "361602", "SUB_DISTRICT_NAME": "ท่ากูบ", "DISTRICT_ID": 370, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3351, "SUB_DISTRICT_CODE": "361603", "SUB_DISTRICT_NAME": "ตะโกทอง", "DISTRICT_ID": 370, "PROVINCE_ID": 25, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3352, "SUB_DISTRICT_CODE": "370101", "SUB_DISTRICT_NAME": "บุ่ง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3353, "SUB_DISTRICT_CODE": "370102", "SUB_DISTRICT_NAME": "ไก่คำ", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3354, "SUB_DISTRICT_CODE": "370103", "SUB_DISTRICT_NAME": "นาจิก", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3355, "SUB_DISTRICT_CODE": "370104", "SUB_DISTRICT_NAME": "ปลาค้าว", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3356, "SUB_DISTRICT_CODE": "370105", "SUB_DISTRICT_NAME": "เหล่าพรวน", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3357, "SUB_DISTRICT_CODE": "370106", "SUB_DISTRICT_NAME": "สร้างนกทา", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3358, "SUB_DISTRICT_CODE": "370107", "SUB_DISTRICT_NAME": "คึมใหญ่", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3359, "SUB_DISTRICT_CODE": "370108", "SUB_DISTRICT_NAME": "นาผือ", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3360, "SUB_DISTRICT_CODE": "370109", "SUB_DISTRICT_NAME": "น้ำปลีก", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3361, "SUB_DISTRICT_CODE": "370110", "SUB_DISTRICT_NAME": "นาวัง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3362, "SUB_DISTRICT_CODE": "370111", "SUB_DISTRICT_NAME": "นาหมอม้า", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3363, "SUB_DISTRICT_CODE": "370112", "SUB_DISTRICT_NAME": "โนนโพธิ์", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3364, "SUB_DISTRICT_CODE": "370113", "SUB_DISTRICT_NAME": "โนนหนามแท่ง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3365, "SUB_DISTRICT_CODE": "370114", "SUB_DISTRICT_NAME": "ห้วยไร่", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3366, "SUB_DISTRICT_CODE": "370115", "SUB_DISTRICT_NAME": "หนองมะแซว", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3367, "SUB_DISTRICT_CODE": "370116", "SUB_DISTRICT_NAME": "กุดปลาดุก", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3368, "SUB_DISTRICT_CODE": "370117", "SUB_DISTRICT_NAME": "ดอนเมย", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3369, "SUB_DISTRICT_CODE": "370118", "SUB_DISTRICT_NAME": "นายม", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3370, "SUB_DISTRICT_CODE": "370119", "SUB_DISTRICT_NAME": "นาแต้", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3371, "SUB_DISTRICT_CODE": "370190", "SUB_DISTRICT_NAME": "*โพนทอง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3372, "SUB_DISTRICT_CODE": "370191", "SUB_DISTRICT_NAME": "*ดงมะยาง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3373, "SUB_DISTRICT_CODE": "370192", "SUB_DISTRICT_NAME": "*เปือย", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3374, "SUB_DISTRICT_CODE": "370193", "SUB_DISTRICT_NAME": "*หนองไฮ", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3375, "SUB_DISTRICT_CODE": "370194", "SUB_DISTRICT_NAME": "*นาเวียง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3376, "SUB_DISTRICT_CODE": "370195", "SUB_DISTRICT_NAME": "*ไร่ขี", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3377, "SUB_DISTRICT_CODE": "370196", "SUB_DISTRICT_NAME": "*ไร่สีสุก", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3378, "SUB_DISTRICT_CODE": "370197", "SUB_DISTRICT_NAME": "*เสนางคนิคม", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3379, "SUB_DISTRICT_CODE": "370198", "SUB_DISTRICT_NAME": "*อำนาจ", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3380, "SUB_DISTRICT_CODE": "370199", "SUB_DISTRICT_NAME": "*ดงบัง", "DISTRICT_ID": 380, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3381, "SUB_DISTRICT_CODE": "370201", "SUB_DISTRICT_NAME": "ชานุมาน", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3382, "SUB_DISTRICT_CODE": "370202", "SUB_DISTRICT_NAME": "โคกสาร", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3383, "SUB_DISTRICT_CODE": "370203", "SUB_DISTRICT_NAME": "คำเขื่อนแก้ว", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3384, "SUB_DISTRICT_CODE": "370204", "SUB_DISTRICT_NAME": "โคกก่ง", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3385, "SUB_DISTRICT_CODE": "370205", "SUB_DISTRICT_NAME": "ป่าก่อ", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3386, "SUB_DISTRICT_CODE": "370299", "SUB_DISTRICT_NAME": "*หนองข่า", "DISTRICT_ID": 381, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3387, "SUB_DISTRICT_CODE": "370301", "SUB_DISTRICT_NAME": "หนองข่า", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3388, "SUB_DISTRICT_CODE": "370302", "SUB_DISTRICT_NAME": "คำโพน", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3389, "SUB_DISTRICT_CODE": "370303", "SUB_DISTRICT_NAME": "นาหว้า", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3390, "SUB_DISTRICT_CODE": "370304", "SUB_DISTRICT_NAME": "ลือ", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3391, "SUB_DISTRICT_CODE": "370305", "SUB_DISTRICT_NAME": "ห้วย", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3392, "SUB_DISTRICT_CODE": "370306", "SUB_DISTRICT_NAME": "โนนงาม", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3393, "SUB_DISTRICT_CODE": "370307", "SUB_DISTRICT_NAME": "นาป่าแซง", "DISTRICT_ID": 382, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3394, "SUB_DISTRICT_CODE": "370401", "SUB_DISTRICT_NAME": "พนา", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3395, "SUB_DISTRICT_CODE": "370402", "SUB_DISTRICT_NAME": "จานลาน", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3396, "SUB_DISTRICT_CODE": "370403", "SUB_DISTRICT_NAME": "ไม้กลอน", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3397, "SUB_DISTRICT_CODE": "370404", "SUB_DISTRICT_NAME": "พระเหลา", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3398, "SUB_DISTRICT_CODE": "370497", "SUB_DISTRICT_NAME": "*นาหว้า", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3399, "SUB_DISTRICT_CODE": "370498", "SUB_DISTRICT_NAME": "*ลือ", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3400, "SUB_DISTRICT_CODE": "370499", "SUB_DISTRICT_NAME": "*ห้วย", "DISTRICT_ID": 383, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3401, "SUB_DISTRICT_CODE": "370501", "SUB_DISTRICT_NAME": "เสนางคนิคม", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3402, "SUB_DISTRICT_CODE": "370502", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3403, "SUB_DISTRICT_CODE": "370503", "SUB_DISTRICT_NAME": "ไร่สีสุก", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3404, "SUB_DISTRICT_CODE": "370504", "SUB_DISTRICT_NAME": "นาเวียง", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3405, "SUB_DISTRICT_CODE": "370505", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3406, "SUB_DISTRICT_CODE": "370506", "SUB_DISTRICT_NAME": "หนองสามสี", "DISTRICT_ID": 384, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3407, "SUB_DISTRICT_CODE": "370601", "SUB_DISTRICT_NAME": "หัวตะพาน", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3408, "SUB_DISTRICT_CODE": "370602", "SUB_DISTRICT_NAME": "คำพระ", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3409, "SUB_DISTRICT_CODE": "370603", "SUB_DISTRICT_NAME": "เค็งใหญ่", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3410, "SUB_DISTRICT_CODE": "370604", "SUB_DISTRICT_NAME": "หนองแก้ว", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3411, "SUB_DISTRICT_CODE": "370605", "SUB_DISTRICT_NAME": "โพนเมืองน้อย", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3412, "SUB_DISTRICT_CODE": "370606", "SUB_DISTRICT_NAME": "สร้างถ่อน้อย", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3413, "SUB_DISTRICT_CODE": "370607", "SUB_DISTRICT_NAME": "จิกดู่", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3414, "SUB_DISTRICT_CODE": "370608", "SUB_DISTRICT_NAME": "รัตนวารี", "DISTRICT_ID": 385, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3415, "SUB_DISTRICT_CODE": "370701", "SUB_DISTRICT_NAME": "อำนาจ", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3416, "SUB_DISTRICT_CODE": "370702", "SUB_DISTRICT_NAME": "ดงมะยาง", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3417, "SUB_DISTRICT_CODE": "370703", "SUB_DISTRICT_NAME": "เปือย", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3418, "SUB_DISTRICT_CODE": "370704", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3419, "SUB_DISTRICT_CODE": "370705", "SUB_DISTRICT_NAME": "ไร่ขี", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3420, "SUB_DISTRICT_CODE": "370706", "SUB_DISTRICT_NAME": "แมด", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3421, "SUB_DISTRICT_CODE": "370707", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 386, "PROVINCE_ID": 26, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3422, "SUB_DISTRICT_CODE": "390101", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3423, "SUB_DISTRICT_CODE": "390102", "SUB_DISTRICT_NAME": "หนองภัยศูนย์", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3424, "SUB_DISTRICT_CODE": "390103", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3425, "SUB_DISTRICT_CODE": "390104", "SUB_DISTRICT_NAME": "หนองสวรรค์", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3426, "SUB_DISTRICT_CODE": "390105", "SUB_DISTRICT_NAME": "หัวนา", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3427, "SUB_DISTRICT_CODE": "390106", "SUB_DISTRICT_NAME": "บ้านขาม", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3428, "SUB_DISTRICT_CODE": "390107", "SUB_DISTRICT_NAME": "นามะเฟือง", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3429, "SUB_DISTRICT_CODE": "390108", "SUB_DISTRICT_NAME": "บ้านพร้าว", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3430, "SUB_DISTRICT_CODE": "390109", "SUB_DISTRICT_NAME": "โนนขมิ้น", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3431, "SUB_DISTRICT_CODE": "390110", "SUB_DISTRICT_NAME": "ลำภู", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3432, "SUB_DISTRICT_CODE": "390111", "SUB_DISTRICT_NAME": "กุดจิก", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3433, "SUB_DISTRICT_CODE": "390112", "SUB_DISTRICT_NAME": "โนนทัน", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3434, "SUB_DISTRICT_CODE": "390113", "SUB_DISTRICT_NAME": "นาคำไฮ", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3435, "SUB_DISTRICT_CODE": "390114", "SUB_DISTRICT_NAME": "ป่าไม้งาม", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3436, "SUB_DISTRICT_CODE": "390115", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 387, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3437, "SUB_DISTRICT_CODE": "390201", "SUB_DISTRICT_NAME": "นากลาง", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3438, "SUB_DISTRICT_CODE": "390202", "SUB_DISTRICT_NAME": "ด่านช้าง", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3439, "SUB_DISTRICT_CODE": "390203", "SUB_DISTRICT_NAME": "*นาเหล่า", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3440, "SUB_DISTRICT_CODE": "390204", "SUB_DISTRICT_NAME": "*นาแก", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3441, "SUB_DISTRICT_CODE": "390205", "SUB_DISTRICT_NAME": "กุดดินจี่", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3442, "SUB_DISTRICT_CODE": "390206", "SUB_DISTRICT_NAME": "ฝั่งแดง", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3443, "SUB_DISTRICT_CODE": "390207", "SUB_DISTRICT_NAME": "เก่ากลอย", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3444, "SUB_DISTRICT_CODE": "390208", "SUB_DISTRICT_NAME": "*วังทอง", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3445, "SUB_DISTRICT_CODE": "390209", "SUB_DISTRICT_NAME": "โนนเมือง", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3446, "SUB_DISTRICT_CODE": "390210", "SUB_DISTRICT_NAME": "อุทัยสวรรค์", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3447, "SUB_DISTRICT_CODE": "390211", "SUB_DISTRICT_NAME": "ดงสวรรค์", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3448, "SUB_DISTRICT_CODE": "390212", "SUB_DISTRICT_NAME": "*วังปลาป้อม", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3449, "SUB_DISTRICT_CODE": "390213", "SUB_DISTRICT_NAME": "กุดแห่", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3450, "SUB_DISTRICT_CODE": "390214", "SUB_DISTRICT_NAME": "*เทพคีรี", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3451, "SUB_DISTRICT_CODE": "390215", "SUB_DISTRICT_NAME": "โนนภูทอง*", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3452, "SUB_DISTRICT_CODE": "390296", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3453, "SUB_DISTRICT_CODE": "390297", "SUB_DISTRICT_NAME": "*นาสี", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3454, "SUB_DISTRICT_CODE": "390298", "SUB_DISTRICT_NAME": "*บ้านโคก", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3455, "SUB_DISTRICT_CODE": "390299", "SUB_DISTRICT_NAME": "*โคกนาเหล่า", "DISTRICT_ID": 388, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3456, "SUB_DISTRICT_CODE": "390301", "SUB_DISTRICT_NAME": "โนนสัง", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3457, "SUB_DISTRICT_CODE": "390302", "SUB_DISTRICT_NAME": "บ้านถิ่น", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3458, "SUB_DISTRICT_CODE": "390303", "SUB_DISTRICT_NAME": "หนองเรือ", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3459, "SUB_DISTRICT_CODE": "390304", "SUB_DISTRICT_NAME": "กุดดู่", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3460, "SUB_DISTRICT_CODE": "390305", "SUB_DISTRICT_NAME": "บ้านค้อ", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3461, "SUB_DISTRICT_CODE": "390306", "SUB_DISTRICT_NAME": "โนนเมือง", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3462, "SUB_DISTRICT_CODE": "390307", "SUB_DISTRICT_NAME": "โคกใหญ่", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3463, "SUB_DISTRICT_CODE": "390308", "SUB_DISTRICT_NAME": "โคกม่วง", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3464, "SUB_DISTRICT_CODE": "390309", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3465, "SUB_DISTRICT_CODE": "390310", "SUB_DISTRICT_NAME": "ปางกู่", "DISTRICT_ID": 389, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3466, "SUB_DISTRICT_CODE": "390401", "SUB_DISTRICT_NAME": "เมืองใหม่", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3467, "SUB_DISTRICT_CODE": "390402", "SUB_DISTRICT_NAME": "ศรีบุญเรือง", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3468, "SUB_DISTRICT_CODE": "390403", "SUB_DISTRICT_NAME": "หนองบัวใต้", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3469, "SUB_DISTRICT_CODE": "390404", "SUB_DISTRICT_NAME": "กุดสะเทียน", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3470, "SUB_DISTRICT_CODE": "390405", "SUB_DISTRICT_NAME": "นากอก", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3471, "SUB_DISTRICT_CODE": "390406", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3472, "SUB_DISTRICT_CODE": "390407", "SUB_DISTRICT_NAME": "ยางหล่อ", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3473, "SUB_DISTRICT_CODE": "390408", "SUB_DISTRICT_NAME": "โนนม่วง", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3474, "SUB_DISTRICT_CODE": "390409", "SUB_DISTRICT_NAME": "หนองกุงแก้ว", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3475, "SUB_DISTRICT_CODE": "390410", "SUB_DISTRICT_NAME": "หนองแก", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3476, "SUB_DISTRICT_CODE": "390411", "SUB_DISTRICT_NAME": "ทรายทอง", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3477, "SUB_DISTRICT_CODE": "390412", "SUB_DISTRICT_NAME": "หันนางาม", "DISTRICT_ID": 390, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3478, "SUB_DISTRICT_CODE": "390501", "SUB_DISTRICT_NAME": "นาสี", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3479, "SUB_DISTRICT_CODE": "390502", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3480, "SUB_DISTRICT_CODE": "390503", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3481, "SUB_DISTRICT_CODE": "390504", "SUB_DISTRICT_NAME": "นาด่าน", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3482, "SUB_DISTRICT_CODE": "390505", "SUB_DISTRICT_NAME": "ดงมะไฟ", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3483, "SUB_DISTRICT_CODE": "390506", "SUB_DISTRICT_NAME": "สุวรรณคูหา", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3484, "SUB_DISTRICT_CODE": "390507", "SUB_DISTRICT_NAME": "บุญทัน", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3485, "SUB_DISTRICT_CODE": "390508", "SUB_DISTRICT_NAME": "กุดผึ้ง", "DISTRICT_ID": 391, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3486, "SUB_DISTRICT_CODE": "390601", "SUB_DISTRICT_NAME": "นาเหล่า", "DISTRICT_ID": 392, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3487, "SUB_DISTRICT_CODE": "390602", "SUB_DISTRICT_NAME": "นาแก", "DISTRICT_ID": 392, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3488, "SUB_DISTRICT_CODE": "390603", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 392, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3489, "SUB_DISTRICT_CODE": "390604", "SUB_DISTRICT_NAME": "วังปลาป้อม", "DISTRICT_ID": 392, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3490, "SUB_DISTRICT_CODE": "390605", "SUB_DISTRICT_NAME": "เทพคีรี", "DISTRICT_ID": 392, "PROVINCE_ID": 27, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3491, "SUB_DISTRICT_CODE": "400101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3492, "SUB_DISTRICT_CODE": "400102", "SUB_DISTRICT_NAME": "สำราญ", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3493, "SUB_DISTRICT_CODE": "400103", "SUB_DISTRICT_NAME": "โคกสี", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3494, "SUB_DISTRICT_CODE": "400104", "SUB_DISTRICT_NAME": "ท่าพระ", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3495, "SUB_DISTRICT_CODE": "400105", "SUB_DISTRICT_NAME": "บ้านทุ่ม", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3496, "SUB_DISTRICT_CODE": "400106", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3497, "SUB_DISTRICT_CODE": "400107", "SUB_DISTRICT_NAME": "พระลับ", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3498, "SUB_DISTRICT_CODE": "400108", "SUB_DISTRICT_NAME": "สาวะถี", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3499, "SUB_DISTRICT_CODE": "400109", "SUB_DISTRICT_NAME": "บ้านหว้า", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3500, "SUB_DISTRICT_CODE": "400110", "SUB_DISTRICT_NAME": "บ้านค้อ", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3501, "SUB_DISTRICT_CODE": "400111", "SUB_DISTRICT_NAME": "แดงใหญ่", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3502, "SUB_DISTRICT_CODE": "400112", "SUB_DISTRICT_NAME": "ดอนช้าง", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3503, "SUB_DISTRICT_CODE": "400113", "SUB_DISTRICT_NAME": "ดอนหัน", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3504, "SUB_DISTRICT_CODE": "400114", "SUB_DISTRICT_NAME": "ศิลา", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3505, "SUB_DISTRICT_CODE": "400115", "SUB_DISTRICT_NAME": "บ้านเป็ด", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3506, "SUB_DISTRICT_CODE": "400116", "SUB_DISTRICT_NAME": "หนองตูม", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3507, "SUB_DISTRICT_CODE": "400117", "SUB_DISTRICT_NAME": "บึงเนียม", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3508, "SUB_DISTRICT_CODE": "400118", "SUB_DISTRICT_NAME": "โนนท่อน", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3509, "SUB_DISTRICT_CODE": "400198", "SUB_DISTRICT_NAME": "*บ้านโต้น", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3510, "SUB_DISTRICT_CODE": "400199", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 393, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3511, "SUB_DISTRICT_CODE": "400201", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3512, "SUB_DISTRICT_CODE": "400202", "SUB_DISTRICT_NAME": "ป่าหวายนั่ง", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3513, "SUB_DISTRICT_CODE": "400203", "SUB_DISTRICT_NAME": "โนนฆ้อง", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3514, "SUB_DISTRICT_CODE": "400204", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3515, "SUB_DISTRICT_CODE": "400205", "SUB_DISTRICT_NAME": "ป่ามะนาว", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3516, "SUB_DISTRICT_CODE": "400206", "SUB_DISTRICT_NAME": "บ้านฝาง", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3517, "SUB_DISTRICT_CODE": "400207", "SUB_DISTRICT_NAME": "โคกงาม", "DISTRICT_ID": 394, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3518, "SUB_DISTRICT_CODE": "400301", "SUB_DISTRICT_NAME": "พระยืน", "DISTRICT_ID": 395, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3519, "SUB_DISTRICT_CODE": "400302", "SUB_DISTRICT_NAME": "พระบุ", "DISTRICT_ID": 395, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3520, "SUB_DISTRICT_CODE": "400303", "SUB_DISTRICT_NAME": "บ้านโต้น", "DISTRICT_ID": 395, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3521, "SUB_DISTRICT_CODE": "400304", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 395, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3522, "SUB_DISTRICT_CODE": "400305", "SUB_DISTRICT_NAME": "ขามป้อม", "DISTRICT_ID": 395, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3523, "SUB_DISTRICT_CODE": "400401", "SUB_DISTRICT_NAME": "หนองเรือ", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3524, "SUB_DISTRICT_CODE": "400402", "SUB_DISTRICT_NAME": "บ้านเม็ง", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3525, "SUB_DISTRICT_CODE": "400403", "SUB_DISTRICT_NAME": "บ้านกง", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3526, "SUB_DISTRICT_CODE": "400404", "SUB_DISTRICT_NAME": "ยางคำ", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3527, "SUB_DISTRICT_CODE": "400405", "SUB_DISTRICT_NAME": "จระเข้", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3528, "SUB_DISTRICT_CODE": "400406", "SUB_DISTRICT_NAME": "โนนทอง", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3529, "SUB_DISTRICT_CODE": "400407", "SUB_DISTRICT_NAME": "กุดกว้าง", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3530, "SUB_DISTRICT_CODE": "400408", "SUB_DISTRICT_NAME": "โนนทัน", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3531, "SUB_DISTRICT_CODE": "400409", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3532, "SUB_DISTRICT_CODE": "400410", "SUB_DISTRICT_NAME": "บ้านผือ", "DISTRICT_ID": 396, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3533, "SUB_DISTRICT_CODE": "400501", "SUB_DISTRICT_NAME": "ชุมแพ", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3534, "SUB_DISTRICT_CODE": "400502", "SUB_DISTRICT_NAME": "โนนหัน", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3535, "SUB_DISTRICT_CODE": "400503", "SUB_DISTRICT_NAME": "นาหนองทุ่ม", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3536, "SUB_DISTRICT_CODE": "400504", "SUB_DISTRICT_NAME": "โนนอุดม", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3537, "SUB_DISTRICT_CODE": "400505", "SUB_DISTRICT_NAME": "ขัวเรียง", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3538, "SUB_DISTRICT_CODE": "400506", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3539, "SUB_DISTRICT_CODE": "400507", "SUB_DISTRICT_NAME": "ไชยสอ", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3540, "SUB_DISTRICT_CODE": "400508", "SUB_DISTRICT_NAME": "วังหินลาด", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3541, "SUB_DISTRICT_CODE": "400509", "SUB_DISTRICT_NAME": "นาเพียง", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3542, "SUB_DISTRICT_CODE": "400510", "SUB_DISTRICT_NAME": "หนองเขียด", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3543, "SUB_DISTRICT_CODE": "400511", "SUB_DISTRICT_NAME": "หนองเสาเล้า", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3544, "SUB_DISTRICT_CODE": "400512", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 397, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3545, "SUB_DISTRICT_CODE": "400601", "SUB_DISTRICT_NAME": "สีชมพู", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3546, "SUB_DISTRICT_CODE": "400602", "SUB_DISTRICT_NAME": "ศรีสุข", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3547, "SUB_DISTRICT_CODE": "400603", "SUB_DISTRICT_NAME": "นาจาน", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3548, "SUB_DISTRICT_CODE": "400604", "SUB_DISTRICT_NAME": "วังเพิ่ม", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3549, "SUB_DISTRICT_CODE": "400605", "SUB_DISTRICT_NAME": "ซำยาง", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3550, "SUB_DISTRICT_CODE": "400606", "SUB_DISTRICT_NAME": "หนองแดง", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3551, "SUB_DISTRICT_CODE": "400607", "SUB_DISTRICT_NAME": "ดงลาน", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3552, "SUB_DISTRICT_CODE": "400608", "SUB_DISTRICT_NAME": "บริบูรณ์", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3553, "SUB_DISTRICT_CODE": "400609", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3554, "SUB_DISTRICT_CODE": "400610", "SUB_DISTRICT_NAME": "ภูห่าน", "DISTRICT_ID": 398, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3555, "SUB_DISTRICT_CODE": "400701", "SUB_DISTRICT_NAME": "น้ำพอง", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3556, "SUB_DISTRICT_CODE": "400702", "SUB_DISTRICT_NAME": "วังชัย", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3557, "SUB_DISTRICT_CODE": "400703", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3558, "SUB_DISTRICT_CODE": "400704", "SUB_DISTRICT_NAME": "บัวใหญ่", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3559, "SUB_DISTRICT_CODE": "400705", "SUB_DISTRICT_NAME": "สะอาด", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3560, "SUB_DISTRICT_CODE": "400706", "SUB_DISTRICT_NAME": "ม่วงหวาน", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3561, "SUB_DISTRICT_CODE": "400707", "SUB_DISTRICT_NAME": "บ้านขาม", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3562, "SUB_DISTRICT_CODE": "400708", "SUB_DISTRICT_NAME": "บัวเงิน", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3563, "SUB_DISTRICT_CODE": "400709", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3564, "SUB_DISTRICT_CODE": "400710", "SUB_DISTRICT_NAME": "ท่ากระเสริม", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3565, "SUB_DISTRICT_CODE": "400711", "SUB_DISTRICT_NAME": "พังทุย", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3566, "SUB_DISTRICT_CODE": "400712", "SUB_DISTRICT_NAME": "กุดน้ำใส", "DISTRICT_ID": 399, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3567, "SUB_DISTRICT_CODE": "400801", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3568, "SUB_DISTRICT_CODE": "400802", "SUB_DISTRICT_NAME": "บ้านดง", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3569, "SUB_DISTRICT_CODE": "400803", "SUB_DISTRICT_NAME": "เขื่อนอุบลรัตน์", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3570, "SUB_DISTRICT_CODE": "400804", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3571, "SUB_DISTRICT_CODE": "400805", "SUB_DISTRICT_NAME": "ศรีสุขสำราญ", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3572, "SUB_DISTRICT_CODE": "400806", "SUB_DISTRICT_NAME": "ทุ่งโป่ง", "DISTRICT_ID": 400, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3573, "SUB_DISTRICT_CODE": "400901", "SUB_DISTRICT_NAME": "หนองโก", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3574, "SUB_DISTRICT_CODE": "400902", "SUB_DISTRICT_NAME": "หนองกุงใหญ่", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3575, "SUB_DISTRICT_CODE": "400903", "SUB_DISTRICT_NAME": "*กระนวน", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3576, "SUB_DISTRICT_CODE": "400904", "SUB_DISTRICT_NAME": "*บ้านโนน", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3577, "SUB_DISTRICT_CODE": "400905", "SUB_DISTRICT_NAME": "ห้วยโจด", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3578, "SUB_DISTRICT_CODE": "400906", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3579, "SUB_DISTRICT_CODE": "400907", "SUB_DISTRICT_NAME": "บ้านฝาง", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3580, "SUB_DISTRICT_CODE": "400908", "SUB_DISTRICT_NAME": "*คำแมด", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3581, "SUB_DISTRICT_CODE": "400909", "SUB_DISTRICT_NAME": "ดูนสาด", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3582, "SUB_DISTRICT_CODE": "400910", "SUB_DISTRICT_NAME": "หนองโน", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3583, "SUB_DISTRICT_CODE": "400911", "SUB_DISTRICT_NAME": "น้ำอ้อม", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3584, "SUB_DISTRICT_CODE": "400912", "SUB_DISTRICT_NAME": "หัวนาคำ", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3585, "SUB_DISTRICT_CODE": "400913", "SUB_DISTRICT_NAME": "*คูคำ", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3586, "SUB_DISTRICT_CODE": "400914", "SUB_DISTRICT_NAME": "*ห้วยเตย", "DISTRICT_ID": 401, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3587, "SUB_DISTRICT_CODE": "401001", "SUB_DISTRICT_NAME": "บ้านไผ่", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3588, "SUB_DISTRICT_CODE": "401002", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3589, "SUB_DISTRICT_CODE": "401003", "SUB_DISTRICT_NAME": "*บ้านแฮด", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3590, "SUB_DISTRICT_CODE": "401004", "SUB_DISTRICT_NAME": "*โคกสำราญ", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3591, "SUB_DISTRICT_CODE": "401005", "SUB_DISTRICT_NAME": "เมืองเพีย", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3592, "SUB_DISTRICT_CODE": "401006", "SUB_DISTRICT_NAME": "เปือยใหญ่*", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3593, "SUB_DISTRICT_CODE": "401007", "SUB_DISTRICT_NAME": "โนนศิลา*", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3594, "SUB_DISTRICT_CODE": "401008", "SUB_DISTRICT_NAME": "บ้านหัน*", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3595, "SUB_DISTRICT_CODE": "401009", "SUB_DISTRICT_NAME": "บ้านลาน", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3596, "SUB_DISTRICT_CODE": "401010", "SUB_DISTRICT_NAME": "แคนเหนือ", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3597, "SUB_DISTRICT_CODE": "401011", "SUB_DISTRICT_NAME": "ภูเหล็ก", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3598, "SUB_DISTRICT_CODE": "401012", "SUB_DISTRICT_NAME": "*หนองแซง", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3599, "SUB_DISTRICT_CODE": "401013", "SUB_DISTRICT_NAME": "ป่าปอ", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3600, "SUB_DISTRICT_CODE": "401014", "SUB_DISTRICT_NAME": "หินตั้ง", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3601, "SUB_DISTRICT_CODE": "401015", "SUB_DISTRICT_NAME": "*โนนสมบูรณ์", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3602, "SUB_DISTRICT_CODE": "401016", "SUB_DISTRICT_NAME": "หนองน้ำใส", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3603, "SUB_DISTRICT_CODE": "401017", "SUB_DISTRICT_NAME": "หัวหนอง", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3604, "SUB_DISTRICT_CODE": "401018", "SUB_DISTRICT_NAME": "*บ้านแฮด", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3605, "SUB_DISTRICT_CODE": "401019", "SUB_DISTRICT_NAME": "โนนแดง*", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3606, "SUB_DISTRICT_CODE": "401020", "SUB_DISTRICT_NAME": "หนองปลาหมอ*", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3607, "SUB_DISTRICT_CODE": "401096", "SUB_DISTRICT_NAME": "*สระแก้ว", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3608, "SUB_DISTRICT_CODE": "401097", "SUB_DISTRICT_NAME": "*ขามป้อม", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3609, "SUB_DISTRICT_CODE": "401098", "SUB_DISTRICT_NAME": "*วังม่วง", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3610, "SUB_DISTRICT_CODE": "401099", "SUB_DISTRICT_NAME": "*เปือยน้อย", "DISTRICT_ID": 402, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3611, "SUB_DISTRICT_CODE": "401101", "SUB_DISTRICT_NAME": "เปือยน้อย", "DISTRICT_ID": 403, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3612, "SUB_DISTRICT_CODE": "401102", "SUB_DISTRICT_NAME": "วังม่วง", "DISTRICT_ID": 403, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3613, "SUB_DISTRICT_CODE": "401103", "SUB_DISTRICT_NAME": "ขามป้อม", "DISTRICT_ID": 403, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3614, "SUB_DISTRICT_CODE": "401104", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 403, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3615, "SUB_DISTRICT_CODE": "401201", "SUB_DISTRICT_NAME": "เมืองพล", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3616, "SUB_DISTRICT_CODE": "401203", "SUB_DISTRICT_NAME": "โจดหนองแก", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3617, "SUB_DISTRICT_CODE": "401204", "SUB_DISTRICT_NAME": "เก่างิ้ว", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3618, "SUB_DISTRICT_CODE": "401205", "SUB_DISTRICT_NAME": "หนองมะเขือ", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3619, "SUB_DISTRICT_CODE": "401206", "SUB_DISTRICT_NAME": "หนองแวงโสกพระ", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3620, "SUB_DISTRICT_CODE": "401207", "SUB_DISTRICT_NAME": "เพ็กใหญ่", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3621, "SUB_DISTRICT_CODE": "401208", "SUB_DISTRICT_NAME": "โคกสง่า", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3622, "SUB_DISTRICT_CODE": "401209", "SUB_DISTRICT_NAME": "หนองแวงนางเบ้า", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3623, "SUB_DISTRICT_CODE": "401210", "SUB_DISTRICT_NAME": "ลอมคอม", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3624, "SUB_DISTRICT_CODE": "401211", "SUB_DISTRICT_NAME": "โนนข่า", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3625, "SUB_DISTRICT_CODE": "401212", "SUB_DISTRICT_NAME": "โสกนกเต็น", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3626, "SUB_DISTRICT_CODE": "401213", "SUB_DISTRICT_NAME": "หัวทุ่ง", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3627, "SUB_DISTRICT_CODE": "401290", "SUB_DISTRICT_NAME": "*ทางขวาง", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3628, "SUB_DISTRICT_CODE": "401291", "SUB_DISTRICT_NAME": "*ท่าวัด", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3629, "SUB_DISTRICT_CODE": "401292", "SUB_DISTRICT_NAME": "*ท่านางแมว", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3630, "SUB_DISTRICT_CODE": "401293", "SUB_DISTRICT_NAME": "*แวงน้อย", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3631, "SUB_DISTRICT_CODE": "401294", "SUB_DISTRICT_NAME": "*ก้านเหลือง", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3632, "SUB_DISTRICT_CODE": "401295", "SUB_DISTRICT_NAME": "*ละหารนา", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3633, "SUB_DISTRICT_CODE": "401296", "SUB_DISTRICT_NAME": "*แวงใหญ่", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3634, "SUB_DISTRICT_CODE": "401297", "SUB_DISTRICT_NAME": "*โนนทอง", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3635, "SUB_DISTRICT_CODE": "401298", "SUB_DISTRICT_NAME": "*ใหม่นาเพียง", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3636, "SUB_DISTRICT_CODE": "401299", "SUB_DISTRICT_NAME": "*คอนฉิม", "DISTRICT_ID": 404, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3637, "SUB_DISTRICT_CODE": "401301", "SUB_DISTRICT_NAME": "คอนฉิม", "DISTRICT_ID": 405, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3638, "SUB_DISTRICT_CODE": "401302", "SUB_DISTRICT_NAME": "ใหม่นาเพียง", "DISTRICT_ID": 405, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3639, "SUB_DISTRICT_CODE": "401303", "SUB_DISTRICT_NAME": "โนนทอง", "DISTRICT_ID": 405, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3640, "SUB_DISTRICT_CODE": "401304", "SUB_DISTRICT_NAME": "แวงใหญ่", "DISTRICT_ID": 405, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3641, "SUB_DISTRICT_CODE": "401305", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 405, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3642, "SUB_DISTRICT_CODE": "401401", "SUB_DISTRICT_NAME": "แวงน้อย", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3643, "SUB_DISTRICT_CODE": "401402", "SUB_DISTRICT_NAME": "ก้านเหลือง", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3644, "SUB_DISTRICT_CODE": "401403", "SUB_DISTRICT_NAME": "ท่านางแนว", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3645, "SUB_DISTRICT_CODE": "401404", "SUB_DISTRICT_NAME": "ละหานนา", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3646, "SUB_DISTRICT_CODE": "401405", "SUB_DISTRICT_NAME": "ท่าวัด", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3647, "SUB_DISTRICT_CODE": "401406", "SUB_DISTRICT_NAME": "ทางขวาง", "DISTRICT_ID": 406, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3648, "SUB_DISTRICT_CODE": "401501", "SUB_DISTRICT_NAME": "หนองสองห้อง", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3649, "SUB_DISTRICT_CODE": "401502", "SUB_DISTRICT_NAME": "คึมชาด", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3650, "SUB_DISTRICT_CODE": "401503", "SUB_DISTRICT_NAME": "โนนธาตุ", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3651, "SUB_DISTRICT_CODE": "401504", "SUB_DISTRICT_NAME": "ตะกั่วป่า", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3652, "SUB_DISTRICT_CODE": "401505", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3653, "SUB_DISTRICT_CODE": "401506", "SUB_DISTRICT_NAME": "หนองเม็ก", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3654, "SUB_DISTRICT_CODE": "401507", "SUB_DISTRICT_NAME": "ดอนดู่", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3655, "SUB_DISTRICT_CODE": "401508", "SUB_DISTRICT_NAME": "ดงเค็ง", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3656, "SUB_DISTRICT_CODE": "401509", "SUB_DISTRICT_NAME": "หันโจด", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3657, "SUB_DISTRICT_CODE": "401510", "SUB_DISTRICT_NAME": "ดอนดั่ง", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3658, "SUB_DISTRICT_CODE": "401511", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3659, "SUB_DISTRICT_CODE": "401512", "SUB_DISTRICT_NAME": "หนองไผ่ล้อม", "DISTRICT_ID": 407, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3660, "SUB_DISTRICT_CODE": "401601", "SUB_DISTRICT_NAME": "บ้านเรือ", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3661, "SUB_DISTRICT_CODE": "401602", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3662, "SUB_DISTRICT_CODE": "401603", "SUB_DISTRICT_NAME": "*บ้านโคก", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3663, "SUB_DISTRICT_CODE": "401604", "SUB_DISTRICT_NAME": "หว้าทอง", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3664, "SUB_DISTRICT_CODE": "401605", "SUB_DISTRICT_NAME": "กุดขอนแก่น", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3665, "SUB_DISTRICT_CODE": "401606", "SUB_DISTRICT_NAME": "นาชุมแสง", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3666, "SUB_DISTRICT_CODE": "401607", "SUB_DISTRICT_NAME": "นาหว้า", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3667, "SUB_DISTRICT_CODE": "401608", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3668, "SUB_DISTRICT_CODE": "401609", "SUB_DISTRICT_NAME": "*กุดธาตุ", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3669, "SUB_DISTRICT_CODE": "401610", "SUB_DISTRICT_NAME": "หนองกุงธนสาร", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3670, "SUB_DISTRICT_CODE": "401611", "SUB_DISTRICT_NAME": "*ขนวน", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3671, "SUB_DISTRICT_CODE": "401612", "SUB_DISTRICT_NAME": "หนองกุงเซิน", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3672, "SUB_DISTRICT_CODE": "401613", "SUB_DISTRICT_NAME": "สงเปือย", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3673, "SUB_DISTRICT_CODE": "401614", "SUB_DISTRICT_NAME": "ทุ่งชมพู", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3674, "SUB_DISTRICT_CODE": "401615", "SUB_DISTRICT_NAME": "เมืองเก่าพัฒนา", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3675, "SUB_DISTRICT_CODE": "401616", "SUB_DISTRICT_NAME": "ดินดำ", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3676, "SUB_DISTRICT_CODE": "401617", "SUB_DISTRICT_NAME": "ภูเวียง", "DISTRICT_ID": 408, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3677, "SUB_DISTRICT_CODE": "401701", "SUB_DISTRICT_NAME": "กุดเค้า", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3678, "SUB_DISTRICT_CODE": "401702", "SUB_DISTRICT_NAME": "สวนหม่อน", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3679, "SUB_DISTRICT_CODE": "401703", "SUB_DISTRICT_NAME": "หนองแปน", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3680, "SUB_DISTRICT_CODE": "401704", "SUB_DISTRICT_NAME": "โพนเพ็ก", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3681, "SUB_DISTRICT_CODE": "401705", "SUB_DISTRICT_NAME": "คำแคน", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3682, "SUB_DISTRICT_CODE": "401706", "SUB_DISTRICT_NAME": "นาข่า", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3683, "SUB_DISTRICT_CODE": "401707", "SUB_DISTRICT_NAME": "นางาม", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3684, "SUB_DISTRICT_CODE": "401708", "SUB_DISTRICT_NAME": "*บ้านโคก", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3685, "SUB_DISTRICT_CODE": "401709", "SUB_DISTRICT_NAME": "*โพธิ์ไชย", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3686, "SUB_DISTRICT_CODE": "401710", "SUB_DISTRICT_NAME": "ท่าศาลา", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3687, "SUB_DISTRICT_CODE": "401711", "SUB_DISTRICT_NAME": "*ซับสมบูรณ์", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3688, "SUB_DISTRICT_CODE": "401712", "SUB_DISTRICT_NAME": "*นาแพง", "DISTRICT_ID": 409, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3689, "SUB_DISTRICT_CODE": "401801", "SUB_DISTRICT_NAME": "ชนบท", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3690, "SUB_DISTRICT_CODE": "401802", "SUB_DISTRICT_NAME": "กุดเพียขอม", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3691, "SUB_DISTRICT_CODE": "401803", "SUB_DISTRICT_NAME": "วังแสง", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3692, "SUB_DISTRICT_CODE": "401804", "SUB_DISTRICT_NAME": "ห้วยแก", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3693, "SUB_DISTRICT_CODE": "401805", "SUB_DISTRICT_NAME": "บ้านแท่น", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3694, "SUB_DISTRICT_CODE": "401806", "SUB_DISTRICT_NAME": "ศรีบุญเรือง", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3695, "SUB_DISTRICT_CODE": "401807", "SUB_DISTRICT_NAME": "โนนพะยอม", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3696, "SUB_DISTRICT_CODE": "401808", "SUB_DISTRICT_NAME": "ปอแดง", "DISTRICT_ID": 410, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3697, "SUB_DISTRICT_CODE": "401901", "SUB_DISTRICT_NAME": "เขาสวนกวาง", "DISTRICT_ID": 411, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3698, "SUB_DISTRICT_CODE": "401902", "SUB_DISTRICT_NAME": "ดงเมืองแอม", "DISTRICT_ID": 411, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3699, "SUB_DISTRICT_CODE": "401903", "SUB_DISTRICT_NAME": "นางิ้ว", "DISTRICT_ID": 411, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3700, "SUB_DISTRICT_CODE": "401904", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 411, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3701, "SUB_DISTRICT_CODE": "401905", "SUB_DISTRICT_NAME": "คำม่วง", "DISTRICT_ID": 411, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3702, "SUB_DISTRICT_CODE": "402001", "SUB_DISTRICT_NAME": "โนนคอม", "DISTRICT_ID": 412, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3703, "SUB_DISTRICT_CODE": "402002", "SUB_DISTRICT_NAME": "นาฝาย", "DISTRICT_ID": 412, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3704, "SUB_DISTRICT_CODE": "402003", "SUB_DISTRICT_NAME": "ภูผาม่าน", "DISTRICT_ID": 412, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3705, "SUB_DISTRICT_CODE": "402004", "SUB_DISTRICT_NAME": "วังสวาบ", "DISTRICT_ID": 412, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3706, "SUB_DISTRICT_CODE": "402005", "SUB_DISTRICT_NAME": "ห้วยม่วง", "DISTRICT_ID": 412, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3707, "SUB_DISTRICT_CODE": "402101", "SUB_DISTRICT_NAME": "กระนวน", "DISTRICT_ID": 413, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3708, "SUB_DISTRICT_CODE": "402102", "SUB_DISTRICT_NAME": "คำแมด", "DISTRICT_ID": 413, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3709, "SUB_DISTRICT_CODE": "402103", "SUB_DISTRICT_NAME": "บ้านโนน", "DISTRICT_ID": 413, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3710, "SUB_DISTRICT_CODE": "402104", "SUB_DISTRICT_NAME": "คูคำ", "DISTRICT_ID": 413, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3711, "SUB_DISTRICT_CODE": "402105", "SUB_DISTRICT_NAME": "ห้วยเตย", "DISTRICT_ID": 413, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3712, "SUB_DISTRICT_CODE": "402201", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 414, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3713, "SUB_DISTRICT_CODE": "402202", "SUB_DISTRICT_NAME": "โพธิ์ไชย", "DISTRICT_ID": 414, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3714, "SUB_DISTRICT_CODE": "402203", "SUB_DISTRICT_NAME": "ซับสมบูรณ์", "DISTRICT_ID": 414, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3715, "SUB_DISTRICT_CODE": "402204", "SUB_DISTRICT_NAME": "นาแพง", "DISTRICT_ID": 414, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3716, "SUB_DISTRICT_CODE": "402301", "SUB_DISTRICT_NAME": "กุดธาตุ", "DISTRICT_ID": 415, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3717, "SUB_DISTRICT_CODE": "402302", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 415, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3718, "SUB_DISTRICT_CODE": "402303", "SUB_DISTRICT_NAME": "ขนวน", "DISTRICT_ID": 415, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3719, "SUB_DISTRICT_CODE": "402401", "SUB_DISTRICT_NAME": "บ้านแฮด", "DISTRICT_ID": 416, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3720, "SUB_DISTRICT_CODE": "402402", "SUB_DISTRICT_NAME": "โคกสำราญ", "DISTRICT_ID": 416, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3721, "SUB_DISTRICT_CODE": "402403", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 416, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3722, "SUB_DISTRICT_CODE": "402404", "SUB_DISTRICT_NAME": "หนองแซง", "DISTRICT_ID": 416, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3723, "SUB_DISTRICT_CODE": "402501", "SUB_DISTRICT_NAME": "โนนศิลา", "DISTRICT_ID": 417, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3724, "SUB_DISTRICT_CODE": "402502", "SUB_DISTRICT_NAME": "หนองปลาหมอ", "DISTRICT_ID": 417, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3725, "SUB_DISTRICT_CODE": "402503", "SUB_DISTRICT_NAME": "บ้านหัน", "DISTRICT_ID": 417, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3726, "SUB_DISTRICT_CODE": "402504", "SUB_DISTRICT_NAME": "เปือยใหญ่", "DISTRICT_ID": 417, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3727, "SUB_DISTRICT_CODE": "402505", "SUB_DISTRICT_NAME": "โนนแดง", "DISTRICT_ID": 417, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3728, "SUB_DISTRICT_CODE": "402901", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 418, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3729, "SUB_DISTRICT_CODE": "402902", "SUB_DISTRICT_NAME": "เมืองเก่าพัฒนา", "DISTRICT_ID": 418, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3730, "SUB_DISTRICT_CODE": "402903", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 418, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3731, "SUB_DISTRICT_CODE": "406801", "SUB_DISTRICT_NAME": "บ้านเป็ด*", "DISTRICT_ID": 419, "PROVINCE_ID": 28, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3732, "SUB_DISTRICT_CODE": "410101", "SUB_DISTRICT_NAME": "หมากแข้ง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3733, "SUB_DISTRICT_CODE": "410102", "SUB_DISTRICT_NAME": "นิคมสงเคราะห์", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3734, "SUB_DISTRICT_CODE": "410103", "SUB_DISTRICT_NAME": "บ้านขาว", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3735, "SUB_DISTRICT_CODE": "410104", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3736, "SUB_DISTRICT_CODE": "410105", "SUB_DISTRICT_NAME": "บ้านตาด", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3737, "SUB_DISTRICT_CODE": "410106", "SUB_DISTRICT_NAME": "โนนสูง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3738, "SUB_DISTRICT_CODE": "410107", "SUB_DISTRICT_NAME": "หมูม่น", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3739, "SUB_DISTRICT_CODE": "410108", "SUB_DISTRICT_NAME": "เชียงยืน", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3740, "SUB_DISTRICT_CODE": "410109", "SUB_DISTRICT_NAME": "หนองนาคำ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3741, "SUB_DISTRICT_CODE": "410110", "SUB_DISTRICT_NAME": "กุดสระ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3742, "SUB_DISTRICT_CODE": "410111", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3743, "SUB_DISTRICT_CODE": "410112", "SUB_DISTRICT_NAME": "บ้านเลื่อม", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3744, "SUB_DISTRICT_CODE": "410113", "SUB_DISTRICT_NAME": "เชียงพิณ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3745, "SUB_DISTRICT_CODE": "410114", "SUB_DISTRICT_NAME": "สามพร้าว", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3746, "SUB_DISTRICT_CODE": "410115", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3747, "SUB_DISTRICT_CODE": "410116", "SUB_DISTRICT_NAME": "นาข่า", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3748, "SUB_DISTRICT_CODE": "410117", "SUB_DISTRICT_NAME": "บ้านจั่น", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3749, "SUB_DISTRICT_CODE": "410118", "SUB_DISTRICT_NAME": "หนองขอนกว้าง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3750, "SUB_DISTRICT_CODE": "410119", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3751, "SUB_DISTRICT_CODE": "410120", "SUB_DISTRICT_NAME": "นากว้าง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3752, "SUB_DISTRICT_CODE": "410121", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3753, "SUB_DISTRICT_CODE": "410190", "SUB_DISTRICT_NAME": "*ขอนยูง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3754, "SUB_DISTRICT_CODE": "410191", "SUB_DISTRICT_NAME": "*ปะโค", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3755, "SUB_DISTRICT_CODE": "410194", "SUB_DISTRICT_NAME": "*หนองหว้า", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3756, "SUB_DISTRICT_CODE": "410195", "SUB_DISTRICT_NAME": "*ขอนยูง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3757, "SUB_DISTRICT_CODE": "410196", "SUB_DISTRICT_NAME": "*ปะโค", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3758, "SUB_DISTRICT_CODE": "410197", "SUB_DISTRICT_NAME": "*เชียงเพ็ง", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3759, "SUB_DISTRICT_CODE": "410198", "SUB_DISTRICT_NAME": "*กุดจับ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3760, "SUB_DISTRICT_CODE": "410199", "SUB_DISTRICT_NAME": "*หนองปุ", "DISTRICT_ID": 421, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3761, "SUB_DISTRICT_CODE": "410201", "SUB_DISTRICT_NAME": "กุดจับ", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3762, "SUB_DISTRICT_CODE": "410202", "SUB_DISTRICT_NAME": "ปะโค", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3763, "SUB_DISTRICT_CODE": "410203", "SUB_DISTRICT_NAME": "ขอนยูง", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3764, "SUB_DISTRICT_CODE": "410204", "SUB_DISTRICT_NAME": "เชียงเพ็ง", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3765, "SUB_DISTRICT_CODE": "410205", "SUB_DISTRICT_NAME": "สร้างก่อ", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3766, "SUB_DISTRICT_CODE": "410206", "SUB_DISTRICT_NAME": "เมืองเพีย", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3767, "SUB_DISTRICT_CODE": "410207", "SUB_DISTRICT_NAME": "ตาลเลียน", "DISTRICT_ID": 422, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3768, "SUB_DISTRICT_CODE": "410301", "SUB_DISTRICT_NAME": "หมากหญ้า", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3769, "SUB_DISTRICT_CODE": "410302", "SUB_DISTRICT_NAME": "หนองอ้อ", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3770, "SUB_DISTRICT_CODE": "410303", "SUB_DISTRICT_NAME": "อูบมุง", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3771, "SUB_DISTRICT_CODE": "410304", "SUB_DISTRICT_NAME": "กุดหมากไฟ", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3772, "SUB_DISTRICT_CODE": "410305", "SUB_DISTRICT_NAME": "น้ำพ่น", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3773, "SUB_DISTRICT_CODE": "410306", "SUB_DISTRICT_NAME": "หนองบัวบาน", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3774, "SUB_DISTRICT_CODE": "410307", "SUB_DISTRICT_NAME": "โนนหวาย", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3775, "SUB_DISTRICT_CODE": "410308", "SUB_DISTRICT_NAME": "หนองวัวซอ", "DISTRICT_ID": 423, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3776, "SUB_DISTRICT_CODE": "410401", "SUB_DISTRICT_NAME": "ตูมใต้", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3777, "SUB_DISTRICT_CODE": "410402", "SUB_DISTRICT_NAME": "พันดอน", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3778, "SUB_DISTRICT_CODE": "410403", "SUB_DISTRICT_NAME": "เวียงคำ", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3779, "SUB_DISTRICT_CODE": "410404", "SUB_DISTRICT_NAME": "แชแล", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3780, "SUB_DISTRICT_CODE": "410405", "SUB_DISTRICT_NAME": "อุ่มจาน*", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3781, "SUB_DISTRICT_CODE": "410406", "SUB_DISTRICT_NAME": "เชียงแหว", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3782, "SUB_DISTRICT_CODE": "410407", "SUB_DISTRICT_NAME": "ห้วยเกิ้ง", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3783, "SUB_DISTRICT_CODE": "410408", "SUB_DISTRICT_NAME": "ห้วยสามพาด*", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3784, "SUB_DISTRICT_CODE": "410409", "SUB_DISTRICT_NAME": "เสอเพลอ", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3785, "SUB_DISTRICT_CODE": "410410", "SUB_DISTRICT_NAME": "สีออ", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3786, "SUB_DISTRICT_CODE": "410411", "SUB_DISTRICT_NAME": "ปะโค", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3787, "SUB_DISTRICT_CODE": "410412", "SUB_DISTRICT_NAME": "นาม่วง*", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3788, "SUB_DISTRICT_CODE": "410413", "SUB_DISTRICT_NAME": "ผาสุก", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3789, "SUB_DISTRICT_CODE": "410414", "SUB_DISTRICT_NAME": "ท่าลี่", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3790, "SUB_DISTRICT_CODE": "410415", "SUB_DISTRICT_NAME": "กุมภวาปี", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3791, "SUB_DISTRICT_CODE": "410416", "SUB_DISTRICT_NAME": "หนองหว้า", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3792, "SUB_DISTRICT_CODE": "410495", "SUB_DISTRICT_NAME": "*โนนสะอาด", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3793, "SUB_DISTRICT_CODE": "410496", "SUB_DISTRICT_NAME": "*โพธิ์ศรีสำราญ", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3794, "SUB_DISTRICT_CODE": "410497", "SUB_DISTRICT_NAME": "*บุ่งแก้ว", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3795, "SUB_DISTRICT_CODE": "410498", "SUB_DISTRICT_NAME": "*หนองแสง", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3796, "SUB_DISTRICT_CODE": "410499", "SUB_DISTRICT_NAME": "*แสงสว่าง", "DISTRICT_ID": 424, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3797, "SUB_DISTRICT_CODE": "410501", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3798, "SUB_DISTRICT_CODE": "410502", "SUB_DISTRICT_NAME": "บุ่งแก้ว", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3799, "SUB_DISTRICT_CODE": "410503", "SUB_DISTRICT_NAME": "โพธิ์ศรีสำราญ", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3800, "SUB_DISTRICT_CODE": "410504", "SUB_DISTRICT_NAME": "ทมนางาม", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3801, "SUB_DISTRICT_CODE": "410505", "SUB_DISTRICT_NAME": "หนองกุงศรี", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3802, "SUB_DISTRICT_CODE": "410506", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 425, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3803, "SUB_DISTRICT_CODE": "410601", "SUB_DISTRICT_NAME": "หนองหาน", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3804, "SUB_DISTRICT_CODE": "410602", "SUB_DISTRICT_NAME": "หนองเม็ก", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3805, "SUB_DISTRICT_CODE": "410603", "SUB_DISTRICT_NAME": "*คอนสาย", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3806, "SUB_DISTRICT_CODE": "410604", "SUB_DISTRICT_NAME": "*บ้านจีต", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3807, "SUB_DISTRICT_CODE": "410605", "SUB_DISTRICT_NAME": "พังงู", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3808, "SUB_DISTRICT_CODE": "410606", "SUB_DISTRICT_NAME": "สะแบง", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3809, "SUB_DISTRICT_CODE": "410607", "SUB_DISTRICT_NAME": "สร้อยพร้าว", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3810, "SUB_DISTRICT_CODE": "410608", "SUB_DISTRICT_NAME": "*บ้านแดง", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3811, "SUB_DISTRICT_CODE": "410609", "SUB_DISTRICT_NAME": "บ้านเชียง", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3812, "SUB_DISTRICT_CODE": "410610", "SUB_DISTRICT_NAME": "บ้านยา", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3813, "SUB_DISTRICT_CODE": "410611", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3814, "SUB_DISTRICT_CODE": "410612", "SUB_DISTRICT_NAME": "ผักตบ", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3815, "SUB_DISTRICT_CODE": "410613", "SUB_DISTRICT_NAME": "*ดอนกลอย", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3816, "SUB_DISTRICT_CODE": "410614", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3817, "SUB_DISTRICT_CODE": "410615", "SUB_DISTRICT_NAME": "*นาทราย", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3818, "SUB_DISTRICT_CODE": "410616", "SUB_DISTRICT_NAME": "*ค้อใหญ่", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3819, "SUB_DISTRICT_CODE": "410617", "SUB_DISTRICT_NAME": "ดอนหายโศก", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3820, "SUB_DISTRICT_CODE": "410618", "SUB_DISTRICT_NAME": "หนองสระปลา", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3821, "SUB_DISTRICT_CODE": "410619", "SUB_DISTRICT_NAME": "*โนนทองอินทร์", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3822, "SUB_DISTRICT_CODE": "410694", "SUB_DISTRICT_NAME": "*หนองหลัก", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3823, "SUB_DISTRICT_CODE": "410695", "SUB_DISTRICT_NAME": "*บ้านแดง", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3824, "SUB_DISTRICT_CODE": "410696", "SUB_DISTRICT_NAME": "*ทุ่งใหญ่", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3825, "SUB_DISTRICT_CODE": "410697", "SUB_DISTRICT_NAME": "*ทุ่งฝน", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3826, "SUB_DISTRICT_CODE": "410698", "SUB_DISTRICT_NAME": "*โพนสูง", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3827, "SUB_DISTRICT_CODE": "410699", "SUB_DISTRICT_NAME": "*ไชยวาน", "DISTRICT_ID": 426, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3828, "SUB_DISTRICT_CODE": "410701", "SUB_DISTRICT_NAME": "ทุ่งฝน", "DISTRICT_ID": 427, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3829, "SUB_DISTRICT_CODE": "410702", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 427, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3830, "SUB_DISTRICT_CODE": "410703", "SUB_DISTRICT_NAME": "นาชุมแสง", "DISTRICT_ID": 427, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3831, "SUB_DISTRICT_CODE": "410704", "SUB_DISTRICT_NAME": "นาทม", "DISTRICT_ID": 427, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3832, "SUB_DISTRICT_CODE": "410801", "SUB_DISTRICT_NAME": "ไชยวาน", "DISTRICT_ID": 428, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3833, "SUB_DISTRICT_CODE": "410802", "SUB_DISTRICT_NAME": "หนองหลัก", "DISTRICT_ID": 428, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3834, "SUB_DISTRICT_CODE": "410803", "SUB_DISTRICT_NAME": "คำเลาะ", "DISTRICT_ID": 428, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3835, "SUB_DISTRICT_CODE": "410804", "SUB_DISTRICT_NAME": "โพนสูง", "DISTRICT_ID": 428, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3836, "SUB_DISTRICT_CODE": "410901", "SUB_DISTRICT_NAME": "ศรีธาตุ", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3837, "SUB_DISTRICT_CODE": "410902", "SUB_DISTRICT_NAME": "จำปี", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3838, "SUB_DISTRICT_CODE": "410903", "SUB_DISTRICT_NAME": "บ้านโปร่ง", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3839, "SUB_DISTRICT_CODE": "410904", "SUB_DISTRICT_NAME": "หัวนาคำ", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3840, "SUB_DISTRICT_CODE": "410905", "SUB_DISTRICT_NAME": "หนองนกเขียน", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3841, "SUB_DISTRICT_CODE": "410906", "SUB_DISTRICT_NAME": "นายูง", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3842, "SUB_DISTRICT_CODE": "410907", "SUB_DISTRICT_NAME": "ตาดทอง", "DISTRICT_ID": 429, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3843, "SUB_DISTRICT_CODE": "411001", "SUB_DISTRICT_NAME": "หนองกุงทับม้า", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3844, "SUB_DISTRICT_CODE": "411002", "SUB_DISTRICT_NAME": "หนองหญ้าไซ", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3845, "SUB_DISTRICT_CODE": "411003", "SUB_DISTRICT_NAME": "บะยาว", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3846, "SUB_DISTRICT_CODE": "411004", "SUB_DISTRICT_NAME": "ผาสุก", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3847, "SUB_DISTRICT_CODE": "411005", "SUB_DISTRICT_NAME": "คำโคกสูง", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3848, "SUB_DISTRICT_CODE": "411006", "SUB_DISTRICT_NAME": "วังสามหมอ", "DISTRICT_ID": 430, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3849, "SUB_DISTRICT_CODE": "411101", "SUB_DISTRICT_NAME": "ศรีสุทโธ", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3850, "SUB_DISTRICT_CODE": "411102", "SUB_DISTRICT_NAME": "บ้านดุง", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3851, "SUB_DISTRICT_CODE": "411103", "SUB_DISTRICT_NAME": "ดงเย็น", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3852, "SUB_DISTRICT_CODE": "411104", "SUB_DISTRICT_NAME": "โพนสูง", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3853, "SUB_DISTRICT_CODE": "411105", "SUB_DISTRICT_NAME": "อ้อมกอ", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3854, "SUB_DISTRICT_CODE": "411106", "SUB_DISTRICT_NAME": "บ้านจันทน์", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3855, "SUB_DISTRICT_CODE": "411107", "SUB_DISTRICT_NAME": "บ้านชัย", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3856, "SUB_DISTRICT_CODE": "411108", "SUB_DISTRICT_NAME": "นาไหม", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3857, "SUB_DISTRICT_CODE": "411109", "SUB_DISTRICT_NAME": "ถ่อนนาลับ", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3858, "SUB_DISTRICT_CODE": "411110", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3859, "SUB_DISTRICT_CODE": "411111", "SUB_DISTRICT_NAME": "บ้านม่วง", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3860, "SUB_DISTRICT_CODE": "411112", "SUB_DISTRICT_NAME": "บ้านตาด", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3861, "SUB_DISTRICT_CODE": "411113", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 431, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3862, "SUB_DISTRICT_CODE": "411201", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3863, "SUB_DISTRICT_CODE": "411202", "SUB_DISTRICT_NAME": "*หนองภัยศูนย์", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3864, "SUB_DISTRICT_CODE": "411203", "SUB_DISTRICT_NAME": "*โพธิ์ชัย", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3865, "SUB_DISTRICT_CODE": "411204", "SUB_DISTRICT_NAME": "*หนองสวรรค์", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3866, "SUB_DISTRICT_CODE": "411205", "SUB_DISTRICT_NAME": "*หัวนา", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3867, "SUB_DISTRICT_CODE": "411206", "SUB_DISTRICT_NAME": "*บ้านขาม", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3868, "SUB_DISTRICT_CODE": "411207", "SUB_DISTRICT_NAME": "*นามะเฟือง", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3869, "SUB_DISTRICT_CODE": "411208", "SUB_DISTRICT_NAME": "*บ้านพร้าว", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3870, "SUB_DISTRICT_CODE": "411209", "SUB_DISTRICT_NAME": "*โนนขมิ้น", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3871, "SUB_DISTRICT_CODE": "411210", "SUB_DISTRICT_NAME": "*ลำภู", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3872, "SUB_DISTRICT_CODE": "411211", "SUB_DISTRICT_NAME": "*กุดจิก", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3873, "SUB_DISTRICT_CODE": "411212", "SUB_DISTRICT_NAME": "*โนนทัน", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3874, "SUB_DISTRICT_CODE": "411213", "SUB_DISTRICT_NAME": "*นาคำไฮ", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3875, "SUB_DISTRICT_CODE": "411214", "SUB_DISTRICT_NAME": "*ป่าไม้งาม", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3876, "SUB_DISTRICT_CODE": "411215", "SUB_DISTRICT_NAME": "*หนองหว้า", "DISTRICT_ID": 432, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3877, "SUB_DISTRICT_CODE": "411301", "SUB_DISTRICT_NAME": "*เมืองใหม่", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3878, "SUB_DISTRICT_CODE": "411302", "SUB_DISTRICT_NAME": "*ศรีบุญเรือง", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3879, "SUB_DISTRICT_CODE": "411303", "SUB_DISTRICT_NAME": "*หนองบัวใต้", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3880, "SUB_DISTRICT_CODE": "411304", "SUB_DISTRICT_NAME": "*กุดสะเทียน", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3881, "SUB_DISTRICT_CODE": "411305", "SUB_DISTRICT_NAME": "*นากอก", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3882, "SUB_DISTRICT_CODE": "411306", "SUB_DISTRICT_NAME": "*โนนสะอาด", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3883, "SUB_DISTRICT_CODE": "411307", "SUB_DISTRICT_NAME": "*ยางหล่อ", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3884, "SUB_DISTRICT_CODE": "411308", "SUB_DISTRICT_NAME": "*โนนม่วง", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3885, "SUB_DISTRICT_CODE": "411309", "SUB_DISTRICT_NAME": "*หนองกุงแก้ว", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3886, "SUB_DISTRICT_CODE": "411310", "SUB_DISTRICT_NAME": "*หนองแก", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3887, "SUB_DISTRICT_CODE": "411311", "SUB_DISTRICT_NAME": "*ทรายทอง", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3888, "SUB_DISTRICT_CODE": "411312", "SUB_DISTRICT_NAME": "*หันนางาม", "DISTRICT_ID": 433, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3889, "SUB_DISTRICT_CODE": "411401", "SUB_DISTRICT_NAME": "*นากลาง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3890, "SUB_DISTRICT_CODE": "411402", "SUB_DISTRICT_NAME": "*ด่านช้าง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3891, "SUB_DISTRICT_CODE": "411403", "SUB_DISTRICT_NAME": "*นาเหล่า", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3892, "SUB_DISTRICT_CODE": "411404", "SUB_DISTRICT_NAME": "*นาแก", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3893, "SUB_DISTRICT_CODE": "411405", "SUB_DISTRICT_NAME": "*กุดดินจี่", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3894, "SUB_DISTRICT_CODE": "411406", "SUB_DISTRICT_NAME": "*ฝั่งแดง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3895, "SUB_DISTRICT_CODE": "411407", "SUB_DISTRICT_NAME": "*เก่ากลอย", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3896, "SUB_DISTRICT_CODE": "411408", "SUB_DISTRICT_NAME": "*วังทอง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3897, "SUB_DISTRICT_CODE": "411409", "SUB_DISTRICT_NAME": "*โนนเมือง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3898, "SUB_DISTRICT_CODE": "411410", "SUB_DISTRICT_NAME": "*อุทัยสวรรค์", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3899, "SUB_DISTRICT_CODE": "411411", "SUB_DISTRICT_NAME": "*ดงสวรรค์", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3900, "SUB_DISTRICT_CODE": "411412", "SUB_DISTRICT_NAME": "*วังปลาป้อม", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3901, "SUB_DISTRICT_CODE": "411413", "SUB_DISTRICT_NAME": "*กุดแห่", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3902, "SUB_DISTRICT_CODE": "411414", "SUB_DISTRICT_NAME": "*เทพคีรี", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3903, "SUB_DISTRICT_CODE": "411415", "SUB_DISTRICT_NAME": "*โนนภูทอง", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3904, "SUB_DISTRICT_CODE": "411493", "SUB_DISTRICT_NAME": "*บุญทัน", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3905, "SUB_DISTRICT_CODE": "411494", "SUB_DISTRICT_NAME": "*สุวรรณคูหา", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3906, "SUB_DISTRICT_CODE": "411495", "SUB_DISTRICT_NAME": "*ดงมะไฟ", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3907, "SUB_DISTRICT_CODE": "411496", "SUB_DISTRICT_NAME": "*นาด่าน", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3908, "SUB_DISTRICT_CODE": "411497", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3909, "SUB_DISTRICT_CODE": "411498", "SUB_DISTRICT_NAME": "*บ้านโคก", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3910, "SUB_DISTRICT_CODE": "411499", "SUB_DISTRICT_NAME": "*นาสี", "DISTRICT_ID": 434, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3911, "SUB_DISTRICT_CODE": "411501", "SUB_DISTRICT_NAME": "*นาสี", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3912, "SUB_DISTRICT_CODE": "411502", "SUB_DISTRICT_NAME": "*บ้านโคก", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3913, "SUB_DISTRICT_CODE": "411503", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3914, "SUB_DISTRICT_CODE": "411504", "SUB_DISTRICT_NAME": "*นาด่าน", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3915, "SUB_DISTRICT_CODE": "411505", "SUB_DISTRICT_NAME": "*ดงมะไฟ", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3916, "SUB_DISTRICT_CODE": "411506", "SUB_DISTRICT_NAME": "*สุวรรณคูหา", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3917, "SUB_DISTRICT_CODE": "411507", "SUB_DISTRICT_NAME": "*บุญทัน", "DISTRICT_ID": 435, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3918, "SUB_DISTRICT_CODE": "411601", "SUB_DISTRICT_NAME": "*โนนสัง", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3919, "SUB_DISTRICT_CODE": "411602", "SUB_DISTRICT_NAME": "*บ้านถิ่น", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3920, "SUB_DISTRICT_CODE": "411603", "SUB_DISTRICT_NAME": "*หนองเรือ", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3921, "SUB_DISTRICT_CODE": "411604", "SUB_DISTRICT_NAME": "*กุดดู่", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3922, "SUB_DISTRICT_CODE": "411605", "SUB_DISTRICT_NAME": "*บ้านค้อ", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3923, "SUB_DISTRICT_CODE": "411606", "SUB_DISTRICT_NAME": "*โนนเมือง", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3924, "SUB_DISTRICT_CODE": "411607", "SUB_DISTRICT_NAME": "*โคกใหญ่", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3925, "SUB_DISTRICT_CODE": "411608", "SUB_DISTRICT_NAME": "*โคกม่วง", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3926, "SUB_DISTRICT_CODE": "411609", "SUB_DISTRICT_NAME": "*นิคมพัฒนา", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3927, "SUB_DISTRICT_CODE": "411610", "SUB_DISTRICT_NAME": "*ปางกู่", "DISTRICT_ID": 436, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3928, "SUB_DISTRICT_CODE": "411701", "SUB_DISTRICT_NAME": "บ้านผือ", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3929, "SUB_DISTRICT_CODE": "411702", "SUB_DISTRICT_NAME": "หายโศก", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3930, "SUB_DISTRICT_CODE": "411703", "SUB_DISTRICT_NAME": "เขือน้ำ", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3931, "SUB_DISTRICT_CODE": "411704", "SUB_DISTRICT_NAME": "คำบง", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3932, "SUB_DISTRICT_CODE": "411705", "SUB_DISTRICT_NAME": "โนนทอง", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3933, "SUB_DISTRICT_CODE": "411706", "SUB_DISTRICT_NAME": "ข้าวสาร", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3934, "SUB_DISTRICT_CODE": "411707", "SUB_DISTRICT_NAME": "จำปาโมง", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3935, "SUB_DISTRICT_CODE": "411708", "SUB_DISTRICT_NAME": "กลางใหญ่", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3936, "SUB_DISTRICT_CODE": "411709", "SUB_DISTRICT_NAME": "เมืองพาน", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3937, "SUB_DISTRICT_CODE": "411710", "SUB_DISTRICT_NAME": "คำด้วง", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3938, "SUB_DISTRICT_CODE": "411711", "SUB_DISTRICT_NAME": "หนองหัวคู", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3939, "SUB_DISTRICT_CODE": "411712", "SUB_DISTRICT_NAME": "บ้านค้อ", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3940, "SUB_DISTRICT_CODE": "411713", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3941, "SUB_DISTRICT_CODE": "411799", "SUB_DISTRICT_NAME": "*บ้านเม็ก", "DISTRICT_ID": 437, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3942, "SUB_DISTRICT_CODE": "411801", "SUB_DISTRICT_NAME": "นางัว", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3943, "SUB_DISTRICT_CODE": "411802", "SUB_DISTRICT_NAME": "น้ำโสม", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3944, "SUB_DISTRICT_CODE": "411803", "SUB_DISTRICT_NAME": "*นายูง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3945, "SUB_DISTRICT_CODE": "411804", "SUB_DISTRICT_NAME": "*นาแค", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3946, "SUB_DISTRICT_CODE": "411805", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3947, "SUB_DISTRICT_CODE": "411806", "SUB_DISTRICT_NAME": "บ้านหยวก", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3948, "SUB_DISTRICT_CODE": "411807", "SUB_DISTRICT_NAME": "โสมเยี่ยม", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3949, "SUB_DISTRICT_CODE": "411808", "SUB_DISTRICT_NAME": "*โนนทอง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3950, "SUB_DISTRICT_CODE": "411809", "SUB_DISTRICT_NAME": "*บ้านก้อง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3951, "SUB_DISTRICT_CODE": "411810", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3952, "SUB_DISTRICT_CODE": "411811", "SUB_DISTRICT_NAME": "*ทุบกุง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3953, "SUB_DISTRICT_CODE": "411812", "SUB_DISTRICT_NAME": "สามัคคี", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3954, "SUB_DISTRICT_CODE": "411898", "SUB_DISTRICT_NAME": "*นาแค", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3955, "SUB_DISTRICT_CODE": "411899", "SUB_DISTRICT_NAME": "*นายูง", "DISTRICT_ID": 438, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3956, "SUB_DISTRICT_CODE": "411901", "SUB_DISTRICT_NAME": "เพ็ญ", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3957, "SUB_DISTRICT_CODE": "411902", "SUB_DISTRICT_NAME": "บ้านธาตุ", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3958, "SUB_DISTRICT_CODE": "411903", "SUB_DISTRICT_NAME": "นาพู่", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3959, "SUB_DISTRICT_CODE": "411904", "SUB_DISTRICT_NAME": "เชียงหวาง", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3960, "SUB_DISTRICT_CODE": "411905", "SUB_DISTRICT_NAME": "สุมเส้า", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3961, "SUB_DISTRICT_CODE": "411906", "SUB_DISTRICT_NAME": "นาบัว", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3962, "SUB_DISTRICT_CODE": "411907", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3963, "SUB_DISTRICT_CODE": "411908", "SUB_DISTRICT_NAME": "จอมศรี", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3964, "SUB_DISTRICT_CODE": "411909", "SUB_DISTRICT_NAME": "เตาไห", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3965, "SUB_DISTRICT_CODE": "411910", "SUB_DISTRICT_NAME": "โคกกลาง", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3966, "SUB_DISTRICT_CODE": "411911", "SUB_DISTRICT_NAME": "สร้างแป้น", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3967, "SUB_DISTRICT_CODE": "411997", "SUB_DISTRICT_NAME": "*เชียงดา", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3968, "SUB_DISTRICT_CODE": "411998", "SUB_DISTRICT_NAME": "*บ้านยวด", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3969, "SUB_DISTRICT_CODE": "411999", "SUB_DISTRICT_NAME": "*สร้างคอม", "DISTRICT_ID": 439, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3970, "SUB_DISTRICT_CODE": "412001", "SUB_DISTRICT_NAME": "สร้างคอม", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3971, "SUB_DISTRICT_CODE": "412002", "SUB_DISTRICT_NAME": "เชียงดา", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3972, "SUB_DISTRICT_CODE": "412003", "SUB_DISTRICT_NAME": "บ้านยวด", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3973, "SUB_DISTRICT_CODE": "412004", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3974, "SUB_DISTRICT_CODE": "412005", "SUB_DISTRICT_NAME": "นาสะอาด", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3975, "SUB_DISTRICT_CODE": "412006", "SUB_DISTRICT_NAME": "บ้านหินโงม", "DISTRICT_ID": 440, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3976, "SUB_DISTRICT_CODE": "412101", "SUB_DISTRICT_NAME": "หนองแสง", "DISTRICT_ID": 441, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3977, "SUB_DISTRICT_CODE": "412102", "SUB_DISTRICT_NAME": "แสงสว่าง", "DISTRICT_ID": 441, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3978, "SUB_DISTRICT_CODE": "412103", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 441, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3979, "SUB_DISTRICT_CODE": "412104", "SUB_DISTRICT_NAME": "ทับกุง", "DISTRICT_ID": 441, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3980, "SUB_DISTRICT_CODE": "412201", "SUB_DISTRICT_NAME": "นายูง", "DISTRICT_ID": 442, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3981, "SUB_DISTRICT_CODE": "412202", "SUB_DISTRICT_NAME": "บ้านก้อง", "DISTRICT_ID": 442, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3982, "SUB_DISTRICT_CODE": "412203", "SUB_DISTRICT_NAME": "นาแค", "DISTRICT_ID": 442, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3983, "SUB_DISTRICT_CODE": "412204", "SUB_DISTRICT_NAME": "โนนทอง", "DISTRICT_ID": 442, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3984, "SUB_DISTRICT_CODE": "412301", "SUB_DISTRICT_NAME": "บ้านแดง", "DISTRICT_ID": 443, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3985, "SUB_DISTRICT_CODE": "412302", "SUB_DISTRICT_NAME": "นาทราย", "DISTRICT_ID": 443, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3986, "SUB_DISTRICT_CODE": "412303", "SUB_DISTRICT_NAME": "ดอนกลอย", "DISTRICT_ID": 443, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3987, "SUB_DISTRICT_CODE": "412401", "SUB_DISTRICT_NAME": "บ้านจีต", "DISTRICT_ID": 444, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3988, "SUB_DISTRICT_CODE": "412402", "SUB_DISTRICT_NAME": "โนนทองอินทร์", "DISTRICT_ID": 444, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3989, "SUB_DISTRICT_CODE": "412403", "SUB_DISTRICT_NAME": "ค้อใหญ่", "DISTRICT_ID": 444, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3990, "SUB_DISTRICT_CODE": "412404", "SUB_DISTRICT_NAME": "คอนสาย", "DISTRICT_ID": 444, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3991, "SUB_DISTRICT_CODE": "412501", "SUB_DISTRICT_NAME": "นาม่วง", "DISTRICT_ID": 445, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3992, "SUB_DISTRICT_CODE": "412502", "SUB_DISTRICT_NAME": "ห้วยสามพาด", "DISTRICT_ID": 445, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3993, "SUB_DISTRICT_CODE": "412503", "SUB_DISTRICT_NAME": "อุ่มจาน", "DISTRICT_ID": 445, "PROVINCE_ID": 29, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3994, "SUB_DISTRICT_CODE": "420101", "SUB_DISTRICT_NAME": "กุดป่อง", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3995, "SUB_DISTRICT_CODE": "420102", "SUB_DISTRICT_NAME": "เมือง", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3996, "SUB_DISTRICT_CODE": "420103", "SUB_DISTRICT_NAME": "นาอ้อ", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3997, "SUB_DISTRICT_CODE": "420104", "SUB_DISTRICT_NAME": "กกดู่", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3998, "SUB_DISTRICT_CODE": "420105", "SUB_DISTRICT_NAME": "น้ำหมาน", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 3999, "SUB_DISTRICT_CODE": "420106", "SUB_DISTRICT_NAME": "เสี้ยว", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4000, "SUB_DISTRICT_CODE": "420107", "SUB_DISTRICT_NAME": "นาอาน", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4001, "SUB_DISTRICT_CODE": "420108", "SUB_DISTRICT_NAME": "นาโป่ง", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4002, "SUB_DISTRICT_CODE": "420109", "SUB_DISTRICT_NAME": "นาดินดำ", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4003, "SUB_DISTRICT_CODE": "420110", "SUB_DISTRICT_NAME": "น้ำสวย", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4004, "SUB_DISTRICT_CODE": "420111", "SUB_DISTRICT_NAME": "ชัยพฤกษ์", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4005, "SUB_DISTRICT_CODE": "420112", "SUB_DISTRICT_NAME": "นาแขม", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4006, "SUB_DISTRICT_CODE": "420113", "SUB_DISTRICT_NAME": "ศรีสองรัก", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4007, "SUB_DISTRICT_CODE": "420114", "SUB_DISTRICT_NAME": "กกทอง", "DISTRICT_ID": 446, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4008, "SUB_DISTRICT_CODE": "420201", "SUB_DISTRICT_NAME": "นาด้วง", "DISTRICT_ID": 447, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4009, "SUB_DISTRICT_CODE": "420202", "SUB_DISTRICT_NAME": "นาดอกคำ", "DISTRICT_ID": 447, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4010, "SUB_DISTRICT_CODE": "420203", "SUB_DISTRICT_NAME": "ท่าสะอาด", "DISTRICT_ID": 447, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4011, "SUB_DISTRICT_CODE": "420204", "SUB_DISTRICT_NAME": "ท่าสวรรค์", "DISTRICT_ID": 447, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4012, "SUB_DISTRICT_CODE": "420301", "SUB_DISTRICT_NAME": "เชียงคาน", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4013, "SUB_DISTRICT_CODE": "420302", "SUB_DISTRICT_NAME": "ธาตุ", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4014, "SUB_DISTRICT_CODE": "420303", "SUB_DISTRICT_NAME": "นาซ่าว", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4015, "SUB_DISTRICT_CODE": "420304", "SUB_DISTRICT_NAME": "เขาแก้ว", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4016, "SUB_DISTRICT_CODE": "420305", "SUB_DISTRICT_NAME": "ปากตม", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4017, "SUB_DISTRICT_CODE": "420306", "SUB_DISTRICT_NAME": "บุฮม", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4018, "SUB_DISTRICT_CODE": "420307", "SUB_DISTRICT_NAME": "จอมศรี", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4019, "SUB_DISTRICT_CODE": "420308", "SUB_DISTRICT_NAME": "หาดทรายขาว", "DISTRICT_ID": 448, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4020, "SUB_DISTRICT_CODE": "420401", "SUB_DISTRICT_NAME": "ปากชม", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4021, "SUB_DISTRICT_CODE": "420402", "SUB_DISTRICT_NAME": "เชียงกลม", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4022, "SUB_DISTRICT_CODE": "420403", "SUB_DISTRICT_NAME": "หาดคัมภีร์", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4023, "SUB_DISTRICT_CODE": "420404", "SUB_DISTRICT_NAME": "ห้วยบ่อซืน", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4024, "SUB_DISTRICT_CODE": "420405", "SUB_DISTRICT_NAME": "ห้วยพิชัย", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4025, "SUB_DISTRICT_CODE": "420406", "SUB_DISTRICT_NAME": "ชมเจริญ", "DISTRICT_ID": 449, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4026, "SUB_DISTRICT_CODE": "420501", "SUB_DISTRICT_NAME": "ด่านซ้าย", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4027, "SUB_DISTRICT_CODE": "420502", "SUB_DISTRICT_NAME": "ปากหมัน", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4028, "SUB_DISTRICT_CODE": "420503", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4029, "SUB_DISTRICT_CODE": "420504", "SUB_DISTRICT_NAME": "โคกงาม", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4030, "SUB_DISTRICT_CODE": "420505", "SUB_DISTRICT_NAME": "โพนสูง", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4031, "SUB_DISTRICT_CODE": "420506", "SUB_DISTRICT_NAME": "อิปุ่ม", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4032, "SUB_DISTRICT_CODE": "420507", "SUB_DISTRICT_NAME": "กกสะทอน", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4033, "SUB_DISTRICT_CODE": "420508", "SUB_DISTRICT_NAME": "โป่ง", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4034, "SUB_DISTRICT_CODE": "420509", "SUB_DISTRICT_NAME": "วังยาว", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4035, "SUB_DISTRICT_CODE": "420510", "SUB_DISTRICT_NAME": "นาหอ", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4036, "SUB_DISTRICT_CODE": "420593", "SUB_DISTRICT_NAME": "*ร่องจิก", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4037, "SUB_DISTRICT_CODE": "420594", "SUB_DISTRICT_NAME": "*แสงภา", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4038, "SUB_DISTRICT_CODE": "420595", "SUB_DISTRICT_NAME": "*ปลาบ่า", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4039, "SUB_DISTRICT_CODE": "420596", "SUB_DISTRICT_NAME": "*นาพึ่ง", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4040, "SUB_DISTRICT_CODE": "420597", "SUB_DISTRICT_NAME": "*ท่าศาลา", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4041, "SUB_DISTRICT_CODE": "420598", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4042, "SUB_DISTRICT_CODE": "420599", "SUB_DISTRICT_NAME": "*นาแห้ว", "DISTRICT_ID": 450, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4043, "SUB_DISTRICT_CODE": "420601", "SUB_DISTRICT_NAME": "นาแห้ว", "DISTRICT_ID": 451, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4044, "SUB_DISTRICT_CODE": "420602", "SUB_DISTRICT_NAME": "แสงภา", "DISTRICT_ID": 451, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4045, "SUB_DISTRICT_CODE": "420603", "SUB_DISTRICT_NAME": "นาพึง", "DISTRICT_ID": 451, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4046, "SUB_DISTRICT_CODE": "420604", "SUB_DISTRICT_NAME": "นามาลา", "DISTRICT_ID": 451, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4047, "SUB_DISTRICT_CODE": "420605", "SUB_DISTRICT_NAME": "เหล่ากอหก", "DISTRICT_ID": 451, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4048, "SUB_DISTRICT_CODE": "420701", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4049, "SUB_DISTRICT_CODE": "420702", "SUB_DISTRICT_NAME": "ท่าศาลา", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4050, "SUB_DISTRICT_CODE": "420703", "SUB_DISTRICT_NAME": "ร่องจิก", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4051, "SUB_DISTRICT_CODE": "420704", "SUB_DISTRICT_NAME": "ปลาบ่า", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4052, "SUB_DISTRICT_CODE": "420705", "SUB_DISTRICT_NAME": "ลาดค่าง", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4053, "SUB_DISTRICT_CODE": "420706", "SUB_DISTRICT_NAME": "สานตม", "DISTRICT_ID": 452, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4054, "SUB_DISTRICT_CODE": "420801", "SUB_DISTRICT_NAME": "ท่าลี่", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4055, "SUB_DISTRICT_CODE": "420802", "SUB_DISTRICT_NAME": "หนองผือ", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4056, "SUB_DISTRICT_CODE": "420803", "SUB_DISTRICT_NAME": "อาฮี", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4057, "SUB_DISTRICT_CODE": "420804", "SUB_DISTRICT_NAME": "น้ำแคม", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4058, "SUB_DISTRICT_CODE": "420805", "SUB_DISTRICT_NAME": "โคกใหญ่", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4059, "SUB_DISTRICT_CODE": "420806", "SUB_DISTRICT_NAME": "น้ำทูน", "DISTRICT_ID": 453, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4060, "SUB_DISTRICT_CODE": "420901", "SUB_DISTRICT_NAME": "วังสะพุง", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4061, "SUB_DISTRICT_CODE": "420902", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4062, "SUB_DISTRICT_CODE": "420903", "SUB_DISTRICT_NAME": "หนองหญ้าปล้อง", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4063, "SUB_DISTRICT_CODE": "420904", "SUB_DISTRICT_NAME": "หนองงิ้ว", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4064, "SUB_DISTRICT_CODE": "420905", "SUB_DISTRICT_NAME": "ปากปวน", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4065, "SUB_DISTRICT_CODE": "420906", "SUB_DISTRICT_NAME": "ผาน้อย", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4066, "SUB_DISTRICT_CODE": "420907", "SUB_DISTRICT_NAME": "*เอราวัณ", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4067, "SUB_DISTRICT_CODE": "420908", "SUB_DISTRICT_NAME": "*ผาอินทร์แปลง", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4068, "SUB_DISTRICT_CODE": "420909", "SUB_DISTRICT_NAME": "*ผาสามยอด", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4069, "SUB_DISTRICT_CODE": "420910", "SUB_DISTRICT_NAME": "ผาบิ้ง", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4070, "SUB_DISTRICT_CODE": "420911", "SUB_DISTRICT_NAME": "เขาหลวง", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4071, "SUB_DISTRICT_CODE": "420912", "SUB_DISTRICT_NAME": "โคกขมิ้น", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4072, "SUB_DISTRICT_CODE": "420913", "SUB_DISTRICT_NAME": "ศรีสงคราม", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4073, "SUB_DISTRICT_CODE": "420914", "SUB_DISTRICT_NAME": "*ทรัพย์ไพวัลย์", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4074, "SUB_DISTRICT_CODE": "420998", "SUB_DISTRICT_NAME": "*หนองคัน", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4075, "SUB_DISTRICT_CODE": "420999", "SUB_DISTRICT_NAME": "*ภูหอ", "DISTRICT_ID": 454, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4076, "SUB_DISTRICT_CODE": "421001", "SUB_DISTRICT_NAME": "ศรีฐาน", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4077, "SUB_DISTRICT_CODE": "421002", "SUB_DISTRICT_NAME": "ปวนพุ*", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4078, "SUB_DISTRICT_CODE": "421003", "SUB_DISTRICT_NAME": "*ท่าช้างคล้อง", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4079, "SUB_DISTRICT_CODE": "421004", "SUB_DISTRICT_NAME": "*ผาขาว", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4080, "SUB_DISTRICT_CODE": "421005", "SUB_DISTRICT_NAME": "ผานกเค้า", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4081, "SUB_DISTRICT_CODE": "421006", "SUB_DISTRICT_NAME": "*โนนป่าซาง", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4082, "SUB_DISTRICT_CODE": "421007", "SUB_DISTRICT_NAME": "ภูกระดึง", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4083, "SUB_DISTRICT_CODE": "421008", "SUB_DISTRICT_NAME": "หนองหิน*", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4084, "SUB_DISTRICT_CODE": "421009", "SUB_DISTRICT_NAME": "*โนนปอแดง", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4085, "SUB_DISTRICT_CODE": "421010", "SUB_DISTRICT_NAME": "ห้วยส้ม", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4086, "SUB_DISTRICT_CODE": "421011", "SUB_DISTRICT_NAME": "ตาดข่า*", "DISTRICT_ID": 455, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4087, "SUB_DISTRICT_CODE": "421101", "SUB_DISTRICT_NAME": "ภูหอ", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4088, "SUB_DISTRICT_CODE": "421102", "SUB_DISTRICT_NAME": "หนองคัน", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4089, "SUB_DISTRICT_CODE": "421103", "SUB_DISTRICT_NAME": "วังน้ำใส*", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4090, "SUB_DISTRICT_CODE": "421104", "SUB_DISTRICT_NAME": "ห้วยสีเสียด", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4091, "SUB_DISTRICT_CODE": "421105", "SUB_DISTRICT_NAME": "เลยวังไสย์", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4092, "SUB_DISTRICT_CODE": "421106", "SUB_DISTRICT_NAME": "แก่งศรีภูมิ", "DISTRICT_ID": 456, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4093, "SUB_DISTRICT_CODE": "421201", "SUB_DISTRICT_NAME": "ผาขาว", "DISTRICT_ID": 457, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4094, "SUB_DISTRICT_CODE": "421202", "SUB_DISTRICT_NAME": "ท่าช้างคล้อง", "DISTRICT_ID": 457, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4095, "SUB_DISTRICT_CODE": "421203", "SUB_DISTRICT_NAME": "โนนปอแดง", "DISTRICT_ID": 457, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4096, "SUB_DISTRICT_CODE": "421204", "SUB_DISTRICT_NAME": "โนนป่าซาง", "DISTRICT_ID": 457, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4097, "SUB_DISTRICT_CODE": "421205", "SUB_DISTRICT_NAME": "บ้านเพิ่ม", "DISTRICT_ID": 457, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4098, "SUB_DISTRICT_CODE": "421301", "SUB_DISTRICT_NAME": "เอราวัณ", "DISTRICT_ID": 458, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4099, "SUB_DISTRICT_CODE": "421302", "SUB_DISTRICT_NAME": "ผาอินทร์แปลง", "DISTRICT_ID": 458, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4100, "SUB_DISTRICT_CODE": "421303", "SUB_DISTRICT_NAME": "ผาสามยอด", "DISTRICT_ID": 458, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4101, "SUB_DISTRICT_CODE": "421304", "SUB_DISTRICT_NAME": "ทรัพย์ไพวัลย์", "DISTRICT_ID": 458, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4102, "SUB_DISTRICT_CODE": "421401", "SUB_DISTRICT_NAME": "หนองหิน", "DISTRICT_ID": 459, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4103, "SUB_DISTRICT_CODE": "421402", "SUB_DISTRICT_NAME": "ตาดข่า", "DISTRICT_ID": 459, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4104, "SUB_DISTRICT_CODE": "421403", "SUB_DISTRICT_NAME": "ปวนพุ", "DISTRICT_ID": 459, "PROVINCE_ID": 30, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4105, "SUB_DISTRICT_CODE": "430101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4106, "SUB_DISTRICT_CODE": "430102", "SUB_DISTRICT_NAME": "มีชัย", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4107, "SUB_DISTRICT_CODE": "430103", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4108, "SUB_DISTRICT_CODE": "430104", "SUB_DISTRICT_NAME": "กวนวัน", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4109, "SUB_DISTRICT_CODE": "430105", "SUB_DISTRICT_NAME": "เวียงคุก", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4110, "SUB_DISTRICT_CODE": "430106", "SUB_DISTRICT_NAME": "วัดธาตุ", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4111, "SUB_DISTRICT_CODE": "430107", "SUB_DISTRICT_NAME": "หาดคำ", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4112, "SUB_DISTRICT_CODE": "430108", "SUB_DISTRICT_NAME": "หินโงม", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4113, "SUB_DISTRICT_CODE": "430109", "SUB_DISTRICT_NAME": "บ้านเดื่อ", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4114, "SUB_DISTRICT_CODE": "430110", "SUB_DISTRICT_NAME": "ค่ายบกหวาน", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4115, "SUB_DISTRICT_CODE": "430111", "SUB_DISTRICT_NAME": "สองห้อง", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4116, "SUB_DISTRICT_CODE": "430112", "SUB_DISTRICT_NAME": "*สระใคร", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4117, "SUB_DISTRICT_CODE": "430113", "SUB_DISTRICT_NAME": "พระธาตุบังพวน", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4118, "SUB_DISTRICT_CODE": "430114", "SUB_DISTRICT_NAME": "*บ้านฝาง", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4119, "SUB_DISTRICT_CODE": "430115", "SUB_DISTRICT_NAME": "*คอกช้าง", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4120, "SUB_DISTRICT_CODE": "430116", "SUB_DISTRICT_NAME": "หนองกอมเกาะ", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4121, "SUB_DISTRICT_CODE": "430117", "SUB_DISTRICT_NAME": "ปะโค", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4122, "SUB_DISTRICT_CODE": "430118", "SUB_DISTRICT_NAME": "เมืองหมี", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4123, "SUB_DISTRICT_CODE": "430119", "SUB_DISTRICT_NAME": "สีกาย", "DISTRICT_ID": 460, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4124, "SUB_DISTRICT_CODE": "430201", "SUB_DISTRICT_NAME": "ท่าบ่อ", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4125, "SUB_DISTRICT_CODE": "430202", "SUB_DISTRICT_NAME": "น้ำโมง", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4126, "SUB_DISTRICT_CODE": "430203", "SUB_DISTRICT_NAME": "กองนาง", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4127, "SUB_DISTRICT_CODE": "430204", "SUB_DISTRICT_NAME": "โคกคอน", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4128, "SUB_DISTRICT_CODE": "430205", "SUB_DISTRICT_NAME": "บ้านเดื่อ", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4129, "SUB_DISTRICT_CODE": "430206", "SUB_DISTRICT_NAME": "บ้านถ่อน", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4130, "SUB_DISTRICT_CODE": "430207", "SUB_DISTRICT_NAME": "บ้านว่าน", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4131, "SUB_DISTRICT_CODE": "430208", "SUB_DISTRICT_NAME": "นาข่า", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4132, "SUB_DISTRICT_CODE": "430209", "SUB_DISTRICT_NAME": "โพนสา", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4133, "SUB_DISTRICT_CODE": "430210", "SUB_DISTRICT_NAME": "หนองนาง", "DISTRICT_ID": 461, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8914, "SUB_DISTRICT_CODE": "330320", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 292, "PROVINCE_ID": 22, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4153, "SUB_DISTRICT_CODE": "430401", "SUB_DISTRICT_NAME": "ศรีชมภู", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4154, "SUB_DISTRICT_CODE": "430402", "SUB_DISTRICT_NAME": "ดอนหญ้านาง", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4155, "SUB_DISTRICT_CODE": "430403", "SUB_DISTRICT_NAME": "พรเจริญ", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4156, "SUB_DISTRICT_CODE": "430404", "SUB_DISTRICT_NAME": "หนองหัวช้าง", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4157, "SUB_DISTRICT_CODE": "430405", "SUB_DISTRICT_NAME": "วังชมภู", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4158, "SUB_DISTRICT_CODE": "430406", "SUB_DISTRICT_NAME": "ป่าแฝก", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4159, "SUB_DISTRICT_CODE": "430407", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 463, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4160, "SUB_DISTRICT_CODE": "430501", "SUB_DISTRICT_NAME": "จุมพล", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4161, "SUB_DISTRICT_CODE": "430502", "SUB_DISTRICT_NAME": "วัดหลวง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4162, "SUB_DISTRICT_CODE": "430503", "SUB_DISTRICT_NAME": "กุดบง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4163, "SUB_DISTRICT_CODE": "430504", "SUB_DISTRICT_NAME": "ชุมช้าง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4164, "SUB_DISTRICT_CODE": "430505", "SUB_DISTRICT_NAME": "*รัตนวาปี", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4165, "SUB_DISTRICT_CODE": "430506", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4166, "SUB_DISTRICT_CODE": "430507", "SUB_DISTRICT_NAME": "เหล่าต่างคำ", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4167, "SUB_DISTRICT_CODE": "430508", "SUB_DISTRICT_NAME": "นาหนัง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4168, "SUB_DISTRICT_CODE": "430509", "SUB_DISTRICT_NAME": "เซิม", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4169, "SUB_DISTRICT_CODE": "430510", "SUB_DISTRICT_NAME": "*หนองหลวง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4170, "SUB_DISTRICT_CODE": "430511", "SUB_DISTRICT_NAME": "*โพนแพง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4171, "SUB_DISTRICT_CODE": "430512", "SUB_DISTRICT_NAME": "*เฝ้าไร่", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4172, "SUB_DISTRICT_CODE": "430513", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4173, "SUB_DISTRICT_CODE": "430514", "SUB_DISTRICT_NAME": "*นาทับไฮ", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4174, "SUB_DISTRICT_CODE": "430515", "SUB_DISTRICT_NAME": "*วังหลวง", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4175, "SUB_DISTRICT_CODE": "430516", "SUB_DISTRICT_NAME": "*พระบาทนาสิงห์", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4176, "SUB_DISTRICT_CODE": "430517", "SUB_DISTRICT_NAME": "*อุดมพร", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4177, "SUB_DISTRICT_CODE": "430518", "SUB_DISTRICT_NAME": "*นาดี", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4178, "SUB_DISTRICT_CODE": "430520", "SUB_DISTRICT_NAME": "*บ้านต้อน", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4179, "SUB_DISTRICT_CODE": "430521", "SUB_DISTRICT_NAME": "บ้านผือ", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4180, "SUB_DISTRICT_CODE": "430522", "SUB_DISTRICT_NAME": "สร้างนางขาว", "DISTRICT_ID": 464, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4181, "SUB_DISTRICT_CODE": "430601", "SUB_DISTRICT_NAME": "โซ่", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4182, "SUB_DISTRICT_CODE": "430602", "SUB_DISTRICT_NAME": "หนองพันทา", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4183, "SUB_DISTRICT_CODE": "430603", "SUB_DISTRICT_NAME": "ศรีชมภู", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4184, "SUB_DISTRICT_CODE": "430604", "SUB_DISTRICT_NAME": "คำแก้ว", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4185, "SUB_DISTRICT_CODE": "430605", "SUB_DISTRICT_NAME": "บัวตูม", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4186, "SUB_DISTRICT_CODE": "430606", "SUB_DISTRICT_NAME": "ถ้ำเจริญ", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4187, "SUB_DISTRICT_CODE": "430607", "SUB_DISTRICT_NAME": "เหล่าทอง", "DISTRICT_ID": 465, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4188, "SUB_DISTRICT_CODE": "430701", "SUB_DISTRICT_NAME": "พานพร้าว", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4189, "SUB_DISTRICT_CODE": "430702", "SUB_DISTRICT_NAME": "โพธิ์ตาก*", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4190, "SUB_DISTRICT_CODE": "430703", "SUB_DISTRICT_NAME": "บ้านหม้อ", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4191, "SUB_DISTRICT_CODE": "430704", "SUB_DISTRICT_NAME": "พระพุทธบาท", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4192, "SUB_DISTRICT_CODE": "430705", "SUB_DISTRICT_NAME": "หนองปลาปาก", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4193, "SUB_DISTRICT_CODE": "430706", "SUB_DISTRICT_NAME": "โพนทอง*", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4194, "SUB_DISTRICT_CODE": "430707", "SUB_DISTRICT_NAME": "ด่านศรีสุข*", "DISTRICT_ID": 466, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4195, "SUB_DISTRICT_CODE": "430801", "SUB_DISTRICT_NAME": "แก้งไก่", "DISTRICT_ID": 467, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4196, "SUB_DISTRICT_CODE": "430802", "SUB_DISTRICT_NAME": "ผาตั้ง", "DISTRICT_ID": 467, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4197, "SUB_DISTRICT_CODE": "430803", "SUB_DISTRICT_NAME": "บ้านม่วง", "DISTRICT_ID": 467, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4198, "SUB_DISTRICT_CODE": "430804", "SUB_DISTRICT_NAME": "นางิ้ว", "DISTRICT_ID": 467, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4199, "SUB_DISTRICT_CODE": "430805", "SUB_DISTRICT_NAME": "สังคม", "DISTRICT_ID": 467, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4200, "SUB_DISTRICT_CODE": "430901", "SUB_DISTRICT_NAME": "เซกา", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4201, "SUB_DISTRICT_CODE": "430902", "SUB_DISTRICT_NAME": "ซาง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4202, "SUB_DISTRICT_CODE": "430903", "SUB_DISTRICT_NAME": "ท่ากกแดง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4203, "SUB_DISTRICT_CODE": "430904", "SUB_DISTRICT_NAME": "*โพธิ์หมากแข้ง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4204, "SUB_DISTRICT_CODE": "430905", "SUB_DISTRICT_NAME": "*ดงบัง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4205, "SUB_DISTRICT_CODE": "430906", "SUB_DISTRICT_NAME": "บ้านต้อง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4206, "SUB_DISTRICT_CODE": "430907", "SUB_DISTRICT_NAME": "ป่งไฮ", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4207, "SUB_DISTRICT_CODE": "430908", "SUB_DISTRICT_NAME": "น้ำจั้น", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4208, "SUB_DISTRICT_CODE": "430909", "SUB_DISTRICT_NAME": "ท่าสะอาด", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4209, "SUB_DISTRICT_CODE": "430910", "SUB_DISTRICT_NAME": "*บึงโขงหลง", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4210, "SUB_DISTRICT_CODE": "430911", "SUB_DISTRICT_NAME": "*ท่าดอกคำ", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4211, "SUB_DISTRICT_CODE": "430912", "SUB_DISTRICT_NAME": "หนองทุ่ม", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4212, "SUB_DISTRICT_CODE": "430913", "SUB_DISTRICT_NAME": "โสกก่าม", "DISTRICT_ID": 468, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4213, "SUB_DISTRICT_CODE": "431001", "SUB_DISTRICT_NAME": "ปากคาด", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4214, "SUB_DISTRICT_CODE": "431002", "SUB_DISTRICT_NAME": "หนองยอง", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4215, "SUB_DISTRICT_CODE": "431003", "SUB_DISTRICT_NAME": "นากั้ง", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4216, "SUB_DISTRICT_CODE": "431004", "SUB_DISTRICT_NAME": "โนนศิลา", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4217, "SUB_DISTRICT_CODE": "431005", "SUB_DISTRICT_NAME": "สมสนุก", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4218, "SUB_DISTRICT_CODE": "431006", "SUB_DISTRICT_NAME": "นาดง", "DISTRICT_ID": 469, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4219, "SUB_DISTRICT_CODE": "431101", "SUB_DISTRICT_NAME": "บึงโขงหลง", "DISTRICT_ID": 470, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4220, "SUB_DISTRICT_CODE": "431102", "SUB_DISTRICT_NAME": "โพธิ์หมากแข้ง", "DISTRICT_ID": 470, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4221, "SUB_DISTRICT_CODE": "431103", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 470, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4222, "SUB_DISTRICT_CODE": "431104", "SUB_DISTRICT_NAME": "ท่าดอกคำ", "DISTRICT_ID": 470, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4223, "SUB_DISTRICT_CODE": "431201", "SUB_DISTRICT_NAME": "ศรีวิไล", "DISTRICT_ID": 471, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4224, "SUB_DISTRICT_CODE": "431202", "SUB_DISTRICT_NAME": "ชุมภูพร", "DISTRICT_ID": 471, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4225, "SUB_DISTRICT_CODE": "431203", "SUB_DISTRICT_NAME": "นาแสง", "DISTRICT_ID": 471, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4226, "SUB_DISTRICT_CODE": "431204", "SUB_DISTRICT_NAME": "นาสะแบง", "DISTRICT_ID": 471, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4227, "SUB_DISTRICT_CODE": "431205", "SUB_DISTRICT_NAME": "นาสิงห์", "DISTRICT_ID": 471, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4228, "SUB_DISTRICT_CODE": "431301", "SUB_DISTRICT_NAME": "บุ่งคล้า", "DISTRICT_ID": 472, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4229, "SUB_DISTRICT_CODE": "431302", "SUB_DISTRICT_NAME": "หนองเดิ่น", "DISTRICT_ID": 472, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4230, "SUB_DISTRICT_CODE": "431303", "SUB_DISTRICT_NAME": "โคกกว้าง", "DISTRICT_ID": 472, "PROVINCE_ID": 97, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4231, "SUB_DISTRICT_CODE": "431401", "SUB_DISTRICT_NAME": "สระใคร", "DISTRICT_ID": 473, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4232, "SUB_DISTRICT_CODE": "431402", "SUB_DISTRICT_NAME": "คอกช้าง", "DISTRICT_ID": 473, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4233, "SUB_DISTRICT_CODE": "431403", "SUB_DISTRICT_NAME": "บ้านฝาง", "DISTRICT_ID": 473, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4234, "SUB_DISTRICT_CODE": "431501", "SUB_DISTRICT_NAME": "เฝ้าไร่", "DISTRICT_ID": 474, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4235, "SUB_DISTRICT_CODE": "431502", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 474, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4236, "SUB_DISTRICT_CODE": "431503", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 474, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4237, "SUB_DISTRICT_CODE": "431504", "SUB_DISTRICT_NAME": "วังหลวง", "DISTRICT_ID": 474, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4238, "SUB_DISTRICT_CODE": "431505", "SUB_DISTRICT_NAME": "อุดมพร", "DISTRICT_ID": 474, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4239, "SUB_DISTRICT_CODE": "431601", "SUB_DISTRICT_NAME": "รัตนวาปี", "DISTRICT_ID": 475, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4240, "SUB_DISTRICT_CODE": "431602", "SUB_DISTRICT_NAME": "นาทับไฮ", "DISTRICT_ID": 475, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4241, "SUB_DISTRICT_CODE": "431603", "SUB_DISTRICT_NAME": "บ้านต้อน", "DISTRICT_ID": 475, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4242, "SUB_DISTRICT_CODE": "431604", "SUB_DISTRICT_NAME": "พระบาทนาสิงห์", "DISTRICT_ID": 475, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4243, "SUB_DISTRICT_CODE": "431605", "SUB_DISTRICT_NAME": "โพนแพง", "DISTRICT_ID": 475, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4244, "SUB_DISTRICT_CODE": "431701", "SUB_DISTRICT_NAME": "โพธิ์ตาก", "DISTRICT_ID": 476, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4245, "SUB_DISTRICT_CODE": "431702", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 476, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4246, "SUB_DISTRICT_CODE": "431703", "SUB_DISTRICT_NAME": "ด่านศรีสุข", "DISTRICT_ID": 476, "PROVINCE_ID": 31, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4247, "SUB_DISTRICT_CODE": "440101", "SUB_DISTRICT_NAME": "ตลาด", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4248, "SUB_DISTRICT_CODE": "440102", "SUB_DISTRICT_NAME": "เขวา", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4249, "SUB_DISTRICT_CODE": "440103", "SUB_DISTRICT_NAME": "ท่าตูม", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4250, "SUB_DISTRICT_CODE": "440104", "SUB_DISTRICT_NAME": "แวงน่าง", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4251, "SUB_DISTRICT_CODE": "440105", "SUB_DISTRICT_NAME": "โคกก่อ", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4252, "SUB_DISTRICT_CODE": "440106", "SUB_DISTRICT_NAME": "ดอนหว่าน", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4253, "SUB_DISTRICT_CODE": "440107", "SUB_DISTRICT_NAME": "เกิ้ง", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4254, "SUB_DISTRICT_CODE": "440108", "SUB_DISTRICT_NAME": "แก่งเลิงจาน", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4255, "SUB_DISTRICT_CODE": "440109", "SUB_DISTRICT_NAME": "ท่าสองคอน", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4256, "SUB_DISTRICT_CODE": "440110", "SUB_DISTRICT_NAME": "ลาดพัฒนา", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4257, "SUB_DISTRICT_CODE": "440111", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4258, "SUB_DISTRICT_CODE": "440112", "SUB_DISTRICT_NAME": "ห้วยแอ่ง", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4259, "SUB_DISTRICT_CODE": "440113", "SUB_DISTRICT_NAME": "หนองโน", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4260, "SUB_DISTRICT_CODE": "440114", "SUB_DISTRICT_NAME": "บัวค้อ", "DISTRICT_ID": 477, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4261, "SUB_DISTRICT_CODE": "440201", "SUB_DISTRICT_NAME": "แกดำ", "DISTRICT_ID": 478, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4262, "SUB_DISTRICT_CODE": "440202", "SUB_DISTRICT_NAME": "วังแสง", "DISTRICT_ID": 478, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4263, "SUB_DISTRICT_CODE": "440203", "SUB_DISTRICT_NAME": "มิตรภาพ", "DISTRICT_ID": 478, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4264, "SUB_DISTRICT_CODE": "440204", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 478, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4265, "SUB_DISTRICT_CODE": "440205", "SUB_DISTRICT_NAME": "โนนภิบาล", "DISTRICT_ID": 478, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4266, "SUB_DISTRICT_CODE": "440301", "SUB_DISTRICT_NAME": "หัวขวาง", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4267, "SUB_DISTRICT_CODE": "440302", "SUB_DISTRICT_NAME": "ยางน้อย", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4268, "SUB_DISTRICT_CODE": "440303", "SUB_DISTRICT_NAME": "วังยาว", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4269, "SUB_DISTRICT_CODE": "440304", "SUB_DISTRICT_NAME": "เขวาไร่", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4270, "SUB_DISTRICT_CODE": "440305", "SUB_DISTRICT_NAME": "แพง", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4271, "SUB_DISTRICT_CODE": "440306", "SUB_DISTRICT_NAME": "แก้งแก", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4272, "SUB_DISTRICT_CODE": "440307", "SUB_DISTRICT_NAME": "หนองเหล็ก", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4273, "SUB_DISTRICT_CODE": "440308", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4274, "SUB_DISTRICT_CODE": "440309", "SUB_DISTRICT_NAME": "เหล่า", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4275, "SUB_DISTRICT_CODE": "440310", "SUB_DISTRICT_NAME": "เขื่อน", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4276, "SUB_DISTRICT_CODE": "440311", "SUB_DISTRICT_NAME": "หนองบอน", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4277, "SUB_DISTRICT_CODE": "440312", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4278, "SUB_DISTRICT_CODE": "440313", "SUB_DISTRICT_NAME": "ยางท่าแจ้ง", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4279, "SUB_DISTRICT_CODE": "440314", "SUB_DISTRICT_NAME": "แห่ใต้", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4280, "SUB_DISTRICT_CODE": "440315", "SUB_DISTRICT_NAME": "หนองกุงสวรรค์", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4281, "SUB_DISTRICT_CODE": "440316", "SUB_DISTRICT_NAME": "เลิงใต้", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4282, "SUB_DISTRICT_CODE": "440317", "SUB_DISTRICT_NAME": "ดอนกลาง", "DISTRICT_ID": 479, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4283, "SUB_DISTRICT_CODE": "440401", "SUB_DISTRICT_NAME": "โคกพระ", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4284, "SUB_DISTRICT_CODE": "440402", "SUB_DISTRICT_NAME": "คันธารราษฎร์", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4285, "SUB_DISTRICT_CODE": "440403", "SUB_DISTRICT_NAME": "มะค่า", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4286, "SUB_DISTRICT_CODE": "440404", "SUB_DISTRICT_NAME": "ท่าขอนยาง", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4287, "SUB_DISTRICT_CODE": "440405", "SUB_DISTRICT_NAME": "นาสีนวน", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4288, "SUB_DISTRICT_CODE": "440406", "SUB_DISTRICT_NAME": "ขามเรียง", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4289, "SUB_DISTRICT_CODE": "440407", "SUB_DISTRICT_NAME": "เขวาใหญ่", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4290, "SUB_DISTRICT_CODE": "440408", "SUB_DISTRICT_NAME": "ศรีสุข", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4291, "SUB_DISTRICT_CODE": "440409", "SUB_DISTRICT_NAME": "กุดใส้จ่อ", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4292, "SUB_DISTRICT_CODE": "440410", "SUB_DISTRICT_NAME": "ขามเฒ่าพัฒนา", "DISTRICT_ID": 480, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4293, "SUB_DISTRICT_CODE": "440501", "SUB_DISTRICT_NAME": "เชียงยืน", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4294, "SUB_DISTRICT_CODE": "440502", "SUB_DISTRICT_NAME": "ชื่นชม*", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4295, "SUB_DISTRICT_CODE": "440503", "SUB_DISTRICT_NAME": "หนองซอน", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4296, "SUB_DISTRICT_CODE": "440504", "SUB_DISTRICT_NAME": "เหล่าดอกไม้*", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4297, "SUB_DISTRICT_CODE": "440505", "SUB_DISTRICT_NAME": "ดอนเงิน", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4298, "SUB_DISTRICT_CODE": "440506", "SUB_DISTRICT_NAME": "กู่ทอง", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4299, "SUB_DISTRICT_CODE": "440507", "SUB_DISTRICT_NAME": "นาทอง", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4300, "SUB_DISTRICT_CODE": "440508", "SUB_DISTRICT_NAME": "เสือเฒ่า", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4301, "SUB_DISTRICT_CODE": "440509", "SUB_DISTRICT_NAME": "กุดปลาดุก*", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4302, "SUB_DISTRICT_CODE": "440510", "SUB_DISTRICT_NAME": "หนองกุง*", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4303, "SUB_DISTRICT_CODE": "440511", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4304, "SUB_DISTRICT_CODE": "440512", "SUB_DISTRICT_NAME": "เหล่าบัวบาน", "DISTRICT_ID": 481, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4305, "SUB_DISTRICT_CODE": "440601", "SUB_DISTRICT_NAME": "บรบือ", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4306, "SUB_DISTRICT_CODE": "440602", "SUB_DISTRICT_NAME": "บ่อใหญ่", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4307, "SUB_DISTRICT_CODE": "440603", "SUB_DISTRICT_NAME": "*กุดรัง", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4308, "SUB_DISTRICT_CODE": "440604", "SUB_DISTRICT_NAME": "วังไชย", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4309, "SUB_DISTRICT_CODE": "440605", "SUB_DISTRICT_NAME": "หนองม่วง", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4310, "SUB_DISTRICT_CODE": "440606", "SUB_DISTRICT_NAME": "กำพี้", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4311, "SUB_DISTRICT_CODE": "440607", "SUB_DISTRICT_NAME": "โนนราษี", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4312, "SUB_DISTRICT_CODE": "440608", "SUB_DISTRICT_NAME": "โนนแดง", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4313, "SUB_DISTRICT_CODE": "440609", "SUB_DISTRICT_NAME": "*เลิงแฝก", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4314, "SUB_DISTRICT_CODE": "440610", "SUB_DISTRICT_NAME": "หนองจิก", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4315, "SUB_DISTRICT_CODE": "440611", "SUB_DISTRICT_NAME": "บัวมาศ", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4316, "SUB_DISTRICT_CODE": "440612", "SUB_DISTRICT_NAME": "*นาโพธิ์", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4317, "SUB_DISTRICT_CODE": "440613", "SUB_DISTRICT_NAME": "หนองคูขาด", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4318, "SUB_DISTRICT_CODE": "440614", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4319, "SUB_DISTRICT_CODE": "440615", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4320, "SUB_DISTRICT_CODE": "440616", "SUB_DISTRICT_NAME": "ยาง", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4321, "SUB_DISTRICT_CODE": "440617", "SUB_DISTRICT_NAME": "*ห้วยเตย", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4322, "SUB_DISTRICT_CODE": "440618", "SUB_DISTRICT_NAME": "หนองสิม", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4323, "SUB_DISTRICT_CODE": "440619", "SUB_DISTRICT_NAME": "หนองโก", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4324, "SUB_DISTRICT_CODE": "440620", "SUB_DISTRICT_NAME": "ดอนงัว", "DISTRICT_ID": 482, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4325, "SUB_DISTRICT_CODE": "440701", "SUB_DISTRICT_NAME": "นาเชือก", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4326, "SUB_DISTRICT_CODE": "440702", "SUB_DISTRICT_NAME": "สำโรง", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4327, "SUB_DISTRICT_CODE": "440703", "SUB_DISTRICT_NAME": "หนองแดง", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4328, "SUB_DISTRICT_CODE": "440704", "SUB_DISTRICT_NAME": "เขวาไร่", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4329, "SUB_DISTRICT_CODE": "440705", "SUB_DISTRICT_NAME": "หนองโพธิ์", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4330, "SUB_DISTRICT_CODE": "440706", "SUB_DISTRICT_NAME": "ปอพาน", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4331, "SUB_DISTRICT_CODE": "440707", "SUB_DISTRICT_NAME": "หนองเม็ก", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4332, "SUB_DISTRICT_CODE": "440708", "SUB_DISTRICT_NAME": "หนองเรือ", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4333, "SUB_DISTRICT_CODE": "440709", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4334, "SUB_DISTRICT_CODE": "440710", "SUB_DISTRICT_NAME": "สันป่าตอง", "DISTRICT_ID": 483, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4335, "SUB_DISTRICT_CODE": "440801", "SUB_DISTRICT_NAME": "ปะหลาน", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4336, "SUB_DISTRICT_CODE": "440802", "SUB_DISTRICT_NAME": "ก้ามปู", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4337, "SUB_DISTRICT_CODE": "440803", "SUB_DISTRICT_NAME": "เวียงสะอาด", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4338, "SUB_DISTRICT_CODE": "440804", "SUB_DISTRICT_NAME": "เม็กดำ", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4339, "SUB_DISTRICT_CODE": "440805", "SUB_DISTRICT_NAME": "นาสีนวล", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4340, "SUB_DISTRICT_CODE": "440806", "SUB_DISTRICT_NAME": "*ดงเมือง", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4341, "SUB_DISTRICT_CODE": "440807", "SUB_DISTRICT_NAME": "*แวงดง", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4342, "SUB_DISTRICT_CODE": "440808", "SUB_DISTRICT_NAME": "*ขามเรียน", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4343, "SUB_DISTRICT_CODE": "440809", "SUB_DISTRICT_NAME": "ราษฎร์เจริญ", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4344, "SUB_DISTRICT_CODE": "440810", "SUB_DISTRICT_NAME": "หนองบัวแก้ว", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4345, "SUB_DISTRICT_CODE": "440811", "SUB_DISTRICT_NAME": "*นาภู", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4346, "SUB_DISTRICT_CODE": "440812", "SUB_DISTRICT_NAME": "เมืองเตา", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4347, "SUB_DISTRICT_CODE": "440813", "SUB_DISTRICT_NAME": "*บ้านกู่", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4348, "SUB_DISTRICT_CODE": "440814", "SUB_DISTRICT_NAME": "*ยางสีสุราช", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4349, "SUB_DISTRICT_CODE": "440815", "SUB_DISTRICT_NAME": "ลานสะแก", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4350, "SUB_DISTRICT_CODE": "440816", "SUB_DISTRICT_NAME": "เวียงชัย", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4351, "SUB_DISTRICT_CODE": "440817", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4352, "SUB_DISTRICT_CODE": "440818", "SUB_DISTRICT_NAME": "ราษฎร์พัฒนา", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4353, "SUB_DISTRICT_CODE": "440819", "SUB_DISTRICT_NAME": "เมืองเสือ", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4354, "SUB_DISTRICT_CODE": "440820", "SUB_DISTRICT_NAME": "ภารแอ่น", "DISTRICT_ID": 484, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4355, "SUB_DISTRICT_CODE": "440901", "SUB_DISTRICT_NAME": "หนองแสง", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4356, "SUB_DISTRICT_CODE": "440902", "SUB_DISTRICT_NAME": "ขามป้อม", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4357, "SUB_DISTRICT_CODE": "440903", "SUB_DISTRICT_NAME": "เสือโก้ก", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4358, "SUB_DISTRICT_CODE": "440904", "SUB_DISTRICT_NAME": "ดงใหญ่", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4359, "SUB_DISTRICT_CODE": "440905", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4360, "SUB_DISTRICT_CODE": "440906", "SUB_DISTRICT_NAME": "หัวเรือ", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4361, "SUB_DISTRICT_CODE": "440907", "SUB_DISTRICT_NAME": "แคน", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4362, "SUB_DISTRICT_CODE": "440908", "SUB_DISTRICT_NAME": "งัวบา", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4363, "SUB_DISTRICT_CODE": "440909", "SUB_DISTRICT_NAME": "นาข่า", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4364, "SUB_DISTRICT_CODE": "440910", "SUB_DISTRICT_NAME": "บ้านหวาย", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4365, "SUB_DISTRICT_CODE": "440911", "SUB_DISTRICT_NAME": "หนองไฮ", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4366, "SUB_DISTRICT_CODE": "440912", "SUB_DISTRICT_NAME": "ประชาพัฒนา", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4367, "SUB_DISTRICT_CODE": "440913", "SUB_DISTRICT_NAME": "หนองทุ่ม", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4368, "SUB_DISTRICT_CODE": "440914", "SUB_DISTRICT_NAME": "หนองแสน", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4369, "SUB_DISTRICT_CODE": "440915", "SUB_DISTRICT_NAME": "โคกสีทองหลาง", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4370, "SUB_DISTRICT_CODE": "440997", "SUB_DISTRICT_NAME": "*หนองไผ่", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4371, "SUB_DISTRICT_CODE": "440998", "SUB_DISTRICT_NAME": "*นาดูน", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4372, "SUB_DISTRICT_CODE": "440999", "SUB_DISTRICT_NAME": "*หนองคู", "DISTRICT_ID": 485, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4373, "SUB_DISTRICT_CODE": "441001", "SUB_DISTRICT_NAME": "นาดูน", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4374, "SUB_DISTRICT_CODE": "441002", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4375, "SUB_DISTRICT_CODE": "441003", "SUB_DISTRICT_NAME": "หนองคู", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4376, "SUB_DISTRICT_CODE": "441004", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4377, "SUB_DISTRICT_CODE": "441005", "SUB_DISTRICT_NAME": "ดงดวน", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4378, "SUB_DISTRICT_CODE": "441006", "SUB_DISTRICT_NAME": "หัวดง", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4379, "SUB_DISTRICT_CODE": "441007", "SUB_DISTRICT_NAME": "ดงยาง", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4380, "SUB_DISTRICT_CODE": "441008", "SUB_DISTRICT_NAME": "กู่สันตรัตน์", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4381, "SUB_DISTRICT_CODE": "441009", "SUB_DISTRICT_NAME": "พระธาตุ", "DISTRICT_ID": 486, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4382, "SUB_DISTRICT_CODE": "441101", "SUB_DISTRICT_NAME": "ยางสีสุราช", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4383, "SUB_DISTRICT_CODE": "441102", "SUB_DISTRICT_NAME": "นาภู", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4384, "SUB_DISTRICT_CODE": "441103", "SUB_DISTRICT_NAME": "แวงดง", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4385, "SUB_DISTRICT_CODE": "441104", "SUB_DISTRICT_NAME": "บ้านกู่", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4386, "SUB_DISTRICT_CODE": "441105", "SUB_DISTRICT_NAME": "ดงเมือง", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4387, "SUB_DISTRICT_CODE": "441106", "SUB_DISTRICT_NAME": "ขามเรียน", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4388, "SUB_DISTRICT_CODE": "441107", "SUB_DISTRICT_NAME": "หนองบัวสันตุ", "DISTRICT_ID": 487, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4389, "SUB_DISTRICT_CODE": "441201", "SUB_DISTRICT_NAME": "กุดรัง", "DISTRICT_ID": 488, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4390, "SUB_DISTRICT_CODE": "441202", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 488, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4391, "SUB_DISTRICT_CODE": "441203", "SUB_DISTRICT_NAME": "เลิงแฝก", "DISTRICT_ID": 488, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4392, "SUB_DISTRICT_CODE": "441204", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 488, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4393, "SUB_DISTRICT_CODE": "441205", "SUB_DISTRICT_NAME": "ห้วยเตย", "DISTRICT_ID": 488, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4394, "SUB_DISTRICT_CODE": "441301", "SUB_DISTRICT_NAME": "ชื่นชม", "DISTRICT_ID": 489, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4395, "SUB_DISTRICT_CODE": "441302", "SUB_DISTRICT_NAME": "กุดปลาดุก", "DISTRICT_ID": 489, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4396, "SUB_DISTRICT_CODE": "441303", "SUB_DISTRICT_NAME": "เหล่าดอกไม้", "DISTRICT_ID": 489, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4397, "SUB_DISTRICT_CODE": "441304", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 489, "PROVINCE_ID": 32, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4398, "SUB_DISTRICT_CODE": "450101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4399, "SUB_DISTRICT_CODE": "450102", "SUB_DISTRICT_NAME": "รอบเมือง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4400, "SUB_DISTRICT_CODE": "450103", "SUB_DISTRICT_NAME": "เหนือเมือง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4401, "SUB_DISTRICT_CODE": "450104", "SUB_DISTRICT_NAME": "ขอนแก่น", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4402, "SUB_DISTRICT_CODE": "450105", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4403, "SUB_DISTRICT_CODE": "450106", "SUB_DISTRICT_NAME": "สะอาดสมบูรณ์", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4404, "SUB_DISTRICT_CODE": "450107", "SUB_DISTRICT_NAME": "*ปาฝา", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4405, "SUB_DISTRICT_CODE": "450108", "SUB_DISTRICT_NAME": "สีแก้ว", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4406, "SUB_DISTRICT_CODE": "450109", "SUB_DISTRICT_NAME": "ปอภาร  (ปอพาน)", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4407, "SUB_DISTRICT_CODE": "450110", "SUB_DISTRICT_NAME": "โนนรัง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4408, "SUB_DISTRICT_CODE": "450111", "SUB_DISTRICT_NAME": "*ดงสิงห์", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4409, "SUB_DISTRICT_CODE": "450112", "SUB_DISTRICT_NAME": "*สวนจิก", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4410, "SUB_DISTRICT_CODE": "450113", "SUB_DISTRICT_NAME": "*ม่วงลาด", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4411, "SUB_DISTRICT_CODE": "450114", "SUB_DISTRICT_NAME": "*โพธิ์ทอง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4412, "SUB_DISTRICT_CODE": "450115", "SUB_DISTRICT_NAME": "*จังหาร", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4413, "SUB_DISTRICT_CODE": "450116", "SUB_DISTRICT_NAME": "*ดินดำ", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4414, "SUB_DISTRICT_CODE": "450117", "SUB_DISTRICT_NAME": "หนองแก้ว", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4415, "SUB_DISTRICT_CODE": "450118", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4416, "SUB_DISTRICT_CODE": "450119", "SUB_DISTRICT_NAME": "*ศรีสมเด็จ", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4417, "SUB_DISTRICT_CODE": "450120", "SUB_DISTRICT_NAME": "ดงลาน", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4418, "SUB_DISTRICT_CODE": "450121", "SUB_DISTRICT_NAME": "*หนองใหญ่", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4419, "SUB_DISTRICT_CODE": "450122", "SUB_DISTRICT_NAME": "*เมืองเปลือย", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4420, "SUB_DISTRICT_CODE": "450123", "SUB_DISTRICT_NAME": "แคนใหญ่", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4421, "SUB_DISTRICT_CODE": "450124", "SUB_DISTRICT_NAME": "โนนตาล", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4422, "SUB_DISTRICT_CODE": "450125", "SUB_DISTRICT_NAME": "เมืองทอง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4423, "SUB_DISTRICT_CODE": "450191", "SUB_DISTRICT_NAME": "*ดงสิงห์", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4424, "SUB_DISTRICT_CODE": "450192", "SUB_DISTRICT_NAME": "*จังหาร", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4425, "SUB_DISTRICT_CODE": "450193", "SUB_DISTRICT_NAME": "*ม่วงลาด", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4426, "SUB_DISTRICT_CODE": "450194", "SUB_DISTRICT_NAME": "*ปาฝา", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4427, "SUB_DISTRICT_CODE": "450195", "SUB_DISTRICT_NAME": "*ดินดำ", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4428, "SUB_DISTRICT_CODE": "450196", "SUB_DISTRICT_NAME": "*สวนจิก", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4429, "SUB_DISTRICT_CODE": "450197", "SUB_DISTRICT_NAME": "*เมืองเปลือย", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4430, "SUB_DISTRICT_CODE": "450198", "SUB_DISTRICT_NAME": "*ศรีสมเด็จ", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4431, "SUB_DISTRICT_CODE": "450199", "SUB_DISTRICT_NAME": "*โพธิ์ทอง", "DISTRICT_ID": 491, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4432, "SUB_DISTRICT_CODE": "450201", "SUB_DISTRICT_NAME": "เกษตรวิสัย", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4433, "SUB_DISTRICT_CODE": "450202", "SUB_DISTRICT_NAME": "เมืองบัว", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4434, "SUB_DISTRICT_CODE": "450203", "SUB_DISTRICT_NAME": "เหล่าหลวง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4435, "SUB_DISTRICT_CODE": "450204", "SUB_DISTRICT_NAME": "สิงห์โคก", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4436, "SUB_DISTRICT_CODE": "450205", "SUB_DISTRICT_NAME": "ดงครั่งใหญ่", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4437, "SUB_DISTRICT_CODE": "450206", "SUB_DISTRICT_NAME": "บ้านฝาง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4438, "SUB_DISTRICT_CODE": "450207", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4439, "SUB_DISTRICT_CODE": "450208", "SUB_DISTRICT_NAME": "กำแพง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4440, "SUB_DISTRICT_CODE": "450209", "SUB_DISTRICT_NAME": "กู่กาสิงห์", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4441, "SUB_DISTRICT_CODE": "450210", "SUB_DISTRICT_NAME": "น้ำอ้อม", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4442, "SUB_DISTRICT_CODE": "450211", "SUB_DISTRICT_NAME": "โนนสว่าง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4443, "SUB_DISTRICT_CODE": "450212", "SUB_DISTRICT_NAME": "ทุ่งทอง", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4444, "SUB_DISTRICT_CODE": "450213", "SUB_DISTRICT_NAME": "ดงครั่งน้อย", "DISTRICT_ID": 492, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4445, "SUB_DISTRICT_CODE": "450301", "SUB_DISTRICT_NAME": "บัวแดง", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4446, "SUB_DISTRICT_CODE": "450302", "SUB_DISTRICT_NAME": "ดอกล้ำ", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4447, "SUB_DISTRICT_CODE": "450303", "SUB_DISTRICT_NAME": "หนองแคน", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4448, "SUB_DISTRICT_CODE": "450304", "SUB_DISTRICT_NAME": "โพนสูง", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4449, "SUB_DISTRICT_CODE": "450305", "SUB_DISTRICT_NAME": "โนนสวรรค์", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4450, "SUB_DISTRICT_CODE": "450306", "SUB_DISTRICT_NAME": "สระบัว", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4451, "SUB_DISTRICT_CODE": "450307", "SUB_DISTRICT_NAME": "โนนสง่า", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4452, "SUB_DISTRICT_CODE": "450308", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 493, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4453, "SUB_DISTRICT_CODE": "450401", "SUB_DISTRICT_NAME": "หัวช้าง", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4454, "SUB_DISTRICT_CODE": "450402", "SUB_DISTRICT_NAME": "หนองผือ", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4455, "SUB_DISTRICT_CODE": "450403", "SUB_DISTRICT_NAME": "เมืองหงส์", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4456, "SUB_DISTRICT_CODE": "450404", "SUB_DISTRICT_NAME": "โคกล่าม", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4457, "SUB_DISTRICT_CODE": "450405", "SUB_DISTRICT_NAME": "น้ำใส", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4458, "SUB_DISTRICT_CODE": "450406", "SUB_DISTRICT_NAME": "ดงแดง", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4459, "SUB_DISTRICT_CODE": "450407", "SUB_DISTRICT_NAME": "ดงกลาง", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4460, "SUB_DISTRICT_CODE": "450408", "SUB_DISTRICT_NAME": "ป่าสังข์", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4461, "SUB_DISTRICT_CODE": "450409", "SUB_DISTRICT_NAME": "อีง่อง", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4462, "SUB_DISTRICT_CODE": "450410", "SUB_DISTRICT_NAME": "ลิ้นฟ้า", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4463, "SUB_DISTRICT_CODE": "450411", "SUB_DISTRICT_NAME": "ดู่น้อย", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4464, "SUB_DISTRICT_CODE": "450412", "SUB_DISTRICT_NAME": "ศรีโคตร", "DISTRICT_ID": 494, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4465, "SUB_DISTRICT_CODE": "450501", "SUB_DISTRICT_NAME": "นิเวศน์", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4466, "SUB_DISTRICT_CODE": "450502", "SUB_DISTRICT_NAME": "ธงธานี", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4467, "SUB_DISTRICT_CODE": "450503", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4468, "SUB_DISTRICT_CODE": "450504", "SUB_DISTRICT_NAME": "ธวัชบุรี", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4469, "SUB_DISTRICT_CODE": "450505", "SUB_DISTRICT_NAME": "*หมูม้น", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4470, "SUB_DISTRICT_CODE": "450506", "SUB_DISTRICT_NAME": "อุ่มเม้า", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4471, "SUB_DISTRICT_CODE": "450507", "SUB_DISTRICT_NAME": "มะอึ", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4472, "SUB_DISTRICT_CODE": "450508", "SUB_DISTRICT_NAME": "เหล่า*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4473, "SUB_DISTRICT_CODE": "450509", "SUB_DISTRICT_NAME": "มะบ้า*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4474, "SUB_DISTRICT_CODE": "450510", "SUB_DISTRICT_NAME": "เขวาทุ่ง", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4475, "SUB_DISTRICT_CODE": "450511", "SUB_DISTRICT_NAME": "*พระธาตุ", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4476, "SUB_DISTRICT_CODE": "450512", "SUB_DISTRICT_NAME": "บึงงาม*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4477, "SUB_DISTRICT_CODE": "450513", "SUB_DISTRICT_NAME": "*บ้านเขือง", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4478, "SUB_DISTRICT_CODE": "450514", "SUB_DISTRICT_NAME": "*พระเจ้า", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4479, "SUB_DISTRICT_CODE": "450515", "SUB_DISTRICT_NAME": "ไพศาล", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4480, "SUB_DISTRICT_CODE": "450516", "SUB_DISTRICT_NAME": "เทอดไทย*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4481, "SUB_DISTRICT_CODE": "450517", "SUB_DISTRICT_NAME": "เมืองน้อย", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4482, "SUB_DISTRICT_CODE": "450518", "SUB_DISTRICT_NAME": "โนนศิลา*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4483, "SUB_DISTRICT_CODE": "450519", "SUB_DISTRICT_NAME": "*เชียงขวัญ", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4484, "SUB_DISTRICT_CODE": "450520", "SUB_DISTRICT_NAME": "บึงนคร", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4485, "SUB_DISTRICT_CODE": "450521", "SUB_DISTRICT_NAME": "*พลับพลา", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4486, "SUB_DISTRICT_CODE": "450522", "SUB_DISTRICT_NAME": "ราชธานี", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4487, "SUB_DISTRICT_CODE": "450523", "SUB_DISTRICT_NAME": "ทุ่งเขาหลวง*", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4488, "SUB_DISTRICT_CODE": "450524", "SUB_DISTRICT_NAME": "หนองพอก", "DISTRICT_ID": 495, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4489, "SUB_DISTRICT_CODE": "450601", "SUB_DISTRICT_NAME": "พนมไพร", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4490, "SUB_DISTRICT_CODE": "450602", "SUB_DISTRICT_NAME": "แสนสุข", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4491, "SUB_DISTRICT_CODE": "450603", "SUB_DISTRICT_NAME": "กุดน้ำใส", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4492, "SUB_DISTRICT_CODE": "450604", "SUB_DISTRICT_NAME": "หนองทัพไทย", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4493, "SUB_DISTRICT_CODE": "450605", "SUB_DISTRICT_NAME": "โพธิ์ใหญ่", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4494, "SUB_DISTRICT_CODE": "450606", "SUB_DISTRICT_NAME": "วารีสวัสดิ์", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4495, "SUB_DISTRICT_CODE": "450607", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4496, "SUB_DISTRICT_CODE": "450608", "SUB_DISTRICT_NAME": "*หนองฮี", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4497, "SUB_DISTRICT_CODE": "450609", "SUB_DISTRICT_NAME": "*เด่นราษฎร์", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4498, "SUB_DISTRICT_CODE": "450610", "SUB_DISTRICT_NAME": "*ดูกอึ่ง", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4499, "SUB_DISTRICT_CODE": "450611", "SUB_DISTRICT_NAME": "โพธิ์ชัย", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4500, "SUB_DISTRICT_CODE": "450612", "SUB_DISTRICT_NAME": "นานวล", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4501, "SUB_DISTRICT_CODE": "450613", "SUB_DISTRICT_NAME": "คำไฮ", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4502, "SUB_DISTRICT_CODE": "450614", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4503, "SUB_DISTRICT_CODE": "450615", "SUB_DISTRICT_NAME": "ค้อใหญ่", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4504, "SUB_DISTRICT_CODE": "450616", "SUB_DISTRICT_NAME": "*สาวแห", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4505, "SUB_DISTRICT_CODE": "450617", "SUB_DISTRICT_NAME": "ชานุวรรณ", "DISTRICT_ID": 496, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4506, "SUB_DISTRICT_CODE": "450701", "SUB_DISTRICT_NAME": "แวง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4507, "SUB_DISTRICT_CODE": "450702", "SUB_DISTRICT_NAME": "โคกกกม่วง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4508, "SUB_DISTRICT_CODE": "450703", "SUB_DISTRICT_NAME": "นาอุดม", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4509, "SUB_DISTRICT_CODE": "450704", "SUB_DISTRICT_NAME": "สว่าง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4510, "SUB_DISTRICT_CODE": "450705", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4511, "SUB_DISTRICT_CODE": "450706", "SUB_DISTRICT_NAME": "โพธิ์ทอง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4512, "SUB_DISTRICT_CODE": "450707", "SUB_DISTRICT_NAME": "โนนชัยศรี", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4513, "SUB_DISTRICT_CODE": "450708", "SUB_DISTRICT_NAME": "โพธิ์ศรีสว่าง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4514, "SUB_DISTRICT_CODE": "450709", "SUB_DISTRICT_NAME": "อุ่มเม่า", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4515, "SUB_DISTRICT_CODE": "450710", "SUB_DISTRICT_NAME": "คำนาดี", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4516, "SUB_DISTRICT_CODE": "450711", "SUB_DISTRICT_NAME": "พรมสวรรค์", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4517, "SUB_DISTRICT_CODE": "450712", "SUB_DISTRICT_NAME": "สระนกแก้ว", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4518, "SUB_DISTRICT_CODE": "450713", "SUB_DISTRICT_NAME": "วังสามัคคี", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4519, "SUB_DISTRICT_CODE": "450714", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4520, "SUB_DISTRICT_CODE": "450794", "SUB_DISTRICT_NAME": "*ชุมพร", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4521, "SUB_DISTRICT_CODE": "450795", "SUB_DISTRICT_NAME": "*เมยวดี", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4522, "SUB_DISTRICT_CODE": "450796", "SUB_DISTRICT_NAME": "*คำพอง", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4523, "SUB_DISTRICT_CODE": "450797", "SUB_DISTRICT_NAME": "*อัคคะคำ", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4524, "SUB_DISTRICT_CODE": "450798", "SUB_DISTRICT_NAME": "*เชียงใหม่", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4525, "SUB_DISTRICT_CODE": "450799", "SUB_DISTRICT_NAME": "*ขามเบี้ย", "DISTRICT_ID": 497, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4526, "SUB_DISTRICT_CODE": "450801", "SUB_DISTRICT_NAME": "ขามเปี้ย", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4527, "SUB_DISTRICT_CODE": "450802", "SUB_DISTRICT_NAME": "เชียงใหม่", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4528, "SUB_DISTRICT_CODE": "450803", "SUB_DISTRICT_NAME": "บัวคำ", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4529, "SUB_DISTRICT_CODE": "450804", "SUB_DISTRICT_NAME": "อัคคะคำ", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4530, "SUB_DISTRICT_CODE": "450805", "SUB_DISTRICT_NAME": "สะอาด", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4531, "SUB_DISTRICT_CODE": "450806", "SUB_DISTRICT_NAME": "คำพอุง", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4532, "SUB_DISTRICT_CODE": "450807", "SUB_DISTRICT_NAME": "หนองตาไก้", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4533, "SUB_DISTRICT_CODE": "450808", "SUB_DISTRICT_NAME": "ดอนโอง", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4534, "SUB_DISTRICT_CODE": "450809", "SUB_DISTRICT_NAME": "โพธิ์ศรี", "DISTRICT_ID": 498, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4535, "SUB_DISTRICT_CODE": "450901", "SUB_DISTRICT_NAME": "หนองพอก", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4536, "SUB_DISTRICT_CODE": "450902", "SUB_DISTRICT_NAME": "บึงงาม", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4537, "SUB_DISTRICT_CODE": "450903", "SUB_DISTRICT_NAME": "ภูเขาทอง", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4538, "SUB_DISTRICT_CODE": "450904", "SUB_DISTRICT_NAME": "กกโพธิ์", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4539, "SUB_DISTRICT_CODE": "450905", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4540, "SUB_DISTRICT_CODE": "450906", "SUB_DISTRICT_NAME": "หนองขุ่นใหญ่", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4541, "SUB_DISTRICT_CODE": "450907", "SUB_DISTRICT_NAME": "รอบเมือง", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4542, "SUB_DISTRICT_CODE": "450908", "SUB_DISTRICT_NAME": "ผาน้ำย้อย", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4543, "SUB_DISTRICT_CODE": "450909", "SUB_DISTRICT_NAME": "ท่าสีดา", "DISTRICT_ID": 499, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4544, "SUB_DISTRICT_CODE": "451001", "SUB_DISTRICT_NAME": "กลาง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4545, "SUB_DISTRICT_CODE": "451002", "SUB_DISTRICT_NAME": "นางาม", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4546, "SUB_DISTRICT_CODE": "451003", "SUB_DISTRICT_NAME": "เมืองไพร", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4547, "SUB_DISTRICT_CODE": "451004", "SUB_DISTRICT_NAME": "นาแซง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4548, "SUB_DISTRICT_CODE": "451005", "SUB_DISTRICT_NAME": "นาเมือง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4549, "SUB_DISTRICT_CODE": "451006", "SUB_DISTRICT_NAME": "วังหลวง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4550, "SUB_DISTRICT_CODE": "451007", "SUB_DISTRICT_NAME": "ท่าม่วง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4551, "SUB_DISTRICT_CODE": "451008", "SUB_DISTRICT_NAME": "ขวาว", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4552, "SUB_DISTRICT_CODE": "451009", "SUB_DISTRICT_NAME": "โพธิ์ทอง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4553, "SUB_DISTRICT_CODE": "451010", "SUB_DISTRICT_NAME": "ภูเงิน", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4554, "SUB_DISTRICT_CODE": "451011", "SUB_DISTRICT_NAME": "เกาะแก้ว", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4555, "SUB_DISTRICT_CODE": "451012", "SUB_DISTRICT_NAME": "นาเลิง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4556, "SUB_DISTRICT_CODE": "451013", "SUB_DISTRICT_NAME": "เหล่าน้อย", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4557, "SUB_DISTRICT_CODE": "451014", "SUB_DISTRICT_NAME": "ศรีวิลัย", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4558, "SUB_DISTRICT_CODE": "451015", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4559, "SUB_DISTRICT_CODE": "451016", "SUB_DISTRICT_NAME": "พรสวรรค์", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4560, "SUB_DISTRICT_CODE": "451017", "SUB_DISTRICT_NAME": "ขวัญเมือง", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4561, "SUB_DISTRICT_CODE": "451018", "SUB_DISTRICT_NAME": "บึงเกลือ", "DISTRICT_ID": 500, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4562, "SUB_DISTRICT_CODE": "451101", "SUB_DISTRICT_NAME": "สระคู", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4563, "SUB_DISTRICT_CODE": "451102", "SUB_DISTRICT_NAME": "ดอกไม้", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4564, "SUB_DISTRICT_CODE": "451103", "SUB_DISTRICT_NAME": "นาใหญ่", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4565, "SUB_DISTRICT_CODE": "451104", "SUB_DISTRICT_NAME": "หินกอง", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4566, "SUB_DISTRICT_CODE": "451105", "SUB_DISTRICT_NAME": "เมืองทุ่ง", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4567, "SUB_DISTRICT_CODE": "451106", "SUB_DISTRICT_NAME": "หัวโทน", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4568, "SUB_DISTRICT_CODE": "451107", "SUB_DISTRICT_NAME": "บ่อพันขัน", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4569, "SUB_DISTRICT_CODE": "451108", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4570, "SUB_DISTRICT_CODE": "451109", "SUB_DISTRICT_NAME": "หัวช้าง", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4571, "SUB_DISTRICT_CODE": "451110", "SUB_DISTRICT_NAME": "น้ำคำ", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4572, "SUB_DISTRICT_CODE": "451111", "SUB_DISTRICT_NAME": "ห้วยหินลาด", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4573, "SUB_DISTRICT_CODE": "451112", "SUB_DISTRICT_NAME": "ช้างเผือก", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4574, "SUB_DISTRICT_CODE": "451113", "SUB_DISTRICT_NAME": "ทุ่งกุลา", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4575, "SUB_DISTRICT_CODE": "451114", "SUB_DISTRICT_NAME": "ทุ่งศรีเมือง", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4576, "SUB_DISTRICT_CODE": "451115", "SUB_DISTRICT_NAME": "จำปาขัน", "DISTRICT_ID": 501, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4577, "SUB_DISTRICT_CODE": "451201", "SUB_DISTRICT_NAME": "หนองผือ", "DISTRICT_ID": 502, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4578, "SUB_DISTRICT_CODE": "451202", "SUB_DISTRICT_NAME": "หนองหิน", "DISTRICT_ID": 502, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4579, "SUB_DISTRICT_CODE": "451203", "SUB_DISTRICT_NAME": "คูเมือง", "DISTRICT_ID": 502, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4580, "SUB_DISTRICT_CODE": "451204", "SUB_DISTRICT_NAME": "กกกุง", "DISTRICT_ID": 502, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4581, "SUB_DISTRICT_CODE": "451205", "SUB_DISTRICT_NAME": "เมืองสรวง", "DISTRICT_ID": 502, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4582, "SUB_DISTRICT_CODE": "451301", "SUB_DISTRICT_NAME": "โพนทราย", "DISTRICT_ID": 503, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4583, "SUB_DISTRICT_CODE": "451302", "SUB_DISTRICT_NAME": "สามขา", "DISTRICT_ID": 503, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4584, "SUB_DISTRICT_CODE": "451303", "SUB_DISTRICT_NAME": "ศรีสว่าง", "DISTRICT_ID": 503, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4585, "SUB_DISTRICT_CODE": "451304", "SUB_DISTRICT_NAME": "ยางคำ", "DISTRICT_ID": 503, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4586, "SUB_DISTRICT_CODE": "451305", "SUB_DISTRICT_NAME": "ท่าหาดยาว", "DISTRICT_ID": 503, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4587, "SUB_DISTRICT_CODE": "451401", "SUB_DISTRICT_NAME": "อาจสามารถ", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4588, "SUB_DISTRICT_CODE": "451402", "SUB_DISTRICT_NAME": "โพนเมือง", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4589, "SUB_DISTRICT_CODE": "451403", "SUB_DISTRICT_NAME": "บ้านแจ้ง", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4590, "SUB_DISTRICT_CODE": "451404", "SUB_DISTRICT_NAME": "หน่อม", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4591, "SUB_DISTRICT_CODE": "451405", "SUB_DISTRICT_NAME": "หนองหมื่นถ่าน", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4592, "SUB_DISTRICT_CODE": "451406", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4593, "SUB_DISTRICT_CODE": "451407", "SUB_DISTRICT_NAME": "โหรา", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4594, "SUB_DISTRICT_CODE": "451408", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4595, "SUB_DISTRICT_CODE": "451409", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4596, "SUB_DISTRICT_CODE": "451410", "SUB_DISTRICT_NAME": "บ้านดู่", "DISTRICT_ID": 504, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4597, "SUB_DISTRICT_CODE": "451501", "SUB_DISTRICT_NAME": "เมยวดี", "DISTRICT_ID": 505, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4598, "SUB_DISTRICT_CODE": "451502", "SUB_DISTRICT_NAME": "ชุมพร", "DISTRICT_ID": 505, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4599, "SUB_DISTRICT_CODE": "451503", "SUB_DISTRICT_NAME": "บุ่งเลิศ", "DISTRICT_ID": 505, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4600, "SUB_DISTRICT_CODE": "451504", "SUB_DISTRICT_NAME": "ชมสะอาด", "DISTRICT_ID": 505, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4601, "SUB_DISTRICT_CODE": "451601", "SUB_DISTRICT_NAME": "โพธิ์ทอง", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4602, "SUB_DISTRICT_CODE": "451602", "SUB_DISTRICT_NAME": "ศรีสมเด็จ", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4603, "SUB_DISTRICT_CODE": "451603", "SUB_DISTRICT_NAME": "เมืองเปลือย", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4604, "SUB_DISTRICT_CODE": "451604", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4605, "SUB_DISTRICT_CODE": "451605", "SUB_DISTRICT_NAME": "สวนจิก", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4606, "SUB_DISTRICT_CODE": "451606", "SUB_DISTRICT_NAME": "โพธิ์สัย", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4607, "SUB_DISTRICT_CODE": "451607", "SUB_DISTRICT_NAME": "หนองแวงควง", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4608, "SUB_DISTRICT_CODE": "451608", "SUB_DISTRICT_NAME": "บ้านบาก", "DISTRICT_ID": 506, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4609, "SUB_DISTRICT_CODE": "451701", "SUB_DISTRICT_NAME": "ดินดำ", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4610, "SUB_DISTRICT_CODE": "451702", "SUB_DISTRICT_NAME": "ปาฝา", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4611, "SUB_DISTRICT_CODE": "451703", "SUB_DISTRICT_NAME": "ม่วงลาด", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4612, "SUB_DISTRICT_CODE": "451704", "SUB_DISTRICT_NAME": "จังหาร", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4613, "SUB_DISTRICT_CODE": "451705", "SUB_DISTRICT_NAME": "ดงสิงห์", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4614, "SUB_DISTRICT_CODE": "451706", "SUB_DISTRICT_NAME": "ยางใหญ่", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4615, "SUB_DISTRICT_CODE": "451707", "SUB_DISTRICT_NAME": "ผักแว่น", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4616, "SUB_DISTRICT_CODE": "451708", "SUB_DISTRICT_NAME": "แสนชาติ", "DISTRICT_ID": 507, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4617, "SUB_DISTRICT_CODE": "451801", "SUB_DISTRICT_NAME": "เชียงขวัญ", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4618, "SUB_DISTRICT_CODE": "451802", "SUB_DISTRICT_NAME": "พลับพลา", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4619, "SUB_DISTRICT_CODE": "451803", "SUB_DISTRICT_NAME": "พระธาตุ", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4620, "SUB_DISTRICT_CODE": "451804", "SUB_DISTRICT_NAME": "พระเจ้า", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4621, "SUB_DISTRICT_CODE": "451805", "SUB_DISTRICT_NAME": "หมูม้น", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4622, "SUB_DISTRICT_CODE": "451806", "SUB_DISTRICT_NAME": "บ้านเขือง", "DISTRICT_ID": 508, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4623, "SUB_DISTRICT_CODE": "451901", "SUB_DISTRICT_NAME": "หนองฮี", "DISTRICT_ID": 509, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4624, "SUB_DISTRICT_CODE": "451902", "SUB_DISTRICT_NAME": "สาวแห", "DISTRICT_ID": 509, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4625, "SUB_DISTRICT_CODE": "451903", "SUB_DISTRICT_NAME": "ดูกอึ่ง", "DISTRICT_ID": 509, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4626, "SUB_DISTRICT_CODE": "451904", "SUB_DISTRICT_NAME": "เด่นราษฎร์", "DISTRICT_ID": 509, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4627, "SUB_DISTRICT_CODE": "452001", "SUB_DISTRICT_NAME": "ทุ่งเขาหลวง", "DISTRICT_ID": 510, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4628, "SUB_DISTRICT_CODE": "452002", "SUB_DISTRICT_NAME": "เทอดไทย", "DISTRICT_ID": 510, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4629, "SUB_DISTRICT_CODE": "452003", "SUB_DISTRICT_NAME": "บึงงาม", "DISTRICT_ID": 510, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4630, "SUB_DISTRICT_CODE": "452004", "SUB_DISTRICT_NAME": "มะบ้า", "DISTRICT_ID": 510, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4631, "SUB_DISTRICT_CODE": "452005", "SUB_DISTRICT_NAME": "เหล่า", "DISTRICT_ID": 510, "PROVINCE_ID": 33, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4632, "SUB_DISTRICT_CODE": "460101", "SUB_DISTRICT_NAME": "กาฬสินธุ์", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4633, "SUB_DISTRICT_CODE": "460102", "SUB_DISTRICT_NAME": "เหนือ", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4634, "SUB_DISTRICT_CODE": "460103", "SUB_DISTRICT_NAME": "หลุบ", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4635, "SUB_DISTRICT_CODE": "460104", "SUB_DISTRICT_NAME": "ไผ่", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4636, "SUB_DISTRICT_CODE": "460105", "SUB_DISTRICT_NAME": "ลำปาว", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4637, "SUB_DISTRICT_CODE": "460106", "SUB_DISTRICT_NAME": "ลำพาน", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4638, "SUB_DISTRICT_CODE": "460107", "SUB_DISTRICT_NAME": "เชียงเครือ", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4639, "SUB_DISTRICT_CODE": "460108", "SUB_DISTRICT_NAME": "บึงวิชัย", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4640, "SUB_DISTRICT_CODE": "460109", "SUB_DISTRICT_NAME": "ห้วยโพธิ์", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4641, "SUB_DISTRICT_CODE": "460110", "SUB_DISTRICT_NAME": "ม่วงนา*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4642, "SUB_DISTRICT_CODE": "460111", "SUB_DISTRICT_NAME": "ภูปอ", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4643, "SUB_DISTRICT_CODE": "460112", "SUB_DISTRICT_NAME": "ดงพยุง*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4644, "SUB_DISTRICT_CODE": "460113", "SUB_DISTRICT_NAME": "ภูดิน", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4645, "SUB_DISTRICT_CODE": "460114", "SUB_DISTRICT_NAME": "ดอนจาน*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4646, "SUB_DISTRICT_CODE": "460115", "SUB_DISTRICT_NAME": "หนองกุง", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4647, "SUB_DISTRICT_CODE": "460116", "SUB_DISTRICT_NAME": "กลางหมื่น", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4648, "SUB_DISTRICT_CODE": "460117", "SUB_DISTRICT_NAME": "ขมิ้น", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4649, "SUB_DISTRICT_CODE": "460118", "SUB_DISTRICT_NAME": "นาจำปา*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4650, "SUB_DISTRICT_CODE": "460119", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4651, "SUB_DISTRICT_CODE": "460120", "SUB_DISTRICT_NAME": "นาจารย์", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4652, "SUB_DISTRICT_CODE": "460121", "SUB_DISTRICT_NAME": "ลำคลอง", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4653, "SUB_DISTRICT_CODE": "460122", "SUB_DISTRICT_NAME": "สะอาดไชยศรี*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4654, "SUB_DISTRICT_CODE": "460198", "SUB_DISTRICT_NAME": "นามน*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4655, "SUB_DISTRICT_CODE": "460199", "SUB_DISTRICT_NAME": "ยอดแกง*", "DISTRICT_ID": 511, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4656, "SUB_DISTRICT_CODE": "460201", "SUB_DISTRICT_NAME": "นามน", "DISTRICT_ID": 512, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4657, "SUB_DISTRICT_CODE": "460202", "SUB_DISTRICT_NAME": "ยอดแกง", "DISTRICT_ID": 512, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4658, "SUB_DISTRICT_CODE": "460203", "SUB_DISTRICT_NAME": "สงเปลือย", "DISTRICT_ID": 512, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4659, "SUB_DISTRICT_CODE": "460204", "SUB_DISTRICT_NAME": "หลักเหลี่ยม", "DISTRICT_ID": 512, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4660, "SUB_DISTRICT_CODE": "460205", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 512, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4661, "SUB_DISTRICT_CODE": "460301", "SUB_DISTRICT_NAME": "กมลาไสย", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4662, "SUB_DISTRICT_CODE": "460302", "SUB_DISTRICT_NAME": "หลักเมือง", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4663, "SUB_DISTRICT_CODE": "460303", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4664, "SUB_DISTRICT_CODE": "460304", "SUB_DISTRICT_NAME": "ดงลิง", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4665, "SUB_DISTRICT_CODE": "460305", "SUB_DISTRICT_NAME": "ธัญญา", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4666, "SUB_DISTRICT_CODE": "460306", "SUB_DISTRICT_NAME": "กุดฆ้องชัย*", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4667, "SUB_DISTRICT_CODE": "460307", "SUB_DISTRICT_NAME": "ลำชี*", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4668, "SUB_DISTRICT_CODE": "460308", "SUB_DISTRICT_NAME": "หนองแปน", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4669, "SUB_DISTRICT_CODE": "460309", "SUB_DISTRICT_NAME": "โคกสะอาด*", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4670, "SUB_DISTRICT_CODE": "460310", "SUB_DISTRICT_NAME": "เจ้าท่า", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4671, "SUB_DISTRICT_CODE": "460311", "SUB_DISTRICT_NAME": "โคกสมบูรณ์", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4672, "SUB_DISTRICT_CODE": "460312", "SUB_DISTRICT_NAME": "โนนศิลา*", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4673, "SUB_DISTRICT_CODE": "460313", "SUB_DISTRICT_NAME": "ฆ้องชัยพัฒนา*", "DISTRICT_ID": 513, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4674, "SUB_DISTRICT_CODE": "460401", "SUB_DISTRICT_NAME": "ร่องคำ", "DISTRICT_ID": 514, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4675, "SUB_DISTRICT_CODE": "460402", "SUB_DISTRICT_NAME": "สามัคคี", "DISTRICT_ID": 514, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4676, "SUB_DISTRICT_CODE": "460403", "SUB_DISTRICT_NAME": "เหล่าอ้อย", "DISTRICT_ID": 514, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4677, "SUB_DISTRICT_CODE": "460501", "SUB_DISTRICT_NAME": "บัวขาว", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4678, "SUB_DISTRICT_CODE": "460502", "SUB_DISTRICT_NAME": "แจนแลน", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4679, "SUB_DISTRICT_CODE": "460503", "SUB_DISTRICT_NAME": "เหล่าใหญ่", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4680, "SUB_DISTRICT_CODE": "460504", "SUB_DISTRICT_NAME": "จุมจัง", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4681, "SUB_DISTRICT_CODE": "460505", "SUB_DISTRICT_NAME": "เหล่าไฮงาม", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4682, "SUB_DISTRICT_CODE": "460506", "SUB_DISTRICT_NAME": "กุดหว้า", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4683, "SUB_DISTRICT_CODE": "460507", "SUB_DISTRICT_NAME": "สามขา", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4684, "SUB_DISTRICT_CODE": "460508", "SUB_DISTRICT_NAME": "นาขาม", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4685, "SUB_DISTRICT_CODE": "460509", "SUB_DISTRICT_NAME": "หนองห้าง", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4686, "SUB_DISTRICT_CODE": "460510", "SUB_DISTRICT_NAME": "นาโก", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4687, "SUB_DISTRICT_CODE": "460511", "SUB_DISTRICT_NAME": "สมสะอาด", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4688, "SUB_DISTRICT_CODE": "460512", "SUB_DISTRICT_NAME": "กุดค้าว", "DISTRICT_ID": 515, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4689, "SUB_DISTRICT_CODE": "460601", "SUB_DISTRICT_NAME": "คุ้มเก่า", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4690, "SUB_DISTRICT_CODE": "460602", "SUB_DISTRICT_NAME": "สงเปลือย", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4691, "SUB_DISTRICT_CODE": "460603", "SUB_DISTRICT_NAME": "หนองผือ", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4692, "SUB_DISTRICT_CODE": "460604", "SUB_DISTRICT_NAME": "*ภูแล่นช้าง", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4693, "SUB_DISTRICT_CODE": "460605", "SUB_DISTRICT_NAME": "*นาคู", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4694, "SUB_DISTRICT_CODE": "460606", "SUB_DISTRICT_NAME": "กุดสิมคุ้มใหม่", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4695, "SUB_DISTRICT_CODE": "460607", "SUB_DISTRICT_NAME": "*บ่อแก้ว", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4696, "SUB_DISTRICT_CODE": "460608", "SUB_DISTRICT_NAME": "สระพังทอง", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4697, "SUB_DISTRICT_CODE": "460609", "SUB_DISTRICT_NAME": "*สายนาวัง", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4698, "SUB_DISTRICT_CODE": "460610", "SUB_DISTRICT_NAME": "*โนนนาจาน", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4699, "SUB_DISTRICT_CODE": "460611", "SUB_DISTRICT_NAME": "กุดปลาค้าว", "DISTRICT_ID": 516, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4700, "SUB_DISTRICT_CODE": "460701", "SUB_DISTRICT_NAME": "ยางตลาด", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4701, "SUB_DISTRICT_CODE": "460702", "SUB_DISTRICT_NAME": "หัวงัว", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4702, "SUB_DISTRICT_CODE": "460703", "SUB_DISTRICT_NAME": "อุ่มเม่า", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4703, "SUB_DISTRICT_CODE": "460704", "SUB_DISTRICT_NAME": "บัวบาน", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4704, "SUB_DISTRICT_CODE": "460705", "SUB_DISTRICT_NAME": "เว่อ", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4705, "SUB_DISTRICT_CODE": "460706", "SUB_DISTRICT_NAME": "อิตื้อ", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4706, "SUB_DISTRICT_CODE": "460707", "SUB_DISTRICT_NAME": "หัวนาคำ", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4707, "SUB_DISTRICT_CODE": "460708", "SUB_DISTRICT_NAME": "หนองอิเฒ่า", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4708, "SUB_DISTRICT_CODE": "460709", "SUB_DISTRICT_NAME": "ดอนสมบูรณ์", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4709, "SUB_DISTRICT_CODE": "460710", "SUB_DISTRICT_NAME": "นาเชือก", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4710, "SUB_DISTRICT_CODE": "460711", "SUB_DISTRICT_NAME": "คลองขาม", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4711, "SUB_DISTRICT_CODE": "460712", "SUB_DISTRICT_NAME": "เขาพระนอน", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4712, "SUB_DISTRICT_CODE": "460713", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4713, "SUB_DISTRICT_CODE": "460714", "SUB_DISTRICT_NAME": "โนนสูง", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4714, "SUB_DISTRICT_CODE": "460715", "SUB_DISTRICT_NAME": "หนองตอกแป้น", "DISTRICT_ID": 517, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4715, "SUB_DISTRICT_CODE": "460801", "SUB_DISTRICT_NAME": "ห้วยเม็ก", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4716, "SUB_DISTRICT_CODE": "460802", "SUB_DISTRICT_NAME": "คำใหญ่", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4717, "SUB_DISTRICT_CODE": "460803", "SUB_DISTRICT_NAME": "กุดโดน", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4718, "SUB_DISTRICT_CODE": "460804", "SUB_DISTRICT_NAME": "บึงนาเรียง", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4719, "SUB_DISTRICT_CODE": "460805", "SUB_DISTRICT_NAME": "หัวหิน", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4720, "SUB_DISTRICT_CODE": "460806", "SUB_DISTRICT_NAME": "พิมูล", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4721, "SUB_DISTRICT_CODE": "460807", "SUB_DISTRICT_NAME": "คำเหมือดแก้ว", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4722, "SUB_DISTRICT_CODE": "460808", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4723, "SUB_DISTRICT_CODE": "460809", "SUB_DISTRICT_NAME": "ทรายทอง", "DISTRICT_ID": 518, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4724, "SUB_DISTRICT_CODE": "460901", "SUB_DISTRICT_NAME": "ภูสิงห์", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4725, "SUB_DISTRICT_CODE": "460902", "SUB_DISTRICT_NAME": "สหัสขันธ์", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4726, "SUB_DISTRICT_CODE": "460903", "SUB_DISTRICT_NAME": "นามะเขือ", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4727, "SUB_DISTRICT_CODE": "460904", "SUB_DISTRICT_NAME": "โนนศิลา", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4728, "SUB_DISTRICT_CODE": "460905", "SUB_DISTRICT_NAME": "นิคม", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4729, "SUB_DISTRICT_CODE": "460906", "SUB_DISTRICT_NAME": "โนนแหลมทอง", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4730, "SUB_DISTRICT_CODE": "460907", "SUB_DISTRICT_NAME": "โนนบุรี", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4731, "SUB_DISTRICT_CODE": "460908", "SUB_DISTRICT_NAME": "โนนน้ำเกลี้ยง", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4732, "SUB_DISTRICT_CODE": "460996", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4733, "SUB_DISTRICT_CODE": "460997", "SUB_DISTRICT_NAME": "*ทุ่งคลอง", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4734, "SUB_DISTRICT_CODE": "460998", "SUB_DISTRICT_NAME": "*สำราญ", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4735, "SUB_DISTRICT_CODE": "460999", "SUB_DISTRICT_NAME": "*โพน", "DISTRICT_ID": 519, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4736, "SUB_DISTRICT_CODE": "461001", "SUB_DISTRICT_NAME": "ทุ่งคลอง", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4737, "SUB_DISTRICT_CODE": "461002", "SUB_DISTRICT_NAME": "โพน", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4738, "SUB_DISTRICT_CODE": "461003", "SUB_DISTRICT_NAME": "*สำราญ", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4739, "SUB_DISTRICT_CODE": "461004", "SUB_DISTRICT_NAME": "*สำราญใต้", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4740, "SUB_DISTRICT_CODE": "461005", "SUB_DISTRICT_NAME": "ดินจี่", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4741, "SUB_DISTRICT_CODE": "461006", "SUB_DISTRICT_NAME": "นาบอน", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4742, "SUB_DISTRICT_CODE": "461007", "SUB_DISTRICT_NAME": "นาทัน", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4743, "SUB_DISTRICT_CODE": "461008", "SUB_DISTRICT_NAME": "*คำสร้างเที่ยง", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4744, "SUB_DISTRICT_CODE": "461009", "SUB_DISTRICT_NAME": "เนินยาง", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4745, "SUB_DISTRICT_CODE": "461010", "SUB_DISTRICT_NAME": "*หนองช้าง", "DISTRICT_ID": 520, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4746, "SUB_DISTRICT_CODE": "461101", "SUB_DISTRICT_NAME": "ท่าคันโท", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4747, "SUB_DISTRICT_CODE": "461102", "SUB_DISTRICT_NAME": "กุงเก่า", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4748, "SUB_DISTRICT_CODE": "461103", "SUB_DISTRICT_NAME": "ยางอู้ม", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4749, "SUB_DISTRICT_CODE": "461104", "SUB_DISTRICT_NAME": "กุดจิก", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4750, "SUB_DISTRICT_CODE": "461105", "SUB_DISTRICT_NAME": "นาตาล", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4751, "SUB_DISTRICT_CODE": "461106", "SUB_DISTRICT_NAME": "ดงสมบูรณ์", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4752, "SUB_DISTRICT_CODE": "461198", "SUB_DISTRICT_NAME": "*โคกเครือ", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4753, "SUB_DISTRICT_CODE": "461199", "SUB_DISTRICT_NAME": "*สหัสขันธ์", "DISTRICT_ID": 521, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4754, "SUB_DISTRICT_CODE": "461201", "SUB_DISTRICT_NAME": "หนองกุงศรี", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4755, "SUB_DISTRICT_CODE": "461202", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4756, "SUB_DISTRICT_CODE": "461203", "SUB_DISTRICT_NAME": "โคกเครือ", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4757, "SUB_DISTRICT_CODE": "461204", "SUB_DISTRICT_NAME": "หนองสรวง", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4758, "SUB_DISTRICT_CODE": "461205", "SUB_DISTRICT_NAME": "เสาเล้า", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4759, "SUB_DISTRICT_CODE": "461206", "SUB_DISTRICT_NAME": "หนองใหญ่", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4760, "SUB_DISTRICT_CODE": "461207", "SUB_DISTRICT_NAME": "ดงมูล", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4761, "SUB_DISTRICT_CODE": "461208", "SUB_DISTRICT_NAME": "ลำหนองแสน", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4762, "SUB_DISTRICT_CODE": "461209", "SUB_DISTRICT_NAME": "หนองหิน", "DISTRICT_ID": 522, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4763, "SUB_DISTRICT_CODE": "461301", "SUB_DISTRICT_NAME": "สมเด็จ", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4764, "SUB_DISTRICT_CODE": "461302", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4765, "SUB_DISTRICT_CODE": "461303", "SUB_DISTRICT_NAME": "แซงบาดาล", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4766, "SUB_DISTRICT_CODE": "461304", "SUB_DISTRICT_NAME": "มหาไชย", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4767, "SUB_DISTRICT_CODE": "461305", "SUB_DISTRICT_NAME": "หมูม่น", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4768, "SUB_DISTRICT_CODE": "461306", "SUB_DISTRICT_NAME": "ผาเสวย", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4769, "SUB_DISTRICT_CODE": "461307", "SUB_DISTRICT_NAME": "ศรีสมเด็จ", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4770, "SUB_DISTRICT_CODE": "461308", "SUB_DISTRICT_NAME": "ลำห้วยหลัว", "DISTRICT_ID": 523, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4771, "SUB_DISTRICT_CODE": "461401", "SUB_DISTRICT_NAME": "คำบง", "DISTRICT_ID": 524, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4772, "SUB_DISTRICT_CODE": "461402", "SUB_DISTRICT_NAME": "ไค้นุ่น", "DISTRICT_ID": 524, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4773, "SUB_DISTRICT_CODE": "461403", "SUB_DISTRICT_NAME": "นิคมห้วยผึ้ง", "DISTRICT_ID": 524, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4774, "SUB_DISTRICT_CODE": "461404", "SUB_DISTRICT_NAME": "หนองอีบุตร", "DISTRICT_ID": 524, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4775, "SUB_DISTRICT_CODE": "461501", "SUB_DISTRICT_NAME": "สำราญ", "DISTRICT_ID": 525, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4776, "SUB_DISTRICT_CODE": "461502", "SUB_DISTRICT_NAME": "สำราญใต้", "DISTRICT_ID": 525, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4777, "SUB_DISTRICT_CODE": "461503", "SUB_DISTRICT_NAME": "คำสร้างเที่ยง", "DISTRICT_ID": 525, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4778, "SUB_DISTRICT_CODE": "461504", "SUB_DISTRICT_NAME": "หนองช้าง", "DISTRICT_ID": 525, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4779, "SUB_DISTRICT_CODE": "461601", "SUB_DISTRICT_NAME": "นาคู", "DISTRICT_ID": 526, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4780, "SUB_DISTRICT_CODE": "461602", "SUB_DISTRICT_NAME": "สายนาวัง", "DISTRICT_ID": 526, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4781, "SUB_DISTRICT_CODE": "461603", "SUB_DISTRICT_NAME": "โนนนาจาน", "DISTRICT_ID": 526, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4782, "SUB_DISTRICT_CODE": "461604", "SUB_DISTRICT_NAME": "บ่อแก้ว", "DISTRICT_ID": 526, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4783, "SUB_DISTRICT_CODE": "461605", "SUB_DISTRICT_NAME": "ภูแล่นช้าง", "DISTRICT_ID": 526, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4784, "SUB_DISTRICT_CODE": "461701", "SUB_DISTRICT_NAME": "ดอนจาน", "DISTRICT_ID": 527, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4785, "SUB_DISTRICT_CODE": "461702", "SUB_DISTRICT_NAME": "สะอาดไชยศรี", "DISTRICT_ID": 527, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4786, "SUB_DISTRICT_CODE": "461703", "SUB_DISTRICT_NAME": "ดงพยุง", "DISTRICT_ID": 527, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4787, "SUB_DISTRICT_CODE": "461704", "SUB_DISTRICT_NAME": "ม่วงนา", "DISTRICT_ID": 527, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4788, "SUB_DISTRICT_CODE": "461705", "SUB_DISTRICT_NAME": "นาจำปา", "DISTRICT_ID": 527, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4789, "SUB_DISTRICT_CODE": "461801", "SUB_DISTRICT_NAME": "ฆ้องชัยพัฒนา", "DISTRICT_ID": 528, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4790, "SUB_DISTRICT_CODE": "461802", "SUB_DISTRICT_NAME": "เหล่ากลาง", "DISTRICT_ID": 528, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4791, "SUB_DISTRICT_CODE": "461803", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 528, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4792, "SUB_DISTRICT_CODE": "461804", "SUB_DISTRICT_NAME": "โนนศิลาเลิง", "DISTRICT_ID": 528, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4793, "SUB_DISTRICT_CODE": "461805", "SUB_DISTRICT_NAME": "ลำชี", "DISTRICT_ID": 528, "PROVINCE_ID": 34, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4794, "SUB_DISTRICT_CODE": "470101", "SUB_DISTRICT_NAME": "ธาตุเชิงชุม", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4795, "SUB_DISTRICT_CODE": "470102", "SUB_DISTRICT_NAME": "ขมิ้น", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4796, "SUB_DISTRICT_CODE": "470103", "SUB_DISTRICT_NAME": "งิ้วด่อน", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4797, "SUB_DISTRICT_CODE": "470104", "SUB_DISTRICT_NAME": "โนนหอม", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4798, "SUB_DISTRICT_CODE": "470105", "SUB_DISTRICT_NAME": "*นาตงวัฒนา", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4799, "SUB_DISTRICT_CODE": "470106", "SUB_DISTRICT_NAME": "เชียงเครือ", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4800, "SUB_DISTRICT_CODE": "470107", "SUB_DISTRICT_NAME": "ท่าแร่", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4801, "SUB_DISTRICT_CODE": "470108", "SUB_DISTRICT_NAME": "*บ้านโพน", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4802, "SUB_DISTRICT_CODE": "470109", "SUB_DISTRICT_NAME": "ม่วงลาย", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4803, "SUB_DISTRICT_CODE": "470110", "SUB_DISTRICT_NAME": "กกปลาซิว*", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4804, "SUB_DISTRICT_CODE": "470111", "SUB_DISTRICT_NAME": "ดงชน", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4805, "SUB_DISTRICT_CODE": "470112", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4806, "SUB_DISTRICT_CODE": "470113", "SUB_DISTRICT_NAME": "พังขว้าง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4807, "SUB_DISTRICT_CODE": "470114", "SUB_DISTRICT_NAME": "*นาแก้ว", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4808, "SUB_DISTRICT_CODE": "470115", "SUB_DISTRICT_NAME": "ดงมะไฟ", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4809, "SUB_DISTRICT_CODE": "470116", "SUB_DISTRICT_NAME": "ธาตุนาเวง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4810, "SUB_DISTRICT_CODE": "470117", "SUB_DISTRICT_NAME": "เหล่าปอแดง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4811, "SUB_DISTRICT_CODE": "470118", "SUB_DISTRICT_NAME": "หนองลาด", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4812, "SUB_DISTRICT_CODE": "470119", "SUB_DISTRICT_NAME": "*บ้านแป้น", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4813, "SUB_DISTRICT_CODE": "470120", "SUB_DISTRICT_NAME": "ฮางโฮง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4814, "SUB_DISTRICT_CODE": "470121", "SUB_DISTRICT_NAME": "โคกก่อง", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4815, "SUB_DISTRICT_CODE": "470194", "SUB_DISTRICT_NAME": "*นาตงวัฒนา", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4816, "SUB_DISTRICT_CODE": "470195", "SUB_DISTRICT_NAME": "*นาแก้ว", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4817, "SUB_DISTRICT_CODE": "470196", "SUB_DISTRICT_NAME": "*บ้านโพน", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4818, "SUB_DISTRICT_CODE": "470197", "SUB_DISTRICT_NAME": "*เหล่าโพนค้อ", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4819, "SUB_DISTRICT_CODE": "470198", "SUB_DISTRICT_NAME": "*ตองโขบ", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4820, "SUB_DISTRICT_CODE": "470199", "SUB_DISTRICT_NAME": "*เต่างอย", "DISTRICT_ID": 529, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4821, "SUB_DISTRICT_CODE": "470201", "SUB_DISTRICT_NAME": "กุสุมาลย์", "DISTRICT_ID": 530, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4822, "SUB_DISTRICT_CODE": "470202", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 530, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4823, "SUB_DISTRICT_CODE": "470203", "SUB_DISTRICT_NAME": "นาเพียง", "DISTRICT_ID": 530, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4824, "SUB_DISTRICT_CODE": "470204", "SUB_DISTRICT_NAME": "โพธิไพศาล", "DISTRICT_ID": 530, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4825, "SUB_DISTRICT_CODE": "470205", "SUB_DISTRICT_NAME": "อุ่มจาน", "DISTRICT_ID": 530, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4826, "SUB_DISTRICT_CODE": "470301", "SUB_DISTRICT_NAME": "กุดบาก", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4827, "SUB_DISTRICT_CODE": "470302", "SUB_DISTRICT_NAME": "*โคกภู", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4828, "SUB_DISTRICT_CODE": "470303", "SUB_DISTRICT_NAME": "นาม่อง", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4829, "SUB_DISTRICT_CODE": "470304", "SUB_DISTRICT_NAME": "*สร้างค้อ", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4830, "SUB_DISTRICT_CODE": "470305", "SUB_DISTRICT_NAME": "กุดไห", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4831, "SUB_DISTRICT_CODE": "470306", "SUB_DISTRICT_NAME": "*หลุบเลา", "DISTRICT_ID": 531, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4832, "SUB_DISTRICT_CODE": "470401", "SUB_DISTRICT_NAME": "พรรณา", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4833, "SUB_DISTRICT_CODE": "470402", "SUB_DISTRICT_NAME": "วังยาง", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4834, "SUB_DISTRICT_CODE": "470403", "SUB_DISTRICT_NAME": "พอกน้อย", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4835, "SUB_DISTRICT_CODE": "470404", "SUB_DISTRICT_NAME": "นาหัวบ่อ", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4836, "SUB_DISTRICT_CODE": "470405", "SUB_DISTRICT_NAME": "ไร่", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4837, "SUB_DISTRICT_CODE": "470406", "SUB_DISTRICT_NAME": "ช้างมิ่ง", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4838, "SUB_DISTRICT_CODE": "470407", "SUB_DISTRICT_NAME": "นาใน", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4839, "SUB_DISTRICT_CODE": "470408", "SUB_DISTRICT_NAME": "สว่าง", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4840, "SUB_DISTRICT_CODE": "470409", "SUB_DISTRICT_NAME": "บะฮี", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4841, "SUB_DISTRICT_CODE": "470410", "SUB_DISTRICT_NAME": "เชิงชุม", "DISTRICT_ID": 532, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4842, "SUB_DISTRICT_CODE": "470501", "SUB_DISTRICT_NAME": "พังโคน", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4843, "SUB_DISTRICT_CODE": "470502", "SUB_DISTRICT_NAME": "ม่วงไข่", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4844, "SUB_DISTRICT_CODE": "470503", "SUB_DISTRICT_NAME": "แร่", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4845, "SUB_DISTRICT_CODE": "470504", "SUB_DISTRICT_NAME": "ไฮหย่อง", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4846, "SUB_DISTRICT_CODE": "470505", "SUB_DISTRICT_NAME": "ต้นผึ้ง", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4847, "SUB_DISTRICT_CODE": "470597", "SUB_DISTRICT_NAME": "*คลองกระจัง", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4848, "SUB_DISTRICT_CODE": "470598", "SUB_DISTRICT_NAME": "*สระกรวด", "DISTRICT_ID": 533, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4849, "SUB_DISTRICT_CODE": "470601", "SUB_DISTRICT_NAME": "วาริชภูมิ", "DISTRICT_ID": 534, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4850, "SUB_DISTRICT_CODE": "470602", "SUB_DISTRICT_NAME": "ปลาโหล", "DISTRICT_ID": 534, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4851, "SUB_DISTRICT_CODE": "470603", "SUB_DISTRICT_NAME": "หนองลาด", "DISTRICT_ID": 534, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4852, "SUB_DISTRICT_CODE": "470604", "SUB_DISTRICT_NAME": "คำบ่อ", "DISTRICT_ID": 534, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4853, "SUB_DISTRICT_CODE": "470605", "SUB_DISTRICT_NAME": "ค้อเขียว", "DISTRICT_ID": 534, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4854, "SUB_DISTRICT_CODE": "470701", "SUB_DISTRICT_NAME": "นิคมน้ำอูน", "DISTRICT_ID": 535, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4855, "SUB_DISTRICT_CODE": "470702", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 535, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4856, "SUB_DISTRICT_CODE": "470703", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 535, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4857, "SUB_DISTRICT_CODE": "470704", "SUB_DISTRICT_NAME": "สุวรรณคาม", "DISTRICT_ID": 535, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4858, "SUB_DISTRICT_CODE": "470801", "SUB_DISTRICT_NAME": "วานรนิวาส", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4859, "SUB_DISTRICT_CODE": "470802", "SUB_DISTRICT_NAME": "เดื่อศรีคันไชย", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4860, "SUB_DISTRICT_CODE": "470803", "SUB_DISTRICT_NAME": "ขัวก่าย", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4861, "SUB_DISTRICT_CODE": "470804", "SUB_DISTRICT_NAME": "หนองสนม", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4862, "SUB_DISTRICT_CODE": "470805", "SUB_DISTRICT_NAME": "คูสะคาม", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4863, "SUB_DISTRICT_CODE": "470806", "SUB_DISTRICT_NAME": "ธาตุ", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4864, "SUB_DISTRICT_CODE": "470807", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4865, "SUB_DISTRICT_CODE": "470808", "SUB_DISTRICT_NAME": "ศรีวิชัย", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4866, "SUB_DISTRICT_CODE": "470809", "SUB_DISTRICT_NAME": "นาซอ", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4867, "SUB_DISTRICT_CODE": "470810", "SUB_DISTRICT_NAME": "อินทร์แปลง", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4868, "SUB_DISTRICT_CODE": "470811", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4869, "SUB_DISTRICT_CODE": "470812", "SUB_DISTRICT_NAME": "คอนสวรรค์", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4870, "SUB_DISTRICT_CODE": "470813", "SUB_DISTRICT_NAME": "กุดเรือคำ", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4871, "SUB_DISTRICT_CODE": "470814", "SUB_DISTRICT_NAME": "หนองแวงใต้", "DISTRICT_ID": 536, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4872, "SUB_DISTRICT_CODE": "470901", "SUB_DISTRICT_NAME": "คำตากล้า", "DISTRICT_ID": 537, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4873, "SUB_DISTRICT_CODE": "470902", "SUB_DISTRICT_NAME": "หนองบัวสิม", "DISTRICT_ID": 537, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4874, "SUB_DISTRICT_CODE": "470903", "SUB_DISTRICT_NAME": "นาแต้", "DISTRICT_ID": 537, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4875, "SUB_DISTRICT_CODE": "470904", "SUB_DISTRICT_NAME": "แพด", "DISTRICT_ID": 537, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4876, "SUB_DISTRICT_CODE": "471001", "SUB_DISTRICT_NAME": "ม่วง", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4877, "SUB_DISTRICT_CODE": "471002", "SUB_DISTRICT_NAME": "มาย", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4878, "SUB_DISTRICT_CODE": "471003", "SUB_DISTRICT_NAME": "ดงหม้อทอง", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4879, "SUB_DISTRICT_CODE": "471004", "SUB_DISTRICT_NAME": "ดงเหนือ", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4880, "SUB_DISTRICT_CODE": "471005", "SUB_DISTRICT_NAME": "ดงหม้อทองใต้", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4881, "SUB_DISTRICT_CODE": "471006", "SUB_DISTRICT_NAME": "ห้วยหลัว", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4882, "SUB_DISTRICT_CODE": "471007", "SUB_DISTRICT_NAME": "โนนสะอาด", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4883, "SUB_DISTRICT_CODE": "471008", "SUB_DISTRICT_NAME": "หนองกวั่ง", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4884, "SUB_DISTRICT_CODE": "471009", "SUB_DISTRICT_NAME": "บ่อแก้ว", "DISTRICT_ID": 538, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4885, "SUB_DISTRICT_CODE": "471101", "SUB_DISTRICT_NAME": "อากาศ", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4886, "SUB_DISTRICT_CODE": "471102", "SUB_DISTRICT_NAME": "โพนแพง", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4887, "SUB_DISTRICT_CODE": "471103", "SUB_DISTRICT_NAME": "วาใหญ่", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4888, "SUB_DISTRICT_CODE": "471104", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4889, "SUB_DISTRICT_CODE": "471105", "SUB_DISTRICT_NAME": "ท่าก้อน", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4890, "SUB_DISTRICT_CODE": "471106", "SUB_DISTRICT_NAME": "นาฮี", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4891, "SUB_DISTRICT_CODE": "471107", "SUB_DISTRICT_NAME": "บะหว้า", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4892, "SUB_DISTRICT_CODE": "471108", "SUB_DISTRICT_NAME": "สามัคคีพัฒนา", "DISTRICT_ID": 539, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4893, "SUB_DISTRICT_CODE": "471201", "SUB_DISTRICT_NAME": "สว่างแดนดิน", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4894, "SUB_DISTRICT_CODE": "471202", "SUB_DISTRICT_NAME": "*บ้านเหล่า", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4895, "SUB_DISTRICT_CODE": "471203", "SUB_DISTRICT_NAME": "คำสะอาด", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4896, "SUB_DISTRICT_CODE": "471204", "SUB_DISTRICT_NAME": "บ้านต้าย", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4897, "SUB_DISTRICT_CODE": "471205", "SUB_DISTRICT_NAME": "*เจริญศิลป์", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4898, "SUB_DISTRICT_CODE": "471206", "SUB_DISTRICT_NAME": "บงเหนือ", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4899, "SUB_DISTRICT_CODE": "471207", "SUB_DISTRICT_NAME": "โพนสูง", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4900, "SUB_DISTRICT_CODE": "471208", "SUB_DISTRICT_NAME": "โคกสี", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4901, "SUB_DISTRICT_CODE": "471209", "SUB_DISTRICT_NAME": "*ทุ่งแก", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4902, "SUB_DISTRICT_CODE": "471210", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4903, "SUB_DISTRICT_CODE": "471211", "SUB_DISTRICT_NAME": "บงใต้", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4904, "SUB_DISTRICT_CODE": "471212", "SUB_DISTRICT_NAME": "ค้อใต้", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4905, "SUB_DISTRICT_CODE": "471213", "SUB_DISTRICT_NAME": "พันนา", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4906, "SUB_DISTRICT_CODE": "471214", "SUB_DISTRICT_NAME": "แวง", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4907, "SUB_DISTRICT_CODE": "471215", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4908, "SUB_DISTRICT_CODE": "471216", "SUB_DISTRICT_NAME": "ตาลโกน", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4909, "SUB_DISTRICT_CODE": "471217", "SUB_DISTRICT_NAME": "ตาลเนิ้ง", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4910, "SUB_DISTRICT_CODE": "471218", "SUB_DISTRICT_NAME": "*โคกศิลา", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4911, "SUB_DISTRICT_CODE": "471219", "SUB_DISTRICT_NAME": "*หนองแปน", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4912, "SUB_DISTRICT_CODE": "471220", "SUB_DISTRICT_NAME": "ธาตุทอง", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4913, "SUB_DISTRICT_CODE": "471221", "SUB_DISTRICT_NAME": "บ้านถ่อน", "DISTRICT_ID": 540, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4914, "SUB_DISTRICT_CODE": "471301", "SUB_DISTRICT_NAME": "ส่องดาว", "DISTRICT_ID": 541, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4915, "SUB_DISTRICT_CODE": "471302", "SUB_DISTRICT_NAME": "ท่าศิลา", "DISTRICT_ID": 541, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4916, "SUB_DISTRICT_CODE": "471303", "SUB_DISTRICT_NAME": "วัฒนา", "DISTRICT_ID": 541, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4917, "SUB_DISTRICT_CODE": "471304", "SUB_DISTRICT_NAME": "ปทุมวาปี", "DISTRICT_ID": 541, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4918, "SUB_DISTRICT_CODE": "471401", "SUB_DISTRICT_NAME": "เต่างอย", "DISTRICT_ID": 542, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4919, "SUB_DISTRICT_CODE": "471402", "SUB_DISTRICT_NAME": "บึงทวาย", "DISTRICT_ID": 542, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4920, "SUB_DISTRICT_CODE": "471403", "SUB_DISTRICT_NAME": "นาตาล", "DISTRICT_ID": 542, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4921, "SUB_DISTRICT_CODE": "471404", "SUB_DISTRICT_NAME": "จันทร์เพ็ญ", "DISTRICT_ID": 542, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4922, "SUB_DISTRICT_CODE": "471501", "SUB_DISTRICT_NAME": "ตองโขบ", "DISTRICT_ID": 543, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4923, "SUB_DISTRICT_CODE": "471502", "SUB_DISTRICT_NAME": "เหล่าโพนค้อ", "DISTRICT_ID": 543, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4924, "SUB_DISTRICT_CODE": "471503", "SUB_DISTRICT_NAME": "ด่านม่วงคำ", "DISTRICT_ID": 543, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4925, "SUB_DISTRICT_CODE": "471504", "SUB_DISTRICT_NAME": "แมดนาท่ม", "DISTRICT_ID": 543, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4926, "SUB_DISTRICT_CODE": "471601", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 544, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4927, "SUB_DISTRICT_CODE": "471602", "SUB_DISTRICT_NAME": "เจริญศิลป์", "DISTRICT_ID": 544, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4928, "SUB_DISTRICT_CODE": "471603", "SUB_DISTRICT_NAME": "ทุ่งแก", "DISTRICT_ID": 544, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4929, "SUB_DISTRICT_CODE": "471604", "SUB_DISTRICT_NAME": "โคกศิลา", "DISTRICT_ID": 544, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4930, "SUB_DISTRICT_CODE": "471605", "SUB_DISTRICT_NAME": "หนองแปน", "DISTRICT_ID": 544, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4931, "SUB_DISTRICT_CODE": "471701", "SUB_DISTRICT_NAME": "บ้านโพน", "DISTRICT_ID": 545, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4932, "SUB_DISTRICT_CODE": "471702", "SUB_DISTRICT_NAME": "นาแก้ว", "DISTRICT_ID": 545, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4933, "SUB_DISTRICT_CODE": "471703", "SUB_DISTRICT_NAME": "นาตงวัฒนา", "DISTRICT_ID": 545, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4934, "SUB_DISTRICT_CODE": "471704", "SUB_DISTRICT_NAME": "บ้านแป้น", "DISTRICT_ID": 545, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4935, "SUB_DISTRICT_CODE": "471705", "SUB_DISTRICT_NAME": "เชียงสือ", "DISTRICT_ID": 545, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4936, "SUB_DISTRICT_CODE": "471801", "SUB_DISTRICT_NAME": "สร้างค้อ", "DISTRICT_ID": 546, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4937, "SUB_DISTRICT_CODE": "471802", "SUB_DISTRICT_NAME": "หลุบเลา", "DISTRICT_ID": 546, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4938, "SUB_DISTRICT_CODE": "471803", "SUB_DISTRICT_NAME": "โคกภู", "DISTRICT_ID": 546, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4939, "SUB_DISTRICT_CODE": "471804", "SUB_DISTRICT_NAME": "กกปลาซิว", "DISTRICT_ID": 546, "PROVINCE_ID": 35, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4940, "SUB_DISTRICT_CODE": "480101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4941, "SUB_DISTRICT_CODE": "480102", "SUB_DISTRICT_NAME": "หนองแสง", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4942, "SUB_DISTRICT_CODE": "480103", "SUB_DISTRICT_NAME": "นาทราย", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4943, "SUB_DISTRICT_CODE": "480104", "SUB_DISTRICT_NAME": "นาราชควาย", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4944, "SUB_DISTRICT_CODE": "480105", "SUB_DISTRICT_NAME": "กุรุคุ", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4945, "SUB_DISTRICT_CODE": "480106", "SUB_DISTRICT_NAME": "บ้านผึ้ง", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4946, "SUB_DISTRICT_CODE": "480107", "SUB_DISTRICT_NAME": "อาจสามารถ", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4947, "SUB_DISTRICT_CODE": "480108", "SUB_DISTRICT_NAME": "ขามเฒ่า", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4948, "SUB_DISTRICT_CODE": "480109", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4949, "SUB_DISTRICT_CODE": "480110", "SUB_DISTRICT_NAME": "ท่าค้อ", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4950, "SUB_DISTRICT_CODE": "480111", "SUB_DISTRICT_NAME": "คำเตย", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4951, "SUB_DISTRICT_CODE": "480112", "SUB_DISTRICT_NAME": "หนองญาติ", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4952, "SUB_DISTRICT_CODE": "480113", "SUB_DISTRICT_NAME": "ดงขวาง", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4953, "SUB_DISTRICT_CODE": "480114", "SUB_DISTRICT_NAME": "วังตามัว", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4954, "SUB_DISTRICT_CODE": "480115", "SUB_DISTRICT_NAME": "โพธิ์ตาก", "DISTRICT_ID": 549, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4955, "SUB_DISTRICT_CODE": "480201", "SUB_DISTRICT_NAME": "ปลาปาก", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4956, "SUB_DISTRICT_CODE": "480202", "SUB_DISTRICT_NAME": "หนองฮี", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4957, "SUB_DISTRICT_CODE": "480203", "SUB_DISTRICT_NAME": "กุตาไก้", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4958, "SUB_DISTRICT_CODE": "480204", "SUB_DISTRICT_NAME": "โคกสว่าง", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4959, "SUB_DISTRICT_CODE": "480205", "SUB_DISTRICT_NAME": "โคกสูง", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4960, "SUB_DISTRICT_CODE": "480206", "SUB_DISTRICT_NAME": "มหาชัย", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4961, "SUB_DISTRICT_CODE": "480207", "SUB_DISTRICT_NAME": "นามะเขือ", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4962, "SUB_DISTRICT_CODE": "480208", "SUB_DISTRICT_NAME": "หนองเทาใหญ่", "DISTRICT_ID": 550, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4963, "SUB_DISTRICT_CODE": "480301", "SUB_DISTRICT_NAME": "ท่าอุเทน", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4964, "SUB_DISTRICT_CODE": "480302", "SUB_DISTRICT_NAME": "โนนตาล", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4965, "SUB_DISTRICT_CODE": "480303", "SUB_DISTRICT_NAME": "ท่าจำปา", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4966, "SUB_DISTRICT_CODE": "480304", "SUB_DISTRICT_NAME": "ไชยบุรี", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4967, "SUB_DISTRICT_CODE": "480305", "SUB_DISTRICT_NAME": "พนอม", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4968, "SUB_DISTRICT_CODE": "480306", "SUB_DISTRICT_NAME": "พะทาย", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4969, "SUB_DISTRICT_CODE": "480307", "SUB_DISTRICT_NAME": "*นาขมิ้น", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4970, "SUB_DISTRICT_CODE": "480308", "SUB_DISTRICT_NAME": "*โพนบก", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4971, "SUB_DISTRICT_CODE": "480309", "SUB_DISTRICT_NAME": "*โพนสวรรค์", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4972, "SUB_DISTRICT_CODE": "480310", "SUB_DISTRICT_NAME": "*บ้านค้อ", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4973, "SUB_DISTRICT_CODE": "480311", "SUB_DISTRICT_NAME": "เวินพระบาท", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4974, "SUB_DISTRICT_CODE": "480312", "SUB_DISTRICT_NAME": "รามราช", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4975, "SUB_DISTRICT_CODE": "480313", "SUB_DISTRICT_NAME": "*นาหัวบ่อ", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4976, "SUB_DISTRICT_CODE": "480314", "SUB_DISTRICT_NAME": "หนองเทา", "DISTRICT_ID": 551, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4977, "SUB_DISTRICT_CODE": "480401", "SUB_DISTRICT_NAME": "บ้านแพง", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4978, "SUB_DISTRICT_CODE": "480402", "SUB_DISTRICT_NAME": "ไผ่ล้อม", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4979, "SUB_DISTRICT_CODE": "480403", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4980, "SUB_DISTRICT_CODE": "480404", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4981, "SUB_DISTRICT_CODE": "480405", "SUB_DISTRICT_NAME": "*นาทม", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4982, "SUB_DISTRICT_CODE": "480406", "SUB_DISTRICT_NAME": "*หนองซน", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4983, "SUB_DISTRICT_CODE": "480407", "SUB_DISTRICT_NAME": "*ดอนเตย", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4984, "SUB_DISTRICT_CODE": "480408", "SUB_DISTRICT_NAME": "นางัว", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4985, "SUB_DISTRICT_CODE": "480409", "SUB_DISTRICT_NAME": "นาเข", "DISTRICT_ID": 552, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4986, "SUB_DISTRICT_CODE": "480501", "SUB_DISTRICT_NAME": "ธาตุพนม", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4987, "SUB_DISTRICT_CODE": "480502", "SUB_DISTRICT_NAME": "ฝั่งแดง", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4988, "SUB_DISTRICT_CODE": "480503", "SUB_DISTRICT_NAME": "โพนแพง", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4989, "SUB_DISTRICT_CODE": "480504", "SUB_DISTRICT_NAME": "พระกลางทุ่ง", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4990, "SUB_DISTRICT_CODE": "480505", "SUB_DISTRICT_NAME": "นาถ่อน", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4991, "SUB_DISTRICT_CODE": "480506", "SUB_DISTRICT_NAME": "แสนพัน", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4992, "SUB_DISTRICT_CODE": "480507", "SUB_DISTRICT_NAME": "ดอนนางหงส์", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4993, "SUB_DISTRICT_CODE": "480508", "SUB_DISTRICT_NAME": "น้ำก่ำ", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4994, "SUB_DISTRICT_CODE": "480509", "SUB_DISTRICT_NAME": "อุ่มเหม้า", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4995, "SUB_DISTRICT_CODE": "480510", "SUB_DISTRICT_NAME": "นาหนาด", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4996, "SUB_DISTRICT_CODE": "480511", "SUB_DISTRICT_NAME": "กุดฉิม", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4997, "SUB_DISTRICT_CODE": "480512", "SUB_DISTRICT_NAME": "ธาตุพนมเหนือ", "DISTRICT_ID": 553, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4998, "SUB_DISTRICT_CODE": "480601", "SUB_DISTRICT_NAME": "เรณู", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 4999, "SUB_DISTRICT_CODE": "480602", "SUB_DISTRICT_NAME": "โพนทอง", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5000, "SUB_DISTRICT_CODE": "480603", "SUB_DISTRICT_NAME": "ท่าลาด", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5001, "SUB_DISTRICT_CODE": "480604", "SUB_DISTRICT_NAME": "นางาม", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5002, "SUB_DISTRICT_CODE": "480605", "SUB_DISTRICT_NAME": "โคกหินแฮ่", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5003, "SUB_DISTRICT_CODE": "480606", "SUB_DISTRICT_NAME": "*เรณูนคร", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5004, "SUB_DISTRICT_CODE": "480607", "SUB_DISTRICT_NAME": "หนองย่างชิ้น", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5005, "SUB_DISTRICT_CODE": "480608", "SUB_DISTRICT_NAME": "เรณูใต้", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5006, "SUB_DISTRICT_CODE": "480609", "SUB_DISTRICT_NAME": "นาขาม", "DISTRICT_ID": 554, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5007, "SUB_DISTRICT_CODE": "480701", "SUB_DISTRICT_NAME": "นาแก", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5008, "SUB_DISTRICT_CODE": "480702", "SUB_DISTRICT_NAME": "พระซอง", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5009, "SUB_DISTRICT_CODE": "480703", "SUB_DISTRICT_NAME": "หนองสังข์", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5010, "SUB_DISTRICT_CODE": "480704", "SUB_DISTRICT_NAME": "นาคู่", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5011, "SUB_DISTRICT_CODE": "480705", "SUB_DISTRICT_NAME": "พิมาน", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5012, "SUB_DISTRICT_CODE": "480706", "SUB_DISTRICT_NAME": "พุ่มแก", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5013, "SUB_DISTRICT_CODE": "480707", "SUB_DISTRICT_NAME": "ก้านเหลือง", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5014, "SUB_DISTRICT_CODE": "480708", "SUB_DISTRICT_NAME": "หนองบ่อ", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5015, "SUB_DISTRICT_CODE": "480709", "SUB_DISTRICT_NAME": "นาเลียง", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5016, "SUB_DISTRICT_CODE": "480710", "SUB_DISTRICT_NAME": "โคกสี*", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5017, "SUB_DISTRICT_CODE": "480711", "SUB_DISTRICT_NAME": "วังยาง*", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5018, "SUB_DISTRICT_CODE": "480712", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5019, "SUB_DISTRICT_CODE": "480713", "SUB_DISTRICT_NAME": "คำพี้", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5020, "SUB_DISTRICT_CODE": "480714", "SUB_DISTRICT_NAME": "ยอดชาด*", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5021, "SUB_DISTRICT_CODE": "480715", "SUB_DISTRICT_NAME": "สีชมพู", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5022, "SUB_DISTRICT_CODE": "480716", "SUB_DISTRICT_NAME": "หนองโพธิ์*", "DISTRICT_ID": 555, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5023, "SUB_DISTRICT_CODE": "480801", "SUB_DISTRICT_NAME": "ศรีสงคราม", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5024, "SUB_DISTRICT_CODE": "480802", "SUB_DISTRICT_NAME": "นาเดื่อ", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5025, "SUB_DISTRICT_CODE": "480803", "SUB_DISTRICT_NAME": "บ้านเอื้อง", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5026, "SUB_DISTRICT_CODE": "480804", "SUB_DISTRICT_NAME": "สามผง", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5027, "SUB_DISTRICT_CODE": "480805", "SUB_DISTRICT_NAME": "ท่าบ่อสงคราม", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5028, "SUB_DISTRICT_CODE": "480806", "SUB_DISTRICT_NAME": "บ้านข่า", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5029, "SUB_DISTRICT_CODE": "480807", "SUB_DISTRICT_NAME": "นาคำ", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5030, "SUB_DISTRICT_CODE": "480808", "SUB_DISTRICT_NAME": "โพนสว่าง", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5031, "SUB_DISTRICT_CODE": "480809", "SUB_DISTRICT_NAME": "หาดแพง", "DISTRICT_ID": 556, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5032, "SUB_DISTRICT_CODE": "480901", "SUB_DISTRICT_NAME": "นาหว้า", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5033, "SUB_DISTRICT_CODE": "480902", "SUB_DISTRICT_NAME": "นางัว", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5034, "SUB_DISTRICT_CODE": "480903", "SUB_DISTRICT_NAME": "บ้านเสียว", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5035, "SUB_DISTRICT_CODE": "480904", "SUB_DISTRICT_NAME": "นาคูณใหญ่", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5036, "SUB_DISTRICT_CODE": "480905", "SUB_DISTRICT_NAME": "เหล่าพัฒนา", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5037, "SUB_DISTRICT_CODE": "480906", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 557, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5038, "SUB_DISTRICT_CODE": "481001", "SUB_DISTRICT_NAME": "โพนสวรรค์", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5039, "SUB_DISTRICT_CODE": "481002", "SUB_DISTRICT_NAME": "นาหัวบ่อ", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5040, "SUB_DISTRICT_CODE": "481003", "SUB_DISTRICT_NAME": "นาขมิ้น", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5041, "SUB_DISTRICT_CODE": "481004", "SUB_DISTRICT_NAME": "โพนบก", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5042, "SUB_DISTRICT_CODE": "481005", "SUB_DISTRICT_NAME": "บ้านค้อ", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5043, "SUB_DISTRICT_CODE": "481006", "SUB_DISTRICT_NAME": "โพนจาน", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5044, "SUB_DISTRICT_CODE": "481007", "SUB_DISTRICT_NAME": "นาใน", "DISTRICT_ID": 558, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5045, "SUB_DISTRICT_CODE": "481101", "SUB_DISTRICT_NAME": "นาทม", "DISTRICT_ID": 559, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5046, "SUB_DISTRICT_CODE": "481102", "SUB_DISTRICT_NAME": "หนองซน", "DISTRICT_ID": 559, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5047, "SUB_DISTRICT_CODE": "481103", "SUB_DISTRICT_NAME": "ดอนเตย", "DISTRICT_ID": 559, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5048, "SUB_DISTRICT_CODE": "481201", "SUB_DISTRICT_NAME": "วังยาง", "DISTRICT_ID": 560, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5049, "SUB_DISTRICT_CODE": "481202", "SUB_DISTRICT_NAME": "โคกสี", "DISTRICT_ID": 560, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5050, "SUB_DISTRICT_CODE": "481203", "SUB_DISTRICT_NAME": "ยอดชาด", "DISTRICT_ID": 560, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5051, "SUB_DISTRICT_CODE": "481204", "SUB_DISTRICT_NAME": "หนองโพธิ์", "DISTRICT_ID": 560, "PROVINCE_ID": 36, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5052, "SUB_DISTRICT_CODE": "490101", "SUB_DISTRICT_NAME": "มุกดาหาร", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5053, "SUB_DISTRICT_CODE": "490102", "SUB_DISTRICT_NAME": "ศรีบุญเรือง", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5054, "SUB_DISTRICT_CODE": "490103", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5055, "SUB_DISTRICT_CODE": "490104", "SUB_DISTRICT_NAME": "บางทรายใหญ่", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5056, "SUB_DISTRICT_CODE": "490105", "SUB_DISTRICT_NAME": "โพนทราย", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5057, "SUB_DISTRICT_CODE": "490106", "SUB_DISTRICT_NAME": "ผึ่งแดด", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5058, "SUB_DISTRICT_CODE": "490107", "SUB_DISTRICT_NAME": "นาโสก", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5059, "SUB_DISTRICT_CODE": "490108", "SUB_DISTRICT_NAME": "นาสีนวน", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5060, "SUB_DISTRICT_CODE": "490109", "SUB_DISTRICT_NAME": "คำป่าหลาย", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5061, "SUB_DISTRICT_CODE": "490110", "SUB_DISTRICT_NAME": "คำอาฮวน", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5062, "SUB_DISTRICT_CODE": "490111", "SUB_DISTRICT_NAME": "ดงเย็น", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5063, "SUB_DISTRICT_CODE": "490112", "SUB_DISTRICT_NAME": "ดงมอน", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5064, "SUB_DISTRICT_CODE": "490113", "SUB_DISTRICT_NAME": "กุดแข้", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5065, "SUB_DISTRICT_CODE": "490194", "SUB_DISTRICT_NAME": "*หนองแวง", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5066, "SUB_DISTRICT_CODE": "490195", "SUB_DISTRICT_NAME": "*กกแดง", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5067, "SUB_DISTRICT_CODE": "490196", "SUB_DISTRICT_NAME": "*นากอก", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5068, "SUB_DISTRICT_CODE": "490197", "SUB_DISTRICT_NAME": "*นำคมคำสร้อย", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5069, "SUB_DISTRICT_CODE": "490198", "SUB_DISTRICT_NAME": "*บางทรายน้อย", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5070, "SUB_DISTRICT_CODE": "490199", "SUB_DISTRICT_NAME": "*หว้านใหญ่", "DISTRICT_ID": 561, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5071, "SUB_DISTRICT_CODE": "490201", "SUB_DISTRICT_NAME": "นิคมคำสร้อย", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5072, "SUB_DISTRICT_CODE": "490202", "SUB_DISTRICT_NAME": "นากอก", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5073, "SUB_DISTRICT_CODE": "490203", "SUB_DISTRICT_NAME": "หนองแวง", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5074, "SUB_DISTRICT_CODE": "490204", "SUB_DISTRICT_NAME": "กกแดง", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5075, "SUB_DISTRICT_CODE": "490205", "SUB_DISTRICT_NAME": "นาอุดม", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5076, "SUB_DISTRICT_CODE": "490206", "SUB_DISTRICT_NAME": "โชคชัย", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5077, "SUB_DISTRICT_CODE": "490207", "SUB_DISTRICT_NAME": "ร่มเกล้า", "DISTRICT_ID": 562, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5078, "SUB_DISTRICT_CODE": "490301", "SUB_DISTRICT_NAME": "ดอนตาล", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5079, "SUB_DISTRICT_CODE": "490302", "SUB_DISTRICT_NAME": "โพธิ์ไทร", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5080, "SUB_DISTRICT_CODE": "490303", "SUB_DISTRICT_NAME": "ป่าไร่", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5081, "SUB_DISTRICT_CODE": "490304", "SUB_DISTRICT_NAME": "เหล่าหมี", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5082, "SUB_DISTRICT_CODE": "490305", "SUB_DISTRICT_NAME": "บ้านบาก", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5083, "SUB_DISTRICT_CODE": "490306", "SUB_DISTRICT_NAME": "นาสะเม็ง", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5084, "SUB_DISTRICT_CODE": "490307", "SUB_DISTRICT_NAME": "บ้านแก้ง", "DISTRICT_ID": 563, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5085, "SUB_DISTRICT_CODE": "490401", "SUB_DISTRICT_NAME": "ดงหลวง", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5086, "SUB_DISTRICT_CODE": "490402", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5087, "SUB_DISTRICT_CODE": "490403", "SUB_DISTRICT_NAME": "กกตูม", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5088, "SUB_DISTRICT_CODE": "490404", "SUB_DISTRICT_NAME": "หนองแคน", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5089, "SUB_DISTRICT_CODE": "490405", "SUB_DISTRICT_NAME": "ชะโนดน้อย", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5090, "SUB_DISTRICT_CODE": "490406", "SUB_DISTRICT_NAME": "พังแดง", "DISTRICT_ID": 564, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5091, "SUB_DISTRICT_CODE": "490501", "SUB_DISTRICT_NAME": "*หนองสูงใต้", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5092, "SUB_DISTRICT_CODE": "490502", "SUB_DISTRICT_NAME": "*หนองสูง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5093, "SUB_DISTRICT_CODE": "490503", "SUB_DISTRICT_NAME": "บ้านซ่ง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5094, "SUB_DISTRICT_CODE": "490504", "SUB_DISTRICT_NAME": "คำชะอี", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5095, "SUB_DISTRICT_CODE": "490505", "SUB_DISTRICT_NAME": "หนองเอี่ยน", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5096, "SUB_DISTRICT_CODE": "490506", "SUB_DISTRICT_NAME": "บ้านค้อ", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5097, "SUB_DISTRICT_CODE": "490507", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5098, "SUB_DISTRICT_CODE": "490508", "SUB_DISTRICT_NAME": "โพนงาม", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5099, "SUB_DISTRICT_CODE": "490509", "SUB_DISTRICT_NAME": "*โนนยาง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5100, "SUB_DISTRICT_CODE": "490510", "SUB_DISTRICT_NAME": "*บ้านเป้า", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5101, "SUB_DISTRICT_CODE": "490511", "SUB_DISTRICT_NAME": "เหล่าสร้างถ่อ", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5102, "SUB_DISTRICT_CODE": "490512", "SUB_DISTRICT_NAME": "คำบก", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5103, "SUB_DISTRICT_CODE": "490513", "SUB_DISTRICT_NAME": "*ภูวง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5104, "SUB_DISTRICT_CODE": "490514", "SUB_DISTRICT_NAME": "น้ำเที่ยง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5105, "SUB_DISTRICT_CODE": "490597", "SUB_DISTRICT_NAME": "*หนองสูงใต้", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5106, "SUB_DISTRICT_CODE": "490598", "SUB_DISTRICT_NAME": "*บ้านเป้า", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5107, "SUB_DISTRICT_CODE": "490599", "SUB_DISTRICT_NAME": "*หนองสูง", "DISTRICT_ID": 565, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5108, "SUB_DISTRICT_CODE": "490601", "SUB_DISTRICT_NAME": "หว้านใหญ่", "DISTRICT_ID": 566, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5109, "SUB_DISTRICT_CODE": "490602", "SUB_DISTRICT_NAME": "ป่งขาม", "DISTRICT_ID": 566, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5110, "SUB_DISTRICT_CODE": "490603", "SUB_DISTRICT_NAME": "บางทรายน้อย", "DISTRICT_ID": 566, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5111, "SUB_DISTRICT_CODE": "490604", "SUB_DISTRICT_NAME": "ชะโนด", "DISTRICT_ID": 566, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5112, "SUB_DISTRICT_CODE": "490605", "SUB_DISTRICT_NAME": "ดงหมู", "DISTRICT_ID": 566, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5113, "SUB_DISTRICT_CODE": "490701", "SUB_DISTRICT_NAME": "หนองสูง", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5114, "SUB_DISTRICT_CODE": "490702", "SUB_DISTRICT_NAME": "โนนยาง", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5115, "SUB_DISTRICT_CODE": "490703", "SUB_DISTRICT_NAME": "ภูวง", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5116, "SUB_DISTRICT_CODE": "490704", "SUB_DISTRICT_NAME": "บ้านเป้า", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5117, "SUB_DISTRICT_CODE": "490705", "SUB_DISTRICT_NAME": "หนองสูงใต้", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5118, "SUB_DISTRICT_CODE": "490706", "SUB_DISTRICT_NAME": "หนองสูงเหนือ", "DISTRICT_ID": 567, "PROVINCE_ID": 37, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 5119, "SUB_DISTRICT_CODE": "500101", "SUB_DISTRICT_NAME": "ศรีภูมิ", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5120, "SUB_DISTRICT_CODE": "500102", "SUB_DISTRICT_NAME": "พระสิงห์", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5121, "SUB_DISTRICT_CODE": "500103", "SUB_DISTRICT_NAME": "หายยา", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5122, "SUB_DISTRICT_CODE": "500104", "SUB_DISTRICT_NAME": "ช้างม่อย", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5123, "SUB_DISTRICT_CODE": "500105", "SUB_DISTRICT_NAME": "ช้างคลาน", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5124, "SUB_DISTRICT_CODE": "500106", "SUB_DISTRICT_NAME": "วัดเกต", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5125, "SUB_DISTRICT_CODE": "500107", "SUB_DISTRICT_NAME": "ช้างเผือก", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5126, "SUB_DISTRICT_CODE": "500108", "SUB_DISTRICT_NAME": "สุเทพ", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5127, "SUB_DISTRICT_CODE": "500109", "SUB_DISTRICT_NAME": "แม่เหียะ", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5128, "SUB_DISTRICT_CODE": "500110", "SUB_DISTRICT_NAME": "ป่าแดด", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5129, "SUB_DISTRICT_CODE": "500111", "SUB_DISTRICT_NAME": "หนองหอย", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5130, "SUB_DISTRICT_CODE": "500112", "SUB_DISTRICT_NAME": "ท่าศาลา", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5131, "SUB_DISTRICT_CODE": "500113", "SUB_DISTRICT_NAME": "หนองป่าครั่ง", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5132, "SUB_DISTRICT_CODE": "500114", "SUB_DISTRICT_NAME": "ฟ้าฮ่าม", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5133, "SUB_DISTRICT_CODE": "500115", "SUB_DISTRICT_NAME": "ป่าตัน", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5134, "SUB_DISTRICT_CODE": "500116", "SUB_DISTRICT_NAME": "สันผีเสื้อ", "DISTRICT_ID": 568, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5135, "SUB_DISTRICT_CODE": "500201", "SUB_DISTRICT_NAME": "*ยางคราม", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5136, "SUB_DISTRICT_CODE": "500202", "SUB_DISTRICT_NAME": "*สองแคว", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5137, "SUB_DISTRICT_CODE": "500203", "SUB_DISTRICT_NAME": "บ้านหลวง", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5138, "SUB_DISTRICT_CODE": "500204", "SUB_DISTRICT_NAME": "ข่วงเปา", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5139, "SUB_DISTRICT_CODE": "500205", "SUB_DISTRICT_NAME": "สบเตี๊ยะ", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5140, "SUB_DISTRICT_CODE": "500206", "SUB_DISTRICT_NAME": "บ้านแปะ", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5141, "SUB_DISTRICT_CODE": "500207", "SUB_DISTRICT_NAME": "ดอยแก้ว", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5142, "SUB_DISTRICT_CODE": "500208", "SUB_DISTRICT_NAME": "*ดอยหล่อ", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5143, "SUB_DISTRICT_CODE": "500209", "SUB_DISTRICT_NAME": "แม่สอย", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5144, "SUB_DISTRICT_CODE": "500210", "SUB_DISTRICT_NAME": "*สันติสุข", "DISTRICT_ID": 569, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5145, "SUB_DISTRICT_CODE": "500301", "SUB_DISTRICT_NAME": "ช่างเคิ่ง", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5146, "SUB_DISTRICT_CODE": "500302", "SUB_DISTRICT_NAME": "ท่าผา", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5147, "SUB_DISTRICT_CODE": "500303", "SUB_DISTRICT_NAME": "บ้านทับ", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5148, "SUB_DISTRICT_CODE": "500304", "SUB_DISTRICT_NAME": "แม่ศึก", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5149, "SUB_DISTRICT_CODE": "500305", "SUB_DISTRICT_NAME": "แม่นาจร", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5150, "SUB_DISTRICT_CODE": "500306", "SUB_DISTRICT_NAME": "บ้านจันทร์", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5151, "SUB_DISTRICT_CODE": "500307", "SUB_DISTRICT_NAME": "ปางหินฝน", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5152, "SUB_DISTRICT_CODE": "500308", "SUB_DISTRICT_NAME": "กองแขก", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5153, "SUB_DISTRICT_CODE": "500309", "SUB_DISTRICT_NAME": "แม่แดด", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5154, "SUB_DISTRICT_CODE": "500310", "SUB_DISTRICT_NAME": "แจ่มหลวง", "DISTRICT_ID": 570, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5155, "SUB_DISTRICT_CODE": "500401", "SUB_DISTRICT_NAME": "เชียงดาว", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5156, "SUB_DISTRICT_CODE": "500402", "SUB_DISTRICT_NAME": "เมืองนะ", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5157, "SUB_DISTRICT_CODE": "500403", "SUB_DISTRICT_NAME": "เมืองงาย", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5158, "SUB_DISTRICT_CODE": "500404", "SUB_DISTRICT_NAME": "แม่นะ", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5159, "SUB_DISTRICT_CODE": "500405", "SUB_DISTRICT_NAME": "เมืองคอง", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5160, "SUB_DISTRICT_CODE": "500406", "SUB_DISTRICT_NAME": "ปิงโค้ง", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5161, "SUB_DISTRICT_CODE": "500407", "SUB_DISTRICT_NAME": "ทุ่งข้าวพวง", "DISTRICT_ID": 571, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5162, "SUB_DISTRICT_CODE": "500501", "SUB_DISTRICT_NAME": "เชิงดอย", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5163, "SUB_DISTRICT_CODE": "500502", "SUB_DISTRICT_NAME": "สันปูเลย", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5164, "SUB_DISTRICT_CODE": "500503", "SUB_DISTRICT_NAME": "ลวงเหนือ", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5165, "SUB_DISTRICT_CODE": "500504", "SUB_DISTRICT_NAME": "ป่าป้อง", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5166, "SUB_DISTRICT_CODE": "500505", "SUB_DISTRICT_NAME": "สง่าบ้าน", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5167, "SUB_DISTRICT_CODE": "500506", "SUB_DISTRICT_NAME": "ป่าลาน", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5168, "SUB_DISTRICT_CODE": "500507", "SUB_DISTRICT_NAME": "ตลาดขวัญ", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5169, "SUB_DISTRICT_CODE": "500508", "SUB_DISTRICT_NAME": "สำราญราษฎร์", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5170, "SUB_DISTRICT_CODE": "500509", "SUB_DISTRICT_NAME": "แม่คือ", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5171, "SUB_DISTRICT_CODE": "500510", "SUB_DISTRICT_NAME": "ตลาดใหญ่", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5172, "SUB_DISTRICT_CODE": "500511", "SUB_DISTRICT_NAME": "แม่ฮ้อยเงิน", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5173, "SUB_DISTRICT_CODE": "500512", "SUB_DISTRICT_NAME": "แม่โป่ง", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5174, "SUB_DISTRICT_CODE": "500513", "SUB_DISTRICT_NAME": "ป่าเมี่ยง", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5175, "SUB_DISTRICT_CODE": "500514", "SUB_DISTRICT_NAME": "เทพเสด็จ", "DISTRICT_ID": 572, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5176, "SUB_DISTRICT_CODE": "500601", "SUB_DISTRICT_NAME": "สันมหาพน", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5177, "SUB_DISTRICT_CODE": "500602", "SUB_DISTRICT_NAME": "แม่แตง", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5178, "SUB_DISTRICT_CODE": "500603", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5179, "SUB_DISTRICT_CODE": "500604", "SUB_DISTRICT_NAME": "ช่อแล", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5180, "SUB_DISTRICT_CODE": "500605", "SUB_DISTRICT_NAME": "แม่หอพระ", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5181, "SUB_DISTRICT_CODE": "500606", "SUB_DISTRICT_NAME": "สบเปิง", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5182, "SUB_DISTRICT_CODE": "500607", "SUB_DISTRICT_NAME": "บ้านเป้า", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5183, "SUB_DISTRICT_CODE": "500608", "SUB_DISTRICT_NAME": "สันป่ายาง", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5184, "SUB_DISTRICT_CODE": "500609", "SUB_DISTRICT_NAME": "ป่าแป๋", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5185, "SUB_DISTRICT_CODE": "500610", "SUB_DISTRICT_NAME": "เมืองก๋าย", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5186, "SUB_DISTRICT_CODE": "500611", "SUB_DISTRICT_NAME": "บ้านช้าง", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5187, "SUB_DISTRICT_CODE": "500612", "SUB_DISTRICT_NAME": "กื้ดช้าง", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5188, "SUB_DISTRICT_CODE": "500613", "SUB_DISTRICT_NAME": "อินทขิล", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5189, "SUB_DISTRICT_CODE": "500614", "SUB_DISTRICT_NAME": "สมก๋าย", "DISTRICT_ID": 573, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5190, "SUB_DISTRICT_CODE": "500701", "SUB_DISTRICT_NAME": "ริมใต้", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5191, "SUB_DISTRICT_CODE": "500702", "SUB_DISTRICT_NAME": "ริมเหนือ", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5192, "SUB_DISTRICT_CODE": "500703", "SUB_DISTRICT_NAME": "สันโป่ง", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5193, "SUB_DISTRICT_CODE": "500704", "SUB_DISTRICT_NAME": "ขี้เหล็ก", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5194, "SUB_DISTRICT_CODE": "500705", "SUB_DISTRICT_NAME": "สะลวง", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5195, "SUB_DISTRICT_CODE": "500706", "SUB_DISTRICT_NAME": "ห้วยทราย", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5196, "SUB_DISTRICT_CODE": "500707", "SUB_DISTRICT_NAME": "แม่แรม", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5197, "SUB_DISTRICT_CODE": "500708", "SUB_DISTRICT_NAME": "โป่งแยง", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5198, "SUB_DISTRICT_CODE": "500709", "SUB_DISTRICT_NAME": "แม่สา", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5199, "SUB_DISTRICT_CODE": "500710", "SUB_DISTRICT_NAME": "ดอนแก้ว", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5200, "SUB_DISTRICT_CODE": "500711", "SUB_DISTRICT_NAME": "เหมืองแก้ว", "DISTRICT_ID": 574, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5201, "SUB_DISTRICT_CODE": "500801", "SUB_DISTRICT_NAME": "สะเมิงใต้", "DISTRICT_ID": 575, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5202, "SUB_DISTRICT_CODE": "500802", "SUB_DISTRICT_NAME": "สะเมิงเหนือ", "DISTRICT_ID": 575, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5203, "SUB_DISTRICT_CODE": "500803", "SUB_DISTRICT_NAME": "แม่สาบ", "DISTRICT_ID": 575, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5204, "SUB_DISTRICT_CODE": "500804", "SUB_DISTRICT_NAME": "บ่อแก้ว", "DISTRICT_ID": 575, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5205, "SUB_DISTRICT_CODE": "500805", "SUB_DISTRICT_NAME": "ยั้งเมิน", "DISTRICT_ID": 575, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5206, "SUB_DISTRICT_CODE": "500901", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5207, "SUB_DISTRICT_CODE": "500902", "SUB_DISTRICT_NAME": "*ปงตำ", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5208, "SUB_DISTRICT_CODE": "500903", "SUB_DISTRICT_NAME": "ม่อนปิ่น", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5209, "SUB_DISTRICT_CODE": "500904", "SUB_DISTRICT_NAME": "แม่งอน", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5210, "SUB_DISTRICT_CODE": "500905", "SUB_DISTRICT_NAME": "แม่สูน", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5211, "SUB_DISTRICT_CODE": "500906", "SUB_DISTRICT_NAME": "สันทราย", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5212, "SUB_DISTRICT_CODE": "500907", "SUB_DISTRICT_NAME": "*ศรีดงเย็น", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5213, "SUB_DISTRICT_CODE": "500908", "SUB_DISTRICT_NAME": "*แม่ทะลบ", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5214, "SUB_DISTRICT_CODE": "500909", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5215, "SUB_DISTRICT_CODE": "500910", "SUB_DISTRICT_NAME": "แม่คะ", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5216, "SUB_DISTRICT_CODE": "500911", "SUB_DISTRICT_NAME": "แม่ข่า", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5217, "SUB_DISTRICT_CODE": "500912", "SUB_DISTRICT_NAME": "โป่งน้ำร้อน", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5218, "SUB_DISTRICT_CODE": "500995", "SUB_DISTRICT_NAME": "แม่นาวาง*", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5219, "SUB_DISTRICT_CODE": "500996", "SUB_DISTRICT_NAME": "แม่สาว*", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5220, "SUB_DISTRICT_CODE": "500997", "SUB_DISTRICT_NAME": "แม่อาย*", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5221, "SUB_DISTRICT_CODE": "500998", "SUB_DISTRICT_NAME": "*ศรีดงเย็น", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5222, "SUB_DISTRICT_CODE": "500999", "SUB_DISTRICT_NAME": "*ปงตำ", "DISTRICT_ID": 576, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5223, "SUB_DISTRICT_CODE": "501001", "SUB_DISTRICT_NAME": "แม่อาย", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5224, "SUB_DISTRICT_CODE": "501002", "SUB_DISTRICT_NAME": "แม่สาว", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5225, "SUB_DISTRICT_CODE": "501003", "SUB_DISTRICT_NAME": "สันต้นหมื้อ", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5226, "SUB_DISTRICT_CODE": "501004", "SUB_DISTRICT_NAME": "แม่นาวาง", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5227, "SUB_DISTRICT_CODE": "501005", "SUB_DISTRICT_NAME": "ท่าตอน", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5228, "SUB_DISTRICT_CODE": "501006", "SUB_DISTRICT_NAME": "บ้านหลวง", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5229, "SUB_DISTRICT_CODE": "501007", "SUB_DISTRICT_NAME": "มะลิกา", "DISTRICT_ID": 577, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5230, "SUB_DISTRICT_CODE": "501101", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5231, "SUB_DISTRICT_CODE": "501102", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5232, "SUB_DISTRICT_CODE": "501103", "SUB_DISTRICT_NAME": "ป่าตุ้ม", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5233, "SUB_DISTRICT_CODE": "501104", "SUB_DISTRICT_NAME": "ป่าไหน่", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5234, "SUB_DISTRICT_CODE": "501105", "SUB_DISTRICT_NAME": "สันทราย", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5235, "SUB_DISTRICT_CODE": "501106", "SUB_DISTRICT_NAME": "บ้านโป่ง", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5236, "SUB_DISTRICT_CODE": "501107", "SUB_DISTRICT_NAME": "น้ำแพร่", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5237, "SUB_DISTRICT_CODE": "501108", "SUB_DISTRICT_NAME": "เขื่อนผาก", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5238, "SUB_DISTRICT_CODE": "501109", "SUB_DISTRICT_NAME": "แม่แวน", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5239, "SUB_DISTRICT_CODE": "501110", "SUB_DISTRICT_NAME": "แม่ปั๋ง", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5240, "SUB_DISTRICT_CODE": "501111", "SUB_DISTRICT_NAME": "โหล่งขอด", "DISTRICT_ID": 578, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5241, "SUB_DISTRICT_CODE": "501201", "SUB_DISTRICT_NAME": "ยุหว่า", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5242, "SUB_DISTRICT_CODE": "501202", "SUB_DISTRICT_NAME": "สันกลาง", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5243, "SUB_DISTRICT_CODE": "501203", "SUB_DISTRICT_NAME": "ท่าวังพร้าว", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5244, "SUB_DISTRICT_CODE": "501204", "SUB_DISTRICT_NAME": "มะขามหลวง", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5245, "SUB_DISTRICT_CODE": "501205", "SUB_DISTRICT_NAME": "แม่ก๊า", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5246, "SUB_DISTRICT_CODE": "501206", "SUB_DISTRICT_NAME": "บ้านแม", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5247, "SUB_DISTRICT_CODE": "501207", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5248, "SUB_DISTRICT_CODE": "501208", "SUB_DISTRICT_NAME": "ทุ่งสะโตก", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5249, "SUB_DISTRICT_CODE": "501209", "SUB_DISTRICT_NAME": "*ทุ่งปี้", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5250, "SUB_DISTRICT_CODE": "501210", "SUB_DISTRICT_NAME": "ทุ่งต้อม", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5251, "SUB_DISTRICT_CODE": "501211", "SUB_DISTRICT_NAME": "*บ้านกาด", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5252, "SUB_DISTRICT_CODE": "501212", "SUB_DISTRICT_NAME": "*แม่วิน", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5253, "SUB_DISTRICT_CODE": "501213", "SUB_DISTRICT_NAME": "*ทุ่งรวงทอง", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5254, "SUB_DISTRICT_CODE": "501214", "SUB_DISTRICT_NAME": "น้ำบ่อหลวง", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5255, "SUB_DISTRICT_CODE": "501215", "SUB_DISTRICT_NAME": "มะขุนหวาน", "DISTRICT_ID": 579, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5256, "SUB_DISTRICT_CODE": "501301", "SUB_DISTRICT_NAME": "สันกำแพง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5257, "SUB_DISTRICT_CODE": "501302", "SUB_DISTRICT_NAME": "ทรายมูล", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5258, "SUB_DISTRICT_CODE": "501303", "SUB_DISTRICT_NAME": "ร้องวัวแดง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5259, "SUB_DISTRICT_CODE": "501304", "SUB_DISTRICT_NAME": "บวกค้าง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5260, "SUB_DISTRICT_CODE": "501305", "SUB_DISTRICT_NAME": "แช่ช้าง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5261, "SUB_DISTRICT_CODE": "501306", "SUB_DISTRICT_NAME": "ออนใต้", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5262, "SUB_DISTRICT_CODE": "501307", "SUB_DISTRICT_NAME": "*ออนเหนือ", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5263, "SUB_DISTRICT_CODE": "501308", "SUB_DISTRICT_NAME": "*บ้านสหกรณ์", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5264, "SUB_DISTRICT_CODE": "501309", "SUB_DISTRICT_NAME": "*ห้วยแก้ว", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5265, "SUB_DISTRICT_CODE": "501310", "SUB_DISTRICT_NAME": "แม่ปูคา", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5266, "SUB_DISTRICT_CODE": "501311", "SUB_DISTRICT_NAME": "ห้วยทราย", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5267, "SUB_DISTRICT_CODE": "501312", "SUB_DISTRICT_NAME": "ต้นเปา", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5268, "SUB_DISTRICT_CODE": "501313", "SUB_DISTRICT_NAME": "สันกลาง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5269, "SUB_DISTRICT_CODE": "501314", "SUB_DISTRICT_NAME": "*แม่ทา", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5270, "SUB_DISTRICT_CODE": "501315", "SUB_DISTRICT_NAME": "*ทาเหนือ", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5271, "SUB_DISTRICT_CODE": "501316", "SUB_DISTRICT_NAME": "*ออนกลาง", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5272, "SUB_DISTRICT_CODE": "501397", "SUB_DISTRICT_NAME": "*แม่วิน", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5273, "SUB_DISTRICT_CODE": "501398", "SUB_DISTRICT_NAME": "*ทุ่งปี้", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5274, "SUB_DISTRICT_CODE": "501399", "SUB_DISTRICT_NAME": "*บ้านกาด", "DISTRICT_ID": 580, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5275, "SUB_DISTRICT_CODE": "501401", "SUB_DISTRICT_NAME": "สันทรายหลวง", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5276, "SUB_DISTRICT_CODE": "501402", "SUB_DISTRICT_NAME": "สันทรายน้อย", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5277, "SUB_DISTRICT_CODE": "501403", "SUB_DISTRICT_NAME": "สันพระเนตร", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5278, "SUB_DISTRICT_CODE": "501404", "SUB_DISTRICT_NAME": "สันนาเม็ง", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5279, "SUB_DISTRICT_CODE": "501405", "SUB_DISTRICT_NAME": "สันป่าเปา", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5280, "SUB_DISTRICT_CODE": "501406", "SUB_DISTRICT_NAME": "หนองแหย่ง", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5281, "SUB_DISTRICT_CODE": "501407", "SUB_DISTRICT_NAME": "หนองจ๊อม", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5282, "SUB_DISTRICT_CODE": "501408", "SUB_DISTRICT_NAME": "หนองหาร", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5283, "SUB_DISTRICT_CODE": "501409", "SUB_DISTRICT_NAME": "แม่แฝก", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5284, "SUB_DISTRICT_CODE": "501410", "SUB_DISTRICT_NAME": "แม่แฝกใหม่", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5285, "SUB_DISTRICT_CODE": "501411", "SUB_DISTRICT_NAME": "เมืองเล็น", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5286, "SUB_DISTRICT_CODE": "501412", "SUB_DISTRICT_NAME": "ป่าไผ่", "DISTRICT_ID": 581, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5287, "SUB_DISTRICT_CODE": "501501", "SUB_DISTRICT_NAME": "หางดง", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5288, "SUB_DISTRICT_CODE": "501502", "SUB_DISTRICT_NAME": "หนองแก๋ว", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5289, "SUB_DISTRICT_CODE": "501503", "SUB_DISTRICT_NAME": "หารแก้ว", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5290, "SUB_DISTRICT_CODE": "501504", "SUB_DISTRICT_NAME": "หนองตอง", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5291, "SUB_DISTRICT_CODE": "501505", "SUB_DISTRICT_NAME": "ขุนคง", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5292, "SUB_DISTRICT_CODE": "501506", "SUB_DISTRICT_NAME": "สบแม่ข่า", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5293, "SUB_DISTRICT_CODE": "501507", "SUB_DISTRICT_NAME": "บ้านแหวน", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5294, "SUB_DISTRICT_CODE": "501508", "SUB_DISTRICT_NAME": "สันผักหวาน", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5295, "SUB_DISTRICT_CODE": "501509", "SUB_DISTRICT_NAME": "หนองควาย", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5296, "SUB_DISTRICT_CODE": "501510", "SUB_DISTRICT_NAME": "บ้านปง", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5297, "SUB_DISTRICT_CODE": "501511", "SUB_DISTRICT_NAME": "น้ำแพร่", "DISTRICT_ID": 582, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5298, "SUB_DISTRICT_CODE": "501601", "SUB_DISTRICT_NAME": "หางดง", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5299, "SUB_DISTRICT_CODE": "501602", "SUB_DISTRICT_NAME": "ฮอด", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5300, "SUB_DISTRICT_CODE": "501603", "SUB_DISTRICT_NAME": "บ้านตาล", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5301, "SUB_DISTRICT_CODE": "501604", "SUB_DISTRICT_NAME": "บ่อหลวง", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5302, "SUB_DISTRICT_CODE": "501605", "SUB_DISTRICT_NAME": "บ่อสลี", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5303, "SUB_DISTRICT_CODE": "501606", "SUB_DISTRICT_NAME": "นาคอเรือ", "DISTRICT_ID": 583, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5304, "SUB_DISTRICT_CODE": "501701", "SUB_DISTRICT_NAME": "ดอยเต่า", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5305, "SUB_DISTRICT_CODE": "501702", "SUB_DISTRICT_NAME": "ท่าเดื่อ", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5306, "SUB_DISTRICT_CODE": "501703", "SUB_DISTRICT_NAME": "มืดกา", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5307, "SUB_DISTRICT_CODE": "501704", "SUB_DISTRICT_NAME": "บ้านแอ่น", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5308, "SUB_DISTRICT_CODE": "501705", "SUB_DISTRICT_NAME": "บงตัน", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5309, "SUB_DISTRICT_CODE": "501706", "SUB_DISTRICT_NAME": "โปงทุ่ง", "DISTRICT_ID": 584, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5310, "SUB_DISTRICT_CODE": "501801", "SUB_DISTRICT_NAME": "อมก๋อย", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5311, "SUB_DISTRICT_CODE": "501802", "SUB_DISTRICT_NAME": "ยางเปียง", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5312, "SUB_DISTRICT_CODE": "501803", "SUB_DISTRICT_NAME": "แม่ตื่น", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5313, "SUB_DISTRICT_CODE": "501804", "SUB_DISTRICT_NAME": "ม่อนจอง", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5314, "SUB_DISTRICT_CODE": "501805", "SUB_DISTRICT_NAME": "สบโขง", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5315, "SUB_DISTRICT_CODE": "501806", "SUB_DISTRICT_NAME": "นาเกียน", "DISTRICT_ID": 585, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5316, "SUB_DISTRICT_CODE": "501901", "SUB_DISTRICT_NAME": "ยางเนิ้ง", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5317, "SUB_DISTRICT_CODE": "501902", "SUB_DISTRICT_NAME": "สารภี", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5318, "SUB_DISTRICT_CODE": "501903", "SUB_DISTRICT_NAME": "ชมภู", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5319, "SUB_DISTRICT_CODE": "501904", "SUB_DISTRICT_NAME": "ไชยสถาน", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5320, "SUB_DISTRICT_CODE": "501905", "SUB_DISTRICT_NAME": "ขัวมุง", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5321, "SUB_DISTRICT_CODE": "501906", "SUB_DISTRICT_NAME": "หนองแฝก", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5322, "SUB_DISTRICT_CODE": "501907", "SUB_DISTRICT_NAME": "หนองผึ้ง", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5323, "SUB_DISTRICT_CODE": "501908", "SUB_DISTRICT_NAME": "ท่ากว้าง", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5324, "SUB_DISTRICT_CODE": "501909", "SUB_DISTRICT_NAME": "ดอนแก้ว", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5325, "SUB_DISTRICT_CODE": "501910", "SUB_DISTRICT_NAME": "ท่าวังตาล", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5326, "SUB_DISTRICT_CODE": "501911", "SUB_DISTRICT_NAME": "สันทราย", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5327, "SUB_DISTRICT_CODE": "501912", "SUB_DISTRICT_NAME": "ป่าบง", "DISTRICT_ID": 586, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5328, "SUB_DISTRICT_CODE": "502001", "SUB_DISTRICT_NAME": "เมืองแหง", "DISTRICT_ID": 587, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5329, "SUB_DISTRICT_CODE": "502002", "SUB_DISTRICT_NAME": "เปียงหลวง", "DISTRICT_ID": 587, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5330, "SUB_DISTRICT_CODE": "502003", "SUB_DISTRICT_NAME": "แสนไห", "DISTRICT_ID": 587, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5331, "SUB_DISTRICT_CODE": "502101", "SUB_DISTRICT_NAME": "ปงตำ", "DISTRICT_ID": 588, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5332, "SUB_DISTRICT_CODE": "502102", "SUB_DISTRICT_NAME": "ศรีดงเย็น", "DISTRICT_ID": 588, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5333, "SUB_DISTRICT_CODE": "502103", "SUB_DISTRICT_NAME": "แม่ทะลบ", "DISTRICT_ID": 588, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5334, "SUB_DISTRICT_CODE": "502104", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 588, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5335, "SUB_DISTRICT_CODE": "502201", "SUB_DISTRICT_NAME": "บ้านกาด", "DISTRICT_ID": 589, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5336, "SUB_DISTRICT_CODE": "502202", "SUB_DISTRICT_NAME": "ทุ่งปี้", "DISTRICT_ID": 589, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5337, "SUB_DISTRICT_CODE": "502203", "SUB_DISTRICT_NAME": "ทุ่งรวงทอง", "DISTRICT_ID": 589, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5338, "SUB_DISTRICT_CODE": "502204", "SUB_DISTRICT_NAME": "แม่วิน", "DISTRICT_ID": 589, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5339, "SUB_DISTRICT_CODE": "502205", "SUB_DISTRICT_NAME": "ดอนเปา", "DISTRICT_ID": 589, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5340, "SUB_DISTRICT_CODE": "502301", "SUB_DISTRICT_NAME": "ออนเหนือ", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5341, "SUB_DISTRICT_CODE": "502302", "SUB_DISTRICT_NAME": "ออนกลาง", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5342, "SUB_DISTRICT_CODE": "502303", "SUB_DISTRICT_NAME": "บ้านสหกรณ์", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5343, "SUB_DISTRICT_CODE": "502304", "SUB_DISTRICT_NAME": "ห้วยแก้ว", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5344, "SUB_DISTRICT_CODE": "502305", "SUB_DISTRICT_NAME": "แม่ทา", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5345, "SUB_DISTRICT_CODE": "502306", "SUB_DISTRICT_NAME": "ทาเหนือ", "DISTRICT_ID": 590, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5346, "SUB_DISTRICT_CODE": "502401", "SUB_DISTRICT_NAME": "ดอยหล่อ", "DISTRICT_ID": 591, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5347, "SUB_DISTRICT_CODE": "502402", "SUB_DISTRICT_NAME": "สองแคว", "DISTRICT_ID": 591, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5348, "SUB_DISTRICT_CODE": "502403", "SUB_DISTRICT_NAME": "ยางคราม", "DISTRICT_ID": 591, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5349, "SUB_DISTRICT_CODE": "502404", "SUB_DISTRICT_NAME": "สันติสุข", "DISTRICT_ID": 591, "PROVINCE_ID": 38, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5350, "SUB_DISTRICT_CODE": "510101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5351, "SUB_DISTRICT_CODE": "510102", "SUB_DISTRICT_NAME": "เหมืองง่า", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5352, "SUB_DISTRICT_CODE": "510103", "SUB_DISTRICT_NAME": "อุโมงค์", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5353, "SUB_DISTRICT_CODE": "510104", "SUB_DISTRICT_NAME": "หนองช้างคืน", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5354, "SUB_DISTRICT_CODE": "510105", "SUB_DISTRICT_NAME": "ประตูป่า", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5355, "SUB_DISTRICT_CODE": "510106", "SUB_DISTRICT_NAME": "ริมปิง", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5356, "SUB_DISTRICT_CODE": "510107", "SUB_DISTRICT_NAME": "ต้นธง", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5357, "SUB_DISTRICT_CODE": "510108", "SUB_DISTRICT_NAME": "บ้านแป้น", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5358, "SUB_DISTRICT_CODE": "510109", "SUB_DISTRICT_NAME": "เหมืองจี้", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5359, "SUB_DISTRICT_CODE": "510110", "SUB_DISTRICT_NAME": "ป่าสัก", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5360, "SUB_DISTRICT_CODE": "510111", "SUB_DISTRICT_NAME": "เวียงยอง", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5361, "SUB_DISTRICT_CODE": "510112", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5362, "SUB_DISTRICT_CODE": "510113", "SUB_DISTRICT_NAME": "มะเขือแจ้", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5363, "SUB_DISTRICT_CODE": "510114", "SUB_DISTRICT_NAME": "*บ้านธิ", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5364, "SUB_DISTRICT_CODE": "510115", "SUB_DISTRICT_NAME": "*ห้วยยาบ", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5365, "SUB_DISTRICT_CODE": "510116", "SUB_DISTRICT_NAME": "ศรีบัวบาน", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5366, "SUB_DISTRICT_CODE": "510117", "SUB_DISTRICT_NAME": "หนองหนาม", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5367, "SUB_DISTRICT_CODE": "510198", "SUB_DISTRICT_NAME": "*ห้วยยาบ", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5368, "SUB_DISTRICT_CODE": "510199", "SUB_DISTRICT_NAME": "*บ้านธิ", "DISTRICT_ID": 595, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5369, "SUB_DISTRICT_CODE": "510201", "SUB_DISTRICT_NAME": "ทาปลาดุก", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5370, "SUB_DISTRICT_CODE": "510202", "SUB_DISTRICT_NAME": "ทาสบเส้า", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5371, "SUB_DISTRICT_CODE": "510203", "SUB_DISTRICT_NAME": "ทากาศ", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5372, "SUB_DISTRICT_CODE": "510204", "SUB_DISTRICT_NAME": "ทาขุมเงิน", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5373, "SUB_DISTRICT_CODE": "510205", "SUB_DISTRICT_NAME": "ทาทุ่งหลวง", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5374, "SUB_DISTRICT_CODE": "510206", "SUB_DISTRICT_NAME": "ทาแม่ลอบ", "DISTRICT_ID": 596, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5375, "SUB_DISTRICT_CODE": "510301", "SUB_DISTRICT_NAME": "บ้านโฮ่ง", "DISTRICT_ID": 597, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5376, "SUB_DISTRICT_CODE": "510302", "SUB_DISTRICT_NAME": "ป่าพลู", "DISTRICT_ID": 597, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5377, "SUB_DISTRICT_CODE": "510303", "SUB_DISTRICT_NAME": "เหล่ายาว", "DISTRICT_ID": 597, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5378, "SUB_DISTRICT_CODE": "510304", "SUB_DISTRICT_NAME": "ศรีเตี้ย", "DISTRICT_ID": 597, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5379, "SUB_DISTRICT_CODE": "510305", "SUB_DISTRICT_NAME": "หนองปลาสะวาย", "DISTRICT_ID": 597, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5380, "SUB_DISTRICT_CODE": "510401", "SUB_DISTRICT_NAME": "ลี้", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5381, "SUB_DISTRICT_CODE": "510402", "SUB_DISTRICT_NAME": "แม่ตืน", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5382, "SUB_DISTRICT_CODE": "510403", "SUB_DISTRICT_NAME": "นาทราย", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5383, "SUB_DISTRICT_CODE": "510404", "SUB_DISTRICT_NAME": "ดงดำ", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5384, "SUB_DISTRICT_CODE": "510405", "SUB_DISTRICT_NAME": "ก้อ", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5385, "SUB_DISTRICT_CODE": "510406", "SUB_DISTRICT_NAME": "แม่ลาน", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5386, "SUB_DISTRICT_CODE": "510407", "SUB_DISTRICT_NAME": "บ้านไผ่*", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5387, "SUB_DISTRICT_CODE": "510408", "SUB_DISTRICT_NAME": "ป่าไผ่", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5388, "SUB_DISTRICT_CODE": "510409", "SUB_DISTRICT_NAME": "ศรีวิชัย", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5389, "SUB_DISTRICT_CODE": "510498", "SUB_DISTRICT_NAME": "*บ้านปวง", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5390, "SUB_DISTRICT_CODE": "510499", "SUB_DISTRICT_NAME": "*ทุ่งหัวช้าง", "DISTRICT_ID": 598, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5391, "SUB_DISTRICT_CODE": "510501", "SUB_DISTRICT_NAME": "ทุ่งหัวช้าง", "DISTRICT_ID": 599, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5392, "SUB_DISTRICT_CODE": "510502", "SUB_DISTRICT_NAME": "บ้านปวง", "DISTRICT_ID": 599, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5393, "SUB_DISTRICT_CODE": "510503", "SUB_DISTRICT_NAME": "ตะเคียนปม", "DISTRICT_ID": 599, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5394, "SUB_DISTRICT_CODE": "510601", "SUB_DISTRICT_NAME": "ปากบ่อง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5395, "SUB_DISTRICT_CODE": "510602", "SUB_DISTRICT_NAME": "ป่าซาง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5396, "SUB_DISTRICT_CODE": "510603", "SUB_DISTRICT_NAME": "แม่แรง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5397, "SUB_DISTRICT_CODE": "510604", "SUB_DISTRICT_NAME": "ม่วงน้อย", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5398, "SUB_DISTRICT_CODE": "510605", "SUB_DISTRICT_NAME": "บ้านเรือน", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5399, "SUB_DISTRICT_CODE": "510606", "SUB_DISTRICT_NAME": "มะกอก", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5400, "SUB_DISTRICT_CODE": "510607", "SUB_DISTRICT_NAME": "ท่าตุ้ม", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5401, "SUB_DISTRICT_CODE": "510608", "SUB_DISTRICT_NAME": "น้ำดิบ", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5402, "SUB_DISTRICT_CODE": "510609", "SUB_DISTRICT_NAME": "*วังผาง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5403, "SUB_DISTRICT_CODE": "510610", "SUB_DISTRICT_NAME": "*หนองล่อง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5404, "SUB_DISTRICT_CODE": "510611", "SUB_DISTRICT_NAME": "นครเจดีย์", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5405, "SUB_DISTRICT_CODE": "510612", "SUB_DISTRICT_NAME": "*หนองยวง", "DISTRICT_ID": 600, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5406, "SUB_DISTRICT_CODE": "510701", "SUB_DISTRICT_NAME": "บ้านธิ", "DISTRICT_ID": 601, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5407, "SUB_DISTRICT_CODE": "510702", "SUB_DISTRICT_NAME": "ห้วยยาบ", "DISTRICT_ID": 601, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5408, "SUB_DISTRICT_CODE": "510801", "SUB_DISTRICT_NAME": "หนองล่อง", "DISTRICT_ID": 602, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5409, "SUB_DISTRICT_CODE": "510802", "SUB_DISTRICT_NAME": "หนองยวง", "DISTRICT_ID": 602, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5410, "SUB_DISTRICT_CODE": "510803", "SUB_DISTRICT_NAME": "วังผาง", "DISTRICT_ID": 602, "PROVINCE_ID": 39, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5411, "SUB_DISTRICT_CODE": "520101", "SUB_DISTRICT_NAME": "เวียงเหนือ", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5412, "SUB_DISTRICT_CODE": "520102", "SUB_DISTRICT_NAME": "หัวเวียง", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5413, "SUB_DISTRICT_CODE": "520103", "SUB_DISTRICT_NAME": "สวนดอก", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5414, "SUB_DISTRICT_CODE": "520104", "SUB_DISTRICT_NAME": "สบตุ๋ย", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5415, "SUB_DISTRICT_CODE": "520105", "SUB_DISTRICT_NAME": "พระบาท", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5416, "SUB_DISTRICT_CODE": "520106", "SUB_DISTRICT_NAME": "ชมพู", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5417, "SUB_DISTRICT_CODE": "520107", "SUB_DISTRICT_NAME": "กล้วยแพะ", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5418, "SUB_DISTRICT_CODE": "520108", "SUB_DISTRICT_NAME": "ปงแสนทอง", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5419, "SUB_DISTRICT_CODE": "520109", "SUB_DISTRICT_NAME": "บ้านแลง", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5420, "SUB_DISTRICT_CODE": "520110", "SUB_DISTRICT_NAME": "บ้านเสด็จ", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5421, "SUB_DISTRICT_CODE": "520111", "SUB_DISTRICT_NAME": "พิชัย", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5422, "SUB_DISTRICT_CODE": "520112", "SUB_DISTRICT_NAME": "ทุ่งฝาย", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5423, "SUB_DISTRICT_CODE": "520113", "SUB_DISTRICT_NAME": "บ้านเอื้อม", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5424, "SUB_DISTRICT_CODE": "520114", "SUB_DISTRICT_NAME": "บ้านเป้า", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5425, "SUB_DISTRICT_CODE": "520115", "SUB_DISTRICT_NAME": "บ้านค่า", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5426, "SUB_DISTRICT_CODE": "520116", "SUB_DISTRICT_NAME": "บ่อแฮ้ว", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5427, "SUB_DISTRICT_CODE": "520117", "SUB_DISTRICT_NAME": "ต้นธงชัย", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5428, "SUB_DISTRICT_CODE": "520118", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5429, "SUB_DISTRICT_CODE": "520119", "SUB_DISTRICT_NAME": "บุญนาคพัฒนา", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5430, "SUB_DISTRICT_CODE": "520198", "SUB_DISTRICT_NAME": "*นาสัก", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5431, "SUB_DISTRICT_CODE": "520199", "SUB_DISTRICT_NAME": "*บ้านดง", "DISTRICT_ID": 603, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5432, "SUB_DISTRICT_CODE": "520201", "SUB_DISTRICT_NAME": "บ้านดง", "DISTRICT_ID": 604, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5433, "SUB_DISTRICT_CODE": "520202", "SUB_DISTRICT_NAME": "นาสัก", "DISTRICT_ID": 604, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5434, "SUB_DISTRICT_CODE": "520203", "SUB_DISTRICT_NAME": "จางเหนือ", "DISTRICT_ID": 604, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5435, "SUB_DISTRICT_CODE": "520204", "SUB_DISTRICT_NAME": "แม่เมาะ", "DISTRICT_ID": 604, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5436, "SUB_DISTRICT_CODE": "520205", "SUB_DISTRICT_NAME": "สบป้าด", "DISTRICT_ID": 604, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5437, "SUB_DISTRICT_CODE": "520301", "SUB_DISTRICT_NAME": "ลำปางหลวง", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5438, "SUB_DISTRICT_CODE": "520302", "SUB_DISTRICT_NAME": "นาแก้ว", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5439, "SUB_DISTRICT_CODE": "520303", "SUB_DISTRICT_NAME": "ไหล่หิน", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5440, "SUB_DISTRICT_CODE": "520304", "SUB_DISTRICT_NAME": "วังพร้าว", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5441, "SUB_DISTRICT_CODE": "520305", "SUB_DISTRICT_NAME": "ศาลา", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5442, "SUB_DISTRICT_CODE": "520306", "SUB_DISTRICT_NAME": "เกาะคา", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5443, "SUB_DISTRICT_CODE": "520307", "SUB_DISTRICT_NAME": "นาแส่ง", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5444, "SUB_DISTRICT_CODE": "520308", "SUB_DISTRICT_NAME": "ท่าผา", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5445, "SUB_DISTRICT_CODE": "520309", "SUB_DISTRICT_NAME": "ใหม่พัฒนา", "DISTRICT_ID": 605, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5446, "SUB_DISTRICT_CODE": "520401", "SUB_DISTRICT_NAME": "ทุ่งงาม", "DISTRICT_ID": 606, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5447, "SUB_DISTRICT_CODE": "520402", "SUB_DISTRICT_NAME": "เสริมขวา", "DISTRICT_ID": 606, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5448, "SUB_DISTRICT_CODE": "520403", "SUB_DISTRICT_NAME": "เสริมซ้าย", "DISTRICT_ID": 606, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5449, "SUB_DISTRICT_CODE": "520404", "SUB_DISTRICT_NAME": "เสริมกลาง", "DISTRICT_ID": 606, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5450, "SUB_DISTRICT_CODE": "520501", "SUB_DISTRICT_NAME": "หลวงเหนือ", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5451, "SUB_DISTRICT_CODE": "520502", "SUB_DISTRICT_NAME": "หลวงใต้", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5452, "SUB_DISTRICT_CODE": "520503", "SUB_DISTRICT_NAME": "บ้านโป่ง", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5453, "SUB_DISTRICT_CODE": "520504", "SUB_DISTRICT_NAME": "บ้านร้อง", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5454, "SUB_DISTRICT_CODE": "520505", "SUB_DISTRICT_NAME": "ปงเตา", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5455, "SUB_DISTRICT_CODE": "520506", "SUB_DISTRICT_NAME": "นาแก", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5456, "SUB_DISTRICT_CODE": "520507", "SUB_DISTRICT_NAME": "บ้านอ้อน", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5457, "SUB_DISTRICT_CODE": "520508", "SUB_DISTRICT_NAME": "บ้านแหง", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5458, "SUB_DISTRICT_CODE": "520509", "SUB_DISTRICT_NAME": "บ้านหวด", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5459, "SUB_DISTRICT_CODE": "520510", "SUB_DISTRICT_NAME": "แม่ตีบ", "DISTRICT_ID": 607, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5460, "SUB_DISTRICT_CODE": "520601", "SUB_DISTRICT_NAME": "แจ้ห่ม", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5461, "SUB_DISTRICT_CODE": "520602", "SUB_DISTRICT_NAME": "บ้านสา", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5462, "SUB_DISTRICT_CODE": "520603", "SUB_DISTRICT_NAME": "ปงดอน", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5463, "SUB_DISTRICT_CODE": "520604", "SUB_DISTRICT_NAME": "แม่สุก", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5464, "SUB_DISTRICT_CODE": "520605", "SUB_DISTRICT_NAME": "เมืองมาย", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5465, "SUB_DISTRICT_CODE": "520606", "SUB_DISTRICT_NAME": "ทุ่งผึ้ง", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5466, "SUB_DISTRICT_CODE": "520607", "SUB_DISTRICT_NAME": "วิเชตนคร", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5467, "SUB_DISTRICT_CODE": "520696", "SUB_DISTRICT_NAME": "*แจ้ซ้อน", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5468, "SUB_DISTRICT_CODE": "520697", "SUB_DISTRICT_NAME": "*ทุ่งกว๋าว", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5469, "SUB_DISTRICT_CODE": "520698", "SUB_DISTRICT_NAME": "*บ้านขอ", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5470, "SUB_DISTRICT_CODE": "520699", "SUB_DISTRICT_NAME": "*เมืองปาน", "DISTRICT_ID": 608, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5471, "SUB_DISTRICT_CODE": "520701", "SUB_DISTRICT_NAME": "ทุ่งฮั้ว", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5472, "SUB_DISTRICT_CODE": "520702", "SUB_DISTRICT_NAME": "วังเหนือ", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5473, "SUB_DISTRICT_CODE": "520703", "SUB_DISTRICT_NAME": "วังใต้", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5474, "SUB_DISTRICT_CODE": "520704", "SUB_DISTRICT_NAME": "ร่องเคาะ", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5475, "SUB_DISTRICT_CODE": "520705", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5476, "SUB_DISTRICT_CODE": "520706", "SUB_DISTRICT_NAME": "วังซ้าย", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5477, "SUB_DISTRICT_CODE": "520707", "SUB_DISTRICT_NAME": "วังแก้ว", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5478, "SUB_DISTRICT_CODE": "520708", "SUB_DISTRICT_NAME": "วังทรายคำ", "DISTRICT_ID": 609, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5479, "SUB_DISTRICT_CODE": "520801", "SUB_DISTRICT_NAME": "ล้อมแรด", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5480, "SUB_DISTRICT_CODE": "520802", "SUB_DISTRICT_NAME": "แม่วะ", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5481, "SUB_DISTRICT_CODE": "520803", "SUB_DISTRICT_NAME": "แม่ปะ", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5482, "SUB_DISTRICT_CODE": "520804", "SUB_DISTRICT_NAME": "แม่มอก", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5483, "SUB_DISTRICT_CODE": "520805", "SUB_DISTRICT_NAME": "เวียงมอก", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5484, "SUB_DISTRICT_CODE": "520806", "SUB_DISTRICT_NAME": "นาโป่ง", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5485, "SUB_DISTRICT_CODE": "520807", "SUB_DISTRICT_NAME": "แม่ถอด", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5486, "SUB_DISTRICT_CODE": "520808", "SUB_DISTRICT_NAME": "เถินบุรี", "DISTRICT_ID": 610, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5487, "SUB_DISTRICT_CODE": "520901", "SUB_DISTRICT_NAME": "แม่พริก", "DISTRICT_ID": 611, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5488, "SUB_DISTRICT_CODE": "520902", "SUB_DISTRICT_NAME": "ผาปัง", "DISTRICT_ID": 611, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5489, "SUB_DISTRICT_CODE": "520903", "SUB_DISTRICT_NAME": "แม่ปุ", "DISTRICT_ID": 611, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5490, "SUB_DISTRICT_CODE": "520904", "SUB_DISTRICT_NAME": "พระบาทวังตวง", "DISTRICT_ID": 611, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5491, "SUB_DISTRICT_CODE": "521001", "SUB_DISTRICT_NAME": "แม่ทะ", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5492, "SUB_DISTRICT_CODE": "521002", "SUB_DISTRICT_NAME": "นาครัว", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5493, "SUB_DISTRICT_CODE": "521003", "SUB_DISTRICT_NAME": "ป่าตัน", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5494, "SUB_DISTRICT_CODE": "521004", "SUB_DISTRICT_NAME": "บ้านกิ่ว", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5495, "SUB_DISTRICT_CODE": "521005", "SUB_DISTRICT_NAME": "บ้านบอม", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5496, "SUB_DISTRICT_CODE": "521006", "SUB_DISTRICT_NAME": "น้ำโจ้", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5497, "SUB_DISTRICT_CODE": "521007", "SUB_DISTRICT_NAME": "ดอนไฟ", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5498, "SUB_DISTRICT_CODE": "521008", "SUB_DISTRICT_NAME": "หัวเสือ", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5499, "SUB_DISTRICT_CODE": "521009", "SUB_DISTRICT_NAME": "สบป้าด*", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5500, "SUB_DISTRICT_CODE": "521010", "SUB_DISTRICT_NAME": "วังเงิน", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5501, "SUB_DISTRICT_CODE": "521011", "SUB_DISTRICT_NAME": "สันดอนแก้ว", "DISTRICT_ID": 612, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5502, "SUB_DISTRICT_CODE": "521101", "SUB_DISTRICT_NAME": "สบปราบ", "DISTRICT_ID": 613, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5503, "SUB_DISTRICT_CODE": "521102", "SUB_DISTRICT_NAME": "สมัย", "DISTRICT_ID": 613, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5504, "SUB_DISTRICT_CODE": "521103", "SUB_DISTRICT_NAME": "แม่กัวะ", "DISTRICT_ID": 613, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5505, "SUB_DISTRICT_CODE": "521104", "SUB_DISTRICT_NAME": "นายาง", "DISTRICT_ID": 613, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5506, "SUB_DISTRICT_CODE": "521201", "SUB_DISTRICT_NAME": "ห้างฉัตร", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5507, "SUB_DISTRICT_CODE": "521202", "SUB_DISTRICT_NAME": "หนองหล่ม", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5508, "SUB_DISTRICT_CODE": "521203", "SUB_DISTRICT_NAME": "เมืองยาว", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5509, "SUB_DISTRICT_CODE": "521204", "SUB_DISTRICT_NAME": "ปงยางคก", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5510, "SUB_DISTRICT_CODE": "521205", "SUB_DISTRICT_NAME": "เวียงตาล", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5511, "SUB_DISTRICT_CODE": "521206", "SUB_DISTRICT_NAME": "แม่สัน", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5512, "SUB_DISTRICT_CODE": "521207", "SUB_DISTRICT_NAME": "วอแก้ว", "DISTRICT_ID": 614, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5513, "SUB_DISTRICT_CODE": "521301", "SUB_DISTRICT_NAME": "เมืองปาน", "DISTRICT_ID": 615, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5514, "SUB_DISTRICT_CODE": "521302", "SUB_DISTRICT_NAME": "บ้านขอ", "DISTRICT_ID": 615, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5515, "SUB_DISTRICT_CODE": "521303", "SUB_DISTRICT_NAME": "ทุ่งกว๋าว", "DISTRICT_ID": 615, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5516, "SUB_DISTRICT_CODE": "521304", "SUB_DISTRICT_NAME": "แจ้ซ้อน", "DISTRICT_ID": 615, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5517, "SUB_DISTRICT_CODE": "521305", "SUB_DISTRICT_NAME": "หัวเมือง", "DISTRICT_ID": 615, "PROVINCE_ID": 40, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5518, "SUB_DISTRICT_CODE": "530101", "SUB_DISTRICT_NAME": "ท่าอิฐ", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5519, "SUB_DISTRICT_CODE": "530102", "SUB_DISTRICT_NAME": "ท่าเสา", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5520, "SUB_DISTRICT_CODE": "530103", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5521, "SUB_DISTRICT_CODE": "530104", "SUB_DISTRICT_NAME": "ป่าเซ่า", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5522, "SUB_DISTRICT_CODE": "530105", "SUB_DISTRICT_NAME": "คุ้งตะเภา", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5523, "SUB_DISTRICT_CODE": "530106", "SUB_DISTRICT_NAME": "วังกะพี้", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5524, "SUB_DISTRICT_CODE": "530107", "SUB_DISTRICT_NAME": "หาดกรวด", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5525, "SUB_DISTRICT_CODE": "530108", "SUB_DISTRICT_NAME": "น้ำริด", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5526, "SUB_DISTRICT_CODE": "530109", "SUB_DISTRICT_NAME": "งิ้วงาม", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5527, "SUB_DISTRICT_CODE": "530110", "SUB_DISTRICT_NAME": "บ้านด่านนาขาม", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5528, "SUB_DISTRICT_CODE": "530111", "SUB_DISTRICT_NAME": "บ้านด่าน", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5529, "SUB_DISTRICT_CODE": "530112", "SUB_DISTRICT_NAME": "ผาจุก", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5530, "SUB_DISTRICT_CODE": "530113", "SUB_DISTRICT_NAME": "วังดิน", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5531, "SUB_DISTRICT_CODE": "530114", "SUB_DISTRICT_NAME": "แสนตอ", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5532, "SUB_DISTRICT_CODE": "530115", "SUB_DISTRICT_NAME": "หาดงิ้ว", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5533, "SUB_DISTRICT_CODE": "530116", "SUB_DISTRICT_NAME": "ขุนฝาง", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5534, "SUB_DISTRICT_CODE": "530117", "SUB_DISTRICT_NAME": "ถ้ำฉลอง", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5535, "SUB_DISTRICT_CODE": "530199", "SUB_DISTRICT_NAME": "*ร่วมจิตร", "DISTRICT_ID": 616, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5536, "SUB_DISTRICT_CODE": "530201", "SUB_DISTRICT_NAME": "วังแดง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5537, "SUB_DISTRICT_CODE": "530202", "SUB_DISTRICT_NAME": "บ้านแก่ง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5538, "SUB_DISTRICT_CODE": "530203", "SUB_DISTRICT_NAME": "หาดสองแคว", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5539, "SUB_DISTRICT_CODE": "530204", "SUB_DISTRICT_NAME": "น้ำอ่าง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5540, "SUB_DISTRICT_CODE": "530205", "SUB_DISTRICT_NAME": "ข่อยสูง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5541, "SUB_DISTRICT_CODE": "530296", "SUB_DISTRICT_NAME": "*น้ำพี้", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5542, "SUB_DISTRICT_CODE": "530297", "SUB_DISTRICT_NAME": "*บ่อทอง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5543, "SUB_DISTRICT_CODE": "530298", "SUB_DISTRICT_NAME": "*ผักขวง", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5544, "SUB_DISTRICT_CODE": "530299", "SUB_DISTRICT_NAME": "*ป่าคาย", "DISTRICT_ID": 617, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5545, "SUB_DISTRICT_CODE": "530301", "SUB_DISTRICT_NAME": "ท่าปลา", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5546, "SUB_DISTRICT_CODE": "530302", "SUB_DISTRICT_NAME": "หาดล้า", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5547, "SUB_DISTRICT_CODE": "530303", "SUB_DISTRICT_NAME": "ผาเลือด", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5548, "SUB_DISTRICT_CODE": "530304", "SUB_DISTRICT_NAME": "จริม", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5549, "SUB_DISTRICT_CODE": "530305", "SUB_DISTRICT_NAME": "น้ำหมัน", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5550, "SUB_DISTRICT_CODE": "530306", "SUB_DISTRICT_NAME": "ท่าแฝก", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5551, "SUB_DISTRICT_CODE": "530307", "SUB_DISTRICT_NAME": "นางพญา", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5552, "SUB_DISTRICT_CODE": "530308", "SUB_DISTRICT_NAME": "ร่วมจิต", "DISTRICT_ID": 618, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5553, "SUB_DISTRICT_CODE": "530401", "SUB_DISTRICT_NAME": "แสนตอ", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5554, "SUB_DISTRICT_CODE": "530402", "SUB_DISTRICT_NAME": "บ้านฝาย", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5555, "SUB_DISTRICT_CODE": "530403", "SUB_DISTRICT_NAME": "เด่นเหล็ก", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5556, "SUB_DISTRICT_CODE": "530404", "SUB_DISTRICT_NAME": "น้ำไคร้", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5557, "SUB_DISTRICT_CODE": "530405", "SUB_DISTRICT_NAME": "น้ำไผ่", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5558, "SUB_DISTRICT_CODE": "530406", "SUB_DISTRICT_NAME": "ห้วยมุ่น", "DISTRICT_ID": 619, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5559, "SUB_DISTRICT_CODE": "530501", "SUB_DISTRICT_NAME": "ฟากท่า", "DISTRICT_ID": 620, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5560, "SUB_DISTRICT_CODE": "530502", "SUB_DISTRICT_NAME": "สองคอน", "DISTRICT_ID": 620, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5561, "SUB_DISTRICT_CODE": "530503", "SUB_DISTRICT_NAME": "บ้านเสี้ยว", "DISTRICT_ID": 620, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5562, "SUB_DISTRICT_CODE": "530504", "SUB_DISTRICT_NAME": "สองห้อง", "DISTRICT_ID": 620, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5563, "SUB_DISTRICT_CODE": "530601", "SUB_DISTRICT_NAME": "ม่วงเจ็ดต้น", "DISTRICT_ID": 621, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5564, "SUB_DISTRICT_CODE": "530602", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 621, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5565, "SUB_DISTRICT_CODE": "530603", "SUB_DISTRICT_NAME": "นาขุม", "DISTRICT_ID": 621, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5566, "SUB_DISTRICT_CODE": "530604", "SUB_DISTRICT_NAME": "บ่อเบี้ย", "DISTRICT_ID": 621, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5567, "SUB_DISTRICT_CODE": "530701", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5568, "SUB_DISTRICT_CODE": "530702", "SUB_DISTRICT_NAME": "บ้านดารา", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5569, "SUB_DISTRICT_CODE": "530703", "SUB_DISTRICT_NAME": "ไร่อ้อย", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5570, "SUB_DISTRICT_CODE": "530704", "SUB_DISTRICT_NAME": "ท่าสัก", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5571, "SUB_DISTRICT_CODE": "530705", "SUB_DISTRICT_NAME": "คอรุม", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5572, "SUB_DISTRICT_CODE": "530706", "SUB_DISTRICT_NAME": "บ้านหม้อ", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5573, "SUB_DISTRICT_CODE": "530707", "SUB_DISTRICT_NAME": "ท่ามะเฟือง", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5574, "SUB_DISTRICT_CODE": "530708", "SUB_DISTRICT_NAME": "บ้านโคน", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5575, "SUB_DISTRICT_CODE": "530709", "SUB_DISTRICT_NAME": "พญาแมน", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5576, "SUB_DISTRICT_CODE": "530710", "SUB_DISTRICT_NAME": "นาอิน", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5577, "SUB_DISTRICT_CODE": "530711", "SUB_DISTRICT_NAME": "นายาง", "DISTRICT_ID": 622, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5578, "SUB_DISTRICT_CODE": "530801", "SUB_DISTRICT_NAME": "ศรีพนมมาศ", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5579, "SUB_DISTRICT_CODE": "530802", "SUB_DISTRICT_NAME": "แม่พูล", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5580, "SUB_DISTRICT_CODE": "530803", "SUB_DISTRICT_NAME": "นานกกก", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5581, "SUB_DISTRICT_CODE": "530804", "SUB_DISTRICT_NAME": "ฝายหลวง", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5582, "SUB_DISTRICT_CODE": "530805", "SUB_DISTRICT_NAME": "ชัยจุมพล", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5583, "SUB_DISTRICT_CODE": "530806", "SUB_DISTRICT_NAME": "ไผ่ล้อม", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5584, "SUB_DISTRICT_CODE": "530807", "SUB_DISTRICT_NAME": "ทุ่งยั้ง", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5585, "SUB_DISTRICT_CODE": "530808", "SUB_DISTRICT_NAME": "ด่านแม่คำมัน", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5586, "SUB_DISTRICT_CODE": "530899", "SUB_DISTRICT_NAME": "*ศรีพนมมาศ", "DISTRICT_ID": 623, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5587, "SUB_DISTRICT_CODE": "530901", "SUB_DISTRICT_NAME": "ผักขวง", "DISTRICT_ID": 624, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5588, "SUB_DISTRICT_CODE": "530902", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 624, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5589, "SUB_DISTRICT_CODE": "530903", "SUB_DISTRICT_NAME": "ป่าคาย", "DISTRICT_ID": 624, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5590, "SUB_DISTRICT_CODE": "530904", "SUB_DISTRICT_NAME": "น้ำพี้", "DISTRICT_ID": 624, "PROVINCE_ID": 41, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5591, "SUB_DISTRICT_CODE": "540101", "SUB_DISTRICT_NAME": "ในเวียง", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5592, "SUB_DISTRICT_CODE": "540102", "SUB_DISTRICT_NAME": "นาจักร", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5593, "SUB_DISTRICT_CODE": "540103", "SUB_DISTRICT_NAME": "น้ำชำ", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5594, "SUB_DISTRICT_CODE": "540104", "SUB_DISTRICT_NAME": "ป่าแดง", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5595, "SUB_DISTRICT_CODE": "540105", "SUB_DISTRICT_NAME": "ทุ่งโฮ้ง", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5596, "SUB_DISTRICT_CODE": "540106", "SUB_DISTRICT_NAME": "เหมืองหม้อ", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5597, "SUB_DISTRICT_CODE": "540107", "SUB_DISTRICT_NAME": "วังธง", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5598, "SUB_DISTRICT_CODE": "540108", "SUB_DISTRICT_NAME": "แม่หล่าย", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5599, "SUB_DISTRICT_CODE": "540109", "SUB_DISTRICT_NAME": "ห้วยม้า", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5600, "SUB_DISTRICT_CODE": "540110", "SUB_DISTRICT_NAME": "ป่าแมต", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5601, "SUB_DISTRICT_CODE": "540111", "SUB_DISTRICT_NAME": "บ้านถิ่น", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5602, "SUB_DISTRICT_CODE": "540112", "SUB_DISTRICT_NAME": "สวนเขื่อน", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5603, "SUB_DISTRICT_CODE": "540113", "SUB_DISTRICT_NAME": "วังหงส์", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5604, "SUB_DISTRICT_CODE": "540114", "SUB_DISTRICT_NAME": "แม่คำมี", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5605, "SUB_DISTRICT_CODE": "540115", "SUB_DISTRICT_NAME": "ทุ่งกวาว", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5606, "SUB_DISTRICT_CODE": "540116", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5607, "SUB_DISTRICT_CODE": "540117", "SUB_DISTRICT_NAME": "แม่ยม", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5608, "SUB_DISTRICT_CODE": "540118", "SUB_DISTRICT_NAME": "ช่อแฮ", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5609, "SUB_DISTRICT_CODE": "540119", "SUB_DISTRICT_NAME": "ร่องฟอง", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5610, "SUB_DISTRICT_CODE": "540120", "SUB_DISTRICT_NAME": "กาญจนา", "DISTRICT_ID": 625, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5611, "SUB_DISTRICT_CODE": "540201", "SUB_DISTRICT_NAME": "ร้องกวาง", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5612, "SUB_DISTRICT_CODE": "540202", "SUB_DISTRICT_NAME": "*หนองม่วงไข่", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5613, "SUB_DISTRICT_CODE": "540203", "SUB_DISTRICT_NAME": "*แม่คำมี", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5614, "SUB_DISTRICT_CODE": "540204", "SUB_DISTRICT_NAME": "ร้องเข็ม", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5615, "SUB_DISTRICT_CODE": "540205", "SUB_DISTRICT_NAME": "น้ำเลา", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5616, "SUB_DISTRICT_CODE": "540206", "SUB_DISTRICT_NAME": "บ้านเวียง", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5617, "SUB_DISTRICT_CODE": "540207", "SUB_DISTRICT_NAME": "ทุ่งศรี", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5618, "SUB_DISTRICT_CODE": "540208", "SUB_DISTRICT_NAME": "แม่ยางตาล", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5619, "SUB_DISTRICT_CODE": "540209", "SUB_DISTRICT_NAME": "แม่ยางฮ่อ", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5620, "SUB_DISTRICT_CODE": "540210", "SUB_DISTRICT_NAME": "ไผ่โทน", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5621, "SUB_DISTRICT_CODE": "540211", "SUB_DISTRICT_NAME": "*น้ำรัด", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5622, "SUB_DISTRICT_CODE": "540212", "SUB_DISTRICT_NAME": "*วังหลวง", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5623, "SUB_DISTRICT_CODE": "540213", "SUB_DISTRICT_NAME": "ห้วยโรง", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5624, "SUB_DISTRICT_CODE": "540214", "SUB_DISTRICT_NAME": "แม่ทราย", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5625, "SUB_DISTRICT_CODE": "540215", "SUB_DISTRICT_NAME": "แม่ยางร้อง", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5626, "SUB_DISTRICT_CODE": "540298", "SUB_DISTRICT_NAME": "*หนองม่วงไข่", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5627, "SUB_DISTRICT_CODE": "540299", "SUB_DISTRICT_NAME": "*แม่คำมี", "DISTRICT_ID": 626, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5628, "SUB_DISTRICT_CODE": "540301", "SUB_DISTRICT_NAME": "ห้วยอ้อ", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5629, "SUB_DISTRICT_CODE": "540302", "SUB_DISTRICT_NAME": "บ้านปิน", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5630, "SUB_DISTRICT_CODE": "540303", "SUB_DISTRICT_NAME": "ต้าผามอก", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5631, "SUB_DISTRICT_CODE": "540304", "SUB_DISTRICT_NAME": "เวียงต้า", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5632, "SUB_DISTRICT_CODE": "540305", "SUB_DISTRICT_NAME": "ปากกาง", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5633, "SUB_DISTRICT_CODE": "540306", "SUB_DISTRICT_NAME": "หัวทุ่ง", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5634, "SUB_DISTRICT_CODE": "540307", "SUB_DISTRICT_NAME": "ทุ่งแล้ง", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5635, "SUB_DISTRICT_CODE": "540308", "SUB_DISTRICT_NAME": "บ่อเหล็กลอง", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5636, "SUB_DISTRICT_CODE": "540309", "SUB_DISTRICT_NAME": "แม่ปาน", "DISTRICT_ID": 627, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5637, "SUB_DISTRICT_CODE": "540401", "SUB_DISTRICT_NAME": "สูงเม่น", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5638, "SUB_DISTRICT_CODE": "540402", "SUB_DISTRICT_NAME": "น้ำชำ", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5639, "SUB_DISTRICT_CODE": "540403", "SUB_DISTRICT_NAME": "หัวฝาย", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5640, "SUB_DISTRICT_CODE": "540404", "SUB_DISTRICT_NAME": "ดอนมูล", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5641, "SUB_DISTRICT_CODE": "540405", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5642, "SUB_DISTRICT_CODE": "540406", "SUB_DISTRICT_NAME": "บ้านกวาง", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5643, "SUB_DISTRICT_CODE": "540407", "SUB_DISTRICT_NAME": "บ้านปง", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5644, "SUB_DISTRICT_CODE": "540408", "SUB_DISTRICT_NAME": "บ้านกาศ", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5645, "SUB_DISTRICT_CODE": "540409", "SUB_DISTRICT_NAME": "ร่องกาศ", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5646, "SUB_DISTRICT_CODE": "540410", "SUB_DISTRICT_NAME": "สบสาย", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5647, "SUB_DISTRICT_CODE": "540411", "SUB_DISTRICT_NAME": "เวียงทอง", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5648, "SUB_DISTRICT_CODE": "540412", "SUB_DISTRICT_NAME": "พระหลวง", "DISTRICT_ID": 628, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5649, "SUB_DISTRICT_CODE": "540501", "SUB_DISTRICT_NAME": "เด่นชัย", "DISTRICT_ID": 629, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5650, "SUB_DISTRICT_CODE": "540502", "SUB_DISTRICT_NAME": "แม่จั๊วะ", "DISTRICT_ID": 629, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5651, "SUB_DISTRICT_CODE": "540503", "SUB_DISTRICT_NAME": "ไทรย้อย", "DISTRICT_ID": 629, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5652, "SUB_DISTRICT_CODE": "540504", "SUB_DISTRICT_NAME": "ห้วยไร่", "DISTRICT_ID": 629, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5653, "SUB_DISTRICT_CODE": "540505", "SUB_DISTRICT_NAME": "ปงป่าหวาย", "DISTRICT_ID": 629, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5654, "SUB_DISTRICT_CODE": "540601", "SUB_DISTRICT_NAME": "บ้านหนุน", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5655, "SUB_DISTRICT_CODE": "540602", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5656, "SUB_DISTRICT_CODE": "540603", "SUB_DISTRICT_NAME": "ห้วยหม้าย", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5657, "SUB_DISTRICT_CODE": "540604", "SUB_DISTRICT_NAME": "เตาปูน", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5658, "SUB_DISTRICT_CODE": "540605", "SUB_DISTRICT_NAME": "หัวเมือง", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5659, "SUB_DISTRICT_CODE": "540606", "SUB_DISTRICT_NAME": "สะเอียบ", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5660, "SUB_DISTRICT_CODE": "540607", "SUB_DISTRICT_NAME": "แดนชุมพล", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5661, "SUB_DISTRICT_CODE": "540608", "SUB_DISTRICT_NAME": "ทุ่งน้าว", "DISTRICT_ID": 630, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5662, "SUB_DISTRICT_CODE": "540701", "SUB_DISTRICT_NAME": "วังชิ้น", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5663, "SUB_DISTRICT_CODE": "540702", "SUB_DISTRICT_NAME": "สรอย", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5664, "SUB_DISTRICT_CODE": "540703", "SUB_DISTRICT_NAME": "แม่ป้าก", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5665, "SUB_DISTRICT_CODE": "540704", "SUB_DISTRICT_NAME": "นาพูน", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5666, "SUB_DISTRICT_CODE": "540705", "SUB_DISTRICT_NAME": "แม่พุง", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5667, "SUB_DISTRICT_CODE": "540706", "SUB_DISTRICT_NAME": "ป่าสัก", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5668, "SUB_DISTRICT_CODE": "540707", "SUB_DISTRICT_NAME": "แม่เกิ๋ง", "DISTRICT_ID": 631, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5669, "SUB_DISTRICT_CODE": "540801", "SUB_DISTRICT_NAME": "แม่คำมี", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5670, "SUB_DISTRICT_CODE": "540802", "SUB_DISTRICT_NAME": "หนองม่วงไข่", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5671, "SUB_DISTRICT_CODE": "540803", "SUB_DISTRICT_NAME": "น้ำรัด", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5672, "SUB_DISTRICT_CODE": "540804", "SUB_DISTRICT_NAME": "วังหลวง", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5673, "SUB_DISTRICT_CODE": "540805", "SUB_DISTRICT_NAME": "ตำหนักธรรม", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5674, "SUB_DISTRICT_CODE": "540806", "SUB_DISTRICT_NAME": "ทุ่งแค้ว", "DISTRICT_ID": 632, "PROVINCE_ID": 42, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5675, "SUB_DISTRICT_CODE": "550101", "SUB_DISTRICT_NAME": "ในเวียง", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5676, "SUB_DISTRICT_CODE": "550102", "SUB_DISTRICT_NAME": "บ่อ", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5677, "SUB_DISTRICT_CODE": "550103", "SUB_DISTRICT_NAME": "ผาสิงห์", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5678, "SUB_DISTRICT_CODE": "550104", "SUB_DISTRICT_NAME": "ไชยสถาน", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5679, "SUB_DISTRICT_CODE": "550105", "SUB_DISTRICT_NAME": "ถืมตอง", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5680, "SUB_DISTRICT_CODE": "550106", "SUB_DISTRICT_NAME": "เรือง", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5681, "SUB_DISTRICT_CODE": "550107", "SUB_DISTRICT_NAME": "นาซาว", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5682, "SUB_DISTRICT_CODE": "550108", "SUB_DISTRICT_NAME": "ดู่ใต้", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5683, "SUB_DISTRICT_CODE": "550109", "SUB_DISTRICT_NAME": "กองควาย", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5684, "SUB_DISTRICT_CODE": "550110", "SUB_DISTRICT_NAME": "ฝายแก้ว*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5685, "SUB_DISTRICT_CODE": "550111", "SUB_DISTRICT_NAME": "ม่วงตึ๊ด*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5686, "SUB_DISTRICT_CODE": "550112", "SUB_DISTRICT_NAME": "ท่าน้าว*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5687, "SUB_DISTRICT_CODE": "550113", "SUB_DISTRICT_NAME": "นาปัง*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5688, "SUB_DISTRICT_CODE": "550114", "SUB_DISTRICT_NAME": "เมืองจัง*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5689, "SUB_DISTRICT_CODE": "550115", "SUB_DISTRICT_NAME": "น้ำแก่น*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5690, "SUB_DISTRICT_CODE": "550116", "SUB_DISTRICT_NAME": "สวก", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5691, "SUB_DISTRICT_CODE": "550117", "SUB_DISTRICT_NAME": "สะเนียน", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5692, "SUB_DISTRICT_CODE": "550118", "SUB_DISTRICT_NAME": "น้ำเกี๋ยน*", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5693, "SUB_DISTRICT_CODE": "550196", "SUB_DISTRICT_NAME": "*ป่าคาหลวง", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5694, "SUB_DISTRICT_CODE": "550197", "SUB_DISTRICT_NAME": "*หมอเมือง", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5695, "SUB_DISTRICT_CODE": "550198", "SUB_DISTRICT_NAME": "*บ้านฟ้า", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5696, "SUB_DISTRICT_CODE": "550199", "SUB_DISTRICT_NAME": "*ดู่พงษ์", "DISTRICT_ID": 633, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5697, "SUB_DISTRICT_CODE": "550201", "SUB_DISTRICT_NAME": "*พงษ์", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5698, "SUB_DISTRICT_CODE": "550202", "SUB_DISTRICT_NAME": "หนองแดง", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5699, "SUB_DISTRICT_CODE": "550203", "SUB_DISTRICT_NAME": "หมอเมือง", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5700, "SUB_DISTRICT_CODE": "550204", "SUB_DISTRICT_NAME": "น้ำพาง", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5701, "SUB_DISTRICT_CODE": "550205", "SUB_DISTRICT_NAME": "น้ำปาย", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5702, "SUB_DISTRICT_CODE": "550206", "SUB_DISTRICT_NAME": "แม่จริม", "DISTRICT_ID": 634, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5703, "SUB_DISTRICT_CODE": "550301", "SUB_DISTRICT_NAME": "บ้านฟ้า", "DISTRICT_ID": 635, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5704, "SUB_DISTRICT_CODE": "550302", "SUB_DISTRICT_NAME": "ป่าคาหลวง", "DISTRICT_ID": 635, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5705, "SUB_DISTRICT_CODE": "550303", "SUB_DISTRICT_NAME": "สวด", "DISTRICT_ID": 635, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5706, "SUB_DISTRICT_CODE": "550304", "SUB_DISTRICT_NAME": "บ้านพี้", "DISTRICT_ID": 635, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5707, "SUB_DISTRICT_CODE": "550401", "SUB_DISTRICT_NAME": "นาน้อย", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5708, "SUB_DISTRICT_CODE": "550402", "SUB_DISTRICT_NAME": "เชียงของ", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5709, "SUB_DISTRICT_CODE": "550403", "SUB_DISTRICT_NAME": "ศรีษะเกษ", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5710, "SUB_DISTRICT_CODE": "550404", "SUB_DISTRICT_NAME": "สถาน", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5711, "SUB_DISTRICT_CODE": "550405", "SUB_DISTRICT_NAME": "สันทะ", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5712, "SUB_DISTRICT_CODE": "550406", "SUB_DISTRICT_NAME": "บัวใหญ่", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5713, "SUB_DISTRICT_CODE": "550407", "SUB_DISTRICT_NAME": "น้ำตก", "DISTRICT_ID": 636, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5714, "SUB_DISTRICT_CODE": "550501", "SUB_DISTRICT_NAME": "ปัว", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5715, "SUB_DISTRICT_CODE": "550502", "SUB_DISTRICT_NAME": "แงง", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5716, "SUB_DISTRICT_CODE": "550503", "SUB_DISTRICT_NAME": "สถาน", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5717, "SUB_DISTRICT_CODE": "550504", "SUB_DISTRICT_NAME": "ศิลาแลง", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5718, "SUB_DISTRICT_CODE": "550505", "SUB_DISTRICT_NAME": "ศิลาเพชร", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5719, "SUB_DISTRICT_CODE": "550506", "SUB_DISTRICT_NAME": "อวน", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5720, "SUB_DISTRICT_CODE": "550507", "SUB_DISTRICT_NAME": "*บ่อเกลือเหนือ", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5721, "SUB_DISTRICT_CODE": "550508", "SUB_DISTRICT_NAME": "*บ่อเกลือใต้", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5722, "SUB_DISTRICT_CODE": "550509", "SUB_DISTRICT_NAME": "ไชยวัฒนา", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5723, "SUB_DISTRICT_CODE": "550510", "SUB_DISTRICT_NAME": "เจดีย์ชัย", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5724, "SUB_DISTRICT_CODE": "550511", "SUB_DISTRICT_NAME": "ภูคา", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5725, "SUB_DISTRICT_CODE": "550512", "SUB_DISTRICT_NAME": "สกาด", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5726, "SUB_DISTRICT_CODE": "550513", "SUB_DISTRICT_NAME": "ป่ากลาง", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5727, "SUB_DISTRICT_CODE": "550514", "SUB_DISTRICT_NAME": "วรนคร", "DISTRICT_ID": 637, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5728, "SUB_DISTRICT_CODE": "550601", "SUB_DISTRICT_NAME": "ริม", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5729, "SUB_DISTRICT_CODE": "550602", "SUB_DISTRICT_NAME": "ป่าคา", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5730, "SUB_DISTRICT_CODE": "550603", "SUB_DISTRICT_NAME": "ผาตอ", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5731, "SUB_DISTRICT_CODE": "550604", "SUB_DISTRICT_NAME": "ยม", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5732, "SUB_DISTRICT_CODE": "550605", "SUB_DISTRICT_NAME": "ตาลชุม", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5733, "SUB_DISTRICT_CODE": "550606", "SUB_DISTRICT_NAME": "ศรีภูมิ", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5734, "SUB_DISTRICT_CODE": "550607", "SUB_DISTRICT_NAME": "จอมพระ", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5735, "SUB_DISTRICT_CODE": "550608", "SUB_DISTRICT_NAME": "แสนทอง", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5736, "SUB_DISTRICT_CODE": "550609", "SUB_DISTRICT_NAME": "ท่าวังผา", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5737, "SUB_DISTRICT_CODE": "550610", "SUB_DISTRICT_NAME": "ผาทอง", "DISTRICT_ID": 638, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5738, "SUB_DISTRICT_CODE": "550701", "SUB_DISTRICT_NAME": "กลางเวียง", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5739, "SUB_DISTRICT_CODE": "550702", "SUB_DISTRICT_NAME": "ขึ่ง", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5740, "SUB_DISTRICT_CODE": "550703", "SUB_DISTRICT_NAME": "ไหล่น่าน", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5741, "SUB_DISTRICT_CODE": "550704", "SUB_DISTRICT_NAME": "ตาลชุม", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5742, "SUB_DISTRICT_CODE": "550705", "SUB_DISTRICT_NAME": "นาเหลือง", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5743, "SUB_DISTRICT_CODE": "550706", "SUB_DISTRICT_NAME": "ส้าน", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5744, "SUB_DISTRICT_CODE": "550707", "SUB_DISTRICT_NAME": "น้ำมวบ", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5745, "SUB_DISTRICT_CODE": "550708", "SUB_DISTRICT_NAME": "น้ำปั้ว", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5746, "SUB_DISTRICT_CODE": "550709", "SUB_DISTRICT_NAME": "ยาบหัวนา", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5747, "SUB_DISTRICT_CODE": "550710", "SUB_DISTRICT_NAME": "ปงสนุก", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5748, "SUB_DISTRICT_CODE": "550711", "SUB_DISTRICT_NAME": "อ่ายนาไลย", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5749, "SUB_DISTRICT_CODE": "550712", "SUB_DISTRICT_NAME": "ส้านนาหนองใหม่", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5750, "SUB_DISTRICT_CODE": "550713", "SUB_DISTRICT_NAME": "แม่ขะนิง", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5751, "SUB_DISTRICT_CODE": "550714", "SUB_DISTRICT_NAME": "แม่สาคร", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5752, "SUB_DISTRICT_CODE": "550715", "SUB_DISTRICT_NAME": "จอมจันทร์", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5753, "SUB_DISTRICT_CODE": "550716", "SUB_DISTRICT_NAME": "แม่สา", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5754, "SUB_DISTRICT_CODE": "550717", "SUB_DISTRICT_NAME": "ทุ่งศรีทอง", "DISTRICT_ID": 639, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5755, "SUB_DISTRICT_CODE": "550801", "SUB_DISTRICT_NAME": "ปอน", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5756, "SUB_DISTRICT_CODE": "550802", "SUB_DISTRICT_NAME": "งอบ", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5757, "SUB_DISTRICT_CODE": "550803", "SUB_DISTRICT_NAME": "และ", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5758, "SUB_DISTRICT_CODE": "550804", "SUB_DISTRICT_NAME": "ทุ่งช้าง", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5759, "SUB_DISTRICT_CODE": "550805", "SUB_DISTRICT_NAME": "ห้วยโก๋น*", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5760, "SUB_DISTRICT_CODE": "550898", "SUB_DISTRICT_NAME": "*เปือ", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5761, "SUB_DISTRICT_CODE": "550899", "SUB_DISTRICT_NAME": "*เชียงกลาง", "DISTRICT_ID": 640, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5762, "SUB_DISTRICT_CODE": "550901", "SUB_DISTRICT_NAME": "เชียงกลาง", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5763, "SUB_DISTRICT_CODE": "550902", "SUB_DISTRICT_NAME": "เปือ", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5764, "SUB_DISTRICT_CODE": "550903", "SUB_DISTRICT_NAME": "เชียงคาน", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5765, "SUB_DISTRICT_CODE": "550904", "SUB_DISTRICT_NAME": "พระธาตุ", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5766, "SUB_DISTRICT_CODE": "550905", "SUB_DISTRICT_NAME": "*นนาไร่หลวง", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5767, "SUB_DISTRICT_CODE": "550906", "SUB_DISTRICT_NAME": "*ชชนแดน", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5768, "SUB_DISTRICT_CODE": "550907", "SUB_DISTRICT_NAME": "*ยยอด", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5769, "SUB_DISTRICT_CODE": "550908", "SUB_DISTRICT_NAME": "พญาแก้ว", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5770, "SUB_DISTRICT_CODE": "550909", "SUB_DISTRICT_NAME": "พระพุทธบาท", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5771, "SUB_DISTRICT_CODE": "550998", "SUB_DISTRICT_NAME": "*นาไร่หลวง", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5772, "SUB_DISTRICT_CODE": "550999", "SUB_DISTRICT_NAME": "*ยอด", "DISTRICT_ID": 641, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5773, "SUB_DISTRICT_CODE": "551001", "SUB_DISTRICT_NAME": "นาทะนุง", "DISTRICT_ID": 642, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5774, "SUB_DISTRICT_CODE": "551002", "SUB_DISTRICT_NAME": "บ่อแก้ว", "DISTRICT_ID": 642, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5775, "SUB_DISTRICT_CODE": "551003", "SUB_DISTRICT_NAME": "เมืองลี", "DISTRICT_ID": 642, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5776, "SUB_DISTRICT_CODE": "551004", "SUB_DISTRICT_NAME": "ปิงหลวง", "DISTRICT_ID": 642, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5777, "SUB_DISTRICT_CODE": "551101", "SUB_DISTRICT_NAME": "ดู่พงษ์", "DISTRICT_ID": 643, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5778, "SUB_DISTRICT_CODE": "551102", "SUB_DISTRICT_NAME": "ป่าแลวหลวง", "DISTRICT_ID": 643, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5779, "SUB_DISTRICT_CODE": "551103", "SUB_DISTRICT_NAME": "พงษ์", "DISTRICT_ID": 643, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5780, "SUB_DISTRICT_CODE": "551201", "SUB_DISTRICT_NAME": "บ่อเกลือเหนือ", "DISTRICT_ID": 644, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5781, "SUB_DISTRICT_CODE": "551202", "SUB_DISTRICT_NAME": "บ่อเกลือใต้", "DISTRICT_ID": 644, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5782, "SUB_DISTRICT_CODE": "551203", "SUB_DISTRICT_NAME": "ขุนน่าน*", "DISTRICT_ID": 644, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5783, "SUB_DISTRICT_CODE": "551204", "SUB_DISTRICT_NAME": "ภูฟ้า", "DISTRICT_ID": 644, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5784, "SUB_DISTRICT_CODE": "551205", "SUB_DISTRICT_NAME": "ดงพญา", "DISTRICT_ID": 644, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5785, "SUB_DISTRICT_CODE": "551301", "SUB_DISTRICT_NAME": "นาไร่หลวง", "DISTRICT_ID": 645, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5786, "SUB_DISTRICT_CODE": "551302", "SUB_DISTRICT_NAME": "ชนแดน", "DISTRICT_ID": 645, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5787, "SUB_DISTRICT_CODE": "551303", "SUB_DISTRICT_NAME": "ยอด", "DISTRICT_ID": 645, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5788, "SUB_DISTRICT_CODE": "551401", "SUB_DISTRICT_NAME": "ม่วงตึ๊ด", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5789, "SUB_DISTRICT_CODE": "551402", "SUB_DISTRICT_NAME": "นาปัง", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5790, "SUB_DISTRICT_CODE": "551403", "SUB_DISTRICT_NAME": "น้ำแก่น", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5791, "SUB_DISTRICT_CODE": "551404", "SUB_DISTRICT_NAME": "น้ำเกี๋ยน", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5792, "SUB_DISTRICT_CODE": "551405", "SUB_DISTRICT_NAME": "เมืองจัง", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5793, "SUB_DISTRICT_CODE": "551406", "SUB_DISTRICT_NAME": "ท่าน้าว", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5794, "SUB_DISTRICT_CODE": "551407", "SUB_DISTRICT_NAME": "ฝายแก้ว", "DISTRICT_ID": 646, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5795, "SUB_DISTRICT_CODE": "551501", "SUB_DISTRICT_NAME": "ห้วยโก๋น", "DISTRICT_ID": 647, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5796, "SUB_DISTRICT_CODE": "551502", "SUB_DISTRICT_NAME": "ขุนน่าน", "DISTRICT_ID": 647, "PROVINCE_ID": 43, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5797, "SUB_DISTRICT_CODE": "560101", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5798, "SUB_DISTRICT_CODE": "560102", "SUB_DISTRICT_NAME": "แม่ต๋ำ", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5799, "SUB_DISTRICT_CODE": "560103", "SUB_DISTRICT_NAME": "ดงเจน*", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5800, "SUB_DISTRICT_CODE": "560104", "SUB_DISTRICT_NAME": "แม่นาเรือ", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5801, "SUB_DISTRICT_CODE": "560105", "SUB_DISTRICT_NAME": "บ้านตุ่น", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5802, "SUB_DISTRICT_CODE": "560106", "SUB_DISTRICT_NAME": "บ้านต๊ำ", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5803, "SUB_DISTRICT_CODE": "560107", "SUB_DISTRICT_NAME": "บ้านต๋อม", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5804, "SUB_DISTRICT_CODE": "560108", "SUB_DISTRICT_NAME": "แม่ปืม", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5805, "SUB_DISTRICT_CODE": "560109", "SUB_DISTRICT_NAME": "ห้วยแก้ว*", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5806, "SUB_DISTRICT_CODE": "560110", "SUB_DISTRICT_NAME": "แม่กา", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5807, "SUB_DISTRICT_CODE": "560111", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5808, "SUB_DISTRICT_CODE": "560112", "SUB_DISTRICT_NAME": "จำป่าหวาย", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5809, "SUB_DISTRICT_CODE": "560113", "SUB_DISTRICT_NAME": "ท่าวังทอง", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5810, "SUB_DISTRICT_CODE": "560114", "SUB_DISTRICT_NAME": "แม่ใส", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5811, "SUB_DISTRICT_CODE": "560115", "SUB_DISTRICT_NAME": "บ้านสาง", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5812, "SUB_DISTRICT_CODE": "560116", "SUB_DISTRICT_NAME": "ท่าจำปี", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5813, "SUB_DISTRICT_CODE": "560117", "SUB_DISTRICT_NAME": "แม่อิง*", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5814, "SUB_DISTRICT_CODE": "560118", "SUB_DISTRICT_NAME": "สันป่าม่วง", "DISTRICT_ID": 648, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5815, "SUB_DISTRICT_CODE": "560201", "SUB_DISTRICT_NAME": "ห้วยข้าวก่ำ", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5816, "SUB_DISTRICT_CODE": "560202", "SUB_DISTRICT_NAME": "จุน", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5817, "SUB_DISTRICT_CODE": "560203", "SUB_DISTRICT_NAME": "ลอ", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5818, "SUB_DISTRICT_CODE": "560204", "SUB_DISTRICT_NAME": "หงส์หิน", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5819, "SUB_DISTRICT_CODE": "560205", "SUB_DISTRICT_NAME": "ทุ่งรวงทอง", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5820, "SUB_DISTRICT_CODE": "560206", "SUB_DISTRICT_NAME": "ห้วยยางขาม", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5821, "SUB_DISTRICT_CODE": "560207", "SUB_DISTRICT_NAME": "พระธาตุขิงแกง", "DISTRICT_ID": 649, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5822, "SUB_DISTRICT_CODE": "560301", "SUB_DISTRICT_NAME": "หย่วน", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5823, "SUB_DISTRICT_CODE": "560302", "SUB_DISTRICT_NAME": "ทุ่งกล้วย*", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5824, "SUB_DISTRICT_CODE": "560303", "SUB_DISTRICT_NAME": "สบบง*", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5825, "SUB_DISTRICT_CODE": "560304", "SUB_DISTRICT_NAME": "เชียงแรง*", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5826, "SUB_DISTRICT_CODE": "560305", "SUB_DISTRICT_NAME": "ภูซาง*", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5827, "SUB_DISTRICT_CODE": "560306", "SUB_DISTRICT_NAME": "น้ำแวน", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5828, "SUB_DISTRICT_CODE": "560307", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5829, "SUB_DISTRICT_CODE": "560308", "SUB_DISTRICT_NAME": "ฝายกวาง", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5830, "SUB_DISTRICT_CODE": "560309", "SUB_DISTRICT_NAME": "เจดีย์คำ", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5831, "SUB_DISTRICT_CODE": "560310", "SUB_DISTRICT_NAME": "ร่มเย็น", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5832, "SUB_DISTRICT_CODE": "560311", "SUB_DISTRICT_NAME": "เชียงบาน", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5833, "SUB_DISTRICT_CODE": "560312", "SUB_DISTRICT_NAME": "แม่ลาว", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5834, "SUB_DISTRICT_CODE": "560313", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5835, "SUB_DISTRICT_CODE": "560314", "SUB_DISTRICT_NAME": "ทุ่งผาสุข", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5836, "SUB_DISTRICT_CODE": "560315", "SUB_DISTRICT_NAME": "ป่าสัก*", "DISTRICT_ID": 650, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5837, "SUB_DISTRICT_CODE": "560401", "SUB_DISTRICT_NAME": "เชียงม่วน", "DISTRICT_ID": 651, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5838, "SUB_DISTRICT_CODE": "560402", "SUB_DISTRICT_NAME": "บ้านมาง", "DISTRICT_ID": 651, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5839, "SUB_DISTRICT_CODE": "560403", "SUB_DISTRICT_NAME": "สระ", "DISTRICT_ID": 651, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5840, "SUB_DISTRICT_CODE": "560501", "SUB_DISTRICT_NAME": "ดอกคำใต้", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5841, "SUB_DISTRICT_CODE": "560502", "SUB_DISTRICT_NAME": "ดอนศรีชุม", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5842, "SUB_DISTRICT_CODE": "560503", "SUB_DISTRICT_NAME": "บ้านถ้ำ", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5843, "SUB_DISTRICT_CODE": "560504", "SUB_DISTRICT_NAME": "บ้านปิน", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5844, "SUB_DISTRICT_CODE": "560505", "SUB_DISTRICT_NAME": "ห้วยลาน", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5845, "SUB_DISTRICT_CODE": "560506", "SUB_DISTRICT_NAME": "สันโค้ง", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5846, "SUB_DISTRICT_CODE": "560507", "SUB_DISTRICT_NAME": "ป่าซาง", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5847, "SUB_DISTRICT_CODE": "560508", "SUB_DISTRICT_NAME": "หนองหล่ม", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5848, "SUB_DISTRICT_CODE": "560509", "SUB_DISTRICT_NAME": "ดงสุวรรณ", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5849, "SUB_DISTRICT_CODE": "560510", "SUB_DISTRICT_NAME": "บุญเกิด", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5850, "SUB_DISTRICT_CODE": "560511", "SUB_DISTRICT_NAME": "สว่างอารมณ์", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5851, "SUB_DISTRICT_CODE": "560512", "SUB_DISTRICT_NAME": "คือเวียง", "DISTRICT_ID": 652, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5852, "SUB_DISTRICT_CODE": "560601", "SUB_DISTRICT_NAME": "ปง", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5853, "SUB_DISTRICT_CODE": "560602", "SUB_DISTRICT_NAME": "ควร", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5854, "SUB_DISTRICT_CODE": "560603", "SUB_DISTRICT_NAME": "ออย", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5855, "SUB_DISTRICT_CODE": "560604", "SUB_DISTRICT_NAME": "งิม", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5856, "SUB_DISTRICT_CODE": "560605", "SUB_DISTRICT_NAME": "ผาช้างน้อย", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5857, "SUB_DISTRICT_CODE": "560606", "SUB_DISTRICT_NAME": "นาปรัง", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5858, "SUB_DISTRICT_CODE": "560607", "SUB_DISTRICT_NAME": "ขุนควร", "DISTRICT_ID": 653, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5859, "SUB_DISTRICT_CODE": "560701", "SUB_DISTRICT_NAME": "แม่ใจ", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5860, "SUB_DISTRICT_CODE": "560702", "SUB_DISTRICT_NAME": "ศรีถ้อย", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5861, "SUB_DISTRICT_CODE": "560703", "SUB_DISTRICT_NAME": "แม่สุก", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5862, "SUB_DISTRICT_CODE": "560704", "SUB_DISTRICT_NAME": "ป่าแฝก", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5863, "SUB_DISTRICT_CODE": "560705", "SUB_DISTRICT_NAME": "บ้านเหล่า", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5864, "SUB_DISTRICT_CODE": "560706", "SUB_DISTRICT_NAME": "เจริญราษฎร์", "DISTRICT_ID": 654, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5865, "SUB_DISTRICT_CODE": "560801", "SUB_DISTRICT_NAME": "ภูซาง", "DISTRICT_ID": 655, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5866, "SUB_DISTRICT_CODE": "560802", "SUB_DISTRICT_NAME": "ป่าสัก", "DISTRICT_ID": 655, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5867, "SUB_DISTRICT_CODE": "560803", "SUB_DISTRICT_NAME": "ทุ่งกล้วย", "DISTRICT_ID": 655, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5868, "SUB_DISTRICT_CODE": "560804", "SUB_DISTRICT_NAME": "เชียงแรง", "DISTRICT_ID": 655, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5869, "SUB_DISTRICT_CODE": "560805", "SUB_DISTRICT_NAME": "สบบง", "DISTRICT_ID": 655, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5870, "SUB_DISTRICT_CODE": "560901", "SUB_DISTRICT_NAME": "ห้วยแก้ว", "DISTRICT_ID": 656, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5871, "SUB_DISTRICT_CODE": "560902", "SUB_DISTRICT_NAME": "ดงเจน", "DISTRICT_ID": 656, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5872, "SUB_DISTRICT_CODE": "560903", "SUB_DISTRICT_NAME": "แม่อิง", "DISTRICT_ID": 656, "PROVINCE_ID": 44, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5873, "SUB_DISTRICT_CODE": "570101", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5874, "SUB_DISTRICT_CODE": "570102", "SUB_DISTRICT_NAME": "รอบเวียง", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5875, "SUB_DISTRICT_CODE": "570103", "SUB_DISTRICT_NAME": "บ้านดู่", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5876, "SUB_DISTRICT_CODE": "570104", "SUB_DISTRICT_NAME": "นางแล", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5877, "SUB_DISTRICT_CODE": "570105", "SUB_DISTRICT_NAME": "แม่ข้าวต้ม", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5878, "SUB_DISTRICT_CODE": "570106", "SUB_DISTRICT_NAME": "แม่ยาว", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5879, "SUB_DISTRICT_CODE": "570107", "SUB_DISTRICT_NAME": "สันทราย", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5880, "SUB_DISTRICT_CODE": "570108", "SUB_DISTRICT_NAME": "*บัวสลี", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5881, "SUB_DISTRICT_CODE": "570109", "SUB_DISTRICT_NAME": "*ดงมะดะ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5882, "SUB_DISTRICT_CODE": "570110", "SUB_DISTRICT_NAME": "*ป่าก่อดำ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5883, "SUB_DISTRICT_CODE": "570111", "SUB_DISTRICT_NAME": "แม่กรณ์", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5884, "SUB_DISTRICT_CODE": "570112", "SUB_DISTRICT_NAME": "ห้วยชมภู", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5885, "SUB_DISTRICT_CODE": "570113", "SUB_DISTRICT_NAME": "ห้วยสัก", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5886, "SUB_DISTRICT_CODE": "570114", "SUB_DISTRICT_NAME": "ริมกก", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5887, "SUB_DISTRICT_CODE": "570115", "SUB_DISTRICT_NAME": "ดอยลาน", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5888, "SUB_DISTRICT_CODE": "570116", "SUB_DISTRICT_NAME": "ป่าอ้อดอนชัย", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5889, "SUB_DISTRICT_CODE": "570117", "SUB_DISTRICT_NAME": "*จอมหมอกแก้ว", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5890, "SUB_DISTRICT_CODE": "570118", "SUB_DISTRICT_NAME": "ท่าสาย", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5891, "SUB_DISTRICT_CODE": "570119", "SUB_DISTRICT_NAME": "*โป่งแพร่", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5892, "SUB_DISTRICT_CODE": "570120", "SUB_DISTRICT_NAME": "ดอยฮาง", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5893, "SUB_DISTRICT_CODE": "570121", "SUB_DISTRICT_NAME": "ท่าสุด", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5894, "SUB_DISTRICT_CODE": "570192", "SUB_DISTRICT_NAME": "*ทุ่งก่อ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5895, "SUB_DISTRICT_CODE": "570193", "SUB_DISTRICT_NAME": "*ป่าก่อดำ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5896, "SUB_DISTRICT_CODE": "570194", "SUB_DISTRICT_NAME": "*ดงมะดะ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5897, "SUB_DISTRICT_CODE": "570195", "SUB_DISTRICT_NAME": "*บัวสลี", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5898, "SUB_DISTRICT_CODE": "570196", "SUB_DISTRICT_NAME": "*เวียงเหนือ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5899, "SUB_DISTRICT_CODE": "570197", "SUB_DISTRICT_NAME": "*ผางาม", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5900, "SUB_DISTRICT_CODE": "570198", "SUB_DISTRICT_NAME": "*เวียงชัย", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5901, "SUB_DISTRICT_CODE": "570199", "SUB_DISTRICT_NAME": "*ทุ่งก่อ", "DISTRICT_ID": 657, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5902, "SUB_DISTRICT_CODE": "570201", "SUB_DISTRICT_NAME": "*ทุ่งก่อ", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5903, "SUB_DISTRICT_CODE": "570202", "SUB_DISTRICT_NAME": "เวียงชัย", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5904, "SUB_DISTRICT_CODE": "570203", "SUB_DISTRICT_NAME": "ผางาม", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5905, "SUB_DISTRICT_CODE": "570204", "SUB_DISTRICT_NAME": "เวียงเหนือ", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5906, "SUB_DISTRICT_CODE": "570205", "SUB_DISTRICT_NAME": "*ป่าซาง", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5907, "SUB_DISTRICT_CODE": "570206", "SUB_DISTRICT_NAME": "ดอนศิลา", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5908, "SUB_DISTRICT_CODE": "570207", "SUB_DISTRICT_NAME": "*ดงมหาวัน", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5909, "SUB_DISTRICT_CODE": "570208", "SUB_DISTRICT_NAME": "เมืองชุม", "DISTRICT_ID": 658, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5910, "SUB_DISTRICT_CODE": "570301", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5911, "SUB_DISTRICT_CODE": "570302", "SUB_DISTRICT_NAME": "สถาน", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5912, "SUB_DISTRICT_CODE": "570303", "SUB_DISTRICT_NAME": "ครึ่ง", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5913, "SUB_DISTRICT_CODE": "570304", "SUB_DISTRICT_NAME": "บุญเรือง", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5914, "SUB_DISTRICT_CODE": "570305", "SUB_DISTRICT_NAME": "ห้วยซ้อ", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5915, "SUB_DISTRICT_CODE": "570306", "SUB_DISTRICT_NAME": "*ม่วงยาย", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5916, "SUB_DISTRICT_CODE": "570307", "SUB_DISTRICT_NAME": "*ปอ", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5917, "SUB_DISTRICT_CODE": "570308", "SUB_DISTRICT_NAME": "ศรีดอนชัย", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5918, "SUB_DISTRICT_CODE": "570309", "SUB_DISTRICT_NAME": "*หล่ายงาว", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5919, "SUB_DISTRICT_CODE": "570310", "SUB_DISTRICT_NAME": "ริมโขง", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5920, "SUB_DISTRICT_CODE": "570398", "SUB_DISTRICT_NAME": "*ปอ", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5921, "SUB_DISTRICT_CODE": "570399", "SUB_DISTRICT_NAME": "*ม่วงยาย", "DISTRICT_ID": 659, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5922, "SUB_DISTRICT_CODE": "570401", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5923, "SUB_DISTRICT_CODE": "570402", "SUB_DISTRICT_NAME": "งิ้ว", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5924, "SUB_DISTRICT_CODE": "570403", "SUB_DISTRICT_NAME": "ปล้อง", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5925, "SUB_DISTRICT_CODE": "570404", "SUB_DISTRICT_NAME": "แม่ลอย", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5926, "SUB_DISTRICT_CODE": "570405", "SUB_DISTRICT_NAME": "เชียงเคี่ยน", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5927, "SUB_DISTRICT_CODE": "570406", "SUB_DISTRICT_NAME": "*ตต้า", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5928, "SUB_DISTRICT_CODE": "570407", "SUB_DISTRICT_NAME": "*ปป่าตาล", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5929, "SUB_DISTRICT_CODE": "570408", "SUB_DISTRICT_NAME": "*ยยางฮอม", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5930, "SUB_DISTRICT_CODE": "570409", "SUB_DISTRICT_NAME": "ตับเต่า", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5931, "SUB_DISTRICT_CODE": "570410", "SUB_DISTRICT_NAME": "หงาว", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5932, "SUB_DISTRICT_CODE": "570411", "SUB_DISTRICT_NAME": "สันทรายงาม", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5933, "SUB_DISTRICT_CODE": "570412", "SUB_DISTRICT_NAME": "ศรีดอนไชย", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5934, "SUB_DISTRICT_CODE": "570413", "SUB_DISTRICT_NAME": "หนองแรด", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5935, "SUB_DISTRICT_CODE": "570495", "SUB_DISTRICT_NAME": "*แม่ลอย", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5936, "SUB_DISTRICT_CODE": "570496", "SUB_DISTRICT_NAME": "*ต้า", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5937, "SUB_DISTRICT_CODE": "570497", "SUB_DISTRICT_NAME": "ยางฮอม*", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5938, "SUB_DISTRICT_CODE": "570498", "SUB_DISTRICT_NAME": "*แม่เปา", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5939, "SUB_DISTRICT_CODE": "570499", "SUB_DISTRICT_NAME": "*ป่าตาล", "DISTRICT_ID": 660, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5940, "SUB_DISTRICT_CODE": "570501", "SUB_DISTRICT_NAME": "สันมะเค็ด", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5941, "SUB_DISTRICT_CODE": "570502", "SUB_DISTRICT_NAME": "แม่อ้อ", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5942, "SUB_DISTRICT_CODE": "570503", "SUB_DISTRICT_NAME": "ธารทอง", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5943, "SUB_DISTRICT_CODE": "570504", "SUB_DISTRICT_NAME": "สันติสุข", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5944, "SUB_DISTRICT_CODE": "570505", "SUB_DISTRICT_NAME": "ดอยงาม", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5945, "SUB_DISTRICT_CODE": "570506", "SUB_DISTRICT_NAME": "หัวง้ม", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5946, "SUB_DISTRICT_CODE": "570507", "SUB_DISTRICT_NAME": "เจริญเมือง", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5947, "SUB_DISTRICT_CODE": "570508", "SUB_DISTRICT_NAME": "ป่าหุ่ง", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5948, "SUB_DISTRICT_CODE": "570509", "SUB_DISTRICT_NAME": "ม่วงคำ", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5949, "SUB_DISTRICT_CODE": "570510", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5950, "SUB_DISTRICT_CODE": "570511", "SUB_DISTRICT_NAME": "สันกลาง", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5951, "SUB_DISTRICT_CODE": "570512", "SUB_DISTRICT_NAME": "แม่เย็น", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5952, "SUB_DISTRICT_CODE": "570513", "SUB_DISTRICT_NAME": "เมืองพาน", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5953, "SUB_DISTRICT_CODE": "570514", "SUB_DISTRICT_NAME": "ทานตะวัน", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5954, "SUB_DISTRICT_CODE": "570515", "SUB_DISTRICT_NAME": "เวียงห้าว", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5955, "SUB_DISTRICT_CODE": "570597", "SUB_DISTRICT_NAME": "*ป่าแงะ", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5956, "SUB_DISTRICT_CODE": "570598", "SUB_DISTRICT_NAME": "*สันมะค่า", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5957, "SUB_DISTRICT_CODE": "570599", "SUB_DISTRICT_NAME": "*ป่าแดด", "DISTRICT_ID": 661, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5958, "SUB_DISTRICT_CODE": "570601", "SUB_DISTRICT_NAME": "ป่าแดด", "DISTRICT_ID": 662, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5959, "SUB_DISTRICT_CODE": "570602", "SUB_DISTRICT_NAME": "ป่าแงะ", "DISTRICT_ID": 662, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5960, "SUB_DISTRICT_CODE": "570603", "SUB_DISTRICT_NAME": "สันมะค่า", "DISTRICT_ID": 662, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5961, "SUB_DISTRICT_CODE": "570605", "SUB_DISTRICT_NAME": "โรงช้าง", "DISTRICT_ID": 662, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5962, "SUB_DISTRICT_CODE": "570606", "SUB_DISTRICT_NAME": "ศรีโพธิ์เงิน", "DISTRICT_ID": 662, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5963, "SUB_DISTRICT_CODE": "570701", "SUB_DISTRICT_NAME": "แม่จัน", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5964, "SUB_DISTRICT_CODE": "570702", "SUB_DISTRICT_NAME": "จันจว้า", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5965, "SUB_DISTRICT_CODE": "570703", "SUB_DISTRICT_NAME": "แม่คำ", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5966, "SUB_DISTRICT_CODE": "570704", "SUB_DISTRICT_NAME": "ป่าซาง", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5967, "SUB_DISTRICT_CODE": "570705", "SUB_DISTRICT_NAME": "สันทราย", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5968, "SUB_DISTRICT_CODE": "570706", "SUB_DISTRICT_NAME": "ท่าข้าวเปลือก", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5969, "SUB_DISTRICT_CODE": "570707", "SUB_DISTRICT_NAME": "ปงน้อย*", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5970, "SUB_DISTRICT_CODE": "570708", "SUB_DISTRICT_NAME": "ป่าตึง", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5971, "SUB_DISTRICT_CODE": "570709", "SUB_DISTRICT_NAME": "หนองป่าก่อ*", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5972, "SUB_DISTRICT_CODE": "570710", "SUB_DISTRICT_NAME": "แม่ไร่", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5973, "SUB_DISTRICT_CODE": "570711", "SUB_DISTRICT_NAME": "ศรีค้ำ", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5974, "SUB_DISTRICT_CODE": "570712", "SUB_DISTRICT_NAME": "จันจว้าใต้", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5975, "SUB_DISTRICT_CODE": "570713", "SUB_DISTRICT_NAME": "จอมสวรรค์", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5976, "SUB_DISTRICT_CODE": "570714", "SUB_DISTRICT_NAME": "*เเทอดไทย", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5977, "SUB_DISTRICT_CODE": "570715", "SUB_DISTRICT_NAME": "*แแม่สลองใน", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5978, "SUB_DISTRICT_CODE": "570716", "SUB_DISTRICT_NAME": "*แม่สลองนอก", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5979, "SUB_DISTRICT_CODE": "570717", "SUB_DISTRICT_NAME": "โชคชัย*", "DISTRICT_ID": 663, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5980, "SUB_DISTRICT_CODE": "570801", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5981, "SUB_DISTRICT_CODE": "570802", "SUB_DISTRICT_NAME": "ป่าสัก", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5982, "SUB_DISTRICT_CODE": "570803", "SUB_DISTRICT_NAME": "บ้านแซว", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5983, "SUB_DISTRICT_CODE": "570804", "SUB_DISTRICT_NAME": "ศรีดอนมูล", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5984, "SUB_DISTRICT_CODE": "570805", "SUB_DISTRICT_NAME": "แม่เงิน", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5985, "SUB_DISTRICT_CODE": "570806", "SUB_DISTRICT_NAME": "โยนก", "DISTRICT_ID": 664, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5986, "SUB_DISTRICT_CODE": "570901", "SUB_DISTRICT_NAME": "แม่สาย", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5987, "SUB_DISTRICT_CODE": "570902", "SUB_DISTRICT_NAME": "ห้วยไคร้", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5988, "SUB_DISTRICT_CODE": "570903", "SUB_DISTRICT_NAME": "เกาะช้าง", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5989, "SUB_DISTRICT_CODE": "570904", "SUB_DISTRICT_NAME": "โป่งผา", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5990, "SUB_DISTRICT_CODE": "570905", "SUB_DISTRICT_NAME": "ศรีเมืองชุม", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5991, "SUB_DISTRICT_CODE": "570906", "SUB_DISTRICT_NAME": "เวียงพางคำ", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5992, "SUB_DISTRICT_CODE": "570908", "SUB_DISTRICT_NAME": "บ้านด้าย", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5993, "SUB_DISTRICT_CODE": "570909", "SUB_DISTRICT_NAME": "โป่งงาม", "DISTRICT_ID": 665, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5994, "SUB_DISTRICT_CODE": "571001", "SUB_DISTRICT_NAME": "แม่สรวย", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5995, "SUB_DISTRICT_CODE": "571002", "SUB_DISTRICT_NAME": "ป่าแดด", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5996, "SUB_DISTRICT_CODE": "571003", "SUB_DISTRICT_NAME": "แม่พริก", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5997, "SUB_DISTRICT_CODE": "571004", "SUB_DISTRICT_NAME": "ศรีถ้อย", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5998, "SUB_DISTRICT_CODE": "571005", "SUB_DISTRICT_NAME": "ท่าก๊อ", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 5999, "SUB_DISTRICT_CODE": "571006", "SUB_DISTRICT_NAME": "วาวี", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6000, "SUB_DISTRICT_CODE": "571007", "SUB_DISTRICT_NAME": "เจดีย์หลวง", "DISTRICT_ID": 666, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6001, "SUB_DISTRICT_CODE": "571101", "SUB_DISTRICT_NAME": "สันสลี", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6002, "SUB_DISTRICT_CODE": "571102", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6003, "SUB_DISTRICT_CODE": "571103", "SUB_DISTRICT_NAME": "บ้านโป่ง", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6004, "SUB_DISTRICT_CODE": "571104", "SUB_DISTRICT_NAME": "ป่างิ้ว", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6005, "SUB_DISTRICT_CODE": "571105", "SUB_DISTRICT_NAME": "เวียงกาหลง", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6006, "SUB_DISTRICT_CODE": "571106", "SUB_DISTRICT_NAME": "แม่เจดีย์", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6007, "SUB_DISTRICT_CODE": "571107", "SUB_DISTRICT_NAME": "แม่เจดีย์ใหม่", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6008, "SUB_DISTRICT_CODE": "571108", "SUB_DISTRICT_NAME": "เวียงกาหลง*", "DISTRICT_ID": 667, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6009, "SUB_DISTRICT_CODE": "571201", "SUB_DISTRICT_NAME": "แม่เปา", "DISTRICT_ID": 668, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6010, "SUB_DISTRICT_CODE": "571202", "SUB_DISTRICT_NAME": "แม่ต๋ำ", "DISTRICT_ID": 668, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6011, "SUB_DISTRICT_CODE": "571203", "SUB_DISTRICT_NAME": "ไม้ยา", "DISTRICT_ID": 668, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6012, "SUB_DISTRICT_CODE": "571204", "SUB_DISTRICT_NAME": "เม็งราย", "DISTRICT_ID": 668, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6013, "SUB_DISTRICT_CODE": "571205", "SUB_DISTRICT_NAME": "ตาดควัน", "DISTRICT_ID": 668, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6014, "SUB_DISTRICT_CODE": "571301", "SUB_DISTRICT_NAME": "ม่วงยาย", "DISTRICT_ID": 669, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6015, "SUB_DISTRICT_CODE": "571302", "SUB_DISTRICT_NAME": "ปอ", "DISTRICT_ID": 669, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6016, "SUB_DISTRICT_CODE": "571303", "SUB_DISTRICT_NAME": "หล่ายงาว", "DISTRICT_ID": 669, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6017, "SUB_DISTRICT_CODE": "571304", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 669, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6018, "SUB_DISTRICT_CODE": "571401", "SUB_DISTRICT_NAME": "ต้า", "DISTRICT_ID": 670, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6019, "SUB_DISTRICT_CODE": "571402", "SUB_DISTRICT_NAME": "ป่าตาล", "DISTRICT_ID": 670, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6020, "SUB_DISTRICT_CODE": "571403", "SUB_DISTRICT_NAME": "ยางฮอม", "DISTRICT_ID": 670, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6021, "SUB_DISTRICT_CODE": "571501", "SUB_DISTRICT_NAME": "เทอดไทย", "DISTRICT_ID": 671, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6022, "SUB_DISTRICT_CODE": "571502", "SUB_DISTRICT_NAME": "แม่สลองใน", "DISTRICT_ID": 671, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6023, "SUB_DISTRICT_CODE": "571503", "SUB_DISTRICT_NAME": "แม่สลองนอก", "DISTRICT_ID": 671, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6024, "SUB_DISTRICT_CODE": "571504", "SUB_DISTRICT_NAME": "แม่ฟ้าหลวง", "DISTRICT_ID": 671, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6025, "SUB_DISTRICT_CODE": "571601", "SUB_DISTRICT_NAME": "ดงมะดะ", "DISTRICT_ID": 672, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6026, "SUB_DISTRICT_CODE": "571602", "SUB_DISTRICT_NAME": "จอมหมอกแก้ว", "DISTRICT_ID": 672, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6027, "SUB_DISTRICT_CODE": "571603", "SUB_DISTRICT_NAME": "บัวสลี", "DISTRICT_ID": 672, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6028, "SUB_DISTRICT_CODE": "571604", "SUB_DISTRICT_NAME": "ป่าก่อดำ", "DISTRICT_ID": 672, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6029, "SUB_DISTRICT_CODE": "571605", "SUB_DISTRICT_NAME": "โป่งแพร่", "DISTRICT_ID": 672, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6030, "SUB_DISTRICT_CODE": "571701", "SUB_DISTRICT_NAME": "ทุ่งก่อ", "DISTRICT_ID": 673, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6031, "SUB_DISTRICT_CODE": "571702", "SUB_DISTRICT_NAME": "ดงมหาวัน", "DISTRICT_ID": 673, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6032, "SUB_DISTRICT_CODE": "571703", "SUB_DISTRICT_NAME": "ป่าซาง", "DISTRICT_ID": 673, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6033, "SUB_DISTRICT_CODE": "571801", "SUB_DISTRICT_NAME": "ปงน้อย", "DISTRICT_ID": 674, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6034, "SUB_DISTRICT_CODE": "571802", "SUB_DISTRICT_NAME": "โชคชัย", "DISTRICT_ID": 674, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6035, "SUB_DISTRICT_CODE": "571803", "SUB_DISTRICT_NAME": "หนองป่าก่อ", "DISTRICT_ID": 674, "PROVINCE_ID": 45, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6036, "SUB_DISTRICT_CODE": "580101", "SUB_DISTRICT_NAME": "จองคำ", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6037, "SUB_DISTRICT_CODE": "580102", "SUB_DISTRICT_NAME": "ห้วยโป่ง", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6038, "SUB_DISTRICT_CODE": "580103", "SUB_DISTRICT_NAME": "ผาบ่อง", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6039, "SUB_DISTRICT_CODE": "580104", "SUB_DISTRICT_NAME": "ปางหมู", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6040, "SUB_DISTRICT_CODE": "580105", "SUB_DISTRICT_NAME": "หมอกจำแป่", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6041, "SUB_DISTRICT_CODE": "580106", "SUB_DISTRICT_NAME": "ห้วยผา", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6042, "SUB_DISTRICT_CODE": "580107", "SUB_DISTRICT_NAME": "*ปางมะผ้า", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6043, "SUB_DISTRICT_CODE": "580108", "SUB_DISTRICT_NAME": "*สบป่อง", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6044, "SUB_DISTRICT_CODE": "580109", "SUB_DISTRICT_NAME": "ห้วยปูลิง", "DISTRICT_ID": 675, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6045, "SUB_DISTRICT_CODE": "580201", "SUB_DISTRICT_NAME": "ขุนยวม", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6046, "SUB_DISTRICT_CODE": "580202", "SUB_DISTRICT_NAME": "แม่เงา", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6047, "SUB_DISTRICT_CODE": "580203", "SUB_DISTRICT_NAME": "เมืองปอน", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6048, "SUB_DISTRICT_CODE": "580204", "SUB_DISTRICT_NAME": "แม่ยวมน้อย", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6049, "SUB_DISTRICT_CODE": "580205", "SUB_DISTRICT_NAME": "แม่กิ๊", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6050, "SUB_DISTRICT_CODE": "580206", "SUB_DISTRICT_NAME": "แม่อูคอ", "DISTRICT_ID": 676, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6051, "SUB_DISTRICT_CODE": "580301", "SUB_DISTRICT_NAME": "เวียงใต้", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6052, "SUB_DISTRICT_CODE": "580302", "SUB_DISTRICT_NAME": "เวียงเหนือ", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6053, "SUB_DISTRICT_CODE": "580303", "SUB_DISTRICT_NAME": "แม่นาเติง", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6054, "SUB_DISTRICT_CODE": "580304", "SUB_DISTRICT_NAME": "แม่ฮี้", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6055, "SUB_DISTRICT_CODE": "580305", "SUB_DISTRICT_NAME": "ทุ่งยาว", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6056, "SUB_DISTRICT_CODE": "580306", "SUB_DISTRICT_NAME": "เมืองแปง", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6057, "SUB_DISTRICT_CODE": "580307", "SUB_DISTRICT_NAME": "โป่งสา", "DISTRICT_ID": 677, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6058, "SUB_DISTRICT_CODE": "580401", "SUB_DISTRICT_NAME": "บ้านกาศ", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6059, "SUB_DISTRICT_CODE": "580402", "SUB_DISTRICT_NAME": "แม่สะเรียง", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6060, "SUB_DISTRICT_CODE": "580403", "SUB_DISTRICT_NAME": "แม่คง", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6061, "SUB_DISTRICT_CODE": "580404", "SUB_DISTRICT_NAME": "แม่เหาะ", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6062, "SUB_DISTRICT_CODE": "580405", "SUB_DISTRICT_NAME": "แม่ยวม", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6063, "SUB_DISTRICT_CODE": "580406", "SUB_DISTRICT_NAME": "เสาหิน", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6064, "SUB_DISTRICT_CODE": "580408", "SUB_DISTRICT_NAME": "ป่าแป๋", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6065, "SUB_DISTRICT_CODE": "580497", "SUB_DISTRICT_NAME": "*กองกอย", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6066, "SUB_DISTRICT_CODE": "580498", "SUB_DISTRICT_NAME": "*แม่คะตวน", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6067, "SUB_DISTRICT_CODE": "580499", "SUB_DISTRICT_NAME": "*สบเมย", "DISTRICT_ID": 678, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6068, "SUB_DISTRICT_CODE": "580501", "SUB_DISTRICT_NAME": "แม่ลาน้อย", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6069, "SUB_DISTRICT_CODE": "580502", "SUB_DISTRICT_NAME": "แม่ลาหลวง", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6070, "SUB_DISTRICT_CODE": "580503", "SUB_DISTRICT_NAME": "ท่าผาปุ้ม", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6071, "SUB_DISTRICT_CODE": "580504", "SUB_DISTRICT_NAME": "แม่โถ", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6072, "SUB_DISTRICT_CODE": "580505", "SUB_DISTRICT_NAME": "ห้วยห้อม", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6073, "SUB_DISTRICT_CODE": "580506", "SUB_DISTRICT_NAME": "แม่นาจาง", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6074, "SUB_DISTRICT_CODE": "580507", "SUB_DISTRICT_NAME": "สันติคีรี", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6075, "SUB_DISTRICT_CODE": "580508", "SUB_DISTRICT_NAME": "ขุนแม่ลาน้อย", "DISTRICT_ID": 679, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6076, "SUB_DISTRICT_CODE": "580601", "SUB_DISTRICT_NAME": "สบเมย", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6077, "SUB_DISTRICT_CODE": "580602", "SUB_DISTRICT_NAME": "แม่คะตวน", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6078, "SUB_DISTRICT_CODE": "580603", "SUB_DISTRICT_NAME": "กองก๋อย", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6079, "SUB_DISTRICT_CODE": "580604", "SUB_DISTRICT_NAME": "แม่สวด", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6080, "SUB_DISTRICT_CODE": "580605", "SUB_DISTRICT_NAME": "ป่าโปง", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6081, "SUB_DISTRICT_CODE": "580606", "SUB_DISTRICT_NAME": "แม่สามแลบ", "DISTRICT_ID": 680, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6082, "SUB_DISTRICT_CODE": "580701", "SUB_DISTRICT_NAME": "สบป่อง", "DISTRICT_ID": 681, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6083, "SUB_DISTRICT_CODE": "580702", "SUB_DISTRICT_NAME": "ปางมะผ้า", "DISTRICT_ID": 681, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6084, "SUB_DISTRICT_CODE": "580703", "SUB_DISTRICT_NAME": "ถ้ำลอด", "DISTRICT_ID": 681, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6085, "SUB_DISTRICT_CODE": "580704", "SUB_DISTRICT_NAME": "นาปู่ป้อม", "DISTRICT_ID": 681, "PROVINCE_ID": 46, "GEO_ID": 1 },
    { "SUB_DISTRICT_ID": 6086, "SUB_DISTRICT_CODE": "600101", "SUB_DISTRICT_NAME": "ปากน้ำโพ", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6087, "SUB_DISTRICT_CODE": "600102", "SUB_DISTRICT_NAME": "กลางแดด", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6088, "SUB_DISTRICT_CODE": "600103", "SUB_DISTRICT_NAME": "เกรียงไกร", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6089, "SUB_DISTRICT_CODE": "600104", "SUB_DISTRICT_NAME": "แควใหญ่", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6090, "SUB_DISTRICT_CODE": "600105", "SUB_DISTRICT_NAME": "ตะเคียนเลื่อน", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6091, "SUB_DISTRICT_CODE": "600106", "SUB_DISTRICT_NAME": "นครสวรรค์ตก", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6092, "SUB_DISTRICT_CODE": "600107", "SUB_DISTRICT_NAME": "นครสวรรค์ออก", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6093, "SUB_DISTRICT_CODE": "600108", "SUB_DISTRICT_NAME": "บางพระหลวง", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6094, "SUB_DISTRICT_CODE": "600109", "SUB_DISTRICT_NAME": "บางม่วง", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6095, "SUB_DISTRICT_CODE": "600110", "SUB_DISTRICT_NAME": "บ้านมะเกลือ", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6096, "SUB_DISTRICT_CODE": "600111", "SUB_DISTRICT_NAME": "บ้านแก่ง", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6097, "SUB_DISTRICT_CODE": "600112", "SUB_DISTRICT_NAME": "พระนอน", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6098, "SUB_DISTRICT_CODE": "600113", "SUB_DISTRICT_NAME": "วัดไทร", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6099, "SUB_DISTRICT_CODE": "600114", "SUB_DISTRICT_NAME": "หนองกรด", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6100, "SUB_DISTRICT_CODE": "600115", "SUB_DISTRICT_NAME": "หนองกระโดน", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6101, "SUB_DISTRICT_CODE": "600116", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6102, "SUB_DISTRICT_CODE": "600117", "SUB_DISTRICT_NAME": "บึงเสนาท", "DISTRICT_ID": 683, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6103, "SUB_DISTRICT_CODE": "600201", "SUB_DISTRICT_NAME": "โกรกพระ", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6104, "SUB_DISTRICT_CODE": "600202", "SUB_DISTRICT_NAME": "ยางตาล", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6105, "SUB_DISTRICT_CODE": "600203", "SUB_DISTRICT_NAME": "บางมะฝ่อ", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6106, "SUB_DISTRICT_CODE": "600204", "SUB_DISTRICT_NAME": "บางประมุง", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6107, "SUB_DISTRICT_CODE": "600205", "SUB_DISTRICT_NAME": "นากลาง", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6108, "SUB_DISTRICT_CODE": "600206", "SUB_DISTRICT_NAME": "ศาลาแดง", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6109, "SUB_DISTRICT_CODE": "600207", "SUB_DISTRICT_NAME": "เนินกว้าว", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6110, "SUB_DISTRICT_CODE": "600208", "SUB_DISTRICT_NAME": "เนินศาลา", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6111, "SUB_DISTRICT_CODE": "600209", "SUB_DISTRICT_NAME": "หาดสูง", "DISTRICT_ID": 684, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6112, "SUB_DISTRICT_CODE": "600301", "SUB_DISTRICT_NAME": "ชุมแสง", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6113, "SUB_DISTRICT_CODE": "600302", "SUB_DISTRICT_NAME": "ทับกฤช", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6114, "SUB_DISTRICT_CODE": "600303", "SUB_DISTRICT_NAME": "พิกุล", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6115, "SUB_DISTRICT_CODE": "600304", "SUB_DISTRICT_NAME": "เกยไชย", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6116, "SUB_DISTRICT_CODE": "600305", "SUB_DISTRICT_NAME": "ท่าไม้", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6117, "SUB_DISTRICT_CODE": "600306", "SUB_DISTRICT_NAME": "บางเคียน", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6118, "SUB_DISTRICT_CODE": "600307", "SUB_DISTRICT_NAME": "หนองกระเจา", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6119, "SUB_DISTRICT_CODE": "600308", "SUB_DISTRICT_NAME": "พันลาน", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6120, "SUB_DISTRICT_CODE": "600309", "SUB_DISTRICT_NAME": "โคกหม้อ", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6121, "SUB_DISTRICT_CODE": "600310", "SUB_DISTRICT_NAME": "ไผ่สิงห์", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6122, "SUB_DISTRICT_CODE": "600311", "SUB_DISTRICT_NAME": "ฆะมัง", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6123, "SUB_DISTRICT_CODE": "600312", "SUB_DISTRICT_NAME": "ทับกฤชใต้", "DISTRICT_ID": 685, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6124, "SUB_DISTRICT_CODE": "600401", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6125, "SUB_DISTRICT_CODE": "600402", "SUB_DISTRICT_NAME": "หนองกลับ", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6126, "SUB_DISTRICT_CODE": "600403", "SUB_DISTRICT_NAME": "ธารทหาร", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6127, "SUB_DISTRICT_CODE": "600404", "SUB_DISTRICT_NAME": "ห้วยร่วม", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6128, "SUB_DISTRICT_CODE": "600405", "SUB_DISTRICT_NAME": "ห้วยถั่วใต้", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6129, "SUB_DISTRICT_CODE": "600406", "SUB_DISTRICT_NAME": "ห้วยถั่วเหนือ", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6130, "SUB_DISTRICT_CODE": "600407", "SUB_DISTRICT_NAME": "ห้วยใหญ่", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6131, "SUB_DISTRICT_CODE": "600408", "SUB_DISTRICT_NAME": "ทุ่งทอง", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6132, "SUB_DISTRICT_CODE": "600409", "SUB_DISTRICT_NAME": "วังบ่อ", "DISTRICT_ID": 686, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6133, "SUB_DISTRICT_CODE": "600501", "SUB_DISTRICT_NAME": "ท่างิ้ว", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6134, "SUB_DISTRICT_CODE": "600502", "SUB_DISTRICT_NAME": "บางตาหงาย", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6135, "SUB_DISTRICT_CODE": "600503", "SUB_DISTRICT_NAME": "หูกวาง", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6136, "SUB_DISTRICT_CODE": "600504", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6137, "SUB_DISTRICT_CODE": "600505", "SUB_DISTRICT_NAME": "บ้านแดน", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6138, "SUB_DISTRICT_CODE": "600506", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6139, "SUB_DISTRICT_CODE": "600507", "SUB_DISTRICT_NAME": "ตาขีด", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6140, "SUB_DISTRICT_CODE": "600508", "SUB_DISTRICT_NAME": "ตาสัง", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6141, "SUB_DISTRICT_CODE": "600509", "SUB_DISTRICT_NAME": "ด่านช้าง", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6142, "SUB_DISTRICT_CODE": "600510", "SUB_DISTRICT_NAME": "หนองกรด", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6143, "SUB_DISTRICT_CODE": "600511", "SUB_DISTRICT_NAME": "หนองตางู", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6144, "SUB_DISTRICT_CODE": "600512", "SUB_DISTRICT_NAME": "บึงปลาทู", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6145, "SUB_DISTRICT_CODE": "600513", "SUB_DISTRICT_NAME": "เจริญผล", "DISTRICT_ID": 687, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6146, "SUB_DISTRICT_CODE": "600601", "SUB_DISTRICT_NAME": "มหาโพธิ", "DISTRICT_ID": 688, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6147, "SUB_DISTRICT_CODE": "600602", "SUB_DISTRICT_NAME": "เก้าเลี้ยว", "DISTRICT_ID": 688, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6148, "SUB_DISTRICT_CODE": "600603", "SUB_DISTRICT_NAME": "หนองเต่า", "DISTRICT_ID": 688, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6149, "SUB_DISTRICT_CODE": "600604", "SUB_DISTRICT_NAME": "เขาดิน", "DISTRICT_ID": 688, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6150, "SUB_DISTRICT_CODE": "600605", "SUB_DISTRICT_NAME": "หัวดง", "DISTRICT_ID": 688, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6151, "SUB_DISTRICT_CODE": "600701", "SUB_DISTRICT_NAME": "ตาคลี", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6152, "SUB_DISTRICT_CODE": "600702", "SUB_DISTRICT_NAME": "ช่องแค", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6153, "SUB_DISTRICT_CODE": "600703", "SUB_DISTRICT_NAME": "จันเสน", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6154, "SUB_DISTRICT_CODE": "600704", "SUB_DISTRICT_NAME": "ห้วยหอม", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6155, "SUB_DISTRICT_CODE": "600705", "SUB_DISTRICT_NAME": "หัวหวาย", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6156, "SUB_DISTRICT_CODE": "600706", "SUB_DISTRICT_NAME": "หนองโพ", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6157, "SUB_DISTRICT_CODE": "600707", "SUB_DISTRICT_NAME": "หนองหม้อ", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6158, "SUB_DISTRICT_CODE": "600708", "SUB_DISTRICT_NAME": "สร้อยทอง", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6159, "SUB_DISTRICT_CODE": "600709", "SUB_DISTRICT_NAME": "ลาดทิพรส", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6160, "SUB_DISTRICT_CODE": "600710", "SUB_DISTRICT_NAME": "พรหมนิมิต", "DISTRICT_ID": 689, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6161, "SUB_DISTRICT_CODE": "600801", "SUB_DISTRICT_NAME": "ท่าตะโก", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6162, "SUB_DISTRICT_CODE": "600802", "SUB_DISTRICT_NAME": "พนมรอก", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6163, "SUB_DISTRICT_CODE": "600803", "SUB_DISTRICT_NAME": "หัวถนน", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6164, "SUB_DISTRICT_CODE": "600804", "SUB_DISTRICT_NAME": "สายลำโพง", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6165, "SUB_DISTRICT_CODE": "600805", "SUB_DISTRICT_NAME": "วังมหากร", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6166, "SUB_DISTRICT_CODE": "600806", "SUB_DISTRICT_NAME": "ดอนคา", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6167, "SUB_DISTRICT_CODE": "600807", "SUB_DISTRICT_NAME": "ทำนบ", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6168, "SUB_DISTRICT_CODE": "600808", "SUB_DISTRICT_NAME": "วังใหญ่", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6169, "SUB_DISTRICT_CODE": "600809", "SUB_DISTRICT_NAME": "พนมเศษ", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6170, "SUB_DISTRICT_CODE": "600810", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 690, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6171, "SUB_DISTRICT_CODE": "600901", "SUB_DISTRICT_NAME": "โคกเดื่อ", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6172, "SUB_DISTRICT_CODE": "600902", "SUB_DISTRICT_NAME": "สำโรงชัย", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6173, "SUB_DISTRICT_CODE": "600903", "SUB_DISTRICT_NAME": "วังน้ำลัด", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6174, "SUB_DISTRICT_CODE": "600904", "SUB_DISTRICT_NAME": "ตะคร้อ", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6175, "SUB_DISTRICT_CODE": "600905", "SUB_DISTRICT_NAME": "โพธิ์ประสาท", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6176, "SUB_DISTRICT_CODE": "600906", "SUB_DISTRICT_NAME": "วังข่อย", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6177, "SUB_DISTRICT_CODE": "600907", "SUB_DISTRICT_NAME": "นาขอม", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6178, "SUB_DISTRICT_CODE": "600908", "SUB_DISTRICT_NAME": "ไพศาลี", "DISTRICT_ID": 691, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6179, "SUB_DISTRICT_CODE": "601001", "SUB_DISTRICT_NAME": "พยุหะ", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6180, "SUB_DISTRICT_CODE": "601002", "SUB_DISTRICT_NAME": "เนินมะกอก", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6181, "SUB_DISTRICT_CODE": "601003", "SUB_DISTRICT_NAME": "นิคมเขาบ่อแก้ว", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6182, "SUB_DISTRICT_CODE": "601004", "SUB_DISTRICT_NAME": "ม่วงหัก", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6183, "SUB_DISTRICT_CODE": "601005", "SUB_DISTRICT_NAME": "ยางขาว", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6184, "SUB_DISTRICT_CODE": "601006", "SUB_DISTRICT_NAME": "ย่านมัทรี", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6185, "SUB_DISTRICT_CODE": "601007", "SUB_DISTRICT_NAME": "เขาทอง", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6186, "SUB_DISTRICT_CODE": "601008", "SUB_DISTRICT_NAME": "ท่าน้ำอ้อย", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6187, "SUB_DISTRICT_CODE": "601009", "SUB_DISTRICT_NAME": "น้ำทรง", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6188, "SUB_DISTRICT_CODE": "601010", "SUB_DISTRICT_NAME": "เขากะลา", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6189, "SUB_DISTRICT_CODE": "601011", "SUB_DISTRICT_NAME": "สระทะเล", "DISTRICT_ID": 692, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6190, "SUB_DISTRICT_CODE": "601101", "SUB_DISTRICT_NAME": "ลาดยาว", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6191, "SUB_DISTRICT_CODE": "601102", "SUB_DISTRICT_NAME": "ห้วยน้ำหอม", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6192, "SUB_DISTRICT_CODE": "601103", "SUB_DISTRICT_NAME": "วังม้า", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6193, "SUB_DISTRICT_CODE": "601104", "SUB_DISTRICT_NAME": "วังเมือง", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6194, "SUB_DISTRICT_CODE": "601105", "SUB_DISTRICT_NAME": "สร้อยละคร", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6195, "SUB_DISTRICT_CODE": "601106", "SUB_DISTRICT_NAME": "มาบแก", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6196, "SUB_DISTRICT_CODE": "601107", "SUB_DISTRICT_NAME": "หนองยาว", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6197, "SUB_DISTRICT_CODE": "601108", "SUB_DISTRICT_NAME": "หนองนมวัว", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6198, "SUB_DISTRICT_CODE": "601109", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6199, "SUB_DISTRICT_CODE": "601110", "SUB_DISTRICT_NAME": "เนินขี้เหล็ก", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6200, "SUB_DISTRICT_CODE": "601111", "SUB_DISTRICT_NAME": "*แแม่เล่ย์", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6201, "SUB_DISTRICT_CODE": "601112", "SUB_DISTRICT_NAME": "*แแม่วงก์", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6202, "SUB_DISTRICT_CODE": "601113", "SUB_DISTRICT_NAME": "*ววังซ่าน", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6203, "SUB_DISTRICT_CODE": "601114", "SUB_DISTRICT_NAME": "*เเขาชนกัน", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6204, "SUB_DISTRICT_CODE": "601115", "SUB_DISTRICT_NAME": "*ปปางสวรรค์", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6205, "SUB_DISTRICT_CODE": "601116", "SUB_DISTRICT_NAME": "ศาลเจ้าไก่ต่อ", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6206, "SUB_DISTRICT_CODE": "601117", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 693, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6207, "SUB_DISTRICT_CODE": "601201", "SUB_DISTRICT_NAME": "ตากฟ้า", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6208, "SUB_DISTRICT_CODE": "601202", "SUB_DISTRICT_NAME": "ลำพยนต์", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6209, "SUB_DISTRICT_CODE": "601203", "SUB_DISTRICT_NAME": "สุขสำราญ", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6210, "SUB_DISTRICT_CODE": "601204", "SUB_DISTRICT_NAME": "หนองพิกุล", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6211, "SUB_DISTRICT_CODE": "601205", "SUB_DISTRICT_NAME": "พุนกยูง", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6212, "SUB_DISTRICT_CODE": "601206", "SUB_DISTRICT_NAME": "อุดมธัญญา", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6213, "SUB_DISTRICT_CODE": "601207", "SUB_DISTRICT_NAME": "เขาชายธง", "DISTRICT_ID": 694, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6214, "SUB_DISTRICT_CODE": "601301", "SUB_DISTRICT_NAME": "แม่วงก์", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6215, "SUB_DISTRICT_CODE": "601302", "SUB_DISTRICT_NAME": "ห้วยน้ำหอม*", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6216, "SUB_DISTRICT_CODE": "601303", "SUB_DISTRICT_NAME": "แม่เล่ย์", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6217, "SUB_DISTRICT_CODE": "601304", "SUB_DISTRICT_NAME": "วังซ่าน", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6218, "SUB_DISTRICT_CODE": "601305", "SUB_DISTRICT_NAME": "เขาชนกัน", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6219, "SUB_DISTRICT_CODE": "601306", "SUB_DISTRICT_NAME": "ปางสวรรค์*", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6220, "SUB_DISTRICT_CODE": "601307", "SUB_DISTRICT_NAME": "แม่เปิน*", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6221, "SUB_DISTRICT_CODE": "601308", "SUB_DISTRICT_NAME": "ชุมตาบง*", "DISTRICT_ID": 695, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6222, "SUB_DISTRICT_CODE": "601401", "SUB_DISTRICT_NAME": "แม่เปิน", "DISTRICT_ID": 696, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6223, "SUB_DISTRICT_CODE": "601501", "SUB_DISTRICT_NAME": "ชุมตาบง", "DISTRICT_ID": 697, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6224, "SUB_DISTRICT_CODE": "601502", "SUB_DISTRICT_NAME": "ปางสวรรค์", "DISTRICT_ID": 697, "PROVINCE_ID": 47, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6225, "SUB_DISTRICT_CODE": "610101", "SUB_DISTRICT_NAME": "อุทัยใหม่", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6226, "SUB_DISTRICT_CODE": "610102", "SUB_DISTRICT_NAME": "น้ำซึม", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6227, "SUB_DISTRICT_CODE": "610103", "SUB_DISTRICT_NAME": "สะแกกรัง", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6228, "SUB_DISTRICT_CODE": "610104", "SUB_DISTRICT_NAME": "ดอนขวาง", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6229, "SUB_DISTRICT_CODE": "610105", "SUB_DISTRICT_NAME": "หาดทนง", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6230, "SUB_DISTRICT_CODE": "610106", "SUB_DISTRICT_NAME": "เกาะเทโพ", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6231, "SUB_DISTRICT_CODE": "610107", "SUB_DISTRICT_NAME": "ท่าซุง", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6232, "SUB_DISTRICT_CODE": "610108", "SUB_DISTRICT_NAME": "หนองแก", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6233, "SUB_DISTRICT_CODE": "610109", "SUB_DISTRICT_NAME": "โนนเหล็ก", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6234, "SUB_DISTRICT_CODE": "610110", "SUB_DISTRICT_NAME": "หนองเต่า", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6235, "SUB_DISTRICT_CODE": "610111", "SUB_DISTRICT_NAME": "หนองไผ่แบน", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6236, "SUB_DISTRICT_CODE": "610112", "SUB_DISTRICT_NAME": "หนองพังค่า", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6237, "SUB_DISTRICT_CODE": "610113", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6238, "SUB_DISTRICT_CODE": "610114", "SUB_DISTRICT_NAME": "เนินแจง", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6239, "SUB_DISTRICT_CODE": "610199", "SUB_DISTRICT_NAME": "*ข้าวเม่า", "DISTRICT_ID": 701, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6240, "SUB_DISTRICT_CODE": "610201", "SUB_DISTRICT_NAME": "ทัพทัน", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6241, "SUB_DISTRICT_CODE": "610202", "SUB_DISTRICT_NAME": "ทุ่งนาไทย", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6242, "SUB_DISTRICT_CODE": "610203", "SUB_DISTRICT_NAME": "เขาขี้ฝอย", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6243, "SUB_DISTRICT_CODE": "610204", "SUB_DISTRICT_NAME": "หนองหญ้าปล้อง", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6244, "SUB_DISTRICT_CODE": "610205", "SUB_DISTRICT_NAME": "โคกหม้อ", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6245, "SUB_DISTRICT_CODE": "610206", "SUB_DISTRICT_NAME": "หนองยายดา", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6246, "SUB_DISTRICT_CODE": "610207", "SUB_DISTRICT_NAME": "หนองกลางดง", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6247, "SUB_DISTRICT_CODE": "610208", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6248, "SUB_DISTRICT_CODE": "610209", "SUB_DISTRICT_NAME": "หนองสระ", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6249, "SUB_DISTRICT_CODE": "610210", "SUB_DISTRICT_NAME": "ตลุกดู่", "DISTRICT_ID": 702, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6250, "SUB_DISTRICT_CODE": "610301", "SUB_DISTRICT_NAME": "สว่างอารมณ์", "DISTRICT_ID": 703, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6251, "SUB_DISTRICT_CODE": "610302", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 703, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6252, "SUB_DISTRICT_CODE": "610303", "SUB_DISTRICT_NAME": "พลวงสองนาง", "DISTRICT_ID": 703, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6253, "SUB_DISTRICT_CODE": "610304", "SUB_DISTRICT_NAME": "ไผ่เขียว", "DISTRICT_ID": 703, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6254, "SUB_DISTRICT_CODE": "610305", "SUB_DISTRICT_NAME": "บ่อยาง", "DISTRICT_ID": 703, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6255, "SUB_DISTRICT_CODE": "610401", "SUB_DISTRICT_NAME": "หนองฉาง", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6256, "SUB_DISTRICT_CODE": "610402", "SUB_DISTRICT_NAME": "หนองยาง", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6257, "SUB_DISTRICT_CODE": "610403", "SUB_DISTRICT_NAME": "หนองนางนวล", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6258, "SUB_DISTRICT_CODE": "610404", "SUB_DISTRICT_NAME": "หนองสรวง", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6259, "SUB_DISTRICT_CODE": "610405", "SUB_DISTRICT_NAME": "บ้านเก่า", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6260, "SUB_DISTRICT_CODE": "610406", "SUB_DISTRICT_NAME": "อุทัยเก่า", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6261, "SUB_DISTRICT_CODE": "610407", "SUB_DISTRICT_NAME": "ทุ่งโพ", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6262, "SUB_DISTRICT_CODE": "610408", "SUB_DISTRICT_NAME": "ทุ่งพง", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6263, "SUB_DISTRICT_CODE": "610409", "SUB_DISTRICT_NAME": "เขาบางแกรก", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6264, "SUB_DISTRICT_CODE": "610410", "SUB_DISTRICT_NAME": "เขากวางทอง", "DISTRICT_ID": 704, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6265, "SUB_DISTRICT_CODE": "610501", "SUB_DISTRICT_NAME": "หนองขาหย่าง", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6266, "SUB_DISTRICT_CODE": "610502", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6267, "SUB_DISTRICT_CODE": "610503", "SUB_DISTRICT_NAME": "ดอนกลอย", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6268, "SUB_DISTRICT_CODE": "610504", "SUB_DISTRICT_NAME": "ห้วยรอบ", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6269, "SUB_DISTRICT_CODE": "610505", "SUB_DISTRICT_NAME": "ทุ่งพึ่ง", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6270, "SUB_DISTRICT_CODE": "610506", "SUB_DISTRICT_NAME": "ท่าโพ", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6271, "SUB_DISTRICT_CODE": "610507", "SUB_DISTRICT_NAME": "หมกแถว", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6272, "SUB_DISTRICT_CODE": "610508", "SUB_DISTRICT_NAME": "หลุมเข้า", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6273, "SUB_DISTRICT_CODE": "610509", "SUB_DISTRICT_NAME": "ดงขวาง", "DISTRICT_ID": 705, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6274, "SUB_DISTRICT_CODE": "610601", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6275, "SUB_DISTRICT_CODE": "610602", "SUB_DISTRICT_NAME": "ทัพหลวง", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6276, "SUB_DISTRICT_CODE": "610603", "SUB_DISTRICT_NAME": "ห้วยแห้ง", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6277, "SUB_DISTRICT_CODE": "610604", "SUB_DISTRICT_NAME": "คอกควาย", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6278, "SUB_DISTRICT_CODE": "610605", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6279, "SUB_DISTRICT_CODE": "610606", "SUB_DISTRICT_NAME": "เมืองการุ้ง", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6280, "SUB_DISTRICT_CODE": "610607", "SUB_DISTRICT_NAME": "แก่นมะกรูด", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6281, "SUB_DISTRICT_CODE": "610609", "SUB_DISTRICT_NAME": "หนองจอก", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6282, "SUB_DISTRICT_CODE": "610610", "SUB_DISTRICT_NAME": "หูช้าง", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6283, "SUB_DISTRICT_CODE": "610611", "SUB_DISTRICT_NAME": "บ้านบึง", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6284, "SUB_DISTRICT_CODE": "610612", "SUB_DISTRICT_NAME": "บ้านใหม่คลองเคียน", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6285, "SUB_DISTRICT_CODE": "610613", "SUB_DISTRICT_NAME": "หนองบ่มกล้วย", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6286, "SUB_DISTRICT_CODE": "610614", "SUB_DISTRICT_NAME": "เจ้าวัด", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6287, "SUB_DISTRICT_CODE": "610695", "SUB_DISTRICT_NAME": "*ห้วยคต", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6288, "SUB_DISTRICT_CODE": "610696", "SUB_DISTRICT_NAME": "*สุขฤทัย", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6289, "SUB_DISTRICT_CODE": "610697", "SUB_DISTRICT_NAME": "*ป่าอ้อ", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6290, "SUB_DISTRICT_CODE": "610698", "SUB_DISTRICT_NAME": "*ประดู่ยืน", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6291, "SUB_DISTRICT_CODE": "610699", "SUB_DISTRICT_NAME": "*ลานสัก", "DISTRICT_ID": 706, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6292, "SUB_DISTRICT_CODE": "610701", "SUB_DISTRICT_NAME": "ลานสัก", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6293, "SUB_DISTRICT_CODE": "610702", "SUB_DISTRICT_NAME": "ประดู่ยืน", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6294, "SUB_DISTRICT_CODE": "610703", "SUB_DISTRICT_NAME": "ป่าอ้อ", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6295, "SUB_DISTRICT_CODE": "610704", "SUB_DISTRICT_NAME": "ระบำ", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6296, "SUB_DISTRICT_CODE": "610705", "SUB_DISTRICT_NAME": "น้ำรอบ", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6297, "SUB_DISTRICT_CODE": "610706", "SUB_DISTRICT_NAME": "ทุ่งนางาม", "DISTRICT_ID": 707, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6298, "SUB_DISTRICT_CODE": "610801", "SUB_DISTRICT_NAME": "สุขฤทัย", "DISTRICT_ID": 708, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6299, "SUB_DISTRICT_CODE": "610802", "SUB_DISTRICT_NAME": "ทองหลาง", "DISTRICT_ID": 708, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6300, "SUB_DISTRICT_CODE": "610803", "SUB_DISTRICT_NAME": "ห้วยคต", "DISTRICT_ID": 708, "PROVINCE_ID": 48, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6301, "SUB_DISTRICT_CODE": "620101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6302, "SUB_DISTRICT_CODE": "620102", "SUB_DISTRICT_NAME": "ไตรตรึงษ์", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6303, "SUB_DISTRICT_CODE": "620103", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6304, "SUB_DISTRICT_CODE": "620104", "SUB_DISTRICT_NAME": "นาบ่อคำ", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6305, "SUB_DISTRICT_CODE": "620105", "SUB_DISTRICT_NAME": "นครชุม", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6306, "SUB_DISTRICT_CODE": "620106", "SUB_DISTRICT_NAME": "ทรงธรรม", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6307, "SUB_DISTRICT_CODE": "620107", "SUB_DISTRICT_NAME": "ลานดอกไม้", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6308, "SUB_DISTRICT_CODE": "620108", "SUB_DISTRICT_NAME": "ลานดอกไม้ตก*", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6309, "SUB_DISTRICT_CODE": "620109", "SUB_DISTRICT_NAME": "โกสัมพี*", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6310, "SUB_DISTRICT_CODE": "620110", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6311, "SUB_DISTRICT_CODE": "620111", "SUB_DISTRICT_NAME": "คณฑี", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6312, "SUB_DISTRICT_CODE": "620112", "SUB_DISTRICT_NAME": "นิคมทุ่งโพธิ์ทะเล", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6313, "SUB_DISTRICT_CODE": "620113", "SUB_DISTRICT_NAME": "เทพนคร", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6314, "SUB_DISTRICT_CODE": "620114", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6315, "SUB_DISTRICT_CODE": "620115", "SUB_DISTRICT_NAME": "ท่าขุนราม", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6316, "SUB_DISTRICT_CODE": "620116", "SUB_DISTRICT_NAME": "เพชรชมภู*", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6317, "SUB_DISTRICT_CODE": "620117", "SUB_DISTRICT_NAME": "คลองแม่ลาย", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6318, "SUB_DISTRICT_CODE": "620118", "SUB_DISTRICT_NAME": "ธำมรงค์", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6319, "SUB_DISTRICT_CODE": "620119", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6320, "SUB_DISTRICT_CODE": "620197", "SUB_DISTRICT_NAME": "*หนองคล้า", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6321, "SUB_DISTRICT_CODE": "620198", "SUB_DISTRICT_NAME": "*โป่งน้ำร้อน", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6322, "SUB_DISTRICT_CODE": "620199", "SUB_DISTRICT_NAME": "*ไทรงาม", "DISTRICT_ID": 709, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6323, "SUB_DISTRICT_CODE": "620201", "SUB_DISTRICT_NAME": "ไทรงาม", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6324, "SUB_DISTRICT_CODE": "620202", "SUB_DISTRICT_NAME": "หนองคล้า", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6325, "SUB_DISTRICT_CODE": "620203", "SUB_DISTRICT_NAME": "หนองทอง", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6326, "SUB_DISTRICT_CODE": "620204", "SUB_DISTRICT_NAME": "หนองไม้กอง", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6327, "SUB_DISTRICT_CODE": "620205", "SUB_DISTRICT_NAME": "มหาชัย", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6328, "SUB_DISTRICT_CODE": "620206", "SUB_DISTRICT_NAME": "พานทอง", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6329, "SUB_DISTRICT_CODE": "620207", "SUB_DISTRICT_NAME": "หนองแม่แตง", "DISTRICT_ID": 710, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6330, "SUB_DISTRICT_CODE": "620301", "SUB_DISTRICT_NAME": "คลองน้ำไหล", "DISTRICT_ID": 711, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6331, "SUB_DISTRICT_CODE": "620302", "SUB_DISTRICT_NAME": "โป่งน้ำร้อน", "DISTRICT_ID": 711, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6332, "SUB_DISTRICT_CODE": "620303", "SUB_DISTRICT_NAME": "คลองลานพัฒนา", "DISTRICT_ID": 711, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6333, "SUB_DISTRICT_CODE": "620304", "SUB_DISTRICT_NAME": "สักงาม", "DISTRICT_ID": 711, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6334, "SUB_DISTRICT_CODE": "620401", "SUB_DISTRICT_NAME": "*วังชะโอน", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6335, "SUB_DISTRICT_CODE": "620402", "SUB_DISTRICT_NAME": "*ระหาน", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6336, "SUB_DISTRICT_CODE": "620403", "SUB_DISTRICT_NAME": "ยางสูง", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6337, "SUB_DISTRICT_CODE": "620404", "SUB_DISTRICT_NAME": "ป่าพุทรา", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6338, "SUB_DISTRICT_CODE": "620405", "SUB_DISTRICT_NAME": "แสนตอ", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6339, "SUB_DISTRICT_CODE": "620406", "SUB_DISTRICT_NAME": "สลกบาตร", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6340, "SUB_DISTRICT_CODE": "620407", "SUB_DISTRICT_NAME": "บ่อถ้ำ", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6341, "SUB_DISTRICT_CODE": "620408", "SUB_DISTRICT_NAME": "ดอนแตง", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6342, "SUB_DISTRICT_CODE": "620409", "SUB_DISTRICT_NAME": "วังชะพลู", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6343, "SUB_DISTRICT_CODE": "620410", "SUB_DISTRICT_NAME": "โค้งไผ่", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6344, "SUB_DISTRICT_CODE": "620411", "SUB_DISTRICT_NAME": "ปางมะค่า", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6345, "SUB_DISTRICT_CODE": "620412", "SUB_DISTRICT_NAME": "วังหามแห", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6346, "SUB_DISTRICT_CODE": "620413", "SUB_DISTRICT_NAME": "เกาะตาล", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6347, "SUB_DISTRICT_CODE": "620414", "SUB_DISTRICT_NAME": "*บึงสามัคคี", "DISTRICT_ID": 712, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6348, "SUB_DISTRICT_CODE": "620501", "SUB_DISTRICT_NAME": "คลองขลุง", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6349, "SUB_DISTRICT_CODE": "620502", "SUB_DISTRICT_NAME": "ท่ามะเขือ", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6350, "SUB_DISTRICT_CODE": "620503", "SUB_DISTRICT_NAME": "*ททุ่งทราย", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6351, "SUB_DISTRICT_CODE": "620504", "SUB_DISTRICT_NAME": "ท่าพุทรา", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6352, "SUB_DISTRICT_CODE": "620505", "SUB_DISTRICT_NAME": "แม่ลาด", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6353, "SUB_DISTRICT_CODE": "620506", "SUB_DISTRICT_NAME": "วังยาง", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6354, "SUB_DISTRICT_CODE": "620507", "SUB_DISTRICT_NAME": "วังแขม", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6355, "SUB_DISTRICT_CODE": "620508", "SUB_DISTRICT_NAME": "หัวถนน", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6356, "SUB_DISTRICT_CODE": "620509", "SUB_DISTRICT_NAME": "วังไทร", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6357, "SUB_DISTRICT_CODE": "620510", "SUB_DISTRICT_NAME": "*โพธิ์ทอง", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6358, "SUB_DISTRICT_CODE": "620511", "SUB_DISTRICT_NAME": "*ปางตาไว", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6359, "SUB_DISTRICT_CODE": "620512", "SUB_DISTRICT_NAME": "*ถถาวรวัฒนา", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6360, "SUB_DISTRICT_CODE": "620513", "SUB_DISTRICT_NAME": "วังบัว", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6361, "SUB_DISTRICT_CODE": "620514", "SUB_DISTRICT_NAME": "*ทุ่งทอง", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6362, "SUB_DISTRICT_CODE": "620515", "SUB_DISTRICT_NAME": "*หินดาต", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6363, "SUB_DISTRICT_CODE": "620516", "SUB_DISTRICT_NAME": "คลองสมบูรณ์", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6364, "SUB_DISTRICT_CODE": "620599", "SUB_DISTRICT_NAME": "*ทุ่งทราย", "DISTRICT_ID": 713, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6365, "SUB_DISTRICT_CODE": "620601", "SUB_DISTRICT_NAME": "พรานกระต่าย", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6366, "SUB_DISTRICT_CODE": "620602", "SUB_DISTRICT_NAME": "หนองหัววัว", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6367, "SUB_DISTRICT_CODE": "620603", "SUB_DISTRICT_NAME": "ท่าไม้", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6368, "SUB_DISTRICT_CODE": "620604", "SUB_DISTRICT_NAME": "วังควง", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6369, "SUB_DISTRICT_CODE": "620605", "SUB_DISTRICT_NAME": "วังตะแบก", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6370, "SUB_DISTRICT_CODE": "620606", "SUB_DISTRICT_NAME": "เขาคีริส", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6371, "SUB_DISTRICT_CODE": "620607", "SUB_DISTRICT_NAME": "คุยบ้านโอง", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6372, "SUB_DISTRICT_CODE": "620608", "SUB_DISTRICT_NAME": "คลองพิไกร", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6373, "SUB_DISTRICT_CODE": "620609", "SUB_DISTRICT_NAME": "ถ้ำกระต่ายทอง", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6374, "SUB_DISTRICT_CODE": "620610", "SUB_DISTRICT_NAME": "ห้วยยั้ง", "DISTRICT_ID": 714, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6375, "SUB_DISTRICT_CODE": "620701", "SUB_DISTRICT_NAME": "ลานกระบือ", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6376, "SUB_DISTRICT_CODE": "620702", "SUB_DISTRICT_NAME": "ช่องลม", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6377, "SUB_DISTRICT_CODE": "620703", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6378, "SUB_DISTRICT_CODE": "620704", "SUB_DISTRICT_NAME": "โนนพลวง", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6379, "SUB_DISTRICT_CODE": "620705", "SUB_DISTRICT_NAME": "ประชาสุขสันต์", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6380, "SUB_DISTRICT_CODE": "620706", "SUB_DISTRICT_NAME": "บึงทับแรต", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6381, "SUB_DISTRICT_CODE": "620707", "SUB_DISTRICT_NAME": "จันทิมา", "DISTRICT_ID": 715, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6382, "SUB_DISTRICT_CODE": "620801", "SUB_DISTRICT_NAME": "ทุ่งทราย", "DISTRICT_ID": 716, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6383, "SUB_DISTRICT_CODE": "620802", "SUB_DISTRICT_NAME": "ทุ่งทอง", "DISTRICT_ID": 716, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6384, "SUB_DISTRICT_CODE": "620803", "SUB_DISTRICT_NAME": "ถาวรวัฒนา", "DISTRICT_ID": 716, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6385, "SUB_DISTRICT_CODE": "620901", "SUB_DISTRICT_NAME": "โพธิ์ทอง", "DISTRICT_ID": 717, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6386, "SUB_DISTRICT_CODE": "620902", "SUB_DISTRICT_NAME": "หินดาต", "DISTRICT_ID": 717, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6387, "SUB_DISTRICT_CODE": "620903", "SUB_DISTRICT_NAME": "ปางตาไว", "DISTRICT_ID": 717, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6388, "SUB_DISTRICT_CODE": "621001", "SUB_DISTRICT_NAME": "บึงสามัคคี", "DISTRICT_ID": 718, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6389, "SUB_DISTRICT_CODE": "621002", "SUB_DISTRICT_NAME": "วังชะโอน", "DISTRICT_ID": 718, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6390, "SUB_DISTRICT_CODE": "621003", "SUB_DISTRICT_NAME": "ระหาน", "DISTRICT_ID": 718, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6391, "SUB_DISTRICT_CODE": "621004", "SUB_DISTRICT_NAME": "เทพนิมิต", "DISTRICT_ID": 718, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6392, "SUB_DISTRICT_CODE": "621101", "SUB_DISTRICT_NAME": "โกสัมพี", "DISTRICT_ID": 719, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6393, "SUB_DISTRICT_CODE": "621102", "SUB_DISTRICT_NAME": "เพชรชมภู", "DISTRICT_ID": 719, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6394, "SUB_DISTRICT_CODE": "621103", "SUB_DISTRICT_NAME": "ลานดอกไม้ตก", "DISTRICT_ID": 719, "PROVINCE_ID": 49, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6395, "SUB_DISTRICT_CODE": "630101", "SUB_DISTRICT_NAME": "ระแหง", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6396, "SUB_DISTRICT_CODE": "630102", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6397, "SUB_DISTRICT_CODE": "630103", "SUB_DISTRICT_NAME": "เชียงเงิน", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6398, "SUB_DISTRICT_CODE": "630104", "SUB_DISTRICT_NAME": "หัวเดียด", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6399, "SUB_DISTRICT_CODE": "630105", "SUB_DISTRICT_NAME": "หนองบัวเหนือ", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6400, "SUB_DISTRICT_CODE": "630106", "SUB_DISTRICT_NAME": "ไม้งาม", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6401, "SUB_DISTRICT_CODE": "630107", "SUB_DISTRICT_NAME": "โป่งแดง", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6402, "SUB_DISTRICT_CODE": "630108", "SUB_DISTRICT_NAME": "น้ำรึม", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6403, "SUB_DISTRICT_CODE": "630109", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6404, "SUB_DISTRICT_CODE": "630110", "SUB_DISTRICT_NAME": "เชียงทอง*", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6405, "SUB_DISTRICT_CODE": "630111", "SUB_DISTRICT_NAME": "แม่ท้อ", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6406, "SUB_DISTRICT_CODE": "630112", "SUB_DISTRICT_NAME": "ป่ามะม่วง", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6407, "SUB_DISTRICT_CODE": "630113", "SUB_DISTRICT_NAME": "หนองบัวใต้", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6408, "SUB_DISTRICT_CODE": "630114", "SUB_DISTRICT_NAME": "วังประจบ", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6409, "SUB_DISTRICT_CODE": "630115", "SUB_DISTRICT_NAME": "ตลุกกลางทุ่ง", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6410, "SUB_DISTRICT_CODE": "630116", "SUB_DISTRICT_NAME": "นาโบสถ์*", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6411, "SUB_DISTRICT_CODE": "630117", "SUB_DISTRICT_NAME": "ประดาง*", "DISTRICT_ID": 720, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6412, "SUB_DISTRICT_CODE": "630201", "SUB_DISTRICT_NAME": "ตากออก", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6413, "SUB_DISTRICT_CODE": "630202", "SUB_DISTRICT_NAME": "สมอโคน", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6414, "SUB_DISTRICT_CODE": "630203", "SUB_DISTRICT_NAME": "แม่สลิด", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6415, "SUB_DISTRICT_CODE": "630204", "SUB_DISTRICT_NAME": "ตากตก", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6416, "SUB_DISTRICT_CODE": "630205", "SUB_DISTRICT_NAME": "เกาะตะเภา", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6417, "SUB_DISTRICT_CODE": "630206", "SUB_DISTRICT_NAME": "ทุ่งกระเชาะ", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6418, "SUB_DISTRICT_CODE": "630207", "SUB_DISTRICT_NAME": "ท้องฟ้า", "DISTRICT_ID": 721, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6419, "SUB_DISTRICT_CODE": "630301", "SUB_DISTRICT_NAME": "สามเงา", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6420, "SUB_DISTRICT_CODE": "630302", "SUB_DISTRICT_NAME": "วังหมัน", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6421, "SUB_DISTRICT_CODE": "630303", "SUB_DISTRICT_NAME": "ยกกระบัตร", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6422, "SUB_DISTRICT_CODE": "630304", "SUB_DISTRICT_NAME": "ย่านรี", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6423, "SUB_DISTRICT_CODE": "630305", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6424, "SUB_DISTRICT_CODE": "630306", "SUB_DISTRICT_NAME": "วังจันทร์", "DISTRICT_ID": 722, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6425, "SUB_DISTRICT_CODE": "630401", "SUB_DISTRICT_NAME": "แม่ระมาด", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6426, "SUB_DISTRICT_CODE": "630402", "SUB_DISTRICT_NAME": "แม่จะเรา", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6427, "SUB_DISTRICT_CODE": "630403", "SUB_DISTRICT_NAME": "ขะเนจื้อ", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6428, "SUB_DISTRICT_CODE": "630404", "SUB_DISTRICT_NAME": "แม่ตื่น", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6429, "SUB_DISTRICT_CODE": "630405", "SUB_DISTRICT_NAME": "สามหมื่น", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6430, "SUB_DISTRICT_CODE": "630406", "SUB_DISTRICT_NAME": "พระธาตุ", "DISTRICT_ID": 723, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6431, "SUB_DISTRICT_CODE": "630501", "SUB_DISTRICT_NAME": "ท่าสองยาง", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6432, "SUB_DISTRICT_CODE": "630502", "SUB_DISTRICT_NAME": "แม่ต้าน", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6433, "SUB_DISTRICT_CODE": "630503", "SUB_DISTRICT_NAME": "แม่สอง", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6434, "SUB_DISTRICT_CODE": "630504", "SUB_DISTRICT_NAME": "แม่หละ", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6435, "SUB_DISTRICT_CODE": "630505", "SUB_DISTRICT_NAME": "แม่วะหลวง", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6436, "SUB_DISTRICT_CODE": "630506", "SUB_DISTRICT_NAME": "แม่อุสุ", "DISTRICT_ID": 724, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6437, "SUB_DISTRICT_CODE": "630601", "SUB_DISTRICT_NAME": "แม่สอด", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6438, "SUB_DISTRICT_CODE": "630602", "SUB_DISTRICT_NAME": "แม่กุ", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6439, "SUB_DISTRICT_CODE": "630603", "SUB_DISTRICT_NAME": "พะวอ", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6440, "SUB_DISTRICT_CODE": "630604", "SUB_DISTRICT_NAME": "แม่ตาว", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6441, "SUB_DISTRICT_CODE": "630605", "SUB_DISTRICT_NAME": "แม่กาษา", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6442, "SUB_DISTRICT_CODE": "630606", "SUB_DISTRICT_NAME": "ท่าสายลวด", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6443, "SUB_DISTRICT_CODE": "630607", "SUB_DISTRICT_NAME": "แม่ปะ", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6444, "SUB_DISTRICT_CODE": "630608", "SUB_DISTRICT_NAME": "มหาวัน", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6445, "SUB_DISTRICT_CODE": "630609", "SUB_DISTRICT_NAME": "ด่านแม่ละเมา", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6446, "SUB_DISTRICT_CODE": "630610", "SUB_DISTRICT_NAME": "พระธาตุผาแดง", "DISTRICT_ID": 725, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6447, "SUB_DISTRICT_CODE": "630701", "SUB_DISTRICT_NAME": "พบพระ", "DISTRICT_ID": 726, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6448, "SUB_DISTRICT_CODE": "630702", "SUB_DISTRICT_NAME": "ช่องแคบ", "DISTRICT_ID": 726, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6449, "SUB_DISTRICT_CODE": "630703", "SUB_DISTRICT_NAME": "คีรีราษฎร์", "DISTRICT_ID": 726, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6450, "SUB_DISTRICT_CODE": "630704", "SUB_DISTRICT_NAME": "วาเล่ย์", "DISTRICT_ID": 726, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6451, "SUB_DISTRICT_CODE": "630705", "SUB_DISTRICT_NAME": "รวมไทยพัฒนา", "DISTRICT_ID": 726, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6452, "SUB_DISTRICT_CODE": "630801", "SUB_DISTRICT_NAME": "อุ้มผาง", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6453, "SUB_DISTRICT_CODE": "630802", "SUB_DISTRICT_NAME": "หนองหลวง", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6454, "SUB_DISTRICT_CODE": "630803", "SUB_DISTRICT_NAME": "โมโกร", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6455, "SUB_DISTRICT_CODE": "630804", "SUB_DISTRICT_NAME": "แม่จัน", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6456, "SUB_DISTRICT_CODE": "630805", "SUB_DISTRICT_NAME": "แม่ละมุ้ง", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6457, "SUB_DISTRICT_CODE": "630806", "SUB_DISTRICT_NAME": "แม่กลอง", "DISTRICT_ID": 727, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6458, "SUB_DISTRICT_CODE": "630901", "SUB_DISTRICT_NAME": "เชียงทอง", "DISTRICT_ID": 728, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6459, "SUB_DISTRICT_CODE": "630902", "SUB_DISTRICT_NAME": "นาโบสถ์", "DISTRICT_ID": 728, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6460, "SUB_DISTRICT_CODE": "630903", "SUB_DISTRICT_NAME": "ประดาง", "DISTRICT_ID": 728, "PROVINCE_ID": 50, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6461, "SUB_DISTRICT_CODE": "640101", "SUB_DISTRICT_NAME": "ธานี", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6462, "SUB_DISTRICT_CODE": "640102", "SUB_DISTRICT_NAME": "บ้านสวน", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6463, "SUB_DISTRICT_CODE": "640103", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6464, "SUB_DISTRICT_CODE": "640104", "SUB_DISTRICT_NAME": "ปากแคว", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6465, "SUB_DISTRICT_CODE": "640105", "SUB_DISTRICT_NAME": "ยางซ้าย", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6466, "SUB_DISTRICT_CODE": "640106", "SUB_DISTRICT_NAME": "บ้านกล้วย", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6467, "SUB_DISTRICT_CODE": "640107", "SUB_DISTRICT_NAME": "บ้านหลุม", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6468, "SUB_DISTRICT_CODE": "640108", "SUB_DISTRICT_NAME": "ตาลเตี้ย", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6469, "SUB_DISTRICT_CODE": "640109", "SUB_DISTRICT_NAME": "ปากพระ", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6470, "SUB_DISTRICT_CODE": "640110", "SUB_DISTRICT_NAME": "วังทองแดง", "DISTRICT_ID": 730, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6471, "SUB_DISTRICT_CODE": "640201", "SUB_DISTRICT_NAME": "ลานหอย", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6472, "SUB_DISTRICT_CODE": "640202", "SUB_DISTRICT_NAME": "บ้านด่าน", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6473, "SUB_DISTRICT_CODE": "640203", "SUB_DISTRICT_NAME": "วังตะคร้อ", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6474, "SUB_DISTRICT_CODE": "640204", "SUB_DISTRICT_NAME": "วังน้ำขาว", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6475, "SUB_DISTRICT_CODE": "640205", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6476, "SUB_DISTRICT_CODE": "640206", "SUB_DISTRICT_NAME": "หนองหญ้าปล้อง", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6477, "SUB_DISTRICT_CODE": "640207", "SUB_DISTRICT_NAME": "วังลึก", "DISTRICT_ID": 731, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6478, "SUB_DISTRICT_CODE": "640301", "SUB_DISTRICT_NAME": "โตนด", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6479, "SUB_DISTRICT_CODE": "640302", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6480, "SUB_DISTRICT_CODE": "640303", "SUB_DISTRICT_NAME": "บ้านป้อม", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6481, "SUB_DISTRICT_CODE": "640304", "SUB_DISTRICT_NAME": "สามพวง", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6482, "SUB_DISTRICT_CODE": "640305", "SUB_DISTRICT_NAME": "ศรีคีรีมาศ", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6483, "SUB_DISTRICT_CODE": "640306", "SUB_DISTRICT_NAME": "หนองจิก", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6484, "SUB_DISTRICT_CODE": "640307", "SUB_DISTRICT_NAME": "นาเชิงคีรี", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6485, "SUB_DISTRICT_CODE": "640308", "SUB_DISTRICT_NAME": "หนองกระดิ่ง", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6486, "SUB_DISTRICT_CODE": "640309", "SUB_DISTRICT_NAME": "บ้านน้ำพุ", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6487, "SUB_DISTRICT_CODE": "640310", "SUB_DISTRICT_NAME": "ทุ่งยางเมือง", "DISTRICT_ID": 732, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6488, "SUB_DISTRICT_CODE": "640401", "SUB_DISTRICT_NAME": "กง", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6489, "SUB_DISTRICT_CODE": "640402", "SUB_DISTRICT_NAME": "บ้านกร่าง", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6490, "SUB_DISTRICT_CODE": "640403", "SUB_DISTRICT_NAME": "ไกรนอก", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6491, "SUB_DISTRICT_CODE": "640404", "SUB_DISTRICT_NAME": "ไกรกลาง", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6492, "SUB_DISTRICT_CODE": "640405", "SUB_DISTRICT_NAME": "ไกรใน", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6493, "SUB_DISTRICT_CODE": "640406", "SUB_DISTRICT_NAME": "ดงเดือย", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6494, "SUB_DISTRICT_CODE": "640407", "SUB_DISTRICT_NAME": "ป่าแฝก", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6495, "SUB_DISTRICT_CODE": "640408", "SUB_DISTRICT_NAME": "กกแรต", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6496, "SUB_DISTRICT_CODE": "640409", "SUB_DISTRICT_NAME": "ท่าฉนวน", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6497, "SUB_DISTRICT_CODE": "640410", "SUB_DISTRICT_NAME": "หนองตูม", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6498, "SUB_DISTRICT_CODE": "640411", "SUB_DISTRICT_NAME": "บ้านใหม่สุขเกษม", "DISTRICT_ID": 733, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6499, "SUB_DISTRICT_CODE": "640501", "SUB_DISTRICT_NAME": "หาดเสี้ยว", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6500, "SUB_DISTRICT_CODE": "640502", "SUB_DISTRICT_NAME": "ป่างิ้ว", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6501, "SUB_DISTRICT_CODE": "640503", "SUB_DISTRICT_NAME": "แม่สำ", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6502, "SUB_DISTRICT_CODE": "640504", "SUB_DISTRICT_NAME": "แม่สิน", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6503, "SUB_DISTRICT_CODE": "640505", "SUB_DISTRICT_NAME": "บ้านตึก", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6504, "SUB_DISTRICT_CODE": "640506", "SUB_DISTRICT_NAME": "หนองอ้อ", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6505, "SUB_DISTRICT_CODE": "640507", "SUB_DISTRICT_NAME": "ท่าชัย", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6506, "SUB_DISTRICT_CODE": "640508", "SUB_DISTRICT_NAME": "ศรีสัชนาลัย", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6507, "SUB_DISTRICT_CODE": "640509", "SUB_DISTRICT_NAME": "ดงคู่", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6508, "SUB_DISTRICT_CODE": "640510", "SUB_DISTRICT_NAME": "บ้านแก่ง", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6509, "SUB_DISTRICT_CODE": "640511", "SUB_DISTRICT_NAME": "สารจิตร", "DISTRICT_ID": 734, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6510, "SUB_DISTRICT_CODE": "640601", "SUB_DISTRICT_NAME": "คลองตาล", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6511, "SUB_DISTRICT_CODE": "640602", "SUB_DISTRICT_NAME": "วังลึก", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6512, "SUB_DISTRICT_CODE": "640603", "SUB_DISTRICT_NAME": "สามเรือน", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6513, "SUB_DISTRICT_CODE": "640604", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6514, "SUB_DISTRICT_CODE": "640605", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6515, "SUB_DISTRICT_CODE": "640606", "SUB_DISTRICT_NAME": "นาขุนไกร", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6516, "SUB_DISTRICT_CODE": "640607", "SUB_DISTRICT_NAME": "เกาะตาเลี้ยง", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6517, "SUB_DISTRICT_CODE": "640608", "SUB_DISTRICT_NAME": "วัดเกาะ", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6518, "SUB_DISTRICT_CODE": "640609", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6519, "SUB_DISTRICT_CODE": "640610", "SUB_DISTRICT_NAME": "ทับผึ้ง", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6520, "SUB_DISTRICT_CODE": "640611", "SUB_DISTRICT_NAME": "บ้านซ่าน", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6521, "SUB_DISTRICT_CODE": "640612", "SUB_DISTRICT_NAME": "วังใหญ่", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6522, "SUB_DISTRICT_CODE": "640613", "SUB_DISTRICT_NAME": "ราวต้นจันทร์", "DISTRICT_ID": 735, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6523, "SUB_DISTRICT_CODE": "640701", "SUB_DISTRICT_NAME": "เมืองสวรรคโลก", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6524, "SUB_DISTRICT_CODE": "640702", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6525, "SUB_DISTRICT_CODE": "640703", "SUB_DISTRICT_NAME": "คลองกระจง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6526, "SUB_DISTRICT_CODE": "640704", "SUB_DISTRICT_NAME": "วังพิณพาทย์", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6527, "SUB_DISTRICT_CODE": "640705", "SUB_DISTRICT_NAME": "วังไม้ขอน", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6528, "SUB_DISTRICT_CODE": "640706", "SUB_DISTRICT_NAME": "ย่านยาว", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6529, "SUB_DISTRICT_CODE": "640707", "SUB_DISTRICT_NAME": "นาทุ่ง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6530, "SUB_DISTRICT_CODE": "640708", "SUB_DISTRICT_NAME": "คลองยาง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6531, "SUB_DISTRICT_CODE": "640709", "SUB_DISTRICT_NAME": "เมืองบางยม", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6532, "SUB_DISTRICT_CODE": "640710", "SUB_DISTRICT_NAME": "ท่าทอง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6533, "SUB_DISTRICT_CODE": "640711", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6534, "SUB_DISTRICT_CODE": "640712", "SUB_DISTRICT_NAME": "ป่ากุมเกาะ", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6535, "SUB_DISTRICT_CODE": "640713", "SUB_DISTRICT_NAME": "เมืองบางขลัง", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6536, "SUB_DISTRICT_CODE": "640714", "SUB_DISTRICT_NAME": "หนองกลับ", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6537, "SUB_DISTRICT_CODE": "640795", "SUB_DISTRICT_NAME": "*ประชาราษฎร์", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6538, "SUB_DISTRICT_CODE": "640796", "SUB_DISTRICT_NAME": "*คลองมะพลับ", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6539, "SUB_DISTRICT_CODE": "640797", "SUB_DISTRICT_NAME": "*น้ำขุม", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6540, "SUB_DISTRICT_CODE": "640798", "SUB_DISTRICT_NAME": "*นครเดิฐ", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6541, "SUB_DISTRICT_CODE": "640799", "SUB_DISTRICT_NAME": "*ศรีนคร", "DISTRICT_ID": 736, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6542, "SUB_DISTRICT_CODE": "640801", "SUB_DISTRICT_NAME": "ศรีนคร", "DISTRICT_ID": 737, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6543, "SUB_DISTRICT_CODE": "640802", "SUB_DISTRICT_NAME": "นครเดิฐ", "DISTRICT_ID": 737, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6544, "SUB_DISTRICT_CODE": "640803", "SUB_DISTRICT_NAME": "น้ำขุม", "DISTRICT_ID": 737, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6545, "SUB_DISTRICT_CODE": "640804", "SUB_DISTRICT_NAME": "คลองมะพลับ", "DISTRICT_ID": 737, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6546, "SUB_DISTRICT_CODE": "640805", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 737, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6547, "SUB_DISTRICT_CODE": "640901", "SUB_DISTRICT_NAME": "บ้านใหม่ไชยมงคล", "DISTRICT_ID": 738, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6548, "SUB_DISTRICT_CODE": "640902", "SUB_DISTRICT_NAME": "ไทยชนะศึก", "DISTRICT_ID": 738, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6549, "SUB_DISTRICT_CODE": "640903", "SUB_DISTRICT_NAME": "ทุ่งเสลี่ยม", "DISTRICT_ID": 738, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6550, "SUB_DISTRICT_CODE": "640904", "SUB_DISTRICT_NAME": "กลางดง", "DISTRICT_ID": 738, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6551, "SUB_DISTRICT_CODE": "640905", "SUB_DISTRICT_NAME": "เขาแก้วศรีสมบูรณ์", "DISTRICT_ID": 738, "PROVINCE_ID": 51, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6552, "SUB_DISTRICT_CODE": "650101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6553, "SUB_DISTRICT_CODE": "650102", "SUB_DISTRICT_NAME": "วังน้ำคู้", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6554, "SUB_DISTRICT_CODE": "650103", "SUB_DISTRICT_NAME": "วัดจันทร์", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6555, "SUB_DISTRICT_CODE": "650104", "SUB_DISTRICT_NAME": "วัดพริก", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6556, "SUB_DISTRICT_CODE": "650105", "SUB_DISTRICT_NAME": "ท่าทอง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6557, "SUB_DISTRICT_CODE": "650106", "SUB_DISTRICT_NAME": "ท่าโพธิ์", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6558, "SUB_DISTRICT_CODE": "650107", "SUB_DISTRICT_NAME": "สมอแข", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6559, "SUB_DISTRICT_CODE": "650108", "SUB_DISTRICT_NAME": "ดอนทอง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6560, "SUB_DISTRICT_CODE": "650109", "SUB_DISTRICT_NAME": "บ้านป่า", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6561, "SUB_DISTRICT_CODE": "650110", "SUB_DISTRICT_NAME": "ปากโทก", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6562, "SUB_DISTRICT_CODE": "650111", "SUB_DISTRICT_NAME": "หัวรอ", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6563, "SUB_DISTRICT_CODE": "650112", "SUB_DISTRICT_NAME": "จอมทอง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6564, "SUB_DISTRICT_CODE": "650113", "SUB_DISTRICT_NAME": "บ้านกร่าง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6565, "SUB_DISTRICT_CODE": "650114", "SUB_DISTRICT_NAME": "บ้านคลอง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6566, "SUB_DISTRICT_CODE": "650115", "SUB_DISTRICT_NAME": "พลายชุมพล", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6567, "SUB_DISTRICT_CODE": "650116", "SUB_DISTRICT_NAME": "มะขามสูง", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6568, "SUB_DISTRICT_CODE": "650117", "SUB_DISTRICT_NAME": "อรัญญิก", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6569, "SUB_DISTRICT_CODE": "650118", "SUB_DISTRICT_NAME": "บึงพระ", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6570, "SUB_DISTRICT_CODE": "650119", "SUB_DISTRICT_NAME": "ไผ่ขอดอน", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6571, "SUB_DISTRICT_CODE": "650120", "SUB_DISTRICT_NAME": "งิ้วงาม", "DISTRICT_ID": 739, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6572, "SUB_DISTRICT_CODE": "650201", "SUB_DISTRICT_NAME": "นครไทย", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6573, "SUB_DISTRICT_CODE": "650202", "SUB_DISTRICT_NAME": "หนองกะท้าว", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6574, "SUB_DISTRICT_CODE": "650203", "SUB_DISTRICT_NAME": "บ้านแยง", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6575, "SUB_DISTRICT_CODE": "650204", "SUB_DISTRICT_NAME": "เนินเพิ่ม", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6576, "SUB_DISTRICT_CODE": "650205", "SUB_DISTRICT_NAME": "นาบัว", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6577, "SUB_DISTRICT_CODE": "650206", "SUB_DISTRICT_NAME": "นครชุม", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6578, "SUB_DISTRICT_CODE": "650207", "SUB_DISTRICT_NAME": "น้ำกุ่ม", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6579, "SUB_DISTRICT_CODE": "650208", "SUB_DISTRICT_NAME": "ยางโกลน", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6580, "SUB_DISTRICT_CODE": "650209", "SUB_DISTRICT_NAME": "บ่อโพธิ์", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6581, "SUB_DISTRICT_CODE": "650210", "SUB_DISTRICT_NAME": "บ้านพร้าว", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6582, "SUB_DISTRICT_CODE": "650211", "SUB_DISTRICT_NAME": "ห้วยเฮี้ย", "DISTRICT_ID": 740, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6583, "SUB_DISTRICT_CODE": "650301", "SUB_DISTRICT_NAME": "ป่าแดง", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6584, "SUB_DISTRICT_CODE": "650302", "SUB_DISTRICT_NAME": "ชาติตระการ", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6585, "SUB_DISTRICT_CODE": "650303", "SUB_DISTRICT_NAME": "สวนเมี่ยง", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6586, "SUB_DISTRICT_CODE": "650304", "SUB_DISTRICT_NAME": "บ้านดง", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6587, "SUB_DISTRICT_CODE": "650305", "SUB_DISTRICT_NAME": "บ่อภาค", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6588, "SUB_DISTRICT_CODE": "650306", "SUB_DISTRICT_NAME": "ท่าสะแก", "DISTRICT_ID": 741, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6589, "SUB_DISTRICT_CODE": "650401", "SUB_DISTRICT_NAME": "บางระกำ", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6590, "SUB_DISTRICT_CODE": "650402", "SUB_DISTRICT_NAME": "ปลักแรด", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6591, "SUB_DISTRICT_CODE": "650403", "SUB_DISTRICT_NAME": "พันเสา", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6592, "SUB_DISTRICT_CODE": "650404", "SUB_DISTRICT_NAME": "วังอิทก", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6593, "SUB_DISTRICT_CODE": "650405", "SUB_DISTRICT_NAME": "บึงกอก", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6594, "SUB_DISTRICT_CODE": "650406", "SUB_DISTRICT_NAME": "หนองกุลา", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6595, "SUB_DISTRICT_CODE": "650407", "SUB_DISTRICT_NAME": "ชุมแสงสงคราม", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6596, "SUB_DISTRICT_CODE": "650408", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6597, "SUB_DISTRICT_CODE": "650409", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6598, "SUB_DISTRICT_CODE": "650410", "SUB_DISTRICT_NAME": "ท่านางงาม", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6599, "SUB_DISTRICT_CODE": "650411", "SUB_DISTRICT_NAME": "คุยม่วง", "DISTRICT_ID": 742, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6600, "SUB_DISTRICT_CODE": "650501", "SUB_DISTRICT_NAME": "บางกระทุ่ม", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6601, "SUB_DISTRICT_CODE": "650502", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6602, "SUB_DISTRICT_CODE": "650503", "SUB_DISTRICT_NAME": "โคกสลุด", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6603, "SUB_DISTRICT_CODE": "650504", "SUB_DISTRICT_NAME": "สนามคลี", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6604, "SUB_DISTRICT_CODE": "650505", "SUB_DISTRICT_NAME": "ท่าตาล", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6605, "SUB_DISTRICT_CODE": "650506", "SUB_DISTRICT_NAME": "ไผ่ล้อม", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6606, "SUB_DISTRICT_CODE": "650507", "SUB_DISTRICT_NAME": "นครป่าหมาก", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6607, "SUB_DISTRICT_CODE": "650508", "SUB_DISTRICT_NAME": "เนินกุ่ม", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6608, "SUB_DISTRICT_CODE": "650509", "SUB_DISTRICT_NAME": "วัดตายม", "DISTRICT_ID": 743, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6609, "SUB_DISTRICT_CODE": "650601", "SUB_DISTRICT_NAME": "พรหมพิราม", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6610, "SUB_DISTRICT_CODE": "650602", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6611, "SUB_DISTRICT_CODE": "650603", "SUB_DISTRICT_NAME": "วงฆ้อง", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6612, "SUB_DISTRICT_CODE": "650604", "SUB_DISTRICT_NAME": "มะตูม", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6613, "SUB_DISTRICT_CODE": "650605", "SUB_DISTRICT_NAME": "หอกลอง", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6614, "SUB_DISTRICT_CODE": "650606", "SUB_DISTRICT_NAME": "ศรีภิรมย์", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6615, "SUB_DISTRICT_CODE": "650607", "SUB_DISTRICT_NAME": "ตลุกเทียม", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6616, "SUB_DISTRICT_CODE": "650608", "SUB_DISTRICT_NAME": "วังวน", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6617, "SUB_DISTRICT_CODE": "650609", "SUB_DISTRICT_NAME": "หนองแขม", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6618, "SUB_DISTRICT_CODE": "650610", "SUB_DISTRICT_NAME": "มะต้อง", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6619, "SUB_DISTRICT_CODE": "650611", "SUB_DISTRICT_NAME": "ทับยายเชียง", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6620, "SUB_DISTRICT_CODE": "650612", "SUB_DISTRICT_NAME": "ดงประคำ", "DISTRICT_ID": 744, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6621, "SUB_DISTRICT_CODE": "650701", "SUB_DISTRICT_NAME": "วัดโบสถ์", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6622, "SUB_DISTRICT_CODE": "650702", "SUB_DISTRICT_NAME": "ท่างาม", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6623, "SUB_DISTRICT_CODE": "650703", "SUB_DISTRICT_NAME": "ท้อแท้", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6624, "SUB_DISTRICT_CODE": "650704", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6625, "SUB_DISTRICT_CODE": "650705", "SUB_DISTRICT_NAME": "หินลาด", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6626, "SUB_DISTRICT_CODE": "650706", "SUB_DISTRICT_NAME": "คันโช้ง", "DISTRICT_ID": 745, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6627, "SUB_DISTRICT_CODE": "650801", "SUB_DISTRICT_NAME": "วังทอง", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6628, "SUB_DISTRICT_CODE": "650802", "SUB_DISTRICT_NAME": "พันชาลี", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6629, "SUB_DISTRICT_CODE": "650803", "SUB_DISTRICT_NAME": "แม่ระกา", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6630, "SUB_DISTRICT_CODE": "650804", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6631, "SUB_DISTRICT_CODE": "650805", "SUB_DISTRICT_NAME": "วังพิกุล", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6632, "SUB_DISTRICT_CODE": "650806", "SUB_DISTRICT_NAME": "แก่งโสภา", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6633, "SUB_DISTRICT_CODE": "650807", "SUB_DISTRICT_NAME": "ท่าหมื่นราม", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6634, "SUB_DISTRICT_CODE": "650808", "SUB_DISTRICT_NAME": "วังนกแอ่น", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6635, "SUB_DISTRICT_CODE": "650809", "SUB_DISTRICT_NAME": "หนองพระ", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6636, "SUB_DISTRICT_CODE": "650810", "SUB_DISTRICT_NAME": "ชัยนาม", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6637, "SUB_DISTRICT_CODE": "650811", "SUB_DISTRICT_NAME": "ดินทอง", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6638, "SUB_DISTRICT_CODE": "650895", "SUB_DISTRICT_NAME": "*บ้านน้อยซุ้มขี้เหล็ก", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6639, "SUB_DISTRICT_CODE": "650896", "SUB_DISTRICT_NAME": "*วังโพรง", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6640, "SUB_DISTRICT_CODE": "650897", "SUB_DISTRICT_NAME": "*ไทรย้อย", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6641, "SUB_DISTRICT_CODE": "650898", "SUB_DISTRICT_NAME": "*บ้านมุง", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6642, "SUB_DISTRICT_CODE": "650899", "SUB_DISTRICT_NAME": "*ชมพู", "DISTRICT_ID": 746, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6643, "SUB_DISTRICT_CODE": "650901", "SUB_DISTRICT_NAME": "ชมพู", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6644, "SUB_DISTRICT_CODE": "650902", "SUB_DISTRICT_NAME": "บ้านมุง", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6645, "SUB_DISTRICT_CODE": "650903", "SUB_DISTRICT_NAME": "ไทรย้อย", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6646, "SUB_DISTRICT_CODE": "650904", "SUB_DISTRICT_NAME": "วังโพรง", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6647, "SUB_DISTRICT_CODE": "650905", "SUB_DISTRICT_NAME": "บ้านน้อยซุ้มขี้เหล็ก", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6648, "SUB_DISTRICT_CODE": "650906", "SUB_DISTRICT_NAME": "เนินมะปราง", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6649, "SUB_DISTRICT_CODE": "650907", "SUB_DISTRICT_NAME": "วังยาง", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6650, "SUB_DISTRICT_CODE": "650908", "SUB_DISTRICT_NAME": "โคกแหลม", "DISTRICT_ID": 747, "PROVINCE_ID": 52, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6651, "SUB_DISTRICT_CODE": "660101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6652, "SUB_DISTRICT_CODE": "660102", "SUB_DISTRICT_NAME": "ไผ่ขวาง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6653, "SUB_DISTRICT_CODE": "660103", "SUB_DISTRICT_NAME": "ย่านยาว", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6654, "SUB_DISTRICT_CODE": "660104", "SUB_DISTRICT_NAME": "ท่าฬ่อ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6655, "SUB_DISTRICT_CODE": "660105", "SUB_DISTRICT_NAME": "ปากทาง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6656, "SUB_DISTRICT_CODE": "660106", "SUB_DISTRICT_NAME": "คลองคะเชนทร์", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6657, "SUB_DISTRICT_CODE": "660107", "SUB_DISTRICT_NAME": "โรงช้าง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6658, "SUB_DISTRICT_CODE": "660108", "SUB_DISTRICT_NAME": "เมืองเก่า", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6659, "SUB_DISTRICT_CODE": "660109", "SUB_DISTRICT_NAME": "ท่าหลวง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6660, "SUB_DISTRICT_CODE": "660110", "SUB_DISTRICT_NAME": "บ้านบุ่ง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6661, "SUB_DISTRICT_CODE": "660111", "SUB_DISTRICT_NAME": "ฆะมัง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6662, "SUB_DISTRICT_CODE": "660112", "SUB_DISTRICT_NAME": "ดงป่าคำ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6663, "SUB_DISTRICT_CODE": "660113", "SUB_DISTRICT_NAME": "หัวดง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6664, "SUB_DISTRICT_CODE": "660114", "SUB_DISTRICT_NAME": "*หนองปล้อง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6665, "SUB_DISTRICT_CODE": "660115", "SUB_DISTRICT_NAME": "ป่ามะคาบ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6666, "SUB_DISTRICT_CODE": "660116", "SUB_DISTRICT_NAME": "*สากเหล็ก", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6667, "SUB_DISTRICT_CODE": "660117", "SUB_DISTRICT_NAME": "*ท่าเยี่ยม", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6668, "SUB_DISTRICT_CODE": "660118", "SUB_DISTRICT_NAME": "*คลองทราย", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6669, "SUB_DISTRICT_CODE": "660119", "SUB_DISTRICT_NAME": "สายคำโห้", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6670, "SUB_DISTRICT_CODE": "660120", "SUB_DISTRICT_NAME": "ดงกลาง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6671, "SUB_DISTRICT_CODE": "660192", "SUB_DISTRICT_NAME": "*ไผ่รอบ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6672, "SUB_DISTRICT_CODE": "660193", "SUB_DISTRICT_NAME": "*วังจิก", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6673, "SUB_DISTRICT_CODE": "660194", "SUB_DISTRICT_NAME": "*โพธิ์ประทับช้าง", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6674, "SUB_DISTRICT_CODE": "660195", "SUB_DISTRICT_NAME": "*ไผ่ท่าโพ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6675, "SUB_DISTRICT_CODE": "660196", "SUB_DISTRICT_NAME": "*วังจิก", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6676, "SUB_DISTRICT_CODE": "660197", "SUB_DISTRICT_NAME": "*หนองพระ", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6677, "SUB_DISTRICT_CODE": "660198", "SUB_DISTRICT_NAME": "*หนองปลาไหล", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6678, "SUB_DISTRICT_CODE": "660199", "SUB_DISTRICT_NAME": "*วังทรายพูน", "DISTRICT_ID": 748, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6679, "SUB_DISTRICT_CODE": "660201", "SUB_DISTRICT_NAME": "วังทรายพูน", "DISTRICT_ID": 749, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6680, "SUB_DISTRICT_CODE": "660202", "SUB_DISTRICT_NAME": "หนองปลาไหล", "DISTRICT_ID": 749, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6681, "SUB_DISTRICT_CODE": "660203", "SUB_DISTRICT_NAME": "หนองพระ", "DISTRICT_ID": 749, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6682, "SUB_DISTRICT_CODE": "660204", "SUB_DISTRICT_NAME": "หนองปล้อง", "DISTRICT_ID": 749, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6683, "SUB_DISTRICT_CODE": "660301", "SUB_DISTRICT_NAME": "โพธิ์ประทับช้าง", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6684, "SUB_DISTRICT_CODE": "660302", "SUB_DISTRICT_NAME": "ไผ่ท่าโพ", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6685, "SUB_DISTRICT_CODE": "660303", "SUB_DISTRICT_NAME": "วังจิก", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6686, "SUB_DISTRICT_CODE": "660304", "SUB_DISTRICT_NAME": "ไผ่รอบ", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6687, "SUB_DISTRICT_CODE": "660305", "SUB_DISTRICT_NAME": "ดงเสือเหลือง", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6688, "SUB_DISTRICT_CODE": "660306", "SUB_DISTRICT_NAME": "เนินสว่าง", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6689, "SUB_DISTRICT_CODE": "660307", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 750, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6690, "SUB_DISTRICT_CODE": "660401", "SUB_DISTRICT_NAME": "ตะพานหิน", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6691, "SUB_DISTRICT_CODE": "660402", "SUB_DISTRICT_NAME": "งิ้วราย", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6692, "SUB_DISTRICT_CODE": "660403", "SUB_DISTRICT_NAME": "ห้วยเกตุ", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6693, "SUB_DISTRICT_CODE": "660404", "SUB_DISTRICT_NAME": "ไทรโรงโขน", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6694, "SUB_DISTRICT_CODE": "660405", "SUB_DISTRICT_NAME": "หนองพยอม", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6695, "SUB_DISTRICT_CODE": "660406", "SUB_DISTRICT_NAME": "ทุ่งโพธิ์", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6696, "SUB_DISTRICT_CODE": "660407", "SUB_DISTRICT_NAME": "ดงตะขบ", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6697, "SUB_DISTRICT_CODE": "660408", "SUB_DISTRICT_NAME": "คลองคูณ", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6698, "SUB_DISTRICT_CODE": "660409", "SUB_DISTRICT_NAME": "วังสำโรง", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6699, "SUB_DISTRICT_CODE": "660410", "SUB_DISTRICT_NAME": "วังหว้า", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6700, "SUB_DISTRICT_CODE": "660411", "SUB_DISTRICT_NAME": "วังหลุม", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6701, "SUB_DISTRICT_CODE": "660412", "SUB_DISTRICT_NAME": "ทับหมัน", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6702, "SUB_DISTRICT_CODE": "660413", "SUB_DISTRICT_NAME": "ไผ่หลวง", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6703, "SUB_DISTRICT_CODE": "660496", "SUB_DISTRICT_NAME": "*ท้ายทุ่ง", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6704, "SUB_DISTRICT_CODE": "660497", "SUB_DISTRICT_NAME": "*เขาเจ็ดลูก", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6705, "SUB_DISTRICT_CODE": "660498", "SUB_DISTRICT_NAME": "*เขาทราย", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6706, "SUB_DISTRICT_CODE": "660499", "SUB_DISTRICT_NAME": "*ทับคล้อ", "DISTRICT_ID": 751, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6707, "SUB_DISTRICT_CODE": "660501", "SUB_DISTRICT_NAME": "บางมูลนาก", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6708, "SUB_DISTRICT_CODE": "660502", "SUB_DISTRICT_NAME": "บางไผ่", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6709, "SUB_DISTRICT_CODE": "660503", "SUB_DISTRICT_NAME": "หอไกร", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6710, "SUB_DISTRICT_CODE": "660504", "SUB_DISTRICT_NAME": "เนินมะกอก", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6711, "SUB_DISTRICT_CODE": "660505", "SUB_DISTRICT_NAME": "วังสำโรง", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6712, "SUB_DISTRICT_CODE": "660506", "SUB_DISTRICT_NAME": "ภูมิ", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6713, "SUB_DISTRICT_CODE": "660507", "SUB_DISTRICT_NAME": "วังกรด", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6714, "SUB_DISTRICT_CODE": "660508", "SUB_DISTRICT_NAME": "ห้วยเขน", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6715, "SUB_DISTRICT_CODE": "660509", "SUB_DISTRICT_NAME": "วังตะกู", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6716, "SUB_DISTRICT_CODE": "660510", "SUB_DISTRICT_NAME": "สำนักขุนเณร*", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6717, "SUB_DISTRICT_CODE": "660511", "SUB_DISTRICT_NAME": "ห้วยพุก*", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6718, "SUB_DISTRICT_CODE": "660512", "SUB_DISTRICT_NAME": "ห้วยร่วม*", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6719, "SUB_DISTRICT_CODE": "660513", "SUB_DISTRICT_NAME": "วังงิ้ว*", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6720, "SUB_DISTRICT_CODE": "660514", "SUB_DISTRICT_NAME": "ลำประดา", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6721, "SUB_DISTRICT_CODE": "660515", "SUB_DISTRICT_NAME": "วังงิ้วใต้*", "DISTRICT_ID": 752, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6722, "SUB_DISTRICT_CODE": "660601", "SUB_DISTRICT_NAME": "โพทะเล", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6723, "SUB_DISTRICT_CODE": "660602", "SUB_DISTRICT_NAME": "ท้ายน้ำ", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6724, "SUB_DISTRICT_CODE": "660603", "SUB_DISTRICT_NAME": "ทะนง", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6725, "SUB_DISTRICT_CODE": "660604", "SUB_DISTRICT_NAME": "ท่าบัว", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6726, "SUB_DISTRICT_CODE": "660605", "SUB_DISTRICT_NAME": "ทุ่งน้อย", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6727, "SUB_DISTRICT_CODE": "660606", "SUB_DISTRICT_NAME": "ท่าขมิ้น", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6728, "SUB_DISTRICT_CODE": "660607", "SUB_DISTRICT_NAME": "ท่าเสา", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6729, "SUB_DISTRICT_CODE": "660608", "SUB_DISTRICT_NAME": "บางคลาน", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6730, "SUB_DISTRICT_CODE": "660609", "SUB_DISTRICT_NAME": "บางลาย*", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6731, "SUB_DISTRICT_CODE": "660610", "SUB_DISTRICT_NAME": "บึงนาราง*", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6732, "SUB_DISTRICT_CODE": "660611", "SUB_DISTRICT_NAME": "ท่านั่ง", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6733, "SUB_DISTRICT_CODE": "660612", "SUB_DISTRICT_NAME": "บ้านน้อย", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6734, "SUB_DISTRICT_CODE": "660613", "SUB_DISTRICT_NAME": "วัดขวาง", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6735, "SUB_DISTRICT_CODE": "660614", "SUB_DISTRICT_NAME": "โพธิ์ไทรงาม*", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6736, "SUB_DISTRICT_CODE": "660615", "SUB_DISTRICT_NAME": "แหลมรัง*", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6737, "SUB_DISTRICT_CODE": "660616", "SUB_DISTRICT_NAME": "ห้วยแก้ว*", "DISTRICT_ID": 753, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6738, "SUB_DISTRICT_CODE": "660701", "SUB_DISTRICT_NAME": "สามง่าม", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6739, "SUB_DISTRICT_CODE": "660702", "SUB_DISTRICT_NAME": "กำแพงดิน", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6740, "SUB_DISTRICT_CODE": "660703", "SUB_DISTRICT_NAME": "รังนก", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6741, "SUB_DISTRICT_CODE": "660704", "SUB_DISTRICT_NAME": "หนองหลุม*", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6742, "SUB_DISTRICT_CODE": "660705", "SUB_DISTRICT_NAME": "บ้านนา*", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6743, "SUB_DISTRICT_CODE": "660706", "SUB_DISTRICT_NAME": "เนินปอ", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6744, "SUB_DISTRICT_CODE": "660707", "SUB_DISTRICT_NAME": "หนองโสน", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6745, "SUB_DISTRICT_CODE": "660708", "SUB_DISTRICT_NAME": "วังโมกข์*", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6746, "SUB_DISTRICT_CODE": "660709", "SUB_DISTRICT_NAME": "บึงบัว*", "DISTRICT_ID": 754, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6747, "SUB_DISTRICT_CODE": "660801", "SUB_DISTRICT_NAME": "ทับคล้อ", "DISTRICT_ID": 755, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6748, "SUB_DISTRICT_CODE": "660802", "SUB_DISTRICT_NAME": "เขาทราย", "DISTRICT_ID": 755, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6749, "SUB_DISTRICT_CODE": "660803", "SUB_DISTRICT_NAME": "เขาเจ็ดลูก", "DISTRICT_ID": 755, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6750, "SUB_DISTRICT_CODE": "660804", "SUB_DISTRICT_NAME": "ท้ายทุ่ง", "DISTRICT_ID": 755, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6751, "SUB_DISTRICT_CODE": "660901", "SUB_DISTRICT_NAME": "สากเหล็ก", "DISTRICT_ID": 756, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6752, "SUB_DISTRICT_CODE": "660902", "SUB_DISTRICT_NAME": "ท่าเยี่ยม", "DISTRICT_ID": 756, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6753, "SUB_DISTRICT_CODE": "660903", "SUB_DISTRICT_NAME": "คลองทราย", "DISTRICT_ID": 756, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6754, "SUB_DISTRICT_CODE": "660904", "SUB_DISTRICT_NAME": "หนองหญ้าไทร", "DISTRICT_ID": 756, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6755, "SUB_DISTRICT_CODE": "660905", "SUB_DISTRICT_NAME": "วังทับไทร", "DISTRICT_ID": 756, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6756, "SUB_DISTRICT_CODE": "661001", "SUB_DISTRICT_NAME": "ห้วยแก้ว", "DISTRICT_ID": 757, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6757, "SUB_DISTRICT_CODE": "661002", "SUB_DISTRICT_NAME": "โพธิ์ไทรงาม", "DISTRICT_ID": 757, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6758, "SUB_DISTRICT_CODE": "661003", "SUB_DISTRICT_NAME": "แหลมรัง", "DISTRICT_ID": 757, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6759, "SUB_DISTRICT_CODE": "661004", "SUB_DISTRICT_NAME": "บางลาย", "DISTRICT_ID": 757, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6760, "SUB_DISTRICT_CODE": "661005", "SUB_DISTRICT_NAME": "บึงนาราง", "DISTRICT_ID": 757, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6761, "SUB_DISTRICT_CODE": "661101", "SUB_DISTRICT_NAME": "วังงิ้วใต้", "DISTRICT_ID": 758, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6762, "SUB_DISTRICT_CODE": "661102", "SUB_DISTRICT_NAME": "วังงิ้ว", "DISTRICT_ID": 758, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6763, "SUB_DISTRICT_CODE": "661103", "SUB_DISTRICT_NAME": "ห้วยร่วม", "DISTRICT_ID": 758, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6764, "SUB_DISTRICT_CODE": "661104", "SUB_DISTRICT_NAME": "ห้วยพุก", "DISTRICT_ID": 758, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6765, "SUB_DISTRICT_CODE": "661105", "SUB_DISTRICT_NAME": "สำนักขุนเณร", "DISTRICT_ID": 758, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6766, "SUB_DISTRICT_CODE": "661201", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 759, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6767, "SUB_DISTRICT_CODE": "661202", "SUB_DISTRICT_NAME": "บึงบัว", "DISTRICT_ID": 759, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6768, "SUB_DISTRICT_CODE": "661203", "SUB_DISTRICT_NAME": "วังโมกข์", "DISTRICT_ID": 759, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6769, "SUB_DISTRICT_CODE": "661204", "SUB_DISTRICT_NAME": "หนองหลุม", "DISTRICT_ID": 759, "PROVINCE_ID": 53, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6770, "SUB_DISTRICT_CODE": "670101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6771, "SUB_DISTRICT_CODE": "670102", "SUB_DISTRICT_NAME": "ตะเบาะ", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6772, "SUB_DISTRICT_CODE": "670103", "SUB_DISTRICT_NAME": "บ้านโตก", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6773, "SUB_DISTRICT_CODE": "670104", "SUB_DISTRICT_NAME": "สะเดียง", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6774, "SUB_DISTRICT_CODE": "670105", "SUB_DISTRICT_NAME": "ป่าเลา", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6775, "SUB_DISTRICT_CODE": "670106", "SUB_DISTRICT_NAME": "นางั่ว", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6776, "SUB_DISTRICT_CODE": "670107", "SUB_DISTRICT_NAME": "ท่าพล", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6777, "SUB_DISTRICT_CODE": "670108", "SUB_DISTRICT_NAME": "ดงมูลเหล็ก", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6778, "SUB_DISTRICT_CODE": "670109", "SUB_DISTRICT_NAME": "บ้านโคก", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6779, "SUB_DISTRICT_CODE": "670110", "SUB_DISTRICT_NAME": "ชอนไพร", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6780, "SUB_DISTRICT_CODE": "670111", "SUB_DISTRICT_NAME": "นาป่า", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6781, "SUB_DISTRICT_CODE": "670112", "SUB_DISTRICT_NAME": "นายม", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6782, "SUB_DISTRICT_CODE": "670113", "SUB_DISTRICT_NAME": "วังชมภู", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6783, "SUB_DISTRICT_CODE": "670114", "SUB_DISTRICT_NAME": "น้ำร้อน", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6784, "SUB_DISTRICT_CODE": "670115", "SUB_DISTRICT_NAME": "ห้วยสะแก", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6785, "SUB_DISTRICT_CODE": "670116", "SUB_DISTRICT_NAME": "ห้วยใหญ่", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6786, "SUB_DISTRICT_CODE": "670117", "SUB_DISTRICT_NAME": "ระวิง", "DISTRICT_ID": 760, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6787, "SUB_DISTRICT_CODE": "670201", "SUB_DISTRICT_NAME": "ชนแดน", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6788, "SUB_DISTRICT_CODE": "670202", "SUB_DISTRICT_NAME": "ดงขุย", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6789, "SUB_DISTRICT_CODE": "670203", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6790, "SUB_DISTRICT_CODE": "670204", "SUB_DISTRICT_NAME": "พุทธบาท", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6791, "SUB_DISTRICT_CODE": "670205", "SUB_DISTRICT_NAME": "ลาดแค", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6792, "SUB_DISTRICT_CODE": "670206", "SUB_DISTRICT_NAME": "บ้านกล้วย", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6793, "SUB_DISTRICT_CODE": "670207", "SUB_DISTRICT_NAME": "*ซับเปิม", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6794, "SUB_DISTRICT_CODE": "670208", "SUB_DISTRICT_NAME": "ซับพุทรา", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6795, "SUB_DISTRICT_CODE": "670209", "SUB_DISTRICT_NAME": "ตะกุดไร", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6796, "SUB_DISTRICT_CODE": "670210", "SUB_DISTRICT_NAME": "ศาลาลาย", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6797, "SUB_DISTRICT_CODE": "670298", "SUB_DISTRICT_NAME": "*ท้ายดง", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6798, "SUB_DISTRICT_CODE": "670299", "SUB_DISTRICT_NAME": "*วังโป่ง", "DISTRICT_ID": 761, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6799, "SUB_DISTRICT_CODE": "670301", "SUB_DISTRICT_NAME": "หล่มสัก", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6800, "SUB_DISTRICT_CODE": "670302", "SUB_DISTRICT_NAME": "วัดป่า", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6801, "SUB_DISTRICT_CODE": "670303", "SUB_DISTRICT_NAME": "ตาลเดี่ยว", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6802, "SUB_DISTRICT_CODE": "670304", "SUB_DISTRICT_NAME": "ฝายนาแซง", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6803, "SUB_DISTRICT_CODE": "670305", "SUB_DISTRICT_NAME": "หนองสว่าง", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6804, "SUB_DISTRICT_CODE": "670306", "SUB_DISTRICT_NAME": "น้ำเฮี้ย", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6805, "SUB_DISTRICT_CODE": "670307", "SUB_DISTRICT_NAME": "สักหลง", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6806, "SUB_DISTRICT_CODE": "670308", "SUB_DISTRICT_NAME": "ท่าอิบุญ", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6807, "SUB_DISTRICT_CODE": "670309", "SUB_DISTRICT_NAME": "บ้านโสก", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6808, "SUB_DISTRICT_CODE": "670310", "SUB_DISTRICT_NAME": "บ้านติ้ว", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6809, "SUB_DISTRICT_CODE": "670311", "SUB_DISTRICT_NAME": "ห้วยไร่", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6810, "SUB_DISTRICT_CODE": "670312", "SUB_DISTRICT_NAME": "น้ำก้อ", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6811, "SUB_DISTRICT_CODE": "670313", "SUB_DISTRICT_NAME": "ปากช่อง", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6812, "SUB_DISTRICT_CODE": "670314", "SUB_DISTRICT_NAME": "น้ำชุน", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6813, "SUB_DISTRICT_CODE": "670315", "SUB_DISTRICT_NAME": "หนองไขว่", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6814, "SUB_DISTRICT_CODE": "670316", "SUB_DISTRICT_NAME": "ลานบ่า", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6815, "SUB_DISTRICT_CODE": "670317", "SUB_DISTRICT_NAME": "บุ่งคล้า", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6816, "SUB_DISTRICT_CODE": "670318", "SUB_DISTRICT_NAME": "บุ่งน้ำเต้า", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6817, "SUB_DISTRICT_CODE": "670319", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6818, "SUB_DISTRICT_CODE": "670320", "SUB_DISTRICT_NAME": "ช้างตะลูด", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6819, "SUB_DISTRICT_CODE": "670321", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6820, "SUB_DISTRICT_CODE": "670322", "SUB_DISTRICT_NAME": "ปากดุก", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6821, "SUB_DISTRICT_CODE": "670323", "SUB_DISTRICT_NAME": "บ้านหวาย", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6822, "SUB_DISTRICT_CODE": "670399", "SUB_DISTRICT_NAME": "*แคมป์สน", "DISTRICT_ID": 762, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6823, "SUB_DISTRICT_CODE": "670401", "SUB_DISTRICT_NAME": "หล่มเก่า", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6824, "SUB_DISTRICT_CODE": "670402", "SUB_DISTRICT_NAME": "นาซำ", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6825, "SUB_DISTRICT_CODE": "670403", "SUB_DISTRICT_NAME": "หินฮาว", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6826, "SUB_DISTRICT_CODE": "670404", "SUB_DISTRICT_NAME": "บ้านเนิน", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6827, "SUB_DISTRICT_CODE": "670405", "SUB_DISTRICT_NAME": "ศิลา", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6828, "SUB_DISTRICT_CODE": "670406", "SUB_DISTRICT_NAME": "นาแซง", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6829, "SUB_DISTRICT_CODE": "670407", "SUB_DISTRICT_NAME": "วังบาล", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6830, "SUB_DISTRICT_CODE": "670408", "SUB_DISTRICT_NAME": "นาเกาะ", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6831, "SUB_DISTRICT_CODE": "670409", "SUB_DISTRICT_NAME": "ตาดกลอย", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6832, "SUB_DISTRICT_CODE": "670499", "SUB_DISTRICT_NAME": "*น้ำหนาว", "DISTRICT_ID": 763, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6833, "SUB_DISTRICT_CODE": "670501", "SUB_DISTRICT_NAME": "ท่าโรง", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6834, "SUB_DISTRICT_CODE": "670502", "SUB_DISTRICT_NAME": "สระประดู่", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6835, "SUB_DISTRICT_CODE": "670503", "SUB_DISTRICT_NAME": "สามแยก", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6836, "SUB_DISTRICT_CODE": "670504", "SUB_DISTRICT_NAME": "โคกปรง", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6837, "SUB_DISTRICT_CODE": "670505", "SUB_DISTRICT_NAME": "น้ำร้อน", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6838, "SUB_DISTRICT_CODE": "670506", "SUB_DISTRICT_NAME": "บ่อรัง", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6839, "SUB_DISTRICT_CODE": "670507", "SUB_DISTRICT_NAME": "พุเตย", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6840, "SUB_DISTRICT_CODE": "670508", "SUB_DISTRICT_NAME": "พุขาม", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6841, "SUB_DISTRICT_CODE": "670509", "SUB_DISTRICT_NAME": "ภูน้ำหยด", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6842, "SUB_DISTRICT_CODE": "670510", "SUB_DISTRICT_NAME": "ซับสมบูรณ์", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6843, "SUB_DISTRICT_CODE": "670511", "SUB_DISTRICT_NAME": "บึงกระจับ", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6844, "SUB_DISTRICT_CODE": "670512", "SUB_DISTRICT_NAME": "วังใหญ่", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6845, "SUB_DISTRICT_CODE": "670513", "SUB_DISTRICT_NAME": "ยางสาว", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6846, "SUB_DISTRICT_CODE": "670514", "SUB_DISTRICT_NAME": "ซับน้อย", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6847, "SUB_DISTRICT_CODE": "670595", "SUB_DISTRICT_NAME": "*นาสนุ่น", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6848, "SUB_DISTRICT_CODE": "670597", "SUB_DISTRICT_NAME": "*คลองกระจัง", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6849, "SUB_DISTRICT_CODE": "670598", "SUB_DISTRICT_NAME": "*สระกรวด", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6850, "SUB_DISTRICT_CODE": "670599", "SUB_DISTRICT_NAME": "*ศรีเทพ", "DISTRICT_ID": 764, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6851, "SUB_DISTRICT_CODE": "670601", "SUB_DISTRICT_NAME": "ศรีเทพ", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6852, "SUB_DISTRICT_CODE": "670602", "SUB_DISTRICT_NAME": "สระกรวด", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6853, "SUB_DISTRICT_CODE": "670603", "SUB_DISTRICT_NAME": "คลองกระจัง", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6854, "SUB_DISTRICT_CODE": "670604", "SUB_DISTRICT_NAME": "นาสนุ่น", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6855, "SUB_DISTRICT_CODE": "670605", "SUB_DISTRICT_NAME": "โคกสะอาด", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6856, "SUB_DISTRICT_CODE": "670606", "SUB_DISTRICT_NAME": "หนองย่างทอย", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6857, "SUB_DISTRICT_CODE": "670607", "SUB_DISTRICT_NAME": "ประดู่งาม", "DISTRICT_ID": 765, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6858, "SUB_DISTRICT_CODE": "670701", "SUB_DISTRICT_NAME": "กองทูล", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6859, "SUB_DISTRICT_CODE": "670702", "SUB_DISTRICT_NAME": "นาเฉลียง", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6860, "SUB_DISTRICT_CODE": "670703", "SUB_DISTRICT_NAME": "บ้านโภชน์", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6861, "SUB_DISTRICT_CODE": "670704", "SUB_DISTRICT_NAME": "ท่าแดง", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6862, "SUB_DISTRICT_CODE": "670705", "SUB_DISTRICT_NAME": "เพชรละคร", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6863, "SUB_DISTRICT_CODE": "670706", "SUB_DISTRICT_NAME": "บ่อไทย", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6864, "SUB_DISTRICT_CODE": "670707", "SUB_DISTRICT_NAME": "ห้วยโป่ง", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6865, "SUB_DISTRICT_CODE": "670708", "SUB_DISTRICT_NAME": "วังท่าดี", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6866, "SUB_DISTRICT_CODE": "670709", "SUB_DISTRICT_NAME": "บัววัฒนา", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6867, "SUB_DISTRICT_CODE": "670710", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6868, "SUB_DISTRICT_CODE": "670711", "SUB_DISTRICT_NAME": "วังโบสถ์", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6869, "SUB_DISTRICT_CODE": "670712", "SUB_DISTRICT_NAME": "ยางงาม", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6870, "SUB_DISTRICT_CODE": "670713", "SUB_DISTRICT_NAME": "ท่าด้วง", "DISTRICT_ID": 766, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6871, "SUB_DISTRICT_CODE": "670801", "SUB_DISTRICT_NAME": "ซับสมอทอด", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6872, "SUB_DISTRICT_CODE": "670802", "SUB_DISTRICT_NAME": "ซับไม้แดง", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6873, "SUB_DISTRICT_CODE": "670803", "SUB_DISTRICT_NAME": "หนองแจง", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6874, "SUB_DISTRICT_CODE": "670804", "SUB_DISTRICT_NAME": "กันจุ", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6875, "SUB_DISTRICT_CODE": "670805", "SUB_DISTRICT_NAME": "วังพิกุล", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6876, "SUB_DISTRICT_CODE": "670806", "SUB_DISTRICT_NAME": "พญาวัง", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6877, "SUB_DISTRICT_CODE": "670807", "SUB_DISTRICT_NAME": "ศรีมงคล", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6878, "SUB_DISTRICT_CODE": "670808", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6879, "SUB_DISTRICT_CODE": "670809", "SUB_DISTRICT_NAME": "บึงสามพัน", "DISTRICT_ID": 767, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6880, "SUB_DISTRICT_CODE": "670901", "SUB_DISTRICT_NAME": "น้ำหนาว", "DISTRICT_ID": 768, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6881, "SUB_DISTRICT_CODE": "670902", "SUB_DISTRICT_NAME": "หลักด่าน", "DISTRICT_ID": 768, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6882, "SUB_DISTRICT_CODE": "670903", "SUB_DISTRICT_NAME": "วังกวาง", "DISTRICT_ID": 768, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6883, "SUB_DISTRICT_CODE": "670904", "SUB_DISTRICT_NAME": "โคกมน", "DISTRICT_ID": 768, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6884, "SUB_DISTRICT_CODE": "671001", "SUB_DISTRICT_NAME": "วังโป่ง", "DISTRICT_ID": 769, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6885, "SUB_DISTRICT_CODE": "671002", "SUB_DISTRICT_NAME": "ท้ายดง", "DISTRICT_ID": 769, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6886, "SUB_DISTRICT_CODE": "671003", "SUB_DISTRICT_NAME": "ซับเปิบ", "DISTRICT_ID": 769, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6887, "SUB_DISTRICT_CODE": "671004", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 769, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6888, "SUB_DISTRICT_CODE": "671005", "SUB_DISTRICT_NAME": "วังศาล", "DISTRICT_ID": 769, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6889, "SUB_DISTRICT_CODE": "671101", "SUB_DISTRICT_NAME": "ทุ่งสมอ", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6890, "SUB_DISTRICT_CODE": "671102", "SUB_DISTRICT_NAME": "แคมป์สน", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6891, "SUB_DISTRICT_CODE": "671103", "SUB_DISTRICT_NAME": "เขาค้อ", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6892, "SUB_DISTRICT_CODE": "671104", "SUB_DISTRICT_NAME": "ริมสีม่วง", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6893, "SUB_DISTRICT_CODE": "671105", "SUB_DISTRICT_NAME": "สะเดาะพง", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6894, "SUB_DISTRICT_CODE": "671106", "SUB_DISTRICT_NAME": "หนองแม่นา", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6895, "SUB_DISTRICT_CODE": "671107", "SUB_DISTRICT_NAME": "เข็กน้อย", "DISTRICT_ID": 770, "PROVINCE_ID": 54, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 6896, "SUB_DISTRICT_CODE": "700101", "SUB_DISTRICT_NAME": "หน้าเมือง", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6897, "SUB_DISTRICT_CODE": "700102", "SUB_DISTRICT_NAME": "เจดีย์หัก", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6898, "SUB_DISTRICT_CODE": "700103", "SUB_DISTRICT_NAME": "ดอนตะโก", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6899, "SUB_DISTRICT_CODE": "700104", "SUB_DISTRICT_NAME": "หนองกลางนา", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6900, "SUB_DISTRICT_CODE": "700105", "SUB_DISTRICT_NAME": "ห้วยไผ่", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6901, "SUB_DISTRICT_CODE": "700106", "SUB_DISTRICT_NAME": "คุ้งน้ำวน", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6902, "SUB_DISTRICT_CODE": "700107", "SUB_DISTRICT_NAME": "คุ้งกระถิน", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6903, "SUB_DISTRICT_CODE": "700108", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6904, "SUB_DISTRICT_CODE": "700109", "SUB_DISTRICT_NAME": "โคกหม้อ", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6905, "SUB_DISTRICT_CODE": "700110", "SUB_DISTRICT_NAME": "สามเรือน", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6906, "SUB_DISTRICT_CODE": "700111", "SUB_DISTRICT_NAME": "พิกุลทอง", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6907, "SUB_DISTRICT_CODE": "700112", "SUB_DISTRICT_NAME": "น้ำพุ", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6908, "SUB_DISTRICT_CODE": "700113", "SUB_DISTRICT_NAME": "ดอนแร่", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6909, "SUB_DISTRICT_CODE": "700114", "SUB_DISTRICT_NAME": "หินกอง", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6910, "SUB_DISTRICT_CODE": "700115", "SUB_DISTRICT_NAME": "เขาแร้ง", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6911, "SUB_DISTRICT_CODE": "700116", "SUB_DISTRICT_NAME": "เกาะพลับพลา", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6912, "SUB_DISTRICT_CODE": "700117", "SUB_DISTRICT_NAME": "หลุมดิน", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6913, "SUB_DISTRICT_CODE": "700118", "SUB_DISTRICT_NAME": "บางป่า", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6914, "SUB_DISTRICT_CODE": "700119", "SUB_DISTRICT_NAME": "พงสวาย", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6915, "SUB_DISTRICT_CODE": "700120", "SUB_DISTRICT_NAME": "คูบัว", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6916, "SUB_DISTRICT_CODE": "700121", "SUB_DISTRICT_NAME": "ท่าราบ", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6917, "SUB_DISTRICT_CODE": "700122", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 771, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6918, "SUB_DISTRICT_CODE": "700201", "SUB_DISTRICT_NAME": "จอมบึง", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6919, "SUB_DISTRICT_CODE": "700202", "SUB_DISTRICT_NAME": "ปากช่อง", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6920, "SUB_DISTRICT_CODE": "700203", "SUB_DISTRICT_NAME": "เบิกไพร", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6921, "SUB_DISTRICT_CODE": "700204", "SUB_DISTRICT_NAME": "ด่านทับตะโก", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6922, "SUB_DISTRICT_CODE": "700205", "SUB_DISTRICT_NAME": "แก้มอ้น", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6923, "SUB_DISTRICT_CODE": "700206", "SUB_DISTRICT_NAME": "รางบัว", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6924, "SUB_DISTRICT_CODE": "700297", "SUB_DISTRICT_NAME": "*ป่าหวาย", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6925, "SUB_DISTRICT_CODE": "700298", "SUB_DISTRICT_NAME": "*บ้านผึ้ง", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6926, "SUB_DISTRICT_CODE": "700299", "SUB_DISTRICT_NAME": "*สวนผึ้ง", "DISTRICT_ID": 772, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6927, "SUB_DISTRICT_CODE": "700301", "SUB_DISTRICT_NAME": "สวนผึ้ง", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6928, "SUB_DISTRICT_CODE": "700302", "SUB_DISTRICT_NAME": "ป่าหวาย", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6929, "SUB_DISTRICT_CODE": "700303", "SUB_DISTRICT_NAME": "บ้านบึง*", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6930, "SUB_DISTRICT_CODE": "700304", "SUB_DISTRICT_NAME": "ท่าเคย", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6931, "SUB_DISTRICT_CODE": "700305", "SUB_DISTRICT_NAME": "บ้านคา*", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6932, "SUB_DISTRICT_CODE": "700306", "SUB_DISTRICT_NAME": "หนองพันจันทร์*", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6933, "SUB_DISTRICT_CODE": "700307", "SUB_DISTRICT_NAME": "ตะนาวศรี", "DISTRICT_ID": 773, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6934, "SUB_DISTRICT_CODE": "700401", "SUB_DISTRICT_NAME": "ดำเนินสะดวก", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6935, "SUB_DISTRICT_CODE": "700402", "SUB_DISTRICT_NAME": "ประสาทสิทธิ์", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6936, "SUB_DISTRICT_CODE": "700403", "SUB_DISTRICT_NAME": "ศรีสุราษฎร์", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6937, "SUB_DISTRICT_CODE": "700404", "SUB_DISTRICT_NAME": "ตาหลวง", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6938, "SUB_DISTRICT_CODE": "700405", "SUB_DISTRICT_NAME": "ดอนกรวย", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6939, "SUB_DISTRICT_CODE": "700406", "SUB_DISTRICT_NAME": "ดอนคลัง", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6940, "SUB_DISTRICT_CODE": "700407", "SUB_DISTRICT_NAME": "บัวงาม", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6941, "SUB_DISTRICT_CODE": "700408", "SUB_DISTRICT_NAME": "บ้านไร่", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6942, "SUB_DISTRICT_CODE": "700409", "SUB_DISTRICT_NAME": "แพงพวย", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6943, "SUB_DISTRICT_CODE": "700410", "SUB_DISTRICT_NAME": "สี่หมื่น", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6944, "SUB_DISTRICT_CODE": "700411", "SUB_DISTRICT_NAME": "ท่านัด", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6945, "SUB_DISTRICT_CODE": "700412", "SUB_DISTRICT_NAME": "ขุนพิทักษ์", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6946, "SUB_DISTRICT_CODE": "700413", "SUB_DISTRICT_NAME": "ดอนไผ่", "DISTRICT_ID": 774, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6947, "SUB_DISTRICT_CODE": "700501", "SUB_DISTRICT_NAME": "บ้านโป่ง", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6948, "SUB_DISTRICT_CODE": "700502", "SUB_DISTRICT_NAME": "ท่าผา", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6949, "SUB_DISTRICT_CODE": "700503", "SUB_DISTRICT_NAME": "กรับใหญ่", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6950, "SUB_DISTRICT_CODE": "700504", "SUB_DISTRICT_NAME": "ปากแรต", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6951, "SUB_DISTRICT_CODE": "700505", "SUB_DISTRICT_NAME": "หนองกบ", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6952, "SUB_DISTRICT_CODE": "700506", "SUB_DISTRICT_NAME": "หนองอ้อ", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6953, "SUB_DISTRICT_CODE": "700507", "SUB_DISTRICT_NAME": "ดอนกระเบื้อง", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6954, "SUB_DISTRICT_CODE": "700508", "SUB_DISTRICT_NAME": "สวนกล้วย", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6955, "SUB_DISTRICT_CODE": "700509", "SUB_DISTRICT_NAME": "นครชุมน์", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6956, "SUB_DISTRICT_CODE": "700510", "SUB_DISTRICT_NAME": "บ้านม่วง", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6957, "SUB_DISTRICT_CODE": "700511", "SUB_DISTRICT_NAME": "คุ้งพยอม", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6958, "SUB_DISTRICT_CODE": "700512", "SUB_DISTRICT_NAME": "หนองปลาหมอ", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6959, "SUB_DISTRICT_CODE": "700513", "SUB_DISTRICT_NAME": "เขาขลุง", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6960, "SUB_DISTRICT_CODE": "700514", "SUB_DISTRICT_NAME": "เบิกไพร", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6961, "SUB_DISTRICT_CODE": "700515", "SUB_DISTRICT_NAME": "ลาดบัวขาว", "DISTRICT_ID": 775, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6962, "SUB_DISTRICT_CODE": "700601", "SUB_DISTRICT_NAME": "บางแพ", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6963, "SUB_DISTRICT_CODE": "700602", "SUB_DISTRICT_NAME": "วังเย็น", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6964, "SUB_DISTRICT_CODE": "700603", "SUB_DISTRICT_NAME": "หัวโพ", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6965, "SUB_DISTRICT_CODE": "700604", "SUB_DISTRICT_NAME": "วัดแก้ว", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6966, "SUB_DISTRICT_CODE": "700605", "SUB_DISTRICT_NAME": "ดอนใหญ่", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6967, "SUB_DISTRICT_CODE": "700606", "SUB_DISTRICT_NAME": "ดอนคา", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6968, "SUB_DISTRICT_CODE": "700607", "SUB_DISTRICT_NAME": "โพหัก", "DISTRICT_ID": 776, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6969, "SUB_DISTRICT_CODE": "700701", "SUB_DISTRICT_NAME": "โพธาราม", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6970, "SUB_DISTRICT_CODE": "700702", "SUB_DISTRICT_NAME": "ดอนกระเบื้อง", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6971, "SUB_DISTRICT_CODE": "700703", "SUB_DISTRICT_NAME": "หนองโพ", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6972, "SUB_DISTRICT_CODE": "700704", "SUB_DISTRICT_NAME": "บ้านเลือก", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6973, "SUB_DISTRICT_CODE": "700705", "SUB_DISTRICT_NAME": "คลองตาคต", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6974, "SUB_DISTRICT_CODE": "700706", "SUB_DISTRICT_NAME": "บ้านฆ้อง", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6975, "SUB_DISTRICT_CODE": "700707", "SUB_DISTRICT_NAME": "บ้านสิงห์", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6976, "SUB_DISTRICT_CODE": "700708", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6977, "SUB_DISTRICT_CODE": "700709", "SUB_DISTRICT_NAME": "เจ็ดเสมียน", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6978, "SUB_DISTRICT_CODE": "700710", "SUB_DISTRICT_NAME": "คลองข่อย", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6979, "SUB_DISTRICT_CODE": "700711", "SUB_DISTRICT_NAME": "ชำแระ", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6980, "SUB_DISTRICT_CODE": "700712", "SUB_DISTRICT_NAME": "สร้อยฟ้า", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6981, "SUB_DISTRICT_CODE": "700713", "SUB_DISTRICT_NAME": "ท่าชุมพล", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6982, "SUB_DISTRICT_CODE": "700714", "SUB_DISTRICT_NAME": "บางโตนด", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6983, "SUB_DISTRICT_CODE": "700715", "SUB_DISTRICT_NAME": "เตาปูน", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6984, "SUB_DISTRICT_CODE": "700716", "SUB_DISTRICT_NAME": "นางแก้ว", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6985, "SUB_DISTRICT_CODE": "700717", "SUB_DISTRICT_NAME": "ธรรมเสน", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6986, "SUB_DISTRICT_CODE": "700718", "SUB_DISTRICT_NAME": "เขาชะงุ้ม", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6987, "SUB_DISTRICT_CODE": "700719", "SUB_DISTRICT_NAME": "หนองกวาง", "DISTRICT_ID": 777, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6988, "SUB_DISTRICT_CODE": "700801", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6989, "SUB_DISTRICT_CODE": "700802", "SUB_DISTRICT_NAME": "วังมะนาว", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6990, "SUB_DISTRICT_CODE": "700803", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6991, "SUB_DISTRICT_CODE": "700804", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6992, "SUB_DISTRICT_CODE": "700805", "SUB_DISTRICT_NAME": "ปากท่อ", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6993, "SUB_DISTRICT_CODE": "700806", "SUB_DISTRICT_NAME": "ป่าไก่", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6994, "SUB_DISTRICT_CODE": "700807", "SUB_DISTRICT_NAME": "วัดยางงาม", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6995, "SUB_DISTRICT_CODE": "700808", "SUB_DISTRICT_NAME": "อ่างหิน", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6996, "SUB_DISTRICT_CODE": "700809", "SUB_DISTRICT_NAME": "บ่อกระดาน", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6997, "SUB_DISTRICT_CODE": "700810", "SUB_DISTRICT_NAME": "ยางหัก", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6998, "SUB_DISTRICT_CODE": "700811", "SUB_DISTRICT_NAME": "วันดาว", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 6999, "SUB_DISTRICT_CODE": "700812", "SUB_DISTRICT_NAME": "ห้วยยางโทน", "DISTRICT_ID": 778, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7000, "SUB_DISTRICT_CODE": "700901", "SUB_DISTRICT_NAME": "เกาะศาลพระ", "DISTRICT_ID": 779, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7001, "SUB_DISTRICT_CODE": "700902", "SUB_DISTRICT_NAME": "จอมประทัด", "DISTRICT_ID": 779, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7002, "SUB_DISTRICT_CODE": "700903", "SUB_DISTRICT_NAME": "วัดเพลง", "DISTRICT_ID": 779, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7003, "SUB_DISTRICT_CODE": "701001", "SUB_DISTRICT_NAME": "บ้านคา", "DISTRICT_ID": 780, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7004, "SUB_DISTRICT_CODE": "701002", "SUB_DISTRICT_NAME": "บ้านบึง", "DISTRICT_ID": 780, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7005, "SUB_DISTRICT_CODE": "701003", "SUB_DISTRICT_NAME": "หนองพันจันทร์", "DISTRICT_ID": 780, "PROVINCE_ID": 55, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7006, "SUB_DISTRICT_CODE": "710101", "SUB_DISTRICT_NAME": "บ้านเหนือ", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7007, "SUB_DISTRICT_CODE": "710102", "SUB_DISTRICT_NAME": "บ้านใต้", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7008, "SUB_DISTRICT_CODE": "710103", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7009, "SUB_DISTRICT_CODE": "710104", "SUB_DISTRICT_NAME": "ท่ามะขาม", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7010, "SUB_DISTRICT_CODE": "710105", "SUB_DISTRICT_NAME": "แก่งเสี้ยน", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7011, "SUB_DISTRICT_CODE": "710106", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7012, "SUB_DISTRICT_CODE": "710107", "SUB_DISTRICT_NAME": "ลาดหญ้า", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7013, "SUB_DISTRICT_CODE": "710108", "SUB_DISTRICT_NAME": "วังด้ง", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7014, "SUB_DISTRICT_CODE": "710109", "SUB_DISTRICT_NAME": "ช่องสะเดา", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7015, "SUB_DISTRICT_CODE": "710110", "SUB_DISTRICT_NAME": "หนองหญ้า", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7016, "SUB_DISTRICT_CODE": "710111", "SUB_DISTRICT_NAME": "เกาะสำโรง", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7017, "SUB_DISTRICT_CODE": "710112", "SUB_DISTRICT_NAME": "*ด่านมะขามเตี้ย", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7018, "SUB_DISTRICT_CODE": "710113", "SUB_DISTRICT_NAME": "บ้านเก่า", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7019, "SUB_DISTRICT_CODE": "710114", "SUB_DISTRICT_NAME": "*จรเข้เผือก", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7020, "SUB_DISTRICT_CODE": "710115", "SUB_DISTRICT_NAME": "*กลอนโด", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7021, "SUB_DISTRICT_CODE": "710116", "SUB_DISTRICT_NAME": "วังเย็น", "DISTRICT_ID": 782, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7022, "SUB_DISTRICT_CODE": "710201", "SUB_DISTRICT_NAME": "ลุ่มสุ่ม", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7023, "SUB_DISTRICT_CODE": "710202", "SUB_DISTRICT_NAME": "ท่าเสา", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7024, "SUB_DISTRICT_CODE": "710203", "SUB_DISTRICT_NAME": "สิงห์", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7025, "SUB_DISTRICT_CODE": "710204", "SUB_DISTRICT_NAME": "ไทรโยค", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7026, "SUB_DISTRICT_CODE": "710205", "SUB_DISTRICT_NAME": "วังกระแจะ", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7027, "SUB_DISTRICT_CODE": "710206", "SUB_DISTRICT_NAME": "ศรีมงคล", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7028, "SUB_DISTRICT_CODE": "710207", "SUB_DISTRICT_NAME": "บ้องตี้", "DISTRICT_ID": 783, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7029, "SUB_DISTRICT_CODE": "710301", "SUB_DISTRICT_NAME": "บ่อพลอย", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7030, "SUB_DISTRICT_CODE": "710302", "SUB_DISTRICT_NAME": "หนองกุ่ม", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7031, "SUB_DISTRICT_CODE": "710303", "SUB_DISTRICT_NAME": "หนองรี", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7032, "SUB_DISTRICT_CODE": "710304", "SUB_DISTRICT_NAME": "*หนองปรือ", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7033, "SUB_DISTRICT_CODE": "710305", "SUB_DISTRICT_NAME": "หลุมรัง", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7034, "SUB_DISTRICT_CODE": "710306", "SUB_DISTRICT_NAME": "*หนองปลาไหล", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7035, "SUB_DISTRICT_CODE": "710307", "SUB_DISTRICT_NAME": "*สมเด็จเจริญ", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7036, "SUB_DISTRICT_CODE": "710308", "SUB_DISTRICT_NAME": "ช่องด่าน", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7037, "SUB_DISTRICT_CODE": "710309", "SUB_DISTRICT_NAME": "หนองกร่าง", "DISTRICT_ID": 784, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7038, "SUB_DISTRICT_CODE": "710401", "SUB_DISTRICT_NAME": "นาสวน", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7039, "SUB_DISTRICT_CODE": "710402", "SUB_DISTRICT_NAME": "ด่านแม่แฉลบ", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7040, "SUB_DISTRICT_CODE": "710403", "SUB_DISTRICT_NAME": "หนองเป็ด", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7041, "SUB_DISTRICT_CODE": "710404", "SUB_DISTRICT_NAME": "ท่ากระดาน", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7042, "SUB_DISTRICT_CODE": "710405", "SUB_DISTRICT_NAME": "เขาโจด", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7043, "SUB_DISTRICT_CODE": "710406", "SUB_DISTRICT_NAME": "แม่กระบุง", "DISTRICT_ID": 785, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7044, "SUB_DISTRICT_CODE": "710501", "SUB_DISTRICT_NAME": "พงตึก", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7045, "SUB_DISTRICT_CODE": "710502", "SUB_DISTRICT_NAME": "ยางม่วง", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7046, "SUB_DISTRICT_CODE": "710503", "SUB_DISTRICT_NAME": "ดอนชะเอม", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7047, "SUB_DISTRICT_CODE": "710504", "SUB_DISTRICT_NAME": "ท่าไม้", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7048, "SUB_DISTRICT_CODE": "710505", "SUB_DISTRICT_NAME": "ตะคร้ำเอน", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7049, "SUB_DISTRICT_CODE": "710506", "SUB_DISTRICT_NAME": "ท่ามะกา", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7050, "SUB_DISTRICT_CODE": "710507", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7051, "SUB_DISTRICT_CODE": "710508", "SUB_DISTRICT_NAME": "โคกตะบอง", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7052, "SUB_DISTRICT_CODE": "710509", "SUB_DISTRICT_NAME": "ดอนขมิ้น", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7053, "SUB_DISTRICT_CODE": "710510", "SUB_DISTRICT_NAME": "อุโลกสี่หมื่น", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7054, "SUB_DISTRICT_CODE": "710511", "SUB_DISTRICT_NAME": "เขาสามสิบหาบ", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7055, "SUB_DISTRICT_CODE": "710512", "SUB_DISTRICT_NAME": "พระแท่น", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7056, "SUB_DISTRICT_CODE": "710513", "SUB_DISTRICT_NAME": "หวายเหนียว", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7057, "SUB_DISTRICT_CODE": "710514", "SUB_DISTRICT_NAME": "แสนตอ", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7058, "SUB_DISTRICT_CODE": "710515", "SUB_DISTRICT_NAME": "สนามแย้", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7059, "SUB_DISTRICT_CODE": "710516", "SUB_DISTRICT_NAME": "ท่าเสา", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7060, "SUB_DISTRICT_CODE": "710517", "SUB_DISTRICT_NAME": "หนองลาน", "DISTRICT_ID": 786, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7061, "SUB_DISTRICT_CODE": "710601", "SUB_DISTRICT_NAME": "ท่าม่วง", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7062, "SUB_DISTRICT_CODE": "710602", "SUB_DISTRICT_NAME": "วังขนาย", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7063, "SUB_DISTRICT_CODE": "710603", "SUB_DISTRICT_NAME": "วังศาลา", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7064, "SUB_DISTRICT_CODE": "710604", "SUB_DISTRICT_NAME": "ท่าล้อ", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7065, "SUB_DISTRICT_CODE": "710605", "SUB_DISTRICT_NAME": "หนองขาว", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7066, "SUB_DISTRICT_CODE": "710606", "SUB_DISTRICT_NAME": "ทุ่งทอง", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7067, "SUB_DISTRICT_CODE": "710607", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7068, "SUB_DISTRICT_CODE": "710608", "SUB_DISTRICT_NAME": "ม่วงชุม", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7069, "SUB_DISTRICT_CODE": "710609", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7070, "SUB_DISTRICT_CODE": "710610", "SUB_DISTRICT_NAME": "พังตรุ", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7071, "SUB_DISTRICT_CODE": "710611", "SUB_DISTRICT_NAME": "ท่าตะคร้อ", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7072, "SUB_DISTRICT_CODE": "710612", "SUB_DISTRICT_NAME": "รางสาลี่", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7073, "SUB_DISTRICT_CODE": "710613", "SUB_DISTRICT_NAME": "หนองตากยา", "DISTRICT_ID": 787, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7074, "SUB_DISTRICT_CODE": "710701", "SUB_DISTRICT_NAME": "ท่าขนุน", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7075, "SUB_DISTRICT_CODE": "710702", "SUB_DISTRICT_NAME": "ปิล๊อก", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7076, "SUB_DISTRICT_CODE": "710703", "SUB_DISTRICT_NAME": "หินดาด", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7077, "SUB_DISTRICT_CODE": "710704", "SUB_DISTRICT_NAME": "ลิ่นถิ่น", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7078, "SUB_DISTRICT_CODE": "710705", "SUB_DISTRICT_NAME": "ชะแล", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7079, "SUB_DISTRICT_CODE": "710706", "SUB_DISTRICT_NAME": "ห้วยเขย่ง", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7080, "SUB_DISTRICT_CODE": "710707", "SUB_DISTRICT_NAME": "สหกรณ์นิคม", "DISTRICT_ID": 788, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7081, "SUB_DISTRICT_CODE": "710801", "SUB_DISTRICT_NAME": "หนองลู", "DISTRICT_ID": 789, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7082, "SUB_DISTRICT_CODE": "710802", "SUB_DISTRICT_NAME": "ปรังเผล", "DISTRICT_ID": 789, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7083, "SUB_DISTRICT_CODE": "710803", "SUB_DISTRICT_NAME": "ไล่โว่", "DISTRICT_ID": 789, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7084, "SUB_DISTRICT_CODE": "710901", "SUB_DISTRICT_NAME": "พนมทวน", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7085, "SUB_DISTRICT_CODE": "710902", "SUB_DISTRICT_NAME": "หนองโรง", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7086, "SUB_DISTRICT_CODE": "710903", "SUB_DISTRICT_NAME": "ทุ่งสมอ", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7087, "SUB_DISTRICT_CODE": "710904", "SUB_DISTRICT_NAME": "ดอนเจดีย์", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7088, "SUB_DISTRICT_CODE": "710905", "SUB_DISTRICT_NAME": "พังตรุ", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7089, "SUB_DISTRICT_CODE": "710906", "SUB_DISTRICT_NAME": "รางหวาย", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7090, "SUB_DISTRICT_CODE": "710907", "SUB_DISTRICT_NAME": "*ดอนแสลบ", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7091, "SUB_DISTRICT_CODE": "710908", "SUB_DISTRICT_NAME": "*ห้วยกระเจา", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7092, "SUB_DISTRICT_CODE": "710909", "SUB_DISTRICT_NAME": "*สระลงเรือ", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7093, "SUB_DISTRICT_CODE": "710910", "SUB_DISTRICT_NAME": "*วังไผ่", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7094, "SUB_DISTRICT_CODE": "710911", "SUB_DISTRICT_NAME": "หนองสาหร่าย", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7095, "SUB_DISTRICT_CODE": "710912", "SUB_DISTRICT_NAME": "ดอนตาเพชร", "DISTRICT_ID": 790, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7096, "SUB_DISTRICT_CODE": "711001", "SUB_DISTRICT_NAME": "เลาขวัญ", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7097, "SUB_DISTRICT_CODE": "711002", "SUB_DISTRICT_NAME": "หนองโสน", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7098, "SUB_DISTRICT_CODE": "711003", "SUB_DISTRICT_NAME": "หนองประดู่", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7099, "SUB_DISTRICT_CODE": "711004", "SUB_DISTRICT_NAME": "หนองปลิง", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7100, "SUB_DISTRICT_CODE": "711005", "SUB_DISTRICT_NAME": "หนองนกแก้ว", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7101, "SUB_DISTRICT_CODE": "711006", "SUB_DISTRICT_NAME": "ทุ่งกระบ่ำ", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7102, "SUB_DISTRICT_CODE": "711007", "SUB_DISTRICT_NAME": "หนองฝ้าย", "DISTRICT_ID": 791, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7103, "SUB_DISTRICT_CODE": "711101", "SUB_DISTRICT_NAME": "ด่านมะขามเตี้ย", "DISTRICT_ID": 792, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7104, "SUB_DISTRICT_CODE": "711102", "SUB_DISTRICT_NAME": "กลอนโด", "DISTRICT_ID": 792, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7105, "SUB_DISTRICT_CODE": "711103", "SUB_DISTRICT_NAME": "จรเข้เผือก", "DISTRICT_ID": 792, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7106, "SUB_DISTRICT_CODE": "711104", "SUB_DISTRICT_NAME": "หนองไผ่", "DISTRICT_ID": 792, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7107, "SUB_DISTRICT_CODE": "711201", "SUB_DISTRICT_NAME": "หนองปรือ", "DISTRICT_ID": 793, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7108, "SUB_DISTRICT_CODE": "711202", "SUB_DISTRICT_NAME": "หนองปลาไหล", "DISTRICT_ID": 793, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7109, "SUB_DISTRICT_CODE": "711203", "SUB_DISTRICT_NAME": "สมเด็จเจริญ", "DISTRICT_ID": 793, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7110, "SUB_DISTRICT_CODE": "711301", "SUB_DISTRICT_NAME": "ห้วยกระเจา", "DISTRICT_ID": 794, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7111, "SUB_DISTRICT_CODE": "711302", "SUB_DISTRICT_NAME": "วังไผ่", "DISTRICT_ID": 794, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7112, "SUB_DISTRICT_CODE": "711303", "SUB_DISTRICT_NAME": "ดอนแสลบ", "DISTRICT_ID": 794, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7113, "SUB_DISTRICT_CODE": "711304", "SUB_DISTRICT_NAME": "สระลงเรือ", "DISTRICT_ID": 794, "PROVINCE_ID": 56, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7114, "SUB_DISTRICT_CODE": "720101", "SUB_DISTRICT_NAME": "ท่าพี่เลี้ยง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7115, "SUB_DISTRICT_CODE": "720102", "SUB_DISTRICT_NAME": "รั้วใหญ่", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7116, "SUB_DISTRICT_CODE": "720103", "SUB_DISTRICT_NAME": "ทับตีเหล็ก", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7117, "SUB_DISTRICT_CODE": "720104", "SUB_DISTRICT_NAME": "ท่าระหัด", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7118, "SUB_DISTRICT_CODE": "720105", "SUB_DISTRICT_NAME": "ไผ่ขวาง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7119, "SUB_DISTRICT_CODE": "720106", "SUB_DISTRICT_NAME": "โคกโคเฒ่า", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7120, "SUB_DISTRICT_CODE": "720107", "SUB_DISTRICT_NAME": "ดอนตาล", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7121, "SUB_DISTRICT_CODE": "720108", "SUB_DISTRICT_NAME": "ดอนมะสังข์", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7122, "SUB_DISTRICT_CODE": "720109", "SUB_DISTRICT_NAME": "พิหารแดง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7123, "SUB_DISTRICT_CODE": "720110", "SUB_DISTRICT_NAME": "ดอนกำยาน", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7124, "SUB_DISTRICT_CODE": "720111", "SUB_DISTRICT_NAME": "ดอนโพธิ์ทอง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7125, "SUB_DISTRICT_CODE": "720112", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7126, "SUB_DISTRICT_CODE": "720113", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7127, "SUB_DISTRICT_CODE": "720114", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7128, "SUB_DISTRICT_CODE": "720115", "SUB_DISTRICT_NAME": "บางกุ้ง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7129, "SUB_DISTRICT_CODE": "720116", "SUB_DISTRICT_NAME": "ศาลาขาว", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7130, "SUB_DISTRICT_CODE": "720117", "SUB_DISTRICT_NAME": "สวนแตง", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7131, "SUB_DISTRICT_CODE": "720118", "SUB_DISTRICT_NAME": "สนามชัย", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7132, "SUB_DISTRICT_CODE": "720119", "SUB_DISTRICT_NAME": "โพธิ์พระยา", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7133, "SUB_DISTRICT_CODE": "720120", "SUB_DISTRICT_NAME": "สนามคลี", "DISTRICT_ID": 797, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7134, "SUB_DISTRICT_CODE": "720201", "SUB_DISTRICT_NAME": "เขาพระ", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7135, "SUB_DISTRICT_CODE": "720202", "SUB_DISTRICT_NAME": "เดิมบาง", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7136, "SUB_DISTRICT_CODE": "720203", "SUB_DISTRICT_NAME": "นางบวช", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7137, "SUB_DISTRICT_CODE": "720204", "SUB_DISTRICT_NAME": "เขาดิน", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7138, "SUB_DISTRICT_CODE": "720205", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7139, "SUB_DISTRICT_CODE": "720206", "SUB_DISTRICT_NAME": "ทุ่งคลี", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7140, "SUB_DISTRICT_CODE": "720207", "SUB_DISTRICT_NAME": "โคกช้าง", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7141, "SUB_DISTRICT_CODE": "720208", "SUB_DISTRICT_NAME": "หัวเขา", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7142, "SUB_DISTRICT_CODE": "720209", "SUB_DISTRICT_NAME": "หัวนา", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7143, "SUB_DISTRICT_CODE": "720210", "SUB_DISTRICT_NAME": "บ่อกรุ", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7144, "SUB_DISTRICT_CODE": "720211", "SUB_DISTRICT_NAME": "วังศรีราช", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7145, "SUB_DISTRICT_CODE": "720212", "SUB_DISTRICT_NAME": "ป่าสะแก", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7146, "SUB_DISTRICT_CODE": "720213", "SUB_DISTRICT_NAME": "ยางนอน", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7147, "SUB_DISTRICT_CODE": "720214", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7148, "SUB_DISTRICT_CODE": "720296", "SUB_DISTRICT_NAME": "*องค์พระ", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7149, "SUB_DISTRICT_CODE": "720297", "SUB_DISTRICT_NAME": "*ห้วยขมิ้น", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7150, "SUB_DISTRICT_CODE": "720298", "SUB_DISTRICT_NAME": "*ด่านช้าง", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7151, "SUB_DISTRICT_CODE": "720299", "SUB_DISTRICT_NAME": "*หนองมะค่าโมง", "DISTRICT_ID": 798, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7152, "SUB_DISTRICT_CODE": "720301", "SUB_DISTRICT_NAME": "หนองมะค่าโมง", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7153, "SUB_DISTRICT_CODE": "720302", "SUB_DISTRICT_NAME": "ด่านช้าง", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7154, "SUB_DISTRICT_CODE": "720303", "SUB_DISTRICT_NAME": "ห้วยขมิ้น", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7155, "SUB_DISTRICT_CODE": "720304", "SUB_DISTRICT_NAME": "องค์พระ", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7156, "SUB_DISTRICT_CODE": "720305", "SUB_DISTRICT_NAME": "วังคัน", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7157, "SUB_DISTRICT_CODE": "720306", "SUB_DISTRICT_NAME": "นิคมกระเสียว", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7158, "SUB_DISTRICT_CODE": "720307", "SUB_DISTRICT_NAME": "วังยาว", "DISTRICT_ID": 799, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7159, "SUB_DISTRICT_CODE": "720401", "SUB_DISTRICT_NAME": "โคกคราม", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7160, "SUB_DISTRICT_CODE": "720402", "SUB_DISTRICT_NAME": "บางปลาม้า", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7161, "SUB_DISTRICT_CODE": "720403", "SUB_DISTRICT_NAME": "ตะค่า", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7162, "SUB_DISTRICT_CODE": "720404", "SUB_DISTRICT_NAME": "บางใหญ่", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7163, "SUB_DISTRICT_CODE": "720405", "SUB_DISTRICT_NAME": "กฤษณา", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7164, "SUB_DISTRICT_CODE": "720406", "SUB_DISTRICT_NAME": "สาลี", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7165, "SUB_DISTRICT_CODE": "720407", "SUB_DISTRICT_NAME": "ไผ่กองดิน", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7166, "SUB_DISTRICT_CODE": "720408", "SUB_DISTRICT_NAME": "องครักษ์", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7167, "SUB_DISTRICT_CODE": "720409", "SUB_DISTRICT_NAME": "จรเข้ใหญ่", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7168, "SUB_DISTRICT_CODE": "720410", "SUB_DISTRICT_NAME": "บ้านแหลม", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7169, "SUB_DISTRICT_CODE": "720411", "SUB_DISTRICT_NAME": "มะขามล้ม", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7170, "SUB_DISTRICT_CODE": "720412", "SUB_DISTRICT_NAME": "วังน้ำเย็น", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7171, "SUB_DISTRICT_CODE": "720413", "SUB_DISTRICT_NAME": "วัดโบสถ์", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7172, "SUB_DISTRICT_CODE": "720414", "SUB_DISTRICT_NAME": "วัดดาว", "DISTRICT_ID": 800, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7173, "SUB_DISTRICT_CODE": "720501", "SUB_DISTRICT_NAME": "ศรีประจันต์", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7174, "SUB_DISTRICT_CODE": "720502", "SUB_DISTRICT_NAME": "บ้านกร่าง", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7175, "SUB_DISTRICT_CODE": "720503", "SUB_DISTRICT_NAME": "มดแดง", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7176, "SUB_DISTRICT_CODE": "720504", "SUB_DISTRICT_NAME": "บางงาม", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7177, "SUB_DISTRICT_CODE": "720505", "SUB_DISTRICT_NAME": "ดอนปรู", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7178, "SUB_DISTRICT_CODE": "720506", "SUB_DISTRICT_NAME": "ปลายนา", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7179, "SUB_DISTRICT_CODE": "720507", "SUB_DISTRICT_NAME": "วังหว้า", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7180, "SUB_DISTRICT_CODE": "720508", "SUB_DISTRICT_NAME": "วังน้ำซับ", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7181, "SUB_DISTRICT_CODE": "720509", "SUB_DISTRICT_NAME": "วังยาง", "DISTRICT_ID": 801, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7182, "SUB_DISTRICT_CODE": "720601", "SUB_DISTRICT_NAME": "ดอนเจดีย์", "DISTRICT_ID": 802, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7183, "SUB_DISTRICT_CODE": "720602", "SUB_DISTRICT_NAME": "หนองสาหร่าย", "DISTRICT_ID": 802, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7184, "SUB_DISTRICT_CODE": "720603", "SUB_DISTRICT_NAME": "ไร่รถ", "DISTRICT_ID": 802, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7185, "SUB_DISTRICT_CODE": "720604", "SUB_DISTRICT_NAME": "สระกระโจม", "DISTRICT_ID": 802, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7186, "SUB_DISTRICT_CODE": "720605", "SUB_DISTRICT_NAME": "ทะเลบก", "DISTRICT_ID": 802, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7187, "SUB_DISTRICT_CODE": "720701", "SUB_DISTRICT_NAME": "สองพี่น้อง", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7188, "SUB_DISTRICT_CODE": "720702", "SUB_DISTRICT_NAME": "บางเลน", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7189, "SUB_DISTRICT_CODE": "720703", "SUB_DISTRICT_NAME": "บางตาเถร", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7190, "SUB_DISTRICT_CODE": "720704", "SUB_DISTRICT_NAME": "บางตะเคียน", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7191, "SUB_DISTRICT_CODE": "720705", "SUB_DISTRICT_NAME": "บ้านกุ่ม", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7192, "SUB_DISTRICT_CODE": "720706", "SUB_DISTRICT_NAME": "หัวโพธิ์", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7193, "SUB_DISTRICT_CODE": "720707", "SUB_DISTRICT_NAME": "บางพลับ", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7194, "SUB_DISTRICT_CODE": "720708", "SUB_DISTRICT_NAME": "เนินพระปรางค์", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7195, "SUB_DISTRICT_CODE": "720709", "SUB_DISTRICT_NAME": "บ้านช้าง", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7196, "SUB_DISTRICT_CODE": "720710", "SUB_DISTRICT_NAME": "ต้นตาล", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7197, "SUB_DISTRICT_CODE": "720711", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7198, "SUB_DISTRICT_CODE": "720712", "SUB_DISTRICT_NAME": "ทุ่งคอก", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7199, "SUB_DISTRICT_CODE": "720713", "SUB_DISTRICT_NAME": "หนองบ่อ", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7200, "SUB_DISTRICT_CODE": "720714", "SUB_DISTRICT_NAME": "บ่อสุพรรณ", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7201, "SUB_DISTRICT_CODE": "720715", "SUB_DISTRICT_NAME": "ดอนมะนาว", "DISTRICT_ID": 803, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7202, "SUB_DISTRICT_CODE": "720801", "SUB_DISTRICT_NAME": "ย่านยาว", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7203, "SUB_DISTRICT_CODE": "720802", "SUB_DISTRICT_NAME": "วังลึก", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7204, "SUB_DISTRICT_CODE": "720803", "SUB_DISTRICT_NAME": "สามชุก", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7205, "SUB_DISTRICT_CODE": "720804", "SUB_DISTRICT_NAME": "หนองผักนาก", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7206, "SUB_DISTRICT_CODE": "720805", "SUB_DISTRICT_NAME": "บ้านสระ", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7207, "SUB_DISTRICT_CODE": "720806", "SUB_DISTRICT_NAME": "หนองสะเดา", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7208, "SUB_DISTRICT_CODE": "720807", "SUB_DISTRICT_NAME": "กระเสียว", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7209, "SUB_DISTRICT_CODE": "720896", "SUB_DISTRICT_NAME": "*แจงงาม", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7210, "SUB_DISTRICT_CODE": "720897", "SUB_DISTRICT_NAME": "*หนองโพธิ์", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7211, "SUB_DISTRICT_CODE": "720898", "SUB_DISTRICT_NAME": "*หนองราชวัตร", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7212, "SUB_DISTRICT_CODE": "720899", "SUB_DISTRICT_NAME": "*หนองหญ้าไซ", "DISTRICT_ID": 804, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7213, "SUB_DISTRICT_CODE": "720901", "SUB_DISTRICT_NAME": "อู่ทอง", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7214, "SUB_DISTRICT_CODE": "720902", "SUB_DISTRICT_NAME": "สระยายโสม", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7215, "SUB_DISTRICT_CODE": "720903", "SUB_DISTRICT_NAME": "จรเข้สามพัน", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7216, "SUB_DISTRICT_CODE": "720904", "SUB_DISTRICT_NAME": "บ้านดอน", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7217, "SUB_DISTRICT_CODE": "720905", "SUB_DISTRICT_NAME": "ยุ้งทะลาย", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7218, "SUB_DISTRICT_CODE": "720906", "SUB_DISTRICT_NAME": "ดอนมะเกลือ", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7219, "SUB_DISTRICT_CODE": "720907", "SUB_DISTRICT_NAME": "หนองโอ่ง", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7220, "SUB_DISTRICT_CODE": "720908", "SUB_DISTRICT_NAME": "ดอนคา", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7221, "SUB_DISTRICT_CODE": "720909", "SUB_DISTRICT_NAME": "พลับพลาไชย", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7222, "SUB_DISTRICT_CODE": "720910", "SUB_DISTRICT_NAME": "บ้านโข้ง", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7223, "SUB_DISTRICT_CODE": "720911", "SUB_DISTRICT_NAME": "เจดีย์", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7224, "SUB_DISTRICT_CODE": "720912", "SUB_DISTRICT_NAME": "สระพังลาน", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7225, "SUB_DISTRICT_CODE": "720913", "SUB_DISTRICT_NAME": "กระจัน", "DISTRICT_ID": 805, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7226, "SUB_DISTRICT_CODE": "721001", "SUB_DISTRICT_NAME": "หนองหญ้าไซ", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7227, "SUB_DISTRICT_CODE": "721002", "SUB_DISTRICT_NAME": "หนองราชวัตร", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7228, "SUB_DISTRICT_CODE": "721003", "SUB_DISTRICT_NAME": "หนองโพธิ์", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7229, "SUB_DISTRICT_CODE": "721004", "SUB_DISTRICT_NAME": "แจงงาม", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7230, "SUB_DISTRICT_CODE": "721005", "SUB_DISTRICT_NAME": "หนองขาม", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7231, "SUB_DISTRICT_CODE": "721006", "SUB_DISTRICT_NAME": "ทัพหลวง", "DISTRICT_ID": 806, "PROVINCE_ID": 57, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7232, "SUB_DISTRICT_CODE": "730101", "SUB_DISTRICT_NAME": "พระปฐมเจดีย์", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7233, "SUB_DISTRICT_CODE": "730102", "SUB_DISTRICT_NAME": "บางแขม", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7234, "SUB_DISTRICT_CODE": "730103", "SUB_DISTRICT_NAME": "พระประโทน", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7235, "SUB_DISTRICT_CODE": "730104", "SUB_DISTRICT_NAME": "ธรรมศาลา", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7236, "SUB_DISTRICT_CODE": "730105", "SUB_DISTRICT_NAME": "ตาก้อง", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7237, "SUB_DISTRICT_CODE": "730106", "SUB_DISTRICT_NAME": "มาบแค", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7238, "SUB_DISTRICT_CODE": "730107", "SUB_DISTRICT_NAME": "สนามจันทร์", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7239, "SUB_DISTRICT_CODE": "730108", "SUB_DISTRICT_NAME": "ดอนยายหอม", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7240, "SUB_DISTRICT_CODE": "730109", "SUB_DISTRICT_NAME": "ถนนขาด", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7241, "SUB_DISTRICT_CODE": "730110", "SUB_DISTRICT_NAME": "บ่อพลับ", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7242, "SUB_DISTRICT_CODE": "730111", "SUB_DISTRICT_NAME": "นครปฐม", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7243, "SUB_DISTRICT_CODE": "730112", "SUB_DISTRICT_NAME": "วังตะกู", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7244, "SUB_DISTRICT_CODE": "730113", "SUB_DISTRICT_NAME": "หนองปากโลง", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7245, "SUB_DISTRICT_CODE": "730114", "SUB_DISTRICT_NAME": "สามควายเผือก", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7246, "SUB_DISTRICT_CODE": "730115", "SUB_DISTRICT_NAME": "ทุ่งน้อย", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7247, "SUB_DISTRICT_CODE": "730116", "SUB_DISTRICT_NAME": "หนองดินแดง", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7248, "SUB_DISTRICT_CODE": "730117", "SUB_DISTRICT_NAME": "วังเย็น", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7249, "SUB_DISTRICT_CODE": "730118", "SUB_DISTRICT_NAME": "โพรงมะเดื่อ", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7250, "SUB_DISTRICT_CODE": "730119", "SUB_DISTRICT_NAME": "ลำพยา", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7251, "SUB_DISTRICT_CODE": "730120", "SUB_DISTRICT_NAME": "สระกะเทียม", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7252, "SUB_DISTRICT_CODE": "730121", "SUB_DISTRICT_NAME": "สวนป่าน", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7253, "SUB_DISTRICT_CODE": "730122", "SUB_DISTRICT_NAME": "ห้วยจรเข้", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7254, "SUB_DISTRICT_CODE": "730123", "SUB_DISTRICT_NAME": "ทัพหลวง", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7255, "SUB_DISTRICT_CODE": "730124", "SUB_DISTRICT_NAME": "หนองงูเหลือม", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7256, "SUB_DISTRICT_CODE": "730125", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 807, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7257, "SUB_DISTRICT_CODE": "730201", "SUB_DISTRICT_NAME": "ทุ่งกระพังโหม", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7258, "SUB_DISTRICT_CODE": "730202", "SUB_DISTRICT_NAME": "กระตีบ", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7259, "SUB_DISTRICT_CODE": "730203", "SUB_DISTRICT_NAME": "ทุ่งลูกนก", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7260, "SUB_DISTRICT_CODE": "730204", "SUB_DISTRICT_NAME": "ห้วยขวาง", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7261, "SUB_DISTRICT_CODE": "730205", "SUB_DISTRICT_NAME": "ทุ่งขวาง", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7262, "SUB_DISTRICT_CODE": "730206", "SUB_DISTRICT_NAME": "สระสี่มุม", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7263, "SUB_DISTRICT_CODE": "730207", "SUB_DISTRICT_NAME": "ทุ่งบัว", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7264, "SUB_DISTRICT_CODE": "730208", "SUB_DISTRICT_NAME": "ดอนข่อย", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7265, "SUB_DISTRICT_CODE": "730209", "SUB_DISTRICT_NAME": "สระพัฒนา", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7266, "SUB_DISTRICT_CODE": "730210", "SUB_DISTRICT_NAME": "ห้วยหมอนทอง", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7267, "SUB_DISTRICT_CODE": "730211", "SUB_DISTRICT_NAME": "ห้วยม่วง", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7268, "SUB_DISTRICT_CODE": "730212", "SUB_DISTRICT_NAME": "กำแพงแสน", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7269, "SUB_DISTRICT_CODE": "730213", "SUB_DISTRICT_NAME": "รางพิกุล", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7270, "SUB_DISTRICT_CODE": "730214", "SUB_DISTRICT_NAME": "หนองกระทุ่ม", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7271, "SUB_DISTRICT_CODE": "730215", "SUB_DISTRICT_NAME": "วังน้ำเขียว", "DISTRICT_ID": 808, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7272, "SUB_DISTRICT_CODE": "730301", "SUB_DISTRICT_NAME": "นครชัยศรี", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7273, "SUB_DISTRICT_CODE": "730302", "SUB_DISTRICT_NAME": "บางกระเบา", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7274, "SUB_DISTRICT_CODE": "730303", "SUB_DISTRICT_NAME": "วัดแค", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7275, "SUB_DISTRICT_CODE": "730304", "SUB_DISTRICT_NAME": "ท่าตำหนัก", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7276, "SUB_DISTRICT_CODE": "730305", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7277, "SUB_DISTRICT_CODE": "730306", "SUB_DISTRICT_NAME": "ท่ากระชับ", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7278, "SUB_DISTRICT_CODE": "730307", "SUB_DISTRICT_NAME": "ขุนแก้ว", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7279, "SUB_DISTRICT_CODE": "730308", "SUB_DISTRICT_NAME": "ท่าพระยา", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7280, "SUB_DISTRICT_CODE": "730309", "SUB_DISTRICT_NAME": "พะเนียด", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7281, "SUB_DISTRICT_CODE": "730310", "SUB_DISTRICT_NAME": "บางระกำ", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7282, "SUB_DISTRICT_CODE": "730311", "SUB_DISTRICT_NAME": "โคกพระเจดีย์", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7283, "SUB_DISTRICT_CODE": "730312", "SUB_DISTRICT_NAME": "ศรีษะทอง", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7284, "SUB_DISTRICT_CODE": "730313", "SUB_DISTRICT_NAME": "แหลมบัว", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7285, "SUB_DISTRICT_CODE": "730314", "SUB_DISTRICT_NAME": "ศรีมหาโพธิ์", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7286, "SUB_DISTRICT_CODE": "730315", "SUB_DISTRICT_NAME": "สัมปทวน", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7287, "SUB_DISTRICT_CODE": "730316", "SUB_DISTRICT_NAME": "วัดสำโรง", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7288, "SUB_DISTRICT_CODE": "730317", "SUB_DISTRICT_NAME": "ดอนแฝก", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7289, "SUB_DISTRICT_CODE": "730318", "SUB_DISTRICT_NAME": "ห้วยพลู", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7290, "SUB_DISTRICT_CODE": "730319", "SUB_DISTRICT_NAME": "วัดละมุด", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7291, "SUB_DISTRICT_CODE": "730320", "SUB_DISTRICT_NAME": "บางพระ", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7292, "SUB_DISTRICT_CODE": "730321", "SUB_DISTRICT_NAME": "บางแก้วฟ้า", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7293, "SUB_DISTRICT_CODE": "730322", "SUB_DISTRICT_NAME": "ลานตากฟ้า", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7294, "SUB_DISTRICT_CODE": "730323", "SUB_DISTRICT_NAME": "งิ้วราย", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7295, "SUB_DISTRICT_CODE": "730324", "SUB_DISTRICT_NAME": "ไทยาวาส", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7296, "SUB_DISTRICT_CODE": "730325", "SUB_DISTRICT_NAME": "*ศาลายา", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7297, "SUB_DISTRICT_CODE": "730326", "SUB_DISTRICT_NAME": "*มหาสวัสดิ์", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7298, "SUB_DISTRICT_CODE": "730327", "SUB_DISTRICT_NAME": "*คลองโยง", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7299, "SUB_DISTRICT_CODE": "730397", "SUB_DISTRICT_NAME": "*มหาสวัสดิ์", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7300, "SUB_DISTRICT_CODE": "730398", "SUB_DISTRICT_NAME": "*คลองโยง", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7301, "SUB_DISTRICT_CODE": "730399", "SUB_DISTRICT_NAME": "*ศาลายา", "DISTRICT_ID": 809, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7302, "SUB_DISTRICT_CODE": "730401", "SUB_DISTRICT_NAME": "สามง่าม", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7303, "SUB_DISTRICT_CODE": "730402", "SUB_DISTRICT_NAME": "ห้วยพระ", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7304, "SUB_DISTRICT_CODE": "730403", "SUB_DISTRICT_NAME": "ลำเหย", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7305, "SUB_DISTRICT_CODE": "730404", "SUB_DISTRICT_NAME": "ดอนพุทรา", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7306, "SUB_DISTRICT_CODE": "730405", "SUB_DISTRICT_NAME": "บ้านหลวง", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7307, "SUB_DISTRICT_CODE": "730406", "SUB_DISTRICT_NAME": "ดอนรวก", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7308, "SUB_DISTRICT_CODE": "730407", "SUB_DISTRICT_NAME": "ห้วยด้วน", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7309, "SUB_DISTRICT_CODE": "730408", "SUB_DISTRICT_NAME": "ลำลูกบัว", "DISTRICT_ID": 810, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7310, "SUB_DISTRICT_CODE": "730501", "SUB_DISTRICT_NAME": "บางเลน", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7311, "SUB_DISTRICT_CODE": "730502", "SUB_DISTRICT_NAME": "บางปลา", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7312, "SUB_DISTRICT_CODE": "730503", "SUB_DISTRICT_NAME": "บางหลวง", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7313, "SUB_DISTRICT_CODE": "730504", "SUB_DISTRICT_NAME": "บางภาษี", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7314, "SUB_DISTRICT_CODE": "730505", "SUB_DISTRICT_NAME": "บางระกำ", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7315, "SUB_DISTRICT_CODE": "730506", "SUB_DISTRICT_NAME": "บางไทรป่า", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7316, "SUB_DISTRICT_CODE": "730507", "SUB_DISTRICT_NAME": "หินมูล", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7317, "SUB_DISTRICT_CODE": "730508", "SUB_DISTRICT_NAME": "ไทรงาม", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7318, "SUB_DISTRICT_CODE": "730509", "SUB_DISTRICT_NAME": "ดอนตูม", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7319, "SUB_DISTRICT_CODE": "730510", "SUB_DISTRICT_NAME": "นิลเพชร", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7320, "SUB_DISTRICT_CODE": "730511", "SUB_DISTRICT_NAME": "บัวปากท่า", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7321, "SUB_DISTRICT_CODE": "730512", "SUB_DISTRICT_NAME": "คลองนกกระทุง", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7322, "SUB_DISTRICT_CODE": "730513", "SUB_DISTRICT_NAME": "นราภิรมย์", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7323, "SUB_DISTRICT_CODE": "730514", "SUB_DISTRICT_NAME": "ลำพญา", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7324, "SUB_DISTRICT_CODE": "730515", "SUB_DISTRICT_NAME": "ไผ่หูช้าง", "DISTRICT_ID": 811, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7325, "SUB_DISTRICT_CODE": "730601", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7326, "SUB_DISTRICT_CODE": "730602", "SUB_DISTRICT_NAME": "ทรงคนอง", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7327, "SUB_DISTRICT_CODE": "730603", "SUB_DISTRICT_NAME": "หอมเกร็ด", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7328, "SUB_DISTRICT_CODE": "730604", "SUB_DISTRICT_NAME": "บางกระทึก", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7329, "SUB_DISTRICT_CODE": "730605", "SUB_DISTRICT_NAME": "บางเตย", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7330, "SUB_DISTRICT_CODE": "730606", "SUB_DISTRICT_NAME": "สามพราน", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7331, "SUB_DISTRICT_CODE": "730607", "SUB_DISTRICT_NAME": "บางช้าง", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7332, "SUB_DISTRICT_CODE": "730608", "SUB_DISTRICT_NAME": "ไร่ขิง", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7333, "SUB_DISTRICT_CODE": "730609", "SUB_DISTRICT_NAME": "ท่าตลาด", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7334, "SUB_DISTRICT_CODE": "730610", "SUB_DISTRICT_NAME": "กระทุ่มล้ม", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7335, "SUB_DISTRICT_CODE": "730611", "SUB_DISTRICT_NAME": "คลองใหม่", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7336, "SUB_DISTRICT_CODE": "730612", "SUB_DISTRICT_NAME": "ตลาดจินดา", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7337, "SUB_DISTRICT_CODE": "730613", "SUB_DISTRICT_NAME": "คลองจินดา", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7338, "SUB_DISTRICT_CODE": "730614", "SUB_DISTRICT_NAME": "ยายชา", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7339, "SUB_DISTRICT_CODE": "730615", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7340, "SUB_DISTRICT_CODE": "730616", "SUB_DISTRICT_NAME": "อ้อมใหญ่", "DISTRICT_ID": 812, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7341, "SUB_DISTRICT_CODE": "730701", "SUB_DISTRICT_NAME": "ศาลายา", "DISTRICT_ID": 813, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7342, "SUB_DISTRICT_CODE": "730702", "SUB_DISTRICT_NAME": "คลองโยง", "DISTRICT_ID": 813, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7343, "SUB_DISTRICT_CODE": "730703", "SUB_DISTRICT_NAME": "มหาสวัสดิ์", "DISTRICT_ID": 813, "PROVINCE_ID": 58, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7344, "SUB_DISTRICT_CODE": "740101", "SUB_DISTRICT_NAME": "มหาชัย", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7345, "SUB_DISTRICT_CODE": "740102", "SUB_DISTRICT_NAME": "ท่าฉลอม", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7346, "SUB_DISTRICT_CODE": "740103", "SUB_DISTRICT_NAME": "โกรกกราก", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7347, "SUB_DISTRICT_CODE": "740104", "SUB_DISTRICT_NAME": "บ้านบ่อ", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7348, "SUB_DISTRICT_CODE": "740105", "SUB_DISTRICT_NAME": "บางโทรัด", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7349, "SUB_DISTRICT_CODE": "740106", "SUB_DISTRICT_NAME": "กาหลง", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7350, "SUB_DISTRICT_CODE": "740107", "SUB_DISTRICT_NAME": "นาโคก", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7351, "SUB_DISTRICT_CODE": "740108", "SUB_DISTRICT_NAME": "ท่าจีน", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7352, "SUB_DISTRICT_CODE": "740109", "SUB_DISTRICT_NAME": "นาดี", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7353, "SUB_DISTRICT_CODE": "740110", "SUB_DISTRICT_NAME": "ท่าทราย", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7354, "SUB_DISTRICT_CODE": "740111", "SUB_DISTRICT_NAME": "คอกกระบือ", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7355, "SUB_DISTRICT_CODE": "740112", "SUB_DISTRICT_NAME": "บางน้ำจืด", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7356, "SUB_DISTRICT_CODE": "740113", "SUB_DISTRICT_NAME": "พันท้ายนรสิงห์", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7357, "SUB_DISTRICT_CODE": "740114", "SUB_DISTRICT_NAME": "โคกขาม", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7358, "SUB_DISTRICT_CODE": "740115", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7359, "SUB_DISTRICT_CODE": "740116", "SUB_DISTRICT_NAME": "บางกระเจ้า", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7360, "SUB_DISTRICT_CODE": "740117", "SUB_DISTRICT_NAME": "บางหญ้าแพรก", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7361, "SUB_DISTRICT_CODE": "740118", "SUB_DISTRICT_NAME": "ชัยมงคล", "DISTRICT_ID": 814, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7362, "SUB_DISTRICT_CODE": "740201", "SUB_DISTRICT_NAME": "ตลาดกระทุ่มแบน", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7363, "SUB_DISTRICT_CODE": "740202", "SUB_DISTRICT_NAME": "อ้อมน้อย", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7364, "SUB_DISTRICT_CODE": "740203", "SUB_DISTRICT_NAME": "ท่าไม้", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7365, "SUB_DISTRICT_CODE": "740204", "SUB_DISTRICT_NAME": "สวนหลวง", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7366, "SUB_DISTRICT_CODE": "740205", "SUB_DISTRICT_NAME": "บางยาง", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7367, "SUB_DISTRICT_CODE": "740206", "SUB_DISTRICT_NAME": "คลองมะเดื่อ", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7368, "SUB_DISTRICT_CODE": "740207", "SUB_DISTRICT_NAME": "หนองนกไข่", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7369, "SUB_DISTRICT_CODE": "740208", "SUB_DISTRICT_NAME": "ดอนไก่ดี", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7370, "SUB_DISTRICT_CODE": "740209", "SUB_DISTRICT_NAME": "แคราย", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7371, "SUB_DISTRICT_CODE": "740210", "SUB_DISTRICT_NAME": "ท่าเสา", "DISTRICT_ID": 815, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7372, "SUB_DISTRICT_CODE": "740301", "SUB_DISTRICT_NAME": "บ้านแพ้ว", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7373, "SUB_DISTRICT_CODE": "740302", "SUB_DISTRICT_NAME": "หลักสาม", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7374, "SUB_DISTRICT_CODE": "740303", "SUB_DISTRICT_NAME": "ยกกระบัตร", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7375, "SUB_DISTRICT_CODE": "740304", "SUB_DISTRICT_NAME": "โรงเข้", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7376, "SUB_DISTRICT_CODE": "740305", "SUB_DISTRICT_NAME": "หนองสองห้อง", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7377, "SUB_DISTRICT_CODE": "740306", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7378, "SUB_DISTRICT_CODE": "740307", "SUB_DISTRICT_NAME": "หลักสอง", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7379, "SUB_DISTRICT_CODE": "740308", "SUB_DISTRICT_NAME": "เจ็ดริ้ว", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7380, "SUB_DISTRICT_CODE": "740309", "SUB_DISTRICT_NAME": "คลองตัน", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7381, "SUB_DISTRICT_CODE": "740310", "SUB_DISTRICT_NAME": "อำแพง", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7382, "SUB_DISTRICT_CODE": "740311", "SUB_DISTRICT_NAME": "สวนส้ม", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7383, "SUB_DISTRICT_CODE": "740312", "SUB_DISTRICT_NAME": "เกษตรพัฒนา", "DISTRICT_ID": 816, "PROVINCE_ID": 59, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7384, "SUB_DISTRICT_CODE": "750101", "SUB_DISTRICT_NAME": "แม่กลอง", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7385, "SUB_DISTRICT_CODE": "750102", "SUB_DISTRICT_NAME": "บางขันแตก", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7386, "SUB_DISTRICT_CODE": "750103", "SUB_DISTRICT_NAME": "ลาดใหญ่", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7387, "SUB_DISTRICT_CODE": "750104", "SUB_DISTRICT_NAME": "บ้านปรก", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7388, "SUB_DISTRICT_CODE": "750105", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7389, "SUB_DISTRICT_CODE": "750106", "SUB_DISTRICT_NAME": "ท้ายหาด", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7390, "SUB_DISTRICT_CODE": "750107", "SUB_DISTRICT_NAME": "แหลมใหญ่", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7391, "SUB_DISTRICT_CODE": "750108", "SUB_DISTRICT_NAME": "คลองเขิน", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7392, "SUB_DISTRICT_CODE": "750109", "SUB_DISTRICT_NAME": "คลองโคน", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7393, "SUB_DISTRICT_CODE": "750110", "SUB_DISTRICT_NAME": "นางตะเคียน", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7394, "SUB_DISTRICT_CODE": "750111", "SUB_DISTRICT_NAME": "บางจะเกร็ง", "DISTRICT_ID": 817, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7395, "SUB_DISTRICT_CODE": "750201", "SUB_DISTRICT_NAME": "กระดังงา", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7396, "SUB_DISTRICT_CODE": "750202", "SUB_DISTRICT_NAME": "บางสะแก", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7397, "SUB_DISTRICT_CODE": "750203", "SUB_DISTRICT_NAME": "บางยี่รงค์", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7398, "SUB_DISTRICT_CODE": "750204", "SUB_DISTRICT_NAME": "โรงหีบ", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7399, "SUB_DISTRICT_CODE": "750205", "SUB_DISTRICT_NAME": "บางคนที", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7400, "SUB_DISTRICT_CODE": "750206", "SUB_DISTRICT_NAME": "ดอนมะโนรา", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7401, "SUB_DISTRICT_CODE": "750207", "SUB_DISTRICT_NAME": "บางพรม", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7402, "SUB_DISTRICT_CODE": "750208", "SUB_DISTRICT_NAME": "บางกุ้ง", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7403, "SUB_DISTRICT_CODE": "750209", "SUB_DISTRICT_NAME": "จอมปลวก", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7404, "SUB_DISTRICT_CODE": "750210", "SUB_DISTRICT_NAME": "บางนกแขวก", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7405, "SUB_DISTRICT_CODE": "750211", "SUB_DISTRICT_NAME": "ยายแพง", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7406, "SUB_DISTRICT_CODE": "750212", "SUB_DISTRICT_NAME": "บางกระบือ", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7407, "SUB_DISTRICT_CODE": "750213", "SUB_DISTRICT_NAME": "บ้านปราโมทย์", "DISTRICT_ID": 818, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7408, "SUB_DISTRICT_CODE": "750301", "SUB_DISTRICT_NAME": "อัมพวา", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7409, "SUB_DISTRICT_CODE": "750302", "SUB_DISTRICT_NAME": "สวนหลวง", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7410, "SUB_DISTRICT_CODE": "750303", "SUB_DISTRICT_NAME": "ท่าคา", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7411, "SUB_DISTRICT_CODE": "750304", "SUB_DISTRICT_NAME": "วัดประดู่", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7412, "SUB_DISTRICT_CODE": "750305", "SUB_DISTRICT_NAME": "เหมืองใหม่", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7413, "SUB_DISTRICT_CODE": "750306", "SUB_DISTRICT_NAME": "บางช้าง", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7414, "SUB_DISTRICT_CODE": "750307", "SUB_DISTRICT_NAME": "แควอ้อม", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7415, "SUB_DISTRICT_CODE": "750308", "SUB_DISTRICT_NAME": "ปลายโพงพาง", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7416, "SUB_DISTRICT_CODE": "750309", "SUB_DISTRICT_NAME": "บางแค", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7417, "SUB_DISTRICT_CODE": "750310", "SUB_DISTRICT_NAME": "แพรกหนามแดง", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7418, "SUB_DISTRICT_CODE": "750311", "SUB_DISTRICT_NAME": "ยี่สาร", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7419, "SUB_DISTRICT_CODE": "750312", "SUB_DISTRICT_NAME": "บางนางลี่", "DISTRICT_ID": 819, "PROVINCE_ID": 60, "GEO_ID": 2 },
    { "SUB_DISTRICT_ID": 7420, "SUB_DISTRICT_CODE": "760101", "SUB_DISTRICT_NAME": "ท่าราบ", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7421, "SUB_DISTRICT_CODE": "760102", "SUB_DISTRICT_NAME": "คลองกระแชง", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7422, "SUB_DISTRICT_CODE": "760103", "SUB_DISTRICT_NAME": "บางจาน", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7423, "SUB_DISTRICT_CODE": "760104", "SUB_DISTRICT_NAME": "นาพันสาม", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7424, "SUB_DISTRICT_CODE": "760105", "SUB_DISTRICT_NAME": "ธงชัย", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7425, "SUB_DISTRICT_CODE": "760106", "SUB_DISTRICT_NAME": "บ้านกุ่ม", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7426, "SUB_DISTRICT_CODE": "760107", "SUB_DISTRICT_NAME": "หนองโสน", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7427, "SUB_DISTRICT_CODE": "760108", "SUB_DISTRICT_NAME": "ไร่ส้ม", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7428, "SUB_DISTRICT_CODE": "760109", "SUB_DISTRICT_NAME": "เวียงคอย", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7429, "SUB_DISTRICT_CODE": "760110", "SUB_DISTRICT_NAME": "บางจาก", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7430, "SUB_DISTRICT_CODE": "760111", "SUB_DISTRICT_NAME": "บ้านหม้อ", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7431, "SUB_DISTRICT_CODE": "760112", "SUB_DISTRICT_NAME": "ต้นมะม่วง", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7432, "SUB_DISTRICT_CODE": "760113", "SUB_DISTRICT_NAME": "ช่องสะแก", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7433, "SUB_DISTRICT_CODE": "760114", "SUB_DISTRICT_NAME": "นาวุ้ง", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7434, "SUB_DISTRICT_CODE": "760115", "SUB_DISTRICT_NAME": "สำมะโรง", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7435, "SUB_DISTRICT_CODE": "760116", "SUB_DISTRICT_NAME": "โพพระ", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7436, "SUB_DISTRICT_CODE": "760117", "SUB_DISTRICT_NAME": "หาดเจ้าสำราญ", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7437, "SUB_DISTRICT_CODE": "760118", "SUB_DISTRICT_NAME": "หัวสะพาน", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7438, "SUB_DISTRICT_CODE": "760119", "SUB_DISTRICT_NAME": "ต้นมะพร้าว", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7439, "SUB_DISTRICT_CODE": "760120", "SUB_DISTRICT_NAME": "วังตะโก", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7440, "SUB_DISTRICT_CODE": "760121", "SUB_DISTRICT_NAME": "โพไร่หวาน", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7441, "SUB_DISTRICT_CODE": "760122", "SUB_DISTRICT_NAME": "ดอนยาง", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7442, "SUB_DISTRICT_CODE": "760123", "SUB_DISTRICT_NAME": "หนองขนาน", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7443, "SUB_DISTRICT_CODE": "760124", "SUB_DISTRICT_NAME": "หนองพลับ", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7444, "SUB_DISTRICT_CODE": "760199", "SUB_DISTRICT_NAME": "*มาตยาวงศ์", "DISTRICT_ID": 820, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7445, "SUB_DISTRICT_CODE": "760201", "SUB_DISTRICT_NAME": "เขาย้อย", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7446, "SUB_DISTRICT_CODE": "760202", "SUB_DISTRICT_NAME": "สระพัง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7447, "SUB_DISTRICT_CODE": "760203", "SUB_DISTRICT_NAME": "บางเค็ม", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7448, "SUB_DISTRICT_CODE": "760204", "SUB_DISTRICT_NAME": "ทับคาง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7449, "SUB_DISTRICT_CODE": "760205", "SUB_DISTRICT_NAME": "หนองปลาไหล", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7450, "SUB_DISTRICT_CODE": "760206", "SUB_DISTRICT_NAME": "หนองปรง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7451, "SUB_DISTRICT_CODE": "760207", "SUB_DISTRICT_NAME": "หนองชุมพล", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7452, "SUB_DISTRICT_CODE": "760208", "SUB_DISTRICT_NAME": "ห้วยโรง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7453, "SUB_DISTRICT_CODE": "760209", "SUB_DISTRICT_NAME": "ห้วยท่าช้าง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7454, "SUB_DISTRICT_CODE": "760210", "SUB_DISTRICT_NAME": "หนองชุมพลเหนือ", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7455, "SUB_DISTRICT_CODE": "760297", "SUB_DISTRICT_NAME": "*ยางน้ำกลักใต้", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7456, "SUB_DISTRICT_CODE": "760298", "SUB_DISTRICT_NAME": "*ยางน้ำกลักเหนือ", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7457, "SUB_DISTRICT_CODE": "760299", "SUB_DISTRICT_NAME": "*หนองหญ้าปล้อง", "DISTRICT_ID": 821, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7458, "SUB_DISTRICT_CODE": "760301", "SUB_DISTRICT_NAME": "หนองหญ้าปล้อง", "DISTRICT_ID": 822, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7459, "SUB_DISTRICT_CODE": "760302", "SUB_DISTRICT_NAME": "ยางน้ำกลัดเหนือ", "DISTRICT_ID": 822, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7460, "SUB_DISTRICT_CODE": "760303", "SUB_DISTRICT_NAME": "ยางน้ำกลัดใต้", "DISTRICT_ID": 822, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7461, "SUB_DISTRICT_CODE": "760304", "SUB_DISTRICT_NAME": "ท่าตะคร้อ", "DISTRICT_ID": 822, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7462, "SUB_DISTRICT_CODE": "760401", "SUB_DISTRICT_NAME": "ชะอำ", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7463, "SUB_DISTRICT_CODE": "760402", "SUB_DISTRICT_NAME": "บางเก่า", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7464, "SUB_DISTRICT_CODE": "760403", "SUB_DISTRICT_NAME": "นายาง", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7465, "SUB_DISTRICT_CODE": "760404", "SUB_DISTRICT_NAME": "เขาใหญ่", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7466, "SUB_DISTRICT_CODE": "760405", "SUB_DISTRICT_NAME": "หนองศาลา", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7467, "SUB_DISTRICT_CODE": "760406", "SUB_DISTRICT_NAME": "ห้วยทรายเหนือ", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7468, "SUB_DISTRICT_CODE": "760407", "SUB_DISTRICT_NAME": "ไร่ใหม่พัฒนา", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7469, "SUB_DISTRICT_CODE": "760408", "SUB_DISTRICT_NAME": "สามพระยา", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7470, "SUB_DISTRICT_CODE": "760409", "SUB_DISTRICT_NAME": "ดอนขุนห้วย", "DISTRICT_ID": 823, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7471, "SUB_DISTRICT_CODE": "760501", "SUB_DISTRICT_NAME": "ท่ายาง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7472, "SUB_DISTRICT_CODE": "760502", "SUB_DISTRICT_NAME": "ท่าคอย", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7473, "SUB_DISTRICT_CODE": "760503", "SUB_DISTRICT_NAME": "ยางหย่อง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7474, "SUB_DISTRICT_CODE": "760504", "SUB_DISTRICT_NAME": "หนองจอก", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7475, "SUB_DISTRICT_CODE": "760505", "SUB_DISTRICT_NAME": "มาบปลาเค้า", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7476, "SUB_DISTRICT_CODE": "760506", "SUB_DISTRICT_NAME": "ท่าไม้รวก", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7477, "SUB_DISTRICT_CODE": "760507", "SUB_DISTRICT_NAME": "วังไคร้", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7478, "SUB_DISTRICT_CODE": "760508", "SUB_DISTRICT_NAME": "*วังจันทร์", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7479, "SUB_DISTRICT_CODE": "760509", "SUB_DISTRICT_NAME": "*สองพี่น้อง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7480, "SUB_DISTRICT_CODE": "760510", "SUB_DISTRICT_NAME": "*แก่งกระจาน", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7481, "SUB_DISTRICT_CODE": "760511", "SUB_DISTRICT_NAME": "กลัดหลวง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7482, "SUB_DISTRICT_CODE": "760512", "SUB_DISTRICT_NAME": "ปึกเตียน", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7483, "SUB_DISTRICT_CODE": "760513", "SUB_DISTRICT_NAME": "เขากระปุก", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7484, "SUB_DISTRICT_CODE": "760514", "SUB_DISTRICT_NAME": "ท่าแลง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7485, "SUB_DISTRICT_CODE": "760515", "SUB_DISTRICT_NAME": "บ้านในดง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7486, "SUB_DISTRICT_CODE": "760594", "SUB_DISTRICT_NAME": "*สระปลาดู่", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7487, "SUB_DISTRICT_CODE": "760595", "SUB_DISTRICT_NAME": "*บางเมือง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7488, "SUB_DISTRICT_CODE": "760596", "SUB_DISTRICT_NAME": "*นาไพร", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7489, "SUB_DISTRICT_CODE": "760597", "SUB_DISTRICT_NAME": "*วังจันทร์", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7490, "SUB_DISTRICT_CODE": "760598", "SUB_DISTRICT_NAME": "*สองพี่น้อง", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7491, "SUB_DISTRICT_CODE": "760599", "SUB_DISTRICT_NAME": "*แก่งกระจาน", "DISTRICT_ID": 824, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7492, "SUB_DISTRICT_CODE": "760601", "SUB_DISTRICT_NAME": "บ้านลาด", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7493, "SUB_DISTRICT_CODE": "760602", "SUB_DISTRICT_NAME": "บ้านหาด", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7494, "SUB_DISTRICT_CODE": "760603", "SUB_DISTRICT_NAME": "บ้านทาน", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7495, "SUB_DISTRICT_CODE": "760604", "SUB_DISTRICT_NAME": "ตำหรุ", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7496, "SUB_DISTRICT_CODE": "760605", "SUB_DISTRICT_NAME": "สมอพลือ", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7497, "SUB_DISTRICT_CODE": "760606", "SUB_DISTRICT_NAME": "ไร่มะขาม", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7498, "SUB_DISTRICT_CODE": "760607", "SUB_DISTRICT_NAME": "ท่าเสน", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7499, "SUB_DISTRICT_CODE": "760608", "SUB_DISTRICT_NAME": "หนองกระเจ็ด", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7500, "SUB_DISTRICT_CODE": "760609", "SUB_DISTRICT_NAME": "หนองกะปุ", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7501, "SUB_DISTRICT_CODE": "760610", "SUB_DISTRICT_NAME": "ลาดโพธิ์", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7502, "SUB_DISTRICT_CODE": "760611", "SUB_DISTRICT_NAME": "สะพานไกร", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7503, "SUB_DISTRICT_CODE": "760612", "SUB_DISTRICT_NAME": "ไร่โคก", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7504, "SUB_DISTRICT_CODE": "760613", "SUB_DISTRICT_NAME": "โรงเข้", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7505, "SUB_DISTRICT_CODE": "760614", "SUB_DISTRICT_NAME": "ไร่สะท้อน", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7506, "SUB_DISTRICT_CODE": "760615", "SUB_DISTRICT_NAME": "ห้วยข้อง", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7507, "SUB_DISTRICT_CODE": "760616", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7508, "SUB_DISTRICT_CODE": "760617", "SUB_DISTRICT_NAME": "ถ้ำรงค์", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7509, "SUB_DISTRICT_CODE": "760618", "SUB_DISTRICT_NAME": "ห้วยลึก", "DISTRICT_ID": 825, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7510, "SUB_DISTRICT_CODE": "760701", "SUB_DISTRICT_NAME": "บ้านแหลม", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7511, "SUB_DISTRICT_CODE": "760702", "SUB_DISTRICT_NAME": "บางขุนไทร", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7512, "SUB_DISTRICT_CODE": "760703", "SUB_DISTRICT_NAME": "ปากทะเล", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7513, "SUB_DISTRICT_CODE": "760704", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7514, "SUB_DISTRICT_CODE": "760705", "SUB_DISTRICT_NAME": "แหลมผักเบี้ย", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7515, "SUB_DISTRICT_CODE": "760706", "SUB_DISTRICT_NAME": "บางตะบูน", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7516, "SUB_DISTRICT_CODE": "760707", "SUB_DISTRICT_NAME": "บางตะบูนออก", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7517, "SUB_DISTRICT_CODE": "760708", "SUB_DISTRICT_NAME": "บางครก", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7518, "SUB_DISTRICT_CODE": "760709", "SUB_DISTRICT_NAME": "ท่าแร้ง", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7519, "SUB_DISTRICT_CODE": "760710", "SUB_DISTRICT_NAME": "ท่าแร้งออก", "DISTRICT_ID": 826, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7520, "SUB_DISTRICT_CODE": "760801", "SUB_DISTRICT_NAME": "แก่งกระจาน", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7521, "SUB_DISTRICT_CODE": "760802", "SUB_DISTRICT_NAME": "สองพี่น้อง", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7522, "SUB_DISTRICT_CODE": "760803", "SUB_DISTRICT_NAME": "วังจันทร์", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7523, "SUB_DISTRICT_CODE": "760804", "SUB_DISTRICT_NAME": "ป่าเด็ง", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7524, "SUB_DISTRICT_CODE": "760805", "SUB_DISTRICT_NAME": "พุสวรรค์", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7525, "SUB_DISTRICT_CODE": "760806", "SUB_DISTRICT_NAME": "ห้วยแม่เพรียง", "DISTRICT_ID": 827, "PROVINCE_ID": 61, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7526, "SUB_DISTRICT_CODE": "770101", "SUB_DISTRICT_NAME": "ประจวบคีรีขันธ์", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7527, "SUB_DISTRICT_CODE": "770102", "SUB_DISTRICT_NAME": "เกาะหลัก", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7528, "SUB_DISTRICT_CODE": "770103", "SUB_DISTRICT_NAME": "คลองวาฬ", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7529, "SUB_DISTRICT_CODE": "770104", "SUB_DISTRICT_NAME": "ห้วยทราย", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7530, "SUB_DISTRICT_CODE": "770105", "SUB_DISTRICT_NAME": "อ่าวน้อย", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7531, "SUB_DISTRICT_CODE": "770106", "SUB_DISTRICT_NAME": "บ่อนอก", "DISTRICT_ID": 828, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7532, "SUB_DISTRICT_CODE": "770201", "SUB_DISTRICT_NAME": "กุยบุรี", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7533, "SUB_DISTRICT_CODE": "770202", "SUB_DISTRICT_NAME": "กุยเหนือ", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7534, "SUB_DISTRICT_CODE": "770203", "SUB_DISTRICT_NAME": "เขาแดง", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7535, "SUB_DISTRICT_CODE": "770204", "SUB_DISTRICT_NAME": "ดอนยายหนู", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7536, "SUB_DISTRICT_CODE": "770205", "SUB_DISTRICT_NAME": "ไร่ใหม่*", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7537, "SUB_DISTRICT_CODE": "770206", "SUB_DISTRICT_NAME": "สามกระทาย", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7538, "SUB_DISTRICT_CODE": "770207", "SUB_DISTRICT_NAME": "หาดขาม", "DISTRICT_ID": 829, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7539, "SUB_DISTRICT_CODE": "770301", "SUB_DISTRICT_NAME": "ทับสะแก", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7540, "SUB_DISTRICT_CODE": "770302", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7541, "SUB_DISTRICT_CODE": "770303", "SUB_DISTRICT_NAME": "นาหูกวาง", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7542, "SUB_DISTRICT_CODE": "770304", "SUB_DISTRICT_NAME": "เขาล้าน", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7543, "SUB_DISTRICT_CODE": "770305", "SUB_DISTRICT_NAME": "ห้วยยาง", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7544, "SUB_DISTRICT_CODE": "770306", "SUB_DISTRICT_NAME": "แสงอรุณ", "DISTRICT_ID": 830, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7545, "SUB_DISTRICT_CODE": "770401", "SUB_DISTRICT_NAME": "กำเนิดนพคุณ", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7546, "SUB_DISTRICT_CODE": "770402", "SUB_DISTRICT_NAME": "พงศ์ประศาสน์", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7547, "SUB_DISTRICT_CODE": "770403", "SUB_DISTRICT_NAME": "ร่อนทอง", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7548, "SUB_DISTRICT_CODE": "770404", "SUB_DISTRICT_NAME": "ธงชัย", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7549, "SUB_DISTRICT_CODE": "770405", "SUB_DISTRICT_NAME": "ชัยเกษม", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7550, "SUB_DISTRICT_CODE": "770406", "SUB_DISTRICT_NAME": "ทองมงคล", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7551, "SUB_DISTRICT_CODE": "770407", "SUB_DISTRICT_NAME": "แม่รำพึง", "DISTRICT_ID": 831, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7552, "SUB_DISTRICT_CODE": "770501", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 832, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7553, "SUB_DISTRICT_CODE": "770502", "SUB_DISTRICT_NAME": "บางสะพาน", "DISTRICT_ID": 832, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7554, "SUB_DISTRICT_CODE": "770503", "SUB_DISTRICT_NAME": "ทรายทอง", "DISTRICT_ID": 832, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7555, "SUB_DISTRICT_CODE": "770504", "SUB_DISTRICT_NAME": "ช้างแรก", "DISTRICT_ID": 832, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7556, "SUB_DISTRICT_CODE": "770505", "SUB_DISTRICT_NAME": "ไชยราช", "DISTRICT_ID": 832, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7557, "SUB_DISTRICT_CODE": "770601", "SUB_DISTRICT_NAME": "ปราณบุรี", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7558, "SUB_DISTRICT_CODE": "770602", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7559, "SUB_DISTRICT_CODE": "770603", "SUB_DISTRICT_NAME": "*ศิลาลอย", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7560, "SUB_DISTRICT_CODE": "770604", "SUB_DISTRICT_NAME": "ปากน้ำปราณ", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7561, "SUB_DISTRICT_CODE": "770605", "SUB_DISTRICT_NAME": "*สามร้อยยอด", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7562, "SUB_DISTRICT_CODE": "770606", "SUB_DISTRICT_NAME": "*ไร่เก่า", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7563, "SUB_DISTRICT_CODE": "770607", "SUB_DISTRICT_NAME": "หนองตาแต้ม", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7564, "SUB_DISTRICT_CODE": "770608", "SUB_DISTRICT_NAME": "วังก์พง", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7565, "SUB_DISTRICT_CODE": "770609", "SUB_DISTRICT_NAME": "เขาจ้าว", "DISTRICT_ID": 833, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7566, "SUB_DISTRICT_CODE": "770701", "SUB_DISTRICT_NAME": "หัวหิน", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7567, "SUB_DISTRICT_CODE": "770702", "SUB_DISTRICT_NAME": "หนองแก", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7568, "SUB_DISTRICT_CODE": "770703", "SUB_DISTRICT_NAME": "หินเหล็กไฟ", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7569, "SUB_DISTRICT_CODE": "770704", "SUB_DISTRICT_NAME": "หนองพลับ", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7570, "SUB_DISTRICT_CODE": "770705", "SUB_DISTRICT_NAME": "ทับใต้", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7571, "SUB_DISTRICT_CODE": "770706", "SUB_DISTRICT_NAME": "ห้วยสัตว์ใหญ่", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7572, "SUB_DISTRICT_CODE": "770707", "SUB_DISTRICT_NAME": "บึงนคร", "DISTRICT_ID": 834, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7573, "SUB_DISTRICT_CODE": "770801", "SUB_DISTRICT_NAME": "สามร้อยยอด", "DISTRICT_ID": 835, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7574, "SUB_DISTRICT_CODE": "770802", "SUB_DISTRICT_NAME": "ศิลาลอย", "DISTRICT_ID": 835, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7575, "SUB_DISTRICT_CODE": "770803", "SUB_DISTRICT_NAME": "ไร่เก่า", "DISTRICT_ID": 835, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7576, "SUB_DISTRICT_CODE": "770804", "SUB_DISTRICT_NAME": "ศาลาลัย", "DISTRICT_ID": 835, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7577, "SUB_DISTRICT_CODE": "770805", "SUB_DISTRICT_NAME": "ไร่ใหม่", "DISTRICT_ID": 835, "PROVINCE_ID": 62, "GEO_ID": 4 },
    { "SUB_DISTRICT_ID": 7578, "SUB_DISTRICT_CODE": "800101", "SUB_DISTRICT_NAME": "ในเมือง", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7579, "SUB_DISTRICT_CODE": "800102", "SUB_DISTRICT_NAME": "ท่าวัง", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7580, "SUB_DISTRICT_CODE": "800103", "SUB_DISTRICT_NAME": "คลัง", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7581, "SUB_DISTRICT_CODE": "800104", "SUB_DISTRICT_NAME": "*นา", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7582, "SUB_DISTRICT_CODE": "800105", "SUB_DISTRICT_NAME": "*ศาลามีชัย", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7583, "SUB_DISTRICT_CODE": "800106", "SUB_DISTRICT_NAME": "ท่าไร่", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7584, "SUB_DISTRICT_CODE": "800107", "SUB_DISTRICT_NAME": "ปากนคร", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7585, "SUB_DISTRICT_CODE": "800108", "SUB_DISTRICT_NAME": "นาทราย", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7586, "SUB_DISTRICT_CODE": "800109", "SUB_DISTRICT_NAME": "*นาพรุ", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7587, "SUB_DISTRICT_CODE": "800110", "SUB_DISTRICT_NAME": "*ช้างซ้าย", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7588, "SUB_DISTRICT_CODE": "800111", "SUB_DISTRICT_NAME": "*นาสาร", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7589, "SUB_DISTRICT_CODE": "800112", "SUB_DISTRICT_NAME": "กำแพงเซา", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7590, "SUB_DISTRICT_CODE": "800113", "SUB_DISTRICT_NAME": "ไชยมนตรี", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7591, "SUB_DISTRICT_CODE": "800114", "SUB_DISTRICT_NAME": "มะม่วงสองต้น", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7592, "SUB_DISTRICT_CODE": "800115", "SUB_DISTRICT_NAME": "นาเคียน", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7593, "SUB_DISTRICT_CODE": "800116", "SUB_DISTRICT_NAME": "ท่างิ้ว", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7594, "SUB_DISTRICT_CODE": "800117", "SUB_DISTRICT_NAME": "*ท้ายสำเภา", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7595, "SUB_DISTRICT_CODE": "800118", "SUB_DISTRICT_NAME": "โพธิ์เสด็จ", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7596, "SUB_DISTRICT_CODE": "800119", "SUB_DISTRICT_NAME": "บางจาก", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7597, "SUB_DISTRICT_CODE": "800120", "SUB_DISTRICT_NAME": "ปากพูน", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7598, "SUB_DISTRICT_CODE": "800121", "SUB_DISTRICT_NAME": "ท่าซัก", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7599, "SUB_DISTRICT_CODE": "800122", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7600, "SUB_DISTRICT_CODE": "800195", "SUB_DISTRICT_NAME": "*อินคีรี", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7601, "SUB_DISTRICT_CODE": "800196", "SUB_DISTRICT_NAME": "*พรหมโลก", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7602, "SUB_DISTRICT_CODE": "800197", "SUB_DISTRICT_NAME": "*ศาลามีชัย", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7603, "SUB_DISTRICT_CODE": "800198", "SUB_DISTRICT_NAME": "*นา", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7604, "SUB_DISTRICT_CODE": "800199", "SUB_DISTRICT_NAME": "*บ้านเกาะ", "DISTRICT_ID": 836, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7605, "SUB_DISTRICT_CODE": "800201", "SUB_DISTRICT_NAME": "พรหมโลก", "DISTRICT_ID": 837, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7606, "SUB_DISTRICT_CODE": "800202", "SUB_DISTRICT_NAME": "บ้านเกาะ", "DISTRICT_ID": 837, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7607, "SUB_DISTRICT_CODE": "800203", "SUB_DISTRICT_NAME": "อินคีรี", "DISTRICT_ID": 837, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7608, "SUB_DISTRICT_CODE": "800204", "SUB_DISTRICT_NAME": "ทอนหงส์", "DISTRICT_ID": 837, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7609, "SUB_DISTRICT_CODE": "800205", "SUB_DISTRICT_NAME": "นาเรียง", "DISTRICT_ID": 837, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7610, "SUB_DISTRICT_CODE": "800301", "SUB_DISTRICT_NAME": "เขาแก้ว", "DISTRICT_ID": 838, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7611, "SUB_DISTRICT_CODE": "800302", "SUB_DISTRICT_NAME": "ลานสกา", "DISTRICT_ID": 838, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7612, "SUB_DISTRICT_CODE": "800303", "SUB_DISTRICT_NAME": "ท่าดี", "DISTRICT_ID": 838, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7613, "SUB_DISTRICT_CODE": "800304", "SUB_DISTRICT_NAME": "กำโลน", "DISTRICT_ID": 838, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7614, "SUB_DISTRICT_CODE": "800305", "SUB_DISTRICT_NAME": "ขุนทะเล", "DISTRICT_ID": 838, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7615, "SUB_DISTRICT_CODE": "800401", "SUB_DISTRICT_NAME": "ฉวาง", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7616, "SUB_DISTRICT_CODE": "800402", "SUB_DISTRICT_NAME": "ช้างกลาง*", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7617, "SUB_DISTRICT_CODE": "800403", "SUB_DISTRICT_NAME": "ละอาย", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7618, "SUB_DISTRICT_CODE": "800404", "SUB_DISTRICT_NAME": "นาแว", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7619, "SUB_DISTRICT_CODE": "800405", "SUB_DISTRICT_NAME": "ไม้เรียง", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7620, "SUB_DISTRICT_CODE": "800406", "SUB_DISTRICT_NAME": "กะเปียด", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7621, "SUB_DISTRICT_CODE": "800407", "SUB_DISTRICT_NAME": "นากะชะ", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7622, "SUB_DISTRICT_CODE": "800408", "SUB_DISTRICT_NAME": "*ถ้ำพรรณรา", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7623, "SUB_DISTRICT_CODE": "800409", "SUB_DISTRICT_NAME": "ห้วยปริก", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7624, "SUB_DISTRICT_CODE": "800410", "SUB_DISTRICT_NAME": "ไสหร้า", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7625, "SUB_DISTRICT_CODE": "800411", "SUB_DISTRICT_NAME": "หลักช้าง*", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7626, "SUB_DISTRICT_CODE": "800412", "SUB_DISTRICT_NAME": "สวนขัน*", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7627, "SUB_DISTRICT_CODE": "800413", "SUB_DISTRICT_NAME": "*คลองเส", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7628, "SUB_DISTRICT_CODE": "800414", "SUB_DISTRICT_NAME": "*ดุสิต", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7629, "SUB_DISTRICT_CODE": "800415", "SUB_DISTRICT_NAME": "นาเขลียง", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7630, "SUB_DISTRICT_CODE": "800416", "SUB_DISTRICT_NAME": "จันดี", "DISTRICT_ID": 839, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7631, "SUB_DISTRICT_CODE": "800501", "SUB_DISTRICT_NAME": "พิปูน", "DISTRICT_ID": 840, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7632, "SUB_DISTRICT_CODE": "800502", "SUB_DISTRICT_NAME": "กะทูน", "DISTRICT_ID": 840, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7633, "SUB_DISTRICT_CODE": "800503", "SUB_DISTRICT_NAME": "เขาพระ", "DISTRICT_ID": 840, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7634, "SUB_DISTRICT_CODE": "800504", "SUB_DISTRICT_NAME": "ยางค้อม", "DISTRICT_ID": 840, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7635, "SUB_DISTRICT_CODE": "800505", "SUB_DISTRICT_NAME": "ควนกลาง", "DISTRICT_ID": 840, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7636, "SUB_DISTRICT_CODE": "800601", "SUB_DISTRICT_NAME": "เชียรใหญ่", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7637, "SUB_DISTRICT_CODE": "800602", "SUB_DISTRICT_NAME": "เชียรเขา*", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7638, "SUB_DISTRICT_CODE": "800603", "SUB_DISTRICT_NAME": "ท่าขนาน", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7639, "SUB_DISTRICT_CODE": "800604", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7640, "SUB_DISTRICT_CODE": "800605", "SUB_DISTRICT_NAME": "บ้านเนิน", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7641, "SUB_DISTRICT_CODE": "800606", "SUB_DISTRICT_NAME": "ไสหมาก", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7642, "SUB_DISTRICT_CODE": "800607", "SUB_DISTRICT_NAME": "ท้องลำเจียก", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7643, "SUB_DISTRICT_CODE": "800608", "SUB_DISTRICT_NAME": "ดอนตรอ*", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7644, "SUB_DISTRICT_CODE": "800609", "SUB_DISTRICT_NAME": "สวนหลวง*", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7645, "SUB_DISTRICT_CODE": "800610", "SUB_DISTRICT_NAME": "เสือหึง", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7646, "SUB_DISTRICT_CODE": "800611", "SUB_DISTRICT_NAME": "การะเกด", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7647, "SUB_DISTRICT_CODE": "800612", "SUB_DISTRICT_NAME": "เขาพระบาท", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7648, "SUB_DISTRICT_CODE": "800613", "SUB_DISTRICT_NAME": "แม่เจ้าอยู่หัว", "DISTRICT_ID": 841, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7649, "SUB_DISTRICT_CODE": "800701", "SUB_DISTRICT_NAME": "ชะอวด", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7650, "SUB_DISTRICT_CODE": "800702", "SUB_DISTRICT_NAME": "ท่าเสม็ด", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7651, "SUB_DISTRICT_CODE": "800703", "SUB_DISTRICT_NAME": "ท่าประจะ", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7652, "SUB_DISTRICT_CODE": "800704", "SUB_DISTRICT_NAME": "เคร็ง", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7653, "SUB_DISTRICT_CODE": "800705", "SUB_DISTRICT_NAME": "วังอ่าง", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7654, "SUB_DISTRICT_CODE": "800706", "SUB_DISTRICT_NAME": "บ้านตูล", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7655, "SUB_DISTRICT_CODE": "800707", "SUB_DISTRICT_NAME": "ขอนหาด", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7656, "SUB_DISTRICT_CODE": "800708", "SUB_DISTRICT_NAME": "เกาะขันธ์", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7657, "SUB_DISTRICT_CODE": "800709", "SUB_DISTRICT_NAME": "ควนหนองหงษ์", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7658, "SUB_DISTRICT_CODE": "800710", "SUB_DISTRICT_NAME": "เขาพระทอง", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7659, "SUB_DISTRICT_CODE": "800711", "SUB_DISTRICT_NAME": "นางหลง", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7660, "SUB_DISTRICT_CODE": "800712", "SUB_DISTRICT_NAME": "*บ้านควนมุด", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7661, "SUB_DISTRICT_CODE": "800713", "SUB_DISTRICT_NAME": "*บ้านชะอวด", "DISTRICT_ID": 842, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7662, "SUB_DISTRICT_CODE": "800801", "SUB_DISTRICT_NAME": "ท่าศาลา", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7663, "SUB_DISTRICT_CODE": "800802", "SUB_DISTRICT_NAME": "กลาย", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7664, "SUB_DISTRICT_CODE": "800803", "SUB_DISTRICT_NAME": "ท่าขึ้น", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7665, "SUB_DISTRICT_CODE": "800804", "SUB_DISTRICT_NAME": "หัวตะพาน", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7666, "SUB_DISTRICT_CODE": "800805", "SUB_DISTRICT_NAME": "*กะหรอ", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7667, "SUB_DISTRICT_CODE": "800806", "SUB_DISTRICT_NAME": "สระแก้ว", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7668, "SUB_DISTRICT_CODE": "800807", "SUB_DISTRICT_NAME": "โมคลาน", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7669, "SUB_DISTRICT_CODE": "800808", "SUB_DISTRICT_NAME": "*นบพิตำ", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7670, "SUB_DISTRICT_CODE": "800809", "SUB_DISTRICT_NAME": "ไทยบุรี", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7671, "SUB_DISTRICT_CODE": "800810", "SUB_DISTRICT_NAME": "ดอนตะโก", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7672, "SUB_DISTRICT_CODE": "800811", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7673, "SUB_DISTRICT_CODE": "800812", "SUB_DISTRICT_NAME": "*กรุงชิง", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7674, "SUB_DISTRICT_CODE": "800813", "SUB_DISTRICT_NAME": "โพธิ์ทอง", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7675, "SUB_DISTRICT_CODE": "800814", "SUB_DISTRICT_NAME": "*นาเหรง", "DISTRICT_ID": 843, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7676, "SUB_DISTRICT_CODE": "800901", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7677, "SUB_DISTRICT_CODE": "800902", "SUB_DISTRICT_NAME": "ชะมาย", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7678, "SUB_DISTRICT_CODE": "800903", "SUB_DISTRICT_NAME": "หนองหงส์", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7679, "SUB_DISTRICT_CODE": "800904", "SUB_DISTRICT_NAME": "ควนกรด", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7680, "SUB_DISTRICT_CODE": "800905", "SUB_DISTRICT_NAME": "นาไม้ไผ่", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7681, "SUB_DISTRICT_CODE": "800906", "SUB_DISTRICT_NAME": "นาหลวงเสน", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7682, "SUB_DISTRICT_CODE": "800907", "SUB_DISTRICT_NAME": "เขาโร", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7683, "SUB_DISTRICT_CODE": "800908", "SUB_DISTRICT_NAME": "กะปาง", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7684, "SUB_DISTRICT_CODE": "800909", "SUB_DISTRICT_NAME": "ที่วัง", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7685, "SUB_DISTRICT_CODE": "800910", "SUB_DISTRICT_NAME": "น้ำตก", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7686, "SUB_DISTRICT_CODE": "800911", "SUB_DISTRICT_NAME": "ถ้ำใหญ่", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7687, "SUB_DISTRICT_CODE": "800912", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7688, "SUB_DISTRICT_CODE": "800913", "SUB_DISTRICT_NAME": "เขาขาว", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7689, "SUB_DISTRICT_CODE": "800994", "SUB_DISTRICT_NAME": "*วังหิน", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7690, "SUB_DISTRICT_CODE": "800995", "SUB_DISTRICT_NAME": "*บ้านลำนาว", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7691, "SUB_DISTRICT_CODE": "800996", "SUB_DISTRICT_NAME": "*บางขัน", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7692, "SUB_DISTRICT_CODE": "800997", "SUB_DISTRICT_NAME": "*แก้วแสน", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7693, "SUB_DISTRICT_CODE": "800998", "SUB_DISTRICT_NAME": "*ทุ่งสง", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7694, "SUB_DISTRICT_CODE": "800999", "SUB_DISTRICT_NAME": "*นาบอน", "DISTRICT_ID": 844, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7695, "SUB_DISTRICT_CODE": "801001", "SUB_DISTRICT_NAME": "นาบอน", "DISTRICT_ID": 845, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7696, "SUB_DISTRICT_CODE": "801002", "SUB_DISTRICT_NAME": "ทุ่งสง", "DISTRICT_ID": 845, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7697, "SUB_DISTRICT_CODE": "801003", "SUB_DISTRICT_NAME": "แก้วแสน", "DISTRICT_ID": 845, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7698, "SUB_DISTRICT_CODE": "801101", "SUB_DISTRICT_NAME": "ท่ายาง", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7699, "SUB_DISTRICT_CODE": "801102", "SUB_DISTRICT_NAME": "ทุ่งสัง", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7700, "SUB_DISTRICT_CODE": "801103", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7701, "SUB_DISTRICT_CODE": "801104", "SUB_DISTRICT_NAME": "กุแหระ", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7702, "SUB_DISTRICT_CODE": "801105", "SUB_DISTRICT_NAME": "ปริก", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7703, "SUB_DISTRICT_CODE": "801106", "SUB_DISTRICT_NAME": "บางรูป", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7704, "SUB_DISTRICT_CODE": "801107", "SUB_DISTRICT_NAME": "กรุงหยัน", "DISTRICT_ID": 846, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7705, "SUB_DISTRICT_CODE": "801201", "SUB_DISTRICT_NAME": "ปากพนัง", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7706, "SUB_DISTRICT_CODE": "801202", "SUB_DISTRICT_NAME": "คลองน้อย", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7707, "SUB_DISTRICT_CODE": "801203", "SUB_DISTRICT_NAME": "ป่าระกำ", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7708, "SUB_DISTRICT_CODE": "801204", "SUB_DISTRICT_NAME": "ชะเมา", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7709, "SUB_DISTRICT_CODE": "801205", "SUB_DISTRICT_NAME": "คลองกระบือ", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7710, "SUB_DISTRICT_CODE": "801206", "SUB_DISTRICT_NAME": "เกาะทวด", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7711, "SUB_DISTRICT_CODE": "801207", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7712, "SUB_DISTRICT_CODE": "801208", "SUB_DISTRICT_NAME": "หูล่อง", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7713, "SUB_DISTRICT_CODE": "801209", "SUB_DISTRICT_NAME": "แหลมตะลุมพุก", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7714, "SUB_DISTRICT_CODE": "801210", "SUB_DISTRICT_NAME": "ปากพนังฝั่งตะวันตก", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7715, "SUB_DISTRICT_CODE": "801211", "SUB_DISTRICT_NAME": "บางศาลา", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7716, "SUB_DISTRICT_CODE": "801212", "SUB_DISTRICT_NAME": "บางพระ", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7717, "SUB_DISTRICT_CODE": "801213", "SUB_DISTRICT_NAME": "บางตะพง", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7718, "SUB_DISTRICT_CODE": "801214", "SUB_DISTRICT_NAME": "ปากพนังฝั่งตะวันออก", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7719, "SUB_DISTRICT_CODE": "801215", "SUB_DISTRICT_NAME": "บ้านเพิง", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7720, "SUB_DISTRICT_CODE": "801216", "SUB_DISTRICT_NAME": "ท่าพยา", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7721, "SUB_DISTRICT_CODE": "801217", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7722, "SUB_DISTRICT_CODE": "801218", "SUB_DISTRICT_NAME": "ขนาบนาก", "DISTRICT_ID": 847, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7723, "SUB_DISTRICT_CODE": "801301", "SUB_DISTRICT_NAME": "ร่อนพิบูลย์", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7724, "SUB_DISTRICT_CODE": "801302", "SUB_DISTRICT_NAME": "หินตก", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7725, "SUB_DISTRICT_CODE": "801303", "SUB_DISTRICT_NAME": "เสาธง", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7726, "SUB_DISTRICT_CODE": "801304", "SUB_DISTRICT_NAME": "ควนเกย", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7727, "SUB_DISTRICT_CODE": "801305", "SUB_DISTRICT_NAME": "ควนพัง", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7728, "SUB_DISTRICT_CODE": "801306", "SUB_DISTRICT_NAME": "ควนชุม", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7729, "SUB_DISTRICT_CODE": "801307", "SUB_DISTRICT_NAME": "*สามตำบล", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7730, "SUB_DISTRICT_CODE": "801308", "SUB_DISTRICT_NAME": "ทางพูน*", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7731, "SUB_DISTRICT_CODE": "801309", "SUB_DISTRICT_NAME": "*นาหมอบุญ", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7732, "SUB_DISTRICT_CODE": "801310", "SUB_DISTRICT_NAME": "*ทุ่งโพธิ์", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7733, "SUB_DISTRICT_CODE": "801311", "SUB_DISTRICT_NAME": "*ควนหนองคว้า", "DISTRICT_ID": 848, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7734, "SUB_DISTRICT_CODE": "801401", "SUB_DISTRICT_NAME": "สิชล", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7735, "SUB_DISTRICT_CODE": "801402", "SUB_DISTRICT_NAME": "ทุ่งปรัง", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7736, "SUB_DISTRICT_CODE": "801403", "SUB_DISTRICT_NAME": "ฉลอง", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7737, "SUB_DISTRICT_CODE": "801404", "SUB_DISTRICT_NAME": "เสาเภา", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7738, "SUB_DISTRICT_CODE": "801405", "SUB_DISTRICT_NAME": "เปลี่ยน", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7739, "SUB_DISTRICT_CODE": "801406", "SUB_DISTRICT_NAME": "สี่ขีด", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7740, "SUB_DISTRICT_CODE": "801407", "SUB_DISTRICT_NAME": "เทพราช", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7741, "SUB_DISTRICT_CODE": "801408", "SUB_DISTRICT_NAME": "เขาน้อย", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7742, "SUB_DISTRICT_CODE": "801409", "SUB_DISTRICT_NAME": "ทุ่งใส", "DISTRICT_ID": 849, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7743, "SUB_DISTRICT_CODE": "801501", "SUB_DISTRICT_NAME": "ขนอม", "DISTRICT_ID": 850, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7744, "SUB_DISTRICT_CODE": "801502", "SUB_DISTRICT_NAME": "ควนทอง", "DISTRICT_ID": 850, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7745, "SUB_DISTRICT_CODE": "801503", "SUB_DISTRICT_NAME": "ท้องเนียน", "DISTRICT_ID": 850, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7746, "SUB_DISTRICT_CODE": "801601", "SUB_DISTRICT_NAME": "หัวไทร", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7747, "SUB_DISTRICT_CODE": "801602", "SUB_DISTRICT_NAME": "หน้าสตน", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7748, "SUB_DISTRICT_CODE": "801603", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7749, "SUB_DISTRICT_CODE": "801604", "SUB_DISTRICT_NAME": "แหลม", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7750, "SUB_DISTRICT_CODE": "801605", "SUB_DISTRICT_NAME": "เขาพังไกร", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7751, "SUB_DISTRICT_CODE": "801606", "SUB_DISTRICT_NAME": "บ้านราม", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7752, "SUB_DISTRICT_CODE": "801607", "SUB_DISTRICT_NAME": "บางนบ", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7753, "SUB_DISTRICT_CODE": "801608", "SUB_DISTRICT_NAME": "ท่าซอม", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7754, "SUB_DISTRICT_CODE": "801609", "SUB_DISTRICT_NAME": "ควนชะลิก", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7755, "SUB_DISTRICT_CODE": "801610", "SUB_DISTRICT_NAME": "รามแก้ว", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7756, "SUB_DISTRICT_CODE": "801611", "SUB_DISTRICT_NAME": "เกาะเพชร", "DISTRICT_ID": 851, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7757, "SUB_DISTRICT_CODE": "801701", "SUB_DISTRICT_NAME": "บางขัน", "DISTRICT_ID": 852, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7758, "SUB_DISTRICT_CODE": "801702", "SUB_DISTRICT_NAME": "บ้านลำนาว", "DISTRICT_ID": 852, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7759, "SUB_DISTRICT_CODE": "801703", "SUB_DISTRICT_NAME": "วังหิน", "DISTRICT_ID": 852, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7760, "SUB_DISTRICT_CODE": "801704", "SUB_DISTRICT_NAME": "บ้านนิคม", "DISTRICT_ID": 852, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7761, "SUB_DISTRICT_CODE": "801801", "SUB_DISTRICT_NAME": "ถ้ำพรรณรา", "DISTRICT_ID": 853, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7762, "SUB_DISTRICT_CODE": "801802", "SUB_DISTRICT_NAME": "คลองเส", "DISTRICT_ID": 853, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7763, "SUB_DISTRICT_CODE": "801803", "SUB_DISTRICT_NAME": "ดุสิต", "DISTRICT_ID": 853, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7764, "SUB_DISTRICT_CODE": "801901", "SUB_DISTRICT_NAME": "บ้านควนมุด", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7765, "SUB_DISTRICT_CODE": "801902", "SUB_DISTRICT_NAME": "บ้านชะอวด", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7766, "SUB_DISTRICT_CODE": "801903", "SUB_DISTRICT_NAME": "ควนหนองคว้า", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7767, "SUB_DISTRICT_CODE": "801904", "SUB_DISTRICT_NAME": "ทุ่งโพธิ์", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7768, "SUB_DISTRICT_CODE": "801905", "SUB_DISTRICT_NAME": "นาหมอบุญ", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7769, "SUB_DISTRICT_CODE": "801906", "SUB_DISTRICT_NAME": "สามตำบล", "DISTRICT_ID": 854, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7770, "SUB_DISTRICT_CODE": "802001", "SUB_DISTRICT_NAME": "นาพรุ", "DISTRICT_ID": 855, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7771, "SUB_DISTRICT_CODE": "802002", "SUB_DISTRICT_NAME": "นาสาร", "DISTRICT_ID": 855, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7772, "SUB_DISTRICT_CODE": "802003", "SUB_DISTRICT_NAME": "ท้ายสำเภา", "DISTRICT_ID": 855, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7773, "SUB_DISTRICT_CODE": "802004", "SUB_DISTRICT_NAME": "ช้างซ้าย", "DISTRICT_ID": 855, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7774, "SUB_DISTRICT_CODE": "802101", "SUB_DISTRICT_NAME": "นบพิตำ", "DISTRICT_ID": 856, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7775, "SUB_DISTRICT_CODE": "802102", "SUB_DISTRICT_NAME": "กรุงชิง", "DISTRICT_ID": 856, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7776, "SUB_DISTRICT_CODE": "802103", "SUB_DISTRICT_NAME": "กะหรอ", "DISTRICT_ID": 856, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7777, "SUB_DISTRICT_CODE": "802104", "SUB_DISTRICT_NAME": "นาเหรง", "DISTRICT_ID": 856, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7778, "SUB_DISTRICT_CODE": "802201", "SUB_DISTRICT_NAME": "ช้างกลาง", "DISTRICT_ID": 857, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7779, "SUB_DISTRICT_CODE": "802202", "SUB_DISTRICT_NAME": "หลักช้าง", "DISTRICT_ID": 857, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7780, "SUB_DISTRICT_CODE": "802203", "SUB_DISTRICT_NAME": "สวนขัน", "DISTRICT_ID": 857, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7781, "SUB_DISTRICT_CODE": "802301", "SUB_DISTRICT_NAME": "เชียรเขา", "DISTRICT_ID": 858, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7782, "SUB_DISTRICT_CODE": "802302", "SUB_DISTRICT_NAME": "ดอนตรอ", "DISTRICT_ID": 858, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7783, "SUB_DISTRICT_CODE": "802303", "SUB_DISTRICT_NAME": "สวนหลวง", "DISTRICT_ID": 858, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7784, "SUB_DISTRICT_CODE": "802304", "SUB_DISTRICT_NAME": "ทางพูน", "DISTRICT_ID": 858, "PROVINCE_ID": 63, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7785, "SUB_DISTRICT_CODE": "810101", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7786, "SUB_DISTRICT_CODE": "810102", "SUB_DISTRICT_NAME": "กระบี่ใหญ่", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7787, "SUB_DISTRICT_CODE": "810103", "SUB_DISTRICT_NAME": "กระบี่น้อย", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7788, "SUB_DISTRICT_CODE": "810104", "SUB_DISTRICT_NAME": "*เกาะศรีบอยา", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7789, "SUB_DISTRICT_CODE": "810105", "SUB_DISTRICT_NAME": "เขาคราม", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7790, "SUB_DISTRICT_CODE": "810106", "SUB_DISTRICT_NAME": "เขาทอง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7791, "SUB_DISTRICT_CODE": "810107", "SUB_DISTRICT_NAME": "*คลองขนาน", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7792, "SUB_DISTRICT_CODE": "810108", "SUB_DISTRICT_NAME": "*คลองเขม้า", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7793, "SUB_DISTRICT_CODE": "810109", "SUB_DISTRICT_NAME": "*โคกยาง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7794, "SUB_DISTRICT_CODE": "810110", "SUB_DISTRICT_NAME": "*ตลิ่งชัน", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7795, "SUB_DISTRICT_CODE": "810111", "SUB_DISTRICT_NAME": "ทับปริก", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7796, "SUB_DISTRICT_CODE": "810112", "SUB_DISTRICT_NAME": "*ปกาสัย", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7797, "SUB_DISTRICT_CODE": "810113", "SUB_DISTRICT_NAME": "*ห้วยยูง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7798, "SUB_DISTRICT_CODE": "810114", "SUB_DISTRICT_NAME": "*เหนือคลอง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7799, "SUB_DISTRICT_CODE": "810115", "SUB_DISTRICT_NAME": "ไสไทย", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7800, "SUB_DISTRICT_CODE": "810116", "SUB_DISTRICT_NAME": "อ่าวนาง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7801, "SUB_DISTRICT_CODE": "810117", "SUB_DISTRICT_NAME": "หนองทะเล", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7802, "SUB_DISTRICT_CODE": "810118", "SUB_DISTRICT_NAME": "คลองประสงค์", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7803, "SUB_DISTRICT_CODE": "810192", "SUB_DISTRICT_NAME": "*เกาะศรีบายอ", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7804, "SUB_DISTRICT_CODE": "810193", "SUB_DISTRICT_NAME": "*คลองเขม้า", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7805, "SUB_DISTRICT_CODE": "810194", "SUB_DISTRICT_NAME": "*โคกยาง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7806, "SUB_DISTRICT_CODE": "810195", "SUB_DISTRICT_NAME": "*ห้วยยูง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7807, "SUB_DISTRICT_CODE": "810196", "SUB_DISTRICT_NAME": "*คลองขนาน", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7808, "SUB_DISTRICT_CODE": "810197", "SUB_DISTRICT_NAME": "*ตลิ่งชัน", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7809, "SUB_DISTRICT_CODE": "810198", "SUB_DISTRICT_NAME": "*ปกาสัย", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7810, "SUB_DISTRICT_CODE": "810199", "SUB_DISTRICT_NAME": "*เหนือคลอง", "DISTRICT_ID": 864, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7811, "SUB_DISTRICT_CODE": "810201", "SUB_DISTRICT_NAME": "เขาพนม", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7812, "SUB_DISTRICT_CODE": "810202", "SUB_DISTRICT_NAME": "เขาดิน", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7813, "SUB_DISTRICT_CODE": "810203", "SUB_DISTRICT_NAME": "สินปุน", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7814, "SUB_DISTRICT_CODE": "810204", "SUB_DISTRICT_NAME": "พรุเตียว", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7815, "SUB_DISTRICT_CODE": "810205", "SUB_DISTRICT_NAME": "หน้าเขา", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7816, "SUB_DISTRICT_CODE": "810206", "SUB_DISTRICT_NAME": "โคกหาร", "DISTRICT_ID": 865, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7817, "SUB_DISTRICT_CODE": "810301", "SUB_DISTRICT_NAME": "เกาะลันตาใหญ่", "DISTRICT_ID": 866, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7818, "SUB_DISTRICT_CODE": "810302", "SUB_DISTRICT_NAME": "เกาะลันตาน้อย", "DISTRICT_ID": 866, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7819, "SUB_DISTRICT_CODE": "810303", "SUB_DISTRICT_NAME": "เกาะกลาง", "DISTRICT_ID": 866, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7820, "SUB_DISTRICT_CODE": "810304", "SUB_DISTRICT_NAME": "คลองยาง", "DISTRICT_ID": 866, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7821, "SUB_DISTRICT_CODE": "810305", "SUB_DISTRICT_NAME": "ศาลาด่าน", "DISTRICT_ID": 866, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7822, "SUB_DISTRICT_CODE": "810401", "SUB_DISTRICT_NAME": "คลองท่อมใต้", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7823, "SUB_DISTRICT_CODE": "810402", "SUB_DISTRICT_NAME": "คลองท่อมเหนือ", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7824, "SUB_DISTRICT_CODE": "810403", "SUB_DISTRICT_NAME": "คลองพน", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7825, "SUB_DISTRICT_CODE": "810404", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7826, "SUB_DISTRICT_CODE": "810405", "SUB_DISTRICT_NAME": "ห้วยน้ำขาว", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7827, "SUB_DISTRICT_CODE": "810406", "SUB_DISTRICT_NAME": "พรุดินนา", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7828, "SUB_DISTRICT_CODE": "810407", "SUB_DISTRICT_NAME": "เพหลา", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7829, "SUB_DISTRICT_CODE": "810499", "SUB_DISTRICT_NAME": "ลำทับ*", "DISTRICT_ID": 867, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7830, "SUB_DISTRICT_CODE": "810501", "SUB_DISTRICT_NAME": "อ่าวลึกใต้", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7831, "SUB_DISTRICT_CODE": "810502", "SUB_DISTRICT_NAME": "แหลมสัก", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7832, "SUB_DISTRICT_CODE": "810503", "SUB_DISTRICT_NAME": "นาเหนือ", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7833, "SUB_DISTRICT_CODE": "810504", "SUB_DISTRICT_NAME": "คลองหิน", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7834, "SUB_DISTRICT_CODE": "810505", "SUB_DISTRICT_NAME": "อ่าวลึกน้อย", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7835, "SUB_DISTRICT_CODE": "810506", "SUB_DISTRICT_NAME": "อ่าวลึกเหนือ", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7836, "SUB_DISTRICT_CODE": "810507", "SUB_DISTRICT_NAME": "เขาใหญ่", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7837, "SUB_DISTRICT_CODE": "810508", "SUB_DISTRICT_NAME": "คลองยา", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7838, "SUB_DISTRICT_CODE": "810509", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7839, "SUB_DISTRICT_CODE": "810597", "SUB_DISTRICT_NAME": "*เขาเขน", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7840, "SUB_DISTRICT_CODE": "810598", "SUB_DISTRICT_NAME": "*เขาต่อ", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7841, "SUB_DISTRICT_CODE": "810599", "SUB_DISTRICT_NAME": "*ปลายพระยา", "DISTRICT_ID": 868, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7842, "SUB_DISTRICT_CODE": "810601", "SUB_DISTRICT_NAME": "ปลายพระยา", "DISTRICT_ID": 869, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7843, "SUB_DISTRICT_CODE": "810602", "SUB_DISTRICT_NAME": "เขาเขน", "DISTRICT_ID": 869, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7844, "SUB_DISTRICT_CODE": "810603", "SUB_DISTRICT_NAME": "เขาต่อ", "DISTRICT_ID": 869, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7845, "SUB_DISTRICT_CODE": "810604", "SUB_DISTRICT_NAME": "คีรีวง", "DISTRICT_ID": 869, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7846, "SUB_DISTRICT_CODE": "810701", "SUB_DISTRICT_NAME": "ลำทับ", "DISTRICT_ID": 870, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7847, "SUB_DISTRICT_CODE": "810702", "SUB_DISTRICT_NAME": "ดินอุดม", "DISTRICT_ID": 870, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7848, "SUB_DISTRICT_CODE": "810703", "SUB_DISTRICT_NAME": "ทุ่งไทรทอง", "DISTRICT_ID": 870, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7849, "SUB_DISTRICT_CODE": "810704", "SUB_DISTRICT_NAME": "ดินแดง", "DISTRICT_ID": 870, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7850, "SUB_DISTRICT_CODE": "810801", "SUB_DISTRICT_NAME": "เหนือคลอง", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7851, "SUB_DISTRICT_CODE": "810802", "SUB_DISTRICT_NAME": "เกาะศรีบอยา", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7852, "SUB_DISTRICT_CODE": "810803", "SUB_DISTRICT_NAME": "คลองขนาน", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7853, "SUB_DISTRICT_CODE": "810804", "SUB_DISTRICT_NAME": "คลองเขม้า", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7854, "SUB_DISTRICT_CODE": "810805", "SUB_DISTRICT_NAME": "โคกยาง", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7855, "SUB_DISTRICT_CODE": "810806", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7856, "SUB_DISTRICT_CODE": "810807", "SUB_DISTRICT_NAME": "ปกาสัย", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7857, "SUB_DISTRICT_CODE": "810808", "SUB_DISTRICT_NAME": "ห้วยยูง", "DISTRICT_ID": 871, "PROVINCE_ID": 64, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7858, "SUB_DISTRICT_CODE": "820101", "SUB_DISTRICT_NAME": "ท้ายช้าง", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7859, "SUB_DISTRICT_CODE": "820102", "SUB_DISTRICT_NAME": "นบปริง", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7860, "SUB_DISTRICT_CODE": "820103", "SUB_DISTRICT_NAME": "ถ้ำน้ำผุด", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7861, "SUB_DISTRICT_CODE": "820104", "SUB_DISTRICT_NAME": "บางเตย", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7862, "SUB_DISTRICT_CODE": "820105", "SUB_DISTRICT_NAME": "ตากแดด", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7863, "SUB_DISTRICT_CODE": "820106", "SUB_DISTRICT_NAME": "สองแพรก", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7864, "SUB_DISTRICT_CODE": "820107", "SUB_DISTRICT_NAME": "ทุ่งคาโงก", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7865, "SUB_DISTRICT_CODE": "820108", "SUB_DISTRICT_NAME": "เกาะปันหยี", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7866, "SUB_DISTRICT_CODE": "820109", "SUB_DISTRICT_NAME": "ป่ากอ", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7867, "SUB_DISTRICT_CODE": "820198", "SUB_DISTRICT_NAME": "*เกาะยาวใหญ่", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7868, "SUB_DISTRICT_CODE": "820199", "SUB_DISTRICT_NAME": "*เกาะยาวน้อย", "DISTRICT_ID": 872, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7869, "SUB_DISTRICT_CODE": "820201", "SUB_DISTRICT_NAME": "เกาะยาวน้อย", "DISTRICT_ID": 873, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7870, "SUB_DISTRICT_CODE": "820202", "SUB_DISTRICT_NAME": "เกาะยาวใหญ่", "DISTRICT_ID": 873, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7871, "SUB_DISTRICT_CODE": "820203", "SUB_DISTRICT_NAME": "พรุใน", "DISTRICT_ID": 873, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7872, "SUB_DISTRICT_CODE": "820301", "SUB_DISTRICT_NAME": "กะปง", "DISTRICT_ID": 874, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7873, "SUB_DISTRICT_CODE": "820302", "SUB_DISTRICT_NAME": "ท่านา", "DISTRICT_ID": 874, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7874, "SUB_DISTRICT_CODE": "820303", "SUB_DISTRICT_NAME": "เหมาะ", "DISTRICT_ID": 874, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7875, "SUB_DISTRICT_CODE": "820304", "SUB_DISTRICT_NAME": "เหล", "DISTRICT_ID": 874, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7876, "SUB_DISTRICT_CODE": "820305", "SUB_DISTRICT_NAME": "รมณีย์", "DISTRICT_ID": 874, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7877, "SUB_DISTRICT_CODE": "820401", "SUB_DISTRICT_NAME": "ถ้ำ", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7878, "SUB_DISTRICT_CODE": "820402", "SUB_DISTRICT_NAME": "กระโสม", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7879, "SUB_DISTRICT_CODE": "820403", "SUB_DISTRICT_NAME": "กะไหล", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7880, "SUB_DISTRICT_CODE": "820404", "SUB_DISTRICT_NAME": "ท่าอยู่", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7881, "SUB_DISTRICT_CODE": "820405", "SUB_DISTRICT_NAME": "หล่อยูง", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7882, "SUB_DISTRICT_CODE": "820406", "SUB_DISTRICT_NAME": "โคกกลอย", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7883, "SUB_DISTRICT_CODE": "820407", "SUB_DISTRICT_NAME": "คลองเคียน", "DISTRICT_ID": 875, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7884, "SUB_DISTRICT_CODE": "820501", "SUB_DISTRICT_NAME": "ตะกั่วป่า", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7885, "SUB_DISTRICT_CODE": "820502", "SUB_DISTRICT_NAME": "บางนายสี", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7886, "SUB_DISTRICT_CODE": "820503", "SUB_DISTRICT_NAME": "บางไทร", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7887, "SUB_DISTRICT_CODE": "820504", "SUB_DISTRICT_NAME": "บางม่วง", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7888, "SUB_DISTRICT_CODE": "820505", "SUB_DISTRICT_NAME": "ตำตัว", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7889, "SUB_DISTRICT_CODE": "820506", "SUB_DISTRICT_NAME": "โคกเคียน", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7890, "SUB_DISTRICT_CODE": "820507", "SUB_DISTRICT_NAME": "คึกคัก", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7891, "SUB_DISTRICT_CODE": "820508", "SUB_DISTRICT_NAME": "เกาะคอเขา", "DISTRICT_ID": 876, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7892, "SUB_DISTRICT_CODE": "820601", "SUB_DISTRICT_NAME": "คุระ", "DISTRICT_ID": 877, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7893, "SUB_DISTRICT_CODE": "820602", "SUB_DISTRICT_NAME": "บางวัน", "DISTRICT_ID": 877, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7894, "SUB_DISTRICT_CODE": "820603", "SUB_DISTRICT_NAME": "เกาะพระทอง", "DISTRICT_ID": 877, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7895, "SUB_DISTRICT_CODE": "820604", "SUB_DISTRICT_NAME": "*เกาะคอเขา", "DISTRICT_ID": 877, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7896, "SUB_DISTRICT_CODE": "820605", "SUB_DISTRICT_NAME": "แม่นางขาว", "DISTRICT_ID": 877, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7897, "SUB_DISTRICT_CODE": "820701", "SUB_DISTRICT_NAME": "ทับปุด", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7898, "SUB_DISTRICT_CODE": "820702", "SUB_DISTRICT_NAME": "มะรุ่ย", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7899, "SUB_DISTRICT_CODE": "820703", "SUB_DISTRICT_NAME": "บ่อแสน", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7900, "SUB_DISTRICT_CODE": "820704", "SUB_DISTRICT_NAME": "ถ้ำทองหลาง", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7901, "SUB_DISTRICT_CODE": "820705", "SUB_DISTRICT_NAME": "โคกเจริญ", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7902, "SUB_DISTRICT_CODE": "820706", "SUB_DISTRICT_NAME": "บางเหรียง", "DISTRICT_ID": 878, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7903, "SUB_DISTRICT_CODE": "820801", "SUB_DISTRICT_NAME": "ท้ายเหมือง", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7904, "SUB_DISTRICT_CODE": "820802", "SUB_DISTRICT_NAME": "นาเตย", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7905, "SUB_DISTRICT_CODE": "820803", "SUB_DISTRICT_NAME": "บางทอง", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7906, "SUB_DISTRICT_CODE": "820804", "SUB_DISTRICT_NAME": "ทุ่งมะพร้าว", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7907, "SUB_DISTRICT_CODE": "820805", "SUB_DISTRICT_NAME": "ลำภี", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7908, "SUB_DISTRICT_CODE": "820806", "SUB_DISTRICT_NAME": "ลำแก่น", "DISTRICT_ID": 879, "PROVINCE_ID": 65, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7909, "SUB_DISTRICT_CODE": "830101", "SUB_DISTRICT_NAME": "ตลาดใหญ่", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7910, "SUB_DISTRICT_CODE": "830102", "SUB_DISTRICT_NAME": "ตลาดเหนือ", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7911, "SUB_DISTRICT_CODE": "830103", "SUB_DISTRICT_NAME": "เกาะแก้ว", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7912, "SUB_DISTRICT_CODE": "830104", "SUB_DISTRICT_NAME": "รัษฎา", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7913, "SUB_DISTRICT_CODE": "830105", "SUB_DISTRICT_NAME": "วิชิต", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7914, "SUB_DISTRICT_CODE": "830106", "SUB_DISTRICT_NAME": "ฉลอง", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7915, "SUB_DISTRICT_CODE": "830107", "SUB_DISTRICT_NAME": "ราไวย์", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7916, "SUB_DISTRICT_CODE": "830108", "SUB_DISTRICT_NAME": "กะรน", "DISTRICT_ID": 880, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7917, "SUB_DISTRICT_CODE": "830201", "SUB_DISTRICT_NAME": "กะทู้", "DISTRICT_ID": 881, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7918, "SUB_DISTRICT_CODE": "830202", "SUB_DISTRICT_NAME": "ป่าตอง", "DISTRICT_ID": 881, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7919, "SUB_DISTRICT_CODE": "830203", "SUB_DISTRICT_NAME": "กมลา", "DISTRICT_ID": 881, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7920, "SUB_DISTRICT_CODE": "830301", "SUB_DISTRICT_NAME": "เทพกระษัตรี", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7921, "SUB_DISTRICT_CODE": "830302", "SUB_DISTRICT_NAME": "ศรีสุนทร", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7922, "SUB_DISTRICT_CODE": "830303", "SUB_DISTRICT_NAME": "เชิงทะเล", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7923, "SUB_DISTRICT_CODE": "830304", "SUB_DISTRICT_NAME": "ป่าคลอก", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7924, "SUB_DISTRICT_CODE": "830305", "SUB_DISTRICT_NAME": "ไม้ขาว", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7925, "SUB_DISTRICT_CODE": "830306", "SUB_DISTRICT_NAME": "สาคู", "DISTRICT_ID": 882, "PROVINCE_ID": 66, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7926, "SUB_DISTRICT_CODE": "840101", "SUB_DISTRICT_NAME": "ตลาด", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7927, "SUB_DISTRICT_CODE": "840102", "SUB_DISTRICT_NAME": "มะขามเตี้ย", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7928, "SUB_DISTRICT_CODE": "840103", "SUB_DISTRICT_NAME": "วัดประดู่", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7929, "SUB_DISTRICT_CODE": "840104", "SUB_DISTRICT_NAME": "ขุนทะเล", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7930, "SUB_DISTRICT_CODE": "840105", "SUB_DISTRICT_NAME": "บางใบไม้", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7931, "SUB_DISTRICT_CODE": "840106", "SUB_DISTRICT_NAME": "บางชนะ", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7932, "SUB_DISTRICT_CODE": "840107", "SUB_DISTRICT_NAME": "คลองน้อย", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7933, "SUB_DISTRICT_CODE": "840108", "SUB_DISTRICT_NAME": "บางไทร", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7934, "SUB_DISTRICT_CODE": "840109", "SUB_DISTRICT_NAME": "บางโพธิ์", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7935, "SUB_DISTRICT_CODE": "840110", "SUB_DISTRICT_NAME": "บางกุ้ง", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7936, "SUB_DISTRICT_CODE": "840111", "SUB_DISTRICT_NAME": "คลองฉนาก", "DISTRICT_ID": 884, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7937, "SUB_DISTRICT_CODE": "840201", "SUB_DISTRICT_NAME": "ท่าทองใหม่", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7938, "SUB_DISTRICT_CODE": "840202", "SUB_DISTRICT_NAME": "ท่าทอง", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7939, "SUB_DISTRICT_CODE": "840203", "SUB_DISTRICT_NAME": "กะแดะ", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7940, "SUB_DISTRICT_CODE": "840204", "SUB_DISTRICT_NAME": "ทุ่งกง", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7941, "SUB_DISTRICT_CODE": "840205", "SUB_DISTRICT_NAME": "กรูด", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7942, "SUB_DISTRICT_CODE": "840206", "SUB_DISTRICT_NAME": "ช้างซ้าย", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7943, "SUB_DISTRICT_CODE": "840207", "SUB_DISTRICT_NAME": "พลายวาส", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7944, "SUB_DISTRICT_CODE": "840208", "SUB_DISTRICT_NAME": "ป่าร่อน", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7945, "SUB_DISTRICT_CODE": "840209", "SUB_DISTRICT_NAME": "ตะเคียนทอง", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7946, "SUB_DISTRICT_CODE": "840210", "SUB_DISTRICT_NAME": "ช้างขวา", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7947, "SUB_DISTRICT_CODE": "840211", "SUB_DISTRICT_NAME": "ท่าอุแท", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7948, "SUB_DISTRICT_CODE": "840212", "SUB_DISTRICT_NAME": "ทุ่งรัง", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7949, "SUB_DISTRICT_CODE": "840213", "SUB_DISTRICT_NAME": "คลองสระ", "DISTRICT_ID": 885, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7950, "SUB_DISTRICT_CODE": "840301", "SUB_DISTRICT_NAME": "ดอนสัก", "DISTRICT_ID": 886, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7951, "SUB_DISTRICT_CODE": "840302", "SUB_DISTRICT_NAME": "ชลคราม", "DISTRICT_ID": 886, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7952, "SUB_DISTRICT_CODE": "840303", "SUB_DISTRICT_NAME": "ไชยคราม", "DISTRICT_ID": 886, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7953, "SUB_DISTRICT_CODE": "840304", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 886, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7954, "SUB_DISTRICT_CODE": "840401", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7955, "SUB_DISTRICT_CODE": "840402", "SUB_DISTRICT_NAME": "ลิปะน้อย", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7956, "SUB_DISTRICT_CODE": "840403", "SUB_DISTRICT_NAME": "ตลิ่งงาม", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7957, "SUB_DISTRICT_CODE": "840404", "SUB_DISTRICT_NAME": "หน้าเมือง", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7958, "SUB_DISTRICT_CODE": "840405", "SUB_DISTRICT_NAME": "มะเร็ต", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7959, "SUB_DISTRICT_CODE": "840406", "SUB_DISTRICT_NAME": "บ่อผุด", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7960, "SUB_DISTRICT_CODE": "840407", "SUB_DISTRICT_NAME": "แม่น้ำ", "DISTRICT_ID": 887, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7961, "SUB_DISTRICT_CODE": "840501", "SUB_DISTRICT_NAME": "เกาะพะงัน", "DISTRICT_ID": 888, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7962, "SUB_DISTRICT_CODE": "840502", "SUB_DISTRICT_NAME": "บ้านใต้", "DISTRICT_ID": 888, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7963, "SUB_DISTRICT_CODE": "840503", "SUB_DISTRICT_NAME": "เกาะเต่า", "DISTRICT_ID": 888, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7964, "SUB_DISTRICT_CODE": "840601", "SUB_DISTRICT_NAME": "ตลาดไชยา", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7965, "SUB_DISTRICT_CODE": "840602", "SUB_DISTRICT_NAME": "พุมเรียง", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7966, "SUB_DISTRICT_CODE": "840603", "SUB_DISTRICT_NAME": "เลม็ด", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7967, "SUB_DISTRICT_CODE": "840604", "SUB_DISTRICT_NAME": "เวียง", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7968, "SUB_DISTRICT_CODE": "840605", "SUB_DISTRICT_NAME": "ทุ่ง", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7969, "SUB_DISTRICT_CODE": "840606", "SUB_DISTRICT_NAME": "ป่าเว", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7970, "SUB_DISTRICT_CODE": "840607", "SUB_DISTRICT_NAME": "ตะกรบ", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7971, "SUB_DISTRICT_CODE": "840608", "SUB_DISTRICT_NAME": "โมถ่าย", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7972, "SUB_DISTRICT_CODE": "840609", "SUB_DISTRICT_NAME": "ปากหมาก", "DISTRICT_ID": 889, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7973, "SUB_DISTRICT_CODE": "840701", "SUB_DISTRICT_NAME": "ท่าชนะ", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7974, "SUB_DISTRICT_CODE": "840702", "SUB_DISTRICT_NAME": "สมอทอง", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7975, "SUB_DISTRICT_CODE": "840703", "SUB_DISTRICT_NAME": "ประสงค์", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7976, "SUB_DISTRICT_CODE": "840704", "SUB_DISTRICT_NAME": "คันธุลี", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7977, "SUB_DISTRICT_CODE": "840705", "SUB_DISTRICT_NAME": "วัง", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7978, "SUB_DISTRICT_CODE": "840706", "SUB_DISTRICT_NAME": "คลองพา", "DISTRICT_ID": 890, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7979, "SUB_DISTRICT_CODE": "840801", "SUB_DISTRICT_NAME": "ท่าขนอน", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7980, "SUB_DISTRICT_CODE": "840802", "SUB_DISTRICT_NAME": "บ้านยาง", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7981, "SUB_DISTRICT_CODE": "840803", "SUB_DISTRICT_NAME": "น้ำหัก", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7982, "SUB_DISTRICT_CODE": "840804", "SUB_DISTRICT_NAME": "*ตะกุกใต้", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7983, "SUB_DISTRICT_CODE": "840805", "SUB_DISTRICT_NAME": "*ตะกุกเหนือ", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7984, "SUB_DISTRICT_CODE": "840806", "SUB_DISTRICT_NAME": "กะเปา", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7985, "SUB_DISTRICT_CODE": "840807", "SUB_DISTRICT_NAME": "ท่ากระดาน", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7986, "SUB_DISTRICT_CODE": "840808", "SUB_DISTRICT_NAME": "ย่านยาว", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7987, "SUB_DISTRICT_CODE": "840809", "SUB_DISTRICT_NAME": "ถ้ำสิงขร", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7988, "SUB_DISTRICT_CODE": "840810", "SUB_DISTRICT_NAME": "บ้านทำเนียบ", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7989, "SUB_DISTRICT_CODE": "840899", "SUB_DISTRICT_NAME": "*ตะกุดใต้", "DISTRICT_ID": 891, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7990, "SUB_DISTRICT_CODE": "840901", "SUB_DISTRICT_NAME": "เขาวง", "DISTRICT_ID": 892, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7991, "SUB_DISTRICT_CODE": "840902", "SUB_DISTRICT_NAME": "พะแสง", "DISTRICT_ID": 892, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7992, "SUB_DISTRICT_CODE": "840903", "SUB_DISTRICT_NAME": "พรุไทย", "DISTRICT_ID": 892, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7993, "SUB_DISTRICT_CODE": "840904", "SUB_DISTRICT_NAME": "เขาพัง", "DISTRICT_ID": 892, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7994, "SUB_DISTRICT_CODE": "840905", "SUB_DISTRICT_NAME": "*ไกรสร", "DISTRICT_ID": 892, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7995, "SUB_DISTRICT_CODE": "841001", "SUB_DISTRICT_NAME": "พนม", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7996, "SUB_DISTRICT_CODE": "841002", "SUB_DISTRICT_NAME": "ต้นยวน", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7997, "SUB_DISTRICT_CODE": "841003", "SUB_DISTRICT_NAME": "คลองศก", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7998, "SUB_DISTRICT_CODE": "841004", "SUB_DISTRICT_NAME": "พลูเถื่อน", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 7999, "SUB_DISTRICT_CODE": "841005", "SUB_DISTRICT_NAME": "พังกาญจน์", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8000, "SUB_DISTRICT_CODE": "841006", "SUB_DISTRICT_NAME": "คลองชะอุ่น", "DISTRICT_ID": 893, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8001, "SUB_DISTRICT_CODE": "841101", "SUB_DISTRICT_NAME": "ท่าฉาง", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8002, "SUB_DISTRICT_CODE": "841102", "SUB_DISTRICT_NAME": "ท่าเคย", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8003, "SUB_DISTRICT_CODE": "841103", "SUB_DISTRICT_NAME": "คลองไทร", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8004, "SUB_DISTRICT_CODE": "841104", "SUB_DISTRICT_NAME": "เขาถ่าน", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8005, "SUB_DISTRICT_CODE": "841105", "SUB_DISTRICT_NAME": "เสวียด", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8006, "SUB_DISTRICT_CODE": "841106", "SUB_DISTRICT_NAME": "ปากฉลุย", "DISTRICT_ID": 894, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8007, "SUB_DISTRICT_CODE": "841201", "SUB_DISTRICT_NAME": "นาสาร", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8008, "SUB_DISTRICT_CODE": "841202", "SUB_DISTRICT_NAME": "พรุพี", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8009, "SUB_DISTRICT_CODE": "841203", "SUB_DISTRICT_NAME": "ทุ่งเตา", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8010, "SUB_DISTRICT_CODE": "841204", "SUB_DISTRICT_NAME": "ลำพูน", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8011, "SUB_DISTRICT_CODE": "841205", "SUB_DISTRICT_NAME": "ท่าชี", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8012, "SUB_DISTRICT_CODE": "841206", "SUB_DISTRICT_NAME": "ควนศรี", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8013, "SUB_DISTRICT_CODE": "841207", "SUB_DISTRICT_NAME": "ควนสุบรรณ", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8014, "SUB_DISTRICT_CODE": "841208", "SUB_DISTRICT_NAME": "คลองปราบ", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8015, "SUB_DISTRICT_CODE": "841209", "SUB_DISTRICT_NAME": "น้ำพุ", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8016, "SUB_DISTRICT_CODE": "841210", "SUB_DISTRICT_NAME": "ทุ่งเตาใหม่", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8017, "SUB_DISTRICT_CODE": "841211", "SUB_DISTRICT_NAME": "เพิ่มพูนทรัพย์", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8018, "SUB_DISTRICT_CODE": "841298", "SUB_DISTRICT_NAME": "*ท่าเรือ", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8019, "SUB_DISTRICT_CODE": "841299", "SUB_DISTRICT_NAME": "*บ้านนา", "DISTRICT_ID": 895, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8020, "SUB_DISTRICT_CODE": "841301", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 896, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8021, "SUB_DISTRICT_CODE": "841302", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 896, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8022, "SUB_DISTRICT_CODE": "841303", "SUB_DISTRICT_NAME": "ทรัพย์ทวี", "DISTRICT_ID": 896, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8023, "SUB_DISTRICT_CODE": "841304", "SUB_DISTRICT_NAME": "นาใต้", "DISTRICT_ID": 896, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8024, "SUB_DISTRICT_CODE": "841401", "SUB_DISTRICT_NAME": "เคียนซา", "DISTRICT_ID": 897, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8025, "SUB_DISTRICT_CODE": "841402", "SUB_DISTRICT_NAME": "พ่วงพรมคร", "DISTRICT_ID": 897, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8026, "SUB_DISTRICT_CODE": "841403", "SUB_DISTRICT_NAME": "เขาตอก", "DISTRICT_ID": 897, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8027, "SUB_DISTRICT_CODE": "841404", "SUB_DISTRICT_NAME": "อรัญคามวารี", "DISTRICT_ID": 897, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8028, "SUB_DISTRICT_CODE": "841405", "SUB_DISTRICT_NAME": "บ้านเสด็จ", "DISTRICT_ID": 897, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8029, "SUB_DISTRICT_CODE": "841501", "SUB_DISTRICT_NAME": "เวียงสระ", "DISTRICT_ID": 898, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8030, "SUB_DISTRICT_CODE": "841502", "SUB_DISTRICT_NAME": "บ้านส้อง", "DISTRICT_ID": 898, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8031, "SUB_DISTRICT_CODE": "841503", "SUB_DISTRICT_NAME": "คลองฉนวน", "DISTRICT_ID": 898, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8032, "SUB_DISTRICT_CODE": "841504", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 898, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8033, "SUB_DISTRICT_CODE": "841505", "SUB_DISTRICT_NAME": "เขานิพันธ์", "DISTRICT_ID": 898, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8034, "SUB_DISTRICT_CODE": "841601", "SUB_DISTRICT_NAME": "อิปัน", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8035, "SUB_DISTRICT_CODE": "841602", "SUB_DISTRICT_NAME": "สินปุน", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8036, "SUB_DISTRICT_CODE": "841603", "SUB_DISTRICT_NAME": "บางสวรรค์", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8037, "SUB_DISTRICT_CODE": "841604", "SUB_DISTRICT_NAME": "ไทรขึง", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8038, "SUB_DISTRICT_CODE": "841605", "SUB_DISTRICT_NAME": "สินเจริญ", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8039, "SUB_DISTRICT_CODE": "841606", "SUB_DISTRICT_NAME": "ไทรโสภา", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8040, "SUB_DISTRICT_CODE": "841607", "SUB_DISTRICT_NAME": "สาคู", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8041, "SUB_DISTRICT_CODE": "841698", "SUB_DISTRICT_NAME": "*ชัยบุรี", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8042, "SUB_DISTRICT_CODE": "841699", "SUB_DISTRICT_NAME": "*สองแพรก", "DISTRICT_ID": 899, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8043, "SUB_DISTRICT_CODE": "841701", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8044, "SUB_DISTRICT_CODE": "841702", "SUB_DISTRICT_NAME": "ท่าสะท้อน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8045, "SUB_DISTRICT_CODE": "841703", "SUB_DISTRICT_NAME": "ลีเล็ด", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8046, "SUB_DISTRICT_CODE": "841704", "SUB_DISTRICT_NAME": "บางมะเดื่อ", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8047, "SUB_DISTRICT_CODE": "841705", "SUB_DISTRICT_NAME": "บางเดือน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8048, "SUB_DISTRICT_CODE": "841706", "SUB_DISTRICT_NAME": "ท่าโรงช้าง", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8049, "SUB_DISTRICT_CODE": "841707", "SUB_DISTRICT_NAME": "กรูด", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8050, "SUB_DISTRICT_CODE": "841708", "SUB_DISTRICT_NAME": "พุนพิน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8051, "SUB_DISTRICT_CODE": "841709", "SUB_DISTRICT_NAME": "บางงอน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8052, "SUB_DISTRICT_CODE": "841710", "SUB_DISTRICT_NAME": "ศรีวิชัย", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8053, "SUB_DISTRICT_CODE": "841711", "SUB_DISTRICT_NAME": "น้ำรอบ", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8054, "SUB_DISTRICT_CODE": "841712", "SUB_DISTRICT_NAME": "มะลวน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8055, "SUB_DISTRICT_CODE": "841713", "SUB_DISTRICT_NAME": "หัวเตย", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8056, "SUB_DISTRICT_CODE": "841714", "SUB_DISTRICT_NAME": "หนองไทร", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8057, "SUB_DISTRICT_CODE": "841715", "SUB_DISTRICT_NAME": "เขาหัวควาย", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8058, "SUB_DISTRICT_CODE": "841716", "SUB_DISTRICT_NAME": "ตะปาน", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8059, "SUB_DISTRICT_CODE": "841799", "SUB_DISTRICT_NAME": "*คลองไทร", "DISTRICT_ID": 900, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8060, "SUB_DISTRICT_CODE": "841801", "SUB_DISTRICT_NAME": "สองแพรก", "DISTRICT_ID": 901, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8061, "SUB_DISTRICT_CODE": "841802", "SUB_DISTRICT_NAME": "ชัยบุรี", "DISTRICT_ID": 901, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8062, "SUB_DISTRICT_CODE": "841803", "SUB_DISTRICT_NAME": "คลองน้อย", "DISTRICT_ID": 901, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8063, "SUB_DISTRICT_CODE": "841804", "SUB_DISTRICT_NAME": "ไทรทอง", "DISTRICT_ID": 901, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8064, "SUB_DISTRICT_CODE": "841901", "SUB_DISTRICT_NAME": "ตะกุกใต้", "DISTRICT_ID": 902, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8065, "SUB_DISTRICT_CODE": "841902", "SUB_DISTRICT_NAME": "ตะกุกเหนือ", "DISTRICT_ID": 902, "PROVINCE_ID": 67, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8066, "SUB_DISTRICT_CODE": "850101", "SUB_DISTRICT_NAME": "เขานิเวศน์", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8067, "SUB_DISTRICT_CODE": "850102", "SUB_DISTRICT_NAME": "ราชกรูด", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8068, "SUB_DISTRICT_CODE": "850103", "SUB_DISTRICT_NAME": "หงาว", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8069, "SUB_DISTRICT_CODE": "850104", "SUB_DISTRICT_NAME": "บางริ้น", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8070, "SUB_DISTRICT_CODE": "850105", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8071, "SUB_DISTRICT_CODE": "850106", "SUB_DISTRICT_NAME": "บางนอน", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8072, "SUB_DISTRICT_CODE": "850107", "SUB_DISTRICT_NAME": "หาดส้มแป้น", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8073, "SUB_DISTRICT_CODE": "850108", "SUB_DISTRICT_NAME": "ทรายแดง", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8074, "SUB_DISTRICT_CODE": "850109", "SUB_DISTRICT_NAME": "เกาะพยาม", "DISTRICT_ID": 905, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8075, "SUB_DISTRICT_CODE": "850201", "SUB_DISTRICT_NAME": "ละอุ่นใต้", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8076, "SUB_DISTRICT_CODE": "850202", "SUB_DISTRICT_NAME": "ละอุ่นเหนือ", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8077, "SUB_DISTRICT_CODE": "850203", "SUB_DISTRICT_NAME": "บางพระใต้", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8078, "SUB_DISTRICT_CODE": "850204", "SUB_DISTRICT_NAME": "บางพระเหนือ", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8079, "SUB_DISTRICT_CODE": "850205", "SUB_DISTRICT_NAME": "บางแก้ว", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8080, "SUB_DISTRICT_CODE": "850206", "SUB_DISTRICT_NAME": "ในวงเหนือ", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8081, "SUB_DISTRICT_CODE": "850207", "SUB_DISTRICT_NAME": "ในวงใต้", "DISTRICT_ID": 906, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8082, "SUB_DISTRICT_CODE": "850301", "SUB_DISTRICT_NAME": "ม่วงกลวง", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8083, "SUB_DISTRICT_CODE": "850302", "SUB_DISTRICT_NAME": "กะเปอร์", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8084, "SUB_DISTRICT_CODE": "850303", "SUB_DISTRICT_NAME": "เชี่ยวเหลียง", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8085, "SUB_DISTRICT_CODE": "850304", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8086, "SUB_DISTRICT_CODE": "850305", "SUB_DISTRICT_NAME": "บางหิน", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8087, "SUB_DISTRICT_CODE": "850306", "SUB_DISTRICT_NAME": "*นาคา", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8088, "SUB_DISTRICT_CODE": "850307", "SUB_DISTRICT_NAME": "*กำพวน", "DISTRICT_ID": 907, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8089, "SUB_DISTRICT_CODE": "850401", "SUB_DISTRICT_NAME": "น้ำจืด", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8090, "SUB_DISTRICT_CODE": "850402", "SUB_DISTRICT_NAME": "น้ำจืดน้อย", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8091, "SUB_DISTRICT_CODE": "850403", "SUB_DISTRICT_NAME": "มะมุ", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8092, "SUB_DISTRICT_CODE": "850404", "SUB_DISTRICT_NAME": "ปากจั่น", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8093, "SUB_DISTRICT_CODE": "850405", "SUB_DISTRICT_NAME": "ลำเลียง", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8094, "SUB_DISTRICT_CODE": "850406", "SUB_DISTRICT_NAME": "จ.ป.ร.", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8095, "SUB_DISTRICT_CODE": "850407", "SUB_DISTRICT_NAME": "บางใหญ่", "DISTRICT_ID": 908, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8096, "SUB_DISTRICT_CODE": "850501", "SUB_DISTRICT_NAME": "นาคา", "DISTRICT_ID": 909, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8097, "SUB_DISTRICT_CODE": "850502", "SUB_DISTRICT_NAME": "กำพวน", "DISTRICT_ID": 909, "PROVINCE_ID": 68, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8098, "SUB_DISTRICT_CODE": "860101", "SUB_DISTRICT_NAME": "ท่าตะเภา", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8099, "SUB_DISTRICT_CODE": "860102", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8100, "SUB_DISTRICT_CODE": "860103", "SUB_DISTRICT_NAME": "ท่ายาง", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8101, "SUB_DISTRICT_CODE": "860104", "SUB_DISTRICT_NAME": "บางหมาก", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8102, "SUB_DISTRICT_CODE": "860105", "SUB_DISTRICT_NAME": "นาทุ่ง", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8103, "SUB_DISTRICT_CODE": "860106", "SUB_DISTRICT_NAME": "นาชะอัง", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8104, "SUB_DISTRICT_CODE": "860107", "SUB_DISTRICT_NAME": "ตากแดด", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8105, "SUB_DISTRICT_CODE": "860108", "SUB_DISTRICT_NAME": "บางลึก", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8106, "SUB_DISTRICT_CODE": "860109", "SUB_DISTRICT_NAME": "หาดพันไกร", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8107, "SUB_DISTRICT_CODE": "860110", "SUB_DISTRICT_NAME": "วังไผ่", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8108, "SUB_DISTRICT_CODE": "860111", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8109, "SUB_DISTRICT_CODE": "860112", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8110, "SUB_DISTRICT_CODE": "860113", "SUB_DISTRICT_NAME": "ขุนกระทิง", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8111, "SUB_DISTRICT_CODE": "860114", "SUB_DISTRICT_NAME": "ทุ่งคา", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8112, "SUB_DISTRICT_CODE": "860115", "SUB_DISTRICT_NAME": "วิสัยเหนือ", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8113, "SUB_DISTRICT_CODE": "860116", "SUB_DISTRICT_NAME": "หาดทรายรี", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8114, "SUB_DISTRICT_CODE": "860117", "SUB_DISTRICT_NAME": "ถ้ำสิงห์", "DISTRICT_ID": 910, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8115, "SUB_DISTRICT_CODE": "860201", "SUB_DISTRICT_NAME": "ท่าแซะ", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8116, "SUB_DISTRICT_CODE": "860202", "SUB_DISTRICT_NAME": "คุริง", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8117, "SUB_DISTRICT_CODE": "860203", "SUB_DISTRICT_NAME": "สลุย", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8118, "SUB_DISTRICT_CODE": "860204", "SUB_DISTRICT_NAME": "นากระตาม", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8119, "SUB_DISTRICT_CODE": "860205", "SUB_DISTRICT_NAME": "รับร่อ", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8120, "SUB_DISTRICT_CODE": "860206", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8121, "SUB_DISTRICT_CODE": "860207", "SUB_DISTRICT_NAME": "หงษ์เจริญ", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8122, "SUB_DISTRICT_CODE": "860208", "SUB_DISTRICT_NAME": "หินแก้ว", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8123, "SUB_DISTRICT_CODE": "860209", "SUB_DISTRICT_NAME": "ทรัพย์อนันต์", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8124, "SUB_DISTRICT_CODE": "860210", "SUB_DISTRICT_NAME": "สองพี่น้อง", "DISTRICT_ID": 911, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8125, "SUB_DISTRICT_CODE": "860301", "SUB_DISTRICT_NAME": "บางสน", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8126, "SUB_DISTRICT_CODE": "860302", "SUB_DISTRICT_NAME": "ทะเลทรัพย์", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8127, "SUB_DISTRICT_CODE": "860303", "SUB_DISTRICT_NAME": "สะพลี", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8128, "SUB_DISTRICT_CODE": "860304", "SUB_DISTRICT_NAME": "ชุมโค", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8129, "SUB_DISTRICT_CODE": "860305", "SUB_DISTRICT_NAME": "ดอนยาง", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8130, "SUB_DISTRICT_CODE": "860306", "SUB_DISTRICT_NAME": "ปากคลอง", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8131, "SUB_DISTRICT_CODE": "860307", "SUB_DISTRICT_NAME": "เขาไชยราช", "DISTRICT_ID": 912, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8132, "SUB_DISTRICT_CODE": "860401", "SUB_DISTRICT_NAME": "หลังสวน", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8133, "SUB_DISTRICT_CODE": "860402", "SUB_DISTRICT_NAME": "ขันเงิน", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8134, "SUB_DISTRICT_CODE": "860403", "SUB_DISTRICT_NAME": "ท่ามะพลา", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8135, "SUB_DISTRICT_CODE": "860404", "SUB_DISTRICT_NAME": "นาขา", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8136, "SUB_DISTRICT_CODE": "860405", "SUB_DISTRICT_NAME": "นาพญา", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8137, "SUB_DISTRICT_CODE": "860406", "SUB_DISTRICT_NAME": "บ้านควน", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8138, "SUB_DISTRICT_CODE": "860407", "SUB_DISTRICT_NAME": "บางมะพร้าว", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8139, "SUB_DISTRICT_CODE": "860408", "SUB_DISTRICT_NAME": "บางน้ำจืด", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8140, "SUB_DISTRICT_CODE": "860409", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8141, "SUB_DISTRICT_CODE": "860410", "SUB_DISTRICT_NAME": "พ้อแดง", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8142, "SUB_DISTRICT_CODE": "860411", "SUB_DISTRICT_NAME": "แหลมทราย", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8143, "SUB_DISTRICT_CODE": "860412", "SUB_DISTRICT_NAME": "วังตะกอ", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8144, "SUB_DISTRICT_CODE": "860413", "SUB_DISTRICT_NAME": "หาดยาย", "DISTRICT_ID": 913, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8145, "SUB_DISTRICT_CODE": "860501", "SUB_DISTRICT_NAME": "ละแม", "DISTRICT_ID": 914, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8146, "SUB_DISTRICT_CODE": "860502", "SUB_DISTRICT_NAME": "ทุ่งหลวง", "DISTRICT_ID": 914, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8147, "SUB_DISTRICT_CODE": "860503", "SUB_DISTRICT_NAME": "สวนแตง", "DISTRICT_ID": 914, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8148, "SUB_DISTRICT_CODE": "860504", "SUB_DISTRICT_NAME": "ทุ่งคาวัด", "DISTRICT_ID": 914, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8149, "SUB_DISTRICT_CODE": "860601", "SUB_DISTRICT_NAME": "พะโต๊ะ", "DISTRICT_ID": 915, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8150, "SUB_DISTRICT_CODE": "860602", "SUB_DISTRICT_NAME": "ปากทรง", "DISTRICT_ID": 915, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8151, "SUB_DISTRICT_CODE": "860603", "SUB_DISTRICT_NAME": "ปังหวาน", "DISTRICT_ID": 915, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8152, "SUB_DISTRICT_CODE": "860604", "SUB_DISTRICT_NAME": "พระรักษ์", "DISTRICT_ID": 915, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8153, "SUB_DISTRICT_CODE": "860701", "SUB_DISTRICT_NAME": "นาโพธิ์", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8154, "SUB_DISTRICT_CODE": "860702", "SUB_DISTRICT_NAME": "สวี", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8155, "SUB_DISTRICT_CODE": "860703", "SUB_DISTRICT_NAME": "ทุ่งระยะ", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8156, "SUB_DISTRICT_CODE": "860704", "SUB_DISTRICT_NAME": "ท่าหิน", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8157, "SUB_DISTRICT_CODE": "860705", "SUB_DISTRICT_NAME": "ปากแพรก", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8158, "SUB_DISTRICT_CODE": "860706", "SUB_DISTRICT_NAME": "ด่านสวี", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8159, "SUB_DISTRICT_CODE": "860707", "SUB_DISTRICT_NAME": "ครน", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8160, "SUB_DISTRICT_CODE": "860708", "SUB_DISTRICT_NAME": "วิสัยใต้", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8161, "SUB_DISTRICT_CODE": "860709", "SUB_DISTRICT_NAME": "นาสัก", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8162, "SUB_DISTRICT_CODE": "860710", "SUB_DISTRICT_NAME": "เขาทะลุ", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8163, "SUB_DISTRICT_CODE": "860711", "SUB_DISTRICT_NAME": "เขาค่าย", "DISTRICT_ID": 916, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8164, "SUB_DISTRICT_CODE": "860801", "SUB_DISTRICT_NAME": "ปากตะโก", "DISTRICT_ID": 917, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8165, "SUB_DISTRICT_CODE": "860802", "SUB_DISTRICT_NAME": "ทุ่งตะไคร", "DISTRICT_ID": 917, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8166, "SUB_DISTRICT_CODE": "860803", "SUB_DISTRICT_NAME": "ตะโก", "DISTRICT_ID": 917, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8167, "SUB_DISTRICT_CODE": "860804", "SUB_DISTRICT_NAME": "ช่องไม้แก้ว", "DISTRICT_ID": 917, "PROVINCE_ID": 69, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8168, "SUB_DISTRICT_CODE": "900101", "SUB_DISTRICT_NAME": "บ่อยาง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8169, "SUB_DISTRICT_CODE": "900102", "SUB_DISTRICT_NAME": "เขารูปช้าง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8170, "SUB_DISTRICT_CODE": "900103", "SUB_DISTRICT_NAME": "เกาะแต้ว", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8171, "SUB_DISTRICT_CODE": "900104", "SUB_DISTRICT_NAME": "พะวง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8172, "SUB_DISTRICT_CODE": "900105", "SUB_DISTRICT_NAME": "ทุ่งหวัง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8173, "SUB_DISTRICT_CODE": "900106", "SUB_DISTRICT_NAME": "เกาะยอ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8174, "SUB_DISTRICT_CODE": "900107", "SUB_DISTRICT_NAME": "*ชิงโค", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8175, "SUB_DISTRICT_CODE": "900108", "SUB_DISTRICT_NAME": "*สทิงหม้อ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8176, "SUB_DISTRICT_CODE": "900109", "SUB_DISTRICT_NAME": "*ทำนบ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8177, "SUB_DISTRICT_CODE": "900110", "SUB_DISTRICT_NAME": "*รำแดง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8178, "SUB_DISTRICT_CODE": "900111", "SUB_DISTRICT_NAME": "*วัดขนุน", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8179, "SUB_DISTRICT_CODE": "900112", "SUB_DISTRICT_NAME": "*ชะแล้", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8180, "SUB_DISTRICT_CODE": "900113", "SUB_DISTRICT_NAME": "*ปากรอ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8181, "SUB_DISTRICT_CODE": "900114", "SUB_DISTRICT_NAME": "*ป่าขาด", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8182, "SUB_DISTRICT_CODE": "900115", "SUB_DISTRICT_NAME": "*หัวเขา", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8183, "SUB_DISTRICT_CODE": "900116", "SUB_DISTRICT_NAME": "*บางเขียด", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8184, "SUB_DISTRICT_CODE": "900117", "SUB_DISTRICT_NAME": "*ม่วงงาม", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8185, "SUB_DISTRICT_CODE": "900188", "SUB_DISTRICT_NAME": "*ปากรอ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8186, "SUB_DISTRICT_CODE": "900189", "SUB_DISTRICT_NAME": "*ทำนบ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8187, "SUB_DISTRICT_CODE": "900190", "SUB_DISTRICT_NAME": "*ชลเจริญ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8188, "SUB_DISTRICT_CODE": "900191", "SUB_DISTRICT_NAME": "*ม่วงงาม", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8189, "SUB_DISTRICT_CODE": "900192", "SUB_DISTRICT_NAME": "*หัวเขา", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8190, "SUB_DISTRICT_CODE": "900193", "SUB_DISTRICT_NAME": "*ชะแล้", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8191, "SUB_DISTRICT_CODE": "900194", "SUB_DISTRICT_NAME": "*วัดขนุน", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8192, "SUB_DISTRICT_CODE": "900195", "SUB_DISTRICT_NAME": "*สทิงหม้อ", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8193, "SUB_DISTRICT_CODE": "900196", "SUB_DISTRICT_NAME": "*บางเขียด", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8194, "SUB_DISTRICT_CODE": "900197", "SUB_DISTRICT_NAME": "*ป่าขาด", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8195, "SUB_DISTRICT_CODE": "900198", "SUB_DISTRICT_NAME": "*รำแดง", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8196, "SUB_DISTRICT_CODE": "900199", "SUB_DISTRICT_NAME": "*ชิงโค", "DISTRICT_ID": 918, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8197, "SUB_DISTRICT_CODE": "900201", "SUB_DISTRICT_NAME": "จะทิ้งพระ", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8198, "SUB_DISTRICT_CODE": "900202", "SUB_DISTRICT_NAME": "กระดังงา", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8199, "SUB_DISTRICT_CODE": "900203", "SUB_DISTRICT_NAME": "สนามชัย", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8200, "SUB_DISTRICT_CODE": "900204", "SUB_DISTRICT_NAME": "ดีหลวง", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8201, "SUB_DISTRICT_CODE": "900205", "SUB_DISTRICT_NAME": "ชุมพล", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8202, "SUB_DISTRICT_CODE": "900206", "SUB_DISTRICT_NAME": "คลองรี", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8203, "SUB_DISTRICT_CODE": "900207", "SUB_DISTRICT_NAME": "คูขุด", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8204, "SUB_DISTRICT_CODE": "900208", "SUB_DISTRICT_NAME": "ท่าหิน", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8205, "SUB_DISTRICT_CODE": "900209", "SUB_DISTRICT_NAME": "วัดจันทร์", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8206, "SUB_DISTRICT_CODE": "900210", "SUB_DISTRICT_NAME": "บ่อแดง", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8207, "SUB_DISTRICT_CODE": "900211", "SUB_DISTRICT_NAME": "บ่อดาน", "DISTRICT_ID": 919, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8208, "SUB_DISTRICT_CODE": "900301", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8209, "SUB_DISTRICT_CODE": "900302", "SUB_DISTRICT_NAME": "ป่าชิง", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8210, "SUB_DISTRICT_CODE": "900303", "SUB_DISTRICT_NAME": "สะพานไม้แก่น", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8211, "SUB_DISTRICT_CODE": "900304", "SUB_DISTRICT_NAME": "สะกอม", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8212, "SUB_DISTRICT_CODE": "900305", "SUB_DISTRICT_NAME": "นาหว้า", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8213, "SUB_DISTRICT_CODE": "900306", "SUB_DISTRICT_NAME": "นาทับ", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8214, "SUB_DISTRICT_CODE": "900307", "SUB_DISTRICT_NAME": "น้ำขาว", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8215, "SUB_DISTRICT_CODE": "900308", "SUB_DISTRICT_NAME": "ขุนตัดหวาย", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8216, "SUB_DISTRICT_CODE": "900309", "SUB_DISTRICT_NAME": "ท่าหมอไทร", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8217, "SUB_DISTRICT_CODE": "900310", "SUB_DISTRICT_NAME": "จะโหนง", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8218, "SUB_DISTRICT_CODE": "900311", "SUB_DISTRICT_NAME": "คู", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8219, "SUB_DISTRICT_CODE": "900312", "SUB_DISTRICT_NAME": "แค", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8220, "SUB_DISTRICT_CODE": "900313", "SUB_DISTRICT_NAME": "คลองเปียะ", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8221, "SUB_DISTRICT_CODE": "900314", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 920, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8222, "SUB_DISTRICT_CODE": "900401", "SUB_DISTRICT_NAME": "นาทวี", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8223, "SUB_DISTRICT_CODE": "900402", "SUB_DISTRICT_NAME": "ฉาง", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8224, "SUB_DISTRICT_CODE": "900403", "SUB_DISTRICT_NAME": "นาหมอศรี", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8225, "SUB_DISTRICT_CODE": "900404", "SUB_DISTRICT_NAME": "คลองทราย", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8226, "SUB_DISTRICT_CODE": "900405", "SUB_DISTRICT_NAME": "ปลักหนู", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8227, "SUB_DISTRICT_CODE": "900406", "SUB_DISTRICT_NAME": "ท่าประดู่", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8228, "SUB_DISTRICT_CODE": "900407", "SUB_DISTRICT_NAME": "สะท้อน", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8229, "SUB_DISTRICT_CODE": "900408", "SUB_DISTRICT_NAME": "ทับช้าง", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8230, "SUB_DISTRICT_CODE": "900409", "SUB_DISTRICT_NAME": "ประกอบ", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8231, "SUB_DISTRICT_CODE": "900410", "SUB_DISTRICT_NAME": "คลองกวาง", "DISTRICT_ID": 921, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8232, "SUB_DISTRICT_CODE": "900501", "SUB_DISTRICT_NAME": "เทพา", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8233, "SUB_DISTRICT_CODE": "900502", "SUB_DISTRICT_NAME": "ปากบาง", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8234, "SUB_DISTRICT_CODE": "900503", "SUB_DISTRICT_NAME": "เกาะสะบ้า", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8235, "SUB_DISTRICT_CODE": "900504", "SUB_DISTRICT_NAME": "ลำไพล", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8236, "SUB_DISTRICT_CODE": "900505", "SUB_DISTRICT_NAME": "ท่าม่วง", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8237, "SUB_DISTRICT_CODE": "900506", "SUB_DISTRICT_NAME": "วังใหญ่", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8238, "SUB_DISTRICT_CODE": "900507", "SUB_DISTRICT_NAME": "สะกอม", "DISTRICT_ID": 922, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8239, "SUB_DISTRICT_CODE": "900601", "SUB_DISTRICT_NAME": "สะบ้าย้อย", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8240, "SUB_DISTRICT_CODE": "900602", "SUB_DISTRICT_NAME": "ทุ่งพอ", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8241, "SUB_DISTRICT_CODE": "900603", "SUB_DISTRICT_NAME": "เปียน", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8242, "SUB_DISTRICT_CODE": "900604", "SUB_DISTRICT_NAME": "บ้านโหนด", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8243, "SUB_DISTRICT_CODE": "900605", "SUB_DISTRICT_NAME": "จะแหน", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8244, "SUB_DISTRICT_CODE": "900606", "SUB_DISTRICT_NAME": "คูหา", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8245, "SUB_DISTRICT_CODE": "900607", "SUB_DISTRICT_NAME": "เขาแดง", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8246, "SUB_DISTRICT_CODE": "900608", "SUB_DISTRICT_NAME": "บาโหย", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8247, "SUB_DISTRICT_CODE": "900609", "SUB_DISTRICT_NAME": "ธารคีรี", "DISTRICT_ID": 923, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8248, "SUB_DISTRICT_CODE": "900701", "SUB_DISTRICT_NAME": "ระโนด", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8249, "SUB_DISTRICT_CODE": "900702", "SUB_DISTRICT_NAME": "คลองแดน", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8250, "SUB_DISTRICT_CODE": "900703", "SUB_DISTRICT_NAME": "ตะเครียะ", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8251, "SUB_DISTRICT_CODE": "900704", "SUB_DISTRICT_NAME": "ท่าบอน", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8252, "SUB_DISTRICT_CODE": "900705", "SUB_DISTRICT_NAME": "บ้านใหม่", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8253, "SUB_DISTRICT_CODE": "900706", "SUB_DISTRICT_NAME": "บ่อตรุ", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8254, "SUB_DISTRICT_CODE": "900707", "SUB_DISTRICT_NAME": "ปากแตระ", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8255, "SUB_DISTRICT_CODE": "900708", "SUB_DISTRICT_NAME": "พังยาง", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8256, "SUB_DISTRICT_CODE": "900709", "SUB_DISTRICT_NAME": "ระวะ", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8257, "SUB_DISTRICT_CODE": "900710", "SUB_DISTRICT_NAME": "วัดสน", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8258, "SUB_DISTRICT_CODE": "900711", "SUB_DISTRICT_NAME": "บ้านขาว", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8259, "SUB_DISTRICT_CODE": "900712", "SUB_DISTRICT_NAME": "แดนสงวน", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8260, "SUB_DISTRICT_CODE": "900797", "SUB_DISTRICT_NAME": "*เชิงแส", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8261, "SUB_DISTRICT_CODE": "900798", "SUB_DISTRICT_NAME": "*โรง", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8262, "SUB_DISTRICT_CODE": "900799", "SUB_DISTRICT_NAME": "*เกาะใหญ่", "DISTRICT_ID": 924, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8263, "SUB_DISTRICT_CODE": "900801", "SUB_DISTRICT_NAME": "เกาะใหญ่", "DISTRICT_ID": 925, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8264, "SUB_DISTRICT_CODE": "900802", "SUB_DISTRICT_NAME": "โรง", "DISTRICT_ID": 925, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8265, "SUB_DISTRICT_CODE": "900803", "SUB_DISTRICT_NAME": "เชิงแส", "DISTRICT_ID": 925, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8266, "SUB_DISTRICT_CODE": "900804", "SUB_DISTRICT_NAME": "กระแสสินธุ์", "DISTRICT_ID": 925, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8267, "SUB_DISTRICT_CODE": "900901", "SUB_DISTRICT_NAME": "กำแพงเพชร", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8268, "SUB_DISTRICT_CODE": "900902", "SUB_DISTRICT_NAME": "ท่าชะมวง", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8269, "SUB_DISTRICT_CODE": "900903", "SUB_DISTRICT_NAME": "คูหาใต้", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8270, "SUB_DISTRICT_CODE": "900904", "SUB_DISTRICT_NAME": "ควนรู", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8271, "SUB_DISTRICT_CODE": "900905", "SUB_DISTRICT_NAME": "*ควนโส", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8272, "SUB_DISTRICT_CODE": "900906", "SUB_DISTRICT_NAME": "*รัตภูมิ", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8273, "SUB_DISTRICT_CODE": "900907", "SUB_DISTRICT_NAME": "*บางเหรียง", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8274, "SUB_DISTRICT_CODE": "900908", "SUB_DISTRICT_NAME": "*ห้วยลึก", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8275, "SUB_DISTRICT_CODE": "900909", "SUB_DISTRICT_NAME": "เขาพระ", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8276, "SUB_DISTRICT_CODE": "900996", "SUB_DISTRICT_NAME": "*บางเหรี่ยง", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8277, "SUB_DISTRICT_CODE": "900997", "SUB_DISTRICT_NAME": "*ห้วยลึก", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8278, "SUB_DISTRICT_CODE": "900998", "SUB_DISTRICT_NAME": "*ควนโส", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8279, "SUB_DISTRICT_CODE": "900999", "SUB_DISTRICT_NAME": "*รัตนภูมิ", "DISTRICT_ID": 926, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8280, "SUB_DISTRICT_CODE": "901001", "SUB_DISTRICT_NAME": "สะเดา", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8281, "SUB_DISTRICT_CODE": "901002", "SUB_DISTRICT_NAME": "ปริก", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8282, "SUB_DISTRICT_CODE": "901003", "SUB_DISTRICT_NAME": "พังลา", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8283, "SUB_DISTRICT_CODE": "901004", "SUB_DISTRICT_NAME": "สำนักแต้ว", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8284, "SUB_DISTRICT_CODE": "901005", "SUB_DISTRICT_NAME": "ทุ่งหมอ", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8285, "SUB_DISTRICT_CODE": "901006", "SUB_DISTRICT_NAME": "ท่าโพธิ์", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8286, "SUB_DISTRICT_CODE": "901007", "SUB_DISTRICT_NAME": "ปาดังเบซาร์", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8287, "SUB_DISTRICT_CODE": "901008", "SUB_DISTRICT_NAME": "สำนักขาม", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8288, "SUB_DISTRICT_CODE": "901009", "SUB_DISTRICT_NAME": "เขามีเกียรติ", "DISTRICT_ID": 927, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8289, "SUB_DISTRICT_CODE": "901101", "SUB_DISTRICT_NAME": "หาดใหญ่", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8290, "SUB_DISTRICT_CODE": "901102", "SUB_DISTRICT_NAME": "ควนลัง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8291, "SUB_DISTRICT_CODE": "901103", "SUB_DISTRICT_NAME": "คูเต่า", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8292, "SUB_DISTRICT_CODE": "901104", "SUB_DISTRICT_NAME": "คอหงส์", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8293, "SUB_DISTRICT_CODE": "901105", "SUB_DISTRICT_NAME": "คลองแห", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8294, "SUB_DISTRICT_CODE": "901106", "SUB_DISTRICT_NAME": "คลองหอยโข่ง*", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8295, "SUB_DISTRICT_CODE": "901107", "SUB_DISTRICT_NAME": "คลองอู่ตะเภา", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8296, "SUB_DISTRICT_CODE": "901108", "SUB_DISTRICT_NAME": "ฉลุง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8297, "SUB_DISTRICT_CODE": "901109", "SUB_DISTRICT_NAME": "ทุ่งลาน*", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8298, "SUB_DISTRICT_CODE": "901110", "SUB_DISTRICT_NAME": "ท่าช้าง*", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8299, "SUB_DISTRICT_CODE": "901111", "SUB_DISTRICT_NAME": "ทุ่งใหญ่", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8300, "SUB_DISTRICT_CODE": "901112", "SUB_DISTRICT_NAME": "ทุ่งตำเสา", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8301, "SUB_DISTRICT_CODE": "901113", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8302, "SUB_DISTRICT_CODE": "901114", "SUB_DISTRICT_NAME": "น้ำน้อย", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8303, "SUB_DISTRICT_CODE": "901115", "SUB_DISTRICT_NAME": "*บางกล่ำ", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8304, "SUB_DISTRICT_CODE": "901116", "SUB_DISTRICT_NAME": "บ้านพรุ", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8305, "SUB_DISTRICT_CODE": "901117", "SUB_DISTRICT_NAME": "*บ้านหาร", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8306, "SUB_DISTRICT_CODE": "901118", "SUB_DISTRICT_NAME": "พะตง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8307, "SUB_DISTRICT_CODE": "901119", "SUB_DISTRICT_NAME": "*แม่ทอม", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8308, "SUB_DISTRICT_CODE": "901121", "SUB_DISTRICT_NAME": "*โคกม่วง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8309, "SUB_DISTRICT_CODE": "901190", "SUB_DISTRICT_NAME": "*ทุ่งลาน", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8310, "SUB_DISTRICT_CODE": "901191", "SUB_DISTRICT_NAME": "*คลองหอยโข่ง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8311, "SUB_DISTRICT_CODE": "901192", "SUB_DISTRICT_NAME": "*บ้านหาร", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8312, "SUB_DISTRICT_CODE": "901193", "SUB_DISTRICT_NAME": "*แม่ทอม", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8313, "SUB_DISTRICT_CODE": "901194", "SUB_DISTRICT_NAME": "*ท่าช้าง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8314, "SUB_DISTRICT_CODE": "901195", "SUB_DISTRICT_NAME": "*บางกล่ำ", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8315, "SUB_DISTRICT_CODE": "901196", "SUB_DISTRICT_NAME": "*คลองหรัง", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8316, "SUB_DISTRICT_CODE": "901197", "SUB_DISTRICT_NAME": "*ทุ่งขมิ้น", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8317, "SUB_DISTRICT_CODE": "901198", "SUB_DISTRICT_NAME": "*พิจิตร", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8318, "SUB_DISTRICT_CODE": "901199", "SUB_DISTRICT_NAME": "*นาหม่อม", "DISTRICT_ID": 928, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8319, "SUB_DISTRICT_CODE": "901201", "SUB_DISTRICT_NAME": "นาหม่อม", "DISTRICT_ID": 929, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8320, "SUB_DISTRICT_CODE": "901202", "SUB_DISTRICT_NAME": "พิจิตร", "DISTRICT_ID": 929, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8321, "SUB_DISTRICT_CODE": "901203", "SUB_DISTRICT_NAME": "ทุ่งขมิ้น", "DISTRICT_ID": 929, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8322, "SUB_DISTRICT_CODE": "901204", "SUB_DISTRICT_NAME": "คลองหรัง", "DISTRICT_ID": 929, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8323, "SUB_DISTRICT_CODE": "901301", "SUB_DISTRICT_NAME": "รัตภูมิ", "DISTRICT_ID": 930, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8324, "SUB_DISTRICT_CODE": "901302", "SUB_DISTRICT_NAME": "ควนโส", "DISTRICT_ID": 930, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8325, "SUB_DISTRICT_CODE": "901303", "SUB_DISTRICT_NAME": "ห้วยลึก", "DISTRICT_ID": 930, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8326, "SUB_DISTRICT_CODE": "901304", "SUB_DISTRICT_NAME": "บางเหรียง", "DISTRICT_ID": 930, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8327, "SUB_DISTRICT_CODE": "901401", "SUB_DISTRICT_NAME": "บางกล่ำ", "DISTRICT_ID": 931, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8328, "SUB_DISTRICT_CODE": "901402", "SUB_DISTRICT_NAME": "ท่าช้าง", "DISTRICT_ID": 931, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8329, "SUB_DISTRICT_CODE": "901403", "SUB_DISTRICT_NAME": "แม่ทอม", "DISTRICT_ID": 931, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8330, "SUB_DISTRICT_CODE": "901404", "SUB_DISTRICT_NAME": "บ้านหาร", "DISTRICT_ID": 931, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8331, "SUB_DISTRICT_CODE": "901501", "SUB_DISTRICT_NAME": "ชิงโค", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8332, "SUB_DISTRICT_CODE": "901502", "SUB_DISTRICT_NAME": "สทิงหม้อ", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8333, "SUB_DISTRICT_CODE": "901503", "SUB_DISTRICT_NAME": "ทำนบ", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8334, "SUB_DISTRICT_CODE": "901504", "SUB_DISTRICT_NAME": "รำแดง", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8335, "SUB_DISTRICT_CODE": "901505", "SUB_DISTRICT_NAME": "วัดขนุน", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8336, "SUB_DISTRICT_CODE": "901506", "SUB_DISTRICT_NAME": "ชะแล้", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8337, "SUB_DISTRICT_CODE": "901507", "SUB_DISTRICT_NAME": "ปากรอ", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8338, "SUB_DISTRICT_CODE": "901508", "SUB_DISTRICT_NAME": "ป่าขาด", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8339, "SUB_DISTRICT_CODE": "901509", "SUB_DISTRICT_NAME": "หัวเขา", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8340, "SUB_DISTRICT_CODE": "901510", "SUB_DISTRICT_NAME": "บางเขียด", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8341, "SUB_DISTRICT_CODE": "901511", "SUB_DISTRICT_NAME": "ม่วงงาม", "DISTRICT_ID": 932, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8342, "SUB_DISTRICT_CODE": "901601", "SUB_DISTRICT_NAME": "คลองหอยโข่ง", "DISTRICT_ID": 933, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8343, "SUB_DISTRICT_CODE": "901602", "SUB_DISTRICT_NAME": "ทุ่งลาน", "DISTRICT_ID": 933, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8344, "SUB_DISTRICT_CODE": "901603", "SUB_DISTRICT_NAME": "โคกม่วง", "DISTRICT_ID": 933, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8345, "SUB_DISTRICT_CODE": "901604", "SUB_DISTRICT_NAME": "คลองหลา", "DISTRICT_ID": 933, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8346, "SUB_DISTRICT_CODE": "907701", "SUB_DISTRICT_NAME": "สำนักขาม*", "DISTRICT_ID": 934, "PROVINCE_ID": 70, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8347, "SUB_DISTRICT_CODE": "910101", "SUB_DISTRICT_NAME": "พิมาน", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8348, "SUB_DISTRICT_CODE": "910102", "SUB_DISTRICT_NAME": "คลองขุด", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8349, "SUB_DISTRICT_CODE": "910103", "SUB_DISTRICT_NAME": "ควนขัน", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8350, "SUB_DISTRICT_CODE": "910104", "SUB_DISTRICT_NAME": "บ้านควน", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8351, "SUB_DISTRICT_CODE": "910105", "SUB_DISTRICT_NAME": "ฉลุง", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8352, "SUB_DISTRICT_CODE": "910106", "SUB_DISTRICT_NAME": "เกาะสาหร่าย", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8353, "SUB_DISTRICT_CODE": "910107", "SUB_DISTRICT_NAME": "ตันหยงโป", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8354, "SUB_DISTRICT_CODE": "910108", "SUB_DISTRICT_NAME": "เจ๊ะบิลัง", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8355, "SUB_DISTRICT_CODE": "910109", "SUB_DISTRICT_NAME": "ตำมะลัง", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8356, "SUB_DISTRICT_CODE": "910110", "SUB_DISTRICT_NAME": "ปูยู", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8357, "SUB_DISTRICT_CODE": "910111", "SUB_DISTRICT_NAME": "ควนโพธิ์", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8358, "SUB_DISTRICT_CODE": "910112", "SUB_DISTRICT_NAME": "เกตรี", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8359, "SUB_DISTRICT_CODE": "910199", "SUB_DISTRICT_NAME": "*ท่าแพ", "DISTRICT_ID": 936, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8360, "SUB_DISTRICT_CODE": "910201", "SUB_DISTRICT_NAME": "ควนโดน", "DISTRICT_ID": 937, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8361, "SUB_DISTRICT_CODE": "910202", "SUB_DISTRICT_NAME": "ควนสตอ", "DISTRICT_ID": 937, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8362, "SUB_DISTRICT_CODE": "910203", "SUB_DISTRICT_NAME": "ย่านซื่อ", "DISTRICT_ID": 937, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8363, "SUB_DISTRICT_CODE": "910204", "SUB_DISTRICT_NAME": "วังประจัน", "DISTRICT_ID": 937, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8364, "SUB_DISTRICT_CODE": "910301", "SUB_DISTRICT_NAME": "ทุ่งนุ้ย", "DISTRICT_ID": 938, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8365, "SUB_DISTRICT_CODE": "910302", "SUB_DISTRICT_NAME": "ควนกาหลง", "DISTRICT_ID": 938, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8366, "SUB_DISTRICT_CODE": "910303", "SUB_DISTRICT_NAME": "อุใดเจริญ", "DISTRICT_ID": 938, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8367, "SUB_DISTRICT_CODE": "910304", "SUB_DISTRICT_NAME": "นิคมพัฒนา*", "DISTRICT_ID": 938, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8368, "SUB_DISTRICT_CODE": "910305", "SUB_DISTRICT_NAME": "ปาล์มพัฒนา*", "DISTRICT_ID": 938, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8369, "SUB_DISTRICT_CODE": "910401", "SUB_DISTRICT_NAME": "ท่าแพ", "DISTRICT_ID": 939, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8370, "SUB_DISTRICT_CODE": "910402", "SUB_DISTRICT_NAME": "แป-ระ", "DISTRICT_ID": 939, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8371, "SUB_DISTRICT_CODE": "910403", "SUB_DISTRICT_NAME": "สาคร", "DISTRICT_ID": 939, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8372, "SUB_DISTRICT_CODE": "910404", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 939, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8373, "SUB_DISTRICT_CODE": "910501", "SUB_DISTRICT_NAME": "กำแพง", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8374, "SUB_DISTRICT_CODE": "910502", "SUB_DISTRICT_NAME": "ละงู", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8375, "SUB_DISTRICT_CODE": "910503", "SUB_DISTRICT_NAME": "เขาขาว", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8376, "SUB_DISTRICT_CODE": "910504", "SUB_DISTRICT_NAME": "ปากน้ำ", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8377, "SUB_DISTRICT_CODE": "910505", "SUB_DISTRICT_NAME": "น้ำผุด", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8378, "SUB_DISTRICT_CODE": "910506", "SUB_DISTRICT_NAME": "แหลมสน", "DISTRICT_ID": 940, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8379, "SUB_DISTRICT_CODE": "910601", "SUB_DISTRICT_NAME": "ทุ่งหว้า", "DISTRICT_ID": 941, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8380, "SUB_DISTRICT_CODE": "910602", "SUB_DISTRICT_NAME": "นาทอน", "DISTRICT_ID": 941, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8381, "SUB_DISTRICT_CODE": "910603", "SUB_DISTRICT_NAME": "ขอนคลาน", "DISTRICT_ID": 941, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8382, "SUB_DISTRICT_CODE": "910604", "SUB_DISTRICT_NAME": "ทุ่งบุหลัง", "DISTRICT_ID": 941, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8383, "SUB_DISTRICT_CODE": "910605", "SUB_DISTRICT_NAME": "ป่าแก่บ่อหิน", "DISTRICT_ID": 941, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8384, "SUB_DISTRICT_CODE": "910701", "SUB_DISTRICT_NAME": "ปาล์มพัฒนา", "DISTRICT_ID": 942, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8385, "SUB_DISTRICT_CODE": "910702", "SUB_DISTRICT_NAME": "นิคมพัฒนา", "DISTRICT_ID": 942, "PROVINCE_ID": 71, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8386, "SUB_DISTRICT_CODE": "920101", "SUB_DISTRICT_NAME": "ทับเที่ยง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8387, "SUB_DISTRICT_CODE": "920102", "SUB_DISTRICT_NAME": "*โคกสะบ้า", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8388, "SUB_DISTRICT_CODE": "920103", "SUB_DISTRICT_NAME": "*ละมอ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8389, "SUB_DISTRICT_CODE": "920104", "SUB_DISTRICT_NAME": "นาพละ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8390, "SUB_DISTRICT_CODE": "920105", "SUB_DISTRICT_NAME": "บ้านควน", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8391, "SUB_DISTRICT_CODE": "920106", "SUB_DISTRICT_NAME": "นาบินหลา", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8392, "SUB_DISTRICT_CODE": "920107", "SUB_DISTRICT_NAME": "ควนปริง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8393, "SUB_DISTRICT_CODE": "920108", "SUB_DISTRICT_NAME": "นาโยงใต้", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8394, "SUB_DISTRICT_CODE": "920109", "SUB_DISTRICT_NAME": "บางรัก", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8395, "SUB_DISTRICT_CODE": "920110", "SUB_DISTRICT_NAME": "โคกหล่อ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8396, "SUB_DISTRICT_CODE": "920111", "SUB_DISTRICT_NAME": "*นาข้าวเสีย", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8397, "SUB_DISTRICT_CODE": "920112", "SUB_DISTRICT_NAME": "*นาหมื่นศรี", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8398, "SUB_DISTRICT_CODE": "920113", "SUB_DISTRICT_NAME": "นาโต๊ะหมิง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8399, "SUB_DISTRICT_CODE": "920114", "SUB_DISTRICT_NAME": "หนองตรุด", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8400, "SUB_DISTRICT_CODE": "920115", "SUB_DISTRICT_NAME": "น้ำผุด", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8401, "SUB_DISTRICT_CODE": "920116", "SUB_DISTRICT_NAME": "*นาโยงเหนือ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8402, "SUB_DISTRICT_CODE": "920117", "SUB_DISTRICT_NAME": "นาตาล่วง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8403, "SUB_DISTRICT_CODE": "920118", "SUB_DISTRICT_NAME": "บ้านโพธิ์", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8404, "SUB_DISTRICT_CODE": "920119", "SUB_DISTRICT_NAME": "นาท่ามเหนือ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8405, "SUB_DISTRICT_CODE": "920120", "SUB_DISTRICT_NAME": "นาท่ามใต้", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8406, "SUB_DISTRICT_CODE": "920121", "SUB_DISTRICT_NAME": "*ช่อง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8407, "SUB_DISTRICT_CODE": "920194", "SUB_DISTRICT_NAME": "*นาข้าวเสีย", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8408, "SUB_DISTRICT_CODE": "920195", "SUB_DISTRICT_NAME": "*โคกสะบ้า", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8409, "SUB_DISTRICT_CODE": "920196", "SUB_DISTRICT_NAME": "*ละมอ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8410, "SUB_DISTRICT_CODE": "920197", "SUB_DISTRICT_NAME": "*นาหมื่นศรี", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8411, "SUB_DISTRICT_CODE": "920198", "SUB_DISTRICT_NAME": "*ช่อง", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8412, "SUB_DISTRICT_CODE": "920199", "SUB_DISTRICT_NAME": "*นาโยงเหนือ", "DISTRICT_ID": 943, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8413, "SUB_DISTRICT_CODE": "920201", "SUB_DISTRICT_NAME": "กันตัง", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8414, "SUB_DISTRICT_CODE": "920202", "SUB_DISTRICT_NAME": "ควนธานี", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8415, "SUB_DISTRICT_CODE": "920203", "SUB_DISTRICT_NAME": "บางหมาก", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8416, "SUB_DISTRICT_CODE": "920204", "SUB_DISTRICT_NAME": "บางเป้า", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8417, "SUB_DISTRICT_CODE": "920205", "SUB_DISTRICT_NAME": "วังวน", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8418, "SUB_DISTRICT_CODE": "920206", "SUB_DISTRICT_NAME": "กันตังใต้", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8419, "SUB_DISTRICT_CODE": "920207", "SUB_DISTRICT_NAME": "โคกยาง", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8420, "SUB_DISTRICT_CODE": "920208", "SUB_DISTRICT_NAME": "คลองลุ", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8421, "SUB_DISTRICT_CODE": "920209", "SUB_DISTRICT_NAME": "ย่านซื่อ", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8422, "SUB_DISTRICT_CODE": "920210", "SUB_DISTRICT_NAME": "บ่อน้ำร้อน", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8423, "SUB_DISTRICT_CODE": "920211", "SUB_DISTRICT_NAME": "บางสัก", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8424, "SUB_DISTRICT_CODE": "920212", "SUB_DISTRICT_NAME": "นาเกลือ", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8425, "SUB_DISTRICT_CODE": "920213", "SUB_DISTRICT_NAME": "เกาะลิบง", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8426, "SUB_DISTRICT_CODE": "920214", "SUB_DISTRICT_NAME": "คลองชีล้อม", "DISTRICT_ID": 944, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8427, "SUB_DISTRICT_CODE": "920301", "SUB_DISTRICT_NAME": "ย่านตาขาว", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8428, "SUB_DISTRICT_CODE": "920302", "SUB_DISTRICT_NAME": "หนองบ่อ", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8429, "SUB_DISTRICT_CODE": "920303", "SUB_DISTRICT_NAME": "นาชุมเห็ด", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8430, "SUB_DISTRICT_CODE": "920304", "SUB_DISTRICT_NAME": "ในควน", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8431, "SUB_DISTRICT_CODE": "920305", "SUB_DISTRICT_NAME": "โพรงจระเข้", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8432, "SUB_DISTRICT_CODE": "920306", "SUB_DISTRICT_NAME": "ทุ่งกระบือ", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8433, "SUB_DISTRICT_CODE": "920307", "SUB_DISTRICT_NAME": "ทุ่งค่าย", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8434, "SUB_DISTRICT_CODE": "920308", "SUB_DISTRICT_NAME": "เกาะเปียะ", "DISTRICT_ID": 945, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8435, "SUB_DISTRICT_CODE": "920401", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8436, "SUB_DISTRICT_CODE": "920402", "SUB_DISTRICT_NAME": "ทุ่งยาว", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8437, "SUB_DISTRICT_CODE": "920403", "SUB_DISTRICT_NAME": "ปะเหลียน", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8438, "SUB_DISTRICT_CODE": "920404", "SUB_DISTRICT_NAME": "บางด้วน", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8439, "SUB_DISTRICT_CODE": "920405", "SUB_DISTRICT_NAME": "*หาดสำราญ", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8440, "SUB_DISTRICT_CODE": "920406", "SUB_DISTRICT_NAME": "*ตะเสะ", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8441, "SUB_DISTRICT_CODE": "920407", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8442, "SUB_DISTRICT_CODE": "920408", "SUB_DISTRICT_NAME": "*บ้าหวี", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8443, "SUB_DISTRICT_CODE": "920409", "SUB_DISTRICT_NAME": "สุโสะ", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8444, "SUB_DISTRICT_CODE": "920410", "SUB_DISTRICT_NAME": "ลิพัง", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8445, "SUB_DISTRICT_CODE": "920411", "SUB_DISTRICT_NAME": "เกาะสุกร", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8446, "SUB_DISTRICT_CODE": "920412", "SUB_DISTRICT_NAME": "ท่าพญา", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8447, "SUB_DISTRICT_CODE": "920413", "SUB_DISTRICT_NAME": "แหลมสอม", "DISTRICT_ID": 946, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8448, "SUB_DISTRICT_CODE": "920501", "SUB_DISTRICT_NAME": "บ่อหิน", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8449, "SUB_DISTRICT_CODE": "920502", "SUB_DISTRICT_NAME": "เขาไม้แก้ว", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8450, "SUB_DISTRICT_CODE": "920503", "SUB_DISTRICT_NAME": "กะลาเส", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8451, "SUB_DISTRICT_CODE": "920504", "SUB_DISTRICT_NAME": "ไม้ฝาด", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8452, "SUB_DISTRICT_CODE": "920505", "SUB_DISTRICT_NAME": "นาเมืองเพชร", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8453, "SUB_DISTRICT_CODE": "920595", "SUB_DISTRICT_NAME": "*ท่าสะบ้า", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8454, "SUB_DISTRICT_CODE": "920596", "SUB_DISTRICT_NAME": "*สิเกา", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8455, "SUB_DISTRICT_CODE": "920597", "SUB_DISTRICT_NAME": "*อ่าวตง", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8456, "SUB_DISTRICT_CODE": "920598", "SUB_DISTRICT_NAME": "*วังมะปราง", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8457, "SUB_DISTRICT_CODE": "920599", "SUB_DISTRICT_NAME": "*เขาวิเศษ", "DISTRICT_ID": 947, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8458, "SUB_DISTRICT_CODE": "920601", "SUB_DISTRICT_NAME": "ห้วยยอด", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8459, "SUB_DISTRICT_CODE": "920602", "SUB_DISTRICT_NAME": "หนองช้างแล่น", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8460, "SUB_DISTRICT_CODE": "920603", "SUB_DISTRICT_NAME": "*หนองปรือ", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8461, "SUB_DISTRICT_CODE": "920604", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8462, "SUB_DISTRICT_CODE": "920605", "SUB_DISTRICT_NAME": "บางดี", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8463, "SUB_DISTRICT_CODE": "920606", "SUB_DISTRICT_NAME": "บางกุ้ง", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8464, "SUB_DISTRICT_CODE": "920607", "SUB_DISTRICT_NAME": "เขากอบ", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8465, "SUB_DISTRICT_CODE": "920608", "SUB_DISTRICT_NAME": "เขาขาว", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8466, "SUB_DISTRICT_CODE": "920609", "SUB_DISTRICT_NAME": "เขาปูน", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8467, "SUB_DISTRICT_CODE": "920610", "SUB_DISTRICT_NAME": "ปากแจ่ม", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8468, "SUB_DISTRICT_CODE": "920611", "SUB_DISTRICT_NAME": "ปากคม", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8469, "SUB_DISTRICT_CODE": "920612", "SUB_DISTRICT_NAME": "*คลองปาง", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8470, "SUB_DISTRICT_CODE": "920613", "SUB_DISTRICT_NAME": "*ควนเมา", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8471, "SUB_DISTRICT_CODE": "920614", "SUB_DISTRICT_NAME": "ท่างิ้ว", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8472, "SUB_DISTRICT_CODE": "920615", "SUB_DISTRICT_NAME": "ลำภูรา", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8473, "SUB_DISTRICT_CODE": "920616", "SUB_DISTRICT_NAME": "นาวง", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8474, "SUB_DISTRICT_CODE": "920617", "SUB_DISTRICT_NAME": "ห้วยนาง", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8475, "SUB_DISTRICT_CODE": "920618", "SUB_DISTRICT_NAME": "*เขาไพร", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8476, "SUB_DISTRICT_CODE": "920619", "SUB_DISTRICT_NAME": "ในเตา", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8477, "SUB_DISTRICT_CODE": "920620", "SUB_DISTRICT_NAME": "ทุ่งต่อ", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8478, "SUB_DISTRICT_CODE": "920621", "SUB_DISTRICT_NAME": "วังคีรี", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8479, "SUB_DISTRICT_CODE": "920696", "SUB_DISTRICT_NAME": "*หนองปรือ", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8480, "SUB_DISTRICT_CODE": "920697", "SUB_DISTRICT_NAME": "*หนองบัว", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8481, "SUB_DISTRICT_CODE": "920698", "SUB_DISTRICT_NAME": "*คลองปาง", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8482, "SUB_DISTRICT_CODE": "920699", "SUB_DISTRICT_NAME": "*ควนเมา", "DISTRICT_ID": 948, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8483, "SUB_DISTRICT_CODE": "920701", "SUB_DISTRICT_NAME": "เขาวิเศษ", "DISTRICT_ID": 949, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8484, "SUB_DISTRICT_CODE": "920702", "SUB_DISTRICT_NAME": "วังมะปราง", "DISTRICT_ID": 949, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8485, "SUB_DISTRICT_CODE": "920703", "SUB_DISTRICT_NAME": "อ่าวตง", "DISTRICT_ID": 949, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8486, "SUB_DISTRICT_CODE": "920704", "SUB_DISTRICT_NAME": "ท่าสะบ้า", "DISTRICT_ID": 949, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8487, "SUB_DISTRICT_CODE": "920705", "SUB_DISTRICT_NAME": "วังมะปรางเหนือ", "DISTRICT_ID": 949, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8488, "SUB_DISTRICT_CODE": "920801", "SUB_DISTRICT_NAME": "นาโยงเหนือ", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8489, "SUB_DISTRICT_CODE": "920802", "SUB_DISTRICT_NAME": "ช่อง", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8490, "SUB_DISTRICT_CODE": "920803", "SUB_DISTRICT_NAME": "ละมอ", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8491, "SUB_DISTRICT_CODE": "920804", "SUB_DISTRICT_NAME": "โคกสะบ้า", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8492, "SUB_DISTRICT_CODE": "920805", "SUB_DISTRICT_NAME": "นาหมื่นศรี", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8493, "SUB_DISTRICT_CODE": "920806", "SUB_DISTRICT_NAME": "นาข้าวเสีย", "DISTRICT_ID": 950, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8494, "SUB_DISTRICT_CODE": "920901", "SUB_DISTRICT_NAME": "ควนเมา", "DISTRICT_ID": 951, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8495, "SUB_DISTRICT_CODE": "920902", "SUB_DISTRICT_NAME": "คลองปาง", "DISTRICT_ID": 951, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8496, "SUB_DISTRICT_CODE": "920903", "SUB_DISTRICT_NAME": "หนองบัว", "DISTRICT_ID": 951, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8497, "SUB_DISTRICT_CODE": "920904", "SUB_DISTRICT_NAME": "หนองปรือ", "DISTRICT_ID": 951, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8498, "SUB_DISTRICT_CODE": "920905", "SUB_DISTRICT_NAME": "เขาไพร", "DISTRICT_ID": 951, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8499, "SUB_DISTRICT_CODE": "921001", "SUB_DISTRICT_NAME": "หาดสำราญ", "DISTRICT_ID": 952, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8500, "SUB_DISTRICT_CODE": "921002", "SUB_DISTRICT_NAME": "บ้าหวี", "DISTRICT_ID": 952, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8501, "SUB_DISTRICT_CODE": "921003", "SUB_DISTRICT_NAME": "ตะเสะ", "DISTRICT_ID": 952, "PROVINCE_ID": 72, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8502, "SUB_DISTRICT_CODE": "930101", "SUB_DISTRICT_NAME": "คูหาสวรรค์", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8503, "SUB_DISTRICT_CODE": "930102", "SUB_DISTRICT_NAME": "บ้านนา*", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8504, "SUB_DISTRICT_CODE": "930103", "SUB_DISTRICT_NAME": "เขาเจียก", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8505, "SUB_DISTRICT_CODE": "930104", "SUB_DISTRICT_NAME": "ท่ามิหรำ", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8506, "SUB_DISTRICT_CODE": "930105", "SUB_DISTRICT_NAME": "โคกชะงาย", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8507, "SUB_DISTRICT_CODE": "930106", "SUB_DISTRICT_NAME": "นาท่อม", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8508, "SUB_DISTRICT_CODE": "930107", "SUB_DISTRICT_NAME": "ปรางหมู่", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8509, "SUB_DISTRICT_CODE": "930108", "SUB_DISTRICT_NAME": "ท่าแค", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8510, "SUB_DISTRICT_CODE": "930109", "SUB_DISTRICT_NAME": "ลำปำ", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8511, "SUB_DISTRICT_CODE": "930110", "SUB_DISTRICT_NAME": "ตำนาน", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8512, "SUB_DISTRICT_CODE": "930111", "SUB_DISTRICT_NAME": "ควนมะพร้าว", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8513, "SUB_DISTRICT_CODE": "930112", "SUB_DISTRICT_NAME": "ร่มเมือง", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8514, "SUB_DISTRICT_CODE": "930113", "SUB_DISTRICT_NAME": "ชัยบุรี", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8515, "SUB_DISTRICT_CODE": "930114", "SUB_DISTRICT_NAME": "นาโหนด", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8516, "SUB_DISTRICT_CODE": "930115", "SUB_DISTRICT_NAME": "พญาขัน", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8517, "SUB_DISTRICT_CODE": "930116", "SUB_DISTRICT_NAME": "ลำสินธุ์*", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8518, "SUB_DISTRICT_CODE": "930117", "SUB_DISTRICT_NAME": "อ่างทอง*", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8519, "SUB_DISTRICT_CODE": "930118", "SUB_DISTRICT_NAME": "ชุมพล*", "DISTRICT_ID": 954, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8520, "SUB_DISTRICT_CODE": "930201", "SUB_DISTRICT_NAME": "กงหรา", "DISTRICT_ID": 955, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8521, "SUB_DISTRICT_CODE": "930202", "SUB_DISTRICT_NAME": "ชะรัด", "DISTRICT_ID": 955, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8522, "SUB_DISTRICT_CODE": "930203", "SUB_DISTRICT_NAME": "คลองเฉลิม", "DISTRICT_ID": 955, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8523, "SUB_DISTRICT_CODE": "930204", "SUB_DISTRICT_NAME": "คลองทรายขาว", "DISTRICT_ID": 955, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8524, "SUB_DISTRICT_CODE": "930205", "SUB_DISTRICT_NAME": "สมหวัง", "DISTRICT_ID": 955, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8525, "SUB_DISTRICT_CODE": "930301", "SUB_DISTRICT_NAME": "เขาชัยสน", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8526, "SUB_DISTRICT_CODE": "930302", "SUB_DISTRICT_NAME": "ควนขนุน", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8527, "SUB_DISTRICT_CODE": "930303", "SUB_DISTRICT_NAME": "*ท่ามะเดื่อ", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8528, "SUB_DISTRICT_CODE": "930304", "SUB_DISTRICT_NAME": "*นาปะขอ", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8529, "SUB_DISTRICT_CODE": "930305", "SUB_DISTRICT_NAME": "จองถนน", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8530, "SUB_DISTRICT_CODE": "930306", "SUB_DISTRICT_NAME": "หานโพธิ์", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8531, "SUB_DISTRICT_CODE": "930307", "SUB_DISTRICT_NAME": "โคกม่วง", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8532, "SUB_DISTRICT_CODE": "930308", "SUB_DISTRICT_NAME": "*โคกสัก", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8533, "SUB_DISTRICT_CODE": "930395", "SUB_DISTRICT_NAME": "*นาปะขอ", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8534, "SUB_DISTRICT_CODE": "930396", "SUB_DISTRICT_NAME": "*คลองใหญ่", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8535, "SUB_DISTRICT_CODE": "930397", "SUB_DISTRICT_NAME": "*ตะโหมด", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8536, "SUB_DISTRICT_CODE": "930398", "SUB_DISTRICT_NAME": "*ท่ามะเดื่อ", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8537, "SUB_DISTRICT_CODE": "930399", "SUB_DISTRICT_NAME": "*แม่ขรี", "DISTRICT_ID": 956, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8538, "SUB_DISTRICT_CODE": "930401", "SUB_DISTRICT_NAME": "แม่ขรี", "DISTRICT_ID": 957, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8539, "SUB_DISTRICT_CODE": "930402", "SUB_DISTRICT_NAME": "ตะโหมด", "DISTRICT_ID": 957, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8540, "SUB_DISTRICT_CODE": "930403", "SUB_DISTRICT_NAME": "คลองใหญ่", "DISTRICT_ID": 957, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8541, "SUB_DISTRICT_CODE": "930501", "SUB_DISTRICT_NAME": "ควนขนุน", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8542, "SUB_DISTRICT_CODE": "930502", "SUB_DISTRICT_NAME": "ทะเลน้อย", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8543, "SUB_DISTRICT_CODE": "930503", "SUB_DISTRICT_NAME": "*เกาะเต่า", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8544, "SUB_DISTRICT_CODE": "930504", "SUB_DISTRICT_NAME": "นาขยาด", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8545, "SUB_DISTRICT_CODE": "930505", "SUB_DISTRICT_NAME": "พนมวังก์", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8546, "SUB_DISTRICT_CODE": "930506", "SUB_DISTRICT_NAME": "แหลมโตนด", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8547, "SUB_DISTRICT_CODE": "930507", "SUB_DISTRICT_NAME": "*ป่าพะยอม", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8548, "SUB_DISTRICT_CODE": "930508", "SUB_DISTRICT_NAME": "ปันแต", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8549, "SUB_DISTRICT_CODE": "930509", "SUB_DISTRICT_NAME": "โตนดด้วน", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8550, "SUB_DISTRICT_CODE": "930510", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8551, "SUB_DISTRICT_CODE": "930511", "SUB_DISTRICT_NAME": "มะกอกเหนือ", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8552, "SUB_DISTRICT_CODE": "930512", "SUB_DISTRICT_NAME": "พนางตุง", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8553, "SUB_DISTRICT_CODE": "930513", "SUB_DISTRICT_NAME": "ชะมวง", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8554, "SUB_DISTRICT_CODE": "930514", "SUB_DISTRICT_NAME": "*บ้านพร้าว", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8555, "SUB_DISTRICT_CODE": "930515", "SUB_DISTRICT_NAME": "*ลานข่อย", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8556, "SUB_DISTRICT_CODE": "930516", "SUB_DISTRICT_NAME": "แพรกหา", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8557, "SUB_DISTRICT_CODE": "930596", "SUB_DISTRICT_NAME": "*คำไผ่", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8558, "SUB_DISTRICT_CODE": "930597", "SUB_DISTRICT_NAME": "*คำเตย", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8559, "SUB_DISTRICT_CODE": "930598", "SUB_DISTRICT_NAME": "*ส้มผ่อ", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8560, "SUB_DISTRICT_CODE": "930599", "SUB_DISTRICT_NAME": "*ป่าพะยอม", "DISTRICT_ID": 958, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8561, "SUB_DISTRICT_CODE": "930601", "SUB_DISTRICT_NAME": "ปากพะยูน", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8562, "SUB_DISTRICT_CODE": "930602", "SUB_DISTRICT_NAME": "ดอนประดู่", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8563, "SUB_DISTRICT_CODE": "930603", "SUB_DISTRICT_NAME": "เกาะนางคำ", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8564, "SUB_DISTRICT_CODE": "930604", "SUB_DISTRICT_NAME": "เกาะหมาก", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8565, "SUB_DISTRICT_CODE": "930605", "SUB_DISTRICT_NAME": "ฝาละมี", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8566, "SUB_DISTRICT_CODE": "930606", "SUB_DISTRICT_NAME": "หารเทา", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8567, "SUB_DISTRICT_CODE": "930607", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8568, "SUB_DISTRICT_CODE": "930697", "SUB_DISTRICT_NAME": "*หนองแซง", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8569, "SUB_DISTRICT_CODE": "930698", "SUB_DISTRICT_NAME": "*โคกทราย", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8570, "SUB_DISTRICT_CODE": "930699", "SUB_DISTRICT_NAME": "*ป่าบอน", "DISTRICT_ID": 959, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8571, "SUB_DISTRICT_CODE": "930701", "SUB_DISTRICT_NAME": "เขาย่า", "DISTRICT_ID": 960, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8572, "SUB_DISTRICT_CODE": "930702", "SUB_DISTRICT_NAME": "เขาปู่", "DISTRICT_ID": 960, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8573, "SUB_DISTRICT_CODE": "930703", "SUB_DISTRICT_NAME": "ตะแพน", "DISTRICT_ID": 960, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8574, "SUB_DISTRICT_CODE": "930801", "SUB_DISTRICT_NAME": "ป่าบอน", "DISTRICT_ID": 961, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8575, "SUB_DISTRICT_CODE": "930802", "SUB_DISTRICT_NAME": "โคกทราย", "DISTRICT_ID": 961, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8576, "SUB_DISTRICT_CODE": "930803", "SUB_DISTRICT_NAME": "หนองธง", "DISTRICT_ID": 961, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8577, "SUB_DISTRICT_CODE": "930804", "SUB_DISTRICT_NAME": "ทุ่งนารี", "DISTRICT_ID": 961, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8578, "SUB_DISTRICT_CODE": "930806", "SUB_DISTRICT_NAME": "วังใหม่", "DISTRICT_ID": 961, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8579, "SUB_DISTRICT_CODE": "930901", "SUB_DISTRICT_NAME": "ท่ามะเดื่อ", "DISTRICT_ID": 962, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8580, "SUB_DISTRICT_CODE": "930902", "SUB_DISTRICT_NAME": "นาปะขอ", "DISTRICT_ID": 962, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8581, "SUB_DISTRICT_CODE": "930903", "SUB_DISTRICT_NAME": "โคกสัก", "DISTRICT_ID": 962, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8582, "SUB_DISTRICT_CODE": "931001", "SUB_DISTRICT_NAME": "ป่าพะยอม", "DISTRICT_ID": 963, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8583, "SUB_DISTRICT_CODE": "931002", "SUB_DISTRICT_NAME": "ลานข่อย", "DISTRICT_ID": 963, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8584, "SUB_DISTRICT_CODE": "931003", "SUB_DISTRICT_NAME": "เกาะเต่า", "DISTRICT_ID": 963, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8585, "SUB_DISTRICT_CODE": "931004", "SUB_DISTRICT_NAME": "บ้านพร้าว", "DISTRICT_ID": 963, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8586, "SUB_DISTRICT_CODE": "931101", "SUB_DISTRICT_NAME": "ชุมพล", "DISTRICT_ID": 964, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8587, "SUB_DISTRICT_CODE": "931102", "SUB_DISTRICT_NAME": "บ้านนา", "DISTRICT_ID": 964, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8588, "SUB_DISTRICT_CODE": "931103", "SUB_DISTRICT_NAME": "อ่างทอง", "DISTRICT_ID": 964, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8589, "SUB_DISTRICT_CODE": "931104", "SUB_DISTRICT_NAME": "ลำสินธุ์", "DISTRICT_ID": 964, "PROVINCE_ID": 73, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8590, "SUB_DISTRICT_CODE": "940101", "SUB_DISTRICT_NAME": "สะบารัง", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8591, "SUB_DISTRICT_CODE": "940102", "SUB_DISTRICT_NAME": "อาเนาะรู", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8592, "SUB_DISTRICT_CODE": "940103", "SUB_DISTRICT_NAME": "จะบังติกอ", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8593, "SUB_DISTRICT_CODE": "940104", "SUB_DISTRICT_NAME": "บานา", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8594, "SUB_DISTRICT_CODE": "940105", "SUB_DISTRICT_NAME": "ตันหยงลุโละ", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8595, "SUB_DISTRICT_CODE": "940106", "SUB_DISTRICT_NAME": "คลองมานิง", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8596, "SUB_DISTRICT_CODE": "940107", "SUB_DISTRICT_NAME": "กะมิยอ", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8597, "SUB_DISTRICT_CODE": "940108", "SUB_DISTRICT_NAME": "บาราโหม", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8598, "SUB_DISTRICT_CODE": "940109", "SUB_DISTRICT_NAME": "ปะกาฮะรัง", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8599, "SUB_DISTRICT_CODE": "940110", "SUB_DISTRICT_NAME": "รูสะมิแล", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8600, "SUB_DISTRICT_CODE": "940111", "SUB_DISTRICT_NAME": "ตะลุโบะ", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8601, "SUB_DISTRICT_CODE": "940112", "SUB_DISTRICT_NAME": "บาราเฮาะ", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8602, "SUB_DISTRICT_CODE": "940113", "SUB_DISTRICT_NAME": "ปุยุด", "DISTRICT_ID": 965, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8603, "SUB_DISTRICT_CODE": "940201", "SUB_DISTRICT_NAME": "โคกโพธิ์", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8604, "SUB_DISTRICT_CODE": "940202", "SUB_DISTRICT_NAME": "มะกรูด", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8605, "SUB_DISTRICT_CODE": "940203", "SUB_DISTRICT_NAME": "บางโกระ", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8606, "SUB_DISTRICT_CODE": "940204", "SUB_DISTRICT_NAME": "ป่าบอน", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8607, "SUB_DISTRICT_CODE": "940205", "SUB_DISTRICT_NAME": "ทรายขาว", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8608, "SUB_DISTRICT_CODE": "940206", "SUB_DISTRICT_NAME": "นาประดู่", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8609, "SUB_DISTRICT_CODE": "940207", "SUB_DISTRICT_NAME": "ปากล่อ", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8610, "SUB_DISTRICT_CODE": "940208", "SUB_DISTRICT_NAME": "ทุ่งพลา", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8611, "SUB_DISTRICT_CODE": "940209", "SUB_DISTRICT_NAME": "*แม่ลาน", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8612, "SUB_DISTRICT_CODE": "940210", "SUB_DISTRICT_NAME": "*ป่าไร่", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8613, "SUB_DISTRICT_CODE": "940211", "SUB_DISTRICT_NAME": "ท่าเรือ", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8614, "SUB_DISTRICT_CODE": "940212", "SUB_DISTRICT_NAME": "*ม่วงเตี้ย", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8615, "SUB_DISTRICT_CODE": "940213", "SUB_DISTRICT_NAME": "นาเกตุ", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8616, "SUB_DISTRICT_CODE": "940214", "SUB_DISTRICT_NAME": "ควนโนรี", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8617, "SUB_DISTRICT_CODE": "940215", "SUB_DISTRICT_NAME": "ช้างให้ตก", "DISTRICT_ID": 966, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8618, "SUB_DISTRICT_CODE": "940301", "SUB_DISTRICT_NAME": "เกาะเปาะ", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8619, "SUB_DISTRICT_CODE": "940302", "SUB_DISTRICT_NAME": "คอลอตันหยง", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8620, "SUB_DISTRICT_CODE": "940303", "SUB_DISTRICT_NAME": "ดอนรัก", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8621, "SUB_DISTRICT_CODE": "940304", "SUB_DISTRICT_NAME": "ดาโต๊ะ", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8622, "SUB_DISTRICT_CODE": "940305", "SUB_DISTRICT_NAME": "ตุยง", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8623, "SUB_DISTRICT_CODE": "940306", "SUB_DISTRICT_NAME": "ท่ากำชำ", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8624, "SUB_DISTRICT_CODE": "940307", "SUB_DISTRICT_NAME": "บ่อทอง", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8625, "SUB_DISTRICT_CODE": "940308", "SUB_DISTRICT_NAME": "บางเขา", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8626, "SUB_DISTRICT_CODE": "940309", "SUB_DISTRICT_NAME": "บางตาวา", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8627, "SUB_DISTRICT_CODE": "940310", "SUB_DISTRICT_NAME": "ปุโละปุโย", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8628, "SUB_DISTRICT_CODE": "940311", "SUB_DISTRICT_NAME": "ยาบี", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8629, "SUB_DISTRICT_CODE": "940312", "SUB_DISTRICT_NAME": "ลิปะสะโง", "DISTRICT_ID": 967, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8630, "SUB_DISTRICT_CODE": "940401", "SUB_DISTRICT_NAME": "ปะนาเระ", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8631, "SUB_DISTRICT_CODE": "940402", "SUB_DISTRICT_NAME": "ท่าข้าม", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8632, "SUB_DISTRICT_CODE": "940403", "SUB_DISTRICT_NAME": "บ้านนอก", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8633, "SUB_DISTRICT_CODE": "940404", "SUB_DISTRICT_NAME": "ดอน", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8634, "SUB_DISTRICT_CODE": "940405", "SUB_DISTRICT_NAME": "ควน", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8635, "SUB_DISTRICT_CODE": "940406", "SUB_DISTRICT_NAME": "ท่าน้ำ", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8636, "SUB_DISTRICT_CODE": "940407", "SUB_DISTRICT_NAME": "คอกกระบือ", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8637, "SUB_DISTRICT_CODE": "940408", "SUB_DISTRICT_NAME": "พ่อมิ่ง", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8638, "SUB_DISTRICT_CODE": "940409", "SUB_DISTRICT_NAME": "บ้านกลาง", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8639, "SUB_DISTRICT_CODE": "940410", "SUB_DISTRICT_NAME": "บ้านน้ำบ่อ", "DISTRICT_ID": 968, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8640, "SUB_DISTRICT_CODE": "940501", "SUB_DISTRICT_NAME": "มายอ", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8641, "SUB_DISTRICT_CODE": "940502", "SUB_DISTRICT_NAME": "ถนน", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8642, "SUB_DISTRICT_CODE": "940503", "SUB_DISTRICT_NAME": "ตรัง", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8643, "SUB_DISTRICT_CODE": "940504", "SUB_DISTRICT_NAME": "กระหวะ", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8644, "SUB_DISTRICT_CODE": "940505", "SUB_DISTRICT_NAME": "ลุโบะยิไร", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8645, "SUB_DISTRICT_CODE": "940506", "SUB_DISTRICT_NAME": "ลางา", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8646, "SUB_DISTRICT_CODE": "940507", "SUB_DISTRICT_NAME": "กระเสาะ", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8647, "SUB_DISTRICT_CODE": "940508", "SUB_DISTRICT_NAME": "เกาะจัน", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8648, "SUB_DISTRICT_CODE": "940509", "SUB_DISTRICT_NAME": "ปะโด", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8649, "SUB_DISTRICT_CODE": "940510", "SUB_DISTRICT_NAME": "สาคอบน", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8650, "SUB_DISTRICT_CODE": "940511", "SUB_DISTRICT_NAME": "สาคอใต้", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8651, "SUB_DISTRICT_CODE": "940512", "SUB_DISTRICT_NAME": "สะกำ", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8652, "SUB_DISTRICT_CODE": "940513", "SUB_DISTRICT_NAME": "ปานัน", "DISTRICT_ID": 969, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8653, "SUB_DISTRICT_CODE": "940601", "SUB_DISTRICT_NAME": "ตะโละแมะนา", "DISTRICT_ID": 970, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8654, "SUB_DISTRICT_CODE": "940602", "SUB_DISTRICT_NAME": "พิเทน", "DISTRICT_ID": 970, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8655, "SUB_DISTRICT_CODE": "940603", "SUB_DISTRICT_NAME": "น้ำดำ", "DISTRICT_ID": 970, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8656, "SUB_DISTRICT_CODE": "940604", "SUB_DISTRICT_NAME": "ปากู", "DISTRICT_ID": 970, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8657, "SUB_DISTRICT_CODE": "940701", "SUB_DISTRICT_NAME": "ตะลุบัน", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8658, "SUB_DISTRICT_CODE": "940702", "SUB_DISTRICT_NAME": "ตะบิ้ง", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8659, "SUB_DISTRICT_CODE": "940703", "SUB_DISTRICT_NAME": "ปะเสยะวอ", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8660, "SUB_DISTRICT_CODE": "940704", "SUB_DISTRICT_NAME": "บางเก่า", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8661, "SUB_DISTRICT_CODE": "940705", "SUB_DISTRICT_NAME": "บือเระ", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8662, "SUB_DISTRICT_CODE": "940706", "SUB_DISTRICT_NAME": "เตราะบอน", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8663, "SUB_DISTRICT_CODE": "940707", "SUB_DISTRICT_NAME": "กะดุนง", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8664, "SUB_DISTRICT_CODE": "940708", "SUB_DISTRICT_NAME": "ละหาร", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8665, "SUB_DISTRICT_CODE": "940709", "SUB_DISTRICT_NAME": "มะนังดาลำ", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8666, "SUB_DISTRICT_CODE": "940710", "SUB_DISTRICT_NAME": "แป้น", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8667, "SUB_DISTRICT_CODE": "940711", "SUB_DISTRICT_NAME": "ทุ่งคล้า", "DISTRICT_ID": 971, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8668, "SUB_DISTRICT_CODE": "940801", "SUB_DISTRICT_NAME": "ไทรทอง", "DISTRICT_ID": 972, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8669, "SUB_DISTRICT_CODE": "940802", "SUB_DISTRICT_NAME": "ไม้แก่น", "DISTRICT_ID": 972, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8670, "SUB_DISTRICT_CODE": "940803", "SUB_DISTRICT_NAME": "ตะโละไกรทอง", "DISTRICT_ID": 972, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8671, "SUB_DISTRICT_CODE": "940804", "SUB_DISTRICT_NAME": "ดอนทราย", "DISTRICT_ID": 972, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8672, "SUB_DISTRICT_CODE": "940901", "SUB_DISTRICT_NAME": "ตะโละ", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8673, "SUB_DISTRICT_CODE": "940902", "SUB_DISTRICT_NAME": "ตะโละกาโปร์", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8674, "SUB_DISTRICT_CODE": "940903", "SUB_DISTRICT_NAME": "ตันหยงดาลอ", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8675, "SUB_DISTRICT_CODE": "940904", "SUB_DISTRICT_NAME": "ตันหยงจึงงา", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8676, "SUB_DISTRICT_CODE": "940905", "SUB_DISTRICT_NAME": "ตอหลัง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8677, "SUB_DISTRICT_CODE": "940906", "SUB_DISTRICT_NAME": "ตาแกะ", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8678, "SUB_DISTRICT_CODE": "940907", "SUB_DISTRICT_NAME": "ตาลีอายร์", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8679, "SUB_DISTRICT_CODE": "940908", "SUB_DISTRICT_NAME": "ยามู", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8680, "SUB_DISTRICT_CODE": "940909", "SUB_DISTRICT_NAME": "บางปู", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8681, "SUB_DISTRICT_CODE": "940910", "SUB_DISTRICT_NAME": "หนองแรต", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8682, "SUB_DISTRICT_CODE": "940911", "SUB_DISTRICT_NAME": "ปิยามุมัง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8683, "SUB_DISTRICT_CODE": "940912", "SUB_DISTRICT_NAME": "ปุลากง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8684, "SUB_DISTRICT_CODE": "940913", "SUB_DISTRICT_NAME": "บาโลย", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8685, "SUB_DISTRICT_CODE": "940914", "SUB_DISTRICT_NAME": "สาบัน", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8686, "SUB_DISTRICT_CODE": "940915", "SUB_DISTRICT_NAME": "มะนังยง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8687, "SUB_DISTRICT_CODE": "940916", "SUB_DISTRICT_NAME": "ราตาปันยัง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8688, "SUB_DISTRICT_CODE": "940917", "SUB_DISTRICT_NAME": "จะรัง", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8689, "SUB_DISTRICT_CODE": "940918", "SUB_DISTRICT_NAME": "แหลมโพธิ์", "DISTRICT_ID": 973, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8690, "SUB_DISTRICT_CODE": "941001", "SUB_DISTRICT_NAME": "ยะรัง", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8691, "SUB_DISTRICT_CODE": "941002", "SUB_DISTRICT_NAME": "สะดาวา", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8692, "SUB_DISTRICT_CODE": "941003", "SUB_DISTRICT_NAME": "ประจัน", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8693, "SUB_DISTRICT_CODE": "941004", "SUB_DISTRICT_NAME": "สะนอ", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8694, "SUB_DISTRICT_CODE": "941005", "SUB_DISTRICT_NAME": "ระแว้ง", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8695, "SUB_DISTRICT_CODE": "941006", "SUB_DISTRICT_NAME": "ปิตูมุดี", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8696, "SUB_DISTRICT_CODE": "941007", "SUB_DISTRICT_NAME": "วัด", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8697, "SUB_DISTRICT_CODE": "941008", "SUB_DISTRICT_NAME": "กระโด", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8698, "SUB_DISTRICT_CODE": "941009", "SUB_DISTRICT_NAME": "คลองใหม่", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8699, "SUB_DISTRICT_CODE": "941010", "SUB_DISTRICT_NAME": "เมาะมาวี", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8700, "SUB_DISTRICT_CODE": "941011", "SUB_DISTRICT_NAME": "กอลำ", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8701, "SUB_DISTRICT_CODE": "941012", "SUB_DISTRICT_NAME": "เขาตูม", "DISTRICT_ID": 974, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8702, "SUB_DISTRICT_CODE": "941101", "SUB_DISTRICT_NAME": "กะรุบี", "DISTRICT_ID": 975, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8703, "SUB_DISTRICT_CODE": "941102", "SUB_DISTRICT_NAME": "ตะโละดือรามัน", "DISTRICT_ID": 975, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8704, "SUB_DISTRICT_CODE": "941103", "SUB_DISTRICT_NAME": "ปล่องหอย", "DISTRICT_ID": 975, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8705, "SUB_DISTRICT_CODE": "941201", "SUB_DISTRICT_NAME": "แม่ลาน", "DISTRICT_ID": 976, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8706, "SUB_DISTRICT_CODE": "941202", "SUB_DISTRICT_NAME": "ม่วงเตี้ย", "DISTRICT_ID": 976, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8707, "SUB_DISTRICT_CODE": "941203", "SUB_DISTRICT_NAME": "ป่าไร่", "DISTRICT_ID": 976, "PROVINCE_ID": 74, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8708, "SUB_DISTRICT_CODE": "950101", "SUB_DISTRICT_NAME": "สะเตง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8709, "SUB_DISTRICT_CODE": "950102", "SUB_DISTRICT_NAME": "บุดี", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8710, "SUB_DISTRICT_CODE": "950103", "SUB_DISTRICT_NAME": "ยุโป", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8711, "SUB_DISTRICT_CODE": "950104", "SUB_DISTRICT_NAME": "ลิดล", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8712, "SUB_DISTRICT_CODE": "950105", "SUB_DISTRICT_NAME": "*ปุโรง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8713, "SUB_DISTRICT_CODE": "950106", "SUB_DISTRICT_NAME": "ยะลา", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8714, "SUB_DISTRICT_CODE": "950107", "SUB_DISTRICT_NAME": "*สะเอะ", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8715, "SUB_DISTRICT_CODE": "950108", "SUB_DISTRICT_NAME": "ท่าสาป", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8716, "SUB_DISTRICT_CODE": "950109", "SUB_DISTRICT_NAME": "ลำใหม่", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8717, "SUB_DISTRICT_CODE": "950110", "SUB_DISTRICT_NAME": "หน้าถ้ำ", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8718, "SUB_DISTRICT_CODE": "950111", "SUB_DISTRICT_NAME": "ลำพะยา", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8719, "SUB_DISTRICT_CODE": "950112", "SUB_DISTRICT_NAME": "เปาะเส้ง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8720, "SUB_DISTRICT_CODE": "950113", "SUB_DISTRICT_NAME": "*กรงปินัง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8721, "SUB_DISTRICT_CODE": "950114", "SUB_DISTRICT_NAME": "พร่อน", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8722, "SUB_DISTRICT_CODE": "950115", "SUB_DISTRICT_NAME": "บันนังสาเรง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8723, "SUB_DISTRICT_CODE": "950116", "SUB_DISTRICT_NAME": "สะเตงนอก", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8724, "SUB_DISTRICT_CODE": "950117", "SUB_DISTRICT_NAME": "*ห้วยกระทิง", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8725, "SUB_DISTRICT_CODE": "950118", "SUB_DISTRICT_NAME": "ตาเซะ", "DISTRICT_ID": 977, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8726, "SUB_DISTRICT_CODE": "950201", "SUB_DISTRICT_NAME": "เบตง", "DISTRICT_ID": 978, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8727, "SUB_DISTRICT_CODE": "950202", "SUB_DISTRICT_NAME": "ยะรม", "DISTRICT_ID": 978, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8728, "SUB_DISTRICT_CODE": "950203", "SUB_DISTRICT_NAME": "ตาเนาะแมเราะ", "DISTRICT_ID": 978, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8729, "SUB_DISTRICT_CODE": "950204", "SUB_DISTRICT_NAME": "อัยเยอร์เวง", "DISTRICT_ID": 978, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8730, "SUB_DISTRICT_CODE": "950205", "SUB_DISTRICT_NAME": "ธารน้ำทิพย์", "DISTRICT_ID": 978, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8731, "SUB_DISTRICT_CODE": "950301", "SUB_DISTRICT_NAME": "บันนังสตา", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8732, "SUB_DISTRICT_CODE": "950302", "SUB_DISTRICT_NAME": "บาเจาะ", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8733, "SUB_DISTRICT_CODE": "950303", "SUB_DISTRICT_NAME": "ตาเนาะปูเต๊ะ", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8734, "SUB_DISTRICT_CODE": "950304", "SUB_DISTRICT_NAME": "ถ้ำทะลุ", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8735, "SUB_DISTRICT_CODE": "950305", "SUB_DISTRICT_NAME": "ตลิ่งชัน", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8736, "SUB_DISTRICT_CODE": "950306", "SUB_DISTRICT_NAME": "เขื่อนบางลาง", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8737, "SUB_DISTRICT_CODE": "950397", "SUB_DISTRICT_NAME": "*แม่หวาด", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8738, "SUB_DISTRICT_CODE": "950398", "SUB_DISTRICT_NAME": "*บ้านแหร", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8739, "SUB_DISTRICT_CODE": "950399", "SUB_DISTRICT_NAME": "*ธารโต", "DISTRICT_ID": 979, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8740, "SUB_DISTRICT_CODE": "950401", "SUB_DISTRICT_NAME": "ธารโต", "DISTRICT_ID": 980, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8741, "SUB_DISTRICT_CODE": "950402", "SUB_DISTRICT_NAME": "บ้านแหร", "DISTRICT_ID": 980, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8742, "SUB_DISTRICT_CODE": "950403", "SUB_DISTRICT_NAME": "แม่หวาด", "DISTRICT_ID": 980, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8743, "SUB_DISTRICT_CODE": "950404", "SUB_DISTRICT_NAME": "คีรีเขต", "DISTRICT_ID": 980, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8744, "SUB_DISTRICT_CODE": "950501", "SUB_DISTRICT_NAME": "ยะหา", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8745, "SUB_DISTRICT_CODE": "950502", "SUB_DISTRICT_NAME": "ละแอ", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8746, "SUB_DISTRICT_CODE": "950503", "SUB_DISTRICT_NAME": "ปะแต", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8747, "SUB_DISTRICT_CODE": "950504", "SUB_DISTRICT_NAME": "บาโร๊ะ", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8748, "SUB_DISTRICT_CODE": "950505", "SUB_DISTRICT_NAME": "*กาบัง", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8749, "SUB_DISTRICT_CODE": "950506", "SUB_DISTRICT_NAME": "ตาชี", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8750, "SUB_DISTRICT_CODE": "950507", "SUB_DISTRICT_NAME": "บาโงยซิแน", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8751, "SUB_DISTRICT_CODE": "950508", "SUB_DISTRICT_NAME": "กาตอง", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8752, "SUB_DISTRICT_CODE": "950509", "SUB_DISTRICT_NAME": "*บาละ", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8753, "SUB_DISTRICT_CODE": "950599", "SUB_DISTRICT_NAME": "*กาบัง", "DISTRICT_ID": 981, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8754, "SUB_DISTRICT_CODE": "950601", "SUB_DISTRICT_NAME": "กายูบอเกาะ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8755, "SUB_DISTRICT_CODE": "950602", "SUB_DISTRICT_NAME": "กาลูปัง", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8756, "SUB_DISTRICT_CODE": "950603", "SUB_DISTRICT_NAME": "กาลอ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8757, "SUB_DISTRICT_CODE": "950604", "SUB_DISTRICT_NAME": "กอตอตือร๊ะ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8758, "SUB_DISTRICT_CODE": "950605", "SUB_DISTRICT_NAME": "โกตาบารู", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8759, "SUB_DISTRICT_CODE": "950606", "SUB_DISTRICT_NAME": "เกะรอ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8760, "SUB_DISTRICT_CODE": "950607", "SUB_DISTRICT_NAME": "จะกว๊ะ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8761, "SUB_DISTRICT_CODE": "950608", "SUB_DISTRICT_NAME": "ท่าธง", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8762, "SUB_DISTRICT_CODE": "950609", "SUB_DISTRICT_NAME": "เนินงาม", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8763, "SUB_DISTRICT_CODE": "950610", "SUB_DISTRICT_NAME": "บาลอ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8764, "SUB_DISTRICT_CODE": "950611", "SUB_DISTRICT_NAME": "บาโงย", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8765, "SUB_DISTRICT_CODE": "950612", "SUB_DISTRICT_NAME": "บือมัง", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8766, "SUB_DISTRICT_CODE": "950613", "SUB_DISTRICT_NAME": "ยะต๊ะ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8767, "SUB_DISTRICT_CODE": "950614", "SUB_DISTRICT_NAME": "วังพญา", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8768, "SUB_DISTRICT_CODE": "950615", "SUB_DISTRICT_NAME": "อาซ่อง", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8769, "SUB_DISTRICT_CODE": "950616", "SUB_DISTRICT_NAME": "ตะโล๊ะหะลอ", "DISTRICT_ID": 982, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8770, "SUB_DISTRICT_CODE": "950701", "SUB_DISTRICT_NAME": "กาบัง", "DISTRICT_ID": 983, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8771, "SUB_DISTRICT_CODE": "950702", "SUB_DISTRICT_NAME": "บาละ", "DISTRICT_ID": 983, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8772, "SUB_DISTRICT_CODE": "950801", "SUB_DISTRICT_NAME": "กรงปินัง", "DISTRICT_ID": 984, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8773, "SUB_DISTRICT_CODE": "950802", "SUB_DISTRICT_NAME": "สะเอะ", "DISTRICT_ID": 984, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8774, "SUB_DISTRICT_CODE": "950803", "SUB_DISTRICT_NAME": "ห้วยกระทิง", "DISTRICT_ID": 984, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8775, "SUB_DISTRICT_CODE": "950804", "SUB_DISTRICT_NAME": "ปุโรง", "DISTRICT_ID": 984, "PROVINCE_ID": 75, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8776, "SUB_DISTRICT_CODE": "960101", "SUB_DISTRICT_NAME": "บางนาค", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8777, "SUB_DISTRICT_CODE": "960102", "SUB_DISTRICT_NAME": "ลำภู", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8778, "SUB_DISTRICT_CODE": "960103", "SUB_DISTRICT_NAME": "มะนังตายอ", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8779, "SUB_DISTRICT_CODE": "960104", "SUB_DISTRICT_NAME": "บางปอ", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8780, "SUB_DISTRICT_CODE": "960105", "SUB_DISTRICT_NAME": "กะลุวอ", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8781, "SUB_DISTRICT_CODE": "960106", "SUB_DISTRICT_NAME": "กะลุวอเหนือ", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8782, "SUB_DISTRICT_CODE": "960107", "SUB_DISTRICT_NAME": "โคกเคียน", "DISTRICT_ID": 985, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8783, "SUB_DISTRICT_CODE": "960201", "SUB_DISTRICT_NAME": "เจ๊ะเห", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8784, "SUB_DISTRICT_CODE": "960202", "SUB_DISTRICT_NAME": "ไพรวัน", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8785, "SUB_DISTRICT_CODE": "960203", "SUB_DISTRICT_NAME": "พร่อน", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8786, "SUB_DISTRICT_CODE": "960204", "SUB_DISTRICT_NAME": "ศาลาใหม่", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8787, "SUB_DISTRICT_CODE": "960205", "SUB_DISTRICT_NAME": "บางขุนทอง", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8788, "SUB_DISTRICT_CODE": "960206", "SUB_DISTRICT_NAME": "เกาะสะท้อน", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8789, "SUB_DISTRICT_CODE": "960207", "SUB_DISTRICT_NAME": "นานาค", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8790, "SUB_DISTRICT_CODE": "960208", "SUB_DISTRICT_NAME": "โฆษิต", "DISTRICT_ID": 986, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8791, "SUB_DISTRICT_CODE": "960301", "SUB_DISTRICT_NAME": "บาเจาะ", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8792, "SUB_DISTRICT_CODE": "960302", "SUB_DISTRICT_NAME": "ลุโบะสาวอ", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8793, "SUB_DISTRICT_CODE": "960303", "SUB_DISTRICT_NAME": "กาเยาะมาตี", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8794, "SUB_DISTRICT_CODE": "960304", "SUB_DISTRICT_NAME": "ปะลุกาสาเมาะ", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8795, "SUB_DISTRICT_CODE": "960305", "SUB_DISTRICT_NAME": "บาเระเหนือ", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8796, "SUB_DISTRICT_CODE": "960306", "SUB_DISTRICT_NAME": "บาเระใต้", "DISTRICT_ID": 987, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8797, "SUB_DISTRICT_CODE": "960401", "SUB_DISTRICT_NAME": "ยี่งอ", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8798, "SUB_DISTRICT_CODE": "960402", "SUB_DISTRICT_NAME": "ละหาร", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8799, "SUB_DISTRICT_CODE": "960403", "SUB_DISTRICT_NAME": "จอเบาะ", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8800, "SUB_DISTRICT_CODE": "960404", "SUB_DISTRICT_NAME": "ลุโบะบายะ", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8801, "SUB_DISTRICT_CODE": "960405", "SUB_DISTRICT_NAME": "ลุโบะบือซา", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8802, "SUB_DISTRICT_CODE": "960406", "SUB_DISTRICT_NAME": "ตะปอเยาะ", "DISTRICT_ID": 988, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8803, "SUB_DISTRICT_CODE": "960501", "SUB_DISTRICT_NAME": "ตันหยงมัส", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8804, "SUB_DISTRICT_CODE": "960502", "SUB_DISTRICT_NAME": "ตันหยงลิมอ", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8805, "SUB_DISTRICT_CODE": "960503", "SUB_DISTRICT_NAME": "*จวบ", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8806, "SUB_DISTRICT_CODE": "960504", "SUB_DISTRICT_NAME": "*มะรือโบตะวันออก", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8807, "SUB_DISTRICT_CODE": "960505", "SUB_DISTRICT_NAME": "*บูกิต", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8808, "SUB_DISTRICT_CODE": "960506", "SUB_DISTRICT_NAME": "บองอ", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8809, "SUB_DISTRICT_CODE": "960507", "SUB_DISTRICT_NAME": "กาลิซา", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8810, "SUB_DISTRICT_CODE": "960508", "SUB_DISTRICT_NAME": "บาโงสะโต", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8811, "SUB_DISTRICT_CODE": "960509", "SUB_DISTRICT_NAME": "เฉลิม", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8812, "SUB_DISTRICT_CODE": "960510", "SUB_DISTRICT_NAME": "มะรือโบตก", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8813, "SUB_DISTRICT_CODE": "960598", "SUB_DISTRICT_NAME": "*ดุซงญอ", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8814, "SUB_DISTRICT_CODE": "960599", "SUB_DISTRICT_NAME": "*จะแนะ", "DISTRICT_ID": 989, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8815, "SUB_DISTRICT_CODE": "960601", "SUB_DISTRICT_NAME": "รือเสาะ", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8816, "SUB_DISTRICT_CODE": "960602", "SUB_DISTRICT_NAME": "สาวอ", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8817, "SUB_DISTRICT_CODE": "960603", "SUB_DISTRICT_NAME": "เรียง", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8818, "SUB_DISTRICT_CODE": "960604", "SUB_DISTRICT_NAME": "สามัคคี", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8819, "SUB_DISTRICT_CODE": "960605", "SUB_DISTRICT_NAME": "บาตง", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8820, "SUB_DISTRICT_CODE": "960606", "SUB_DISTRICT_NAME": "ลาโละ", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8821, "SUB_DISTRICT_CODE": "960607", "SUB_DISTRICT_NAME": "รือเสาะออก", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8822, "SUB_DISTRICT_CODE": "960608", "SUB_DISTRICT_NAME": "โคกสะตอ", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8823, "SUB_DISTRICT_CODE": "960609", "SUB_DISTRICT_NAME": "สุวารี", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8824, "SUB_DISTRICT_CODE": "960698", "SUB_DISTRICT_NAME": "*ตะมะยูง", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8825, "SUB_DISTRICT_CODE": "960699", "SUB_DISTRICT_NAME": "*ชากอ", "DISTRICT_ID": 990, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8826, "SUB_DISTRICT_CODE": "960701", "SUB_DISTRICT_NAME": "ซากอ", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8827, "SUB_DISTRICT_CODE": "960702", "SUB_DISTRICT_NAME": "ตะมะยูง", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8828, "SUB_DISTRICT_CODE": "960703", "SUB_DISTRICT_NAME": "ศรีสาคร", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8829, "SUB_DISTRICT_CODE": "960704", "SUB_DISTRICT_NAME": "เชิงคีรี", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8830, "SUB_DISTRICT_CODE": "960705", "SUB_DISTRICT_NAME": "กาหลง", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8831, "SUB_DISTRICT_CODE": "960706", "SUB_DISTRICT_NAME": "ศรีบรรพต", "DISTRICT_ID": 991, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8832, "SUB_DISTRICT_CODE": "960801", "SUB_DISTRICT_NAME": "แว้ง", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8833, "SUB_DISTRICT_CODE": "960802", "SUB_DISTRICT_NAME": "กายูคละ", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8834, "SUB_DISTRICT_CODE": "960803", "SUB_DISTRICT_NAME": "ฆอเลาะ", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8835, "SUB_DISTRICT_CODE": "960804", "SUB_DISTRICT_NAME": "โละจูด", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8836, "SUB_DISTRICT_CODE": "960805", "SUB_DISTRICT_NAME": "แม่ดง", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8837, "SUB_DISTRICT_CODE": "960806", "SUB_DISTRICT_NAME": "เอราวัณ", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8838, "SUB_DISTRICT_CODE": "960899", "SUB_DISTRICT_NAME": "*มาโม", "DISTRICT_ID": 992, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8839, "SUB_DISTRICT_CODE": "960901", "SUB_DISTRICT_NAME": "มาโมง", "DISTRICT_ID": 993, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8840, "SUB_DISTRICT_CODE": "960902", "SUB_DISTRICT_NAME": "สุคิริน", "DISTRICT_ID": 993, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8841, "SUB_DISTRICT_CODE": "960903", "SUB_DISTRICT_NAME": "เกียร์", "DISTRICT_ID": 993, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8842, "SUB_DISTRICT_CODE": "960904", "SUB_DISTRICT_NAME": "ภูเขาทอง", "DISTRICT_ID": 993, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8843, "SUB_DISTRICT_CODE": "960905", "SUB_DISTRICT_NAME": "ร่มไทร", "DISTRICT_ID": 993, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8844, "SUB_DISTRICT_CODE": "961001", "SUB_DISTRICT_NAME": "สุไหงโก-ลก", "DISTRICT_ID": 994, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8845, "SUB_DISTRICT_CODE": "961002", "SUB_DISTRICT_NAME": "ปาเสมัส", "DISTRICT_ID": 994, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8846, "SUB_DISTRICT_CODE": "961003", "SUB_DISTRICT_NAME": "มูโนะ", "DISTRICT_ID": 994, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8847, "SUB_DISTRICT_CODE": "961004", "SUB_DISTRICT_NAME": "ปูโยะ", "DISTRICT_ID": 994, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8848, "SUB_DISTRICT_CODE": "961101", "SUB_DISTRICT_NAME": "ปะลุรู", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8849, "SUB_DISTRICT_CODE": "961102", "SUB_DISTRICT_NAME": "สุไหงปาดี", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8850, "SUB_DISTRICT_CODE": "961103", "SUB_DISTRICT_NAME": "โต๊ะเด็ง", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8851, "SUB_DISTRICT_CODE": "961104", "SUB_DISTRICT_NAME": "สากอ", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8852, "SUB_DISTRICT_CODE": "961105", "SUB_DISTRICT_NAME": "ริโก๋", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8853, "SUB_DISTRICT_CODE": "961106", "SUB_DISTRICT_NAME": "กาวะ", "DISTRICT_ID": 995, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8854, "SUB_DISTRICT_CODE": "961201", "SUB_DISTRICT_NAME": "จะแนะ", "DISTRICT_ID": 996, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8855, "SUB_DISTRICT_CODE": "961202", "SUB_DISTRICT_NAME": "ดุซงญอ", "DISTRICT_ID": 996, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8856, "SUB_DISTRICT_CODE": "961203", "SUB_DISTRICT_NAME": "ผดุงมาตร", "DISTRICT_ID": 996, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8857, "SUB_DISTRICT_CODE": "961204", "SUB_DISTRICT_NAME": "ช้างเผือก", "DISTRICT_ID": 996, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8858, "SUB_DISTRICT_CODE": "961301", "SUB_DISTRICT_NAME": "จวบ", "DISTRICT_ID": 997, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8859, "SUB_DISTRICT_CODE": "961302", "SUB_DISTRICT_NAME": "บูกิต", "DISTRICT_ID": 997, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8860, "SUB_DISTRICT_CODE": "961303", "SUB_DISTRICT_NAME": "มะรือโบออก", "DISTRICT_ID": 997, "PROVINCE_ID": 76, "GEO_ID": 6 },
    { "SUB_DISTRICT_ID": 8861, "SUB_DISTRICT_CODE": "380101", "SUB_DISTRICT_NAME": "คำนาดี", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8862, "SUB_DISTRICT_CODE": "380102", "SUB_DISTRICT_NAME": "โคกก่อง", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8863, "SUB_DISTRICT_CODE": "380103", "SUB_DISTRICT_NAME": "ไคสี", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8864, "SUB_DISTRICT_CODE": "380104", "SUB_DISTRICT_NAME": "ชัยพร", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8865, "SUB_DISTRICT_CODE": "380105", "SUB_DISTRICT_NAME": "นาสวรรค์", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8866, "SUB_DISTRICT_CODE": "380106", "SUB_DISTRICT_NAME": "โนนสมบูรณ์", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8867, "SUB_DISTRICT_CODE": "380107", "SUB_DISTRICT_NAME": "บึงกาฬ", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8868, "SUB_DISTRICT_CODE": "380108", "SUB_DISTRICT_NAME": "โป่งเปือย", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8869, "SUB_DISTRICT_CODE": "380109", "SUB_DISTRICT_NAME": "วิศิษฐ์", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8870, "SUB_DISTRICT_CODE": "380110", "SUB_DISTRICT_NAME": "หนองเข็ง", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8871, "SUB_DISTRICT_CODE": "380111", "SUB_DISTRICT_NAME": "หนองเลิง", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8872, "SUB_DISTRICT_CODE": "380112", "SUB_DISTRICT_NAME": "หอคำ", "DISTRICT_ID": 999, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8873, "SUB_DISTRICT_CODE": "380201", "SUB_DISTRICT_NAME": "ซาง", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8874, "SUB_DISTRICT_CODE": "380202", "SUB_DISTRICT_NAME": "เซกา", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8875, "SUB_DISTRICT_CODE": "380203", "SUB_DISTRICT_NAME": "ท่ากกแดง", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8876, "SUB_DISTRICT_CODE": "380204", "SUB_DISTRICT_NAME": "ท่าสะอาด", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8877, "SUB_DISTRICT_CODE": "380205", "SUB_DISTRICT_NAME": "น้ำจั้น", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8878, "SUB_DISTRICT_CODE": "380206", "SUB_DISTRICT_NAME": "บ้านต้อง", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8879, "SUB_DISTRICT_CODE": "380207", "SUB_DISTRICT_NAME": "ป่งไฮ", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8880, "SUB_DISTRICT_CODE": "380208", "SUB_DISTRICT_NAME": "โสกก่าม", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8881, "SUB_DISTRICT_CODE": "380209", "SUB_DISTRICT_NAME": "หนองทุ่ม", "DISTRICT_ID": 1000, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8882, "SUB_DISTRICT_CODE": "380301", "SUB_DISTRICT_NAME": "คำแก้ว", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8883, "SUB_DISTRICT_CODE": "380302", "SUB_DISTRICT_NAME": "โซ่", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8884, "SUB_DISTRICT_CODE": "380303", "SUB_DISTRICT_NAME": "ถ้ำเจริญ", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8885, "SUB_DISTRICT_CODE": "380304", "SUB_DISTRICT_NAME": "บัวตูม", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8886, "SUB_DISTRICT_CODE": "380305", "SUB_DISTRICT_NAME": "ศรีชมภู", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8887, "SUB_DISTRICT_CODE": "380306", "SUB_DISTRICT_NAME": "หนองพันทา", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8888, "SUB_DISTRICT_CODE": "380307", "SUB_DISTRICT_NAME": "เหล่าทอง", "DISTRICT_ID": 1001, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8889, "SUB_DISTRICT_CODE": "380401", "SUB_DISTRICT_NAME": "ดอนหญ้านาง", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8890, "SUB_DISTRICT_CODE": "380402", "SUB_DISTRICT_NAME": "ป่าแฝก", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8891, "SUB_DISTRICT_CODE": "380403", "SUB_DISTRICT_NAME": "พรเจริญ", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8892, "SUB_DISTRICT_CODE": "380404", "SUB_DISTRICT_NAME": "วังชมภู", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8893, "SUB_DISTRICT_CODE": "380405", "SUB_DISTRICT_NAME": "ศรีชมภู", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8894, "SUB_DISTRICT_CODE": "380406", "SUB_DISTRICT_NAME": "ศรีสำราญ", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8895, "SUB_DISTRICT_CODE": "380407", "SUB_DISTRICT_NAME": "หนองหัวช้าง", "DISTRICT_ID": 1002, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8896, "SUB_DISTRICT_CODE": "380501", "SUB_DISTRICT_NAME": "ชุมภูพร", "DISTRICT_ID": 1003, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8897, "SUB_DISTRICT_CODE": "380502", "SUB_DISTRICT_NAME": "นาสะแบง", "DISTRICT_ID": 1003, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8898, "SUB_DISTRICT_CODE": "380503", "SUB_DISTRICT_NAME": "นาสิงห์", "DISTRICT_ID": 1003, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8899, "SUB_DISTRICT_CODE": "380504", "SUB_DISTRICT_NAME": "นาแสง", "DISTRICT_ID": 1003, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8900, "SUB_DISTRICT_CODE": "380505", "SUB_DISTRICT_NAME": "ศรีวิไล", "DISTRICT_ID": 1003, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8901, "SUB_DISTRICT_CODE": "380601", "SUB_DISTRICT_NAME": "ดงบัง", "DISTRICT_ID": 1004, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8902, "SUB_DISTRICT_CODE": "380602", "SUB_DISTRICT_NAME": "ท่าดอกคำ", "DISTRICT_ID": 1004, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8903, "SUB_DISTRICT_CODE": "380603", "SUB_DISTRICT_NAME": "บึงโขงหลง", "DISTRICT_ID": 1004, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8904, "SUB_DISTRICT_CODE": "380604", "SUB_DISTRICT_NAME": "โพธิ์หมากแข้ง", "DISTRICT_ID": 1004, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8905, "SUB_DISTRICT_CODE": "380701", "SUB_DISTRICT_NAME": "นากั้ง", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8906, "SUB_DISTRICT_CODE": "380702", "SUB_DISTRICT_NAME": "นาดง", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8907, "SUB_DISTRICT_CODE": "380703", "SUB_DISTRICT_NAME": "โนนศิลา", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8908, "SUB_DISTRICT_CODE": "380704", "SUB_DISTRICT_NAME": "ปากคาด", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8909, "SUB_DISTRICT_CODE": "380705", "SUB_DISTRICT_NAME": "สมสนุก", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8910, "SUB_DISTRICT_CODE": "380706", "SUB_DISTRICT_NAME": "หนองยอง", "DISTRICT_ID": 1005, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8911, "SUB_DISTRICT_CODE": "380801", "SUB_DISTRICT_NAME": "โคกกว้าง", "DISTRICT_ID": 1006, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8912, "SUB_DISTRICT_CODE": "380802", "SUB_DISTRICT_NAME": "บุ่งคล้า", "DISTRICT_ID": 1006, "PROVINCE_ID": 77, "GEO_ID": 3 },
    { "SUB_DISTRICT_ID": 8913, "SUB_DISTRICT_CODE": "380803", "SUB_DISTRICT_NAME": "หนองเดิ่น", "DISTRICT_ID": 1006, "PROVINCE_ID": 77, "GEO_ID": 3 }
  ]