import { Button, Snackbar, IconButton, Box, Container, Grid, TextField, Typography, FormControl, InputLabel, OutlinedInput, FormHelperText, Avatar, InputAdornment, Autocomplete, Select, MenuItem, Tooltip } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateProfile } from "../../actions/index"
import { fetchUserProfile, updateUserProfile } from '../../utils/useApi'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { fDate } from '../../utils/formatTime'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const AvatarWrapper = styled(Avatar)`
    position: relative;
    cursor: pointer;
    &:hover::before {
        content: 'Upload';
        opacity: 1;
        font-size: 16px;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 40px 24px;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
    }

    &:hover::after {
        content: '';
        opacity: 0;
        background-size: cover;
        width: 24px;
        height: 24px;
        position: absolute;
        top: -28px;
        left: -60px;
        z-index: 1;
        transition: opacity 1s ease;
    }
`;

const MySelectField = styled(Select)`
    & .MuiInputBase-input {
        display: inline-flex;
        border-radius: 8px;
        line-height: 1.4375em;
        font-size: 0.875rem;
    }
`

const MyDatePickerField = styled(DatePicker)`
    & .MuiInputBase-input {
        display: inline-flex;
        border-radius: 8px;
        line-height: 1.4375em;
        font-size: 0.875rem;
    }
`

const validateEditProfile = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Email is required."),
    firstname: yup.string().nullable().required('Firstname is required'),
    lastname: yup.string().nullable().required('Lastname is required'),
    birthday: yup.date().required('Birthday is required').nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null)),
    gender: yup.string().nullable().required('Gender is required'),
    image: yup.mixed().notRequired(),
})

const EditProfile = () => {
    const { profile, auth } = useSelector(state => state)
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { control, register, handleSubmit, setValue, setFocus, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validateEditProfile),
        mode: "onChange"
    })

    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    //     onDrop: (acceptedFiles) => {
    //         setFile(URL.createObjectURL(acceptedFiles[0]))
    //         setValue('file', acceptedFiles[0])
    //     },
    //     multiple: false,
    //     maxFiles: 1
    // });

    const fetchEdit = async () => {
        const fetch_profile = await fetchUserProfile({ "userName": auth.userName })
        if (fetch_profile.status) {
            dispatch(updateProfile(fetch_profile.data.user))
        } else {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'error',
                title: 'Error',
                text: 'It has some a problem!',
                allowOutsideClick: false
            })
        }
        setValue('email', profile.user.email)
        setValue('firstname', profile.user.firstname)
        setValue('lastname', profile.user.lastname)
        setValue('birthday', profile.user.birthday)
        setValue('gender', profile.user.gender)
        if (profile.user.image) {
            setFile(`${process.env.REACT_APP_IMAGE_DESTINATION_URL}/profile/${profile.user.image}`)
        }

    }
    useEffect(() => {
        setLoading(true)
        fetchEdit()
        setLoading(false)
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleFileChange = (event) => {
        setValue(event.target.name, event.target.files[0])
        setFile(URL.createObjectURL(event.target.files[0]))
    };

    const updateData = async (formData) => {
        const result_edit = await updateUserProfile(formData)
        const fetch_profile = await fetchUserProfile({ "userName": auth.userName })
        if (fetch_profile.status) {
            dispatch(updateProfile(fetch_profile.data.user))
        }
        if (result_edit.status) {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'success',
                title: t('profile_page.edit_profile.alert_message.header_success'),
                text: t('profile_page.edit_profile.alert_message.header_desc_success'),
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/profile")
                }
            })
        } else {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'error',
                title: 'Error',
                text: (result_edit.data.response.data.message === "ERR_EMAIL_EXISTS" ? "Email is duplicate with another an account." : ""),
                allowOutsideClick: false
            })
        }
    }
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append(`user_name`, auth.userName);
        formData.append(`email`, data.email);
        formData.append(`firstname`, data.firstname);
        formData.append(`lastname`, data.lastname);
        formData.append(`birthday`, fDate(data.birthday, "yyyy-MM-dd"));
        formData.append(`gender`, data.gender);
        formData.append('image', data.image || null);
        Swal.fire({
            customClass: {
                container: 'my-swal',
            },
            icon: 'info',
            title: t('profile_page.edit_profile.alert_message.header'),
            text: t('profile_page.edit_profile.alert_message.description'),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('profile_page.edit_profile.alert_message.yes_btn'),
            cancelButtonText: t('profile_page.edit_profile.alert_message.no_btn')
        }).then((result) => {
            if (result.isConfirmed) {
                updateData(formData)
            }
        })
    }

    return (
        <Box>
            <Helmet>
                <title> Edit Profile | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="md" component={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 400 }}>
                    {t('profile_page.edit_profile.header')}
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} my={1}>
                        <FormControl variant="outlined" sx={{ cursor: 'pointer' }}>
                            <div>
                                <input id="avatar-upload" name="image" accept="image/*" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                                <label htmlFor="avatar-upload">
                                    <AvatarWrapper src={file} alt="profile_img" sx={{ width: 96, height: 96 }} />
                                </label>
                            </div>
                            <FormHelperText error={errors.file ? true : false} id="complex-border-textfield-helper-text">
                                {errors.file?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                control={control}
                                name="email"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="email"
                                        label={t('profile_page.edit_profile.email')}
                                        type="email"
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                control={control}
                                name="firstname"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="firstname"
                                        label={t('profile_page.edit_profile.firstname')}
                                        type="text"
                                        error={!!errors.firstname}
                                        helperText={errors.firstname?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                control={control}
                                name="lastname"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="lastname"
                                        label={t('profile_page.edit_profile.lastname')}
                                        type="text"
                                        error={!!errors.lastname}
                                        helperText={errors.lastname?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                name="birthday"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field, fieldState }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MyDatePickerField
                                            {...field}
                                            value={new Date(field.value)}
                                            onChange={(date) => {
                                                field.onChange(date)
                                            }}
                                            label={t('profile_page.edit_profile.birthday')}
                                            inputVariant="outlined"
                                            maxDate={new Date()}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            <FormHelperText error={errors.birthday ? true : false} id="complex-border-textfield-helper-text">
                                {errors.birthday?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="complex-border-textfield">{t('profile_page.edit_profile.gender.header')}</InputLabel>
                            <Controller
                                name="gender"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field, fieldState }) => (
                                    <MySelectField
                                        labelId="amphur-label"
                                        label={t('profile_page.edit_profile.gender.header')}
                                        id="amphur-select"
                                        value={field.value || ''}
                                        onChange={(sub) => {
                                            field.onChange(sub)
                                        }}
                                    >
                                        <MenuItem key={"1"} value={"male"}>
                                            {t('profile_page.edit_profile.gender.male')}
                                        </MenuItem>
                                        <MenuItem key={"2"} value={"female"}>
                                            {t('profile_page.edit_profile.gender.female')}
                                        </MenuItem>
                                    </MySelectField>
                                )}
                            />
                            <FormHelperText error={errors.gender ? true : false} id="complex-border-textfield-helper-text">
                                {errors.gender?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box mt={3} mb={2} display={"flex"} flexDirection={"row-reverse"}>
                    <Button type="submit" variant="contained" color="primary" sx={{ px: 2 }}>
                        {t('profile_page.edit_profile.save_btn')}
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}
// useEffect(() => {
//     setLoading(true)
//     setEmail(profile.user.email)
//     setFirstname(profile.user.firstname)
//     setLastname(profile.user.lastname)
//     setBirthday(profile.user.birthday)
//     setGender(profile.user.gender)
//     setMobile(profile.user.mobile)
//     setAddress(profile.user.address)
//     setCity(profile.user.city)
//     setPostalcode(profile.user.zip_code)
//     setLoading(false)
// }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps
// // variable
// const [email, setEmail] = useState("")
// const [firstname, setFirstname] = useState("")
// const [lastname, setLastname] = useState("")
// const [birthday, setBirthday] = useState(new Date())
// const [gender, setGender] = useState("")
// const [mobile, setMobile] = useState("")
// const [address, setAddress] = useState("")
// const [city, setCity] = useState("")
// const [postalcode, setPostalcode] = useState("")
// const [image, setImage] = useState("")

// // submit function
// const submitProfile = async (event) => {
//     event.preventDefault()
//     const user = {
//         userName: auth.userName,
//         email: event.target.elements.email.value,
//         firstName: event.target.elements.firstname.value,
//         lastName: event.target.elements.lastname.value,
//         birthday: event.target.elements.birthday.value,
//         gender: event.target.elements.gender.value,
//         address: event.target.elements.address.value,
//         mobile: event.target.elements.mobile.value,
//         city: event.target.elements.city.value,
//         zip_code: event.target.elements.postalcode.value
//     }
//     const local_user = {
//         email: event.target.elements.email.value,
//         firstname: event.target.elements.firstname.value,
//         lastname: event.target.elements.lastname.value,
//         birthday: event.target.elements.birthday.value,
//         gender: event.target.elements.gender.value,
//         address: event.target.elements.address.value,
//         mobile: event.target.elements.mobile.value,
//         city: event.target.elements.city.value,
//         zip_code: event.target.elements.postalcode.value
//     }
//     const result_edit = await updateUserProfile(JSON.stringify(user))
//     if (result_edit.status) {
//         dispatch(updateProfile(local_user))
//         setOpen(true)
//         window.location.replace("/profile")
//     }
// }
// const [open, setOpen] = useState(false);
// const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//         return;
//     }

//     setOpen(false);
// };
// const action = (
//     <>
//         <IconButton
//             size="small"
//             aria-label="close"
//             color="inherit"
//             onClick={handleClose}
//         >
//             <CloseIcon fontSize="small" />
//         </IconButton>
//     </>
// );
// return (
//     <Container>
//         <h3 className="mb-4">Edit Profile</h3>
//         <Form method="GET" onSubmit={submitProfile}>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="email">
//                             Email
//                         </Label>
//                         <Input
//                             id="email"
//                             name="email"
//                             placeholder="Email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             type="email"
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="firstname">
//                             First name
//                         </Label>
//                         <Input
//                             id="firstname"
//                             name="firstname"
//                             placeholder="Firstname"
//                             value={firstname}
//                             onChange={(e) => setFirstname(e.target.value)}
//                             type="text"
//                         />
//                     </FormGroup>
//                 </Col>
//                 <Col>
//                     <FormGroup>
//                         <Label for="lastname">
//                             Last name
//                         </Label>
//                         <Input
//                             id="lastname"
//                             name="lastname"
//                             placeholder="Lastname"
//                             value={lastname}
//                             onChange={(e) => setLastname(e.target.value)}
//                             type="text"
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="birthday">
//                             Birthday
//                         </Label>
//                         <Input
//                             id="birthday"
//                             name="birthday"
//                             placeholder="birthday"
//                             value={fDate(birthday,"yyyy-MM-dd")}
//                             onChange={(e) => setBirthday(e.target.value)}
//                             type="date"
//                         />
//                     </FormGroup>
//                 </Col>
//                 <Col>
//                     <FormGroup>
//                         <Label for="gender">
//                             Gender
//                         </Label>
//                         <Input
//                             id="gender"
//                             name="gender"
//                             type="select"
//                             value={gender}
//                             onChange={(e) => setGender(e.target.value)}
//                         >
//                             <option value={"male"}>
//                                 Male
//                             </option>
//                             <option value={"female"}>
//                                 Female
//                             </option>
//                         </Input>
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="mobile">
//                             Mobile Phone
//                         </Label>
//                         <Input
//                             id="mobile"
//                             name="mobile"
//                             type="tel"
//                             value={mobile}
//                             onChange={(e) => setMobile(e.target.value)}
//                             placeholder='08X-XXX-XXXX'
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="address">
//                             Address
//                         </Label>
//                         <Input
//                             id="address"
//                             name="address"
//                             type="textarea"
//                             value={address}
//                             onChange={(e) => setAddress(e.target.value)}
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="city">
//                             City
//                         </Label>
//                         <Input
//                             id="city"
//                             name="city"
//                             placeholder="City"
//                             type="text"
//                             value={city}
//                             onChange={(e) => setCity(e.target.value)}
//                         />
//                     </FormGroup>
//                 </Col>
//                 <Col>
//                     <FormGroup>
//                         <Label for="postalcode">
//                             Zip Code
//                         </Label>
//                         <Input
//                             id="postalcode"
//                             name="postalcode"
//                             placeholder="Zip Code"
//                             type="text"
//                             value={postalcode}
//                             onChange={(e) => setPostalcode(e.target.value)}
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <FormGroup>
//                         <Label for="image">
//                             Upload Image
//                         </Label>
//                         <Input
//                             id="image"
//                             name="image"
//                             placeholder="image"
//                             type="file"
//                             value={image}
//                             onChange={(e) => setImage(e.target.value)}
//                         />
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row className='mt-3 justify-content-center'>
//                 <Col lg={3} md={3}>
//                     <Button variant="contained" color="success" type="submit">Save</Button>
//                 </Col>
//             </Row>
//         </Form>
//         <Snackbar
//             open={open}
//             autoHideDuration={6000}
//             onClose={handleClose}
//             message="Save successfully!"
//             anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//             action={action}
//         />
//     </Container>

export default EditProfile