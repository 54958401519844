let initialState = {
  userName: null,
  refreshToken: {
    token: null,
    expire: null
  },
  accessToken: {
    token: null,
    expire: null
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        refreshToken: action.refresh_tok,
        accessToken: action.access_tok
      };
    case "RESET_TOKEN":
      return {
        ...state,
        refreshToken: {
          token: null,
          expire: null
        },
        accessToken: {
          token: null,
          expire: null
        }
      }
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: {
          token: action.token,
          expire: action.date
        },
      }
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: {
          token: action.token,
          expire: action.date
        },
      }
    case "UPDATE_USERNAME":
      return {
        ...state,
        userName: action.payload
      }
    case "LOG_OUT":
      return initialState
    default:
      return state;
  }
};
export default authReducer;