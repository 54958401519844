import { Box, Button, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import HeartIcon from '../../assets/icon/heart_cardiogram.svg'
import DrugIcon from '../../assets/icon/medicines.svg'
import PediatricIcon from '../../assets/icon/pediatric.svg'
import LabIcon from '../../assets/icon/laboratory.svg'
import AOS from 'aos'
import 'aos/dist/aos.css';

AOS.init()

const DetailService = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 100%;
    align-items: center;
`
const ServiceSection = () => {
    return (
        <>
            <Box pt={2} sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <img src={require("../../assets/slide/carousel-1.webp")} alt="married" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: "#a8cecc",
                            padding: "30px",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <img src={HeartIcon} alt="heart_cardio" width="50px" height="50px" />
                            <Typography py={1} variant="h3" component="h3" sx={{ letterSpacing: "1px", fontWeight: "bold", textAlign: "center", color: "#FFFFFF" }}>
                                Our Services
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#FFFFFF" }}>
                                Visiting your doctor on a regular basis is key to a healthy life. Whether you're feeling the effects of the common cold or just coming in for a checkup, we want to make sure you have all...
                            </Typography>
                            <Link href="/about/contact" underline="none" my={3}>
                                <Button disableElevation disableRipple sx={{
                                    borderRadius: 0, backgroundColor: "#daebea", color: "#111111", px: 5, py: 1, '&:hover': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}>
                                    <Typography variant="p" sx={{ letterSpacing: "3px", fontWeight: 700 }}>
                                        CONTACT US
                                    </Typography>
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img src={require("../../assets/slide/carousel-2.webp")} alt="single" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </Grid>
                </Grid>
            </Box >
            <Box py={2} sx={{ backgroundColor: "#536766", justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={3}>
                        <DetailService>
                            <img src={HeartIcon} alt="heart_cardio" width="50px" height="50px" data-aos='flip-up' data-aos-delay='500' data-aos-duration="1000"/>
                            <Typography py={1} variant="h6" sx={{ fontWeight: 400, textAlign: "center", color: "#FFFFFF", textTransform: "uppercase" }}>
                                ECHOCARDIOGRAMS
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#FFFFFF" }}>
                                Associates is able to view the beating of the heart and many of its structures.
                            </Typography>
                        </DetailService>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DetailService>
                            <img src={DrugIcon} alt="drug" width="50px" height="50px" data-aos='flip-up' data-aos-delay='500' data-aos-duration="1000"/>
                            <Typography py={1} variant="h6" sx={{ fontWeight: 400, textAlign: "center", color: "#FFFFFF", textTransform: "uppercase" }}>
                                Dermatology
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#FFFFFF" }}>
                                Our providers are trained to handle a spectrum of skin conditions, covering the majority of your dermatology.
                            </Typography>
                        </DetailService>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DetailService>
                            <img src={PediatricIcon} alt="pediatric" width="50px" height="50px" data-aos='flip-up' data-aos-delay='500' data-aos-duration="1000"/>
                            <Typography py={1} variant="h6" sx={{ fontWeight: 400, textAlign: "center", color: "#FFFFFF", textTransform: "uppercase" }}>
                                Pediatric
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#FFFFFF" }}>
                                Our clinic pediatricians can be arranged prior to or after your hospital/birth discharge.
                            </Typography>
                        </DetailService>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <DetailService>
                            <img src={LabIcon} alt="laboratory" width="50px" height="50px" data-aos='flip-up' data-aos-delay='500' data-aos-duration="1000"/>
                            <Typography py={1} variant="h6" sx={{ fontWeight: 400, textAlign: "center", color: "#FFFFFF", textTransform: "uppercase" }}>
                                Laboratory
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#FFFFFF" }}>
                                Laboratory orders are created within the patient’s electronic medical record (EHR) and transmitted to our laboratory.
                            </Typography>
                        </DetailService>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ServiceSection;