import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyleCard = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5fafa;

    @media (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        width: 340px;
    }
`
const ServiceBox = (props) => {
    return (
        <StyleCard>
            <div style={{ backgroundColor: "#7d9a99", height: "15px", margin: 0 }} />
            <div style={{ padding: "15px"}}>
                <Typography py={2} variant="h2" sx={{ letterSpacing: "1px", fontWeight: "bold", textAlign: "center", color: "#A8CECC" }}>
                    {props.amount}
                </Typography>
                <Typography variant="h6" sx={{ letterSpacing: "3px", fontWeight: "bold", textAlign: "center", color: "#A8CECC", textTransform: "uppercase" }}>
                    {props.title}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ margin: "20px 1px 0" }}>
                    {props.description}
                </Typography>
            </div>
        </StyleCard>
    );
};

export default ServiceBox;