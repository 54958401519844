export const surveyData = [
    //section 1
    {
        id: 'q0001',
        question: 'assessment_question.q1.question',
        type: "text-input",
        input_type: "text",
        helperText: "assessment_question.q1.help_text"
    },
    {
        id: 'q0002',
        question: 'assessment_question.q2.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: 'assessment_question.q2.choice.c0' },
            { id: '2', value: '2', text: 'assessment_question.q2.choice.c1' }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0003',
        question: 'assessment_question.q3.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: 'assessment_question.q3.choice.c0' },
            { id: '2', value: '2', text: 'assessment_question.q3.choice.c1' }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0004',
        question: 'assessment_question.q4.question',
        type: "text-input",
        input_type: "email",
        helperText: "assessment_question.q4.help_text"
    },
    {
        id: 'q0005',
        question: 'assessment_question.q5.question',
        type: "text-input",
        input_type: "number",
        helperText: "assessment_question.q5.help_text"
    },
    {
        id: 'q0006',
        question: 'assessment_question.q6.question',
        type: "text-input",
        input_type: "number",
        helperText: "assessment_question.q6.help_text"
    },
    {
        id: 'q0007',
        question: 'assessment_question.q7.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q7.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q7.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0008',
        question: 'assessment_question.q8.question',
        type: "multi-select",
        choices: [
            { id: '1', value: 'ex_0001', text: "assessment_question.q8.choice.c0" }, //Mushroom
            { id: '2', value: 'ex_0000', text: "assessment_question.q8.choice.c1" }, //Milk
            { id: '3', value: 'ex_0003', text: "assessment_question.q8.choice.c2" }, //Egg
            { id: '4', value: 'ex_0023', text: "assessment_question.q8.choice.c3" }, //Fish
            { id: '5', value: 'ex_0052', text: "assessment_question.q8.choice.c4" }, //Soy
            { id: '6', value: 'ex_0062', text: "assessment_question.q8.choice.c5" }, //Yeast
            { id: '7', value: 'ex_0018', text: "assessment_question.q8.choice.c6" }, //Shell Fish
            { id: '8', value: '8', text: "assessment_question.q8.choice.c7" },       //None
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0009',
        question: 'assessment_question.q9.question',
        type: "text-input",
        input_type: "text",
        helperText: ""
    },
    {
        id: 'q0010',
        question: 'assessment_question.q10.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q10.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q10.choice.c1" }
        ],
        input_type: "text",
        helperText: ""
    },
    {
        id: 'q0011',
        question: 'assessment_question.q11.question',
        type: "multi-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q11.choice.c0", rule: ["1", "2"] },
            { id: '2', value: '2', text: "assessment_question.q11.choice.c1", rule: ["1", "2"] },
            { id: '3', value: '3', text: "assessment_question.q11.choice.c2", rule: ["1", "2"] },
            { id: '4', value: '4', text: "assessment_question.q11.choice.c3", rule: ["1", "2"] },
            { id: '5', value: '5', text: "assessment_question.q11.choice.c4", rule: ["1", "2"] },
            { id: '6', value: '6', text: "assessment_question.q11.choice.c5", rule: ["2"] },
            { id: '7', value: '7', text: "assessment_question.q11.choice.c6", rule: ["2"] },
            { id: '8', value: '8', text: "assessment_question.q11.choice.c7", rule: ["1", "2"] },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0012',
        question: 'assessment_question.q12.question',
        type: "multi-select",
        choices: [
            { id: '1', value: '1', array_list: [22], text: "assessment_question.q12.choice.c0" }, //Mood disorders
            { id: '2', value: '2', array_list: [], text: "assessment_question.q12.choice.c1" },
            { id: '3', value: '3', array_list: [14], text: "assessment_question.q12.choice.c2" }, //Premature aging
            { id: '4', value: '4', array_list: [15], text: "assessment_question.q12.choice.c3" }, //Brain health
            { id: '5', value: '5', array_list: [20], text: "assessment_question.q12.choice.c4" }, //Bone and Joint
            { id: '6', value: '6', array_list: [12, 13], text: "assessment_question.q12.choice.c5" }, //Boost energy
            { id: '7', value: '7', array_list: [16], text: "assessment_question.q12.choice.c6" }, //Detox
            { id: '8', value: '8', array_list: [21], text: "assessment_question.q12.choice.c7" }, //Eyes and vision
            { id: '9', value: '9', array_list: [17], text: "assessment_question.q12.choice.c8" }, //Immune
            { id: '10', value: '10', array_list: [14], text: "assessment_question.q12.choice.c9" }, //Skin
            { id: '11', value: '11', array_list: [12, 13], text: "assessment_question.q12.choice.c10" }, //Sleep
            { id: '12', value: '12', array_list: [19], text: "assessment_question.q12.choice.c11" }, //Weight management
            { id: '13', value: '13', array_list: [18], text: "assessment_question.q12.choice.c12" }, //Sex Health
            { id: '14', value: '14', array_list: [23], text: "assessment_question.q12.choice.c13" }, //Stress
        ],
        input_type: "",
        helperText: ""
    },

    //section 2
    {
        id: 'q0013',
        question: 'assessment_question.q13.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q13.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q13.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0014',
        question: 'assessment_question.q14.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q14.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q14.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q14.subchoice.sc1' }
                ]
            },
            { id: 2, text: 'assessment_question.q14.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0015',
        question: 'assessment_question.q15.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q15.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q15.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q15.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q15.subchoice.sc2' },
                    { id: 4, value: '4', text: 'assessment_question.q15.subchoice.sc3' },
                    { id: 5, value: '5', text: 'assessment_question.q15.subchoice.sc4' },
                    { id: 6, value: '6', text: 'assessment_question.q15.subchoice.sc5' },
                ]
            },
            { id: 2, text: 'assessment_question.q15.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0016',
        question: 'assessment_question.q16.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q16.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q16.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q16.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q16.subchoice.sc2' }
                ]
            },
            { id: 2, text: 'assessment_question.q16.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0017',
        question: 'assessment_question.q17.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q17.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q17.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q17.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q17.subchoice.sc2' },
                    { id: 4, value: '4', text: 'assessment_question.q17.subchoice.sc3' },
                ]
            },
            { id: 2, text: 'assessment_question.q17.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    // {
    //     id: 'q0018',
    //     question: 'assessment_question.q18.question',
    //     type: "single-select",
    //     choices: [
    //         {
    //             id: 1, text: 'assessment_question.q18.choice.c0', value: 'yes', subChoices: [
    //                 { id: 1, value: '1', text: 'assessment_question.q18.subchoice.sc0' },
    //                 { id: 2, value: '2', text: 'assessment_question.q18.subchoice.sc1' },
    //                 { id: 3, value: '3', text: 'assessment_question.q18.subchoice.sc2' },
    //                 { id: 4, value: '4', text: 'assessment_question.q18.subchoice.sc3' },
    //                 { id: 5, value: '5', text: 'assessment_question.q18.subchoice.sc4' },
    //                 { id: 6, value: '6', text: 'assessment_question.q18.subchoice.sc5' },
    //             ]
    //         },
    //         { id: 2, text: 'assessment_question.q18.choice.c1', value: 'no' },
    //     ],
    //     input_type: "",
    //     helperText: ""
    // },
    {
        id: 'q0018',
        question: 'assessment_question.q18.question',
        type: "multi-select",
        choices: [
            { id: 1, value: '1', text: 'assessment_question.q18.subchoice.sc0' },
            { id: 2, value: '2', text: 'assessment_question.q18.subchoice.sc1' },
            { id: 3, value: '3', text: 'assessment_question.q18.subchoice.sc2' },
            { id: 4, value: '4', text: 'assessment_question.q18.subchoice.sc3' },
            { id: 5, value: '5', text: 'assessment_question.q18.subchoice.sc4' },
            { id: 6, value: '6', text: 'assessment_question.q18.subchoice.sc5' },
            { id: 8, value: '8', text: 'assessment_question.q18.choice.c1'},
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0019',
        question: 'assessment_question.q19.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q19.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q19.subchoice.sc0', rule: ["1"] },
                    { id: 2, value: '2', text: 'assessment_question.q19.subchoice.sc1', rule: ["1"] },
                    { id: 3, value: '3', text: 'assessment_question.q19.subchoice.sc2', rule: ["1"] },
                    { id: 4, value: '4', text: 'assessment_question.q19.subchoice.sc3', rule: ["2"] },
                    { id: 5, value: '5', text: 'assessment_question.q19.subchoice.sc4', rule: ["2"] }
                ]
            },
            { id: 2, text: 'assessment_question.q19.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0020',
        question: 'assessment_question.q20.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q20.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q20.choice.c1" },
            { id: '3', value: '3', text: "assessment_question.q20.choice.c2" },
            { id: '4', value: '4', text: "assessment_question.q20.choice.c3" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0021',
        question: 'assessment_question.q21.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q21.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q21.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q21.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q21.subchoice.sc2' },
                    { id: 4, value: '4', text: 'assessment_question.q21.subchoice.sc3' },
                    { id: 5, value: '5', text: 'assessment_question.q21.subchoice.sc4' }
                ]
            },
            { id: 2, text: 'assessment_question.q21.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0022',
        question: 'assessment_question.q22.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q22.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q22.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q22.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q22.subchoice.sc2' }
                ]
            },
            { id: 2, text: 'assessment_question.q21.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0023',
        question: 'assessment_question.q23.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q23.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q23.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q23.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q23.subchoice.sc2' }
                ]
            },
            { id: 2, text: 'assessment_question.q23.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0024',
        question: 'assessment_question.q24.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q24.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q24.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },

    //section 3
    {
        id: 'q0025',
        question: 'assessment_question.q25.question',
        type: "single-select",
        choices: [
            {
                id: 1, text: 'assessment_question.q25.choice.c0', value: 'yes', subChoices: [
                    { id: 1, value: '1', text: 'assessment_question.q25.subchoice.sc0' },
                    { id: 2, value: '2', text: 'assessment_question.q25.subchoice.sc1' },
                    { id: 3, value: '3', text: 'assessment_question.q25.subchoice.sc2' },
                    { id: 4, value: '4', text: 'assessment_question.q25.subchoice.sc3' }
                ]
            },
            { id: 2, text: 'assessment_question.q25.choice.c1', value: 'no' },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0026',
        question: 'assessment_question.q26.question',
        type: "single-select",
        choices: [
            { id: '1', value: '2', text: "assessment_question.q26.choice.c0" },
            { id: '2', value: '1', text: "assessment_question.q26.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0027',
        question: 'assessment_question.q27.question',
        type: "single-select",
        choices: [
            { id: '1', value: '2', text: "assessment_question.q27.choice.c0" },
            { id: '2', value: '1', text: "assessment_question.q27.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0028',
        question: 'assessment_question.q28.question',
        type: "single-select",
        choices: [
            { id: '1', value: '2', text: "assessment_question.q28.choice.c0" },
            { id: '2', value: '1', text: "assessment_question.q28.choice.c1" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0029',
        question: 'assessment_question.q29.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q29.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q29.choice.c1" },
            { id: '3', value: '3', text: "assessment_question.q29.choice.c2" },
            { id: '4', value: '4', text: "assessment_question.q29.choice.c3" }
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0030',
        question: 'assessment_question.q30.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q30.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q30.choice.c1" },
            { id: '3', value: '3', text: "assessment_question.q30.choice.c2" },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0031',
        question: 'assessment_question.q31.question',
        type: "single-select",
        choices: [
            { id: '1', value: '1', text: "assessment_question.q31.choice.c0" },
            { id: '2', value: '2', text: "assessment_question.q31.choice.c1" },
            { id: '3', value: '3', text: "assessment_question.q31.choice.c2" },
        ],
        input_type: "",
        helperText: ""
    },
    {
        id: 'q0032',
        question: 'assessment_question.q32.question',
        type: "multi-select",
        choices: [
            { id: '1', value: 'Website', text: "assessment_question.q32.choice.c0" },
            { id: '2', value: 'Facebook', text: "assessment_question.q32.choice.c1" },
            { id: '3', value: 'Line', text: "assessment_question.q32.choice.c2" },
            { id: '4', value: 'From a friend', text: "assessment_question.q32.choice.c3" },
            { id: '5', value: 'Online search', text: "assessment_question.q32.choice.c4" },
            { id: '6', value: 'TV or Streaming service', text: "assessment_question.q32.choice.c5" },
            { id: '7', value: 'Other', text: "assessment_question.q32.choice.c6" },
        ],
        input_type: "",
        helperText: ""
    }
];