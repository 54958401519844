//Assessment 
export const setAnswers = (newAnswers, index) => {
    return {
        type: "SET_ANSWERS",
        payload: {
            index: index,
            answers: newAnswers
        }
    }
}

export const setAlternativeVitamin = (vitamin) => {
    return {
        type: "SET_ALTERVITAMIN",
        payload: vitamin
    }
}

export const clearAnswers = () => {
    return {
        type: "CLEAR_ANSWER"
    }
}