import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Assessment from './pages/Assessment/Assessment';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Ourteam from './pages/About/Ourteam/Ourteam';
import Contact from './pages/About/Contact/Contact';
import Review from './pages/Explore/Review/Review';
import Products from './pages/Explore/Products/Products';
import NotFoundPage from './pages/404/NotFoundPage';
import LayoutMain from './layout/LayoutMain';
import LayoutAssessment from './layout/LayoutAssessment';
import Protected from './router/Protected';
import { useSelector } from 'react-redux';
import LayoutProfile from './layout/LayoutProfile';
import OTPVerification from './pages/Signup/OTPVerification';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetOTPVerification from './pages/ForgotPassword/ResetOTPVerification';
import VerifyOTP from './pages/VerifyOTP/VerifyOTP';
import VerifyOTPVerification from './pages/VerifyOTP/VerifyOTPVerification';
import Cart from './pages/Cart/Cart';
import ProductDetail from './pages/Explore/Products/ProductDetail';
import Checkout from './pages/Checkout/Checkout';
import SummaryAssessment from './pages/Assessment/SummaryAssessment';
import Payment from './pages/Payment/Payment';
import EditProfile from './pages/Profile/EditProfile';
import Ordershop from './pages/Profile/Ordershop';
import Personalinfo from './pages/Profile/Personalinfo';
import ChangePassword from './pages/Profile/ChangePassword';
import AddressList from './pages/Profile/AddressList';
import Addressaddpage from './pages/Profile/Address/Addressaddpage';
import ReviewOrder from './pages/ReviewOrder/ReviewOrder';
import Addresseditpage from './pages/Profile/Address/Addresseditpage';
import OrderDescription from './pages/Profile/Order/OrderDescription';
import MyReport from './pages/Profile/Report/MyReport'
import Blog from './pages/Blog/Blog';
import DetailArticle from './pages/Blog/DetailArticle';
import useSessionAuth from './utils/useSessionAuth';
import ReviewPage from './pages/Profile/Review/ReviewPage';
import BillPayment from './pages/Payment/BillPayment';
import PromptPayPayment from './pages/Payment/PromptPayPayment';
import LayoutCheckout from './layout/LayoutCheckout';
import CookiePolicyPage from './pages/legal/CookiePolicyPage';
import PrivatePolicyPage from './pages/legal/PrivatePolicyPage';
import TermConditionsPage from './pages/legal/TermConditionsPage';
import PDPAPage from './pages/legal/PDPA';

function App() {
  const { auth } = useSelector(state => state)
  const user = auth.accessToken.token
  useSessionAuth()
  return (

    <main>
      <Routes>
        <Route path="/" element={<LayoutMain />}>
          <Route index element={<Home />} />
          <Route path="about">
            <Route path="ourteam" element={<Ourteam />} />
            <Route path="contact" element={<Contact />} />
            <Route path="cookie-policy" element={<CookiePolicyPage/>}/>
            <Route path="privacy-policy" element={<PrivatePolicyPage/>}/>
            <Route path="terms-conditions" element={<TermConditionsPage/>}/>
            <Route path="pdpa" element={<PDPAPage/>}/>
          </Route>
          <Route path="blog">
            <Route index element={<Blog />} />
            <Route path=":article_id" element={<DetailArticle />} />
          </Route>
          <Route path="explore">
            <Route path="review" element={<Review />} />
            <Route path="products" element={<LayoutCheckout />}>
              <Route index element={<Products />} />
              <Route path=":product_id" element={<ProductDetail />} />
            </Route>
          </Route>
          <Route path="cart">
            <Route index element={<Cart />} />
          </Route>
          <Route path="checkout">
            <Route index element={
              <Protected isLoggedIn={user}>
                <Checkout />
              </Protected>
            } />
          </Route>
          <Route path="summary/:order_id" element={
            <Protected isLoggedIn={user}>
              <ReviewOrder />
            </Protected>
          } />
          <Route path="payment">
            <Route index element={
              <Protected isLoggedIn={user}>
                <Payment />
              </Protected>
            } />
            <Route path="barcode/billpayment" element={
              <Protected isLoggedIn={user}>
                <BillPayment />
              </Protected>
            } />
            <Route path="qr/promptpay" element={
              <Protected isLoggedIn={user}>
                <PromptPayPayment />
              </Protected>
            } />
          </Route>
        </Route>
        <Route path="assessment" element={<LayoutAssessment />}>
          <Route index element={<Assessment />} />
          <Route path="summary" element={<SummaryAssessment />} />
        </Route>
        <Route path="profile" element={
          <Protected isLoggedIn={user}>
            <LayoutProfile />
          </Protected>
        }>
          <Route index element={
            <Personalinfo />
          } />
          <Route path="edit" element={<EditProfile />} />
          <Route path="review" element={<ReviewPage />} />
          <Route path="order">
            <Route index element={<Ordershop />} />
            <Route path=":order_id" element={<OrderDescription />} />
          </Route>
          <Route path="change_password" element={<ChangePassword />} />
          <Route path="report" element={<MyReport />} />
          <Route path="address">
            <Route index element={<AddressList />} />
            <Route path="add" element={<Addressaddpage />} />
            <Route path=":address_id/edit" element={<Addresseditpage />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="login" element={<Login />} />
        <Route path="reset">
          <Route index element={<ForgotPassword />} />
          <Route path="otp-verification" element={<ResetOTPVerification />} />
        </Route>
        <Route path="signup">
          <Route index element={<Signup />} />
          <Route path="otp-verification" element={<OTPVerification />} />
        </Route>
        <Route path="verify">
          <Route index element={<VerifyOTP />} />
          <Route path="otp-verification" element={<VerifyOTPVerification />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;
