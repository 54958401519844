import { Box, Collapse, Divider, IconButton, Link, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../../actions/cart';
import { moneyFormat } from '../../utils/formatCurrency';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

const PaperCartItem = styled(Paper)`
    @media only screen and (max-width: 425px) {
        flex-wrap: wrap
    }
`
const PaperBoxItem = styled(Box)`
    @media only screen and (max-width: 425px) {
        flex-wrap: wrap
    }
`

const ImageItem = styled.img`
    width: 140px;
    aspect-ratio: auto 140/140;
    @media only screen and (max-width: 425px) {
        height: auto;
        min-width: 100%
    }
`

const ProductCardSummary = ({ item, handleDeleteItem }) => {
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.cart)
    const each_id = items.filter((i) => i.product_id === item.product_id)[0]

    const [expanded, setExpanded] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleAddClick = () => {
        dispatch(addToCart(each_id))
    };

    const handleMinusClick = () => {
        dispatch(removeFromCart(each_id))
    };

    return (
        <Paper elevation={0} sx={{ display: "flex", flexDirection: "column", mb: 2.5, backgroundColor: "#f3f2f2", boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.3)', borderRadius: "16px" }} square>
            <PaperBoxItem sx={{ display: "flex", flexDirection: "row" }}>
                <ImageItem src={item.image ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/product_master/${item.image}` : require("../../assets/card-1.jpg")} />
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", rowGap: "16px", p: 2 }}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="body1">
                            {item[`name_${i18n.language}`]}
                        </Typography>
                        <Link onClick={handleDeleteItem}>
                            <Typography align="center" variant="body1" style={{ cursor: "pointer" }}>
                                <CloseIcon />
                            </Typography>
                        </Link>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="body1">
                            ฿ {moneyFormat(item.price)} x {item.qty}
                        </Typography>
                    </Stack>
                    <Stack direction={"row-reverse"} justifyContent={"space-between"}>
                        <Box display="flex" alignItems="end" sx={{ pt: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                ฿ {moneyFormat(item.price * item.qty)}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                            <IconButton disabled={each_id.qty === 1} onClick={handleMinusClick} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.5rem', padding: "5px", minWidth: "0px", minHeight: "0px" }}>
                                <RemoveIcon fontSize='small' color="#d23f57" />
                            </IconButton>
                            <Typography variant='body1' component='h5' textAlign="center" sx={{ fontWeight: 600, lineHeight: 1.5, mx: 1.5, fontSize: "15px" }}>{each_id.qty}</Typography>
                            <IconButton onClick={handleAddClick} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.5rem', padding: "5px", minWidth: "0px", minHeight: "0px" }}>
                                <AddIcon fontSize='small' color="#d23f57" />
                            </IconButton>
                        </Box>
                    </Stack>
                </Box>
            </PaperBoxItem>
            <PaperBoxItem sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ cursor: "pointer", p: 1 }}>
                    <Link onClick={handleExpandClick}>{t('checkout_page.link_expand')}</Link>
                </Box>
                <Divider sx={{ mx: 1.75 }} />
                <Collapse in={expanded}>
                    <Box sx={{ p: 1.5, display: "flex", flexDirection: "row" }}>
                        <Box sx={{ p: 0.5 }}>
                            <img src={require("../../assets/icon/drugs.png")} style={{ width: "35px", height: "35px" }} />
                        </Box>
                        <Box sx={{ p: 0.5 }}>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify" sx={{ fontWeight: "bold" }}>
                                {t('profile_page.order_description.table.recommend')}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: item[`description_${i18n.language}`] || "No Description" }}></div>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <b>{t('profile_page.order_description.table.qty_meal')}</b> : {item['qty_meal'] || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <b>{t('profile_page.order_description.table.timetoeat')}</b> : {item['time'] ? item['time'][`time_name_${i18n.language}`] : "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <b>{t('profile_page.order_description.table.qty_serving')}</b> : {item['qty_serving'] || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                <b>{t('profile_page.order_description.table.fda_no')}</b> : {item['fda_no'] || "N/A"}
                            </Typography>
                        </Box>
                    </Box>
                </Collapse>
            </PaperBoxItem>
        </Paper>
    );
};

export default ProductCardSummary;