import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BlogPostCard from '../NewsCard/BlogPostCard';
import { fetchBlog } from '../../utils/useApi';

const BlogListSection = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const fetchData = async () => {
        const result_fetch = await fetchBlog()
        setLoading(true)
        if (result_fetch.status) {
            setData(result_fetch.data)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} py={3}>
                {data.sort((a, b) => {
                    return b.row_id - a.row_id
                }).map((post, index) => (
                    <BlogPostCard key={index.toString()} post={post} index={index} loading={loading} />
                ))}
            </Grid>
        </Container>
    );
};

export default BlogListSection;