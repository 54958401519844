import { Card, CardMedia, CardContent, Typography, Rating } from '@mui/material';

const CustomerReviewCard = (props) => {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <CardMedia
                alt="Sample"
                image={props.image}
                height="220"
                component="img"
            />
            <CardContent style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Rating size='large' value={props.rating} readOnly sx={{ mb: 2 }} />
                <Typography variant="subtitle2" color="text.secondary" style={{ wordBreak: 'break-all' }}>
                    {props.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default CustomerReviewCard