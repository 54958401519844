import { Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReviewCard from './ReviewCard';
import AOS from 'aos'
import 'aos/dist/aos.css';
import { fetchProductReview } from '../../utils/useApi';

AOS.init()

const ReviewSection = () => {
    const [data, setData] = useState({})
    const fetchData = async () => {
        const result_fetch = await fetchProductReview()
        if (result_fetch.status) {
            setData({...result_fetch.data[0]})
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Container maxWidth="lg">
            <Grid my={3}>
                <Grid item>
                    <div style={{ textAlign: "center", marginBottom: "30px" }}>
                        <Typography gutterBottom variant="h3" color="#ABCECC" component="div" style={{ fontWeight: 700 }}>{data[`header`]}</Typography>
                        <Typography gutterBottom variant="body1" component="div">{data[`description`]}</Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container my={2} spacing={2}>
                <Grid item xs={12} sm={6} md={4} data-aos='flip-up' data-aos-delay='100' data-aos-duration="1500">
                    <ReviewCard img={data[`destination_0`] && `https://whealthvitamin.com:5003${data[`destination_0`]?.replace("..", "")}${data[`img_0`]}`} num="1" description={data[`para_0`]}/>
                    {/* <ReviewCard img={data[`destination_0`] && `http://192.168.1.90:6497${data[`destination_0`]?.replace("..", "")}${data[`img_0`]}`} num="1" description={data[`para_0`]}/> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} data-aos='flip-up' data-aos-delay='200' data-aos-duration="1500" >
                    <ReviewCard img={data[`destination_1`] && `https://whealthvitamin.com:5003${data[`destination_1`]?.replace("..", "")}${data[`img_1`]}`} num="2" description={data[`para_1`]}/>
                    {/* <ReviewCard img={data[`destination_1`] && `http://192.168.1.90:6497${data[`destination_1`]?.replace("..", "")}${data[`img_1`]}`} num="2" description={data[`para_1`]}/> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} data-aos='flip-up' data-aos-delay='300' data-aos-duration="1500">
                    <ReviewCard img={data[`destination_2`] && `https://whealthvitamin.com:5003${data[`destination_2`]?.replace("..", "")}${data[`img_2`]}`} num="3" description={data[`para_2`]} />
                    {/* <ReviewCard img={data[`destination_2`] && `http://192.168.1.90:6497${data[`destination_2`]?.replace("..", "")}${data[`img_2`]}`} num="3" description={data[`para_2`]} /> */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReviewSection;