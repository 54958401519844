import React, { useState, useEffect } from 'react';
import { Box, Button, Container, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useNavigate } from 'react-router-dom';
import { deleteAddressAPI, fetchAddressData, setDefaultaddressAPI } from '../../utils/useApi';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const AddressList = () => {
    const navigate = useNavigate()
    const { userName, accessToken } = useSelector(state => state.auth)
    const [addressData, setAddressData] = useState([])
    const [defaultaddress, setDefaultaddress] = useState("")
    const { t } = useTranslation()

    const fetchData = async () => {
        const result = await fetchAddressData({ "user_name": userName }, accessToken.token)
        if (result.status) {
            setAddressData(result.data.data)
            setDefaultaddress(result.data.main_address)
        }
    }
    const showsetDefaultAddress = () => {
        const result = addressData.find((i) => i.address_id === defaultaddress)
        return result?.info_address
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleDeleteAPI = async (address_id, index) => {
        const result_del = await deleteAddressAPI(JSON.stringify({ 'address_id': address_id }))
        if (result_del.status) {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'success',
                title: t('profile_page.address.address_deletealert.title_success'),
                text: t('profile_page.address.address_deletealert.desc_success'),
                allowOutsideClick: false
            })
            const newItems = [...addressData];
            newItems.splice(index, 1)
            setAddressData(newItems)
        } else {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                title: t('profile_page.address.address_deletealert.title_failed'),
                text: t('profile_page.address.address_deletealert.desc_failed'),
                icon: 'error',
            })
        }

    }
    const handleSetDefaultAddress = (address_id) => {
        Swal.fire({
            customClass: {
                container: 'my-swal',
            },
            title: t('profile_page.address.address_alert.title_confirm'),
            text: t('profile_page.address.address_alert.desc_confirm'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('profile_page.edit_profile.alert_message.yes_btn'),
            cancelButtonText: t('profile_page.edit_profile.alert_message.no_btn')
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result_set = await setDefaultaddressAPI({ "address_id": address_id, "user_name": userName })
                if (result_set.status) {
                    setDefaultaddress(address_id)
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t('profile_page.address.address_alert.title_success'),
                        text: t('profile_page.address.address_alert.desc_success'),
                        icon: 'success',
                    })
                } else {
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t('profile_page.address.address_alert.title_failed'),
                        text: t('profile_page.address.address_alert.desc_failed'),
                        icon: 'error',
                    })
                }
            }
        })
    }

    const handleEditAddress = (address_id) => {
        navigate(`/profile/address/${address_id}/edit`)
    }

    const handleDeleteAddress = (address_id, index) => {
        Swal.fire({
            customClass: {
                container: 'my-swal',
            },
            icon: 'warning',
            title: t('profile_page.address.address_deletealert.title_confirm'),
            text: t('profile_page.address.address_deletealert.desc_confirm'),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('profile_page.edit_profile.alert_message.yes_btn'),
            cancelButtonText: t('profile_page.edit_profile.alert_message.no_btn')
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteAPI(address_id, index)
            }
        })
    }

    return (
        <Box>
            <Helmet>
                <title> Address | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }} display={"flex"} flexDirection={"row"} justifyContent="space-between">
                    <Typography variant="h5" gutterBottom>
                        {t('profile_page.address.header')}
                    </Typography>
                    <Button variant='contained' startIcon={<AddIcon />} onClick={() => navigate('/profile/address/add')} sx={{ textTransform: 'none' }}>
                        {t('profile_page.address.add_btn')}
                    </Button>
                </Box>
                <Box sx={{ mb: 2, p: 1 }} component={Paper}>
                    <Typography variant='h5' component="h6">{t('profile_page.address.default_address')}</Typography>
                    <Typography variant='subtitle1' component="h6">{showsetDefaultAddress()}</Typography>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell align='center'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addressData.length > 0 ? (addressData.map((order, index) => (
                                <TableRow key={order.address_id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{order.info_address}</TableCell>
                                    <TableCell align='center'>
                                        {order.address_id === defaultaddress ? (
                                            <IconButton>
                                                <CheckCircleIcon fontSize='20px' color='success' />
                                            </IconButton>
                                        ) : (
                                            <Tooltip title={t('profile_page.address.set_default_address')} placement="top">
                                                <IconButton onClick={() => handleSetDefaultAddress(order.address_id)}>
                                                    <CheckCircleOutlinedIcon fontSize='20px' />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        <IconButton onClick={() => handleEditAddress(order.address_id)}>
                                            <EditIcon fontSize='16px' />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteAddress(order.address_id, index)} disabled={order.address_id === defaultaddress}>
                                            <DeleteIcon fontSize='16px' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))) : (
                                <TableRow>
                                    <TableCell colSpan={3} align={"center"}>No Data</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
};

export default AddressList;