import { Box, Container, Grid, Typography } from '@mui/material';
import StepperPayment from '../../components/Payment/StepperPayment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { useState } from 'react';
import { getChargedatawithIdOmise } from '../../utils/useApi';
import { useEffect } from 'react';
import { moneyFormat } from '../../utils/formatCurrency';

const PromptPayPayment = () => {
    let [searchParams] = useSearchParams();
    const { userName, accessToken } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const socket = io(process.env.REACT_APP_WEBHOOK_URL);
    const location = useLocation()
    const [payBarcode, setpayBarcode] = useState("")
    const [price, setPrice] = useState(0)

    const getCharge = async () => {
        const result = await getChargedatawithIdOmise({ "user_id": userName, "order_no": searchParams.get('id'), "type": "promptpay" }, accessToken.token)
        if (result.status) {
            setpayBarcode(result.data.qr_code)
            setPrice(result.data.amount)
        } else {
            navigate('/checkout', { replace: true })
        }
    }
    useEffect(() => {
        getCharge()
        socket.on('connect', () => {
            console.log(`${searchParams.get('id')} pay with Bill Payment`);
        });

        socket.on('paymentTrigger', (data) => {
            window.location.replace(`/summary/${data.order_id}`)
        });

        socket.on('disconnect', () => {
            console.log('disconnect');
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('promptpay_value');

            socket.disconnect()
        };
    }, [])

    return (
        <Box>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <StepperPayment step={1} />
                <Grid container>
                    <Grid item xs={12} p={2}>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <img src={payBarcode} style={{ maxWidth: '100%', width: "300px" }} />
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant="h6" component="div">
                                Order ID : {searchParams.get('id')}
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant="h6" component="div">
                                Amount
                            </Typography>
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant="h6" component="div">
                                ฿ {moneyFormat(price)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PromptPayPayment;