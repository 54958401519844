let initialState = {
    phone: "",
    token: ""
}

const resetReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_PHONE":
            return {
                ...state,
                phone: action.payload
            }
        case "UPDATE_TOKEN":
            return {
                ...state,
                token: action.payload
            }
        case "RESET_DATA":
            return {
                ...initialState
            }
        default:
            return state;
    }
};

export default resetReducer;