import React from 'react';
import LanguagePopover from './LanguagePopover';
import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';

const AssessmentNavbar = () => {
    return (
        <Box sx={{ display: 'flex', padding: "0.5rem 0px", marginBottom: "70px" }}>
            <AppBar
                position="fixed"
                color="default"
                className='navbar-custom'
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, py: 0.5 }}
            >
                <Container maxWidth="lg" disableGutters>
                    <Toolbar sx={{ flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex',  flexGrow: 1, mr: 1 }}>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="/"
                                sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                            >
                                <img
                                    alt="logo"
                                    src={require('../../assets/W.png')}
                                    style={{
                                        height: 60
                                    }}
                                />
                            </Link>
                        </Box>
                        <Box sx={{ px: 2, display: 'flex' }}>
                            <LanguagePopover />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}

export default AssessmentNavbar;