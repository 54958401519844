import { useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Footer = ({ pgroupList }) => {
    const { i18n, t } = useTranslation()

    return (
        <Box sx={{ py: 4 }}>
            <Container maxWidth="xl" sx={{ px: 4 }}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box display="flex" flexDirection="column">
                            <img
                                alt="footer_logo"
                                src={require("../../assets/W.png")}
                                style={{
                                    height: 100,
                                    width: 200,
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                                className="mb-3"
                            />
                            <Box
                                component={"img"}
                                sx={{ 
                                    display: { xs: 'none', sm: 'none', md: 'block' },
                                    height: 200,
                                    width: 250,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    borderRadius: '8px'
                                }}
                                alt="footer_logo"
                                src={require("../../assets/test-tube.webp")}
                            />
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={9} rowSpacing={2} mt={2}>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography variant='h6' component='h6' sx={{ fontWeight: 700 }}>
                                {t("footer.part_1.header")}
                                <span style={{ height: "2px", width: "28px", display: "block", margin: "4px 0", backgroundColor: "black" }}></span>
                            </Typography>
                            <ul style={{ padding: "4px 0" }}>
                                {pgroupList.map((i, index) => (
                                    <li key={index}>
                                        <a href={`/explore/products?group=${i['group_name_en']}`} className="nav-link btn_nva_link_footer p-1 text-muted">{i[`group_name_${i18n.language}`]}</a>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography variant='h6' component='h6' sx={{ fontWeight: 700 }}>
                                {t("footer.part_2.header")}
                                <span style={{ height: "2px", width: "28px", display: "block", margin: "4px 0", backgroundColor: "black" }}></span>
                            </Typography>
                            <ul style={{ padding: "4px 0" }}>
                                <li>
                                    <a href="/about/ourteam" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_2.ourteam")}</a>
                                </li>
                                <li>
                                    <a href="/blog" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_2.news_article")}</a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography variant='h6' component='h6' sx={{ fontWeight: 700 }}>
                                {t("footer.part_3.header")}
                                <span style={{ height: "2px", width: "28px", display: "block", margin: "4px 0", backgroundColor: "black" }}></span>
                            </Typography>
                            <ul style={{ padding: "4px 0" }}>
                                <li>
                                    <a href="/explore/products" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_3.product")}</a>
                                </li>
                                <li>
                                    <a href="/explore/review" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_3.product_review")}</a>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography variant='h6' component='h6' sx={{ fontWeight: 700 }}>
                                {t("footer.part_4.header")}
                                <span style={{ height: "2px", width: "28px", display: "block", margin: "4px 0", backgroundColor: "black" }}></span>
                            </Typography>
                            <ul style={{ padding: "4px 0" }}>
                                <li>
                                    <a href="/about/pdpa" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_4.pdpa")}</a>
                                </li>
                                <li>
                                    <a href="/about/cookie-policy" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_4.cookie")}</a>
                                </li>
                                <li>
                                    <a href="/about/privacy-policy" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_4.policy")}</a>
                                </li>
                                <li>
                                    <a href="/about/terms-conditions" className="nav-link btn_nva_link_footer p-1 text-muted">{t("footer.part_4.terms")}</a>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xl={12}>
                        <Typography variant='body1' component='h6' sx={{ fontWeight: 400, lineHeight: 1.66, fontSize: "0.75rem", m: 0 }}>
                            © 2022 - {new Date().getFullYear().toString()} Copyright : &nbsp;
                            <a style={{ textDecoration: "none" }} href="https://whealthvitamin.com/">whealthvitamin.com</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer