export const storeRegister = (signup) => {
  return {
    type: "REGISTER_STORE",
    payload: signup
  };
};

export const storeUserIdfromAssessment = (user_id) => {
  return {
    type: "ADD_USER_ID",
    payload: user_id
  }
}

export const clearRegisterData = () => {
  return {
    type: "CLEAR_REGISTER_STORE"
  }
}

