import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetForm, resetSlide } from '../../actions/'
import { Box, Button, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress, Paper, Typography, Container, FormControlLabel, Checkbox, Grid, FormGroup, FormControl, RadioGroup, Radio, TextField } from '@mui/material';
import styled from 'styled-components';
import { setAlternativeVitamin, setAnswers } from '../../actions/assessment';
import { fetchCart, setCartType } from '../../actions/cart'
import store from '../../store';
import { surveyData } from '../../data/AssessmentSurveyDataEN';
import Swal from 'sweetalert2';
import { processAnswerAPI } from '../../utils/useApi';
import { motion, AnimatePresence } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { storeUserIdfromAssessment } from '../../actions/register';
import { result } from 'lodash';
import Terms from './Terms';

const StyledBox = styled(Box)({
    width: '100%',
    minHeight: '89vh',
    display: "flex",
    backgroundColor: "#e3effb",
    overflow: 'hidden'
})

const RenderQuestion = ({ question_list, index, handleAnswer, currentAnswers, nextButton, prevButton, handleCheckboxChoiceSelect, index2, vitaminList, translate }) => {
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const sendAnswer = async () => {
        const obj = store.getState().assessment.answers.reduce((acc, cur) => {
            const key = Object.keys(cur)[0];
            acc[key] = cur[key];
            if (["q0015", "q0016", "q0022"].includes(key)) {
                if (typeof cur[key] === 'object' & cur[key] !== null & cur[key] !== undefined) {
                    cur[key].sort()
                    if (key === "q0015" & cur[key].toString() === "1,6") {
                        acc[key] = "7"
                    } else if (key === "q0015" & cur[key].toString() === "5,6") {
                        acc[key] = "8"
                    } else if (key === "q0015" & cur[key].length === 6) {
                        acc[key] = "9"
                    } else if (key === "q0016" & cur[key].toString() === "1,2") {
                        acc[key] = "4"
                    } else if (key === "q0016" & cur[key].toString() === "1,3") {
                        acc[key] = "5"
                    } else if (key === "q0016" & cur[key].toString() === "2,3") {
                        acc[key] = "6"
                    } else if (key === "q0016" & cur[key].toString() === "1,2,3") {
                        acc[key] = "7"
                    }
                }
            } else if (["q0014", "q0017", "q0018", "q0021"].includes(key)) {
                if (typeof cur[key] === 'object' & cur[key] !== null & cur[key] !== undefined) {
                    cur[key].sort()
                    if (key === "q0014" & cur[key].length === 2) {
                        acc[key] = "3"
                    } else if (key === "q0017" & cur[key].length === 4) {
                        acc[key] = "5"
                    } else if (key === "q0018" & cur[key].length === 6) {
                        acc[key] = "7"
                    } else if (key === "q0021" & cur[key].length === 5) {
                        acc[key] = "6"
                    }
                }
            }
            return acc;
        }, {});
        obj['user_name'] = auth.userName
        console.log(obj)
        const result_ans = await processAnswerAPI(JSON.stringify(obj))
        if (result_ans.status) {
            console.log(result_ans.data)
            dispatch(fetchCart(result_ans.data.product))
            dispatch(setAlternativeVitamin(result_ans.data.product11))
            dispatch(storeUserIdfromAssessment(result_ans.data.user_id))
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'success',
                title: translate("assessment_question.alert.alert_finish_header"),
                text: translate("assessment_question.alert.alert_finish_description"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: translate("assessment_question.alert.ok2_btn"),
                cancelButtonText: translate("assessment_question.alert.cancel2_btn"),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setCartType("A"))
                    window.location.replace('/assessment/summary')
                } else {
                    window.location.replace('/')
                }
            })
        } else {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'error',
                title: "Error!",
            }).then((result) => {
                if (result.isConfirmed) {
                    // window.location.replace('/assessment/summary')
                    window.location.href = '/'
                }
            })
        }
    }
    const { type, question, choices, id, input_type, helperText } = question_list[index]
    const gender = Object.values(store.getState().assessment.answers[2] || {})[0]
    const height = Object.values(store.getState().assessment.answers[4] || {})[0]
    const weight = Object.values(store.getState().assessment.answers[5] || {})[0]
    const isSubchoice = Object.values(store.getState().assessment.answers[index] || {})[0]
    const BMI = ((parseFloat(weight) / parseFloat(height) / parseFloat(height)) * 10000).toFixed(2)
    const isDisable = (type) => {
        switch (type) {
            case 'single-select':
                if (["q0014", "q0015", "q0016", "q0017", "q0018", "q0019", "q0021", "q0022", "q0023", "q0025"].includes(id)) {
                    let arr = Object.values(currentAnswers)[0] || []
                    return Object.values(currentAnswers)[0] !== null && Object.values(arr).length === 0
                } else {
                    return Object.values(currentAnswers)[0] === "" || Object.values(currentAnswers)[0] === undefined
                }
            case 'multi-select':
                let arr = Object.values(currentAnswers)[0] || []
                if (id === "q0012") {
                    return Object.values(arr).length !== 2 || Object.values(arr).length === 0
                } else {
                    return Object.values(arr).length === 0
                }
            case 'text-input':
                if (id === "q0004") {
                    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                    return (Object.values(currentAnswers)[0] !== "" && !regex.test(Object.values(currentAnswers)[0])) || Object.values(currentAnswers)[0] === undefined || Object.values(currentAnswers)[0] === ""
                } else {
                    return Object.values(currentAnswers)[0] === "" || Object.values(currentAnswers)[0] === undefined
                }
            default:
                return true
        }
    }
    switch (type) {
        case 'single-select':
            return (
                <Box sx={{ padding: '5rem 0rem' }} key={index}>
                    {id === "q0019" ? (
                        // <p className="fs-2 text-center">{question.replace("YOURSEX", gender === "1" ? "male" : "female")}</p>
                        <p className="fs-2 text-center">{translate(question, { gender: gender === "1" ? translate("assessment_question.q3.choice.c0") : translate("assessment_question.q3.choice.c1") })}</p>
                    ) : (
                        <p className="fs-2 text-center">{translate(question)}</p>
                    )}

                    <Grid container direction="column" alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <FormControl sx={{
                                mt: 2
                            }} component="fieldset">
                                <FormGroup>
                                    {["q0014", "q0015", "q0016", "q0017", "q0018", "q0019", "q0021", "q0022", "q0023", "q0025"].includes(id) ? (
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="row-radio-buttons-group"
                                            value={currentAnswers[id] === undefined ? "" : (currentAnswers[id] === null ? "no" : "yes")}
                                            onChange={handleAnswer}
                                        >
                                            {choices.map((choice, i) => (
                                                <FormControlLabel value={choice.value} control={<Radio id={id} color="error" name={(i + 1).toString()} />} label={translate(choice.text)} key={`q${index}-c${i}`} />
                                            ))}
                                        </RadioGroup>
                                    ) : (
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="row-radio-buttons-group"
                                            value={currentAnswers[id] || ""}
                                            onChange={handleAnswer}
                                        >
                                            {choices.map((choice, i) => (
                                                <FormControlLabel value={choice.value} control={<Radio id={id} color="error" name={(i + 1).toString()} />} label={translate(choice.text)} key={`q${index}-c${i}`} />
                                            ))}
                                        </RadioGroup>
                                    )}

                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {
                            choices[0].subChoices && (Array.isArray(isSubchoice)) && (
                                <Grid item>
                                    {["q0019"].includes(id) ? choices[0].subChoices.map((subChoice, i) => (
                                        <Grid item xs={12} key={i}>
                                            {subChoice.rule.includes(gender) && (
                                                <FormControlLabel key={`q${index}-c${i}`}
                                                    control={
                                                        <Checkbox
                                                            id={id}
                                                            checked={currentAnswers[id]?.includes(subChoice.value) || false}
                                                            value={subChoice.value}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            onChange={() => handleCheckboxChoiceSelect(subChoice.value, id)}
                                                            color="error"
                                                            name={`q${index}-c${i}`}
                                                        />
                                                    }
                                                    label={translate(subChoice.text)}
                                                />)}
                                        </Grid>
                                    )) : ["q0025"].includes(id) ? choices[0].subChoices.map((subChoice, i) => (
                                        <Grid item xs={12} key={i}>
                                            <FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox
                                                        id={id}
                                                        checked={currentAnswers[id]?.includes(subChoice.value) || false}
                                                        value={subChoice.value}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        onChange={() => handleCheckboxChoiceSelect(subChoice.value, id)}
                                                        color="error"
                                                        name={`q${index}-c${i}`}
                                                    />
                                                }
                                                label={translate(subChoice.text)}
                                            />
                                        </Grid>
                                    )) : choices[0].subChoices.map((subChoice, i) => (
                                        <Grid item xs={12} key={i}>
                                            <FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox
                                                        id={id}
                                                        checked={currentAnswers[id]?.includes(subChoice.value) || false}
                                                        value={subChoice.value}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        onChange={() => handleCheckboxChoiceSelect(subChoice.value, id)}
                                                        color="error"
                                                        name={`q${index}-c${i}`}
                                                    />
                                                }
                                                label={translate(subChoice.text)}
                                            />
                                        </Grid>))}
                                </Grid>
                            )
                        }
                    </Grid>
                    <Stack direction="row" spacing={2} sx={{ mt: 10 }} justifyContent="center">
                        {(index < 12 || index >= 24 || index2 !== 0) ? (
                            <Button variant="outlined" color="error" onClick={prevButton} sx={{ textTransform: "none" }}>{translate("assessment.previous_button")}</Button>
                        ) : (
                            <Button variant="outlined" color="error" disabled={true} sx={{ textTransform: "none" }}>{translate("assessment.previous_button")}</Button>
                        )}
                        {index < surveyData.length - 1 ? (
                            <Button variant="contained" color="error" onClick={nextButton} disabled={isDisable(type)} sx={{ textTransform: "none" }}>{translate("assessment.next_button")}</Button>
                        ) : (
                            <Button variant="contained" color="error" onClick={sendAnswer} sx={{ textTransform: "none" }}>{translate("assessment.finish_button")}</Button>
                        )}
                    </Stack>
                </Box>
            );
        case 'multi-select':
            return (
                <Box sx={{ padding: '5rem 0rem' }} key={index} >
                    <p className="fs-2 text-center">{translate(question)}</p>
                    <Grid container display="flex" direction="column" alignItems="center">
                        <FormControl sx={{
                            mt: 2
                        }} component="fieldset">
                            {["q0011"].includes(id) ? choices.map((choice, i) => (
                                <Grid key={choice.id} item xs={12}>
                                    {choice.rule.includes(gender) ? (
                                        <FormControl fullWidth>
                                            <FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox id={id} checked={currentAnswers[id]?.includes(choice.value) || false} value={choice.id} inputProps={{ 'aria-label': 'controlled' }} onChange={handleAnswer} color="error" name={`q${index}-c${i}`} />
                                                }
                                                label={translate(choice.text)}
                                            />
                                        </FormControl>
                                    ) : (null)}
                                </Grid>
                            )) : ["q0012"].includes(id) ? choices.map((choice, i) => (
                                <Grid key={choice.id} item xs={12}>
                                    {BMI < 18.5 ?
                                        (i !== 11 && (<FormControl fullWidth>
                                            <FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox id={id} checked={currentAnswers[id]?.includes(choice.value) || false} value={choice.id} inputProps={{ 'aria-label': 'controlled' }} onChange={handleAnswer} color="error" name={`q${index}-c${i}`} />
                                                }
                                                label={translate(choice.text)}
                                            />
                                        </FormControl>))
                                        : (<FormControl fullWidth>
                                            <FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox id={id} checked={currentAnswers[id]?.includes(choice.value) || false} value={choice.id} inputProps={{ 'aria-label': 'controlled' }} onChange={handleAnswer} color="error" name={`q${index}-c${i}`} />
                                                }
                                                label={translate(choice.text)}
                                            />
                                        </FormControl>)}
                                </Grid>
                            )) : choices.map((choice, i) => {
                                return (
                                    <Grid key={choice.id} item xs={12}>
                                        <FormControl fullWidth>
                                            {["q0032", "q0008"].includes(id) ? (<FormControlLabel key={`q${index}-c${i}`}
                                                control={
                                                    <Checkbox id={id} checked={currentAnswers[id]?.includes(choice.value) || false} value={choice.value} inputProps={{ 'aria-label': 'controlled' }} onChange={handleAnswer} color="error" name={`q${index}-c${i}`} />
                                                }
                                                label={translate(choice.text)}
                                            />) :
                                                (<FormControlLabel key={`q${index}-c${i}`}
                                                    control={
                                                        <Checkbox id={id} checked={currentAnswers[id]?.includes(choice.value) || false} value={choice.id} inputProps={{ 'aria-label': 'controlled' }} onChange={handleAnswer} color="error" name={`q${index}-c${i}`} />
                                                    }
                                                    label={translate(choice.text)}
                                                />
                                                )}
                                        </FormControl>
                                    </Grid>
                                )
                            })}
                        </FormControl>
                    </Grid>
                    <Stack direction="row" spacing={2} sx={{ mt: 10 }} justifyContent="center">
                        {(index < 12 || index >= 24 || index2 !== 0) ? (
                            <Button variant="outlined" color="error" onClick={prevButton} sx={{ textTransform: 'none' }}>{translate("assessment.previous_button")}</Button>
                        ) : (
                            <Button variant="outlined" color="error" disabled={true} sx={{ textTransform: 'none' }}>{translate("assessment.previous_button")}</Button>
                        )}
                        {index < surveyData.length - 1 ? (
                            <Button variant="contained" color="error" onClick={nextButton} disabled={isDisable(type)} sx={{ textTransform: 'none' }}>{translate("assessment.next_button")}</Button>
                        ) : (
                            <Button variant="contained" color="error" onClick={sendAnswer} sx={{ textTransform: 'none' }}>{translate("assessment.finish_button")}</Button>
                        )}
                    </Stack>
                </Box>
            );
        case 'text-input':
            return (
                <Box sx={{ textAlign: "center", padding: '5rem 1rem' }} key={index}>
                    <p className="fs-2">{translate(question)}</p>
                    {id === "q0009" && (
                        <p>{translate("assessment_question.q9_ins")}</p>
                    )}
                    <FormControl sx={{
                        width: '100%',
                        maxWidth: '500px',
                        mt: 2
                    }}>
                        {["q0005", "q0006"].includes(id) ? (<TextField
                            id={id}
                            variant="standard"
                            name="name"
                            defaultValue={currentAnswers[id] || ''}
                            onChange={handleAnswer}
                            type={input_type}
                            required
                            InputProps={{
                                inputProps: {
                                    style: { textAlign: "center" },
                                    max: 300,
                                    min: 0,
                                    step: 1
                                }
                            }}
                            error={isDisable(type)}
                            helperText={isDisable(type) && translate(helperText)}
                        />) : (
                            <TextField
                                id={id}
                                variant="standard"
                                name="name"
                                defaultValue={currentAnswers[id] || ''}
                                onChange={handleAnswer}
                                type={input_type}
                                required
                                InputProps={{
                                    inputProps: {
                                        style: { textAlign: "center" },
                                    }
                                }}
                                error={isDisable(type)}
                                helperText={isDisable(type) && translate(helperText)}
                            />
                        )}
                    </FormControl>
                    {id === "q0006" && (
                        <Box sx={{ textAlign: "center", padding: '5rem 1rem' }}>
                            <p className="fs-2">{translate("assessment_question.bmi_sentence")}</p>
                            <p className="fs-2">{BMI === "NaN" ? 0 : BMI}</p>
                        </Box>
                    )}
                    <Stack direction="row" spacing={2} sx={{ mt: 10 }} justifyContent="center">
                        {index > 0 ? (
                            <Button variant="outlined" color="error" onClick={prevButton} sx={{ textTransform: "none" }}>{translate("assessment.previous_button")}</Button>
                        ) : (
                            <Button variant="outlined" color="error" disabled={true} sx={{ textTransform: "none" }}>{translate("assessment.previous_button")}</Button>
                        )}
                        {index < surveyData.length - 1 ? (
                            <Button variant="contained" color="error" onClick={nextButton} disabled={isDisable(type)} sx={{ textTransform: "none" }}>{translate("assessment.next_button")}</Button>
                        ) : (
                            <Button variant="contained" color="error" onClick={sendAnswer} sx={{ textTransform: "none" }}>{translate("assessment.finish_button")}</Button>
                        )}
                    </Stack>
                </Box>
            );
        default:
            return null;
    }
};

const Assessment = () => {
    const { assessment, cart } = useSelector(state => state)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [sec2List, setSec2List] = useState([])
    const uniqueArr = [...new Set(sec2List.sort())]
    const [currentQuestion, setCurrentQuestion] = useState(-1)
    const [currentSection2Q, setCurrentSection2Q] = useState(0)
    const [isScroll, setisScroll] = useState(false)
    const [modal, setModal] = useState(false)
    const [vitaminList, setVitaminList] = useState([])

    const [animationComplete, setAnimationComplete] = useState(false);
    const toggle = () => {
        if (cart.items.length > 0) {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'warning',
                title: t("assessment_question.alert.alert_itemincart_header"),
                text: t("assessment_question.alert.alert_itemincart_description"),
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: t("assessment_question.alert.ok2_btn"),
                cancelButtonText: t("assessment_question.alert.cancel2_btn")
            }).then((result) => {
                if (result.isConfirmed) {
                    setModal(!modal)
                }
            })
        } else {
            setModal(!modal)
        }
    }
    const getStartedHandler = () => {
        setisScroll(false)
        setModal(false)
        handleNextQuestion()
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - Math.round(e.target.scrollTop)) === e.target.clientHeight
        if (bottom) {
            setisScroll(true)
        }
    };

    const handleAnswer = (event) => {
        const qid = event.target.id
        const answer = event.target.value
        const checked = event.target.checked
        let newAnswers = store.getState().assessment.answers[currentQuestion] || {}

        /* Checking the type of input and then setting the value of newAnswers based on the type of input. */
        if (event.target.type === 'checkbox') {
            if (["q0008", "q0011", "q0018"].includes(qid)) {
                if (answer === "8") {
                    if (checked) {
                        if (Object.keys(newAnswers).length <= 0) {
                            newAnswers = [answer]
                        } else {
                            newAnswers[qid] = []
                            newAnswers[qid].push(answer)
                            newAnswers = newAnswers[qid]
                        }
                    } else {
                        newAnswers = newAnswers[qid].filter((a) => a !== answer);
                    }
                } else {
                    if (checked) {
                        if (Object.keys(newAnswers).length <= 0) {
                            newAnswers = [answer]
                        } else {
                            newAnswers[qid].push(answer)
                            newAnswers = newAnswers[qid].filter((a) => a !== "8")
                        }
                    } else {
                        newAnswers = newAnswers[qid].filter((a) => a !== answer);
                    }
                }
            } else if (qid === "q0012") {
                let arr = Object.values(currentAnswers)[0] || []

                if (checked) {
                    if (Object.values(arr).length === 0) {
                        setSec2List([...sec2List, ...surveyData[11].choices[answer - 1].array_list])
                        newAnswers = [answer]
                    } else if (Object.values(arr).length >= 2) {
                        newAnswers = newAnswers[qid]
                    } else {
                        setSec2List([...sec2List, ...surveyData[11].choices[answer - 1].array_list])
                        newAnswers[qid].push(answer)
                        newAnswers = newAnswers[qid]
                    }
                } else {
                    newAnswers = newAnswers[qid].filter((a) => a !== answer);
                    const removeItem = sec2List.filter((i) => !surveyData[11].choices[answer - 1].array_list.includes(i))
                    setSec2List(removeItem)
                }
            } else {
                if (checked) {
                    if (Object.keys(newAnswers).length <= 0) {
                        newAnswers = [answer]
                    } else {
                        newAnswers[qid].push(answer)
                        newAnswers = newAnswers[qid]
                    }
                } else {
                    newAnswers = newAnswers[qid].filter((a) => a !== answer);
                }
            }
        } else if (event.target.type === 'radio') {
            if (["q0007"].includes(qid)) {
                if (answer === "1") {
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t("assessment_question.alert.alert_header"),
                        text: t("assessment_question.alert.alert_description"),
                        confirmButtonText: t("assessment_question.alert.ok_btn"),
                        cancelButtonText: t("assessment_question.alert.cancel_btn"),
                        icon: 'warning',
                        showCancelButton: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const link_q7 = "https://whealthvitamin.com/explore/products/p00079"
                            Swal.fire({
                                customClass: {
                                    container: 'my-swal',
                                },
                                'icon': 'error',
                                'title': t("assessment_question.alert.alert_confirm_header"),
                                'html': t('assessment_question.alert.alert_confirm_description2', { link: link_q7 }),
                                confirmButtonText: t("assessment_question.alert.ok2_btn"),
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                'preConfirm': () => {
                                    window.location.replace('/')
                                }
                            })
                        }
                    })
                    newAnswers = undefined
                } else {
                    newAnswers = answer
                }
            } else if (["q0010"].includes(qid)) {
                if (answer === "1") {
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t("assessment_question.alert.alert_header"),
                        text: t("assessment_question.alert.alert_description"),
                        confirmButtonText: t("assessment_question.alert.ok_btn"),
                        cancelButtonText: t("assessment_question.alert.cancel_btn"),
                        icon: 'warning',
                        showCancelButton: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                customClass: {
                                    container: 'my-swal',
                                },
                                'icon': 'error',
                                'title': t("assessment_question.alert.alert_confirm_header"),
                                'text': t("assessment_question.alert.alert_confirm_description"),
                                confirmButtonText: t("assessment_question.alert.ok2_btn"),
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                'preConfirm': () => {
                                    window.location.replace('/')
                                }
                            })
                        }
                    })
                    newAnswers = undefined
                } else {
                    newAnswers = answer
                }
            } else if (["q0014", "q0015", "q0016", "q0017", "q0018", "q0019", "q0021", "q0022", "q0023", "q0025"].includes(qid)) {
                if (answer === "yes") {
                    newAnswers = []
                } else {
                    newAnswers = null
                }
            } else {
                newAnswers = answer
            }

        } else {
            newAnswers = answer
        }
        dispatch(setAnswers({ [qid]: newAnswers }, currentQuestion))
        console.log(assessment.answers)
    }

    const handleCheckboxChoiceSelect = (choiceId, qid) => {
        let newAnswers = store.getState().assessment.answers[currentQuestion] || {}
        if (qid === "q0025") {
            if (typeof Object.values(newAnswers)[0] === "string") {
                newAnswers = []
            } else {
                if (Object.values(newAnswers)[0].includes(choiceId)) {
                    newAnswers = newAnswers[qid].filter((a) => a !== choiceId);
                } else {
                    newAnswers[qid] = [choiceId]
                    newAnswers = newAnswers[qid]
                }
            }
        } else {
            if (typeof Object.values(newAnswers)[0] === "string") {
                newAnswers = []
            } else {
                if (Object.values(newAnswers)[0].includes(choiceId)) {
                    newAnswers = newAnswers[qid].filter((a) => a !== choiceId);
                } else {
                    newAnswers[qid].push(choiceId)
                    newAnswers = newAnswers[qid]
                }
            }
        }
        console.log(assessment.answers)
        dispatch(setAnswers({ [qid]: newAnswers }, currentQuestion))
    }

    const handleNextQuestion = () => {
        if (currentQuestion === 11) {
            setCurrentQuestion(uniqueArr[currentSection2Q])
        } else {
            setCurrentQuestion(currentQuestion + 1);
        }
    }
    const handlePreviousQuestion = () => {
        if (currentQuestion === 24) {
            setCurrentQuestion(uniqueArr[currentSection2Q])
        } else {
            setCurrentQuestion(currentQuestion - 1);
        }
    }

    const handleNextQuestionSec2 = () => {
        if (currentSection2Q === uniqueArr.length - 1) {
            setCurrentQuestion(24)
        } else {
            setCurrentSection2Q(currentSection2Q + 1);
        }

    }

    const handlePreviousQuestionSec2 = () => {
        if (currentSection2Q === 0) {
            setCurrentQuestion(11)
        } else {
            setCurrentSection2Q(currentSection2Q - 1);
            setCurrentQuestion(uniqueArr[currentSection2Q])
        }
    }

    useEffect(() => {
        if (currentQuestion >= 12 && currentQuestion < 24) {
            setCurrentQuestion(uniqueArr[currentSection2Q])
        }
    }, [currentSection2Q])

    useEffect(() => {
        dispatch(resetForm())
        dispatch(resetSlide())
    }, [dispatch])

    const currentAnswers = store.getState().assessment.answers[currentQuestion] || []

    return (
        <StyledBox>
            {currentQuestion === -1 && (
                <Box sx={{ maxWidth: '700px', textAlign: "center", margin: "0 auto", padding: "80px 0 30px" }}>
                    <Typography component="h4" variant='h4' sx={{ mb: 1, fontWeight: 'normal' }}>
                        {t('assessment.welcome_header_sentence')}
                    </Typography>
                    <Typography component="p" variant='body1' sx={{ mt: 2, mb: 5 }}>
                        {t('assessment.welcome_sentence')}
                    </Typography>
                    <Box justifyContent="center" display="flex" alignItems="center">
                        <Button disableElevation disableRipple sx={{
                            borderRadius: 1, border: '1px solid transparent', backgroundColor: "#FD546A", color: "#ffffff", px: 5, py: 2, mt: 5, '&:hover': {
                                backgroundColor: '#DB4A5D',
                            },
                        }} onClick={toggle}>{t('assessment.get_started')}</Button>
                    </Box>
                    <Dialog
                        open={modal}
                        onClose={() => {setModal(!modal);setisScroll(false)}}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{t('assessment.pdpa_title')}</DialogTitle>
                        <DialogContent style={{ maxHeight: '400px', overflowX: 'hidden' }} onScroll={handleScroll}>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Terms/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => window.location.replace('/')}>{t('assessment.pdpa_decline_button')}</Button>
                            <Button onClick={getStartedHandler} disabled={!isScroll}>{t('assessment.pdpa_accept_button')}</Button>
                        </DialogActions>
                    </Dialog>
                </Box>

            )}

            {((currentQuestion < 12 && currentQuestion >= 0) || (currentQuestion >= 24)) ? (
                <Container maxWidth="md" sx={{ margin: '50px auto' }}>
                    <Paper variant="outlined">
                        <div style={{ backgroundColor: "#7d9a99", height: "15px", margin: 0 }} />
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={currentQuestion}
                                initial={{ opacity: 0, x: -100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 100 }}
                                transition={{ duration: 0.5 }}
                                onAnimationStart={() => setAnimationComplete(false)}
                                onAnimationComplete={() => setAnimationComplete(true)}
                            >
                                <RenderQuestion
                                    question_list={surveyData}
                                    index={currentQuestion}
                                    handleAnswer={handleAnswer}
                                    currentAnswers={currentAnswers}
                                    nextButton={handleNextQuestion}
                                    prevButton={handlePreviousQuestion}
                                    handleCheckboxChoiceSelect={handleCheckboxChoiceSelect}
                                    index2={currentSection2Q}
                                    vitaminList={vitaminList}
                                    translate={t}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </Paper>
                </Container>
            ) : null}

            {currentQuestion >= 12 && currentQuestion < 24 ? (
                <Container maxWidth="md" sx={{ margin: '50px auto' }}>
                    <Paper variant="outlined">
                        <div style={{ backgroundColor: "#7d9a99", height: "15px", margin: 0 }} />
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={currentQuestion}
                                initial={{ opacity: 0, x: -100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 100 }}
                                transition={{ duration: 0.5 }}
                            >
                                <RenderQuestion
                                    question_list={surveyData}
                                    index={currentQuestion}
                                    handleAnswer={handleAnswer}
                                    currentAnswers={currentAnswers}
                                    nextButton={handleNextQuestionSec2}
                                    prevButton={handlePreviousQuestionSec2}
                                    handleCheckboxChoiceSelect={handleCheckboxChoiceSelect}
                                    index2={currentSection2Q}
                                    vitaminList={vitaminList}
                                    translate={t}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </Paper>
                </Container>
            ) : null}

            <LinearProgress variant="determinate" value={currentQuestion === -1 ? 0 : Math.round((100 / surveyData.length) * (currentQuestion + 1))} style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                left: 0,
                height: "30px",
            }} />
        </StyledBox>
    )
}
export default Assessment