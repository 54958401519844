import { Box, Button, Container, Divider, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, removeAllFromCart, removeFromCart } from '../../actions/cart';
import CartItem from '../../components/Cart/CartItem';
import OrderSummary from '../../components/Cart/OrderSummary';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const DoctorImage = styled.img`
    @media only screen and (max-width: 1199px) {
        min-height: 200px;
    }
    @media only screen and (max-width: 991px) {
        min-height: 200px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        min-height: 200px;
    }
    @media (max-width: 575px) {
        min-height: 200px;
    }
`

const Cart = () => {
    const { t } = useTranslation()
    const { items } = useSelector(state => state.cart)
    const cartTotal = items.reduce((total, item) => total + (item.price * item.qty), 0)
    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(fetchCart([{"name_en": "P1", "price": 500, "qty": 1}]))
    // }, [])
    return (
        <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
            {items.length !== 0 ? (
                <Container maxWidth="lg" sx={{ py: 3 }}>
                    <Grid container sx={{ mt: 2 }} columnSpacing={4}>
                        <Grid item xs={12} sm={12} md={8}>
                            {items.map((i, index) => (
                                <CartItem key={index} item={i} handleDeleteItem={() => dispatch(removeAllFromCart(i))} />
                            ))}
                            <Divider sx={{ my: 2, py: 0.05 }} />
                            <Paper elevation={0} sx={{ p: 3, mb: 1.5, backgroundColor: "#f3f2f2", borderRadius: "16px" }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {t('checkout_page.footer1')}
                                </Typography>
                                <Typography variant="body1" sx={{ py: 2 }}>
                                    {t('checkout_page.footer2')}
                                </Typography>
                                <Link href='/explore/products' underline="always" align="center">
                                    {t('checkout_page.footer3')}
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <OrderSummary total={cartTotal} type_shop={"S"} />
                        </Grid>
                    </Grid>
                </Container>) : (
                <Container maxWidth="lg">
                    <Grid container columnSpacing={{ xs: 3, sm: 2, md: 2 }} rowSpacing={1} my={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sx={{ minHeight: '65vh' }} display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                            <DoctorImage src={require("../../assets/shopping/cart.png")} style={{
                                width: '175px',
                                maxWidth: '100%'
                            }} />
                            <Typography align="center" variant="body1" sx={{ py: 2 }}>
                                {t('checkout_page.cart_empty')}
                            </Typography>
                            <Link href="/explore/products" underline="none">
                                <Button color="primary" variant='contained' sx={{ display: 'inline-block', textTransform: 'none' }}>{t('checkout_page.shop_btn')}</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Box>
    );
};

export default Cart;