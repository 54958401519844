import { Box, Container, Grid, LinearProgress, Rating, Stack, Typography } from "@mui/material"
import "../Review/Review.css"
import CustomerReviewCard from "../../../components/CustomerReviewCard/CustomerReviewCard"
import { fetchCustomerReview } from "../../../utils/useApi"
import { useEffect, useState } from "react"
import { oneDigitDec } from "../../../utils/formatCurrency"
const Review = () => {
    const [data, setData] = useState(
        {
            "para_0": "",
            "para_1": "",
            "para_2": "",
            "para_3": "",
            "img_0": "",
            "img_1": "",
            "img_2": "",
            "img_3": "",
            "destination_0": "",
            "destination_1": "",
            "destination_2": "",
            "destination_3": "",
            "update_date": "",
            "rate": 0,
            "rate2": 0,
            "rate3": 0,
            "rate4": 0
        }
    )
    const [rating, setRating] = useState({
        "rank_rating1": 0,
        "rank_rating2": 0,
        "rank_rating3": 0,
        "rank_rating4": 0,
        "rank_rating5": 0,
        "overall": 0
    })
    const arr = Object.entries(data)
    const fetchData = async () => {
        const result_fetch = await fetchCustomerReview()
        if (result_fetch.status) {
            setData(result_fetch.data.comment[0])
            setRating(result_fetch.data.rating)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <Container maxWidth="lg">
            <Grid container my={5} justifyContent="center" alignItems="center" spacing={2}>
                <Grid item md={6} xs={12} >
                    <h2>Customer Review</h2>
                    <Stack direction={"row"}>
                        <h1>{oneDigitDec(rating.overall) || 0}</h1>
                        <span className="align-self-center">
                            <Rating name="size-large" value={rating.overall || 0} size="large" readOnly />
                        </span>
                    </Stack>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction={"row"} justifyContent="space-evenly" spacing={2}>
                                <p>5.0</p>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate" value={rating.rank_rating5 || 0} sx={{ my: 1 }} />
                                </Box>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <p>4.0</p>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate" value={rating.rank_rating4 || 0} sx={{ my: 1 }} />
                                </Box>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <p>3.0</p>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate" value={rating.rank_rating3 || 0} sx={{ my: 1 }} />
                                </Box>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <p>2.0</p>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate" value={rating.rank_rating2 || 0} sx={{ my: 1 }} />
                                </Box>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <p>1.0</p>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress variant="determinate" value={rating.rank_rating1 || 0} sx={{ my: 1 }} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <img src={require("../../../assets/3651947.png")} alt="vitaminbot" width="70%" height="100%" style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    />
                </Grid>
            </Grid>
            {/* <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", mb: 2 }}>
                <Typography gutterBottom variant="h3" component="div" style={{ fontWeight: 700 }}>Reviews</Typography>
                <Typography gutterBottom variant="h6" component="div">Your content</Typography>
            </Box> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <CustomerReviewCard image={arr[8][1] && `https://whealthvitamin.com:5003${arr[8][1].replace("..", "")}${arr[4][1]}`} rating={Number(arr[13][1])} description={arr[0][1]} />
                    {/* <CustomerReviewCard image={arr[8][1] && `http://192.168.1.90:6497${arr[8][1].replace("..", "")}${arr[4][1]}`} rating={Number(arr[13][1])} description={arr[0][1]} /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <CustomerReviewCard image={arr[9][1] && `https://whealthvitamin.com:5003${arr[9][1].replace("..", "")}${arr[5][1]}`} rating={Number(arr[14][1])} description={arr[1][1]} />
                    {/* <CustomerReviewCard image={arr[9][1] && `http://192.168.1.90:6497${arr[9][1].replace("..", "")}${arr[5][1]}`} rating={Number(arr[14][1])} description={arr[1][1]} /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <CustomerReviewCard image={arr[10][1] && `https://whealthvitamin.com:5003${arr[10][1].replace("..", "")}${arr[6][1]}`} rating={Number(arr[15][1])} description={arr[2][1]} />
                    {/* <CustomerReviewCard image={arr[10][1] && `http://192.168.1.90:6497${arr[10][1].replace("..", "")}${arr[6][1]}`} rating={Number(arr[15][1])} description={arr[2][1]} /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <CustomerReviewCard image={arr[11][1] && `https://whealthvitamin.com:5003${arr[11][1].replace("..", "")}${arr[7][1]}`} rating={Number(arr[16][1])} description={arr[3][1]} />
                    {/* <CustomerReviewCard image={arr[11][1] && `http://192.168.1.90:6497${arr[11][1].replace("..", "")}${arr[7][1]}`} rating={Number(arr[16][1])} description={arr[3][1]} /> */}
                </Grid>
            </Grid>
        </Container>
    )
}
export default Review