import React, { Suspense, lazy } from 'react';
import "../Home/Home.css"
// import PackSection from '../../components/Home/PackSection';
import ContentSection from '../../components/Home/ContentSection';
import FirstSection from '../../components/Home/FirstSection';
import ChatSection from '../../components/Home/ChatSection';
import StatSection from '../../components/Home/StatSection';
import ReviewSection from '../../components/Home/ReviewSection';
import ServiceSection from '../../components/Home/ServiceSection';
import ContactSection from '../../components/Home/ContactSection';
import { CircularProgress } from '@mui/material';

const PackSection = lazy(() => import('../../components/Home/PackSection'))
const Home = () => {
    return (
        <>
            <FirstSection />
            <Suspense fallback={<CircularProgress/>}>
                <PackSection />
            </Suspense>
            <ContentSection />
            <ChatSection />
            <StatSection />
            <ReviewSection />
            <ServiceSection />
            <ContactSection />
        </>
    )
}
export default Home