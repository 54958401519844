import { Box, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchOrderHistoryData } from "../../utils/useApi";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Label from '../../components/Label/Label'
import { useNavigate } from "react-router-dom";
import { fDateTime } from '../../utils/formatTime'
import { moneyFormat } from "../../utils/formatCurrency";
import styled from 'styled-components'
import TableToolbar from "../../components/Table/TableToolbar";
import TableTopToolbar from "../../components/Table/TableTopToolbar";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
// import Scrollbar from "../../components/scrollbar/Scrollbar";

const StatusMenuList = [
    { label: 'All', value: 'all' },
    { label: 'Success', value: 'success' },
    { label: 'Waiting', value: 'waiting' }
]

const StyledTablePagination = styled(TablePagination)`
  && {
    display: flex;
    justify-content: flex-end;
    .MuiTablePagination-selectRoot {
      margin-right: 20px;
    }
    .MuiTablePagination-selectLabel {
        margin-bottom: 0;
    }
    .MuiTablePagination-displayedRows {
        margin-bottom: 0;
    }
  }
`;

const CustomLoader = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #766DF4;
    animation:s2 1s infinite linear;
    @keyframes s2 {to{transform: rotate(1turn)}}
`

const Ordershop = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [orderHistory, setorderHistory] = useState([])
    const { userName } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filters, setFilters] = useState({
        order_no: '',
        status: 'all',
    });
    const [startDate, setstartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleAllClear = () => {
        setstartDate(null)
        setendDate(null)
        setFilters({
            order_no: '',
            status: 'all',
        })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handletoOrderDesc = (id) => {
        navigate(`/profile/order/${id}`)
    }

    // const check_empty = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderHistory.length) : 0;
    const filteredData = orderHistory.filter(item => {
        // if (filters.status === 'all' || (startDate !== null || endDate !== null)) {
        return (
            item.order_no.toLowerCase().includes(filters.order_no.toLowerCase()) &&
            (startDate === null && endDate === null ? true : (!startDate || new Date(item.create_date) >= startDate) && (!endDate || new Date(item.create_date) <= endDate.setHours(23, 59, 59, 59))) &&
            (filters.status === 'all' ? true : item.status === filters.status)
        );
        // }
    });

    const handleChangeType = (event, newValue) => {
        const { name } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: newValue
        }));
        setPage(0);
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
        setPage(0);
    }

    const handleClearName = () => {
        setFilters(prevFilters => ({
            ...prevFilters,
            order_no: ""
        }));
    }
    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            const result = await fetchOrderHistoryData({ "user_name": userName })
            if (result.status) {
                setorderHistory(result.data.order_list)
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 700)
        }
        fetchData()
    }, [userName])


    return (
        <Box>
            <Helmet>
                <title> Order History | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }} display={"flex"} flexDirection={"row"} justifyContent="space-between">
                    <Typography variant="h5" gutterBottom>
                        {t('profile_page.order_history.header')}
                    </Typography>
                </Box>
                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableTopToolbar
                        value={filters.status}
                        onHandleChange={handleChangeType}
                        MenuList={StatusMenuList}
                        dataList={orderHistory}
                    />
                    <TableToolbar
                        filterType={"order_id"}
                        filterName={filters.order_no}
                        startDate={startDate}
                        endDate={endDate}
                        onstartDate={(startDate) => {
                            setstartDate(startDate)
                            if (startDate === null){
                                setendDate(null)
                            }
                            setPage(0)
                        }}
                        onendDate={(endDate) => {setendDate(endDate);setPage(0)}}
                        onFilterName={handleFilterChange}
                        onClear={handleClearName}
                        onAllClear={handleAllClear}
                    />
                    {/* <Scrollbar> */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('profile_page.order_description.order_id')}</TableCell>
                                    <TableCell>{t('profile_page.order_description.table.track_id')}</TableCell>
                                    <TableCell>{t('profile_page.order_description.date')}</TableCell>
                                    <TableCell>{t('profile_page.order_description.table.total')}</TableCell>
                                    <TableCell>{t('profile_page.order_description.status')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            {isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} align="center">
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                <CustomLoader />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (filteredData.length < 1 & !filters.order_no) ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} align="center">
                                            <Typography variant="h6" paragraph>{t('profile_page.order_history.not_data')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredData
                                    ).map((order, index) => (
                                        <TableRow key={order.order_no}>
                                            <TableCell width="100">{order.order_no}</TableCell>
                                            <TableCell width="120" >{order.tracking ? order.tracking : "N/A"}</TableCell>
                                            <TableCell width="90" >{fDateTime(order.create_date, "dd/MM/yyyy HH:mm")}</TableCell>
                                            <TableCell width="80" >{moneyFormat(order.amount)}</TableCell>
                                            <TableCell width="100"><Label color={(order.status === 'waiting' && 'primary') || (order.status === 'success' && 'success') || 'error'}>{order.status}</Label></TableCell>
                                            <TableCell width="80" >
                                                <IconButton size="small" color="inherit" onClick={() => handletoOrderDesc(order.order_no)}>
                                                    <ArrowForwardIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            )}

                            {(filteredData.length < 1 && filters.order_no !== "") && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>

                                            <Typography variant="h6" paragraph>
                                                {t('profile_page.order_history.not_data')}
                                            </Typography>

                                            <Typography variant="body2">
                                                No results found for &nbsp;
                                                <strong>&quot;{filters.order_no}&quot;</strong>.
                                                <br /> Try checking for typos or using complete words.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}

                        </Table>
                    </TableContainer>
                    {/* </Scrollbar> */}
                    <StyledTablePagination
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Container>
        </Box >
    )
}
export default Ordershop