import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import AOS from 'aos'
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

AOS.init()

const HeaderBranner = styled.h1`
    color: white;
    font-size: 5rem;
    @media only screen and (max-width: 991px) {
        font-size: 3.75rem
    }
    @media only screen and (max-width: 575px) {
        font-size: 3rem
    }
`

const DoctorImage = styled.img`
    @media only screen and (max-width: 1199px) {
        min-height: 576px;
    }
    @media only screen and (max-width: 991px) {
        min-height: 441px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        min-width: 100%;
    }
    @media (max-width: 575px) {
        min-width: 100%;
    }
`

const FirstSection = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ backgroundColor: "#9cd7db", justifyContent: "center", alignItems: "center", display: "flex", height: "100%", paddingTop: "83px", paddingBottom: "82px" }}>
            <Container maxWidth="lg">
                <Grid container columnSpacing={{ xs: 3, sm: 2, md: 5 }} rowSpacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <HeaderBranner data-aos='fade-right' data-aos-delay='200' data-aos-duration="1000">
                            {t('blog_headbanner.head')}
                        </HeaderBranner>
                        <Typography variant='h6' color="white" gutterBottom sx={{ fontWeight: 500, mb: 3 }}>
                            {t('blog_headbanner.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <DoctorImage src={require("../../assets/news/newsarticle.png")} style={{
                            display: 'block',
                            alignContent: "end",
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '400px',
                            maxWidth: '50%'
                        }} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default FirstSection;