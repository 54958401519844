import { Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material"
import { useForm } from 'react-hook-form'
import "../Contact/Contact.css"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { sendEmailtoCompany } from "../../../utils/useApi";
import Swal from "sweetalert2";


const schema = yup.object().shape({
    email: yup.string().email().required(),
    phone: yup.string().min(10).matches(/^0\d*$/, "Invalid phone").required(),
    comment: yup.string().required()
});

const Contact = () => {
    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    const sendEmail = async (obj) => {
        Swal.fire({
            customClass: {
                container: 'my-swal',
            },
            title: t("contact_page.send_load"),
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        const result_email = await sendEmailtoCompany(obj)
        if (result_email) {
            Swal.close()
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'success',
                title: t('contact_page.send_ok'),
            })
            reset()
        } else {
            Swal.close()
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'error',
                title: t('contact_page.send_fail'),
            })
            reset()
        }
    }

    const onSubmit = (data) => {
        sendEmail({
            "from": data.email,
            "description": `${data.comment} เบอร์โทรศัพท์ : ${data.phone}`,
        })

    }

    return (
        <>
            <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%", pt: 4, pb: 2, textAlign: "center" }}>
                <Grid container mt={3}>
                    <Grid item xs={12}>
                        <Typography component={"h3"} variant='h3' sx={{ mb: 1 }}>{t('contact_page.header')}</Typography>
                        <Typography component={"h6"} variant='body1' sx={{ mt: 1 }}>{t('contact_page.description')}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Container component="form" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label={t('contact_page.email')}
                                fullWidth
                                variant="outlined"
                                type="email"
                                {...register("email")}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="formatted-numberformat-input"
                                name="phone"
                                label={t('contact_page.mobile')}
                                fullWidth
                                variant="outlined"
                                type="tel"
                                {...register("phone")}
                                inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                                error={Boolean(errors.phone)}
                                helperText={errors.phone?.message}
                                placeholder={"XXX-XXX-XXXX"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="comment"
                                name="comment"
                                label={t('contact_page.comment')}
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={3}
                                type="text"
                                {...register("comment")}
                                error={Boolean(errors.comment)}
                                helperText={errors.comment?.message}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit" onClick={handleSubmit(onSubmit)}>
                            {t('contact_page.send_btn')}
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </>
    )
}

export default Contact