import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
const Protected = ({ isLoggedIn, children }) => {
    const location = useLocation();
    if (!isLoggedIn) {
        // if (location.state) {
        //     // Render the navigation component with the non-null state value
        //     return <Navigate to="/login" replace state={{ from: location }} />;
        // } else {
        //     // Render the navigation component with a default state value
        //     return <Navigate to="/login" replace state={{ from: "/" }} />;
        // }
        return <Navigate to="/login" replace state={{ from: location }} />
    }
    return children
};

export default Protected;