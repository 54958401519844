import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { Box, Paper, Container, Grid, TextField, Button, InputAdornment, IconButton, Alert, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { resetPasswordAPI } from '../../utils/useApi';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { resetStorePass } from '../../actions/resetpass';

const schema = yup.object().shape({
    password: yup.string().matches(RegExp("(.{7,}.)"), "Password must more than 7 characters.")
        .matches(RegExp("^(?=.*[a-z])(?=.*[A-Z])"), "Password must have uppercase and lowercase letters.")
        .matches(RegExp("(.*\\d.*)"), "Password must have numberic characters.")
        .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must have special characters.")
        .required(`Please input your password.`),
    repassword: yup.string().oneOf([yup.ref('password'), null], 'Password not match.').required(`Please input your repassword.`),
});

const Reset = ({ signatureId }) => {
    const { phone } = useSelector(state => state.resetForm)
    const dispatch = useDispatch()
    const [loginLoading, setLoginLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setShowconfirmPassword] = useState(false);
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (!phone) {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'error',
                title: "Forbidden",
                preConfirm: () => {
                    navigate(`/login`)
                }
            })
        }
    }, [phone])

    const onSubmit = async (values) => {
        try {
            const obj = {
                "token": signatureId,
                "phone": phone,
                "password": values.password
            }
            const resetPassSubmit = await resetPasswordAPI(JSON.stringify(obj))
            if (resetPassSubmit.status === true) {
                Swal.fire({
                    customClass: {
                        container: 'my-swal',
                    },
                    icon: 'success',
                    title: "Change password successfully!",
                    preConfirm: () => {
                        navigate(`/login`)
                        dispatch(resetStorePass())
                    }
                })
            } else {
                Swal.fire({
                    customClass: {
                        container: 'my-swal',
                    },
                    icon: 'error',
                    title: 'Oops...',
                    text: resetPassSubmit.data.response.data.message,
                })
            }
            setLoginLoading(true)
        } catch {
            console.log("Error")
        } finally {
            setLoginLoading(false)
        }
    }
    return (
        <Container component="form" maxWidth="xs">
            <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                <Typography component="h5" variant="h5" sx={{ textAlign: "center", mb: 2 }}>Reset Password</Typography>
                <div style={{
                    height: '4px',
                    width: '55px',
                    display: 'block',
                    margin: '10px auto 20px',
                    backgroundColor: '#ff3366'
                }}></div>

                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <TextField
                            size='small'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            label="Password"
                            fullWidth
                            variant="outlined"
                            {...register("password")}
                            autoComplete="on"
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size='small'
                            type={showconfirmPassword ? "text" : "password"}
                            id="repassword"
                            name="repassword"
                            label="Confirm Password"
                            fullWidth
                            variant="outlined"
                            {...register("repassword")}
                            autoComplete="on"
                            error={Boolean(errors.repassword)}
                            helperText={errors.repassword?.message}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowconfirmPassword(!showconfirmPassword)}
                                        >
                                            {!showconfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mt: 2 }}>
                    <LoadingButton
                        size="large"
                        fullWidth
                        sx={{ mx: 1 }}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        loading={loginLoading}
                        variant="contained"
                    >Reset password</LoadingButton>
                </Box>
            </Paper>
        </Container>
    );
};

export default Reset;