import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import DrawerProfile from '../components/Navbar/DrawerProfile';
import ProfileNavbar from '../components/Navbar/ProfileNavbar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  });

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 44,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const LayoutProfile = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <StyledRoot>
            <DrawerProfile open={mobileOpen} handleDrawerClose={handleDrawerToggle} />
            <ProfileNavbar handleDrawerToggle={handleDrawerToggle} />
            <Main>
                <Outlet />
            </Main>
        </StyledRoot>
    );
};

export default LayoutProfile;