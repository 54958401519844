import { Box, Container, Grid } from '@mui/material';
import React, { useEffect,useState } from 'react';
import ServiceBox from './ServiceBox';
import AOS from 'aos'
import 'aos/dist/aos.css';
import { getVisitorPage } from '../../utils/useApi';

AOS.init()

const StatSection = () => {
    const [pageView, setPageView] = useState(0)
    useEffect(() => {
        getVisitorPage().then((res) => res.status ? setPageView(res.data+1) : setPageView(0))
    }, [])
    return (
        <Box py={5} sx={{ backgroundColor: "transparent", justifyContent: "center", alignItems: "center", display: "flex", height: "100%", marginTop: -15 }}>
            <Container maxWidth="xl">
                <Grid container columnSpacing={2} rowSpacing={3} data-aos='slide-up' data-aos-delay='250' data-aos-duration="2000">
                    <Grid item xs={12} sm={6} md={3}>
                        <ServiceBox title="customers" amount={pageView} description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ServiceBox title="surveys" amount={30} description="Lorem Ipsum is simply dummy text of the printing and typesetting"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ServiceBox title="reviews" amount={55} description="Lorem Ipsum is simply dummy text of the printing"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ServiceBox title="orders" amount={214} description="Lorem Ipsum"/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default StatSection;