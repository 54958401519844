import { Box, Button, Container, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Rating, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import styled from 'styled-components';
import UploadIcon from '@mui/icons-material/Upload';
import { addNewReview } from '../../../utils/useApi';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const MyTextField = styled(OutlinedInput)`
    display: inline-flex;
    border-radius: 8px;
    line-height: 1.4375em;
    font-size: 0.875rem;
`

const StyledRating = styled(Rating)`
  && {
    & .MuiRating-icon {
        font-size: 2.5rem;
    }
    & .MuiRating-iconFilled {
      color: #faaf00;
    }
  }
`;

const ImageBlock = styled.div`
    width: 80px;
    height: 80px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin: 4px;
    border: 1px solid #e5e8eb;
    overflow: hidden;
    position: relative;
`
const CloseButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    top: 4px;
    margin: 0;
    right: 4px;
    padding: 5px;
    position: absolute;
    text-align: center;
    color: rgba(255, 255, 255, 0.72);
    background-color: rgba(22, 28, 36, 0.48);
    font-size: 0.625rem;
`

const validateReview = yup.object().shape({
    review: yup.string().required(),
    rating: yup.number().required().min(1, 'Rating must be at least 0'),
    image: yup.mixed().notRequired()
})

const ReviewPage = () => {
    const { t } = useTranslation()
    const { auth } = useSelector(state => state)
    const image_button = useRef()
    const { watch, control, register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validateReview),
        mode: "onChange"
    })

    const handleFileChange = (event) => {
        setValue(event.target.name, event.target.files[0])
    };

    const removeFile = () => {
        setValue("image", null);
        image_button.current.value = null
    };
    const addReview = async (formData) => {
        const result_add = await addNewReview(formData)
        if (result_add.status) {
            Swal.fire({
                customClass: {
                    container: 'my-swal',
                },
                icon: 'success',
                title: 'Successful',
                text: 'Add review successful.',
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    setValue("review", "");
                    setValue("rating", 0);
                    setValue("image", null);
                }
            })
        }
    }

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('user_name', auth.userName);
        formData.append('description', data.review);
        formData.append('rating', data.rating);
        formData.append('image', data.image || null);
        addReview(formData)
    }
    return (
        <Box>
            <Helmet>
                <title> {t("profile_page.review.header")} | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="sm" component={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 400 }}>
                    {t("profile_page.review.header")}
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"} my={1}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="complex-border-textfield">{t("profile_page.review.text")}</InputLabel>
                            <MyTextField
                                id="complex-border-textfield"
                                name="text"
                                label={t("profile_page.review.text")}
                                type={"text"}
                                multiline={true}
                                minRows={3}
                                {...register("review")}
                            />
                            <FormHelperText error={errors.review ? true : false} id="complex-border-textfield-helper-text">
                                {errors.review?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Controller
                                id="rating"
                                name="rating"
                                defaultValue={0}
                                control={control}
                                render={({ field }) => (
                                    <StyledRating
                                        {...field}
                                        size="large"
                                        id="rating"
                                        name="rating"
                                        precision={1}
                                        value={field.value}
                                    />
                                )}
                            />
                            <FormHelperText error={errors.rating ? true : false} id="complex-border-textfield-helper-text">
                                {errors.rating?.message}
                            </FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <Button variant="contained" component="label" color='error'>
                            <UploadIcon sx={{ mr: 0.5 }} />
                            {t("profile_page.review.upload_btn")}
                            <input ref={image_button} name="image" hidden accept="image/*" type="file" onChange={handleFileChange} />
                        </Button>
                        <FormHelperText error={errors.image ? true : false} id="complex-border-textfield-helper-text">
                            {errors.image?.message}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {/* <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row' }}> */}
                        {watch("image") && (<ImageBlock style={{ opacity: 1, transform: 'none' }}>
                            <Box
                                component={"img"}
                                src={watch("image") ? URL.createObjectURL(watch("image")) : watch("image")}
                                style={{
                                    display: "block",
                                    maxWidth: "100%",
                                    width: "100%",
                                    height: "100%",
                                    flexShrink: 0,
                                    objectFit: "cover",
                                    position: "initial"
                                }}
                            />
                            <CloseButton type='button' onClick={removeFile}>X</CloseButton>
                        </ImageBlock>)}
                        {/* </Box> */}
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {watch('image') && <img src={URL.createObjectURL(watch('image'))} />}
                    </Grid> */}
                </Grid>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit" disabled={!watch("review")}>
                        {t("profile_page.review.send_btn")}
                    </Button>
                </Box>

            </Container>
        </Box >
    );
};

export default ReviewPage;