import { Alert, Box, Button, Container, Grid, Paper, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OTPRequest, setOTPStatus, verifyOTP } from '../../utils/useApi';
import SignUpImg from '../../assets/register.webp'
import { resetStorePass, storeToken } from '../../actions/resetpass';
import Swal from 'sweetalert2';
import { MuiOtpInput } from 'mui-one-time-password-input';

const VerifyOTPVerification = () => {
    const dispatch = useDispatch()
    const [time, setTime] = useState(300)
    const [otpCode, setOtpCode] = useState('')
    // const [refCode, setrefCode] = useState('')
    const [openVerification, setopenVerification] = useState(false)
    const [token, setToken] = useState('')
    // const [otpRequest, setOtpRequest] = useState({ token: '', ref: '' })
    const [messageSnackbar, setMessageSnackbar] = useState("ttt")
    const [statusSnackbar, setStatusSnackbar] = useState("info")
    const { register, resetForm } = useSelector(state => state)

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Required for some browsers

            const confirmationMessage = 'Please note that if you refresh the page, the OTP might not be sent again. Kindly ensure you have the valid OTP before proceeding.';
            event.returnValue = confirmationMessage; 
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        const interval = setInterval(() => {
            setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0)); // Decrease time by 1 second
        }, 1000);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            clearInterval(interval);
        };
    }, [])

    // useEffect(() => {
    //     resendCode()
    // }, [])

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const navigate = useNavigate()
    const handleOTPChange = (newValue) => {
        const regex = /^[0-9\b]+$/;
        if (newValue === "" || regex.test(newValue)) {
            setOtpCode(newValue);
        }
    };

    const resendCode = async () => {
        const obj = { "phone": register.register_data.phone }
        const otpreq = await OTPRequest(JSON.stringify(obj))
        if (otpreq.status === "success") {
            dispatch(storeToken(otpreq.token))
            setStatusSnackbar("info")
            setMessageSnackbar("Send OTP, please check your phone.")
            setopenVerification(true)
            setTime(300)
        }

    }
    const handleVerificationAlertClose = () => {
        setopenVerification(false);
    };

    const submitOTP = async (e) => {
        e.preventDefault()
        const verify = await setOTPStatus(JSON.stringify({
            "token": resetForm.token,
            "phone": register.register_data.phone,
            "pin": otpCode
        }))

        if (verify.status === true) {
            Swal.fire({
                icon: 'success',
                title: "Verify OTP Successfully",
                preConfirm: () => {
                    navigate(`/login`)
                    dispatch(resetStorePass())
                }
            })
        } else {
            Swal.fire(
                {
                    icon: 'error',
                    title: verify.data.response.data.error.errors[0].message.replace(/^./, str => str.toUpperCase())
                }
            )
        }

    }
    return (
        <Box
            sx={{
                backgroundImage: `url(${SignUpImg})`,
                display: "flex",
                /* Center and scale the image nicely */
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '100vh',
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Container component="form" maxWidth="xs" noValidate autoComplete="off" onSubmit={submitOTP}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                    <Typography variant="h4" component="h4" sx={{ py: 1, textAlign: "center" }}>
                        OTP Verification
                    </Typography>
                    {/* <Typography variant="body1" component="p" sx={{ pb: 1, textAlign: "center" }}>
                        ref : {refCode}
                    </Typography> */}
                    <Typography variant="body1" component="p" sx={{ pb: 1.5, textAlign: "center" }}>
                        Time left : {`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
                    </Typography>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <MuiOtpInput length={6} value={otpCode} onChange={handleOTPChange} />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Button variant="text" fullWidth sx={{ mt: 2 }} onClick={resendCode} disabled={Boolean(time > 290)}>Resend Code</Button>
                        <Button variant="contained" color="success" fullWidth sx={{ mt: 1 }} type="submit" disabled={otpCode.length !== 6}>
                            Submit
                        </Button>
                    </Box>
                </Paper>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    autoHideDuration={5000}
                    open={openVerification}
                    onClose={handleVerificationAlertClose}
                    key={'otpver'}
                >
                    <Alert onClose={handleVerificationAlertClose} severity={statusSnackbar} variant='filled' sx={{ width: '100%' }}>
                        {messageSnackbar}
                    </Alert>
                </Snackbar>
            </Container>
        </Box>
    );
};

export default VerifyOTPVerification;