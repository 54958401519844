import React from 'react';
import FirstSection from '../../components/Blog/FirstSection';
import BlogListSection from '../../components/Blog/BlogListSection';

const Blog = () => {
    return (
        <>
            <FirstSection/>
            <BlogListSection/>
        </>
    );
};

export default Blog;