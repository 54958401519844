import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AccountPopover from './AccountPopover';
import MenuIcon from '@mui/icons-material/Menu';
import "./MyNavbar.css"
import { AppBar, Badge, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import LanguagePopover from './LanguagePopover';
import { useTranslation } from 'react-i18next';

const ProfileNavbar = ({ handleDrawerToggle }) => {
    const { auth, profile } = useSelector(state => state)
    const { t } = useTranslation()
    return (
        <Box sx={{ display: 'flex', padding: "0.5rem 0px", marginBottom: "70px" }}>
            <AppBar
                position="fixed"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, py: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <Link
                            variant="button"
                            color="text.primary"
                            href="/"
                            sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        >
                            <img
                                alt="logo"
                                src={require('../../assets/W.png')}
                                style={{
                                    height: 60,
                                    maxWidth: "100%",
                                }}
                            />
                        </Link>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerToggle}
                            color="inherit"
                        >
                            <MenuIcon fontSize='24pt' />
                        </IconButton>
                    </Box>
                    <Link
                        variant="button"
                        color="text.primary"
                        href=""
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none', display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}
                    >
                        <img
                            alt="logo"
                            src={require('../../assets/W.png')}
                            style={{
                                height: 60,
                                maxWidth: "100%",
                            }}
                        />
                    </Link>
                    <Box sx={{ flexGrow: 1, mx: 2, display: { xs: 'none', md: 'flex' } }}>
                        <div className='dropdown list'>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="/explore/products"
                                sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                textTransform="none"
                            >
                                {t('navbar.shopping')}
                            </Link>
                        </div>
                        <div className='dropdown list'>
                            <Link
                                variant="button"
                                color="text.primary"
                                href="#"
                                sx={{ my: 1, px: 1, textDecoration: 'none', fontSize: '11pt' }}
                                textTransform="none"
                            >
                                {t('navbar.explore')}
                            </Link>
                            <ul className="dropdown-content">
                                <li><a href="/explore/review">{t('sub_navbar.customer_review')}</a></li>
                                <li><a href="/explore/products">{t('sub_navbar.product')}</a></li>
                                <li><a href="/assessment">{t('sub_navbar.assessment')}</a></li>
                            </ul>
                        </div>
                    </Box>
                    <Box sx={{ px: 2, display: { xs: 'none', md: 'flex' } }}>
                        <LanguagePopover />
                    </Box>
                    {auth.accessToken.token ? (
                        <AccountPopover username={auth.userName} image={profile.user.image}/>
                    ) : (
                        <Button href="/login" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default ProfileNavbar;