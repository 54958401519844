import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Badge, Box, DialogContent, DialogTitle, Fab, Fade, Hidden, IconButton, Paper, Popper, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';


const CartBox = styled(Box)`
    right: 0px;
    top: 112px;
    z-index: 999;
    cursor: pointer;
    position: fixed;
    color: rgb(33, 43, 54);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 12px 24px 12px 16px;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const LayoutCheckout = () => {
    const navigate = useNavigate()
    const { auth, cart, profile } = useSelector(state => state)
    const SumQtyinCart = () => {
        const sum = cart.items.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.qty);
        }, 0);

        return sum
    };
    return (
        <div>
            <CartBox sx={{ display: { xs: 'flex', md: 'none' } }} onClick={() => navigate(`/cart`)}>
                <Badge color="error" badgeContent={SumQtyinCart()} size="large">
                    <ShoppingCartIcon fontSize='medium' />
                </Badge>
            </CartBox>
            <Outlet />
        </div>
    );
};

export default LayoutCheckout;