import { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Pagination, Typography } from '@mui/material'
import ProductCard2 from './ProductCard2';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DoctorImage = styled.img`
    @media only screen and (max-width: 1199px) {
        min-height: 200px;
    }
    @media only screen and (max-width: 991px) {
        min-height: 200px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        min-height: 200px;
    }
    @media (max-width: 575px) {
        min-height: 200px;
    }
`

export default function ProductPagination({ products, itemsPerPage, currentPage, setCurrentPage, loading, text }) {

    const [displayedProducts, setDisplayedProducts] = useState([]);
    const { i18n, t } = useTranslation()
    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        if (products.length > 0) {
            setDisplayedProducts(products.slice(startIndex, endIndex));
            // setDisplayedProducts(products)
        } else {
            setDisplayedProducts([])
        }

    }, [currentPage, products, itemsPerPage]);

    if (loading) {
        return (
            <CircularProgress />
        )
    }
    return (
        <div>
            <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                    {loading ? (
                        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Grid>
                    ) : displayedProducts.length > 0 ? (displayedProducts.map((product, index) => (
                        <Grid item key={index} lg={3} md={4} sm={6} xs={12}>
                            <ProductCard2 item={product} id={product.product_id} name={product[`name_${i18n.language}`]} price={product.price} image={product.image} />
                        </Grid>
                    ))) : (text) && (
                        <Grid item xs={12} display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                            <DoctorImage src={require("../../assets/shopping/not-found.png")} style={{
                                width: '175px',
                                maxWidth: '100%'
                            }} />
                            <Typography component={"h4"} align="center" variant="body1" sx={{ py: 1 }}>
                                {t('shopping_page.not_found')}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box display={"flex"} flexDirection={"row-reverse"} justifyContent={"space-between"} mt={2}>
                {
                    displayedProducts.length > 0 && <Pagination
                        count={Math.ceil(products.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                    />
                }
                {
                    displayedProducts.length > 0 && <Typography>Page {currentPage} of {Math.ceil(products.length / itemsPerPage)}</Typography>
                }

            </Box>
        </div>
    );
}