import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Link } from '@mui/material'
import { fetchAssessmentResult } from '../../../utils/useApi';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { moneyFormat } from '../../../utils/formatCurrency';
import { fetchCart, setCartType } from '../../../actions/cart';
import { setAlternativeVitamin } from '../../../actions/assessment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CustomLoader = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #766DF4;
    animation:s2 1s infinite linear;
    @keyframes s2 {to{transform: rotate(1turn)}}
`

const MyReport = () => {
    const [data, setData] = useState({ "assignment": [], "assignment11": [] })
    const [loading, setLoading] = useState(false)
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const fetchData = async () => {
        const result = await fetchAssessmentResult({ "user_name": auth.userName })
        if (result.status) {
            setData(result.data)
        }
    }
    const handleToSummary = () => {
        dispatch(fetchCart(data.assignment))
        dispatch(setAlternativeVitamin(data.assignment11))
        dispatch(setCartType("A"))
        navigate("/assessment/summary", { replace: true })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])
    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="65vh"
            >
                <CustomLoader />
            </Box>
        )
    }

    if (data.assignment.length < 1) {
        return (
            <Box>
                <Helmet>
                    <title> My Report | Whealth Vitamin </title>
                </Helmet>
                <Container maxWidth="md">
                    <Grid container columnSpacing={{ xs: 3, sm: 2, md: 2 }} rowSpacing={1} my={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sx={{ minHeight: '65vh' }} display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                            <Typography align="center" variant="body1" sx={{ py: 2 }}>
                                {t('profile_page.report.not_vitamin')}
                            </Typography>
                            <Box display="flex" flexDirection="row" justifyContent="space-around">
                                <Link href="/assessment" underline="none">
                                    <Button color="primary" variant='contained' sx={{ display: 'inline-block', textTransform: 'none', mx: 0.5 }}>{t('profile_page.report.ass_btn')}</Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        )
    }
    return (
        <Box>
            <Helmet>
                <title> My Report | Whealth Vitamin </title>
            </Helmet>
            <Container maxWidth="md">
                <Box sx={{ mb: 2 }} display={"flex"} flexDirection={"row"} justifyContent="space-between">
                    <Typography variant="h5" gutterBottom>
                        {t('profile_page.report.header')}
                    </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant="h6" gutterBottom my={2}>
                        {t('profile_page.report.header_1')}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="customized table">
                            <TableBody>
                                {data.assignment.map((row) => (
                                    <TableRow key={row.product_id}>
                                        <TableCell align="left">{row[`name_${i18n.language}`]}</TableCell>
                                        <TableCell align="right">฿ {moneyFormat(row.price)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography variant="h6" gutterBottom my={2}>
                        {t('profile_page.report.header_2')}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="customized table">
                            <TableBody>
                                {data.assignment11.length > 0 ? data.assignment11.map((row) => (
                                    <TableRow key={row.product_id}>
                                        <TableCell align="left">{row[`name_${i18n.language}`]}</TableCell>
                                        <TableCell align="right">฿ {moneyFormat(row.price)}</TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell align="center">{t('profile_page.report.not_data')}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box mt={3} mb={2} display={"flex"} flexDirection={"row-reverse"}>
                    <Button type="button" variant="contained" color="error" sx={{ px: 2 }} onClick={handleToSummary}>
                        {t('profile_page.report.sum_btn')}
                    </Button>
                </Box>
            </Container >
        </Box >
    );
};

export default MyReport;