let initialState = {
    user: {
        email: "",
        firstname: "",
        lastname: "",
        mobile: "",
        birthday: "",
        gender: "",
        address: "",
        subdistrict: "",
        district:"",
        province:"",
        zip_code: "",
        image: ""
    }
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PROFILE":
            return {
                ...state,
                user: action.payload
            }
        case "UPDATE_PROFILE":
            return {
                ...state,
                user: action.payload
            }
        case "CLEAR_PROFILE":
            return initialState
        default:
            return state;
    }
};

export default profileReducer;