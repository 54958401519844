import { Typography, Box, Container, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CookiePolicyPage = () => {
    const { t } = useTranslation()
    return (
        <Box py={2}>
            <Container maxWidth="md">
                <Typography variant='h3' align='center' mb={3}>{t('cookie_policy.subject')}</Typography>
                <Stack direction={"column"} gap={2}>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item1_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item1_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item2_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item2_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item3_header')}</Typography>
                        <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('cookie_policy.item3.1_desc') }}></Typography>
                        <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('cookie_policy.item3.2_desc') }}></Typography>
                        <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('cookie_policy.item3.3_desc') }}></Typography>
                        <Typography variant='body1' dangerouslySetInnerHTML={{ __html: t('cookie_policy.item3.4_desc') }}></Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item4_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item4_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item5_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item5_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item6_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item6_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item7_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item7_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item8_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item8_desc')}</Typography>
                        <Box px={3}>
                            <Typography variant='body1'>{t('homepage.company')}</Typography>
                            <Typography variant='body1'>{t('homepage.address')}</Typography>
                            <Typography variant='body1'>{t('homepage.email')}</Typography>
                            <Typography variant='body1'>{t('homepage.phone')}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('cookie_policy.item9_header')}</Typography>
                        <Typography variant='body1'>{t('cookie_policy.item9_desc')}</Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default CookiePolicyPage;