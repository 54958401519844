import { Container, Grid, Box, Typography, Avatar } from '@mui/material'
import "./Ourteam.css"
import { useTranslation } from 'react-i18next'
const Ourteam = () => {
    const { t } = useTranslation()
    return (
        <>
            <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%", pt: 4, pb: 3, textAlign: "center" }}>
                <Grid container mt={3}>
                    <Grid item xs={12}>
                        <Typography component={"h3"} variant='h3' sx={{ mb: 1 }}>{t('ourteam_page.header')}</Typography>
                        <Typography component={"h6"} variant='body1' sx={{ mt: 1 }}>{t('ourteam_page.description')}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ backgroundColor: "#9cd7db", height: "100%", paddingTop: "83px", paddingBottom: "82px", my: 5 }}>
                <Container maxWidth="md">
                    <Grid container rowSpacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12} borderRight={{sm: 'none', md: "3px dashed #94b1ad"}}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                alignItems: "center",
                            }}>
                                <Avatar
                                    alt="people1"
                                    src={require("../../../assets/doctor/doctor-1.jpg")}
                                    sx={{ width: 200, height: 200, border: '8px solid #516563' }}
                                />
                                <Typography component={"h6"} variant='h6' sx={{ mb: 1, mt: 3 }}>{t('ourteam_page.person_1.name')}</Typography>
                                <Typography component={"h6"} variant='body1' gutterBottom sx={{ fontStyle: 'italic', fontWeight: 300 }}>({t('ourteam_page.person_1.role')})</Typography>
                                <Typography component={"h6"} variant='body1' sx={{ mt: 2, px: 5, color: '#ffffff' }}>{t('ourteam_page.person_1.position')}</Typography>
                                <Typography component={"h6"} variant='body1' sx={{px: 5, color: '#ffffff' }}>{t('ourteam_page.person_1.addition_detail')}</Typography>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                alignItems: "center",
                            }}>
                                <Avatar
                                    alt="people2"
                                    src={require("../../../assets/doctor/doctor-2.jpg")}
                                    sx={{ width: 200, height: 200, border: '8px solid #516563' }}
                                />
                                <Typography component={"h6"} variant='h6' sx={{ mb: 1, mt: 3 }}>{t('ourteam_page.person_2.name')}</Typography>
                                <Typography component={"h6"} variant='body1' gutterBottom sx={{ fontStyle: 'italic', fontWeight: 300 }}>({t('ourteam_page.person_2.role')})</Typography>
                                <Typography component={"h6"} variant='body1' sx={{ mt: 2, px: 5, color: '#ffffff' }}>{t('ourteam_page.person_2.position')}</Typography>
                                <Typography component={"h6"} variant='body1' sx={{px: 5, color: '#ffffff' }}>{t('ourteam_page.person_2.addition_detail')}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
export default Ourteam