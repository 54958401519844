
// import { useDispatch, useSelector } from "react-redux"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { Box, Paper, Container, Grid, TextField, InputAdornment, IconButton, Alert, Typography, Divider, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoginImg from "../../assets/login_wave.svg"
// import "../Login/Login.css"
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordIcon from '@mui/icons-material/Password';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, updateProfile, updateUsername } from '../../actions';
import { fetchUserProfile, SignIn } from '../../utils/useApi';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { fetchCart, setCartType, setOrderId } from '../../actions/cart';
import { LoadingButton } from '@mui/lab';

const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required()
});

const Login = () => {
    const { auth, cart } = useSelector(state => state)
    const [showPassword, setShowPassword] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [messageAlert, setmessageAlert] = useState("")
    const [isLoading, setisLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema)

    });

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const fetchProfile = async (user) => {
        const fetch_pdata = await fetchUserProfile({ "userName": user })
        if (fetch_pdata.status) {
            dispatch(updateProfile(fetch_pdata.data.user))
            dispatch(setOrderId(fetch_pdata.data.order_waiting_data?.order_no || ""))
            dispatch(setCartType(fetch_pdata.data.order_waiting_data?.type || ""))
            if (cart.items.length === 0){
                dispatch(fetchCart(fetch_pdata.data.order_detail_waiting_data))
            } else if (cart.items.length !== 0) {
                navigate("/cart")
            }
        }
    }

    const onSubmit = async (values) => {
        const obj = {
            "user_name": values.username,
            "password": values.password
        }
        setisLoading(true)
        try {
            const result_login = await SignIn(JSON.stringify(obj))

            if (result_login.status) {
                dispatch(updateUsername(values.username))
                dispatch(login({ token: result_login.data.refresh_token.token, expire: result_login.data.refresh_token.expire }, { token: result_login.data.access_token.token, expire: result_login.data.access_token.expire }))
                setShowAlert(false)

                fetchProfile(values.username)
                /* Saving the previous url in localStorage and then navigating to that url. */
                let from = location.state?.from?.pathname || '/';


                navigate(from, { replace: true })

            } else {
                setmessageAlert(result_login.data.response.data.message)
                setShowAlert(true)
            }
        } catch {
            console.log("Cannot connect to API")
        } finally {
            setisLoading(false)
        }

    }

    const watchLoginField = watch(["username", "password"])
    const checkLoginEnabled = !(Boolean(watchLoginField[0]) && Boolean(watchLoginField[1]))
    return (
        <Box
            sx={{
                backgroundImage: `url(${LoginImg})`,
                display: "flex",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '100vh',
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Container maxWidth="xs">
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
                    <Stack>
                        <Link to={"/"} style={{ textDecoration: "none" }}>
                            <IconButton>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                        </Link>
                    </Stack>
                    <img
                        alt="logo"
                        src={require('../../assets/W.png')}
                        style={{
                            height: 80,
                            width: 160,
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '15px'
                        }}
                    />
                    <div style={{
                        height: '4px',
                        width: '55px',
                        display: 'block',
                        margin: '10px auto 20px',
                        backgroundColor: '#ff3366'
                    }}></div>
                    {showAlert && <Alert severity="error" action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    } sx={{ my: 2 }}>{messageAlert}</Alert>}
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    size='small'
                                    id="username"
                                    name="username"
                                    label="Username"
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    {...register("username")}
                                    error={Boolean(errors.username)}
                                    helperText={errors.username?.message}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <PasswordIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    size='small'
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    variant="outlined"
                                    type={showPassword ? "text" : "password"}
                                    {...register("password")}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    autoComplete="on"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mt: 2 }}>
                        <LoadingButton
                            size='large'
                            variant="contained"
                            fullWidth
                            sx={{ mx: 1, textTransform: 'none' }}
                            loading={isLoading}
                            onClick={handleSubmit(onSubmit)}
                            disabled={checkLoginEnabled}
                        >
                            Sign in
                        </LoadingButton>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to={"/signup"} style={{ textAlign: "center", textDecoration: "none" }}>
                            Create an account
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant='body1'>- OR -</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to={"/reset"} style={{ textAlign: "center", textDecoration: "none" }}>
                            Forgot Password?
                        </Link>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {"Account not Verify?"}
                        <Link to={"/verify"} style={{ marginLeft: 2 }}>
                            Verify
                        </Link>
                    </Box>
                </Paper>
            </Container>
        </Box >
    )
}

export default Login