import { format, getTime, formatDistanceToNow } from 'date-fns';
import th from 'date-fns/locale/th'
import en from 'date-fns/locale/en-US'
// ----------------------------------------------------------------------

export function fDate(date, language) {
  let newdate = new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  if (language === 'th') {
    newdate = new Date(date).toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
  return date ? newdate : "";
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTimeTH(date, language) {
  const fm = 'dd MMM yyyy p';

  return date ? format(new Date(date), fm, { locale: language === 'th' ? th : en }) : '';
}
export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}
