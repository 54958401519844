import { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'language_list.eng',
    icon: 'ic_flag_en',
  },
  {
    value: 'th',
    label: 'language_list.th',
    icon: 'ic_flag_th',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState(0)

  const fetchLanguage = () => {
    const filter_lang = LANGS.findIndex((i) => i.value === i18n.language)
    setLang(filter_lang)
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const selectLang = (index, language) => {
    i18n.changeLanguage(language)
    setLang(index)
    handleClose()
  }

  useEffect(() => {
    fetchLanguage()
  }, [lang])
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={`/static/images/${LANGS[lang].icon}.svg`} alt={LANGS[lang].label} width="30px" height="100%" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option, index) => (
            <MenuItem key={option.value} selected={i18n.language === option.value} onClick={() => selectLang(index, option.value)}>
              <Box component="img" alt={option.label} src={`/static/images/${option.icon}.svg`} sx={{ width: 28, mr: 2 }} />

              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
