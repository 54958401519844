import { Grid, Container, TextField, InputAdornment, Box, Button, IconButton, Paper, Typography, Link } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import ProductPagination from '../../../components/ProductCard/ProductPagination';
import { fetchProduct, fetchProductGroup } from '../../../utils/useApi';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  position: relative;
  margin: 0 15px 0 0;
  padding: 3px 8px;
  font-size: 18px;
  color: black;
  &.active {
    background-color: #FFEDF0;
    border: 1px solid #FD546A;
    border-radius: 30px;
  }
`;

const StyledLink2 = styled(Link)`
  position: relative;
  margin: 0 15px 0 0;
  padding: 3px 8px;
  font-size: 18px;
  color: black;
  &:hover,
    &.active {
        &:before {
            content: '';
            position: absolute;
            bottom: -6.5px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #123456;
        }
    }
`;

const Products = () => {
    const { t, i18n } = useTranslation()
    const [products, setProducts] = useState([])
    const [filterProduct, setfilterProduct] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [group, setgroup] = useState([])
    const [text, setText] = useState("")
    const [searchText, setSearchText] = useState("")
    const [loading, setLoading] = useState(false)
    const [filtergroup, setFiltergroup] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    // const [priceRange, setpriceRange] = useState([250, 750])

    const fetchProductAPI = async () => {
        const result = await fetchProduct(JSON.stringify({ "filter": "S" }))
        if (result.data) {
            setProducts(result.data)
        } else {
            setProducts([])
            console.log("Cannot connect to API")
        }
    }

    const fetchProductGroupAPI = async () => {
        setLoading(true)
        try {
            const result_group = await fetchProductGroup()
            if (result_group.data) {
                setgroup(result_group.data)
            } else {
                setgroup([])
                console.log("Cannot connect to API")
            }
        } catch {
            console.log("Cannot connect to API")
        } finally {
            setLoading(false)
        }

    }


    useEffect(() => {
        setLoading(true)
        fetchProductAPI()
        fetchProductGroupAPI()
        setLoading(false)
    }, [])

    useEffect(() => {
        const group_name = searchParams.get('group')
        if (group_name) {
            setFiltergroup(group_name);
        } else {
            setFiltergroup("all")
        }
    }, [searchParams])


    const handleFilterChange = (filter) => {
        setFiltergroup(filter)
    }

    useEffect(() => {

        if (filtergroup === 'all') {
            searchParams.forEach((_, key) => searchParams.delete(key));
        } else if (filtergroup) {
            searchParams.set('group', filtergroup);
        }

        setSearchParams(searchParams)

    }, [searchParams, filtergroup])

    useEffect(() => {
        const filtered = products.length > 0 ? products.filter(product => (
            (filtergroup === 'all' || product[`group_name_en`] === filtergroup) &&
            (!text || product[`name_${i18n.language}`].toLowerCase().includes(text.toLowerCase()))
        )) : []

        setfilterProduct(filtered)
    }, [filtergroup, text, products])



    const clearText = () => {
        setSearchText("")
        setText("")
    }

    const handleSearch = () => {
        setText(searchText)
        setCurrentPage(1)
    };

    if (loading) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <Box sx={{ backgroundColor: "#f6f9fc" }} py={2}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Paper>
                            <img
                                alt="Sample"
                                src={require("../../../assets/product/banner.jpg")}
                                height="300px"
                                width="100%"
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container my={3}>
                    <Box display="flex" flex={1} >
                        <Box display="flex" flex={1} sx={{ px: 1 }}>
                            <TextField
                                value={searchText}
                                size={'small'}
                                onChange={e => { e.preventDefault(); setSearchText(e.target.value) }}
                                fullWidth
                                sx={{ alignItems: 'center', p: 0, m: 0, outline: 'none' }}
                                placeholder={t('shopping_page.search_placeholder')}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" >
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (searchText && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={clearText}>
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>
                                    ))
                                }}
                            />
                        </Box>
                        <Box display="flex">
                            <Button variant='contained' onClick={handleSearch} sx={{ padding: "0 30px" }}>
                                <Search />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Box sx={{ mb: 1, padding: "10px 15px", overflowX: 'overlay', position: 'relative' }}>
                    <Box sx={{ display: "flex", flexDirection: "row", whiteSpace: "nowrap", flexWrap: "nowrap" }}>
                        <StyledLink onClick={() => handleFilterChange("all")} className={filtergroup === 'all' ? 'active' : ''} sx={{ cursor: "pointer" }} underline="none">
                            {t('shopping_page.all_btn')}
                        </StyledLink>
                        {group.length > 0 && group.map((i, index) => (
                            <StyledLink2 key={index} onClick={() => handleFilterChange(i['group_name_en'])} className={filtergroup === i['group_name_en'] ? 'active' : ''} sx={{ cursor: "pointer" }} underline="none">
                                {i[`group_name_${i18n.language}`]}
                            </StyledLink2>
                        ))}
                    </Box>
                </Box>
                <Paper sx={{ mb: 4, padding: "0.5rem 1.25rem", display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant='body1' component="h5" sx={{ fontSize: '16px', fontWeight: 600, lineHeight: 1.5 }}>{t('shopping_page.result1', { word: (text === "" ? `ALL` : `${text || ''}`) })}</Typography>
                        <Typography variant='body1' component="h5" sx={{ fontSize: '14px' }}>{t('shopping_page.result2', { num: (filterProduct.length > -1 ? filterProduct.length : 0) })}</Typography>
                    </Box>
                    <Box></Box>
                </Paper>
                <Grid container columnSpacing={3}>
                    <Grid item md={12} xs={12}>
                        <ProductPagination
                            products={filterProduct}
                            itemsPerPage={12}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            loading={loading}
                            text={text}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Products