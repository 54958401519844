export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVEALL_FROM_CART = 'REMOVEALL_FROM_CART';
export const SET_CART = 'SET_CART';
export const ADDALTERNATIVE_TO_CART = 'ADDALTERNATIVE_TO_CART';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const CLEAR_ORDER_ID = 'CLEAR_ORDER_ID';
export const SET_CART_TYPE = 'SET_CART_TYPE';

export const addToCart = (item) => {
    return { type: ADD_TO_CART, payload: item };
}

export const addAlternativeToCart = (item) => {
    return { type: ADDALTERNATIVE_TO_CART, payload: item };
}

export const removeFromCart = (item) => {
    return { type: REMOVE_FROM_CART, payload: item };
}

export const removeAllFromCart = (item) => {
    return { type: REMOVEALL_FROM_CART, payload: item };
}

export const fetchCart = (list_item) => {
    return {
        type: SET_CART,
        payload: list_item
    }
}

export const setOrderId = (order_id) => {
    return {
        type: SET_ORDER_ID,
        payload: order_id
    }
}

export const setCartType = (type) => {
    return {
        type: SET_CART_TYPE,
        payload: type
    }
}

export const resetOrderId = () => {
    return {
        type: CLEAR_ORDER_ID
    }
}