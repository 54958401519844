import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PDPAPage = () => {
    const { t } = useTranslation()
    return (
        <Box py={2}>
            <Container maxWidth="md">
                <Typography variant='h3' align='center' mb={3}>{t('pdpa_condition.subject')}</Typography>
                <Stack direction={"column"} gap={2}>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('pdpa_condition.intro')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.intro_read')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item1_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item1.1_desc')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item1.2_desc')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item1.3_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item2_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item2.1_desc')}</Typography>
                        <Box px={3} component={"ul"}>
                            <Typography variant='body1'>- {t('pdpa_condition.item2.1.1_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item2.1.2_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item2.1.3_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item2.1.4_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item2.1.5_desc')}</Typography>
                        </Box>
                        <Typography variant='body1'>{t('pdpa_condition.item2.2_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item3_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item3.1_desc')}</Typography>
                        <Box px={3}>
                            <Typography variant='body1'>- {t('pdpa_condition.item3.1.1_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item3.1.2_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item3.1.3_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item3.1.4_desc')}</Typography>
                            <Typography variant='body1'>- {t('pdpa_condition.item3.1.5_desc')}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item4_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item4.1_desc')}</Typography>
                        <Box px={3}>
                            <Typography variant='body1'>{t('pdpa_condition.item4.1.1_desc')}</Typography>
                            <Typography variant='body1'>{t('pdpa_condition.item4.1.2_desc')}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item5_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item5_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item6_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item6_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item7_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item7_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item8_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item8_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item9_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item9_desc')}</Typography>
                        <Box px={3}>
                            <Typography variant='body1'>- {t('homepage.company')}</Typography>
                            <Typography variant='body1'>- {t('homepage.address')}</Typography>
                            <Typography variant='body1'>- {t('homepage.email')}</Typography>
                            <Typography variant='body1'>- {t('homepage.phone')}</Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('pdpa_condition.item10_header')}</Typography>
                        <Typography variant='body1'>{t('pdpa_condition.item10_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('pdpa_condition.conclusion_1')}</Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default PDPAPage;