import { Box, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDetailBlog } from '../../utils/useApi';
import { fDateTime, fDateTimeTH } from '../../utils/formatTime';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactSocial from '../../components/Blog/ReactSocial';
import { Helmet } from 'react-helmet-async';
// import './style.css'

const ContentImage = styled.img`
    display: block;
    align-content: end;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    @media only screen and (min-width: 1199px) {
        max-width: 1000px
    }
    @media only screen and (max-width: 1199px) {
        max-width: 800px
    }

`

const DetailArticle = () => {
    const { article_id } = useParams()
    const navigate = useNavigate()
    const { i18n } = useTranslation()
    const [data, setData] = useState({"post_title": ""})
    const [loading, setLoading] = useState(false)
    const fetch_article = async () => {
        setLoading(true)
        const result_fetch = await fetchDetailBlog({ "article_id": article_id })
        if (result_fetch.status) {
            setData(result_fetch.data)
            if (typeof result_fetch.data === 'string') {
                navigate("/")
            }
        }
        setLoading(false)
    }
    useEffect(() => {
        fetch_article()
    }, [])

    return (
        <Box sx={{ py: 3 }}>
            <Helmet>
                <title> {data?.post_title} </title>
            </Helmet>
            <Container maxWidth="lg">
                <Typography variant='h3' component={'h3'} sx={{ fontWeight: 'bold', mb: 1 }}>{loading ? <Skeleton animation="wave" /> : data.post_title}</Typography>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant='body1' component={'h5'}>{loading ? <Skeleton width="20%" animation="wave" /> : fDateTimeTH(data.create_date, i18n.language)}</Typography>
                    <ReactSocial url={`https://whealthvitamin.com/blog/${article_id}`} />
                </Stack>

                <Grid container py={2}>
                    {data.img ? (<Grid item xs={12}>
                        <ContentImage src={`${process.env.REACT_APP_IMAGE_DESTINATION_URL}/article/${data.img}`} />
                    </Grid>) : (
                        <Grid item xs={12} display="flex" justifyContent={"center"}>
                            <Skeleton variant="rectangular" width={800} height={600} animation="wave" />
                        </Grid>
                    )}
                    {loading ? (<Grid item xs={12} my={4} display="flex" justifyContent={"center"}><Skeleton variant='rectangular' width={800} height={600} animation="wave" /></Grid>) : (<Grid item xs={12} my={2}><div className="ql-editor" dangerouslySetInnerHTML={{ __html: data.contents }}></div></Grid>)}
                </Grid>
            </Container>
        </Box>
    );
};

export default DetailArticle;