import numeral from 'numeral';

export function moneyFormat(number) {
    const format = number ? numeral(number).format('฿ 0,0.00') : '';

    return result(format, '.000');
}

export function oneDigitDec(number) {
    const format = number ? numeral(number).format('฿ 0,0.0') : '';

    return result(format, '.000');
}

function result(format, key = '.000') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
}

export function displayValue(value) {
    const displayValue = isNaN(value) ? '' : value;
    return displayValue
}