import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TermConditionsPage = () => {
    const { t } = useTranslation()
    return (
        <Box py={2}>
            <Container maxWidth="md">
                <Typography variant='h3' align='center' mb={3}>{t('terms_condition.subject')}</Typography>
                <Stack direction={"column"} gap={2}>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('terms_condition.intro')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item1_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item1_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item2_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item2_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item3_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item3_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item4_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item4_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item5_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item5_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item6_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item6_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item7_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item7_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item8_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item8_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item9_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item9_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item10_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item10_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('terms_condition.item11_header')}</Typography>
                        <Typography variant='body1'>{t('terms_condition.item11_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('terms_condition.conclusion_1')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('terms_condition.conclusion_2')}</Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default TermConditionsPage;