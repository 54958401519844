import * as yup from "yup";
import { TextField, Box, Typography, Container, Grid, Paper, Button, InputAdornment, IconButton, Alert } from "@mui/material"
import { useFormik } from "formik";
// import Province from '../../data/Province'
import SignUpImg from '../../assets/register.webp'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeRegister } from '../../actions/register'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { SignUpAPI } from "../../utils/useApi";
import CloseIcon from '@mui/icons-material/Close';
import { storeToken } from "../../actions/resetpass";

const initialValues = {
    username: '',
    email: '',
    phone: '',
    password: '',
    repassword: ''
}
const signupSchema = yup.object().shape({
    username: yup.string().matches(RegExp("^([a-zA-Z0-9]).{6,29}$"), "Username should have 7-29 characters.").required(`Please input your username.`),
    password: yup.string().matches(RegExp("(.{7,}.)"), "Password must more than 7 characters.")
        .matches(RegExp("^(?=.*[a-z])(?=.*[A-Z])"), "Password must have uppercase and lowercase letters.")
        .matches(RegExp("(.*\\d.*)"), "Password must have numberic characters.")
        .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must have special characters.")
        .required(`Please input your password.`),
    repassword: yup.string().oneOf([yup.ref('password'), null], 'Password do not match.').required(`Please input your repassword.`),
    email: yup.string().email("Your email must be a valid email").required(`Please input your email.`),
    phone: yup.string().matches(/^0/, 'Phone number must start with 0').matches(/^[0-9]+$/, 'Phone number must contain only numbers').matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit phone number').required(`Please input your phone number.`)

});

const Signup = () => {
    const { register } = useSelector(state => state)
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setShowconfirmPassword] = useState(false);
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onregisterSubmit = async (data, { setSubmitting }) => {
        const obj = {
            username: data.username,
            email: data.email,
            phone: data.phone,
            password: data.password
        }
        const obj_data = {
            "email": data.email,
            "password": data.password,
            "user_name": data.username,
            "phone": data.phone,
        }
        if (register.user_id_from_assessment) {
            obj_data["user_id"] = register.user_id_from_assessment
        }
        const SignUprequest = await SignUpAPI(obj_data)
        dispatch(storeRegister(obj))
        if (SignUprequest.status === true) {
            dispatch(storeToken(SignUprequest.data.token))
            navigate("/signup/otp-verification")
        } else {
            setAlertMessage(SignUprequest.data.response.data.message)
            setShowAlert(true)
        }

        // Send data to the server and send email
    }

    const { values, errors, touched, handleBlur, handleChange, isSubmitting, handleSubmit } = useFormik({
        initialValues,
        onSubmit: onregisterSubmit,
        validationSchema: signupSchema
    })


    return (
        <Box
            sx={{
                backgroundImage: `url(${SignUpImg})`,
                display: "flex",
                /* Center and scale the image nicely */
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '100vh',
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Container component="form" maxWidth="xs" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Paper variant="outlined" sx={{ p: 3 }} elevation={0}>
                    <Typography variant="h4" component="h4" sx={{ textAlign: "center", mb: 2 }}>
                        Register
                    </Typography>
                    <div style={{
                        height: '4px',
                        width: '55px',
                        display: 'block',
                        margin: '10px auto 20px',
                        backgroundColor: '#ff3366'
                    }}></div>

                    {showAlert && (<Alert severity="error" action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    } sx={{ my: 2 }}>{alertMessage}</Alert>)}
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="username"
                                name="username"
                                label="Username"
                                fullWidth
                                variant="outlined"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                error={errors.username && touched.username}
                                helperText={errors.username && touched.username && `${errors.username}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                variant="outlined"
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={errors.email && touched.email}
                                helperText={errors.email && touched.email && `${errors.email}`}
                                placeholder={"yourmail@hostmail.com"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="phone"
                                name="phone"
                                label="Phone"
                                fullWidth
                                variant="outlined"
                                type="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                error={errors.phone && touched.phone}
                                helperText={errors.phone && touched.phone && `${errors.phone}`}
                                placeholder={"XXX-XXX-XXXX"}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="password"
                                name="password"
                                label="Password"
                                fullWidth
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={errors.password && touched.password}
                                helperText={errors.password && touched.password && `${errors.password}`}
                                autoComplete="on"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {!showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="repassword"
                                name="repassword"
                                label="Repeat Password"
                                fullWidth
                                variant="outlined"
                                type={showconfirmPassword ? "text" : "password"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.repassword}
                                disabled={!values.password}
                                error={errors.repassword && touched.repassword}
                                helperText={errors.repassword && touched.repassword && `${errors.repassword}`}
                                autoComplete="on"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowconfirmPassword(!showconfirmPassword)}
                                                disabled={!values.password}
                                            >
                                                {!showconfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 1, mt: 2 }}>
                        <Button variant="contained" fullWidth sx={{ mx: 1 }} type="submit" disabled={isSubmitting}>
                            Register
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        Already have an account?
                        <span style={{ paddingLeft: "5px" }}>
                            <Link to={"/login"} style={{ textAlign: "center", textDecoration: "none" }}>
                                Login
                            </Link>
                        </span>
                    </Box>
                </Paper>
            </Container>
            )
        </Box>
    )
}

export default Signup