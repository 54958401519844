import { Box, Button, Divider, Link, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { moneyFormat } from '../../utils/formatCurrency';
import { useDispatch } from 'react-redux';
import { fetchCart, setCartType } from '../../actions/cart';
import { setAlternativeVitamin } from '../../actions/assessment';
import { useTranslation } from 'react-i18next';

const OrderSummary = ({ total, type_shop }) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleToCheckout = () => {
        dispatch(setCartType(type_shop))
        navigate('/checkout')
    }
    const handleToHomepage = () => {
        dispatch(fetchCart([]))
        dispatch(setAlternativeVitamin([]))
        navigate('/', { replace: true })
    }
    return (
        <Paper elevation={0} sx={{ p: 2, backgroundColor: "#f3f2f2", borderRadius: "16px" }}>
            <Typography align="center" variant="h4">
                {t('checkout_page.summary_header')}
            </Typography>
            <Typography align="center" variant="body1">
                {t('checkout_page.summary_description')}
            </Typography>
            <Divider sx={{ my: 2, py: 0.05 }} />
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography align="center" variant="body1" sx={{ fontWeight: "bold" }}>
                    {t('checkout_page.sub_total')}
                </Typography>
                <Typography align="center" variant="body1" sx={{ fontWeight: "bold" }}>
                    ฿ {moneyFormat(total)}
                </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography align="center" variant="body1" sx={{ fontWeight: "bold" }}>
                    {t('checkout_page.nutrition_consult')}
                </Typography>
                <Typography align="center" variant="body1" sx={{ fontWeight: "bold" }}>
                    {i18n.language === 'en' ? "FREE" : "ฟรี"}
                </Typography>
            </Stack>
            <Divider sx={{ my: 2, py: 0.05 }} />
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography align="center" variant="body1">
                    {t('checkout_page.Total')}
                </Typography>
                <Typography align="center" variant="body1">
                    ฿ {moneyFormat(total)}
                </Typography>
            </Stack>
            <Box display="flex" flexDirection={"column"} sx={{ my: 2, mx: 5 }}>
                <Typography align="center" variant="body1">
                    {t('checkout_page.desc1')}
                </Typography>
            </Box>
            {/* <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                    {t('checkout_page.coupon_header')}
                </Typography>
                <Stack direction={"row"} flex="1" justifyContent={"space-between"} mt={1} spacing={1}>
                    <TextField size="small" sx={{ flex: 0.7 }} />
                    <Button disableElevation variant='contained' color="error" fullWidth sx={{ py: 1, flex: 0.3, textTransform: 'none' }}>{t('checkout_page.coupon_btn')}</Button>
                </Stack>
            </Box> */}
            <Button disableElevation variant='contained' color="error" fullWidth={true} onClick={handleToCheckout} size="large" sx={{ mt: 2, py: 1, height: '48px', textTransform: 'none', borderRadius: '8px' }}>{t('checkout_page.checkout_btn')}</Button>
            {type_shop === "A" && (<Box display="flex" flexDirection={"column"} sx={{ my: 2 }}>
                <Link onClick={handleToHomepage} underline="always" align="center" sx={{ cursor: 'pointer' }}>
                    {t('checkout_page.later_btn')}
                </Link>
                <Typography align="center" variant="body1">
                    {t('checkout_page.later_desc')}
                </Typography>
            </Box>)}
        </Paper>
    );
};

export default OrderSummary;