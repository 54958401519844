import { Backdrop, Box, Button, CircularProgress, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckoutCreditCard from '../../components/Payment/CheckoutCreditCard';
import StepperPayment from '../../components/Payment/StepperPayment';
import CheckoutInternetBanking from '../../components/Payment/CheckoutInternetBanking';

import { Icon } from '@iconify/react';
import CheckoutTruemoney from '../../components/Payment/CheckoutTruemoney';
import CheckoutAlipay from '../../components/Payment/CheckoutAlipay';
import CheckoutMobileBanking from '../../components/Payment/CheckoutMobileBanking';
import CheckoutPwpoint from '../../components/Payment/CheckoutPwpoint';
import { isMobile } from 'react-device-detect'
// import { io } from 'socket.io-client';
import { checkoutwithLotusBill, checkoutwithOmise } from '../../utils/useApi';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
// import socket from '../../utils/Socket';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckoutBillpayment from '../../components/Payment/CheckoutBillpayment';
import CheckoutPromptpay from '../../components/Payment/CheckoutPromptpay';
import CheckoutInstallment from '../../components/Payment/CheckoutInstallment';

let OmiseCard

const Payment = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()

    if (!location.state) {
        navigate('/')
    }

    return (
        <Box>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <StepperPayment step={1} />
                <Grid container>
                    <Grid item xs={12} p={2}>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant='h5'>{t('payment_page.header')}</Typography>
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <CheckoutCreditCard payment_title={"Card"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutInternetBanking payment_title={"Internet banking"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutMobileBanking payment_title={"Mobile banking"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutPwpoint payment_title={"Pay with Points (Citi)"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutTruemoney payment_title={"True wallet"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutAlipay payment_title={"Alipay"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutBillpayment payment_title={"Bill Payment"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                <CheckoutPromptpay payment_title={"Promptpay"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} />
                                {/* <CheckoutInstallment payment_title={"Installment"} amount={location.state?.amount} email={location.state?.email} name={location.state?.name} order_no={location.state?.order_no} /> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
}

export default Payment;