import { Box, Button, Container, Grid, Link, Typography, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchHomepage } from '../../utils/useApi';
import { useTranslation } from 'react-i18next';


const ContentSection = () => {
    const [data, setData] = useState({})
    const { i18n } = useTranslation()
    const current_lang = localStorage.getItem("i18nextLng")
    const fetchData = async (langauge) => {
        const result_fetch = await fetchHomepage({ "lang": langauge })
        if (result_fetch.status) {
            setData({ ...result_fetch.data[0] })
        }
    }
    useEffect(() => {
        fetchData(i18n.language)
    }, [i18n.language])
    return (
        <Box mb={2}>
            <Container maxWidth="xl">
                <Grid container spacing={4} mt={3} justifyContent="center">
                    <Grid item lg={6} md={12}>
                        <Typography gutterBottom variant="h3" color="#ABCECC" component="div" style={{ fontWeight: 700 }}>
                            {!data ? <Skeleton /> : data[`header_0${current_lang}`]}
                        </Typography>
                        {!data ? <Skeleton variant='rectangular' animation='wave' width="100%" height={450} /> : <img src={data[`img_0`] && `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/homepage_image/${data[`img_0`]}`} alt="vitaminbot" style={{ maxWidth: "100%", width: "100%", height: "450px", objectFit: "cover" }} />}
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Typography gutterBottom variant="h3" color="#ABCECC" component="div" style={{ fontWeight: 700 }}> {!data ? <Skeleton /> : data[`header_1${current_lang}`]}</Typography>
                        <Typography variant="body1" gutterBottom align='justify'>
                            {!data ? <Skeleton /> : data[`paragraph1${current_lang}`]}
                        </Typography>
                        <Link href="/blog" underline="none" >
                            <Button disableElevation disableRipple sx={{
                                borderRadius: 0, backgroundColor: "#daebea", color: "#111111", px: 5, py: 1, '&:hover': {
                                    backgroundColor: '#ffffff',
                                },
                            }}>
                                <Typography variant="p" sx={{ letterSpacing: "3px", fontWeight: 700 }}>
                                    READ ARTICLE
                                </Typography>
                            </Button>
                        </Link>
                        <Grid container spacing={2} mt={3} justifyContent="center">
                            <Grid item lg={6} md={12}>
                                {!data ? <Skeleton variant='rectangular' animation='wave' width="100%" height={350} /> : <img src={data[`img_1`] && `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/homepage_image/${data[`img_1`]}`} alt="vitaminbot" style={{ objectFit: "cover", maxWidth: "100%", width: "100%", height: "80%" }} />}
                            </Grid>
                            <Grid item lg={6} md={12}>
                                <Typography gutterBottom variant="h6" color="#ABCECC" component="div" style={{ fontWeight: 700 }}> {!data ? <Skeleton /> : data[`header_2${current_lang}`]}</Typography>
                                <Typography variant="body1" gutterBottom align='justify'>
                                    {!data ? <Skeleton /> : data[`paragraph2${current_lang}`]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ContentSection;