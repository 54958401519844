import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, Select, InputAdornment, InputLabel, MenuItem, FormControl, Box, Grid, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 1, 2, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    // boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterType: PropTypes.string,
  onClear: PropTypes.func,
  onAllClear: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onstartDate: PropTypes.func,
  onendDate: PropTypes.func
};

export default function TableToolbar({ filterName, onFilterName, filterType, onClear, startDate, endDate, onstartDate, onendDate, onAllClear }) {
  const {t} = useTranslation()
  return (
    <StyledRoot>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item lg={2} md={4} sm={4} xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  name="startdate"
                  label={t('profile_page.order_history.sdate')}
                  value={startDate}
                  onChange={onstartDate}
                  slotProps={{
                    textField: { variant: 'outlined', size: 'small' },
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  disableFuture={true}
                  format="dd/MM/yyyy"
                  maxDate={endDate}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={6}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  name="enddate"
                  label={t('profile_page.order_history.edate')}
                  value={endDate}
                  disabled={!startDate}
                  onChange={onendDate}
                  slotProps={{
                    textField: { variant: 'outlined', size: 'small' },
                    actionBar: {
                      actions: ['clear'],
                    },
                  }}
                  disableFuture={true}
                  format="dd/MM/yyyy"
                  minDate={startDate}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item lg={7} md={4} sm={4} xs={12}>
            <StyledSearch
              name="order_no"
              value={filterName}
              size="small"
              onChange={onFilterName}
              placeholder={t('profile_page.order_history.search_box', {type: filterType})}
              fullwidth="true"
              endAdornment={
                filterName && (<InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onClear}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>)
              }
            />
          </Grid>
          <Grid item lg={1} md={12} sm={12} xs={12} sx={{display: "flex", alignItems: "center"}}>
            <Button variant='outlined' fullWidth sx={{textTransform: 'none', py: 0.85}} onClick={onAllClear}>Clear</Button>
          </Grid>
        </Grid>
      </Box>
      {/* {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      )} */}
    </StyledRoot>
  );
}
