import { AppBar, Box, Button, Container, Dialog, Grid, IconButton, List, Slide, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import StepperPayment from '../../components/Payment/StepperPayment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react';
import { getChargedatawithIdOmise } from '../../utils/useApi';
import BarCodeGenIcon from '../../assets/icon/barcode_generate.png';
import CloseIcon from '@mui/icons-material/Close';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { moneyFormat } from '../../utils/formatCurrency';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BillPayment = () => {
    let [searchParams] = useSearchParams();
    const { userName, accessToken } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const socket = io(process.env.REACT_APP_WEBHOOK_URL);
    const location = useLocation()
    const [payBarcode, setpayBarcode] = useState("")
    const [price, setPrice] = useState(0)
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCharge = async () => {
        const result = await getChargedatawithIdOmise({ "user_id": userName, "order_no": searchParams.get('id'), "type": "bill_payment_tesco_lotus" }, accessToken.token)
        if (result.status) {
            setpayBarcode(result.data.barcode)
            setPrice(result.data.amount)
        } else {
            navigate('/checkout', { replace: true })
        }
    }
    useEffect(() => {
        getCharge()
        socket.on('connect', () => {
            console.log(`${searchParams.get('id')} pay with Bill Payment`);
        });

        socket.on('paymentTrigger', (payload) => {
            const { order_id } = payload;
            if (order_id === searchParams.get('id')){
                window.location.replace(`/summary/${order_id}`)
            }
        });

        socket.on('disconnect', () => {
            console.log('disconnect');
        });
        // Clean up the event listener when component unmounts
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('promptpay_value');

            socket.disconnect()
        };
    }, [])
    return (
        <Box>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <StepperPayment step={1} />
                <Grid container>
                    <Grid item xs={12} p={2}>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <img src={BarCodeGenIcon} width={64} />
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant='h5'>Your Barcode is generated.</Typography>
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 1.5 }}>
                            <Typography variant='body1'>Use this barcode to pay at Lotus's</Typography>
                        </Box>
                        <Box display="flex" sx={{ justifyContent: "center", my: 3 }}>
                            <Button variant="contained" color='success' onClick={handleClickOpen}>
                                Show Barcode
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'fixed'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Lotus's Bill Payment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box display={"flex"} sx={{marginTop: 8}}>
                    <Grid container sx={{ px: 5, pt: 2 }} justifyContent="center" alignItems="center">
                        <Grid item xs={5} sx={{ pl: { 'md': 5, 'sm': 2 } }}>
                            {/* <img src={LotusIcon} style={{ maxWidth: '100%', width: "200px" }} /> */}
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" component="div">
                                Pay
                            </Typography>
                            <Typography variant="h6" component="div">
                                {moneyFormat(price)} ฿
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h6" component="div">
                                To
                            </Typography>
                            <Typography variant="h6" component="div">
                                Whealth Vitamin
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="h6" component="div">
                                At
                            </Typography>
                            <Typography variant="h6" component="div">
                                Lotus's
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
                <Box display="flex" sx={{ justifyContent: "center" }}>
                    <img src={payBarcode} width={"100%"} height={250} />
                </Box>
            </Dialog>
        </Box>
    );
};

export default BillPayment;