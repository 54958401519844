import { Box, Button, Collapse, Container, Divider, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchCart, removeAllFromCart } from '../../actions/cart';
import OrderSummary from '../../components/Cart/OrderSummary';
import ProductCardSummary from '../../components/ProductCard/ProductCardSummary';
import AddonsProduct from '../../components/Summary/AddonsProduct';
import { moneyFormat } from '../../utils/formatCurrency';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AlternativeProductCard from '../../components/ProductCard/AlternativeProductCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DoctorImage = styled.img`
    @media only screen and (max-width: 1199px) {
        min-height: 200px;
    }
    @media only screen and (max-width: 991px) {
        min-height: 200px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        min-height: 200px;
    }
    @media (max-width: 575px) {
        min-height: 200px;
    }
`

const SummaryAssessment = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { items, cart_type } = useSelector(state => state.cart)
    const { auth, assessment } = useSelector(state => state)
    const cartTotal = items.reduce((total, item) => total + (item.price * item.qty), 0)

    const [expanded, setExpanded] = useState(true)
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (cart_type != 'A') {
            navigate('/', { replace: true })
        }
    }, [])
    // useEffect(() => {
    //     if (!location.pathname.includes['/checkout', '/assessment/summary']) {
    //         return (() => {
    //             dispatch(fetchCart([]))
    //         })
    //     }
    // }, [location.pathname])

    return (
        <Box>
            {items.length !== 0 ? (
                <>
                    <Box sx={{ backgroundColor: "#9cd7db", justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                        <Container maxWidth="lg">
                            <Grid container columnSpacing={{ xs: 3, sm: 2, md: 2 }} rowSpacing={1} my={3} justifyContent="center" alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    sx={{
                                        '@media only screen and (max-width: 991px)': {
                                            display: "flex",
                                            justifyContent: "center"
                                        }
                                    }}
                                >
                                    <div style={{ position: "relative", display: 'block', justifyContent: "center" }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <DoctorImage src={require("../../assets/assessment/sachet_assessment.png")} style={{
                                                width: '250px',
                                                maxWidth: '100%'
                                            }} />
                                            <div style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                textAlign: "center"
                                            }}>
                                                <Typography
                                                    variant='body1'
                                                    color="black"
                                                    sx={{
                                                        fontWeight: 500,
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {auth?.userName ? auth.userName : (assessment.answers[0]?.q0001 || "-")}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={9}
                                >
                                    <Typography variant='h4' color="white" sx={{ fontWeight: 500 }}>
                                        {t('assessment_summary.header1')}
                                    </Typography>
                                    <Typography variant='h4' color="white" sx={{ fontWeight: 500 }}>
                                        {t('assessment_summary.header2', { total: moneyFormat(cartTotal / 30) })}
                                    </Typography>
                                    <Typography variant='body1' component="h5" color="white" sx={{ fontWeight: 500, paddingLeft: '1em' }}>
                                        <ul>
                                            <li style={{ listStyleType: 'disc' }}>{t('assessment_summary.desc1')}</li>
                                            <li style={{ listStyleType: 'disc' }}>{t('assessment_summary.desc2')}</li>
                                            <li style={{ listStyleType: 'disc' }}>{t('assessment_summary.desc3')}</li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                        <Container maxWidth="lg" sx={{ py: 3 }}>
                            <Grid container sx={{ mt: 2 }} columnSpacing={4}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', pb: 1.5 }}>
                                        {t("checkout_page.result_header")}
                                    </Typography>
                                    <Box>
                                        {items.map((i, index) => (
                                            <ProductCardSummary key={index.toString()} item={i} handleDeleteItem={() => dispatch(removeAllFromCart(i))} />
                                        ))}
                                    </Box>
                                    <Divider sx={{ my: 2, py: 0.05 }} />
                                    <Box sx={{ mt: 2 }}>
                                        <Paper elevation={0} sx={{ backgroundColor: "#f3f2f2", boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.3)', borderRadius: "16px" }}>
                                            <Box sx={{ p: 1.5, display: "flex", flexDirection: "column" }}>
                                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                                        {t("checkout_page.alt_header")}
                                                    </Typography>
                                                    <Link sx={{ fontWeight: 'bold', cursor: 'pointer', color: 'secondary' }} onClick={handleExpandClick}>
                                                        {expanded ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {t("checkout_page.alt_description", { num: assessment.alternative_vitlist.length })}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Divider sx={{ mx: 1.75 }} />
                                            <Collapse in={expanded}>
                                                <Box>
                                                    {assessment.alternative_vitlist.map((i, index) => (
                                                        <AlternativeProductCard key={index.toString()} item={i} handleDeleteItem={() => dispatch(removeAllFromCart(i))} />
                                                    ))}
                                                </Box>
                                            </Collapse>
                                        </Paper>
                                    </Box>
                                    <Divider sx={{ my: 2, py: 0.05 }} />
                                    <Box>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                                            {t("checkout_page.alt_header")}
                                        </Typography>
                                        <AddonsProduct />
                                    </Box>
                                    <Divider sx={{ my: 2, py: 0.05 }} />
                                    <Paper elevation={0} sx={{ p: 3, mb: 1.5, backgroundColor: "#f3f2f2", borderRadius: "16px" }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            {t('checkout_page.footer1')}
                                        </Typography>
                                        <Typography variant="body1" sx={{ py: 2 }}>
                                            {t('checkout_page.footer2')}
                                        </Typography>
                                        <Link href='/explore/products' underline="always" align="center">
                                            {t('checkout_page.footer3')}
                                        </Link>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <OrderSummary total={cartTotal} type_shop={"A"} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </>
            ) : (
                <Container maxWidth="lg">
                    <Grid container columnSpacing={{ xs: 3, sm: 2, md: 2 }} rowSpacing={1} my={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sx={{ minHeight: '65vh' }} display="flex" flexDirection='column' justifyContent="center" alignItems="center">
                            <DoctorImage src={require("../../assets/assessment/empty-blue-box.png")} style={{
                                width: '175px',
                                maxWidth: '100%'
                            }} />
                            <Typography align="center" variant="body1" sx={{ py: 2 }}>
                                There are no results of vitamin from assessment because you have deleted everything.
                            </Typography>
                            <Box display="flex" flexDirection="row" justifyContent="space-around">
                                <Link href="/" underline="none">
                                    <Button color="primary" variant='contained' sx={{ display: 'inline-block', textTransform: 'none', mx: 0.5 }}>Go to homepage</Button>
                                </Link>
                                <Link href="/assessment" underline="none">
                                    <Button color="primary" variant='contained' sx={{ display: 'inline-block', textTransform: 'none', mx: 0.5 }}>Take an assessment</Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </Box>
    )
}

export default SummaryAssessment