import OrderIcon from '../../assets/menuicon/order.svg'
import ProfileIcon from '../../assets/menuicon/profile-circle.svg'
import ProfileEditIcon from '../../assets/menuicon/edit-profile.svg'
import AddressIcon from '../../assets/menuicon/address.svg'
import DocumentIcon from '../../assets/menuicon/document.svg'
import ReviewIcon from '../../assets/menuicon/review.svg'

export const ProfileMenuList = [
    {
        id: 1,
        title: "profile_drawer.profile",
        icon: ProfileIcon,
        path: '/profile'
    },
    {
        id: 2,
        title: "profile_drawer.edit_profile",
        icon: ProfileEditIcon,
        path: '/profile/edit',
    },
    {
        id: 3,
        title: "profile_drawer.my_report",
        icon: DocumentIcon,
        path: '/profile/report',
    },
    {
        id: 4,
        title: "profile_drawer.order_his",
        icon: OrderIcon,
        path: '/profile/order'
    },
    {
        id: 5,
        title: "profile_drawer.address",
        icon: AddressIcon,
        path: '/profile/address'
    },
    {
        id: 6,
        title: "profile_drawer.review",
        icon: ReviewIcon,
        path: '/profile/review'
    },

]