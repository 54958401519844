export const login = (refresh, access) => {
  return {
    type: "LOG_IN",
    refresh_tok: refresh,
    access_tok: access
  };
};

export const updateUsername = (user) => {
  return {
    type: "UPDATE_USERNAME",
    payload: user
  }
}

export const clearProfile = () => {
  return {
    type: "CLEAR_PROFILE"
  }
}

export const resetSlide = () => {
  return {
    type: "RESET_SLIDE"
  }
}

export const resetForm = () => {
  return {
    type: "RESET_FORM"
  }
}

export const logout = () => {
  return {
    type: "LOG_OUT",
  };
};

export const updateProfile = (user) => {
  return {
    type: "UPDATE_PROFILE",
    payload: user
  }
}

export const resetToken = () => {
  return {
    type: "RESET_TOKEN",
  }
}

export const setRefreshToken = (token, date) => {
  return {
    type: "SET_REFRESH_TOKEN",
    token: token,
    date: date
  }
}

export const setAccessToken = (token, date) => {
  return {
    type: "SET_ACCESS_TOKEN",
    token: token,
    date: date
  }
}