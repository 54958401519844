import { Badge, Box, Collapse, Divider, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import ExploreIcon from '@mui/icons-material/Explore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import LanguagePopover from './LanguagePopover';
import { useTranslation } from 'react-i18next';

const option = [
    {
        id: 'd408d9e7-224a-54af-a81e-7960a445e9ec',
        name: 'navbar.home',
        icon: <HomeIcon />,
        link: '/'
    },
    {
        id: 'bb36f2dd-cf7e-5aef-960a-612a0ae7f179',
        name: 'navbar.shopping',
        icon: <ShoppingBagIcon />,
        link: '/explore/products'
    },
    {
        id: 'f2638b4c-12ad-5bf7-9e8e-c20f0e15887e',
        name: 'navbar.about',
        icon: <InfoIcon />,
        link: '/',
        subMenu: [
            {
                id: 'cba57797-9c92-53c1-b993-5f8c3b5a0882',
                name: 'sub_navbar.ourteams',
                icon: <GroupsIcon />,
                link: '/about/ourteam'
            },
            {
                id: '30c1946b-bb63-5b50-8f61-46bf37b5e04d',
                name: 'sub_navbar.contact',
                icon: <GroupsIcon />,
                link: '/about/contact'
            }
        ]
    },
    {
        id: '355dd757-af91-5ea7-aaff-ca5fa8923086',
        name: 'navbar.news',
        icon: <ArticleIcon />,
        link: '/blog'
    },
    {
        id: '34734de8-0e5e-5f87-a2f6-48504f5e0f57',
        name: 'navbar.explore',
        icon: <ExploreIcon />,
        link: '/',
        subMenu: [
            {
                id: 'aa8b3d7b-fe68-5180-ac08-039f3d4df342',
                name: 'sub_navbar.customer_review',
                icon: <ReviewsIcon />,
                link: '/explore/review'
            },
            {
                id: '7dbdf80a-b52e-5d1a-9d99-c5640f482fb1',
                name: 'sub_navbar.product',
                icon: <ShoppingBasketIcon />,
                link: '/explore/products'
            },
            {
                id: '74e00a31-aeab-5b65-a956-795240fcf785',
                name: 'sub_navbar.assessment',
                icon: <StickyNote2Icon />,
                link: '/assessment'
            }
        ]
    }
]

const DrawerMyNavbar = ({ open, handleDrawerClose, handleDrawerOpen, cartLength }) => {
    const [anchorElId, setanchorElId] = useState("")
    const handleSubMenuClick = (id) => {
        setanchorElId(prev => prev === id || id)
    }
    const { t } = useTranslation()
    return (
        <SwipeableDrawer
            sx={{
                width: 260,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 260,
                    boxSizing: 'border-box',
                },
            }}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            onOpen={handleDrawerOpen}
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <List>
                    {option.map((text, index) => {
                        if (text.subMenu) {
                            return (
                                <div key={text.id}>
                                    <ListItem disablePadding onClick={() => handleSubMenuClick(text.id)}>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {text.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={t(text.name)} />
                                            {anchorElId === text.id ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={anchorElId === text.id} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {text.subMenu.map((i, index) => (
                                                <ListItemButton key={i.id} sx={{ pl: 4 }} href={i.link}>
                                                    <ListItemIcon>
                                                        {i.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={t(i.name)} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                </div>
                            )
                        } else {
                            return (
                                <ListItem key={text.id} disablePadding>
                                    <ListItemButton component="a" href={text.link}>
                                        <ListItemIcon>
                                            {text.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(text.name)} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    })}
                </List>
                <Box p={2} />
                <Box display="flex" flexDirection="row" justifyContent="space-around">
                    <LanguagePopover/>
                    <Link
                        variant="button"
                        underline="none"
                        color="#000000"
                        href="/cart"
                        sx={{ my: 1, px: 2 }}
                    >
                        <Badge color="error" badgeContent={cartLength}>
                            <ShoppingCartIcon />
                        </Badge>
                    </Link>
                </Box>
            </Box>
        </SwipeableDrawer >
    );
};

export default DrawerMyNavbar;