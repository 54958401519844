import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material'
import "../ForgotPassword/ForgotPassword.css"
import Forgot from '../../components/ForgotPassword/Forgot';
import Reset from '../../components/ForgotPassword/Reset';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [resetStatus, setResetStatus] = useState(false)

    const urlParam = new URLSearchParams(window.location.search)
    const signatureId = urlParam.get('id') || null
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 800);
        if (urlParam.has('id')) {
            setResetStatus(true)
        } else {
            setResetStatus(false)
        }
    }, [signatureId])

    if (loading) {
        return (
            <Box
                sx={{
                    // backgroundImage: `url(${LoginImg})`,
                    display: "flex",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '100vh',
                }}
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress color="secondary"/>
            </Box>
        )
    }
    return (
        <Box
            sx={{
                // backgroundImage: `url(${LoginImg})`,
                display: "flex",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
                height: '100vh',
            }}
            justifyContent="center"
            alignItems="center"
        >
            {resetStatus ? (<Reset signatureId={signatureId} />) : (<Forgot />)}
        </Box >
    );
};

export default ForgotPassword;