let initialState = {
    answers: [],
    alternative_vitlist: []
}

export default function assessmentReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_ANSWERS":
            let temp = state.answers
            temp[action.payload.index] = action.payload.answers
            return { ...state, answers: temp }
        case "SET_ALTERVITAMIN":
            return {
                ...state,
                alternative_vitlist: action.payload
            }
        case "RESET_SLIDE":
            return initialState
        case "CLEAR_ANSWER":
            return {
                ...state,
                answers: []
            }
        default:
            return state;
    }
};