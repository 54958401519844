import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import MyNavbar from '../components/Navbar/MyNavbar';
import { Box, DialogContent, DialogTitle, Fab, Fade, Hidden, IconButton, Paper, Popper, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CookieConsent from 'react-cookie-consent';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { fetchProductGroup } from '../utils/useApi';
import { useEffect } from 'react';

const LayoutMain = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [group, setgroup] = useState([])
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const fetchGroup = async () => {
        const fetch_group = await fetchProductGroup()
        if (fetch_group.status) {
            setgroup(fetch_group.data)
        } else {
            setgroup([])
        }
    }
    useEffect(() => {
        fetchGroup()
    }, [])
    return (
        <div>
            <MyNavbar />
            <Outlet />
            <Footer pgroupList={group} />

            <Popper open={open} onClose={handleClose} anchorEl={anchorEl} placement={'top-end'} transition sx={{ zIndex: 101 }} style={{ position: 'fixed', bottom: 75, right: 15, top: 'unset', left: 'unset' }} disablePortal={true}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                        <Paper sx={{ mb: 1, width: 300 }}>
                            <DialogTitle>
                                {t('help_dialog.help_header')}
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 15,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Box display={"flex"} sx={{ alignItems: "center" }} flexDirection={"column"}>
                                    <Typography variant='body1'>
                                        {t('help_dialog.help_description')}
                                    </Typography>
                                    <img src={require('../assets/lineoa/B2C_LINEOA.png')} alt="LineOAIcon" style={{ width: 250, height: 250 }} />
                                </Box>
                            </DialogContent>
                        </Paper>
                    </Fade>
                )}
            </Popper>

            <div className="button-help" style={{ zIndex: 101 }}>
                <Fab style={{ backgroundColor: "#f44336" }} variant="extended" onClick={handleOpen}>
                    <HelpOutlineIcon style={{ color: "white" }} />
                    <Hidden mdDown><Typography component={"span"} style={{ color: "white" }} sx={{ ml: 1 }}>{t('help_dialog.fab_button_help')}</Typography></Hidden>
                </Fab>
            </div>
            <CookieConsent
                location="bottom"
                cookieName="ConsentMarketingCookie"
                flipButtons={true}
                style={{
                    color: "#000000",
                    background: "#FFFFFF",
                    zIndex: 99,
                    margin: '0 auto',
                    padding: '5px 30px',
                    borderTop: '1px solid #cbd6e2',
                    display: 'block',
                    textAlign: 'center',
                    boxShadow: '-1px 3px #eaf0f6'
                }}
                buttonStyle={{
                    color: "#ffffff",
                    backgroundColor: "#00bfdd",
                    fontSize: "16px",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    border: "1px solid #00bfdd",
                    transition: 'background 0.3s ease-in-out',
                }}
                expires={150}
                contentStyle={{ flex: '1 0 20px', display: 'block', textAlign: 'left' }}
                enableDeclineButton={true}
                buttonText={t("homepage.cookie_banner.agree_btn")}
                declineButtonText={t("homepage.cookie_banner.decline_btn")}
                declineButtonStyle={{
                    color: "#00bfdd",
                    backgroundColor: "#ffffff",
                    fontWeight: 400,
                    border: "1px solid #00bfdd",
                    fontSize: "16px",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    transition: 'background 0.3s ease-in-out',
                }}
            >
                {/* We use cookies to ensure that we give you the best experience on our website. */}
                <div style={{ maxWidth: "1000px", margin: '0 auto' }}>
                    {t("homepage.cookie_banner.description")}
                </div>
            </CookieConsent>
        </div>
    );
};

export default LayoutMain;