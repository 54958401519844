import React from 'react';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, LineIcon, LineShareButton } from 'react-share';

const ReactSocial = ({ url }) => {
    return (
        <div style={{marginTop: 5, display: "flex", gap: "8px"}}>
            <FacebookShareButton
                url={url}
                quote={'Share this story for your friend'}
                hashtag="#whealthvitamin"
            >
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
                url={url}
                quote={'Share this story for your friend'}
                hashtag="#whealthvitamin"
            >
                <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LineShareButton
                url={url}
                quote={'Share this story for your friend'}
                hashtag="#whealthvitamin"
            >
                <LineIcon size={32} round />
            </LineShareButton>
        </div>
    );
};

export default ReactSocial;