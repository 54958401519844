import { useCallback, useState } from 'react';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Fade } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfile, logout } from '../../actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchCart, resetOrderId } from '../../actions/cart';
import { SignOut, updateOrderShopping } from '../../utils/useApi';
import { clearAnswers } from '../../actions/assessment';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'navbar_menu.my_profile',
    icon: 'eva:person-fill',
    href: '/profile'
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ username, image }) {
  const { t } = useTranslation()
  const { items, order_id } = useSelector(state => state.cart)
  const { userName, refreshToken } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(null);
  const cartTotal = items.reduce((total, item) => total + (item.price * item.qty), 0)
  let item_temp = []
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  // items, order_id
  const handleClose = () => {
    setOpen(null);
  };

  const linkHandle = (option) => {
    navigate(option)
    setOpen(null);
  }

  const updateCartLogout = async () => {
    items.map((i) => {
      item_temp.push({
        "product_id": i.product_id,
        "qty": i.qty,
        "price": Number(i.price) * i.qty
      })
    })
    const update_subobj = {
      "price": cartTotal,
      "user_name": userName,
      "order": item_temp,
    }
    const update_obj = {
      "order_no": order_id,
      "data": update_subobj
    }
    
    await updateOrderShopping(JSON.stringify(update_obj))

  }

  const logoutHandle = async () => {
    await SignOut({ "userId": userName }, refreshToken.token)

    if (order_id !== "") {
      updateCartLogout()
    }
    dispatch(clearProfile())
    dispatch(fetchCart([]))
    dispatch(resetOrderId())
    dispatch(clearAnswers())
    setOpen(null)

    navigate('/login', { replace: true })
    dispatch(logout())

  }

  return (
    <>
      <IconButton
        disableRipple={true}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute'
            },
          }),
        }}
      >
        <Avatar sx={{ bgcolor: "#536766", mx: 1 }} src={image ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/profile/${image}` : null}> {username[0].toUpperCase()}</Avatar>
        {open ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
      </IconButton>

      <Popover
        elevation={1}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {process.env.REACT_APP_OMISE_PUBLIC_KEY}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => linkHandle(option.href)}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <MenuItem onClick={logoutHandle} sx={{ mx: 1, mb: 1 }} LinkComponent="/logout">
          {t("navbar_menu.logout")}
        </MenuItem>
      </Popover>
    </>
  );
}
