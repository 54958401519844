import { Box, Container, Typography, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivatePolicyPage = () => {
    const { t } = useTranslation()
    return (
        <Box py={2}>
            <Container maxWidth="md">
                <Typography variant='h3' align='center' mb={3}>{t('privacy_policy.subject')}</Typography>
                <Stack direction={"column"} gap={2}>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('privacy_policy.intro')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item1_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item1.1_desc')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item1.2_desc')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item1.3_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item2_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item2.1_desc')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item2.2_desc')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item2.3_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item3_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item3.1_desc')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item3.2_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item4_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item4_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item5_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item5_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item6_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item6_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item7_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item7_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='h5'>{t('privacy_policy.item8_header')}</Typography>
                        <Typography variant='body1'>{t('privacy_policy.item8_desc')}</Typography>
                    </Stack>
                    <Stack direction={"column"} gap={1}>
                        <Typography variant='body1'>{t('privacy_policy.conclusion')}</Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box >
    );
};

export default PrivatePolicyPage;