import React from 'react';
import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import Label from '../Label/Label';
import styled from 'styled-components';

const StyleTab = styled(Tab)`
    display: flex;
    align-items: center;
    &.MuiTab-root{
        min-height: 48px;
    }
    
`

TableTopToolbar.propTypes = {
    value: PropTypes.string,
    onHandleChange: PropTypes.func,
    MenuList: PropTypes.array,
    dataList: PropTypes.array
};

const countType = (status, data) => {
    if (data) {
        if (status === 'all') {
            return data.length
        } else {
            const filter_data = data.filter((i) => i.status === status).length
            return filter_data
        }

    }
}

export default function TableTopToolbar({ value, onHandleChange, MenuList, dataList }) {
    return (
        <Tabs aria-label="basic tabs example" value={value} onChange={onHandleChange} variant="scrollable" scrollButtons="auto" sx={{ background: "#f5f5f5" }}>
            {MenuList.map((i, index) => (
                <StyleTab
                    disableRipple={true}
                    iconPosition={"start"}
                    icon={
                        <Label color={(i.label === 'Waiting' && 'primary') || (i.label === 'Success' && 'success') || 'error'}>
                            {countType(i.value, dataList)}
                        </Label>
                    }
                    key={index.toString()}
                    label={i.label}
                    name="status"
                    value={i.value}
                    sx={{ textTransform: 'none', color: "#21215a" }}
                />
            ))}
        </Tabs>
    );
};
