import { Box, Button, Container, Divider, Grid, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import StepperPayment from '../../components/Payment/StepperPayment';
import SuccessIcon from '../../assets/icon/check.png'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchOrderById, getInternetBankingCharge, sendOrderToCus } from '../../utils/useApi';
import { fDate } from '../../utils/formatTime'
import { moneyFormat } from '../../utils/formatCurrency';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, resetOrderId } from '../../actions/cart';
import html2canvas from 'html2canvas';
import styled from 'styled-components';
import { thphoneFormat } from '../../utils/formatTelephone';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
const StyledTableHeader = styled.th`
    background-color: #e8f6f6;
    font-size: 18px;
    line-height: 1.4;
    padding: 8px;
`
const StyledTableCell = styled.td`
    line-height: 1.4;
    padding: 8px;
`
const ReviewOrder = () => {
    const { order_id } = useParams()
    const { t } = useTranslation()
    const { user } = useSelector(state => state.profile)
    const { userName } = useSelector(state => state.auth)
    const pngRef = useRef(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [data, setData] = useState({ "order": {}, "order_details": [] })
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [statusOrder, setstatusOrder] = useState("")
    const [messageInfo, setMessageInfo] = useState("Please Wait, Do not leave this page.");

    const handleClose = () => {
        setOpen(false);
    }
    /* Creating a state variable called data and setting it to the value of the object. */


    const fetchOrder = async (id) => {
        const result_fetch = await fetchOrderById(JSON.stringify({ "order_no": id, "user_name": userName }))
        if (result_fetch.status) {
            if (result_fetch.data.order != null) {
                setstatusOrder(result_fetch.data.order.status)
                if (result_fetch.data.order.status === "waiting") {
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        icon: 'error',
                        title: t("payment_alert.fail_title"),
                        text: t(`payment_status.${result_fetch.data.payment_status['failure_code']}`) ,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.replace("/checkout", { replace: true })
                        }
                    })
                } else {
                    dispatch(fetchCart([]))
                    dispatch(resetOrderId())
                }
                setData(result_fetch.data)
            } else {
                Swal.fire({
                    customClass: {
                        container: 'my-swal',
                    },
                    icon: 'error',
                    title: "ไม่พบออเดอร์นี้ในระบบ",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.replace("/")
                    }
                })
            }

        }
    }

    const handleConvertToPng = () => {
        html2canvas(pngRef.current).then((canvas) => {
            const base64String = canvas.toDataURL('image/jpeg').split(',')[1]
            sendEmail(base64String)
        }).catch((error) => {
            console.error('Error converting HTML to PNG', error);
        })
    }

    const sendEmail = async (base64_data) => {
        setOpen(true)
        const obj = {
            "to": user.email,
            "order_no": order_id,
            "base64": `data:image/jpeg;base64,${base64_data}`
        }
        const result = await sendOrderToCus(JSON.stringify(obj))
        if (result.status) {
            setMessageInfo("Receipt send, Please check your inbox.")
            setInterval(() => {
                handleClose()
            }, 5000)
        }
    }

    useEffect(() => {
        fetchOrder(order_id)
        if (statusOrder != "waiting") {
            const timer = setTimeout(() => {
                handleConvertToPng()
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [order_id])

    const handleToHomepage = () => {
        window.location.replace(`/`)
    }

    return (
        <Box>
            <Container maxWidth="lg" sx={{ py: 3 }} >
                <StepperPayment step={2} />
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 400 }}>
                    Review Order
                </Typography>
                {statusOrder != "waiting" && (<Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Container maxWidth="sm">
                            <Box display="flex" sx={{ justifyContent: "center" }}>
                                <img src={SuccessIcon} alt="success_icon" width="80px" height="80px" />
                            </Box>
                            <Box display="flex" sx={{ justifyContent: "center", my: 1 }}>
                                <Typography variant='body1' component='h4'>{t('payment_page.success_message')}</Typography>
                            </Box>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Container ref={pngRef} maxWidth="sm" sx={{ py: 3, backgroundColor: "#e8f6f6" }} id="slip-area">
                            <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1}>
                                <Box display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                                    <Box display="flex" sx={{ justifyContent: "center", alignItems: "center" }}>
                                        <img alt="whealth_icon" src={require("../../assets/W.png")} style={{ width: "150px" }} />
                                    </Box>
                                    <Box display="flex" sx={{ flexDirection: "column", justifyContent: "center", alignItems: "end" }}>
                                        <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>ใบเสร็จรับเงิน</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1}>
                                <Box sx={{ p: 2, backgroundColor: "#ffffff" }}>
                                    <Box display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                                        <Box display="flex" sx={{ flex: 0.65, flexDirection: "column", alignItems: "start", py: 1 }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>Order ID : {data.order?.order_no}</Typography>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>ชื่อ</span> : {data.order?.name}</Typography>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>ที่อยู่</span> : {data.order?.info_address}</Typography>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>เบอร์โทร</span> : {thphoneFormat(data.order?.phone)}</Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 0.35, flexDirection: "column", alignItems: "start", py: 1, mx: 1 }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>เลขที่ : <span style={{ fontWeight: 700 }}>{data.order?.order_no}</span></Typography>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>วันที่ : <span style={{ fontWeight: 700 }}>{fDate(data.order?.create_date, "d/M/yyyy")}</span></Typography>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>ผู้ขาย : <span style={{ fontWeight: 700 }}>Whealth vitamin</span></Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <table width="100%">
                                        <thead>
                                            <tr>
                                                <StyledTableHeader>#</StyledTableHeader>
                                                <StyledTableHeader style={{ width: 240 }}>รายการสินค้า</StyledTableHeader>
                                                <StyledTableHeader style={{ textAlign: "end" }}>จำนวน</StyledTableHeader>
                                                <StyledTableHeader style={{ textAlign: "end" }}>ยอดรวม</StyledTableHeader>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.order_details.map((i, index) =>
                                            (<tr key={index}>
                                                <StyledTableCell>{index + 1}</StyledTableCell>
                                                <StyledTableCell style={{ width: 240 }}>{i.name_en}</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: "end" }}>{i.qty}</StyledTableCell>
                                                <StyledTableCell style={{ textAlign: "end" }}>{moneyFormat(i.price)}</StyledTableCell>
                                            </tr>)
                                            )}
                                        </tbody>
                                    </table>
                                    <hr style={{
                                        backgroundImage: "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2210%22 height=%2210%22 viewBox=%220 0 10 10%22%3E%3Cpolygon fill=%22black%22 points=%220 3 3.5 3 5 0 6.5 3 10 3 7 5.5 8 9 5 7 2 9 3.5 5.5 0%22/%3E%3C/svg%3E%0A')",
                                        height: '10px',
                                        backgroundColor: "transparent",
                                        width: '100%',
                                        backgroundRepeat: 'repeat-x',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }} />
                                    <Box display="flex" flexDirection={"row"} flex={1} width="100%" my={2}>
                                        <Box style={{ flex: 0.6, textAlign: "end" }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>รวมเป็นเงิน</Typography>
                                        </Box>
                                        <Box style={{ flex: 0.4, textAlign: "end" }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{moneyFormat(data.order?.amount)}</Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ my: 1 }} />
                                    <Box display="flex" flexDirection={"row"} flex={1} width="100%" mt={2}>
                                        <Box style={{ flex: 0.6, textAlign: "end" }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>ยอดชำระ</Typography>
                                        </Box>
                                        <Box style={{ flex: 0.4, textAlign: "end" }}>
                                            <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{moneyFormat(data.order?.amount)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Container>

                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" sx={{ justifyContent: "center" }}>
                            <Button variant='contained' onClick={handleToHomepage}>Continue</Button>
                        </Box>
                    </Grid>
                </Grid>)}
                <Snackbar
                    anchorOrigin={{ "vertical": "bottom", "horizontal": 'left' }}
                    open={open}
                    onClose={handleClose}
                    message={messageInfo}
                // action={
                //     <React.Fragment>
                //         <IconButton
                //             aria-label="close"
                //             color="inherit"
                //             sx={{ p: 0.5 }}
                //             onClick={handleClose}
                //         >
                //             <CloseIcon />
                //         </IconButton>
                //     </React.Fragment>
                // }
                />
            </Container>
        </Box>
    );
};

export default ReviewOrder;