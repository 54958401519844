import { Box, Button, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Backdrop, CircularProgress, List } from '@mui/material';
import { Axios } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { callPaywithOmiseAPI, checkoutwithOmise } from '../../utils/useApi';
import Swal from 'sweetalert2'
import styled from 'styled-components';
import OmiseIcon from '../../assets/icon/creditcard.png'
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


let OmiseCard

const OmiseImage = styled.img`
    @media only screen and (max-width: 1199px) {
        min-width: 100px;
    }
    @media only screen and (max-width: 991px) {
        min-width: 100px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        min-width: 100px;
    }
    @media (max-width: 575px) {
        min-width: 100px;
    }
`

const PaymentBox = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
`

const OmiseBox = styled(Box)`
    background-color: #f7f9fa;
    outline: none;
    padding: 20px 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
`

const CheckoutMobileBanking = ({ email, name, amount, order_no, payment_title }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const handleLoadScript = () => {
        OmiseCard = window.OmiseCard
        OmiseCard.configure({
            publicKey: process.env.REACT_APP_OMISE_PUBLIC_KEY,
            currency: 'THB',
            frameLabel: 'Whealth Vitamin',
            submitLabel: 'Checkout',
            buttonLabel: 'Pay with Omise'
        });
    }

    const mobilebankingChargeConfigure = () => {
        OmiseCard.configure({
            defaultPaymentMethod: 'mobile_banking',
            otherPaymentMethods: []
        });
        OmiseCard.configureButton("#mobilebanking");
        OmiseCard.attach();
    }

    const omiseCardHandler = () => {
        OmiseCard.open({
            frameLabel: 'Whealth Vitamin',
            frameDescription: `Order #${order_no}`,
            amount: amount*100,
            onCreateTokenSuccess: async (token) => {
                handleOpen()
                const result_pay = await checkoutwithOmise(
                    {
                        "user_name": name,
                        "order_no": order_no,
                        "amount": amount*100,
                        "token": token
                    }
                )
                if (result_pay.status) {
                    try {
                        window.location.href = result_pay.data.authorize_uri
                    } catch (e) {
                        console.log("d",e)
                    }
                    
                } else {
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        icon: 'error',
                        title: t("payment_alert.fail_title"),
                        text: result_pay.data.response.data.message,
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(-1)
                        }
                    })
                }
            },
            onFormClosed: () => {
                navigate(-1, { replace: true })
            },
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        mobilebankingChargeConfigure();
        omiseCardHandler();
    }

    useEffect(() => {
        handleLoadScript()
    }, [])
    return (
        <div>
            <form>
                <List>
                    <ListItem disablePadding disableElevation disableRipple>
                        <ListItemButton id="mobilebanking" onClick={handleClick} disabled={!true}>
                            <ListItemIcon>
                                <Icon width={30} height={30} icon="bi:phone-fill" style={{color: "#3f50b5"}} />
                            </ListItemIcon>
                            <ListItemText primary={payment_title} />
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                    onMouseDown={handleBackdropClick} // Stop event propagation on mouse down
                    onTouchStart={handleBackdropClick} // Stop event propagation on touch start
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </form>
        </div>
    );
};

export default CheckoutMobileBanking;