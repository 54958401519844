import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ChatIcon from '../../assets/icon/live-chat.svg'
import AOS from 'aos'
import 'aos/dist/aos.css';

AOS.init()

const HeaderBanner = styled.h1`
    color: white;
    font-size: 8.75rem;
    font-weight: 700;

`
const HeaderBanner2 = styled.h3`
    color: white;
    font-size: 3rem;
    font-weight: 700;
    @media only screen and (max-width: 991px) {
        margin-right: 0;
    }
    @media (max-width: 767px) {
        font-size: 2.25rem;
    }
    @media only screen and (max-width: 575px) {
        font-size: 1.875rem;
    }
`

const ChatSection = () => {
    return (
        <Box py={5} sx={{ backgroundColor: "#9cd7db", justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
            <Container maxWidth="xl">
                <Grid container columnSpacing={{ xs: 3, sm: 4, md: 5 }} px={5} py={8}>
                    <Grid item xs={12} sm={6} md={6}>
                        <HeaderBanner2>Why Choose Our Vitamin?</HeaderBanner2>
                        <HeaderBanner data-aos='fade-right' data-aos-delay='150' data-aos-duration="1000">24/7</HeaderBanner>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Stack direction={"column"} alignItems="flex-end">
                            <img src={ChatIcon} alt="Live chat" width="200px" height="200px" data-aos='flip-up' data-aos-delay='150' data-aos-duration="1000" data-aos-easing="linear"/>
                            <Typography variant="p" container="p" color="white" sx={{mt: 3}}>
                                Our experience shows that customers need extensive support that require us to be highly professional, to swiftly react to changes in international and domestic law and to possess knowledge of legal system of different countries.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ChatSection;