import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from './locales/en/trans.json'
import thTranslation from './locales/th/trans.json'

const fallbackLng = ['en'];
const availableLanguages = ['en', 'th'];

const resources = {
  en: {
    translation: enTranslation
  },
  th: {
    translation: thTranslation
  }
};

i18n.use(LanguageDetector).use(initReactI18next)
.init({
  lng: localStorage.getItem('i18nextLng') || 'en',
  fallbackLng,
  
  detection: {
    checkWhitelist: true, 
  },

  debug: false,

  whitelist: availableLanguages,

  interpolation: {
    escapeValue: false,
  },
  resources

})

// i18next
//   .use(ChainedBackend)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",
//     backend: {
//       backends: [
//         HttpBackend,
//         resourcesToBackend((lng, ns) => `http://192.168.1.227/${lng}/Address.json`)
//       ],
//       backendOptions: [{
//         loadPath: `http://192.168.1.227/{{lng}}/Address.json`
//       }]
//     }
//   });

export default i18n