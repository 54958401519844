import * as yup from "yup";
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Container, Divider, Grid, Link, Paper, Step, StepLabel, TextField, Typography, Stack, Stepper, CircularProgress, OutlinedInput, InputLabel, FormControl, FormHelperText, Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moneyFormat } from '../../utils/formatCurrency';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StepperPayment from "../../components/Payment/StepperPayment";
import { createOrderShopping, fetchUserProfile, fetchAddressData, fecthAddressDataById, updateOrderShopping } from "../../utils/useApi";
import { setOrderId } from "../../actions/cart";
import { Province } from '../../data/Province'
import { District } from '../../data/District'
import { SubDistrict } from '../../data/SubDistrict'
import { Zipcode } from '../../data/Zipcode'
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MyTextField = styled(OutlinedInput)`
    display: inline-flex;
    border-radius: 8px;
    line-height: 1.4375em;
    font-size: 0.95rem;
`

const checkoutSchema = yup.object().shape({
    fname: yup.string().required(`Please input your firstname.`),
    lname: yup.string().required(`Please input your lastname.`),
    address: yup.string().required(`Please input your address.`),
    sub_district: yup.string().required(`Please input your sub district.`),
    district: yup.string().required(`Please input your district.`),
    zip_code: yup.string().required(`Please input your zipcode.`),
    province: yup.string().required(`Please input your province.`),
    phone: yup.string().min(10).matches(/^0\d*$/, 'Number must start with 0 and contain only numbers').required(`Please input your phone number.`),

});

const Checkout = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const { items, order_id, cart_type } = useSelector(state => state.cart)
    const { auth, profile } = useSelector(state => state)
    const cartTotal = items.reduce((total, item) => total + (item.price * item.qty), 0)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    let item_temp = []
    const [provinceId, setProvinceId] = useState(-1)
    const [districtId, setDistrictId] = useState(-1)
    const [subdistrictId, setSubDistrictId] = useState(-1)
    const getProvinceId = Province.find((i) => i.PROVINCE_ID === provinceId) || []
    const getDistrictId = District.find((i) => i.DISTRICT_ID === districtId) || []
    const filterDistrictbyProvinceId = District.filter((i) => i.PROVINCE_ID === getProvinceId.PROVINCE_ID) || []
    const filterSubDistrictbyDistrictId = SubDistrict.filter((i) => i.DISTRICT_ID === getDistrictId.DISTRICT_ID) || []
    // const filterZipCode = Zipcode.find((i) => i.PROVINCE_ID === provinceId.toString() & i.DISTRICT_ID === districtId.toString() & i.SUB_DISTRICT_ID === subdistrictId.toString())

    const onCheckoutSubmit = async (data, { setSubmitting }) => {
        const item = items.map((i) => {
            item_temp.push({
                "product_id": i.product_id,
                "qty": i.qty,
                "price": Number(i.price) * i.qty
            })
        })
        const obj = {
            "price": total,
            "user_name": auth.userName,
            "order": item_temp,
            "address": data.address,
            "province": provinceId,
            "zip_code": data.zip_code,
            "subdistrict": subdistrictId,
            "district": districtId,
            "phone": data.phone,
            "name": data.fname + " " + data.lname,
            "type": cart_type
        }
        const update_subobj = {
            "price": total,
            "user_name": auth.userName,
            "order": item_temp,
            "address": data.address,
            "province": provinceId,
            "zip_code": data.zip_code,
            "subdistrict": subdistrictId,
            "district": districtId,
            "phone": data.phone,
            "name": data.fname + " " + data.lname,
            "type": cart_type
        }
        const update_obj = {
            "order_no": order_id,
            "data": update_subobj
        }
        if (order_id !== "") {
            const result_updateorder = await updateOrderShopping(JSON.stringify(update_obj))
            if (result_updateorder.status) {
                navigate('/payment', {
                    state: {
                        amount: (total).toString(),
                        name: auth.userName,
                        email: "dd@dd.com",
                        order_no: order_id
                    }
                })
            }
        } else {
            const result_createorder = await createOrderShopping(JSON.stringify(obj))
            if (result_createorder.status) {
                dispatch(setOrderId(result_createorder.data.order_no))
                navigate('/payment', {
                    state: {
                        amount: (total).toString(),
                        name: auth.userName,
                        email: "dd@dd.com",
                        order_no: result_createorder.data.order_no
                    }
                })
            }
        }
    }

    const { control, register, handleSubmit, setValue, setFocus, getValues, reset, formState: { errors } } = useForm({
        resolver: yupResolver(checkoutSchema),
        mode: "onChange"
    })

    const fetchProvinceId = (p_name) => {
        let g = Province.find((i) => i.PROVINCE_NAME === p_name) || []
        setProvinceId(g.PROVINCE_ID)
    }
    const fetchDistrictId = (d_name) => {
        let g = District.find((i) => i.DISTRICT_NAME === d_name) || []
        setDistrictId(g.DISTRICT_ID)
    }
    const fetchSubDistrictId = (sd_name) => {
        let g = SubDistrict.find((i) => i.SUB_DISTRICT_NAME === sd_name) || []
        setSubDistrictId(g.SUB_DISTRICT_ID)
    }

    const fetchDataAddress = async () => {
        const fetch_address = await fetchAddressData({ "user_name": auth.userName }, auth.accessToken.token)
        const fetch_main_address = await fecthAddressDataById({ "address_id": fetch_address.data.main_address }, auth.accessToken.token)
        if (fetch_main_address.status) {
            reset({
                'fname': fetch_main_address.data?.firstname,
                'lname': fetch_main_address.data?.lastname,
                'address': fetch_main_address.data?.address,
                'province': fetch_main_address.data.province,
                'district': fetch_main_address.data.district,
                'sub_district': fetch_main_address.data.subdistrict,
                'zip_code': fetch_main_address.data?.zip_code,
                'phone': fetch_main_address.data?.phone
            })
            fetchProvinceId(fetch_main_address.data?.province)
            fetchDistrictId(fetch_main_address.data?.district)
            fetchSubDistrictId(fetch_main_address.data?.subdistrict)
        }
    }
    const fetchDataisExist = async () => {
        const fetch_profile = await fetchUserProfile({ "userName": auth.userName })
        if (fetch_profile.status) {
            setValue('fname', fetch_profile.data.firstname)
            setValue('lname', fetch_profile.data.lastname)
        }
    }

    useEffect(() => {
        if (items.length < 1 & !cart_type) {
            window.location.replace('/')
        }
        fetchDataisExist()
        fetchDataAddress()
        // if (filterZipCode) {
        //     setValue('zip_code', filterZipCode.ZIPCODE)
        // }
        setTotal(cartTotal)
    }, [])

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="85vh"
            >
                <CircularProgress />
            </Box>
        )
    }
    return (
        <Box>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <StepperPayment step={0} />
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={8} component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onCheckoutSubmit)}>
                        <Paper sx={{ p: 2 }}>
                            <Typography component="h5" variant='h5'>Shipping Details</Typography>
                            <Divider sx={{ mt: 2, mb: 1 }} />
                            <Grid container spacing={3} sx={{ py: 2 }}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="fname">Firstname</InputLabel>
                                        <Controller
                                            name="fname"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <MyTextField
                                                    {...field}
                                                    label="Firstname"
                                                    type='text'
                                                />
                                            )}
                                        />
                                        <FormHelperText error={errors.fname ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.fname?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="lname">Lastname</InputLabel>
                                        <Controller
                                            name="lname"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <MyTextField
                                                    {...field}
                                                    label="Lastname"
                                                    type='text'
                                                />
                                            )}
                                        />
                                        <FormHelperText error={errors.lname ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.lname?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="address">Address</InputLabel>
                                        <Controller
                                            name="address"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <MyTextField
                                                    {...field}
                                                    label="Address"
                                                    type='text'
                                                    multiline
                                                    minRows={3}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={errors.address ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.address?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Controller
                                            name="province"
                                            control={control}
                                            defaultValue=""
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    id="province"
                                                    options={Province}
                                                    value={field.value || null}
                                                    inputValue={field.value || ""}
                                                    onChange={(e, newValue, reason) => {
                                                        if (reason === 'selectOption') {
                                                            setValue('district', "")
                                                            setValue('sub_district', "")
                                                            setValue('zip_code', "")
                                                            setDistrictId(-1)
                                                            setSubDistrictId(-1)
                                                            if (newValue?.PROVINCE_NAME) {
                                                                field.onChange(newValue.PROVINCE_NAME)
                                                                setProvinceId(newValue.PROVINCE_ID)
                                                            }
                                                        }


                                                    }}
                                                    onInputChange={(e, newValue, reason) => {
                                                        if (reason === 'clear') {
                                                            setValue('province', "")
                                                            setValue('district', "")
                                                            setValue('sub_district', "")
                                                            setValue('zip_code', "")
                                                            setProvinceId(-1)
                                                            setDistrictId(-1)
                                                            setSubDistrictId(-1)
                                                        } else {
                                                            setValue('province', newValue)
                                                        }
                                                    }}
                                                    getOptionLabel={(option) => option.PROVINCE_NAME || option}
                                                    isOptionEqualToValue={(option, value) => option.PROVINCE_ID === provinceId}
                                                    renderInput={(params) => <TextField fullWidth {...params} label="Province" />}
                                                />
                                            )} />
                                        <FormHelperText error={errors.province ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.province?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Controller
                                            name="district"
                                            control={control}
                                            defaultValue=""
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    disabled={provinceId === -1}
                                                    disablePortal
                                                    id="district"
                                                    options={filterDistrictbyProvinceId}
                                                    value={field.value || null}
                                                    inputValue={field.value || ""}
                                                    onChange={(e, newValue, reason) => {
                                                        if (reason === 'selectOption') {
                                                            setValue('sub_district', "")
                                                            setValue('zip_code', "")
                                                            setSubDistrictId(-1)
                                                            if (newValue?.DISTRICT_NAME) {
                                                                field.onChange(newValue.DISTRICT_NAME)
                                                                setDistrictId(newValue.DISTRICT_ID)
                                                            }
                                                        }
                                                    }}
                                                    onInputChange={(e, newValue, reason) => {
                                                        if (reason === 'clear') {
                                                            setValue('district', "")
                                                            setValue('sub_district', "")
                                                            setValue('zip_code', "")
                                                            setDistrictId(-1)
                                                            setSubDistrictId(-1)
                                                        } else {
                                                            setValue('district', newValue)
                                                        }
                                                    }}
                                                    getOptionLabel={(option) => option.DISTRICT_NAME || option}
                                                    isOptionEqualToValue={(option, value) => option.DISTRICT_ID === districtId}
                                                    renderInput={(params) => <TextField fullWidth {...params} label="District" />}
                                                />
                                            )} />
                                        <FormHelperText error={errors.district ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.district?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Controller
                                            name="sub_district"
                                            control={control}
                                            defaultValue=""
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    disabled={districtId === -1}
                                                    id="sub_district"
                                                    options={filterSubDistrictbyDistrictId}
                                                    value={field.value || null}
                                                    inputValue={field.value || ""}
                                                    onChange={(e, newValue, reason) => {
                                                        if (reason === 'selectOption') {
                                                            setValue('zip_code', "")
                                                            setSubDistrictId(-1)
                                                            if (newValue?.SUB_DISTRICT_NAME) {
                                                                field.onChange(newValue.SUB_DISTRICT_NAME)
                                                                setSubDistrictId(newValue.SUB_DISTRICT_ID)
                                                            }
                                                        }
                                                    }}
                                                    onInputChange={(e, newValue, reason) => {
                                                        if (reason === 'clear') {
                                                            setValue('sub_district', "")
                                                            setValue('zip_code', "")
                                                            setSubDistrictId(-1)
                                                        } else {
                                                            setValue('sub_district', newValue)
                                                        }
                                                    }}
                                                    getOptionLabel={(option) => option.SUB_DISTRICT_NAME || option}
                                                    isOptionEqualToValue={(option, value) => option.SUB_DISTRICT_NAME === value}
                                                    renderInput={(params) => <TextField fullWidth {...params} label="Sub District" />}
                                                />
                                            )} />
                                        <FormHelperText error={errors.sub_district ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.sub_district?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="district">Postal Code</InputLabel>
                                        <Controller
                                            name="zip_code"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <MyTextField {...field} label="Postal Code" />
                                            )}
                                        />
                                        <FormHelperText error={errors.zip_code ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.zip_code?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="phone">Mobile number</InputLabel>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <MyTextField {...field} label="Mobile number" inputProps={{maxLength: 10}}/>
                                            )}
                                        />
                                        <FormHelperText error={errors.phone ? true : false} id="complex-border-textfield-helper-text">
                                            {errors.phone?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container columnSpacing={2} rowSpacing={2} mt={2}>
                            <Grid item xs={12} md={6}>
                                <Link href="/cart" underline="none">
                                    <Button variant='outlined' fullWidth style={{ textTransform: 'none' }}>Back to Cart</Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button id="credit-card" variant='contained' color="primary" type="submit" fullWidth style={{ textTransform: 'none' }}>
                                    Proceed to Payment
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} rowSpacing={2} columnSpacing={2}>
                        <Paper sx={{ p: 2 }}>
                            <Typography component="h5" variant='h5'>Cart</Typography>
                            <Divider sx={{ mt: 2, mb: 1 }} />
                            <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1}>
                                {items.map((i, index) => {
                                    return (
                                        <Box key={index} display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                                            <Box display="flex" sx={{ justifyContent: "center", alignItems: "center" }}>
                                                <img src={i["image"] ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/product_master/${i.image}`:require("../../assets/card-1.jpg")} style={{ width: "60px", height: "60px", marginRight: "10px" }} />
                                                <Typography component="h6" variant='body1' sx={{ fontSize: "0.8rem" }}>{i[`name_${i18n.language}`]}</Typography>
                                            </Box>
                                            <Box display="flex" sx={{ flexDirection: "column", justifyContent: "center", alignItems: "end" }}>
                                                <Typography component="h5" variant='body1' sx={{ fontSize: "0.8rem" }}>{i.qty} x {moneyFormat(i.price)}</Typography>
                                                <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>฿{moneyFormat(i.price * i.qty)}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1} px={1}>
                                <Box display="flex" flexDirection={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "14px", fontWeight: 400, lineHeight: 1.5 }}>Subtotal : </Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "18px", fontWeight: 600 }}>฿ {moneyFormat(cartTotal)}</Typography>
                                </Box>
                                <Box display="flex" flexDirection={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "14px", fontWeight: 400, lineHeight: 1.5 }}>Shipping : </Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "18px", fontWeight: 600 }}>฿ {moneyFormat("0")}</Typography>
                                </Box>
                                <Box display="flex" flexDirection={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "14px", fontWeight: 400, lineHeight: 1.5 }}>Tax : </Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "18px", fontWeight: 600 }}>฿ {moneyFormat("0")}</Typography>
                                </Box>
                                <Box display="flex" flexDirection={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "14px", fontWeight: 400, lineHeight: 1.5 }}>Discount : </Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "18px", fontWeight: 600 }}>฿ {moneyFormat("0")}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Checkout;