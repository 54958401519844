import { Box, Button, Container, Divider, IconButton, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { fDate, fDateTime } from '../../../utils/formatTime';
import { moneyFormat } from '../../../utils/formatCurrency';
import html2canvas from 'html2canvas';
import Label from '../../../components/Label/Label';
import styled from 'styled-components';
import { fetchOrderById } from '../../../utils/useApi';
import Swal from 'sweetalert2';
import { thphoneFormat } from '../../../utils/formatTelephone';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ExportOrderPDF from './ExportOrderPDF';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';

const StyledTableHeader = styled.th`
    background-color: #e8f6f6;
    font-size: 18px;
    line-height: 1.4;
    padding: 8px;
`
const StyledTableCell = styled.td`
    line-height: 1.4;
    padding: 8px;
`
const CustomLoader = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #766DF4;
    animation:s2 1s infinite linear;
    @keyframes s2 {to{transform: rotate(1turn)}}
`

const OrderDescription = () => {
    const { userName } = useSelector(state => state.auth)
    const { t, i18n } = useTranslation()
    const { order_id } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ "order": {}, "order_details": [] })
    const [loadpdf, setloadpdf] = useState(false)
    const pngRef = useRef(null)
    const handleBack = () => {
        navigate(-1)
    }

    const fetchDetailOrder = async () => {
        const result_orderdetail = await fetchOrderById(JSON.stringify({ "order_no": order_id, "user_name": userName }))
        if (result_orderdetail.status) {
            if (result_orderdetail.data.order != null) {
                setData(result_orderdetail.data)
            } else {
                Swal.fire({
                    customClass: {
                        container: 'my-swal',
                    },
                    icon: 'error',
                    title: 'Error',
                    text: 'Cannot found this order.',
                    allowEscapeKey: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/profile/order')
                    }
                })
            }
        }
    }
    const handleConvertToPng = () => {
        html2canvas(pngRef.current).then((canvas) => {
            /* It's creating a link element and setting the download attribute to the order_id. */
            const link = document.createElement('a');
            link.download = `${order_id}_receipt.png`;

            /* It's converting the canvas to a blob and then creating a URL for it. */
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
            }, 'image/png');
        }).catch((error) => {
            console.error('Error converting HTML to PNG', error);
        })
    }

    const GeneratePDF = () => {
        return (
            <PDFDownloadLink document={<ExportOrderPDF order={data.order} order_detail={data.order_details} />} fileName={`order_${data.order?.order_no}.pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? (
                        <LoadingButton loading variant="outlined">
                            Loading document...
                        </LoadingButton>
                    ) : (
                        <Button variant='contained' startIcon={<DownloadIcon />} disableElevation disabled={data.order?.status !== 'success'}>
                            {t('profile_page.order_description.btn_download')}
                        </Button>
                    )
                }
            </PDFDownloadLink>
        )
    }

    useEffect(() => {
        setLoading(true)
        fetchDetailOrder()
        setTimeout(() => {
            setLoading(false)
        }, 1000)
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="85vh"
            >
                <CustomLoader />
            </Box>
        )
    }
    return (
        <Box>
            <Container maxWidth="lg">
                <Box sx={{ my: 2 }} display={"flex"} flexDirection={"row"} justifyContent="flex-start" alignItems="center">
                    <IconButton size="small" color="inherit" onClick={handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" fontSize={"16pt"} pl={1}>
                        {order_id}
                    </Typography>
                </Box>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Typography variant="h6">
                        {t('profile_page.order_description.header1')}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <List sx={{ p: 0 }}>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.customer')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {data.order?.name}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.order_id')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {data.order?.order_no}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.date')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {fDateTime(data.order?.create_date, "dd/MM/yyyy HH:mm")}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.address')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {data.order?.info_address}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.phone')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {thphoneFormat(data.order?.phone)}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.total')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        ฿ {moneyFormat(data.order?.amount)}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.status')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        <Label color={(data.order?.status === 'waiting' && 'primary') || (data.order?.status === 'success' && 'success') || 'error'}>{data.order?.status}</Label>
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                        <ListItem sx={{ display: "flex", width: "100%", padding: "12px 24px" }}>
                            <ListItemText disableTypography sx={{ display: "flex", flexDirection: "row", flex: "1 1 auto" }}>
                                <Typography component="h6" variant="subtitle2" sx={{ fontWeight: 500, lineHeight: 1.57, minWidth: "180px" }}>
                                    {t('profile_page.order_description.track')}
                                </Typography>
                                <Box sx={{ flex: "1 1 0%", mt: 0 }}>
                                    <Typography variant="body2" component={"p"}>
                                        {data.order?.tracking ? data.order?.tracking : "N/A"}
                                    </Typography>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Paper>
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Box sx={{ my: 1.5 }} display={"flex"} flexDirection={"row"} justifyContent="space-between">
                        <Typography variant="h6">
                            {t('profile_page.order_description.header2')}
                        </Typography>
                        {loadpdf ?
                            <GeneratePDF />
                            : (data.order?.status === 'success' && <Button variant='contained' onClick={() => setloadpdf(!loadpdf)} disableElevation disabled={data.order?.status !== 'success'}>
                                Export PDF
                            </Button>)
                        }
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <TableContainer sx={{ my: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t('profile_page.order_description.table.p_name')}</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>{t('profile_page.order_description.table.qty_meal')}</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>{t('profile_page.order_description.table.timetoeat')}</TableCell>
                                    <TableCell style={{ textAlign: "end" }}>{t('profile_page.order_description.table.amount')}</TableCell>
                                    <TableCell style={{ textAlign: "end" }}>{t('profile_page.order_description.table.total')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.order_details.map((i, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{i.name_en}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{t('profile_page.order_description.table.qty_meal_amount', { num: i.qty_meal }) || "N/A"}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{i[`time_name_${i18n.language}`] || "N/A"}</TableCell>
                                        <TableCell style={{ textAlign: "end" }}>{i.qty}</TableCell>
                                        <TableCell style={{ textAlign: "end" }}>{moneyFormat(i.price)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {data.order?.status === 'success' && (<Box sx={{ my: 3 }} display={"flex"} flexDirection={"row"} justifyContent="space-between">
                    <Typography variant="h6">
                        {t('profile_page.order_description.header3')}
                    </Typography>
                    <Button variant='contained' startIcon={<DownloadIcon />} onClick={handleConvertToPng} disableElevation>
                        {t('profile_page.order_description.btn_download')}
                    </Button>
                </Box>)}
                {data.order?.status === 'success' && (<Container ref={pngRef} maxWidth="sm" sx={{ py: 3, backgroundColor: "#e8f6f6" }} id="slip-area">
                    <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1}>
                        <Box display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                            <Box display="flex" sx={{ justifyContent: "center", alignItems: "center" }}>
                                <img alt="whealth_icon" src={require("../../../assets/W.png")} style={{ width: "150px" }} />
                            </Box>
                            <Box display="flex" sx={{ flexDirection: "column", justifyContent: "center", alignItems: "end" }}>
                                <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>ใบเสร็จรับเงิน</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection={"column"} justifyContent={"space-between"} width="100%" mb={1}>
                        <Box sx={{ p: 2, backgroundColor: "#ffffff" }}>
                            <Box display="flex" flexDirection={"row"} justifyContent={"space-between"}>
                                <Box display="flex" sx={{ flex: 0.65, flexDirection: "column", alignItems: "start", py: 1 }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>Order ID : {data.order?.order_no}</Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>ชื่อ</span> : {data.order?.name}</Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>ที่อยู่</span> : {data.order?.info_address}</Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}><span style={{ fontWeight: 700 }}>เบอร์โทร</span> : {thphoneFormat(data.order?.phone)}</Typography>
                                </Box>
                                <Box display="flex" sx={{ flex: 0.35, flexDirection: "column", alignItems: "start", py: 1, mx: 1 }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>เลขที่ : <span style={{ fontWeight: 700 }}>{data.order?.order_no}</span></Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>วันที่ : <span style={{ fontWeight: 700 }}>{fDate(data.order?.create_date, i18n.language)}</span></Typography>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>ผู้ขาย : <span style={{ fontWeight: 700 }}>Whealth vitamin</span></Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <StyledTableHeader>#</StyledTableHeader>
                                        <StyledTableHeader style={{ width: 240 }}>รายการสินค้า</StyledTableHeader>
                                        <StyledTableHeader style={{ textAlign: "end" }}>จำนวน</StyledTableHeader>
                                        <StyledTableHeader style={{ textAlign: "end" }}>ยอดรวม</StyledTableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.order_details.map((i, index) => (
                                        <tr key={index}>
                                            <StyledTableCell>{index + 1}</StyledTableCell>
                                            <StyledTableCell style={{ width: 240 }}>{i.name_en}</StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "end" }}>{i.qty}</StyledTableCell>
                                            <StyledTableCell style={{ textAlign: "end" }}>{moneyFormat(i.price)}</StyledTableCell>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Divider sx={{ my: 1 }} />
                            <Box display="flex" flexDirection={"row"} flex={1} width="100%" mb={1}>
                                <Box style={{ flex: 0.6, textAlign: "end" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>รวมเป็นเงิน</Typography>
                                </Box>
                                <Box style={{ flex: 0.4, textAlign: "end" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{moneyFormat(data.order?.amount)}</Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Box display="flex" flexDirection={"row"} flex={1} width="100%" mb={1}>
                                <Box style={{ flex: 0.6, textAlign: "end" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>ยอดชำระ</Typography>
                                </Box>
                                <Box style={{ flex: 0.4, textAlign: "end" }}>
                                    <Typography component="h5" variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 600 }}>{moneyFormat(data.order?.amount)}</Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    </Box>
                </Container>)}
            </Container>
        </Box>
    );
};

export default OrderDescription;