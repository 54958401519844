import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addToCart, removeFromCart } from '../../../actions/cart';
import { fetchDetailProduct } from '../../../utils/useApi';
import { moneyFormat } from '../../../utils/formatCurrency';
import { useTranslation } from 'react-i18next';

const ProductDetail = () => {
    const navigate = useNavigate()
    const { i18n, t } = useTranslation()
    const { product_id } = useParams();
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.cart)
    const each_id = items.filter((i) => i.product_id === product_id)[0]
    const [product, setProduct] = useState([{ "image": "" }]);
    const [amount, setAmount] = useState(0);
    // const [selectedImage, setSelectedImage] = useState(`http://223.27.247.179/B2CAPI/img/product_master/${product.image}`);

    const handleAddClick = () => {
        dispatch(addToCart(product))
        setAmount(amount + 1);
    };

    const handleToShopping = () => {
        navigate('/explore/products')
    }
    // const handleMinusClick = () => {
    //     dispatch(removeFromCart(product))
    //     setAmount(amount - 1);
    // };

    // const handleImageClick = (image) => {
    //     setSelectedImage(image);
    // };

    const fetchProduct = async () => {
        const response = await fetchDetailProduct(JSON.stringify({ "product_id": product_id }))
        if (response.status) {
            console.log(response.data["shopping"])
            setProduct(response.data);
            if (response.data["shopping"] !== "Shopping") {
                navigate("/explore/products")
            }
        } else {
            window.location.replace(`/explore/products`)
        }
    };

    useEffect(() => {
        // Fetch product details using the ID from the URL
        fetchProduct();
        setAmount(each_id?.qty)
    }, [product_id, each_id]);

    if (!product) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="85vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg">
            <Grid container my={4} columnSpacing={{ xs: 3, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={12} md={6} display="flex" flexDirection="column" alignItems="center" sx={{
                    pl: 4,
                    pt: 5
                }}>
                    <Box sx={{ width: "300px", height: "300px", mb: 4 }} display="flex" justifyContent="center">
                        <img src={product["image"] ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/product_master/${product.image}` : require("../../../assets/card-1.jpg")} style={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            minHeight: '100 %',
                            maxHeight: '100 %',
                            objectFit: 'fill'
                        }} />
                    </Box>
                    {/* <Box display="flex" overflow="auto">
                        <ProductThumbnail isSelected={selectedImage === "https://picsum.photos/id/200/300"} image="https://picsum.photos/id/200/300" onClick={() => handleImageClick("https://picsum.photos/id/200/300")} />
                        <ProductThumbnail isSelected={selectedImage === "https://picsum.photos/id/221/300"} image="https://picsum.photos/id/221/300" onClick={() => handleImageClick("https://picsum.photos/id/221/300")} />
                        <ProductThumbnail isSelected={selectedImage === "https://picsum.photos/id/236/300"} image="https://picsum.photos/id/236/300" onClick={() => handleImageClick("https://picsum.photos/id/236/300")} />
                    </Box> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ pl: 4, pt: 4 }}>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant='h4' component='h4' sx={{ fontWeight: 700, mb: 1, lineHeight: 1.5 }}>{product[`name_${i18n.language}`]}</Typography>
                        {/* <Typography variant='body1' component='h5' sx={{ fontWeight: 400 }}>{product.name_th}</Typography> */}
                    </Box>
                    {/* <Typography variant='body1' component='h5' sx={{ my: 2 }}>Description</Typography> */}
                    {/* <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                        <Typography variant='body1' component='h5'>Rated : </Typography>
                        <Box sx={{ mx: 1, lineHeight: 1 }}>
                            <Rating name="read-only" value={4} readOnly sx={{ display: "inline-flex", fontSize: "1.25rem" }} />
                        </Box>
                    </Box> */}
                    {/* <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
                        <Typography variant='body1' component='h5'>Number of products available : 200</Typography>
                    </Box> */}
                    <Box sx={{ pt: 1, mb: 3 }}>
                        <Typography variant='h5' component='h5' sx={{ mb: 2, color: "#D23F57", fontWeight: 700, lineHeight: 1 }}>฿ {moneyFormat(product.price)}</Typography>
                        
                        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: product[`description_${i18n.language}`] }}></div>
                       
                        <Typography variant="body1" component="p" align="justify">
                            <b>{t('profile_page.order_description.table.qty_meal')}</b> : {product['qty_meal'] || "N/A"}
                        </Typography>
                        <Typography variant="body1" component="p" align="justify">
                            <b>{t('profile_page.order_description.table.timetoeat')}</b> : {product['time'] ? product['time'][`time_name_${i18n.language}`] : "N/A"}
                        </Typography>
                        <Typography variant="body1" component="p" align="justify">
                            <b>{t('profile_page.order_description.table.qty_serving')}</b> : {product['qty_serving'] || "N/A"}
                        </Typography>
                        <Typography variant="body1" component="p" align="justify">
                            <b>{t('profile_page.order_description.table.fda_no')}</b> : {product['fda_no'] || "N/A"}
                        </Typography>
                    </Box>
                    {/* <Box display="flex" alignItems="center" sx={{ pt: 1, mb: 3 }}>
                        <IconButton onClick={handleMinusClick} disabled={!amount} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "8px", minWidth: "0px", minHeight: "0px" }}>
                            <RemoveIcon fontSize='medium' color="#d23f57" />
                        </IconButton>
                        <Typography variant='body1' component='h5' textAlign="center" sx={{ fontWeight: 600, lineHeight: 1.5, mx: 2.5, fontSize: "20px" }}>{each_id?.qty ? each_id?.qty : "0"}</Typography>
                        <IconButton onClick={handleAddClick} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "8px", minWidth: "0px", minHeight: "0px" }}>
                            <AddIcon fontSize='medium' color="#d23f57" />
                        </IconButton>
                    </Box> */}
                    <Box sx={{ pt: 1, mb: 1 }}>
                        {amount > 0 ? (
                            <Button variant="contained" disabled onClick={handleAddClick} sx={{ px: 3.5, py: 0.75, height: "40px", mb: 1 }}>
                                {t('shopping_page.incart_btn')}
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={handleAddClick} sx={{ px: 3.5, py: 0.75, height: "40px", mb: 1 }}>
                                {t('shopping_page.add_btn')}
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ pt: 1, mb: 1 }}>
                        <Button variant="contained" onClick={handleToShopping} color='success' sx={{ display: { 'sm': 'inherit', 'md': 'none' }, px: 3.5, py: 0.75, height: "40px", mb: 2 }}>
                            {t('shopping_page.con_shop')}
                        </Button>
                    </Box>
                    {/* {amount > 0 ? (
                        <Box display="flex" alignItems="center" sx={{ pt: 1, mb: 3 }}>
                            <IconButton onClick={handleMinusClick} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "8px", minWidth: "0px", minHeight: "0px" }}>
                                <RemoveIcon fontSize='medium' color="#d23f57" />
                            </IconButton>
                            <Typography variant='body1' component='h5' textAlign="center" sx={{ fontWeight: 600, lineHeight: 1.5, mx: 2.5, fontSize: "20px" }}>{amount}</Typography>
                            <IconButton onClick={handleAddClick} style={{ display: 'inline-flex', position: 'relative', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "8px", minWidth: "0px", minHeight: "0px" }}>
                                <AddIcon fontSize='medium' color="#d23f57" />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center" sx={{ pt: 1, mb: 3 }}>
                            <Button variant="contained" onClick={handleAddClick} sx={{ px: 3.5, py: 0.75, height: "40px", mb: 4 }}>
                                Add to Cart
                            </Button>
                        </Box>
                    )} */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductDetail;