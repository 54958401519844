import { Box, Button, Container, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from "@mui/material"
import styled from 'styled-components'
import * as yup from 'yup'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const MyTextField = styled(OutlinedInput)`
    display: inline-flex;
    border-radius: 8px;
    line-height: 1.4375em;
    font-size: 0.95rem;
`

const ChangePasswordValidate = yup.object().shape({
    old_password: yup.string().required("Please Enter Current Password."),
    new_password: yup.string().required("Please Enter your password.").matches(RegExp("(.{7,}.)"), "Password must more than 7 characters.")
        .matches(RegExp("^(?=.*[a-z])(?=.*[A-Z])"), "Password must have uppercase and lowercase letters.")
        .matches(RegExp("(.*\\d.*)"), "Password must have numberic characters.")
        .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must have special characters."),
    confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Password do not match.').required(`Please Enter your confirm password.`),
})

const ChangePassword = () => {
    const onSubmit = async (data, { setSubmitting }) => {
        console.log(data)
    }
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(ChangePasswordValidate),
        mode: "onChange"
    })
    return (
        <Box>
            <Container maxWidth="md" component={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 400 }}>
                    Change Password
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="old_password">Current Password</InputLabel>
                            <MyTextField
                                id="old_password"
                                name="old_password"
                                label={"Current Password"}
                                type={"password"}
                                {...register("old_password")}
                            />
                            <FormHelperText error={errors.old_password ? true : false} id="complex-border-textfield-helper-text">
                                {errors.old_password?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} display={{xs: "none", sm: "block"}}></Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="new_password">New Password</InputLabel>
                            <MyTextField
                                id="new_password"
                                name="new_password"
                                label={"New Password"}
                                type={"password"}
                                {...register("new_password")}
                            />
                            <FormHelperText error={errors.new_password ? true : false} id="complex-border-textfield-helper-text">
                                {errors.new_password?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
                            <MyTextField
                                id="confirm_password"
                                name="confirm_password"
                                label={"Confirm Password"}
                                type={"password"}
                            {...register("confirm_password")}
                            />
                            <FormHelperText error={errors.confirm_password ? true : false} id="complex-border-textfield-helper-text">
                                {errors.confirm_password?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                        <Box mt={3} mb={2}>
                            <Button type="submit" variant="outlined" color="primary">
                                Update Password
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default ChangePassword