import { Card, CardMedia, CardContent, Typography, CardActions, Button, Box, Link, Rating, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../../actions/cart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { moneyFormat } from '../../utils/formatCurrency';
import styled from 'styled-components';

const ImageItem = styled.img`
    width: 300px;
    height: 290px;
    aspect-ratio: auto 300/300;
    @media only screen and (max-width: 425px) {
        height: auto;
        min-width: 100%
    }
`

const ProductCard2 = (props) => {
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.cart)
    const each_id = items.filter((i) => i.product_id === props.id)[0]
    const [amount, setAmount] = useState(0);
    const handleAddClick = () => {
        dispatch(addToCart(props.item))
        setAmount(amount + 1);
    };

    const handleMinusClick = () => {
        dispatch(removeFromCart(props.item))
        setAmount(amount - 1);
    };

    useEffect(() => {
        setAmount(each_id?.qty)
    }, [amount, each_id])

    return (
        <Paper elevation={0} sx={{ border: "2px solid #eef1f6", borderRadius: "8px", height: "100%" }}>
            <Box sx={{ textAlign: "center", position: "relative", display: "flex", justifyContent: "center" }}>
                <Link href={`/explore/products/${props.id}`} underline="none">
                    <ImageItem
                        src={props.image ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/product_master/${props.image}` : require("../../assets/card-1.jpg")}
                    />
                </Link>
            </Box>
            <Box sx={{ padding: "1rem" }}>
                <Box display="flex" flexDirection={"column"}>
                    <Box sx={{ display: "flex", flexDirection: "column", minHeight: 64, height: "auto" }}>
                        <Link href={`/explore/products/${props.id}`} underline="none">
                            {
                                props.name.includes("(") ? (
                                    <Typography variant='body1' display='inline' component='div' mb={1} sx={{ lineHeight: 1.5, fontWeight: 600, fontSize: "16px" }}>{props.name.split("(")[0]} <br /> ({props.name.split("(")[1]}</Typography>
                                ) : (
                                    <Typography variant='body1' display='inline' component='div' mb={1} sx={{ lineHeight: 1.5, fontWeight: 600, fontSize: "16px" }}>{props.name}</Typography>
                                )
                            }
                        </Link>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box flex={0.4} display="flex" alignItems="center" >
                            <Typography variant='body1' component='h5' sx={{ fontSize: "16px", fontWeight: 600, color: "#D23F57" }}>฿ {moneyFormat(props.price)}</Typography>
                            {/* <Rating name="read-only" size="small" value={4} readOnly sx={{ marginLeft: '-1px', fontSize: '1.25rem' }} /> */}
                        </Box>
                        {amount > 0 ? (
                            <Box flex={0.6} display="flex" flexDirection="row" justifyContent="flex-end" width="30px" alignItems="center">
                                <Button onClick={handleMinusClick} style={{ display: 'inline-flex', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "3px", width: "30px", minWidth: "0px", minHeight: "0px" }}>
                                    <RemoveIcon fontSize='medium' color="#d23f57" />
                                </Button>
                                <Typography variant='body1' component='h5' textAlign="center" sx={{ fontWeight: 600, px: 1 }}>{amount}</Typography>
                                <Button onClick={handleAddClick} style={{ display: 'inline-flex', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "3px", width: "30px", minWidth: "0px", minHeight: "0px" }}>
                                    <AddIcon fontSize='medium' color="#d23f57" />
                                </Button>
                            </Box>
                        ) : (
                            <Box flex={0.6} display="flex" flexDirection="row" justifyContent="flex-end" width="30px" alignItems="end">
                                <Button onClick={handleAddClick} style={{ display: 'inline-flex', justifyContent: 'center', backgroundColor: 'transparent', outline: 0, border: '1px solid #e89fab', borderRadius: '4px', fontSize: '0.875rem', padding: "3px", width: "30px", minWidth: "0px", minHeight: "0px" }}>
                                    <AddIcon fontSize='medium' color="#d23f57" />
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Paper >
        // <Card sx={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     height: '100%',
        //     width: '100%',
        // }}>
        //     <CardMedia
        //         alt="Sample"
        //         image={props.image}
        //         height="300"
        //         component="img"
        //     />
        //     <CardContent>
        //         <Link href={`/explore/products/${props.id}`} underline="hover">
        //             <Typography variant="body1" color="text.secondary" component="p" style={{ fontWeight: "bold" }}>
        //                 {props.name}
        //             </Typography>
        //         </Link>
        //         <Rating name="read-only" value={4} readOnly size="small" sx={{marginLeft: '-1px'}}/>
        //     </CardContent>
        //     <Box sx={{ flexGrow: 1 }} />
        //     <Box>
        //         <CardActions sx={{ display: "flex", justifyContent: "space-between", px : 2 }}>
        //             <Typography color="text.secondary">฿ {price} </Typography>
        //             <Button size="small" color="primary" variant='contained'>+</Button>
        //         </CardActions>
        //     </Box>
        // </Card>


        // <Card style={{ width: "20rem" }}>
        //     <div className='text-center'>
        //         <img
        //             alt="Sample"
        //             src={props.image}
        //             style={{
        //                 maxWidth: "100%",
        //                 height: "200px"
        //             }}
        //             className='mt-4'
        //         />
        //     </div>
        //     <CardBody>
        //         <Row>
        //             <Col className="text-center">
        //                 <CardTitle tag="h4">
        //                     {props.name}
        //                 </CardTitle>
        //                 <CardSubtitle
        //                     className="mb-2 text-primary font-weight-bold"
        //                     tag="h4"
        //                 >
        //                     ฿ {price} {props.oldprice ? <span className='fs-6 text-decoration-line-through text-danger'>฿ {props.oldprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : null}
        //                 </CardSubtitle>
        //                 <Button style={{backgroundColor:"#1DC1DD", borderColor:"#1DC1DD"}} className="btn-labeled">Add to Cart <span className="btn-label"><i className="fa fa-shopping-cart"></i></span></Button>
        //             </Col>
        //         </Row>
        //         { props.oldprice ? <h4>
        //             <Badge color="danger" className='position-absolute mt-2 mx-2' style={{ top: 0, left: 0 }}>
        //                 Sale!
        //             </Badge> 
        //         </h4> : null }
        //     </CardBody>
        // </Card>
    )
}

export default ProductCard2