import { Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import AOS from 'aos'
import 'aos/dist/aos.css';

AOS.init()

const StyleCard = styled.div`
    border: 1px solid #A8CECC;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #ffffff;
    color: #979797;
    @media (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
        width: 300px;
    }
`
const ReviewCard = (props) => {
    return (
        <StyleCard>
            <div style={{ padding: "20px 20px 14px 20px" }}>
                <img src={props.img} alt="review_image" width="100%" height="240px" style={{ overflow: "hidden", objectFit: "contain" }}/>
                <Grid container sx={{ margin: "20px 1px 0" }}>
                    <Grid item md={12} lg={2}>
                        <Typography variant="h3" sx={{fontWeight: 700, color: "#A8CECC"}}>
                            {props.num}
                        </Typography>
                    </Grid>
                    <Grid item md={12} lg={10}>
                        <Typography variant="body1" gutterBottom>
                            {props.description}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </StyleCard>
    );
};

export default ReviewCard;