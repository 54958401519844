import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Container, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material"
import styled from 'styled-components'
import * as yup from 'yup'
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from 'react-router-dom';
import { Province } from '../../../data/Province'
import { District } from '../../../data/District'
import { SubDistrict } from '../../../data/SubDistrict'
import { useSelector } from 'react-redux';
import { addnewAddressAPI } from '../../../utils/useApi';
import { Zipcode } from '../../../data/Zipcode'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';

const MyTextField = styled(OutlinedInput)`
    display: inline-flex;
    border-radius: 8px;
    line-height: 1.4375em;
    font-size: 0.95rem;
`
const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

const ChangePasswordValidate = yup.object().shape({
    firstname: yup.string().required("Please Enter your firstname."),
    lastname: yup.string().required("Please Enter your lastname."),
    address: yup.string().required("Please Enter your address."),
    province: yup.string().required("Please Enter your province."),
    district: yup.string().required("Please Enter your district."),
    subdistrict: yup.string().required("Please Enter your subdistrict."),
    zip_code: yup.string().required("Please Enter your postal code."),
    phone: yup.string().required("Please Enter your mobile number.").matches(phoneRegex, "Invalid phone number"),
})

const Addressaddpage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userName } = useSelector(state => state.auth)
    const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(ChangePasswordValidate),
        mode: "onChange"
    })
    const [provinceId, setProvinceId] = useState(-1)
    const [districtId, setDistrictId] = useState(-1)
    const [subdistrictId, setSubDistrictId] = useState(-1)
    const getProvinceId = Province.find((i) => i.PROVINCE_ID === provinceId) || []
    const getDistrictId = District.find((i) => i.DISTRICT_ID === districtId) || []
    const filterDistrictbyProvinceId = District.filter((i) => i.PROVINCE_ID === getProvinceId.PROVINCE_ID) || []
    const filterSubDistrictbyDistrictId = SubDistrict.filter((i) => i.DISTRICT_ID === getDistrictId.DISTRICT_ID) || []
    const filterZipCode = Zipcode.find((i) => i.PROVINCE_ID === provinceId.toString() & i.DISTRICT_ID === districtId.toString() & i.SUB_DISTRICT_ID === subdistrictId.toString())
    const onSubmit = async (data, { setSubmitting }) => {
        const address_obj = {
            "firstname": data.firstname,
            "lastname": data.lastname,
            "user_name": userName,
            "address": data.address,
            "district": districtId,
            "province": provinceId,
            "subdistrict": subdistrictId,
            "zip_code": data.zip_code,
            "phone": data.phone,
        }
        Swal.fire({
            customClass: {
                container: 'my-swal',
            },
            title: t('profile_page.address.address_addalert.title_confirm'),
            text: t('profile_page.address.address_addalert.desc_confirm'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('profile_page.edit_profile.alert_message.yes_btn'),
            cancelButtonText: t('profile_page.edit_profile.alert_message.no_btn'),
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading()
                const result_add = await addnewAddressAPI(JSON.stringify(address_obj))
                if (result_add.status) {
                    Swal.close()
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t('profile_page.address.address_addalert.title_success'),
                        text: t('profile_page.address.address_addalert.desc_success'),
                        icon: 'success',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/profile/address')
                        }
                    })
                } else {
                    Swal.close()
                    Swal.fire({
                        customClass: {
                            container: 'my-swal',
                        },
                        title: t('profile_page.address.address_addalert.title_failed'),
                        text: t('profile_page.address.address_addalert.desc_failed'),
                        icon: 'error',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/profile/address')
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (filterZipCode) {
            setValue('zip_code', filterZipCode.ZIPCODE)
        }
    }, [filterZipCode])
    return (
        <Box sx={{ minHeight: "500px" }}>
            <Container maxWidth="md" component={"form"} onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 400 }}>
                    {t('profile_page.address_form.header_create')}
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="firstname">{t('profile_page.address_form.firstname')}</InputLabel>
                            <MyTextField
                                id="firstname"
                                name="firstname"
                                label={t('profile_page.address_form.firstname')}
                                type={"text"}
                                {...register("firstname")}
                            />
                            <FormHelperText error={errors.firstname ? true : false} id="complex-border-textfield-helper-text">
                                {errors.firstname?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="lastname">{t('profile_page.address_form.lastname')}</InputLabel>
                            <MyTextField
                                id="lastname"
                                name="lastname"
                                label={t('profile_page.address_form.lastname')}
                                type={"text"}
                                {...register("lastname")}
                            />
                            <FormHelperText error={errors.lastname ? true : false} id="complex-border-textfield-helper-text">
                                {errors.lastname?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="address">{t('profile_page.address_form.address')}</InputLabel>
                            <MyTextField
                                id="address"
                                name="address"
                                label={t('profile_page.address_form.address')}
                                type={"text"}
                                {...register("address")}
                                multiline
                                minRows={3}
                            />
                            <FormHelperText error={errors.address ? true : false} id="complex-border-textfield-helper-text">
                                {errors.address?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                id="province"
                                name="province"
                                control={control}
                                defaultValue=""
                                {...register("province")}
                                render={({ field }) => (
                                    <Autocomplete
                                        disablePortal
                                        id="province"
                                        options={Province}
                                        value={field.value || null}
                                        onChange={(e, newValue, reason) => {
                                            if (reason === 'selectOption') {
                                                setValue('district', "")
                                                setValue('subdistrict', "")
                                                setValue('zip_code', "")
                                                setDistrictId(-1)
                                                setSubDistrictId(-1)
                                                if (newValue?.PROVINCE_NAME) {
                                                    field.onChange(newValue.PROVINCE_NAME)
                                                    setProvinceId(newValue.PROVINCE_ID)
                                                }
                                            }


                                        }}
                                        onInputChange={(e, newValue, reason) => {
                                            if (reason === 'clear') {
                                                setValue('province', "")
                                                setValue('district', "")
                                                setValue('subdistrict', "")
                                                setValue('zip_code', "")
                                                setProvinceId(-1)
                                                setDistrictId(-1)
                                                setSubDistrictId(-1)
                                            }
                                        }}
                                        getOptionLabel={(option) => option.PROVINCE_NAME || option}
                                        isOptionEqualToValue={(option, value) => option.PROVINCE_ID === provinceId}
                                        renderInput={(params) => <TextField fullWidth {...params} label={t('profile_page.address_form.province')} />}
                                        noOptionsText={t('profile_page.address_form.no_search_province')}
                                    />
                                )} />
                            <FormHelperText error={errors.province ? true : false} id="complex-border-textfield-helper-text">
                                {errors.province?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                id="district"
                                name="district"
                                control={control}
                                {...register("district")}
                                defaultValue=""
                                render={({ field }) => (
                                    <Autocomplete
                                        disabled={provinceId === -1}
                                        disablePortal
                                        id="district"
                                        options={filterDistrictbyProvinceId}
                                        value={field.value || null}
                                        onChange={(e, newValue, reason) => {
                                            if (reason === 'selectOption') {
                                                setValue('subdistrict', "")
                                                setValue('zip_code', "")
                                                setSubDistrictId(-1)
                                                if (newValue?.DISTRICT_NAME) {
                                                    field.onChange(newValue.DISTRICT_NAME)
                                                    setDistrictId(newValue.DISTRICT_ID)
                                                }
                                            }
                                        }}
                                        onInputChange={(e, newValue, reason) => {
                                            if (reason === 'clear') {
                                                setValue('district', "")
                                                setValue('subdistrict', "")
                                                setValue('zip_code', "")
                                                setDistrictId(-1)
                                                setSubDistrictId(-1)
                                            }
                                        }}
                                        getOptionLabel={(option) => option.DISTRICT_NAME || option}
                                        isOptionEqualToValue={(option, value) => option.DISTRICT_ID === districtId}
                                        renderInput={(params) => <TextField fullWidth {...params} label={t('profile_page.address_form.district')} />}
                                        noOptionsText={t('profile_page.address_form.no_search_district')}
                                    />
                                )} />
                            <FormHelperText error={errors.district ? true : false} id="complex-border-textfield-helper-text">
                                {errors.district?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                id="subdistrict"
                                name="subdistrict"
                                control={control}
                                {...register("subdistrict")}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disabled={districtId === -1}
                                        id="subdistrict"
                                        options={filterSubDistrictbyDistrictId}
                                        value={field.value || null}
                                        onChange={(e, newValue, reason) => {
                                            if (reason === 'selectOption') {
                                                setValue('zip_code', "")
                                                setSubDistrictId(-1)
                                                if (newValue?.SUB_DISTRICT_NAME) {
                                                    field.onChange(newValue.SUB_DISTRICT_NAME)
                                                    setSubDistrictId(newValue.SUB_DISTRICT_ID)
                                                }
                                            }
                                        }}
                                        onInputChange={(e, newValue, reason) => {
                                            if (reason === 'clear') {
                                                setValue('subdistrict', "")
                                                setValue('zip_code', "")
                                                setSubDistrictId(-1)
                                            }
                                        }}
                                        getOptionLabel={(option) => option.SUB_DISTRICT_NAME || option}
                                        isOptionEqualToValue={(option, value) => option.SUB_DISTRICT_NAME === value}
                                        renderInput={(params) => <TextField fullWidth {...params} label={t('profile_page.address_form.sub_district')} />}
                                        noOptionsText={t('profile_page.address_form.no_search_sub_district')}
                                    />
                                )} />
                            <FormHelperText error={errors.subdistrict ? true : false} id="complex-border-textfield-helper-text">
                                {errors.subdistrict?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="district">{t('profile_page.address_form.postal_code')}</InputLabel>
                            <Controller
                                name="zip_code"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <MyTextField {...field} label={t('profile_page.address_form.postal_code')} />
                                )}
                            />
                            <FormHelperText error={errors.zip_code ? true : false} id="complex-border-textfield-helper-text">
                                {errors.zip_code?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="district">{t('profile_page.address_form.mobile_number')}</InputLabel>
                            <MyTextField
                                id="phone"
                                name="phone"
                                label={t('profile_page.address_form.mobile_number')}
                                type={"text"}
                                {...register("phone")}
                            />
                            <FormHelperText error={errors.phone ? true : false} id="complex-border-textfield-helper-text">
                                {errors.phone?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"end"} spacing={3}>
                        <Box mt={3} mb={2} mx={1}>
                            <Button variant="outlined" color="secondary" sx={{ textTransform: 'none' }} onClick={() => navigate(-1)}>
                                {t('profile_page.address_form.cancel_btn')}
                            </Button>
                        </Box>
                        <Box mt={3} mb={2} mx={1}>
                            <Button type="submit" variant="contained" color="primary" sx={{ textTransform: 'none' }}>
                                {t('profile_page.address_form.create_btn')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Addressaddpage;