import { Card, CardMedia, CardContent, Typography, CardActions, Button, Box, Divider, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addToCart } from '../../actions/cart';
import { moneyFormat } from '../../utils/formatCurrency';

const ImageItem = styled.img`
    width: 60px;
    max-width: 100%;
`

const SummaryProductCard = (props) => {
    const dispatch = useDispatch()
    const { items } = useSelector(state => state.cart)
    const price = props.price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const check_exists = items.filter((i) => i.product_id === props.item.product_id)[0]
    const addProducttoCart = () => {
        dispatch(addToCart(props.item))
    }

    return (
        <Paper sx={{ p: 1, position: 'relative', zIndex: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ImageItem
                    src={props.image ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/product_master/${props.image}` : require("../../assets/card-1.jpg")}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" gutterBottom alignItems="center">
                        {props.name}
                    </Typography>
                    <Typography variant="body1" alignItems="end" fontWeight={"bold"}>
                        ฿ {moneyFormat(props.price)}
                    </Typography>
                </Box>
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography variant="body1">
                    believed equator according order song felt appropriate seldom hospital hunt private run powder duty equal dear fort later steel slabs choose explore age green
                </Typography>
            </Box> */}
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {!check_exists ? (
                    <Button onClick={addProducttoCart}>
                        Add
                    </Button>) : (
                    <Button disabled>
                        In cart
                    </Button>
                )}
            </Box>
        </Paper>
        // <Card
        //     sx={{
        //         display: 'flex',
        //         boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)',
        //     }}
        // >
        //     <CardMedia
        //         alt="Sample"
        //         image={require("../../assets/card-1.jpg")}
        //         height="100%"
        //         component="img"
        //     />
        //     <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        //         <CardContent sx={{ flex: '1 0 auto' }}>
        //             <Typography gutterBottom variant="body1" color="text.secondary" component="div" style={{ fontWeight: "bold" }}>
        //                 {props.name}
        //             </Typography>
        //         </CardContent>
        //         <Box sx={{ flexGrow: 1 }} />
        //         <Divider />
        //         <Box>
        //             <CardActions style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
        //                 <Typography color="text.secondary">฿ <b>{price}</b> {props.oldprice ? <span className='fs-6 text-decoration-line-through text-danger'>฿ {props.oldprice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : null}</Typography>
        //                 <Button size="small">Add</Button>
        //             </CardActions>
        //         </Box>
        //     </Box>
        // </Card>
    )
}

export default SummaryProductCard