let initialState = {
  register_data: {
    username: "",
    email: "",
    phone: "",
    password: ""
  },
  user_id_from_assessment: ""
}

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_STORE":
      return {
        ...state,
        register_data: action.payload
      };
    case "ADD_USER_ID":
      return {
        ...state,
        user_id_from_assessment: action.payload
      };
    case "CLEAR_REGISTER_STORE":
      return initialState
    default:
      return state;
  }
};
export default registerReducer;