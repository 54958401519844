import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, CardContent, Skeleton } from '@mui/material';
// utils
import { fDate, fDateTime } from '../../utils/formatTime';
//

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    cursor: "pointer"
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

const CustomLoader = styled('div')`
    width:50px;
    height:50px;
    border-radius:50%;
    border:8px solid;
    border-color:#E4E4ED;
    border-right-color: #766DF4;
    animation:s2 1s infinite linear;
    @keyframes s2 {to{transform: rotate(1turn)}}
`
// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number,
    loading: PropTypes.bool
};

export default function BlogPostCard({ post, index, loading }) {
    // const { cover, title, view, comment, share, author, createdAt } = post;
    const { article_id, cover, post_title, img, create_date } = post;
    const latestPostLarge = index === 0;

    const POST_INFO = [
        { number: create_date, icon: 'eva:message-circle-fill' },
        // { number: view, icon: 'eva:eye-fill' },
        // { number: share, icon: 'eva:share-fill' },
    ];

    return (
        <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 12 : 3}>
            <Card sx={{ position: 'relative' }}>
                <StyledCardMedia
                    sx={{
                        ...((latestPostLarge) && {
                            pt: 'calc(100% * 4 / 3)',
                            '&:after': {
                                top: 0,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
                            },
                        }),
                        ...(latestPostLarge && {
                            pt: {
                                xs: 'calc(50% * 4 / 3)',
                                sm: 'calc(50% * 3 / 4.66)',
                            },
                        }),
                    }}
                >
                    {/* <SvgColor
                        color="paper"
                        src="/assets/icon/shape-avatar.svg"
                        sx={{
                            width: 80,
                            height: 36,
                            zIndex: 9,
                            bottom: -15,
                            position: 'absolute',
                            color: 'background.paper',
                            ...((latestPostLarge || latestPost) && { display: 'none' }),
                        }}
                    /> */}
                    {/* <StyledAvatar
                        alt={author.name}
                        src={author.avatarUrl}
                        sx={{
                            ...((latestPostLarge || latestPost) && {
                                zIndex: 9,
                                top: 24,
                                left: 24,
                                width: 40,
                                height: 40,
                            }),
                        }}
                    /> */}

                    <StyledCover alt={post_title} src={post.img ? `${process.env.REACT_APP_IMAGE_DESTINATION_URL}/article/${post.img}` : `https://dummyimage.com/600x300/e8eaee/000000&text=No+Image`} />
                </StyledCardMedia>

                <CardContent
                    sx={{
                        pt: 3,
                        ...((latestPostLarge) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute',
                        }),
                    }}
                >
                    {latestPostLarge && <Typography gutterBottom variant="button" sx={{ color: 'common.white', display: 'block' }}>
                        NEWEST
                    </Typography>}

                    <StyledTitle
                        color="inherit"
                        variant="subtitle2"
                        underline="none"
                        href={`/blog/${article_id}`}
                        sx={{
                            ...(latestPostLarge && { typography: 'h5', height: 70 }),
                            ...((latestPostLarge) && {
                                color: 'common.white',
                            }),
                        }}
                    >
                        {post_title}
                    </StyledTitle>

                    <StyledInfo>
                        {POST_INFO.map((info, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: index === 0 ? 0 : 1.5,
                                    ...((latestPostLarge) && {
                                        color: 'grey.500',
                                    }),
                                }}
                            >
                                {/* <Iconify icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} /> */}
                                <Typography variant="caption">{fDateTime(info.number)}</Typography> 
                            </Box>
                        ))}
                    </StyledInfo>
                </CardContent>
            </Card>
        </Grid>
    );
}
