import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';

const LoadingBox = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const Loading = () => {
    return (
        <LoadingBox>
            <CircularProgress />
        </LoadingBox>
    );
};

export default Loading;