import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { thphoneFormat } from '../../../utils/formatTelephone';
import ThaiFont from '../../../assets/font/NotoSansThai.ttf';
import { fDate } from '../../../utils/formatTime';
import { getImage } from '../../../utils/useApi';

Font.register({ family: 'Noto Sans Thai', fonts: [{ src: ThaiFont }] })

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    header: {
        marginBottom: 5,
        textAlign: 'center',
        color: 'grey',
        width: 50
    },
    pageNumber: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: 120,
        marginLeft: 20,
        marginBottom: 5

    },
    image: {
        marginTop: 5,
        width: 200
    },
});

const removeHtmlTags = (htmlString) => {
    const cleanedString = htmlString.replace(/<\/li>/g, '\n').replace(/<\/p>/g, '\n');

    const tempElement = document.createElement('div');
    tempElement.innerHTML = cleanedString;

    return tempElement.textContent;
}

const ExportOrderPDF = ({ order_detail, order }) => {
    const segments = [
        { color: '#f04c54', width: 15 },
        { color: '#12c0dd', width: 15 },
        { color: '#808285', width: 40 },
        { color: '#f04c54', width: 8 },
        { color: '#12c0dd', width: 3 },
        { color: '#808285', width: 19 }
    ];

    const totalWidth = 100;

    const getImageAsApi = async (url) => {
        try {
            const response = await getImage({ "filename": url })
            const imageData = await response.data;
            return imageData
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };


    return (
        <Document>
            {order_detail.map((item, index) => (
                <Page size="A4" key={index} style={styles.page} wrap>
                    <View style={styles.section}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} fixed>
                            <Image src={require('../../../assets/W.png')} style={styles.header} />
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', marginBottom: 1 }}>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 3 }}>
                                <View style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: 16, fontWeight: 'extrabold', fontFamily: 'Noto Sans Thai' }}>{item["name_en"]}</Text>
                                    <Text style={{ fontSize: 10, fontWeight: 300, fontFamily: 'Noto Sans Thai' }}>{item["name_th"]}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                                    <Text style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Noto Sans Thai' }}>รายการวิตามิน</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', marginBottom: 1 }}>
                            <View style={{ paddingVertical: 10, paddingHorizontal: 20, backgroundColor: '#ffffff', height: '100%' }}>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingVertical: 1 }}>
                                        <Text style={{ fontSize: 14, fontWeight: 600, fontFamily: 'Noto Sans Thai' }}><Text style={{ fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>Order ID</Text> : {order["order_no"].toString()}</Text>
                                        <Text style={{ fontSize: 14, fontWeight: 300, fontFamily: 'Noto Sans Thai' }}><Text style={{ fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>ชื่อ</Text> : {order["name"].toString()}</Text>
                                        <Text style={{ fontSize: 14, fontWeight: 300, fontFamily: 'Noto Sans Thai' }}><Text style={{ fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>ที่อยู่</Text> : {order["info_address"].toString() + " " + " "}</Text>
                                        <Text style={{ fontSize: 14, fontWeight: 300, fontFamily: 'Noto Sans Thai' }}><Text style={{ fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>เบอร์โทร</Text> : {thphoneFormat(order["phone"].toString())}</Text>
                                        <Text style={{ fontSize: 14, fontWeight: 300, fontFamily: 'Noto Sans Thai' }}><Text style={{ fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>วันที่</Text> : {fDate(order["create_date"])}</Text>
                                        <Text style={{ borderBottom: '1px solid black', width: '100%', marginTop: 5 }} />
                                    </View>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Image
                                        style={styles.image}
                                        src={getImageAsApi(item["image"])}
                                    />
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ fontSize: 14, fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>รายการสินค้า</Text>
                                    <Text style={{ fontSize: 14, fontWeight: 400, fontFamily: 'Noto Sans Thai' }}>{item["name_th"].toString()}</Text>
                                    <Text style={{ fontSize: 14, fontWeight: 700, fontFamily: 'Noto Sans Thai' }}>รายละเอียดสินค้า</Text>
                                    <Text style={{ fontSize: 14, fontWeight: 400, fontFamily: 'Noto Sans Thai' }}>{removeHtmlTags(item["description_en"].toString())}</Text>
                                </View>

                                <Image src={require('../../../assets/W.png')} style={styles.pageNumber} fixed />
                                {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                    `${pageNumber} / ${totalPages}`
                                )} fixed /> */}
                            </View>
                        </View>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: `${totalWidth}%`,
                                height: 20,
                                border: '1px solid #ccc',
                                overflow: 'hidden',
                            }}
                        >
                            {segments.map((segment, index) => (
                                <div
                                    key={index}
                                    style={{
                                        backgroundColor: segment.color,
                                        width: `${segment.width}%`,
                                        height: '100%',
                                    }}
                                />
                            ))}
                        </div>
                    </View>
                </Page>
            ))
            }

        </Document >
    );
};

export default ExportOrderPDF;