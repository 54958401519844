import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import { Box, Paper, useMediaQuery } from '@mui/material';
import SummaryProductCard from './SummaryProductCard';
import { fetchProduct } from '../../utils/useApi';
import { useDispatch } from 'react-redux';
import './style.css'
import { useTranslation } from 'react-i18next';
const AddonsProduct = () => {
    const {i18n} = useTranslation()
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [product, setProduct] = useState([])
    const slidesPerView = product.length < 1 ? 1 : isSmallScreen ? 1 : 1;
    const fetchonTopProduct = async () => {
        const result = await fetchProduct(JSON.stringify({ "filter": "SU" }))
        if (result.status) {
            setProduct(result.data)
        }
    }

    useEffect(() => {
        fetchonTopProduct()
    }, [])

    return (
        <div className="swiper-wrapper">
            <Swiper
                style={{
                    "--swiper-navigation-color": "#80f",
                    "--swiper-pagination-color": "#80f",
                }}
                slidesPerView={slidesPerView}
                spaceBetween={8}
                grabCursor={true}
                touchEventsTarget={"container"}
                navigation={true}
                loop={true}
                modules={[Navigation]}
                className="mySwiper"
            >
                {product.map((i, index) => (
                    <SwiperSlide key={index.toString()}>
                        <Box
                            sx={{
                                display: 'flex',
                                '& > :not(style)': {
                                    mx: 5,
                                    my: 0.5,
                                    width: "100%",
                                    height: "100%",
                                },
                            }}
                        >
                            <SummaryProductCard item={i} name={i[`name_${i18n.language}`]} price={parseFloat(i.price)} image={i.image} />
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper >
        </div>
    );
};

export default AddonsProduct;