import { combineReducers } from 'redux'

import auth from './authReducer'
import assessment from './assessmentReducer'
import profile from './profileReducer'
import cart from './cartReducer'
import register from './registerReducer'
import resetForm from './resetReducer'
const allReducers = combineReducers({
  auth,
  assessment,
  profile,
  cart,
  register,
  resetForm
})

export default allReducers;