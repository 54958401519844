import { ADD_TO_CART, REMOVE_FROM_CART, SET_CART, REMOVEALL_FROM_CART, SET_ORDER_ID, SET_CART_TYPE, CLEAR_ORDER_ID, ADDALTERNATIVE_TO_CART } from '../actions/cart';

const initialState = {
    items: [],
    order_id: "",
    cart_type: ""
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART:
            let exist = state.items.find((item) => item.product_id === action.payload.product_id)
            if (exist) {
                return {
                    ...state,
                    items: state.items.map((item) => {
                        let num = parseInt(item.qty)
                        if (item.product_id === action.payload.product_id) return { ...item, qty: num += 1 };
                        else return item;
                    }),
                };
            } else {
                action.payload["qty"] = 1
                return {
                    ...state,
                    items: [...state.items, action.payload],
                }
            }
        case ADDALTERNATIVE_TO_CART:
            action.payload["qty"] = 1
            return {
                ...state,
                items: [action.payload, ...state.items],
            }
        case REMOVE_FROM_CART:
            let reexist = state.items.find((item) => item.product_id === action.payload.product_id)
            if (reexist.qty > 1) {
                return {
                    ...state,
                    items: state.items.map((item) => {
                        let num = parseInt(item.qty)
                        if (item.product_id === action.payload.product_id) return { ...item, qty: num -= 1 };
                        else return item;
                    }),
                };
            } else {
                return {
                    ...state,
                    items: state.items.filter(item => item.product_id !== action.payload.product_id),
                };
            }
        case REMOVEALL_FROM_CART:
            return {
                ...state,
                items: state.items.filter(item => item !== action.payload)
            };
        case SET_CART:
            return {
                ...state,
                items: action.payload
            }
        case SET_ORDER_ID:
            return {
                ...state,
                order_id: action.payload
            }
        case SET_CART_TYPE:
            return {
                ...state,
                cart_type: action.payload
            }
        case CLEAR_ORDER_ID:
            return {
                ...state,
                order_id: "",
                cart_type: ""
            }
        default:
            return state;
    }
}